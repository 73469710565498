import React from 'react';
import { recipeDetails } from '../../data/recipedetails';

const NutritionPanel = (props) => {
  const { nutritionList } = props;

  return (
    <div className="nutrition-panel1">
      <div className="nutrition-title">Nutrition Facts</div>
      <div className="nutrition-servings">
        Servings: <span className="view-prep-details-container">{nutritionList.servings}</span>{' '}
      </div>
      <div>Amount per serving </div>
      <div className="view-calories">
        Calories: <span className="view-calories-details">{nutritionList.calories}</span>{' '}
      </div>
      <div className="view-prep-cal">
        Calories from Fat: <span>{nutritionList.caloriefat}</span>{' '}
      </div>
      <div className="view-daily-val">% Daily Value*</div>
      <div>
        {recipeDetails.nutritionDetails.map((detail, i) => {
          return (
            <div key={`nutrition-detail-${i}`}>
              <div className="view-vitamin">
                <div>
                  {detail.name} <span>{detail.mg}</span>
                </div>
                <div className="total-fat-percent">{detail.percent}</div>
              </div>
              {detail.components.map((sub, i) => {
                return (
                  <div key={`vitamin-sub-${i}`} className="view-vitamin-sub">
                    <div>
                      {sub.name} <span>{sub.mg}</span>
                    </div>
                    <div className="total-fat-percent">{sub.percent}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NutritionPanel;
