import { request } from './http.service';
// import { decodeShoplistCategoryMessages } from '../protobuf-models/categorizedshoplist.proto.js';
import { decodeShoplistItemMessages } from '../protobuf-models/shoplist.proto.js';
//import { result } from 'lodash';

export const bulkAddItems = (params) => {
  return request('POST', '/shoplist/bulk', 201, params).then(
    (result) => {
      // console.log('RETURNED', result.newItems);
      return result.newItems;
    },
    (err) => {
      //console.log(err)
    }
  );
};

export const addItem = (params) => {
  return request('POST', '/shoplist', 201, params).then(
    (result) => {
      console.log('ADDED ITEM', result);
      return result;
    },
    (err) => {
      //console.log(err)
    }
  );
};

// get items added to shopping list via stations
export const getItems = (params) => {
  return request('POST', '/shoplist/search', 200, params, decodeShoplistItemMessages).then(
    (result) => {
      console.log('DECODED ======>', result);
      if (result && result.shoplistitems) {
        return result.shoplistitems.map((s, i) => {
          // s['children'] = JSON.parse(s.IngredientListItems);
          // delete s['IngredientListItems'];
          console.log(`shoplist item ${i + 1}`, s);
          return s;
        });
      } else {
        return [];
      }
    },
    (err) => {
      console.log(err);
    }
  );
};

// get extra information added to shopping list on the page
export const getItemRequester = (operatorId) => {
  return request('GET', '/shoplist/added/items/by/' + operatorId, 200).then((result) => {
    if (result) {
      return result.requesterName;
    } else {
      return null;
    }
  });
};

export const getItemStatus = (type, id) => {
  return request('GET', '/shoplist/in/' + type + '/station/' + id, 200).then(
    (result) => {
      console.log('STATUS OF ITEMS', result);
      return result;
    },
    (err) => {
      //console.log(err);
    }
  );
};

export const itemOrdered = (id, params) => {
  console.log('TYPE OF ITEM ID', typeof id);
  return request('PATCH', '/shoplist/' + id + '/is/ordered', 200, params).then(
    (result) => {
      // console.log('ITEM CHANGED', result);
      return result;
    },
    (err) => {
      //console.log(err)
    }
  );
};

export const itemNeeded = (id, params) => {
  return request('PATCH', '/shoplist/' + id + '/is/needed', 200, params).then(
    (result) => {
      // console.log('ITEM CHANGED', result);
      return result;
    },
    (err) => {
      //console.log(err)
    }
  );
};

export const deleteItem = (id) => {
  return request('DELETE', '/shoplist/' + id, 204).then(
    (result) => {
      return result;
    },
    (err) => {
      //console.log(err);
    }
  );
};

export const deleteStationItems = (type, id) => {
  return request('DELETE', '/shoplist/items/in/' + type + '/station/' + id, 204).then(
    (result) => {
      return result;
    },
    (err) => {
      //console.log(err);
    }
  );
};
