import React, { useState, useEffect, useCallback, useRef } from 'react';
import './index.css';
import ProgressCircle from '../../components/progressCircle/progresscircle';
import { stationDetail } from '../../data/stationdetaildata';
import StationGeneral from './station_general';
import StationFiltered from './station_filtered';
import StationRecipes from '../../components/StationRecipes/stationRecipes';
import StationChecklists from '../../components/StationChecklists/stationChecklists';
import { Select, Spin } from 'antd';
import { getEventStationIngredients } from '../../services/station.service';
import { get, searchEvents, menus as getEventStationMenus } from '../../services/event.service';
import { details } from '../../services/recipe.service';
import { searchmeas } from '../../services/measurement.service';
import { isEmpty } from 'lodash';
import { getCheckList } from '../../services/checklist.service';
import { searchDocLinks } from '../../services/doc.service';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { BackButton, ChevronLeft } from '../../components/common/common_ui';
import ErrorPage from '../../components/ErrorPages/ErrorPage';
import { formatIfNeeded, reformatVal } from './utilities';
import { arrangeDBMeasurementUnits } from '../Recipes/utilities';

const { Option } = Select;

const StationDetailsEvent = (props) => {
  const [filtered, isFiltered] = useState(false);
  const [stations, setStations] = useState([]);
  const [recList, setRecList] = useState([]);
  const [activeRecs, setActiveRecs] = useState([]);
  const [filteredChecklist, setFilteredChecklist] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [showSingleList, setShowSingleList] = useState({});
  const [tableData, setTableData] = useState([]);
  const [pristineIngs, setPristineIngs] = useState([]);
  const [selectedStation, setSelectedStation] = useState(0);
  const [selectedStationName, setSelectedStationName] = useState('');
  const [healthCheckCompleted, setHealthCheckCompleted] = useState(0);
  const [updateHealthCheck, setUpdateHealthCheck] = useState(true);
  const [completed, setCompleted] = useState(0);
  const [updateCompleted, setUpdateCompleted] = useState(true);
  const [totalIngs, setTotalIngs] = useState(0);
  const [completedIngs, setCompletedIngs] = useState(0);
  const [showChecklists, setShowChecklists] = useState(true);
  const [activateChecklists, setActivateList] = useState(true);
  const [updateNeeded, setUpdateNeeded] = useState(true);
  const [neededIngs, setNeeded] = useState(0);
  const [percentNeeded, setPercentNeeded] = useState(0);
  const [stationIngs, setStationIngs] = useState([]);
  const [stationIngr, setStationIngr] = useState([]);
  const [recipesOnly, setRecipesOnly] = useState(false);
  const [stationChecklists, setStationChecklists] = useState([]);
  const [hasUpdateChecklist, setHasUpdateChecklist] = useState(false);
  const [hasRecipeLists, setHasRecipeLists] = useState(false);
  const [showRecipeLists, setShowRecipeLists] = useState(false);
  const [hasList, setHasList] = useState(false);
  const [documents, setDocuments] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSelect, setActiveSelect] = useState(false);
  const [checklistItemUpdate, setChecklistItemUpdate] = useState('0');
  const [stationIngsUpdate, setStationIngsUpdate] = useState('');
  const [stationMenuUpdate, setStationMenuUpdate] = useState('');
  const [stationRestoId, setStationRestoId] = useState(0);
  const [eventChecklistFiltered, setEventChecklistFiltered] = useState(false);
  const [currentChecklist, setCurrentChecklist] = useState(0);
  const [progressOpen, setProgressOpen] = useState(true);
  const [recipeSideReset, resetRecipeSide] = useState(false);
  const [listsReset, resetLists] = useState(false);
  const [filteredList, filterList] = useState(false);
  const [measurements, setMeasurements] = useState(false);
  const [showFirstRecipe, setShowFirstRecipe] = useState(false);

  const showFirstRecipeRef = useRef(showFirstRecipe);
  useEffect(() => {
    showFirstRecipeRef.current = showFirstRecipe;
  }, [showFirstRecipe]);

  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/checklist`));
  const [eventSocket2] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/station`));
  const [eventSocket3] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/menu`));

  const resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  const openRecipePanel_ids = JSON.parse(localStorage.getItem('openRecipePanelStations')) || [];
  const operatorLoggedIn = JSON.parse(localStorage.getItem('operator')) || {};
  const operatorRestaurants = operatorLoggedIn.restaurants;

  const progressCircleSize = 60;
  const progressCircleStroke = 8;
  const progressCircleTextX = 30;
  const progressCircleTextY = 34;

  const toggleProgressOpen = () => {
    setProgressOpen(!progressOpen);
  };

  const handleShowRecipeLists = (bool) => {
    setShowRecipeLists(bool);
  };

  const handleShowChecklists = (bool) => {
    setShowChecklists(bool);
  };

  const handleActivateChecklists = (bool) => {
    setActivateList(bool);
  };

  const checklistCompletedPercent = (() => {
    let totalChecklist = 0;
    let checkeditems = 0;
    for (let i = 0; i < stationIngr.length; i++) {
      const { children } = stationIngr[i];
      for (let j = 0; j < children.length; j++) {
        const { complete } = children[j];
        totalChecklist++;
        if (complete == 2) {
          checkeditems++;
        }
      }
    }
    return totalChecklist > 0 ? Math.round((checkeditems / totalChecklist) * 100) : 0;
  })();

  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }, []);

  const getEventChecklists = (params) => {
    if (selectedStation > 0) {
        getCheckList({
          restaurantId: resto.id,
          event_id: selectedStation,
        }).then((res) => {
          let selectedChecklist = res.checklists.filter((c) => JSON.parse(c.ChecklistItems).length > 0);

          if (selectedChecklist.length === 0) {
            if (openRecipePanel_ids.includes(selectedStation.toString())) {
              isFiltered(true);
              setShowChecklists(false);
              setRecipesOnly(true);
              setActivateList(false);
            } else {
              setShowChecklists(false);
              setActiveList(false);
              setRecipesOnly(true);
              isFiltered(false);
            }
          } else {
            if (openRecipePanel_ids.includes(selectedStation.toString())) {
              setShowChecklists(false);
              setRecipesOnly(true);
              setActivateList(false);
              isFiltered(true);
            } else {
              setShowChecklists(true);
              setRecipesOnly(false);
              setActivateList(true);
              isFiltered(false);
            }
          }

          let lists = selectedChecklist.map((s) => {
            const checklistInfo = JSON.parse(s.info);
            let obj = {
              key: s.id + 'CK',
              id: s.id,
              name: s.title,
              image: checklistInfo?.imageUrl
                ? checklistInfo?.imageUrl
                : checklistInfo?.imageUrlThumb
                ? checklistInfo?.imageUrlThumb
                : '',
              selected: 1,
              itemsTotal: JSON.parse(s.ChecklistItems).length,
              itemsCompleted: JSON.parse(s.ChecklistItems).filter((f) => f?.info?.complete == 2).length,
            };
            return obj;
          });
          let stationSelectors = [
            {
              key: '1',
              name: 'Checklists',
              list: [
                {
                  name: '',
                  list: lists,
                },
              ],
            },
          ];

          if (params.type == 'general') {
            setStationChecklists(stationSelectors);
          }

          if (lists && lists.length > 0) {
            setHasList(true);
          } else {
            setHasList(false);
          }

          if (lists && lists.length === 1) {
            handleSetCurrentChecklist(lists[0].id);
          }

          let tasks = selectedChecklist.map((c) => {
            return JSON.parse(c.ChecklistItems);
          });

          let merged = [].concat.apply([], tasks);

          merged.sort((x, y) => {
            return x.id - y.id;
          });

          let taskItems = merged.map((s) => {
            const assignee = (() => {
              let a = '';
              if (!isEmpty(s.Station)) {
                a = s.Station.name;
              }
              if (!isEmpty(s.Operator)) {
                a = `${s.Operator.firstName} ${s.Operator.lastName} `;
              }
              if (!isEmpty(s.Event)) {
                a = s.Event.name;
              }
              return a;
            })();
            let parsedInfo = s.info;
            let obj = {
              key: s.id,
              name: s.title,
              type: 'child',
              completed: 1,
              checklistId: s.checklistId,
              parentId: s.checklistId.toString() + 'CK',
              keyId: s.checklistId.toString(),
              checked: parsedInfo.checked,
              complete: parsedInfo.complete == 0 ? 1 : parsedInfo.complete,
              description: parsedInfo.description,
              details: parsedInfo.details,
              tags: parsedInfo.tags,
              dueDate: s.dueDate,
              project: s.project,
              priority: s.priority,
              assignee: assignee,
            };
            return obj;
          });

          let taskItems1 = merged.map((s) => {
            const assignee = (() => {
              let a = '';
              if (!isEmpty(s.Station)) {
                a = s.Station.name;
              }
              if (!isEmpty(s.Operator)) {
                a = `${s.Operator.firstName} ${s.Operator.lastName} `;
              }
              if (!isEmpty(s.Event)) {
                a = s.Event.name;
              }
              return a;
            })();
            let parsedInfo = s.info;
            let obj = {
              key: s.id,
              name: s.title,
              type: 'child',
              completed: 1,
              checklistId: s.checklistId,
              parentId: s.checklistId.toString() + 'CK',
              keyId: s.checklistId.toString(),
              checked: parsedInfo.checked,
              complete: parsedInfo.complete == 0 ? 1 : parsedInfo.complete,
              description: parsedInfo.description,
              details: parsedInfo.details,
              tagList: parsedInfo.tags,
              dueDate: s.dueDate,
              project: s.project,
              priority: s.priority,
              assignee: assignee,
            };
            return obj;
          });

          let taskListTile = selectedChecklist.map((s) => {
            const checklistInfo = JSON.parse(s.info);
            let obj = {
              key: s.id + 'CK',
              name: s.title,
              image: checklistInfo?.imageUrl
                ? checklistInfo?.imageUrl
                : checklistInfo?.imageUrlThumb
                ? checklistInfo?.imageUrlThumb
                : '',
              type: 'parent',
              ingredients: 1,
              id: s.id,
              children: [
                {
                  key: s.id,
                  type: 'child',
                  tags: taskItems1.filter((t) => t.checklistId == s.id),
                },
              ],
            };
            return obj;
          });

          setStationIngs(taskListTile);

          let taskList = selectedChecklist.map((s) => {
            let obj = {
              key: s.id + 'CK',
              name: s.title,
              type: 'parent',
              ingredients: 1,
              id: s.id,
              imageUrl:
                s.info && (s.info?.imageUrl || s.info?.imageUrlThumb) ? s.info?.imageUrl || s.info?.imageUrlThumb : '',
              children: taskItems.filter((t) => t.checklistId == s.id),
            };
            return obj;
          });

          setStationIngr(taskList);

          if (taskListTile.length == 1) {
            if (recipeSideReset && showRecipeLists) {
              isFiltered(false);
            } else {
              if (listsReset && !showRecipeLists && !filteredList) {
                if (showFirstRecipeRef.current) {
                  isFiltered(true);
                  setShowFirstRecipe(false);
                } else {
                  isFiltered(false);
                }
              } else {
                isFiltered(true);
              }
            }
            if (listsReset && !showRecipeLists && !filteredList) {
              if (showFirstRecipeRef.current) {
                isFiltered(true);
                setShowFirstRecipe(false);
              } else {
                isFiltered(false);
              }
            } else {
              if (recipeSideReset && showRecipeLists) {
                isFiltered(false);
              } else {
                isFiltered(true);
              }
            }
          } else if (taskList.length == 1) {
            if (recipeSideReset && showRecipeLists) {
              isFiltered(false);
            } else {
              if (listsReset && !showRecipeLists && !filteredList) {
                isFiltered(false);
              } else {
                isFiltered(true);
              }
            }
            if (listsReset && !showRecipeLists && !filteredList) {
              isFiltered(false);
            } else {
              if (recipeSideReset && showRecipeLists) {
                isFiltered(false);
              } else {
                isFiltered(true);
              }
            }
          } else if (params.type !== 'general') {
            isFiltered(true);
            let lists2 = selectedChecklist.map((s) => {
              let obj = {
                key: s.id + 'CK',
                id: s.id,
                name: s.title,
                image:
                  s.info && (s.info?.imageUrl || s.info?.imageUrlThumb)
                    ? s.info?.imageUrl || s.info?.imageUrlThumb
                    : '',
                selected: params.id == s.id ? 1 : 0,
                itemsTotal: JSON.parse(s.ChecklistItems).length,
                itemsCompleted: JSON.parse(s.ChecklistItems).filter((f) => f?.info?.complete == 2).length,
              };
              return obj;
            });
            let stationSelectors2 = [
              {
                key: '1',
                name: 'Checklists',
                list: [
                  {
                    name: '',
                    list: lists2,
                  },
                ],
              },
            ];
            setStationChecklists(stationSelectors2);
          } else if (taskList.length == 0 || taskListTile.length == 0) {
            if (showRecipeLists) {
              if (recipeSideReset) {
                isFiltered(false);
              } else {
                isFiltered(true);
              }
            }
          } else if (taskList.length > 1 || taskListTile.length > 1) {
            if (showRecipeLists) {
              if (recipeSideReset) {
                isFiltered(false);
              } else {
                isFiltered(true);
              }
            }
          } else {
            isFiltered(false);
          }
        });
    } else {
      setShowChecklists(false);
    }
  };

  const getDocLinks = (stationId) => {
    let params = {
      type: 'events',
      linkId: stationId.toString(),
    };
    searchDocLinks(params)
      .then((res) => {
        console.log(res);

        if (res && res.length > 0) {
          let links = res;
          let removeDeleted = links.filter((l) => l.Document.folderId !== null);
          const list = Array.from(
            removeDeleted.reduce(
              (m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]),
              new Map()
            ),
            ([linkId, documentId]) => ({ linkId, documentId })
          );

          console.log(list);
          setDocuments(list[0].documentId.length);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    let accountId = localStorage.getItem('operator') != undefined && 
      JSON.parse(localStorage.getItem('operator')).accountId != null ? 
      JSON.parse(localStorage.getItem('operator')).accountId : '';
    let params = {
      restaurantId: resto.id,
      startTimestamp: '315626789',
      endTimestamp: '64060679385',
      from: 0,
      limit: 1000,
    };

    searchmeas({ accountId: accountId }).then((res) => {
      setMeasurements(arrangeDBMeasurementUnits(res));
    });
    // detects if url has indicated event station id
    if (props.match.params.id) {
      // 1. first it queries for location/restaurant id of event
      get(props.match.params.id).then((res) => {
        const stationRestaurantId = res.restaurantId;

        // if current location is not the same as the event location
        if (stationRestaurantId !== resto.id) {
          // change settings then refresh page
          const appropriateRestaurant = operatorRestaurants.find((r) => r.id === stationRestaurantId * 1);
          const restaurantName = appropriateRestaurant.name;
          localStorage.setItem(
            'restaurant',
            JSON.stringify({
              id: stationRestaurantId.toString(),
              name: restaurantName,
            })
          );
          localStorage.setItem('restaurantId', stationRestaurantId.toString());
          window.location.reload();
        } else {
          // until user is redirected to a new location, then query more details from event station
          searchEvents(params).then((res) => {
            let filterEvs = res.filter((e) => e.type == 'event');
            setStations(filterEvs);
            let selected = res.filter((r) => r.id == props.match.params.id);
            setSelectedStationName(selected[0]?.name);
            setStationRestoId(selected[0]?.restaurantId);
            setSelectedStation(parseInt(props.match.params.id));
            setCompleted(selected[0]?.percentage);
          });
        }
      });
    } else {
      searchEvents(params).then((res) => {
        let filterEvs = res.filter((e) => e.type == 'event');
        setStations(filterEvs);
        setSelectedStationName(res[0].name);
        setSelectedStation(parseInt(res[0].id));
      });
    }

    localStorage.removeItem('checkedRec');
  }, []);

  useEffect(() => {
    if (selectedStation && selectedStation > 0) {
      getEventChecklists({ type: 'general', id: '' });
      getEventStationIngredients(selectedStation).then((ings, i) => {
        if (ings && ings.length > 0) {
          getEventStationMenus(selectedStation).then((menus) => {
            if (menus.EventMenus.length) {
              const eventMenus = menus.EventMenus.map((em, i) => {
                return {
                  menuId: em.menuId,
                  order: i
                }
              });
              console.log('STATION EVENT MENUS', eventMenus);
              let formattedQuantities = ings.map((ing, i) => {
                ing.rowKey = `${i}-${ing.recipeId}-${ing.courseId}-${ing.menuId}-${ing.ingredientId}`;
                const formatOne = 
                  ing?.ingredientQty * 1 > 0 && ing?.measurementId > 0 ? 
                  reformatVal(ing?.ingredientQty, ing.measurementName, ing.ingredientId, measurements) :
                  undefined;
                const formatTwo = ing?.ingredientQty * 1 > 0 ? formatIfNeeded(ing.ingredientQty) : undefined;
                ing.formattedQty = formatTwo != undefined ? formatTwo : formatOne != undefined ? formatOne : '0';
                ing.menuOrder = eventMenus.filter((m) => m.menuId === ing.menuId)[0].order;
                return ing;
              });
              setPristineIngs(formattedQuantities);
              setHasRecipeLists(true);
            }
          });
        }
      });
      getDocLinks(selectedStation);
      if (recipeSideReset) {
        setActiveSelect(false);
        localStorage.removeItem('checkedRec');
      } else {
        let selected = JSON.parse(localStorage.getItem('checkedRec'));
        if (selected) {
          setActiveSelect(true);
        } else {
          setActiveSelect(false);
        }
      }
    }
  }, [selectedStation, recipeSideReset]);

  const computeOverallStatusIngredients = (data, filtered = true) => {
    let ingredients = [];
    if (filtered) {
      ingredients = data;
    } else {
      ingredients = data.reduce((res, category) => res.concat(category.children), []);
    }

    let completedHealthChecks = ingredients.filter((h) => h.healthCheck == 2);
    let healthCheckPercentage = Math.round(
      (parseInt(completedHealthChecks.length) / parseInt(ingredients.length)) * 100
    );
    if (updateHealthCheck && updateCompleted && updateNeeded) {
      let healthPercent = isNaN(parseInt(healthCheckPercentage)) ? 0 : healthCheckPercentage;
      console.log(healthPercent);
      setHealthCheckCompleted(healthPercent);

      let completedIngs = ingredients.filter((h) => h.completed == 2);
      let completedPercentage = Math.round((parseInt(completedIngs.length) / parseInt(ingredients.length)) * 100);

      let itemsNeeded = ingredients.filter((h) => h.needed == 2);
      let itemsPercentage = Math.round((parseInt(itemsNeeded.length) / parseInt(ingredients.length)) * 100);

      let completePercent = isNaN(parseInt(completedPercentage)) ? 0 : completedPercentage;

      setCompleted(completePercent);
      setCompletedIngs(completedIngs.length);
      setTotalIngs(ingredients.length);
      setNeeded(itemsNeeded.length);
      setPercentNeeded(itemsPercentage);
    }
  };

  const buildCategorizedIngredientsView = (data, activatedRecipes = []) => {
    let toTable = [];
    toTable = data
      .reduce((categories, item) => {
        let theCategory = {
          name: item.categoryName,
          rowKey: `station-${props.match.params.id}-${item.categoryName}`,
          type: 'parent',
          children: [],
          childrenCount: 0,
        };
        const existingCategory = categories.findIndex((cat) => cat.name === item.categoryName);
        if (item.rowKey == undefined) {
          item.rowKey = `${item.key}-${Math.random().toString()}`;
        }
        if (existingCategory === -1) {
          theCategory.children.push(item);
          theCategory.childrenCount += 1;
          categories.push(theCategory);
        } else {
          categories[existingCategory].childrenCount += 1;
          categories[existingCategory].children.push(item);
          categories[existingCategory].children = categories[existingCategory].children.sort((a, b) =>
            a.ingredientId > b.ingredientId ? 1 : a.ingredientId < b.ingredientId ? -1 : 0
          );
        }
        return categories;
      }, [])
      .sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
      );
    if (toTable.length > 0) {
      computeOverallStatusIngredients(toTable, false);
      setTableData(toTable);
    } else {
      setTableData([]);
    }
  };

  const buildRecipeIngredientsView = useCallback((pristineIngs, activeRecs) => {
    if (pristineIngs.length > 0 && activeRecs.length === 1) {
      const data = pristineIngs;
      const key = activeRecs[0].key;
      const toTable = data
        .reduce((toTable, item) => {
          if (key === item.rowKey.slice(item.rowKey.indexOf('-') + 1, item.rowKey.lastIndexOf('-'))) {
            toTable.push(item);
          }
          return toTable;
        }, [])
        .sort((a, b) => a.ingredientId - b.ingredientId)
        .sort((a, b) => (a.ingredientOrder > b.ingredientOrder ? 1 : a.ingredientOrder < b.ingredientOrder ? -1 : 0));

      if (toTable.length > 0) {
        // set total station stats of ingredients to show
        computeOverallStatusIngredients(toTable);
        // set if table view is filtered or not
        
        details(toTable[0].recipeId).then((res) => {
          let newArr = res?.RecipeDetails?.map((r) => ({
            ingredientId: r.ingredientId,
            ingredientOrder: r.info?.order,
          }));

          console.log(newArr);

          let orderedArr = newArr?.reduce((a, b) => {
            a[b.ingredientId] = b;
            return a;
          }, {});
          let combinedOrderedArr = toTable.map((d) => Object.assign(d, orderedArr[d.ingredientId]));
          combinedOrderedArr.sort((x, y) => {
            return x.ingredientOrder - y.ingredientOrder || x.name.localeCompare(y.name);
          });
          console.log(combinedOrderedArr);
          setTableData(combinedOrderedArr);
        });
      } else {
        setTableData([]);
      }
    }
  }, [pristineIngs, activeRecs]);

  useEffect(() => {
    if (pristineIngs.length !== 0) {
      // build fast select menu
      if (recList.length === 0) {
        const toFastSelect = pristineIngs.reduce((result, item) => {
          const newMenu = {
            key: `${item.menuId}`,
            menuId: item.menuId,
            menuName: item.menuName,
            menuOrder: item.menuOrder,
            children: [],
            active: 1,
          };
          const newCourse = {
            key: `${item.courseId}-${item.menuId}`,
            courseId: item.courseId,
            courseName: item.courseName,
            courseOrder: item.courseOrder,
            children: [],
          };
          const newRecipe = {
            key: `${item.recipeId}-${item.courseId}-${item.menuId}`,
            recipeId: item.recipeId,
            recipeName: item.recipeName,
            recipeOrder: item.recipeOrder,
            recipeAssignDate: item.recipeAssignDate,
            recipeImage: item.recipeImage,
            menuId: item.menuId,
            active: 1,
          };

          const existingMenu = result.findIndex((i) => i.menuId === item.menuId);
          // if there are no menu item existing
          if (existingMenu > -1) {
            const existingCourse = result[existingMenu].children.findIndex((i) => i.courseId === item.courseId);

            // if there are no course item existing
            if (existingCourse === -1) {
              newCourse.children = [].concat([newRecipe]);
              result[existingMenu].children = result[existingMenu].children
                .concat([newCourse])
                .sort((a, b) =>
                  a.recipeAssignDate > b.recipeAssignDate ? 1 : a.recipeAssignDate === b.recipeAssignDate ? 0 : -1
                )
                .sort((a, b) => (a.recipeOrder > b.recipeOrder ? 1 : a.recipeOrder === b.recipeOrder ? 0 : -1));
            } else {
              const existingRecipe = result[existingMenu].children[existingCourse].children.findIndex(
                (i) => i.recipeId === item.recipeId
              );

              // if there are no recipe item existing
              if (existingRecipe === -1) {
                result[existingMenu].children[existingCourse].children = result[existingMenu].children[existingCourse].children
                  .concat([newRecipe])
                  .sort((a, b) =>
                    a.recipeAssignDate > b.recipeAssignDate ? 1 : a.recipeAssignDate === b.recipeAssignDate ? 0 : -1
                  )
                  .sort((a, b) => (a.recipeOrder > b.recipeOrder ? 1 : a.recipeOrder === b.recipeOrder ? 0 : -1));
              }
            }
          } else {
            newCourse.children = [].concat([newRecipe]);
            newMenu.children = [].concat([newCourse]);
            result.push(newMenu);
          }
          result.sort((a, b) => a.menuOrder - b.menuOrder);
          console.log(result);
          return result;
        }, []);

        console.log('TOFASTSELECT', toFastSelect);
        setRecList(toFastSelect);
      }
      // if one recipe is selected
      if (activeRecs.length === 1) {
        const tableIsFiltered = recipeSideReset ? false : true;
        if (recipeSideReset) {
          buildCategorizedIngredientsView(pristineIngs, activeRecs);
        } else {
          // const recipeKey = activeRecs[0].key;
          buildRecipeIngredientsView(pristineIngs, activeRecs);
        }

        isFiltered(tableIsFiltered);
        // if more than one recipes are selected
      } else if (activeRecs.length > 1) {
        buildCategorizedIngredientsView(pristineIngs, activeRecs);
        isFiltered(false);
        // if no recipes are selected
      } else {
        if (!showChecklists) {
          buildCategorizedIngredientsView(pristineIngs);
          isFiltered(false);
        }
      }
    } else {
      setRecList([]);
      setTableData([]);
    }
  }, [pristineIngs, activeRecs, recipeSideReset, showChecklists]);

  const selectStation = (event) => {
    setSelectedStationName(event);
    let getId = stations.find((s) => s.name == event).id;
    setSelectedStation(parseInt(getId));
    let selectedStation = getId;
    localStorage.removeItem('checkedRec');
    const url = `/event/station/${selectedStation}`;
    props.history.push(url);
    window.location.reload();
  };

  const filterCompletedHealthChecks = useCallback(() => {
    localStorage.removeItem('checkedRec');
    let healthyIngs = pristineIngs.filter((ing) => ing.healthCheck === 1);
    isFiltered(false);
    buildCategorizedIngredientsView(healthyIngs);
    setUpdateHealthCheck(false);
  }, [pristineIngs]);

  const filterProductionReady = useCallback(() => {
    localStorage.removeItem('checkedRec');
    let readyIngs = pristineIngs.filter((ing) => ing.healthCheck === 2 && ing.completed === 1);
    isFiltered(false);
    buildCategorizedIngredientsView(readyIngs);
    setUpdateHealthCheck(false);
    setUpdateCompleted(false);
    setUpdateNeeded(false);
  }, [pristineIngs]);

  const filterCompleted = useCallback(() => {
    localStorage.removeItem('checkedRec');
    let completedIngs = pristineIngs.filter((ing) => ing.completed === 2);
    isFiltered(false);
    buildCategorizedIngredientsView(completedIngs);
    setUpdateCompleted(false);
  }, [pristineIngs]);

  const filterNeeded = useCallback(() => {
    localStorage.removeItem('checkedRec');
    let listedIngs = pristineIngs.filter((ing) => ing.ordered >= 0);
    isFiltered(false);
    buildCategorizedIngredientsView(listedIngs);
    setUpdateHealthCheck(false);
    setUpdateCompleted(false);
    setUpdateNeeded(false);
  }, [pristineIngs]);

  const showRecipePanels = useCallback((data) => {
    setShowChecklists(false);
    setActivateList(false);
  }, []);

  const updateFilter = useCallback(
    (data) => {
      isFiltered(data);
    },
    [pristineIngs]
  );

  const showFirstRecipeOnly = useCallback(
    () => {
      setShowFirstRecipe(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateDocumentsState = useCallback((data) => {
    let params = {
      type: 'events',
      linkId: props.match.params.id.toString(),
    };
    searchDocLinks(params)
      .then((res) => {
        console.log(res);

        if (res && res.length > 0) {
          let links = res;
          let removeDeleted = links.filter((l) => l.Document.folderId !== null);
          const list = Array.from(
            removeDeleted.reduce(
              (m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]),
              new Map()
            ),
            ([linkId, documentId]) => ({ linkId, documentId })
          );

          console.log(list);
          setDocuments(list[0].documentId.length);
        } else {
          setDocuments(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const updateIngredientsState = useCallback(
    (data) => {
      setRecipesOnly(true);
      setShowChecklists(false);
      setActivateList(false);
      let selected = JSON.parse(localStorage.getItem('checkedRec'));
      if (selected) {
        setActiveSelect(true);
      } else {
        setActiveSelect(false);
      }
      eventSocket2.emit('updatesStationIngs', 'updateIngredients');
    },
    [selectedStation]
  );

  const updateActiveRecs = useCallback((recipes) => {
    let selected = JSON.parse(localStorage.getItem('checkedRec'));
    let newActiveRecs = selected ? recipes.filter((d) => d.key === selected.key) : recipes;

    setActiveRecs(newActiveRecs);
    if (recipes.length == 1) {
      isFiltered(true);
    } else {
      isFiltered(false);
    }
  }, []);

  const updateActiveList = useCallback((data) => {
    console.log('ACTIVE LIST CHECK', data);
    setActiveList(data?.checkedItem);
    if (data?.checkedItem?.length == 1) {
      if (data?.resetList) {
        isFiltered(false);
      } else {
        isFiltered(true);
        setShowSingleList(data?.checkedItem[0]);
      }
    } else {
      if (!data?.resetList) {
        isFiltered(true);
      } else {
        isFiltered(false);
      }
    }
  }, []);

  const showListPanels = useCallback((data) => {
    setShowChecklists(true);
    setActivateList(true);
  }, []);

  const showAllIngredients = useCallback(() => {
    localStorage.removeItem('checkedRec');
    isFiltered(false);
    buildCategorizedIngredientsView(pristineIngs);
    setUpdateHealthCheck(false);
    setUpdateCompleted(false);
    setUpdateNeeded(false);
  }, [pristineIngs]);

  const handleSetCurrentChecklist = useCallback((data) => {
    setCurrentChecklist(parseInt(data));
  });

  const resetAllLists = useCallback((data) => {
    resetLists(data);
  }, []);

  useEffect(() => {
    eventSocket.on('updatedStationTask', (message) => {
      let newData = JSON.parse(message);
      console.log('TASKUPDATED!!', newData.id);
      setChecklistItemUpdate(newData.id);
    });
    eventSocket2.on('updatedStationIngs', (message) => {
      console.log('INGREDIENTSUPDATED!!', message);
      setStationIngsUpdate(message);
    });
    eventSocket3.on('updatedStationMenu', (message) => {
      console.log('MENUUPDATED!!', message);
      setStationMenuUpdate(message);
    });
    return () => {
      eventSocket.disconnect();
      eventSocket2.disconnect();
      eventSocket3.disconnect();
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('stationOrder1', JSON.stringify(tableData));
  }, [tableData]);

  useEffect(() => {
    localStorage.setItem('stationOrder', JSON.stringify(stationIngr));
  }, [stationIngr]);

  useEffect(() => {
    if (checklistItemUpdate != 0) {
      if (filteredList) {
        getEventChecklists({ type: 'filter', id: checklistItemUpdate });
      } else {
        getEventChecklists({ type: 'general', id: checklistItemUpdate });
      }
      setHasUpdateChecklist(true);
      setTimeout(() => {
        setChecklistItemUpdate('0');
      }, 2000);
    } else {
      return;
    }
  }, [checklistItemUpdate, filteredList]);

  useEffect(() => {
    if (stationIngsUpdate) {
      let checked = localStorage.getItem('checkedRec');
      if (checked) {
        setActiveSelect(true);
      } else {
        setActiveSelect(false);
      }
      setRecipesOnly(true);
      setShowChecklists(false);
      setActivateList(false);
    }
  }, [stationIngsUpdate]);

  useEffect(() => {
    if (stationMenuUpdate) {
      let checked = localStorage.getItem('checkedRec');
      if (checked) {
        setActiveSelect(true);
      } else {
        setActiveSelect(false);
      }
      setRecipesOnly(true);
      setShowChecklists(false);
      setActivateList(false);
    }
  }, [stationMenuUpdate]);

  const updateTaskState = useCallback((data) => {
    console.log(data);
    if (data.type == 'general') {
      filterList(false);
      eventSocket.emit(
        'updateStationTask',
        JSON.stringify({
          id: data.id,
        })
      );
    } else {
      filterList(true);
      eventSocket.emit(
        'updateStationTask',
        JSON.stringify({
          id: data.id,
        })
      );
    }
  }, []);

  return operatorRestaurants?.filter((r) => r.id == stationRestoId).length === 1 ? (
    <div id="event-station" className="common-page-container">
      <div className="flex-grid-filter">
        <div className="col-1">
          <div className="common-subheader">
            <BackButton backLink="/dashboard" backText="Back to dashboard" />
          </div>
          <div style={{ top: '90px', right: '45%', position: 'absolute' }}>
            {isLoading && <Spin tip="Loading station data..."></Spin>}
          </div>
          <div className="common-page-content">
            <div id="station-progress" className={`common-controls-container${progressOpen ? '' : ' progress-closed'}`}>
              <div>
                <Select
                  className="station-select"
                  onChange={selectStation}
                  value={selectedStationName}
                  showArrow={false}
                  showSearch
                >
                  {stations.map((station, i) => (
                    <Option key={`station-${i}`} value={station.name}>
                      {station.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className={hasList ? 'station-progress-containerEv' : 'station-progress-containerEvNoList'}>
                {progressOpen ? (
                  <ProgressCircle
                    progress={healthCheckCompleted}
                    size={progressCircleSize}
                    strokeWidth={progressCircleStroke}
                    xText={progressCircleTextX}
                    yText={progressCircleTextY}
                    type="percent"
                  />
                ) : (
                  <div className="progress-circle-text">{healthCheckCompleted}%</div>
                )}
                <div className="progress-label">Quality check completed</div>
              </div>
              <div className={hasList ? 'station-progress-containerEv' : 'station-progress-containerEvNoList'}>
                {progressOpen ? (
                  <ProgressCircle
                    progress={completed * 1}
                    size={progressCircleSize}
                    strokeWidth={progressCircleStroke}
                    xText={progressCircleTextX}
                    yText={progressCircleTextY}
                    type="percent"
                  />
                ) : (
                  <div className="progress-circle-text">{completed}%</div>
                )}
                <div className="progress-label">Production ready</div>
              </div>
              <div className={hasList ? 'station-progress-containerEv' : 'station-progress-containerEvNoList'}>
                {progressOpen ? (
                  <ProgressCircle
                    progress={completed * 1}
                    size={progressCircleSize}
                    strokeWidth={progressCircleStroke}
                    xText={progressCircleTextX}
                    yText={progressCircleTextY}
                    type="ingredients"
                    ing={completedIngs}
                    ingtotal={totalIngs}
                  />
                ) : (
                  <div className="progress-circle-text">
                    {completedIngs} / {totalIngs}
                  </div>
                )}
                <div className="progress-label">Production completed</div>
              </div>
              {hasList ? (
                <div className="station-progress-containerEv">
                  {progressOpen ? (
                    <ProgressCircle
                      progress={checklistCompletedPercent}
                      size={progressCircleSize}
                      strokeWidth={progressCircleStroke}
                      xText={progressCircleTextX}
                      yText={progressCircleTextY}
                      type="percent"
                    />
                  ) : (
                    <div className="progress-circle-text">{completed}%</div>
                  )}
                  <div className="progress-label">Checklists completed</div>
                </div>
              ) : (
                ''
              )}
              <div className="station-progress-container-controls">
                <div
                  className={`station-progress-container-switch${progressOpen ? '' : ' progress-closed'}`}
                  onClick={() => {
                    toggleProgressOpen();
                  }}
                  label={progressOpen ? 'Progress open' : 'Progress closed'}
                >
                  <ChevronLeft />
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              {filtered ? (
                <div>
                  <StationFiltered
                    progressOpen={progressOpen}
                    stationIngs={stationIngs}
                    stationIng={stationIngr}
                    stationDetail={stationDetail}
                    tableData={tableData}
                    filterCompletedHealthChecks={filterCompletedHealthChecks}
                    filterProductionReady={filterProductionReady}
                    filterCompleted={filterCompleted}
                    filterNeeded={filterNeeded}
                    showAllIngredients={showAllIngredients}
                    updateIngredientsState={updateIngredientsState}
                    updateDocumentsState={updateDocumentsState}
                    showSingleList={showSingleList}
                    showChecklists={showChecklists}
                    activateChecklists={activateChecklists}
                    hasUpdateChecklist={hasUpdateChecklist}
                    setHasUpdate={setHasUpdateChecklist}
                    assignedToMe={2}
                    documents={documents}
                    stationId={props.match.params.id}
                    stationName={selectedStationName}
                    setEventChecklistFiltered={setEventChecklistFiltered}
                    stationType="event"
                    selectedId={currentChecklist}
                    pristineIngs={pristineIngs}
                    setPristineIngs={setPristineIngs}
                    updateTaskState={updateTaskState}
                  />
                </div>
              ) : (
                <div>
                  <StationGeneral
                    progressOpen={progressOpen}
                    stationIngs={stationIngs}
                    stationIng={stationIngr}
                    showChecklists={showChecklists}
                    stationDetail={stationDetail}
                    tableData={tableData}
                    filterCompletedHealthChecks={filterCompletedHealthChecks}
                    filterProductionReady={filterProductionReady}
                    filterCompleted={filterCompleted}
                    filterNeeded={filterNeeded}
                    updateIngredientsState={updateIngredientsState}
                    updateDocumentsState={updateDocumentsState}
                    showAllIngredients={showAllIngredients}
                    activeList={activeList}
                    hasUpdateChecklist={hasUpdateChecklist}
                    setHasUpdate={setHasUpdateChecklist}
                    stationId={props.match.params.id}
                    assignedToMe={2}
                    documents={documents}
                    stationName={selectedStationName}
                    stationType="event"
                    pristineIngs={pristineIngs}
                    setPristineIngs={setPristineIngs}
                    updateTaskState={updateTaskState}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-2 recipe-corner">
          <div>
            <StationChecklists
              progressOpen={progressOpen}
              selectedStn={stationChecklists}
              showChecklists={showChecklists}
              handleShowChecklists={handleShowChecklists}
              handleShowRecipeLists={handleShowRecipeLists}
              handleActivateChecklists={handleActivateChecklists}
              updateActiveList={updateActiveList}
              ids={props.match.params.id}
              hasRecipeLists={hasRecipeLists}
              hasList={hasList}
              showListPanels={showListPanels}
              setEventChecklistFiltered={setEventChecklistFiltered}
              setFilteredChecklist={setFilteredChecklist}
              isEvent={true}
              handleSetCurrentChecklist={handleSetCurrentChecklist}
              resetLists={resetAllLists}
              filtered={filtered}
            />
          </div>
          <div>
            <StationRecipes
              showFirstRecipeOnly={showFirstRecipeOnly}
              recipesOnly={recipesOnly}
              updateFilter={updateFilter}
              updateActiveRecs={updateActiveRecs}
              recList={recList}
              showRecipePanels={showRecipePanels}
              ids={props.match.params.id}
              hasRecipeLists={hasRecipeLists}
              showRecipeLists={showRecipeLists}
              handleShowChecklists={handleShowChecklists}
              handleActivateChecklists={handleActivateChecklists}
              handleShowRecipeLists={handleShowRecipeLists}
              pristineIngs={pristineIngs}
              isEvent={true}
              filtered={filtered}
              activeSelect={activeSelect}
              resetAll={resetRecipeSide}
              hasList={hasList}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="common-page-container">
      <ErrorPage copy="Kitchen Closed! This content is unavailable to your user account. If you feel you should have access to this content, please contact your local administrator" />
    </div>
  );
};

export default StationDetailsEvent;
