/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useCallback } from 'react';
import { Table, Menu, Dropdown } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { all, searchAccounts, activateUser, deactivateUser } from '../../services/operator.service'; //destroy
import { all as allAccount } from '../../services/account.service';
import _ from 'lodash';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { NewSubHeader } from '../../components/Header/Header';
import { PlusButton, CidekicDropdown, ThumbnailImage, UserIcon, RowOkCancel } from '../../components/common/common_ui';

const SuperUsers = (props) => {
  const [menuName, setMenuName] = useState('');
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [menulist, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const operatorInfo = JSON.parse(localStorage.getItem('operator') || {});

  useEffect(() => {
    getUsers();
    getAccounts();
  }, []);

  const getUsers = () => {
    setIsLoading(true);
    all(0, 1000).then((res) => {
      let allUsers = res.map((r) => {
        let arr = [],
          keys = Object.keys(r.restaurants);

        for (let i = 0, n = keys.length; i < n; i++) {
          let key = keys[i];
          arr[key] = r.restaurants[key];
        }

        let restoIds = arr.map((r) => r.restaurantId);
        return {
          firstName: r.firstName,
          lastName: r.lastName,
          role: r.role.name,
          active: r.info.activated ? 'Yes' : 'No',
          admin: r.role.name == 'Admin' || r.role.name == 'Superadmin'  ? 'Yes' : 'No',
          email: r.email,
          id: r.id,
          key: r.id,
          username: r.username,
          restaurants: restoIds,
          image: r.info ? r.info?.userImageThumb || r.info?.userImage || r.info?.userImg : '',
          accountId: r.accountId,
          deletedAt: r.deletedAt,
        };
      });
      console.log(allUsers);
      //let activeUsers = allUsers.filter((u) => u.active);
      setData(allUsers);
      setOrigData(allUsers);
      setIsLoading(false);
    });
  };

  const getAccounts = () => {
    allAccount(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setList(allAccounts);
      }
    });
  };

  const debouncedApiCall = useCallback(
    _.debounce((e) => {
      filterUsers(e);
    }, 300),
    []
  );

  useEffect(() => {
    debouncedApiCall(nameFilter);
  }, [nameFilter, debouncedApiCall]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All accounts');
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.accountId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All accounts</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>{m?.name}</a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterUsers = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);

      searchAccounts(keyword, 0, 1000).then((res) => {
        console.log(res);
        let userData = res.map((r) => {
          return {
            firstName: r.firstName,
            lastName: r.lastName,
            role: '',
            active: r.info.activated ? 'Yes' : 'No',
            admin: r.role.name == 'Admin' || r.role.name == 'Superadmin'  ? 'Yes' : 'No',
            email: r.email,
            id: r.id,
            key: r.id,
            username: r.username,
            image: r.info ? r.info?.userImageThumb || r.info?.userImage || r.info?.userImg : '',
          };
        });
        setData(userData);
      });
    } else {
      getUsers();
    }
  };

  const updateSuperUser = (e, obj) => {
    setSelected(null);
    props.history.push(`/super/users/edit/${obj.email}`);
  };

  const addSuperUser = (e) => {
    setSelected(null);
    props.history.push(`/super/users/new`);
  };

  // const deleteSuperUser = (e, obj) => {
  //   e.preventDefault();
  //   console.log(obj?.email);
  //   let email = obj?.email;
  //   destroy(email).then((res) => {
  //     console.log(res);
  //     // notification.open({
  //     //   message: 'User Successfully Deactivated',
  //     // });
  //     getUsers();
  // 		setSelected(null)
  //   });
  // };

  // const handleAddSuperUser = (e) => {
  //   e.preventDefault();
  //   if (!isAdding) {
  //     setIsAdding(true);
  //   }
  //   if (selected) {
  //     setSelected(null);
  //   }
  //   let updateDataSource = _.cloneDeep(userData);
  //   updateDataSource.unshift({
  //     firstName: '',
  //     lastName: '',
  //     role: '',
  //     active: 'No',
  //     admin: 'No',
  //     email: '',
  //     id: '0',
  //     key: '0',
  //     username: '',
  //     restaurants: [],
  //     image: '',
  //     deletedAt: '',
  //   });
  //   setData(updateDataSource);
  // };

  // const handleEditSuperUser = (e) => {
  // 	e.preventDefault()
  // 	setIsEditing(true);
  // }

  const activateSuperUser = (e, obj) => {
    e.preventDefault();
    const userToActivate = obj.id;
    const activator = operatorInfo?.email ? operatorInfo.email : '';

    activateUser(userToActivate.toString(), activator).then((res) => {
      getUsers();
      setSelected(null);
    });
  };

  const deactivateSuperUser = (e, obj) => {
    e.preventDefault();
    const userToDeactivate = obj.id;
    const deactivator = operatorInfo?.email ? operatorInfo.email : '';

    deactivateUser(userToDeactivate.toString(), deactivator).then((res) => {
      getUsers();
      setSelected(null);
    });
  };

  const controlActivatedItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => updateSuperUser(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deactivateSuperUser(e, obj)}>Deactivate</span>
        </Menu.Item>
      </Menu>
    );
  };

  const controlDeactivatedItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => updateSuperUser(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => activateSuperUser(e, obj)}>Activate</span>
        </Menu.Item>
      </Menu>
    );
  };

  const handleCancelRow = () => {
    if (selected) {
      setSelected(null);
    }

    if (isEditing) {
      setIsEditing(false);
    }

    if (isAdding) {
      setIsAdding(false);
      let revertDataSource = _.cloneDeep(userData);
      setData(revertDataSource.filter((d) => d.key !== '0'));
    }
  };

  const handleSetSelected = (obj) => {
    setSelected(obj);
  };

  useEffect(() => {
    if (selected) {
      if (isAdding) {
        setIsAdding(false);
        let revertDataSource = _.cloneDeep(userData);
        setData(revertDataSource.filter((d) => d.key !== '0'));
      }
    }
    if (isEditing) {
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'photo',
      render: (key, obj) => (
        <span>
          <ThumbnailImage type={'admin'} src={obj.image !== '' ? obj.image : null} placeholderIcon={<UserIcon />} />
        </span>
      ),
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      sorter: (a, b) => (a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span onMouseOver={() => handleSetSelected(obj)}>
          <div>{key}</div>
        </span>
      ),
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      sorter: (a, b) => (a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      key: 'lastName',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: (a, b) => (a.role < b.role ? -1 : a.role > b.role ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      key: 'role',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      sorter: (a, b) => (a.active < b.active ? -1 : a.active > b.active ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      key: 'active',
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      sorter: (a, b) => (a.admin < b.admin ? -1 : a.admin > b.admin ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      key: 'admin',
    },
    {
      title: '',
      dataIndex: 'controls',
      render: (key, obj) => (
        <span>
          {(isEditing && selected.key === obj.key) || (isAdding && obj.key === '0') ? (
            <RowOkCancel
              onClickOk={isEditing ? (e) => updateSuperUser(e, obj) : isAdding ? (e) => addSuperUser(e) : () => false}
              onClickCancel={() => handleCancelRow()}
            />
          ) : (
            <Dropdown overlay={obj.active == 'Yes' ? controlActivatedItems(obj) : controlDeactivatedItems(obj)}>
              <div className="toggle-switch">
                <img
                  src={IconsMoreOff}
                  className="UI-IconsMoreOff"
                  alt="IconsMoreOff"
                  onMouseOver={() => handleSetSelected(obj)}
                />
              </div>
            </Dropdown>
          )}
        </span>
      ),
    },
  ];

  return (
    <div id="superadmin-users" className="common-page-container">
      <NewSubHeader title="Users" onChange={(evt) => setNameFilter(evt.target.value)} />
      <div className="Line"></div>
      <div className="common-page-content">
        <div className="common-controls-container controls-thin">
          <PlusButton className="flex-control-left" alt="Add new user" onClick={(e) => addSuperUser(e)}>
            Add new user
          </PlusButton>

          <CidekicDropdown overlay={menuList} className="flex-control-right select-wide">
            {menuName ? menuName : 'All accounts'}
          </CidekicDropdown>
        </div>
        {!isLoading && (
          <Table
            dataSource={userData}
            columns={columns}
            pagination={false}
            className={'show-custom-empty'}
            locale={{ emptyText: <Empty image={empty} description="No users" /> }}
            scroll={{ y: 'calc(100vh - var(--table-scroll-offset))' }}
          />
        )}
      </div>
    </div>
  );
};

export default SuperUsers;
