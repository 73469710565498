/* eslint eqeqeq: "off" */
import Measurement from '../Recipes/measurements.js';

const Fractionals = [
  { value: 0.25, symbol: 'Â¼' },
  { value: 0.3333, symbol: 'â…“' },
  { value: 0.5, symbol: 'Â½' },
  { value: 0.6667, symbol: 'â…”' },
  { value: 0.75, symbol: 'Â¾' },
];
export const formatIfNeeded = (x) => {
  if (isNaN(x)) {
    return fixTheFormat(x);
  }
  if (x % 1 != 0) {
    const wholePart = Math.floor(x);
    // console.log('whole part:', wholePart)
    const fracPart = Math.round((x - wholePart + Number.EPSILON) * 100) / 100;
    // console.log('fractional part:', fracPart)
    const equiv_amt = Fractionals.find((f) => f.value === findClosestValue(fracPart)).symbol;
    // console.log('equivalent fraction', equiv_amt)
    const fixedFormat = fixTheFormat(equiv_amt);
    // console.log('fraction formatted or not:', fixedFormat)
    return `${wholePart ? wholePart.toString() : ''}${fixedFormat}`;
  } else {
    return x;
  }
};

const findClosestValue = (val) => {
  let fractionVals = Fractionals.map((f) => f.value);
  let closestFraction = fractionVals.reduce((prev, curr) => {
    return Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev;
  });
  return closestFraction;
};

const fixTheFormat = (y) => {
  // console.log('the fraction to format:', y);
  if (y.length > 0) {
    return y.includes('Â¼')
      ? y.replace(/Â¼/g, '¼')
      : y.includes('Â½')
      ? y.replace(/Â½/g, '½')
      : y.includes('Â¾')
      ? y.replace(/Â¾/g, '¾')
      : y.includes('â…”')
      ? y.replace(/â…”/g, '⅔')
      : y.includes('â…“')
      ? y.replace(/â…“/g, '⅓')
      : y;
  }
};

export const reformatVal = (val, measUnit, ingId, measurements) => {
  const isScalable =
    measUnit && measUnit !== '' && measurements.find((d) => d.name === measUnit) !== undefined ? true : false;
  if (measUnit && typeof measUnit === 'string' && isScalable) {
    const { id: measure_id, equiv: equiv_amt } = measurements.find((item) => {
      return item.name === measUnit || measUnit.includes(item.name);
    });
    let reformattedVal,
      value = val,
      // eslint-disable-next-line no-unused-vars
      changedUnit = measUnit ? measUnit : 'Select';

    if (equiv_amt) {
      const showMeasure = new Measurement(val, measure_id, ingId, measurements);
      const scaledMeasure = showMeasure.scale(1).reduce();
      reformattedVal = scaledMeasure.quantity_ui_string();
      changedUnit = measurements.find((item) => scaledMeasure.measure_id === item.id).name;
      value = scaledMeasure.quantity;
    } else {
      reformattedVal = value;
    }
    return formatIfNeeded(reformattedVal); // format into desired fractional string
  } else {
    return val ? formatIfNeeded(val) : '0';
  }
};
