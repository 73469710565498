import React, { useState, useEffect, useCallback, useMemo } from 'react';
import TabsDash from '../../components/Tabs/tabsDash';
import MeetingAndEventWeekMobile from '../../components/MeetingAndEventWeek/MeetingAndEventWeekMobile';
import MeetingAndEventMonthMobile from '../../components/MeetingAndEventMonth/MeetingAndEventMonthMobile';
import MeetingAndEventDayMobile from '../../components/MeetingAndEventDay/MeetingAndEventDayMobile';
import KitchenStationMobile from '../../components/KitchenStations/KitchenStationMobile';
import EventStationMobile from '../../components/EventStations/EventStationMobile';
import moment from 'moment';
import { filter } from 'lodash';
import { Menu as MenuOption, Dropdown, DatePicker } from 'antd';
import AddEventDrawerV2 from './components/AddEventDrawerV2';
import {
  getEventsToday,
  getEventsWeek,
  getEventsFuture,
  getCurrentWeek,
  formatGetTime,
  momentize,
} from './DashboardUtils';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { KITCHENSTATIONNAME } from '../../components/common/constant.js';
import { PlusIcon } from '../../components/common/common_ui';

const DashboardMobile = () => {
  let currentWeekStart = moment().startOf('isoWeek');
  const [calendarType, setCalendarType] = useState('day');
  const [day, setDay] = useState(moment(new Date()).format('dddd, MMMM D, YYYY'));
  const [meetingsAndEvents, setMeetingsAndEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [weekStart, setWeekStart] = useState(currentWeekStart);
  const [currentWeek, setCurrentWeek] = useState(getCurrentWeek(weekStart));
  const [restaurant, setRestaurant] = useState('');
  const [showDrawer, setShowDrawer] = useState({ type: '', open: false });
  const [hasCreateEvent, setHasCreatedEvent] = useState(true);
  const [today, setToday] = useState(false);
  const [typeName, setTypeName] = useState('');
  const [eventUpdated, updatedEvent] = useState(false);
  const [addEventUpdate, setAddEventUpdate] = useState('');
  const [editEventUpdate, setEditEventUpdate] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/event`));
  const [monthEvents, setMonthEvents] = useState([]);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [monthAddedEvent, setMonthAddedEvent] = useState(false);

  let momentDateToday = momentize(day);

  const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;

  // get Events for Today

  const _getEventsToday = useCallback(
    (day, setMeetingsAndEvents, resto, calendarType) => {
      getEventsToday(day, setMeetingsAndEvents, resto, calendarType);
    },
    [calendarType, day, weekStart]
  );

  useEffect(() => {
    const restoId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
    setHasCreatedEvent(false);
    updatedEvent(false);
    setMonthAddedEvent(false);
    let resto = window.location.href.includes('impersonate') ? restoId : resto_id;
    // getEventsToday(day, setMeetingsAndEvents, restoId);
    if (calendarType == 'week') {
      _getEventsToday(weekStart, setMeetingsAndEvents, resto, calendarType);
    } else if (calendarType == 'day') {
      _getEventsToday(day, setMeetingsAndEvents, resto, calendarType);
    } else {
      console.log(monthEvents);
      setMeetingsAndEvents(monthEvents);
    }
  }, [
    day,
    hasCreateEvent,
    setMeetingsAndEvents,
    eventUpdated,
    window.location.href,
    calendarType,
    weekStart,
    monthEvents,
  ]);

  const _getEventsWeek = useCallback(
    (weekStart, setCurrentWeek, resto) => {
      getEventsWeek(weekStart, setCurrentWeek, resto);
    },
    [weekStart]
  );

  // getEvents for the Week
  useEffect(() => {
    const restoId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
    setHasCreatedEvent(false);
    updatedEvent(false);
    let resto = window.location.href.includes('impersonate') ? restoId : resto_id;
    _getEventsWeek(weekStart, setCurrentWeek, resto);
  }, [setCurrentWeek, hasCreateEvent, weekStart, eventUpdated, window.location.href]);

  const _getEventsFuture = useCallback(
    (day, setFutureEvents, resto) => {
      getEventsFuture(day, setFutureEvents, resto);
    },
    [day]
  );

  // get Events for Tomorrow Onward
  useEffect(() => {
    const restoId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
    setHasCreatedEvent(false);
    updatedEvent(false);
    let resto = window.location.href.includes('impersonate') ? restoId : resto_id;
    _getEventsFuture(day, setFutureEvents, resto);
  }, [day, hasCreateEvent, setFutureEvents, eventUpdated, window.location.href]);

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    setRestaurant(resto.name);
  }, []);

  const eventStationData = useMemo(() => {
    const eventStationData = [];
    const events = filter(meetingsAndEvents, (ev) => {
      return ev.type === 'event';
    });
    for (let i = 0; i < events.length; i++) {
      const {
        id,
        name,
        startTimestamp,
        endTimestamp,
        percentage,
        type,
        repeat,
        repeatDays,
        info,
        EventMenus,
        EventOperators,
      } = events[i];
      const sortDate = repeatDays ? new Date(repeatDays) : new Date(moment.unix(parseInt(startTimestamp)));
      const date = repeatDays
        ? moment(repeatDays[0]).format('ddd, D')
        : moment.unix(parseInt(startTimestamp)).format('ddd, D');
      const time = `${formatGetTime(startTimestamp)} - ${formatGetTime(endTimestamp)}`;

      eventStationData.push({
        id,
        event: name,
        percentage: percentage ? parseInt(percentage) : 0,
        date,
        time,
        type,
        startTimestamp,
        endTimestamp,
        repeat,
        repeatDays,
        name,
        info,
        EventMenus,
        EventOperators,
      });
    }
    eventStationData.sort((a, b) => {
      return a.sortDate - b.sortDate;
    });
    return eventStationData;
  }, [meetingsAndEvents]);

  const changeDay = (day) => {
    setCalendarType(day);
    if (day == 'day') {
      setTypeName('Day');
    } else if (day == 'week') {
      setTypeName('Week');
    } else if (day == 'month') {
      setTypeName('Month');
    }
  };

  const simultateClickEvent = (id) => {
    let el = document.getElementById(id);
    if (!el) return;
    if (el.fireEvent) {
      el.fireEvent('onclick');
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent('click', true, false);
      el.dispatchEvent(evObj);
    }
  };

  const dayList = (
    <MenuOption className="menu-show-preview">
      {['Day', 'Week', 'Month'].map((m, i) => {
        return (
          <MenuOption.Item className="menu-show-preview" onClick={() => changeDay(m.toLowerCase())}>
            <a>{m}</a>
          </MenuOption.Item>
        );
      })}
    </MenuOption>
  );

  const eventList = (
    <MenuOption className="menu-show-preview">
      {[
        { name: 'event', label: 'Event' },
        { name: 'meeting', label: 'Meeting' },
      ].map((m, i) => {
        return (
          <MenuOption.Item className="menu-show-preview" onClick={() => setShowDrawer({ type: m.name, open: true })}>
            <a>{m.label}</a>
          </MenuOption.Item>
        );
      })}
    </MenuOption>
  );

  const getToday = () => {
    let today = moment(new Date()).format('dddd, MMMM D, YYYY');
    setDay(today);
    setToday(true);
    if (calendarType !== 'day') {
      setCalendarType('day');
    }
    getEventsWeek(currentWeekStart, setCurrentWeek);
  };

  const anotherDay = useCallback(
    (data) => {
      setToday(false);
    },
    [today]
  );

  const editedEvent = useCallback((data) => {
    updatedEvent(data);
  }, []);

  const addedEvent = useCallback((data) => {
    getEventsToday(day, setMeetingsAndEvents, resto_id);
    getEventsFuture(day, setFutureEvents, resto_id);
    getEventsWeek(weekStart, setCurrentWeek, resto_id);
    setMonthAddedEvent(true);
  }, []);

  useEffect(() => {
    eventSocket.on('addedEvent', (message) => {
      console.log('EVENTADDED!!', message);
      setAddEventUpdate(message);
    });
    eventSocket.on('updatedEvent', (message) => {
      console.log('EVENTUPDATED!!', message);
      setEditEventUpdate(message);
    });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (addEventUpdate) {
      getEventsToday(day, setMeetingsAndEvents, resto_id);
      getEventsFuture(day, setFutureEvents, resto_id);
      getEventsWeek(weekStart, setCurrentWeek, resto_id);
    }
  }, [addEventUpdate]);

  useEffect(() => {
    if (editEventUpdate) {
      updatedEvent(true);
    }
  }, [editEventUpdate]);

  const handleDateChange = (e) => {
    console.log(e);
    if (calendarType == 'day') {
      setDay(moment(new Date(e)).format('dddd, MMMM D, YYYY'));
      setOpenDatePicker(false);
    } else if (calendarType == 'week') {
      setWeekStart(e.clone().startOf('isoWeek'));
      setOpenDatePicker(false);
    }
  };

  useEffect(() => {
    const container = document.getElementById('clickbox');

    const listener = (event) => {
      if (container && container !== event.target && !container.contains(event.target)) {
        if (openDatePicker) {
          setOpenDatePicker(false);
        }
      }
    };

    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [openDatePicker]);

  return (
    <div className="marginT10">
      <div className="dash-mobile-title">{restaurant}</div>
      <div className="tabs" id="dashBoardMobileTabs">
        <div className="flex1-100 flex dash-mobile-controls page-margins">
          <div>
            {/* <div id="dayPicker">
              <div>
                <div
                  className={calendarType == 'day' ? 'box-Mobi-selected' : 'box-Mobi'}
                  onClick={() => setCalendarType('day')}
                >
                  Day
                </div>
                <div
                  className={calendarType == 'week' ? 'box-Mobi-selected' : 'box-Mobi'}
                  onClick={() => setCalendarType('week')}
                >
                  Week
                </div>
                <div
                  className={calendarType == 'month' ? 'box-Mobi-selected' : 'box-Mobi'}
                  onClick={() => setCalendarType('month')}
                >
                  Month
                </div>
              </div>
            </div> */}
            <Dropdown overlay={dayList}>
              <div className="incomplete-tasks12 ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                <div>
                  <span>{typeName ? typeName : 'Day'}</span>
                  <span className="doc-icon-mob1">
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </Dropdown>
          </div>
          {calendarType == 'month' ? (
            <div
              className="dash-button-mobile today-btn-mob"
              onClick={() => simultateClickEvent('BigCalendar-nav-today')}
            >
              Today
            </div>
          ) : (
            <div className="dash-button-mobile today-btn-mob" onClick={getToday}>
              Today
            </div>
          )}
          <div>
            <RoleAccessibleComponent
              permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
            >
              {calendarType == 'month' ? (
                <Dropdown overlay={eventList} trigger={['click']}>
                  <div alt="add-button" className="common-plus-icon">
                    <PlusIcon />
                  </div>
                </Dropdown>
              ) : (
                <Dropdown overlay={eventList} trigger={['click']}>
                  <div alt="add-button" className="common-plus-icon">
                    <PlusIcon />
                  </div>
                </Dropdown>
              )}
            </RoleAccessibleComponent>
          </div>
        </div>
        <AddEventDrawerV2
          showDrawer={showDrawer.open}
          type={showDrawer.type}
          setHasCreatedEvent={setHasCreatedEvent}
          setShowDrawer={setShowDrawer}
          addedEvent={addedEvent}
        />
        <div className="Line"></div>
        <div className="marginL20">
          <div className="" id="clickbox">
            {calendarType === 'week' ? (
              <div className="date-container-mobile">
                <div
                  className="dash-button-mobile first-btn"
                  onClick={() => {
                    setWeekStart(weekStart.clone().subtract(1, 'weeks').startOf('isoWeek'));
                  }}
                >
                  <span className="Back-Chevron">&#60;</span>
                </div>
                <div className="dash-button-mobile date-btn-mobile">
                  {openDatePicker ? (
                    <DatePicker
                      getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                      style={{ height: 0 }}
                      open={openDatePicker}
                      onChange={(e) => {
                        handleDateChange(e);
                      }}
                      allowClear={false}
                      size={`small`}
                    />
                  ) : (
                    <div onClick={() => setOpenDatePicker(true)}>
                      {' '}
                      {momentize(currentWeek[0].date).format('D MMMM')} - {currentWeek[6].date}
                    </div>
                  )}
                </div>

                <div
                  className="dash-button-mobile last-btn"
                  onClick={() => {
                    setWeekStart(weekStart.clone().add(1, 'weeks').startOf('isoWeek'));
                  }}
                >
                  <span className="Back-Chevron">&#62;</span>
                </div>
              </div>
            ) : null}
          </div>

          {calendarType === 'week' ? (
            <MeetingAndEventWeekMobile
              day={day}
              weekData={currentWeek}
              setCalendarType={setCalendarType}
              setDay={setDay}
              today={today}
              anotherDay={anotherDay}
              setHasEditedEvent={editedEvent}
            />
          ) : calendarType === 'month' ? (
            <div>
              <br></br>
              <MeetingAndEventMonthMobile
                hasCreateEvent={hasCreateEvent}
                setCalendarType={setCalendarType}
                setDay={setDay}
                today={today}
                setMonthEvents={setMonthEvents}
                monthAddedEvent={monthAddedEvent}
              />
            </div>
          ) : null}
        </div>

        {calendarType == 'day' && (
          <TabsDash>
            <div className="tabs-title" label="Overview">
              <div className="marginT20">
                <div className="marginT20 marginL10" id="clickbox">
                  <div className="flex">
                    <div>
                      <div
                        className="dash-button-mobile first-btn"
                        onClick={() => {
                          setDay(momentDateToday.subtract(1, 'days').format('dddd, MMMM D, YYYY'));
                        }}
                      >
                        <span className="Back-Chevron">&#60;</span>
                      </div>

                      <div className="dash-button-mobile date-btn-mobile">
                        {openDatePicker ? (
                          <DatePicker
                            getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                            style={{ height: 0 }}
                            open={openDatePicker}
                            onChange={(e) => {
                              handleDateChange(e);
                            }}
                            allowClear={false}
                            size={`small`}
                          />
                        ) : (
                          <div onClick={() => setOpenDatePicker(true)}>{day}</div>
                        )}
                      </div>
                      <div
                        className="dash-button-mobile last-btn"
                        onClick={() => {
                          setDay(momentDateToday.add(1, 'days').format('dddd, MMMM D, YYYY'));
                        }}
                      >
                        <span className="Back-Chevron">&#62;</span>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>

                <MeetingAndEventDayMobile
                  daySelected={day}
                  setHasCreatedEvent={setHasCreatedEvent}
                  futureEvents={futureEvents}
                  meetingsAndEvents={meetingsAndEvents}
                  setHasEditedEvent={editedEvent}
                />
              </div>
            </div>

            <div label="Event stations">
              <div className="station-mobile">
                <div className="calendar-nav-mobile">
                  <div className="margin20">
                    <div
                      className="dash-button-mobile first-btn"
                      onClick={() => {
                        setDay(momentDateToday.subtract(1, 'days').format('dddd, MMMM D, YYYY'));
                      }}
                    >
                      <span className="Back-Chevron">&#60;</span>
                    </div>
                    <div className="dash-button-mobile date-btn-mobile">{day}</div>
                    <div
                      className="dash-button-mobile last-btn"
                      onClick={() => {
                        setDay(momentDateToday.add(1, 'days').format('dddd, MMMM D, YYYY'));
                      }}
                    >
                      <span className="Back-Chevron">&#62;</span>
                    </div>
                  </div>
                </div>
                <EventStationMobile
                  eventStationData={eventStationData}
                  setHasCreatedEvent={setHasCreatedEvent}
                  setHasEditedEvent={editedEvent}
                />
              </div>
            </div>
            <div label={KITCHENSTATIONNAME + 's'}>
              <div className="station-mobile">
                <div className="flex">
                  <div className="margin20">
                    <div
                      className="dash-button-mobile first-btn"
                      onClick={() => {
                        setDay(momentDateToday.subtract(1, 'days').format('dddd, MMMM D, YYYY'));
                      }}
                    >
                      <span className="Back-Chevron">&#60;</span>
                    </div>
                    <div className="dash-button-mobile date-btn-mobile">{day}</div>
                    <div
                      className="dash-button-mobile last-btn"
                      onClick={() => {
                        setDay(momentDateToday.add(1, 'days').format('dddd, MMMM D, YYYY'));
                      }}
                    >
                      <span className="Back-Chevron">&#62;</span>
                    </div>
                  </div>
                </div>
                <KitchenStationMobile />
              </div>
            </div>
          </TabsDash>
        )}
      </div>
    </div>
  );
};

export default DashboardMobile;
