import React, { Children, useEffect, useState, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.css';
import CustomEvent from './customEvent';
import CustomToolbar from './customToolbar';
// import { events } from '../../data/calendarmonthevents';
import { getEventsMonth } from './MeetingAndEventMonthUtils';

const localizer = momentLocalizer(moment);

const MeetingAndEventMonth = (props) => {
  const { setCalendarType, setDay, hasCreateEvent, today, monthAddedEvent } = props;
  const defaultDayToday = moment().format('dddd, MMMM D, YYYY');
  const [selectedDate, setSelectedDate] = useState(moment().startOf('month').format('YYYY-MM-DD HH:mm'));
  const [monthlyEvents, setMonthlyEvents] = useState([]);
  const [eventUpdated, updatedEvent] = useState(false);

  useEffect(() => {
    getEventsMonth(selectedDate, setMonthlyEvents);
    updatedEvent(false);
  }, [selectedDate, hasCreateEvent, eventUpdated, monthAddedEvent]);

  // useEffect(() => {
  //   setMonthEvents(monthlyEvents)
  // }, [monthlyEvents]);

  // useEffect(() => {
  //   getEventsMonth(selectedDate, setMonthlyEvents);
  // }, [selectedDate, hasCreateEvent]);

  const eventStyleGetter = () => {
    var style = {
      background: selectedDate ? 'var(--mid-fill)' : '',
    };
    return {
      style: style,
    };
  };

  const DateCellWrapper = (props) => {
    const { children } = props;
    //const isSelected = new Date(value).valueOf() === new Date(selectedDate).valueOf();

    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
      },
    });
  };
  const handleSelectSlot = ({ start, end }) => {
    // setSelectedDate(start, end);
    // console.log("Select specific month day");
    setCalendarType('day');
    let day = moment(new Date(start)).format('dddd, MMMM D, YYYY');
    // console.log(day);
    setDay(day);
  };

  const editedEvent = useCallback((data) => {
    updatedEvent(data);
  }, []);

  return (
    <div className="">
      <div className="main scrollable">
        <div className="dash-calendar-mo">
          <Calendar
            selectable
            className="d-flex flex-column"
            events={monthlyEvents}
            step={60}
            localizer={localizer}
            defaultDate={new Date(defaultDayToday)}
            components={{
              event: (props) => (
                <CustomEvent
                  {...props}
                  // setCalendarType={setCalendarType}
                  // setDay={setDay}
                  setHasEditedEvent={editedEvent}
                />
              ),
              toolbar: (props) => <CustomToolbar {...props} setSelectedDate={setSelectedDate} today={today} />,
              dateCellWrapper: DateCellWrapper,
            }}
            eventPropGetter={eventStyleGetter}
            onSelectSlot={handleSelectSlot}
            popup
            messages={{
              showMore: (total) => (
                <div
                  style={{
                    cursor: 'pointer',
                    background: selectedDate ? 'var(--mid-fill)' : 'var(--deep-fill)',
                    textAlign: 'center',
                    paddingTop: 10,
                  }}
                  onMouseOver={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  {`see more...`}
                </div>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MeetingAndEventMonth;
