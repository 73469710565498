import React, { Suspense } from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';

const Loader = () => <div>loading...</div>;

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>,
    rootElement
  );
} else {
  render(
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
