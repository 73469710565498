/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import reorder from '../../img/reorder-icon.png';
//import emptybar from '../../img/ui-elements-progress-bar-red.svg';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { Menu, Dropdown } from 'antd';
import { destroy } from '../../services/event.service';
import EditEventDrawerV2 from '../../pages/Dashboard/components/EditEventDrawerV2';
import './index.css';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import { UserImage, CidekicProgress } from '../../components/common/common_ui';
import { useMeasure } from 'react-grid-dnd/esm/use-measure';

const EventStations = (props) => {
  const { eventStationData, setHasCreatedEvent, setHasEditedEvent } = props;
  const [selectedId, setSelectedId] = useState('');
  const [showDrawer, setShowDrawer] = useState({ type: '', open: false, data: null });
  //const [items, setItems] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]);
  const [sortedEvents, setSortEvents] = useState([]);

  useEffect(() => {
    if (eventStationData.length > 0) {
      setSortEvents(eventStationData);
    } else {
      setSortEvents([]);
    }
  }, [eventStationData]);

  const deleteEventStation = () => {
    destroy(selectedId).then((res) => {
      setHasCreatedEvent(true);
      setTimeout(() => {
        let removeEvent = sortedEvents.filter((s) => s.id !== selectedId);
        setSortEvents(removeEvent);
      }, 500);
    });
  };

  const editEventStation = (e, obj) => {
    console.log(obj);
    setShowDrawer({ type: obj.type, open: true, data: obj });
  };

  const stationActions = (station) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => editEventStation(e, station)}>
        <a href="#">Edit</a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={deleteEventStation}>
        <a href="#">Delete</a>
      </Menu.Item>
    </Menu>
  );

  // const _eventStations = useMemo(() => eventStations, [eventStations])

  const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    const nextState = swap(sortedEvents, sourceIndex, targetIndex);
    console.log(nextState);
    setSortEvents(nextState);
    // const fetchData = () => {
    //   const allRequests = nextState.map((e, index) =>
    //     updateStation(e.id, { info: { order: index } }).then((res) => res)
    //   );

    //   return Promise.all(allRequests);
    // };
    // fetchData().then((res) => {
    //   notification.open({
    //     message: 'Successfully updated station order!',
    //   });
    //   setTimeout(() => {
    //     getKitchenStationList(setKitchenStations, resto_id);
    //   }, 2000);
    // });
  };

  const containerRef = useRef(null);
  const { bounds } = useMeasure(containerRef);
  const [itemsPerRow, setItemsPerRow] = useState(6);
  const minItemWidth = 160;
  const rowHeight = 184;
  const numRows = Math.max(0, Math.ceil(sortedEvents.length / itemsPerRow));
  useEffect(() => {
    if (bounds) {
      setItemsPerRow(Math.floor(bounds.width / minItemWidth));
    }
  }, [bounds]);

  return (
    <div className="station-collection">
      <div className="station-collection-header">
        <div className="station-collection-title flex-control-vert-center">Event stations</div>
        <div className="flex-control-right flex-control-vert-center">
          <img src={reorder} className="stationToggle" alt="Reorder collections" />
        </div>
      </div>
      <div className="station-container1" style={{ marginBottom: `${numRows * rowHeight + 20}px` }} ref={containerRef}>
        {numRows == 0 && <p>There are no event stations for the current time frame.</p>}
        <div className="station-tiles1">
          <GridContextProvider onChange={onChange}>
            <GridDropZone id="items" boxesPerRow={itemsPerRow} rowHeight={rowHeight}>
              {sortedEvents.map((station, i) => {
                return (
                  <GridItem key={station.id}>
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <div className="event-station d-flex flex-column" key={i}>
                        <div className="stationToggleContainer"></div>
                        <div className="flex flex-row">
                          <CidekicProgress percent={station.percentage} />
                          <RoleAccessibleComponent
                            permittedRoles={[
                              'Chef',
                              'Admin',
                              'Superadmin',
                              'Client Admin',
                              'Dev Team',
                              'Account Holder',
                            ]}
                          >
                            <Dropdown overlay={stationActions(station)} trigger={['click']} className="p-1">
                              <div>
                                <img
                                  src={IconsMoreOff}
                                  className="UI-IconsMoreOff"
                                  alt="IconsMoreOff"
                                  onMouseOver={() => setSelectedId(station.id)}
                                />
                              </div>
                            </Dropdown>
                          </RoleAccessibleComponent>
                        </div>
                        <div onMouseDown={(e) => e.stopPropagation()} className="flex-grow-1">
                          <Link to={`event/station/${station.id}`} className="d-flex flex-column h-100">
                            <div className="event-station-title">{station.event}</div>
                            <div className="flex-grow-1 text-center">
                              <div
                                className="station-tile-assignee-image m-2"
                                alt="assignee"
                                style={{
                                  backgroundColor: `${station.info.color}`,
                                }}
                              >
                                <UserImage
                                  src={!!station.assigneeImg ? station.assigneeImg : null}
                                  showPlaceholder={false}
                                />
                              </div>
                            </div>
                            <div className="eventStationFooter">
                              <div className="event-station-footer-date text-center">{station.date}</div>
                              <div className="event-station-footer-time">{station.time}</div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </GridItem>
                );
              })}
            </GridDropZone>
          </GridContextProvider>
        </div>
        {showDrawer.open && (
          <EditEventDrawerV2
            showDrawer={showDrawer.open}
            type={showDrawer.type}
            data={showDrawer.data}
            setHasEditedEvent={setHasEditedEvent}
            setShowDrawer={setShowDrawer}
          />
        )}
      </div>
    </div>
  );
};

export default EventStations;
