/* eslint eqeqeq: "off" */
import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import './index.css';
import { Collapse } from 'antd';
//import { theCheckList } from '../../data/theCheckListData';
import { CheckmarkIcon } from '../../components/common/common_ui';

const { Panel } = Collapse;

const StationChecklists = (props) => {
  const {
    selectedStn,
    updateActiveList,
    showChecklists,
    handleShowChecklists,
    handleShowRecipeLists,
    handleActivateChecklists,
    ids,
    hasList,
    hasRecipeLists,
    checklistSelected,
    setEventChecklistFiltered,
    setFilteredChecklist,
    isEvent,
    handleSetCurrentChecklist,
    resetLists,
    filtered,
  } = props;
  const [expandedRows, setExpandedRows] = useState(['0']);
  //const [selectedListItem, setSelectedListItem] = useState([]);
  // const [activeRecs, setActiveRecs] = useState([]);
  const [checkedListItem, setChecked] = useState(0);
  const [theCheckList, setTheChecklist] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  let checkList = theCheckList;

  // useEffect(() => {
  //   setSelectedListItem(selectedRec);
  // }, [selectedRec]);

  useEffect(() => {
    console.log(checkedListItem, totalItems, filtered);
  }, [checkedListItem, totalItems, filtered]);

  useEffect(() => {
    console.log(selectedStn);
    setTheChecklist(selectedStn);
    countChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStn]);

  useEffect(() => {
    // console.log('UPDATE!!', theCheckList);
    if (showChecklists) {
      let checkedItem = theCheckList
        .reduce((r, obj) => r.concat(obj.list), [])
        .reduce((r, obj) => r.concat(obj.list), [])
        .filter((l) => l.selected == 1);
      setChecked(checkedItem.length);
      setExpandedRows(['0']);
      if (!filtered) {
        resetAllChecklists();
      }
    } else {
      disableAll();
      setChecked(0);
      setExpandedRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theCheckList, showChecklists, filtered]);

  useEffect(() => {
    console.log(hasList);
  }, [hasList]);

  useEffect(() => {
    if (!checklistSelected) {
      console.log('to deselect all checklists!');
      uncheckAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistSelected]);

  const disableAll = () => {
    checkList.map(function iter(a) {
      a.selected = 0;

      Array.isArray(a.list) && a.list.forEach(iter);
      return a;
    });

    setChecked(0);
    //setSelectedListItem(checkList);
  };

  const togglePanels = (key) => {
    let getActiveStations = JSON.parse(localStorage.getItem('openRecipePanelStations'));
    if (getActiveStations && getActiveStations.find((v) => v === ids)) {
      let newRows = getActiveStations.filter((val) => val !== ids);
      localStorage.removeItem('openRecipePanelStations')
      //localStorage.setItem('openRecipePanelStations', JSON.stringify(newRows));
    }
    if (!showChecklists && hasRecipeLists) {
      handleShowChecklists(true);
      handleShowRecipeLists(false);
      handleActivateChecklists(true);
    }
    setExpandedRows(key);
  };

  const resetAllChecklists = () => {
    handleShowRecipeLists(false);
    checkList.map(function iter(a) {
      a.selected = 1;

      Array.isArray(a.list) && a.list.forEach(iter);
      return a;
    });

    let checkedItem = theCheckList
      .reduce((r, obj) => r.concat(obj.list), [])
      .reduce((r, obj) => r.concat(obj.list), [])
      .filter((l) => l.selected == 1);

    setChecked(checkedItem.length);
    //setSelectedListItem(checkList);
    updateActiveList({ checkedItem: checkedItem, resetList: true });

    resetLists(true);

    if (!showChecklists) {
      handleShowChecklists(true);
      handleShowRecipeLists(false);
      handleActivateChecklists(true);
    }

    if (isEvent) {
      setEventChecklistFiltered(false);
    }
  };

  // const checkAll = () => {
  //   handleShowRecipeLists(false);
  //   checkList.map(function iter(a) {
  //     a.selected = 1;

  //     Array.isArray(a.list) && a.list.forEach(iter);
  //   });

  //   let checkedItem = theCheckList
  //     .reduce((r, obj) => r.concat(obj.list), [])
  //     .reduce((r, obj) => r.concat(obj.list), [])
  //     .filter((l) => l.selected == 1);

  //   setChecked(checkedItem.length);
  //   //setSelectedListItem(checkList);
  //   updateActiveList(checkedItem);

  //   if (!showChecklists) {
  //     handleShowChecklists(true);
  //     handleShowRecipeLists(false);
  //     handleActivateChecklists(true);
  //   }

  //   if (isEvent) {
  //     setEventChecklistFiltered(false);
  //   }
  // };

  const uncheckAll = () => {
    checkList.map(function iter(a) {
      a.selected = 0;
      Array.isArray(a.list) && a.list.forEach(iter);
      return a;
    });

    setChecked(0);
  };

  const selectChecklist = (list, index) => (event) => {
    localStorage.removeItem('openRecipePanelStations');
    resetLists(false);
    handleShowChecklists(true);
    handleShowRecipeLists(false);
    console.log('RECLIST', checkList);
    console.log(list);
    console.log(list.name, list.selected, index);

    if (event.ctrlKey || event.metaKey) {
      checkList.map(function iter(a) {
        if (list.key == a.key) {
          a.selected = 1;
        }
        Array.isArray(a.list) && a.list.forEach(iter);
        return a;
      });

      let checkedItem = checkList
        .reduce((r, obj) => r.concat(obj.list), [])
        .reduce((r, obj) => r.concat(obj.list), [])
        .filter((l) => l.selected == 1);

      console.log('CHECKED ITEM', checkedItem);

      setChecked(checkedItem.length);
      //setSelectedListItem(checkList);
      if (checkedItem.length == 1) {
        console.log('CHECKED ITEM ID', checkedItem[0].id);
        handleSetCurrentChecklist(checkedItem[0].id);
      }
      handleSetCurrentChecklist(checkedItem[0].id);
      //setActiveRecs(checkedItem);
      if (isEvent) {
        setFilteredChecklist(checkedItem);
      }
      console.log(checkedItem);
      updateActiveList({ checkedItem: checkedItem, resetList: false });

      if (!showChecklists && checkedItem.length >= 1) {
        handleShowChecklists(true);
        handleShowRecipeLists(false);
        handleActivateChecklists(true);
      }
    } else {
      checkList.map(function iter(a) {
        if (list.key == a.key) {
          a.selected = 1;
        } else {
          a.selected = 0;
        }
        Array.isArray(a.list) && a.list.forEach(iter);
        return a;
      });

      let checkedItem = checkList
        .reduce((r, obj) => r.concat(obj.list), [])
        .reduce((r, obj) => r.concat(obj.list), [])
        .filter((l) => l.selected == 1);

      console.log('CHECKED ITEM', checkedItem);

      setChecked(checkedItem.length);
      //setSelectedListItem(checkList);
      if (checkedItem.length == 1) {
        console.log('CHECKED ITEM ID', checkedItem[0].id);
        handleSetCurrentChecklist(checkedItem[0].id);
      }
      // setActiveRecs(checkedItem);
      if (isEvent) {
        setFilteredChecklist(checkedItem);
      }
      console.log(checkedItem);
      updateActiveList({ checkedItem: checkedItem, resetList: false });

      if (!showChecklists && checkedItem.length >= 1) {
        handleShowChecklists(true);
        handleShowRecipeLists(false);
        handleActivateChecklists(true);
      }
    }
  };

  const countChecklist = () => {
    let total = 0;
    for (let i = 0; i < selectedStn.length; i++) {
      let loopagain = selectedStn[i].list;
      for (let j = 0; j < loopagain.length; j++) {
        total += loopagain[j].list.length;
      }
    }

    console.log('TOTAL ITEMS ', total);
    setTotalItems(total);
  };

  return (
    <div>
      {hasList ? (
        <div className="station-rec-main">
          <div className="station-reci-title">Checklists </div>
          <div className="station-reci-total">
            {totalItems === 0
              ? ''
              : checkedListItem === totalItems
              ? `All ${checkedListItem} item${checkedListItem > 1 ? 's' : ''} selected`
              : `${checkedListItem} item${checkedListItem > 1 ? 's' : ''} selected`}{' '}
            {(checkedListItem > 0 && checkedListItem < totalItems) || filtered ? (
              <span className="checked-reset" onClick={resetAllChecklists}>
                Reset
              </span>
            ) : (
              ''
            )}
          </div>

          <Collapse defaultActiveKey={expandedRows} expandIconPosition={'right'} onChange={togglePanels}>
            {theCheckList.map((listitem, i) => {
              return (
                <Panel
                  header={
                    <div className={`rec-select${expandedRows.includes(i.toString()) ? ' active' : ''}`}>
                      <CheckmarkIcon />
                      {listitem.name}
                    </div>
                  }
                  key={i.toString()}
                >
                  {listitem.list.map((content, i) => {
                    return (
                      <div className="rec-content-box" key={`listitem-${i.toString()}`}>
                        <div className="rec-content-type">
                          <div></div>
                        </div>
                        {content.list.map((list, i) => {
                          return (
                            <div
                              className={`rec-list${list.selected ? ' active' : ''}`}
                              onClick={selectChecklist(list, i)}
                              key={`content-${i.toString()}`}
                            >
                              {list.itemsCompleted === list.itemsTotal && list.selected == 1 && (
                                <CheckmarkIcon className="active" />
                              )}
                              {list.itemsCompleted === list.itemsTotal && list.selected == 0 && <CheckmarkIcon />}
                              <div className="rec-list-name">{list.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </Panel>
              );
            })}
          </Collapse>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default StationChecklists;
