/* eslint eqeqeq: "off" */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/cidekic_logo.svg';
import { Row, Radio } from 'antd';
//import { SettingFilled, LogoutOutlined } from '@ant-design/icons';
//import profileselected from '../../img/profileselect.png';
import 'antd/dist/antd.css';
import { menuItems, menuItems2 } from '../../data/admin';
//import { userLocation } from '../../data/userlocation';
import admin from '../../img/admin_ic.png';
import logout1 from '../../img/logout_ic.png';
import './index.css';
import { slide as BurgerMenu } from 'react-burger-menu';
import { UserImage, CompletionIcon } from '../../components/common/common_ui';

const AdminMenuMobile = () => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  //const [showAlert, setShowAlert] = useState(false);
  // const [showMsg, setShowMsg] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [restaurants, setRestaurants] = useState([]);
  const [userData, setUserData] = useState(false);
  const [getUrl, setGetUrl] = useState('');
  const [selectedRestaurant, setSelected] = useState({});
  const [selectedRestaurantId, setSelectedId] = useState('');

  useEffect(() => {
    let restos = (JSON.parse(localStorage.getItem('operator')) || {}).restaurants;

    if (restos && restos.length) {
      let restoArr = restos.map((r) => {
        return r;
      });
      restoArr.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      setUserInfo(JSON.parse(localStorage.getItem('operator')) || {});
      setRestaurants(restoArr);

      let selectedResto = localStorage.getItem('restaurant') || '';
      let restaurantId = localStorage.getItem('restaurantId') || '';

      setSelected(selectedResto ? JSON.parse(selectedResto) : '');
      setSelectedId(restaurantId);
    } else {
      setRestaurants([]);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      let container = document.getElementById('profile-menu');
      if (container == null || document.getElementById('profile-menu-icon')?.contains(e.target)) {
        return;
      }
      if (!container.contains(e.target)) {
        setProfileMenuOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    let url = window.location.href;
    if (url.includes('super')) {
      setGetUrl('super');
    } else if (url.includes('admin')) {
      setGetUrl('admin');
    }

    function impersonating() {
      const item = localStorage.getItem('impersonate');

      if (item) {
        setUserData(true);
      }
    }

    window.addEventListener('storage', impersonating);

    return () => {
      window.removeEventListener('storage', impersonating);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const logout = () => {
    Object.keys(localStorage).forEach((key) => {
      if (
        !key.includes('restaurantId') &&
        !key.includes('restaurant') &&
        !key.includes('menuOrder') &&
        !key.includes('checklistOrder') &&
        !key.includes('documentOrder')
      )
        delete localStorage[key];
      window.location = '/public/login';
    });
  };

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    let selected = JSON.parse(e.target.value);
    setSelected(selected);
    setSelectedId(selected.id);

    localStorage.setItem('restaurant', e.target.value);
    localStorage.setItem('restaurantId', selected.id);
    localStorage.removeItem('checklistOrder');
    localStorage.removeItem('menuOrder');
    localStorage.removeItem('documentOrder');
    window.location.reload();
  };

  const handleProfileToggle = () => {
    closeSideMenu();
    setProfileMenuOpen(!profileMenuOpen);
  };

  const handleSideMenuStateChange = (state) => {
    setSideMenuOpen(state.isOpen); // the AdminSideMenu, that is
  };

  const closeSideMenu = () => {
    setSideMenuOpen(false);
    console.log('menu closed');
  };

  const menu = (
    <div id="profile-menu">
      <Row>
        <div className="common-user-icon" alt="user-photo">
          <UserImage
            src={
              userInfo && userInfo.info && (userInfo.info?.userImageThumb || userInfo.info?.userImage)
                ? userInfo.info?.userImageThumb || userInfo.info?.userImage
                : null
            }
          />
        </div>
        <span style={{ fontSize: 16 }}>
          {userInfo.firstName} {userInfo.lastName}
        </span>
      </Row>
      <div className="divider1" />
      <Row>
        {' '}
        <span className="myLocations">My locations</span>
      </Row>
      <Row>
        <Radio.Group onChange={onChange} value={selectedRestaurant}>
          {restaurants.map(function (obj, i) {
            return (
              <div className="locationNames" key={`loc-${i}`}>
                <Radio value={obj.id} key={obj.id}>
                  <span className="menu-location-indicator">
                    <CompletionIcon
                      completed={selectedRestaurantId == obj.id}
                      dimmed={selectedRestaurantId != obj.id}
                    />
                  </span>
                  <span style={{ color: 'var(--base-text' }}> {obj.name}</span>
                </Radio>
              </div>
            );
          })}
        </Radio.Group>
      </Row>
      <div className="divider1" />
      <Row>
        {(userInfo.role !== 'Trainer / Manager' || userInfo.role !== 'Front House') && (
          <div>
            <img src={admin} height="28" style={{ marginLeft: '-10px' }} alt="admin" />
            <Link
              to={
                userInfo.role === 'Admin'
                  ? '/admin/users'
                  : userInfo.role == 'Superadmin'
                  ? '/super/accounts'
                  : getUrl === 'admin' || getUrl === 'super'
                  ? '/admin/locations'
                  : '/dashboard'
                // userInfo.role == 'Admin'
              }
            >
              <span style={{ fontSize: 16 }}>{userInfo.role == 'Superadmin' ? 'Super Admin' : userInfo.role}</span>
            </Link>
          </div>
        )}
      </Row>
      <div className="divider1" />
      <Row onClick={logout} style={{ cursor: 'pointer' }}>
        <img src={logout1} height="28" style={{ marginLeft: '-10px' }} alt="logout" />
        <span style={{ fontSize: 16 }}>Logout</span>
      </Row>
    </div>
  );

  return (
    <div className="header-top-container-mobile">
      <BurgerMenu
        className={'side-menu-mobile'}
        noOverlay
        customCrossIcon={false}
        isOpen={sideMenuOpen}
        onStateChange={(state) => handleSideMenuStateChange(state)}
      >
        <AdminSideMenuMobile userData={userData} getUrl={getUrl} closeMenu={closeSideMenu} />
      </BurgerMenu>
      <div className="logo-container-mobile">
        <Link to={'/dashboard'} onClick={closeSideMenu}>
          <img src={logo} className="cidekic-logo-mobile" alt="logo" />
        </Link>
      </div>
      <div className="badge1">
        {/* <div className="icon-spacing">
          <Badge
            count={5} offset={[4, 2]}
            onMouseEnter={() => setShowAlert(true)}
            onMouseLeave={() => setShowAlert(false)}
          >
            <AlertIcon
              highlight={showAlert}
            />
          </Badge>
        </div>
        <div
          className="icon-spacing-msg"
          onMouseEnter={() => setShowMsg(true)}
          onMouseLeave={() => setShowMsg(false)}
        >
          <ChatIcon
            highlight={showMsg}
          />
        </div> */}
        <div className="icon-spacing-user">
          <div
            className="common-user-icon"
            id="profile-menu-icon"
            onClick={() => handleProfileToggle()}
            alt="user-photo"
          >
            <UserImage
              src={
                userInfo && userInfo.info && (userInfo.info?.userImageThumb || userInfo.info?.userImage)
                  ? userInfo.info?.userImageThumb || userInfo.info?.userImage
                  : profileMenuOpen
                  ? null
                  : null
              }
            />
          </div>
          {profileMenuOpen ? menu : ''}
        </div>
      </div>
    </div>
  );
};

export const AdminSideMenuMobile = (props) => {
  const { userData, getUrl, closeMenu } = props;

  return (
    <div className="side-menu-container-mobile">
      <div>
        <Link to={'/dashboard'}>
          <div className="admin-exit-mobile">Exit admin</div>
        </Link>
        {getUrl == 'admin' && !userData && (
          <div>
            <ul className="order-menu1">
              {menuItems.map((menus, i) => {
                return (
                  <li key={menus.name}>
                    <div className="parent-menu-mobile">
                      <div>{menus.title}</div>
                    </div>
                    {i !== 0 ? <div className="menu-line"></div> : null}
                    <div>
                      <ul>
                        {menus.children.map((sub, ii) => {
                          return (
                            <li key={sub.name}>
                              <div className="sidemenu-menuitem-mobile">
                                <Link to={'/admin/' + sub.name} onClick={() => closeMenu()}>
                                  <div className="flex">
                                    <div>{sub.icon ? sub.icon : null}</div>
                                    <div>{sub.title ? sub.title : null}</div>
                                  </div>
                                </Link>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {getUrl == 'super' && !userData && (
          <div>
            <ul className="order-menu1">
              {menuItems2.map((menus, i) => {
                return (
                  <li key={menus.name}>
                    <div className="parent-menu-mobile">
                      <div>{menus.title}</div>
                    </div>
                    {i !== 0 ? <div className="menu-line"></div> : null}
                    <div>
                      <ul>
                        {menus.children.map((sub, ii) => {
                          return (
                            <li key={sub.name}>
                              <div className="sidemenu-menuitem-mobile">
                                <Link to={'/super/' + sub.name} onClick={() => closeMenu()}>
                                  <div className="flex">
                                    <div>{sub.icon ? sub.icon : null}</div>
                                    <div>{sub.title ? sub.title : null}</div>
                                  </div>
                                </Link>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {getUrl == 'admin' && userData && (
          <div>
            <ul className="order-menu1">
              {menuItems.map((menus, i) => {
                return (
                  <li key={menus.name}>
                    <div className="parent-menu-mobile">
                      <div>{menus.title}</div>
                    </div>
                    {i !== 0 ? <div className="menu-line"></div> : null}
                    <div>
                      <ul>
                        {menus.children.map((sub, ii) => {
                          return (
                            <li key={sub.name}>
                              <div className="sidemenu-menuitem-mobile">
                                <Link to={'/admin/' + sub.name} onClick={() => closeMenu()}>
                                  <div className="flex">
                                    <div>{sub.icon ? sub.icon : null}</div>
                                    <div>{sub.title ? sub.title : null}</div>
                                  </div>
                                </Link>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminMenuMobile;
