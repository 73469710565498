/* eslint eqeqeq: "off" */
import React, { useState, useEffect } from 'react';
import './index.css';
import { Collapse } from 'antd';
import select from '../../img/st-rec-select.png';
import unselect from '../../img/st-rec-unselect.png';
import { CheckmarkIcon } from '../../components/common/common_ui';
import _ from 'lodash';

const { Panel } = Collapse;

const StationRecipesMobile = (props) => {
  const {
    recList,
    updateFilter,
    updateActiveRecs,
    stationId,
    showRecipeLists,
    hasRecipeLists,
    handleShowChecklists,
    handleShowRecipeLists,
    pristineIngs,
    filtered,
    showDrawer,
  } = props;
  const [expandedRows, setExpandedRows] = useState([]);
  const [stationRecipes, setStationRecipes] = useState(recList);
  const [checkedRecipe, setChecked] = useState(0);

  useEffect(() => {
    console.log('REC LIST', recList);
    console.log('PRISTINE INGS', pristineIngs);
    let getRecipes =
      pristineIngs &&
      pristineIngs.map((ing) => {
        return {
          recipeId: ing.recipeId,
          totalIngs: pristineIngs.filter((i) => i.recipeId === ing.recipeId).length,
          completedIngs: pristineIngs.filter((i) => i.recipeId === ing.recipeId && i.completed === 2).length,
        };
      });

    const getCompletedRecipes = _.uniqBy(getRecipes, 'recipeId');

    console.log('COMPLETED RECIPES', getCompletedRecipes);

    let newRec = recList?.reduce((list, menu) => {
      menu.completedIng = getCompletedRecipes?.reduce((acc, curr) => acc + curr.completedIngs, 0);
      menu.totalIng = getCompletedRecipes?.reduce((acc, curr) => acc + curr.totalIngs, 0);

      recList.map((menu) => {
        menu.children.map((course) => {
          course.children.map((recipe) => {
            recipe.totalIngs = getCompletedRecipes.find((ing) => ing.recipeId === recipe.recipeId)?.totalIngs;
            recipe.completedIngs = getCompletedRecipes.find((ing) => ing.recipeId === recipe.recipeId)?.completedIngs;
            recipe.selected = filtered ? 0 : 1;
            return recipe;
          });
          return course;
        });
        return menu;
      })
      

      const courseWithIngs = menu.children.map((course) => ({
        ...course,
        children: course.children
          .filter((c) => c.totalIngs !== undefined)
      }));

      if (courseWithIngs.length && courseWithIngs.some((course) => course.children && course.children.length)) {
        list.push({ ...menu, children: courseWithIngs });
      }
      return list;
    }, []);

    console.log('REC LIST NEW', newRec);
    console.log('HOW MANY REC LIST ITEMS', newRec?.length);
    if (newRec?.length > 0) {
      setStationRecipes(newRec);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recList]);

  useEffect(() => {
    if (!showRecipeLists) {
      uncheckAll();
      setExpandedRows([]);
      let getActiveStations =
        localStorage.getItem('openRecipePanelStations') != null
          ? JSON.parse(localStorage.getItem('openRecipePanelStations'))
          : null;
      console.log(getActiveStations);
      if (getActiveStations && getActiveStations.length > 0) {
        let defaultExpanded = stationRecipes[0]?.key;
        setExpandedRows([defaultExpanded]);
      } else {
        uncheckAll();
        setExpandedRows([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRecipeLists]);

  useEffect(() => {
    if (stationRecipes && stationRecipes.length > 0) {
      stationRecipes.reduce((acc, menu) => {
        const courseWithIngs = menu.children.map((course) => ({ ...course, children: course.children
          .filter((recipe) => recipe.totalIngs !== undefined) }));
        if (courseWithIngs.length && courseWithIngs.some((course) => course.children && course.children.length)) {
          acc.push({ ...menu, children: courseWithIngs });
        }
        return acc;
      }, []);

      let checkedRec = stationRecipes
        .reduce((r, menu) => r.concat(menu.children), [])
        .reduce((r, course) => r.concat(course.children), [])
        .filter((recipe) => recipe.active === 1);

      if (stationRecipes.length == 1) {
        checkAll();
      }

      setChecked(checkedRec.length);
      updateActiveRecs(checkedRec);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pristineIngs, stationRecipes]);

  const flatten = (arr) => arr?.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

  const getRecipeIngredients = (list) => {
    let activeList = list?.map((menu) => {
      return menu.children.map((course) => {
        return course.children;
      });
    });
    return flatten(activeList);
  };

  // refactored function enacting UI changes by fast select operations
  const fastSelectRecipe = (keys, activateAll = false) => {
    if (keys) {
      // if recipe panel is only selected
      let keyVals = keys.split('-');
      stationRecipes.map(function iter(a) {
        if (a?.menuId && a?.menuName) {
          if (a.menuId === parseInt(keyVals[2])) {
            a.active = 1;
          } else {
            a.active = 0;
          }
        }

        if (a?.recipeId) {
          if (a.key === keyVals.join('-')) {
            a.active = 1;
          } else {
            a.active = 0;
          }
        }
        Array.isArray(a.children) && a.children.forEach(iter);
        return a;
      });
      updateFilter(true);
    } else {
      // activate/deactivate entire fast select recipe panels
      stationRecipes.map(function iter(a) {
        if (a?.active) {
          a.active = activateAll * 1;
        }
        Array.isArray(a.children) && a.children.forEach(iter);
        return a;
      });
      updateFilter(false);
    }

    let checkedRec = stationRecipes
      .reduce((r, menu) => r.concat(menu.children), [])
      .reduce((r, course) => r.concat(course.children), [])
      .filter((recipe) => recipe.active === 1);

    console.log('Checked Recipe Ingredients', checkedRec);
    setChecked(checkedRec.length);
    updateActiveRecs(checkedRec);
  }

  const togglePanels = (key) => {
    //record station id of open recipe panel
    let getActiveStations = localStorage.getItem('openRecipePanelStations') != null ? JSON.parse(localStorage.getItem('openRecipePanelStations')) : null;
    if (getActiveStations) {
      const newArr = [...getActiveStations, stationId];
      const uniqIds = [...new Set(newArr)];
      localStorage.setItem('openRecipePanelStations', JSON.stringify(uniqIds));
    } else {
      localStorage.setItem('openRecipePanelStations', JSON.stringify([stationId]));
    }

    if (!showRecipeLists) {
      handleShowRecipeLists(true);
      handleShowChecklists(false);
    }

    let lastKey = [];
    if (key.length >= 2) {
      lastKey.push(key[key.length - 1]);
      setExpandedRows(lastKey);
    } else {
      lastKey = key.length > 0 ? key : ['0'];
      setExpandedRows(lastKey);
    }
  };

  const checkAll = () => {
    localStorage.setItem('openRecipePanelStations', JSON.stringify([stationId]));
    localStorage.removeItem('checkRec');
    fastSelectRecipe(null, true);
    if (!showRecipeLists) {
      handleShowRecipeLists(true);
      handleShowChecklists(false);
    }
  };

  const uncheckAll = () => {
    if (stationRecipes && stationRecipes.length > 0) {
      fastSelectRecipe(null, false);
    }
    if (showRecipeLists) {
      handleShowRecipeLists(false);
      handleShowChecklists(true);
    }
  };

  const selectRecipe = (recipe) => (event) => {
    localStorage.setItem('checkedRec', JSON.stringify(recipe));
    localStorage.setItem('openRecipePanelStations', JSON.stringify([stationId]));

    if (event.ctrlKey || event.metaKey) {
      fastSelectRecipe(recipe.key, true);
      if (!showRecipeLists) {
        handleShowRecipeLists(true);
        handleShowChecklists(false);
      }

      showDrawer(false);
    } else {
      fastSelectRecipe(recipe.key, true);
      if (!showRecipeLists) {
        handleShowRecipeLists(true);
        handleShowChecklists(false);
      }
      showDrawer(false);
    }
  };

  return (
    <div>
      {((hasRecipeLists && stationRecipes?.length > 0) || filtered) && (
        /* Recipe Part of the Drawer */
        <div className="station-rec-main">
          <div className="station-reci-title">Recipes </div>
          {/* Number of Shown Recipes in filtered/unfiltered table Display Status */}
          <div className="station-reci-total">
            {`${checkedRecipe} recipe${checkedRecipe > 1 ? 's' : ''} selected`}{' '}
            <span className="checked-reset" onClick={checkAll}>
              Reset
            </span>
          </div>
          {/* The Accordion */}
          <Collapse
            // defaultActiveKey={['0']}
            activeKey={expandedRows}
            expandIconPosition={'right'}
            onChange={togglePanels}
          >
            {/* Menus assigned to kitchen/event station */}
            {stationRecipes && stationRecipes?.map((menu, i) => {
              return (
                <Panel
                  header={
                    expandedRows.includes(i.toString()) ? (
                      <div className="rec-select-active">
                        {menu.completedIng === menu.totalIng && menu.active === 1 && (
                          <img src={select} alt="selected" height="28" />
                        )}
                        {menu.completedIng === menu.totalIng && menu.active === 1 ? (
                          <span>{menu.menuName}</span>
                        ) : (
                          <span style={{ marginLeft: 11 }}>{menu.menuName}</span>
                        )}
                      </div>
                    ) : (
                      <div className="rec-select-inactive">
                        {menu.completedIng === menu.totalIng && menu.active === 0 && (
                          <img src={unselect} alt="selected" height="28" />
                        )}{' '}
                        {menu.completedIng === menu.totalIng && menu.active === 0 ? (
                          <span>{menu.menuName}</span>
                        ) : (
                          <span style={{ marginLeft: 11 }}>{menu.menuName}</span>
                        )}
                      </div>
                    )
                  }
                  key={menu.key}
                >
                  {/* Recipes inside the Menu */}
                  {menu.children
                    .sort((a, b) => (a.courseId > b.courseId ? 1 : -1))
                    .sort((a, b) => (a.courseOrder > b.courseOrder ? 1 : -1))
                    .map((course) => {
                      return (
                        <div 
                          key={course.key}
                          className={course.children.length === 0 ? 'rec-content-box22' : 'rec-content-box'}
                        >
                          <div className="rec-content-type">
                            <div>{course.courseName}</div>
                          </div>
                          {/* Ingredients in each Recipe */}
                          {course.children
                            .sort((a, b) => (a.recipeAssignDate > b.recipeAssignDate ? 1 : -1))
                            .sort((a, b) => (a.recipeOrder > b.recipeOrder ? 1 : -1))
                            .map((recipe) => {
                            return (
                              <div key={recipe.key}>
                                {recipe.totalIngs !== undefined && (
                                  <div
                                    className={recipe.active === 1 ? 'rec-list-contents' : 'rec-list-contents-unselect'}
                                    onClick={selectRecipe(recipe)}
                                  >
                                    <div>
                                      {recipe.completedIngs === recipe.totalIngs && recipe.active === 1 && (
                                        <CheckmarkIcon className="active" />
                                      )}
                                      {recipe.completedIngs === recipe.totalIngs && recipe.active == 0 && (
                                        <CheckmarkIcon />
                                      )}
                                    </div>
                                    <div className="rec-list-name">{recipe.recipeName}</div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </Panel>
              );
            })}
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default StationRecipesMobile;