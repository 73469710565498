import React, { useState, useEffect, useCallback } from 'react';
import './index.css';
import ProgressCircle from '../../components/progressCircle/progresscircle';
import StationGeneralMobile from './station-general-mobile';
import StationFilteredMobile from './station-filtered-mobile';
import attach from '../../img/station-attach.png';
import { Menu, Select, Drawer, Spin } from 'antd';
// import { search } from '../../services/recipe.service';
// import { searchMenu, batchCourses } from '../../services/menu.service';
// import { batchRecipes } from '../../services/course.service';
// import { getItemStatus } from '../../services/shoplist.service';
import { get, searchEvents, menus as getEventStationMenus } from '../../services/event.service';
// import { details } from '../../services/recipe.service';
import { getCheckList } from '../../services/checklist.service';
import { getEventStationIngredients } from '../../services/station.service';
// import moment from 'moment';
import { searchDocLinks } from '../../services/doc.service';
import { searchmeas } from '../../services/measurement.service';
import DocumentList from '../../components/DocumentList/DocumentList';
import useWindowSize from '../../components/windowdimensions/windowSize';
import { BacktoHeaderMobile } from '../../components/Header/HeadMobile.js';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';

import ErrorPage from '../../components/ErrorPages/ErrorPage';
import { isEmpty } from 'lodash';
import { formatIfNeeded, reformatVal } from './utilities';
import { arrangeDBMeasurementUnits } from '../Recipes/utilities';

const { Option } = Select;

const StationDetailsEvMobile = (props) => {
  const [filtered, isFiltered] = useState(false);
  const [restaurant, setRestaurant] = useState('');
  const [stations, setStations] = useState([]);
  const [recList, setRecList] = useState([]);
  const [activeRecs, setActiveRecs] = useState([]);
  const [showRec, setShowRec] = useState({});
  const [tableData, setTableData] = useState([]);
  const [pristineIngs, setPristineIngs] = useState([]);
  const [selectedStation, setSelectedStation] = useState('');
  const [healthCheckCompleted, setHealthCheckCompleted] = useState(0);
  const [updateHealthCheck, setUpdateHealthCheck] = useState(true);
  const [completed, setCompleted] = useState(0);
  const [updateCompleted, setUpdateCompleted] = useState(true);
  const [totalIngs, setTotalIngs] = useState(0);
  const [completedIngs, setCompletedIngs] = useState(0);
  const [menuIdss, setMenuIds] = useState([]);
  const [updateNeeded, setUpdateNeeded] = useState(true);
  const [neededIngs, setNeeded] = useState(0);
  const [percentNeeded, setPercentNeeded] = useState(0);
  const [currentSelectedStation, setCurrentSelectedStation] = useState('');
  const [activeChk, setActiveChk] = useState([]);
  const [getStnId, setGetStnId] = useState('');
  const [selectedStationChecklists, setSelectedStationChecklists] = useState([]);
  const [stationIngs, setStationIngs] = useState([]);
  const [stationTasks, setStationTasks] = useState([]);
  const [showChecklists, setShowChecklists] = useState(false);
  const [hasUpdate, setHasUpdate] = useState(false);
  const [hasChecklist, setHasChecklist] = useState(false);
  const [documents, setDocuments] = useState(0);
  const [visible, updateVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [socketData, setSocketData] = useState([]);
  const [checklistItemUpdate, setChecklistItemUpdate] = useState([]);
  const [stationIngsUpdate, setStationIngsUpdate] = useState('');
  const [hasRecipeLists, setHasRecipeLists] = useState(false);
  const [stationMenuUpdate, setStationMenuUpdate] = useState('');
  const [stationRestoId, setStationRestoId] = useState(0);
  const [measurements, setMeasurements] = useState([]);
  const [isFirstLoading, setFirstLoading] = useState(true);

  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/checklist`));
  const [eventSocket2] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/station`));
  const [eventSocket3] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/menu`));

  const [links1, setLinks1] = useState({});
  const [docLinks1, setDocLinks1] = useState({});
  const windowSize = useWindowSize();

  const operatorLoggedIn = JSON.parse(localStorage.getItem('operator')) || {};
  const operatorRestaurants = operatorLoggedIn.restaurants;
  const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;

  const getStationIngredients = (station) => {
    getEventStationIngredients(station).then((ings) => {
      if (ings && ings.length > 0) {
        getEventStationMenus(station).then((menus) => {
          if (menus.EventMenus.length) {
            const eventMenus = menus.EventMenus.map((em, i) => {
              return {
                menuId: em.menuId,
                order: i
              }
            });
            console.log('STATION EVENT MENUS', eventMenus);
            let formattedQuantities = ings.map((ing, i) => {
              ing.rowKey = `${i}-${ing.recipeId}-${ing.courseId}-${ing.menuId}-${ing.ingredientId}`;
              const formatOne = 
                ing?.ingredientQty * 1 > 0 && ing?.measurementId > 0 ? 
                reformatVal(ing?.ingredientQty * 1, ing.measurementName, ing.ingredientId, measurements) :
                undefined;
              const formatTwo = ing?.ingredientQty * 1 > 0 ? formatIfNeeded(ing.ingredientQty) : undefined;
              ing.formattedQty = 
                formatTwo != undefined ?
                formatTwo :
                formatOne != undefined ?
                formatOne :
                '0';
              ing.menuOrder = eventMenus.filter((m) => m.menuId === ing.menuId)[0].order;
              return ing;
            });
            setPristineIngs(formattedQuantities);
          }
        });
      }
    });
  }

  const getDocLinks = (stationId) => {
    let params = {
      type: 'events',
      linkId: stationId.toString()
    }
    searchDocLinks(params)
      .then((res) => {
        console.log(res);

        if (res && res.length > 0) {
          let links = res;
          let removeDeleted = links.filter((l) => l.Document.folderId !== null);
          const list = Array.from(
            removeDeleted.reduce(
              (m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]),
              new Map()
            ),
            ([linkId, documentId]) => ({ linkId, documentId })
          );

          console.log(list);
          setDocuments(list[0].documentId.length);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }
  
  const computeOverallStatusIngredients = (data, filtered = true) => {
    let ingredients = [];
    if (filtered) {
      ingredients = data;
    } else {
      ingredients = data.reduce((res, category) => res.concat(category.children), []);
    }

    let completedHealthChecks = ingredients.filter((h) => h.healthCheck == 2);
    let healthCheckPercentage = Math.round(
      (parseInt(completedHealthChecks.length) / parseInt(ingredients.length)) * 100
    );
    if (updateHealthCheck && updateCompleted && updateNeeded) {
      let healthPercent = isNaN(parseInt(healthCheckPercentage)) ? 0 : healthCheckPercentage;
      console.log(healthPercent);
      setHealthCheckCompleted(healthPercent);

      let completedIngs = ingredients.filter((h) => h.completed == 2);
      let completedPercentage = Math.round((parseInt(completedIngs.length) / parseInt(ingredients.length)) * 100);

      let itemsNeeded = ingredients.filter((h) => h.needed == 2);
      let itemsPercentage = Math.round((parseInt(itemsNeeded.length) / parseInt(ingredients.length)) * 100);

      let completePercent = isNaN(parseInt(completedPercentage)) ? 0 : completedPercentage;

      setCompleted(completePercent);
      setCompletedIngs(completedIngs.length);
      setTotalIngs(ingredients.length);
      setNeeded(itemsNeeded.length);
      setPercentNeeded(itemsPercentage);
    }
  };

  const buildRecipeIngredientsView = (data, key) => {
    const toTable = data
      .reduce((toTable, item) => {
        if (key === item.rowKey.slice(item.rowKey.indexOf('-') + 1, item.rowKey.lastIndexOf('-'))) {
          toTable.push(item);
        }
        return toTable;
      }, []);

    if (toTable.length > 0) {
      computeOverallStatusIngredients(toTable);
      // details(toTable[0].recipeId).then((res) => {
      //   let newArr = res?.RecipeDetails?.map((r) => ({
      //     ingredientId: r.ingredientId,
      //     ingredientOrder: r.info?.order,
      //   }));

      //   console.log(newArr);

        // let orderedArr = newArr?.reduce((a, b) => {
        //   a[b.ingredientId] = b;
        //   return a;
        // }, {});
      let sortedTable = toTable.sort((a, b) => a.ingredientOrder - b.ingredientOrder || a.name.localeCompare(b.name));
      console.log('SORTED TABLE', sortedTable);
      setTableData(sortedTable);
      // });
    } else {
      setTableData([]);
    }
    isFiltered(true);
  }

  const buildCategorizedIngredientsView = (data) => {
    let toTable = [];
    toTable = data
      .reduce((categories, item) => {
        let theCategory = {
          name: item.categoryName,
          rowKey: `station-${props.match.params.id}-${item.categoryName}`,
          type: 'parent',
          children: [],
          childrenCount: 0,
        };
        const existingCategory = categories.findIndex((cat) => cat.name === item.categoryName);
        if (item.rowKey == undefined) {
          item.rowKey = `${item.key}-${Math.random().toString()}`;
        }
        if (existingCategory === -1) {
          theCategory.children.push(item);
          theCategory.childrenCount += 1;
          categories.push(theCategory);
        } else {
          categories[existingCategory].childrenCount += 1;
          categories[existingCategory].children.push(item);
          categories[existingCategory].children = categories[existingCategory].children.sort((a, b) =>
            a.ingredientId > b.ingredientId ? 1 : a.ingredientId < b.ingredientId ? -1 : 0
          );
        }
        return categories;
      }, []);
    if (toTable.length > 0) {
      const sortedTable = toTable.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
      );
      computeOverallStatusIngredients(sortedTable, false);
      console.log('SORTED TABLE', sortedTable);
      setTableData(sortedTable);
    } else {
      setTableData([]);
    }
    isFiltered(false);
  };

  const checklistCompletedPercent = (() => {
    let totalChecklist = 0;
    let checkeditems = 0;
    for (let i = 0; i < stationTasks.length; i++) {
      const { children } = stationTasks[i];
      for (let j = 0; j < children.length; j++) {
        const { complete } = children[j];
        totalChecklist++;
        if (complete == 2) {
          checkeditems++;
        }
      }
    }

    return totalChecklist > 0 ? Math.round((checkeditems / totalChecklist) * 100) : 0;
  })();

  useEffect(() => {
    if (selectedStation.trim() !== '') {
      let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
      let params = {
        restaurantId: resto.id,
        startTimestamp: '315626789',
        endTimestamp: '64060679385',
        from: 0,
        limit: 1000,
      };
      // getEvents({ id: selectedStation }).then((res) => {
      searchEvents(params).then((res) => {
        let getEvent = res.filter((r) => r.id == selectedStation);
        if (res && res.length > 0) {
          // events percentage progress based on station CHecklist\
          setCompleted(getEvent[0]?.percentage);
        }

        let stnId = getEvent[0]?.id;
        setGetStnId(stnId);

        const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;

        if (selectedStation !== '0') {
          getCheckList({
            restaurantId: resto_id,
            event_id: selectedStation,
          }).then((res) => {
            let selectedChecklist = res.checklists.filter((c) => JSON.parse(c.ChecklistItems).length > 0);

            if (selectedChecklist.length === 0) {
              setShowChecklists(false);
              showFilter(false);
            } else {
              setShowChecklists(true);
              showFilter(false);
            }

            let lists = selectedChecklist.map((s) => {
              let obj = {
                key: s.id + 'CK',
                id: s.id,
                name: s.title,
                selected: 1,
                itemsTotal: JSON.parse(s.ChecklistItems).length,
                itemsCompleted: JSON.parse(s.ChecklistItems).filter((f) => f?.info?.complete == 2).length,
              };
              return obj;
            });
            let stationSelectors = [
              {
                key: '1',
                name: 'Checklists',
                list: [
                  {
                    name: '',
                    list: lists,
                  },
                ],
              },
            ];

            setSelectedStationChecklists(stationSelectors);

            let itHasChecklist = lists && lists.length > 0;
            setHasChecklist(itHasChecklist);

            let tasks = selectedChecklist.map((c) => {
              return JSON.parse(c.ChecklistItems);
            });

            let merged = [].concat.apply([], tasks);

            merged.sort((x, y) => {
              return x.id - y.id;
            });

            let taskItems = merged.map((s) => {
              const assignee = (() => {
                let a = '';
                if (!isEmpty(s.Station)) {
                  a = s.Station.name;
                }
                if (!isEmpty(s.Operator)) {
                  a = `${s.Operator.firstName} ${s.Operator.lastName} `;
                }
                if (!isEmpty(s.Event)) {
                  a = s.Event.name;
                }
                return a;
              })();
              let parsedInfo = s.info;
              let obj = {
                key: s.id,
                name: s.title,
                type: 'child',
                completed: 1,
                checklistId: s.checklistId,
                parentId: s.checklistId.toString() + 'CK',
                keyId: s.checklistId.toString(),
                checked: parsedInfo.checked,
                complete: parsedInfo.complete == 0 ? 1 : parsedInfo.complete,
                description: parsedInfo.description,
                details: parsedInfo.details,
                tags: parsedInfo.tags,
                dueDate: s.dueDate,
                project: s.project,
                priority: s.priority,
                assignee: assignee,
              };
              return obj;
            });

            let taskListTile = selectedChecklist.map((s) => {
              let obj = {
                key: s.id + 'CK',
                name: s.title,
                image: JSON.parse(s.info)?.imageUrl,
                type: 'parent',
                ingredients: 1,
                id: s.id,
                children: [
                  {
                    key: s.id,
                    type: 'child',
                    tags: taskItems.filter((t) => t.checklistId == s.id),
                  },
                ],
              };
              return obj;
            });

            setStationIngs(taskListTile);

            let taskList = selectedChecklist.map((s) => {
              let obj = {
                key: s.id + 'CK',
                name: s.title,
                type: 'parent',
                ingredients: 1,
                id: s.id,
                imageUrl:
                  s.info && (s.info?.imageUrl || s.info?.imageUrlThumb)
                    ? s.info?.imageUrl || s.info?.imageUrlThumb
                    : '',
                children: taskItems.filter((t) => t.checklistId == s.id),
              };
              return obj;
            });

            setStationTasks(taskList);
            setActiveChk(taskList);

            if (taskListTile.length == 1) {
              //showFilter(true);
            } else if (taskList.length == 1) {
              //showFilter(true);
            } else {
              showFilter(false);
            }

            setTimeout(() => {
              setHasUpdate(false);
            }, 1000);
          });
        } else {
          setShowChecklists(false);
        }
      });
      getDocLinks(selectedStation);
      getStationIngredients(selectedStation);
    }
  }, [selectedStation, getStnId, hasUpdate]);

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    let accountId = localStorage.getItem('operator') != undefined && 
      JSON.parse(localStorage.getItem('operator')).accountId != null ? 
      JSON.parse(localStorage.getItem('operator')).accountId : '';
    let params = {
      restaurantId: resto.id,
      startTimestamp: '315626789',
      endTimestamp: '64060679385',
      from: 0,
      limit: 1000,
    };

    searchmeas({ accountId: accountId }).then((res) => {
      setMeasurements(arrangeDBMeasurementUnits(res));
    });

    // detects if url has indicated event station id
    if (props.match.params.id) {
      // 1. first it queries for location/restaurant id of event
      get(props.match.params.id).then((res) => {
        const stationRestaurantId = res.restaurantId;

        // if current location is not the same as the event location
        if (stationRestaurantId !== resto.id) {
          // change settings then refresh page
          const restaurantName = operatorRestaurants.find((r) => r.id === stationRestaurantId);
          localStorage.setItem(
            'restaurant',
            JSON.stringify({
              id: stationRestaurantId.toString(),
              name: restaurantName,
            })
          );
          localStorage.setItem('restaurantId', stationRestaurantId.toString());
          window.location.reload();
        } else {
          // until user is redirected to a new location, then query more details from event station
          searchEvents(params).then((res) => {
            let filterEvs = res.filter((e) => e.type == 'event');
            setStations(filterEvs);
            let selected = res.filter((r) => r.id == props.match.params.id);
            setStationRestoId(selected[0]?.restaurantId);
            setCurrentSelectedStation(selected[0]?.name);
            let eventMenus = selected[0]?.EventMenus?.map((r) => r.menuId);
            setMenuIds(eventMenus);
            setSelectedStation(props.match.params.id);
            console.log('getting event station via params id');
          });
        }
      });
    } else {
      searchEvents(params).then((res) => {
        let filterEvs = res.filter((e) => e.type == 'event');
        setStations(filterEvs);
        setCurrentSelectedStation(res[0].name);
        setSelectedStation(res[0].id);
        let eventMenus = res[0].EventMenus.map((r) => r.menuId);
        setMenuIds(eventMenus);
        console.log('getting random event station');
      });
    }
  }, []);

  useEffect(() => {
    if (pristineIngs.length > 0 && isFirstLoading) {
      if (recList.length === 0) {
        const toFastSelect = pristineIngs.reduce((result, item) => {
          const newMenu = {
            key: `${item.menuId}`,
            menuId: item.menuId,
            menuName: item.menuName,
            menuOrder: item.menuOrder,
            children: [],
            active: 1,
          };
          const newCourse = {
            key: `${item.courseId}-${item.menuId}`,
            courseId: item.courseId,
            courseName: item.courseName,
            courseOrder: item.courseOrder,
            children: [],
          };
          const newRecipe = {
            key: `${item.recipeId}-${item.courseId}-${item.menuId}`,
            recipeId: item.recipeId,
            recipeName: item.recipeName,
            recipeOrder: item.recipeOrder,
            recipeAssignDate: item.recipeAssignDate,
            recipeImage: item.recipeImage,
            menuId: item.menuId,
            active: 1,
          };
  
          const existingMenu = result.findIndex((i) => i.menuId === item.menuId);
          // if there are no menu item existing
          if (existingMenu > -1) {
            const existingCourse = result[existingMenu].children.findIndex((i) => i.courseId === item.courseId);
            // if there are no course item existing
            if (existingCourse === -1) {
              newCourse.children = [].concat([newRecipe]);
              result[existingMenu].children = result[existingMenu].children
                .concat([newCourse]);
            } else {
              const existingRecipe = result[existingMenu].children[existingCourse].children.findIndex(
                (i) => i.recipeId === item.recipeId
              );
              // if there are no recipe item existing
              if (existingRecipe === -1) {
                result[existingMenu].children[existingCourse].children = result[existingMenu].children[existingCourse].children
                  .concat([newRecipe]);
              }
            }
          } else {
            newCourse.children = [].concat([newRecipe]);
            newMenu.children = [].concat([newCourse]);
            result.push(newMenu);
          }
          result.sort((a, b) => a.menuOrder - b.menuOrder);
          return result;
        }, []);
        setRecList(toFastSelect);
        setHasRecipeLists(true);
      }
      setFirstLoading(false);
    }

    if (pristineIngs.length > 0) {
      if (activeRecs.length === 1) {
        buildRecipeIngredientsView(pristineIngs, activeRecs[0].key);
      } else if (activeRecs.length > 1) {
        buildCategorizedIngredientsView(pristineIngs, activeRecs);
      } else {
        if (!showChecklists) {
          buildCategorizedIngredientsView(pristineIngs);
        }
      }
    } else {
      setTableData([]);
    }
  }, [pristineIngs, activeRecs, isFirstLoading]);

  useEffect(() => {
    console.log('SELECTED STN', selectedStationChecklists);
  }, [selectedStationChecklists]);

  const menu = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="">
          Reassign
        </a>
      </Menu.Item>
    </Menu>
  );

  const showFilter = (data) => {
    isFiltered(data);
  };

  const selectStation = (event) => {
    setCurrentSelectedStation(event);
    let getId = stations.find((s) => s.name == event).id;
    setSelectedStation(getId);
    let selectedStation = getId;
    localStorage.removeItem('checkedRec');
    const url = `/event/station/${selectedStation}`;
    props.history.push(url);
    window.location.reload();
  };

  const updateActiveRecs = useCallback((data) => {
    console.log(data);
    setActiveRecs(data);
    // if (data.length == 1) {
    //   setSingleRecipe(true);
    // }

    // if (data.length == 0) {
    //   let testGenData = tableData.map((t) => {
    //     t.ingredients = 0;
    //     t.children = [];
    //     return t;
    //   });

    //   const result2 = Array.from(new Set(testGenData.map((s) => s.name))).map((lab) => {
    //     let childArr = testGenData.filter((s) => s.name === lab).map((e) => e.children);
    //     let child = [].concat.apply([], childArr);

    //     return {
    //       name: lab,
    //       children: child,
    //       categoryId: testGenData.find((s) => s.name === lab).categoryId,
    //       ingredients: child.length,
    //       key: testGenData.find((s) => s.name === lab).key,
    //       type: testGenData.find((s) => s.name === lab).type,
    //     };
    //   });

    //   setTableData(result2);
    // }
  }, []);

  const filterCompletedHealthChecks = useCallback(
    () => {
      localStorage.removeItem('checkedRec');
      let filterbyCompletedHealthChecks = pristineIngs.filter((ing) => ing.healthCheck == 1);
      // setPristineIngs(filterbyCompletedHealthChecks);
      isFiltered(false);
      buildCategorizedIngredientsView(filterbyCompletedHealthChecks);
      setUpdateHealthCheck(false);
    },
    [pristineIngs]
  );

  const filterProductionReady = useCallback(
    () => {
      localStorage.removeItem('checkedRec');
      let filterbyProductionReady = pristineIngs.filter((ing) => ing.healthCheck == 2 && ing.completed == 1);
      // setPristineIngs(filterbyProductionReady);
      isFiltered(false);
      buildCategorizedIngredientsView(filterbyProductionReady);
      setUpdateHealthCheck(false);
      setUpdateCompleted(false);
      setUpdateNeeded(false);
    },
    [pristineIngs]
  );

  const filterCompleted = useCallback(
    () => {
      localStorage.removeItem('checkedRec');
      let filterbyCompleted = pristineIngs.filter((ing) => ing.completed == 2);
      // setPristineIngs(filterbyCompleted);
      isFiltered(false);
      buildCategorizedIngredientsView(filterbyCompleted);
      setUpdateCompleted(false);
    },
    [pristineIngs]
  );

  const filterNeeded = useCallback(
    () => {
      localStorage.removeItem('checkedRec');
      let filterbyList = pristineIngs.filter((ing) => ing.ordered >= 0);
      // setPristineIngs(filterbyList);
      isFiltered(false);
      buildCategorizedIngredientsView(filterbyList);
      setUpdateHealthCheck(false);
      setUpdateCompleted(false);
      setUpdateNeeded(false);
    },
    [pristineIngs]
  );

  const updateIngredientState = useCallback(
    (data) => {
      // getMenus(selectedStation, menuIdss, 'none');
      // console.log('updating ingredient state');
      eventSocket2.emit('updatesStationIngs', 'updateIngredients');
    },
    [selectedStation]
      // , menuIdss]
  );

  // const updateIngredientsState = useCallback(
  //   (data) => {
  //     getMenus(selectedStation);
  //     setActivateList(false);
  //   },
  //   [selectedStation]
  // );

  const updateActiveChks = useCallback((data) => {
    setActiveChk(data);
    // if (data?.length == 1) {
    //   setSingleRecipe(true);
    // }
  });

  const closeDrawer = useCallback((data) => {
    updateVisible(false);
    updateIngredientState(true);
  }, []);

  const showDrawer = (e) => {
    e.preventDefault();
    updateVisible(true);
    setDocLinks1({
      type: 'events',
      linkId: props.match.params.id,
    });
    setLinks1({
      type: 'events',
      linkId: props.match.params.id,
      info: { title: currentSelectedStation },
    });
  };

  const onClose = () => {
    updateVisible(false);
  };

  const showAllIngredients = useCallback(
    () => {
      localStorage.removeItem('checkedRec');
      isFiltered(false);
      buildCategorizedIngredientsView(pristineIngs);
      // setPristineIngs(pristineIngs);
      setUpdateHealthCheck(false);
      setUpdateCompleted(false);
      setUpdateNeeded(false);
    },
    [pristineIngs]
  );

  useEffect(() => {
    eventSocket.on('updatedStationTask', (message) => {
      let newData = JSON.parse(message);
      setChecklistItemUpdate(newData.id);
    });
    eventSocket2.on('updatedStationIngs', (message) => {
      setStationIngsUpdate(message);
    });
    eventSocket3.on('updatedStationMenu', (message) => {
      console.log('MENUUPDATED!!', message);
      setStationMenuUpdate(message);
    });
    return () => {
      eventSocket.disconnect();
      eventSocket2.disconnect();
      eventSocket3.disconnect();
    };
  }, []);

  useEffect(() => {
    if (checklistItemUpdate !== '') {
      setHasUpdate(true);
      setTimeout(() => {
        setChecklistItemUpdate(0);
      }, 2000);
    } else {
      return;
    }
  }, [checklistItemUpdate]);

  useEffect(() => {
    if (stationIngsUpdate) {
      // getMenus(selectedStation, menuIdss, 'none');
      const stationId = selectedStation ? selectedStation : props.match.params.id;
      getStationIngredients(stationId);
      // getDocLinks(stationId);
      console.log('station ingredients updated');
    }
  }, [stationIngsUpdate]);

  useEffect(() => {
    if (stationMenuUpdate) {
      // getMenus(selectedStation, menuIdss, 'none');
      const stationId = selectedStation ? selectedStation : props.match.params.id;
      getStationIngredients(stationId);
      // getDocLinks(stationId);
      console.log('station menu updated');
    }
  }, [stationMenuUpdate]);

  useEffect(() => {
    console.log(resto_id, stationRestoId);
  }, [resto_id, stationRestoId]);

  return operatorRestaurants?.filter((r) => r.id == stationRestoId).length === 1 ? (
    <div>
      <div className="marginT10 windowHFull">
        <div style={{ textAlign: 'center' }}>{isLoading && <Spin tip="Loading station data..."></Spin>}</div>
        <BacktoHeaderMobile restaurant={restaurant} backText="Back to Dashboard" backLink="/dashboard" />
        <div className="mobile-station-controlbox">
          <div className="mobile-station-controls">
            <div className="mobile-station-control1">
              {/* <div className="station-assignee-image-container-mobile">
                <img className="station-image" src={user} alt="user" />
              </div>
              <div className="mobile-stationName">{selectedAssignee}</div>
              <div>
                <Dropdown overlay={menu}>
                  <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <img src={more} alt="more" className="stationMore" width="30" />
                  </a>
                </Dropdown>
              </div> */}
            </div>
            <div className="mobile-station-control2">
              <div>
                {documents > 0 ? (
                  <div onClick={(e) => showDrawer(e)}>
                    {documents}
                    <img src={attach} className="comment-menu" alt="comment" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div>
            <Select
              className="station-select-mobile"
              onChange={selectStation}
              value={currentSelectedStation}
              showArrow={false}
              showSearch
            >
              {stations.map((station, i) => (
                <Option value={station.name} key={`option-${i + 1}-${station.name}`}>
                  {station.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="station-progressbox">
            {hasChecklist ? (
              <div className="station-progress-boxmobile">
                <ProgressCircle
                  progress={checklistCompletedPercent}
                  size={50}
                  strokeWidth={8}
                  xText={25}
                  yText={30}
                  type="percent"
                  resolution="mobile"
                />
                <div>Checklists completed</div>
              </div>
            ) : (
              ''
            )}
            <div className="station-progress-boxmobile">
              <ProgressCircle
                progress={healthCheckCompleted}
                size={50}
                strokeWidth={8}
                xText={25}
                yText={30}
                type="percent"
                resolution="mobile"
              />
              <div>Quality check completed</div>
            </div>
            <div className="station-progress-boxmobile">
              <ProgressCircle
                progress={completed}
                size={50}
                strokeWidth={8}
                xText={25}
                yText={30}
                type="percent"
                resolution="mobile"
              />
              <div>Production ready</div>
            </div>
            <div className="station-progress-boxmobile">
              <ProgressCircle
                progress={completed}
                size={50}
                strokeWidth={8}
                xText={25}
                yText={30}
                type="ingredients"
                resolution="mobile"
                ing={completedIngs}
                ingtotal={totalIngs}
              />
              <div>Items completed</div>
            </div>
          </div>
        </div>
      </div>
      {!filtered ? (
        <div>
          <StationGeneralMobile
            showFilter={showFilter}
            updateActiveRecs={updateActiveRecs}
            updateActiveChks={updateActiveChks}
            recList={recList}
            tableData={tableData}
            filterCompletedHealthChecks={filterCompletedHealthChecks}
            filterProductionReady={filterProductionReady}
            filterCompleted={filterCompleted}
            filterNeeded={filterNeeded}
            updateIngredientState={updateIngredientState}
            selectedStationChecklists={selectedStationChecklists}
            activeRecs={activeRecs}
            activeChk={activeChk}
            stationTasks={stationTasks}
            setHasUpdate={setHasUpdate}
            assignedToMe={2}
            stationId={props.match.params.id}
            hasChecklist={hasChecklist}
            pristineIngs={pristineIngs}
            isEvent={true}
            showAllIngredients={showAllIngredients}
            hasRecipeLists={hasRecipeLists}
            setPristineIngs={setPristineIngs}
          />
        </div>
      ) : (
        <div>
          <div>
            <StationFilteredMobile
              filtered={filtered}
              showRec={showRec}
              setShowRec={setShowRec}
              activeChk={activeChk}
              activeRecs={activeRecs}
              updateActiveRecs={updateActiveRecs}
              updateActiveChks={updateActiveChks}
              recList={recList}
              showFilter={showFilter}
              tableData={tableData}
              stationTasks={stationTasks}
              filterCompletedHealthChecks={filterCompletedHealthChecks}
              filterProductionReady={filterProductionReady}
              filterCompleted={filterCompleted}
              filterNeeded={filterNeeded}
              updateIngredientState={updateIngredientState}
              selectedStationChecklists={selectedStationChecklists}
              setHasUpdate={setHasUpdate}
              assignedToMe={2}
              stationId={props.match.params.id}
              hasChecklist={hasChecklist}
              isEvent={true}
              pristineIngs={pristineIngs}
              setPristineIngs={setPristineIngs}
              showAllIngredients={showAllIngredients}
              hasRecipeLists={hasRecipeLists}
            />
          </div>
        </div>
      )}
      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={visible}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentList
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks={docLinks1}
          links={links1}
          isMobile={true}
          documentsIn="events"
        />
      </Drawer>
    </div>
  ) : (
    <div className="common-page-container">
      <ErrorPage copy="Kitchen Closed! This content is unavailable to your user account. If you feel you should have access to this content, please contact your local administrator" />
    </div>
  );
};

export default StationDetailsEvMobile;
