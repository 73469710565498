import React, { useState, useEffect, useCallback } from 'react';
import './index.css';
import { Switch, Drawer, Dropdown, Menu, notification, Spin, Popconfirm, message, Tag, Select } from 'antd';
import { menuData } from '../../data/recipedata';
import { Link } from 'react-router-dom';
import useWindowSize from '../../components/windowdimensions/windowSize';
import searchy from '../../img/search-ing-icon.png';
import { search, update, duplicate } from '../../services/recipe.service';
import {
  createRecipeCategory,
  // searchRecipeCategories,
  updateRecipeCategory,
  destroyRecipeCategory,
  searchCategorizedRecipes,
} from '../../services/category.service';
import DocumentList from './DocumentList';
import _, { remove, findIndex, cloneDeep } from 'lodash';
import { searchDocLinks } from '../../services/doc.service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RoleAccessibleComponent from '../RoleAccess/roleaccess';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { NewSubHeader } from '../Header/Header';
import {
  CidekicButton,
  PlusButton,
  PlusIcon,
  ThumbnailImage,
  AttachmentIndicator,
  MoreIcon,
  UserImage,
  ExpandingSearchPanel,
  CidekicDropdown,
  MultiTagSelect,
  SelectedTags, // FEATURE 1202040063159015 NEW
} from '../../components/common/common_ui';
import { object } from 'prop-types';

/* This implements the /recipes page */

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  display: 'flex',
  overflow: 'auto',
});

const getItemStyle2 = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const getListStyle2 = (isDraggingOver) => ({
  overflow: 'auto',
});

export const RecipeCategory = (props) => {
  const { currentPath } = props;

  const [visible, updateVisible] = useState(false);
  const [active, setActive] = useState([]);
  // removed tagFilter, since selectedTags are sufficient // BUG 1202030604174459
  const [searchedTag, setSearchedTag] = useState(''); // FEATURE 1202040063159015
  const [activeRecipe, setActiveRecipe] = useState([]);
  const [pristineRecipe, setPristineRecipe] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [drawerTags, setDrawerTags] = useState([]);
  const [tagList, setTags] = useState([]);
  const [seeMore, showSeeMore] = useState(true);
  const [recipeCourse, setRecipeCourse] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [courseTitle, setCourseTitle] = useState('');
  const [courseid, setCourseId] = useState('');
  const [recId, setRecId] = useState('');
  const [allRecipes, setAllRecipes] = useState([]);
  const [pristineData, setPristineData] = useState([]);
  const [restoId, setRestoId] = useState('');
  const [recName, setRecName] = useState('');
  const [isCanceled, setCancel] = useState(false);
  const [visible2, updateVisible2] = useState(false);
  const [docLinks, setDocLinks] = useState({});
  const [links, setLinks] = useState({});
  const [selectedId, setSelectedId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('Category');
  const [selectedTags, setSelectedTags] = useState([]);
  const [inputVal, setIntputVal] = useState(null);

  const { CheckableTag } = Tag;

  const { Option } = Select;

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};

  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }, []);

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    // changed all instances of tagFilter to existing state variable selectedTags within this closure, BUG 1202030604174459
    if (selectedTags.length > 0) {
      const filteredTags = pristineRecipe.filter((a) => a.tags.some((tag) => selectedTags.includes(tag)));
      setActiveRecipe(filteredTags);
    } else {
      setActiveRecipe([]); // FEATURE 1202040063159015
    }
  }, [selectedTags]);

  useEffect(() => {
    console.log('RECIPECOURSE', recipeCourse);
  }, [recipeCourse]);

  useEffect(() => {
    console.log('CANCELED', isCanceled);
  }, [isCanceled]);

  const windowSize = useWindowSize();

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const getCourses = (status) => {
    setRestoId(resto.id);
    // searchRecipeCategories(params).then((res) => {
    //   console.log('CATEGORIES', res);
    //   setIsAdding(false);
    //   let categories = res.map((r) => {
    //     r.categoryId = r.id;
    //     r.order = parseInt(r.order) || 0;
    //     return r;
    //   });
    //   getAdditionalDocLinks(categories);
    // });
    searchCategorizedRecipes({ id: resto.id }).then((res) => {
      setIsAdding(false);
      if (res) {
        let getCategories = res.map((r) => {
          r.order = parseInt(r.order) || 0;
          return r;
        });
        let merged = [].concat.apply([], getCategories);
        getAdditionalDocLinks(merged, status);
        setCourseTitle('');
        setCourseId('');
      }
    });
  };

  const getAdditionalDocLinks = (result, status) => {
    console.log('THE CATEGORIES', result);

    const allTheRecipes = [].concat.apply(
      [],
      result.map((r) => r.recipes).map((rec) => rec)
    );
    // setRecipeCourse(cat);

    // let getCatIds = cat.map((c) => {
    //   return c.id;
    // });
    // console.log('CATIDS', getCatIds);

    // const fetchData = () => {
    //   const allRequests = cat
    // 		.map((c) => c.id)
    // 		.map((e) =>
    // 			search({ restaurantId: restoid, categoryId: e }).then((response) => response.recipes)
    // 		);

    //   return Promise.all(allRequests);
    // };

    // fetchData().then((res) => {
    //   console.log('ALL ASSOCIATED RECIPES', res);
    //   let assocRecipes = [].concat.apply([], res);
    //   let parsed = assocRecipes
    // 		.filter(Boolean)
    //   	.map((e) => {
    // 			e.prepped = e.info ? JSON.parse(e.info) : {};
    // 			e.prepped.id = e.id;
    // 			e.prepped.name = e.name;
    // 			e.prepped.updatedAt = e.updatedAt;
    // 			e.prepped.order = e.info?.order;
    // 			e.prepped.tagLength = e.prepped.tags?.length;
    // 			return e;
    // 		});

    // console.log('PARSED', parsed);

    setAllRecipes(allTheRecipes);

    // let combined = cat.concat(parsed);
    // console.log('COMBO', combined);

    // const result = Object.values(
    // 	cat
    // 	.concat(parsed)
    // 		.reduce((r, o) => {
    // 			const key = `${o.categoryId}`;

    // 			if (!r[key]) r[key] = { ...o, recipes: [] };

    // 			//console.log(o.recipes);

    // 			r[key].recipes.push(o.prepped);

    // 			r[key].recipes = [].concat.apply([], r[key].recipes)
    // 				.filter((u) => u !== undefined)
    // 				.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
    // 				.sort((x, y) => {
    // 					return x.order - y.order;
    // 				});

    // 			return r;
    // 		}, {})
    // 	);

    console.log('MENUS WITH RECIPES', result);

    // console.log('ALL RECIPES', [].concat.apply([], result.map(r => r.recipes).map(rec => rec)))

    let stringList = result
      .sort((x, y) => {
        return y.id - x.id;
      })
      .sort((x, y) => {
        return x.order - y.order;
      })
      .filter((e) => e.recipes)
      .map((e) => e.recipes.map((link) => link.id))
      .reduce((a, b) => a.concat(b), [])
      .filter((l) => l !== undefined)
      .join(', ');

    // let getLinks = result
    //   .filter((e) => e.recipes)
    //   .map((e) => e.recipes.map((link) => link.id))
    //   .reduce((a, b) => a.concat(b), [])
    //   .filter((l) => l !== undefined);

    // let stringList = getLinks.join(', ');

    console.log('STRINGLIST', stringList);

    // ////
    // let params = {
    //   type: 'recipes',
    //   linkId: stringList,
    // };

    if (stringList !== '') {
      searchDocLinks({ type: 'recipes', linkId: stringList }).then((res) => {
        // console.log(res);
        if (res && res.length > 0) {
          // let removeDeleted = res.filter((l) => l.Document.folderId !== null);
          const list = Array.from(
            res
              .filter((l) => l.Document.folderId !== null)
              .reduce((m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]), new Map()),
            ([linkId, documentId]) => ({ linkId, documentId })
          );

          // console.log(list);

          let addSelectedLinks = result.map((o) => {
            (o.recipes || []).map((c) => {
              list.map((d) => {
                if (d.linkId == c.id) {
                  c.selected = d.documentId.length;
                }
              });
            });
            return o;
          });

          // console.log(addSelectedLinks);
          // ////
          console.log('RECIPE COURSE TO SET', addSelectedLinks);
          if (status == 'created') {
            let sortLinks = addSelectedLinks
              .sort((x, y) => {
                return y.categoryId - x.categoryId;
              })
              .sort((x, y) => {
                return x.order - y.order;
              });
            setRecipeCourse(sortLinks);
            setPristineData(sortLinks);
          } else {
            const uniqueValues = new Set(addSelectedLinks.map((v) => v.order));

            if (uniqueValues.size < addSelectedLinks.length) {
              let tmp = addSelectedLinks[1];
              addSelectedLinks[1] = addSelectedLinks[0];
              addSelectedLinks[0] = tmp;
              console.log(addSelectedLinks);
              setRecipeCourse(addSelectedLinks);
              setPristineData(addSelectedLinks);
            } else {
              setRecipeCourse(addSelectedLinks);
              setPristineData(addSelectedLinks);
            }
          }
        } else {
          if (status == 'created') {
            let sortResult = result
              .sort((x, y) => {
                return y.categoryId - x.categoryId;
              })
              .sort((x, y) => {
                return x.order - y.order;
              });
            setRecipeCourse(sortResult);
            setPristineData(sortResult);
          } else {
            const uniqueValues = new Set(result.map((v) => v.order));

            if (uniqueValues.size < result.length) {
              let tmp = result[1];
              result[1] = result[0];
              result[0] = tmp;
              setRecipeCourse(result);
              setPristineData(result);
            } else {
              setRecipeCourse(result);
              setPristineData(result);
            }
          }
        }
      });
    } else {
      console.log('RECIPE COURSE TO SET', result);
      setRecipeCourse(result);
      setPristineData(result);
    }
    // });
  };

  const showDrawer = (e, obj) => {
    setCourseId(obj?.categoryId);
    updateVisible(true);
    setIsLoading(true);
    let params = {
      restaurantId: restoId,
      from: 0,
      limit: 10000,
    };
    search(params).then((res) => {
      let recipe = res.recipes.map((i) => {
        let parsed = JSON.parse(i.info);
        i.info = parsed;
        i.tags = parsed.tags ? parsed.tags : [];
        i.RecipeDetails = JSON.parse(i.RecipeDetails);
        i.imageUrl =
          parsed.recipeImageThumbs?.length > 0
            ? parsed.recipeImageThumbs[0]
            : parsed.recipeImages?.length > 0
            ? parsed.recipeImages[0]
            : null;
        return i;
      });
      console.log(recipe);
      recipe.sort((a, b) => {
        return b.id - a.id;
      });
      let tags = recipe.map((t) => {
        return t.tags;
      });
      let mergedTags = [...new Set([].concat(...tags))]
        .map((t) => {
          if (t === '' || t === null) {
            return 'untagged';
          } else {
            return t.trim();
          }
        })
        .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() > b.toLowerCase() ? 1 : 0)); // FEATURE 1202040063159015 NEW
      // setActiveRecipe(recipe); // BUG 1202040063159015
      setPristineRecipe(recipe);
      console.log(mergedTags);
      let uniqueTags = [...new Set(mergedTags)];
      setTags(uniqueTags);
      setDrawerTags(uniqueTags);
      setIsLoading(false);
      document.getElementById('recipeAddBox').focus();
    });
  };

  const onClose = () => {
    updateVisible(false);
    updateVisible2(false);
    setActiveRecipe([]);
    document.getElementById('recipeCat').value = '';
    setIntputVal(null);
    setSearchedTag('');
    setTags([]);
    // removed setTagFilter(), not needed // BUG 1202030604174459
    setName('All recipes');
    getCourses();
    if (document.getElementById('recipeAddBox')) {
      document.getElementById('recipeAddBox').blur();
    }
  };

  const editCourse = (e, obj) => {
    setIsEditing(true);
    setidss(obj);
  };

  const renameCourse = async (e, obj) => {
    console.log(courseTitle, courseid);
    let params = {
      restaurantId: restoId,
      name: courseTitle.trim(),
    };

    if (e.key === 'Enter') {
      if (!courseTitle) {
        notification.open({
          message: 'Please provide a category name',
        });
        return;
      }

      if (recipeCourse && recipeCourse.length > 0 && Object.keys(obj).length > 0) {
        const cloneList = cloneDeep(recipeCourse);
        const index = findIndex(cloneList, { categoryId: obj.categoryId });
        if (cloneList[index]) {
          cloneList[index].name = courseTitle.trim();
          console.log(cloneList);
          setRecipeCourse(cloneList);
        }
      }

      const res = await updateRecipeCategory(courseid, params);
      setIsEditing(false);
      setTimeout(() => {
        // notification.open({
        //   message: 'Successfully changed category name! Fetching all categories...',
        // });
        getCourses();
      }, 2000);
    }
  };

  const renamesCourse = async (e, obj) => {
    if (isCanceled) {
      setIsEditing(false);
      setCancel(false);
      return;
    }

    let params = {
      restaurantId: restoId,
      name: courseTitle.trim(),
    };

    console.log(params);

    if (!courseTitle) {
      setIsEditing(false);
    } else {
      if (recipeCourse && recipeCourse.length > 0 && Object.keys(obj).length > 0) {
        const cloneList = cloneDeep(recipeCourse);
        const index = findIndex(cloneList, { categoryId: obj.categoryId });
        if (cloneList[index]) {
          cloneList[index].name = courseTitle.trim();
          console.log(cloneList);
          setRecipeCourse(cloneList);
        }
      }
      const res = await updateRecipeCategory(courseid, params);
      setIsEditing(false);
      setTimeout(() => {
        // notification.open({
        //   message: 'Successfully changed category name! Fetching all categories...',
        // });
        getCourses();
      }, 2000);
    }
  };

  const cancelRename = () => {
    setCancel(true);
    setTimeout(() => {
      setIsEditing(false);
      setCancel(false);
    }, 100);
  };

  const selectRecipe = (recipe) => {
    if (active.some((item) => item.name === recipe.name)) {
      const filteractive = active.filter((item) => item.name !== recipe.name);
      setActive(filteractive);
    } else {
      setActive((recipelist) => [...recipelist, recipe]);
    }
  };

  const addRecipes = () => {
    console.log(active, courseid);
    onClose();

    const addRecs = () => {
      const allRequests = active.map((e) => {
        update(e.id, { restaurantId: restoId, name: e.name, info: e.info, categoryId: courseid }).then(
          (response) => response
        );
      });

      return Promise.all(allRequests);
    };

    addRecs().then((res) => {
      setActive([]);
      setName('All recipes');
      // notification.open({
      //   message: 'Successfully added recipe to category!',
      // });
      setTimeout(() => {
        getCourses();
      }, 2000);
    });
  };

  // filterTags() not needed, BUG 1202030604174459

  const addNewCourse = () => {
    setIsAdding(true);
  };

  const delRecipe = () => {
    console.log(recId, courseid);
    let selectRecipe = allRecipes.filter((r) => r.id == recId);
    console.log(selectRecipe);
    update(recId, { restaurantId: restoId, categoryId: '0' })
      .then((response) => {
        // notification.open({
        //   message: 'Successfully unassigned recipe from category! Fetching all categories...',
        // });
        setTimeout(() => {
          getCourses();
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  const duplicateRecipe = (recipeId) => {
    duplicate({ recipeId: recipeId, restaurantId: resto.id })
      .then(() => {
        getCourses();
      })
      .catch((err) => console.log(err));
  };

  const createCourse = async (e) => {
    let course = courseTitle;
    let params = {
      restaurantId: restoId,
      name: course.trim(),
    };

    console.log(params);

    if (e.key === 'Enter') {
      if (!course) {
        // notification.open({
        //   message: 'Please provide a category name',
        // });
        return;
      }

      const res = await createRecipeCategory(params);
      if (res) {
        setTimeout(() => {
          // notification.open({
          //   message: 'Successfully created category! Fetching all categories...',
          // });
          getCourses('created');
        }, 2000);
      }
    }
  };

  const saveCourse = async () => {
    let course = courseTitle;
    let params = {
      restaurantId: restoId,
      name: course.trim(),
    };

    console.log(params);

    if (!course) {
      setIsAdding(false);
    } else {
      const res = await createRecipeCategory(params);
      if (res) {
        setTimeout(() => {
          // notification.open({
          //   message: 'Successfully created category! Fetching all categories...',
          // });
          getCourses('created');
        }, 2000);
      }
    }
  };

  const deleteCourse = (e, obj) => {
    //delete category and unassign associated recipe
    destroyRecipeCategory(obj?.categoryId).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Successfully deleted category! Fetching all categories...',
      // });
      setTimeout(() => {
        getCourses();
      }, 2000);
    });
  };

  // const menu = (
  //   <Menu className="menu-show-preview">
  //     <Menu.Item className="menu-show-preview" onClick={addNewCourse}>
  //       <a>Add new category</a>
  //     </Menu.Item>
  //     <Menu.Item className="menu-show-preview">
  //       <a>
  //         <Link to={{ pathname: '/recipes/CreateRecipes', createProps: { name: 'menus' } }}>Create recipe</Link>
  //       </a>
  //     </Menu.Item>
  //   </Menu>
  // );

  const menu1 = (obj) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a onClick={(e) => editCourse(e, obj)}>Rename {obj?.name ? obj?.name : 'category'}</a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a onClick={(e) => deleteCourse(e, obj)}>Delete {obj?.name ? obj?.name : 'category'}</a>
      </Menu.Item>
    </Menu>
  );

  const menuRec = (obj) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a onClick={() => duplicateRecipe(obj.id)}>Duplicate recipe</a>
        <a onClick={delRecipe}>Remove recipe</a>
      </Menu.Item>
    </Menu>
  );

  const menu3 = (obj) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <Link to={{ pathname: '/recipes/CreateRecipes', createProps: { name: 'cat', catId: obj.categoryId } }}>
          Create new recipe
        </Link>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a onClick={(e) => showDrawer(e, obj)}>Add existing recipe</a>
      </Menu.Item>
    </Menu>
  );

  const getAllRecipes = () => {
    setActiveRecipe(pristineRecipe);
    setName('All recipes');
  };

  const menuFilter = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={getAllRecipes}>
        <span>All recipes</span>
      </Menu.Item>
      {[...recipeCourse]
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((m, i) => {
          return (
            <Menu.Item key={`menu-${i.toString()}`} className="menu-show-preview" onClick={(e) => filteringRecipes(m)}>
              <span>{m.name}</span>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filteringRecipes = (m) => {
    console.log(m);
    setName(m.name);
    console.log(pristineRecipe);
    let inputValue = parseInt(m.categoryId);
    const clonedData = _.cloneDeep(pristineRecipe);
    let filtered = clonedData.filter((obj) => obj.categoryId === inputValue);
    console.log(filtered);
    setActiveRecipe(filtered);
  };

  const filterRecipes = (e) => {
    let inputValue = e.target.value;

    const clonedData = _.cloneDeep(pristineData);

    console.log(pristineData);

    let searchString = inputValue.toLowerCase();

    const searchTags = clonedData
      .map((item) => ({
        ...item,
        recipes: item.recipes.filter((child) => {
          let tagslowercased = child.tags?.map((name) => name.toLowerCase());
          return (
            item.name.toLowerCase().indexOf(searchString) !== -1 ||
            tagslowercased?.find((a) => a.includes(searchString)) ||
            child.name.toLowerCase().indexOf(searchString) !== -1
          );
        }),
      }))
      .filter((item) => item.recipes.length > 0);

    console.log(searchTags);

    if (inputValue == '') {
      setRecipeCourse(clonedData);
    } else {
      setRecipeCourse(searchTags);
    }
  };

  const searchRecipe = (e) => {
    setIntputVal(e.target.value);

    console.log(pristineRecipe);

    let inputValue = e.target.value;

    const clonedData = _.cloneDeep(pristineRecipe);

    let searchString = inputValue.toLowerCase();

    const searchTags = clonedData.filter((child) => {
      return child.tags.find((a) => a.includes(searchString)) || child.name.toLowerCase().indexOf(searchString) !== -1;
    });
    //.filter((item) => item.tags.length > 0);

    console.log(searchTags);

    setActiveRecipe(searchTags);
  };

  const setidss = (recipe) => {
    setCourseId(recipe?.categoryId);
    setRecName(recipe?.name);
    setSelectedId(recipe?.categoryId);
  };

  const showDrawer2 = (e, obj) => {
    updateVisible2(true);
    setDocLinks({
      type: 'recipes',
      linkId: obj.id,
    });
    setLinks({
      type: 'recipes',
      linkId: obj.id,
      info: { title: obj.name },
    });
  };

  const closeDrawer = useCallback((data) => {
    updateVisible(false);
    updateVisible2(false);
    getCourses();
  }, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    if (result.type === 'droppableItem') {
      const items = Array.from(recipeCourse);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      console.log('REORDERED ITEM', reorderedItem);
      setRecipeCourse(items);

      //save category order

      let getCatIds = items.map((item, index) => {
        return {
          id: item.categoryId,
          order: index,
          restaurantId: item.restaurantId,
          name: item.name,
        };
      });

      console.log('CATEGORY IDS', getCatIds);

      const fetchData = () => {
        const allRequests = getCatIds.map((e, index) =>
          updateRecipeCategory(e.id, { order: e.order, restaurantId: e.restaurantId, name: e.name }).then((res) => res)
        );

        return Promise.all(allRequests);
      };

      fetchData().then((res) => {
        // notification.open({
        //   message: 'Successfully updated category order!',
        // });
        setTimeout(() => {
          getCourses();
        }, 2000);
      });
    } else if (result.type === 'droppableSubItem') {
      const itemSubItemMap = recipeCourse.reduce((acc, item) => {
        acc[item.categoryId] = item.recipes || [];
        return acc;
      }, {});

      console.log('ITEM SUBITEM MAP', itemSubItemMap);

      const sourceParentId = parseInt(result.source.droppableId);
      const destParentId = parseInt(result.destination.droppableId);

      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];

      let newItems = [...recipeCourse];

      console.log('NEW ITEMS 1', newItems);

      if (sourceParentId === destParentId) {
        const items1 = Array.from(sourceSubItems);
        const [reorderedItem] = items1.splice(result.source.index, 1);
        items1.splice(result.destination.index, 0, reorderedItem);

        let reOrdered = newItems.map((item) => {
          if (item.categoryId === sourceParentId.toString()) {
            item.recipes = items1;
          }
          return item;
        });

        console.log('NEWITEMS', reOrdered);

        setRecipeCourse(newItems);

        //save order

        console.log(items1);

        let getRecipeIds = items1.map((item, index) => {
          item.order = index;
          item.info.order = index;
          return {
            id: item.id,
            name: item.name,
            info: item.info,
          };
        });

        console.log('RECIPE IDS', getRecipeIds);

        const fetchData = () => {
          const allRequests = getRecipeIds.map((e, index) =>
            update(e.id, {
              restaurantId: restoId,
              name: e.name,
              info: e.info,
              categoryId: sourceParentId,
            }).then((response) => response)
          );

          return Promise.all(allRequests);
        };

        fetchData().then((res) => {
          setActive([]);
          // notification.open({
          //   message: 'Successfully changed the order of a recipe!',
          // });
          setTimeout(() => {
            getCourses();
          }, 2000);
        });
      } else {
        let newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(result.source.index, 1);
        console.log('DRAGGED FROM CATEGORY', sourceParentId);
        console.log('DRAGGED TO CATEGORY', destParentId);
        console.log('DRAGGED ITEM', draggedItem);
        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(result.destination.index, 0, draggedItem);
        let moveItems = newItems.map((item) => {
          if (item.categoryId === sourceParentId.toString()) {
            item.recipes = newSourceSubItems;
          } else if (item.categoryId === destParentId.toString()) {
            item.recipes = newDestSubItems;
          }
          return item;
        });

        console.log('MOVED ITEMS', moveItems);
        setRecipeCourse(moveItems);

        //assign recipe to another category

        update(draggedItem.id, {
          restaurantId: restoId,
          name: draggedItem.name,
          info: draggedItem.info,
          categoryId: destParentId,
        })
          .then((response) => {
            // notification.open({
            //   message: 'Successfully reassigned recipe to another category! Fetching all categories...',
            // });
            setTimeout(() => {
              getCourses();
            }, 2000);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  // const seeMoreTags = () => {
  //   setDrawerTags(tagList);
  //   showSeeMore(false);
  // };

  // const handleTagChange = (tag, checked) => {
  //   const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
  //   console.log('SELECTED TAGS: ', nextSelectedTags);
  //   setSelectedTags(nextSelectedTags);
  // };

  // FEATURE 1202040063159015
  const handleTagAppend = (tag) => {
    setSelectedTags((currentTags) => [...currentTags, tag]);
  };

  // FEATURE 1202040063159015
  const handleTagRemove = (tag) => {
    setSelectedTags((currentTags) => currentTags.filter((t) => t !== tag));
  };

  // FEATURE 1202040063159015
  const tagOptions = (tags, accumulator, inputValue) => (
    <Menu className="assignee-options1">
      {tags?.map((tag, i) => {
        return (
          <Menu.Item className="assignee-options-item1" key={`${i}-${tag}-menu-item`}>
            <span
              onClick={() => {
                inputValue(tag);
                accumulator(tag);
              }}
            >
              {tag}
            </span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  // FEATURE 1202040063159015
  useEffect(() => {
    if (searchedTag !== '') {
      let relatedTags = tagList.filter(
        (tag) =>
          tag.toLowerCase().indexOf(searchedTag) !== -1 || searchedTag.toString().toLowerCase().indexOf(tag) !== -1
      );
      if (selectedTags.length > 0) {
        relatedTags = relatedTags.filter((tag) => !selectedTags.includes(tag));
      } else {
        relatedTags = tagList; // FEATURE 1202040063159015 NEW
      }
      let uniqueTags = [...new Set(tagList)];
      setDrawerTags(uniqueTags.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))); // BUG 1201998443633215
      setSelectedTags(searchedTag);
    } else {
      let relatedTags = tagList; // FEATURE 1202040063159015 NEW
      // FEATURE 1202040063159015 NEW
      if (selectedTags.length > 0) {
        relatedTags = relatedTags.filter((tag) => !selectedTags.includes(tag));
      } else {
        relatedTags = tagList;
      }
      let uniqueTags = [...new Set(relatedTags)];
      setSelectedTags(uniqueTags.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)));
      setDrawerTags(uniqueTags); // FEATURE 1202040063159015 NEW
    }
  }, [searchedTag]);

  return (
    <div className="scrollable-outer h-100">
      <div id="recipes" className="d-flex flex-column h-100 scrollable-container">
        <NewSubHeader title="Recipes" onChange={filterRecipes}>
          <RoleAccessibleComponent
            permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
          >
            <PlusButton className="m-1" alt="Add new category" onClick={addNewCourse}>
              Add Category
            </PlusButton>
          </RoleAccessibleComponent>
        </NewSubHeader>
        <div className="Line"></div>
        <div className="scrollable-outer flex-grow-1">
          <div className="recipe-course-box scrollable-container d-flex flex-column flex-grow-1">
            {isAdding ? (
              <div style={{ display: 'flex' }}>
                <div>
                  <div className="recipe-name-box">
                    <div>
                      <input
                        autoFocus
                        type="text"
                        className="courses-bar"
                        placeholder="Enter a category name"
                        onChange={(e) => setCourseTitle(e.target.value)}
                        onKeyDown={(e) => createCourse(e)}
                        onBlur={saveCourse}
                      />
                    </div>
                  </div>
                  <div className="recipe-tile-container" style={{ height: 400 }}></div>
                </div>
                {recipeCourse &&
                  recipeCourse.length > 0 &&
                  recipeCourse.map((recipe, i) => {
                    return (
                      <div>
                        <div className="recipe-name-box">
                          <div className="recipe-name">{recipe?.name}</div>
                          <RoleAccessibleComponent
                            permittedRoles={[
                              'Chef',
                              'Admin',
                              'Superadmin',
                              'Client Admin',
                              'Dev Team',
                              'Account Holder',
                            ]}
                          >
                            <Dropdown overlay={menu3(recipe)}>
                              <div className="add-menu" alt="add">
                                <PlusIcon />
                              </div>
                            </Dropdown>
                          </RoleAccessibleComponent>
                          <RoleAccessibleComponent
                            permittedRoles={[
                              'Chef',
                              'Admin',
                              'Superadmin',
                              'Client Admin',
                              'Dev Team',
                              'Account Holder',
                            ]}
                          >
                            <Dropdown overlay={menu1(recipe)}>
                              <div
                                className="more-menu ant-dropdown-link"
                                alt="see_more"
                                onClick={(e) => e.preventDefault()}
                              >
                                <MoreIcon />
                              </div>
                            </Dropdown>
                          </RoleAccessibleComponent>
                        </div>
                        <div className="recipe-tile-container">
                          {recipe.recipes &&
                            recipe.recipes.map((r) => {
                              return (
                                <div className="recipe-tile">
                                  <Link to={{ pathname: `/recipes/view/${r.id}`, state: { from: 'recipes' } }}>
                                    <ThumbnailImage
                                      src={
                                        (r?.info.recipeImages || r?.info.recipeImageThumbs) &&
                                        (r?.info.recipeImages?.length || r?.info.recipeImageThumbs?.length) > 0
                                          ? r?.info.recipeImages[0] || r?.info.recipeImageThumbs
                                          : null
                                      }
                                      type={'recipe'}
                                    />
                                  </Link>
                                  <div className="course-name-box">
                                    <Link to={{ pathname: `/recipes/view/${r.id}`, state: { from: 'recipes' } }}>
                                      <div className="course-title">{r.name}</div>
                                    </Link>
                                    <div onMouseOver={() => setRecId(r.id)}>
                                      <RoleAccessibleComponent
                                        permittedRoles={[
                                          'Chef',
                                          'Admin',
                                          'Superadmin',
                                          'Client Admin',
                                          'Dev Team',
                                          'Account Holder',
                                        ]}
                                      >
                                        <Dropdown overlay={menuRec(r)}>
                                          <div className="more-menu-2" alt="see_more">
                                            <MoreIcon />
                                          </div>
                                        </Dropdown>
                                      </RoleAccessibleComponent>
                                    </div>
                                  </div>
                                  <Link to={{ pathname: `/recipes/view/${r.id}`, state: { from: 'recipes' } }}>
                                    <div className="tag-box">
                                      {r?.tags?.length > 0 &&
                                        r?.tags?.map((tag, i) => {
                                          return <div className="tag">{tag}</div>;
                                        })}
                                    </div>
                                  </Link>
                                  <div className="Line-2"></div>
                                  <div className="recipe-box-tile-info">
                                    <div className="common-user-icon flex-control-left" alt="user-photo">
                                      <UserImage src={r.userImg} />
                                    </div>
                                    <AttachmentIndicator
                                      className="flex-control"
                                      showIfZero={true}
                                      count={r.selected}
                                      onClick={(e) => showDrawer2(e, r)}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          <RoleAccessibleComponent
                            permittedRoles={[
                              'Chef',
                              'Admin',
                              'Superadmin',
                              'Client Admin',
                              'Dev Team',
                              'Account Holder',
                            ]}
                          >
                            <div className="create-recipe">
                              <Link
                                to={{
                                  pathname: '/recipes/CreateRecipes',
                                  createProps: { name: 'cat', catId: recipe.categoryId },
                                }}
                              >
                                Create new recipe
                              </Link>
                            </div>
                          </RoleAccessibleComponent>
                          <RoleAccessibleComponent
                            permittedRoles={[
                              'Chef',
                              'Admin',
                              'Superadmin',
                              'Client Admin',
                              'Dev Team',
                              'Account Holder',
                            ]}
                          >
                            <div className="add-recipe" data-value={i} onClick={(e) => showDrawer(e, recipe)}>
                              Add existing recipe
                            </div>
                          </RoleAccessibleComponent>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppableId" direction="horizontal" type="droppableItem">
                  {(provided, snapshot) => (
                    <div
                      // style={{ display: 'flex' }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      className="scrollable-outer fancy-scrollbar"
                    >
                      {recipeCourse &&
                        recipeCourse.length > 0 &&
                        recipeCourse.map((recipe, i) => {
                          return (
                            <Draggable
                              key={recipe.categoryId.toString()}
                              draggableId={recipe.categoryId.toString()}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                  className="scrollable-inner d-flex flex-column mb-2"
                                >
                                  {isEditing && recipe.categoryId === selectedId ? (
                                    // <div onMouseOver={() => setCourseId(recipe.categoryId)}>
                                    <div>
                                      <input
                                        autoFocus
                                        type="text"
                                        className="courses-bar1"
                                        placeholder="Enter new category name"
                                        defaultValue={recipe?.name}
                                        onChange={(e) => setCourseTitle(e.target.value)}
                                        onKeyDown={(e) => renameCourse(e, recipe)}
                                        onBlur={(e) => renamesCourse(e, recipe)}
                                      />
                                      {/* <img src={add} className="add-menu" alt="add" onClick={renameCourse} /> */}

                                      <div
                                        onClick={cancelRename}
                                        style={{
                                          width: 100,
                                          cursor: 'pointer',
                                          margin: '3px 0px 7px 3px',
                                          color: 'var(--accent-color)',
                                        }}
                                      >
                                        <span>Cancel</span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="recipe-name-box"
                                      // onMouseOver={() => setCourseId(recipe?.categoryId)}
                                    >
                                      <div className="recipe-name">{recipe?.name}</div>
                                      <RoleAccessibleComponent
                                        permittedRoles={[
                                          'Chef',
                                          'Admin',
                                          'Superadmin',
                                          'Client Admin',
                                          'Dev Team',
                                          'Account Holder',
                                        ]}
                                      >
                                        <Dropdown overlay={menu3(recipe)}>
                                          <div className="add-menu" alt="add">
                                            <PlusIcon />
                                          </div>
                                        </Dropdown>
                                      </RoleAccessibleComponent>
                                      <RoleAccessibleComponent
                                        permittedRoles={[
                                          'Chef',
                                          'Admin',
                                          'Superadmin',
                                          'Client Admin',
                                          'Dev Team',
                                          'Account Holder',
                                        ]}
                                      >
                                        <Dropdown overlay={menu1(recipe)}>
                                          {/* <div onMouseOver={() => setidss(recipe)}> */}
                                          <div>
                                            <div
                                              className="more-menu ant-dropdown-link"
                                              alt="see_more"
                                              onClick={(e) => setCourseTitle(recipe?.name)}
                                            >
                                              <MoreIcon />
                                            </div>
                                          </div>
                                        </Dropdown>
                                      </RoleAccessibleComponent>
                                    </div>
                                  )}

                                  <Droppable droppableId={recipe.categoryId.toString()} type={`droppableSubItem`}>
                                    {(provided, snapshot) => (
                                      <div
                                        className="recipe-tile-container flex-grow-1 fancy-scrollbar"
                                        // onMouseOver={() => setCourseId(recipe?.categoryId)}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle2(snapshot.isDraggingOver)}
                                      >
                                        {recipe.recipes &&
                                          recipe.recipes.map((r, i) => {
                                            return (
                                              <Draggable
                                                key={r?.id.toString()}
                                                draggableId={r?.id.toString()}
                                                index={i}
                                              >
                                                {(provided, snapshot) => (
                                                  <div>
                                                    <div
                                                      className="recipe-tile"
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={getItemStyle2(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                      )}
                                                    >
                                                      <Link
                                                        to={{
                                                          pathname: `/recipes/view/${r.id}`,
                                                          state: { from: 'recipes' },
                                                        }}
                                                      >
                                                        <ThumbnailImage
                                                          src={
                                                            (r?.info.recipeImages || r?.info.recipeImageThumbs) &&
                                                            (r?.info.recipeImages?.length ||
                                                              r?.info.recipeImageThumbs?.length) > 0
                                                              ? r?.info.recipeImages[0] || r?.info.recipeImageThumbs
                                                              : null
                                                          }
                                                          type={'recipe'}
                                                        />
                                                      </Link>
                                                      <div className="course-name-box">
                                                        <Link
                                                          to={{
                                                            pathname: `/recipes/view/${r.id}`,
                                                            state: { from: 'recipes' },
                                                          }}
                                                        >
                                                          <div className="course-title">{r?.name}</div>
                                                        </Link>
                                                        <div onMouseOver={() => setRecId(r.id)}>
                                                          <RoleAccessibleComponent
                                                            permittedRoles={[
                                                              'Chef',
                                                              'Admin',
                                                              'Superadmin',
                                                              'Client Admin',
                                                              'Dev Team',
                                                              'Account Holder',
                                                            ]}
                                                          >
                                                            <Dropdown overlay={menuRec(r)}>
                                                              <div className="more-menu-2" alt="see_more">
                                                                <MoreIcon />
                                                              </div>
                                                            </Dropdown>
                                                          </RoleAccessibleComponent>
                                                        </div>
                                                      </div>
                                                      <Link
                                                        to={{
                                                          pathname: `/recipes/view/${r.id}`,
                                                          state: { from: 'recipes' },
                                                        }}
                                                      >
                                                        <div className="tag-box">
                                                          {r?.info.tags?.length > 0 &&
                                                            r?.info.tags?.map((tag, j) => {
                                                              return (
                                                                <div
                                                                  className="tag"
                                                                  key={`recipe-${r?.id || j}-tag-${j}`}
                                                                >
                                                                  {tag}
                                                                </div>
                                                              );
                                                            })}
                                                        </div>
                                                      </Link>
                                                      <div className="Line-2"></div>
                                                      <div className="recipe-box-tile-info">
                                                        <div
                                                          className="common-user-icon flex-control-left"
                                                          alt="user-photo"
                                                        >
                                                          <UserImage src={r.userImg} />
                                                        </div>
                                                        <AttachmentIndicator
                                                          className="flex-control"
                                                          showIfZero={true}
                                                          count={r.selected}
                                                          onClick={(e) => showDrawer2(e, r)}
                                                        />
                                                      </div>
                                                    </div>
                                                    {provided.placeholder}
                                                  </div>
                                                )}
                                              </Draggable>
                                            );
                                          })}
                                        {provided.placeholder}
                                        <RoleAccessibleComponent
                                          permittedRoles={[
                                            'Chef',
                                            'Admin',
                                            'Superadmin',
                                            'Client Admin',
                                            'Dev Team',
                                            'Account Holder',
                                          ]}
                                        >
                                          <div className="add-recipe">
                                            <Link
                                              to={{
                                                pathname: '/recipes/CreateRecipes',
                                                createProps: { name: 'cat', catId: recipe.categoryId },
                                              }}
                                            >
                                              Create new recipe
                                            </Link>
                                          </div>
                                        </RoleAccessibleComponent>
                                        <RoleAccessibleComponent
                                          permittedRoles={[
                                            'Chef',
                                            'Admin',
                                            'Superadmin',
                                            'Client Admin',
                                            'Dev Team',
                                            'Account Holder',
                                          ]}
                                        >
                                          <div
                                            className="add-recipe"
                                            data-value={i}
                                            onClick={(e) => showDrawer(e, recipe)}
                                          >
                                            Add existing recipe
                                          </div>
                                        </RoleAccessibleComponent>
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
        </div>
        <Drawer
          width={windowSize.width > 800 ? '850px' : '100%'}
          onClose={onClose}
          visible={visible}
          closable={false}
          drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
        >
          <div id="recipeAddBox" tabIndex="0">
            <br />
            <div className="add-course-box-update">
              <CidekicButton className="button-highlight" alt="add selected recipes" onClick={addRecipes}>
                Add selected recipes
              </CidekicButton>
              {windowSize.width > 700 ? (
                <div className="close-drawer flex-control-right" onClick={onClose}>
                  X <span className="close-text">Close</span>
                </div>
              ) : (
                <div className="close-drawer flex-control-right" onClick={onClose}>
                  X
                </div>
              )}
            </div>
            <div style={{ textAlign: 'center' }}>{isLoading && <Spin tip="Loading recipes..."></Spin>}</div>
            <div className="add-course-box-update">
              <ExpandingSearchPanel
                id={'recipeCat'}
                className="flex-control-left"
                showSearch={showSearch}
                searchPrompt="Search recipes"
                onChange={searchRecipe}
                onClick={() => setShowSearch(true)}
                value={inputVal}
              />
            </div>
            <div className="add-course-box-update">
              <CidekicDropdown overlay={menuFilter} className="rec-drawer-select">
                {name}
              </CidekicDropdown>
              {/* FEATURE 1202040063159015 */}
              <MultiTagSelect
                //overlay={tagOptions(drawerTags, handleTagAppend, setSearchedTag)}
                className="rec-drawer-tags"
                onChange={setSearchedTag}
                value={searchedTag}
              >
                {drawerTags.map((cat, i) => (
                  <Option key={i} value={cat}>
                    {cat}
                  </Option>
                ))}
              </MultiTagSelect>
            </div>
            {/* FEATURE 1202040063159015 NEW */}
            {/* {selectedTags.length > 0 && (
              <SelectedTags
                selectedTags={selectedTags.sort((a, b) =>
                  a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() > b.toLowerCase() ? 1 : 0
                )}
                onRemove={handleTagRemove}
              />
            )} */}
            {/* <div className="tag-box">
            {drawerTags.map((tag) => (
              <CheckableTag
                key={tag}
                checked={selectedTags.indexOf(tag) > -1}
                onChange={(checked) => handleTagChange(tag, checked)}
              >
                <div
                  className={selectedTags.includes(tag) ? 'selected-tag' : 'tag'}
                  data-value={tag}
                  //removed another callback for clicking, CheckableTag's onChange sufficient // BUG 1202030604174459
                >
                  {tag}
                </div>
              </CheckableTag>
            ))}
            {seeMore && (
              <span className="course-see-tags" onClick={seeMoreTags}>
                See more tags
              </span>
            )}
          </div> */}

            <br />
            <div>
              <div className="warpper">
                <div>
                  <div className="recipe-tile-drawerbox">
                    {activeRecipe &&
                      activeRecipe.length > 0 &&
                      activeRecipe.map((course, i) => {
                        return (
                          <div
                            className={
                              active.some((item) => item.id === course.id)
                                ? 'recipe-tile-drawerSelected'
                                : 'recipe-tile-drawer'
                            }
                            key={i}
                            onClick={() => selectRecipe(course)}
                          >
                            <div>
                              <ThumbnailImage src={course.imageUrl} type={'recipe'} />
                            </div>
                            <div className="course-name-box">
                              <div className="course-title">{course?.name}</div>
                            </div>
                            <div className="tag-box">
                              {course?.tags?.map((tag, i) => {
                                return <div className="tag">{tag}</div>;
                              })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
        <Drawer
          width={windowSize.width > 800 ? '850px' : '100%'}
          onClose={onClose}
          visible={visible2}
          closable={false}
          drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
        >
          <DocumentList
            windowSize={windowSize}
            closeDrawer={closeDrawer}
            docLinks={docLinks}
            links={links}
            isMobile={false}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default RecipeCategory;
