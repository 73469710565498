import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

const data = {
  labels: ['January', 'February', 'March', 'April', 'May'],
  datasets: [
    {
      label: 'Ingredient 1',
      fill: false,
      lineTension: 0.02,
      backgroundColor: 'red',
      borderColor: 'red',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [1, 2, 3, 4, 5],
    },
    {
      label: 'Ingredient 2',
      fill: false,
      lineTension: 0.02,
      backgroundColor: 'yellow',
      borderColor: 'yellow',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [1.5, 2.5, 3.5, 4.5, 2],
    },
    {
      label: 'Ingredient 3',
      fill: false,
      lineTension: 0.02,
      backgroundColor: 'green',
      borderColor: 'green',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [1.25, 2.25, 3.25, 4.25, 0],
    },
    {
      label: 'Ingredient 4',
      fill: false,
      lineTension: 0.02,
      backgroundColor: 'orange',
      borderColor: 'orange',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [3.25, 2.25, 4.25, 1.25, 0],
    },
    {
      label: 'Ingredient 5',
      fill: false,
      lineTension: 0.02,
      backgroundColor: 'blue',
      borderColor: 'blue',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [4.55, 2.55, 3.65, 1.75, 2],
    },
  ],
};

class LineGraph extends Component {
  render() {
    return (
      <div className="linegraph-container">
        <div className="bargraph-ing-title">Line Example</div>
        <Line
          ref="chart"
          data={data}
          options={{
            legend: { labels: { usePointStyle: true, fontColor: 'white' }, position: 'right' },
            scales: {
              yAxes: [
                {
                  ticks: {
                    fontColor: 'white',
                    min: 1,
                    max: 5,
                    callback: function (value) {
                      return `$ ${value}`;
                    },
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    fontColor: 'white',
                  },
                },
              ],
            },
          }}
        />
      </div>
    );
  }

  componentDidMount() {
    const { datasets } = this.refs.chart.chartInstance.data;
    console.log(datasets[0].data);
  }
}

export default LineGraph;
