/* eslint eqeqeq: "off" */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/cidekic_logo.svg';
import leftMenu from '../../img/menuIcon/ui-elements-buttons-left-menu-off.svg';
import RightMenu from '../../img/menuIcon/ui-elements-buttons-right-menu-off.svg';
import { menuItems, menuItems2 } from '../../data/admin';
import './index.css';
// import { impersonate } from '../../services/operator.service';

const AdminMenu = () => {
  const [toggleMenu, setToggleMenu] = useState(true);
  const [getUrl, setGetUrl] = useState('');
  const [userData, setUserData] = useState(false);
  const [isAdminRole, setIsAdminRole] = useState(false);

  useEffect(() => {
    let url = window.location.href;
    if (url.includes('super')) {
      setGetUrl('super');
    } else if (url.includes('admin')) {
      setGetUrl('admin');
    }

    const roleString = localStorage.getItem('operator');
    if (roleString) {
      const roleObj = JSON.parse(roleString);
      const role = roleObj.role;

      if (role === 'Superadmin' || role === 'Admin') {
        setIsAdminRole(true);
      }
    }

    function impersonating() {
      const item = localStorage.getItem('impersonate');
      if (item) {
        setUserData(true);
      }
    }

    window.addEventListener('storage', impersonating);

    return () => {
      window.removeEventListener('storage', impersonating);
    };
  }, []);

  useEffect(() => {
    if (document.querySelector('#side-menu').classList.contains('menu-collapse-off')) {
      setToggleMenu(false);
    }
  }, []);

  const toggleTheMenu = (toggle) => {
    document.querySelector('#side-menu').removeAttribute('style');
    setToggleMenu(toggle);
    if (toggle) {
      document.querySelector('#side-menu').classList.add('menu-collapse-on');
      document.querySelector('#side-menu').classList.remove('menu-collapse-off');
    } else {
      document.querySelector('#side-menu').classList.add('menu-collapse-off');
      document.querySelector('#side-menu').classList.remove('menu-collapse-on');
    }
  };
  return (
    <div>
      <div className="bg">
        <div>
          <Link to={'/dashboard'}>
            <img src={logo} className="cidekic-logo" alt="logo" />
          </Link>
        </div>
        {getUrl == 'admin' && !userData && (
          <div>
            <ul>
              {menuItems.map((menus, i) => {
                return (
                  <li key={menus.name}>
                    <div>
                      {i === 0 && toggleMenu ? (
                        <div>
                          <Link to={'/dashboard?impersonate=impersonate'}>
                            <div className="admin-back">Back to restaurant</div>
                          </Link>
                          <div className="parent-menu-0 flex">
                            <div className="sidemenu-section-heading">{menus.title}</div>
                            <div onClick={() => toggleTheMenu(false)} className="menuToggle-btn">
                              <img src={leftMenu} alt="menu" />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {i === 0 && !toggleMenu ? (
                        <div className="parent-menu-0 flex">
                          <div className="margin">{menus.title}</div>
                          <div onClick={() => toggleTheMenu(true)} className="menuToggle-btn rightMenu">
                            <img src={RightMenu} alt="menu" />
                          </div>
                        </div>
                      ) : null}
                      {i > 0 ? (
                        <div className="parent-menu1">
                          <div className="margin">{menus.title}</div>
                        </div>
                      ) : null}
                    </div>
                    {i !== 0 ? <div className="menu-line"></div> : null}
                    <div className="list-margin">
                      <ul>
                        {menus.children
                          .filter((item) => (isAdminRole ? item : item.name !== 'users'))
                          .map((sub, ii) => {
                            return (
                              <li key={sub.name}>
                                <div className="sidemenu-menu-div">
                                  <div className="sidemenu-menu1 flex">
                                    {/* <div className="sidemenu-icon-dashboard  UI-IconsMenusOn"></div> */}

                                    {/* <img src={sub.icon} className="UI-IconsMenusOn"></img> */}
                                    <Link to={'/admin/' + sub.name}>
                                      <div className="flex">
                                        <div>{sub.icon ? sub.icon : null}</div>
                                        {toggleMenu ? <div>{sub.title}</div> : null}
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {getUrl == 'super' && !userData && (
          <div>
            <ul>
              {menuItems2.map((menus, i) => {
                return (
                  <li key={menus.name}>
                    <div>
                      {i === 0 && toggleMenu ? (
                        <div>
                          <Link to={'/dashboard'}>
                            <div className="admin-back">Back to restaurant</div>
                          </Link>
                          <div className="parent-menu-0 flex">
                            <div className="sidemenu-section-heading">{menus.title}</div>
                            <div onClick={() => toggleTheMenu(false)} className="menuToggle-btn">
                              <img src={leftMenu} alt="menu" />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {i === 0 && !toggleMenu ? (
                        <div className="parent-menu-0 flex">
                          <div className="margin">{menus.title}</div>
                          <div onClick={() => toggleTheMenu(true)} className="menuToggle-btn rightMenu">
                            <img src={RightMenu} alt="menu" />
                          </div>
                        </div>
                      ) : null}
                      {i > 0 ? (
                        <div className="parent-menu1">
                          <div className="margin">{menus.title}</div>
                        </div>
                      ) : null}
                    </div>
                    {i !== 0 ? <div className="menu-line"></div> : null}
                    <div className="list-margin">
                      <ul>
                        {menus.children
                          .filter((item) => (isAdminRole ? item : item.name !== 'users'))
                          .map((sub, ii) => {
                            return (
                              <li key={sub.name}>
                                <div className="sidemenu-menu-div">
                                  <div className="sidemenu-menu1 flex">
                                    {/* <div className="sidemenu-icon-dashboard  UI-IconsMenusOn"></div> */}

                                    {/* <img src={sub.icon} className="UI-IconsMenusOn"></img> */}
                                    <Link to={'/super/' + sub.name}>
                                      <div className="flex">
                                        <div>{sub.icon ? sub.icon : null}</div>
                                        {toggleMenu ? <div>{sub.title}</div> : null}
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {getUrl == 'admin' && userData && (
          <div>
            <ul>
              {menuItems.map((menus, i) => {
                return (
                  <li key={menus.name}>
                    <div>
                      {i === 0 && toggleMenu ? (
                        <div>
                          <Link to={'/dashboard'}>
                            <div className="admin-back">Back to restaurant</div>
                          </Link>
                          <div className="parent-menu-0 flex">
                            <div className="sidemenu-section-heading">{menus.title}</div>
                            <div onClick={() => toggleTheMenu(false)} className="menuToggle-btn">
                              <img src={leftMenu} alt="menu" />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {i === 0 && !toggleMenu ? (
                        <div className="parent-menu-0 flex">
                          <div className="margin">{menus.title}</div>
                          <div onClick={() => toggleTheMenu(true)} className="menuToggle-btn rightMenu">
                            <img src={RightMenu} alt="menu" />
                          </div>
                        </div>
                      ) : null}
                      {i > 0 ? (
                        <div className="parent-menu1">
                          <div className="margin">{menus.title}</div>
                        </div>
                      ) : null}
                    </div>
                    {i !== 0 ? <div className="menu-line"></div> : null}
                    <div className="list-margin">
                      <ul>
                        {menus.children
                          .filter((item) => (isAdminRole ? item : item.name !== 'users'))
                          .map((sub, ii) => {
                            return (
                              <li key={sub.name}>
                                <div className="sidemenu-menu-div">
                                  <div className="sidemenu-menu1 flex">
                                    {/* <div className="sidemenu-icon-dashboard  UI-IconsMenusOn"></div> */}

                                    {/* <img src={sub.icon} className="UI-IconsMenusOn"></img> */}
                                    <Link to={'/admin/' + sub.name}>
                                      <div className="flex">
                                        <div>{sub.icon ? sub.icon : null}</div>
                                        {toggleMenu ? <div>{sub.title}</div> : null}
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminMenu;
