import React, { useState, useEffect } from 'react';
import RecipeCourseMobile from '../../components/RecipeCourse/RecipeCourseMobile.js';
import { BacktoHeaderMobile } from '../../components/Header/HeadMobile.js';
import './index.css';

const MenuDetailsMobile = () => {
  const currentPath = window.location.href;
  const [restaurant, setRestaurant] = useState('');

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    setRestaurant(resto.name);
  }, []);

  return (
    <div id="menu-details-mobile" className="common-page-container-mobile">
      <BacktoHeaderMobile restaurant={restaurant} backText="Back to menus" backLink="/menus" />
      <div className="Line"></div>

      <RecipeCourseMobile currentPath={currentPath} />
      {/* <AddRecipe /> */}
    </div>
  );
};

export default MenuDetailsMobile;
