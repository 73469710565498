import { request } from './http.service';
import { decodeKitchenMessages } from '../protobuf-models/kitchen.proto.js';
import { decodeStationIngredientMessages } from '../protobuf-models/stationingredient.proto.js';
import { cloneDeep } from 'lodash';
import moment from 'moment'; //

export const create = (params) => {
  return request('POST', '/station', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const createStationChecklist = (params) => {
  return request('POST', '/station/checklist/item', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const createStationChecklists = (ingredients) => {
  return request('POST', '/station/checklist/bulk', 200, { ingredients: JSON.stringify(ingredients) }).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateStation = (id, params) => {
  return request('PUT', '/station/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateChecklist = (params) => {
  return request('PUT', '/station/checklist', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateChecklists = (params) => {
  return request('PUT', '/station/checklists', 200, { checklists: JSON.stringify(params) }).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroy = (id) => {
  return request('DELETE', '/station/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getStation = (id) => {
  return request('GET', '/station/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchStation = (params) => {
  return request('POST', '/station/search', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const getStationOperators = (params) => {
  return request('GET', '/station/getStationOperators/' + params.id + '/search/' + params.name, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getAllStationOperators = (id) => {
  return request('GET', '/station/getStationOperators/' + id + '/all', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getStationRecipeIngredients = (recipeId) => {
  return request('GET', `/station/ingredients/${recipeId}`, 200).then(
    (result) => {
      if (result?.ingredients) {
        return JSON.parse(result.ingredients);
      } else {
        return [];
      }
    },
    (error) => {
      console.log(error);
    }
  );
};

export const createKitchenStation = (params) => {
  return request('POST', '/station/createKitchenStation', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateKitchenStation = (id, operatorId) => {
  return request('PUT', '/station/updateKitchenStation/' + id, 200, { operatorId: operatorId }).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getKitchenStations = (id) => {
  return request('GET', '/station/getKitchenStations/' + id, 200, null, decodeKitchenMessages).then(
    (result) => {
      console.log('GETKITCHENS', result);
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const loadKitchenStations = (restaurantId) => {
  return request('GET', '/station/loadKitchenStations/' + restaurantId, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const deleteKitchenStation = (id) => {
  return request('DELETE', '/station/deleteKitchenStation/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getExistingEventMenus = (id) => {
  return request('GET', `/station/${id}/menus/of/checklists`, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
}

export const getEventStationIngredients = (stationId) => {
  return request(
    'POST',
    '/station/ingredients',
    200,
    { stationId: stationId.toString(), type: 'event' },
    decodeStationIngredientMessages
  ).then(
    (result) => {
      if (result && result.stationingredients) {
        const resultUnpacked = cloneDeep(result.stationingredients);
        return resultUnpacked.map((item) => {
          const toFloat = parseFloat(item.ingredientQty);
          const parsed = item.measurementInfo !== 'nomeas' ? JSON.parse(item.measurementInfo) : {};
          item.preparationName = item.preparationName !== 'noprep' ? item.preparationName : '';
          item.measurementName = item.measurementName !== 'nomeas' ? item.measurementName : '';
          item.measurementInfo = parsed;
          item.day = item?.setDate && item.setDate !== 'unset' ? moment(item.setDate).format('dddd') : 'Invalid date';
          item.ingredientQty = toFloat;
          item.recipeAssignDate = item.recipeAssignDate.length !== '' ? new Date(item.recipeAssignDate).getTime() : 0;
          item.type = 'child';
          return item;
        });
      }
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getKitchenStationIngredients = (stationId) => {
  return request(
    'POST',
    '/station/ingredients',
    200,
    { stationId: stationId.toString(), type: 'kitchen' },
    decodeStationIngredientMessages
  ).then(
    (result) => {
      if (result && result.stationingredients) {
        const resultUnpacked = cloneDeep(result.stationingredients);
        return resultUnpacked.map((item) => {
          // transform into appropriate datatypes
          const toFloat = parseFloat(item.ingredientQty);
          const parsed = item.measurementInfo !== 'nomeas' ? JSON.parse(item.measurementInfo) : {};
          item.key = `${item.ingredientId}-${item.recipeId}-${item.courseId}-${item.menuId}`;
          item.preparationName = item.preparationName !== 'noprep' ? item.preparationName : '';
          item.measurementName = item.measurementName !== 'nomeas' ? item.measurementName : '';
          item.measurementInfo = parsed;
          item.day = item?.setDate ? moment(item.setDate).format('dddd') : '';
          item.ingredientQty = toFloat;
          item.type = 'child';
          return item;
        });
      }
    },
    (error) => {
      console.log(error);
    }
  );
};

export const deleteStationChecklist = (stationId, recipeId, ingredientId) => {
  return request('DELETE', `/station/${stationId}/checklist/ingredient/${recipeId}/recipe/${ingredientId}`, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};
