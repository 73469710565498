/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import empty from '../../img/cidekic_logo_bw.svg';
import { Table, Menu, Spin, Empty } from 'antd';
import { NewSubHeader } from '../../components/Header/Header';
import {
  PlusButton,
  CidekicDropdown,
  ShoppingCartIcon,
  ThumbnailImage,
  ChefIcon,
} from '../../components/common/common_ui';
import AddIngredientPopup from './AddIngredientPopup';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import delete_img from '../../img/delete-icon.png';
import _, { remove } from 'lodash';
import moment from 'moment';

import {
  bulkAddItems as addItems,
  getItems as getShoplistItems,
  deleteItem as removeFromList,
  itemOrdered as checkItem,
  itemNeeded as revertItem,
  getItemRequester,
} from '../../services/shoplist.service';

const ShoppingList = (props) => {
  // SUBCOMPONENT STATES
  // const [expandedRows, setExpandedRows] = useState([]);
  const [filterMenuName, setFilterMenuName] = useState('Needed');

  // TABLE DATA
  const [pristineData, setPristineData] = useState([]);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [newIngredients, setNewIngredients] = useState([]);

  // COMPONENT STATES
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearchResult, searchResultFound] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [orderedItemsOnly, showOrderedItemsOnly] = useState(false);
  const [allItems, showAllItems] = useState(true);
  const [currentSearchString, setCurrentSearchString] = useState('');
  const [originalSettings, setOriginalSettings] = useState({
    filter: '', // cached filter setting
    search: '', // cached search setting
    order: {
      // cached sort setting
      column: 'dateRequested',
      status: 'descend',
    },
  });

  const restaurantId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  const operatorId = (JSON.parse(localStorage.getItem('operator')) || {}).id;

  // CALLBACKS
  // 1. finalize Table Rows
  const finalizeRows = useCallback(
    (orderedItemsOnly, allItems, filteredBySearch = false, searchResults = []) => {
      let keySwitch = '';

      if (orderedItemsOnly) {
        keySwitch = 'ordered';
      } else if (allItems && !orderedItemsOnly) {
        keySwitch = 'all items';
      } else if (!allItems && !orderedItemsOnly) {
        keySwitch = 'needed';
      }

      setFilterMenuName(keySwitch[0].toUpperCase() + keySwitch.slice(1));
      let toTabularize = [];
      const currentOrderCol = originalSettings.order.column;
      const currentOrderStat = originalSettings.order.status;
      // tabularizes search results instead of whole shoplist item from database
      if (filteredBySearch && searchResults.length) {
        toTabularize = rearrangeTable(currentOrderCol, currentOrderStat, searchResults);
      } else {
        // if not searching, uses pristineData or if it exists, the saved table order, to tabularize
        // show all items
        if (allItems) {
          toTabularize = rearrangeTable(currentOrderCol, currentOrderStat, pristineData);
        } else {
          //filter out needed or ordered shoplist items
          let ingredientsFiltered = pristineData.filter((ing) => ing.ordered == ('ordered' === keySwitch));
          console.log(ingredientsFiltered);
          toTabularize = rearrangeTable(currentOrderCol, currentOrderStat, ingredientsFiltered);
        }
      }
      // saves rearranged table rows based on latest order
      setTableDataSource(toTabularize);
      setIsLoading(false);
    },
    [pristineData, orderedItemsOnly, allItems]
  );

  // COMPONENT FUNCTIONS:
  // 1. Search Bar
  // a. triggerSearch - for manual search
  const triggerSearch = (e) => {
    searchShoplist(e.target.value);
  };
  // b. searchShoplist - search the Shopping List Table
  const searchShoplist = (searchString) => {
    setIsSearching(true);
    let inputValue = searchString;

    // if search input has term
    if (inputValue.length > 0) {
      const clonedData = _.cloneDeep(pristineData);
      let searchString = inputValue.toLowerCase();
      setCurrentSearchString(searchString);
      originalSettings.search = searchString;
      let results = clonedData.filter(searchAllArr(inputValue));

      filterOnSearch(results);
    } else {
      // reset searching state to false
      setIsSearching(false);
      setCurrentSearchString('');
      originalSettings.search = '';
      if (localStorage.getItem('shoplistSearch') != null) {
        localStorage.removeItem('shoplistSearch');
      }
    }
  };
  // c. filterOnSearch - filter based on search results
  const filterOnSearch = (res) => {
    // search for items with ingredient name / station name / requester name
    let searchResults = res.filter((item) => matchSearchKey(item));
    // if there is only one category to be shown, remove except items
    if (searchResults.length >= 1) {
      searchResultFound(true);
      finalizeRows(orderedItemsOnly, allItems, true, searchResults);
    } else {
      searchResultFound(false);
      setIsLoading(false);
    }
  };
  // d. searchAllArr - auxiliary function for filtering based on search string
  const searchAllArr = (text) => (value) => {
    if (!value) return false;
    const valueType = typeof value;

    if (valueType === 'string') {
      return value.toLowerCase().indexOf(text.toLowerCase()) > -1;
    }
    if (Array.isArray(value)) {
      return value.some(searchAllArr(text));
    }
    if (valueType === 'object') {
      return Object.values(value).some(searchAllArr(text));
    }
    return false;
  };
  // e. matchSearchKey - loops over shoplist rows that match search term with set criteria
  const matchSearchKey = useCallback(
    (item) => {
      return (
        item.name.toLowerCase().indexOf(currentSearchString) > -1 ||
        item.stationName?.toLowerCase().indexOf(currentSearchString) > -1 ||
        item.requesterName.toLowerCase().indexOf(currentSearchString) > -1 ||
        item.category.toLowerCase().indexOf(currentSearchString) > -1
      );
    },
    [currentSearchString]
  );
  // f. onSearchFocus - function when focused
  const onSearchFocus = (e) => {
    if (e.target.value === '' && originalSettings.search !== '') {
      if (localStorage?.getItem('shoplistSearch') != null) {
        e.target.value = localStorage.getItem('shoplistSearch');
      }
    }
  };

  // 3. Table Functions
  // a. filterShoplistMenu - menu items of dropdown [filterNeeded, filterOrdered]
  const filterNeeded = () => {
    setFilterMenuName('Needed');
  };
  const filterOrdered = () => {
    setFilterMenuName('Ordered');
  };
  const filterAll = () => {
    setFilterMenuName('All items');
  };
  const filterShoplistMenu = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <span onClick={filterNeeded}>Needed</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterOrdered}>Ordered</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterAll}>All Items</span>
      </Menu.Item>
    </Menu>
  );
  // b. removeItem - remove from shopping list
  const removeItem = (row) => {
    const itemId = row.id;
    // const itemCategory = row.category;
    let newPristineData = _.cloneDeep(pristineData);
    remove(newPristineData, { id: itemId });
    setPristineData(newPristineData);
    setTableDataSource(newPristineData);

    removeFromList(itemId).then(() => {
      // notification.open({
      //   message: 'Removing item from shopping list...',
      // });
      // if currently in search mode or filtered by status,
      if (isSearching || !allItems) {
        let updatedSearchedData = _.cloneDeep(tableDataSource);
        let searchedDataUpdated = rearrangeTable(
          originalSettings.order.column,
          originalSettings.order.status,
          updatedSearchedData.filter((item) => item.id !== itemId)
        );
        setTableDataSource(searchedDataUpdated);
      }
    });
  };
  // c. addNewItems - add new ingredient/s to shopping list
  const addNewItems = (items) => {
    // sorted out the needed information to display at table
    const itemsPrepared = items.map((item) => {
      return {
        ingredientCategoryId: parseInt(item.categoryId),
        category: item.category,
        imageUrl: item?.imageUrl ? item?.imageUrl : item?.info?.imageUrl ? item?.info?.imageUrl : '',
        imageUrlThumb: item?.imageUrlThumb
          ? item?.imageUrlThumb
          : item?.info?.imageUrlThumb
          ? item?.info?.imageUrlThumb
          : '',
        ingredientId: parseInt(item.id),
        name: item.name,
      };
    });

    // prepare new records to Shoplist database
    const currentDate = new Date() * 1;
    let newShoppingListItems = itemsPrepared.map((n) => {
      return {
        operatorId: operatorId,
        ingredientId: n.ingredientId,
        ingredientCategoryId: n.ingredientCategoryId,
        restaurantId: restaurantId,
        dateTime: currentDate,
      };
    });
    const itemsToAdd = JSON.stringify(newShoppingListItems);

    // add new records to Shoplist
    addItems({ items: itemsToAdd })
      .then((newlyAddedItems) => {
        setTimeout(() => {
          // notification.open({
          //   message: 'Items added to shopping list!',
          // });
        }, 2000);

        let newItems = JSON.parse(newlyAddedItems);

        // after successfully adding requested items to Shoplist, get full name of requester
        getItemRequester(operatorId.toString()).then((res) => {
          const requesterName = res;

          // new ingredients
          let newIngs = newItems.map((item) => {
            const ingredientInfo = itemsPrepared.find((p) => p.ingredientId === item.ingredientId);
            return {
              key: item.id.toString(),
              id: parseInt(item.id),
              ingredientId: parseInt(item.ingredientId),
              name: ingredientInfo.name,
              ingredientCategoryId: parseInt(item.ingredientCategoryId),
              category: item.category,
              imageUrl: ingredientInfo?.imageUrl ? ingredientInfo?.imageUrl : '',
              imageUrlThumb: ingredientInfo?.imageUrlThumb ? ingredientInfo?.imageUrlThumb : '',
              requesterId: parseInt(item.operatorId),
              requesterName: requesterName,
              requestDate: item.dateTime,
              dateRequested: moment(item.dateTime).format('MM/DD/YYYY hh:mm A'),
              stationId: null,
              eventId: null,
              stationName: 'None',
              stationType: null,
              ordered: item.ordered,
              // type: 'child',
            };
          });
          // if there are totally new ingredients from new categories
          if (newIngs.length > 0) {
            setPristineData((current) => [...current, ...newIngs]);
            // if currently in search mode or filtered by status,
            if (isSearching || !allItems) {
              let updatedSearchedData = _.cloneDeep(tableDataSource);
              if (!orderedItemsOnly) {
                // only update table items when filtered to 'Needed'
                // otherwise no changing
                if (isSearching) {
                  // further determines which added item matches search term
                  let newSearchResults = newIngs.filter((item) => matchSearchKey(item));
                  if (newSearchResults.length > 0) {
                    updatedSearchedData = updatedSearchedData.concat([...newSearchResults]);
                    updatedSearchedData = rearrangeTable(
                      originalSettings.order.column,
                      originalSettings.order.status,
                      updatedSearchedData
                    );
                    setTableDataSource(updatedSearchedData);
                  }
                }
              }
            }
          }
          setNewIngredients([]);
        });
      })
      .catch((err) => {
        console.log(err);
        setNewIngredients([]);
      });
  };
  // d. toggleOrderedStatus - callback to setting item as 'ordered' or 'needed'
  const toggleOrderedStatus = (row) => {
    // console.log(row);
    // let updatedPristineData = _.cloneDeep(pristineData);
    const currentDate = new Date() * 1;
    const statusChangeDetails = {
      operatorId: operatorId,
      dateUpdated: currentDate.toString(),
    };

    // if ordered, change to needed
    if (row.ordered) {
      revertItem(row.id, statusChangeDetails).then((result) => {
        setTimeout(() => {
          // notification.open({
          //   message: 'Reverted status back to needed.',
          // });
        }, 1000);
        updateOrderedStatus(result);
      });
      // else, vice versa
    } else {
      checkItem(row.id, statusChangeDetails).then((result) => {
        setTimeout(() => {
          // notification.open({
          //   message: 'Changed status to ordered.',
          // });
        }, 1000);
        updateOrderedStatus(result);
      });
    }
  };
  // 5. updateOrderedStatus - callback after setting a shoplist item's status to 'ordered' or 'needed' and vice versa
  const updateOrderedStatus = (row) => {
    let updatedPristineData = _.cloneDeep(pristineData);
    const updatedItemIndex = updatedPristineData.findIndex((i) => i.id === row.id);
    updatedPristineData[updatedItemIndex].ordered = row.ordered;
    setPristineData(updatedPristineData);
    // if currently in search mode or filtered by status,
    if (isSearching || !allItems) {
      let updatedSearchedData = _.cloneDeep(tableDataSource);
      remove(updatedSearchedData, { id: row.id });
      updatedSearchedData = rearrangeTable(
        originalSettings.order.column,
        originalSettings.order.status,
        updatedSearchedData
      );
      setTableDataSource(updatedSearchedData);
    }
  };

  // 4. Table Columns
  // a. shopListCols - column definition
  const shoplistCols = [
    // Ingredients
    {
      title: 'Ingredients',
      dataIndex: 'name',
      width: '30%',
      className: 'shoplist-table-title-cell',
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {
            <div className="shoplist-table-child-key-container">
              <div className="shoplist-table-child-image-container">
                <ThumbnailImage
                  src={
                    obj.imageUrl.length > 0 ? obj?.imageUrl : obj.imageUrlThumb.length > 0 ? obj.imageUrlThumb : null
                  }
                  placeholderIcon={<ChefIcon />}
                />
              </div>
              <div className="shoplist-table-child-key-vert-center">{key}</div>
            </div>
          }
        </span>
      ),
    },
    // Categories
    {
      title: 'Category',
      dataIndex: 'category',
      width: '15%',
      className: 'shoplist-table-title-cell',
      sorter: (a, b) => a.category.toLowerCase().localeCompare(b.category.toLowerCase()),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {<span>{key ? <span className="shoplist-table-child-text-filter">{key}</span> : 'Uncategorized'}</span>}
        </span>
      ),
    },
    // Requested by
    {
      title: 'Requested by',
      dataIndex: 'requesterName',
      width: '15%',
      render: (key, obj) => <span>{key ? <span className="shoplist-table-child-text-filter">{key}</span> : ''}</span>,
    },
    // Date/Time
    {
      title: 'Date/Time',
      dataIndex: 'dateRequested',
      width: '15%',
      sorter: (a, b) =>
        new Date(a.requestDate) * 1 < new Date(b.requestDate) * 1
          ? -1
          : new Date(a.requestDate) * 1 > new Date(b.requestDate)
          ? 1
          : 0,
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => <span>{<span className="shoplist-table-child-text-filter">{key}</span>}</span>,
    },
    // Station
    {
      title: 'Station',
      dataIndex: 'stationName',
      width: '15%',
      render: (key, obj) => (
        <span>
          {obj.stationName !== 'None' && obj.stationType ? (
            <Link
              to={
                obj.stationType === 'event'
                  ? '/event/station/' + obj.eventId.toString()
                  : obj.stationType === 'kitchen'
                  ? '/station/' + obj.stationId.toString()
                  : ''
              }
            >
              <span className="shoplist-table-child-text-link">{key}</span>
            </Link>
          ) : (
            <span className="shoplist-table-child-text-filter">{key}</span>
          )}
        </span>
      ),
    },
    // Status
    {
      title: 'Status',
      dataIndex: 'ordered',
      width: '10%',
      sorter: (a, b) => (a.ordered * 1 < b.ordered * 1 ? -1 : 1),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {
            <span className="shoplist-order-status-icon" alt="ordered-status" onClick={() => toggleOrderedStatus(obj)}>
              <ShoppingCartIcon needed={!key} ordered={key} dimmed={false} />
            </span>
          }
        </span>
      ),
    },
    // Remove
    {
      title: '',
      dataIndex: 'delete',
      width: '5%',
      render: (key, obj) => (
        <span>
          {
            <img
              alt="ordered-status"
              height="12"
              onClick={() => removeItem(obj)}
              src={delete_img}
              style={{ cursor: 'pointer' }}
            />
          }
        </span>
      ),
    },
  ];
  // b. rearrangeTable = automatically restores previous sort setting
  const rearrangeTable = (column, status, rows = []) => {
    // let tableRows = rows.length > 0 ? rows : pristineData;
    let tableRows = rows;
    if (tableRows.length > 1) {
      if (column === 'ordered') {
        if (status === 'ascend') {
          return tableRows.sort((a, b) => (a['ordered'] * 1 < b['ordered'] * 1 ? 1 : -1));
        } else {
          return tableRows.sort((a, b) => (a['ordered'] * 1 > b['ordered'] * 1 ? 1 : -1));
        }
      } else if (column === 'dateRequested') {
        if (status === 'ascend') {
          // from earliest to latest in date added
          return tableRows.sort((a, b) => (new Date(a.requestDate) * 1 >= new Date(b.requestDate) * 1 ? 1 : -1));
        } else {
          // from latest to earliest in date added
          return tableRows.sort((a, b) => (new Date(a.requestDate) * 1 <= new Date(b.requestDate) * 1 ? 1 : -1));
        }
      } else {
        if (status === 'ascend') {
          return tableRows.sort((a, b) =>
            a[column].toLowerCase().localeCompare(b[column].toLowerCase()) >= 0 ? 1 : -1
          );
        } else {
          return tableRows.sort((a, b) =>
            a[column].toLowerCase().localeCompare(b[column].toLowerCase()) <= 0 ? 1 : -1
          );
        }
      }
    } else {
      return tableRows;
    }
  };

  // 5. Table Change
  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order) {
      // console.log(sorter);
      localStorage.setItem(
        'shoplistOrder',
        JSON.stringify({
          column: sorter.columnKey,
          status: sorter.order,
        })
      );
    }
  };

  // INITIALIZATION
  useEffect(() => {
    setIsLoading(true);
    getShoplistItems({
      from: 0,
      limit: 1000,
      restaurantId: restaurantId.toString(),
    }).then((items) => {
      let tableRows = [];
      if (items.length > 0) {
        items.forEach((item) => {
          item['key'] = `${item.id}`;
          item['dateRequested'] = moment(item.requestDate).format('MM/DD/YYYY hh:mm A');
          tableRows.push(item);
        });
      }
      setPristineData(tableRows);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId]);

  // APPLY CACHED SETTINGS ON STATES
  useEffect(() => {
    // 1. apply last search setting
    const lastSearchTerm =
      localStorage?.getItem('shoplistSearch') != null ? localStorage.getItem('shoplistSearch') : '';
    originalSettings.search = lastSearchTerm;
    setCurrentSearchString(lastSearchTerm);
    // 2. apply last filter setting
    const lastFilterSetting =
      localStorage?.getItem('shoplistFilter') != null ? localStorage.getItem('shoplistFilter') : 'All items';
    originalSettings.filter = lastFilterSetting;
    setFilterMenuName(originalSettings.filter);
    // 3. apply last sort setting
    const lastSortSetting =
      localStorage?.getItem('shoplistOrder') != null ? JSON.parse(localStorage.getItem('shoplistOrder')) : null;
    if (lastSortSetting) {
      originalSettings.order.column = lastSortSetting.column;
      originalSettings.order.status = lastSortSetting.status;
    }
  }, []);

  // CHANGE CURRENT FILTER WITH CHANGED MENU NAME
  useEffect(() => {
    if (filterMenuName === 'Needed') {
      showAllItems(false);
      showOrderedItemsOnly(false);
    } else if (filterMenuName === 'Ordered') {
      showOrderedItemsOnly(true);
      showAllItems(false);
    } else {
      showOrderedItemsOnly(false);
      showAllItems(true);
    }

    // stops entire search operation and clears search cache if exist
    setIsSearching(false);
    searchResultFound(false);
    setCurrentSearchString('');
    originalSettings.search = '';
    if (localStorage?.getItem('shoplistSearch') != null) {
      localStorage.removeItem('shoplistSearch');
    }
  }, [filterMenuName]);

  // Saving latest updates for shoplist data
  useEffect(() => {
    if (pristineData.length > 0 && !isSearching) {
      console.log('PRISTINE DATA', pristineData);
      if (pristineData.length > 1) {
        // only caches order if there are more than 1 rows
        localStorage.setItem(
          'shoplistOrder',
          JSON.stringify({
            column: originalSettings.order.column,
            status: originalSettings.order.status,
          })
        );
      }
      if (!isSearching && currentSearchString !== '') {
        searchShoplist(currentSearchString);
        setIsSearching(false);
      } else {
        finalizeRows(orderedItemsOnly, allItems);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pristineData, orderedItemsOnly, allItems, originalSettings.order, currentSearchString, isSearching]);

  // AFTER CHOOSING NEW INGREDIENTS FROM DRAWER
  useEffect(() => {
    if (newIngredients.length > 0) {
      addNewItems(newIngredients);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIngredients]);

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  // AUTH SESSION KEYS
  // 1. save filter setting
  useEffect(() => {
    originalSettings.filter = filterMenuName;
    localStorage.setItem('shoplistFilter', filterMenuName);
  }, [filterMenuName]);
  // 2. save search setting
  useEffect(() => {
    if (currentSearchString.length) {
      originalSettings.search = currentSearchString;
      localStorage.setItem('shoplistSearch', currentSearchString);
    }
  }, [currentSearchString]);

  return (
    <div id="shoplist" className="common-page-container d-flex flex-column">
      <NewSubHeader
        title="Shopping List"
        placeholder={currentSearchString !== '' ? currentSearchString : null}
        onFocus={(e) => onSearchFocus(e)}
        onChange={triggerSearch}
      />
      <div className="Line"></div>
      <div className="page-margins flex-grow-1 d-flex flex-column">
        <div className="controls-thin mb-2 d-flex flex-row">
          <RoleAccessibleComponent
            permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
          >
            <PlusButton
              className="flex-control-left flex-control-rightspace"
              alt="Add new ingredients"
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            >
              Add new ingredients
            </PlusButton>
          </RoleAccessibleComponent>
          <CidekicDropdown overlay={filterShoplistMenu} className="flex-control-right select-wide" trigger={['click']}>
            {filterMenuName}
          </CidekicDropdown>
        </div>
        {isLoading && (
          <div style={{ textAlign: 'center' }}>
            <Spin tip="Loading shopping list ingredients..."></Spin>
          </div>
        )}
        {!isLoading && (
          <div className="flex-grow-1">
            <div className={'shoplist-table'}>
              <Table
                useFixedHeader={true}
                columns={shoplistCols}
                dataSource={tableDataSource}
                className={'show-custom-empty ant-table-fixedheader'}
                pagination={false}
                locale={{
                  emptyText: (
                    <Empty
                      image={empty}
                      description={
                        !hasSearchResult && isSearching
                          ? `No items found`
                          : `No ${orderedItemsOnly ? 'ordered' : 'pending'} items to display`
                      }
                    />
                  ),
                }}
                rowKey={'key'}
                onChange={onTableChange}
              />
            </div>
          </div>
        )}
      </div>

      <AddIngredientPopup
        isDrawerOpen={isDrawerOpen}
        newIngredients={(newOrders) => setNewIngredients(newOrders)}
        closeDrawer={closeDrawer}
        restaurantId={restaurantId}
      />
    </div>
  );
};

export default ShoppingList;
