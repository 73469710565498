
import { saveSettings, loadSettings } from '../../services/operator.service';


export const setDayMode = (bool) => {
  let operatorEmail = JSON.parse(localStorage.getItem('operator') || {}).email;
  console.log("settingDayMode: " + bool);

  saveSettings(`${operatorEmail}`, {
    dayMode: bool,
  }).then((res) => {
    var root = document.querySelector(':root');
    var root_styles = getComputedStyle(root);
    var theme = bool == 0 ? 'dark' : 'light';
    root.style.setProperty('--deep-fill', root_styles.getPropertyValue(`--deep-fill-${theme}`));
    root.style.setProperty('--main-fill', root_styles.getPropertyValue(`--main-fill-${theme}`));
    root.style.setProperty('--main-borders', root_styles.getPropertyValue(`--main-borders-${theme}`));
    root.style.setProperty('--base-text', root_styles.getPropertyValue(`--base-text-${theme}`));
    root.style.setProperty('--mid-fill', root_styles.getPropertyValue(`--mid-fill-${theme}`));
    root.style.setProperty('--accent-color', root_styles.getPropertyValue(`--accent-color-${theme}`));
  });
};

export default setDayMode;
