import React from 'react';
import './index.css';

export const InputSelectCombo3 = (props) => {
  const {
    formLabel,
    showValue,
    timeUnit,
    setTimeUnit,
    onChange,
    onBlur,
    keyword,
    keyword2,
    inputClassMobile,
    windowSize,
  } = props;

  return (
    <div className="col">
      <div className="prep-title">{formLabel}</div>
      <div className="flex-grid col-prep">
        <div className="col">
          <input
            type="text"
            className={inputClassMobile ? inputClassMobile : 'recipe-input-sm'}
            placeholder="0"
            value={showValue}
            onChange={(event) => onChange(event, keyword)} //validation code
            onBlur={(e) => onBlur(e, keyword2)}
          />
        </div>
        <div className="col">
          <select
            className={windowSize > 700 ? 'recipe-metadata-select' : 'recipe-metadata-select-mobile'}
            value={timeUnit}
            onChange={(e) => setTimeUnit(e.target.value)}
          >
            <option value="hours">Hours</option>
            <option value="minutes">Minutes</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default InputSelectCombo3;
