import { request } from './http.service';
import { decodeMenuCategoryMessages } from '../protobuf-models/category.proto.js';
import { decodeCategorizedRecipeMessages } from '../protobuf-models/categorizedrecipes.proto.js';
import { decodeIngredientCategoryMessages } from '../protobuf-models/ingredientcategory.proto.js'; // BUG 1202076501452783

export const createIngredientCategory = (params) => {
  return request('POST', '/category/ingredient', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateIngredientCategory = (id, params) => {
  return request('PUT', '/category/ingredient/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroyIngredientCategory = (id) => {
  return request('DELETE', '/category/ingredient/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getIngredientCategory = (id) => {
  return request('GET', '/category/ingredient/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchIngredientCategories = (params) => {
  return request('POST', '/category/ingredient/search', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const createMenuCategory = (params) => {
  return request('POST', '/category/menu', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateMenuCategory = (id, params) => {
  return request('PUT', '/category/menu/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroyMenuCategory = (id) => {
  return request('DELETE', '/category/menu/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getMenuCategory = (id) => {
  return request('GET', '/category/menu/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchMenuCategories = (params) => {
  return request('POST', '/category/menu/search', 200, params, decodeMenuCategoryMessages).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const createRecipeCategory = (params) => {
  return request('POST', '/category/recipe', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateRecipeCategory = (id, params) => {
  return request('PUT', '/category/recipe/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroyRecipeCategory = (id) => {
  return request('DELETE', '/category/recipe/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getRecipeCategory = (id) => {
  return request('GET', '/category/recipe/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchRecipeCategories = (params) => {
  return request('POST', '/category/recipe/search', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchCategorizedRecipes = (params) => {
  return request('POST', '/category/with/recipes/search', 200, params, decodeCategorizedRecipeMessages).then(
    (result) => {
      if (result && result.categorizedrecipes) {
        console.log('UNPARSED >>>>', result.categorizedrecipes);
        let categories = result.categorizedrecipes.map((r) => {
          r.recipes = JSON.parse(r.Recipes)?.length >= 0 ? JSON.parse(r.Recipes) : [];
          r.categoryId = r.id ? r.id.toString() : '';
          r.name = r.name ? r.name : '';
          r.restaurantId = r.restaurantId.toString();
          delete r.id;
          delete r.Recipes;
          return r;
        });
        // console.log('FETCHED FROM BACKEND >>>>', categories);
        return categories;
      } else {
        return [];
      }
    },
    (error) => {
      console.log(error);
    }
  );
};

// BUG 1202076501452783
export const getCategories = (id) => {
  return request('POST', '/category/ingredient/all', 200, { accountId: id }, decodeIngredientCategoryMessages).then(
    (result) => {
      console.log('FETCHED FROM BACKEND >>>', result);
      if (result) {
        return result.ingredientcategories;
      } else {
        return [];
      }
    },
    (error) => {
      console.log(error);
    }
  );
};
