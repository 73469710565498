/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import info from '../../img/station-info-icon.png';
import { Table, Switch, Menu, Dropdown, Popover, Drawer, Row, Col, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import useWindowSize from '../../components/windowdimensions/windowSize';
import StationRecipesMobile from '../../components/StationRecipes/stationRecipes-mobile';
import StationChecklistsMobile from '../../components/StationChecklists/stationChecklists-mobile';
import { updateChecklist } from '../../services/station.service';
import { updateItem } from '../../services/checklist.service';
import {
  addItem as addItemToShop,
  // itemNeeded as revertItem,
  itemOrdered,
  // deleteStationItems as removeFromShop,
  deleteItem,
} from '../../services/shoplist.service';
import { cloneDeep, findIndex } from 'lodash';
import moment from 'moment';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { formatIfNeeded } from '../../pages/Recipes/utilities';
import {
  CompletionIcon,
  HealthcheckIcon,
  ShoppingCartIcon,
  ThumbnailImage,
  CookbookIcon,
  ChefIcon,
} from '../../components/common/common_ui';

const StationFilteredMobile = (props) => {
  const {
    showFilter,
    recList,
    tableData,
    updateActiveRecs,
    filterCompletedHealthChecks,
    filterCompleted,
    filterProductionReady,
    filterNeeded,
    updateIngredientState,
    activeChk,
    activeRecs,
    updateActiveChks,
    selectedStationChecklists,
    stationId,
    setHasUpdate,
    updateTaskState,
    assignedToMe,
    hasChecklist,
    pristineIngs,
    showAllIngredients,
    filtered,
    isEvent,
    hasRecipeLists,
    stationTasks,
    setPristineIngs,
  } = props;
  const [expandedRows, setExpandedRows] = useState([1]);
  const [visible, updateVisible] = useState(true);
  const [showFilteredDrawerData, updateShowFilteredDrawer] = useState(false);
  const [menuName, setMenuName] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [selectedList, setSelectedList] = useState([]);
  const [showChecklists, setShowChecklists] = useState(true);
  const [pristineList, setPristineList] = useState([]);
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/checklist`));
  const [showRecipeLists, setShowRecipeLists] = useState(false);
  const [currentChecklist, setCurrentChecklist] = useState(0);

  const openRecipePanel_ids = JSON.parse(localStorage.getItem('openRecipePanelStations')) || [];
  const restaurantId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  let userRole = (JSON.parse(localStorage.getItem('operator')) || {}).role;

  console.log(selectedStationChecklists, activeChk);

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('operator')) || {});
    if (selectedStationChecklists[0]?.list[0]?.list?.length > 0) {
      if (openRecipePanel_ids.includes(stationId)) {
        setShowChecklists(false);
      } else {
        setShowChecklists(true);
      }
    } else {
      setShowChecklists(false);
    }
  }, [selectedStationChecklists, showChecklists]);

  useEffect(() => {
    let activeIds = activeChk.map((a) => a.key);

    let stationLists2 = stationTasks.filter((s) => activeIds.includes(s.key));

    if (activeChk.length === 0) {
      setSelectedList(stationTasks);
    } else {
      setSelectedList(stationLists2);
    }

    if (stationTasks.length == 1) {
      setSelectedList(stationTasks);
      setPristineList(stationTasks);
    } else {
      setSelectedList(stationLists2);
    }
  }, [activeChk, selectedStationChecklists]);

  // useEffect(() => {
  //   if (showRec) {
  //     console.log(showRec);
  //     showRec.ingredients = showRec?.ingredients?.filter((ing) => ing.id == showRec.id);
  //   }
  // }, [showRec]);

  // to show ingredients with quality check NOT YET completed
  const filterHealthComplete = () => {
    filterCompletedHealthChecks();
    setMenuName('Quality check incomplete');
  };

  // to show quality checked ingredients NOT YET ready for production
  const filterComplete = () => {
    filterCompleted();
    setMenuName('Production completed');
  };

  // to show quality checked ingredients READY for production
  const filterProductionReady1 = () => {
    filterProductionReady();
    setMenuName('Production ready');
  };

  const filterIncomplete = () => {
    let list = cloneDeep(pristineList);
    list.forEach((o) => {
      o.children = o.children.filter((s) => s.complete == 1);
    });
    setSelectedList(list);
    setMenuName('Items incomplete');
  };

  const filterComplete1 = () => {
    let list1 = cloneDeep(pristineList);
    list1.forEach((o) => {
      o.children = o.children.filter((s) => s.complete == 2);
    });
    setSelectedList(list1);
    setMenuName('Items completed');
  };

  // filter ingredients that already in the shopping list page
  const filterShop = () => {
    filterNeeded();
    setMenuName('Shopping list');
  };

  const updateStationIngredient = (fetchParams, ingredient, mappedUpdates) => {
    let clonedStationIngs = cloneDeep(pristineIngs);
    const ingIndex = findIndex(
      clonedStationIngs,
      (ing) =>
        ing.ingredientId === ingredient.ingredientId &&
        ing.recipeId === ingredient.recipeId &&
        ing.courseId === ingredient.courseId &&
        ing.menuId === ingredient.menuId
    );
    if (ingIndex > -1) {
      mappedUpdates.forEach((pair) => {
        const key = pair.key,
          value = pair.value;
        clonedStationIngs[ingIndex][key] = value;
      });
      setPristineIngs(clonedStationIngs);

      updateChecklist(fetchParams)
        .then((res) => {
          setTimeout(() => {
            updateIngredientState(true);
          }, 1000);
        })
        .catch((err) => console.log(err));
    }
  };

  const toggleHealth = (obj) => {
    let newValue = obj.healthCheck === 1 ? 2 : 1;
    let prepIng = {
      healthCheck: newValue,
      completed: obj.completed,
      ordered: obj.ordered,
      setDate: obj.setDate,
      setTime: obj.setTime,
      setBy: obj.setBy,
      quantity: obj.ingredientQty,
      cost: 0,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (isEvent) {
      params.eventId = stationId;
    } else {
      params.stationId = stationId;
    }

    updateStationIngredient(params, obj, [{ key: 'healthCheck', value: newValue }]);
    // if (obj.healthCheck == 1) {
    //   let prepIng = {
    //     healthCheck: 2,
    //     completed: obj.completed,
    //     ordered: obj.ordered,
    //     setDate: obj.setDate,
    //     setTime: obj.setTime,
    //     setBy: obj.setBy,
    //     quantity: obj.quantity,
    //     cost: obj.cost,
    //     order: obj.order,
    //     originalQty: obj.originalQty,
    //   };
    //   let params = {
    //     menuId: obj.menuId,
    //     recipeId: obj.recipeId,
    //     ingredientId: obj.ingredientId,
    //     preparationId: obj.preparationId,
    //     measurementId: obj.measurementId,
    //     info: prepIng,
    //   };

    //   if (isEvent) {
    //     params.eventId = obj.eventId;
    //   } else {
    //     params.stationId = obj.stationId;
    //   }

    //   let clonedStationShownIngs = cloneDeep(showRec);
    //   const ingredientIndex = findIndex(
    //     clonedStationShownIngs.ingredients,
    //     (rec) =>
    //       rec.ingredientId === obj.ingredientId &&
    //       rec.measurementId === obj.measurementId &&
    //       rec.preparationId === obj.preparationId &&
    //       rec.stationId === obj.stationId &&
    //       rec.recipeId === obj.recipeId
    //   );
    //   clonedStationShownIngs.ingredients[ingredientIndex].healthCheck = 2;

    //   setShowRec(clonedStationShownIngs);

    //   updateChecklist(params)
    //     .then((res) => {
    //       // notification.open({
    //       //   message: 'Successfully activated! Loading updated data...',
    //       // });

    //       setTimeout(() => {
    //         updateIngredientState(true);
    //       }, 1000);
    //     })
    //     .catch((err) => console.log(err));
    // } else {
    //   let prepIng1 = {
    //     healthCheck: 1,
    //     completed: 1,
    //     ordered: obj.ordered,
    //     setDate: obj.setDate,
    //     setTime: obj.setTime,
    //     setBy: obj.setBy,
    //     quantity: obj.quantity,
    //     cost: obj.cost,
    //     order: obj.order,
    //     originalQty: obj.originalQty,
    //   };
    //   let params1 = {
    //     menuId: obj.menuId,
    //     recipeId: obj.recipeId,
    //     ingredientId: obj.ingredientId,
    //     preparationId: obj.preparationId,
    //     measurementId: obj.measurementId,
    //     info: prepIng1,
    //   };
    //   if (isEvent) {
    //     params1.eventId = obj.eventId;
    //   } else {
    //     params1.stationId = obj.stationId;
    //   }

    //   let clonedStationShownIngs = cloneDeep(showRec);
    //   const ingredientIndex = findIndex(
    //     clonedStationShownIngs.ingredients,
    //     (rec) =>
    //       rec.ingredientId === obj.ingredientId &&
    //       rec.measurementId === obj.measurementId &&
    //       rec.preparationId === obj.preparationId &&
    //       rec.stationId === obj.stationId &&
    //       rec.recipeId === obj.recipeId
    //   );
    //   clonedStationShownIngs.ingredients[ingredientIndex].healthCheck = 1;

    //   setShowRec(clonedStationShownIngs);

    //   updateChecklist(params1)
    //     .then((res) => {
    //       // notification.open({
    //       //   message: 'Successfully deactivated! Loading updated data...',
    //       // });
    //       setTimeout(() => {
    //         updateIngredientState(true);
    //       }, 1000);
    //     })
    //     .catch((err) => console.log(err));
    // }
  };

  const toggleCompleted = (obj) => {
    let newValue = obj.completed === 1 ? 2 : 1;
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: newValue,
      ordered: obj.ordered,
      setDate: obj.setDate,
      setTime: obj.setTime,
      setBy: obj.setBy,
      quantity: obj.ingredientQty,
      cost: 0,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (isEvent) {
      params.eventId = stationId;
    } else {
      params.stationId = stationId;
    }
    updateStationIngredient(params, obj, [{ key: 'completed', value: newValue }]);
    // if (obj.completed == 1) {
    //   let prepIng = {
    //     healthCheck: obj.healthCheck,
    //     completed: 2,
    //     ordered: obj.ordered,
    //     setDate: obj.setDate,
    //     setTime: obj.setTime,
    //     setBy: obj.setBy,
    //     quantity: obj.quantity,
    //     cost: obj.cost,
    //     order: obj.order,
    //     originalQty: obj.originalQty,
    //   };
    //   let params = {
    //     menuId: obj.menuId,
    //     recipeId: obj.recipeId,
    //     ingredientId: obj.ingredientId,
    //     preparationId: obj.preparationId,
    //     measurementId: obj.measurementId,
    //     info: prepIng,
    //   };
    //   if (isEvent) {
    //     params.eventId = obj.eventId;
    //   } else {
    //     params.stationId = obj.stationId;
    //   }

    //   let clonedStationShownIngs = cloneDeep(showRec);
    //   const ingredientIndex = findIndex(
    //     clonedStationShownIngs.ingredients,
    //     (rec) =>
    //       rec.ingredientId === obj.ingredientId &&
    //       rec.measurementId === obj.measurementId &&
    //       rec.preparationId === obj.preparationId &&
    //       rec.stationId === obj.stationId &&
    //       rec.recipeId === obj.recipeId
    //   );
    //   clonedStationShownIngs.ingredients[ingredientIndex].completed = 2;

    //   setShowRec(clonedStationShownIngs);

    //   updateChecklist(params)
    //     .then((res) => {
    //       // notification.open({
    //       //   message: 'Successfully activated! Loading updated data...',
    //       // });
    //       setTimeout(() => {
    //         updateIngredientState(true);
    //       }, 1000);
    //     })
    //     .catch((err) => console.log(err));
    // } else {
    //   let prepIng1 = {
    //     healthCheck: obj.healthCheck,
    //     completed: 1,
    //     ordered: obj.ordered,
    //     setDate: obj.setDate,
    //     setTime: obj.setTime,
    //     setBy: obj.setBy,
    //     quantity: obj.quantity,
    //     cost: obj.cost,
    //     order: obj.order,
    //     originalQty: obj.originalQty,
    //   };
    //   let params1 = {
    //     menuId: obj.menuId,
    //     recipeId: obj.recipeId,
    //     ingredientId: obj.ingredientId,
    //     preparationId: obj.preparationId,
    //     measurementId: obj.measurementId,
    //     info: prepIng1,
    //   };
    //   if (isEvent) {
    //     params1.eventId = obj.eventId;
    //   } else {
    //     params1.stationId = obj.stationId;
    //   }

    //   let clonedStationShownIngs = cloneDeep(showRec);
    //   const ingredientIndex = findIndex(
    //     clonedStationShownIngs.ingredients,
    //     (rec) =>
    //       rec.ingredientId === obj.ingredientId &&
    //       rec.measurementId === obj.measurementId &&
    //       rec.preparationId === obj.preparationId &&
    //       rec.stationId === obj.stationId &&
    //       rec.recipeId === obj.recipeId
    //   );
    //   clonedStationShownIngs.ingredients[ingredientIndex].completed = 1;

    //   setShowRec(clonedStationShownIngs);

    //   updateChecklist(params1)
    //     .then((res) => {
    //       // notification.open({
    //       //   message: 'Successfully deactivated! Loading updated data...',
    //       // });
    //       setTimeout(() => {
    //         updateIngredientState(true);
    //       }, 1000);
    //     })
    //     .catch((err) => console.log(err));
    // }
  };

  const updateIngredientOrderStatus = (obj, newStatus) => {
    // UPDATE STATION CHECKLIST
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: obj.completed,
      ordered: newStatus.toString(),
      setDate: obj.setDate,
      setTime: obj.setTime,
      setBy: obj.setBy,
      quantity: obj.ingredientQty,
      cost: 0, // this is temporary, API will be updated to accomodate this field,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };

    let toChecklistParams = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (isEvent) {
      toChecklistParams.eventId = stationId;
    } else {
      toChecklistParams.stationId = stationId;
    }

    updateStationIngredient(toChecklistParams, obj, [{ key: 'ordered', value: newStatus }]);
    // UPDATE STATION CHECKLIST
    // let prepIng = {
    //   healthCheck: obj.healthCheck,
    //   completed: obj.completed,
    //   ordered: newStatus.toString(),
    //   setDate: obj.setDate,
    //   setTime: obj.setTime,
    //   setBy: obj.setBy,
    //   quantity: obj.quantity,
    //   cost: obj.cost,
    //   order: obj.order,
    //   originalQty: obj.originalQty,
    // };

    // let toChecklistParams = {
    //   menuId: obj.menuId,
    //   recipeId: obj.recipeId,
    //   ingredientId: obj.ingredientId,
    //   preparationId: obj.preparationId,
    //   measurementId: obj.measurementId,
    //   info: prepIng,
    // };

    // if (isEvent) {
    //   toChecklistParams.eventId = obj.eventId;
    // } else {
    //   toChecklistParams.stationId = obj.stationId;
    // }

    // let clonedStationShownIngs = cloneDeep(showRec);
    // const ingredientIndex = findIndex(
    //   clonedStationShownIngs.ingredients,
    //   (rec) =>
    //     rec.ingredientId === obj.ingredientId &&
    //     rec.measurementId === obj.measurementId &&
    //     rec.preparationId === obj.preparationId &&
    //     rec.stationId === obj.stationId &&
    //     rec.recipeId === obj.recipeId
    // );
    // // if (clonedStationShownIngs[ingredientIndex]) { // BUG 1201998443633183
    // clonedStationShownIngs.ingredients[ingredientIndex].ordered = newStatus;
    // clonedStationShownIngs.ingredients[ingredientIndex].shoplistId = shopId;
    // setShowRec(clonedStationShownIngs); // BUG 1201998443633183
    // // }

    // updateChecklist(toChecklistParams)
    //   .then((res) => {
    //     // notification.open({
    //     // 	message: customMessage,
    //     // });

    //     setTimeout(() => {
    //       updateIngredientState(true);
    //     }, 1000);
    //   })
    //   .catch((err) => console.log(err));
  };

  const toggleOrdered = (obj) => {
    let newStatus; // remove unused declared variable 'customMessage' BUG 1202030604174525

    // from default/nonexistent in shopping list to "needed"
    if (obj.ordered == -1) {
      // customMessage = 'Successfully added to shopping list!'; // BUG 1202030604174525
      newStatus = 0;
      // from "needed" to "ordered" status in shopping list
    } else if (obj.ordered == 0) {
      // customMessage = "Successfully changed status to 'ordered'"; // BUG 1202030604174525
      newStatus = 1;
      // from "ordered" back to "needed" status in shopping list
    } else if (obj.ordered == 1) {
      // customMessage = "Successfully reverted status to 'needed'"; // BUG 1202030604174525
      newStatus = -1; // BUG 1201998443633183
    }

    // ADD / UPDATE SHOPPING LIST

    // if ingredient is not in shopping list, ADD
    if (obj.ordered == -1) {
      const currentDate = new Date() * 1;
      let toShoplistParams = {
        operatorId: (JSON.parse(localStorage.getItem('operator')) || {}).id,
        stationId: isEvent ? null : stationId,
        eventId: isEvent ? stationId : null,
        ingredientId: obj.ingredientId,
        ingredientCategoryId: obj.categoryId,
        restaurantId: restaurantId,
        ordered: 0,
        dateTime: currentDate,
      };

      addItemToShop(toShoplistParams)
        .then((res) => {
          if (res?.results?.id) {
            console.log('ADDED ITEM TO SHOPPING LIST', res);
            obj.shoplistId = res.results.id;
            updateIngredientOrderStatus(obj, newStatus);
          } else {
            return;
          }
        })
        .catch((err) => console.log(err));

      // else, update the status
    } else {
      const currentDate = new Date() * 1;
      let changeStatParams = {
        operatorId: (JSON.parse(localStorage.getItem('operator')) || {}).id,
        dateUpdated: currentDate.toString(),
      };

      if (newStatus > 0) {
        itemOrdered(obj.shoplistId.toString(), changeStatParams)
          .then((res) => {
            console.log('UPDATED ITEM STATUS TO ORDERED >>>>>>', obj.shoplistId);
            if (res.id === obj.shoplistId) {
              updateIngredientOrderStatus(obj, newStatus);
            }
          })
          .catch((err) => console.log(err));
      } else {
        deleteItem(obj.shoplistId.toString())
          .then((res) => {
            console.log('DELETED ORDERED ITEM >>>>>>>>', obj.shoplistId);
            updateIngredientOrderStatus(obj, newStatus);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const clearStation = () => {
    let stationIngredients = cloneDeep(pristineIngs);
    const fetchData = () => {
      const allRequests = stationIngredients.map((obj) => {
        updateChecklist({
          menuId: obj.menuId,
          recipeId: obj.recipeId,
          ingredientId: obj.ingredientId,
          preparationId: obj.preparationId,
          measurementId: obj.measurementId,
          stationId: isEvent ? null : stationId,
          eventId: isEvent ? stationId : null,
          info: {
            healthCheck: 1,
            completed: 1,
            ordered: parseInt(obj?.ordered) != null ? obj.ordered : -1,
            setDate: obj?.setDate ? obj.setDate : 'unset',
            setTime: obj?.setTime ? obj.setTime : 'unset',
            setBy: obj?.setBy ? obj.setBy : 'unset',
            cost: 0,
            originalQty: obj?.originalQty ? obj.originalQty : 0,
            quantity: obj?.quantity ? obj.quantity : 0,
            order: obj.order,
          },
        }).then((response) => response);
      });

      return Promise.all([...allRequests]);
    };
    fetchData().then((res) => {
      let updatedPristineIngs = cloneDeep(pristineIngs).map((item) => {
        item.healthCheck = 1;
        item.completed = 1;
        item.ordered = parseInt(item?.ordered) != null ? item.ordered : -1;
        item.shoplistId = parseInt(item?.shoplistId) != null ? item.shoplistId : 0;
        item.setDate = item?.setDate ? item.setDate : 'unset';
        item.day = item?.setDate && item.setDate !== 'unset' ? moment(item.setDate).format('dddd') : 'Invalid date';
        item.setTime = item?.setTime ? item.setTime : 'unset';
        item.setBy = item?.setBy ? item.setBy : 'unset';
        return item;
      });
      setPristineIngs(updatedPristineIngs);
      setTimeout(() => {
        updateIngredientState(true);
      }, 1000);
    });
    // removes station Items in Shopping List
    // removeFromShop(stationType, stationId).then(() => {
    //   notification.open({
    //     message: 'Removed items in shopping list!',
    //   });
    //   setTimeout(() => {
    //     updateIngredientsState(true);
    //   }, 1000);
    // });
  };

  const allIngredientFilter = () => {
    // updateIngredientState(false);
    showAllIngredients();
    setMenuName('All ingredients');
  };

  const handleSetCurrentChecklist = useCallback((data) => {
    setCurrentChecklist(data);
  });

  const menu = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <span onClick={allIngredientFilter}>All ingredients</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterHealthComplete}>Quality check incomplete</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterProductionReady1}>Production ready</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterComplete}>Production completed</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterShop}>Shopping list</span>
      </Menu.Item>
    </Menu>
  );

  const menu1 = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <span onClick={() => window.location.reload()}>All items</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterIncomplete}>Items incomplete</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterComplete1}>Items completed</span>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Sorted by name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div className="station-name-key-mobile">
            <RoleAccessibleComponent
              permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
            >
              <div>
                {obj.day && obj.day !== 'Invalid date' ? (
                  <div
                    onClick={(e) =>
                      userRole == 'Cook' || userRole == 'Trainer / Manager' || userRole == 'Front House'
                        ? ''
                        : unsetDay(obj)
                    }
                  >
                    {obj.day == 'Friday' && <div className="station-set-days set-day-fri set-day-orient">FRI</div>}
                    {obj.day == 'Thursday' && <div className="station-set-days set-day-thu set-day-orient">THU</div>}
                    {obj.day == 'Tuesday' && <div className="station-set-days set-day-tue set-day-orient">TUE</div>}
                    {obj.day == 'Wednesday' && <div className="station-set-days set-day-wed set-day-orient">WED</div>}
                    {obj.day == 'Monday' && <div className="station-set-days set-day-mon set-day-orient">MON</div>}
                    {obj.day == 'Sunday' && <div className="station-set-days set-day-sun set-day-orient">SUN</div>}
                    {obj.day == 'Saturday' && <div className="station-set-days set-day-sat set-day-orient">SAT</div>}
                  </div>
                ) : (
                  <div
                    className="station-set-days set-day set-day-orient"
                    onClick={(e) =>
                      userRole == 'Trainer / Manager' || userRole == 'Front House' ? '' : setActiveDay(obj)
                    }
                  >
                    SET
                  </div>
                )}
                <Popover
                  placement="right"
                  content={
                    <div className="day-infoPop">
                      {obj.day && obj.day !== 'Invalid date' ? (
                        <div>
                          <div style={{ marginBottom: 3 }}>
                            <b>Recorded by</b>
                          </div>
                          <p>{obj.setBy}</p>
                          <div style={{ marginBottom: 3 }}>
                            <b>Date</b>
                          </div>
                          <p>{obj.setDate}</p>
                          <div style={{ marginBottom: 3 }}>
                            <b>Time</b>
                          </div>
                          <p>{obj.setTime}</p>
                        </div>
                      ) : (
                        <div>This will display the name, date and time of when the day dot is set</div>
                      )}
                    </div>
                  }
                >
                  <div className="station-info-image-container-mobile">
                    <img className="station-info-button-mobile" src={info} alt="info" />
                  </div>
                </Popover>
              </div>
            </RoleAccessibleComponent>
            <ThumbnailImage
              className="station-ingredient-image-container-mobile"
              src={obj?.ingredientImage ? obj.ingredientImage : <ChefIcon />}
            />
            <div className="station-ingredient-action-container-mobile">
              <span className="station-healthcheck-icon-mobile" alt="healthcheck" onClick={(e) => toggleHealth(obj)}>
                <HealthcheckIcon completed={obj.healthCheck === 2} dimmed={false} />
              </span>
            </div>
            <div className="station-ingredient-action-container-mobile">
              {/* <img
                alt="completed"
                className="station-action-button-mobile"
                onClick={(e) => toggleCompleted(e, obj)}
                src={obj.completed === 2 ? completed3 : obj.healthCheck === 2 ? completed2 : completed1}
              /> */}
              {obj.completed === 2 && (
                <span className="station-checkmark-icon-mobile" onClick={(e) => toggleCompleted(obj)}>
                  <CompletionIcon completed={true} dimmed={false} />
                </span>
              )}
              {obj.healthCheck === 2 && obj.completed !== 2 && (
                <span className="station-checkmark-icon-mobile" onClick={(e) => toggleCompleted(obj)}>
                  <CompletionIcon completed={false} dimmed={false} />
                </span>
              )}
              {obj.healthCheck !== 2 && obj.completed !== 2 && (
                <span className="station-checkmark-icon-mobile" onClick={(e) => toggleCompleted(obj)}>
                  <CompletionIcon completed={false} dimmed={true} />
                </span>
              )}
            </div>
            <RoleAccessibleComponent
              permittedRoles={[
                'Chef',
                'Admin',
                'Superadmin',
                'Client Admin',
                'Dev Team',
                'Account Holder',
                'Billing',
                'Buyer',
              ]}
            >
              <div className="station-ingredient-action-container-mobile">
                <span
                  className="station-shoppingcart-icon-mobile"
                  alt="ordered-status"
                  onClick={(e) => toggleOrdered(obj)}
                >
                  <ShoppingCartIcon needed={obj.ordered === 0} ordered={obj.ordered === 1} dimmed={false} />
                </span>
              </div>
            </RoleAccessibleComponent>
          </div>
          {visible ? (
            <div>
              <div className="chk-mobile-details">
                <div className="station-ingredient-title">{key}</div>
                <div className="station-ingredient-measure">{`${obj.formattedQty} ${
                  obj.measurementName !== 'nomeas' ? obj.measurementName : ''
                }`}</div>
                {obj.preparationName ? <div className="station-ingredient-preparation">{obj.preparationName}</div> : ''}
              </div>
            </div>
          ) : null}
        </span>
      ),
    },
  ];

  const columnChecklist = [
    {
      title: 'Sorted by name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {obj.type == 'child' && (
            <div className="station-name-key-mobile">
              <div className="station-checkmark-image-container-mobile">
                <span className="station-checkmark-icon-mobile" onClick={() => setCompleteTask(obj, true)}>
                  <CompletionIcon completed={obj.complete == 2} dimmed={false} />
                </span>
              </div>

              {/* {assignedToMe == 2 && (
                <div className="station-checkmark-image-container-mobile">
                  {' '}
                  <img
                    alt="completed"
                    className="station-action-button-mobile"
                    onClick={() => setCompleteTask(obj, true)}
                    src={obj.complete == 2 ? completed : unselected2}
                  />
                </div>
              )}
              {assignedToMe == 1 && (
                <div className="station-checkmark-image-container-mobile">
                  {' '}
                  <img
                    alt="completed"
                    className="station-action-button-mobile"
                    src={obj.complete == 2 ? unselected1 : incomplete}
                    style={{ cursor: 'not-allowed' }}
                  />
                </div>
              )} */}
              <div className="station-day-ing"></div>
              <div className="station-ingredient-image-container-mobile"></div>
              <div className="station-ingredient-title" style={{ marginLeft: 10 }}>
                {key}
              </div>
              {/* <span>
                {obj.type == 'child' && obj.texts > 0 ? <span className="texts">{obj.texts}</span> : ''}
                {obj.type == 'child' && obj.texts > 0 ? (
                  <span className="texts-icon">
                    {' '}
                    <img src={comment} className="comment" alt="comment" />
                  </span>
                ) : (
                  ''
                )}
                {obj.type == 'child' && obj.attachments > 0 ? (
                  <span className={obj.texts > 0 ? 'attachments' : 'attachments1'}>{obj.attachments}</span>
                ) : (
                  ''
                )}
                {obj.type == 'child' && obj.attachments > 0 ? (
                  <span className={'texts-icon'}>
                    {' '}
                    <img src={attach} className="comment" alt="attach" />
                  </span>
                ) : (
                  ''
                )}
              </span> */}
            </div>
          )}
          {obj.type == 'parent' && (
            <span className="row-parent-title">
              {key} <div className="station-ing-totals">( {obj.children.length} items )</div>
            </span>
          )}
          <Row gutter={32} className="checklist-item-row">
            <Col span={11}>
              <span style={{ marginLeft: 15 }}>Assignee</span>
            </Col>
            <Col span={20} style={{ width: 'auto' }}>
              <span style={{ marginLeft: 11 }}>{obj.assignee}</span>
            </Col>
          </Row>
          <Row gutter={32} className="checklist-item-row">
            <Col span={11}>
              <span style={{ marginLeft: 15 }}>Due date</span>
            </Col>
            <Col span={20} style={{ width: 'auto' }}>
              <span style={{ marginLeft: 11 }}>
                {obj.dueDate && !obj.dueDate.includes('1970')
                  ? moment.utc(obj.dueDate).format('MM/DD/YYYY')
                  : 'No date'}
              </span>
            </Col>
          </Row>
          <Row gutter={32} className="checklist-item-row">
            <Col span={10}>
              <span style={{ marginLeft: 15 }}>Project</span>
            </Col>
            <Col span={20} style={{ width: 'auto' }}>
              <span style={{ marginLeft: 20 }}>{obj.project}</span>
            </Col>
          </Row>
          {/* <Row gutter={32} className="checklist-item-row">
            <Col span={10}>
              <span style={{ marginLeft: 15 }}>Tags</span>
            </Col>
            <Col span={20} style={{ width: 'auto' }}>
              <span style={{ marginLeft: 20 }}>
                {' '}
                <div className="checklist-taggy" style={{ marginLeft: 12, marginTop: '-20px' }}>
                  {obj.type == 'child' && obj.tags
                    ? obj.tags.map((tag) => {
                        let color = 'black';
                        return (
                          <Tag color={color} key={tag}>
                            {tag}
                          </Tag>
                        );
                      })
                    : ''}{' '}
                </div>
              </span>
            </Col>
          </Row> */}
          <Row gutter={32} className="checklist-item-row">
            <Col span={10}>
              <span style={{ marginLeft: 15 }}>Priority</span>
            </Col>
            <Col span={20} style={{ width: 'auto' }}>
              <span style={{ marginLeft: 14 }}>
                {' '}
                {obj.priority == 'High' && <span className="priorities high">{obj.priority}</span>}
                {obj.priority == 'Medium' && <span className="priorities med">{obj.priority}</span>}
                {obj.priority == 'Low' && <span className="priorities low">{obj.priority}</span>}
              </span>
            </Col>
          </Row>
        </span>
      ),
    },
  ];

  const setCompleteTask = (obj, isComplete) => {
    const id = obj.key;
    const clonedChecklist = cloneDeep(selectedList);
    const foundIndex = findIndex(clonedChecklist, { key: obj.parentId });
    const foundChildIndex = findIndex(clonedChecklist[foundIndex]?.children, { key: obj.key });
    const checkItem = clonedChecklist[foundIndex]?.children[foundChildIndex];

    const iteminfo = {
      checked: !checkItem.checked,
      complete: checkItem.complete !== 2 ? 2 : 1,
      description: checkItem.description,
      details: checkItem.details,
      tags: checkItem.tags,
    };
    updateItem(id, { info: iteminfo }).then((res) => {
      localStorage.removeItem('openRecipePanelStations');
      // notification.open({
      //   message: 'Successfully updated task! Loading updated data...',
      // });
      if (setHasUpdate) {
        setHasUpdate(true);
        eventSocket.emit(
          'updateStationTask',
          JSON.stringify({
            id: id,
          })
        );
      } else {
        setTimeout(() => {
          updateTaskState({ type: 'filter', id: id });
        }, 1000);
      }
    });
  };

  const handleRowExpand = (record) => {
    if (expandedRows.includes(record.key)) {
      let filterRecord = expandedRows.filter((key) => key !== record.key);
      setExpandedRows(filterRecord);
    } else {
      setExpandedRows([...expandedRows, record.key]);
    }
  };

  const windowSize = useWindowSize();

  const onChange = (checked) => {
    if (checked) {
      updateVisible(false);
      localStorage.setItem('mobileCondensedView', true);
    } else {
      updateVisible(true);
      localStorage.removeItem('mobileCondensedView');
    }
  };

  useEffect(() => {
    let condensed_view = localStorage.getItem('mobileCondensedView');
    if (condensed_view) {
      updateVisible(false);
    }
  }, []);

  const onClose = () => {
    updateShowFilteredDrawer(false);
  };

  const updateFilter = (data) => {
    console.log('TO UPDATE FILTER SETTINGS');
    updateShowFilteredDrawer(false);
    showFilter(data);
  };

  const selectRecFilter = () => {
    console.log('TO OPEN DRAWER');
    updateShowFilteredDrawer(true);
    showFilter(true);
  };

  const selectChkFilter = () => {
    updateShowFilteredDrawer(true);
    showFilter(true);
  };

  const setActiveDay = (obj) => {
    console.log('CLICKED', obj);
    let today = moment().format('YYYY-MM-DD');
    let todayTime = moment().format('LT');
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: obj.completed,
      ordered: obj.ordered,
      setDate: today,
      setTime: todayTime,
      setBy: userInfo.firstName + ' ' + userInfo.lastName,
      quantity: obj.ingredientQty,
      cost: 0,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (isEvent) {
      params.eventId = stationId;
    } else {
      params.stationId = stationId;
    }

    let clonedStationShownIngs = cloneDeep(tableData);
    const ingredientIndex = findIndex(
      clonedStationShownIngs.ingredients,
      (rec) =>
        rec.ingredientId === obj.ingredientId &&
        rec.measurementId === obj.measurementId &&
        rec.preparationId === obj.preparationId &&
        rec.stationId === obj.stationId &&
        rec.recipeId === obj.recipeId
    );
    clonedStationShownIngs.ingredients[ingredientIndex].day = moment(today).format('dddd');
    clonedStationShownIngs.ingredients[ingredientIndex].setBy = userInfo.firstName + ' ' + userInfo.lastName;
    clonedStationShownIngs.ingredients[ingredientIndex].setDate = today;
    clonedStationShownIngs.ingredients[ingredientIndex].setTime = todayTime;

    updateStationIngredient(params, obj, [
      { key: 'day', value: moment(today).format('dddd') },
      { key: 'setDate', value: today },
      { key: 'setTime', value: todayTime },
      { key: 'setBy', value: `${userInfo.firstName} ${userInfo.lastName}` },
    ]);

    // let clonedStationShownIngs = cloneDeep(showRec);
    // const ingredientIndex = findIndex(
    //   clonedStationShownIngs.ingredients,
    //   (rec) =>
    //     rec.ingredientId === obj.ingredientId &&
    //     rec.measurementId === obj.measurementId &&
    //     rec.preparationId === obj.preparationId &&
    //     rec.stationId === obj.stationId &&
    //     rec.recipeId === obj.recipeId
    // );
    // clonedStationShownIngs.ingredients[ingredientIndex].day = moment(today).format('dddd');

    // setShowRec(clonedStationShownIngs);
    // updateChecklist(params)
    //   .then((res) => {
    //     // notification.open({
    //     //   message: 'Successfully set day! Loading updated data...',
    //     // });
    //     setTimeout(() => {
    //       updateIngredientState(true);
    //     }, 1000);
    //   })
    //   .catch((err) => console.log(err));
  };

  const unsetDay = (obj) => {
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: obj.completed,
      ordered: obj.ordered,
      setDate: '',
      setTime: '',
      setBy: '',
      quantity: obj.ingredientQty,
      cost: 0,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (isEvent) {
      params.eventId = stationId;
    }

    if (!isEvent) {
      params.stationId = stationId;
    }

    updateStationIngredient(params, obj, [
      { key: 'day', value: 'Invalid date' },
      { key: 'setDate', value: '' },
      { key: 'setTime', value: '' },
      { key: 'setBy', value: '' },
    ]);
    // let today = moment().format('YYYY-MM-DD');
    // let prepIng = {
    //   healthCheck: obj.healthCheck,
    //   completed: obj.completed,
    //   ordered: obj.ordered,
    //   setDate: '',
    //   setTime: '',
    //   setBy: '',
    //   quantity: obj.quantity,
    //   cost: obj.cost,
    //   order: obj.order,
    //   originalQty: obj.originalQty,
    // };
    // let params = {
    //   menuId: obj.menuId,
    //   recipeId: obj.recipeId,
    //   ingredientId: obj.ingredientId,
    //   preparationId: obj.preparationId,
    //   measurementId: obj.measurementId,
    //   stationId: obj.stationId,
    //   info: prepIng,
    // };

    // let clonedStationShownIngs = cloneDeep(showRec);
    // const ingredientIndex = findIndex(
    //   clonedStationShownIngs.ingredients,
    //   (rec) =>
    //     rec.ingredientId === obj.ingredientId &&
    //     rec.measurementId === obj.measurementId &&
    //     rec.preparationId === obj.preparationId &&
    //     rec.stationId === obj.stationId &&
    //     rec.recipeId === obj.recipeId
    // );
    // clonedStationShownIngs.ingredients[ingredientIndex].day = '';

    // setShowRec(clonedStationShownIngs);

    // updateChecklist(params)
    //   .then((res) => {
    //     // notification.open({
    //     //   message: 'Successfully unset day! Loading updated data...',
    //     // });
    //     setTimeout(() => {
    //       updateIngredientState(true);
    //     }, 1000);
    //   })
    //   .catch((err) => console.log(err));
  };

  const handleShowRecipeLists = (bool) => {
    setShowRecipeLists(bool);
  };

  const handleShowChecklists = (bool) => {
    setShowChecklists(bool);
  };

  return (
    <div className="station-filtered-container-mobile">
      <div className="station-filters-mobile">
        {showChecklists ? (
          <div className="station-recipe-filter-mobile" onClick={selectChkFilter}>
            <span className="station-recipe-filter-name-mobile">
              {selectedList.length > 0 ? selectedList[0].name : ''}
            </span>
            <span className="station-icon-rec">
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
          </div>
        ) : (
          <div className="station-recipe-filter-mobile" onClick={selectRecFilter}>
            <span className="station-recipe-filter-name-mobile">
              {activeRecs.length && activeRecs[0]?.recipeName !== '' ? activeRecs[0].recipeName : null}
            </span>
            <span className="station-icon-rec">
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
          </div>
        )}

        {showChecklists ? (
          <div style={{ display: 'flex' }}>
            <div className="station-all-ingMobile">
              <div>{menuName ? menuName : 'All items'}</div>
            </div>
            <Dropdown overlay={menu1}>
              <div className="station-dropdown-icon-mobile">
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
              </div>
            </Dropdown>
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <div className="station-all-ingMobile">
              <div>{menuName ? menuName : 'All ingredients'}</div>
            </div>
            <Dropdown overlay={menu}>
              <div className="station-dropdown-icon-mobile">
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
              </div>
            </Dropdown>
          </div>
        )}
      </div>

      <div className="station-controls-box-mob">
        {showChecklists ? (
          ''
        ) : (
          <RoleAccessibleComponent
            permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
          >
            <div className="station-clear-mobile" onClick={clearStation}>
              Clear Station
            </div>
          </RoleAccessibleComponent>
        )}
        {showChecklists ? (
          ''
        ) : (
          <div className="station-condensed">
            <span className="station-condensedText">Condensed view </span>{' '}
            <Switch checked={!visible} onChange={onChange} />
          </div>
        )}
      </div>

      <div className="station-ing-mob-filtered">
        {showChecklists ? (
          <div className="station-filtered-recipe-container-mobile">
            <ThumbnailImage
              className="station-recipe-image-container-mobile"
              type={'cover'}
              src={selectedList.length && selectedList[0]?.imageUrl ? selectedList[0]?.imageUrl : null}
              placeholderIcon={<CookbookIcon />}
            />
            <div>
              <div className="selected-recipe-title">{selectedList.length > 0 ? selectedList[0].name : ''}</div>
              <div>
                ( {selectedList.length > 0 && selectedList[0].children ? selectedList[0].children.length : '0'} Items )
              </div>
              <Link
                to={{
                  pathname: `/checklist`,
                  selectedId: currentChecklist,
                }}
              >
                <div className="selected-recipe-view"> View Checklists</div>
              </Link>
            </div>
          </div>
        ) : (
          <div className="station-filtered-recipe-container-mobile">
            <ThumbnailImage
              className="station-recipe-image-container-mobile"
              type={'cover'}
              src={activeRecs.length && activeRecs[0]?.recipeImage !== '' ? activeRecs[0].recipeImage : null}
              placeholderIcon={<CookbookIcon />}
            />
            <div>
              <div className="selected-recipe-title">{activeRecs.length && activeRecs[0]?.recipeName}</div>
              <div>( {tableData.length > 0 ? tableData.length : '0'} Ingredients )</div>
              <Link
                to={{
                  pathname: `/recipes/view/${activeRecs[0]?.recipeId}`,
                  state: { from: isEvent ? 'event' : 'station', menuid: activeRecs[0]?.menuId, stationId: stationId },
                }}
              >
                <div className="selected-recipe-view"> View Recipe</div>
              </Link>
            </div>
          </div>
        )}

        {showChecklists ? (
          <div>
            <Table columns={columnChecklist} dataSource={selectedList[0]?.children} pagination={false} />
          </div>
        ) : (
          <div>
            <Table columns={columns} dataSource={tableData} rowKey={'rowKey'} pagination={false} />
          </div>
        )}
      </div>
      <Drawer
        width={windowSize >= 842 ? '842px' : '100%'}
        closable={false}
        onClose={onClose}
        visible={showFilteredDrawerData}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <div style={{ marginRight: 20, marginTop: 20 }}>
          <Row gutter={32}>
            <Col className="gutter-row" span={21}></Col>
            <Col className="gutter-row" span={3}>
              <Button
                className="close-add-event"
                onClick={() => {
                  onClose();
                }}
                type="primary"
                shape="circle"
                size={`small`}
              >
                <CloseCircleOutlined />
              </Button>
            </Col>
          </Row>
        </div>
        <div>
          <StationChecklistsMobile
            updateFilter={updateFilter}
            updateActiveChks={updateActiveChks}
            showChecklists={showChecklists}
            handleShowChecklists={handleShowChecklists}
            handleShowRecipeLists={handleShowRecipeLists}
            selectedStationChecklists={selectedStationChecklists}
            stationId={stationId}
            hasChecklist={hasChecklist}
            showDrawer={updateShowFilteredDrawer}
            handleSetCurrentChecklist={handleSetCurrentChecklist}
          />
        </div>
        <div>
          <StationRecipesMobile
            updateFilter={updateFilter}
            updateActiveRecs={updateActiveRecs}
            recList={recList}
            showRecipeLists={showRecipeLists}
            handleShowChecklists={handleShowChecklists}
            handleShowRecipeLists={handleShowRecipeLists}
            stationId={stationId}
            pristineIngs={pristineIngs}
            filtered={filtered}
            isEvent={isEvent}
            hasRecipeLists={hasRecipeLists}
            showDrawer={updateShowFilteredDrawer}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default StationFilteredMobile;
