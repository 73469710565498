import React, { useState } from 'react';
import EditEventDrawerV2 from '../../pages/Dashboard/components/EditEventDrawerV2';
//import moment from 'moment';
import './index.css';
// import { reduce } from 'lodash';
import RoleAccessibleComponent from '../RoleAccess/roleaccess';

const CustomEvent = ({ event, setCalendarType, setDay, setHasEditedEvent }) => {
  const [showDrawer, setShowDrawer] = useState({ type: '', open: false, data: null });

  // useEffect(() => {
  //   if (showDrawer.open) {
  //     document.documentElement.style.overflow = 'hidden';
  //     document.body.scroll = 'no';
  //   } else {
  //     document.documentElement.style.overflow = 'scroll';
  //     document.body.scroll = 'yes';
  //   }
  // }, [showDrawer]);

  // const formatGetTime = (timeStamp) => {
  //   const momentize = moment.unix(parseInt(timeStamp));
  //   return momentize.format('hh:mm A');
  // };

  return (
    <div className="main">
      <div
        className="custom-event"
        onClick={() => {
          // setDay(event.start.format('dddd, MMMM D, YYYY'));
          // setCalendarType('day');
          setShowDrawer({ type: event.type, open: true, data: event });
        }}
      >
        <span
          style={{ height: 10, width: 10, backgroundColor: event.color, borderRadius: '50%', display: 'inline-block' }}
        ></span>{' '}
        <strong>{event.start.format('hh:mm A')}</strong> {event.title}
      </div>
      <RoleAccessibleComponent
        permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
      >
        {showDrawer.open && (
          <EditEventDrawerV2
            showDrawer={showDrawer.open}
            type={showDrawer.type}
            data={showDrawer.data}
            // setHasCreatedEvent={props.setHasCreatedEvent}
            setShowDrawer={setShowDrawer}
            setHasEditedEvent={setHasEditedEvent}
          />
        )}
      </RoleAccessibleComponent>
    </div>
  );
};

export default CustomEvent;
