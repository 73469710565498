/* eslint eqeqeq: "off" */
import { getEvents, getFutureEvents } from '../../services/event.service';
import { searchMenu } from '../../services/menu.service';
// import { getAllStationOperators } from '../../services/station.service';
import moment from 'moment';
import { filter } from 'lodash';
// import { faIgloo } from '@fortawesome/free-solid-svg-icons';
// import { events } from '../../data/calendarmonthevents';
// import { ConsoleSqlOutlined } from '@ant-design/icons';

export const momentize = (data) => {
  return moment(new Date(data));
};

export const compareArrayOfObjects = (prev, next) => {
  return prev.map((m) => JSON.stringify(m)).toString() === next.map((m) => JSON.stringify(m)).toString();
};

export const momentizeEvent = (eventRecord) => {
  // momentize everything and normalize the 'until' field
  eventRecord.startTimestamp = parseInt(eventRecord.startTimestamp);
  eventRecord.endTimestamp = parseInt(eventRecord.endTimestamp);
  eventRecord.eventStart = moment.unix(eventRecord.startTimestamp);
  eventRecord.eventEnd = moment.unix(eventRecord.endTimestamp);
  eventRecord.eventUntil = momentizeUntil(eventRecord.until);
};

// REFACTOR this should change so that null or 0 is 'forever'
const momentizeUntil = (timecode) => {
  // accepts unix "timecode-in-seconds" or timecode-in-seconds but converts null or '' to forever
  let maybeMoment = moment.unix(parseInt(timecode));
  return maybeMoment.isValid() ? maybeMoment : moment.unix(64060679385); // some arbitrary terribly distant date
};

export const repeatDayIsValid = (event, day) => {
  // console.log("Day is vlid:");
  // console.log(event);
  // console.log(day);

  const info = event.info;
  if (info?.repeat_type === 'everyday') {
    return true;
  } else {
    //eventStartOrigin = offset eventStartLocal to event local time
    const dayOfWeek = day.day();
    // console.log("Day:" + dayOfWeek);
    if (info?.repeat_type === 'weekdays') {
      return dayOfWeek > 0 && dayOfWeek < 6; // 6 = Saturday, 0 = Sunday
    } else if (info?.repeat_type === 'weekends') {
      return dayOfWeek === 6 || dayOfWeek === 0; // 6 = Saturday, 0 = Sunday
    } else if (info?.repeat_type === 'custom') {
      const dayToday = day.format('dddd');
      // identify if this event belongs to this day;

      // return find(info?.custom_days, (c) => {
      //   return c == dayToday;
      // });
      return info?.custom_days.includes(dayToday);
    }
  }
};

export const convertToOldEventRecord = (event) => {
  // refactor scaffold
  if (event.repeat === 'repeat') {
    const info = event.info;
    event.repeat = info?.repeat_type;
  } else {
    console.log('No conversion');
  }
  return event;
};

export const convertToNewEventRecord = (event) => {
  // refactor scaffold
  const rpt = event.repeat;
  if (rpt === 'range') {
    event.repeat = 'never';
  } else if (rpt != 'never' && rpt != 'repeat') {
    // console.log("=================================== CONVDRTED!#@");
    // console.log("event: " + event.name);
    // console.log("rpt: " + rpt);
    const info = event.info;
    info.repeat_type = rpt;
    event.repeat = 'repeat';
  }
  return event;
};

export const getEventsToday = (day, setMeetingsAndEvents, id, calendarType) => {
  let dayBegin = moment(day).startOf('day');
  let dayEnd = calendarType == 'week' ? dayBegin.clone().add(7, 'days') : dayBegin.clone().endOf('day');

  getEvents({
    restaurantId: id,
    startTimestamp: dayBegin.unix(),
    endTimestamp: dayEnd.unix(),
    calcPercentage: true,
  }).then((daily_events) => {
    if (daily_events) {
      console.log('API Day Events');
      console.log(daily_events);
      if (calendarType == 'week') {
        let getDays = daily_events?.map((d) => {
          if (d.type == 'event' && d.repeat == 'repeat') {
            d.repeatDays = d.info?.custom_days.map((c) => {
              let start = moment(day);
              let end = start.clone().add(7, 'days');
              let result = [];
              let current = start.clone();
              let days = { sunday: 0, monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6 };
              let dayList = days[c.toLowerCase()];

              if (current.day(dayList).isBefore(end)) {
                result.push(current.clone().format('MM-DD-YYYY'));
              }
              return result[0];
            });
          }
          return d;
        });
        setMeetingsAndEvents(getDays);
      } else {
        let events = [];

        for (let i = 0; i < daily_events.length; i++) {
          let tempEvent = daily_events[i]; // refactor scaffold
          const eventRecord = convertToNewEventRecord(tempEvent);
          momentizeEvent(eventRecord);

          // For events that range in span of time
          if (eventRecord.repeat === 'never') {
            if (eventRecord.eventStart.isBefore(dayEnd) && eventRecord.eventEnd.isSameOrAfter(dayBegin)) {
              let truncBegin = moment.max(eventRecord.eventStart, dayBegin);
              let truncEnd = moment.min(eventRecord.eventEnd, dayEnd);

              events.push({
                ...eventRecord,
                eventStart: truncBegin,
                eventEnd: truncEnd,
                timeSort: formatGetTime(truncBegin),
              });
            }
          } else {
            // if (eventRecord.repeat === 'repeat')
            // console.log("Repeat day is valid: " + repeatDayIsValid(eventRecord, dayBegin));
            console.log(repeatDayIsValid(eventRecord, dayBegin));
            console.log(eventRecord.eventUntil.isSameOrAfter(dayBegin));
            console.log(eventRecord.eventStart.isBefore(dayEnd));
            if (
              eventRecord.eventStart.isBefore(dayEnd) &&
              eventRecord.eventUntil.isSameOrAfter(dayBegin) &&
              repeatDayIsValid(eventRecord, dayBegin)
            ) {
              // events are allowed on final day

              let truncEnd = moment.min(eventRecord.eventEnd, dayEnd);
              events.push({
                ...eventRecord,
                eventEnd: truncEnd, // truncate end of day for now
                timeSort: formatGetTime(eventRecord.eventStart),
              });
            }
            // todo - could add the truncated fragment to tomorrow
          }
        }

        events.sort((a, b) => {
          return new Date('1970/01/01 ' + a.timeSort) - new Date('1970/01/01 ' + b.timeSort);
        });

        console.log('Final Day Events');
        console.log(events);
        setMeetingsAndEvents(events);
      }
    }
  });
};

export const getCurrentWeek = (weekStart) => {
  let days = [];

  for (let i = 0; i <= 6; i++) {
    days.push({
      date: moment(weekStart).add(i, 'days').format('D MMMM, YYYY'),
      events: [],
    });
  }
  return days;
};

export const getEventsWeek = (weekStart, setCurrentWeek, id) => {
  let weekBegin = moment(weekStart).startOf('day');
  let weekEnd = weekBegin.clone().add(7, 'days'); // really, this is the very beginning of the next week

  // weekEnd and lastDayOfWeek are technically the very beginning of the *next* week
  // but this way the weeks events are: weekBegin >= events < weekEnd

  getEvents({
    restaurantId: id,
    startTimestamp: weekBegin.unix(),
    endTimestamp: weekEnd.unix(),
    calcPercentage: true,
  }).then((weekly_events) => {
    if (weekly_events) {
      console.log('API Week Events');
      console.log(weekly_events);

      let weekData = [];

      // For each day of the week
      for (let i = 0; i < 7; i++) {
        let eventsOfTheDay = [];

        // start of the day
        let dayBegin = weekBegin.clone().add(i, 'days');
        let dayEnd = dayBegin.clone().endOf('day');
        // console.log("day start local: " + dayBegin.format('MM-DD-YYYY H:mm'));
        // console.log("day end local: " + dayEnd.format('MM-DD-YYYY H:mm'));
        // console.log("==================");

        for (let j = 0; j < weekly_events.length; j++) {
          let tempEvent = weekly_events[j];
          const eventRecord = convertToNewEventRecord(tempEvent); // refactor scaffold
          momentizeEvent(eventRecord);

          if (eventRecord.repeat === 'never') {
            // if (eventRecord.name == "Test Nonrecuring") {
            //   console.log(eventRecord.name);
            //   console.log(eventRecord);
            //   console.log("start local: " + eventRecord.eventStart.format('MM-DD-YYYY H:mm'));
            //   console.log("end local: " + eventRecord.eventEnd.format('MM-DD-YYYY H:mm'));
            //   console.log("eventStartsToday: " + eventStartsToday);
            //   console.log("eventEndsToday: " + eventEndsToday);
            //   console.log("eventSpansToday: " + eventSpansToday);
            //   console.log("eventAppearsToday: " + eventAppearsToday);
            // }

            if (eventRecord.eventStart.isBefore(dayEnd) && eventRecord.eventEnd.isSameOrAfter(dayBegin)) {
              let truncBegin = moment.max(eventRecord.eventStart, dayBegin);
              let truncEnd = moment.min(eventRecord.eventEnd, dayEnd);

              eventsOfTheDay.push({
                ...eventRecord,
                eventStart: truncBegin,
                eventEnd: truncEnd,
                timeSort: formatGetTime(truncBegin),
              });
            }
          } /* if (eventRecord.repeat === 'repeat') */ else {
            // TODO scaffolding refactor

            //console.log("Repeat day is valid: " + repeatDayIsValid(eventRecord, dayBegin));
            if (
              eventRecord.eventStart.isBefore(dayEnd) &&
              eventRecord.eventUntil.isSameOrAfter(dayBegin) &&
              repeatDayIsValid(eventRecord, dayBegin)
            ) {
              // events are allowed on final day
              let truncEnd = moment.min(eventRecord.eventEnd, dayEnd);
              //console.log("Pushing week event");
              eventsOfTheDay.push({
                ...eventRecord,
                eventEnd: truncEnd, // truncate end of day for now
                timeSort: formatGetTime(eventRecord.eventStart),
              });
            }
            // todo - could add the truncated fragment to tomorrow
          }
        }

        weekData.push({
          date: dayBegin.format('D MMMM, YYYY'),
          events: eventsOfTheDay.sort((a, b) => {
            return new Date('1970/01/01 ' + a.timeSort) - new Date('1970/01/01 ' + b.timeSort);
          }),
        });
      }

      console.log('Final Week Events');
      console.log(weekData);
      setCurrentWeek(weekData);
    }
  });
};

export const getEventsFuture = (day, setFutureEvents, id) => {
  const resultCount = 30; // results to return - this drives how we create all the future data
  const maxRepeatHorizon = 30; // we don't repeat things more than this may days into the future
  const dayBegin = moment(day).startOf('day');
  const dayEnd = dayBegin.clone().endOf('day');
  // console.log("Today end: " + dayEnd.format('MM-DD-YYYY H:mm'));

  getFutureEvents({ restaurantId: id, startTimestamp: dayEnd.unix(), limit: resultCount }).then((events) => {
    console.log('API Future Events');
    console.log(events);

    // Trim 1-time events to those that *start* in the future
    // Trim recurring events to those that *end* in the future (until)
    if (events) {
      let future_events = events.filter((r) => {
        // check for null
        r = convertToNewEventRecord(r); // TODO refactor scaffold
        momentizeEvent(r);

        switch (r.repeat) {
          case 'never':
            // console.log("Event: " + r.name);
            // console.log("Starts at: " + r.eventStart.format('MM-DD-YYYY H:mm'));
            return r.eventStart.isAfter(dayEnd); // current events should not show as future

          case 'repeat':
            // console.log("Event: " + r.name);
            // console.log("Until: " + r.eventUntil.format('MM-DD-YYYY H:mm'));
            r.eventOffset = moment.duration(r.eventStart.format('HH:mm')); // this is the local time of day at which the event occurs
            return r.eventUntil.isAfter(dayEnd);

          default:
            // console.log("Lost: " + r.name);
            // console.log("repeat: " + r.repeat);
            break;
        }

        return false;
      });

      let expandedEvents = future_events.filter((r) => {
        // add the non-repeaters directly to the results
        return r.repeat == 'never';
      });

      // loop through repeating events and expand them
      // this is done day-by-day to ensure we stop when when we have enough results
      for (let i = 0; i < maxRepeatHorizon; i++) {
        // start of the day
        dayBegin.add(1, 'days');
        dayEnd.add(1, 'days');
        // console.log("Expansions for: " + dayBegin.format('MM-DD-YYYY H:mm'));

        future_events.forEach((eventRecord) => {
          //console.log("Future Event: " + eventRecord.name);
          //console.log(event);

          if (eventRecord.repeat === 'repeat') {
            if (
              eventRecord.eventStart.isBefore(dayEnd) &&
              eventRecord.eventUntil.isSameOrAfter(dayBegin) &&
              repeatDayIsValid(eventRecord, dayBegin)
            ) {
              //event has begun and event is not over and today is a valid day) {
              // console.log("Expanding: " + eventRecord.name);
              // console.log("eventStart: " + eventRecord.eventStart.format('MM-DD-YYYY H:mm'));
              // console.log("eventUntil: " + eventRecord.eventUntil.format('MM-DD-YYYY H:mm'));
              // console.log(eventRecord);

              // expansion event is copy of event adjusted to the appropriate time of that day
              let expandedEventStart = dayBegin.clone().add(eventRecord.eventOffset);

              expandedEvents.push({
                ...eventRecord,
                eventStart: expandedEventStart,
                // startTimestamp: expandedEventStart.unix()
              });
            }
          }
        });
      }

      expandedEvents.sort((a, b) => {
        return a.eventStart.diff(b.eventStart);
      });

      console.log('Final Future Events');
      console.log(expandedEvents);
      setFutureEvents(expandedEvents);
    }
  });
};

export const getMenuListForEvents = (setMenuList) => {
  const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  searchMenu({ restaurantId: resto_id, filterActive: true }).then((res) => {
    setMenuList(res?.menus);
  });
};

// NEW -- Accepts moments, later will only accept momemnts refactor
export const formatGetTime = (timeStamp) => {
  const momentize = moment.isMoment(timeStamp) ? timeStamp : moment.unix(parseInt(timeStamp));
  return momentize.format('hh:mm A');
};

export const getEventParticipants = (locationOperators, setOperators, participants = []) => {
  // CHANGE 1202077200318274
  let operators = locationOperators;
  if (participants?.length > 0) {
    const existingParticipants = participants.map((p) => (p?.id ? p.id : p.operatorId.toString()));
    operators = filter(operators, (op) => !existingParticipants.includes(op.id));
  }
  setOperators(operators);
};

export const hexToRgbA = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.2)';
  }
  throw new Error('Bad Hex');
};
