import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './index.css';
import add from '../../img/calcPlus.png';
import less from '../../img/calcMinus.png';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
// import IngredientsList from '../../components/IngredientsList/IngredientsList';
import InstructionsList from '../../components/InstructionsList/InstructionsList';
import AddIngredientPopup from './AddIngredientPopup';
import AddEquipment from './AddEquipment';
// import AddInstructions from './AddInstructions';
import IngredientTable from './IngredientTable';
import EquipmentTable from './EquipmentTable';
import AddFile from './AddFile';
import { Form, Tag, notification, Radio, Slider, InputNumber, Dropdown, Menu, Modal } from 'antd';
import {
  create,
  update,
  assignDetail,
  assignEquipment,
  assignAllDetails,
  assignAllEquipments,
} from '../../services/recipe.service';
import { createStationChecklist, createStationChecklists } from '../../services/station.service';
import { assignRecipe } from '../../services/course.service';
import { cloneDeep } from 'lodash';
import useWindowSize from '../../components/windowdimensions/windowSize';
import { searchmeas } from '../../services/measurement.service';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { BacktoHeader } from '../../components/Header/Header.js';
import { BacktoHeaderMobile } from '../../components/Header/HeadMobile.js';
import Measurement from './measurements.js';
import { ridAnnoyingZero, formatIfNeeded, roundOff, arrangeDBMeasurementUnits } from './utilities';

import InputSelectCombo from '../../components/Recipe/InputSelectCombo';
import InputSelectCombo2 from '../../components/Recipe/InputSelectCombo2';
import InputSelectCombo3 from '../../components/Recipe/InputSelectCombo3';
import { fc } from 'fraction-calculator';
import { UserImage } from '../../components/common/common_ui';
import { update as updateEquip } from '../../services/equipment.service';

const CreateRecipes = (props) => {
  const originPage = props.location.createProps;
  const history = useHistory();
  const windowSize = useWindowSize();
  const [menuId, setMenuId] = useState(0);
  const [isEditing, setIsEditing] = useState(true);
  const [addIngredientsList, setAddIngredientsList] = useState([]);
  const [addEquipmentList, setAddEquipmentList] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [totalIng, setTotalIng] = useState(0);
  const [addTags, setAddTags] = useState([]);
  const [enteredText, setEnteredText] = useState('');
  const [showIns, setShowIns] = useState(false);
  const [active, setActive] = useState('portion');
  const [restaurant, setRestaurant] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const [catId, setCatId] = useState('');
  const [courseId, setCourseId] = useState('');
  const [portionValue, setPortionValue] = useState('scale');
  const [scaleInputValue, setScaleInputValue] = useState(1);
  const [qtyCombine, setQtyCombine] = useState(false);
  const [measurements, setMeasurements] = useState([]);
  const [showReset, setShowReset] = useState(false);
  const [originalIngs, setOriginalIngredients] = useState([]);

  // ANTD Form.Item replacements
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [comment, setComment] = useState('');
  // Prep Time, Cook Time
  const [showPrepTime, setShowPrepTime] = useState('0');
  const [showCookTime, setShowCookTime] = useState('0');
  const [prepTime, setPrepTime] = useState(0);
  const [cookTime, setCookTime] = useState(0);
  const [prepTimeUnit, setPrepTimeUnit] = useState('hours');
  const [cookTimeUnit, setCookTimeUnit] = useState('hours');
  // Yield, Portion, Quantity
  const [quantityVal, setQuantityVal] = useState(0);
  const [portionVal, setPortionVal] = useState(0);
  const [yieldVal, setYieldVal] = useState(0);
  const [portionDisplay, setPortionDisplay] = useState('0');
  const [yieldDisplay, setYieldDisplay] = useState('0');
  const [quantityDisplay, setQuantityDisplay] = useState('0');

  const [yieldMeasUnit, setYieldMeasUnit] = useState('');
  const [portionMeasUnit, setPortionMeasUnit] = useState('');
  const [quantityMeasUnit, setQuantityMeasUnit] = useState('');

  const [originalYieldVal, setOriginalYieldVal] = useState(0);
  // const [originalPortion, setOriginalPortionVal] = useState(0);
  const [originalYieldMeasUnit, setOriginalYieldMeasUnit] = useState('');
  const [originalPortionMeasUnit, setOriginalPortionMeasUnit] = useState('');
  const [originalQuantityMeasUnit, setOriginalQuantityMeasUnit] = useState('');
  const [originalQuantityVal, setOriginalQuantityVal] = useState(0);

  const [tempDisplayVal, setTempDisplayVal] = useState('');
  const [inputChanged, setInputChanged] = useState(false);
  const [updateEquipCat, setUpdateEquipCat] = useState([]);

  const isMobile = windowSize.width > 700 ? false : true;

  console.log('ORIGIN PAGE', originPage);

  const resto = JSON.parse(localStorage.getItem('restaurant')) || {}; // BUG 1202076501452783
  const userImage =
    JSON.parse(localStorage.getItem('operator'))?.info?.userImageThumb ||
    JSON.parse(localStorage.getItem('operator'))?.info?.userImage;
  const accountId = JSON.parse(localStorage.getItem('operator')).accountId || '';

  useEffect(() => {
    // console.log('origin page', originPage);
    if (originPage && originPage?.menuid) {
      setMenuId(parseInt(originPage.menuid));
    }
  }, [originPage]);

  useEffect(() => {
    let accountId = localStorage.getItem('operator') != undefined && 
      JSON.parse(localStorage.getItem('operator')).accountId != null ? 
      JSON.parse(localStorage.getItem('operator')).accountId : '';
    setRestaurant(resto.name);
    if (originPage && originPage.catId) {
      // debugger
      setCatId(originPage.catId.toString());
    }
    if (originPage && originPage.courseid) {
      // debugger
      setCourseId(originPage.courseid.toString());
    }

    // debugger
    let params = {
      // restaurantId: resto.id,
      accountId: accountId,
    };

    // debugger
    searchmeas(params).then((res) => {
      setMeasurements(arrangeDBMeasurementUnits(res));
    });
  }, []);

  useEffect(() => {
    console.log(addTags);
    console.log('ADDINGREDIENTS', addIngredientsList);
  }, [addTags, addIngredientsList]);

  useEffect(() => {
    console.log(imageUrls);
  }, [imageUrls]);

  useEffect(() => {
    if (portionValue !== 'scale') {
      setScaleInputValue(quantityVal);
    }
  }, [portionValue]);

  const updateIngredients = useCallback((ingredients) => {
    console.log('UPDATED INGS', ingredients);
    if (addIngredientsList.length == 0) {
      setAddIngredientsList(ingredients);
      setOriginalIngredients(ingredients);
      //setTotalIng(ingredients.length);
    } else {
      console.log(addIngredientsList);
      //BUG 1202397472239827
      // let ids = new Set(addIngredientsList.map((d) => d.id));
      // let merged = [...addIngredientsList, ...ingredients.filter((d) => !ids.has(d.id))];

      let merged = [...addIngredientsList, ...ingredients];

      console.log(merged);
      setOriginalIngredients(merged);
      setAddIngredientsList(merged);
      //setTotalIng(merged.length);
    }
  });

  const ingListUpdates = useCallback((ingredients) => {
    console.log('!!UPDATED INGREDIENTSS', ingredients);
    if (ingredients.length > 0) {
      setOriginalIngredients(ingredients);
      setAddIngredientsList(ingredients);
    }
  });

  const eqListUpdates = useCallback((equipment) => {
    console.log('!!UPDATED EQ', equipment);
    if (equipment.length > 0) {
      setAddEquipmentList(equipment);
    }
  });

  const updateEquipment = useCallback((equipment) => {
    console.log('UPDATED EQUIPMENT', equipment);
    let revisedEquip = equipment.map((i) => {
      i.quantity = i.quantity ? parseFloat(i.quantity) : 0;
      i.type = i.type ? i.type : 'type';
      i.size = i.size ? i.size : 'size';
      return i;
    });
    console.log(revisedEquip);

    setAddEquipmentList((oldArray) => [...oldArray.concat(equipment)]);
  });

  useEffect(() => {
    console.log('EXISTING EQUIP', addEquipmentList);
  }, [addEquipmentList]);

  const updateIngCount = (e) => {
    //setTotalIng(e.target.value);
  };

  const updateInstructions = useCallback((instructions) => {
    setInstructions(instructions);
    // setIsEditing(false)
  });

  const typeTag = (e) => {
    setEnteredText(e.target.value);
  };

  const addTag = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let tag = e.target.value;
      setAddTags([...addTags, tag]);
      setEnteredText('');
    }
  };

  const removeTag = (e, tag) => {
    setAddTags(addTags.filter((e) => e !== tag));
  };

  const selectPortion = (selected) => {
    console.log(selected);
    setActive(selected);
  };

  const goBack = () => {
    history.goBack();
  };

  const cancelForm = () => {
    goBack();
    // window.location.reload();
  };

  const unassignIng = useCallback((e) => {
    console.log(e);
    // let filterIng = addIngredientsList.filter((ing) => ing.id !== e.id);
    //BUG 1202397472239827
    let filterIng = addIngredientsList.filter((ing) => ing.key !== e.key);
    setOriginalIngredients(filterIng);
    setAddIngredientsList(filterIng);
  });

  const unassignEquip = useCallback((e) => {
    console.log(e);
    //let filterEq = addEquipmentList.filter((ing) => ing.id !== e.id);
    //BUG 1202397472239827
    let filterEq = addEquipmentList.filter((ing) => ing.key !== e.key);
    console.log(filterEq);
    setAddEquipmentList(filterEq);
  });

  const finalizeTimeToMinutes = (val, unit) => {
    console.log('Unit >>>>>>>', unit);
    console.log('Value >>>>>>>>', val);
    if (unit === 'hours') {
      return val * 60;
    }
    if (unit === 'minutes') {
      return val;
    }
  };

  const savingChecklists = (checklists) => {
    if (checklists.length > 1) {
      createStationChecklists(checklists)
        .then((result) => {
          console.log('CREATED NEW KITCHEN STATION CHECKLISTS');
        })
        .catch((err) => console.log(err));
    } else {
      createStationChecklist(checklists[0])
        .then((result) => {
          console.log('CREATED NEW KITCHEN STATION CHECKLISTS');
        })
        .catch((err) => console.log(err));
    }
  };

  const updateEquipCategory = useCallback((e) => {
    console.log('UPDATEEQUIPCAT!', e);
    setUpdateEquipCat((arr) => [...arr, e]);
  });

  const saveRecipe = async (e) => {
    if (scaleInputValue > 1) setScaleInputValue(1);
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    let ingInfo = addIngredientsList.map((e) => {
      delete e.key;
      delete e.info;
      if (e?.displayQty) {
        delete e.displayQty;
      }
      e.originalQty = parseFloat(e.originalQty);
      e.quantity = parseFloat(e.quantity);
      e.cost = parseFloat(e.cost);
      return e;
    });
    e.preventDefault();

    if (name == 0) {
      // notification.open({
      //   message: 'Title is required!',
      // });
      return;
    }
    const params = { description, tags: addTags, comment };
    params.name = name.trim();
    params.prepMins = finalizeTimeToMinutes(prepTime, prepTimeUnit);
    params.cookMins = finalizeTimeToMinutes(cookTime, cookTimeUnit);
    params.portion = portionVal;
    params.portionVal = portionMeasUnit;
    params.yield = yieldVal;
    params.yieldVal = yieldMeasUnit;
    params.quantity = quantityVal;
    params.qtyVal = quantityMeasUnit;
    params.instructions = instructions;
    params.ingredients = ingInfo;
    params.menuId = ['0'];
    params.recipeImages = imageUrls;
    params.recipeImages = imageUrls;
    let recipeParams = {
      restaurantId: resto.id,
      name: params.name,
      info: params,
    };
    // params.restaurantId:

    // console.log('QUANTITY', quantityVal);
    // console.log('RECIPE INGREDIENTS INFO', ingInfo);
    // console.log('HERE ARE TEH PAREMS');
    //console.log('RECIPE TO CREATE >>>>>', recipeParams);
    // console.log(addIngredientsList);
    // console.log(addEquipmentList);

    create(recipeParams)
      .then((res) => {
        if (res) {
          setTimeout(() => {
            notification['success']({
              message: 'Recipe Created',
              description: `Successfully created a new recipe! Redirecting to recipe page...`,
            });
          }, 500);

          if (addIngredientsList.length > 0) {
            let newStationChecklists = [];
            const recipeIngredients = addIngredientsList.map((ing) => {
              console.log(ing);
              let prepIng = {
                quantity: parseFloat(ing.quantity),
                originalQty: parseFloat(ing.originalQty),
                order: ing.order,
                cost: parseFloat(ing.cost),
                imageUrl: ing.imageUrl || ing.imageUrlThumb,
              };
              let params = {
                ingredientId: parseInt(ing.id),
                preparationId: parseInt(ing.preparationId),
                measurementId: parseInt(ing.measurementId),
                stationId: parseInt(ing.stationId),
                info: prepIng,
              };
              let checklistParams = cloneDeep(params);
              checklistParams.eventId = -1;
              checklistParams.recipeId = res.id;
              checklistParams.menuId = menuId ? menuId : originPage?.menuid ? originPage.menuid : 0;
              checklistParams.info = {
                ...checklistParams.info,
                setBy: '',
                ordered: -1,
                setDay: '',
                completed: 1,
                healthCheck: 1,
                order: 0,
              };

              if (ing.stationId > 0) {
                newStationChecklists.push(checklistParams);
              }

              return params;
            });

            if (newStationChecklists.length) {
              savingChecklists(newStationChecklists);
            }

            if (recipeIngredients.length > 1) {
              assignAllDetails(res.id, JSON.stringify(recipeIngredients))
                .then(() => console.log('Added ingredients'))
                .catch((err) => console.log(err));
            } else {
              assignDetail(res.id, recipeIngredients[0])
                .then(() => console.log('Added ingredient'))
                .catch((err) => console.log(err));
            }
          }

          if (updateEquipCat.length > 0) {
            const updateEquipCatg = () => {
              const allReq = updateEquipCat.map((e) => {
                let params = {
                  accountId: accountId,
                  name: e.name,
                  info: {
                    category: e.category,
                    tags: e.info?.tags,
                    imageUrl: e.info?.imageUrl,
                    id: e.info?.id,
                  },
                  restaurantId: e.Equipment?.restaurantId,
                };

                console.log(params);

                updateEquip(e.info?.id, params)
                  .then((response) => response)
                  .catch((err) => console.log(err));
              });
              return Promise.all(allReq);
            };

            updateEquipCatg().then((res) => {
              console.log(res);
            });
          }

          if (addEquipmentList.length > 0) {
            const recipeEquipments = addEquipmentList
              .map((eq) => {
                delete eq.key;
                return eq;
              })
              .map((e) => {
                console.log(e);
                let prep = {
                  quantity: e?.quantity ? parseInt(e.quantity) : 0,
                  size: 'size',
                  type: 'type',
                  imageUrl: e.info?.imageUrl || e.info?.imageUrlThumb,
                };
                let params = {
                  equipmentId: e.id,
                  info: prep,
                };

                return params;
              });

            if (recipeEquipments.length > 1) {
              assignAllEquipments(res.id, JSON.stringify(recipeEquipments))
                .then(() => console.log('Added equipments'))
                .catch((err) => console.log(err));
            } else {
              assignEquipment(res.id, recipeEquipments[0])
                .then(() => console.log('Added equipment'))
                .catch((err) => console.log(err));
            }
          }

          if ((originPage && originPage.catId) || catId) {
            update(res.id, {
              restaurantId: resto.id,
              name: params.name,
              info: params,
              categoryId: catId,
            }).then((res) => {
              setCatId('');
              setTimeout(() => {
                // notification.open({
                //   message: 'Successfully added recipe to category!',
                // });
                props.history.push(`/recipes`);
              }, 750);
            });
          } else if ((originPage && originPage.courseid) || courseId) {
            assignRecipe(courseId, res.id).then((res) => {
              setCourseId('');
              setTimeout(() => {
                // notification.open({
                //   message: 'Successfully added recipe to category!',
                // });
                let menuid = originPage && originPage?.menuid ? originPage.menuid : menuId;
                props.history.push(`/menu/${menuid}`);
              }, 750);
            });
          } else {
            setTimeout(() => {
              props.history.push(`/recipes/view/${res.id}`);
            }, 750);
          }
        } else {
          setTimeout(() => {
            notification['error']({
              message: 'Recipe Not Created',
              description: `Unable to create recipe. Please try again.`,
            });
          }, 500);
        }
      })
      .finally(() => {
        setAddIngredientsList([]);
        setAddEquipmentList([]);
      });
  };

  const handleDrop = useCallback((data) => {
    console.log('DATA', data);
    if (data !== 'none') {
      setImageUrls((oldArray) => [...oldArray.concat(data)]);
      // notification.open({
      //   message: 'Successfully Uploaded Image/s!',
      // });
    } else {
      // notification.open({
      //   message: 'Image/s not uploaded, please try again',
      // });
    }
  });

  const deleteRecImg = (e, img) => {
    let filterImgList = imageUrls.filter((image) => image !== img);
    setImageUrls(filterImgList);
  };

  const setPortions = (e) => {
    console.log('radio checked', e.target.value);
    setPortionValue(e.target.value);
  };

  const changeScaleVal = (e) => {
    setScaleInputValue(e);
  };

  const increaseVal = () => {
    let increasing = scaleInputValue + 1;
    setScaleInputValue(increasing);
  };

  const decreaseVal = () => {
    let increasing = scaleInputValue - 1;
    setScaleInputValue(increasing);
  };

  const calculateScale = (e) => {
    e.preventDefault();
    setShowReset(true);
    let scale_factor = 1;

    if (portionValue != 'scale') {
      const target_portions = scaleInputValue;
      scale_factor = quantityVal ? (1.0 * target_portions) / quantityVal : target_portions;
    } else {
      scale_factor = scaleInputValue;
    }

    setOriginalYieldVal(yieldVal);
    setOriginalQuantityVal(quantityVal);
    setOriginalQuantityMeasUnit(quantityMeasUnit);
    setOriginalYieldMeasUnit(yieldMeasUnit);

    // new values to assign
    let theYieldDisplay, theNewYield, theYieldMeasUnit;
    let theQuantityDisplay, theNewQuantity, theQuantityMeasUnit;

    const yieldRes = reformatVal(yieldVal, yieldMeasUnit, scale_factor, true);
    const qtyRes = reformatVal(quantityVal, 'Each', scale_factor, true);

    theYieldDisplay = yieldRes.reformattedVal;
    theNewYield = yieldRes.value;
    theYieldMeasUnit = yieldRes.changedUnit;

    theQuantityDisplay = qtyRes.reformattedVal;
    theNewQuantity = qtyRes.value;
    theQuantityMeasUnit = qtyRes.changedUnit;

    setYieldDisplay(scale_factor ? theYieldDisplay : '0');
    setYieldVal(scale_factor ? theNewYield : 0);
    setQuantityDisplay(scale_factor ? theQuantityDisplay : '0');
    setQuantityVal(scale_factor ? theNewQuantity : 0);
    setYieldMeasUnit(scale_factor ? theYieldMeasUnit : 'Select');
    setQuantityMeasUnit(scale_factor ? theQuantityMeasUnit : 'Select');

    const toScaleIngredients = cloneDeep(addIngredientsList);
    // console.log('INGREDIENTS TO UPDATE QUANTITY', cloneList);
    let scaledIngredients = toScaleIngredients.map((c) => {
      console.log(c);
      let ingredientQuantity = c?.originalQty ? c.originalQty : c.quantity;
      if (ingredientQuantity > 0) {
        let ingredientMeasUnit =
        c.measurementId > 0
          ? c?.measurementName
            ? c.measurementName
            : c?.measurement
            ? c.measurement
            : measurements.find((m) => m.id === c.measurementId).name
          : 'Select';
        // ingredientQuantity = ingredientQuantity > 0 ? ingredientQuantity : 1;

        const {
          reformattedVal: displayVal,
          value: actualVal,
          changedUnit: newUnit,
        } = reformatVal(ingredientQuantity, ingredientMeasUnit, scale_factor, true);
        c.quantity = actualVal;
        c.originalQty = actualVal;
        c.displayQty = displayVal;

        let newMeasurementId = '';

        if (measurements.find((item) => item.name === newUnit)) {
          newMeasurementId = measurements.find((item) => item.name === newUnit).id;
        }

        c.measurement = newUnit;
        c.measurementId = newMeasurementId ? newMeasurementId.toString() : '0';
        c.measurementName = newUnit;
      }
      setQtyCombine(true);
      setScaleInputValue(1);
      return c;
    });
    setOriginalIngredients(addIngredientsList);
    setAddIngredientsList(scaledIngredients);
  };

  const updateQtyCombine = useCallback((data) => {
    setQtyCombine(data);
  });

  const resetMultiplier = () => {
    setScaleInputValue(1);

    setYieldVal(originalYieldVal);
    setYieldDisplay(reformatVal(originalYieldVal, originalYieldMeasUnit).reformattedVal);
    setYieldMeasUnit(originalYieldMeasUnit ? originalYieldMeasUnit : 'Select');

    setQuantityVal(originalQuantityVal);
    setQuantityDisplay(reformatVal(originalQuantityVal, originalQuantityMeasUnit).reformattedVal);
    setQuantityMeasUnit(originalQuantityMeasUnit ? originalQuantityMeasUnit : 'Select');

    setAddIngredientsList(originalIngs);
    setPortionValue('scale');
    setShowReset(false);
  };

  function confirmDelete(e, image) {
    console.log(image);
    deleteRecImg(e, image);
  }

  const deletesImg = (obj) => {
    return (
      <Menu className="menu-show-preview">
        <Menu.Item className="menu-show-preview">
          <span style={{ color: 'var(--base-text)' }} onClick={(e) => confirmDelete(e, obj)}>
            Delete
          </span>
        </Menu.Item>
      </Menu>
    );
  };

  // reformat values from numeric to their desired formats based on metric
  const reformatVal = (val, measUnit, inputScale = undefined, toScale = false) => {
    if (measUnit && typeof measUnit === 'string' && measUnit !== 'Select' && toScale) {
      const { id: measure_id, equiv: equiv_amt } = measurements.find((item) => {
        return item.name === measUnit || measUnit.includes(item.name);
      });
      let reformattedVal,
        value = val,
        changedUnit = measUnit ? measUnit : 'Select';
      if (equiv_amt) {
        const showMeasure = new Measurement(val, measure_id, props.match.params.id, measurements);
        const scaledMeasure = showMeasure.scale(inputScale && toScale ? inputScale : 1).reduce();
        reformattedVal = scaledMeasure.quantity_ui_string();
        changedUnit = measurements.find((item) => scaledMeasure.measure_id === item.id).name;
        value = scaledMeasure.quantity;
      } else {
        if (inputScale != 0 && toScale) {
          value *= inputScale;
          reformattedVal = value;
        } else {
          reformattedVal = val;
        }
      }
      return {
        reformattedVal: formatIfNeeded(reformattedVal),
        value: roundOff(value),
        changedUnit,
      };
    } else {
      return {
        reformattedVal: val ? formatIfNeeded(val) : '0',
        value: val ? roundOff(val) : 0,
        changedUnit: measUnit ? measUnit : 'Select',
      };
    }
  };

  // FOCUS/BLUR FUNCTIONS for Recipe Metrics (prep time, cook time, yield, portion, quantity)
  const blurInputTime = (e, metric) => {
    switch (metric) {
      case 'preptime':
        setPrepTime(e.target.value ? parseInt(e.target.value) : 0);
        break;
      case 'cooktime':
        setCookTime(e.target.value ? parseInt(e.target.value) : 0);
        break;
      default:
    }
  };

  const recalculateInputMeasurements = (val, measUnit, setNewVal, setValDisplay, setPrevMeasUnit, setMeasUnit) => {
    if (val) {
      const exactVal = Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100;

      // const {
      //   reformattedVal: theFormattedVal,
      //   value: theActualVal,
      //   changedUnit: theUnit,
      // } = reformatVal(exactVal, measUnit, 1, unitChanged);
      setNewVal(roundOff(exactVal));
      setValDisplay(formatIfNeeded(exactVal));
      setMeasUnit(measUnit);
      setPrevMeasUnit(measUnit);
    } else {
      setNewVal(0);
      setValDisplay('0');
    }
  };

  const blurInputMeasurements = (val, setNewVal, setValDisplay, type) => {
    if (val) {
      const exactVal = Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100;
      setNewVal(exactVal);
      if (inputChanged) {
        // formats the new value
        if (type === 'yield') {
          let measurementVal = yieldVal;
          recalculateInputMeasurements(
            measurementVal,
            yieldMeasUnit,
            setYieldVal,
            setYieldDisplay,
            setOriginalYieldMeasUnit,
            setYieldMeasUnit
            // true
          );
        } else if (type === 'portion') {
          let measurementVal = portionVal;
          recalculateInputMeasurements(
            measurementVal,
            portionMeasUnit,
            setPortionVal,
            setPortionDisplay,
            setOriginalPortionMeasUnit,
            setPortionMeasUnit
            // true
          );
        } else if (type === 'quantity') {
          setNewVal(exactVal);
          setValDisplay(formatIfNeeded(exactVal));
        }
        setInputChanged(false);
      } else {
        // restores the original reformatted value
        setValDisplay(tempDisplayVal);
        setTempDisplayVal('');
      }
    } else {
      setNewVal(0);
      setValDisplay('0');
    }
  };

  // const checkIfUnitHasEquivAmt = (valUnit) => {
  //   const findUnitInfo = measurements.find((d) => d.name === valUnit);
  //   if ('equiv' in findUnitInfo) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const checkMeasurementUnitChange = (valUnit, metric) => {
    let measurementVal;
    //   toScale = false;
    // if (checkIfUnitHasEquivAmt(valUnit) && valUnit !== 'Select') {
    //   toScale = true;
    // }
    switch (metric) {
      case 'yield':
        measurementVal = yieldVal;
        recalculateInputMeasurements(
          measurementVal,
          valUnit,
          setYieldVal,
          setYieldDisplay,
          setOriginalYieldMeasUnit,
          setYieldMeasUnit
          // toScale
        );
        break;
      case 'portion':
        measurementVal = portionVal;
        recalculateInputMeasurements(
          measurementVal,
          valUnit,
          setPortionVal,
          setPortionDisplay,
          setOriginalPortionMeasUnit,
          setPortionMeasUnit
          // toScale
        );
        break;
      default:
    }
  };

  const focusInputMeasurements = (metric) => {
    if (inputChanged) setInputChanged(false);
    switch (metric) {
      case 'yield':
        setTempDisplayVal(yieldDisplay);
        setYieldDisplay(ridAnnoyingZero(yieldVal));
        break;
      case 'portion':
        setTempDisplayVal(portionDisplay);
        setPortionDisplay(ridAnnoyingZero(portionVal));
        break;
      case 'quantity':
        setTempDisplayVal(quantityDisplay);
        setQuantityDisplay(ridAnnoyingZero(quantityVal));
        break;
      default:
    }
  };

  // INPUT FUNCTIONS for Recipe Metrics (prep time, cook time, yield, portion quantity)
  const checkMeasurementInput = (e, metric) => {
    if (!inputChanged) setInputChanged(true);

    let isInvalid = false;
    if (!/^\d*\.?\d*$/.test(e.target.value)) {
      // notification.open({
      //   message: 'Please input a numeric value!',
      // });
      isInvalid = true;
    }

    const val = e.target.value;

    switch (metric) {
      case 'yield':
        setYieldDisplay(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : yieldVal));
        setYieldVal(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : yieldVal));
        break;
      case 'portion':
        setPortionDisplay(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : portionVal));
        setPortionVal(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : portionVal));
        break;
      case 'quantity':
        setQuantityDisplay(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : quantityVal));
        setQuantityVal(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : quantityVal));
        break;
      default:
    }
  };

  const checkTimeInput = (e, activity) => {
    let isInvalid = false;
    if (e.target.value !== '' && !/^-?\d+$/.test(e.target.value)) {
      // notification.open({
      //   message: 'Please input a numeric value!',
      // });
      isInvalid = true;
    }

    const val = e.target.value === '' ? 0 : e.target.value;

    switch (activity) {
      case 'preparation':
        setShowPrepTime(ridAnnoyingZero(!isInvalid ? val : prepTime));
        setPrepTime(isInvalid ? undefined : parseInt(val));
        break;
      case 'cooking':
        setShowCookTime(ridAnnoyingZero(!isInvalid ? val : cookTime));
        setCookTime(isInvalid ? undefined : parseInt(val));
        break;
      default:
    }
  };

  const errorNotification = useRef(false);

  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
    if (status === 'rejected_file_type') {
      if (!errorNotification.current) {
        notification.open({
          message: 'File type is not an image',
        });
        errorNotification.current = true;
        setTimeout(() => {
          errorNotification.current = false;
        }, 1500);
      }
    }
  };

  useEffect(() => {
    if (prepTimeUnit === 'hours') {
      const convertedTimeDuration = Math.ceil(prepTime / 60);
      setPrepTime(convertedTimeDuration * 60);
      setShowPrepTime(ridAnnoyingZero(convertedTimeDuration));
    } else {
      const convertedTimeDuration = prepTime * 1;
      setPrepTime(convertedTimeDuration);
      setShowPrepTime(ridAnnoyingZero(convertedTimeDuration));
    }
  }, [prepTimeUnit]);

  useEffect(() => {
    if (cookTimeUnit === 'hours') {
      const convertedTimeDuration = Math.ceil(cookTime / 60);
      setCookTime(convertedTimeDuration * 60);
      setShowCookTime(ridAnnoyingZero(convertedTimeDuration));
    } else {
      const convertedTimeDuration = cookTime * 1;
      setCookTime(convertedTimeDuration);
      setShowCookTime(ridAnnoyingZero(convertedTimeDuration));
    }
  }, [cookTimeUnit]);

  return (
    <div id="create-recipe" className={'common-page-container' + (isMobile ? ' cpc-mobile' : '')}>
      {!isMobile ? (
        <div className="common-subheader">
          <BacktoHeader
            backText={'Back to ' + (originPage && originPage.name == 'cat' ? 'recipes' : originPage ? 'menu' : 'menus')}
            backLink={
              originPage && originPage.name == 'cat' ? '/recipes' : originPage ? `/menu/${originPage.menuid}` : '/menus'
            }
            showSearch={false}
          />
        </div>
      ) : (
        <BacktoHeaderMobile
          restaurant={restaurant}
          backText="Back to all recipes view"
          backLink={
            originPage && originPage.name == 'cat' ? '/recipes' : originPage ? `/menu/${originPage.menuid}` : '/menus'
          }
        />
      )}
      <div className="Line"></div>
      <div className="common-page-content">
        <Form name="create-edit-recipe-form" class="create-edit-recipe-form">
          <div className="recipe-form-container">
            {/* Title */}
            <div className="recipe-title required-asterisk">Title</div>
            <input
              placeholder="Enter a recipe name"
              type="text"
              className="recipe-input"
              onChange={(e) => setName(e.target.value)}
            />
            {/* Description */}
            <div className="recipe-title">Description</div>
            <textarea
              type="text"
              className="recipe-textarea"
              placeholder="Short description of your recipe"
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            {/* Tags */}
            <div className="recipe-title">
              Tag{' '}
              {showIns ? <span className="show-ins-enter">* Click 'Enter' key after typing each tag to save</span> : ''}
            </div>
            <div className="recipe-input">
              {addTags.map((tag) => {
                let color = 'black';
                return (
                  <Tag color={color} key={tag} closable onClose={(e) => removeTag(e, tag)}>
                    {tag}
                  </Tag>
                );
              })}
              <input
                type="text"
                className="rec-input-create"
                placeholder="Enter a tag"
                value={enteredText}
                onChange={typeTag}
                onFocus={() => setShowIns(true)}
                onKeyDown={addTag}
              />
            </div>
            {/* Files */}
            <br />
            <div className="recipe-title">Add photos, videos, or documents</div>
            {windowSize.width > 700 ? (
              <div>
                <div style={{ position: 'relative' }}>
                  <div className="demo-container">
                    <div className="carousel">
                      <input
                        checked="checked"
                        className="carousel__activator"
                        id="carousel-slide-activator-1"
                        name="carousel"
                        type="radio"
                      />
                      <input
                        className="carousel__activator"
                        id="carousel-slide-activator-2"
                        name="carousel"
                        type="radio"
                      />
                      <input
                        className="carousel__activator"
                        id="carousel-slide-activator-3"
                        name="carousel"
                        type="radio"
                      />
                      <div className="carousel__controls">
                        <label
                          className="carousel__control carousel__control--forward"
                          htmlFor="carousel-slide-activator-2"
                        >
                          <div className="car-control"> › </div>
                        </label>
                      </div>
                      <div className="carousel__controls">
                        <label
                          className="carousel__control carousel__control--backward"
                          htmlFor="carousel-slide-activator-1"
                        >
                          <div className="car-control"> ‹ </div>
                        </label>
                        <label
                          className="carousel__control carousel__control--forward"
                          htmlFor="carousel-slide-activator-3"
                        >
                          <div className="car-control"> › </div>
                        </label>
                      </div>
                      <div className="carousel__controls">
                        <label
                          className="carousel__control carousel__control--backward"
                          htmlFor="carousel-slide-activator-2"
                        >
                          <div className="car-control"> ‹ </div>
                        </label>
                      </div>
                      <div className="carousel__screen">
                        <div className="carousel__track">
                          {imageUrls.map((image, i) => {
                            return (
                              <div
                                key={`carousel-image-${i + 1}`}
                                className="carousel__item carousel__item--mobile-in-1 carousel__item--tablet-in-2 carousel__item--desktop-in-3"
                              >
                                <div className="demo-content">
                                  <div>
                                    <img src={image} />
                                    <Dropdown overlay={deletesImg(image)}>
                                      <div>
                                        {' '}
                                        <img src={IconsMoreOff} className="delete-Icons15" alt="IconsMoreOff" />
                                      </div>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="recipe-title-mobile-spacer"></div>
                <div style={{ position: 'relative', marginTop: 50 }}>
                  <div className="demo-container">
                    <div className="carousel">
                      <input
                        checked="checked"
                        className="carousel__activator"
                        id="carousel-slide-activator-1"
                        name="carousel"
                        type="radio"
                      />
                      <input
                        className="carousel__activator"
                        id="carousel-slide-activator-2"
                        name="carousel"
                        type="radio"
                      />
                      <input
                        className="carousel__activator"
                        id="carousel-slide-activator-3"
                        name="carousel"
                        type="radio"
                      />
                      <div className="carousel__controls">
                        <label
                          className="carousel__control carousel__control--forward"
                          htmlFor="carousel-slide-activator-2"
                        >
                          <div className="car-control"> › </div>
                        </label>
                      </div>
                      <div className="carousel__controls">
                        <label
                          className="carousel__control carousel__control--backward"
                          htmlFor="carousel-slide-activator-1"
                        >
                          <div className="car-control"> ‹ </div>
                        </label>
                        <label
                          className="carousel__control carousel__control--forward"
                          htmlFor="carousel-slide-activator-3"
                        >
                          <div className="car-control"> › </div>
                        </label>
                      </div>
                      <div className="carousel__controls">
                        <label
                          className="carousel__control carousel__control--backward"
                          htmlFor="carousel-slide-activator-2"
                        >
                          <div className="car-control"> ‹ </div>
                        </label>
                      </div>
                      <div className="carousel__screen">
                        <div className="carousel__track">
                          {imageUrls.map((image) => {
                            return (
                              <div className="carousel__item carousel__item--mobile-in-1 carousel__item--tablet-in-2 carousel__item--desktop-in-3">
                                <div className="demo-content-mobile">
                                  <div>
                                    <img src={image} />
                                    <Dropdown overlay={deletesImg(image)}>
                                      <div>
                                        {' '}
                                        <img src={IconsMoreOff} className="delete-Icons15" alt="IconsMoreOff" />
                                      </div>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <div style={{ margin: 10 }}>
							<div style={{ display: 'flex', marginTop: 20 }}>
								{imageUrls.map((image) => {
									return (
										<div style={{ position: 'relative' }}>
											<img className="recipe-thumbnail" src={image} />
											<Dropdown overlay={deletesImg(image)}>
												<a className="remove-img13">
													<img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
												</a>
											</Dropdown>
										</div>
									);
								})}
							</div>
						</div> */}

            {windowSize.width > 700 ? (
              <div className={imageUrls.length > 0 ? 'addFile-margin' : 'addFile-margin1'}>
                <AddFile handleChangeStatus={handleChangeStatus} handleDrop={handleDrop} />
              </div>
            ) : (
              <div className={imageUrls.length > 0 ? 'addFile-margin' : 'addFile-margin1'}>
                <AddFile handleChangeStatus={handleChangeStatus} handleDrop={handleDrop} />
              </div>
            )}
          </div>
          <div className="Line"></div>
          {/* Prep Time and Cook Time*/}
          {!isMobile ? (
            <div className="recipe-form-container">
              <div className="prep-cook">
                <div className="flex-grid">
                  <InputSelectCombo3
                    formLabel="Prep time"
                    showValue={showPrepTime}
                    onChange={checkTimeInput}
                    keyword="preparation"
                    keyword2="preptime"
                    onBlur={blurInputTime}
                    timeUnit={prepTimeUnit}
                    setTimeUnit={setPrepTimeUnit}
                    windowSize={windowSize.width}
                  />
                  <InputSelectCombo3
                    formLabel="Cook time"
                    showValue={showCookTime}
                    onChange={checkTimeInput}
                    keyword="cooking"
                    keyword2="cooktime"
                    onBlur={blurInputTime}
                    timeUnit={cookTimeUnit}
                    setTimeUnit={setCookTimeUnit}
                    windowSize={windowSize.width}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="recipe-form-container">
                <div className="prep-cook-mobile">
                  <div className="flex-grid prep-mobile">
                    <InputSelectCombo3
                      formLabel="Prep time"
                      showValue={showPrepTime}
                      onChange={checkTimeInput}
                      keyword="preparation"
                      keyword2="preptime"
                      onBlur={blurInputTime}
                      timeUnit={prepTimeUnit}
                      setTimeUnit={setPrepTimeUnit}
                      windowSize={windowSize.width}
                    />
                    <InputSelectCombo3
                      formLabel="Cook time"
                      showValue={showCookTime}
                      onChange={checkTimeInput}
                      keyword="cooking"
                      keyword2="cooktime"
                      onBlur={blurInputTime}
                      timeUnit={cookTimeUnit}
                      setTimeUnit={setCookTimeUnit}
                      windowSize={windowSize.width}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="Line"></div>
          <div className="recipe-form-container">
            {/* Yield, Portion, Quantity */}
            {!isMobile ? (
              <div className="prep-cook">
                <div className="flex-grid">
                  <InputSelectCombo
                    formLabel="Yield"
                    onChange={checkMeasurementInput}
                    keyword="yield"
                    onFocus={focusInputMeasurements}
                    onBlur={blurInputMeasurements}
                    showValue={yieldDisplay}
                    selectVal={yieldMeasUnit}
                    setInputVal={setYieldVal}
                    setShowVal={setYieldDisplay}
                    onChangeSelect={checkMeasurementUnitChange}
                    windowSize={windowSize.width}
                  >
                    {measurements &&
                      measurements.length > 0 &&
                      measurements.map((m, i) => (
                        <option key={`${i + 1}`} value={m.name}>
                          {m.name}
                        </option>
                      ))}
                  </InputSelectCombo>
                  <InputSelectCombo
                    formLabel="Portion"
                    onChange={checkMeasurementInput}
                    keyword="portion"
                    onFocus={focusInputMeasurements}
                    onBlur={blurInputMeasurements}
                    showValue={portionDisplay}
                    selectVal={portionMeasUnit}
                    setInputVal={setPortionVal}
                    setShowVal={setPortionDisplay}
                    onChangeSelect={checkMeasurementUnitChange}
                    windowSize={windowSize.width}
                  >
                    {measurements &&
                      measurements.length > 0 &&
                      measurements.map((m, i) => (
                        <option key={`${i + 1}`} value={m.name}>
                          {m.name}
                        </option>
                      ))}
                  </InputSelectCombo>
                  <InputSelectCombo2
                    formLabel="Quantity"
                    onChange={checkMeasurementInput}
                    keyword="quantity"
                    onFocus={focusInputMeasurements}
                    onBlur={blurInputMeasurements}
                    showValue={quantityDisplay}
                    setInputVal={setQuantityVal}
                    setShowVal={setQuantityDisplay}
                  />
                </div>
              </div>
            ) : (
              <div className="prep-cook-mobile">
                <div className="flex-grid prep-mobile">
                  <InputSelectCombo
                    formLabel="Yield"
                    onChange={checkMeasurementInput}
                    keyword="yield"
                    onFocus={focusInputMeasurements}
                    onBlur={blurInputMeasurements}
                    showValue={yieldDisplay}
                    selectVal={yieldMeasUnit}
                    setInputVal={setYieldVal}
                    setShowVal={setYieldDisplay}
                    onChangeSelect={checkMeasurementUnitChange}
                    inputClassMobile="recipe-input-smll"
                    windowSize={windowSize.width}
                  >
                    {measurements &&
                      measurements.length > 0 &&
                      measurements.map((m, i) => (
                        <option key={`${i + 1}`} value={m.name}>
                          {m.name}
                        </option>
                      ))}
                  </InputSelectCombo>
                  <InputSelectCombo
                    formLabel="Portion"
                    onChange={checkMeasurementInput}
                    keyword="portion"
                    onFocus={focusInputMeasurements}
                    onBlur={blurInputMeasurements}
                    showValue={portionDisplay}
                    selectVal={portionMeasUnit}
                    setInputVal={setPortionVal}
                    setShowVal={setPortionDisplay}
                    onChangeSelect={checkMeasurementUnitChange}
                    inputClassMobile="recipe-input-smll"
                    windowSize={windowSize.width}
                  >
                    {measurements &&
                      measurements.length > 0 &&
                      measurements.map((m, i) => (
                        <option key={`${i + 1}`} value={m.name}>
                          {m.name}
                        </option>
                      ))}
                  </InputSelectCombo>
                  <InputSelectCombo2
                    formLabel="Quantity"
                    onChange={checkMeasurementInput}
                    keyword="quantity"
                    onFocus={focusInputMeasurements}
                    onBlur={blurInputMeasurements}
                    showValue={quantityDisplay}
                    setInputVal={setQuantityVal}
                    setShowVal={setQuantityDisplay}
                    inputClassMobile="recipe-input-smll"
                  />
                </div>
              </div>
            )}

            <div className="recipe-title">
              <span>Adjust recipe</span>{' '}
              {showReset && (
                <span onClick={resetMultiplier} className="reset-multiplier">
                  Reset
                </span>
              )}
            </div>
            {!isMobile ? (
              <div className="adj-rec-box">
                <div className="flex-grid-thirds">
                  <div className="col">Convert by</div>
                  <div className="col">
                    {' '}
                    <InputNumber
                      min={0}
                      max={1000}
                      style={{ margin: '0 90px', color: 'white', background: '#454545', border: 'none' }}
                      value={scaleInputValue}
                      onChange={changeScaleVal}
                    />
                  </div>
                  <div className="col"></div>
                </div>
                <div className="flex-grid-thirds1">
                  <div className="col">
                    <Radio.Group onChange={setPortions} value={portionValue}>
                      <Radio.Button value={'scale'}>Scale</Radio.Button>
                      <Radio.Button value={'portions'}>Portions</Radio.Button>
                    </Radio.Group>
                  </div>
                  <div className="col">
                    <div onClick={decreaseVal} className="calc-rec-less">
                      <img src={less} height="28" />
                    </div>
                    <Slider
                      min={1}
                      max={1000}
                      onChange={changeScaleVal}
                      value={typeof scaleInputValue === 'number' ? scaleInputValue : 0}
                    />
                    <div onClick={increaseVal} className="calc-rec-add">
                      <img src={add} height="28" />
                    </div>
                  </div>
                  <div className="col">
                    <button className="calc-btn-recipe" onClick={(e) => calculateScale(e)}>
                      Calculate
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="adj-rec-box-mob doh">
                <div>Convert by</div>
                <div>
                  <div style={{ textAlign: 'center', marginTop: 10 }}>
                    <Radio.Group onChange={setPortions} value={portionValue}>
                      <Radio.Button value={'scale'}>Scale</Radio.Button>
                      <Radio.Button value={'portions'}>Portions</Radio.Button>
                    </Radio.Group>
                  </div>
                  <div style={{ marginTop: 30, textAlign: 'center' }}>
                    {' '}
                    <InputNumber
                      min={0}
                      max={1000}
                      style={{ margin: '0 90px', color: 'white', background: '#454545', border: 'none' }}
                      value={scaleInputValue}
                      onChange={changeScaleVal}
                    />
                  </div>
                  <div>
                    <div onClick={decreaseVal} className="calc-rec-lessMob">
                      <img src={less} height="28" />
                    </div>
                    <div style={{ margin: '30px auto', width: '65%' }}>
                      <Slider
                        min={1}
                        max={1000}
                        onChange={changeScaleVal}
                        value={typeof scaleInputValue === 'number' ? scaleInputValue : 0}
                      />
                    </div>
                    <div onClick={increaseVal} className="calc-rec-addMob">
                      <img src={add} height="28" />
                    </div>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <button className="calc-btn-recipeMob" onClick={(e) => calculateScale(e)}>
                      Calculate
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="prep-cook"></div>
            {/* Ingredients */}
            <div>
              <IngredientTable
                addIngredientsList={addIngredientsList}
                unassignIng={unassignIng}
                ingListUpdates={ingListUpdates}
                qtyCombine={qtyCombine}
                updateQtyCombine={updateQtyCombine}
                isEditing={true}
                totalPortionValue={scaleInputValue}
              />
              <AddIngredientPopup updateIngredients={updateIngredients} restaurantId={resto.id} isEditing={false} />
            </div>
            {/* Equipment */}
            <div>
              <EquipmentTable
                addEquipmentList={addEquipmentList}
                unassignEquip={unassignEquip}
                eqListUpdates={eqListUpdates}
                isEditing={true}
                updateEquipCategory={updateEquipCategory}
                tabStart={addIngredientsList.length * 4} //4 as a factor is the number of columns in the IngredientTable
              />
              <AddEquipment updateEquipment={updateEquipment} />
            </div>
            {/* Instructions */}
            <div>
              <InstructionsList
                instructionlist={instructions}
                isEditing={isEditing}
                updateInstructions={updateInstructions}
              />
            </div>
            {/* Internal comments */}
            <div className="view-recipe-section-title-edit">Internal comments</div>

            <div style={{ display: 'flex' }}>
              <div className="common-user-icon user-ing" alt="user-photo">
                <UserImage src={userImage} />
              </div>
              <input
                type="text"
                className="recipe-input-mde"
                placeholder="Enter comment"
                onChange={(e) => setComment(e.target.value)}
              />
            </div>

            <div className="btn-container">
              <button className="save-btn-recipe" onClick={saveRecipe}>
                Save
              </button>
              <button className="cancel-btn-recipe" onClick={cancelForm}>
                Cancel
              </button>
            </div>
          </div>

          {/* <IngredientsList /> */}

          {/* <CostAnalysis />   */}
        </Form>
      </div>
    </div>
  );
};

export default Form.create()(CreateRecipes);
