import React from 'react';
import './index.css';
import UserMenuMobile from '../UserMenu/UserMenuMobile.js';
import AdminMenuMobile from '../../components/AdminMenu/AdminMenuMobile.js';
import { unimpersonate } from '../../services/operator.service';
import { Link, useHistory } from 'react-router-dom';
import { ChevronLeft } from '../../components/common/common_ui';
import 'antd/dist/antd.css';

const HeaderMobile = (props) => {
  const history = useHistory();
  let impersonating = JSON.parse(localStorage.getItem('impersonate')) || {};

  const unimpersonateUser = () => {
    let changeOperator = localStorage.getItem('operator2');
    let changeResto = localStorage.getItem('restaurant2');
    let changeRestoId = localStorage.getItem('restaurantId2');
    localStorage.setItem('restaurant', changeResto);
    localStorage.setItem('restaurantId', changeRestoId);
    localStorage.setItem('operator', changeOperator);
    unimpersonate(impersonating.id).then((res) => {
      console.log(res);
      localStorage.removeItem('impersonate');
      localStorage.removeItem('operator2');
      localStorage.removeItem('restaurant2');
      localStorage.removeItem('restaurantId2');
      history.push({
        pathname: '/super/accounts',
        state: 'unimpersonate',
        search: '?unimpersonate=unimpersonate',
      });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    });
  };

  return (
    <div>
      {impersonating && Object.keys(impersonating).length !== 0 && (
        <div className="impersonate-user-mobile">
          You are impersonating {impersonating.name}{' '}
          <span className="exit-impersonate" onClick={unimpersonateUser}>
            Exit view
          </span>
        </div>
      )}
      <div className="common-header-mobile">
        <div className="Heading-Mobile">{props.isAdmin ? <AdminMenuMobile /> : <UserMenuMobile />}</div>
        <div className="Line"></div>
      </div>
    </div>
  );
};

HeaderMobile.defaultProps = {
  isAdmin: false,
};

export const SubHeaderMobile = (props) => {
  return (
    <div className="common-subheader-mobile">
      <div className="common-restaurant-title-mobile">{props.restaurant}</div>
      <div className="common-controls-container-mobile">
        <div className="common-header-title-mobile">{props.title}</div>
        {props.showSearch ? (
          <div className="flex-control-right">
            <input
              type="text"
              className="common-search-bar-mobile"
              placeholder={props.searchPrompt ? props.searchPrompt : 'Search ' + props.title}
              onChange={props.onChange}
            />
            <span className="common-search-icon-mobile">
              <i className="fa fa-search" aria-hidden="true"></i>
            </span>
          </div>
        ) : (
          <div className="flex-control-right">
            <span className="common-search-icon-mobile">
              <i className="fa fa-search" aria-hidden="true" onClick={props.clickSearch}></i>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

SubHeaderMobile.defaultProps = {
  showSearch: true,
};

export const BacktoHeaderMobile = (props) => {
  return (
    <div className="common-subheader-mobile">
      <div className="common-restaurant-title-mobile">{props.restaurant}</div>
      <Link to={props.backLink || '#'} onClick={props.onClick}>
        <div className="common-back-nav-mobile" alt={props.backAlt ? props.backAlt : props.backText}>
          <ChevronLeft />
          {props.backText}
        </div>
      </Link>
    </div>
  );
};

export default HeaderMobile;
