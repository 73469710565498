/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { roles, create } from '../../services/operator.service';
import { allLocations } from '../../services/restaurant.service';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { Menu, Dropdown, Form } from 'antd';
import { onFileSelected } from '../../services/upload.service';
import { BacktoHeaderMobile } from '../../components/Header/HeadMobile.js';
import { CompletionIcon } from '../../components/common/common_ui';

const UsersNewMobile = (props) => {
  const {
    form: { getFieldDecorator },
  } = props;

  const [userimg, setUserImg] = useState('');
  const [editing, setEditing] = useState(false);
  const [allRoles, setRoles] = useState(true);
  // const [restos, setRestos] = useState([]);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  //const [user, setUser] = useState('');
  const [role, setRole] = useState('');
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(0);
  const [loading, setLoading] = useState(false);

  const fileInput = useRef();
  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';
  const operatorInfo = JSON.parse(localStorage.getItem('operator') || {}); // BUGS 1202057020235817, 1202057020235823

  const getLocations = () => {
    allLocations(0, 1000).then((res) => {
      console.log(res);
      let allLoc = res;
      let address = allLoc.map((l) => {
        return {
          id: l.id,
          name: l.name,
          address: l.info && l.info?.address ? l.info?.address : '',
        };
      });

      console.log(address);
      setLocations(address);
    });
  };

  const getRoles = () => {
    roles().then((res) => {
      console.log(res);
      let getBasic = res.filter((r) => r.name == 'Superadmin');
      setRoles(getBasic);
    });
  };

  useEffect(() => {
    getLocations();
    getRoles();
  }, []);

  const cancelForm = () => {
    props.history.push('/admin/users');
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const uploadImg = (e) => {
    setLoading(true);
    setEditing(true);
    e.preventDefault();
    e.stopPropagation();

    onFileSelected(e.target.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        setUserImg(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        setLoading(false);
      } else {
        setLoading(false);
        let userimage = URL.createObjectURL(e.target.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const handleOnDrop = (e) => {
    setLoading(true);
    setEditing(true);
    e.preventDefault();
    e.stopPropagation();
    console.log(e.dataTransfer.files[0]);

    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        setUserImg(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        setLoading(false);
      } else {
        setLoading(false);
        let userimage = URL.createObjectURL(e.dataTransfer.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const deleteImg = () => {
    setUserImg('');
    setEditing(false);
  };

  const controlItems = () => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={deleteImg}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const selectedLocation = () => {
    const values = Array.from(document.querySelectorAll('input[type="checkbox"]'))
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => JSON.parse(checkbox.value));

    console.log(values);
    setSelectedLocations(values);

    let ids = values.map((v) => v.id);
    let address = locations;
    address.forEach((p) => {
      if (ids.includes(p.id)) {
        p.checked = true;
      } else {
        p.checked = false;
      }
    });
    console.log(selectedLocations);
    setLocations(address);
  };

  const saveUser = (e) => {
    e.preventDefault();
    props.form.validateFields((err, { password, user, email }) => {
      if (!err) {
        let selectedIds = selectedLocations.map((l) => l.id);
        const params = {
          firstName: firstname.trim(),
          lastName: lastname.trim(),
          email: email.trim(),
          username: user.trim(),
          password: password.trim(),
          roleId: role,
          restaurantIds: selectedIds,
          accountId: accountId,
          info: {
            userImage: userimg,
            activated: true, // BUGS 1202057020235817, 1202057020235823
            activatedBy: operatorInfo.email, // BUGS 1202057020235817, 1202057020235823
            deactivatedBy: '', // BUGS 1202057020235817, 1202057020235823
          },
        };

        console.log(params);

        create(params).then((res) => {
          props.history.push('/admin/users');
          // notification.open({
          //   message: 'Successfully added user!',
          // });
          if (res) {
            // notification.open({
            //   message: 'Successfully added user!',
            // });
            props.history.push('/admin/users');
          }
        });
      } else {
        console.log(err);
      }
    });
  };

  return (
    <div>
      <Form onSubmit={saveUser} style={{ color: 'var(--base-text)' }}>
        <BacktoHeaderMobile restaurant="Admin" backText="Back to users" backLink="/admin/users" />
        <div className="Line"></div>
        <div>
          <div className="userTitle-Mob">Add new user</div>
          <br />
          <div>
            <div>
              <div>
                <div
                  className="upload-boxMob"
                  onClick={() => fileInput.current.click()}
                  onDragOver={handleDragOver}
                  onDrop={handleOnDrop}
                >
                  {editing ? (
                    <img src={userimg} className="preview-imgUser" alt="user" />
                  ) : (
                    // <div className="img-uploadUserMob">Upload</div>
                    <div>Upload</div>
                  )}

                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => uploadImg(e)}
                    ref={fileInput}
                    accept="image/*"
                  />
                </div>
                {loading ? <div style={{ margin: '5px 0px', color: 'var(--red)' }}>Upload in progress...</div> : ''}
                <Dropdown overlay={controlItems}>
                  <img src={IconsMoreOff} style={{ marginTop: 15, marginLeft: 130 }} alt="seemore" />
                </Dropdown>
              </div>
            </div>
            <div className="userInfo-data1">
              <div style={{ marginLeft: 5 }}>First name</div>
              <input
                placeholder="Enter first name"
                type="text"
                className="user-input12"
                defaultValue={firstname}
                onChange={(event) => setFirstname(event.target.value)}
              />
              <br />
              <div style={{ marginLeft: 5 }}>Last name</div>
              <input
                placeholder="Enter last name"
                type="text"
                className="user-input12"
                defaultValue={lastname}
                onChange={(event) => setLastname(event.target.value)}
              />
              <br />
              <div style={{ marginLeft: 5 }}>Email address</div>
              <Form.Item className="password-reset" name="email">
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Email is required' }],
                })(
                  <input
                    placeholder="Enter email address"
                    type="text"
                    className="user-input12"
                    // defaultValue={email}
                    // onChange={(event) => setEmail(event.target.value)}
                  />
                )}
              </Form.Item>
              <br />
              <div style={{ marginLeft: 5 }}>Username</div>
              <Form.Item className="password-reset" name="user">
                {getFieldDecorator('user', {
                  rules: [{ required: true, message: 'Username is required' }],
                })(
                  <input
                    placeholder="Enter username"
                    type="text"
                    className="user-input12"
                    // defaultValue={user}
                    // onChange={(event) => setUser(event.target.value)}
                  />
                )}
              </Form.Item>
              <br />
              <div style={{ marginLeft: 5 }}>Password</div>
              <Form.Item className="password-reset" name="password">
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: 'Password is required' },
                    {
                      min: 9,
                      pattern: new RegExp(
                        /^(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;=?@[\]^_`{|}~])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9!"#$%&'()*+,-./:;=?@[\]^_`{|}~]{8,100}$/
                      ),
                      message:
                        'Password must be minimum 9 characters. have at least one lowercase letter, one uppercase letter, a number, and a special character.',
                    },
                  ],
                })(
                  <input
                    placeholder="Enter password"
                    type="password"
                    className="user-input12"
                    // defaultValue={password}
                    // onChange={(event) => setPassword(event.target.value)}
                  />
                )}
              </Form.Item>
              <br />
              <div style={{ marginLeft: 5 }}>Role</div>
              <select className="user-select12" defaultValue={role} onChange={(event) => setRole(event.target.value)}>
                <option initialvalue="default">Select</option>
                {allRoles &&
                  allRoles.length > 0 &&
                  allRoles.map((m) => {
                    return <option value={m.id}>{m.name}</option>;
                  })}
              </select>
              <div style={{ marginLeft: 5, marginTop: 20 }}>
                Locations{' '}
                <span className="user-locations1">
                  {' '}
                  ( {selectedLocations.length > 0 ? selectedLocations.length : 0} of {locations.length} locations
                  selected )
                </span>
                <div style={{ marginLeft: '-10px', marginTop: 15 }}>
                  {locations &&
                    locations.length > 0 &&
                    locations.map((l) => {
                      return (
                        <div style={{ display: 'flex' }}>
                          <input
                            type="checkbox"
                            id={l.id}
                            value={JSON.stringify({ id: l.id, name: l.name, address: l.address })}
                            checked={l.checked}
                            onChange={selectedLocation}
                            style={{ display: 'none' }}
                          />
                          <label for={l.id} className="checklabel">
                            <span className="check-task">
                              <CompletionIcon completed={l.checked} dimmed={!l.checked} />
                            </span>
                          </label>
                          <div style={{ marginLeft: 10, marginTop: 5 }}>
                            {l.name} - {l.address}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <br />
              <div className="btn-container">
                <button className="save-btn-recipe" htmltype="submit">
                  Save
                </button>
                <button className="cancel-btn-recipe" onClick={cancelForm}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Form.create()(UsersNewMobile);
