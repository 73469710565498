import React from 'react';
import './index.css';
import MailTo from '../../components/MailTo/MailTo';

const ContactUs = () => {
  return (
    <div className="contact-parent">
      <div>
        If you have a question, concern, or you want to tell us how we’re doing, don’t hesitate to reach out to us.
        We’ll resolve your need quickly and easily.
      </div>
      <br />
      <div>
        <span>Email us at </span>
        <MailTo email="info@cidekic.com" subject="Customer Support Request">
          Info@CideKic.com
        </MailTo>
        .
      </div>
    </div>
  );
};

export default ContactUs;
