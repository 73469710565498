/* eslint eqeqeq: "off" */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TabDash from './tabDash';

class TabsDash extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  recipeLabel;

  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };

  // componentDidMount() {
  //   let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props && this.props.children.length == 1) {
      console.log(this.props.children[0].props.label);
      this.setState({ activeTab: this.props.children[0].props.label });
    }
  }

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;

    return (
      <div className="tabs">
        <div className="tab-nav-wrapper">
          <ol className="tab-list marginL10">
            {children.map((child) => {
              const { label } = child.props;

              return <TabDash activeTab={activeTab} key={label} label={label} onClick={onClickTabItem} />;
            })}
          </ol>
        </div>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default TabsDash;
