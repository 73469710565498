import React, { useState, useEffect, useCallback } from 'react';
// import { Link } from 'react-router-dom';
import './index.css';
import attach from '../../img/attach.png';
// import monday from '../../img/station-monday.png';
// import tues from '../../img/station-tues.png';
// import wed from '../../img/station-wed.png';
import info from '../../img/station-info-icon.png';
// import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { Table, Popover, Menu, Dropdown, notification, Drawer, Tag } from 'antd';
import { updateChecklist, updateStation } from '../../services/station.service';
import { updateItem } from '../../services/checklist.service';
import {
  addItem as addItemToShop,
  // itemNeeded as revertItem,
  itemOrdered,
  // deleteStationItems as removeFromShop,
  deleteItem,
} from '../../services/shoplist.service';
import useWindowSize from '../../components/windowdimensions/windowSize';
import DocumentList from '../../components/DocumentList/DocumentList';
import { cloneDeep, findIndex } from 'lodash';
import moment from 'moment';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import {
  CidekicButton,
  PlusButton,
  CidekicDropdown,
  AttachmentIndicator,
  CompletionIcon,
  HealthcheckIcon,
  ShoppingCartIcon,
  ThumbnailImage,
  ChefIcon,
} from '../../components/common/common_ui';

const StationGeneral = (props) => {
  const {
    progressOpen,
    stationDetail,
    stationIngs,
    stationIng,
    tableData,
    showAllIngredients,
    filterProductionReady,
    filterCompletedHealthChecks,
    filterCompleted,
    filterNeeded,
    showChecklists,
    updateIngredientsState,
    updateDocumentsState,
    updateTaskState,
    activeList,
    hasUpdateChecklist,
    setHasUpdate,
    assignedToMe,
    stationId,
    documents,
    stationName,
    stationType,
    pristineIngs,
    setPristineIngs,
  } = props;
  const [expandedRows, setExpandedRows] = useState([]);
  const [menuName, setMenuName] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [selectedList, setSelectedList] = useState([]);
  const [selectedList2, setSelectedList2] = useState([]);
  const [showTiles, setShowTiles] = useState(false);
  const [visible, updateVisible] = useState(false);
  const [visible1, updateVisible1] = useState(false);
  const [docLinks, setDocLinks] = useState({});
  const [links, setLinks] = useState({});
  const [links1, setLinks1] = useState({});
  const [docLinks1, setDocLinks1] = useState({});
  const [shoplistId, setShoplistId] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/checklist`));
  const [sortedInfo, setSortedInfo] = useState(null);
  const [recipeData, setRecipeData] = useState([]);

  const windowSize = useWindowSize();

  const restaurantId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  let userRole = (JSON.parse(localStorage.getItem('operator')) || {}).role;

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('operator')) || {});
    console.log(showChecklists);
  }, [showChecklists]);

  useEffect(() => {
    setRecipeData(tableData);
  }, [tableData]);

  useEffect(() => {
    setMenuName('');
    if (activeList.length > 0) {
      let activeIds = activeList.map((a) => a.key);
      let stationLists = stationIngs.filter((s) => activeIds.includes(s.key));
      let stationLists2 = stationIng.filter((s) => activeIds.includes(s.key));
      setSelectedList(stationLists);
      setSelectedList2(stationLists2);
    } else {
      setSelectedList(stationIngs);
      setSelectedList2(stationIng);
    }
  }, [activeList, stationIngs, stationIng]);

  useEffect(() => {
    let getIds = JSON.parse(localStorage.getItem('stationVisitedIds'));
    if (getIds && getIds.includes(stationId)) {
      let getOpenRows = JSON.parse(localStorage.getItem('stationOpenRows'));
      if (getOpenRows && getOpenRows.length > 0) {
        setExpandedRows(getOpenRows);
      } else {
        setExpandedRows([1, 2]);
      }
    } else {
      setExpandedRows([1, 2]);
    }
  }, [stationId]);

  // const deepUpdatePristineData = (ingredient, field) => {
  // 	let clonedPristine = cloneDeep(pristineData);
  // 	let
  // }

  const filterHealthComplete = () => {
    filterCompletedHealthChecks();
    setMenuName('Quality check incomplete');
  };

  const filterProductionReady1 = () => {
    filterProductionReady();
    setMenuName('Production ready');
  };

  const filterComplete = () => {
    filterCompleted();
    setMenuName('Production completed');
  };

  const filterComplete1 = () => {
    setMenuName('Items completed');
    let comp = stationIng.filter((obj) => obj.children.some((task) => task.checked == true));
    setSelectedList2(comp);
    let comp2 = stationIngs.filter((obj) => obj.children.some((task) => task.tags.some((t) => t.checked == true)));
    setSelectedList(comp2);
  };

  const filterIncomplete = () => {
    setMenuName('Items incomplete');
    let inc = stationIngs.filter((obj) => obj.children.some((task) => task.tags.some((t) => t.checked == false)));
    setSelectedList(inc);
    let inc2 = stationIng.filter((obj) => obj.children.some((task) => task.checked == false));
    setSelectedList2(inc2);
  };

  const filterShop = () => {
    filterNeeded();
    setMenuName('Shopping list');
  };

  const allIngredientFilter = () => {
    showAllIngredients();
    setMenuName('All ingredients');
  };

  const menu = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <span onClick={allIngredientFilter}>All ingredients</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterHealthComplete}>Quality check incomplete</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterProductionReady1}>Production ready</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterComplete}>Production completed</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterShop}>Shopping list</span>
      </Menu.Item>
    </Menu>
  );

  const menu1 = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <span onClick={() => window.location.reload()}>All items</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterIncomplete}>Items incomplete</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterComplete1}>Items completed</span>
      </Menu.Item>
    </Menu>
  );

  const columnChecklist = [
    {
      title: () => {
        return <div>&#10003;</div>;
      },
      className: 'align-center',
      dataIndex: 'completed',
      key: 'completed',
      sorter: (a, b) => (a.complete < b.complete ? -1 : a.complete > b.complete ? 1 : 0),
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo?.columnKey == 'completed' && sortedInfo?.order,
      width: '5%',
      render: (key, obj) => (
        <span>
          {obj.type == 'child' && (
            <span className="station-checkmark-icon" onClick={() => setCompleteTask(obj, true)}>
              <CompletionIcon completed={obj.complete === 2} dimmed={false} />
            </span>
          )}
          {/* {obj.type == 'child' && assignedToMe == 2 && (
            <span>
              {' '}
              <img
                alt="completed"
                height="38"
                onClick={() => setCompleteTask(obj, true)}
                src={obj.complete === 2 ? completed : unselected2}
                style={{ cursor: 'pointer' }}
              />
            </span>
          )} */}
          {/* {obj.type == 'child' && assignedToMe == 1 && (
            <span>
              {' '}
              <img
                alt="completed"
                height="38"
                src={obj.complete === 2 ? unselected1 : incomplete}
                style={{ cursor: 'not-allowed' }}
              />
            </span>
          )} */}
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo?.columnKey == 'name' && sortedInfo?.order,
      render: (key, obj) => (
        <span className="name-key">
          {obj.type == 'child' && (
            <div className="station-name-key">
              <div className="station-day-ing"></div>
              {/* use <ThumbnailImage> src={obj.imageUrl ? obj.imageUrl : null} /> */}
              <div className="station-ingredient-title">{key}</div>
              <span>
                {obj.type == 'child' && obj.texts > 0 ? <span className="texts">{obj.texts}</span> : ''}
                {obj.type == 'child' && obj.texts > 0 ? (
                  <span className="texts-icon">
                    {' '}
                    <img src={attach} className="comment" alt="comment" />
                  </span>
                ) : (
                  ''
                )}
                {obj.type == 'child' && obj.attachments > 0 ? (
                  <span className={obj.texts > 0 ? 'attachments' : 'attachments1'}>{obj.attachments}</span>
                ) : (
                  ''
                )}
                {obj.type == 'child' && obj.attachments > 0 ? (
                  <span className={'texts-icon'}>
                    {' '}
                    <img src={attach} className="comment" alt="attach" />
                  </span>
                ) : (
                  ''
                )}
              </span>
            </div>
          )}
          {obj.type == 'parent' && (
            <span className="row-parent-title">
              {key} <div className="station-ing-totals">( {obj.children.length} items )</div>
            </span>
          )}
        </span>
      ),
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      width: '20%',
      ellipsis: true,
      render: (key, obj) => (
        <span className="name-key">
          {obj.type == 'child' && (
            <div className="station-name-key">
              <div className="station-day-ing"></div>
              <div className="station-ingredient-title">{key}</div>
            </div>
          )}
        </span>
      ),
    },
    {
      title: 'Due date',
      dataIndex: 'dueDate',
      width: '10%',
      render: (key, obj) => (
        <span className="name-key">
          {obj.type == 'child' && (
            <div className="station-name-key">
              <div className="station-day-ing"></div>
              <div className="station-ingredient-title">
                {obj.dueDate && !obj.dueDate.includes('1970')
                  ? moment.utc(obj.dueDate).format('MM/DD/YYYY')
                  : 'No date'}
              </div>
            </div>
          )}
        </span>
      ),
    },
    {
      title: 'Project',
      dataIndex: 'project',
      width: '10%',
      render: (key, obj) => (
        <span className="name-key">
          {obj.type == 'child' && (
            <div className="station-name-key">
              <div className="station-day-ing"></div>

              <div className="station-ingredient-title">{key}</div>
            </div>
          )}
        </span>
      ),
    },
    // {
    //   title: 'Tags',
    //   dataIndex: 'tags',
    //   width: 1,
    //   render: (tags, obj) => (
    //     <div className="checklist-taggy">
    //       {obj.type == 'child' && tags
    //         ? tags.map((tag) => {
    //             let color = 'black';
    //             return (
    //               <Tag color={color} key={tag}>
    //                 {tag}
    //               </Tag>
    //             );
    //           })
    //         : ''}{' '}
    //     </div>
    //   ),
    // },
    {
      title: 'Priority',
      dataIndex: 'priority',
      width: '15%',
      render: (key, obj) => (
        <span className="flex">
          {obj.type == 'child' && (
            <div className="station-name-key">
              <div className="station-day-ing"></div>
              <div className="station-ingredient-title">
                {obj.priority == 'High' && <span className="priorities high">{key}</span>}
                {obj.priority == 'Medium' && <span className="priorities med">{key}</span>}
                {obj.priority == 'Low' && <span className="priorities low">{key}</span>}
              </div>
            </div>
          )}
        </span>
      ),
    },
  ];

  const columnTiles = [
    {
      title: () => {
        return <div>&#10003;</div>;
      },
      dataIndex: 'completed',
      sorter: (a, b) => (a.complete < b.complete ? -1 : a.complete > b.complete ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: 44,
      render: (key, obj) => (
        <span>
          {/* {obj.type == 'child' && (
            <span>
              {' '}
              <img
                alt="completed"
                height="38"
                onClick={(e) => toggleCompleted(e, obj)}
                src={obj.completed === 2 ? completed3 : completed1}
              />
            </span>
          )} */}
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo?.columnKey == 'name' && sortedInfo?.order,
      render: (key, obj) => (
        <span className="name-key">
          {obj.type == 'child' && (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {obj.tags.map((c) => {
                return (
                  <div className="ing-name-box21">
                    <div className="ing-name-panel-12"></div>
                    <div className="ing-name-panel">
                      <div className="ing-name1">{c.name}</div>
                      <div className="ing-infoPanel">
                        <span>
                          {obj.type == 'child' && (
                            <span className="station-checkmark-icon" onClick={() => setCompleteTaskTile(obj, true)}>
                              <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                            </span>
                          )}
                          {/* {obj.type == 'child' && assignedToMe == 2 && (
                            <span>
                              {' '}
                              <img
                                alt="completed"
                                style={{cursor: 'pointer'}}
                                height="38"
                                onClick={() => setCompleteTaskTile(obj, true)}
                                src={c.complete === 2 ? completed : unselected2}
                              />
                            </span>
                          )}
                          {obj.type == 'child' && assignedToMe == 1 && (
                            <span>
                              {' '}
                              <img
                                alt="completed"
                                height="38"
                                src={c.complete === 2 ? unselected1 : incomplete}
                                style={{ cursor: 'not-allowed' }}
                              />
                            </span>
                          )} */}
                        </span>
                        <span>
                          {c.texts > 0 ? <span className="texts1">{c.texts}</span> : ''}
                          {c.texts > 0 ? (
                            <span className="texts-icon">
                              {' '}
                              <img src={attach} className="comment" alt="comment" />
                            </span>
                          ) : (
                            ''
                          )}
                          {c.attachments > 0 ? (
                            <span className={c.texts > 0 ? 'comment1' : 'comment1'}>{c.attachments}</span>
                          ) : (
                            ''
                          )}
                          {c.attachments > 0 ? (
                            <span className={'texts-icon'}>
                              {' '}
                              <img src={attach} className="comment" alt="attach" />
                            </span>
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {obj.type == 'parent' && (
            <span className="row-parent-title">
              {obj.name} <div className="station-ing-totals">( {obj.children.length} items )</div>
            </span>
          )}
        </span>
      ),
    },
  ];

  const columns = [
    {
      title: 'Ingredient',
      dataIndex: 'name',
      width: 200,
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['ascend', 'descend'],
      render: (key, obj) => (
        <span>
          {obj.type == 'child' && (
            <div className="station-name-key">
              <div className="station-day-ing">
                {obj.day && obj.day !== 'Invalid date' ? (
                  <div
                    onClick={(e) =>
                      userRole == 'Cook' || userRole == 'Trainer / Manager' || userRole == 'Front House'
                        ? ''
                        : unsetDay(obj)
                    }
                  >
                    {obj.day == 'Friday' && <div className="station-set-days set-day-fri set-day-orient">FRI</div>}
                    {obj.day == 'Thursday' && <div className="station-set-days set-day-thu set-day-orient">THU</div>}
                    {obj.day == 'Tuesday' && <div className="station-set-days set-day-tue set-day-orient">TUE</div>}
                    {obj.day == 'Wednesday' && <div className="station-set-days set-day-wed set-day-orient">WED</div>}
                    {obj.day == 'Monday' && <div className="station-set-days set-day-mon set-day-orient">MON</div>}
                    {obj.day == 'Sunday' && <div className="station-set-days set-day-sun set-day-orient">SUN</div>}
                    {obj.day == 'Saturday' && <div className="station-set-days set-day-sat set-day-orient">SAT</div>}
                  </div>
                ) : (
                  <div
                    className="station-set-days set-day set-day-orient"
                    onClick={() =>
                      userRole == 'Trainer / Manager' || userRole == 'Front House' ? '' : setActiveDay(obj)
                    }
                  >
                    SET
                  </div>
                )}
                <Popover
                  content={
                    <div className="day-infoPop">
                      {obj.day && obj.day !== 'Invalid date' ? (
                        <div>
                          <div style={{ marginBottom: 3 }}>
                            <b>Recorded by</b>
                          </div>
                          <p>{obj.setBy}</p>
                          <div style={{ marginBottom: 3 }}>
                            <b>Date</b>
                          </div>
                          <p>{obj.setDate}</p>
                          <div style={{ marginBottom: 3 }}>
                            <b>Time</b>
                          </div>
                          <p>{obj.setTime}</p>
                        </div>
                      ) : (
                        <div>This will display the name, date and time of when the day dot is set</div>
                      )}
                    </div>
                  }
                >
                  <div className="station-infoIcon">
                    <img className="station-infoIconImg" src={info} alt="info" />
                  </div>
                </Popover>
              </div>
              <ThumbnailImage
                className="station-ingredient-image-container"
                src={obj.ingredientImage ? obj.ingredientImage : null}
                placeholderIcon={<ChefIcon />}
              />
              <div className="station-ingredient-title-container">
                <div className="station-ingredient-title">{key}</div>
                <div className="station-ingredient-measure">{`${obj.formattedQty} ${obj.measurementName !== 'nomeas' ? obj.measurementName : ''}`}</div>
                {obj.preparationName ? (
                  <div className="station-ingredient-preparation">
                    {obj.preparationName}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
          {obj.type == 'parent' && (
            <span className="row-parent-title">
              {key}{' '}
              <div className="station-ing-totals">
                ({' '}
                {obj.children.length == 1
                  ? obj.children.length + ' ' + 'ingredient'
                  : obj.children.length + ' ' + 'ingredients'}{' '}
                )
              </div>
            </span>
          )}
        </span>
      ),
    },
    // {
    //   title: 'Qty',
    //   dataIndex: 'ingredientQty',
    //   width: 50,
    //   className: 'align-right',
    //   sorter: (a, b) => parseFloat(a.ingredientQty) - parseFloat(b.ingredientQty),
    //   sortDirections: ['descend', 'ascend'],
    //   render: (key, obj) => (
    //     <span>{obj.type == 'child' && key != null ? <span className="station-name-filter">{obj.formattedQty}</span> : ''}</span>
    //   ),
    // },
    // {
    //   title: 'Measurement',
    //   dataIndex: 'measurementName',
    //   width: 120,
    //   //className: 'align-center',
    //   sorter: (a, b) =>
    //     a.measurementName.toLowerCase() < b.measurementName.toLowerCase()
    //       ? -1
    //       : a.measurementName.toLowerCase() > b.measurementName.toLowerCase()
    //       ? 1
    //       : 0,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (key, obj) => (
    //     <span>{obj.type == 'child' && key ? <span className="station-name-filter">{key}</span> : ''}</span>
    //   ),
    // },
    // {
    //   title: 'Preparation',
    //   dataIndex: 'preparationName',
    //   width: 110,
    //   //className: 'align-center',
    //   sorter: (a, b) =>
    //     a.preparationName.toLowerCase() < b.preparationName.toLowerCase()
    //       ? -1
    //       : a.preparationName.toLowerCase() > b.preparationName.toLowerCase()
    //       ? 1
    //       : 0,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (key, obj) => (
    //     <span>{obj.type == 'child' && key ? <span className="station-name-filter">{key}</span> : ''}</span>
    //   ),
    // },
    {
      title: 'Quality check',
      dataIndex: 'healthCheck',
      width: 60,
      className: 'align-center',
      sorter: (a, b) => (a.healthCheck < b.healthCheck ? -1 : a.healthCheck > b.healthCheck ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {obj.type == 'child' && key ? (
            <span className="station-healthcheck-icon" alt="healthcheck" onClick={(e) => toggleHealth(e, obj)}>
              <HealthcheckIcon completed={key === 2} dimmed={false} />
            </span>
          ) : (
            ''
          )}
        </span>
      ),
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      width: 60,
      className: 'align-center',
      sorter: (a, b) => (a.completed < b.completed ? -1 : a.completed > b.completed ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {obj.type == 'child' && key ? (
            <span>
              {key === 2 && (
                <span className="station-checkmark-icon" onClick={(e) => toggleCompleted(e, obj)}>
                  <CompletionIcon completed={true} dimmed={false} />
                </span>
              )}
              {obj.healthCheck === 2 && key !== 2 && (
                <span className="station-checkmark-icon" onClick={(e) => toggleCompleted(e, obj)}>
                  <CompletionIcon completed={false} dimmed={false} />
                </span>
              )}
              {obj.healthCheck !== 2 && key !== 2 && (
                <span className="station-checkmark-icon" onClick={(e) => toggleCompleted(obj)}>
                  <CompletionIcon completed={false} dimmed={true} />
                </span>
              )}
            </span>
          ) : (
            ''
          )}
        </span>
      ),
    },
    {
      title: () => {
        return (
          <div>
            <RoleAccessibleComponent
              permittedRoles={[
                'Chef',
                'Admin',
                'Superadmin',
                'Client Admin',
                'Dev Team',
                'Account Holder',
                'Billing',
                'Buyer',
              ]}
            >
              <div>Needed</div>
            </RoleAccessibleComponent>
          </div>
        );
      },
      dataIndex: 'ordered',
      width: 60,
      className: 'align-center',
      sorter: (a, b) => (a.needed < b.needed ? -1 : a.needed > b.needed ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <RoleAccessibleComponent
          permittedRoles={[
            'Chef',
            'Admin',
            'Superadmin',
            'Client Admin',
            'Dev Team',
            'Account Holder',
            'Billing',
            'Buyer',
          ]}
        >
          <span>
            {obj.type == 'child' && (
              <span className="station-shoppingcart-icon" alt="ordered-status" onClick={(e) => toggleOrdered(obj)}>
                <ShoppingCartIcon needed={key === 0} ordered={key === 1} dimmed={false} />
              </span>
            )}
          </span>
        </RoleAccessibleComponent>
      ),
    },
  ];

  const handleRowExpand = (record) => {
    let openRows = JSON.parse(localStorage.getItem('stationOpenRows'));
    if (expandedRows.includes(record.rowKey)) {
      let filterRecord = expandedRows.filter((key) => key !== record.rowKey);
      setExpandedRows(filterRecord);

      if (openRows) {
        let newRows = openRows.filter((val) => filterRecord.includes(val));
        localStorage.setItem('stationOpenRows', JSON.stringify(newRows));
      } else {
        localStorage.setItem('stationOpenRows', JSON.stringify(filterRecord));
      }

      //activeStation
      let getActiveStations = JSON.parse(localStorage.getItem('stationVisitedIds'));
      if (getActiveStations) {
        const newArr = [...getActiveStations, stationId];
        const uniqIds = [...new Set(newArr)];
        localStorage.setItem('stationVisitedIds', JSON.stringify(uniqIds));
      } else {
        localStorage.setItem('stationVisitedIds', JSON.stringify([stationId]));
      }
    } else {
      setExpandedRows([...expandedRows, record.rowKey]);

      if (openRows) {
        const newRows = [...openRows, ...expandedRows, record.rowKey];
        const uniqRows = [...new Set(newRows)];
        localStorage.setItem('stationOpenRows', JSON.stringify(uniqRows));
      } else {
        localStorage.setItem('stationOpenRows', JSON.stringify([...expandedRows, record.rowKey]));
      }

      let getActiveStations = JSON.parse(localStorage.getItem('stationVisitedIds'));
      if (getActiveStations) {
        const newArr = [...getActiveStations, stationId];
        const uniqIds = [...new Set(newArr)];
        localStorage.setItem('stationVisitedIds', JSON.stringify(uniqIds));
      } else {
        localStorage.setItem('stationVisitedIds', JSON.stringify([stationId]));
      }
    }
  };

  const handleRowExpand1 = (record) => {
    let openRows = JSON.parse(localStorage.getItem('stationOpenRows'));
    if (expandedRows.includes(record.key)) {
      let filterRecord = expandedRows.filter((key) => key !== record.key);
      setExpandedRows(filterRecord);

      if (openRows) {
        let newRows = openRows.filter((val) => filterRecord.includes(val));
        localStorage.setItem('stationOpenRows', JSON.stringify(newRows));
      } else {
        localStorage.setItem('stationOpenRows', JSON.stringify(filterRecord));
      }

      //activeStation
      let getActiveStations = JSON.parse(localStorage.getItem('stationVisitedIds'));
      if (getActiveStations) {
        const newArr = [...getActiveStations, stationId];
        const uniqIds = [...new Set(newArr)];
        localStorage.setItem('stationVisitedIds', JSON.stringify(uniqIds));
      } else {
        localStorage.setItem('stationVisitedIds', JSON.stringify([stationId]));
      }
    } else {
      setExpandedRows([...expandedRows, record.key]);

      if (openRows) {
        const newRows = [...openRows, ...expandedRows, record.key];
        const uniqRows = [...new Set(newRows)];
        localStorage.setItem('stationOpenRows', JSON.stringify(uniqRows));
      } else {
        localStorage.setItem('stationOpenRows', JSON.stringify([...expandedRows, record.key]));
      }

      let getActiveStations = JSON.parse(localStorage.getItem('stationVisitedIds'));
      if (getActiveStations) {
        const newArr = [...getActiveStations, stationId];
        const uniqIds = [...new Set(newArr)];
        localStorage.setItem('stationVisitedIds', JSON.stringify(uniqIds));
      } else {
        localStorage.setItem('stationVisitedIds', JSON.stringify([stationId]));
      }
    }
  };

  const collapseAll = () => {
    setExpandedRows([]);
    localStorage.setItem('stationOpenRows', JSON.stringify([]));
  };

  const updateStationIngredient = (fetchParams, ingredient, mappedUpdates) => {
    let clonedStationIngs = cloneDeep(pristineIngs);
    const ingIndex = findIndex(
      clonedStationIngs,
      (ing) =>
        ing.ingredientId === ingredient.ingredientId &&
        ing.recipeId === ingredient.recipeId &&
        ing.courseId === ingredient.courseId &&
        ing.menuId === ingredient.menuId
    );
    if (ingIndex > -1) {
      mappedUpdates.forEach((pair) => {
        const key = pair.key,
          value = pair.value;
        clonedStationIngs[ingIndex][key] = value;
      });
      setPristineIngs(clonedStationIngs);

      updateChecklist(fetchParams)
        .then((res) => {
          // notification.open({
          //   message: 'Successfully deactivated!',
          // });
          setTimeout(() => {
            updateIngredientsState(true);
          }, 1000);
        })
        .catch((err) => console.log(err));
    }
  };

  const toggleHealth = (checked, obj) => {
    let newValue = obj.healthCheck === 1 ? 2 : 1;
    let prepIng = {
      healthCheck: newValue,
      completed: obj.completed,
      ordered: obj.ordered,
      setDate: obj.setDate,
      setTime: obj.setTime,
      setBy: obj.setBy,
      quantity: obj.ingredientQty,
      cost: 0, // this is temporary, API will be updated to accomodate this field
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (stationType === 'event') {
      params.eventId = stationId;
    }

    if (stationType === 'kitchen') {
      params.stationId = stationId;
    }

    updateStationIngredient(params, obj, [{ key: 'healthCheck', value: newValue }]);
  };

  const toggleCompleted = (checked, obj) => {
    let newValue = obj.completed === 1 ? 2 : 1;
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: newValue,
      ordered: obj.ordered,
      setDate: obj.setDate,
      setTime: obj.setTime,
      setBy: obj.setBy,
      quantity: obj.ingredientQty,
      cost: 0, // this is temporary, API will be updated to accomodate this field
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (stationType === 'event') {
      params.eventId = stationId;
    }

    if (stationType === 'kitchen') {
      params.stationId = stationId;
    }
    updateStationIngredient(params, obj, [{ key: 'completed', value: newValue }]);
  };

  const updateIngredientOrderStatus = (obj, newStatus) => {
    // UPDATE STATION CHECKLIST
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: obj.completed,
      ordered: newStatus.toString(),
      setDate: obj.setDate,
      setTime: obj.setTime,
      setBy: obj.setBy,
      quantity: obj.ingredientQty,
      cost: 0, // this is temporary, API will be updated to accomodate this field,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };

    let toChecklistParams = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (stationType === 'event') {
      toChecklistParams.eventId = stationId;
    }

    if (stationType === 'kitchen') {
      toChecklistParams.stationId = stationId;
    }

    updateStationIngredient(toChecklistParams, obj, [{ key: 'ordered', value: newStatus }]);
  };

  const toggleOrdered = (obj) => {
    console.log('TOGGLED', obj);
    let newStatus;

    // from default/nonexistent in shopping list to "needed"
    if (obj.ordered == -1) {
      newStatus = 0;
      // from "needed" to "ordered" status in shopping list
    } else if (obj.ordered == 0) {
      newStatus = 1;
      // from "ordered" back to "needed" status in shopping list
    } else if (obj.ordered == 1) {
      newStatus = -1; // BUG 1201998443633183
    }
    // ADD / UPDATE SHOPPING LIST
    // if ingredient is not in shopping list, ADD
    if (obj.ordered == -1) {
      const currentDate = new Date() * 1;
      let toShoplistParams = {
        operatorId: (JSON.parse(localStorage.getItem('operator')) || {}).id,
        stationId: stationType === 'kitchen' ? stationId * 1 : null,
        eventId: stationType === 'event' ? stationId * 1 : null,
        ingredientId: obj.ingredientId,
        ingredientCategoryId: obj.categoryId,
        restaurantId: restaurantId,
        ordered: 0,
        dateTime: currentDate,
      };

      addItemToShop(toShoplistParams)
        .then((res) => {
          if (res?.results?.id) {
            obj.shoplistId = res.results.id;
            updateIngredientOrderStatus(obj, newStatus);
          } else {
            return;
          }
        })
        .catch((err) => console.log(err));

      // else, update the status
    } else {
      const currentDate = new Date() * 1;
      let changeStatParams = {
        operatorId: (JSON.parse(localStorage.getItem('operator')) || {}).id,
        dateUpdated: currentDate.toString(),
      };

      if (newStatus > 0) {
        itemOrdered(obj.shoplistId?.toString(), changeStatParams)
          .then((res) => {
            console.log('UPDATED ITEM STATUS TO ORDERED >>>>>>', obj.shoplistId);
            if (res.id === obj.shoplistId) {
              updateIngredientOrderStatus(obj, newStatus);
            }
          })
          .catch((err) => console.log(err));
      } else {
        deleteItem(obj.shoplistId?.toString())
          .then(() => {
            console.log('DELETED ORDERED ITEM >>>>>>>>', obj.shoplistId);
            updateIngredientOrderStatus(obj, newStatus);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const clearStation = () => {
    let stationIngredients = cloneDeep(pristineIngs);
    const fetchData = () => {
      const allRequests = stationIngredients.map((obj) => {
        const params = {
          menuId: obj.menuId,
          recipeId: obj.recipeId,
          ingredientId: obj.ingredientId,
          preparationId: obj.preparationId,
          measurementId: obj.measurementId,
          info: {
            healthCheck: 1,
            completed: 1,
            ordered: parseInt(obj?.ordered) != null ? obj.ordered : -1,
            setDate: obj?.setDate ? obj.setDate : 'unset',
            setTime: obj?.setTime ? obj.setTime : 'unset',
            setBy: obj?.setBy ? obj.setBy : 'unset',
            cost: 0,
            originalQty: obj?.originalQty ? obj.originalQty : 0,
            quantity: obj?.quantity ? obj.quantity : 0,
            order: obj.order
          },
        }

        if (stationType === 'kitchen') {
          params.stationId = stationId;
        }

        if (stationType === 'event') {
          params.eventId = stationId;
        }

        updateChecklist(params).then((response) => response);
      });

      // const removeShoppingLists = removeFromShop(stationType, stationId).then((response) => response);

      return Promise.all([...allRequests]);
    };

    fetchData().then((res) => {
      console.log('CLEARED ALL', res);
      let updatedPristineIngs = cloneDeep(pristineIngs).map((item) => {
        item.healthCheck = 1;
        item.completed = 1;
        item.ordered = parseInt(item?.ordered) != null ? item.ordered : -1;
        item.shoplistId = parseInt(item?.shoplistId) != null ? item.shoplistId : 0;
        item.setDate = item?.setDate ? item.setDate : 'unset';
        item.day = item?.setDate && item.setDate !== 'unset' ? moment(item.setDate).format('dddd') : 'Invalid date';
        item.setTime = item?.setTime ? item.setTime : 'unset';
        item.setBy = item?.setBy ? item.setBy : 'unset';
        return item;
      });
      setPristineIngs(updatedPristineIngs);
      setTimeout(() => {
        updateIngredientsState(true);
      }, 1000);
    });
  };

  const setActiveDay = (obj) => {
    let today = moment().format('YYYY-MM-DD');
    let todayTime = moment().format('LT');
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: obj.completed,
      ordered: obj.ordered.toString(),
      setDate: today,
      setTime: todayTime,
      setBy: userInfo.firstName + ' ' + userInfo.lastName,
      quantity: obj.ingredientQty,
      cost: 0, // this is temporary, API will be updated to accomodate this field,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (stationType === 'event') {
      params.eventId = stationId;
    }

    if (stationType === 'kitchen') {
      params.stationId = stationId;
    }

    let clonedStationIngs = cloneDeep(tableData);
    const recIndex = findIndex(clonedStationIngs, (rec) => rec.name === obj.categoryName);
    const ingIndex = findIndex(clonedStationIngs[recIndex]?.children, (ing) => ing.ingredientId === obj.ingredientId);
    if (clonedStationIngs[recIndex]) {
      clonedStationIngs[recIndex].children[ingIndex].day = moment(today).format('dddd');
      clonedStationIngs[recIndex].children[ingIndex].setBy = userInfo.firstName + ' ' + userInfo.lastName;
      clonedStationIngs[recIndex].children[ingIndex].setDate = today;
      clonedStationIngs[recIndex].children[ingIndex].setTime = todayTime;
    }

    updateStationIngredient(params, obj, [
      { key: 'day', value: moment(today).format('dddd') },
      { key: 'setDate', value: today },
      { key: 'setTime', value: todayTime },
      { key: 'setBy', value: `${userInfo.firstName} ${userInfo.lastName}` },
    ]);
  };

  const unsetDay = (obj) => {
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: obj.completed,
      ordered: obj.ordered.toString(),
      setDate: '',
      setTime: '',
      setBy: '',
      quantity: obj.ingredientQty,
      cost: 0, // this is temporary, API will be updated to accomodate this field,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (stationType === 'event') {
      params.eventId = stationId;
    }

    if (stationType === 'kitchen') {
      params.stationId = stationId;
    }

    updateStationIngredient(params, obj, [
      { key: 'day', value: 'Invalid date' },
      { key: 'setDate', value: '' },
      { key: 'setTime', value: '' },
      { key: 'setBy', value: '' },
    ]);
  };

  const setCompleteTask = (obj, isComplete) => {
    console.log(obj);
    const id = obj.key;
    const clonedChecklist = cloneDeep(selectedList2);
    const foundIndex = findIndex(clonedChecklist, { key: obj.parentId });
    const foundChildIndex = findIndex(clonedChecklist[foundIndex]?.children, { key: obj.key });
    const checkItem = clonedChecklist[foundIndex]?.children[foundChildIndex];

    const iteminfo = {
      checked: checkItem.complete == 2 ? checkItem.checked : !checkItem.checked,
      complete: checkItem.complete !== 2 ? 2 : 1,
      description: checkItem.description,
      details: checkItem.details,
      tags: checkItem.tags,
    };

    console.log(iteminfo);
    updateItem(id, { info: iteminfo }).then((res) => {
      localStorage.removeItem('openRecipePanelStations');
      // notification.open({
      //   message: 'Successfully updated task!',
      // });
      if (!hasUpdateChecklist) {
        setHasUpdate(true);
        eventSocket.emit(
          'updateStationTask',
          JSON.stringify({
            id: id,
          })
        );
      } else {
        setTimeout(() => {
          updateTaskState({ type: 'general', id: id });
        }, 1000);
        clonedChecklist[foundIndex].children[foundChildIndex].complete = iteminfo.complete;
        console.log(clonedChecklist);
        setSelectedList2(clonedChecklist);
      }
    });
  };

  const setCompleteTaskTile = (obj, isComplete) => {
    console.log(obj);
    const clonedChecklist = cloneDeep(selectedList);
    const foundIndex = findIndex(clonedChecklist, { key: obj.parentId });
    const foundChildIndex = findIndex(clonedChecklist[foundIndex]?.children, { key: obj.key });
    const checkItem = clonedChecklist[foundIndex]?.children[foundChildIndex];
    const foundTagIndex = findIndex(
      checkItem.tags,
      (e) => {
        return e.parentId == obj.parentId;
      },
      0
    );

    const tagItems = clonedChecklist[foundIndex]?.children[foundChildIndex].tags[foundTagIndex];

    const id = tagItems.key;

    const iteminfo = {
      checked: tagItems.complete == 2 ? tagItems.checked : !tagItems.checked,
      complete: tagItems.complete !== 2 ? 2 : 1,
      description: tagItems.description,
      details: tagItems.details,
      tags: tagItems.tagsList,
    };

    console.log(id, iteminfo);

    updateItem(id, { info: iteminfo }).then((res) => {
      localStorage.removeItem('openRecipePanelStations');
      // notification.open({
      //   message: 'Successfully updated task!',
      // });
      setTimeout(() => {
        updateTaskState({ type: 'general', id: id });
      }, 1000);
      clonedChecklist[foundIndex].children[foundChildIndex].complete = iteminfo.complete;
      console.log(clonedChecklist);
      setSelectedList(clonedChecklist);
    });
  };

  const closeDrawer = useCallback((data) => {
    updateVisible(false);
    updateVisible1(false);
    updateIngredientsState(true);
    updateDocumentsState(true);
  }, []);

  const showDrawer = () => {
    if (stationType === 'event') {
      updateVisible1(true);
      setDocLinks1({
        type: 'events',
        linkId: stationId,
      });
      setLinks1({
        type: 'events',
        linkId: stationId,
        info: { title: stationName },
      });
    } else {
      updateVisible(true);
      setDocLinks({
        type: 'kitchen',
        linkId: stationId,
      });
      setLinks({
        type: 'kitchen',
        linkId: stationId,
        info: { title: stationName },
      });
    }
  };

  const onClose = () => {
    updateVisible(false);
    updateVisible1(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    if (sorter.order) {
      setSortedInfo(sorter);
    } else {
      setSortedInfo(null);
    }
  };

  useEffect(() => {
    if (sortedInfo == null) {
      let taskOrder = JSON.parse(localStorage.getItem('stationOrder'));
      let recipeOrder = JSON.parse(localStorage.getItem('stationOrder1'));
      if (taskOrder) {
        setSelectedList2(taskOrder);
      }
      if (recipeOrder) {
        console.log(recipeOrder);
        setRecipeData(recipeOrder);
        localStorage.removeItem('recipeOrder');
        localStorage.removeItem('stationOrder');
      }
    }
  }, [sortedInfo]);

  return (
    <div>
      {showChecklists ? (
        /* shows checklists */
        <div className="common-controls-container controls-thin">
          <PlusButton className="flex-control-left" onClick={collapseAll}>
            Collapse all
          </PlusButton>
          {/* <div className="station-clear" onClick={clearStation}>
            Clear Station
          </div> */}
          <AttachmentIndicator
            className="flex-control-right flex-control-vert-center"
            count={documents}
            onClick={showDrawer}
          />
          {/* <div>
            {stationDetail.attachments}
            <img src={attach} className="station-attach-menu" alt="attachment" />
          </div> */}
          <CidekicDropdown overlay={menu1} className="flex-control select-wide" trigger={['click']}>
            {menuName ? menuName : 'All items'}
          </CidekicDropdown>
          {/* <div className="station-tableview" onClick={() => setShowTiles(true)}>
            Tiles
          </div>
          <div className="station-tableview" onClick={() => setShowTiles(false)}>
            List
          </div> */}
        </div>
      ) : (
        /* shows recipes instead */
        <div className="common-controls-container controls-thin">
          <PlusButton className="flex-control-left" onClick={collapseAll}>
            Collapse all
          </PlusButton>
          <RoleAccessibleComponent
            permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
          >
            <CidekicButton className="flex-control-mid button-transparent" onClick={clearStation}>
              Clear Station
            </CidekicButton>
          </RoleAccessibleComponent>
          <AttachmentIndicator
            className="flex-control-right flex-control-vert-center"
            count={documents}
            onClick={showDrawer}
          />
          {/* <div>
            {stationDetail.attachments}
            <img src={attach} className="station-attach-menu" alt="attachment" />
          </div> */}
          <CidekicDropdown overlay={menu} className="flex-control select-wide" trigger={['click']}>
            {menuName ? menuName : 'All ingredients'}
          </CidekicDropdown>
        </div>
      )}

      <div className={showChecklists ? 'station-table station-checklist' : 'station-table'}>
        {showChecklists ? (
          <Table
            columns={showTiles ? columnTiles : columnChecklist}
            dataSource={showTiles ? selectedList : selectedList2}
            rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
            pagination={false}
            scroll={{ y: `calc(100vh - var(--station-unfiltered-scroll-offset${progressOpen ? '' : '-closed'}))` }}
            onExpand={(expanded, record) => handleRowExpand1(record)}
            expandedRowKeys={expandedRows}
            onChange={handleChange}
          />
        ) : (
          <Table
            columns={columns}
            dataSource={recipeData}
            rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
            pagination={false}
            scroll={{ y: `calc(100vh - var(--station-unfiltered-scroll-offset${progressOpen ? '' : '-closed'}))` }}
            onExpand={(expanded, record) => handleRowExpand(record)}
            expandedRowKeys={expandedRows}
            onChange={handleChange}
            rowKey={'rowKey'}
          />
        )}
      </div>
      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={visible}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentList
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks={docLinks}
          links={links}
          isMobile={false}
          documentsIn="kitchen"
        />
      </Drawer>

      {/* events */}
      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={visible1}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentList
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks={docLinks1}
          links={links1}
          isMobile={false}
          documentsIn="events"
        />
      </Drawer>
    </div>
  );
};

export default StationGeneral;
