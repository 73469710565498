import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Table, Drawer, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { cloneDeep, remove, findIndex } from 'lodash';
// import _ from 'lodash';
import {
  searchDocuments,
  searchDocFolders,
  createDocumentFolder,
  updateDocFolder,
  searchDocLinks,
  createDocLinks,
  deleteLink,
} from '../../services/doc.service';
import DocumentsDetailPopup from '../../components/DocumentList/DocumentsDetailPopup';
import { docIcon, docThumbnailSrc } from '../../pages/Documents/DocUtils';
import {
  CompletionIcon,
  ThumbnailImage,
  ExpandingSearchPanel,
  CidekicButton,
  CidekicDropdown,
} from '../../components/common/common_ui';

// Atached document display for <Unknown> but Recipe mobile and desktop is done with DocumentList.js
// OK

const DocumentList2 = (props) => {
  const { windowSize, closeDrawer, docLinks1, links1, isMobile } = props;

  console.log(docLinks1);

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};

  const [showSearch, setShowSearch] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataSource1, setDataSource1] = useState([]);
  const [origSource, setOriginal] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [documentid, setDocumentId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [visible3, updateVisible3] = useState(false);
  // const [visible1, updateVisible1] = useState(false);
  // const [visible2, updateVisible2] = useState(false);
  // const [folderid, setFolderId] = useState('');
  const [rename, setRename] = useState(false);
  const [incTasks, setIncTasks] = useState('All');
  const [selectedDocument, setSelectedDocument] = useState({});
  const [allSelectedIds, setAllSelectedIds] = useState([]);
  const [allSelected, setAllSelected] = useState([]);

  useEffect(() => {
    searchDocLinks(docLinks1).then((res) => {
      console.log(res);
      let links = res;
      let removeDeleted = links.filter((l) => l.Document.folderId !== null);
      let selectedLinks = removeDeleted.map((l) => {
        return {
          name: l?.Document?.info?.title && l?.Document?.info?.title !== '' ? l?.Document?.info?.title : 'Untitled',
          content: l?.Document?.title,
          type: l?.Document?.info?.type,
          date_uploaded: moment(l?.Document?.info?.timestamp).utc().format('MM-DD-YYYY'),
          documentId: l?.documentId,
          category: l?.Document?.info?.type,
          info: { file: l?.Document?.info?.file, comments: l?.Document?.info?.comments },
          id: l.documentId,
          upload_date: l?.Document?.createdAt
            ? moment(l?.Document?.createdAt).format('MM-DD-YYYY')
            : moment(l?.Document?.createdAt).format('MM-DD-YYYY'),
          attachments: l?.Document?.info?.comments?.length,
          assignee: l?.Document?.info?.createdBy,
        };
      });
      console.log(selectedLinks);
      setDataSource(selectedLinks);
      setOriginal(selectedLinks);

      searchDocFolders({ restaurantId: resto.id }).then((res) => {
        let folders = [];
        let folderInfo = res.documentfolders;
        if (res) {
          for (let i = 0; i < (folderInfo || []).length; i++) {
            const { id, title } = folderInfo[i];

            let documentData = [];

            folders.push({
              key: id,
              folderId: id,
              name: title,
              type: 'parent',
              // children: documentData,
              children: [],
            });

            console.log(documentData);
            console.log(folders);
          }
          let getFolderIds = folders.map((f) => f.key);
          console.log(getFolderIds);

          const fetchData = () => {
            const allRequests = getFolderIds.map((e) => searchDocuments({ folderId: e }).then((res) => res));

            return Promise.all(allRequests);
          };

          fetchData().then((res) => {
            let docList = res;
            let merged = [].concat.apply([], docList);

            console.log(merged);

            let docListData = merged.map((d) => {
              return {
                folderId: d.folderId,
                id: d.id,
                name: d.info?.title,
                content: d.title && d.title !== '' ? d.title : '',
                type: 'child',
                info: d.info,
                category: d.info?.type,
                selectedTask: d.info?.selectedTasks,
                assignee: d.info?.createdBy,
                upload_date: d?.info?.timestamp
                  ? moment(d?.info?.timestamp).utc().format('MM-DD-YYYY')
                  : moment(d?.info?.updatedDate).utc().format('MM-DD-YYYY'),
                linked: d.info?.linked,
                attachments: d.info?.comments?.length,
                details: 'true',
              };
            });

            console.log(docListData);

            let dataCombined = folders.concat(docListData);

            console.log(dataCombined);

            const result = Object.values(
              dataCombined.reduce((r, o) => {
                const key = `${o.folderId}`;

                if (!r[key])
                  r[key] = {
                    ...o,
                  };

                r[key].children.unshift(o);

                r[key].children = [].concat
                  .apply([], r[key].children)
                  .filter((u) => u !== undefined && u.type !== 'parent');

                r[key].notes = r[key]?.children?.filter((c) => c?.info?.type == 'Note').length;

                r[key].uploads = r[key]?.children?.filter(
                  (c) => c?.info?.type !== 'Note' && c.type !== 'add-button'
                ).length;

                r[key].selectedTasks = [].concat.apply(
                  [],
                  r[key]?.children?.map((t) => t.selectedTask).filter((u) => u !== undefined)
                );

                r[key].children.sort((x, y) => {
                  return x.id - y.id;
                });

                console.log(r[key]);

                return r;
              }, {})
            );

            console.log('RESULT', result);

            let ids = selectedLinks.map((d) => d.documentId);

            const idMap = result.reduce(function merge(map, node) {
              map[node.id] = node;

              if (Array.isArray(node.children)) {
                node.children.reduce(merge, map);
              }

              return map;
            }, {});

            const items = ids.map((id) => idMap[id]);
            items.forEach((item) => (item.checked = true));

            console.log(items);

            setDataSource1(result);
            // setOriginal(result);
          });
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docLinks1, hasChanged]);

  const getAllFolders = () => {
    searchDocFolders({ restaurantId: resto.id }).then((res) => {
      let folders = [];
      let folderInfo = res.documentfolders;
      if (res) {
        for (let i = 0; i < (folderInfo || []).length; i++) {
          const { id, title } = folderInfo[i];

          let documentData = [];

          // documentData.push({
          //   // always on the last row
          //   key: Math.random().toString(),
          //   type: 'add-button',
          //   parent_id: id,
          // });

          folders.push({
            key: id,
            folderId: id,
            name: title,
            type: 'parent',
            // children: documentData,
            children: [],
          });

          console.log(documentData);
          console.log(folders);
        }
        getDocuments(folders);
      }
    });
  };

  const getDocuments = (folders) => {
    let getFolderIds = folders.map((f) => f.key);
    console.log(getFolderIds);

    const fetchData = () => {
      const allRequests = getFolderIds.map((e) => searchDocuments({ folderId: e }).then((res) => res));

      return Promise.all(allRequests);
    };

    fetchData().then((res) => {
      let docList = res;
      let merged = [].concat.apply([], docList);

      console.log(merged);

      let docListData = merged.map((d) => {
        return {
          folderId: d.folderId,
          id: d.id,
          name: d.info?.title,
          content: d.title && d.title !== '' ? d.title : '',
          type: 'child',
          info: d.info,
          category: d.info?.type,
          selectedTask: d.info?.selectedTasks,
          assignee: d.info?.createdBy,
          upload_date: d?.info?.timestamp
            ? moment(d?.info?.timestamp).utc().format('MM-DD-YYYY')
            : moment(d?.info?.updatedDate).utc().format('MM-DD-YYYY'),
          linked: d.info?.linked,
          attachments: d.info?.comments?.length,
          details: 'true',
        };
      });

      console.log(docListData);

      let dataCombined = folders.concat(docListData);

      console.log(dataCombined);

      const result = Object.values(
        dataCombined.reduce((r, o, i) => {
          const key = `${o.folderId}`;

          if (!r[key])
            r[key] = {
              ...o,
            };

          r[key].children.unshift(o);

          r[key].children = [].concat.apply([], r[key].children).filter((u) => u !== undefined && u.type !== 'parent');

          r[key].notes = r[key]?.children?.filter((c) => c?.info?.type == 'Note').length;

          r[key].uploads = r[key]?.children?.filter((c) => c?.info?.type !== 'Note' && c.type !== 'add-button').length;

          r[key].selectedTasks = [].concat.apply(
            [],
            r[key]?.children?.map((t) => t.selectedTask).filter((u) => u !== undefined)
          );

          r[key].children.sort((x, y) => {
            return x.id - y.id;
          });

          console.log(r[key]);

          return r;
        }, {})
      );

      console.log('RESULT', result);
      console.log(
        'LINKS',
        dataSource.map((d) => d.documentId)
      );

      let ids = dataSource.map((d) => d.documentId);

      const idMap = result.reduce(function merge(map, node) {
        map[node.id] = node;

        if (Array.isArray(node.children)) {
          node.children.reduce(merge, map);
        }

        return map;
      }, {});

      const items = ids.map((id) => idMap[id]);
      items.forEach((item) => (item.checked = true));

      console.log(items);

      setDataSource1(result);
      // setOriginal(result);
    });
  };

  // useEffect(() => {
  // searchDocuments({ folderId: '87' }).then((res) => {
  //   let docs = res;
  //   console.log(docs);
  // });
  //   getAllFolders();
  // }, [hasChanged, dataSource]);

  const onClose = () => {
    closeDrawer(true);
  };

  const onClose1 = () => {
    updateVisible3(false);
  };

  const closedDrawer = useCallback((data) => {
    updateVisible3(false);
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: () => {
        return isMobile ? 200 : 400;
      },
      render: (key, obj) => (
        <span onClick={(e) => showDrawer(e, obj)} style={{ cursor: 'pointer' }}>
          {obj.type !== 'Note' ? obj.content : key}
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Date uploaded',
      dataIndex: 'upload_date',
      render: (key, obj) => <span>{key}</span>,
    },
  ];

  const handleRowExpand = (record) => {
    if (expandedRows.includes(record.key)) {
      let filterRecord = expandedRows.filter((key) => key !== record.key);
      setExpandedRows(filterRecord);
    } else {
      setExpandedRows([...expandedRows, record.key]);
    }
  };

  const toggleSelect = (e, obj, status) => {
    if (status == 'selected') {
      let addSelectedLinks = dataSource1.map((o) => {
        o.children.map((c) => {
          if (obj.id == c.id) {
            c.checked = false;
          }
          return c;
        });
        return o;
      });

      setDataSource1(addSelectedLinks);
      let filteredIds = allSelectedIds.filter((item) => item !== obj.id);
      setAllSelectedIds(filteredIds);

      //call delete link API
      deleteLink(obj.id, 'menus', links1.linkId).then((res) => res);
    } else {
      let addSelectedLinks = dataSource1.map((o) => {
        o.children.map((c) => {
          if (obj.id == c.id) {
            c.checked = true;
          }
          return c;
        });
        return o;
      });

      const value = [
        {
          linkId: links1.linkId,
          info: { title: { label: links1.info?.title, value: links1.linkId } },
          type: 'menus',
        },
      ];
      setDataSource1(addSelectedLinks);
      setAllSelectedIds((oldArray) => [...oldArray, obj.id]);
      setAllSelected(value);
    }
  };

  const columns1 = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: (isMobile ? '55%' : 300),
      ellipsis: true,
      render: (key, obj) => (
        <span>
          <span className={obj.type == 'parent' ? '' : 'doc-name-key1'}>
            {obj.type == 'child' ? (
              <span>
                {obj.checked ? (
                  <span className="checkmark-icon" onClick={(e) => toggleSelect(e, obj, 'selected')}>
                    <CompletionIcon completed={true} dimmed={false} />
                  </span>
                ) : (
                  <span className="checkmark-icon" onClick={(e) => toggleSelect(e, obj, 'unselected')}>
                    <CompletionIcon completed={false} dimmed={false} />
                  </span>
                )}
              </span>
            ) : (
              ''
            )}
            {obj.type == 'child' ? (
              <ThumbnailImage
                className="doc-attached-image-container"
                src={docThumbnailSrc(obj)}
                placeholderIcon={docIcon(obj)}
                onClick={(e) => showDrawer(e, obj)}
              />
            ) : (
              ''
            )}
            {obj.type == 'child' || obj.type == 'add-button' ? (
              <span className="doc-attached-title-container" onClick={() => setDocProps(obj)}>
                {key && (
                  <span>
                    <Link
                      target={obj.category !== 'Note' ? '_blank' : ''}
                      to={
                        obj.category !== 'Note'
                          ? {
                              pathname: `/document/public/${obj.id}`,
                            }
                          : '/documents'
                      }
                    >
                      {' '}
                      {key}
                    </Link>
                  </span>
                )}
              </span>
            ) : obj.type === 'parent' && documentid == obj.key && isEditing ? (
              <input
                autoFocus
                type="text"
                className="document-bar1"
                defaultValue={obj.name}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddDocumentFolder(obj);
                  }
                }}
                onBlur={(e) => saveDocumentFolder(e, obj)}
                onChange={(e) => {
                  const cloneList = cloneDeep(dataSource);
                  const index = findIndex(cloneList, { key: obj.key });
                  cloneList[index].name = e.target.value;
                  // setDataSource(cloneList);
                }}
                placeholder="Enter document folder name"
              />
            ) : (
              <span className="attached-doc-folder-title">{key}</span>
            )}

            {/*
            {obj.type === 'parent' && obj.key !== 0 ? (
              <div className="attached-doc-upload-count">
                ( {obj.children.filter((j) => j.checked !== obj.checked).length} of {obj.children.length} selected )
              </div>
            ) : (
              ''
            )}

            {obj.type == 'child' && obj.attachments > 0 ? (
              <span className={'doc-attached-attachments-count'}>{obj.attachments}</span>
            ) : (
              ''
            )}
            {obj.type == 'child' && obj.attachments > 0 ? (
              <span className={'doc-attached-attachments-icon'}>
                {' '}
                <img src={attach} className="comment" alt="comment" />
              </span>
            ) : (
              ''
            )}
            */}
            {/* {obj.type == 'add-button' ? (
              <span>
                {!key && (
                  <span className="doc-item-add" onClick={(e) => showDocForm(e, obj.parent_id)}>
                    Upload
                  </span>
                )}
                {!key && (
                  <span className="doc-item-add" onClick={(e) => showNote(e, obj.parent_id)}>
                    Create note
                  </span>
                )}
              </span>
            ) : (
              ''
            )} */}
          </span>
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'category',
      width: (isMobile ? '20%' : '20%'),
      sorter: (a, b) => (a.category < b.category ? -1 : a.category > b.category ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (key, obj) => <span>{obj.type == 'child' && key ? <span>{key}</span> : ''}</span>,
    },
    {
      title: (isMobile ? 'Uploaded' : 'Date uploaded'),
      dataIndex: 'upload_date',
      width: (isMobile ? '25%' : '25%'),
      sorter: (a, b) => (a.upload_date < b.upload_date ? -1 : a.upload_date > b.upload_date ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Linked',
    //   dataIndex: 'linked',
    //   sorter: (a, b) => (a.linked < b.linked ? -1 : a.linked > b.linked ? 1 : 0),
    //   sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: 'Initiated by',
    //   dataIndex: 'assignee',
    //   sorter: (a, b) => (a.assignee < b.assignee ? -1 : a.assignee > b.assignee ? 1 : 0),
    //   sortDirections: ['descend', 'ascend'],
    //   ellipsis: true,
    //   render: (key, obj) => (
    //     <span>
    //       {obj.type == 'child' && key ? <img src={user} className="user" alt="user-photo" /> : ''}
    //       {obj.type == 'child' && key ? <span>{key}</span> : ''}
    //     </span>
    //   ),
    // },
    // {
    //   title: 'Details',
    //   dataIndex: 'details',
    //   sorter: (a, b) => (a.details < b.details ? -1 : a.details > b.details ? 1 : 0),
    //   sortDirections: ['descend', 'ascend'],
    //   render: (key, obj) => (
    //     <span>
    //       {obj.type == 'child' && (
    //         <span className="docs-details" onClick={(e) => showDrawer(e, obj)}>
    //           Details
    //         </span>
    //       )}
    //     </span>
    //   ),
    // },
    // {
    //   title: '',
    //   dataIndex: 'delete',
    //   width: 50,
    //   render: (key, obj) => (
    //     <span>
    //       {/* {obj.type == 'child' && (
    //         <span>
    //           {' '}
    //           <Dropdown overlay={deletesTask(obj)}>
    //             <div className="toggle-switch" onMouseOver={(e) => setDeleteTask(obj)}>
    //               <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
    //             </div>
    //           </Dropdown>
    //         </span>
    //       )} */}

    //       {obj.type == 'parent' && obj.key !== 0 && (
    //         <span>
    //           {' '}
    //           <Dropdown overlay={docFolder(obj)}>
    //             <div className="toggle-switch">
    //               <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
    //             </div>
    //           </Dropdown>
    //         </span>
    //       )}
    //     </span>
    //   ),
    // },
  ];

  const setDocProps = (obj) => {
    console.log(obj);
    if (obj.category !== 'Note') {
      localStorage.setItem(
        'docProps',
        JSON.stringify({
          name: obj.name,
          date: obj.upload_date,
          file: obj.info?.file,
          type: obj.info?.type,
        })
      );
    } else {
      localStorage.removeItem('docProps');
    }
  };

  const handleAddDocumentFolder = (info) => {
    setHasChanged(true);
    if (rename) {
      updateDocFolder(info?.key, { title: info?.name.trim() }).then((res) => {
        setIsEditing(false);
        setHasChanged(false);
        setDocumentId('0');
        setRename(false);
        getAllFolders();
      });
    } else {
      createDocumentFolder({ title: info?.name.trim(), restaurantId: resto.id }).then((res) => {
        setHasChanged(false);
        setIsEditing(false);
      });
    }
  };

  const saveDocumentFolder = (e, info) => {
    e.preventDefault();
    console.log('DOC FOLDER', info);
    if (!info?.name) {
      const clonedList = cloneDeep(dataSource);
      remove(clonedList, (cl) => cl.key === 0);
      console.log(clonedList);
      // setDataSource(clonedList);
    } else {
      if (rename) {
        updateDocFolder(info?.key, { title: info?.name.trim() }).then((res) => {
          setIsEditing(false);
          setHasChanged(false);
          setDocumentId('0');
          setRename(false);
          getAllFolders();
        });
      } else {
        createDocumentFolder({ title: info?.name.trim(), restaurantId: resto.id }).then((res) => {
          setHasChanged(false);
          setIsEditing(false);
        });
      }
    }
  };

  const showDrawer = (e, obj) => {
    //updateVisible(true);
    updateVisible3(true);
    setSelectedDocument(obj);
    if (obj.category !== 'Note') {
      localStorage.setItem(
        'docProps',
        JSON.stringify({
          name: obj.name,
          date: obj.upload_date,
          file: obj.info?.file,
          type: obj.type,
        })
      );
    } else {
      localStorage.removeItem('docProps');
    }
  };

  // const showNote = (e, folderId) => {
  //   e.preventDefault();
  //   updateVisible1(true);
  //   setFolderId(folderId);
  // };

  // const showDocForm = (e, folderId) => {
  //   e.preventDefault();
  //   updateVisible2(true);
  //   setFolderId(folderId);
  // };

  // const docFolder = (action) => {
  //   return (
  //     <Menu className="menu-show-preview">
  //       <Menu.Item className="menu-show-preview" onClick={() => renameFolder(action)}>
  //         <span>Rename</span>
  //       </Menu.Item>
  //       <Menu.Item className="menu-show-preview" onClick={() => deleteFolder(action)}>
  //         <span>Delete</span>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // };

  // const renameFolder = (info) => {
  //   console.log('RENAME THIS FOLDER', info?.key);
  //   setDocumentId(info?.key);
  //   setIsEditing(true);
  //   setRename(true);
  // };

  // const deleteFolder = (info) => {
  //   console.log(info);
  //   if (info?.key > 0) {
  //     setHasChanged(true);
  //     destroyDocFolder(info?.key).then((res) => {
  //       console.log('Resss', res);
  //       setHasChanged(false);
  //       // notification.open({
  //       //   message: 'Document Folder Successfully deleted!',
  //       // });
  //     });
  //   } else {
  //     const clonedList = cloneDeep(dataSource);
  //     remove(clonedList, (cl) => cl.key === 0);
  //     console.log(clonedList);
  //     // setDataSource(clonedList);
  //   }
  // };

  const incompleteTasks = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={() => filterTask('all')}>
        <span>All</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={() => filterTask('notes')}>
        <span>Notes</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={() => filterTask('documents')}>
        <span>Documents</span>
      </Menu.Item>
    </Menu>
  );

  const filterTask = (status) => {
    if (status == 'documents') {
      let documents = origSource.filter((obj) => obj.type !== 'Note');
      setDataSource(documents);
      setIncTasks('All');
    } else if (status == 'notes') {
      let notes = origSource.filter((obj) => obj.type == 'Note');
      setDataSource(notes);
      setIncTasks('Notes');
    } else if (status == 'all') {
      let all = origSource;
      setDataSource(all);
      setIncTasks('All');
    }
  };

  const saveClose = () => {
    console.log(allSelectedIds);
    console.log(allSelected);
    console.log(links1);

    const fetchData = () => {
      const allRequests = allSelectedIds.map((e) => createDocLinks(e, allSelected).then((res) => res));

      return Promise.all(allRequests);
    };

    fetchData().then((res) => {
      // notification.open({
      //   message: 'Document Successfully updated!',
      // });
      closeDrawer(true);
      setHasChanged(true);
    });
  };

  const searchDoc = (e) => {
    let keyword = e.target.value;
    const filtered = origSource.filter((entry) =>
      Object.values(entry).some((val) => typeof val === 'string' && val.includes(keyword))
    );
    setDataSource(filtered);
  };

  return (
    <div>
      <br />
      <div className="add-course-box-update">
        <CidekicButton className="button-highlight" alt="save and close" onClick={saveClose}>
          Save and close
        </CidekicButton>
        {windowSize.width > 700 ? (
          <div className="close-drawer flex-control-right" onClick={onClose}>
            X <span className="close-text">Close</span>
          </div>
        ) : (
          <div className="close-drawer flex-control-right" onClick={onClose}>
            X
          </div>
        )}
      </div>
      <br />
      <div className="add-course-box-update">
        <CidekicDropdown overlay={incompleteTasks} className="doc-drawer-select">
          {incTasks}
        </CidekicDropdown>
        <ExpandingSearchPanel
          className="flex-control-right"
          showSearch={showSearch}
          searchPrompt="Search documents"
          onChange={searchDoc}
          onClick={() => setShowSearch(true)}
        />
      </div>
      <br />
      <div className="doc-table1">
        <Table dataSource={dataSource} columns={columns} pagination={false} />{' '}
      </div>
      <br />
      <div className="add-course-box">
        <div>
          <div>
            <div className="add-ing-text1">Add more documents</div>
          </div>
          <br />
          <div className="doc-attached-table">
            <Table
              columns={columns1}
              dataSource={dataSource1}
              rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
              pagination={false}
              onExpand={(expanded, record) => handleRowExpand(record)}
              expandedRowKeys={expandedRows}
            />
          </div>
        </div>
      </div>
      <Drawer
        width={windowSize.width > 800 ? '400px' : '100%'}
        onClose={onClose1}
        visible={visible3}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentsDetailPopup data={selectedDocument} closedDrawer={closedDrawer} />
      </Drawer>
    </div>
  );
};

export default DocumentList2;
