import React from 'react';
import './index.css';

export const InputSelectCombo = (props) => {
  const {
    formLabel,
    onChange,
    keyword,
    onFocus,
    onBlur,
    showValue,
    selectVal,
    setInputVal,
    setShowVal,
    onChangeSelect,
    inputClassMobile,
    windowSize,
  } = props;

  return (
    <div className="col">
      <div className="prep-title">{formLabel}</div>
      <div className="flex-grid">
        <div className="smallcol">
          <input
            type="text"
            className={inputClassMobile ? inputClassMobile : 'recipe-input-smx'}
            value={showValue}
            onChange={(e) => onChange(e, keyword)}
            onFocus={() => onFocus(keyword)}
            onBlur={(e) => onBlur(e.target.value, setInputVal, setShowVal, keyword)}
          />
        </div>
        <div className="col">
          <select
            className={windowSize > 700 ? 'recipe-metadata-select' : 'recipe-metadata-select'}
            value={selectVal}
            onChange={(e) => onChangeSelect(e.target.value, keyword)}
          >
            <option key="0" value="Select" initialvalue="default">
              Select
            </option>
            {props.children}
          </select>
        </div>
      </div>
    </div>
  );
};

export default InputSelectCombo;
