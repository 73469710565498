/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Table, Menu, Dropdown } from 'antd';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { all, destroy, update, create } from '../../services/account.service';
import { impersonate } from '../../services/operator.service';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';

const AccountsMobile = (props) => {
  const history = useHistory();
  const [showSearch, setShowSearch] = useState(false);
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [isAdding, setAdding] = useState(false);

  const location_name = useRef(null);

  useEffect(() => {
    console.log(props.location.state);
    if (props.location.state == 'unimpersonate') {
      setHasUpdated(true);
    }
  }, [props.location.state]);

  useEffect(() => {
    getAccounts();
    setTimeout(() => {
      setHasUpdated(false);
      setEditing(false);
      setAdding(false);
      setEditId(null);
    }, 2000);
  }, [hasUpdated]);

  const getAccounts = () => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setData(allAccounts);
        setOrigData(allAccounts);
      }
    });
  };

  const filterMeas = useCallback((e, origData) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getAccounts();
    }
  }, []);

  useEffect(() => {
    filterMeas(nameFilter, origData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter, filterMeas]);

  const deleteAccount = (e) => {
    e.preventDefault();
    let id = selected?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Account Successfully Deleted',
      // });
      setHasUpdated(true);
    });
  };

  const editAccount = (e, obj) => {
    e.preventDefault();
    console.log(obj);
    setEditing(true);
    setEditId(obj.id);
  };

  const impersonateUser = (e, obj) => {
    let changeOperator = localStorage.getItem('operator');
    let changeResto = localStorage.getItem('restaurant');
    let changeRestoId = localStorage.getItem('restaurantId');
    localStorage.setItem('operator2', changeOperator);
    localStorage.setItem('restaurant2', changeResto);
    localStorage.setItem('restaurantId2', changeRestoId);
    impersonate(obj.id).then((res) => {
      if (res) {
        console.log('NEW OPERATOR', res);
        localStorage.setItem('operator', JSON.stringify(res));
        localStorage.setItem(
          'restaurant',
          res.restaurants.length !== 0 ? JSON.stringify(res.restaurants[0]) : JSON.stringify({ id: 0, name: '' })
        );
        localStorage.setItem('restaurantId', res.restaurants.length !== 0 ? res.restaurants[0].id : 0);
        localStorage.setItem('impersonate', JSON.stringify({ name: obj.name, id: obj.id }));
        history.push({
          pathname: '/admin/users',
          state: 'impersonate',
          search: '?impersonate=impersonate',
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
    });
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => impersonateUser(e, obj)}>Impersonate</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editAccount(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteAccount(e)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updateAccount = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename,
      info: {
        name: rename,
      },
    };
    console.log(params);
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated account!',
      // });
      setHasUpdated(true);
    });
  };

  const updateAccount2 = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename,
      info: {
        name: rename,
      },
    };
    console.log(params);
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated account!',
      // });
      setHasUpdated(true);
    });
  };

  const addAccount = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename !== '' ? rename : 'Untitled',
      info: { name: rename !== '' ? rename : 'Untitled' },
    };
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created account!',
      // });
      setHasUpdated(true);
    });
  };

  const addAccount2 = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename !== '' ? rename : 'Untitled',
      info: { name: rename !== '' ? rename : 'Untitled' },
    };
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created account!',
      // });
      setHasUpdated(true);
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div>
            <div>
              <Dropdown overlay={controlItems(obj)}>
                <div style={{ cursor: 'pointer', float: 'right' }}>
                  <img
                    src={IconsMoreOff}
                    className="UI-IconsMoreOff"
                    alt="IconsMoreOff"
                    onClick={() => setSelected(obj)}
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            {isEditing && editId == obj.id ? (
              <div>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      updateAccount(e, obj);
                    }
                  }}
                  onBlur={(e) => updateAccount2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className={isAdding && obj.id == 'add' ? '' : 'loc_nameMob190'}>{obj.name}</div>
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addAccount(e, obj);
                    }
                  }}
                  onBlur={(e) => addAccount2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div
            onClick={(e) => impersonateUser(e, obj)}
            style={{ display: 'flex', marginTop: 15, color: 'var(--accent-color)', cursor: 'pointer' }}
          >
            Impersonate
          </div>
        </span>
      ),
    },
  ];

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Accounts"
        restaurant="Super Admin"
        showSearch={showSearch}
        onChange={(evt) => setNameFilter(evt.target.value)}
        clickSearch={() => setShowSearch(true)}
      />
      <div style={{ display: 'flex' }}>
        <div className="spacer-cat"></div>
        <div
          className="add-cat13Mob"
          onClick={() => {
            //setIsAdding(true);
          }}
        >
          <Link to={'/super/accounts/new'}>
            <div style={{ cursor: 'pointer', fontFamily: 'Helvetica Neue' }}>
              + <span style={{ marginLeft: 5 }}>Add account</span>
            </div>
          </Link>
        </div>
      </div>
      <div style={{ padding: '30px 20px 0px 15px' }}>
        <Table dataSource={userData} columns={columns} pagination={false} />
      </div>{' '}
      <div className="add-checklistLoc">
        <Link to={'/super/accounts/new'}>
          <div className="add-checklist-but">
            <span>
              <PlusOutlined />
            </span>{' '}
            Add account
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AccountsMobile;
