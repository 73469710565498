/* eslint eqeqeq: "off" */
import { sortBy } from 'lodash';
import { getStationOperators, createKitchenStation, getKitchenStations } from '../../services/station.service';
import { all } from '../../services/operator.service';

export const getKitchenOperators = (searchedAssignee, setOperators, id) => {
  // TODO ====
  getStationOperators({ id: id, name: searchedAssignee }).then((res) => {
    setOperators(res);
  });
  return [];
};

export const createStation = (stationData, resetForm, id, setHasCreatedKitchen) => {
  const { assignee_id, station } = stationData;
  // setHasCreatedStation(true);
  createKitchenStation({ restaurantId: id, name: station.trim(), operatorId: assignee_id }).then((res) => {
    // setHasCreatedStation(false);
    // notification.open({
    //   message: `${KITCHENSTATIONNAME} Successfully Created`,
    // });
    resetForm();
    setHasCreatedKitchen(true);
  });
};

export const getKitchenStationList = (setKitchenStations, id) => {
  if (id) {
    getKitchenStations(id).then((res) => {
      let kitchensSort = res?.kitchens?.map((k) => {
        return {
          ...k,
          order: k.info ? JSON.parse(k.info)?.order || 0 : 0,
        };
      });

      //get users
      all(0, 1000).then((res) => {
        console.log(res);

        let userData = res.map((r) => {
          let arr = [],
            keys = Object.keys(r.restaurants);

          for (let i = 0, n = keys.length; i < n; i++) {
            let key = keys[i];
            arr[key] = r.restaurants[key];
          }

          return {
            operatorid: r.id,
            username: r.firstName + ' ' + r.lastName,
            assigneeImg: r.info ? r.info?.userImageThumb || r.info?.userImage : '',
            deletedAt: r.deletedAt,
          };
        });
        let usersData = userData.filter((u) => u.deletedAt === '');

        if (kitchensSort && kitchensSort.length > 0) {
          const getUserImg = kitchensSort.map((v) => ({ ...v, ...usersData.find((sp) => sp.username == v.assignee) }));

          const sorted = sortBy(getUserImg, ['id']).reverse();

          const sortByOrder = sortBy(sorted, ['order']);

          sortByOrder.forEach((k) => {
            k.key = k.id;
          });

          console.log(sortByOrder);
          setKitchenStations(sortByOrder);
        } else {
          setKitchenStations([]);
        }
      });
    });
  }
};
