import React, { useState, useEffect, useCallback } from 'react';
//import { Link } from 'react-router-dom';
import { Input } from 'antd';
import { getKitchenStations } from '../../services/station.service';
import { searchEvents } from '../../services/event.service';
import { search } from '../../services/recipe.service';
import { create, createDocLinks } from '../../services/doc.service';
import { getCheckList } from '../../services/checklist.service';
import { searchMenu } from '../../services/menu.service';
import AddDoc from './AddDoc';
//import { KITCHENSTATIONNAME } from '../../components/common/constant.js';
import { PlusButton, UserImage, ThumbnailImage } from '../../components/common/common_ui';

//const { Panel } = Collapse;

const UploadDocument = (props) => {
  const { closeDrawer, folder, visible, setAddedDocument, handleNewDocument } = props;
  //const [kitchenStations, setKitchenStations] = useState([]);
  //const [eventStations, setEventStations] = useState([]);
  // const [recipes, setRecipes] = useState([]);
  //const [tasks, setTasks] = useState([]);
  const [selectedKitchens, setSelectedKitchens] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [createdComment, setCreateComment] = useState([]);
  //const [menus, setMenus] = useState([]);
  const [docName, setDocName] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [type, setType] = useState('');
  const [showImg, setShowImg] = useState(false);
  //const [checkedKitchens, setCheckedKitchens] = useState([]);
  //const [checkedEvents, setCheckedEvents] = useState([]);
  //const [checkedMenus, setCheckedMenus] = useState([]);
  //const [checkedRecipes, setCheckedRecipes] = useState([]);
  //const [checkedTasks, setCheckedTasks] = useState([]);

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  //let operator = JSON.parse(localStorage.getItem('operator')) || {};
  const user_id = (JSON.parse(localStorage.getItem('operator')) || {}).id;
  const user_email = (JSON.parse(localStorage.getItem('operator')) || {}).email;
  const userImage =
    JSON.parse(localStorage.getItem('operator') || {})?.info?.userImageThumb ||
    JSON.parse(localStorage.getItem('operator') || {})?.info?.userImage;

  useEffect(() => {
    console.log(folder);
    let params = {
      restaurantId: resto.id,
      startTimestamp: '315626789',
      endTimestamp: '64060679385',
      from: 0,
      limit: 1000,
    };

    getCheckList({ restaurantId: resto.id, user_id, email: user_email }).then((res) => {
      if (res) {
        let checklists = res.checklists;
        let items = checklists.map((c) => JSON.parse(c.ChecklistItems));
        let merged = [].concat.apply([], items);
        let taskOptions = merged.map((m) => {
          return {
            label: m.title,
            value: m.id,
          };
        });
        taskOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        //setTasks(taskOptions);
      }
    });

    search({ restaurantId: resto.id, from: 0, limit: 10000 }).then((res) => {
      console.log(res);
      let recipes = res.recipes;
      let recipeOptions = recipes.map((k) => {
        return {
          label: k.name,
          value: k.id,
        };
      });
      recipeOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      //setRecipes(recipeOptions);
    });

    searchEvents(params).then((res) => {
      let events = res;
      let eventOptions = events.map((k) => {
        return {
          label: k.name,
          value: k.id,
        };
      });
      eventOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      //setEventStations(eventOptions);
    });

    searchMenu({ restaurantId: resto.id }).then((res) => {
      if (res && res.menus) {
        let menu = res.menus;
        let menuOptions = menu.map((k) => {
          return {
            label: k.name,
            value: k.id,
          };
        });

        menuOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        //setMenus(menuOptions);
      }
    });

    getKitchenStations(resto.id).then((res) => {
      let kitchens = res.kitchens;
      let kitchenOptions = kitchens?.map((k) => {
        return {
          label: k.station,
          value: k.id,
        };
      });
      if (kitchenOptions) {
        kitchenOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      }
      //setKitchenStations(kitchenOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowImg(false);
    setDocName('');
    setCreateComment('');
    setSelectedKitchens([]);
    setSelectedEvents([]);
    setSelectedRecipes([]);
    setSelectedTasks([]);
    setSelectedMenus([]);
    //setCheckedKitchens([]);
    //setCheckedEvents([]);
    //setCheckedMenus([]);
    //setCheckedRecipes([]);
    //setCheckedTasks([]);
  }, [visible]);

  // const getKitchen = (checkedValues) => {
  //   console.log('checked = ', checkedValues);
  //   let names = kitchenStations.filter((e) => checkedValues.includes(e.value));
  //   let checkedKitchens = checkedValues.map((e, i) => {
  //     return {
  //       linkId: e,
  //       info: { title: names[i] },
  //       type: 'kitchen',
  //     };
  //   });
  //   setSelectedKitchens(checkedKitchens);
  //   //setCheckedKitchens(checkedValues);
  // };

  // const getEvent = (checkedValues) => {
  //   console.log('checked = ', checkedValues);
  //   let names = eventStations.filter((e) => checkedValues.includes(e.value));
  //   let checkedEvents = checkedValues.map((e, i) => {
  //     return {
  //       linkId: e,
  //       info: { title: names[i] },
  //       type: 'events',
  //     };
  //   });
  //   setSelectedEvents(checkedEvents);
  //   //setCheckedEvents(checkedValues);
  // };

  // const getRecipes = (checkedValues) => {
  //   console.log('checked = ', checkedValues);
  //   let names = recipes.filter((e) => checkedValues.includes(e.value));
  //   let checkedRecipes = checkedValues.map((e, i) => {
  //     return {
  //       linkId: e,
  //       info: { title: names[i] },
  //       type: 'recipes',
  //     };
  //   });
  //   setSelectedRecipes(checkedRecipes);
  //   // setCheckedRecipes(checkedValues);
  // };

  // const getTasks = (checkedValues) => {
  //   console.log('checked = ', checkedValues);
  //   let names = tasks.filter((e) => checkedValues.includes(e.value));
  //   let checkedTasks = checkedValues.map((e, i) => {
  //     return {
  //       linkId: e,
  //       info: { title: names[i] },
  //       type: 'tasks',
  //     };
  //   });
  //   setSelectedTasks(checkedTasks);
  //   //setCheckedTasks(checkedValues);
  // };

  // const getMenus = (checkedValues) => {
  //   console.log('checked = ', checkedValues);
  //   let names = menus.filter((e) => checkedValues.includes(e.value));
  //   let checkedMenus = checkedValues.map((e, i) => {
  //     return {
  //       linkId: e,
  //       info: { title: names[i] },
  //       type: 'menus',
  //     };
  //   });
  //   setSelectedMenus(checkedMenus);
  //   //setCheckedMenus(checkedValues);
  // };

  const createDocument = () => {
    let allSelectedLinks = [].concat(selectedEvents, selectedKitchens, selectedRecipes, selectedTasks, selectedMenus);
    const operator = JSON.parse(localStorage.getItem('operator')) || {};

    let noteInfo = {
      type: type,
      file: fileUrl,
      comments: [{ text: createdComment, timestamp: new Date() * 1 }],
      timestamp: new Date() * 1,
      updatedDate: new Date() * 1,
      createdDate: new Date() * 1,
      createdBy: operator.firstName + ' ' + operator.lastName,
      collaborator: '',
      linked: allSelectedLinks.length,
    };

    let params = {
      folderId: folder,
      title: docName.trim() !== '' ? docName.trim() : 'Untitled',
      info: noteInfo,
    };

    //save note

    console.log(params);
    console.log(allSelectedLinks);

    create(params).then((res) => {
      let created = res;
      if (created && created.id) {
        createDocLinks(created.id, allSelectedLinks).then((res) => {
          // notification.open({
          //   message: 'Document Successfully created!',
          // });
          if (handleNewDocument != undefined) {
            handleNewDocument(created);
          }
          if (setAddedDocument != undefined) {
            setAddedDocument(true);
          }
          closeDrawer(true);
        });
      }
    });
  };

  const handleDrop = useCallback((data) => {
    console.log('DATA', data);
    if (data !== 'none') {
      if (data.type.includes('pdf')) {
        setType('PDF');
      } else if (data.type.includes('image')) {
        setType(data.type);
      } else if (data.type.includes('officedocument.spreadsheetml') || data.type.includes('vnd.ms-excel')) {
        setType('Excel');
      } else if (data.type.includes('text/plain')) {
        setType('Text');
      } else if (data.type.includes('officedocument.wordprocessingml') || data.type.includes('application/msword')) {
        setType('Word');
      } else if (data.type.includes('officedocument.presentationml') || data.type.includes('vnd.ms-powerpoint')) {
        setType('Powerpoint');
      } else if (data.type.includes('video')) {
        setType('Video');
      } else {
        setType('Misc');
      }
      setFileUrl(data.url);
      // notification.open({
      //   message: 'Successfully Uploaded File!',
      // });
      setShowImg(true);
    } else {
      // notification.open({
      //   message: 'File not uploaded, please try again',
      // });
      setShowImg(false);
    }
  }, []);

  return (
    <div className="main upload-doc-main">
      <div className="doc-note-create">
        <PlusButton alt="Create document" onClick={createDocument}>
          Create Document
        </PlusButton>
      </div>
      <div className="create-newStation">
        <Input
          onChange={(e) => setDocName(e.target.value)}
          placeholder="Document name"
          className="create-newSta-input"
          value={docName}
        />
      </div>
      <div>
        {showImg && fileUrl ? (
          <div className="doc-details-upload-preview-container">
            {/* this should be a <PreviewDocument> component or part of <ThumbnailImage> */}
            {type.includes('image') && <ThumbnailImage src={fileUrl} />}
            {type.includes('Word') && (
              <>
                <div style={{ textAlign: 'center' }}>
                  <iframe
                    style={{ height: 100 }}
                    class="doc"
                    src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="Word"
                  ></iframe>
                </div>
              </>
            )}
            {type.includes('Powerpoint') && (
              <>
                <div style={{ textAlign: 'center' }}>
                  <iframe
                    style={{ height: 100 }}
                    class="doc"
                    src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="Powerpoint"
                  ></iframe>
                </div>
              </>
            )}
            {type.includes('Excel') && (
              <>
                <div style={{ textAlign: 'center' }}>
                  <iframe
                    style={{ height: 100 }}
                    class="doc"
                    src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="Excel"
                  ></iframe>
                </div>
              </>
            )}
            {type.includes('PDF') && (
              <>
                <div style={{ textAlign: 'center' }}>
                  <iframe
                    style={{ height: 100 }}
                    class="doc"
                    src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
                    title="PDF"
                  ></iframe>
                </div>
              </>
            )}
          </div>
        ) : (
          <AddDoc handleDrop={handleDrop} />
        )}
      </div>
      {/* <div>
        <div>
          <div className="doc-note-station">Linky link</div>
          <div className="doc-station-title">Select all doodads you want this document to appear in</div>
          <div className="doc-collapse-box">
            <Collapse bordered={false} defaultActiveKey={[]}>
              <Panel header={<div className="doc-note-header">{KITCHENSTATIONNAME}s</div>} key="1">
                <Row>
                  <Col>
                    <Checkbox.Group options={kitchenStations} value={checkedKitchens} onChange={getKitchen} />
                  </Col>
                </Row>
              </Panel>
              <Panel header={<div className="doc-note-header">Event stations</div>} key="2">
                <Row>
                  <Col>
                    <Checkbox.Group options={eventStations} value={checkedEvents} onChange={getEvent} />
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
          <div className="doc-collapse-box">
            <Collapse bordered={false} defaultActiveKey={[]}>
              <Panel header={<div className="doc-note-header">Menus</div>} key="1">
                <Row>
                  <Col>
                    {' '}
                    <Checkbox.Group options={menus} value={checkedMenus} onChange={getMenus} />
                  </Col>
                </Row>
              </Panel>
              <Panel header={<div className="doc-note-header">Recipes</div>} key="3">
                <Row>
                  <Col>
                    <Checkbox.Group options={recipes} value={checkedRecipes} onChange={getRecipes} />
                  </Col>
                </Row>
              </Panel>
              <Panel header={<div className="doc-note-header">Tasks</div>} key="4">
                <Row>
                  <Col>
                    <Checkbox.Group options={tasks} value={checkedTasks} onChange={getTasks} />
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div> */}
      <div>
        <div id="ck-add-comment">
          <div className="Line"></div>
          <div className="doc-comment-box">
            <div className="doc-log-image-container">
              <div className="common-user-icon" alt="user-photo">
                <UserImage src={userImage} />
              </div>
            </div>
            <input
              type="text"
              className="ck-input-md"
              placeholder="Enter comment"
              onChange={(e) => setCreateComment(e.target.value)}
              value={createdComment}
            />
          </div>
          {/* <div className="doc-collab">
            <div className="doc-collab-text">Collaborators</div>
            <div className="flex">
              <div className="doc-log-image-container">
                <div className="common-user-icon" alt="user-photo">
                  <UserImage src={userImage} />
                </div>
              </div>
              <span>Add collaborators</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default UploadDocument;
