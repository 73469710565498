import * as AWS from 'aws-sdk';
import { request } from './http.service';

const getS3UploadAccess = () => {
  return request('GET', '/aws/s3token', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

// const generateRandomName = () => {
//   let text = '';
//   const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//   const random = (Math.floor(Math.random() * (possible.length / 7)) + 7);
//   for (let i = 0; i < random; i++) {
//     text += possible.charAt(Math.floor(Math.random() * possible.length));
//   }
//   return text;
// };

const uploadToS3 = (file, data) => {
  console.log(file);
  return new Promise((resolve) => {
    const s3 = new AWS.S3();
    s3.config.accessKeyId = data.tempAwsKey;
    s3.config.sessionToken = data.sessionToken;
    s3.config.region = 'us-east-1';
    s3.config.credentials = new AWS.Credentials({
      accessKeyId: data.tempAwsKey,
      sessionToken: data.sessionToken,
      secretAccessKey: data.tempSecretAccessKey,
    });
    const params = {
      // Key: generateRandomName(),
      Key: file.name,
      ContentType: file.type,
      Body: file,
      Bucket: data.bucket,
      ACL: 'public-read',
    };
    s3.upload(params, (err, res) => {
      if (err) return resolve(err);
      resolve(res);
    });
  });
};

export const onFileSelected = async (file, cb) => {
  const token = await getS3UploadAccess();
  const result = await uploadToS3(file, token);
  return result;
};
