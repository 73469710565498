/* eslint eqeqeq: "off" */
import React, { useEffect, useState } from 'react';
import { Form, Checkbox } from 'antd';
import './login.css';
import { login } from '../../services/operator.service';
import logo from '../../img/cidekic_logo.svg';
import value from '../../img/value-ic.png';
import benefit from '../../img/benefit-ic.png';
import market from '../../img/market-ic.png';
import copyright from '../../img/copyright.png';
import erroralert from '../../img/erroralert.png';
import lockalert from '../../img/lock-error.png';
import { Link } from 'react-router-dom';
import MailTo from '../../components/MailTo/MailTo';

const Login = (props = {}) => {
  const {
    form: { getFieldDecorator, getFieldError },
  } = props;

  useEffect(() => {}, []);

  const [hasError, setHasError] = useState(false);
  //const [lockuser, setLockUser] = useState(false);

  const usernameError = getFieldError('email');
  const pwdError = getFieldError('password');

  const handleLogin = async (e) => {
    e.preventDefault();
    props.form.validateFields((err, { email, password }) => {
      if (!err) {
        //if (localStorage.getItem('counter') != null && parseInt(localStorage.getItem('counter')) == 5) {
        //setLockUser(true);
        //return;
        //}
        console.log(email, password);
        const rememberMe = localStorage.getItem('rememberMe') === 'true';
        login(email, password, rememberMe).then(
          () => {
            console.log('success');
            localStorage.setItem('counter', 0);
            if (props.location.state) {
              let { from } = props.location.state;
              setTimeout(() => {
                window.location = `${from.pathname}`;
              }, 2000);
            } else {
              window.location = '/';
            }
          },
          (err) => {
            console.log('error', err);
            setHasError(true);
            let counter = localStorage.getItem('counter') ? parseFloat(localStorage.getItem('counter')) : 0;
            if (counter == null) {
              counter = 0;
            }
            ++counter;

            localStorage.setItem('counter', '' + counter);
            props.form.setFields({
              email: {
                value: email,
              },
            });
          }
        );
      } else {
        console.log(err);
      }
    });
  };

  const onChangeCheckbox = async (e) => {
    localStorage.setItem('rememberMe', e.target.checked);
  };

  return (
    <div>
      <div className="login-container">
        <div className="form-content">
          <div style={{ textAlign: 'center' }}>
            <img className="cidekic-logo-login" src={logo} alt="logo" />
          </div>
          <Form onSubmit={handleLogin} hideRequiredMark={true}>
            <div>
              <div>
                {/* {hasError && !lockuser ? ( */}
                {hasError ? (
                  <div className="incorrect-login">
                    <div>
                      {' '}
                      <img src={lockalert} className="locked-alert" alt="locked" />{' '}
                    </div>
                    <div> Incorrect login. Please check your username and password and try again.</div>
                  </div>
                ) : (
                  ''
                )}
                {/* {lockuser ? ( */}
                {/* <div className="incorrect-login">
                  <div>
                    {' '}
                    <img src={lockalert} className="locked-alert" alt="locked" />{' '}
                  </div>
                  <div>
                    {' '}
                    Sorry, you have been locked out of your account. Please{' '}
                    <MailTo email="info@cidekic.com" subject="Customer Access Request">
                      contact us
                    </MailTo>{' '}
                    and we will help reset your account.
                  </div>
                </div> */}
                {/* ) : (
                  ''
                )} */}
                <div className="login-labels">
                  Username
                  {/* <span className="forget-info">Forgot username?</span> */}
                </div>
                <Form.Item>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        message: (
                          <span>
                            <img src={erroralert} height="20" alt="error" /> Please enter email address
                          </span>
                        ),
                      },
                      {
                        type: 'email',
                        message: (
                          <span>
                            <img src={erroralert} height="20" alt="error" /> Sorry, invalid email address. Please check
                            and correct the email address.
                          </span>
                        ),
                      },
                    ],
                  })(
                    <input
                      type="email"
                      //disabled={lockuser}
                      className={usernameError == undefined ? 'login-user-input' : 'login-user-error'}
                      placeholder="Enter your username"
                      autoComplete="email"
                    />
                  )}
                </Form.Item>
              </div>
              <div>
                <div className="login-labels">
                  Password{' '}
                  <Link to={'/public/login/resetpwd'}>
                    <span className="forget-info">Forgot password? </span>
                  </Link>
                </div>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: (
                          <span>
                            <img src={erroralert} height="20" alt="error" /> Please enter a password
                          </span>
                        ),
                      },
                      {
                        min: 9,
                        message: (
                          <span>
                            <img src={erroralert} height="20" alt="password" /> Password must be at least 9 characters
                          </span>
                        ),
                      },
                    ],
                  })(
                    <input
                      // disabled={lockuser}
                      className={pwdError == undefined ? 'login-user-input' : 'login-user-error'}
                      placeholder="Enter your password"
                      type="password"
                    />
                  )}
                </Form.Item>
              </div>
              <Form.Item>
                {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: localStorage.getItem('rememberMe') === 'true',
                })(
                  <Checkbox className="checkbx" onChange={onChangeCheckbox}>
                    <span className="login-remember-me">Keep me logged in</span>
                  </Checkbox>
                )}
              </Form.Item>
              <button className="login-btn-recipe" htmltype="submit">
                Login
              </button>
              <br />
              {/* <div className="signup-message">
                <span>or register a new account</span>
              </div> */}
            </div>
          </Form>
        </div>
      </div>
      <div>
        <div className="wrapper-login">
          <header className="header-login">
            <h2 className="login-title">What is Cide Kic</h2>
          </header>

          <section className="columns-login">
            <div className="column-login1">
              <img src={value} className="login-ic" alt="value" />
              <h4 className="login-caps">THE VALUE</h4>
              <p>Automate and streamline all aspects of professional kitchen operations</p>
            </div>

            <div className="column-login1">
              <img src={benefit} className="login-ic" alt="benefit" />
              <h4 className="login-caps">THE BENEFIT</h4>
              <p>Gain insights in real-time to efficiently manage kitchen workflow</p>
            </div>

            <div className="column-login1">
              <img src={market} className="login-ic" alt="market" />
              <h4 className="login-caps">THE MARKET</h4>
              <p>Hotels, resorts, private clubs, restaurants and so many more!</p>
            </div>
          </section>

          <footer>
            <p>
              <span>
                <img src={copyright} className="copyrt-ic" alt="copyright" />
              </span>{' '}
              2022 Cide Kic All rights reserved
            </p>
            <div className="login-contactus">
              <MailTo email="info@cidekic.com" subject="Customer Access Request">
                Contact Us
              </MailTo>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Form.create({ name: 'login' })(Login);
