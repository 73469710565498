/* eslint eqeqeq: "off" */
import React from 'react';
import approve from '../../img/approve-ck.png';
import paperclip from '../../img/paperclip-ck.png';
import { Tag, Dropdown, Menu } from 'antd';
import { MoreIcon, UserImage, ThumbnailImage } from '../../components/common/common_ui';

const CheckListDetailPopup = (props) => {
  const { data } = props;

  const menu = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Request approval
        </a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Download
        </a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Delete
        </a>
      </Menu.Item>
    </Menu>
  );

  const menu1 = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Duplicate task
        </a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Create subtask
        </a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Print
        </a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Delete Task
        </a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Leave Task
        </a>
      </Menu.Item>
    </Menu>
  );

  const menu2 = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          CideKic Documents
        </a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Your computer
        </a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Dropbox
        </a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Google Drive
        </a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          Box
        </a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="#/">
          One Drive / Sharepoint
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="doc-details-main">
      <div className="doc-details-subcontainer">
        <div className="ck-top-controls">
          <div className="ck-mark-complete">&#10003; Mark complete</div>
          <div>
            <div className="ck-icon-bar">
              {' '}
              <img src={approve} className="approve-ck" alt="approve" height="25" />
              <img src={paperclip} className="approve-ck" alt="approve" height="25" />
              <Dropdown overlay={menu}>
                <a href="#/" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                  <MoreIcon />
                </a>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="Line"></div>
        <div className="ck-task-box">
          <div className="ck-details-title">{data.name}</div>
          <div>
            Assignee{' '}
            <span className="check-detail-spanDue">
              {' '}
              <div className="common-user-icon" alt="user-photo">
                <UserImage src={null} />
              </div>
              {data.assignee}
            </span>{' '}
          </div>
          <div>
            Due Date <span className="check-detail-spanDue">{data.due}</span>{' '}
          </div>
          <div>
            Project <span className="check-detail-spanDue">{data.project}</span>{' '}
          </div>
          <div>
            Tags{' '}
            <span className="check-detail-spanDue">
              {data.tags
                ? data.tags.map((tag) => {
                    let color = 'black';
                    return (
                      <Tag color={color} key={tag}>
                        {tag}
                      </Tag>
                    );
                  })
                : ''}
            </span>{' '}
          </div>
          <div>
            Priority{' '}
            <span className="check-detail-spanDue">
              {' '}
              {data.priority == 'High' && <span className="priorities high">{data.priority}</span>}
              {data.priority == 'Medium' && <span className="priorities med">{data.priority}</span>}
              {data.priority == 'Low' && <span className="priorities low">{data.priority}</span>}
            </span>{' '}
          </div>
          <div className="ck-detail-desc">
            <div> Description </div>
            <div className="ck-desc-text">{data.description}</div>
          </div>
        </div>
        <br />
        <div className="ck-subtask-title">Subtask</div>
        <div className="Line"></div>
        <div className="ck-subtask">Add subtask</div>
        <div className="Line"></div>
        <div className="ck-subtask-files">
          <div className="ck-doc">
            <ThumbnailImage className="doc-details-preview-container" src={null} />
            <div className="ck-show-preview">
              <Dropdown overlay={menu1}>
                <a href="#/" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                  <MoreIcon />
                </a>
              </Dropdown>
            </div>
          </div>
          <Dropdown overlay={menu2}>
            <a href="#/" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <div className="ck-doc-2">Add file</div>
            </a>
          </Dropdown>
        </div>
        <div>
          <div className="Line"></div>
          <div className="ck-activity-log">
            <div>
              {' '}
              <div className="common-user-icon" alt="user-photo">
                <UserImage src={null} />
              </div>{' '}
            </div>
            <div className="ck-comment-mid">{data.assignee} created this task item.</div>
            <div>2 hours ago</div>
          </div>
          <div className="ck-activity-log">
            <div>
              {' '}
              <div className="common-user-icon" alt="user-photo">
                <UserImage src={null} />
              </div>{' '}
            </div>
            <div>
              <div className="ck-comment-middle">{data.assignee}</div>
              <div className="ck-text-comment">Test comment 1</div>
            </div>
            <div className="ck-time-approval">
              <div className="ck-timestamp">41 minutes ago</div>
              <div>
                {' '}
                <img src={approve} className="approve-ck" alt="approve" height="20" />
              </div>
            </div>
          </div>
          <div className="ck-activity-log">
            {' '}
            <div className="common-user-icon" alt="user-photo">
              <UserImage src={null} />
            </div>{' '}
            <div>
              <div className="ck-comment-middle">{data.assignee}</div>
              <div className="ck-text-comment">Test comment 2</div>
            </div>
            <div className="ck-time-approval">
              <div className="ck-timestamp">1 minute ago</div>
              <div>
                {' '}
                <img src={approve} className="approve-ck" alt="approve" height="20" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="ck-add-comment">
        <div className="Line"></div>
        <div className="ck-comment-box">
          <div className="common-user-icon" alt="user-photo">
            <UserImage src={null} />
          </div>
          <input type="text" className="ck-input-md" placeholder="Enter comment" />
        </div>
        <div className="ck-collab">
          <div>Collaborators</div>
          <div>
            <div className="common-user-icon" alt="user-photo">
              <UserImage src={null} />
            </div>
            <span>Add collaborators</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckListDetailPopup;
