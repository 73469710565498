import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './index.css';
import WindowDimensionsProvider from './components/windowdimensions/windowdimensions';
import ResponsiveLayout from './components/responsive/responsive';
import Headers from './components/Header/Header.js';
import HeaderMobile from './components/Header/HeadMobile.js';
import SideMenu from './components/SideMenu/SideMenu.js';
import AdminMenu from './components/AdminMenu/AdminMenu.js';
import CreateRecipes from './pages/Recipes/CreateRecipes';
// import ShareRecipe from './pages/Recipes/ShareRecipe';
import StationDetails from './pages/StationDetails/stationdetails';
import StationDetailsMobile from './pages/StationDetails/stationdetailsmobile';
import StationDetailsEvMobile from './pages/StationDetails/stationdetails-eventMob';
import StationDetailsEvent from './pages/StationDetails/stationdetails-event';
import MenuDetails from './pages/Menus/MenuDetails';
import ViewRecipes from './pages/Recipes/ViewRecipes';
import ViewMenuRecipe from './pages/Recipes/ViewMenuRecipe';
import ViewMenuRecipeMobile from './pages/Recipes/ViewMenuRecipeMobile';
// import ShareRecipePublicView from './pages/Recipes/ShareRecipePublicView';
import Dashboard from './pages/Dashboard/Dashboard';
import Menus from './pages/Menus/Menus';
import Recipes from './pages/Recipes/Recipes';
import Checklist from './pages/Checklist/Checklist';
import Documents from './pages/Documents/Documents';
import DocumentView from './pages/Documents/DocumentView';
import Login from './pages/login/login';
import ResetPassword from './pages/resetPassword/resetPassword';
import ActivateAccount from './pages/activateAccount/activateAccount';
import RecipeMobile from './pages/Recipes/RecipeMobile';
// import ShareRecipePublicMobile from './pages/Recipes/ShareRecipePublicMobile';
import DocumentsMobile from './pages/Documents/DocumentsMobile';
import DocumentViewMobile from './pages/Documents/DocumentViewMobile';
import ViewRecipesMobile from './pages/Recipes/ViewRecipesMobile';
import DashboardMobile from './pages/Dashboard/DashboardMobile';
import ChecklistMobile from './pages/Checklist/ChecklistMobile';
import MenusMobile from './pages/Menus/MenusMobile';
import MenuMobile from './pages/Menus/MenuMobile';
import Users from './pages/Users/Users';
import SuperUsersMobile from './pages/SuperUsers/superusersMobile';
import SuperUsersNewMobile from './pages/SuperUsers/superusersnewMobile';
import SuperUsersEditMobile from './pages/SuperUsers/superuserseditMobile';
import UsersMobile from './pages/Users/UsersMobile';
import SuperUsers from './pages/SuperUsers/superusers';
import SuperUsersNew from './pages/SuperUsers/superusersnew';
import SuperUsersEdit from './pages/SuperUsers/superusersedit';
import UsersNew from './pages/Users/UsersNew';
import UsersNewMobile from './pages/Users/UsersNewMobile';
import UsersEdit from './pages/Users/UsersEdit';
import UsersEditMobile from './pages/Users/UsersEditMobile';
import Locations from './pages/Locations/locations';
import LocationsMobile from './pages/Locations/locationsMobile';
import SuperLocations from './pages/SuperLocations/superlocations';
import SuperLocationsMobile from './pages/SuperLocations/superlocationsMobile';
import Preparations from './pages/Preparations/preparations';
import PreparationsMobile from './pages/Preparations/preparationsMobile';
import SuperPreparations from './pages/SuperPreparations/superpreparations';
import SuperPreparationsMobile from './pages/SuperPreparations/superpreparationsmobile';
import Measurements from './pages/Measurements/Measurements';
import MeasurementsMobile from './pages/Measurements/MeasurementsMobile';
import SuperMeasurements from './pages/SuperMeasurements/supermeasurements';
import SuperMeasurementsMobile from './pages/SuperMeasurements/supermeasurementsMobile';
import Equipment from './pages/Equipment/equipment';
import EquipmentMobile from './pages/Equipment/equipmentMobile';
import SuperEquipment from './pages/SuperEquipment/superequipment';
import SuperEquipmentMobile from './pages/SuperEquipment/superequipmentMobile';
import Ingredients from './pages/Ingredients/Ingredients';
import IngredientsMobile from './pages/Ingredients/IngredientsMobile';
import SuperIngredients from './pages/SuperIngredients/superingredients';
import SuperIngredientsMobile from './pages/SuperIngredients/superingredientsMobile';
import Accounts from './pages/Accounts/accounts';
import AccountsMobile from './pages/Accounts/accountsMobile';
import AccountsNew from './pages/Accounts/accountsNew';
import AccountsNewMobile from './pages/Accounts/accountsNewMobile';
import ShoppingList from './pages/ShoppingList/ShoppingList';
import ShoppingListMobile from './pages/ShoppingList/ShoppingListMobile';
import ContactUs from './pages/ContactUs/ContactUs';
import Legal from './pages/Legal/Legal';
import { Layout } from 'antd';
const { Sider, Content } = Layout;

const App = (props) => {
  const [isAuth, updateAuthStatus] = useState(true);

  useEffect(() => {
    setTimeout(updateAuthStatus(!!localStorage.getItem('operator')), 500);
  }, []);
  // const isAuth = useRef(!!localStorage.getItem('operator'));

  const login = () => {
    // isAuth.current = true;
    updateAuthStatus(true);
  };

  // const logout = () => {
  //   // isAuth.current = false;
  //   updateAuthStatus(false);
  // };

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        const { staticContext, ...restProps } = props;
        return !isAuth ? (
          <Redirect to={{ pathname: '/public/login', state: { from: props.location } }} />
        ) : (
          <Component {...restProps} />
        );
      }}
    />
  );

  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        const { staticContext, ...restProps } = props;
        return <Component {...restProps} login={login} />;
      }}
    />
  );

  const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        const { staticContext, ...rest } = props;
        return isAuth.current && props.match.path === '/' && props.match.isExact ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...rest} login={login} />
        );
      }}
    />
  );

  return (
    <Router>
      <Route
        render={(props) => {
          return (
            <div className="App" id="pageContent">
              <Layout>
                <WindowDimensionsProvider>
                  <ResponsiveLayout
                    breakPoint={700}
                    renderDesktop={() => {
                      return props.location.pathname.includes('public') ? null : props.location.pathname.includes(
                          'admin'
                        ) || props.location.pathname.includes('super') ? (
                        <Sider id="side-menu">
                          <AdminMenu />
                        </Sider>
                      ) : (
                        <Sider id="side-menu">
                          <SideMenu />
                        </Sider>
                      );
                    }}
                    renderMobile={() => null}
                  />
                </WindowDimensionsProvider>

                <div className={props.location.pathname.includes('login') ? 'Rectangle-Login' : 'Rectangle'}>
                  <WindowDimensionsProvider>
                    <ResponsiveLayout
                      breakPoint={700}
                      renderDesktop={() => {
                        return props.location.pathname.includes('public') ? null : <Headers />;
                      }}
                      renderMobile={() => {
                        return props.location.pathname.includes('login') ? null : (
                          <HeaderMobile
                            isAdmin={
                              props.location.pathname.includes('admin') || props.location.pathname.includes('super')
                            }
                          />
                        );
                      }}
                    />
                  </WindowDimensionsProvider>

                  <WindowDimensionsProvider>
                    <ResponsiveLayout
                      breakPoint={700}
                      renderDesktop={() => (
                        <Content>
                          {/* <Pages/> */}
                          <PrivateRoute exact path="/">
                            <Redirect to="/dashboard" />
                          </PrivateRoute>
                          <PrivateRoute path="/recipes/CreateRecipes" exact component={CreateRecipes} />
                          {/* <PrivateRoute path="/recipes/share/:id" exact component={ShareRecipe} /> */}
                          {/* <PublicRoute path="/recipes/public/:id/:title" exact component={ShareRecipePublicView} /> */}
                          <PrivateRoute path="/station/:id?" exact component={StationDetails} />
                          <PrivateRoute path="/event/station/:id?" exact component={StationDetailsEvent} />
                          <PrivateRoute path="/dashboard" exact component={Dashboard} />
                          <PrivateRoute path="/menus" exact component={Menus} />
                          <PrivateRoute path="/menu/:id" exact component={MenuDetails} />
                          <PrivateRoute path="/recipes" exact component={Recipes} />
                          <PrivateRoute path="/recipes/view/:id" exact component={ViewRecipes} />
                          <PrivateRoute path="/recipes/view1/:courseid/:menuid/:id" exact component={ViewMenuRecipe} />
                          <PrivateRoute path="/shoppinglist" exact component={ShoppingList} />
                          <PrivateRoute path="/checklist" exact component={Checklist} />
                          <PrivateRoute path="/documents" exact component={Documents} />
                          <PublicRoute path="/document/public/:id" exact component={DocumentView} />
                          <LoginRoute path="/public/login" exact component={Login} />
                          <PublicRoute path="/public/login/resetpwd" exact component={ResetPassword} />
                          <PublicRoute path="/public/login/activate" exact component={ActivateAccount} />
                          <PrivateRoute path="/admin/users" exact component={Users} />
                          <PrivateRoute path="/admin/users/new" exact component={UsersNew} />
                          <PrivateRoute path="/admin/users/edit/:id" exact component={UsersEdit} />
                          <PrivateRoute path="/super/users" exact component={SuperUsers} />
                          <PrivateRoute path="/super/users/new" exact component={SuperUsersNew} />
                          <PrivateRoute path="/super/users/edit/:id" exact component={SuperUsersEdit} />
                          <PrivateRoute path="/admin/locations" exact component={Locations} />
                          <PrivateRoute path="/super/locations" exact component={SuperLocations} />
                          <PrivateRoute path="/admin/preparations" exact component={Preparations} />
                          <PrivateRoute path="/super/preparations" exact component={SuperPreparations} />
                          <PrivateRoute path="/admin/measurements" exact component={Measurements} />
                          <PrivateRoute path="/super/measurements" exact component={SuperMeasurements} />
                          <PrivateRoute path="/admin/equipment" exact component={Equipment} />
                          <PrivateRoute path="/super/equipment" exact component={SuperEquipment} />
                          <PrivateRoute path="/admin/ingredients" exact component={Ingredients} />
                          <PrivateRoute path="/super/ingredients" exact component={SuperIngredients} />
                          <PrivateRoute path="/super/accounts" exact component={Accounts} />
                          <PrivateRoute path="/super/accounts/new" exact component={AccountsNew} />
                          <PrivateRoute path="/contactus" exact component={ContactUs} />
                          <PrivateRoute path="/legal" exact component={Legal} />
                        </Content>
                      )}
                      renderMobile={() => (
                        <Content>
                          <PrivateRoute exact path="/">
                            <Redirect to="/dashboard" />
                          </PrivateRoute>
                          <PrivateRoute path="/dashboard" exact component={DashboardMobile} />
                          <PrivateRoute path="/checklist" exact component={ChecklistMobile} />
                          <PrivateRoute path="/menus" exact component={MenusMobile} />
                          <PrivateRoute path="/menu/:id" exact component={MenuMobile} />
                          <PrivateRoute path="/recipes" exact component={RecipeMobile} />
                          <PrivateRoute path="/recipes/CreateRecipes" exact component={CreateRecipes} />
                          {/* <PrivateRoute path="/recipes/share/:id" exact component={ShareRecipe} /> */}
                          <PrivateRoute path="/station/:id?" exact component={StationDetailsMobile} />
                          <PrivateRoute path="/event/station/:id?" exact component={StationDetailsEvMobile} />
                          {/* <PublicRoute path="/recipes/public/:id/:title" exact component={ShareRecipePublicMobile} /> */}
                          <PrivateRoute path="/documents" exact component={DocumentsMobile} />
                          <PublicRoute path="/document/public/:id" exact component={DocumentViewMobile} />
                          <LoginRoute path="/public/login" exact component={Login} />
                          <PrivateRoute path="/recipes/view/:id" exact component={ViewRecipesMobile} />
                          <PrivateRoute
                            path="/recipes/view1/:courseid/:menuid/:id"
                            exact
                            component={ViewMenuRecipeMobile}
                          />
                          <PrivateRoute path="/shoppinglist" exact component={ShoppingListMobile} />
                          <PrivateRoute path="/admin/users" exact component={UsersMobile} />
                          <PrivateRoute path="/admin/locations" exact component={LocationsMobile} />
                          <PrivateRoute path="/super/locations" exact component={SuperLocationsMobile} />
                          <PrivateRoute path="/admin/preparations" exact component={PreparationsMobile} />
                          <PrivateRoute path="/super/preparations" exact component={SuperPreparationsMobile} />
                          <PrivateRoute path="/admin/measurements" exact component={MeasurementsMobile} />
                          <PrivateRoute path="/super/measurements" exact component={SuperMeasurementsMobile} />
                          <PrivateRoute path="/admin/equipment" exact component={EquipmentMobile} />
                          <PrivateRoute path="/super/equipment" exact component={SuperEquipmentMobile} />
                          <PrivateRoute path="/admin/ingredients" exact component={IngredientsMobile} />
                          <PrivateRoute path="/super/ingredients" exact component={SuperIngredientsMobile} />
                          <PrivateRoute path="/super/users" exact component={SuperUsersMobile} />
                          <PrivateRoute path="/admin/users/new" exact component={UsersNewMobile} />
                          <PrivateRoute path="/super/users/new" exact component={SuperUsersNewMobile} />
                          <PrivateRoute path="/super/users/edit/:id" exact component={SuperUsersEditMobile} />
                          <PrivateRoute path="/admin/users/edit/:id" exact component={UsersEditMobile} />
                          <PrivateRoute path="/super/accounts" exact component={AccountsMobile} />
                          <PrivateRoute path="/super/accounts/new" exact component={AccountsNewMobile} />
                          <PublicRoute path="/public/login/resetpwd" exact component={ResetPassword} />
                          <PublicRoute path="/public/login/activate" exact component={ActivateAccount} />
                          <PrivateRoute path="/contactus" exact component={ContactUs} />
                          <PrivateRoute path="/legal" exact component={Legal} />
                        </Content>
                      )}
                    />
                  </WindowDimensionsProvider>
                </div>
              </Layout>
              {/* <Header/>
                              <SideMenu/>
                              <Layout>

                                  <Layout.Content></Layout.Content>
                              </Layout> */}
            </div>
          );
        }}
      />
    </Router>
  );
};
export default App;
