/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import './index.css';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import _, { cloneDeep, remove, findIndex, isEmpty, has, find, isEqual } from 'lodash';
import { Empty, Table, Drawer, Dropdown, Menu, DatePicker, Popconfirm, message, notification, Spin } from 'antd';
import ReactDragListView from 'react-drag-listview';
import openSocket from 'socket.io-client';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';

import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import reorder from '../../img/reorder-icon.png';
import empty from '../../img/cidekic_logo_bw.svg';
import { data } from '../../data/checklistdata';

import DocumentList from '../../components/DocumentList/DocumentList';
import { NewSubHeader } from '../../components/Header/Header';
import ProgressBar from '../../components/progressBar/progressbar';
import useWindowSize from '../../components/windowdimensions/windowSize';
import {
  CidekicButton,
  PlusButton,
  CidekicDropdown,
  CompletionIcon,
  AttachmentIndicator,
  ExpandCollapseIcon,
  UserImage,
  ThumbnailImage,
  FolderIcon,
  RowAssigneeEdit,
  RowOkCancel,
} from '../../components/common/common_ui';
import CheckListDetailPopup from './CheckListDetailPopup';
import AddImage from './AddImage';

import {
  create,
  getCheckList,
  createListItem,
  getChecklistAssignees,
  destroy,
  destroyChecklist,
  updateItem,
  updateCheckList,
} from '../../services/checklist.service';
import { searchDocLinks } from '../../services/doc.service';
import { KITCHENSTATIONNAME } from '../../components/common/constant.js';

const Checklist = () => {
  // Drawer states and variables
  const [detailDrawerVisible, setDetailDrawerVisible] = useState(false);
  const [menuDrawerVisible, setMenuDrawerVisible] = useState(false);
  const [docLinks, setDocLinks] = useState({});
  const [links, setLinks] = useState({});
  // Table row states
  const [expandedRows, setExpandedRows] = useState([]);
  const [straightTo, setStraightTo] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // Table data source and back-up clones
  const [dataSource, setDataSource] = useState([]);
  const [pristineData, setPristineData] = useState([]);
  // input states and choices
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [assigneeChoices, setAssigneeChoices] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);
  const [currentDueDate, setCurrentDueDate] = useState('');
  // dragging table rows
  const [taskToDrag, setTaskToDrag] = useState({});
  const [dragParent, setDragParent] = useState(false);
  // table filtering feature
  const [filteredchooseTaskStatus, setFilteredchooseTaskStatus] = useState('Incomplete Tasks');
  // Table states
  const isEditingChecklist = useRef({});
  const isEditingTask = useRef({});
  const [tableOrderChanged, hasTableOrderChanged] = useState(true);
  const [chkId, setChkId] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);

  // socket.io state variables
  const [socketData, setSocketData] = useState([]);
  const [checklistUpdate, setChecklistUpdate] = useState([]);
  const [checklistAdd, setChecklistAdd] = useState([]);
  const [checklistDelete, setChecklistDelete] = useState('');
  const [taskAdd, setTaskAdd] = useState({});
  const [taskUpdate, setTaskUpdate] = useState({});
  const [taskCheckUpdate, setTaskCheckUpdate] = useState(0);
  const [taskDelete, setTaskDelete] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/checklist`));
  const windowSize = useWindowSize();

  // globals
  const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  const userInfo = JSON.parse(localStorage.getItem('operator')) || {};
  const user_id = userInfo?.id ? userInfo.id.toString() : '0';
  const user_email = userInfo?.email ? userInfo?.email : '';
  const userImage = userInfo?.info?.userImageThumb || userInfo?.info?.userImage || '';
  const userName = userInfo.firstName + ' ' + userInfo.lastName;

  // for dragging feature
  const activeCat = useRef(null);
  const isDown = useRef(false);

  // for scroll location
  const location = useLocation();

  // format tasks
  const formatTasks = (checkLists, parentId, checkListItem) => {
    const totalItems = checkLists.length;
    let totalCompleted = 0;
    for (let i = 0; i < checkLists.length; i++) {
      if (checkLists[i].hasOwnProperty('id')) {
        const { id, title, info, dueDate, project, priority, Station, Operator, Event } = checkLists[i];
        const assignee = (() => {
          let a = '';
          if (!isEmpty(Station)) {
            a = Station.name;
          }
          if (!isEmpty(Operator)) {
            a = `${Operator.firstName} ${Operator.lastName} `;
          }
          if (!isEmpty(Event)) {
            a = Event.name;
          }
          return a;
        })();
        const parsedInfo = info;
        const formattedDueDate = dueDate && dueDate.trim() !== '' ? moment.utc(dueDate).format('MM/DD/YYYY') : '';
        if (id > 0) {
          const formatted = {
            key: `${parentId}-${id}`,
            id: id,
            name: title,
            type: 'child',
            checked: parsedInfo.checked,
            complete: parsedInfo.complete,
            assignee: assignee,
            assigneeImg: assignee ? (assignee.trim() === userName ? userImage : '') : '',
            due: formattedDueDate,
            texts: 0,
            parentId: parentId,
            attachments: 0,
            project,
            tags: parsedInfo.tags,
            notes: 1,
            selected: 0,
            priority,
            details: parsedInfo.details,
            description: parsedInfo.description,
            operatorId: Object.keys(Operator).length !== 0 && Operator?.id ? Operator?.id : null,
            eventId: Object.keys(Event).length !== 0 && Event?.id ? Event?.id : null,
            stationId: Object.keys(Station).length !== 0 && Station?.id ? Station?.id : null,
            info: parsedInfo,
          };
          checkListItem.push(formatted);
          if (parsedInfo.complete === 2) {
            totalCompleted++;
          }
        }
      }
    }
    const percentage = (() => {
      let perc = 0;
      if (totalItems > 0) {
        perc = (totalCompleted / totalItems) * 100;
      }
      return Math.round(perc);
    })();
    return percentage;
  };

  // table data callbacks
  // checklists
  const getChecklists = () => {
    getCheckList({ restaurantId: resto_id, user_id, email: user_email }).then((res) => {
      let checkList = [];
      if (res && res.checklists.length) {
        const checklists = res.checklists || [];
        for (let i = 0; i < checklists.length; i++) {
          let percent = 0;
          const { id, title, ChecklistItems, info } = checklists[i];

          let checkListItems = JSON.parse(ChecklistItems);
          let checklistinfo = JSON.parse(info);

          checkListItems.sort((x, y) => {
            return x.id - y.id;
          });

          let checkListItem = [
            {
              // always on the first row
              key: `${id}-0`,
              type: 'add-image',
              parentId: id,
              imageUrl:
                checklistinfo && (checklistinfo?.imageUrl || checklistinfo?.imageUrlThumb)
                  ? checklistinfo?.imageUrl || checklistinfo?.imageUrlThumb
                  : '',
            },
          ];

          // merge array when there's an
          if (checkListItems.length > 0) {
            percent = formatTasks(checkListItems, id, checkListItem);
          }

          checkListItem.push({
            // always on the last row
            key: `${id}-9999999`,
            type: 'add-button',
            parentId: id,
          });

          checkList.push({
            id: id,
            key: `${id}`,
            name: title,
            type: 'parent',
            percentage: percent,
            children: checkListItem,
          });
        }

        let getLinks = checkList
          .filter((e) => e.children || [])
          .map((e) => (e.children || []).map((link) => link.id))
          .reduce((a, b) => a.concat(b), [])
          .filter((l) => l !== undefined);

        let stringList = getLinks.length > 0 ? getLinks.join(', ') : 0;

        console.log('stringList - ', stringList);

        let params = {
          type: 'tasks',
          linkId: stringList,
        };

        searchDocLinks(params)
          .then((res) => {
            console.log(res);
            if (res && res.length > 0) {
              let links = res;
              let removeDeleted = links.filter((l) => l.Document.folderId !== null);
              const list = Array.from(
                removeDeleted.reduce(
                  (m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]),
                  new Map()
                ),
                ([linkId, documentId]) => ({ linkId, documentId })
              );

              console.log(list);

              let addSelectedLinks = checkList.map((o) => {
                (o.children || []).map((c) => {
                  list.map((d) => {
                    if (d.linkId == c.id) {
                      return (c.selected = d.documentId.length);
                    }
                    return d;
                  });
                  return c;
                });
                return o;
              });

              // if custom checklist order exists, apply
              // else if ANTD order is cached, apply
              // else no sort to apply
              if (localStorage.getItem('checklistColKeyOrder') != undefined) {
                const { order, columnKey } = JSON.parse(localStorage.getItem('checklistColKeyOrder'));
                const sorted = applyColumnOrder(columnKey, order, addSelectedLinks);
                setDataSource(sorted);
              } else if (
                localStorage.getItem('checklistOrder') != undefined &&
                JSON.parse(localStorage.getItem('checklistOrder')).length === addSelectedLinks.length
              ) {
                const sortedTable = annotateTableOrder(
                  addSelectedLinks,
                  JSON.parse(localStorage.getItem('checklistOrder'))
                );
                setDataSource(sortedTable);
              } else {
                setDataSource(addSelectedLinks);
              }
              setSocketData(addSelectedLinks);
              setPristineData(addSelectedLinks);
              hasTableOrderChanged(false);
              setIsLoading(false);
              localStorage.setItem('originalChecklists', JSON.stringify(addSelectedLinks));
              localStorage.setItem('checklistOriginalData', addSelectedLinks.length);
            } else {
              // if custom checklist order exists, apply
              // else if ANTD order is cached, apply
              // else no sort to apply
              if (localStorage.getItem('checklistColKeyOrder') != undefined) {
                const { order, columnKey } = JSON.parse(localStorage.getItem('checklistColKeyOrder'));
                const sorted = applyColumnOrder(columnKey, order, checkList);
                setDataSource(sorted);
              } else if (
                localStorage.getItem('checklistOrder') != undefined &&
                JSON.parse(localStorage.getItem('checklistOrder')).length === checkList.length
              ) {
                const sortedTable = annotateTableOrder(checkList, JSON.parse(localStorage.getItem('checklistOrder')));
                setDataSource(sortedTable);
              } else {
                setDataSource(checkList);
              }
              setSocketData(checkList);
              setPristineData(checkList);
              hasTableOrderChanged(false);
              setIsLoading(false);
              localStorage.setItem('originalChecklists', JSON.stringify(checkList));
              localStorage.setItem('checklistOriginalData', checkList.length);
            }
          })
          .catch(() => {
            if (localStorage.getItem('checklistColKeyOrder') != undefined) {
              const { order, columnKey } = JSON.parse(localStorage.getItem('checklistColKeyOrder'));
              const sorted = applyColumnOrder(columnKey, order, checkList);
              setDataSource(sorted);
            } else if (
              localStorage.getItem('checklistOrder') != undefined &&
              JSON.parse(localStorage.getItem('checklistOrder')).length === checkList.length
            ) {
              const sortedTable = annotateTableOrder(checkList, JSON.parse(localStorage.getItem('checklistOrder')));
              setDataSource(sortedTable);
            } else {
              setDataSource(checkList);
            }
            setSocketData(checkList);
            setPristineData(checkList);
            hasTableOrderChanged(false);
            setIsLoading(false);
            localStorage.setItem('originalChecklists', JSON.stringify(checkList));
            localStorage.setItem('checklistOriginalData', checkList.length);
          });
      } else {
        setIsLoading(false);
      }
    });
  };

  // accessing Checklist page with specified checklist id
  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    if (location?.selectedId) {
      setStraightTo(location?.selectedId);
    }
  }, [location]);
  useEffect(() => {
    if (straightTo > 0) {
      const rows = [].concat(straightTo);
      setExpandedRows(rows);
    }
  }, [straightTo]);
  useEffect(() => {
    console.log(dataSource, straightTo, expandedRows);
    if (dataSource?.length > 0) {
      if (expandedRows.length === 1 && straightTo > 0 && expandedRows[0] === straightTo) {
        const theTableRow = Array.from(document.getElementsByClassName('ant-table-row-level-0')).filter(
          (e) => e.getAttribute('data-row-key') === straightTo.toString()
        )[0];
        const goTo = theTableRow.getBoundingClientRect().top.toFixed('0');
        const container = Array.from(document.getElementsByClassName('Rectangle'))[0];
        container.scrollTop = parseInt(goTo);
      }
    }
  }, [dataSource, expandedRows, straightTo]);

  // reloads the table
  useEffect(() => {
    if (tableOrderChanged) {
      let checklistOrder =
        localStorage.getItem('checklistOrder') != undefined ? JSON.parse(localStorage.getItem('checklistOrder')) : null;

      if (checklistOrder && checklistOrder?.length > 0) {
        const data = cloneDeep(dataSource);
        const orderedDataSource = annotateTableOrder(data, checklistOrder);
        setDataSource(orderedDataSource);
        setIsLoading(false);
        hasTableOrderChanged(false);
      } else {
        let originalData =
          localStorage.getItem('originalChecklists') != undefined
            ? JSON.parse(localStorage.getItem('originalChecklists'))
            : null;
        if (originalData && originalData?.length > 0) {
          setDataSource(originalData);
          setIsLoading(false);
          hasTableOrderChanged(false);
        } else {
          getChecklists();
        }
      }
    }
  }, [tableOrderChanged]);

  // updates assignee options based on user restaurantId/location
  useEffect(() => {
    getChecklistAssignees({ id: resto_id }).then((res) => {
      if (res) {
        let groupedAssignees = res
          .map((item) => item.type)
          .filter((item, i, ar) => ar.indexOf(item) === i)
          .sort((a, b) => a - b)
          .map((item) => {
            let new_list = res
              .filter((itm) => itm.type == item)
              .map((itm) => {
                return { name: itm.name, id: parseInt(itm.id) };
              });
            return {
              type: item,
              category: item == 'operator' ? 'Resource' : item == 'event' ? 'Event station' : KITCHENSTATIONNAME,
              children: new_list,
            };
          });
        if (groupedAssignees && groupedAssignees.length > 0) {
          groupedAssignees.sort((a, b) => a.category.localeCompare(b.category));
        }

        let sortedAssignees = groupedAssignees.map((a) => {
          return {
            category: a.category,
            type: a.type,
            children: a.children.map((c) => {
              return {
                value: JSON.stringify({ name: c.name, id: c.id, type: a.type }),
                name: c.name,
                id: c.id,
              };
            }),
          };
        });
        setAssigneeChoices(sortedAssignees);
      }
    });
  }, [resto_id]);

  // reset values
  const resetChecklistWatchers = (refreshTable) => {
    setOpenDatePicker(false);
    if (refreshTable) {
      let clonedCheckLists = cloneDeep(dataSource);
      console.log(isEditingTask.current);
      if (Object.keys(isEditingTask.current).length) {
        const findChecklistIndex = findIndex(clonedCheckLists, { key: isEditingTask.current.parentId.toString() });
        if (clonedCheckLists[findChecklistIndex]) {
          const findTaskIndex = findIndex(clonedCheckLists[findChecklistIndex].children, {
            key: isEditingTask.current.key,
          });

          if (has(isEditingTask.current, 'name')) {
            clonedCheckLists[findChecklistIndex].children[findTaskIndex].name = isEditingTask.current.name;
          }

          if (has(isEditingTask.current, 'assignee')) {
            clonedCheckLists[findChecklistIndex].children[findTaskIndex].assignee = isEditingTask.current.assignee;
            clonedCheckLists[findChecklistIndex].children[findTaskIndex].stationId = isEditingTask.current.stationId;
            clonedCheckLists[findChecklistIndex].children[findTaskIndex].eventId = isEditingTask.current.eventId;
            clonedCheckLists[findChecklistIndex].children[findTaskIndex].operatorId = isEditingTask.current.operatorId;
          }

          if (has(isEditingTask.current, 'tags')) {
            clonedCheckLists[findChecklistIndex].children[findTaskIndex].tags =
              isEditingTask.current.tags.length > 0 ? [...isEditingTask.current.tags] : [];
          }

          if (has(isEditingTask.current, 'project')) {
            clonedCheckLists[findChecklistIndex].children[findTaskIndex].project = isEditingTask.current.project;
          }

          if (has(isEditingTask.current, 'priority')) {
            clonedCheckLists[findChecklistIndex].children[findTaskIndex].priority =
              isEditingTask.current.priority !== '' ? isEditingTask.current.priority : '';
          }

          if (currentDueDate !== '01/01/1970' && has(isEditingTask.current, 'due')) {
            clonedCheckLists[findChecklistIndex].children[findTaskIndex].due =
              isEditingTask.current.due !== '01/01/1970' ? isEditingTask.current.due : '01/01/1970';
          }

          if (has(isEditingTask.current, 'assigneeImg')) {
            clonedCheckLists[findChecklistIndex].children[findTaskIndex].assigneeImg =
              isEditingTask.current.assigneeImg !== '' ? isEditingTask.current.assigneeImg : '';
          }
        }

        isEditingTask.current = {};
      }
      if (Object.keys(isEditingChecklist.current).length) {
        const findChecklistIndex = findIndex(clonedCheckLists, { key: isEditingChecklist.current.key });
        if (has(isEditingChecklist.current, 'name') && clonedCheckLists[findChecklistIndex]) {
          clonedCheckLists[findChecklistIndex].name = isEditingChecklist.current.name;
        }
        isEditingChecklist.current = {};
      }
      setDataSource(clonedCheckLists);
    } else {
      if (Object.keys(isEditingTask.current).length) {
        let cloneOriginal = cloneDeep(dataSource);
        const findChecklistIndex = findIndex(cloneOriginal, { key: isEditingTask.current.parentId.toString() });
        const findTaskIndex = findIndex(cloneOriginal[findChecklistIndex]?.children, {
          key: isEditingTask.current.key,
        });
        if (cloneOriginal[findChecklistIndex]) {
          cloneOriginal[findChecklistIndex].children[findTaskIndex].tags = [...currentTags];
        }
        isEditingTask.current = {};
        setDataSource(cloneOriginal);
        setCurrentTags([]);
        setCurrentDueDate('01/01/1970');
      }
      if (Object.keys(isEditingChecklist.current).length) {
        isEditingChecklist.current = {};
      }
    }
  };

  // when page is mounted
  useEffect(() => {
    let getOpenRows = JSON.parse(localStorage.getItem('checklistOpenRows'));
    if (getOpenRows && getOpenRows.length > 0) {
      setExpandedRows(getOpenRows);
    } else {
      setExpandedRows([]);
    }
  }, []);

  // shows document details drawer
  const showDetailDrawer = () => {
    setDetailDrawerVisible(true);
  };

  // shows menu drawer
  const showMenuDrawer = (e, obj) => {
    setMenuDrawerVisible(true);
    setDocLinks({
      type: 'tasks',
      linkId: obj.id,
    });
    setLinks({
      type: 'tasks',
      linkId: obj.id,
      info: { title: obj.name },
    });
  };

  // closing drawers
  const onClose = () => {
    setDetailDrawerVisible(false);
    setMenuDrawerVisible(false);
  };

  // create new checklist
  const createNewChecklist = () => {
    const tempId = Math.random().toString();
    const newChecklist = {
      tempId: tempId,
      key: `${tempId}`,
      name: '',
      type: 'parent',
    };
    let clonedChecklists = cloneDeep(dataSource);
    clonedChecklists.unshift(newChecklist);
    let updatedOrder = cacheTableOrder(clonedChecklists);
    localStorage.setItem('checklistOrder', JSON.stringify(updatedOrder.checklistOrder));
    setDataSource(updatedOrder.orderedChecklist);
  };
  // cancel created checklist
  const cancelNewChecklist = (checklist) => {
    let clonedChecklists = cloneDeep(dataSource);
    remove(clonedChecklists, (c) => c.tempId === checklist.tempId);
    let updatedOrder = cacheTableOrder(clonedChecklists);
    localStorage.setItem('checklistOrder', JSON.stringify(updatedOrder.checklistOrder));
    setDataSource(updatedOrder.orderedChecklist);
  };

  // add Checklist to API
  const addChecklist = (checklist) => {
    const newName = checklist.name && checklist.name !== '' ? checklist.name : 'Untitled';

    create({ title: newName, restaurantId: resto_id }).then((res) => {
      if (res) {
        const newChecklist = {
          children: [
            { key: `${res?.id}-0`, imageUrl: '', type: 'add-image', parentId: res?.id },
            { key: `${res?.id}-9999999`, type: 'add-button', parentId: res?.id },
          ],
          key: `${res?.id}`,
          name: res?.title,
          percentage: 0,
          type: 'parent',
          tempId: checklist.tempId,
        };
        eventSocket.emit('addChecklist', JSON.stringify(newChecklist));
      }
    });
  };
  // edit Checklist to API
  const updateChecklist = (obj) => {
    const newName =
      has(isEditingChecklist.current, 'key') && isEditingChecklist.current.name !== ''
        ? isEditingChecklist.current.name
        : obj.name;
    eventSocket.emit(
      'updatesChecklist',
      JSON.stringify({
        children: [...obj.children],
        key: obj.key,
        name: newName,
        percentage: obj.percentage,
        type: 'parent',
      })
    );
    updateCheckList(obj.key, { title: newName }).then((res) => {
      // notification.open({
      //   message: 'Successfully Renamed!',
      // });
      resetChecklistWatchers(true);
    });
  };
  // delete Checklist to API
  const deleteChecklist = (obj) => {
    destroyChecklist(obj.key).then((res) => {
      // notification.open({
      //   message: 'Checklist Successfully deleted!',
      // });
      eventSocket.emit(
        'deleteChecklist',
        JSON.stringify({
          id: obj.key,
        })
      );
    });
  };

  // creates new task to table
  const createNewTask = (obj) => {
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: obj.parentId.toString() });
    const checkItems = clonedChecklist[foundIndex]?.children;
    const addCheckRow = find(checkItems, { key: obj.key });
    let addCheckRowIndex = findIndex(checkItems, { key: obj.key });

    let toCreate = {
      name: '',
      checked: false,
      complete: 1,
      assignee: '',
      due: '01/01/1970',
      assigneeImg: '',
      texts: 0,
      stationId: 0,
      eventId: 0,
      operatorId: 0,
      attachments: 0,
      project: '',
      selected: 0,
      tags: [],
      priority: '',
      details: false,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    };
    if (clonedChecklist[foundIndex]) {
      clonedChecklist[foundIndex].children[addCheckRowIndex] = { ...addCheckRow, ...toCreate };
      clonedChecklist[foundIndex].children[addCheckRowIndex].type = 'child';
      setDataSource(clonedChecklist);
    }
  };
  // cancel created checklist
  const cancelNewTask = (task) => {
    let clonedChecklists = cloneDeep(dataSource);
    const foundChecklistIndex = findIndex(clonedChecklists, { key: task.parentId.toString() });
    const taskItems = clonedChecklists[foundChecklistIndex]?.children;
    let addTaskRowIndex = findIndex(taskItems, { key: task.key });

    if (clonedChecklists[foundChecklistIndex]) {
      clonedChecklists[foundChecklistIndex].children[addTaskRowIndex] = {
        key: task.key,
        type: 'add-button',
        parentId: task.parentId,
      };

      setDataSource(clonedChecklists);
    }
  };
  // add task to API
  const addTask = (task) => {
    console.log('ADD TASK', task);
    const { parentId, checked, complete, description, details } = task;

    const iteminfo = {
      checked,
      complete,
      description,
      details,
      tags: task.tags.length > 0 ? [...task.tags] : [],
    };

    const listItemInfo = {
      checklistId: parentId,
      assigneeImg: task.assignee ? (task.assignee.trim() === userName ? userImage : '') : '',
      title: task.name !== '' ? task.name : 'Untitled',
      dueDate: task.due !== '01/01/1970' ? task.due : '',
      operatorId: task.operatorId ? task.operatorId : 0,
      stationId: task.stationId ? task.stationId : 0,
      eventId: task.eventId ? task.eventId : 0,
      info: iteminfo,
      project: task.project !== '' ? task.project : '',
      priority: task.priority !== '' ? task.priority : '',
      createdBy: user_email,
    };

    createListItem(listItemInfo).then((result) => {
      if (result) {
        console.log('TASK ADDED', result);
        // notification.open({
        //   message: 'Successfully Added Item!',
        // });
        const newTask = {
          assignee: task.assignee,
          assigneeImg: listItemInfo.assigneeImg,
          attachments: 0,
          checked: false,
          complete: 1,
          details: false,
          description: listItemInfo.info.description,
          info: listItemInfo.info,
          due: listItemInfo.dueDate,
          eventId: listItemInfo.eventId > 0 ? listItemInfo.eventId : null,
          id: parseInt(result?.id),
          key: `${listItemInfo.checklistId}-${result?.id}`,
          name: listItemInfo.title,
          notes: 1,
          operatorId: listItemInfo.operatorId,
          parentId: listItemInfo.checklistId,
          priority: listItemInfo.priority,
          project: listItemInfo.project,
          selected: 0,
          stationId: listItemInfo.stationId > 0 ? listItemInfo.stationId : null,
          tags: task.tags.length > 0 ? [...task.tags] : [],
          texts: 0,
          type: 'child',
        };
        eventSocket.emit('addChecklistItem', JSON.stringify(newTask));
      }
    });
  };
  // edit task to API
  const updateTask = (obj) => {
    const { parentId, checked, complete, description, details } = obj;
    console.log(obj);

    const id = obj.key.split('-')[1];

    const iteminfo = {
      checked,
      complete,
      description,
      details,
      tags:
        has(isEditingTask.current, 'tags') && !isEqual(isEditingTask.current.tags, obj.tags)
          ? isEditingTask.current.tags
          : obj.tags,
    };

    console.log(isEditingTask.current.assignee, obj.assignee);
    const currentAssignee = has(isEditingTask.current, 'assignee') ? isEditingTask.current.assignee : obj.assignee;
    isEditingTask.current.assigneeImg = currentAssignee.trim() == userName
      ? userImage
      : '';

    const listItemInfo = {
      title: has(isEditingTask.current, 'name') ? isEditingTask.current.name : obj.name,
      dueDate:
        has(isEditingTask.current, 'due') && isEditingTask.current.due !== '01/01/1970'
          ? isEditingTask.current.due
          : obj.due
          ? obj.due
          : '01/01/1970',
      operatorId: has(isEditingTask.current, 'operatorId') && isEditingTask.current.operatorId
        ? isEditingTask.current.operatorId
        : obj.operatorId
        ? obj.operatorId
        : 0,
      stationId: has(isEditingTask.current, 'stationId') && isEditingTask.current.stationId
        ? isEditingTask.current.stationId
        : obj.stationId
        ? obj.stationId
        : 0,
      eventId: has(isEditingTask.current, 'eventId') && isEditingTask.current.eventId
        ? isEditingTask.current.eventId
        : obj.eventId
        ? obj.eventId
        : 0,
      info: iteminfo,
      project: has(isEditingTask.current, 'project') ? isEditingTask.current.project : obj.project,
      priority: has(isEditingTask.current, 'priority') ? isEditingTask.current.priority : obj.priority,
      assigneeImg: isEditingTask.current.assigneeImg,
    };

    if (has(isEditingTask.current, 'due') && isEditingTask.current.due !== '01/01/1970') {
      setCurrentDueDate('01/01/1970');
    }

    eventSocket.emit(
      'updateStationTask',
      JSON.stringify({
        id: id,
      })
    );
    eventSocket.emit(
      'updatesChecklistItem',
      JSON.stringify({
        assignee: currentAssignee,
        assigneeImg: listItemInfo.assigneeImg,
        attachments: 0,
        checked: checked,
        complete: complete,
        info: listItemInfo.info,
        details: details,
        due: listItemInfo.dueDate,
        eventId: listItemInfo.eventId > 0 ? listItemInfo.eventId : null,
        id: parseInt(id),
        key: `${parentId}-${id}`,
        name: listItemInfo.title,
        notes: 1,
        operatorId: listItemInfo.operatorId > 0 ? listItemInfo.operatorId : null,
        parentId: parentId,
        priority: listItemInfo.priority,
        project: listItemInfo.project,
        selected: 0,
        stationId: listItemInfo.stationId > 0 ? listItemInfo.stationId : null,
        tags: listItemInfo.info.tags,
        texts: 0,
        type: 'child',
      })
    );
    console.log(listItemInfo);
    updateItem(id.toString(), listItemInfo).then((res) => {
      // notification.open({
      //   message: 'Successfully Updated the Item!',
      // });
      resetChecklistWatchers(true);
    });
  };
  // delete task to API
  const deleteTask = (task) => {
    const id = parseInt(task.key.split('-')[1]);
    destroy(id).then(() => {
      // notification.open({
      //   message: 'Item Successfully deleted!',
      // });
      eventSocket.emit(
        'deleteChecklistItem',
        JSON.stringify({
          id: parseInt(id),
          parentId: task.parentId,
        })
      );
    });
  };
  // duplicate task
  const duplicateTask = (task) => {
    const { parentId, checked, complete, description, details } = task;

    const iteminfo = {
      checked,
      complete,
      description,
      details,
      tags: task.tags.length > 0 ? [...task.tags] : [],
    };

    const listItemInfo = {
      checklistId: parentId,
      title: task.name !== '' ? task.name : 'Untitled',
      dueDate: task.due !== '01/01/1970' ? task.due : null,
      operatorId: task.operatorId ? task.operatorId : 0,
      stationId: task.stationId ? task.stationId : 0,
      eventId: task.eventId ? task.eventId : 0,
      info: iteminfo,
      project: task.project !== '' ? task.project : '',
      priority: task.priority !== '' ? task.priority : '',
      createdBy: user_email,
    };

    createListItem(listItemInfo).then((result) => {
      if (result) {
        console.log('TASK ADDED', result);
        // notification.open({
        //   message: 'Successfully Added Item!',
        // });

        const newTask = {
          assignee: task.assignee,
          assigneeImg: '',
          attachments: 0,
          checked: false,
          complete: 1,
          details: false,
          due: listItemInfo.dueDate,
          eventId: listItemInfo.eventId > 0 ? listItemInfo.eventId : null,
          id: parseInt(result?.id),
          key: `${listItemInfo.checklistId}-${result?.id}`,
          name: listItemInfo.title,
          notes: 1,
          operatorId: listItemInfo.operatorId > 0 ? listItemInfo.operatorId : null,
          parentId: listItemInfo.checklistId,
          priority: listItemInfo.priority,
          project: listItemInfo.project,
          selected: 0,
          stationId: listItemInfo.stationId > 0 ? listItemInfo.stationId : null,
          tags: task.tags.length > 0 ? [...task.tags] : [],
          texts: 0,
          type: 'child',
        };
        eventSocket.emit('addChecklistItem', JSON.stringify(newTask));
      }
    });
  };

  // Return key listener for item edit
  // useEffect(() => {
  // 	const listener = (event) => {
  //
  // 		if (!isAddingTask && isEditingTask && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
  // 			console.log('Enter pressed during edit');
  // 			event.preventDefault();
  // 			updateTask('enter');
  // 		}
  // 	};
  // 	document.addEventListener('keydown', listener);
  // 	return () => {
  // 		document.removeEventListener('keydown', listener);
  // 	};
  // }, [isEditingTask, getTaskName, getAssignee, priorityLevel, taskDueDates, getTags, getProject, isAddingTask]);

  // for changing due dates in a task
  const handleDateChange = (date) => {
    setOpenDatePicker(false);
    return date.format('MM/DD/YYYY');
  };
  // changing assignee
  const handleAssigneeChange = (value) => {
    let newAssigneeVal = {};
    if (value !== undefined && Object.keys(JSON.parse(value)).length) {
      let assigneeVal = JSON.parse(value);
      if (assigneeVal.type === 'operator') {
        newAssigneeVal = { assignee: assigneeVal.name, operatorId: assigneeVal.id, stationId: 0, eventId: 0 };
      }
      if (assigneeVal.type === 'station') {
        newAssigneeVal = { assignee: assigneeVal.name, operatorId: 0, stationId: assigneeVal.id, eventId: 0 };
      }
      if (assigneeVal.type === 'event') {
        newAssigneeVal = { assignee: assigneeVal.name, operatorId: 0, stationId: 0, eventId: assigneeVal.id };
      }
    } else {
      newAssigneeVal = { assignee: '', operatorId: 0, stationId: 0, eventId: 0 };
    }
    return JSON.stringify(newAssigneeVal);
  };
  // change priority through ant table
  const changePriorityAnt = (obj, info) => {
    obj.priority = info.item.props.value;
  };
  // priority options for checklist tasks
  const priorityOptions = (obj) => (
    <Menu className="menu-show-preview" onClick={(info) => changePriorityAnt(obj, info)}>
      {[{ label: 'Low' }, { label: 'Medium' }, { label: 'High' }].map((m, i) => {
        return (
          <Menu.Item className="menu-show-preview" key={i} value={m.label}>
            <span className="priority-label-span">{m.label}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  // marking checklist task as complete
  const setCompleteTask = (obj) => {
    const taskKey = obj.key.split('-');
    const id = taskKey[1];
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: obj.parentId.toString() });
    const foundChildIndex = findIndex(clonedChecklist[foundIndex]?.children, { key: obj.key });
    const checkItem = clonedChecklist[foundIndex]?.children[foundChildIndex];
    const iteminfo = {
      checked: checkItem.complete !== 2 ? checkItem.checked : !checkItem.checked,
      complete: checkItem.complete !== 2 ? 2 : 1,
      description: checkItem.description,
      details: checkItem.details,
      tags: checkItem.tags,
    };
    eventSocket.emit(
      'updateStationTask',
      JSON.stringify({
        id: id,
      })
    );
    eventSocket.emit(
      'updatesChecklistItem',
      JSON.stringify({
        assignee: checkItem.assignee !== '' ? checkItem.assignee : '',
        assigneeImg: '',
        attachments: 0,
        checked: checkItem.complete !== 2 ? checkItem.checked : !checkItem.checked,
        complete: checkItem.complete !== 2 ? 2 : 1,
        details: checkItem.details,
        due: obj.due && obj.due !== '01/01/1970' ? obj.due : '01/01/1970',
        eventId: obj.eventId && obj.eventId > 0 ? obj.eventId : null,
        description: checkItem.description,
        id: id,
        key: `${obj.parentId}-${id}`,
        name: obj.name,
        notes: 1,
        operatorId: obj.operatorId && obj.operatorId > 0 ? obj.operatorId : null,
        parentId: obj.parentId,
        priority: obj.priority,
        project: obj.project,
        selected: 0,
        stationId: obj.stationId && obj.stationId > 0 ? obj.stationId : null,
        tags: checkItem.tags,
        texts: 0,
        type: 'child',
      })
    );
    updateItem(id, { info: iteminfo }).then((res) => {
      // notification.open({
      //   message: 'Successfully Updated Task!',
      // });
    });
  };

  // adding checklist image
  const handleDrop = useCallback(
    (data) => {
      console.log(data, chkId);
      if (data !== 'none') {
        let params = {
          info: { imageUrl: data },
        };
        updateCheckList(chkId, params).then((res) => {
          // notification.open({
          //   message: 'Successfully Uploaded Image!',
          // });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
      } else {
        notification.open({
          message: 'Image not uploaded, please try again',
        });
      }
    },
    [chkId]
  );
  // removing checklist image
  const deleteImage = (checklistId) => {
    updateCheckList(checklistId, { info: { imageUrl: '' } }).then((res) => {
      // notification.open({
      //   message: 'Successfully Removed Image!',
      // });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };

  // cancelling delete checklist image
  function cancelDeleteImg() {
    message.error('Image not deleted');
  }
  // checklist image action
  const removeImg = (checklistId) => {
    return (
      <Menu className="menu-show-preview">
        <Menu.Item className="menu-show-preview">
          <Popconfirm
            title="Are you sure you want to delete the image？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteImage(checklistId)}
            onCancel={cancelDeleteImg}
          >
            <span style={{ color: 'var(--base-text)' }}>Delete</span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  // filter task based on status
  const filterTask = (status) => {
    setIsSearching(false);
    let filtered = [];
    if (status == 'inc') {
      let inc = _.cloneDeep(pristineData).filter((obj) =>
        obj.children.some((task) => task.operatorId === user_id && task.checked == false)
      );
      filtered = inc;
      setFilteredchooseTaskStatus('Incomplete tasks for me');
    } else if (status == 'complete') {
      let complete = _.cloneDeep(pristineData).filter((obj) =>
        obj.children.some((task) => task.operatorId === user_id && task.checked == true)
      );
      filtered = complete;
      setFilteredchooseTaskStatus('Completed tasks for me');
    } else if (status == 'all') {
      let all = cloneDeep(pristineData);
      filtered = all;
      setFilteredchooseTaskStatus('All tasks');
    } else if (status == 'others') {
      let others = _.cloneDeep(pristineData).filter((obj) =>
        obj.children.some((task) => task.operatorId !== user_id && task.checked == true)
      );
      filtered = others;
      setFilteredchooseTaskStatus('Completed tasks for others');
    } else if (status == 'othersic') {
      let othersinc = _.cloneDeep(pristineData).filter((obj) =>
        obj.children.some((task) => task.operatorId !== user_id && task.checked == false)
      );
      filtered = othersinc;
      setFilteredchooseTaskStatus('Incomplete tasks for others');
    }

    let viewOnly = status !== 'all' ? true : false; // when filtered, sorted display filters out open add rows and button rows

    if (viewOnly) {
      setIsFiltering(true);
    } else {
      setIsFiltering(false);
    }

    if (localStorage.getItem('checklistColKeyOrder') != undefined) {
      const { order, columnKey } = JSON.parse(localStorage.getItem('checklistColKeyOrder'));
      const sorted = applyColumnOrder(columnKey, order, filtered, viewOnly);
      setDataSource(sorted);
    } else if (
      localStorage.getItem('checklistOrder') != undefined &&
      JSON.parse(localStorage.getItem('checklistOrder')).length > 0
    ) {
      const checklistOrder = JSON.parse(localStorage.getItem('checklistOrder'));
      const sorted = annotateTableOrder(filtered, checklistOrder, viewOnly);
      setDataSource(sorted);
    } else {
      setDataSource(filtered);
    }
  };

  const startEditTask = (task) => {
    isEditingTask.current.key = task.key;
    isEditingTask.current.parentId = task.parentId;
    setCurrentTags(task.tags);
    setCurrentDueDate(task.dueDate);
    resetChecklistWatchers(false);
  };

  // DROPDOWN OVERLAYS //
  // task row actions
  const taskActions = (task) => {
    return (
      <Menu className="menu-show-preview">
        <Menu.Item
          className="menu-show-preview"
          onClick={async () => {
            setCurrentTags(task.tags);

            isEditingTask.current.key = task.key;
            isEditingTask.current.parentId = task.parentId;
            setCurrentTags(task.tags);
            setCurrentDueDate(task.due);
          }}
        >
          <span>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview" onClick={() => deleteTask(task)}>
          <span>Delete</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview" onClick={() => duplicateTask(task)}>
          <span>Duplicate</span>
        </Menu.Item>
      </Menu>
    );
  };
  // checklist row actions
  const checkListActions = (checklist) => {
    return (
      <Menu className="menu-show-preview">
        <Menu.Item
          className="menu-show-preview"
          onClick={() => {
            isEditingChecklist.current = { ...checklist };
          }}
        >
          <span>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview" onClick={() => deleteChecklist(checklist)}>
          <span>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };
  // dropdown overlay for filter
  const chooseTaskStatus = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={() => filterTask('all')}>
        <span>All tasks</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={() => filterTask('inc')}>
        <span>Incomplete tasks for me</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={() => filterTask('complete')}>
        <span>Completed tasks for me</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={() => filterTask('othersic')}>
        <span>Incomplete tasks for others</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={() => filterTask('others')}>
        <span>Completed tasks for others</span>
      </Menu.Item>
    </Menu>
  );
  // DROPDOWN OVERLAYS //

  // when expanding a row
  const handleRowExpand = (record) => {
    if (expandedRows.includes(record.key)) {
      let filterRecord = expandedRows.filter((key) => key !== record.key);
      setExpandedRows(filterRecord);
      localStorage.setItem('checklistOpenRows', JSON.stringify(filterRecord));
    } else {
      setExpandedRows([...expandedRows, record.key]);
      localStorage.setItem('checklistOpenRows', JSON.stringify([...expandedRows, record.key]));
    }
  };

  // collapses all open rows
  const collapseAll = () => {
    setExpandedRows([]);
    localStorage.setItem('checklistOpenRows', JSON.stringify([]));
  };

  // cacheTableOrder - caches and annotates position of each parent and child row of current table order (index-based position)
  const cacheTableOrder = (data) => {
    let orderCached = [];
    let tableOrdered = [];
    if (data.length > 0) {
      tableOrdered = data.map((d, i) => {
        // for checklist categories
        const parentRow = {};
        parentRow.key = d.key;
        if (d?.order == undefined || d.order !== i) {
          parentRow.order = i; // caches order
          d.order = i; // annotates order
        } else {
          parentRow.order = d.order;
        }
        parentRow.children = [];
        if (d.children && d.children.length) {
          d.children = d.children.map((c, j) => {
            // for checklists
            const childRow = {};
            childRow.key = c.key;
            if (c?.order == undefined || c.order !== j) {
              childRow.order = j; // caches order
              c.order = j; // annotates order
            } else {
              childRow.order = c.order;
            }
            parentRow.children.push(childRow);
            return c;
          });
          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        orderCached.push(parentRow);
        return d;
      });
      if (data.length > 1) {
        tableOrdered = tableOrdered.sort((a, b) => a.order - b.order);
      }
    }
    return {
      checklistOrder: orderCached, // cached table order
      orderedChecklist: tableOrdered, // annotated table rows
    };
  };

  // annotateTableOrder - annotates new/current/updated order for each parent and child record of table (index-based position)
  // callback when localStorage variable 'checklistOrder' exists, then applies to fetched data
  const annotateTableOrder = (data, checklistOrder, viewOnly = false) => {
    let orderedTable = [];
    if (data.length > 0) {
      const tableAnnotated = data.map((d) => {
        // annotate checklist folder cached order
        const folderIndex = findIndex(checklistOrder, (r) => r.key === d.key);
        d.order = checklistOrder[folderIndex].order;
        if (d.children && d.children.length) {
          d.children = d.children.map((c, i) => {
            // annotate checklist cached order
            const checklistIndex = findIndex(checklistOrder[folderIndex].children, (h) => h.key === c.key);

            if (checklistOrder[folderIndex].children[checklistIndex] != undefined) {
              const cachedOrder = checklistOrder[folderIndex]?.children[checklistIndex].order;
              c.order = cachedOrder != undefined && !viewOnly && cachedOrder !== i ? cachedOrder : i;
            } else {
              c.order = i;
            }

            return c;
          });

          if (viewOnly) {
            d.children = d.children.filter((a) => a.type !== 'add-button');
          }

          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        return d;
      });

      orderedTable = tableAnnotated;

      if (viewOnly) {
        // removes open add tasks
        orderedTable = tableAnnotated.filter((a) => a.key.indexOf('0.') === -1);
      }

      if (data.length > 1) {
        // removes open add checklists
        orderedTable = tableAnnotated.sort((a, b) => a.order - b.order);
      }
    }
    return orderedTable;
  };

  // deannotateTableOrder - removes index order for each parent and child record
  const deannotateTableOrder = (data) => {
    if (data.length > 0) {
      // filters out open add checklist folder rows
      let orderRemoved = data;

      if (orderRemoved.length) {
        orderRemoved = orderRemoved.map((d) => {
          if (d?.order != undefined) {
            delete d['order'];
          }
          if (d.children && d.children.length) {
            d.children = d.children.map((e) => {
              if (e?.order != undefined) {
                delete e['order'];
              }
              return e;
            });
            if (d.children.length > 2) {
              d.children = d.children.sort((a, b) => a.key.localeCompare(b.key));
            }
          }
          return d;
        });
        if (orderRemoved.length > 1) {
          orderRemoved = orderRemoved.sort((a, b) => a.key.localeCompare(b.key));
        }
        return orderRemoved;
      }
    }
    return data;
  };

  // removeOpenAdds - remove open add rows in checklistRows
  const removeOpenAdds = (checklistRows) => {
    let removedOpenAdds = checklistRows.filter((k) => k.key.toString().indexOf('0.') === -1); // removes open add checklist folder rows
    if (removedOpenAdds.length > 1) {
      return removedOpenAdds
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((l, i) => {
          if (l.order * 1 !== i) {
            l.order = i;
          }
          return l;
        })
        .sort((a, b) => a.order - b.order);
    } else {
      return removedOpenAdds;
    }
  };

  // applyColumnOrder - apply cached ANTD column order to dataSource
  const applyColumnOrder = (column, status, list = [], viewOnly = false) => {
    let rows = list.length ? list : cloneDeep(pristineData);
    let tableRows = rows.filter((r) => r.key.indexOf('0.') === -1);
    let toAddFolders = rows.filter((r) => r.key.indexOf('0.') !== -1);
    let orderedRows = [];

    const arrangeRows = (rows) => {
      const rowsToSort = rows.filter((c) => c.type !== 'add-image' && c.type !== 'add-button');
      const addTaskRow = rows.filter((c) => c.type === 'add-button');
      const addImageRow = rows.filter((c) => c.type === 'add-image');
      return {
        firstRow: addImageRow[0],
        rowsToSort: rowsToSort,
        lastRow: addTaskRow[0],
      };
    };

    if (tableRows.length) {
      if (tableRows.length > 1) {
        if (column === 'name') {
          // sorts parent rows and child rows, except for image row and add button row
          if (status === 'ascend') {
            orderedRows = tableRows
              .map((p) => {
                if (p.children && p.children.length > 3) {
                  const { firstRow, rowsToSort, lastRow } = arrangeRows(p.children);
                  let sortedChildren = [];
                  if (rowsToSort.length > 1) {
                    sortedChildren = rowsToSort.sort((a, b) =>
                      a[column].toLowerCase().localeCompare(b[column].toLowerCase())
                    );
                  } else if (rowsToSort.length === 1) {
                    sortedChildren.push(rowsToSort[0]);
                  }
                  if (firstRow) {
                    // image row always on top
                    sortedChildren.unshift(firstRow);
                  }
                  if (!viewOnly && lastRow) {
                    // add task row always on the bottom
                    sortedChildren.push(lastRow);
                  }
                  p.children = [...sortedChildren];
                }
                return p;
              })
              .sort((a, b) => a[column].toLowerCase().localeCompare(b[column].toLowerCase())); // also sort checklist folder parents based on ANTD table sort settings
          } else {
            orderedRows = tableRows
              .map((p) => {
                if (p.children && p.children.length > 3) {
                  const { firstRow, rowsToSort, lastRow } = arrangeRows(p.children);
                  let sortedChildren = [];
                  if (rowsToSort.length > 1) {
                    sortedChildren = rowsToSort.sort((a, b) =>
                      b[column].toLowerCase().localeCompare(a[column].toLowerCase())
                    );
                  } else if (rowsToSort.length === 1) {
                    sortedChildren.push(rowsToSort[0]);
                  }
                  if (firstRow) {
                    sortedChildren.unshift(firstRow);
                  }
                  if (!viewOnly && lastRow) {
                    sortedChildren.push(lastRow);
                  }
                  p.children = [...sortedChildren];
                }
                return p;
              })
              .sort((a, b) => b[column].toLowerCase().localeCompare(a[column].toLowerCase()));
          }
        }
      } else {
        orderedRows = tableRows;
      }
      if (!viewOnly && toAddFolders.length) {
        // no sorting but always on top
        const preserveOpenAddRows = toAddFolders.sort((a, b) => a.key.localeCompare(b.key));
        orderedRows.splice(1, 0, ...preserveOpenAddRows);
      }
    }
    console.log(orderedRows);
    return orderedRows;
  };

  // for dragging rows
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (dragParent) {
        const data = [...dataSource];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        localStorage.setItem('checklistOrder', JSON.stringify(cacheTableOrder(data).checklistOrder));
        localStorage.removeItem('checklistColKeyOrder');
        hasTableOrderChanged(true);
      } else {
        let originalData = [...dataSource];
        console.log('ALL ROWS', originalData);
        const getObj = originalData.find((p) => p.key == taskToDrag.parentId);
        console.log('PARENT OF ROW TO MOVE', getObj);
        const data = getObj.children;
        const findIndx = data.findIndex((x) => x.key == taskToDrag.key);
        let firstIndex = Math.floor(fromIndex - findIndx);
        let destinationIndex = Math.floor(toIndex - firstIndex);
        const item = data.splice(findIndx, 1)[0];
        data.splice(destinationIndex, 0, item);
        originalData.find((v) => v.key == taskToDrag.parentId).children = data;
        console.log('REORDERED', originalData);
        localStorage.setItem('checklistOrder', JSON.stringify(cacheTableOrder(originalData).checklistOrder));
        localStorage.removeItem('checklistColKeyOrder');
        hasTableOrderChanged(true);
      }
    },
    handleSelector: 'a',
    nodeSelector: dragParent
      ? 'tr.ant-table-row.parent.ant-table-row-level-0'
      : 'tr.ant-table-row.child.ant-table-row-level-1',
    ignoreSelector: dragParent
      ? 'tr.ant-table-row.child.ant-table-row-level-1'
      : 'tr.ant-table-row.parent.ant-table-row-level-0',
  };

  // filter checklist based on search
  const filterChecklist = (e) => {
    setIsFiltering(false);
    let inputValue = e.target.value;

    if (inputValue.length) {
      setIsSearching(true);

      const clonedData = _.cloneDeep(pristineData);

      let searchString = inputValue.toLowerCase();

      let results = clonedData.filter(searchAllArr(inputValue));

      const searchTags = results.map((item) => ({
        ...item,
        children: item.children?.filter(
          (child) =>
            child.tags?.find((a) => a.includes(searchString)) ||
            child.name?.toLowerCase().indexOf(searchString) !== -1 ||
            child.assignee?.toLowerCase().indexOf(searchString) !== -1 ||
            child.project?.toLowerCase().indexOf(searchString) !== -1 ||
            child.priority?.toLowerCase().indexOf(searchString) !== -1
        ),
      }));

      if (localStorage.getItem('checklistColKeyOrder') != undefined) {
        const { order, columnKey } = JSON.parse(localStorage.getItem('checklistColKeyOrder'));
        const toDisplay = applyColumnOrder(columnKey, order, searchTags, true);
        setDataSource(toDisplay);
      } else if (
        localStorage.getItem('checklistOrder') != undefined &&
        JSON.parse(localStorage.getItem('checklistOrder')).length
      ) {
        const checklistOrder = JSON.parse(localStorage.getItem('checklistOrder'));
        const toDisplay = annotateTableOrder(searchTags, checklistOrder, true);
        setDataSource(toDisplay);
      } else {
        setDataSource(searchTags);
      }
    } else {
      setIsSearching(false);
      if (localStorage.getItem('checklistColKeyOrder') != undefined) {
        const { order, columnKey } = JSON.parse(localStorage.getItem('checklistColKeyOrder'));
        const toDisplay = applyColumnOrder(columnKey, order, socketData, false);
        setDataSource(toDisplay);
      } else if (
        localStorage.getItem('checklistOrder') != undefined &&
        JSON.parse(localStorage.getItem('checklistOrder')).length
      ) {
        const checklistOrder = JSON.parse(localStorage.getItem('checklistOrder'));
        const toDisplay = annotateTableOrder(socketData, checklistOrder, false);
        setDataSource(toDisplay);
      } else {
        setDataSource(socketData);
      }
    }
  };

  const searchAllArr = (text) => (value) => {
    if (!value) return false;
    const valueType = typeof value;

    if (valueType === 'string') {
      return value.toLowerCase().indexOf(text.toLowerCase()) > -1;
    }
    if (Array.isArray(value)) {
      return value.some(searchAllArr(text));
    }
    if (valueType === 'object') {
      return Object.values(value).some(searchAllArr(text));
    }
    return false;
  };

  // when closing drawer
  const closeDrawer = useCallback((data) => {
    setMenuDrawerVisible(false);
    setDetailDrawerVisible(false);
    hasTableOrderChanged(true);
  }, []);

  // EVENT SOCKET EFFECTS
  useEffect(() => {
    eventSocket.on('updatedChecklist', (message) => {
      let newData = JSON.parse(message);
      console.log('CHKLISUPDATED!!', [newData]);
      setChecklistUpdate([newData]);
    });
    eventSocket.on('updatedChecklistItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMUPDATED!!', newData);
      setTaskUpdate(newData);
    });
    eventSocket.on('updatedStationTask', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMUPDATEDSTATION!!', newData.id);
      setTaskCheckUpdate(newData.id);
    });
    eventSocket.on('addedChecklist', (message) => {
      let newData = JSON.parse(message);
      console.log('CHKLISTADDED!!', newData);
      setChecklistAdd(newData);
    });
    eventSocket.on('addedChecklistItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMADDED!!', newData);
      setTaskAdd(newData);
    });
    eventSocket.on('deletedChecklist', (message) => {
      let newData = JSON.parse(message);
      console.log('CHKLISTDELETED!!', newData.id);
      setChecklistDelete(newData.id);
    });
    eventSocket.on('deletedChecklistItem', (message) => {
      let newData = JSON.parse(message);
      console.log('CHKLISTITEMDELETED!!', newData);
      setTaskDelete(newData);
    });
    return () => {
      eventSocket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTaskToChecklist = (cloned, data) => {
    let newCloned = cloned;
    const checklistIndex = findIndex(newCloned, { key: data.parentId.toString() });
    let checklistChildren = newCloned[checklistIndex]?.children;

    if (newCloned[checklistIndex]) {
      newCloned[checklistIndex].children[checklistChildren.length - 1] = { ...data };
      newCloned[checklistIndex].children.push({
        key: `${data.parentId}-9999999`,
        type: 'add-button',
        parentId: data.parentId,
      });
    }
    return newCloned;
  };

  const checkTaskInChecklist = (cloned, data) => {
    let newCloned = cloned.map((c) => {
      c.children.map((t) => {
        if (t.id == data) {
          if (t.checked == true) {
            t.checked = false;
            t.complete = 1;
          } else {
            t.checked = true;
            t.complete = 2;
          }
        }
        return t;
      });
      return c;
    });
    return newCloned;
  };

  const updateTaskInChecklist = (cloned, data) => {
    let index = cloned?.findIndex((obj) => obj.key == data?.parentId);
    let childIndex = cloned[index]?.children.findIndex((obj) => obj.id == data.id);
    let newCloned = cloned.map((c) => {
      c.children.map((t) => {
        if (t.id == data.id) {
          c.children[childIndex] = data;
          return t;
        }
        return t;
      });
      return c;
    });
    return newCloned;
  };

  const removeTaskFromChecklist = (cloned, data) => {
    let newCloned = cloned;
    const findChecklistIndex = findIndex(cloned, { key: data.parentId.toString() });
    remove(newCloned[findChecklistIndex]?.children, (c) => c.id === data.id);
    return newCloned;
  };

  useEffect(() => {
    if (Object.keys(checklistAdd).length > 0) {
      const tempId = checklistAdd.tempId;
      remove(dataSource, (m) => m.tempId === tempId);
      delete checklistAdd['tempId'];

      const updatedData = [...dataSource, checklistAdd];
      const updatedSocket = [...socketData, checklistAdd];

      const updatedTableOrder = cacheTableOrder(updatedData);
      setDataSource(updatedTableOrder.orderedChecklist);
      localStorage.setItem('checklistOrder', JSON.stringify(updatedTableOrder.checklistOrder));

      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
      localStorage.setItem('originalChecklists', JSON.stringify(updatedSocket));
      localStorage.setItem('checklistOriginalData', updatedSocket.length);
    }
  }, [checklistAdd]);

  useEffect(() => {
    if (Object.keys(taskAdd).length > 0) {
      // const clonedCheckList = _.cloneDeep(dataSource);
      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);

      const updatedData = addTaskToChecklist(clonedData, taskAdd);
      const updatedSocket = addTaskToChecklist(clonedSocket, taskAdd);

      const updatedTableOrder = cacheTableOrder(updatedData);
      setDataSource(updatedTableOrder.orderedChecklist);
      localStorage.setItem('checklistOrder', JSON.stringify(updatedTableOrder.checklistOrder));

      setSocketData(updatedSocket);
      setPristineData(updatedSocket);
      localStorage.setItem('originalChecklists', JSON.stringify(updatedSocket));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskAdd]);
  useEffect(() => {
    if (checklistUpdate.length > 0) {
      let updatedData = dataSource.map((obj) => checklistUpdate.find((o) => o.key === obj.key) || obj);
      let updatedSocket = socketData.map((obj) => checklistUpdate.find((o) => o.key === obj.key) || obj);
      setDataSource(updatedData);

      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
      localStorage.setItem('originalChecklists', JSON.stringify(updatedSocket));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistUpdate]);
  useEffect(() => {
    if (taskCheckUpdate !== 0) {
      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);

      const updatedData = checkTaskInChecklist(clonedData, taskCheckUpdate);
      const updatedSocket = checkTaskInChecklist(clonedSocket, taskCheckUpdate);
      setDataSource(updatedData);

      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
      localStorage.setItem('originalChecklists', JSON.stringify(updatedSocket));
      setTimeout(() => {
        setTaskCheckUpdate(0);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskCheckUpdate]);
  useEffect(() => {
    if (Object.keys(taskUpdate).length > 0) {
      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);

      const updatedData = updateTaskInChecklist(clonedData, taskUpdate);
      const updatedSocket = updateTaskInChecklist(clonedSocket, taskUpdate);
      setDataSource(updatedData);

      setSocketData(updatedSocket);
      setPristineData(updatedSocket);
      localStorage.setItem('originalChecklists', JSON.stringify(updatedSocket));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskUpdate]);
  useEffect(() => {
    if (checklistDelete.length) {
      let clonedData = cloneDeep(dataSource);
      let clonedSocket = cloneDeep(socketData);
      remove(clonedData, (c) => c.key === checklistDelete.toString());
      remove(clonedSocket, (c) => c.key === checklistDelete.toString());

      const updatedTableOrder = cacheTableOrder(clonedData);
      setDataSource(updatedTableOrder.orderedChecklist);
      localStorage.setItem('checklistOrder', JSON.stringify(updatedTableOrder.checklistOrder));

      setPristineData(clonedSocket);
      setSocketData(clonedSocket);
      localStorage.setItem('originalChecklists', JSON.stringify(clonedSocket));
      localStorage.setItem('checklistOriginalData', clonedSocket.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistDelete]);
  useEffect(() => {
    if (Object.keys(taskDelete).length > 0) {
      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);

      const updatedData = removeTaskFromChecklist(clonedData, taskDelete);
      const updatedSocket = removeTaskFromChecklist(clonedSocket, taskDelete);

      const updatedTableOrder = cacheTableOrder(updatedData);
      setDataSource(updatedTableOrder.orderedChecklist);
      localStorage.setItem('checklistOrder', JSON.stringify(updatedTableOrder.checklistOrder));

      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
      localStorage.setItem('originalChecklists', JSON.stringify(updatedSocket));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDelete]);

  // ANTD Table onMouseOver callback: triggered when task/checklist row is hovered before dragging
  const hoverBeforeDrag = (e, obj) => {
    if (obj.type == 'parent') {
      setDragParent(true);
      activeCat.current = obj.key;
      isDown.current = true;
    } else {
      setTaskToDrag(obj);
      setDragParent(false);
      activeCat.current = obj.key;
      isDown.current = true;
    }
  };

  // ANTD Table onMouseLeave callback: cancels dependencies created from hovering task/checklist row
  const cancelHover = (e, obj) => {
    if (obj.id == 'child') {
      setTaskToDrag({});
      activeCat.current = null;
      isDown.current = false;
    } else {
      setDragParent(false);
      activeCat.current = null;
      isDown.current = false;
    }
  };
  // ANTD Table onDrop callback
  const setDestination = (e, obj) => {
    e.preventDefault();
  };

  const removeAssignee = (obj) => {
    console.log(obj);
    isEditingTask.current.assignee = '';
    isEditingTask.current.stationId = null;
    isEditingTask.current.eventId = null;
    isEditingTask.current.operatorId = null;
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: obj.parentId.toString() });
    const foundChildIndex = findIndex(clonedChecklist[foundIndex]?.children, { key: obj.key });
    console.log(clonedChecklist, foundIndex, foundChildIndex);
    if (clonedChecklist[foundIndex]) {
      clonedChecklist[foundIndex].children[foundChildIndex].assignee = '';
      clonedChecklist[foundIndex].children[foundChildIndex].stationId = null;
      clonedChecklist[foundIndex].children[foundChildIndex].eventId = null;
      clonedChecklist[foundIndex].children[foundChildIndex].operatorId = null;
    }
    setDataSource(clonedChecklist);
  };

  const changePriority = (e, obj) => {
    isEditingTask.current.priority = e.target.value;
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: obj.parentId.toString() });
    const foundChildIndex = findIndex(clonedChecklist[foundIndex]?.children, { key: obj.key });
    console.log(clonedChecklist, foundIndex, foundChildIndex);
    if (clonedChecklist[foundIndex]) {
      clonedChecklist[foundIndex].children[foundChildIndex].priority = e.target.value;
    }
    setDataSource(clonedChecklist);
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    let data = cloneDeep(dataSource);
    // const current = extra['currentDataSource'];
    const viewOnly = isFiltering || isSearching ? true : false;
    if (data.length && !viewOnly) {
      // if sorter is neither ascending nor descending,
      // resort to manual checklist table order
      // or sort by key
      // else save last auto table order (ascending or descending)

      if (sorter?.order == undefined) {
        if (
          localStorage.getItem('checklistOrder') != undefined &&
          JSON.parse(localStorage.getItem('checklistOrder')).length
        ) {
          const checklistOrder = JSON.parse(localStorage.getItem('checklistOrder'));
          const restoredOrder = annotateTableOrder(data, checklistOrder, viewOnly);
          setDataSource(restoredOrder);
        } else {
          if (viewOnly) {
            setDataSource(data);
          } else {
            const restoredOrder = deannotateTableOrder(data);
            setDataSource(restoredOrder);
          }
        }
        localStorage.removeItem('checklistColKeyOrder');
      } else {
        localStorage.setItem(
          'checklistColKeyOrder',
          JSON.stringify({
            order: sorter.order,
            columnKey: sorter.columnKey,
          })
        );
        const restoredOrder = applyColumnOrder(sorter.columnKey, sorter.order, data);
        setDataSource(restoredOrder);
        // extra['currentDataSource'] = [...restoredOrder]; // ANTD Table remains uncontrolled component when applying newly sorted order from custom function, to be changed...
      }
    }
  };

  // table column definition
  const columns = [
    {
      title: () => {
        return <div></div>;
      },
      className: 'col_handle',
      dataIndex: 'dragrow',
      width: '2%',
      render: (key, obj) =>
        isFiltering || isSearching ? null : (
          <span>
            {
              (obj = 'parent' ? (
                <a className="drag-handle">
                  <span className={isDown.current && obj.key == activeCat.current ? 'showDragIcon' : 'hideDragIcon'}>
                    {' '}
                    <img src={reorder} alt="reorder" className="chklistToggles" />
                  </span>
                </a>
              ) : (
                <a className="drag-handle">
                  <span className={isDown.current && obj.key == activeCat.current ? 'showDragIcon' : 'hideDragIcon'}>
                    <img src={reorder} alt="reorder" className="chklistToggles" />
                  </span>
                </a>
              ))
            }
          </span>
        ),
    },
    // expand-row-icon
    {
      title: () => {
        return <div></div>;
      },
      className: 'col_expand',
      width: '2%',
    },
    // Name
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['ascend', 'descend'],
      width: '30%',
      render: (key, obj) => (
        <div style={{ display: 'flex' }}>
          <span className={obj.type == 'parent' ? '' : 'name-span'}>
            {/* if task is assigned to user, completed */}
            {obj.checked &&
              obj.operatorId !== null &&
              obj.eventId == null &&
              obj.stationId == null &&
              obj.type !== 'add-image' &&
              obj.type !== 'add-button' &&
              obj.type !== 'parent' &&
              obj.key !== `${obj.parentId}-9999999` &&
              obj.key !== isEditingTask.current.key && (
                <span className="check-task" onClick={() => setCompleteTask(obj)}>
                  <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                </span>
              )}
            {/* if task is unassigned, completed */}
            {obj.checked &&
              obj.operatorId == null &&
              obj.eventId == null &&
              obj.stationId == null &&
              obj.type !== 'add-image' &&
              obj.type !== 'add-button' &&
              obj.type !== 'parent' &&
              obj.key !== `${obj.parentId}-9999999` &&
              obj.key !== isEditingTask.current.key && (
                <span className="check-task" onClick={() => setCompleteTask(obj)}>
                  <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                </span>
              )}
            {/* if task is assigned to an event station, completed */}
            {obj.checked &&
              obj.operatorId == null &&
              obj.stationId == null &&
              obj.eventId !== null &&
              obj.type !== 'add-image' &&
              obj.type !== 'add-button' &&
              obj.key !== `${obj.parentId}-9999999` &&
              obj.type !== 'parent' &&
              obj.key !== isEditingTask.current.key && (
                <span className="check-task" onClick={() => setCompleteTask(obj)}>
                  <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                </span>
              )}
            {/* if task is assigned to a kitchen station, completed */}
            {obj.checked &&
              obj.operatorId == null &&
              obj.eventId == null &&
              obj.stationId !== null &&
              obj.type !== 'add-image' &&
              obj.type !== 'add-button' &&
              obj.key !== `${obj.parentId}-9999999` &&
              obj.type !== 'parent' &&
              obj.key !== isEditingTask.current.key && (
                <span className="check-task" onClick={() => setCompleteTask(obj)}>
                  <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                </span>
              )}
            {/* if task to current user, incomplete */}
            {!obj.checked &&
              obj.operatorId !== null &&
              obj.eventId == null &&
              obj.stationId == null &&
              obj.type !== 'add-image' &&
              obj.type !== 'add-button' &&
              obj.key !== `${obj.parentId}-9999999` &&
              obj.type !== 'parent' &&
              obj.key !== isEditingTask.current.key && (
                <span className="check-task" onClick={() => setCompleteTask(obj)}>
                  <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                </span>
              )}
            {/* if task is unassigned, incomplete */}
            {!obj.checked &&
              obj.operatorId == null &&
              obj.eventId == null &&
              obj.stationId == null &&
              obj.type !== 'add-image' &&
              obj.type !== 'add-button' &&
              obj.key !== `${obj.parentId}-9999999` &&
              obj.type !== 'parent' &&
              obj.key !== isEditingTask.current.key && (
                <span className="check-task" onClick={() => setCompleteTask(obj)}>
                  <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                </span>
              )}
            {/* if task is assigned to an event station, incomplete */}
            {!obj.checked &&
              obj.operatorId == null &&
              obj.stationId == null &&
              obj.eventId !== null &&
              obj.type !== 'add-image' &&
              obj.type !== 'add-button' &&
              obj.key !== `${obj.parentId}-9999999` &&
              obj.type !== 'parent' &&
              obj.key !== isEditingTask.current.key && (
                <span className="check-task" onClick={() => setCompleteTask(obj)}>
                  <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                </span>
              )}
            {/* if task is assigned to a kitchen station, incomplete */}
            {!obj.checked &&
              obj.operatorId == null &&
              obj.eventId == null &&
              obj.stationId !== null &&
              obj.type !== 'add-image' &&
              obj.type !== 'add-button' &&
              obj.key !== `${obj.parentId}-9999999` &&
              obj.type !== 'parent' &&
              obj.key !== isEditingTask.current.key && (
                <span className="check-task" onClick={() => setCompleteTask(obj)}>
                  <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                </span>
              )}
            {/* if task is not assigned to current user and is completed */}
            {obj.checked &&
              obj.operatorId !== null &&
              obj.eventId == null &&
              obj.stationId == null &&
              obj.type !== 'add-image' &&
              obj.type !== 'add-button' &&
              obj.type !== 'parent' &&
              (obj.key === isEditingTask.current.key ||
                (obj.key === `${obj.parentId}-9999999` && has(obj, 'description'))) && (
                <span className="check-task" style={{ cursor: 'not-allowed' }}>
                  <CompletionIcon completed={obj.complete === 2} dimmed={true} />
                </span>
              )}
            {/* if task is not assigned to the current user and is not yet completed */}
            {!obj.checked &&
              obj.operatorId !== null &&
              obj.eventId == null &&
              obj.stationId == null &&
              obj.type !== 'add-image' &&
              obj.type !== 'add-button' &&
              obj.type !== 'parent' &&
              (obj.key === isEditingTask.current.key ||
                (obj.key === `${obj.parentId}-9999999` && has(obj, 'description'))) && (
                <span className="check-task" style={{ cursor: 'not-allowed' }}>
                  <CompletionIcon completed={obj.complete === 2} dimmed={true} />
                </span>
              )}
          </span>
          <div className={obj.type == 'parent' ? '' : 'name-key'}>
            {/* Adding new checklist */}
            {obj.type === 'parent' && obj.key === `${obj.tempId}` && (
              <>
                <div style={{ display: 'flex' }}>
                  <input
                    autoFocus
                    type="text"
                    className="row-name-input"
                    placeholder="Enter new checklist name"
                    defaultValue={obj.name}
                    onChange={(e) => {
                      obj.name = e.target.value;
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        addChecklist(obj);
                      }
                    }}
                  />
                </div>
              </>
            )}
            {/* Display image without url */}
            {obj.type == 'add-image' && obj.imageUrl === '' && (
              <div onMouseOver={() => setChkId(obj.parentId)}>
                <AddImage handleDrop={(e) => handleDrop(e)} dropImg={() => setChkId(obj.parentId)} />
              </div>
            )}
            {/* Display image with url */}
            {obj.type == 'add-image' && obj.imageUrl !== '' && (
              <ThumbnailImage
                className="checklist-cover-image-container"
                src={obj.imageUrl}
                type={'cover'}
                placeholderIcon={<FolderIcon />}
              />
            )}
            {/* Image actions */}
            {obj.type == 'add-image' && obj.imageUrl !== '' && (
              <span>
                <Dropdown overlay={removeImg(obj.parentId)}>
                  <div className="checklist-image-toggle-switch">
                    <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                  </div>
                </Dropdown>
              </span>
            )}
            {/* Editing checklist row (checklist name) */}
            {obj.type === 'parent' &&
              has(isEditingChecklist.current, 'key') &&
              isEditingChecklist.current.key === obj.key && (
                <div style={{ display: 'flex' }}>
                  <input
                    autoFocus
                    type="text"
                    className="row-name-input"
                    placeholder="Change checklist name"
                    defaultValue={has(isEditingChecklist.current, 'key') ? isEditingChecklist.current.name : obj.name}
                    onChange={(e) => {
                      isEditingChecklist.current.name = e.target.value;
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        updateChecklist(obj);
                      }
                    }}
                  />
                </div>
              )}
            {/* Checklist when not being added nor edited */}
            {obj.type === 'parent' && isEditingChecklist.current.key !== obj.key && obj.key !== `${obj.tempId}` && (
              <span className={obj.selected > 0 ? 'highlight' : ''}>
                <a className="drag-handle">
                  <span
                    onClick={() => {
                      isEditingChecklist.current = { ...obj };
                      resetChecklistWatchers(false);
                    }}
                  >
                    {key}
                  </span>
                </a>
              </span>
            )}
          </div>

          {/* Adding new task and Add task button row */}
          {obj.key === `${obj.parentId}-9999999` &&
            (obj.type === 'child' ? (
              <>
                <a className="drag-handle">
                  <span className={isDown && obj.id == activeCat ? 'item-nameWeb' : 'item-name'}>
                    <input
                      autoFocus
                      type="text"
                      className="row-date-edit"
                      onChange={(e) => {
                        obj.name = e.target.value;
                      }}
                      placeholder="Enter Checklist item"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          console.log('task to add', obj);
                          addTask(obj);
                        }
                      }}
                    />
                  </span>
                </a>
              </>
            ) : (
              <CidekicButton className="button-link-replacement" alt="add task" onClick={() => createNewTask(obj)}>
                Add task
              </CidekicButton>
            ))}

          {/* Task name not being edited nor added */}
          {obj.type == 'child' && obj.key === `${obj.parentId}-${obj.id}` && obj.key !== isEditingTask.current.key && (
            <a className="drag-handle">
              <div
                className={isDown && obj.id == activeCat ? 'item-nameWeb' : 'item-name'}
                // onClick={() => {
                //   startEditTask(obj);
                // }}
              >
                {obj.name}
              </div>
            </a>
          )}

          {/* Task name on edit mode */}
          {obj.type == 'child' && has(isEditingTask.current, 'key') && obj.key === isEditingTask.current.key && (
            <a className="drag-handle">
              <span className={isDown && obj.id == activeCat ? 'item-nameWeb' : 'item-name'}>
                <input
                  type="text"
                  defaultValue={has(isEditingTask.current, 'name') ? isEditingTask.current.name : obj.name}
                  onChange={(e) => {
                    isEditingTask.current.name = e.target.value;
                  }}
                  className="row-project"
                  placeholder="Enter task"
                />
              </span>
            </a>
          )}

          {/* selected menus */}
          {obj.type == 'child' && obj.key === `${obj.parentId}-${obj.id}` && obj.key !== isEditingTask.current.key ? (
            <AttachmentIndicator
              className="menu-attachment-indicator"
              showIfZero={true}
              count={obj.selected}
              onClick={(e) => showMenuDrawer(e, obj)}
            />
          ) : (
            ''
          )}

          {/* attached menus */}
          {/* {obj.type == 'child' &&
          obj.key === `${obj.parentId}-${obj.id}` &&
          obj.key !== isEditingTask.current.key ? (
            <AttachmentIndicator
              className="menu-attachment-indicator"
              showIfZero={true}
              count={obj.attachments}
              onClick={(e) => showMenuDrawer(e, obj)}
            />
          ) : (
            ''
          )} */}

          {/* task detail CTA */}
          {obj.type == 'child' &&
          obj.key === `${obj.parentId}-${obj.id}` &&
          obj.key !== isEditingTask.current.key &&
          obj.details ? (
            <span className="details-checklist" onClick={showDetailDrawer}>
              Details
            </span>
          ) : (
            ''
          )}
        </div>
      ),
    },
    // Assignee
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      // sorter: (a, b) => (a.assignee < b.assignee ? -1 : a.assignee > b.assignee ? 1 : 0),
      // sortDirections: ['descend', 'ascend'],
      width: '20%',
      ellipsis: true,
      render: (key, obj) => (
        <span>
          {/* Assignee */}
          {obj.type == 'child' && obj.key === `${obj.parentId}-${obj.id}` && obj.key !== isEditingTask.current.key && (
            <>
              <div className="common-user-icon" alt="user-photo">
                <UserImage src={obj.assigneeImg !== '' ? obj.assigneeImg : null} color={null} /*"#00ff00"*/ />
              </div>
              <span
                onClick={() => {
                  startEditTask(obj);
                }}
              >
                {typeof obj.assignee === 'string' ? obj.assignee : obj.assignee.assignee}
              </span>
            </>
          )}
          {/* Assignee edit mode */}
          {obj.type == 'child' && has(isEditingTask.current, 'key') && obj.key === isEditingTask.current.key && (
            <RowAssigneeEdit
              assigneeChoices={assigneeChoices}
              assignee={has(isEditingTask.current, 'assignee') ? isEditingTask.current.assignee : obj.assignee}
              onChange={(value) => {
                const assigneeInfo = JSON.parse(handleAssigneeChange(value));
                isEditingTask.current.assignee = assigneeInfo.assignee;
                isEditingTask.current.stationId = assigneeInfo.stationId > 0 ? assigneeInfo.stationId : null;
                isEditingTask.current.eventId = assigneeInfo.eventId > 0 ? assigneeInfo.eventId : null;
                isEditingTask.current.operatorId = assigneeInfo.operatorId > 0 ? assigneeInfo.operatorId : null;
              }}
              onClickRemove={() => {
                removeAssignee(obj);
              }}
            />
          )}
          {/* Assignee add mode */}
          {obj.type == 'child' && obj.key === `${obj.parentId}-9999999` && has(obj, 'description') && (
            <RowAssigneeEdit
              assigneeChoices={assigneeChoices}
              assignee={obj.assignee ? obj.assignee : 'Search assignee'}
              onChange={(value) => {
                const assigneeInfo = JSON.parse(handleAssigneeChange(value));
                obj.assignee = assigneeInfo.assignee;
                obj.stationId = assigneeInfo.stationId;
                obj.eventId = assigneeInfo.eventId;
                obj.operatorId = assigneeInfo.operatorId;
              }}
              onClickRemove={() => {
                removeAssignee(obj);
              }}
            />
          )}
        </span>
      ),
    },
    // Due Date
    {
      title: 'Due date',
      dataIndex: 'due',
      width: '10%',
      render: (key, obj) => (
        <span>
          {/* Due date */}
          {obj.type === 'child' && obj.key === `${obj.parentId}-${obj.id}` && obj.key !== isEditingTask.current.key && (
            <span
              onClick={() => {
                startEditTask(obj);
              }}
            >
              {obj.due !== '01/01/1970' ? obj.due : null}
            </span>
          )}
          {obj.type === 'child' && has(isEditingTask.current, 'key') && obj.key === isEditingTask.current.key && (
            <span className="flex">
              <span className="row-date-edit" onClick={() => setOpenDatePicker(true)}>
                {has(isEditingTask.current, 'due')
                  ? isEditingTask.current.due
                  : currentDueDate !== '01/01/1970'
                  ? currentDueDate
                  : 'Select Date'}
              </span>
              {(openDatePicker ||
                (has(isEditingTask.current, 'due') &&
                  isEditingTask.current.due !== 'Select Date' &&
                  isEditingTask.current.due !== '01/01/1970')) && (
                <span
                  className="assignee-search-close-webkit"
                  onClick={() => {
                    setOpenDatePicker(false);
                    if (isEditingTask.current.due !== 'Select Date') {
                      isEditingTask.current.due = 'Select Date';
                    }
                  }}
                >
                  x
                </span>
              )}
              {openDatePicker && (
                <DatePicker
                  getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                  style={{ height: 0 }}
                  open={openDatePicker}
                  onChange={(val) => {
                    isEditingTask.current.due = handleDateChange(val);
                  }}
                  allowClear={false}
                  size={`small`}
                />
              )}
            </span>
          )}
          {obj.type == 'child' && obj.key === `${obj.parentId}-9999999` && has(obj, 'description') && (
            <span className="flex">
              <span className="row-date-edit" onClick={() => setOpenDatePicker(true)}>
                {obj.due !== '01/01/1970' && obj.due !== 'Select Date' ? obj.due : 'Select Date'}
              </span>
              {(openDatePicker || (obj.due !== 'Select Date' && obj.due !== '01/01/1970')) && (
                <span
                  className="assignee-search-close-webkit"
                  onClick={() => {
                    if (obj.due !== 'Select Date') {
                      obj.due = 'Select Date';
                    }
                  }}
                >
                  x
                </span>
              )}
              {openDatePicker && (
                <DatePicker
                  getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                  style={{ height: 0 }}
                  open={openDatePicker}
                  onChange={(val) => {
                    obj.due = handleDateChange(val);
                  }}
                  allowClear={false}
                  size={`small`}
                />
              )}
            </span>
          )}
        </span>
      ),
    },
    // Project
    {
      title: 'Project',
      dataIndex: 'project',
      width: '10%',
      render: (key, obj) => (
        <span>
          {/* Project */}
          {obj.type == 'child' && obj.key === `${obj.parentId}-${obj.id}` && obj.key !== isEditingTask.current.key && (
            <span
              onClick={() => {
                startEditTask(obj);
              }}
            >
              {obj.project}
            </span>
          )}
          {/* Project Edit mode */}
          {obj.type == 'child' && has(isEditingTask.current, 'key') && obj.key === isEditingTask.current.key && (
            <span className="flex">
              <input
                type="text"
                defaultValue={has(isEditingTask.current, 'project') ? isEditingTask.current.project : obj.project}
                onChange={(e) => {
                  isEditingTask.current.project = e.target.value;
                }}
                className="row-project"
                placeholder="Project"
              />
              <span
                className="assignee-search-close-webkit"
                onClick={() => {
                  isEditingTask.current.project = '';
                }}
              >
                x
              </span>
            </span>
          )}
          {/* Project Add mode */}
          {obj.type == 'child' && obj.key === `${obj.parentId}-9999999` && has(obj, 'description') && (
            <span className="flex">
              <input
                type="text"
                onChange={(e) => {
                  obj.project = e.target.value;
                }}
                className="row-project"
                placeholder="Project"
              />
              <span
                className="assignee-search-close-webkit"
                onClick={() => {
                  obj.project = '';
                }}
              >
                x
              </span>
            </span>
          )}
        </span>
      ),
    },
    // Tags
    // {
    //   title: 'Tags',
    //   dataIndex: 'tags',
    //   // sorter: (a, b) => (a.tags < b.tags ? -1 : a.tags > b.tags ? 1 : 0),
    //   // sortDirections: ['descend', 'ascend'],
    //   width: '15%',
    //   render: (tags, obj) => (
    //     <RowTags
    //       isEditing={has(isEditingTask.current, 'key') && isEditingTask.current.key === obj.key}
    //       isAdding={obj.key === `${obj.parentId}-9999999` && has(obj, 'description')}
    //       inputName={`add-tags-${obj.id}-input`}
    //       inputId={`${obj.id}-input-tag`}
    //       onEditKeyPress={(e) => {
    //         if (e.key === 'Enter') {
    //           // if row is open edit
    //           if (has(isEditingTask.current, 'key') && isEditingTask.current.key === obj.key) {
    //             // if reference has 'tags' key already
    //             if (has(isEditingTask.current, 'tags')) {
    //               isEditingTask.current.tags.push(e.target.value.trim());
    //             } else {
    //               isEditingTask.current.tags = currentTags.concat(e.target.value.trim());
    //             }
    //           }
    //           if (obj.key === `${obj.parentId}-9999999`) {
    //             obj.tags.push(e.target.value.trim());
    //           }
    //           e.target.value = '';
    //         }
    //       }}
    //       tags={
    //         has(isEditingTask.current, 'id') && isEditingTask.current.id === obj.id
    //           ? isEditingTask.current.tags
    //           : obj.tags
    //       }
    //       objId={() => (obj.key === `${obj.parentId}-9999999` && has(obj, 'description') ? `${obj.parentId}-9999999` : obj.key)}
    //       closable={
    //         (obj.key === `${obj.parentId}-9999999` && has(obj, 'description')) ||
    //         (has(isEditingTask.current, 'key') && isEditingTask.current.key === obj.key)
    //       }
    //       onClose={(value) => {
    //         if (has(isEditingTask.current, 'tags') && isEditingTask.current.id === obj.id) {
    //           remove(isEditingTask.current.tags, (tag) => tag === value);
    //         } else {
    //           remove(obj.tags, (tag) => tag === value);
    //         }
    //       }}
    //     />
    //   ),
    // },
    // Priority
    {
      title: 'Priority',
      dataIndex: 'priority',
      // sorter: (a, b) => (a.priority < b.priority ? -1 : a.priority > b.priority ? 1 : 0),
      // sortDirections: ['descend', 'ascend'],
      width: '15%',
      render: (key, obj) => (
        <span>
          {/* Checklist progress */}
          {obj.type == 'parent' && (
            <span className="progress-desktop">
              <ProgressBar percentage={obj.percentage} />
            </span>
          )}
          {/* Priority */}
          {obj.type == 'child' && obj.key === `${obj.parentId}-${obj.id}` && obj.key !== isEditingTask.current.key && (
            <span
              className={`priorities ${
                obj.priority == 'High' ? 'high' : obj.priority == 'Medium' ? 'med' : obj.priority == 'Low' ? 'low' : ''
              }`}
            >
              {obj.priority}
            </span>
          )}
          {/* Priority add mode */}
          {obj.type == 'child' && obj.key === `${obj.parentId}-9999999` && has(obj, 'description') && (
            <Dropdown overlay={priorityOptions(obj)} placement="bottomCenter" trigger={['click']}>
              {obj.priority !== '' ? (
                <span className={`priorities ${obj.priority}`}>{obj.priority}</span>
              ) : (
                <span className="select-prio-chk">Select Priority</span>
              )}
            </Dropdown>
          )}

          {/* Priority edit mode */}
          {obj.type == 'child' && has(isEditingTask.current, 'key') && obj.key === isEditingTask.current.key && (
            <span id="clickbox">
              <select
                value={has(isEditingTask.current, 'priority') ? isEditingTask.current.priority : obj.priority}
                onChange={(e) => {
                  e.preventDefault();
                  changePriority(e, obj);
                }}
                className="checklist-prio1"
                id="rgb"
              >
                <option value="select">Select</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </select>
            </span>
          )}
        </span>
      ),
    },
    // controls
    {
      title: '',
      dataIndex: 'controls',
      width: '5%',
      render: (key, obj) => (
        <span>
          {obj.type === 'child' && obj.key === `${obj.parentId}-9999999` && (
            <RowOkCancel
              className="float-right"
              onClickOk={() => {
                addTask(obj);
              }}
              onClickCancel={() => {
                cancelNewTask(obj);
              }}
            />
          )}

          {obj.type == 'child' && has(isEditingTask.current, 'key') && obj.key === isEditingTask.current.key && (
            <RowOkCancel
              className="float-right"
              onClickOk={() => {
                updateTask(obj);
              }}
              onClickCancel={() => {
                resetChecklistWatchers(false);
              }}
            />
          )}

          {obj.type === 'parent' && obj.key === `${obj.tempId}` && (
            <RowOkCancel
              className="float-right"
              onClickOk={() => {
                addChecklist(obj);
              }}
              onClickCancel={() => {
                cancelNewChecklist(obj);
              }}
            />
          )}

          {obj.type == 'parent' &&
            has(isEditingChecklist.current, 'key') &&
            obj.key === isEditingChecklist.current.key && (
              <RowOkCancel
                className="float-right"
                onClickOk={() => {
                  updateChecklist(obj);
                }}
                onClickCancel={() => {
                  resetChecklistWatchers(false);
                }}
              />
            )}
          {obj.type == 'parent' &&
            obj.key !== isEditingChecklist.current.key &&
            obj.key !== `${obj.tempId}` &&
            !Object.keys(isEditingChecklist.current).length &&
            !Object.keys(isEditingTask.current).length && (
              <Dropdown overlay={checkListActions(obj)}>
                <div
                  className="row-toggle-switch"
                  onMouseOver={() => {
                    resetChecklistWatchers(false);
                  }}
                >
                  <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                </div>
              </Dropdown>
            )}

          {obj.type == 'child' &&
            obj.key !== isEditingTask.current.key &&
            obj.key !== `${obj.parentId}-9999999` &&
            !Object.keys(isEditingChecklist.current).length &&
            !Object.keys(isEditingTask.current).length && (
              <Dropdown overlay={taskActions(obj)}>
                <div
                  className="row-toggle-switch"
                  onMouseOver={() => {
                    resetChecklistWatchers(false);
                  }}
                >
                  <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                </div>
              </Dropdown>
            )}
        </span>
      ),
    },
  ];

  const listener = () => {
    let originalChecklists =
      localStorage.getItem('originalChecklists') != undefined
        ? JSON.parse(localStorage.getItem('originalChecklists'))
        : [];
    let checklistOrder =
      localStorage.getItem('checklistOrder') != undefined ? JSON.parse(localStorage.getItem('checklistOrder')) : null;
    let checklistColumnKeyOrder =
      localStorage.getItem('checklistColKeyOrder') != undefined
        ? JSON.parse(localStorage.getItem('checklistColKeyOrder'))
        : null;
    let originalDataCount =
      localStorage.getItem('checklistOriginalData') != undefined ? localStorage.getItem('checklistOriginalData') : null;

    if (checklistOrder && originalDataCount && originalChecklists.length) {
      let openAddsRemoved = checklistOrder;
      // if current number of categories in checklistOrder != originalDataCount, change some variables
      // else return
      if (parseInt(checklistOrder.length) !== parseInt(originalDataCount)) {
        openAddsRemoved = removeOpenAdds(checklistOrder); // removes open add folder rows and resort
        // if checklistOrder retains checklist categories, update to localStorage
        // else remove 'checklistOrder' and 'checklistOriginalData' from localStorage
        if (openAddsRemoved.length) {
          localStorage.setItem('checklistOrder', JSON.stringify(openAddsRemoved));
        } else {
          localStorage.removeItem('checklistOrder');
          localStorage.removeItem('checklistOriginalData');
        }
      } else {
        // if cached table data originalChecklists is not empty array,
        // apply latest column sorting
        // or latest manual sorting (without open add category rows)
        // or no sorting applied if above are not applicable
        // to dataSource and socketData
        if (checklistColumnKeyOrder) {
          const { order, columnKey } = checklistColumnKeyOrder;
          const restoreData = applyColumnOrder(columnKey, order, originalChecklists);
          setPristineData(originalChecklists);
          setDataSource(restoreData);
          setSocketData(originalChecklists);
        } else {
          const restoreData = annotateTableOrder(originalChecklists, checklistOrder);
          setPristineData(originalChecklists);
          setDataSource(restoreData);
          setSocketData(originalChecklists);
        }
        setIsLoading(false);
      }
    } else {
      getChecklists();
    }
  };

  // const removeOrder = () => {
  //   const backUp = cloneDeep(pristineData);
  //   setDataSource(backUp);
  // };

  useEffect(() => {
    document.addEventListener('beforeunload', listener());
    return () => {
      document.removeEventListener('beforeunload', listener());
    };
  }, []);

  return (
    <div id="checklists" className="common-page-container">
      <NewSubHeader title="Checklists" onChange={filterChecklist} />
      <div className="Line"></div>
      <div className="common-page-content">
        <div className="common-controls-container controls-thin">
          <PlusButton
            className="flex-control-left flex-control-rightspace"
            alt="Add new checklist"
            onClick={() => {
              createNewChecklist();
            }}
          >
            Add new checklist
          </PlusButton>

          <PlusButton className="flex-control-left" alt="Close all folders" onClick={collapseAll}>
            Collapse all
          </PlusButton>

          <CidekicDropdown
            overlay={chooseTaskStatus}
            className="flex-control-right select-wide"
            onMouseOver={() => {
              resetChecklistWatchers(false);
            }}
          >
            {filteredchooseTaskStatus}
          </CidekicDropdown>
        </div>
        {isLoading && (
          <div style={{ textAlign: 'center' }}>
            <Spin tip="Loading checklists..."></Spin>
          </div>
        )}
        {!isLoading && (
          <div className="">
            <ReactDragListView {...dragProps}>
              <Table
                useFixedHeader={true}
                columns={columns}
                dataSource={dataSource}
                className={'show-custom-empty ant-table-fixedheader'}
                rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
                onRow={(record, index) => ({
                  onMouseOver: (event) => {
                    hoverBeforeDrag(event, record);
                  },
                  onDrop: (event) => {
                    setDestination(event, record);
                  },
                  onMouseLeave: (event) => {
                    cancelHover(event, record);
                  },
                })}
                expandIconColumnIndex={1}
                expandIcon={(props) => {
                  if (props.record.type == 'parent') {
                    return (
                      <ExpandCollapseIcon
                        expanded={props.expanded}
                        onClick={(e) => {
                          props.onExpand(props.record, e);
                        }}
                      />
                    );
                  }
                }}
                pagination={false}
                // scroll={{ y: 'calc(100vh - var(--table-scroll-offset))' }}
                locale={{ emptyText: <Empty image={empty} description="No checklists" /> }}
                onExpand={(expanded, record) => handleRowExpand(record)}
                expandedRowKeys={expandedRows}
                onChange={onTableChange}
                rowKey={'key'}
              />
            </ReactDragListView>
          </div>
        )}
      </div>

      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={detailDrawerVisible}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <CheckListDetailPopup data={data[0]?.children[0]} />
      </Drawer>

      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={menuDrawerVisible}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentList
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks={docLinks}
          links={links}
          isMobile={false}
          documentsIn="tasks"
        />
      </Drawer>
    </div>
  );
};

export default Checklist;
