import React from 'react';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { menuItems } from '../../data/menu';
import './index.css';

const SideMenuMobile = (props) => {
  // const [showProfile, setShowProfile] = useState(false);
  // const [showAlert, setShowAlert] = useState(false);
  // const [showMsg, setShowMsg] = useState(false);
  // const [userInfo, setUserInfo] = useState({});
  // const [restaurants, setRestaurants] = useState([]);
  // const [selectedRestaurant, setSelected] = useState({});
  // const [selectedRestaurantId, setSelectedId] = useState('');
  // const [tapped, setTapped] = useState(false);

  // useEffect(() => {
  // }, [window.location.href]);

  return (
    <div className="side-menu-container-mobile">
      <div>
        <ul className="order-menu1">
          {menuItems.map((menus, i) => {
            return (
              <li key={menus.name}>
                <div className="parent-menu-mobile">
                  <div>{menus.title}</div>
                </div>
                {i !== -1 ? <div className="menu-line"></div> : null}
                <div>
                  <ul>
                    {menus.children.map((sub, ii) => {
                      return (
                        <li key={sub.name}>
                          <div className="sidemenu-menuitem-mobile">
                            <Link to={'/' + sub.name} onClick={() => props.closeMenu()}>
                              <div className="flex">
                                <div>{sub.icon ? sub.icon : null}</div>
                                <div>{sub.title ? sub.title : null}</div>
                              </div>
                            </Link>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideMenuMobile;
