import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import RecipeCategoryMobile from '../../components/RecipeCourse/RecipeCategoryMobile';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';

const Recipes = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [restaurant, setRestaurant] = useState('');
  const [searchTerm, setSearchTerm] = useState(null);

  const searchVal = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    setRestaurant(resto.name);
  }, []);

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Recipes"
        restaurant={restaurant}
        showSearch={showSearch}
        onChange={searchVal}
        clickSearch={() => setShowSearch(true)}
      />
      <div className="Line"></div>
      <RecipeCategoryMobile searchTerm={searchTerm} />
    </div>
  );
};

export default Recipes;
