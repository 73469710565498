import React, { useState, useEffect } from 'react';
import './index.css';

const ErrorPage = (props) => {
  const { copy } = props;
  const [message, setMessage] = useState(copy);

  useEffect(() => {
    setMessage(copy);
  }, [copy]);

  return (
    <div className="centralize-message">
      <span className="enlarge-message">{message}</span>
    </div>
  );
};

export default ErrorPage;
