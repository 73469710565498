import React from 'react';
import './index.css';

export const InputSelectCombo2 = (props) => {
  const { formLabel, onChange, keyword, onFocus, onBlur, showValue, setInputVal, setShowVal, inputClassMobile } = props;

  return (
    <div className="col">
      <div className="prep-title">{formLabel}</div>
      <div className="flex-grid">
        <div className="smallcol">
          <input
            type="text"
            className={inputClassMobile ? inputClassMobile : 'recipe-input-smx'}
            value={showValue}
            onChange={(e) => onChange(e, keyword)}
            onFocus={() => onFocus(keyword)}
            onBlur={(e) => onBlur(e.target.value, setInputVal, setShowVal, keyword)}
          />
        </div>
        <div className="col">
          <div className="edit-prep-details-portion">Portions</div>
        </div>
      </div>
    </div>
  );
};

export default InputSelectCombo2;
