import { REACT_APP_API_BASE_URL } from '../config';

export const request = (action, endpointUrl, code, payload, protoDecoder) => {
  return makeRequest(REACT_APP_API_BASE_URL, action, endpointUrl, code, payload, protoDecoder);
};

const makeRequest = (baseUrl, action, endpointUrl, code, payload, protoDecoder) => {
  return new Promise((resolve, reject) => {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open(action, baseUrl + endpointUrl);
    xmlhttp.withCredentials = true;
    if (action.toLowerCase() !== 'delete') {
      xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    }
    // xmlhttp.setRequestHeader("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTMsImVtYWlsIjoibXppcGFnYW5nQGJvbGR0eXBlLmNvbSIsInJvbGUiOiJBZG1pbiIsInBlcm1pc3Npb25zIjpudWxsLCJpYXQiOjE1ODU4MTUxMzB9.xQSoWH2eZ4R8X2z-_fGZ_AtaWy0g6HRQs9k9D5qp5uI");
    if (protoDecoder) {
      xmlhttp.responseType = 'arraybuffer';
    }
    xmlhttp.onreadystatechange = (e) => {
      if (xmlhttp.readyState !== 4) {
        return;
      }
      if (xmlhttp.status === code) {
        if (protoDecoder) {
          const buffer = new Uint8Array(xmlhttp.response);
          resolve(protoDecoder(buffer));
        } else {
          try {
            const jsonResponse = JSON.parse(xmlhttp.response);
            resolve(jsonResponse);
          } catch (e) {
            resolve(xmlhttp.response);
          }
        }
      } else {
        if (xmlhttp.status === 401) {
          localStorage.removeItem('operator');
          if (window.location.pathname !== '/' && !xmlhttp.responseURL.includes('login')) {
            window.location = '/public/login';
            return openNotification('error', xmlhttp.response || xmlhttp.responseText || 'Unauthorized request');
          }
          return reject(xmlhttp.response || xmlhttp.responseText || 'Unauthorized request');
        }
        openNotification('error', xmlhttp.response || xmlhttp.responseText || 'Server Error');
        reject(xmlhttp.response || xmlhttp.responseText || 'Server Error');
      }
    };
    if (payload) {
      xmlhttp.send(JSON.stringify(payload));
    } else {
      xmlhttp.send();
    }
  });
};

const openNotification = (notification) => {
  console.log(notification);
};
