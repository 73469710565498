import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Select, Tag, Progress } from 'antd';
import searchglass from '../../img/search-icon.svg';
import spinner from '../../img/spinner.gif';

const { Option, OptGroup } = Select;

//////////////////
// Simple Icons //
//////////////////

const DownSelectArrow = () => {
  return (
    <svg width="13" height="8" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m6.571 7.567-5.996-6L1.717.425l4.854 4.857L11.425.425l1.142 1.142z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const SearchSpyglass = () => {
  return <img src={searchglass} aria-hidden="true" alt="search" />;
};

export const ChevronLeft = () => {
  return (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <path d="m0 5.996 6 5.996 1.143-1.142-4.857-4.854 4.857-4.854L6 0z" fill="currentColor" fillRule="evenodd" />
    </svg>
  );
};

export const PaperclipIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 10 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 18C1.682 18 0 16.58 0 14.836V4.219C0 1.893 2.243 0 5 0s5 1.893 5 4.219v10.617H8.75V4.219c0-1.745-1.682-3.164-3.75-3.164s-3.75 1.42-3.75 3.164v10.617c0 1.163 1.122 2.11 2.5 2.11s2.5-.947 2.5-2.11V6.328c0-.581-.56-1.055-1.25-1.055s-1.25.474-1.25 1.055v8.508H2.5V6.328c0-1.163 1.122-2.11 2.5-2.11s2.5.947 2.5 2.11v8.508C7.5 16.58 5.818 18 3.75 18z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const FolderIcon = () => {
  return (
    <svg width="100%" height="100%" id="Layer_1" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M463.518,94.909H193.885v-10.37c0-22.908-18.574-41.482-41.482-41.482H48.699  c-22.908,0-41.482,18.574-41.482,41.482v51.852v72.593v217.78c0,22.907,18.574,41.482,41.482,41.482h414.819  c22.908,0,41.482-18.575,41.482-41.482V136.391C505,113.483,486.426,94.909,463.518,94.909z M27.958,84.539  c0-11.435,9.308-20.741,20.741-20.741h103.705c11.433,0,20.741,9.306,20.741,20.741v10.37v20.741h20.741h269.633  c11.433,0,20.741,9.306,20.741,20.741v20.741H27.958v-20.741V84.539z M463.518,447.506H48.699c-11.433,0-20.741-9.307-20.741-20.741  v-217.78v-31.111h456.301v248.892C484.259,438.198,474.952,447.506,463.518,447.506z"
        fill="currentColor"
      />
    </svg>
  );
};

export const CommentIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.538 0H2.462C1.104 0 0 1.148 0 2.56v12.8c0 .248.138.475.354.58a.601.601 0 0 0 .655-.088L4.53 12.8h9.008c1.358 0 2.462-1.148 2.462-2.56V2.56C16 1.148 14.896 0 13.538 0zm1.231 10.24c0 .705-.551 1.28-1.23 1.28H4.307a.6.6 0 0 0-.394.148L1.23 13.994V2.56c0-.705.551-1.28 1.23-1.28h11.077c.68 0 1.231.575 1.231 1.28v7.68z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

export const CheckmarkIcon = () => {
  return (
    <svg version="1.1" width="16" height="16" viewBox="0 0 17.837 17.837" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fill="currentColor"
          d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
        />
      </g>
    </svg>
  );
};

export const UserIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="4 3 21 22">
      <g fill="none" fillRule="evenodd">
        <g>
          <path d="M0.973 0H28.006999999999998V28H0.973z" />
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="M21.561 6.929C19.672 5.04 17.161 4 14.49 4c-2.671 0-5.182 1.04-7.071 2.929C5.53 8.818 4.49 11.329 4.49 14c0 2.671 1.04 5.182 2.929 7.071C9.308 22.96 11.819 24 14.49 24c2.671 0 5.182-1.04 7.071-2.929 1.889-1.889 2.929-4.4 2.929-7.071 0-2.671-1.04-5.182-2.929-7.071zM9.503 21.28c.419-2.408 2.505-4.185 4.987-4.185s4.569 1.777 4.987 4.185c-1.42.975-3.138 1.547-4.987 1.547-1.849 0-3.567-.572-4.987-1.547zm1.807-8.536c0-1.754 1.427-3.18 3.18-3.18 1.753 0 3.18 1.427 3.18 3.18 0 1.753-1.427 3.18-3.18 3.18-1.753 0-3.18-1.427-3.18-3.18zm9.182 7.723c-.316-1.121-.942-2.138-1.82-2.933-.539-.487-1.152-.872-1.81-1.143 1.19-.777 1.98-2.122 1.98-3.647 0-2.4-1.953-4.352-4.352-4.352-2.4 0-4.351 1.952-4.351 4.352 0 1.525.789 2.87 1.98 3.647-.66.271-1.272.655-1.81 1.143-.879.795-1.505 1.812-1.82 2.933C6.75 18.854 5.661 16.552 5.661 14c0-4.868 3.96-8.828 8.828-8.828 4.868 0 8.828 3.96 8.828 8.828 0 2.552-1.089 4.855-2.826 6.468z"
          />
        </g>
      </g>
    </svg>
  );
};

export const ChefIcon = (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path id="a" d="M.005 0h17.923v17.97H.005z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M17.928 6.548c0-2.297-1.876-4.166-4.182-4.166-.1 0-.201.004-.303.011A5.393 5.393 0 0 0 8.964 0a5.393 5.393 0 0 0-4.479 2.393 4.174 4.174 0 0 0-.303-.011C1.876 2.382 0 4.25 0 6.548c0 2.095 1.562 3.834 3.587 4.123v5.757c0 .85.694 1.542 1.548 1.542h7.658c.854 0 1.548-.692 1.548-1.542v-5.757c2.025-.29 3.587-2.028 3.587-4.123zm-5.135 10.236H5.135a.361.361 0 0 1-.357-.356v-5.757a4.128 4.128 0 0 0 1.636-.602 5.371 5.371 0 0 0 5.1 0c.5.317 1.053.52 1.636.602v5.757a.361.361 0 0 1-.357.356zm.953-7.256c-.4 0-.788-.078-1.149-.228a5.331 5.331 0 0 0 1.548-2.51.596.596 0 0 0-1.148-.317 4.194 4.194 0 0 1-4.033 3.055 4.194 4.194 0 0 1-4.033-3.055.596.596 0 0 0-1.148.317A5.33 5.33 0 0 0 5.33 9.3a2.99 2.99 0 0 1-4.14-2.752 2.99 2.99 0 0 1 3.502-2.936.597.597 0 0 0 .62-.294 4.198 4.198 0 0 1 3.652-2.132c1.511 0 2.91.817 3.652 2.132a.597.597 0 0 0 .62.294 2.989 2.989 0 0 1 3.501 2.936 2.989 2.989 0 0 1-2.991 2.98z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const CookbookIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <defs>
        <path id="ok41nkuq1a" d="M0.002 0L13.847 0 13.847 14 0.002 14z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill="currentColor"
              d="M12.89 12.011c0 .644-.612 1.168-1.363 1.168H2.563V.82h8.964c.751 0 1.362.524 1.362 1.168V12.01zM1.603 13.18H.958V.82h.647V13.18zM11.527 0H.49C.219 0 0 .188 0 .42v13.16c0 .232.22.42.489.42h11.038c1.28 0 2.32-.892 2.32-1.989V1.99C13.847.892 12.807 0 11.527 0z"
              mask="url(#vg6ujetbtb)"
            />
          </g>
          <path
            fill="currentColor"
            d="M9.895 6.982H5.687c-.353 0-.64-.246-.64-.549 0-.302.287-.549.64-.549.162 0 .312.057.436.147.31.224.8.124.81-.31 0-.437.414-.791.923-.791.487 0 .89.325.92.74l.001.367c0 .006 0-.007 0 0 0 .207.195.394.436.394.133 0 .272-.051.37-.132.085-.068.196-.105.312-.105.254 0 .46.177.46.394 0 .218-.206.394-.46.394zm-3.729 1.74h3.25v-.918h-3.25v.917zm3.73-3.35c-.068 0-.135.005-.201.013-.181-.733-.94-1.276-1.84-1.276-.786 0-1.46.416-1.74 1.004-.138-.033-.282-.05-.428-.05-.882 0-1.6.615-1.6 1.37 0 .613.472 1.133 1.12 1.308v1.39c0 .228.215.412.48.412h4.208c.265 0 .48-.184.48-.411v-1.4c.546-.168.939-.618.939-1.144 0-.67-.637-1.215-1.419-1.215z"
          />
        </g>
      </g>
    </svg>
  );
};

export const PlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            stroke="currentColor"
            strokeLinecap="square"
            strokeWidth="2"
            d="M22.017 15.5L22.017 28.5M28.5 22.014L15.5 22.014"
          />
        </g>
      </g>
    </svg>
  );
};

export const TrimmedPlusIcon = () => {
  return (
    <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg" transform="rotate(45)">
      <g stroke="#8F8F8F" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round">
        <path d="M6.513 1.393v10.214M11.607 6.511H1.393" />
      </g>
    </svg>
  );
};

export const MoreIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4" viewBox="0 0 18 4">
      <g fill="none" fillRule="evenodd">
        <g fill="currentColor">
          <circle cx="8.726" cy="2" r="2" />
          <circle cx="2" cy="2" r="2" />
          <circle cx="15.452" cy="2" r="2" />
        </g>
      </g>
    </svg>
  );
};

export const PrepTimeIcon = () => {
  return (
    <svg width="24" height="20" viewBox="-2 0 24 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M10 1.46C5.291 1.46 1.46 5.29 1.46 10S5.29 18.54 10 18.54s8.54-3.831 8.54-8.54S14.71 1.46 10 1.46zM10 20C4.486 20 0 15.514 0 10S4.486 0 10 0s10 4.486 10 10-4.486 10-10 10z"
          fill="currentColor"
          mask="url(#b)"
        />
        <path
          d="M13.406 11.46H9.513a.73.73 0 0 1-.73-.73V5.62a.73.73 0 1 1 1.46 0V10h3.163a.73.73 0 1 1 0 1.46"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const CookTimeIcon = () => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M11.999 3.483c4.324 0 7.357.875 8.29 1.68H3.709c.932-.825 3.966-1.7 8.29-1.7v.02zm0-2.484a.74.74 0 0 1 .746.735.74.74 0 0 1-.746.736.74.74 0 0 1-.746-.736.74.74 0 0 1 .746-.735zM2.898 6.137H21.1a.5.5 0 0 0 .504-.497c0-1.854-3.835-2.917-8.063-3.126a1.69 1.69 0 0 0 .196-.775c0-.96-.79-1.739-1.763-1.739-.975 0-1.764.779-1.764 1.74.002.27.07.535.196.774-4.213.209-8.063 1.272-8.063 3.126a.5.5 0 0 0 .555.497z"
          fill="currentColor"
        />
        <g transform="translate(0 6.745)">
          <path
            d="M4.661 8.122a.5.5 0 0 0 .504-.497V2.283a.5.5 0 0 0-.504-.497.5.5 0 0 0-.504.497v5.342a.5.5 0 0 0 .504.497"
            fill="currentColor"
            mask="url(#b)"
          />
          <path
            d="M20.596 10.248c0 .91-.353 1.988-2.016 1.988H5.478c-.781 0-2.092-.258-2.092-1.988V.996h17.21v9.252zm2.898-8.328h-1.89V.5A.5.5 0 0 0 21.1.001H2.898A.5.5 0 0 0 2.394.5V1.92H.504A.5.5 0 0 0 0 2.417a.5.5 0 0 0 .504.497h1.895v7.334c0 2.202 1.597 2.982 3.099 2.982H18.55c2.232 0 3.024-1.54 3.024-2.982V2.914h1.894a.5.5 0 0 0 .504-.497.5.5 0 0 0-.504-.497h.026z"
            fill="currentColor"
            mask="url(#b)"
          />
        </g>
      </g>
    </svg>
  );
};

export const YieldIcon = () => {
  return (
    <svg width="24" height="20" viewBox="2 0 13 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fillRule="evenodd">
        <path d="M15.361 1.192c-1.202 1.318-3.055 2.103-5.075 2.103H6.85c-2.02 0-3.873-.785-5.075-2.103H15.36zM7.491 6h2.154V4.487H7.492V6zm7.05 1.978 1.204 9.827a.884.884 0 0 1-.218.702.884.884 0 0 1-.67.301H2.28a.884.884 0 0 1-.67-.301.884.884 0 0 1-.219-.702l1.203-9.827a.896.896 0 0 1 .889-.786h10.17c.451 0 .833.338.888.786zm-.309-4.449c1.202-.633 2.178-1.543 2.822-2.63A.596.596 0 0 0 16.54 0H.596a.596.596 0 0 0-.513.9c.644 1.086 1.62 1.996 2.822 2.629a8.438 8.438 0 0 0 3.395.94V6H3.484a2.089 2.089 0 0 0-2.071 1.833L.21 17.66A2.088 2.088 0 0 0 2.28 20h12.577a2.088 2.088 0 0 0 2.07-2.34l-1.202-9.827A2.089 2.089 0 0 0 13.653 6h-2.816V4.47a8.439 8.439 0 0 0 3.395-.941z" />
        <path d="M8.569 16.691A3.695 3.695 0 0 1 4.878 13a3.695 3.695 0 0 1 3.69-3.691A3.695 3.695 0 0 1 12.26 13a3.695 3.695 0 0 1-3.691 3.691zM3.686 13a4.888 4.888 0 0 0 4.883 4.883A4.888 4.888 0 0 0 13.45 13 4.888 4.888 0 0 0 8.57 8.117 4.888 4.888 0 0 0 3.686 13z" />
        <path d="m8.147 12.579-1.434 1.434a.596.596 0 1 0 .842.843L8.99 13.42a.596.596 0 1 0-.843-.842" />
      </g>
    </svg>
  );
};

export const PortionIcon = () => {
  return (
    <svg width="24" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M14.418 16.406H5.582a8.822 8.822 0 0 1-4.39-7.039h17.617a8.822 8.822 0 0 1-4.391 7.04zm-.277 1.836a.587.587 0 0 1-.586.586h-7.11a.587.587 0 0 1-.586-.586v-.664h8.282v.664zM3.105 7.344a.586.586 0 0 0 .197-.732 1.76 1.76 0 0 1 .062-1.632 1.76 1.76 0 0 1 1.382-.87.586.586 0 0 0 .536-.536 1.76 1.76 0 0 1 .87-1.382 1.76 1.76 0 0 1 1.632-.062c.26.122.57.04.732-.197.316-.455.928-.761 1.523-.761a1.76 1.76 0 0 1 1.445.761.586.586 0 0 0 .732.197 1.76 1.76 0 0 1 1.632.062c.497.288.822.804.87 1.382.023.286.25.513.536.536a1.76 1.76 0 0 1 1.382.87 1.76 1.76 0 0 1 .062 1.632.586.586 0 0 0 .197.733c.309.213.536.516.659.858H2.446c.123-.342.35-.645.66-.859zM20 8.79a.586.586 0 0 0-.586-.586h-.646a2.934 2.934 0 0 0-.844-1.527 2.927 2.927 0 0 0-.273-2.281 2.927 2.927 0 0 0-1.84-1.378 2.928 2.928 0 0 0-1.377-1.84 2.927 2.927 0 0 0-2.282-.272A2.927 2.927 0 0 0 10.039 0a3.14 3.14 0 0 0-2.186.906 2.928 2.928 0 0 0-2.287.271 2.928 2.928 0 0 0-1.377 1.84 2.927 2.927 0 0 0-1.84 1.377 2.927 2.927 0 0 0-.273 2.282 2.934 2.934 0 0 0-.844 1.527H.586A.586.586 0 0 0 0 8.79c0 3.44 1.783 6.63 4.688 8.448v1.005c0 .97.788 1.758 1.757 1.758h7.11a1.76 1.76 0 0 0 1.758-1.758v-1.005A9.977 9.977 0 0 0 20 8.789z"
          fill="currentColor"
          mask="url(#b)"
        />
      </g>
    </svg>
  );
};

export const ServingIcon = () => {
  return (
    <svg
      width="24"
      height="20"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="20 20 350 330"
    >
      <g>
        <g>
          <path
            d="M195.537,53.35c-75.679,0-137.014,61.347-137.014,137.013s61.335,137.013,137.014,137.013
            c75.654,0,137.014-61.335,137.014-137.013C332.539,114.696,271.191,53.35,195.537,53.35z M194.026,278.147
            c-47.509,0-86.007-38.493-86.007-86.001c0-47.509,38.498-86.007,86.007-86.007c47.491,0,86.007,38.498,86.007,86.007
            C280.033,239.654,241.517,278.147,194.026,278.147z"
            fill="currentColor"
          />
          <path
            d="M43.913,77.69h-5.745l1.714,60.202h-6.902V77.69h-5.054v60.574h-5.71V77.69l-5.583,0.012v60.574h-6.78l1.94-60.586H6.53
            l-6.361,51.244c0,0-0.168,1.929-0.168,2.667c0,7.889,3.677,14.954,9.399,19.543c18.869,21.309,5.984,43.059,5.996,48.229
            c0.011,5.705,0.011,93.717,0.011,93.717c0.012,0.069-0.023,0.115-0.011,0.197c0.011,5.391,4.368,9.748,9.754,9.748
            c5.391,0,9.771-4.381,9.771-9.748c-0.012-0.082-0.023-0.128-0.035-0.197h0.023c0,0,0-86.908,0.012-93.52
            c0-6.018-10.887-31.004,7.302-49.6c0.012-0.023,0.052-0.081,0.087-0.11c4.438-4.211,7.372-10.015,7.802-16.533
            c0.035-0.453,0.023-1.621,0.023-1.621L43.913,77.69z"
            fill="currentColor"
          />
          <path
            d="M380.709,223.006c0.011-5.81-0.175-145.304-0.175-145.304l-3.184-0.012c0,0-25.956,14.646-25.956,123.333
            c19.938,5.472,11.549,27.269,10.039,30.045c0,5.531,0.43,62.369,0.43,62.369l0.069-0.035c-0.023,0.082-0.046,0.129-0.046,0.198
            c-0.023,5.217,4.218,9.458,9.411,9.411c5.193,0.023,9.434-4.218,9.411-9.411c0.022-0.069,0.022-0.116,0-0.163l0.011-0.047
            C380.72,293.391,380.72,229.385,380.709,223.006z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export const MasterRecipeIcon = () => {
  return (
    <span></span>
    // <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    //   <g fill="none" fillRule="evenodd">
    //     <path
    //       fill="#2987FC"
    //       d="M22.935 12 24 10.1 22.4 8.62l.425-2.137-1.979-.911-.255-2.164-2.164-.255-.911-1.98-2.137.426L13.901 0 12 1.065 10.1 0 8.62 1.6l-2.137-.425-.911 1.979-2.164.255-.255 2.164-1.98.911.427 2.137L0 10.1 1.065 12 0 13.9l1.6 1.479-.425 2.137 1.979.911.255 2.164 2.164.255.911 1.98 2.137-.427L10.099 24 12 22.935 13.9 24l1.479-1.6 2.137.425.911-1.979 2.164-.255.255-2.164 1.98-.911-.427-2.137 1.6-1.478z"
    //     />
    //     <path
    //       d="M13.71 10.589a.356.356 0 0 1-.319-.232l-1.317-4.05c-.04-.128-.108-.128-.15 0l-1.315 4.05a.357.357 0 0 1-.32.232H6.032c-.135 0-.156.064-.047.143l3.445 2.503c.109.079.164.246.122.375l-1.317 4.05c-.041.127.014.167.123.087l3.444-2.502c.11-.08.286-.08.395 0l3.446 2.504c.108.078.162.039.121-.09l-1.316-4.05a.355.355 0 0 1 .122-.374l3.445-2.503c.109-.079.088-.143-.046-.143H13.71z"
    //       fill="#FFF"
    //     />
    //   </g>
    // </svg>
  );
};

// export const ServingIcon = () => {
//   return (
//     <svg  width="20" height="20" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 297 297">
//       <g>
//         <path d="M175.394,26.894c-46.37,0-88.657,26.461-109.074,67.811C28.188,104.819,0,139.637,0,180.92
//           c0,49.178,39.995,89.187,89.154,89.187c14.211,0,27.653-3.35,39.589-9.292c14.788,6.161,30.455,9.292,46.65,9.292
//           C242.448,270.106,297,215.555,297,148.5C297,81.446,242.448,26.894,175.394,26.894z M19.38,180.92
//           c0-38.491,31.3-69.807,69.774-69.807c38.474,0,69.774,31.315,69.774,69.807s-31.301,69.807-69.774,69.807
//           C50.68,250.727,19.38,219.411,19.38,180.92z M132.776,103.164c11.453-10.743,26.698-16.921,42.617-16.921
//           c34.329,0,62.257,27.929,62.257,62.257s-27.928,62.257-62.257,62.257c-0.732,0-1.467-0.037-2.201-0.063
//           c3.309-9.316,5.116-19.337,5.116-29.773C178.309,147.585,159.928,118.468,132.776,103.164z M175.394,250.727
//           c-9.121,0-18.038-1.183-26.667-3.514c5.838-5.255,10.981-11.269,15.271-17.885c3.789,0.535,7.602,0.808,11.396,0.808
//           c45.015,0,81.637-36.621,81.637-81.636s-36.622-81.636-81.637-81.636c-23.736,0-46.332,10.458-61.764,28.287
//           c-7.407-2.117-15.206-3.294-23.259-3.402c18.765-28.069,50.547-45.476,85.023-45.476c56.368,0,102.226,45.858,102.226,102.227
//           S231.762,250.727,175.394,250.727z" fill="currentColor"/>
//         <path d="M89.154,122.662c-32.086,0-58.189,26.134-58.189,58.258s26.104,58.258,58.189,58.258s58.189-26.134,58.189-58.258
//           S121.24,122.662,89.154,122.662z M89.154,219.798c-21.4,0-38.81-17.44-38.81-38.878s17.41-38.878,38.81-38.878
//           c21.399,0,38.81,17.44,38.81,38.878S110.554,219.798,89.154,219.798z" fill="currentColor"/>
//       </g>
//     </svg>
//   );
// };

// export const ServingIcon = () => {
//   return (
//     <svg width="20" height="20" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
//     	 viewBox="0 0 199.603 199.603" >
//     <path d="M187.251,173.172c-4.141,0-7.509-3.369-7.509-7.509V32.074c0-1.952,1.569-5.644,7.509-5.644
//     	c9.424,0,12.352,33.462,12.352,45.651c0,18.908-4.182,36.269-4.843,38.893v54.688C194.76,169.803,191.392,173.172,187.251,173.172z
//     	 M184.742,113.161v52.502c0,1.383,1.125,2.509,2.509,2.509s2.509-1.125,2.509-2.509v-52.502H184.742z M184.742,108.161h5.548
//     	c1.187-5.159,4.313-20.256,4.313-36.079c0-20.876-4.906-38.858-7.546-40.649c-1.542,0.033-2.218,0.461-2.314,0.771V108.161z
//     	 M16.632,173.172c-1.87,0-3.67-0.734-4.938-2.014c-1.165-1.177-1.799-2.711-1.783-4.318l0.806-81.785
//     	C4.583,82.688,0.142,76.768,0.001,69.852C-0.001,69.79,0,69.727,0.003,69.664L1.718,31.96c0.063-1.378,1.259-2.421,2.61-2.384
//     	c1.38,0.063,2.447,1.232,2.384,2.611l-1.596,35.09h4.361l0.802-35.26c0.031-1.381,1.208-2.48,2.556-2.443
//     	c1.381,0.032,2.474,1.176,2.442,2.556L14.48,67.278h4.306l-0.799-35.147c-0.031-1.38,1.062-2.524,2.442-2.556
//     	c1.358-0.042,2.525,1.062,2.556,2.443l0.802,35.26h4.361l-1.595-35.09c-0.063-1.379,1.004-2.548,2.384-2.611
//     	c1.367-0.052,2.549,1.005,2.61,2.384l1.714,37.703c0.003,0.063,0.004,0.126,0.002,0.188c-0.141,6.915-4.582,12.836-10.716,15.203
//     	l0.807,81.785c0.016,1.607-0.618,3.141-1.783,4.318C20.302,172.438,18.502,173.172,16.632,173.172z M15.706,86.156l-0.795,80.732
//     	c-0.003,0.337,0.181,0.595,0.336,0.751c0.67,0.677,2.099,0.676,2.771,0c0.155-0.157,0.339-0.415,0.336-0.751l-0.796-80.732H15.706z
//     	 M5.333,72.278c1.256,5.078,5.878,8.878,11.299,8.878c5.422,0,10.044-3.8,11.299-8.878h-6.587c0,0-0.003,0-0.005,0h-9.414
//     	c-0.001,0-0.001,0-0.002,0c0,0-0.001,0-0.002,0H5.333z M102.781,163.258c-36.692,0-66.544-29.852-66.544-66.544
//     	s29.852-66.544,66.544-66.544c36.693,0,66.545,29.852,66.545,66.544S139.475,163.258,102.781,163.258z M102.781,35.169
//     	c-33.936,0-61.544,27.609-61.544,61.544s27.608,61.544,61.544,61.544s61.545-27.609,61.545-61.544S136.717,35.169,102.781,35.169z
//     	 M102.781,145.155c-26.711,0-48.441-21.731-48.441-48.441s21.73-48.441,48.441-48.441s48.441,21.731,48.441,48.441
//     	S129.492,145.155,102.781,145.155z M102.781,53.272c-23.954,0-43.441,19.488-43.441,43.441s19.487,43.441,43.441,43.441
//     	s43.441-19.488,43.441-43.441S126.735,53.272,102.781,53.272z" fill="currentColor"/>
//     </svg>
//   );
// };

// make nu iconz her
// export const Icon = (props) => {
//   return (
//   );
// };

///////////////////
// Complex Icons //
///////////////////

// <CompletionIcon>
//   completed={ bool }
//   dimmed={ bool }
// />
export const CompletionIcon = (props) => {
  const opacity = props.dimmed ? 0.2 : 1;
  if (props.completed) {
    return (
      <svg width="20" height="20" opacity={opacity} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M10 20C4.486 20 0 15.514 0 10S4.486 0 10 0s10 4.486 10 10-4.486 10-10 10z" fill="#2ECC71" />
          <path
            d="M8.649 13.54a.808.808 0 0 1-.581-.245L5.04 10.188a.81.81 0 1 1 1.161-1.132l2.47 2.535 5.176-4.909a.81.81 0 1 1 1.116 1.177l-5.757 5.46a.81.81 0 0 1-.558.222"
            fill="#fff"
          />
        </g>
      </svg>
    );
  } else {
    return (
      <svg width="20" height="20" opacity={opacity} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path id="a" d="M.002 0H20v20H.002z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            d="M8.649 13.54a.808.808 0 0 1-.581-.245L5.04 10.188a.81.81 0 1 1 1.161-1.132l2.47 2.535 5.176-4.909a.81.81 0 1 1 1.116 1.177l-5.757 5.46a.81.81 0 0 1-.558.222"
            fill="currentColor"
          />
          <path
            d="M10 1.622C5.38 1.622 1.622 5.38 1.622 10S5.38 18.378 10 18.378 18.378 14.62 18.378 10 14.62 1.622 10 1.622zM10 20C4.486 20 0 15.514 0 10S4.486 0 10 0s10 4.486 10 10-4.486 10-10 10z"
            fill="currentColor"
          />
        </g>
      </svg>
    );
  }
};

// <HealthcheckIcon>
//   completed={ bool }
//   dimmed={ bool }
// />
export const HealthcheckIcon = (props) => {
  //const opacity = props.dimmed ? 0.2 : 1;
  if (props.completed) {
    return (
      <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path id="fpuhxviu2a" d="M0 0h11.917v11.913H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(10.083 10.083)">
            <path
              d="M5.958 11.917A5.965 5.965 0 0 1 0 5.958 5.965 5.965 0 0 1 5.958 0a5.965 5.965 0 0 1 5.959 5.958 5.965 5.965 0 0 1-5.959 5.959z"
              fill="#2ECC71"
            />
          </g>
          <path
            d="M15.125 18.333a.463.463 0 0 1-.325-.133l-1.833-1.834a.459.459 0 0 1 .648-.648l1.487 1.487 2.886-3.298a.458.458 0 1 1 .69.603l-3.208 3.667a.461.461 0 0 1-.33.156h-.015zM8.708 19.25H2.292A2.294 2.294 0 0 1 0 16.958V5.042A2.294 2.294 0 0 1 2.292 2.75h1.833a.459.459 0 0 1 0 .917H2.292c-.758 0-1.375.617-1.375 1.375v11.916c0 .758.617 1.375 1.375 1.375h6.416a.459.459 0 0 1 0 .917zM15.125 8.25a.459.459 0 0 1-.458-.458v-2.75c0-.758-.617-1.375-1.375-1.375h-1.834a.459.459 0 0 1 0-.917h1.834a2.294 2.294 0 0 1 2.291 2.292v2.75a.459.459 0 0 1-.458.458z"
            fill="currentColor"
          />
          <path
            d="M10.542 5.5h-5.5a1.377 1.377 0 0 1-1.375-1.375V2.292c0-.253.205-.459.458-.459h1.42A2.296 2.296 0 0 1 7.793 0c1.107 0 2.032.788 2.245 1.833h1.421c.253 0 .459.206.459.459v1.833c0 .758-.617 1.375-1.375 1.375zM4.583 2.75v1.375a.46.46 0 0 0 .459.458h5.5A.46.46 0 0 0 11 4.125V2.75H9.625a.459.459 0 0 1-.458-.458c0-.758-.617-1.375-1.375-1.375s-1.375.617-1.375 1.375a.459.459 0 0 1-.459.458H4.583z"
            fill="currentColor"
          />
          <path
            d="M3.04 9.454v-.88h.826v.88h.449v-2.04h-.449v.783H3.04v-.784h-.449v2.041h.449zm2.097 0L5.297 9h.763l.155.454h.466l-.764-2.04h-.46l-.772 2.04h.452zm.806-.789h-.529l.266-.749h.006l.257.75zm2.02.835c.126 0 .241-.02.346-.057a.802.802 0 0 0 .463-.429c.047-.103.077-.218.088-.346h-.434A.582.582 0 0 1 8.284 9a.415.415 0 0 1-.321.126.49.49 0 0 1-.429-.214.676.676 0 0 1-.096-.22 1.117 1.117 0 0 1 0-.51.689.689 0 0 1 .096-.224.486.486 0 0 1 .429-.216.467.467 0 0 1 .394.216c.027.043.044.09.052.141h.434a.823.823 0 0 0-.097-.314.765.765 0 0 0-.196-.23.863.863 0 0 0-.268-.142 1.028 1.028 0 0 0-.32-.048 1.03 1.03 0 0 0-.42.083.902.902 0 0 0-.317.228 1.02 1.02 0 0 0-.197.342c-.045.13-.068.272-.068.424 0 .149.023.288.068.416.046.129.112.24.197.336.086.095.192.17.316.225.125.054.266.081.422.081zm2.308 0c.126 0 .241-.02.346-.057a.802.802 0 0 0 .463-.429c.048-.103.077-.218.089-.346h-.435a.582.582 0 0 1-.141.332.415.415 0 0 1-.322.126.49.49 0 0 1-.429-.214.676.676 0 0 1-.095-.22 1.117 1.117 0 0 1 0-.51.689.689 0 0 1 .096-.224.486.486 0 0 1 .428-.216.467.467 0 0 1 .395.216c.027.043.044.09.051.141h.435a.823.823 0 0 0-.097-.314.765.765 0 0 0-.196-.23.863.863 0 0 0-.269-.142 1.028 1.028 0 0 0-.319-.048 1.03 1.03 0 0 0-.421.083.902.902 0 0 0-.316.228 1.02 1.02 0 0 0-.197.342c-.046.13-.069.272-.069.424 0 .149.023.288.069.416.046.129.111.24.197.336.086.095.191.17.316.225.125.054.265.081.421.081zm1.843-.046v-.731h.472a.85.85 0 0 0 .326-.056.564.564 0 0 0 .342-.357.776.776 0 0 0 0-.485.565.565 0 0 0-.342-.356.85.85 0 0 0-.326-.056h-.92v2.041h.448zm.349-1.08h-.349v-.612h.349a.94.94 0 0 1 .148.012c.048.007.09.022.126.044a.241.241 0 0 1 .087.093c.022.04.033.092.033.157a.327.327 0 0 1-.033.157.241.241 0 0 1-.087.093.346.346 0 0 1-.126.044.94.94 0 0 1-.148.012z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  } else {
    return (
      <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path id="qjw3cpxx2a" d="M0 0h11.917v11.913H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(10.083 10.083)">
            <path
              d="M5.958 11.917A5.965 5.965 0 0 1 0 5.958 5.965 5.965 0 0 1 5.958 0a5.965 5.965 0 0 1 5.959 5.958 5.965 5.965 0 0 1-5.959 5.959zm0-11A5.048 5.048 0 0 0 .917 5.958 5.048 5.048 0 0 0 5.958 11 5.048 5.048 0 0 0 11 5.958 5.048 5.048 0 0 0 5.958.917z"
              fill="currentColor"
            />
          </g>
          <path
            d="M15.125 18.333a.463.463 0 0 1-.325-.133l-1.833-1.834a.459.459 0 0 1 .648-.648l1.487 1.487 2.886-3.298a.458.458 0 1 1 .69.603l-3.208 3.667a.461.461 0 0 1-.33.156h-.015zM8.708 19.25H2.292A2.294 2.294 0 0 1 0 16.958V5.042A2.294 2.294 0 0 1 2.292 2.75h1.833a.459.459 0 0 1 0 .917H2.292c-.758 0-1.375.617-1.375 1.375v11.916c0 .758.617 1.375 1.375 1.375h6.416a.459.459 0 0 1 0 .917zM15.125 8.25a.459.459 0 0 1-.458-.458v-2.75c0-.758-.617-1.375-1.375-1.375h-1.834a.459.459 0 0 1 0-.917h1.834a2.294 2.294 0 0 1 2.291 2.292v2.75a.459.459 0 0 1-.458.458z"
            fill="currentColor"
          />
          <path
            d="M10.542 5.5h-5.5a1.377 1.377 0 0 1-1.375-1.375V2.292c0-.253.205-.459.458-.459h1.42A2.296 2.296 0 0 1 7.793 0c1.107 0 2.032.788 2.245 1.833h1.421c.253 0 .459.206.459.459v1.833c0 .758-.617 1.375-1.375 1.375zM4.583 2.75v1.375a.46.46 0 0 0 .459.458h5.5A.46.46 0 0 0 11 4.125V2.75H9.625a.459.459 0 0 1-.458-.458c0-.758-.617-1.375-1.375-1.375s-1.375.617-1.375 1.375a.459.459 0 0 1-.459.458H4.583z"
            fill="currentColor"
          />
          <path
            d="M3.04 9.454v-.88h.826v.88h.449v-2.04h-.449v.783H3.04v-.784h-.449v2.041h.449zm2.097 0L5.297 9h.763l.155.454h.466l-.764-2.04h-.46l-.772 2.04h.452zm.806-.789h-.529l.266-.749h.006l.257.75zm2.02.835c.126 0 .241-.02.346-.057a.802.802 0 0 0 .463-.429c.047-.103.077-.218.088-.346h-.434A.582.582 0 0 1 8.284 9a.415.415 0 0 1-.321.126.49.49 0 0 1-.429-.214.676.676 0 0 1-.096-.22 1.117 1.117 0 0 1 0-.51.689.689 0 0 1 .096-.224.486.486 0 0 1 .429-.216.467.467 0 0 1 .394.216c.027.043.044.09.052.141h.434a.823.823 0 0 0-.097-.314.765.765 0 0 0-.196-.23.863.863 0 0 0-.268-.142 1.028 1.028 0 0 0-.32-.048 1.03 1.03 0 0 0-.42.083.902.902 0 0 0-.317.228 1.02 1.02 0 0 0-.197.342c-.045.13-.068.272-.068.424 0 .149.023.288.068.416.046.129.112.24.197.336.086.095.192.17.316.225.125.054.266.081.422.081zm2.308 0c.126 0 .241-.02.346-.057a.802.802 0 0 0 .463-.429c.048-.103.077-.218.089-.346h-.435a.582.582 0 0 1-.141.332.415.415 0 0 1-.322.126.49.49 0 0 1-.429-.214.676.676 0 0 1-.095-.22 1.117 1.117 0 0 1 0-.51.689.689 0 0 1 .096-.224.486.486 0 0 1 .428-.216.467.467 0 0 1 .395.216c.027.043.044.09.051.141h.435a.823.823 0 0 0-.097-.314.765.765 0 0 0-.196-.23.863.863 0 0 0-.269-.142 1.028 1.028 0 0 0-.319-.048 1.03 1.03 0 0 0-.421.083.902.902 0 0 0-.316.228 1.02 1.02 0 0 0-.197.342c-.046.13-.069.272-.069.424 0 .149.023.288.069.416.046.129.111.24.197.336.086.095.191.17.316.225.125.054.265.081.421.081zm1.843-.046v-.731h.472a.85.85 0 0 0 .326-.056.564.564 0 0 0 .342-.357.776.776 0 0 0 0-.485.565.565 0 0 0-.342-.356.85.85 0 0 0-.326-.056h-.92v2.041h.448zm.349-1.08h-.349v-.612h.349a.94.94 0 0 1 .148.012c.048.007.09.022.126.044a.241.241 0 0 1 .087.093c.022.04.033.092.033.157a.327.327 0 0 1-.033.157.241.241 0 0 1-.087.093.346.346 0 0 1-.126.044.94.94 0 0 1-.148.012z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  }
};

export const ShoppingCartIcon = (props) => {
  //const opacity = props.dimmed ? 0.2 : 1;
  let color = 'currentColor';
  if (props.needed) {
    color = '#f05c22'; // orange
  } else if (props.ordered) {
    color = '#2ECC71'; // green
  }

  return (
    <svg width="22" height="20" viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path id="5h3ia26exa" d="M.003 0h21.83v19.98H.004z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M17.287 16.171a1.09 1.09 0 0 0 0 2.178 1.09 1.09 0 0 0 0-2.178zm0 3.811a2.726 2.726 0 0 1-2.722-2.722 2.726 2.726 0 0 1 2.722-2.722 2.725 2.725 0 0 1 2.723 2.722 2.726 2.726 0 0 1-2.723 2.722zM6.96 11.733h11.43l1.583-6.397H5.432l1.529 6.397zm12.07 1.634H6.316a.817.817 0 0 1-.794-.627L2.867 1.633H.817A.817.817 0 1 1 .817 0h2.695c.378 0 .706.26.794.627l.735 3.075h15.976a.816.816 0 0 1 .793 1.013l-1.988 8.032a.817.817 0 0 1-.792.62zM7.405 16.171a1.09 1.09 0 0 0 0 2.178 1.09 1.09 0 0 0 0-2.178zm0 3.811a2.726 2.726 0 0 1-2.722-2.722 2.726 2.726 0 0 1 2.722-2.722 2.725 2.725 0 0 1 2.722 2.722 2.725 2.725 0 0 1-2.722 2.722z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const AlertIcon = (props) => {
  const color = props.highlight ? '#f05c22' : 'currentColor';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 3 21 21">
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <path
            d="M15.604 15.916c-.057-.038-.484-.348-.912-1.286-.786-1.722-.95-4.147-.95-5.879l-.001-.023c-.009-2.288-1.332-4.261-3.225-5.146V2.228C10.516.999 9.552 0 8.368 0H8.19C7.006 0 6.043 1 6.043 2.228v1.354c-1.9.888-3.226 2.87-3.226 5.169 0 1.732-.165 4.157-.95 5.88-.428.937-.856 1.247-.912 1.285-.24.115-.357.38-.302.648.055.271.3.458.567.458h4.147C5.39 18.668 6.687 20 8.279 20c1.592 0 2.89-1.332 2.913-2.978h4.146c.267 0 .512-.187.568-.458.055-.268-.063-.533-.302-.648zM7.174 2.228c0-.581.456-1.054 1.016-1.054h.178c.56 0 1.016.473 1.016 1.054v.973c-.357-.076-.726-.116-1.105-.116-.378 0-.748.04-1.105.116v-.973zm1.105 16.598c-.968 0-1.758-.805-1.78-1.804h3.561c-.023.999-.813 1.804-1.78 1.804zm2.278-2.978H2.51c.098-.158.198-.337.297-.539.758-1.548 1.142-3.755 1.142-6.558 0-2.477 1.943-4.493 4.33-4.493 2.389 0 4.331 2.016 4.331 4.495v.022c.003 2.791.387 4.99 1.144 6.534.099.202.198.381.296.54h-3.492z"
            transform="translate(6 4)"
          />
        </g>
      </g>
    </svg>
  );
};

export const ChatIcon = (props) => {
  const color = props.highlight ? '#f05c22' : 'currentColor';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <path
              d="M11.875 3.62h-7.5c-.345 0-.625.271-.625.604 0 .333.28.604.625.604h7.5c.345 0 .625-.27.625-.604 0-.333-.28-.603-.625-.603zM9.375 6.034h-5c-.345 0-.625.27-.625.604 0 .333.28.603.625.603h5c.345 0 .625-.27.625-.603 0-.333-.28-.604-.625-.604z"
              transform="translate(4.385 4.345)"
            />
            <path
              d="M13.75 0H2.5C1.121 0 0 1.083 0 2.414v12.069c0 .234.14.448.36.546.084.038.175.057.265.057.144 0 .286-.048.4-.14l3.576-2.877h9.149c1.379 0 2.5-1.083 2.5-2.414V2.414C16.25 1.083 15.129 0 13.75 0zM15 9.655c0 .665-.56 1.207-1.25 1.207H4.375c-.146 0-.287.05-.4.14L1.25 13.195V2.414c0-.665.56-1.207 1.25-1.207h11.25c.69 0 1.25.542 1.25 1.207v7.241z"
              transform="translate(4.385 4.345)"
            />
            <path
              d="M17.5 4.828c-.345 0-.625.27-.625.603 0 .333.28.603.625.603.69 0 1.25.542 1.25 1.207v10.21l-2.11-1.63c-.11-.084-.249-.131-.39-.131H7.5c-.69 0-1.25-.542-1.25-1.207v-.604c0-.333-.28-.603-.625-.603s-.625.27-.625.603v.604c0 1.331 1.121 2.414 2.5 2.414h8.53l2.954 2.282c.113.087.252.131.391.131.091 0 .184-.019.271-.059.216-.101.354-.312.354-.544V7.24c0-1.33-1.121-2.413-2.5-2.413z"
              transform="translate(4.385 4.345)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const UserImage = (props) => {
  if (props.src) {
    return <img src={props.src} alt="userimg" />;
  } else if (props.color) {
    return <span style={{ backgroundColor: `${props.color}` }}></span>;
  } else if (props.showPlaceholder !== false) {
    return <UserIcon />;
  } else {
    return <span></span>;
  }
};

// <ThumbnailImage
//   className="local-class" // class for margins and layout, no sizing
//   src={null}  // provide image url or null for placeholder image
//   type={'cover'} // type selects size and styling, see below
//   placeholderIcon={<ChefIcon />}  // provide desired placeholder icon, null means no placeholder
//   onClick={ whatever }
//   onDragOver={ whatever }
//   onDrop={ whatever }
//   alt={ whatever } // null is 'thumbnail'
// />
export const ThumbnailImage = (props) => {
  var classname;

  switch (props.type) {
    case 'standard':
      classname = 'common-thumbnail-image';
      break;

    case 'cover':
      classname = 'common-cover-image';
      break;

    case 'admin':
      classname = 'common-admin-image';
      break;

    case 'recipe':
      classname = 'common-recipe-image';
      break;

    default:
      // 'standard'
      //<Spin tip="Loading ingredients..."></Spin> (for below)
      classname = 'common-thumbnail-image';
  }

  return (
    <div
      className={classname + ' ' + (props.className || '')}
      onClick={props.onClick ? props.onClick : () => false}
      onDragOver={props.onDragOver ? props.onDragOver : () => false}
      onDrop={props.onDrop ? props.onDrop : () => false}
      alt={props.alt ? props.alt : 'thumbnail'}
    >
      {props.src ? (
        props.src == '' ? (
          <div className="spinner-placeholder" style={{ backgroundImage: `url(${spinner})` }} />
        ) : (
          <img src={props.src} alt="thumbnail" />
        )
      ) : props.placeholderIcon ? (
        props.placeholderIcon
      ) : (
        <span style={{ display: 'inline-block' }}></span>
      )}
    </div>
  );
};

export const CidekicProgress = (props) => {
  let percentage = isNaN(props.percent) ? 0 : props.percent;
  var color = (
    props.percent > 66 ? '#00c866'
    : props.percent > 33 ? '#f5c028'
    : '#ff0008');

  // console.log("percentage is " + percentage);

  return (
    <>
      <div
        onClick={props.onClick}
        className="p-1 smal"
        // style={{ color: color }}
      >
        {percentage}%
      </div>
      <div className="p-1 flex-grow-1">
        <Progress
          percent={percentage}
          strokeColor={color}
          showInfo={false}
        />
      </div>
    </>
  );
};

//////////////
// Controls //
//////////////

export const CidekicButton = (props) => {
  return (
    <div className={'common-button ' + (props.className || '')} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export const PlusButton = (props) => {
  return (
    <Link
      to={props.to || '#'}
      className={'common-plus-button ' + (props.className || '')}
      onClick={props.onClick ? props.onClick : () => false}
    >
      <PlusIcon alt={props.alt} />
      {props.children}
    </Link>
  );
};

export const PlusButtonMobile = (props) => {
  return (
    <div className={'common-plus-button-mobile ' + (props.className || '')} onClick={props.onClick}>
      <PlusIcon alt={props.alt} />
      {props.children}
    </div>
  );
};

// <RowOkCancel
//   className="local-class" // class for margins and layout, no sizing
//   onClickOk={}
//   onClickCancel={}
// />
export const RowOkCancel = (props) => {
  return (
    <div className={'common-controls-container ' + (props.className || '')}>
      <CidekicButton className="button-table-row-tiny button-highlight" onClick={props.onClickOk} alt="ok">
        &#10004;
      </CidekicButton>
      <CidekicButton className="button-table-row-tiny" onClick={props.onClickCancel} alt="cancel">
        X
      </CidekicButton>
    </div>
  );
};

export const RowAssigneeEdit = (props) => {
  return (
    <span className="flex">
      <Select
        className="assignee-search"
        showSearch
        showArrow={false}
        placeholder="Search assignee..."
        onChange={props.onChange}
        onClear={props.onClickRemove}
        value={props.assignee ? props.assignee : 'Search assignee'}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto', width: 250 }}
      >
        {props.assigneeChoices.map((a) => {
          return (
            <OptGroup key={`${a.category}`} label={`${a.category}`} style={{ fontColor: 'white' }}>
              {a.children.map((c) => {
                return (
                  <Option key={`${a.type}-${c.id}`} value={c.value}>
                    {c.name}
                  </Option>
                );
              })}
            </OptGroup>
          );
        })}
      </Select>
      <span className="row-assignee-clear" onClick={props.onClickRemove}>
        <TrimmedPlusIcon />
      </span>
    </span>
  );
};

// CHANGE 1202077200318274
export const RowAssigneeEditWithSearch = (props) => {
  return (
    <span className="flex">
      <Select
        className="assignee-search"
        showSearch
        showArrow={false}
        placeholder="Search assignee..."
        onChange={props.onChange}
        onSearch={props?.onSearch ? props.onSearch : () => false} // CHANGE 1202077200318274
        onClear={props.onClickRemove}
        value={props.assignee ? props.assignee : 'Search assignee'}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto', width: 250 }}
      >
        {props?.assigneeChoices?.map((a) => {
          return (
            <Option key={`assignee-${a.id}`} value={`${a.firstName} ${a.lastName}`}>
              {a.firstName} {a.lastName}
            </Option>
          );
        })}
      </Select>
      <span className="row-assignee-clear" onClick={props.onClickRemove}>
        <TrimmedPlusIcon />
      </span>
    </span>
  );
};

// CHANGE 1202077200318274
export const StationAssigneeEdit = (props) => {
  return (
    <span className="flex">
      <Select
        className="assignee-search"
        showSearch
        showArrow={false}
        placeholder={props?.placeholder ? props.placeholder : 'Search Assignee'}
        onChange={props.onChange}
        onSearch={props?.onSearch ? props.onSearch : () => false}
        onClear={props.onClickRemove}
        value={props.assignee ? props.assignee : 'Search assignee'}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto', width: 'inherit', zIndex: 99999 }}
      >
        {props?.assigneeChoices?.map((a) => {
          return (
            <Option key={`assignee-${a.id}`} value={JSON.stringify({ id: a.id, name: `${a.firstName} ${a.lastName}` })}>
              {a.firstName} {a.lastName}
            </Option>
          );
        })}
      </Select>
      <span
        style={{ position: 'absolute', cursor: 'pointer', top: '10px', right: '10%' }}
        onClick={props.onClickRemove}
      >
        <TrimmedPlusIcon />
      </span>
    </span>
  );
};

// CHANGE 1202077200318274
export const MeetingParticipantAdd = (props) => {
  return (
    <span className="flex">
      <Select
        className="assignee-search"
        showSearch
        showArrow={false}
        placeholder={'Search Participant'}
        onChange={props.onChange}
        onSearch={props?.onSearch ? props.onSearch : () => false}
        onClear={props.onClickRemove}
        value={props.participant ? props.participant : 'Search Participant'}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto', width: 250 }}
      >
        {props?.participantChoices?.map((a) => {
          return (
            <Option
              key={`participant-${a.id}`}
              value={JSON.stringify({ id: a.id, firstName: a.firstName, lastName: a.lastName })}
            >
              {a.firstName} {a.lastName}
            </Option>
          );
        })}
      </Select>
      <span
        style={{ position: 'absolute', cursor: 'pointer', top: '10px', right: '10%' }}
        onClick={props.onClickRemove}
      >
        <TrimmedPlusIcon />
      </span>
    </span>
  );
};

export const RowTags = (props) => {
  return (
    <div className="row-tags-container">
      {(props.isEditing || props.isAdding) && (
        <input
          type="text"
          placeholder={props.tags && props.tags.length > 0 ? 'Add more tags' : 'Add tags'}
          className="row-tags-input"
          name={props.inputName}
          id={props.inputId}
          ref={props.ref}
          onKeyPress={props.onEditKeyPress}
          onBlur={props.onEditBlur}
        />
      )}

      {props.tags &&
        props.tags.length > 0 &&
        props.tags.map((tag, i) => {
          return (
            <Tag
              className="row-tag"
              color={'black'} // modified by style for row display
              key={`${props.objId}-tag-${i}`}
              closable={props.closable}
              onClose={() => {
                props.onClose(tag);
              }}
            >
              {tag}
            </Tag>
          );
        })}
    </div>
  );
};

export const CidekicDropdown = (props) => {
  return (
    <Dropdown overlay={props.overlay} trigger={props.trigger ? props.trigger : ['click']}>
      <div className={'ant-dropdown-link common-select ' + (props.className || '')} onClick={props.onClick}>
        <span>{props.children}</span>
        <span style={{ float: 'right' }}>
          <DownSelectArrow />
        </span>
      </div>
    </Dropdown>
  );
};

// FEATURE 1202040063159015
export const MultiTagSelect = (props) => {
  return (
    // <Dropdown overlay={props.overlay} placement="bottomLeft" trigger={['click']}>
    // 	<input
    // 		type="text"
    // 		placeholder="Find tag..."
    // 		className={'multitag-dropdown common-select ' + (props.className || '')}
    // 		onChange={(e) => {
    // 			props.onChange(e.target.value);
    // 		}}
    // 		value={props.searchedTag}
    // 	/>
    // </Dropdown>
    <Select
      mode="multiple"
      allowClear
      className={'common-select ' + (props.className || '')}
      placeholder="Tag"
      onChange={(e) => props.onChange(e)}
      value={props.value ? props.value : undefined}
    >
      {props.children}
    </Select>
  );
};

export const SelectedTags = (props) => {
  return (
    <div className="tag-box">
      {props.selectedTags.map((tag, i) => (
        <Tag className="selected-tag" key={`${i}-${tag}`}>
          <div onClick={() => props.onRemove(tag)}>{tag}</div>
        </Tag>
      ))}
    </div>
  );
};

// this was the OTHER kind of dropdown that we may switch to, at least the dropdown arrow style
// <div className="control-box-cat12">
//   <div className="add-cat22">
//     <div>{menuName ? menuName : 'Account name'}</div>
//   </div>
//   <Dropdown overlay={menuList}>
//     <div className="dropdown-icon">
//       <i className="fa fa-chevron-down" aria-hidden="true"></i>
//     </div>
//   </Dropdown>
// </div>

export const CidekicSelect = (props) => {
  return (
    <Select
      showSearch={props.showSearch || false}
      className={'ant-select-suffix common-select-select ' + (props.className || '')}
      dropdownClassName={'common-select-select-dropdown ' + (props.dropdownClassName || '')}
      suffixIcon={<DownSelectArrow />}
      //dropdownRender={props.dropdownRender ? props.dropdownRender : () => false}
      placeholder={props.placeholder ? props.placeholder : ''}
      ref={props.ref}
      onKeyPress={props.onKeyPress ? props.onKeyPress : () => false}
      onChange={props.onChange ? props.onChange : () => false}
      onBlur={props.onBlur ? props.onBlur : () => false}
      // value={props.value ? props.value : undefined}
      defaultValue={props.defaultValue ? props.defaultValue : undefined}
      //tabindex={props.tabindex ? props.tabindex : undefined}
      disabled={props.disabled ? props.disabled : undefined}
      tabIndex={props?.tabIndex ? props.tabIndex.toString() : '0'}
    >
      {props.children}
    </Select>
  );
};

export const BackButton = (props) => {
  return (
    <Link
      className={'common-back-nav ' + (props.className || '')}
      to={props.backLink || '#'}
      onClick={props.onClick ? props.onClick : () => false}
      alt={props.alt ? props.alt : props.backText}
    >
      <ChevronLeft />
      {props.backText}
    </Link>
  );
};

export const CommentIndicator = (props) => {
  return (
    <div className={'common-attachment-indicator ' + (props.className || '')}>
      {props.count > 0 && (
        <div
          className="common-attachment-subcontainer"
          onClick={props.onClick}
          alt={props.alt ? props.alt : 'See attachments'}
        >
          <div className="common-attachment-count">{props.count}</div>
          <div className="common-comment-icon">
            <CommentIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export const AttachmentIndicator = (props) => {
  return (
    <div className={'common-attachment-indicator ' + (props.className || '')}>
      <div
        className="common-attachment-subcontainer"
        onClick={props.onClick}
        alt={props.alt ? props.alt : 'See attachments'}
      >
        {props.count > 0 && <div className="common-attachment-count">{props.count}</div>}
        {(props.count > 0 || props.showIfZero !== false) && (
          <div className="common-attachment-icon">
            <PaperclipIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export const AttachmentIndicatorMobile = (props) => {
  return (
    <div className={'common-attachment-indicator-mobile ' + (props.className || '')}>
      <div style={{ display: 'flex' }} onClick={props.onClick} alt={props.alt ? props.alt : 'See attachments'}>
        {props.count > 0 && <div className="common-attachment-count">{props.count}</div>}
        {(props.count > 0 || props.showIfZero !== false) && (
          <div className="common-attachment-icon-mobile">
            <PaperclipIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export const ExpandingSearchMobile = (props) => {
  return (
    <div className={'common-search-container-mobile ' + (props.className || '')}>
      {props.showSearch ? (
        <div>
          <input
            type="text"
            className={'common-search-bar-mobile'}
            placeholder={props.searchPrompt ? props.searchPrompt : 'Enter search term'}
            onChange={props.onChange}
          />
          <span className="common-search-icon-mobile">
            <i className="fa fa-search" aria-hidden="true"></i>
          </span>
        </div>
      ) : (
        <div>
          <span className="common-search-icon-mobile">
            <i className="fa fa-search" aria-hidden="true" onClick={props.onClick}></i>
          </span>
        </div>
      )}
    </div>
  );
};

export const ExpandingSearchPanel = (props) => {
  //the 'true' in the clause below was if 'props.showSearch;'
  return (
    <div className={'common-search-container-panel ' + (props.className || '')}>
      {true ? (
        <div>
          <input
            autoFocus
            type="text"
            className={'common-search-bar-panel'}
            id={props.id}
            value={props.value ? props.value : undefined}
            placeholder={props.searchPrompt ? props.searchPrompt : 'Enter search term'}
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
          />
          <span className="common-search-icon-mobile">
            <i className="fa fa-search" aria-hidden="true"></i>
          </span>
        </div>
      ) : (
        <div>
          <span className="common-search-icon-mobile">
            <i className="fa fa-search" aria-hidden="true" onClick={props.onClick}></i>
          </span>
        </div>
      )}
    </div>
  );
};

export const ExpandCollapseIcon = (props) => {
  //if (props.expanded) {
  return (
    <span className={'common-expand-collapse-icons' + (props.expanded ? ' expanded' : '')} onClick={props.onClick}>
      <ChevronLeft />
    </span>
  );
};
