import React, { useState, useEffect, useCallback } from 'react';
import './index.css';
import comment from '../../img/comment.png';
import add from '../../img/add-recipe-mobile.png';
import { Drawer, Dropdown, Menu, notification, Spin, Tag, Select } from 'antd';
import { menuData } from '../../data/recipedata';
import { Link } from 'react-router-dom';
import TabsCat from '../../components/Tabs/tabsCat';
import Tabs from '../../components/Tabs/tabs';
import searchy from '../../img/search-ing-icon.png';
import useWindowSize from '../../components/windowdimensions/windowSize';
import { search, update, duplicate } from '../../services/recipe.service';
import {
  createRecipeCategory,
  updateRecipeCategory,
  destroyRecipeCategory,
  searchCategorizedRecipes,
} from '../../services/category.service';
import _, { remove, findIndex, cloneDeep } from 'lodash';
import DocumentList from './DocumentList';
import { searchDocLinks } from '../../services/doc.service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RoleAccessibleComponent from '../RoleAccess/roleaccess';
import {
  PlusIcon,
  PlusButton,
  MoreIcon,
  ThumbnailImage,
  AttachmentIndicatorMobile,
  Paperclip,
  UserImage,
  MultiTagSelect,
  SelectedTags,
} from '../../components/common/common_ui';

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  display: 'flex',
  overflow: 'auto',
});

const getItemStyle2 = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const getListStyle2 = (isDraggingOver) => ({
  overflow: 'auto',
});

export const RecipeCategoryMobile = (props) => {
  const { currentPath, searchTerm } = props;

  const [visible, updateVisible] = useState(false);
  const [active, setActive] = useState([]);
  // removed tagFilter, // BUG 1202030604174459
  const [searchedTag, setSearchedTag] = useState(''); // FEATURE 1202040063159015
  const [activeRecipe, setActiveRecipe] = useState([]);
  const [origRecipe, setOrigRecipe] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [drawerTags, setDrawerTags] = useState([]);
  const [recipeCourse, setRecipeCourse] = useState([]);
  const [pristineRecipe, setPristineRecipe] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [courseTitle, setCourseTitle] = useState('');
  const [courseid, setCourseId] = useState('');
  const [recId, setRecId] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [allRecipes, setAllRecipes] = useState([]);
  const [restoId, setRestoId] = useState('');
  const [pristineData, setPristineData] = useState([]);
  const [isCanceled, setCancel] = useState(false);
  const [visible2, updateVisible2] = useState(false);
  const [docLinks, setDocLinks] = useState({});
  const [links, setLinks] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('All recipes');
  const [tagList, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const { CheckableTag } = Tag;

  const { Option } = Select;

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};

  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }, []);

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    // getCourses();
    // 'tagFilter' -> 'selectedTags', // BUG 1202030604174459
    if (selectedTags.length > 0) {
      const filteredTags = pristineRecipe.filter((a) => a.tags.some((tag) => selectedTags.includes(tag)));
      setActiveRecipe(filteredTags);
    } else {
      setActiveRecipe([]); // FEATURE 1202040063159015
    }
  }, [selectedTags]);

  useEffect(() => {
    if (!searchTerm && pristineData.length > 0) {
      setRecipeCourse(pristineData);
      window.location.reload();
    }
    if (searchTerm && pristineData.length > 0) {
      filterRecipes(pristineData, searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    console.log(recipeCourse);
  }, [recipeCourse]);

  const windowSize = useWindowSize();

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const getCourses = () => {
    setCourseTitle('');
    setCourseId('');
    // console.log(resto.id);
    setRestoId(resto.id);
    // let params = {
    //   restaurantId: resto.id,
    // };
    // searchRecipeCategories(params).then((res) => {
    //   console.log('CATEGORIES', res);
    //   setIsAdding(false);
    //   let categories = res.map((r) => {
    //     r.categoryId = r.id;
    //     r.order = parseInt(r.order) || 0;
    //     return r;
    //   });
    //   getAdditionalDocLinks(categories);
    // });
    searchCategorizedRecipes({ id: resto.id }).then((res) => {
      setIsAdding(false);
      if (res) {
        let getCategories = res.map((r) => {
          r.order = parseInt(r.order) || 0;
          return r;
        });
        let merged = [].concat.apply([], getCategories);
        getAdditionalDocLinks(merged);
      }
    });
  };

  const deleteCourse = () => {
    //delete category and unassign associated recipe

    destroyRecipeCategory(courseid).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Successfully deleted category! Fetching all categories...',
      // });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };

  const duplicateRecipe = (recipeId) => {
    duplicate({ recipeId: recipeId, restaurantId: resto.id })
      .then(() => {
        getCourses();
      })
      .catch((err) => console.log(err));
  };

  const delRecipe = () => {
    console.log(recId, courseid);
    let selectRecipe = allRecipes.filter((r) => r.id == recId);
    console.log(selectRecipe);
    update(recId, { restaurantId: restoId, categoryId: '0' })
      .then((response) => {
        // notification.open({
        //   message: 'Successfully unassigned recipe from category! Fetching all categories...',
        // });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  const editCourse = () => {
    setIsEditing(true);
  };

  const renameCourse = async (e, obj) => {
    console.log(courseTitle, courseid);
    let params = {
      restaurantId: restoId,
      name: courseTitle.trim(),
    };

    if (e.key === 'Enter') {
      if (!courseTitle) {
        // notification.open({
        //   message: 'Please provide a category name',
        // });
        return;
      }

      if (recipeCourse && recipeCourse.length > 0 && Object.keys(obj).length > 0) {
        const cloneList = cloneDeep(recipeCourse);
        const index = findIndex(cloneList, { categoryId: obj.categoryId });
        if (cloneList[index]) {
          cloneList[index].name = courseTitle.trim();
          console.log(cloneList);
          setRecipeCourse(cloneList);
        }
      }
      setIsEditing(false);
      const res = await updateRecipeCategory(courseid, params);
      setTimeout(() => {
        // notification.open({
        //   message: 'Successfully created category! Fetching all categories...',
        // });
        window.location.reload();
      }, 2000);
    }
  };

  const renamesCourse = async (e, obj) => {
    if (isCanceled) {
      setIsEditing(false);
      setCancel(false);
      return;
    }

    let params = {
      restaurantId: restoId,
      name: courseTitle.trim(),
    };

    console.log(params);

    if (!courseTitle) {
      setIsEditing(false);
    } else {
      if (recipeCourse && recipeCourse.length > 0 && Object.keys(obj).length > 0) {
        const cloneList = cloneDeep(recipeCourse);
        const index = findIndex(cloneList, { categoryId: obj.categoryId });
        if (cloneList[index]) {
          cloneList[index].name = courseTitle.trim();
          console.log(cloneList);
          setRecipeCourse(cloneList);
        }
      }
      const res = await updateRecipeCategory(courseid, params);
      setIsEditing(false);
      setTimeout(() => {
        // notification.open({
        //   message: 'Successfully changed category name! Fetching all categories...',
        // });
        window.location.reload();
      }, 2000);
    }
  };

  const cancelRename = () => {
    setCancel(true);
  };

  const addNewCourse = () => {
    setIsAdding(true);
    console.log(recipeCourse);
    setRecipeCourse((arr) => [{ menuId: 1, name: 'New Category' }, ...arr]);
  };

  // const menu = (
  //   <Menu className="menu-show-preview">
  //     <Menu.Item className="menu-show-preview" onClick={addNewCourse}>
  //       <a>Add new category</a>
  //     </Menu.Item>
  //     <Menu.Item className="menu-show-preview">
  //       <a>
  //         <Link to={{ pathname: '/recipes/CreateRecipes', createProps: { name: 'menus' } }}>Create recipe</Link>
  //       </a>
  //     </Menu.Item>
  //   </Menu>
  // );

  const menuRec = (obj) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a onClick={() => duplicateRecipe(obj.id)}>Duplicate recipe</a>
        <a onClick={delRecipe}>Remove recipe</a>
      </Menu.Item>
    </Menu>
  );

  const menu1 = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a onClick={editCourse}>Rename</a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a onClick={deleteCourse}>Delete</a>
      </Menu.Item>
    </Menu>
  );

  const getAllRecipes = () => {
    setActiveRecipe(pristineRecipe);
    setName('All recipes');
  };

  const menuFilter = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={getAllRecipes}>
        <span>All recipes</span>
      </Menu.Item>
      {recipeCourse.map((m, i) => {
        return (
          <Menu.Item className="menu-show-preview" onClick={(e) => filteringRecipes(m)}>
            <span>{m.name}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const filteringRecipes = (m) => {
    console.log(m);
    setName(m.name);
    console.log(pristineRecipe);
    let inputValue = parseInt(m.categoryId);
    const clonedData = _.cloneDeep(pristineRecipe);
    let filtered = clonedData.filter((obj) => obj.categoryId === inputValue);
    console.log(filtered);
    setActiveRecipe(filtered);
  };

  const getAdditionalDocLinks = (result) => {
    console.log('NEW CATEOGRIES', result);

    const allTheRecipes = [].concat.apply(
      [],
      result.map((r) => r.recipes).map((rec) => rec)
    );
    // setRecipeCourse(cat);

    // let getCatIds = cat.map((c) => {
    //   return c.id;
    // });
    // console.log('CATIDS', getCatIds);

    // const fetchData = () => {
    //   const allRequests = getCatIds.map((e) =>
    //     search({ restaurantId: restoid, categoryId: e }).then((response) => response.recipes)
    //   );

    //   return Promise.all(allRequests);
    // };

    // fetchData().then((res) => {
    //   console.log('ALL ASSOCIATED RECIPES', res);
    //   let assocRecipes = [].concat.apply([], res);
    //   assocRecipes = assocRecipes.filter(Boolean);
    //   let parsed = assocRecipes.map((e) => {
    //     e.prepped = e.info ? JSON.parse(e.info) : {};
    //     e.prepped.id = e.id;
    //     e.prepped.name = e.name;
    //     e.prepped.updatedAt = e.updatedAt;
    //     e.prepped.order = e.info?.order;
    //     return e;
    //   });

    //   console.log('PARSED', parsed);

    setAllRecipes(allTheRecipes);

    // let combined = cat.concat(parsed);
    // console.log('COMBO', combined);

    // const result = Object.values(
    //   combined.reduce((r, o) => {
    //     const key = `${o.categoryId}`;

    //     if (!r[key]) r[key] = { ...o, recipes: [] };

    //     //console.log(o.recipes);

    //     r[key].recipes.push(o.prepped);

    //     r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined);

    //     r[key].recipes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

    //     r[key].recipes.sort((x, y) => {
    //       return x.order - y.order;
    //     });

    //     return r;
    //   }, {})
    // );

    const name_changer = (n) => {
      let splitted = n.split('_.');
      if (splitted.length > 1) {
        let name = splitted[0];
        let ord = parseInt(splitted[1]);
        return `${name}_.${ord + 1}`;
      }
      return `${n}_.${1}`;
    };

    let stack = [];

    result.forEach((obj) => {
      if (stack.indexOf(obj.name) > -1) {
        obj.name = name_changer(obj.name);
      }
      stack.push(obj.name);
    });

    console.log('MENUS WITH RECIPES', result);

    let stringList = result
      .sort((x, y) => {
        return y.id - x.id;
      })
      .sort((x, y) => {
        return x.order - y.order;
      })
      .filter((e) => e.recipes)
      .map((e) => e.recipes.map((link) => link.id))
      .reduce((a, b) => a.concat(b), [])
      .filter((l) => l !== undefined)
      .join(', ');

    // let getLinks = result
    // 	.filter((e) => e.recipes)
    // 	.map((e) => e.recipes.map((link) => link.id))
    // 	.reduce((a, b) => a.concat(b), [])
    // 	.filter((l) => l !== undefined);

    // let stringList = getLinks.join(', ');

    console.log('STRINGLIST', stringList);

    // ////
    // let params = {
    //   type: 'recipes',
    //   linkId: stringList,
    // };
    if (stringList !== '') {
      searchDocLinks({ type: 'recipes', linkId: stringList }).then((res) => {
        // console.log(res);
        if (res && res.length > 0) {
          // let removeDeleted = res.filter((l) => l.Document.folderId !== null);
          const list = Array.from(
            res
              .filter((l) => l.Document.folderId !== null)
              .reduce((m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]), new Map()),
            ([linkId, documentId]) => ({ linkId, documentId })
          );

          // console.log(list);

          let addSelectedLinks = result.map((o) => {
            (o.recipes || []).map((c) => {
              list.map((d) => {
                if (d.linkId == c.id) {
                  c.selected = d.documentId.length;
                }
              });
            });
            return o;
          });

          // console.log(addSelectedLinks);
          // ////
          console.log('RECIPE COURSE TO SET', addSelectedLinks);
          setRecipeCourse(addSelectedLinks);
          setPristineData(addSelectedLinks);
        } else {
          setRecipeCourse(result);
          setPristineData(result);
        }
      });
    } else {
      setRecipeCourse(result);
      setPristineData(result);
    }
  };

  const createCourse = async (e) => {
    let course = courseTitle;
    let params = {
      restaurantId: restoId,
      name: course.trim(),
      order: 0,
    };

    console.log(params);

    if (e.key === 'Enter') {
      if (!course) {
        // notification.open({
        //   message: 'Please provide a category name',
        // });
        return;
      }

      const res = await createRecipeCategory(params);
      if (res) {
        setTimeout(() => {
          // notification.open({
          //   message: 'Successfully created category! Fetching all categories...',
          // });
          window.location.reload();
        }, 2000);
      }
    }
  };

  const saveCourse = async () => {
    let course = courseTitle;
    let params = {
      restaurantId: restoId,
      name: course.trim(),
      order: 0,
    };

    console.log(params);

    if (!course) {
      console.log(recipeCourse);
      setIsAdding(false);
      setRecipeCourse((currentItems) => currentItems.filter((item) => item.menuId !== 1));
      window.location.reload();
    } else {
      const res = await createRecipeCategory(params);
      if (res) {
        setTimeout(() => {
          // notification.open({
          //   message: 'Successfully created category! Fetching all categories...',
          // });
          window.location.reload();
        }, 2000);
      }
    }
  };

  const showDrawer = (ev) => {
    updateVisible(true);
    setIsLoading(true);
    let val = ev.target.dataset.value;
    console.log(val);
    let params = {
      restaurantId: restoId,
      from: 0,
      limit: 10000,
    };
    search(params).then((res) => {
      let recipe = res.recipes.map((i) => {
        let parsed = JSON.parse(i.info);
        i.info = parsed;
        i.tags = parsed.tags ? parsed.tags : [];
        i.RecipeDetails = JSON.parse(i.RecipeDetails);
        i.imageUrl =
          parsed.recipeImageThumbs?.length > 0
            ? parsed.recipeImageThumbs[0]
            : parsed.recipeImages?.length > 0
            ? parsed.recipeImages[0]
            : null;
        return i;
      });
      let tags = recipe.map((t) => {
        return t.tags;
      });
      let mergedTags = [...new Set([].concat(...tags))]
        .map((t) => {
          if (t === '' || t === null) {
            return 'untagged';
          } else {
            return t.trim();
          }
        })
        .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() > b.toLowerCase() ? 1 : 0)); // FEATURE 1202040063159015 NEW
      recipe.sort((a, b) => {
        return b.id - a.id;
      });
      console.log(recipe);
      // setActiveRecipe(recipe);  // BUG 1201998443633215 RETURNED
      setPristineRecipe(recipe);
      setOrigRecipe(recipe);
      let uniqueTags = [...new Set(mergedTags)];
      setTags(uniqueTags); // FEATURE 1202040063159015 NEW
      setDrawerTags(uniqueTags); // FEATURE 1202040063159015 NEW
      setIsLoading(false);
      document.getElementById('recipeAddBox').focus();
    });
  };

  const onClose = () => {
    updateVisible(false);
    updateVisible2(false);
    // setTagFilter([]), not needed, BUG 1202030604174459
    setName('All recipes');
    if (document.getElementById('recipeAddBox')) {
      document.getElementById('recipeAddBox').blur();
    }
  };

  const selectRecipe = (recipe) => {
    if (active.some((item) => item.name === recipe.name)) {
      const filteractive = active.filter((item) => item.name !== recipe.name);
      setActive(filteractive);
    } else {
      setActive((recipelist) => [...recipelist, recipe]);
    }
  };

  const addRecipes = () => {
    console.log(active, courseid);
    onClose();

    const addRecs = () => {
      const allRequests = active.map((e) =>
        update(e.id, { restaurantId: restoId, name: e.name, info: e.info, categoryId: courseid }).then(
          (response) => response
        )
      );

      return Promise.all(allRequests);
    };

    addRecs().then((res) => {
      setActive([]);
      setName('All recipes');
      // notification.open({
      //   message: 'Successfully added recipe to category!',
      // });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    });
  };

  // removed filterTags(), not needed // BUG 1202030604174459

  const menu3 = (obj) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a onClick={showDrawer}>Add existing recipe</a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <Link to={{ pathname: '/recipes/CreateRecipes', createProps: { name: 'cat', catId: obj.categoryId } }}>
          Create new recipe
        </Link>
      </Menu.Item>
    </Menu>
  );

  // const filterRecipes = (data, inputValue) => {
  //   const re = new RegExp(_.escapeRegExp(inputValue), 'i');

  //   const clonedData = _.cloneDeep(data);
  //   const results = clonedData.filter((object) => {
  //     return (
  //       object.recipes.filter((item) => {
  //         if (re.test(item.name)) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       }).length > 0 || re.test(object.name)
  //     );
  //   });
  //   console.log(results);
  //   setRecipeCourse(results);

  // };

  const filterRecipes = (data, e) => {
    let inputValue = e;

    const clonedData = _.cloneDeep(data);

    let searchString = inputValue.toLowerCase();

    const searchTags = clonedData
      .map((item) => ({
        ...item,
        recipes: item.recipes.filter((child) => {
          let tagslowercased = child.tags?.map((name) => name.toLowerCase());
          return (
            item.name.toLowerCase().indexOf(searchString) !== -1 ||
            tagslowercased?.find((a) => a.includes(searchString)) ||
            child.name.toLowerCase().indexOf(searchString) !== -1
          );
        }),
      }))
      .filter((item) => item.recipes.length > 0);

    console.log(searchTags);

    setRecipeCourse(searchTags);
  };

  const searchRecipe = (e) => {
    console.log(pristineRecipe);

    let inputValue = e.target.value;

    const clonedData = _.cloneDeep(pristineRecipe);

    let searchString = inputValue.toLowerCase();

    const searchTags = clonedData.filter((child) => {
      return child.tags?.find((a) => a.includes(searchString)) || child.name.toLowerCase().indexOf(searchString) !== -1;
    });
    //.filter((item) => item.tags.length > 0);

    console.log(searchTags);

    setActiveRecipe(searchTags);
  };

  const showDrawer2 = (e, obj) => {
    updateVisible2(true);
    setDocLinks({
      type: 'recipes',
      linkId: obj.id,
    });
    setLinks({
      type: 'recipes',
      linkId: obj.id,
      info: { title: obj.name },
    });
  };

  const closeDrawer = useCallback((data) => {
    updateVisible(false);
    updateVisible2(false);
    getCourses();
  }, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const itemSubItemMap = recipeCourse.reduce((acc, item) => {
      acc[item.categoryId] = item.recipes || [];
      return acc;
    }, {});

    console.log(itemSubItemMap);

    const sourceParentId = parseInt(result.source.droppableId);
    const destParentId = parseInt(result.destination.droppableId);

    const sourceSubItems = itemSubItemMap[sourceParentId];
    const destSubItems = itemSubItemMap[destParentId];

    console.log('SOURCESUBITEMS', sourceSubItems);
    console.log(sourceParentId, destParentId);

    let newItems = [...recipeCourse];

    if (sourceParentId === destParentId) {
      const items1 = Array.from(sourceSubItems);
      const [reorderedItem] = items1.splice(result.source.index, 1);
      items1.splice(result.destination.index, 0, reorderedItem);

      console.log(reorderedItem, result.source.index, result.destination.index);

      console.log('ITEMS', items1);

      console.log(newItems, sourceParentId);

      let reOrdered = newItems.map((item) => {
        if (item.categoryId === sourceParentId.toString()) {
          item.recipes = items1;
        }
        return item;
      });

      console.log('NEWITEMS', reOrdered);

      setRecipeCourse(newItems);

      //save order

      console.log(items1);

      let getRecipeIds = items1.map((item, index) => {
        item.order = index;
        item.info.order = index;
        return {
          id: item.id,
          name: item.name,
          info: item.info,
        };
      });

      console.log('RECIPE IDS', getRecipeIds);

      const fetchData = () => {
        const allRequests = getRecipeIds.map((e, index) =>
          update(e.id, {
            restaurantId: restoId,
            name: e.name,
            info: e.info,
            categoryId: sourceParentId,
          }).then((response) => response)
        );

        return Promise.all(allRequests);
      };

      fetchData().then((res) => {
        setActive([]);
        // notification.open({
        //   message: 'Successfully changed the order of a recipe!',
        // });
        setTimeout(() => {
          getCourses();
        }, 2000);
      });
    } else {
      //let newSourceSubItems = [...sourceSubItems];
      let newSourceSubItems = sourceSubItems ? Array.from(sourceSubItems) : [];
      const [draggedItem] = newSourceSubItems.splice(result.source.index, 1);
      let newDestSubItems = [...destSubItems];
      newDestSubItems.splice(result.destination.index, 0, draggedItem);
      let moveItems = newItems.map((item) => {
        if (item.courseId === sourceParentId) {
          item.recipes = newSourceSubItems;
        } else if (item.courseId === destParentId) {
          item.recipes = newDestSubItems;
        }
        return item;
      });
      console.log('MOVED ITEMS', moveItems);
      setRecipeCourse(moveItems);
    }
  };

  // const handleTagChange = (tag, checked) => {
  //   const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
  //   console.log('SELECTED TAGS: ', nextSelectedTags);
  //   setSelectedTags(nextSelectedTags);
  // };

  // FEATURE 1202040063159015
  const handleTagAppend = (tag) => {
    setSelectedTags((currentTags) => [...currentTags, tag]);
  };

  // FEATURE 1202040063159015
  const handleTagRemove = (tag) => {
    setSelectedTags((currentTags) => currentTags.filter((t) => t !== tag));
  };

  // FEATURE 1202040063159015
  const tagOptions = (tags, accumulator, inputValue) => (
    <Menu className="assignee-options1">
      {tags?.map((tag, i) => {
        return (
          <Menu.Item className="assignee-options-item1" key={`${i}-${tag}-menu-item`}>
            <span
              onClick={() => {
                inputValue(tag);
                accumulator(tag);
              }}
            >
              {tag}
            </span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  // FEATURE 1202040063159015
  useEffect(() => {
    if (searchedTag !== '') {
      let relatedTags = tagList.filter(
        (tag) =>
          tag.toLowerCase().indexOf(searchedTag) !== -1 || searchedTag.toString().toLowerCase().indexOf(tag) !== -1
      );
      if (selectedTags.length > 0) {
        relatedTags = relatedTags.filter((tag) => !selectedTags.includes(tag));
      } else {
        relatedTags = tagList; // FEATURE 1202040063159015 NEW
      }
      let uniqueTags = [...new Set(tagList)];
      setDrawerTags(uniqueTags.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))); // BUG 1201998443633215
      setSelectedTags(searchedTag);
    } else {
      let relatedTags = tagList; // FEATURE 1202040063159015 NEW
      // FEATURE 1202040063159015 NEW
      if (selectedTags.length > 0) {
        relatedTags = relatedTags.filter((tag) => !selectedTags.includes(tag));
      } else {
        relatedTags = tagList;
      }
      let uniqueTags = [...new Set(relatedTags)];
      setSelectedTags(uniqueTags.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)));
      setDrawerTags(uniqueTags); // FEATURE 1202040063159015 NEW
    }
  }, [searchedTag]);

  return (
    <div className="common-page-content-nomargins-mobile">
      <div className="common-page-content-mobile">
        <div className="common-controls-container-mobile pad-controls">
          <div className="flex-control-right">
            <RoleAccessibleComponent
              permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
            >
              <PlusButton alt="Add new category" onClick={addNewCourse}>
                Add new category
              </PlusButton>
            </RoleAccessibleComponent>
          </div>
        </div>
      </div>
      <div className="recipe-course-box-mobile">
        {/* <Tabs page={'category'} newCourse={isAdding}> */}
        <TabsCat page={'category'} newCourse={isAdding}>
          {recipeCourse.map((recipe, i) => {
            return (
              <div
                label={recipe.name}
                key={JSON.stringify({
                  id: recipe.categoryId,
                  order: i,
                  restaurantId: recipe.restaurantId,
                  name: recipe.name,
                })}
              >
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId={recipe?.categoryId?.toString()}>
                    {(provided, snapshot) => (
                      <div
                        className="recipe-tile-container-mobile"
                        onMouseOver={() => setCourseId(recipe.categoryId)}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {recipe.name === 'New Category' ? (
                          <div>
                            <input
                              autoFocus
                              type="text"
                              className="courses-bar-mobile"
                              placeholder="Enter a category name"
                              onChange={(e) => setCourseTitle(e.target.value)}
                              onKeyDown={(e) => createCourse(e)}
                              onBlur={saveCourse}
                            />
                            {/* <img src={add} alt="add-button" className="add-recipe-mobile" onClick={createCourse} /> */}
                          </div>
                        ) : (
                          <div>
                            {isEditing && recipe.categoryId === courseid ? (
                              <div className="recipe-name-box-mobile12">
                                <input
                                  autoFocus
                                  type="text"
                                  className="courses-bar-mobile"
                                  placeholder="Enter a category name"
                                  defaultValue={recipe.name}
                                  onChange={(e) => setCourseTitle(e.target.value)}
                                  onKeyDown={(e) => renameCourse(e, recipe)}
                                  onBlur={(e) => renamesCourse(e, recipe)}
                                />
                                {/* <img src={add} alt="add-button" className="add-recipe-mobile" onClick={renameCourse} /> */}
                                <div
                                  onMouseOver={cancelRename}
                                  style={{
                                    width: 100,
                                    cursor: 'pointer',
                                    margin: '7px 0px 1px 7px',
                                    color: 'var(--accent-color)',
                                  }}
                                >
                                  Cancel
                                </div>
                              </div>
                            ) : (
                              <div className="recipe-name-box-mobile">
                                <div className="recipe-name-mobile">
                                  {recipe.name.includes('_.') ? recipe.name.slice(0, -3) : recipe.name}
                                </div>
                                <RoleAccessibleComponent
                                  permittedRoles={[
                                    'Chef',
                                    'Admin',
                                    'Superadmin',
                                    'Client Admin',
                                    'Dev Team',
                                    'Account Holder',
                                  ]}
                                >
                                  <Dropdown overlay={menu3(recipe)}>
                                    <div>
                                      <img src={add} alt="add-button" className="add-recipe-mobile" />
                                    </div>
                                  </Dropdown>
                                </RoleAccessibleComponent>
                                <RoleAccessibleComponent
                                  permittedRoles={[
                                    'Chef',
                                    'Admin',
                                    'Superadmin',
                                    'Client Admin',
                                    'Dev Team',
                                    'Account Holder',
                                  ]}
                                >
                                  <Dropdown overlay={menu1}>
                                    <div className="more-menu-recipe" alt="see_more">
                                      <MoreIcon />
                                    </div>
                                  </Dropdown>
                                </RoleAccessibleComponent>
                              </div>
                            )}
                          </div>
                        )}

                        {recipe.recipes &&
                          recipe.recipes.map((r, i) => {
                            return (
                              <Draggable key={r?.id.toString()} draggableId={r?.id.toString()} index={i}>
                                {(provided, snapshot) => (
                                  <div>
                                    <div
                                      className="recipe-tile"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div>
                                        <Link to={{ pathname: `/recipes/view/${r.id}`, state: { from: 'recipes' } }}>
                                          <ThumbnailImage
                                            src={
                                              (r?.info.recipeImages || r?.info.recipeImageThumbs) &&
                                              (r?.info.recipeImages?.length || r?.info.recipeImageThumbs?.length) > 0
                                                ? r?.info.recipeImages[0] || r?.info.recipeImageThumbs[0]
                                                : null
                                            }
                                            type={'recipe'}
                                          />
                                        </Link>
                                      </div>
                                      <div className="course-name-box-mobile">
                                        <div className="course-title">
                                          {' '}
                                          <Link to={{ pathname: `/recipes/view/${r.id}`, state: { from: 'recipes' } }}>
                                            {r.name}
                                          </Link>
                                        </div>
                                        <RoleAccessibleComponent
                                          permittedRoles={[
                                            'Chef',
                                            'Admin',
                                            'Superadmin',
                                            'Client Admin',
                                            'Dev Team',
                                            'Account Holder',
                                          ]}
                                        >
                                          <Dropdown overlay={menuRec(r)}>
                                            <div
                                              className="more-menu-2"
                                              alt="see_more"
                                              onMouseOver={() => setRecId(r.id)}
                                            >
                                              <MoreIcon />
                                            </div>
                                          </Dropdown>
                                        </RoleAccessibleComponent>
                                      </div>
                                      <Link to={{ pathname: `/recipes/view/${r.id}`, state: { from: 'recipes' } }}>
                                        <div className="tag-box">
                                          {r?.tags?.length > 0 &&
                                            r?.tags?.map((tag, i) => {
                                              return <div className="tag-rec-mobile">{tag}</div>;
                                            })}
                                        </div>
                                      </Link>
                                      <div className="Line-2-mobile"></div>
                                      <div className="profile-box">
                                        <div className="profile-set">
                                          <div>
                                            <div className="common-user-icon" alt="user-photo">
                                              <UserImage src={r.userImg} showPlaceholder={true} />
                                            </div>
                                          </div>
                                          <AttachmentIndicatorMobile
                                            className="comment-box"
                                            count={r?.selected}
                                            showIfZero={true}
                                            onClick={(e) => showDrawer2(e, r)}
                                          />
                                          {/* <div className="attachment-box">
                                          <img src={attach} className="attach-menu" alt="attachment" />
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {recipe.name === 'New Category' ? (
                          <div></div>
                        ) : (
                          <RoleAccessibleComponent
                            permittedRoles={[
                              'Chef',
                              'Admin',
                              'Superadmin',
                              'Client Admin',
                              'Dev Team',
                              'Account Holder',
                            ]}
                          >
                            <div>
                              <div className="add-recipe" data-value={i} onClick={showDrawer}>
                                Add existing recipe
                              </div>
                              <div className="add-recipe">
                                <Link
                                  to={{
                                    pathname: '/recipes/CreateRecipes',
                                    createProps: { name: 'cat', catId: recipe.categoryId },
                                  }}
                                >
                                  Create new recipe
                                </Link>
                              </div>
                            </div>
                          </RoleAccessibleComponent>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            );
          })}
        </TabsCat>
      </div>
      <Drawer
        width={windowSize >= 842 ? '842px' : '100%'}
        onClose={onClose}
        closable={false}
        visible={visible}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <div id="recipeAddBox" tabindex="0">
          <div className="add-course-box">
            <div>
              <div className="add-course-rec">
                <div onClick={addRecipes}>Add selected recipes</div>
              </div>
            </div>
            {windowSize > 700 ? (
              <div className="close-drawer" onClick={onClose}>
                X <span className="close-text">Close</span>
              </div>
            ) : (
              <div className="close-drawer" onClick={onClose}>
                X
              </div>
            )}
          </div>
          <br />
          <div style={{ textAlign: 'center' }}>{isLoading && <Spin tip="Loading recipes..."></Spin>}</div>
          <div className="add-course-box">
            <div>
              <div>
                <div className="add-ing-text">Add existing recipe</div>
              </div>
            </div>
            <div>
              {' '}
              {showSearch ? (
                <div style={{ position: 'relative' }}>
                  {' '}
                  <input
                    type="text"
                    className="search-bar-recMob"
                    placeholder="Enter search term"
                    onChange={searchRecipe}
                  />
                  <span className="search-icon-recMob">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              ) : (
                <img src={searchy} height="40" alt="completed-task" onClick={() => setShowSearch(true)} />
              )}
            </div>
          </div>
          <div className="rec-all-courses">
            <Dropdown overlay={menuFilter}>
              <div>
                {name}
                <span className="course-inc-icon">
                  <i className="fa fa-chevron-down" aria-hidden="true"></i>
                </span>
              </div>
            </Dropdown>
            {/* FEATURE 1202040063159015 */}
          </div>
          <MultiTagSelect
            //overlay={tagOptions(drawerTags, handleTagAppend, setSearchedTag)}
            className="rec-drawer-tags"
            onChange={setSearchedTag}
            value={searchedTag}
          >
            {drawerTags.map((cat, i) => (
              <Option key={i} value={cat}>
                {cat}
              </Option>
            ))}
          </MultiTagSelect>
          <br />
          {/* <div className="tag-box"> */}
          {/* redundant, BUG 1202030604174459 */}
          {/* drawerTags.map((tag, i) => {
              return (
                <div className="tag-rec-mobile" data-value={tag} onClick={() => handleTagChange(tag, true)}> {/* onClick={filterTags} */}
          {/* tag */}
          {/* </div>
              );
            })} */}
          {/* {drawerTags.map((tag) => (
              <CheckableTag
                key={tag}
                checked={selectedTags.indexOf(tag) > -1}
                onChange={(checked) => handleTagChange(tag, checked)}
              >
                <div
                  className={selectedTags.includes(tag) ? 'selectedtag-rec-mobile' : 'tag-rec-mobile'}
                  data-value={tag}
                  // removed filterTags, BUG 1202030604174459
                >
                  {tag}
                </div>
              </CheckableTag>
            ))}
            <span className="course-see-tags">See more tags</span>
          </div> */}

          <br />
          <div>
            <div className="warpper">
              {activeRecipe &&
                activeRecipe.map((course, i) => {
                  return (
                    <div>
                      <div className="recipe-tile-drawerboxMob">
                        <div
                          className={
                            active.some((item) => item.id === course.id)
                              ? 'recipe-tile-drawerMobSelected'
                              : 'recipe-tile-drawerMob'
                          }
                          key={i}
                          onClick={() => selectRecipe(course)}
                        >
                          <ThumbnailImage src={course.imageUrl} type={'recipe'} />
                          <div className="course-name-box">
                            <div className="course-title">{course.name}</div>
                          </div>
                          <div className="tag-box">
                            {course?.tags?.map((tag, i) => {
                              return <div className="tag-rec-mobile">{tag}</div>;
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={visible2}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentList
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks={docLinks}
          links={links}
          isMobile={true}
        />
      </Drawer>
    </div>
  );
};

export default RecipeCategoryMobile;
