/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useCallback } from 'react';
import CheckListDetailPopup from './CheckListDetailPopup';
import collapseall from '../../img/check-collapse.png';
import {
  Table,
  Drawer,
  Dropdown,
  Menu,
  DatePicker,
  Row,
  Col,
  Popconfirm,
  message,
  TreeSelect,
  notification,
} from 'antd';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { data } from '../../data/checklistdata';
import AddImage from './AddImage';
import ProgressBar from '../../components/progressBar/progressbar';
import { PlusOutlined } from '@ant-design/icons';
import useWindowSize from '../../components/windowdimensions/windowSize';
import ReactDragListView from 'react-drag-listview';
import {
  create,
  getCheckList,
  createListItem,
  getChecklistAssignees,
  destroy,
  destroyChecklist,
  updateItem,
  updateCheckList,
} from '../../services/checklist.service';
import { cloneDeep, find, remove, findIndex, isEmpty } from 'lodash';
import _ from 'lodash';
import moment from 'moment';
import DocumentList from '../../components/DocumentList/DocumentList';
import { searchDocLinks } from '../../services/doc.service';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { KITCHENSTATIONNAME } from '../../components/common/constant.js';
import {
  CompletionIcon,
  AttachmentIndicatorMobile,
  ExpandCollapseIcon,
  UserImage,
  ThumbnailImage,
} from '../../components/common/common_ui';

let userInfo = JSON.parse(localStorage.getItem('operator')) || {};
let userImage = userInfo?.info?.userImageThumb || userInfo?.info?.userImage || '';
let userName = userInfo.firstName + ' ' + userInfo.lastName;

const newChecklistValue = {
  key: '0',
  name: '',
  type: 'parent',
  percentage: 0,
  children: [],
};
const formatItems = (checkLists, parentId, checkListItem) => {
  const totalItems = checkLists.length;
  let totalCompleted = 0;
  for (let i = 0; i < checkLists.length; i++) {
    if (checkLists[i].hasOwnProperty('id')) {
      const {
        id,
        title,
        operatorId,
        eventId,
        stationId,
        info,
        dueDate,
        project,
        priority,
        Station,
        Operator,
        Event,
      } = checkLists[i];
      const assignee = (() => {
        let a = '';
        if (!isEmpty(Station)) {
          a = Station.name;
        }
        if (!isEmpty(Operator)) {
          a = `${Operator.firstName} ${Operator.lastName} `;
        }
        if (!isEmpty(Event)) {
          a = Event.name;
        }
        return a;
      })();
      const parsedInfo = info;
      const formattedDueDate = dueDate && dueDate.trim() !== '' ? moment(dueDate).format('MM/DD/YYYY') : '';
      if (id > 0) {
        const formatted = {
          key: `${parentId}-${id}`,
          id: id,
          name: title,
          type: 'child',
          checked: parsedInfo.checked,
          complete: parsedInfo.complete,
          assignee: assignee,
          assigneeImg: assignee ? (assignee.trim() == userName ? userImage : '') : '',
          due: formattedDueDate,
          texts: 0,
          isEditing: false,
          parentId: parentId,
          stationId,
          operatorId,
          eventId: eventId || 0,
          attachments: 0,
          notes: 1,
          selected: 0,
          project,
          tags: parsedInfo.tags,
          priority,
          details: parsedInfo.details,
          description: parsedInfo.description,
          info: parsedInfo,
        };
        checkListItem.push(formatted);
        console.log('parsedInfo complete', parsedInfo.complete);
        if (parsedInfo.complete === 2) {
          totalCompleted++;
        }
      }
    }
  }
  const percentage = (() => {
    let perc = 0;
    if (totalItems > 0) {
      perc = (totalCompleted / totalItems) * 100;
    }
    return Math.round(perc);
  })();
  return percentage;
};

const ChecklistMobile = () => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [visible, updateVisible] = useState(false);
  const [visible1, updateVisible1] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [pristineData, setPristineData] = useState([]);
  const [restaurant, setRestaurant] = useState('');
  const [initialdataSource, setInitialSource] = useState([]);
  const [taskDelete, setDeleteTask] = useState({});
  const [checklistDelete, setDeleteChecklist] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [checklistid, setChecklistId] = useState('');
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [addTask, setAddTask] = useState(false);
  const [isDown, setIsDown] = useState(false);
  const [dragParent, setDragParent] = useState(false);
  const [getCatId, setGetCatId] = useState({});
  const [assignees, setAssignees] = useState([]);
  const [operator, setOperator] = useState(0);
  const [origData, setOrigData] = useState([]);
  const [incTasks, setIncTasks] = useState('Incomplete Tasks');
  const [chkId, setChkId] = useState('');
  const [docLinks, setDocLinks] = useState({});
  const [links, setLinks] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  const [editingItem, setEditingItem] = useState(false);
  const [itemEdited, setItemEdited] = useState({});
  const [getAssignee, setGetAssignee] = useState({});
  const [getPriority, setGetPriority] = useState('');
  const [isCanceled, setCancel] = useState(false);
  const [dueDates, setDueDates] = useState('');
  const [tagArr, setTagArr] = useState([]);
  const [getProj, setGetProj] = useState('');
  const [editItem, setEditItem] = useState(false);
  const [editItemId, setEditItemId] = useState('');
  const [selectPriority, setSelectPriority] = useState(false);
  const [socketData, setSocketData] = useState([]);
  const [checklistUpdate, setChecklistUpdate] = useState([]);
  const [checklistAdd, setChecklistAdd] = useState([]);
  const [checklistsDelete, setChecklistDelete] = useState('');
  const [itemAdd, setItemAdd] = useState({});
  const [itemUpdate, setItemUpdate] = useState({});
  const [itemCheckUpdate, setItemCheckUpdate] = useState(0);
  const [itemDelete, setItemDelete] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/checklist`));
  const [isFiltering, setIsFiltering] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const user_id = (JSON.parse(localStorage.getItem('operator')) || {}).id;

  useEffect(() => {
    const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
    const user_email = (JSON.parse(localStorage.getItem('operator')) || {}).email;

    setOperator(parseInt(user_id));

    // let storedChecklists = JSON.parse(localStorage.getItem('checklistOrder'));
    if (hasChanged && pristineData.length) {
      const clonedData = cloneDeep(pristineData);
      if (
        localStorage.getItem('checklistOrder') != undefined &&
        JSON.parse(localStorage.getItem('checklistOrder')).length
      ) {
        const checklistOrder = JSON.parse(localStorage.getItem('checklistOrder'));
        const tableOrdered = annotateTableOrder(clonedData, checklistOrder);
        setDataSource(tableOrdered);
        setInitialSource(tableOrdered);
      } else {
        setDataSource(clonedData);
        setInitialSource(clonedData);
      }
    } else {
      getCheckList({ restaurantId: resto_id, user_id, email: user_email }).then((res) => {
        let checkList = [];

        for (let i = 0; i < res.checklists.length; i++) {
          let percent = 0;
          const { id, title, ChecklistItems, info } = res.checklists[i];
          let checkListItems = JSON.parse(ChecklistItems);
          let checklistinfo = JSON.parse(info);
          let checkListRows = [
            {
              // always on the first row
              key: `${id}-0`,
              type: 'add-image',
              parentId: id,
              imageUrl: checklistinfo && checklistinfo?.imageUrl ? checklistinfo?.imageUrl : ''
            },
          ];

          checkListItems.sort((x, y) => {
            return x.id - y.id;
          });

          // merge array when there's an
          if (checkListItems.length > 0) {
            percent = formatItems(checkListItems, id, checkListRows);
          }

          checkListRows.push({
            // always on the last row
            key: `${id}-9999999`,
            type: 'add-button',
            parentId: id
          });

          checkList.push({
            key: `${id}`,
            name: title,
            type: 'parent',
            percentage: percent,
            children: checkListRows
          });
        }

        let getLinks = checkList
          .filter((e) => e.children || [])
          .map((e) => (e.children || []).map((link) => link.id))
          .reduce((a, b) => a.concat(b), [])
          .filter((l) => l !== undefined);

        let stringList = getLinks.join(', ');

        let params = {
          type: 'tasks',
          linkId: stringList,
        };
        searchDocLinks(params).then((res) => {
          console.log(res);
          if (res && res.length > 0) {
            let links = res;
            let removeDeleted = links.filter((l) => l.Document.folderId !== null);
            const list = Array.from(
              removeDeleted.reduce(
                (m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]),
                new Map()
              ),
              ([linkId, documentId]) => ({ linkId, documentId })
            );

            console.log(list);

            let addSelectedLinks = checkList.map((o) => {
              (o.children || []).map((c) => {
                list.map((d) => {
                  if (d.linkId == c.id) {
                    c.selected = d.documentId.length;
                  }
                  return d;
                });
                return c;
              });
              return o;
            });

            console.log(addSelectedLinks);

            if (
              localStorage.getItem('checklistOrder') != undefined &&
              JSON.parse(localStorage.getItem('checklistOrder')).length
            ) {
              const checklistOrder = JSON.parse(localStorage.getItem('checklistOrder'));
              const tableOrdered = annotateTableOrder(addSelectedLinks, checklistOrder);
              setDataSource(tableOrdered);
              setInitialSource(tableOrdered);

            } else if (
              localStorage.getItem('checklistColKeyOrder') != undefined
            ) {
              let nameOrder = localStorage.getItem('checklistColKeyOrder');
              if (nameOrder && nameOrder.length) {
                // if (nameOrder == 'ascend') {
                // addSelectedLinks.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                const tableOrdered = applyColumnOrder('name', nameOrder, addSelectedLinks);
                setDataSource(tableOrdered);
                setInitialSource(tableOrdered);
                // } else {
                //   addSelectedLinks.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
                //   setDataSource(addSelectedLinks);
                //   setInitialSource(addSelectedLinks);
                // }
              }
            } else {
              setDataSource(addSelectedLinks);
              setInitialSource(addSelectedLinks);
            }

            setPristineData(addSelectedLinks);
            setSocketData(addSelectedLinks);

            if (editItem) {
              setEditItem(false);
              let openEdit = addSelectedLinks.reduce(
                (prev, item) => prev || item.children.find((item) => item.id == editItemId),
                undefined
              );
              console.log('OPENEDIT', openEdit);
              setEditingItem(true);
              setItemEdited(openEdit);
              const clonedChecklist = cloneDeep(checkList);
              const foundIndex = findIndex(clonedChecklist, { key: `${openEdit.parentId}` });
              if (clonedChecklist[foundIndex]) {
                const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: openEdit.key });
                clonedChecklist[foundIndex].children[foundChildIndex].isEditing = true;
              }
              setDataSource(clonedChecklist);
            }
          } else {
            if (
              localStorage.getItem('checklistOrder') != undefined &&
              JSON.parse(localStorage.getItem('checklistOrder')).length
            ) {
              const checklistOrder = JSON.parse(localStorage.getItem('checklistOrder'));
              const tableOrdered = annotateTableOrder(checkList, checklistOrder);
              setDataSource(tableOrdered);
              setInitialSource(tableOrdered);

            } else if (
              localStorage.getItem('checklistColKeyOrder') != undefined
            ) {
              let nameOrder = localStorage.getItem('checklistColKeyOrder');
              if (nameOrder && nameOrder.length) {
                // if (nameOrder == 'ascend') {
                // checkList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                const tableOrdered = applyColumnOrder('name', nameOrder, checkList);
                setDataSource(tableOrdered);
                setInitialSource(tableOrdered);
                // } else {
                //   checkList.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
                //   setDataSource(checkList);
                //   setInitialSource(checkList);
                // }
              }
            } else {
              setDataSource(checkList);
              setInitialSource(checkList);
            }

            setPristineData(checkList);
            setSocketData(checkList);

            if (editItem) {
              setEditItem(false);
              let openEdit1 = checkList.reduce(
                (prev, item) => prev || item.children.find((item) => item.id == editItemId),
                undefined
              );
              console.log('OPENEDIT!', openEdit1);
              setEditingItem(true);
              setItemEdited(openEdit1);
              const clonedChecklist = cloneDeep(checkList);
              const foundIndex = findIndex(clonedChecklist, { key: `${openEdit1.parentId}` });
              if (clonedChecklist[foundIndex]) {
                const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: openEdit1.key });
                clonedChecklist[foundIndex].children[foundChildIndex].isEditing = true;
              }
              setDataSource(clonedChecklist);
            }
          }
        });

        // setDataSource(checkList);
        // setInitialSource(checkList);
        // setPristineData(checkList);
        // setOrigData(checkList);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged]);

  const applyColumnOrder = (column, status, list = [], viewOnly = false) => {
    let rows = list.length ? list : cloneDeep(pristineData);
    let tableRows = rows.filter((r) => r.key !== '0');
    let toAddFolders = rows.filter((r) => r.key === '0');
    let orderedRows = [];

    const arrangeRows = (rows) => {
      const rowsToSort = rows.filter((c) => c.type !== 'add-image' && c.type !== 'add-button');
      const addTaskRow = rows.filter((c) => c.type === 'add-button');
      const addImageRow = rows.filter((c) => c.type === 'add-image');
      return {
        firstRow: addImageRow[0],
        rowsToSort: rowsToSort,
        lastRow: addTaskRow[0]
      }
    }

    if (tableRows.length) {
      if (tableRows.length > 1) {
        if (column === 'name') { // sorts parent rows and child rows, except for image row and add button row
          if (status === 'ascend') {
            orderedRows = tableRows.map((p) => {
              if (p.children && p.children.length > 3) {
                const { firstRow, rowsToSort, lastRow } = arrangeRows(p.children);
                let sortedChildren = [];
                if (rowsToSort.length > 1) {
                  sortedChildren = rowsToSort.sort((a, b) => a[column].toLowerCase().localeCompare(b[column].toLowerCase()));
                } else if (rowsToSort.length === 1) {
                  sortedChildren.push(rowsToSort[0]);
                }
                if (firstRow) { // image row always on top
                  sortedChildren.unshift(firstRow);
                }
                if (!viewOnly && lastRow) { // add task row always on the bottom
                  sortedChildren.push(lastRow);
                }
                p.children = [ ...sortedChildren ];
              }
              return p;
            }).sort((a, b) => a[column].toLowerCase().localeCompare(b[column].toLowerCase())); // also sort checklist folder parents based on ANTD table sort settings
          } else {
            orderedRows = tableRows.map((p) => {
              if (p.children && p.children.length > 3) {
                const { firstRow, rowsToSort, lastRow } = arrangeRows(p.children);
                let sortedChildren = [];
                if (rowsToSort.length > 1) {
                  sortedChildren = rowsToSort.sort((a, b) => b[column].toLowerCase().localeCompare(a[column].toLowerCase()));
                } else if (rowsToSort.length === 1) {
                  sortedChildren.push(rowsToSort[0]);
                }
                if (firstRow) {
                  sortedChildren.unshift(firstRow);
                }
                if (!viewOnly && lastRow) {
                  sortedChildren.push(lastRow);
                }
                p.children = [ ...sortedChildren ];
              }
              return p;
            }).sort((a, b) => b[column].toLowerCase().localeCompare(a[column].toLowerCase()));
          }
        }
      } else {
        orderedRows = tableRows;
      }
      if (!viewOnly && toAddFolders.length) { // no sorting but always on top
        const preserveOpenAddRows = toAddFolders.sort(
          (a, b) => a.key.localeCompare(b.key)
        );
        orderedRows.splice(1, 0, ...preserveOpenAddRows);
      }
    }
    console.log(orderedRows);
    return orderedRows;
  };

  // cacheTableOrder - caches and annotates position of each parent and child row of current table order (index-based position)
  const cacheTableOrder = (data) => {
    let orderCached = [];
    let tableOrdered = [];
    if (data.length > 0) {
      tableOrdered = data.map((d, i) => { // for checklist categories
        const parentRow = {};
        parentRow.key = d.key;
        if (d?.order == undefined || d.order !== i) {
          parentRow.order = i; // caches order
          d.order = i; // annotates order
        } else {
          parentRow.order = d.order;
        }
        parentRow.children = [];
        if (d.children && d.children.length) {
          d.children = d.children.map((c, j) => { // for checklists
            const childRow = {};
            childRow.key = c.key;
            if (c?.order == undefined || c.order !== j) {
              childRow.order = j; // caches order
              c.order = j; // annotates order
            } else {
              childRow.order = c.order;
            }
            parentRow.children.push(childRow);
            return c;
          });
          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        orderCached.push(parentRow);
        return d;
      });
      if (data.length > 1) {
        tableOrdered = tableOrdered.sort((a, b) => a.order - b.order);
      }
    }
    return {
      checklistOrder: orderCached, // cached table order
      orderedChecklist: tableOrdered // annotated table rows
    };
  }

  // annotateTableOrder - annotates new/current/updated order for each parent and child record of table (index-based position)
  // callback when localStorage variable 'checklistOrder' exists, then applies to fetched data
  const annotateTableOrder = (data, checklistOrder, viewOnly = false) => {
    let orderedTable = [];
    if (data.length > 0) {
      const tableAnnotated = data.map((d) => {
        // annotate checklist folder cached order
        const folderIndex = findIndex(checklistOrder, (r) => r.key === d.key);
        d.order = checklistOrder[folderIndex].order;
        if (d.children && d.children.length) {
          d.children = d.children.map((c, i) => {
            // annotate checklist cached order
            const checklistIndex = findIndex(checklistOrder[folderIndex].children, (h) => h.key === c.key);
            
            if (checklistOrder[folderIndex].children[checklistIndex] != undefined) {
              const cachedOrder = checklistOrder[folderIndex]?.children[checklistIndex].order;
              c.order = (
                cachedOrder != undefined &&
                (
                  !viewOnly && cachedOrder !== i
                )
              ) ? cachedOrder : i;
            } else {
              c.order = i;
            }
            
            return c;
          });

          if (viewOnly) {
            d.children = d.children.filter((a) => a.type !== 'add-button');
          }

          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        return d;
      });

      orderedTable = tableAnnotated;

      if (viewOnly) { // removes open add tasks
        orderedTable = tableAnnotated.filter((a) => a.key !== '0');
      }

      if (data.length > 1) { // removes open add checklists
        orderedTable = tableAnnotated.sort((a, b) => a.order - b.order);
      }
    }
    return orderedTable;
  }

  // deannotateTableOrder - removes index order for each parent and child record
  const deannotateTableOrder = (data) => {
    if (data.length > 0) {
      // filters out open add checklist folder rows
      let orderRemoved = data;
      
      if (orderRemoved.length) {
        orderRemoved = orderRemoved.map((d) => {
          if (d?.order != undefined) {
            delete d['order'];
          }
          if (d.children && d.children.length) {
            d.children = d.children.map((e) => { 
              if (e?.order != undefined) {
                delete e['order'];
              }
              return e;
            });
            if (d.children.length > 2) {
              d.children = d.children.sort((a, b) => a.key.localeCompare(b.key));
            }
          }
          return d;
        });
        if (orderRemoved.length > 1) {
          orderRemoved = orderRemoved.sort((a, b) => a.key * 1 - b.key * 1);
        }
        return orderRemoved;
      }
    }
    return data;
  }

  useEffect(() => {
    const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;

    getChecklistAssignees({ id: resto_id }).then((res) => {
      //setAssignees(res);
      if (res) {
        let groupedAssignees = res
          .map((item) => item.type)
          .filter((item, i, ar) => ar.indexOf(item) === i)
          .sort((a, b) => a - b)
          .map((item) => {
            let new_list = res
              .filter((itm) => itm.type == item)
              .map((itm) => {
                return { name: itm.name, id: itm.id };
              });
            return {
              type: item,
              category: item == 'operator' ? 'Resource' : item == 'event' ? 'Event station' : KITCHENSTATIONNAME,
              children: new_list,
            };
          });

        if (groupedAssignees && groupedAssignees.length > 0) {
          groupedAssignees.sort((a, b) => a.category.localeCompare(b.category));
        }
        console.log('GROUPED!!', groupedAssignees);
        let grouped1 = groupedAssignees.map((a) => {
          return {
            category: a.category,
            title: a.category,
            value: a.category,
            type: a.type,
            children: a.children.map((c) => {
              return {
                title: c.name,
                value: JSON.stringify({ name: c.name, id: c.id, type: a.type }),
                name: c.name,
                id: c.id,
              };
            }),
          };
        });
        console.log('GROUPEDTREE!!', grouped1);
        setAssignees(grouped1);
      }
    });
  }, []);

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    setRestaurant(resto.name);
  }, []);

  useEffect(() => {
    let getOpenRows = JSON.parse(localStorage.getItem('checklistOpenRows'));
    if (getOpenRows && getOpenRows.length > 0) {
      setExpandedRows(getOpenRows);
    } else {
      setExpandedRows([]);
    }
  }, []);

  // useEffect(() => {
  //   console.log(chkId);
  //   console.log(checklistid);
  // }, [chkId, checklistid]);

  useEffect(() => {
    const listener = (event) => {
      if (!editingItem && addTask && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        // const assigneeVals = getAssignee;
        console.log('ADD!! Enter key was pressed. Run add.');
        event.preventDefault();
        console.log(itemEdited);
        let editing = itemEdited;
        editing.project = getProj;
        editing.tags = tagArr;
        editing.due = dueDates;
        editing.assignee = Object.keys(getAssignee).length === 0 ? '' : getAssignee.assignee;
        editing.operatorId = Object.keys(getAssignee).length === 0 ? 0 : getAssignee.operatorId;
        editing.stationId = Object.keys(getAssignee).length === 0 ? 0 : getAssignee.stationId;
        editing.eventId = Object.keys(getAssignee).length === 0 ? 0 : getAssignee.eventId;
        editing.priority = getPriority && getPriority !== '' ? getPriority : itemEdited.priority;
        console.log(editing);
        handleAddChecklistItem(editing);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemEdited, addTask, getAssignee, getPriority, dueDates, tagArr, getProj]);

  useEffect(() => {
    const listener = (event) => {
      if (!addTask && editingItem && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        console.log('EDIT!! Enter key was pressed. Run edit');
        event.preventDefault();
        console.log(itemEdited);
        let editing = itemEdited;
        editing.project = getProj;
        editing.tags = tagArr;
        editing.due = dueDates;
        editing.assignee = getAssignee.assignee;
        editing.operatorId = getAssignee.operatorId;
        editing.stationId = getAssignee.stationId;
        editing.eventId = getAssignee.eventId;
        editing.priority = getPriority && getPriority !== '' ? getPriority : itemEdited.priority;
        console.log(editing);
        handleUpdateChecklistItem(editing, 'enter');
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem, getAssignee, getPriority, getPriority, dueDates, tagArr, getProj]);

  useEffect(() => {
    const container = document.getElementById('clickbox');

    const listener = (event) => {
      if (container && container !== event.target && !container.contains(event.target)) {
        if (selectPriority) {
          console.log('EDIT!! Tap away. Run edit');
          console.log(itemEdited);
          let editing = itemEdited;
          editing.project = getProj;
          editing.tags = tagArr;
          editing.due = dueDates;
          editing.assignee = getAssignee;
          editing.priority = getPriority && getPriority !== '' ? getPriority : itemEdited.priority;
          console.log(editing);
          setSelectPriority(false);
          handleUpdateChecklistItem(editing, 'enter');
        }
      }
    };

    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPriority]);

  const windowSize = useWindowSize();

  const changePriorityAnt = (obj, info) => {
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
    if (clonedChecklist[foundIndex]) {
      const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
      clonedChecklist[foundIndex].children[foundChildIndex].priority = info.item.props.value;
    }
    setDataSource(clonedChecklist);
    setGetPriority(info.item.props.value);
    setSelectPriority(true);
  };

  const priorityOptions = (obj) => (
    <Menu className="menu-show-preview">
      {[{ label: 'Low' }, { label: 'Medium' }, { label: 'High' }].map((m, i) => {
        return (
          <Menu.Item
            className="menu-show-preview"
            key={`priority-options-${i}`}
            value={m.label}
            onClick={(info) => {
              changePriorityAnt(obj, info);
            }}
          >
            <span className="priority-label-span">{m.label}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const showDrawer = () => {
    updateVisible(true);
  };

  const onClose = () => {
    updateVisible(false);
  };

  const deleteTask = (checklist) => {
    console.log(checklist);
    console.log(taskDelete);
    // const id = checklist.id;
    const id = taskDelete.id;
    console.log('Deletethis ', id);
    setItemEdited({});
    // setHasChanged(false);
    destroy(id).then((res) => {
      eventSocket.emit(
        'deleteChecklistItem',
        JSON.stringify({
          id: id,
        })
      );
      console.log('Ress', res);
      // setHasChanged(false);
      // localStorage.removeItem('checklistOrder');
      setItemEdited({});
      // notification.open({
      //   message: 'Item Successfully deleted!',
      // });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    });
  };

  const deleteChecklist = (checkListInfo) => {
    console.log(checkListInfo);
    console.log(checklistDelete.key);
    if (checkListInfo.key * 1 > 0) {
      // setHasChanged(false);
      destroyChecklist(checklistDelete.key).then((res) => {
        console.log('Resss', res);
        // localStorage.removeItem('checklistOrder');
        // notification.open({
        //   message: 'Checklist Successfully deleted!',
        // });
        eventSocket.emit(
          'deleteChecklist',
          JSON.stringify({
            id: checkListInfo.key,
          })
        );
        // setHasChanged(false);
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      const clonedList = cloneDeep(dataSource);
      remove(clonedList, (cl) => cl.key === '0');
      console.log(clonedList);
      setDataSource(clonedList);
      setItemEdited({});
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const duplicateTask = (checklist) => {
    console.log('DUPLICATE TASK');
    handleAddChecklistItem(checklist);
  };

  const handleAddChecklist = (checkListInfo) => {
    // setHasChanged(false);
    setIsAdding(false);
    const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
    if (checkListInfo.name.trim() !== '') {
      const { name } = checkListInfo;
      create({ title: name.trim(), restaurantId: resto_id }).then((res) => {
        // setHasChanged(false);
        // localStorage.removeItem('checklistOrder');
        eventSocket.emit(
          'addChecklist',
          JSON.stringify({
            children: [
              { imageUrl: '', key: `${res?.id}-0`, parentId: res?.id, type: 'add-image' },
              { key: `${res?.id}-9999999`, type: 'add-button', parentId: res?.id },
            ],
            key: `${res?.id}`,
            name: res?.title,
            percentage: 0,
            type: 'parent',
          })
        );
      });
    } else {
      create({ title: 'Untitled', restaurantId: resto_id }).then((res) => {
        // setHasChanged(false);
        // localStorage.removeItem('checklistOrder');
        eventSocket.emit(
          'addChecklist',
          JSON.stringify({
            children: [
              { imageUrl: '', key: `${res?.id}-0`, parentId: res?.id, type: 'add-image' },
              { key: `${res?.id}-9999999`, type: 'add-button', parentId: res?.id },
            ],
            key: `${res?.id}`,
            name: res?.title,
            percentage: 0,
            type: 'parent',
          })
        );
      });
    }
  };

  const handleAddChecklist2 = (e, checkListInfo) => {
    e.preventDefault();
    // setHasChanged(false);
    setIsAdding(false);
    const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
    if (checkListInfo.name.trim() !== '') {
      const { name } = checkListInfo;
      create({ title: name.trim(), restaurantId: resto_id }).then((res) => {
        // setHasChanged(false);
        // localStorage.removeItem('checklistOrder');
        eventSocket.emit(
          'addChecklist',
          JSON.stringify({
            children: [
              { imageUrl: '', key: `${res?.id}-0`, parentId: res?.id, type: 'add-image' },
              { key: `${res?.id}-9999999`, type: 'add-button', parentId: res?.id },
            ],
            key: `${res?.id}`,
            name: res?.title,
            percentage: 0,
            type: 'parent',
          })
        );
      });
    } else {
      create({ title: 'Untitled', restaurantId: resto_id }).then((res) => {
        // setHasChanged(false);
        // localStorage.removeItem('checklistOrder');
        eventSocket.emit(
          'addChecklist',
          JSON.stringify({
            children: [
              { imageUrl: '', key: `${res?.id}-0`, parentId: res?.id, type: 'add-image' },
              { key: `${res?.id}-9999999`, type: 'add-button', parentId: res?.id },
            ],
            key: `${res?.id}`,
            name: res?.title,
            percentage: 0,
            type: 'parent',
          })
        );
      });
    }
  };

  // const handleAddTags = (value, obj, type) => {
  //   if (type == 'edit') {
  //     let editing = itemEdited;
  //     editing.tags.push(value);
  //     console.log('PUSHEDTAGS', editing.tags);
  //     setTagArr(editing.tags);
  //   }

  //   const clonedChecklist = cloneDeep(dataSource);
  //   const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
  //   if (clonedChecklist[foundIndex]) {
  //     const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
  //     clonedChecklist[foundIndex].children[foundChildIndex].tags.push(value);
  //   }
  //   setDataSource(clonedChecklist);
  //   setOpenDatePicker(false);
  // };

  // const handleRemoveTags = (value, obj) => {
  //   let editing = itemEdited;
  //   let filteredTags = editing.tags.filter((t) => t !== value);
  //   setTagArr(filteredTags);
  //   const clonedChecklist = cloneDeep(dataSource);
  //   const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
  //   if (clonedChecklist[foundIndex]) {
  //     const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
  //     remove(clonedChecklist[foundIndex].children[foundChildIndex].tags, (tag) => {
  //       return tag === value;
  //     });
  //   }
  //   setDataSource(clonedChecklist);
  //   setOpenDatePicker(false);
  // };

  const handleProjectChange = (value, obj) => {
    setGetProj(value);
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
    if (clonedChecklist[foundIndex]) {
      const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
      clonedChecklist[foundIndex].children[foundChildIndex].project = value;
    }
    setDataSource(clonedChecklist);
    setOpenDatePicker(false);
  };

  const renameChecklist = (e, checkList) => {
    e.preventDefault();
    setHasChanged(false);
    console.log('RENAME THIS CHKLISt', checkList.key);
    setChecklistId(checkList.key);
    setIsEditing(true);
  };

  const renameChecklist2 = (checkList) => {
    setHasChanged(false);
    console.log('RENAME THIS CHKLISt', checkList.key);
    setChecklistId(checkList.key);
    setIsEditing(true);
  };

  const toggleClass = (e, obj) => {
    setDragParent(true);
    setIsDown(!isDown);
  };

  const setCompleteTask = (obj, isComplete) => {
    const taskKey = obj.key.split('-');
    const id = taskKey[1];
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
    if (clonedChecklist[foundIndex]) {
      const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
      const checkItem = clonedChecklist[foundIndex].children[foundChildIndex];
      const iteminfo = {
        checked: checkItem.complete !== 2 ? checkItem.checked : !checkItem.checked,
        complete: checkItem.complete !== 2 ? 2 : 1,
        description: checkItem.description,
        details: checkItem.details,
        tags: checkItem.tags,
      };
      // setHasChanged(false);
      eventSocket.emit(
        'updateStationTask',
        JSON.stringify({
          id: id,
        })
      );
      eventSocket.emit(
        'updatesChecklistItem',
        JSON.stringify({
          assignee: checkItem.assignee ? checkItem.assignee : '',
          assigneeImg: '',
          attachments: 0,
          checked: checkItem.complete !== 2 ? checkItem.checked : !checkItem.checked,
          complete: checkItem.complete !== 2 ? 2 : 1,
          details: checkItem.details,
          due: obj.due && obj.due !== '' ? obj.due : '',
          eventId: obj.eventId && obj.eventId !== 0 ? obj.eventId : 0,
          description: checkItem.description,
          id: parseInt(id),
          isEditing: false,
          key: `${obj.parentId}-${id}`,
          name: obj.name,
          notes: 1,
          operatorId: obj.operatorId && obj.operatorId !== 0 ? obj.operatorId : 0,
          parentId: obj.parentId,
          priority: obj.priority,
          project: obj.project,
          selected: 0,
          stationId: obj.stationId && obj.stationId !== 0 ? obj.stationId : 0,
          tags: checkItem.tags,
          texts: 0,
          type: 'child',
        })
      );
      updateItem(id, { info: iteminfo }).then((res) => {
        // notification.open({
        //   message: 'Successfully Updated Task!',
        // });
        // setHasChanged(false);
        // localStorage.removeItem('checklistOrder');
      });
    }
  };

  const filterChecklist = (e) => {
    setIsFiltering(false);
    let inputValue = e.target.value;

    const clonedData = _.cloneDeep(pristineData);

    if (inputValue.length) {
      setIsSearching(true);
      let searchString = inputValue.toLowerCase();

      let results = clonedData.filter(searchAllArr(inputValue));

      const searchTags = results.map((item) => ({
        ...item,
        children: item.children?.filter(
          (child) =>
            child.tags?.find((a) => a.includes(searchString)) ||
            child.name?.toLowerCase().indexOf(searchString) !== -1 ||
            child.assignee?.toLowerCase().indexOf(searchString) !== -1 ||
            child.project?.toLowerCase().indexOf(searchString) !== -1 ||
            child.priority?.toLowerCase().indexOf(searchString) !== -1
        ),
      }));

      // console.log(searchTags);
      if (
        localStorage.getItem('checklistOrder') != undefined &&
        JSON.parse(localStorage.getItem('checklistOrder')).length
      ) {
        const checklistOrder = JSON.parse(localStorage.getItem('checklistOrder'));
        const tableOrdered = annotateTableOrder(searchTags, checklistOrder, true);
        setDataSource(tableOrdered);
      } else if (
        localStorage.getItem('checklistColKeyOrder') != undefined
      ) {
        const nameOrder = localStorage.getItem('checklistColKeyOrder');
        // if (nameOrder === 'ascend') {
        // searchTags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        const resultsOrdered = applyColumnOrder('name', nameOrder, searchTags, true);
        setDataSource(resultsOrdered);
        // } else {
        //   searchTags = applyColumnOrder('name', nameOrder, searchTags, true);
        //   setDataSource(searchTags);
        // }
      } else {
        setDataSource(searchTags);
      }
    } else {
      setIsSearching(false);
      if (
        localStorage.getItem('checklistOrder') != undefined &&
        JSON.parse(localStorage.getItem('checklistOrder')).length
      ) {
        const checklistOrder = JSON.parse(localStorage.getItem('checklistOrder'));
        const tableOrdered = annotateTableOrder(clonedData, checklistOrder, false);
        setDataSource(tableOrdered);
      } else if (
        localStorage.getItem('checklistColKeyOrder') != undefined
      ) {
        const nameOrder = localStorage.getItem('checklistColKeyOrder');
        // if (nameOrder === 'ascend') {
        // clonedData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        const resultsOrdered = applyColumnOrder('name', nameOrder, clonedData);
        setDataSource(resultsOrdered);
        // } else {
        //   clonedData.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
        //   setDataSource(clonedData);
        // }
      } else {
        setDataSource(clonedData);
      }
    }
  };

  const searchAllArr = (text) => (value) => {
    if (!value) return false;
    const valueType = typeof value;

    if (valueType === 'string') {
      return value.toLowerCase().indexOf(text.toLowerCase()) > -1;
    }
    if (Array.isArray(value)) {
      return value.some(searchAllArr(text));
    }
    if (valueType === 'object') {
      return Object.values(value).some(searchAllArr(text));
    }
    return false;
  };

  const setId = (e, obj) => {
    setChkId(obj.parentId);
  };

  const handleDrop = useCallback((data, obj) => {
    if (data !== 'none') {
      let params = {
        info: { imageUrl: data },
      };
      updateCheckList(obj.parentId, params).then((res) => {
        // notification.open({
        //   message: 'Successfully Uploaded Image!',
        // });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setHasChanged(false);
        // localStorage.removeItem('checklistOrder');
      });
    } else {
      notification.open({
        message: 'Image not uploaded, please try again',
      });
    }
  }, []);

  const addInputChecklistItem = (obj) => {
    setCancel(false);
    // setHasChanged(false);
    setItemEdited({});
    setEditingItem(false);
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
    if (clonedChecklist[foundIndex]) {
      const checkItem = clonedChecklist[foundIndex].children;
      // const tempItem = clonedChecklist[foundIndex].children[checkItem.length - 1];
      clonedChecklist[foundIndex].children[checkItem.length - 1] = {
        key: `${obj.parentId}-9999998`,
        name: '',
        type: 'child',
        checked: false,
        complete: 1,
        assignee: '',
        due: '',
        texts: 0,
        stationId: 0,
        operatorId: 0,
        eventId: 0,
        parentId: obj.parentId,
        attachments: 0,
        project: '',
        selected: 0,
        tags: [],
        priority: <span className="select-prio-chk">Select Priority</span>,
        details: false,
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      };
      setAddTask(true);
      setDataSource(clonedChecklist);
    }
  };

  const handleEditItem = (obj) => {
    setGetAssignee({});
    setEditingItem(true);
    setItemEdited(obj);
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
    if (clonedChecklist[foundIndex]) {
      const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
      clonedChecklist[foundIndex].children[foundChildIndex].isEditing = true;
      setDataSource(clonedChecklist);
    }
  };

  const handleRemovePrio = (obj) => {
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
    if (clonedChecklist[foundIndex]) {
      const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
      clonedChecklist[foundIndex].children[foundChildIndex].priority = '';
      setDataSource(clonedChecklist);
    }
    setGetPriority('');
    setSelectPriority(true);
    let editing = itemEdited;
    editing.priority = '';
    setItemEdited(editing);
  };

  const handleDateChange = (date, obj) => {
    setDueDates(date.format('MM/DD/YYYY'));
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
    if (clonedChecklist[foundIndex]) {
      const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: `${obj.parentId}-9999998` });
      if (obj.key.split('-')[1] === '9999998') {
        clonedChecklist[foundIndex].children[foundChildIndex].due = date.format('MM/DD/YYYY');
      }
    } else {
      if (clonedChecklist[foundIndex]) {
        const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
        clonedChecklist[foundIndex].children[foundChildIndex].due = date.format('MM/DD/YYYY');
      }
    }
    setDataSource(clonedChecklist);
    setOpenDatePicker(false);
  };

  const handleUpdateChecklistItem = (obj, type) => {
    console.log('Checklist itemsss', obj);
    setSelectPriority(false);
    let newObj = dataSource.reduce((prev, p) => prev || p.children.find((item) => item.id == obj.id), undefined);
    console.log(newObj);
    const {
      parentId,
      checked,
      complete,
      description,
      details,
      due,
      priority,
      project,
      tags,
      operatorId,
      stationId,
      eventId,
      assignee,
    } = newObj;
    const taskKey = obj.key.split('-');
    const id = taskKey[1];

    const iteminfo = {
      checked,
      complete,
      description,
      details,
      tags,
    };

    const listItemInfo = {
      checklistId: parentId,
      title: obj.name && obj.name.trim() !== '' ? obj.name.trim() : 'Untitled',
      dueDate: due && due !== '' ? due : '',
      operatorId: operatorId ? operatorId : 0,
      stationId: stationId ? stationId : 0,
      eventId: eventId ? eventId : 0,
      info: iteminfo,
      project: project,
      priority: priority,
      assignee: assignee,
    };

    // if (!assignee) {
    //   notification.open({
    //     message: 'Assignee is required',
    //   });
    //   return;
    // }
    // setHasChanged(false);
    eventSocket.emit(
      'updateStationTask',
      JSON.stringify({
        id: id,
      })
    );
    eventSocket.emit(
      'updatesChecklistItem',
      JSON.stringify({
        assignee: assignee ? assignee : '',
        assigneeImg: '',
        attachments: 0,
        checked: checked,
        complete: complete,
        details: details,
        due: due && due !== '' ? due : '',
        eventId: eventId,
        id: parseInt(id),
        isEditing: false,
        key: `${listItemInfo.checklistId}-${id}`,
        name: listItemInfo.title,
        notes: 1,
        operatorId: assignee.operatorId && assignee.operatorId !== 0 ? assignee.operatorId : null,
        parentId: listItemInfo.checklistId,
        priority: listItemInfo.priority,
        project: listItemInfo.project,
        selected: 0,
        stationId: stationId,
        tags: tags && tags.length !== 0 ? tags : [],
        texts: 0,
        type: 'child',
      })
    );
    updateItem(id, listItemInfo).then((res) => {
      if (type == 'blur') {
        setEditItemId(id);
        setEditItem(false);
        // notification.open({
        //   message: 'Successfully Updated the Item!',
        // });
        // setHasChanged(false);
        // localStorage.removeItem('checklistOrder');
        setEditingItem(false);
        setItemEdited({});
      } else {
        // notification.open({
        //   message: 'Successfully Updated the Item!',
        // });
        setEditItem(false);
        // setHasChanged(false);
        setEditingItem(false);
        setItemEdited({});
      }
    });
  };

  const handleAddChecklistItem = (checklist) => {
    const {
      parentId,
      checked,
      complete,
      description,
      details,
      due,
      priority,
      project,
      tags,
      name,
      operatorId,
      stationId,
      eventId,
      assignee,
    } = checklist;
    const user_email = (JSON.parse(localStorage.getItem('operator')) || {}).email;

    const iteminfo = {
      checked,
      complete,
      description,
      details,
      tags,
    };

    const listItemInfo = {
      checklistId: parentId,
      title: name && name.trim() !== '' ? name.trim() : 'Untitled',
      dueDate: due && due !== '01/01/1970' ? due : moment().format('MM-DD-YYYY'),
      operatorId: operatorId ? operatorId : 0,
      stationId: stationId ? stationId : 0,
      eventId: eventId ? eventId : 0,
      info: iteminfo,
      project: project,
      priority:
        priority && _.isString(priority) && priority !== ''
          ? priority
          : _.isString(itemEdited.priority)
          ? itemEdited.priority
          : '',
      createdBy: user_email,
      assignee: assignee ? assignee : '',
    };

    console.log(listItemInfo);

    // if (isCanceled) {
    //   setIsEditing(false);
    //   setCancel(false);
    //   setHasChanged(false);
    //   return;
    // }

    // if (!assignee) {
    //   notification.open({
    //     message: 'Assignee is required',
    //   });
    //   return;
    // }

    // setHasChanged(false);
    createListItem(listItemInfo).then((result) => {
      setEditItemId(result.id);
      setEditItem(true);
      // notification.open({
      //   message: 'Successfully Added Item!',
      // });
      // setHasChanged(false);
      // localStorage.removeItem('checklistOrder');
      setAddTask(false);
      setItemEdited({});
      eventSocket.emit(
        'addChecklistItem',
        JSON.stringify({
          assignee: listItemInfo.assignee,
          assigneeImg: '',
          attachments: 0,
          checked: false,
          complete: 1,
          details: false,
          due: listItemInfo.dueDate,
          eventId: listItemInfo.eventId,
          id: parseInt(result?.id),
          isEditing: false,
          key: `${listItemInfo.checklistId}-${result?.id}`,
          name: listItemInfo.title,
          notes: 1,
          operatorId: listItemInfo.operatorId,
          parentId: listItemInfo.checklistId,
          priority: '',
          project: '',
          selected: 0,
          stationId: listItemInfo.stationId,
          tags: [],
          texts: 0,
          type: 'child',
        })
      );
    });
  };

  const handleAssigneeChange = (value, obj) => {
    console.log('VAL', value, 'OBJ', obj);
    if (value !== undefined) {
      let assigneeVal = JSON.parse(value);
      const clonedChecklist = cloneDeep(dataSource);
      const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
      if (clonedChecklist[foundIndex].children) {
        const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
        clonedChecklist[foundIndex].children[foundChildIndex].assignee = assigneeVal.name;
        console.log(assigneeVal);
        if (assigneeVal.type === 'operator') {
          clonedChecklist[foundIndex].children[foundChildIndex].operatorId = assigneeVal.id; // operator id
          clonedChecklist[foundIndex].children[foundChildIndex].stationId = 0; // station id
          clonedChecklist[foundIndex].children[foundChildIndex].eventId = 0; // eventstation id
          setGetAssignee({ assignee: assigneeVal.name, operatorId: assigneeVal.id, stationId: 0, eventId: 0 });
        }
        if (assigneeVal.type === 'station') {
          clonedChecklist[foundIndex].children[foundChildIndex].stationId = assigneeVal.id; // station id
          clonedChecklist[foundIndex].children[foundChildIndex].operatorId = 0; // operator id
          clonedChecklist[foundIndex].children[foundChildIndex].eventId = 0; // eventstation id
          setGetAssignee({ assignee: assigneeVal.name, operatorId: 0, stationId: assigneeVal.id, eventId: 0 });
        }
        if (assigneeVal.type === 'event') {
          clonedChecklist[foundIndex].children[foundChildIndex].eventId = assigneeVal.id; // eventstation id
          clonedChecklist[foundIndex].children[foundChildIndex].stationId = 0; // station id
          clonedChecklist[foundIndex].children[foundChildIndex].operatorId = 0; // operator id
          setGetAssignee({ assignee: assigneeVal.name, operatorId: 0, stationId: 0, eventId: assigneeVal.id });
        }
        setDataSource(clonedChecklist);
      }
    } else {
      const clonedChecklist = cloneDeep(dataSource);
      const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
      if (clonedChecklist[foundIndex].children) {
        const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
        clonedChecklist[foundIndex].children[foundChildIndex].assignee = '';
        clonedChecklist[foundIndex].children[foundChildIndex].operatorId = 0; // operator id
        clonedChecklist[foundIndex].children[foundChildIndex].stationId = 0; // station id
        clonedChecklist[foundIndex].children[foundChildIndex].eventId = 0; // eventstation id
        setGetAssignee({ assignee: '', operatorId: 0, stationId: 0, eventId: 0 });
        setDataSource(clonedChecklist);
      }
    }
  };

  const updateChecklist = (checklist_id) => {
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${checklist_id}` });
    const newName = clonedChecklist[foundIndex].name.trim();
    // setHasChanged(false);
    eventSocket.emit(
      'updatesChecklist',
      JSON.stringify({
        children: clonedChecklist[foundIndex].children,
        key: clonedChecklist[foundIndex].key,
        name: clonedChecklist[foundIndex].name,
        percentage: clonedChecklist[foundIndex].percentage,
        type: 'parent',
      })
    );
    updateCheckList(checklist_id, { title: newName }).then((res) => {
      // notification.open({
      //   message: 'Successfully Renamed!',
      // });
      // setHasChanged(false);
      // localStorage.removeItem('checklistOrder');
      setIsEditing(false);
    });
  };

  const updateChecklist2 = (e, checklist_id) => {
    e.preventDefault();
    if (isCanceled) {
      setIsEditing(false);
      setCancel(false);
      // setHasChanged(false);
      return;
    }
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${checklist_id}` });
    const newName =
      clonedChecklist[foundIndex].name.trim() !== '' ? clonedChecklist[foundIndex].name.trim() : 'Untitled';
    // setHasChanged(false);
    eventSocket.emit(
      'updatesChecklist',
      JSON.stringify({
        children: clonedChecklist[foundIndex].children,
        key: clonedChecklist[foundIndex].key,
        name: clonedChecklist[foundIndex].name,
        percentage: clonedChecklist[foundIndex].percentage,
        type: 'parent',
      })
    );
    updateCheckList(checklist_id, { title: newName }).then((res) => {
      // notification.open({
      //   message: 'Successfully Renamed!',
      // });
      // setHasChanged(false);
      // localStorage.removeItem('checklistOrder');
      setIsEditing(false);
    });
  };

  const cancelRename = (obj) => {
    setCancel(true);
    setIsAdding(false);
    const clonedList = cloneDeep(dataSource);
    remove(clonedList, (cl) => cl.key === '0');
    console.log(clonedList);
    setDataSource(clonedList);
  };

  const cancelRename2 = (obj) => {
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
    if (clonedChecklist[foundIndex]) {
      const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
      clonedChecklist[foundIndex].children[foundChildIndex].isEditing = false;
      clonedChecklist[foundIndex].children[foundChildIndex] = initialdataSource[foundIndex].children[foundChildIndex];
      setDataSource(clonedChecklist);
    }
  };

  const checkListsAction = (checkList) => {
    if (checkList.key * 1 > 0) {
      if (isEditing) {
        return (
          <Menu className="menu-show-preview">
            <Menu.Item className="menu-show-preview" onClick={() => updateChecklist(checkList.key)}>
              <span>Save</span>
            </Menu.Item>
            <Menu.Item
              className="menu-show-preview"
              onClick={() => {
                const clonedChecklist = cloneDeep(dataSource);
                const foundIndex = findIndex(clonedChecklist, { key: checkList.key });
                clonedChecklist[foundIndex].name = initialdataSource[foundIndex].name;
                setDataSource(clonedChecklist);
                setIsEditing(false);
              }}
            >
              <span>Cancel</span>
            </Menu.Item>
          </Menu>
        );
      } else {
        return (
          <Menu className="menu-show-preview">
            <Menu.Item className="menu-show-preview" onClick={() => renameChecklist2(checkList)}>
              <span>Edit</span>
            </Menu.Item>
            <Menu.Item className="menu-show-preview" onClick={() => deleteChecklist(checkList)}>
              <span>Delete</span>
            </Menu.Item>
          </Menu>
        );
      }
    } else {
      return (
        <Menu className="menu-show-preview">
          <Menu.Item className="menu-show-preview" onClick={() => handleAddChecklist(checkList)}>
            <span>Save</span>
          </Menu.Item>
          <Menu.Item className="menu-show-preview" onClick={() => deleteChecklist(checkList)}>
            <span>Delete</span>
          </Menu.Item>
        </Menu>
      );
    }
  };

  const deletesImg = (obj) => {
    return (
      <Menu className="menu-show-preview">
        <Menu.Item className="menu-show-preview">
          <Popconfirm
            title="Are you sure you want to delete the image？"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => confirmDelete(e, obj)}
            onCancel={cancelDelete}
          >
            <span style={{ color: 'var(--base-text)' }}>Delete</span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const deletesTask = (checklist) => {
    console.log('Deletes task', checklist);
    if (addTask) {
      return (
        <Menu className="menu-show-preview">
          {/* <Menu.Item className="menu-show-preview" onClick={() => handleAddChecklistItem(checklist)}>
            <a>Save</span>
          </Menu.Item> */}
          <Menu.Item
            className="menu-show-preview"
            onClick={() => {
              const clonedChecklist = cloneDeep(dataSource);
              const foundIndex = findIndex(clonedChecklist, { key: `${checklist.parentId}` });
              remove(clonedChecklist[foundIndex].children, { key: `${checklist.parentId}-9999998` });
              clonedChecklist[foundIndex].children.push({
                key: `${checklist.parentId}-9999999`,
                type: 'add-button',
                parentId: checklist.parentId
              });
              setDataSource(clonedChecklist);
              setOpenDatePicker(false);
              setAddTask(false);
            }}
          >
            <span>Delete</span>
          </Menu.Item>
        </Menu>
      );
    } else {
      if (checklist.isEditing) {
        return (
          <Menu className="menu-show-preview">
            {/* <Menu.Item className="menu-show-preview" onClick={() => handleUpdateChecklistItem(checklist)}>
              <a>Save</span>
            </Menu.Item>
            <Menu.Item className="menu-show-preview" onClick={() => handleCancelEdit(checklist)}>
              <a>Cancel</span>
            </Menu.Item> */}
          </Menu>
        );
      } else {
        return (
          <Menu className="menu-show-preview">
            <Menu.Item className="menu-show-preview" onClick={() => handleEditItem(checklist)}>
              <span>Edit</span>
            </Menu.Item>
            <Menu.Item className="menu-show-preview" onClick={() => deleteTask(checklist)}>
              <span>Delete</span>
            </Menu.Item>
            <Menu.Item className="menu-show-preview" onClick={() => duplicateTask(checklist)}>
              <span>Duplicate</span>
            </Menu.Item>
          </Menu>
        );
      }
    }
  };

  // const deletesChecklist = (
  //   <Menu className="menu-show-preview">
  //     <Menu.Item className="menu-show-preview" onClick={deleteChecklist}>
  //       <a>Delete</span>
  //     </Menu.Item>
  //     <Menu.Item className="menu-show-preview" onClick={renameChecklist}>
  //       <a>Rename</span>
  //     </Menu.Item>
  //   </Menu>
  // );

  const filterTask = (status) => {
    setIsSearching(false);
    let toDisplay = [];
    if (status == 'inc') {
      setIsFiltering(true);
      toDisplay = cloneDeep(pristineData).filter((obj) =>
        obj.children.some((task) => task.operatorId === operator && task.checked == false)
      );
      // setDataSource(inc);
      setIncTasks('Incomplete tasks for me');
    } else if (status == 'complete') {
      setIsFiltering(true);
      toDisplay = cloneDeep(pristineData).filter((obj) =>
        obj.children.some((task) => task.operatorId === operator && task.checked == true)
      );
      // setDataSource(complete);
      setIncTasks('Completed tasks for me');
    } else if (status == 'others') {
      setIsFiltering(true);
      toDisplay = cloneDeep(pristineData).filter((obj) =>
        obj.children.some((task) => task.operatorId !== operator && task.checked == true)
      );
      // setDataSource(others);
      setIncTasks('Completed tasks for others');
    } else if (status == 'othersic') {
      setIsFiltering(true);
      toDisplay = cloneDeep(pristineData).filter((obj) =>
        obj.children.some((task) => task.operatorId !== operator && task.checked == false)
      );
      // setDataSource(othersinc);
      setIncTasks('Incomplete tasks for others');
    } else if (status == 'all') {
      setIsFiltering(false);
      toDisplay = cloneDeep(pristineData);
      setIncTasks('All tasks');
    }

    const viewOnly = status !== 'all' ? true : false;

    if (
      localStorage.getItem('checklistOrder') != undefined &&
      JSON.parse(localStorage.getItem('checklistOrder')).length
    ) {
      const checklistOrder = JSON.parse(localStorage.getItem('checklistOrder'));
      // const clonedData = cloneDeep(dataSource);
      const tableOrdered = annotateTableOrder(toDisplay, checklistOrder, viewOnly);
      setDataSource(tableOrdered);
    } else if (
      localStorage.getItem('checklistColKeyOrder') != undefined
    ) {
      let nameOrder = localStorage.getItem('checklistColKeyOrder');
      // if (nameOrder === 'ascend') {
      // toDisplay.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      const resultsOrdered = applyColumnOrder('name', nameOrder, toDisplay, viewOnly);
      setDataSource(resultsOrdered);
      // } else {
      //   toDisplay.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
      //   setDataSource(toDisplay);
      // }
    } else {
      setDataSource(toDisplay);
    }
  };

  const incompleteTasks = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => filterTask('all')}>
        <span>All tasks</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterTask('inc')}>
        <span>Incomplete tasks for me</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterTask('complete')}>
        <span>Completed tasks for me</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterTask('othersic')}>
        <span>Incomplete tasks for others</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterTask('others')}>
        <span>Completed tasks for others</span>
      </Menu.Item>
    </Menu>
  );

  const changePriority = (e, obj) => {
    e.preventDefault();
    const optionElement = document.getElementById('rgb');
    if (e.target.value == 'High') {
      optionElement.style.background = '#FF0000';
    } else if (e.target.value == 'Low') {
      optionElement.style.background = '#F5C028';
    } else if (e.target.value == 'Medium') {
      optionElement.style.background = '#F05C22';
    }

    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
    if (clonedChecklist[foundIndex]) {
      const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
      clonedChecklist[foundIndex].children[foundChildIndex].priority = e.target.value;
      setDataSource(clonedChecklist);
    }
    setGetPriority(e.target.value);
    setSelectPriority(true);
  };

  const customSorter = (a, b, sortOrder) => {
    if (a.type !== b.type) {
      if (sortOrder === 'descend') {
        return -1;
      } else {
        return 1;
      }
    } else {
      const toreturn = a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      return toreturn;
    }
  }

  const columns = [
    {
      title: 'Sorted by Name',
      dataIndex: 'name',
      sorter: (a, b, sortOrder) => (sortOrder != undefined ? customSorter(a, b, sortOrder) : null),
      sortDirections: ['descend', 'ascend',],
      width: 320,
      ellipsis: true,
      render: (key, obj) => (
        <span className="chk-list-mobile">
          {' '}
          <span className={'checkist-title-row-container' + (obj.type == 'add-image' ? ' row-cover-image' : '')}>
            <span className={obj.type == 'parent' ? '' : 'name-span-mobile'}>
              {/* Checked */}
              {obj.checked &&
                obj.type !== 'add-image' &&
                obj.type !== 'add-button' &&
                obj.type !== 'parent' &&
                !obj.isEditing &&
                // addTask &&
                obj.key.split('-')[1] !== '9999998' && (
                  <span className="check-task-mobile" onClick={() => setCompleteTask(obj, true)}>
                    <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                  </span>
                )}
              {obj.checked &&
                obj.type !== 'add-image' &&
                obj.type !== 'add-button' &&
                obj.type !== 'parent' &&
                obj.isEditing &&
                // addTask &&
                obj.key.split('-')[1] !== '9999998' && (
                  <span className="check-task-mobile" onClick={() => setCompleteTask(obj, true)}>
                    <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                  </span>
                )}
              {/* Unchecked */}
              {!obj.checked &&
                obj.type !== 'add-image' &&
                obj.type !== 'add-button' &&
                obj.type !== 'parent' &&
                !obj.isEditing &&
                // addTask &&
                obj.key.split('-')[1] !== '9999998' && (
                  <span className="check-task-mobile" onClick={() => setCompleteTask(obj, true)}>
                    <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                  </span>
                )}
              {!obj.checked &&
                obj.type !== 'add-image' &&
                obj.type !== 'add-button' &&
                obj.type !== 'parent' &&
                obj.isEditing &&
                // addTask &&
                obj.key.split('-')[1] !== '9999998' && (
                  <span className="check-task-mobile" onClick={() => setCompleteTask(obj, true)}>
                    <CompletionIcon completed={obj.complete === 2} dimmed={false} />
                  </span>
                )}

              {/* Adding new task: TASK NAME */}
              {obj.type == 'child' && obj.key === `${obj.parentId}-9999998` && (
                <div>
                  <input
                    type="text"
                    className="checklist-bar"
                    onChange={(e) => {
                      handleUpdateItemName(obj, e.target.value);
                    }}
                    placeholder="Enter Checklist item"
                    onFocus={() => {
                      setItemEdited(obj);
                    }}
                  />
                  <div
                    onMouseDown={() => false}
                    style={{
                      width: 100,
                      cursor: 'pointer',
                      margin: '4px 0px 7px 10px',
                      color: 'var(--accent-color)',
                      fontSize: 13,
                    }}
                  >
                    <span onMouseDown={() => cancelRename2(obj)}>Cancel</span>
                    <span onMouseDown={() => handleAddChecklistItem(obj)} style={{ paddingLeft: '14px' }}>
                      Save
                    </span>
                  </div>
                </div>
              )}
              {/* Task Name */}
              {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && !obj.isEditing && (
                <a href="#/" className="drag-handle">
                  <span className="item-name" onTouchStart={(e) => getObj(e, obj)} onClick={() => handleEditItem(obj)}>
                    {obj.name}
                  </span>
                </a>
              )}
            </span>
            {/* Editing new task: TASK NAME */}
            {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && obj.isEditing && (
              <a href="#/" className="drag-handle">
                <span
                  className="item-name"
                  onTouchStart={(e) => getObj(e, obj)}
                  onClick={() => console.log('edit this now')}
                >
                  <input
                    type="text"
                    className="checklist-bar"
                    defaultValue={obj.name}
                    onChange={(e) => handleUpdateItemName(obj, e.target.value)}
                    //onBlur={() => handleUpdateChecklistItem(obj, 'blur')}
                    placeholder="Enter Checklist item"
                  />
                </span>
                <div
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 14px 10px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  <span onMouseDown={() => cancelRename2(obj)}>Cancel</span>
                  <span onMouseDown={() => handleUpdateChecklistItem(obj, 'blur')} style={{ paddingLeft: '14px' }}>
                    Save
                  </span>
                </div>
              </a>
            )}
            {/*ROW: CHECKLIST DETAILS (CHECKLIST NAME AND IMAGE) */}
            <span className={obj.type == 'parent' ? '' : 'name-key-mobile'}>
              {/* Checklist image */}
              {obj.type == 'add-image' && obj.imageUrl == '' && (
                <div onTouchStart={(e) => setId(e, obj)}>
                  <AddImage handleDrop={(e) => handleDrop(e, obj)} />
                </div>
              )}
              {obj.type == 'add-image' && obj.imageUrl !== '' && (
                <ThumbnailImage className="checklist-cover-image-container-mobile" src={obj.imageUrl} type={'cover'} />
              )}

              {obj.type == 'add-image' && obj.imageUrl !== '' && (
                <span style={{ marginTop: 5 }}>
                  <Dropdown overlay={deletesImg(obj)}>
                    <div className="toggle-switch">
                      <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                    </div>
                  </Dropdown>
                </span>
              )}
              {/* Checklist Title: Adding and/or Editing */}
              {(obj.type == 'parent' && obj.key * 1 === 0) ||
              (obj.type === 'parent' && isEditing && checklistid === obj.key) ? (
                <span>
                  <input
                    autoFocus
                    type="text"
                    className="checklist-barMob"
                    defaultValue={obj.name}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        if (isAdding) {
                          handleAddChecklist(obj);
                        } else {
                          updateChecklist(obj.key);
                        }
                      }
                    }}
                    onBlur={(e) => (isAdding ? handleAddChecklist2(e, obj) : updateChecklist2(e, obj.key))}
                    onChange={(e) => {
                      const cloneList = cloneDeep(dataSource);
                      const index = findIndex(cloneList, { key: obj.key });
                      cloneList[index].name = e.target.value;
                      setDataSource(cloneList);
                    }}
                    placeholder="Enter checklist name"
                  />
                  <div
                    onMouseDown={() => cancelRename(obj)}
                    style={{
                      width: 100,
                      cursor: 'pointer',
                      margin: '8px 0px 7px 45px',
                      color: 'var(--accent-color)',
                      fontSize: 15,
                    }}
                  >
                    Cancel
                  </div>
                  <span
                    onTouchStart={() => {
                      setDeleteChecklist(obj);
                    }}
                    onMouseOver={() => {
                      setDeleteChecklist(obj);
                    }}
                    style={{ marginRight: 600, marginTop: 20 }}
                  >
                    <Dropdown overlay={checkListsAction(obj)}>
                      <span className="checklist_more">
                        {' '}
                        <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                      </span>
                    </Dropdown>
                  </span>
                </span>
              ) : (
                key &&
                key !== 'image' &&
                obj.type === 'parent' && (
                  <span className={obj.selected > 0 ? 'highlight' : ''}>
                    <span className="drag-handle">
                      <span
                        onMouseEnter={(e) => toggleClass(e, obj)}
                        onMouseLeave={(e) => toggleClass(e, obj)}
                        onClick={(e) => (isAdding ? '' : renameChecklist(e, obj))}
                        className="checklist-collection-title-mobile"
                      >
                        {key}
                      </span>
                      {obj.type == 'parent' && (
                        <span className="progress-mobile">
                          <ProgressBar percentage={obj.percentage} />
                        </span>
                      )}
                      <span
                        className="checklist_more"
                        onTouchStart={() => {
                          setDeleteChecklist(obj);
                        }}
                        onMouseOver={() => {
                          setDeleteChecklist(obj);
                        }}
                        style={{ marginRight: 600, marginTop: 20 }}
                      >
                        <Dropdown overlay={checkListsAction(obj)}>
                          <span>
                            {' '}
                            <img
                              src={IconsMoreOff}
                              className="UI-IconsMoreOff"
                              alt="IconsMoreOff"
                              style={{ float: 'right' }}
                            />
                          </span>
                        </Dropdown>
                      </span>
                    </span>
                  </span>
                )
              )}

              {obj.type == 'add-button' && !isEditing ? (
                <span className="add-checklist-item" onClick={() => addInputChecklistItem(obj)}>
                  Add task
                </span>
              ) : (
                ''
              )}
            </span>
          </span>
          {/* Attachment Indicator CTA */}
          {obj.type == 'child' && !obj.isEditing ? (
            <AttachmentIndicatorMobile
              className="checklist-attachment-indicator-mobile"
              showIfZero={true}
              count={obj.selected}
              onClick={(e) => showDrawer1(e, obj)}
            />
          ) : (
            ''
          )}
          {/* Task: Object Details */}
          {obj.type == 'child' && obj.details ? (
            <span className="details-checklist" onClick={showDrawer}>
              Details
            </span>
          ) : (
            ''
          )}
          {/* CTA Button */}
          {obj.type == 'child' && !obj.isEditing && obj.key.split('-')[1] !== '9999998' ? (
            <span className="checklist-item-options-container">
              {' '}
              <Dropdown overlay={() => deletesTask(obj)}>
                <span className="toggle-switch">
                  <img
                    src={IconsMoreOff}
                    onTouchStart={() => setDeleteTask(obj)}
                    onMouseOver={() => setDeleteTask(obj)}
                    className="UI-IconsMoreOff"
                    alt="IconsMoreOff"
                    style={{ marginRight: 6 }}
                  />
                </span>
              </Dropdown>
              <span onTouchStart={(e) => getObj(e, obj)}>
                {' '}
                {/* <a className="drag-handle" href="#/">
                  {' '}
                  <img src={reorder} alt="reorder" className="menusToggles" />
                </a> */}
              </span>{' '}
            </span>
          ) : (
            ''
          )}
          <div className="chk-mobile-details">
            {/* {obj.type == 'add-input-checklist' && (
              <div>
                <input
                  type="text"
                  className="checklist-bar"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleAddChecklist(obj);
                    }
                  }}
                  onChange={(e) => {
                    const cloneList = cloneDeep(dataSource);
                    const index = findIndex(cloneList, { key: obj.key });
                    cloneList[index].name = e.target.value;
                    setDataSource(cloneList);
                  }}
                  placeholder="Enter new checklist name"
                />

              </div>
            )} */}
          </div>
          {/* ROW: ASSIGNEE */}
          {obj.type == 'child' && (
            <Row gutter={32} className="checklist-item-row">
              <Col span={4}>Assignee</Col>
              <Col span={20} style={{ width: 'auto' }}>
                {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && !obj.isEditing && obj.assignee !== '' ? (
                  <div className="common-user-icon user-mobile" alt="user-photo">
                    <UserImage src={obj.assigneeImg !== '' ? obj.assigneeImg : null} />
                  </div>
                ) : (
                  ''
                )}
                {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && !obj.isEditing && (
                  <span onClick={() => handleEditItem(obj)}>{obj.assignee}</span>
                )}

                {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && obj.isEditing && (
                  <TreeSelect
                    className="equip-select10"
                    value={obj.assignee ? obj.assignee : 'Search assignee'}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto', width: 250 }}
                    treeData={assignees}
                    placeholder="Search assignee..."
                    treeDefaultExpandAll
                    showSearch
                    allowClear={obj.assignee ? true : false}
                    onChange={(e) => handleAssigneeChange(e, obj)}
                  />
                )}

                {obj.type == 'child' && obj.key.split('-')[1] === '9999998' && (
                  <TreeSelect
                    className="equip-select10"
                    value={obj.assignee ? obj.assignee : 'Search assignee'}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto', width: 250 }}
                    treeData={assignees}
                    placeholder="Search assignee..."
                    treeDefaultExpandAll
                    showSearch
                    allowClear={obj.assignee ? true : false}
                    onChange={(e) => handleAssigneeChange(e, obj)}
                  />
                )}
              </Col>
            </Row>
          )}
          {/* ROW: DUE DATE */}
          {obj.type == 'child' && (
            <Row gutter={32} className="checklist-item-row">
              <Col span={4}>Due date</Col>
              <Col span={20}>
                {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && !obj.isEditing && (
                  <span onClick={() => handleEditItem(obj)}>
                    {obj.due && obj.due !== '01/01/1970' ? obj.due : null}
                  </span>
                )}
                {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && obj.isEditing && (
                  <span>
                    <span className="item-date" onClick={() => setOpenDatePicker(true)}>
                      {obj.due && obj.due !== '01/01/1970' ? obj.due : 'Select Date'}
                    </span>
                    {openDatePicker && (
                      <DatePicker
                        getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                        style={{ height: 0 }}
                        open={openDatePicker}
                        onChange={(e) => {
                          handleDateChange(e, obj);
                        }}
                        allowClear={false}
                        size={`small`}
                      />
                    )}
                  </span>
                )}
                {obj.type == 'child' && obj.key.split('-')[1] === '9999998' && (
                  <span>
                    <span className="item-date" onClick={() => setOpenDatePicker(true)}>
                      {obj.due}
                    </span>
                    {openDatePicker && (
                      <DatePicker
                        getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                        style={{ height: 0 }}
                        open={openDatePicker}
                        onChange={(e) => {
                          handleDateChange(e, obj);
                        }}
                        allowClear={false}
                        size={`small`}
                      />
                    )}
                  </span>
                )}
              </Col>
            </Row>
          )}
          {/* ROW: PROJECT */}
          {obj.type == 'child' && (
            <Row gutter={32} className="checklist-item-row">
              <Col span={4}>Project</Col>
              <Col span={20}>
                {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && !obj.isEditing && (
                  <span onClick={() => handleEditItem(obj)}>{obj.project}</span>
                )}
                {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && obj.isEditing && (
                  <span>
                    <input
                      type="text"
                      defaultValue={obj.project}
                      onChange={(e) => handleProjectChange(e.target.value, obj)}
                      className="project-field-mob"
                      placeholder="Project"
                    />
                  </span>
                )}
                {obj.type == 'child' && obj.key === `${obj.parentId}-9999998` && (
                  <span>
                    <input
                      type="text"
                      onChange={(e) => handleProjectChange(e.target.value, obj)}
                      className="project-field-mob"
                      placeholder="Project"
                    />
                  </span>
                )}
              </Col>
            </Row>
          )}
          {/* ROW: TAGS */}
          {/* {obj.type == 'child' && (
            <Row gutter={32} className="checklist-item-row">
              <Col span={4}>Tags</Col>
              <Col span={20}>
                <div
                  className="checklist-taggy"
                  onClick={() => {
                    if (obj.key !== 0) {
                      handleEditItem(obj);
                    }
                  }}
                >
                  {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && obj.isEditing ? (
                    <input
                      type="text"
                      style={{ width: 60 }}
                      placeholder="Add tags"
                      className="checklist-tags"
                      onKeyPress={(e) => {
                        setEditingItem(false);
                        if (e.key === 'Enter') {
                          handleAddTags(e.target.value, obj, 'edit');
                          e.target.value = '';
                          setEditingItem(true);
                        }
                      }}
                      onBlur={() => setEditingItem(true)}
                    />
                  ) : (
                    ''
                  )}
                  {obj.type == 'child' && obj.key.split('-')[1] === '9999998' ? (
                    <input
                      type="text"
                      placeholder="Add tags"
                      className="checklist-tags"
                      onKeyPress={(e) => {
                        setAddTask(false);
                        if (e.key === 'Enter') {
                          handleAddTags(e.target.value, obj, 'add');
                          e.target.value = '';
                          setAddTask(true);
                        }
                      }}
                      onBlur={() => setAddTask(true)}
                    />
                  ) : (
                    ''
                  )}
                  {obj.type == 'child' && obj.tags
                    ? obj.tags.map((tag) => {
                        let color = 'black';
                        return (
                          <Tag
                            color={color}
                            key={tag}
                            closable={obj.key.split('-')[1] === '9999998' || obj.isEditing}
                            onClose={(e) => {
                              // remove tags
                              handleRemoveTags(tag, obj);
                            }}
                          >
                            {tag}
                          </Tag>
                        );
                      })
                    : ''}{' '}
                </div>
              </Col>
            </Row>
          )} */}
          {/* ROW: PRIORITY */}
          {obj.type == 'child' && (
            <Row gutter={32} className="checklist-item-row">
              <Col span={4}>Priority</Col>
              <Col span={20}>
                {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && obj.priority == 'High' && !obj.isEditing && (
                  <span className="priorities high" onClick={() => handleEditItem(obj)}>
                    {obj.priority}
                  </span>
                )}
                {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && obj.priority == 'Medium' && !obj.isEditing && (
                  <span className="priorities med" onClick={() => handleEditItem(obj)}>
                    {obj.priority}
                  </span>
                )}
                {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && obj.priority == 'Low' && !obj.isEditing && (
                  <span className="priorities low" onClick={() => handleEditItem(obj)}>
                    {obj.priority}
                  </span>
                )}
                {obj.type == 'child' && obj.key === `${obj.parentId}-9999998` && !obj.isEditing && (
                  <Dropdown overlay={priorityOptions(obj)} placement="bottomCenter" trigger={['click']}>
                    <span>
                      {' '}
                      {obj.priority && obj.priority !== '' ? (
                        <span className={`priorities ${obj.priority}`}>{obj.priority}</span>
                      ) : (
                        <span className="select-prio-chk">Select Priority</span>
                      )}
                    </span>
                  </Dropdown>
                )}
                {obj.type == 'child' && obj.key.split('-')[1] !== '9999998' && obj.isEditing && (
                  <span id="clickbox">
                    <select
                      value={obj.priority}
                      onChange={(e) => {
                        changePriority(e, obj);
                      }}
                      className="checklist-prio1"
                      id="rgb"
                    >
                      <option value="">Select</option>
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option value="High">High</option>
                    </select>
                    <span style={{ padding: 7 }} onClick={() => handleRemovePrio(obj)}>
                      X
                    </span>
                  </span>
                )}
              </Col>
            </Row>
          )}
        </span>
      ),
    },
  ];

  const deleteImage = (checklist_id) => {
    //delete from api
    // setHasChanged(false);
    updateCheckList(checklist_id, { info: { imageUrl: '' } }).then((res) => {
      // notification.open({
      //   message: 'Successfully Removed Image!',
      // });
      setHasChanged(false);
      // localStorage.removeItem('checklistOrder');
      setIsEditing(false);
    });
  };

  function confirmDelete(e, checklist) {
    console.log(checklist);
    deleteImage(checklist.parentId);
  }

  function cancelDelete(e) {
    message.error('Image not deleted');
  }

  const handleRowExpand = (record) => {
    if (expandedRows.includes(record.key)) {
      let filterRecord = expandedRows.filter((key) => key !== record.key);
      setExpandedRows(filterRecord);
      localStorage.setItem('checklistOpenRows', JSON.stringify(filterRecord));
    } else {
      setExpandedRows([...expandedRows, record.key]);
      localStorage.setItem('checklistOpenRows', JSON.stringify([...expandedRows, record.key]));
    }
  };

  const collapseAll = () => {
    setExpandedRows([]);
    localStorage.setItem('checklistOpenRows', JSON.stringify([]));
  };

  const getObj = (e, obj) => {
    setGetCatId(obj);
    setDragParent(false);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (dragParent) {
        const data = [...dataSource];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        localStorage.setItem('checklistOrder', JSON.stringify(cacheTableOrder(data).checklistOrder));
        localStorage.removeItem('checklistColKeyOrder');
        setHasChanged(true);
      } else {
        console.log(getCatId);
        let originalData = [...dataSource];
        const getObj = originalData.find((p) => p.key == `${getCatId.parentId}`);
        const data = getObj.children;
        const findIndx = data.findIndex((x) => x.key === getCatId.key);
        let firstIndex = Math.floor(fromIndex - findIndx);
        let destinationIndex = Math.floor(toIndex - firstIndex);
        const item = data.splice(findIndx, 1)[0];
        data.splice(destinationIndex, 0, item);
        originalData.find((v) => v.key === `${getCatId.parentId}`).children = data;
        console.log('REORDERED', originalData);
        // setDataSource(originalData);
        localStorage.setItem('checklistOrder', JSON.stringify(cacheTableOrder(originalData).checklistOrder));
        localStorage.removeItem('checklistColKeyOrder');
        setHasChanged(true);
      }
    },
    handleSelector: 'a',
    nodeSelector: dragParent
      ? 'tr.ant-table-row.parent.ant-table-row-level-0'
      : 'tr.ant-table-row.child.ant-table-row-level-1',
    ignoreSelector: dragParent
      ? 'tr.ant-table-row.child.ant-table-row-level-1'
      : 'tr.ant-table-row.parent.ant-table-row-level-0',
  };

  const handleUpdateItemName = (obj, value) => {
    let editing = itemEdited;
    editing.name = value;
    setItemEdited(editing);
    const clonedChecklist = cloneDeep(dataSource);
    const foundIndex = findIndex(clonedChecklist, { key: `${obj.parentId}` });
    if (clonedChecklist[foundIndex]) {
      const checkItem = clonedChecklist[foundIndex].children;
      if (obj.key == `${obj.parentId}-9999998`) {
        clonedChecklist[foundIndex].children[checkItem.length - 1].name = value;
      } else {
        const foundChildIndex = findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
        clonedChecklist[foundIndex].children[foundChildIndex].name = value;
      }
      setDataSource(clonedChecklist);
    }
  };

  const closeDrawer = useCallback((data) => {
    updateVisible1(false);
    updateVisible(false);
    setHasChanged(false);
  }, []);

  const showDrawer1 = (e, obj) => {
    updateVisible1(true);
    setDocLinks({
      type: 'tasks',
      linkId: obj.id,
    });
    setLinks({
      type: 'tasks',
      linkId: obj.id,
      info: { title: obj.name },
    });
  };

  useEffect(() => {
    eventSocket.on('updatedChecklist', (message) => {
      let newData = JSON.parse(message);
      console.log('CHKLISUPDATED!!', [newData]);
      setChecklistUpdate([newData]);
    });
    eventSocket.on('updatedChecklistItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMUPDATED!!', newData);
      setItemUpdate(newData);
    });
    eventSocket.on('updatedStationTask', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMUPDATEDSTATION!!', newData.id);
      setItemCheckUpdate(newData.id);
    });
    eventSocket.on('addedChecklist', (message) => {
      let newData = JSON.parse(message);
      console.log('CHKLISTADDED!!', newData);
      setChecklistAdd(newData);
    });
    eventSocket.on('addedChecklistItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMADDED!!', newData);
      setItemAdd(newData);
    });
    eventSocket.on('deletedChecklist', (message) => {
      let newData = JSON.parse(message);
      console.log('CHKLISTDELETED!!', newData.id.toString());
      setChecklistDelete(newData.id);
    });
    eventSocket.on('deletedChecklistItem', (message) => {
      let newData = JSON.parse(message);
      console.log('CHKLISTITEMDELETED!!', newData.id.toString());
      setItemDelete(newData.id);
    });
    return () => {
      eventSocket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTaskToChecklist = (cloned, data) => {
    let newCloned = cloned;
    const checklistIndex = findIndex(newCloned, { key: data.parentId.toString() });
    let checklistChildren = newCloned[checklistIndex]?.children;

    if (newCloned[checklistIndex]) {
      newCloned[checklistIndex].children[checklistChildren.length - 1] = { ...data };
    } else {
      newCloned[checklistIndex].unshift(data);
    }

    return newCloned;
  }

  const checkTaskInChecklist = (cloned, data) => {
    let newCloned = cloned.map((c) => {
      c.children.map((t) => {
        if (t.id == data) {
          if (t.checked == true) {
            t.checked = false;
            t.complete = 1;
          } else {
            t.checked = true;
            t.complete = 2;
          }
        }
        return t;
      });
      return c;
    });
    return newCloned;
  }

  const updateTaskInChecklist = (cloned, data) => {
    let index = cloned?.findIndex((obj) => obj.key == data?.parentId);
    let childIndex = cloned[index]?.children.findIndex((obj) => obj.id == data.id);
    let newCloned = cloned.map((c) => {
      c.children.map((t) => {
        if (t.id == data.id) {
          c.children[childIndex] = data;
          return t;
        }
        return t;
      });
      return c;
    });
    return newCloned;
  }

  const removeTaskFromChecklist = (cloned, data) => {
    let newCloned = cloned;
    const findChecklistIndex = findIndex(cloned, { key: data.parentId.toString() });
    remove(newCloned[findChecklistIndex]?.children, (c) => c.id === data.id);
    return newCloned;
  }

  useEffect(() => {
    // setDataSource((oldArray) => [...oldArray, checklistAdd]);
    // setInitialSource((oldArray) => [...oldArray, checklistAdd]);
    // setPristineData((oldArray) => [...oldArray, checklistAdd]);
    // setOrigData((oldArray) => [...oldArray, checklistAdd]);
    // setSocketData((oldArray) => [...oldArray, checklistAdd]);
    if (Object.keys(checklistAdd).length) {
      const updatedData = [ ...dataSource, checklistAdd ];
      const updatedSocket = [ ...socketData, checklistAdd ];

      const updatedTableOrder = cacheTableOrder(updatedData.filter((k) => k.key !== '0'));
      setDataSource(updatedTableOrder.orderedChecklist);
      setInitialSource(updatedTableOrder.orderedChecklist);
      localStorage.setItem('checklistOrder', JSON.stringify(updatedTableOrder.checklistOrder));

      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
      setHasChanged(false);
    }
  }, [checklistAdd]);

  useEffect(() => {
    if (Object.keys(itemAdd).length) {
      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);

      const updatedData = addTaskToChecklist(clonedData, itemAdd);
      const updatedSocket = addTaskToChecklist(clonedSocket, itemAdd);

      const updatedTableOrder = cacheTableOrder(updatedData);
      setDataSource(updatedTableOrder.orderedChecklist);
      setInitialSource(updatedTableOrder.orderedChecklist);
      localStorage.setItem('checklistOrder', JSON.stringify(updatedTableOrder.checklistOrder));

      setSocketData(updatedSocket);
      setPristineData(updatedSocket);
      setHasChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemAdd]);

  useEffect(() => {
    console.log(socketData);
    let updatedData = dataSource.map((obj) => checklistUpdate.find((o) => o.key === obj.key) || obj);
    let updatedSocket = socketData.map((obj) => checklistUpdate.find((o) => o.key === obj.key) || obj);
    setDataSource(updatedData);
    setInitialSource(updatedData);
    
    setPristineData(updatedSocket);
    setSocketData(updatedSocket);
    setHasChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistUpdate]);

  useEffect(() => {
    if (Object.keys(itemUpdate).length > 0) {
      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);

      const updatedData = updateTaskInChecklist(clonedData, itemUpdate);
      const updatedSocket = updateTaskInChecklist(clonedSocket, itemUpdate);
      setDataSource(updatedData);
      setInitialSource(updatedData);
      
      setSocketData(updatedSocket);
      setPristineData(updatedSocket);
      setHasChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemUpdate]);

  useEffect(() => {
    if (itemCheckUpdate !== 0) {
      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);

      const updatedData = checkTaskInChecklist(clonedData, itemCheckUpdate);
      const updatedSocket = checkTaskInChecklist(clonedSocket, itemCheckUpdate);
      setDataSource(updatedData);
      setInitialSource(updatedData);
      
      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
      setHasChanged(false);
      setTimeout(() => {
        setItemCheckUpdate(0);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCheckUpdate]);

  useEffect(() => {
    if (Object.keys(checklistDelete).length > 0) {
      let clonedData = cloneDeep(dataSource);
      let clonedSocket = cloneDeep(socketData);
      remove(clonedData, (c) => c.key === checklistDelete.toString());
      remove(clonedSocket, (c) => c.key === checklistDelete.toString());

      const updatedTableOrder = cacheTableOrder(clonedData);
      setDataSource(updatedTableOrder.orderedChecklist);
      setInitialSource(updatedTableOrder.orderedChecklist);
      localStorage.setItem('checklistOrder', JSON.stringify(updatedTableOrder.checklistOrder));
      
      setPristineData(clonedSocket);
      setSocketData(clonedSocket);
      setHasChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistsDelete]);

  useEffect(() => {
    if (Object.keys(itemDelete).length > 0) {
      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);

      const updatedData = removeTaskFromChecklist(clonedData, itemDelete);
      const updatedSocket = removeTaskFromChecklist(clonedSocket, itemDelete);
      
      const updatedTableOrder = cacheTableOrder(updatedData);
      setDataSource(updatedTableOrder.orderedChecklist);
      setInitialSource(updatedTableOrder.orderedChecklist);
      localStorage.setItem('checklistOrder', JSON.stringify(updatedTableOrder.checklistOrder));
      
      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
      setHasChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDelete]);

  const onTableChange = (pagination, filters, sorter, extra) => {
    let data = cloneDeep(dataSource);

    if (data.length) {
      const viewOnly = isFiltering || isSearching ? true : false;
      if (sorter?.order == undefined) {
        if (viewOnly) {
          setDataSource(data);
        } else {
          const restoredOrder = deannotateTableOrder(data);
          setDataSource(restoredOrder);
          setInitialSource(restoredOrder);
        }
        localStorage.removeItem('checklistColKeyOrder');
      } else {
        localStorage.setItem('checklistColKeyOrder', sorter.order);
      }
    }
  };

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Checklists"
        restaurant={restaurant}
        showSearch={showSearch}
        onChange={filterChecklist}
        clickSearch={() => setShowSearch(true)}
      />
      <div className="checklist-line"></div>
      <div className="check-controls">
        <div className="incomplete-task-mobile">
          <Dropdown overlay={incompleteTasks}>
            <div className="incTask-mob">{incTasks}</div>
          </Dropdown>
        </div>
        <div className="add-task-mobile">
          <div
            className="add-checklist-but"
            onClick={() => {
              setIsAdding(true);
              const clonedDataSource = cloneDeep(dataSource);
              const hasNew = find(clonedDataSource, { key: '0' });
              if (!hasNew) {
                clonedDataSource.unshift(newChecklistValue);
                setDataSource(clonedDataSource);
              }
            }}
          >
            <span>
              <PlusOutlined />
            </span>{' '}
            Add new checklist{' '}
          </div>
        </div>
      </div>
      <div className="collapse-check-mobile">
        <div onClick={collapseAll}>
          <img src={collapseall} className="collapse-all-check" alt="add-button" />
          Collapse all
        </div>
      </div>
      <div className="common-table">
        <ReactDragListView {...dragProps}>
          <Table
            columns={columns}
            dataSource={dataSource}
            className={'show-custom-empty'}
            locale={{ emptyText: <Empty image={empty} description="No checklists" /> }}
            rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
            pagination={false}
            expandIcon={(props) => {
              if (props.record.type == 'parent') {
                return (
                  <ExpandCollapseIcon
                    expanded={props.expanded}
                    onClick={(e) => {
                      props.onExpand(props.record, e);
                    }}
                  />
                );
              }
            }}
            onExpand={(expanded, record) => handleRowExpand(record)}
            expandedRowKeys={expandedRows}
            onChange={onTableChange}
          />
        </ReactDragListView>
      </div>
      <div
        className="add-checklist-mobile"
        onClick={() => {
          setIsAdding(true);
          const clonedDataSource = cloneDeep(dataSource);
          const hasNew = find(clonedDataSource, { key: '0' });
          if (!hasNew) {
            clonedDataSource.push(newChecklistValue);
            setDataSource(clonedDataSource);
          }
        }}
      >
        Add checklist
      </div>
      <Drawer
        width={windowSize >= 842 ? '600px' : '100%'}
        onClose={onClose}
        visible={visible}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <CheckListDetailPopup data={data[0].children[0]} />
      </Drawer>
      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={visible1}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentList
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks={docLinks}
          links={links}
          isMobile={true}
          documentsIn="tasks"
        />
      </Drawer>
    </div>
  );
};

export default ChecklistMobile;
