import { request } from './http.service';
import { decodeAccountMessages } from '../protobuf-models/account.proto.js';

export const create = (params) => {
  return request('POST', '/account', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const update = (id, params) => {
  return request('PUT', '/account/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroy = (id) => {
  return request('DELETE', '/account/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const get = (id) => {
  return request('GET', '/account/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const all = (from, limit) => {
  return request('GET', '/account/' + from + '/' + limit, 200, null, decodeAccountMessages).then(
    (result) => {
      if (result && result.accounts) {
        let all = result.accounts.map((f) => {
          f.info = JSON.parse(f.info);
          return f;
        });
        return all;
      }
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const restaurants = (id) => {
  return request('GET', '/account/' + id + '/restaurants', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};
