import React, { useState, useEffect, useCallback } from 'react';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { Row, Col, Progress, Menu, Dropdown } from 'antd';
import { useHistory } from 'react-router-dom';
import { destroy } from '../../services/event.service';
import EditEventDrawerV2 from '../../pages/Dashboard/components/EditEventDrawerV2';
import './index.css';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import { UserImage } from '../../components/common/common_ui';

const EventStationMobile = (props) => {
  const { eventStationData, setHasCreatedEvent, setHasEditedEvent } = props;
  const [selectedId, setSelectedId] = useState('');
  const [showDrawer, setShowDrawer] = useState({ type: '', open: false, data: null });
  const [sortedEvents, setSortEvents] = useState([]);

  useEffect(() => {
    if (eventStationData.length > 0) {
      setSortEvents(eventStationData);
    } else {
      setSortEvents([]);
    }
  }, [eventStationData]);

  const editEventStation = (e, obj) => {
    console.log(obj);
    setShowDrawer({ type: obj.type, open: true, data: obj });
  };

  const deleteEventStation = () => {
    destroy(selectedId).then((res) => {
      setHasCreatedEvent(true);
      setTimeout(() => {
        let removeEvent = sortedEvents.filter((s) => s.id !== selectedId);
        setSortEvents(removeEvent);
      }, 500);
    });
  };

  const stationActions = (station) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => editEventStation(e, station)}>
        <a href="/#">Edit</a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={deleteEventStation}>
        <a href="/#">Delete</a>
      </Menu.Item>
    </Menu>
  );

  const history = useHistory();
  const handleOnClick = useCallback((id) => history.push(`/event/station/${id}`), [history]);
  return (
    <div className="main marginL10">
      <div>
        {sortedEvents.map((station, i) => {
          return (
            <Row gutter={32}>
              <Col className="gutter-row" span={24}>
                <div className="event-station-mobile-box">
                  <div className="day-mobile-progress">
                    <Row gutter={32}>
                      <Col span={3}>{station.percentage}%</Col>
                      <Col span={18}>
                        <Progress percent={station.percentage} showInfo={false} />
                      </Col>
                      <Col span={3}>
                        <RoleAccessibleComponent
                          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
                        >
                          <Dropdown overlay={stationActions(station)} trigger={['click']}>
                            <div>
                              <img
                                src={IconsMoreOff}
                                className="UI-IconsMoreOff-mobile"
                                alt="IconsMoreOff"
                                onMouseOver={() => setSelectedId(station.id)}
                              />
                            </div>
                          </Dropdown>
                        </RoleAccessibleComponent>
                      </Col>
                    </Row>
                    <Row gutter={32} onClick={() => handleOnClick(station.id)}>
                      <Col style={{ padding: 0 }} span={6}>
                        <div
                          className="station-tile-assignee-image-mobile"
                          alt="assignee"
                          style={{
                            backgroundColor: `${station.info.color}`,
                          }}
                        >
                          <UserImage src={!!station.assigneeImg ? station.assigneeImg : null} showPlaceholder={false} />
                        </div>
                      </Col>
                      <Col span={18}>
                        <div className="event-mobile">{station.event}</div>
                        <div className="event-mobile-date">{station.date}</div>
                        <div className="event-mobile-date">{station.time}</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
      {showDrawer.open && (
        <EditEventDrawerV2
          showDrawer={showDrawer.open}
          type={showDrawer.type}
          data={showDrawer.data}
          setHasCreatedEvent={props.setHasCreatedEvent}
          setShowDrawer={setShowDrawer}
          setHasEditedEvent={setHasEditedEvent}
        />
      )}
    </div>
  );
};

export default EventStationMobile;
