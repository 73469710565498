/* eslint eqeqeq: "off" */
import React, { useState } from 'react';
import './index.css';
import moment from 'moment';
//import { findIndex } from 'lodash';
import { Progress, Row, Col } from 'antd';
import { formatGetTime, hexToRgbA } from '../../pages/Dashboard/DashboardUtils';
import EditEventDrawerV2 from '../../pages/Dashboard/components/EditEventDrawerV2';
//import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import RoleAccessibleComponent from '../RoleAccess/roleaccess';

const MeetingAndEventWeek = (props) => {
  const { weekData, setDay, setCalendarType, setHasEditedEvent } = props;
  // const defaultActive = (() => {
  //   const dayUnix = new Date(moment(new Date()).format('dddd, MMMM D, YYYY')).getTime() / 1000;
  //   return findIndex(weekData, (data) => dayUnix === new Date(data.date).getTime() / 1000);
  // })();
  //const [active, setActive] = useState(defaultActive);
  const [showDrawer, setShowDrawer] = useState({ type: '', open: false, data: null });
  // const [weeklyData, setWeeklyData] = useState(weekData)

  // useEffect(() => {
  //   if (today) {
  //     setActive(defaultActive);
  //   }
  // }, [today]);

  // useEffect(() => {
  //   if (showDrawer.open) {
  //     document.documentElement.style.overflow = 'hidden';
  //     document.body.scroll = 'no';
  //   } else {
  //     document.documentElement.style.overflow = 'scroll';
  //     document.body.scroll = 'yes';
  //   }
  // }, [showDrawer]);

  const clickDay = (param) => {
    //console.log("Select specific week day");
    let date = param.dateObj;
    //console.log(date);
    setCalendarType('day');
    setDay(moment(date).format('dddd, MMMM D, YYYY'));
    // setActive(key);
    // anotherDay(true);
  };

  // const content = (daily_events, i) => {
  //   // generate see more content here
  //   const moreItems = (() => {
  //     let items = [];
  //     for (let i = 4; i < daily_events.length; i++) {
  //       items.push(daily_events[i]);
  //     }
  //     return items;
  //   })();
  //
  //   console.log('MOREITEMS', moreItems)
  //
  //   return (  // this is the standard week calendar MORE render
  //     <div>
  //       <ul>
  //         {moreItems.map((ev) => {
  //           const { info } = ev;
  //           const parsedInfo = info;
  //           const color = (() => {
  //             let ev_color = null;
  //             if (parsedInfo) {
  //               if (parsedInfo.hasOwnProperty('color')) {
  //                 return parsedInfo.color;
  //               }
  //             }
  //             if (!ev_color) {
  //               ev_color = ev.type === 'event' ? '#FF59CE' : '#4CBCF8';
  //             }
  //             return ev_color;
  //           })();
  //           return (
  //             <li key={'i'} className="week-event-name" onDoubleClick={() => {}}>
  //               <table>
  //                 <tr style={{ backgroundColor: hexToRgbA(color) }}>
  //                   <td style={{ borderLeft: ` 5px solid ${color}` }}>
  //                     {ev.type == 'event' ? (
  //                       <div className="tracker-week-event">
  //                         <Row gutter={32}>
  //                           <Col span={3} className="percent-tracker-week">
  //                             {ev.percentage} %
  //                           </Col>
  //                           <Col span={16}>
  //                             <Progress percent={parseInt(ev.percentage)} showInfo={false} />
  //                           </Col>
  //                         </Row>
  //                       </div>
  //                     ) : (
  //                       <div className="meeting-padding"></div>
  //                     )}
  //                     <div className="event-title">{ev.name}</div>
  //                     <div className="event-time">
  //                       {formatGetTime(ev.startTimestamp)} - {formatGetTime(ev.endTimestamp)}
  //                     </div>
  //                     {ev.type == 'meeting' && <div className="filler-padding"></div>}
  //                   </td>
  //                 </tr>
  //               </table>
  //               <div className="Line-3"></div>
  //             </li>
  //           );
  //         })}
  //       </ul>
  //     </div>
  //   );
  // };

  console.log('Week Render');
  console.log(weekData);

  return (
    <div className='scrollable-container'>
      <div id="MeetingAndEventWeek" className='scrollable h-100'>
        <div className="week-container">
          <ul className='mb-0'>
            {weekData.map((daily, key) => {
              let dateObj = new Date(daily.date);
              let daily_date = moment(dateObj).format('ddd, D');
              let current = moment().format('ddd, D');

              return (
                // this is the standard week calendar render
                // <li key={key} onClick={(e) => highlightActive(e, key)} className="daily-col">
                <li key={key} className={daily_date == current ? 'daily-col-highlight' : 'daily-col'}>
                  <div className={daily_date == current ? 'week-daily-header-highlight' : 'week-daily-header'}>
                    <div className="week-daily-header-text" onClick={(e) => clickDay({ dateObj })}>
                      {daily_date}
                    </div>
                  </div>
                  <div className="week-daily-body">
                    <ul>
                      {daily.events.map((evnt, i) => {
                        const { info } = evnt;
                        const parsedInfo = info;
                        const color = (() => {
                          let ev_color = null;
                          if (parsedInfo) {
                            if (parsedInfo.hasOwnProperty('color')) {
                              return parsedInfo.color;
                            }
                          }
                          if (!ev_color) {
                            ev_color = evnt.type === 'event' ? '#FF59CE' : '#4CBCF8';
                          }
                          return ev_color;
                        })();

                        return (
                          <li
                            key={i}
                            className="week-event-name"
                            // onDoubleClick={() => {
                            //   const day = moment(dateObj).format('dddd, MMMM D, YYYY');
                            //   setDay(day);
                            //   setCalendarType('day');
                            // }}
                            onClick={() => setShowDrawer({ type: evnt.type, open: true, data: evnt })}
                          >
                            <table>
                              <tbody>
                                <tr style={{ backgroundColor: hexToRgbA(color) }}>
                                  <td style={{ borderLeft: ` 5px solid ${color}` }}>
                                    {evnt.type == 'event' ? (
                                      <div className="tracker-week-event">
                                        <Row gutter={32}>
                                          <Col span={3} className="percent-tracker-week">
                                            {evnt.percentage}%
                                          </Col>
                                          <Col span={16}>
                                            <Progress percent={parseInt(evnt.percentage)} showInfo={false} />
                                          </Col>
                                        </Row>
                                      </div>
                                    ) : (
                                      <div className="meeting-padding"></div>
                                    )}
                                    <div className="event-title">{evnt.name}</div>
                                    <div className="event-time">
                                      {formatGetTime(evnt.eventStart)} - {formatGetTime(evnt.eventEnd)}
                                    </div>
                                    {evnt.type == 'meeting' && <div className="filler-padding"></div>}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="Line-3"></div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <RoleAccessibleComponent
          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
        >
          {showDrawer.open && (
            <EditEventDrawerV2
              showDrawer={showDrawer.open}
              type={showDrawer.type}
              data={showDrawer.data}
              setHasCreatedEvent={props.setHasCreatedEvent}
              setShowDrawer={setShowDrawer}
              setHasEditedEvent={setHasEditedEvent}
            />
          )}
        </RoleAccessibleComponent>
      </div>
    </div>
  );
};

export default MeetingAndEventWeek;
