/* eslint eqeqeq: "off" */
import React, { useState, useEffect } from 'react';
import './index.css';
import { Collapse } from 'antd';
import _, { findIndex, initial } from 'lodash';
import { CheckmarkIcon } from '../../components/common/common_ui';

const { Panel } = Collapse;

const StationRecipes = (props) => {
  const {
    recList,
    updateFilter,
    updateActiveRecs,
    showRecipePanels,
    showFirstRecipeOnly,
    recipesOnly,
    ids,
    showRecipeLists,
    hasRecipeLists,
    handleShowChecklists,
    handleShowRecipeLists,
    handleActivateChecklists,
    pristineIngs,
    filtered,
    activeSelect,
    resetAll,
    hasList,
  } = props;
  const [expandedRows, setExpandedRows] = useState([]);
  const [totalRecipes, setTotalRecipes] = useState(0);
  const [activeRecs, setActiveRecs] = useState([]);
  const [totalChecked, countChecked] = useState(0);
  const [selectItems, setSelectItems] = useState([]);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [removeInitialState, setRemoveInitialState] = useState(false);

  let lastCheckedRecipe = localStorage.getItem('checkedRec') != undefined ? JSON.parse(localStorage.getItem('checkedRec')) : null;

  useEffect(() => {
    if (recList.length > 0) {
      console.log('STATION RECIPES FAST SELECT', recList);
    }
    // gets total and completed ingredients per recipe
    const ingredientsPerRecipe = pristineIngs.map((ing) => {
      return {
        recipeId: ing.recipeId,
        totalIngs: pristineIngs.filter((i) => i.recipeId === ing.recipeId).length,
        completedIngs: pristineIngs.filter((i) => i.recipeId === ing.recipeId && i.completed === 2).length,
      };
    });

    const getRecipeStats = _.uniqBy(ingredientsPerRecipe, 'recipeId');

    let newRecList = recList.reduce((acc, menu) => {
      menu.completedIng = getRecipeStats.reduce((acc, curr) => acc + curr.completedIngs, 0);
      menu.totalIng = getRecipeStats.reduce((acc, curr) => acc + curr.totalIngs, 0);

      recList.map((menu) => {
        menu.children.map((course) => {
          course.children.map((recipe) => {
            recipe.totalIngs = getRecipeStats.find((s) => s.recipeId === recipe.recipeId)?.totalIngs;
            recipe.completedIngs = getRecipeStats.find((s) => s.recipeId === recipe.recipeId)?.completedIngs;
            recipe.active = lastCheckedRecipe && lastCheckedRecipe.recipeId == recipe.recipeId ? 1 : filtered ? 0 : 1;
            return recipe;
          });
          return course;
        });
        return menu;
      });

      const courseWithIngs = menu.children.map((course) => ({
        ...course,
        children: course.children
          .filter((c) => c.totalIngs !== undefined)
      }));

      if (courseWithIngs.length && courseWithIngs.some((course) => course.children && course.children.length)) {
        acc.push({ ...menu, children: courseWithIngs });
      }
      return acc;
    }, []);

    setSelectItems(newRecList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recList, pristineIngs]);

  useEffect(() => {
    console.log(expandedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRows]);

  useEffect(() => {
    if (showRecipeLists) {
      let recipesChecked = selectItems
        .reduce((r, menu) => r.concat(menu.children), [])
        .reduce((r, course) => r.concat(course.children), [])
        .filter((recipe) => recipe.active === 1);
      countChecked(recipesChecked.length);
      // setExpandedRows([]);
    } else {
      let getActiveStations =
        localStorage.getItem('openRecipePanelStations') != null
          ? JSON.parse(localStorage.getItem('openRecipePanelStations'))
          : null;
      if (getActiveStations && getActiveStations.length > 0) {
        let defaultExpanded = selectItems[0]?.key;
        setExpandedRows([defaultExpanded]);
      } else {
        uncheckAll();
        setExpandedRows([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectItems, showRecipeLists, hasList]);

  useEffect(() => {
    selectItems.reduce((acc, menu) => {
      const courseWithIngs = menu.children.map((course) => ({
        ...course,
        children: course.children
          .filter((recipe) => recipe.totalIngs !== undefined)
      }));
      if (courseWithIngs.length && courseWithIngs.some((course) => course.children && course.children.length)) {
        acc.push({ ...menu, children: courseWithIngs });
      }
      return acc;
    }, []);

    if (recipesOnly) {
      if (activeSelect) {
        // let selected =
        //   localStorage.getItem('checkedRec') == undefined ? JSON.parse(localStorage.getItem('checkedRec')) : null;

        let recipeKeys = selectItems
          .reduce((r, menu) => r.concat(menu.children), [])
          .reduce((r, course) => r.concat(course.children), [])
          .reduce((r, recipe) => recipe.key.split('-'), []);
        let keyVals = lastCheckedRecipe ? [lastCheckedRecipe.key] : recipeKeys;

        if (keyVals.length > 1) {
          console.log(keyVals);
          const menuIds = keyVals.map((key) => parseInt(key.split('-')[2]));
          const menurecipeIds = keyVals.map((key) => `${key.split('-')[0]}-${key.split('-')[2]}`);

          selectItems.map(function iter(a) {
            // active select recipe and parent menu
            if (a?.menuId && a?.menuName) {
              if (menuIds.includes(a.menuId)) {
                a.active = 1;
              } else {
                a.active = 0;
              }
            }

            if (a?.recipeId) {
              if (findIndex(menurecipeIds, (v) => v === `${a.recipeId}-${a.menuId}`) > -1) {
                a.active = 1;
              } else {
                a.active = 0;
              }
            }

            Array.isArray(a.children) && a.children.forEach(iter);
            return a;
          });
        } else {
          selectItems.map(function iter(a) {
            // active select recipe and parent menu
            if (a?.menuId && a?.menuName) {
              if (a.menuId === lastCheckedRecipe.key.split('-')[2] * 1) {
                a.active = 1;
              } else {
                a.active = 0;
              }
            }

            if (a?.recipeId) {
              if (
                a.recipeId === lastCheckedRecipe.key.split('-')[0] * 1 &&
                a.menuId === lastCheckedRecipe.key.split('-')[2] * 1
              ) {
                a.active = 1;
              } else {
                a.active = 0;
              }
            }

            Array.isArray(a.children) && a.children.forEach(iter);
            return a;
          });
        }
      } else {
        localStorage.removeItem('activeSelected');
        selectItems.map(function iter(a) {
          if (a?.active) {
            a.active = 1;
          }
          Array.isArray(a.children) && a.children.forEach(iter);
          return a;
        });
      }

      let checkedRec = selectItems
        .reduce((r, menu) => r.concat(menu.children), [])
        .reduce((r, course) => r.concat(course.children), [])
        .filter((recipe) => recipe.active === 1);

      if (selectItems.length == 1 && !lastCheckedRecipe) {
        checkAll();
      }

      countChecked(checkedRec.length);

      setActiveRecs(checkedRec);

      //setExpandedRows(['0']);
      if (!removeInitialState) {
        let defaultItem = selectItems[0]?.key;
        setExpandedRows([defaultItem]);
        console.log(selectItems);
      }

      if (isInitialRender) {
        setIsInitialRender(false);
        setSelectItems(selectItems);
      }

      let getActiveStation =
        localStorage.getItem('openRecipePanelStations') != null
          ? JSON.parse(localStorage.getItem('openRecipePanelStations'))
          : null;

      if (
        selectItems?.length && (
          (getActiveStation && getActiveStation.length > 0 && !removeInitialState) ||
          (!hasList && !removeInitialState)
        )
      ) {
        showFirstRecipeOnly(true);
        resetAll(false);
        updateFilter(true);
        handleShowChecklists(false);
        localStorage.setItem('checkedRec', JSON.stringify(selectItems[0]?.children[0]?.children[0]));
        let keyVals = selectItems[0]?.children[0]?.children[0]?.key.split('-');

        selectItems.map(function iter(a) {
          if (a?.menuId && a?.menuName) {
            if (a.menuId == parseInt(keyVals[2])) {
              a.active = 1;
            } else {
              a.active = 0;
            }
          }

          if (a?.recipeId) {
            if (a.key === keyVals.join('-')) {
              a.active = 1;
            } else {
              a.active = 0;
            }
          }

          Array.isArray(a.children) && a.children.forEach(iter);
          return a;
        });

        let checkedRec = selectItems
          .reduce((r, menu) => r.concat(menu.children), [])
          .reduce((r, course) => r.concat(course.children), [])
          .filter((recipe) => recipe.active === 1);

        countChecked(checkedRec.length);
        setSelectItems(selectItems);
        setActiveRecs(checkedRec);

        if (!showRecipeLists) {
          handleShowRecipeLists(true);
          handleShowChecklists(false);
          handleActivateChecklists(false);
        }

        let defaultItem = selectItems[0]?.key;
        setExpandedRows([defaultItem]);
        console.log(removeInitialState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipesOnly, isInitialRender, selectItems, removeInitialState, hasList]);

  useEffect(() => {
    if (totalChecked > 1 && totalChecked < totalRecipes) {
      updateActiveRecs(activeRecs);
    }

    if (totalChecked > 1 && totalChecked == totalRecipes) {
      updateActiveRecs(activeRecs);
    }

    // if (activeRecs.length > 0 && !filtered) {
    // 	checkAll();
    // }

    if (totalChecked === 1) {
      updateFilter(true);
      updateActiveRecs(activeRecs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalChecked, activeRecs]);

  // useEffect(() => {
  //   if (recipesOnly) {
  //     resetAllRecipes();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [recipesOnly]);

  const togglePanels = (key) => {
    console.log('5');
    localStorage.removeItem('checkedRec');
    setRemoveInitialState(true);
    //record station id of open recipe panel
    let getActiveStations =
      localStorage.getItem('openRecipePanelStations') != null
        ? JSON.parse(localStorage.getItem('openRecipePanelStations'))
        : null;
    if (getActiveStations) {
      const newArr = [...getActiveStations, ids];
      const uniqIds = [...new Set(newArr)];
      localStorage.setItem('openRecipePanelStations', JSON.stringify(uniqIds));
    } else {
      localStorage.setItem('openRecipePanelStations', JSON.stringify([ids]));
    }

    if (!showRecipeLists) {
      handleShowRecipeLists(true);
      handleShowChecklists(false);
      handleActivateChecklists(false);
    }

    console.log(key);
    let lastKey = [];
    if (key.length >= 2) {
      lastKey.push(key[key.length - 1]);
      setExpandedRows(lastKey);
    } else {
      lastKey = key.length > 0 ? key : ['0'];
      setExpandedRows(lastKey);
    }

    showRecipePanels(true);
    //updateFilter(false);
  };

  const resetAllRecipes = () => {
    handleShowChecklists(false);
    localStorage.setItem('openRecipePanelStations', JSON.stringify([ids]));
    localStorage.removeItem('checkedRec');
    selectItems.map(function iter(a) {
      if (a?.menuId || a?.recipeId) {
        a.active = 1;
      }
      Array.isArray(a.children) && a.children.forEach(iter);
      return a;
    });

    let checkedRec = selectItems
      .reduce((r, menu) => r.concat(menu.children), [])
      .reduce((r, course) => r.concat(course.children), [])
      .filter((recipe) => recipe.active === 1);

    countChecked(checkedRec.length);

    setSelectItems(selectItems);
    setActiveRecs(checkedRec);
    resetAll(true);

    // window.location.reload();

    if (!showRecipeLists) {
      handleShowRecipeLists(true);
      handleShowChecklists(false);
      handleActivateChecklists(false);
    }

    setRemoveInitialState(true);
  };

  const checkAll = () => {
    handleShowChecklists(false);
    localStorage.setItem('openRecipePanelStations', JSON.stringify([ids]));
    localStorage.removeItem('checkedRec');
    selectItems.map(function iter(a) {
      if (a?.active) {
        a.active = 1;
      }
      Array.isArray(a.children) && a.children.forEach(iter);
      return a;
    });

    let checkedRec = selectItems
      .reduce((r, menu) => r.concat(menu.children), [])
      .reduce((r, course) => r.concat(course.children), [])
      .filter((recipe) => recipe.active === 1);

    countChecked(checkedRec.length);

    setSelectItems(selectItems);
    setActiveRecs(checkedRec);

    if (!showRecipeLists) {
      handleShowRecipeLists(true);
      handleShowChecklists(false);
      handleActivateChecklists(false);
    }
  };

  const uncheckAll = () => {
    selectItems.map(function iter(a) {
      if (a?.active) {
        a.active = 0;
      }
      Array.isArray(a.children) && a.children.forEach(iter);
      return a;
    });
    countChecked(0);
  };

  // callback when fast select recipe menu is clicked
  const selectRecipe = (recipe) => (event) => {
    setRemoveInitialState(true);
    resetAll(false);
    updateFilter(true);
    handleShowChecklists(false);
    localStorage.setItem('checkedRec', JSON.stringify(recipe));
    localStorage.setItem('openRecipePanelStations', JSON.stringify([ids]));
    let keyVals = recipe.key.split('-');

    if (event.ctrlKey || event.metaKey) {
      selectItems.map(function iter(a) {
        if (a?.menuId && a?.menuName) {
          if (a.menuId === parseInt(keyVals[2])) {
            a.active = 1;
          } else {
            a.active = 0;
          }
        }

        if (a?.recipeId) {
          if (a.key === keyVals.join('-')) {
            a.active = 1;
          } else {
            a.active = 0;
          }
        }

        Array.isArray(a.children) && a.children.forEach(iter);
        return a;
      });

      let checkedRec = selectItems
        .reduce((r, menu) => r.concat(menu.children), [])
        .reduce((r, course) => r.concat(course.children), [])
        .filter((recipe) => recipe.active === 1);

      countChecked(checkedRec.length);
      setSelectItems(selectItems);
      setActiveRecs(checkedRec);

      if (!showRecipeLists) {
        handleShowRecipeLists(true);
        handleShowChecklists(false);
        handleActivateChecklists(false);
      }
    } else {
      selectItems.map(function iter(a) {
        if (a?.menuId && a?.menuName) {
          if (a.menuId == keyVals[2]) {
            a.active = 1;
          } else {
            a.active = 0;
          }
        }

        if (a?.recipeId) {
          if (a.key === keyVals.join('-')) {
            a.active = 1;
          } else {
            a.active = 0;
          }
        }

        Array.isArray(a.children) && a.children.forEach(iter);
        return a;
      });

      let checkedRec = selectItems
        .reduce((r, menu) => r.concat(menu.children), [])
        .reduce((r, course) => r.concat(course.children), [])
        .filter((recipe) => recipe.active === 1);

      countChecked(checkedRec.length);
      setSelectItems(selectItems);
      setActiveRecs(checkedRec);

      if (!showRecipeLists) {
        handleShowRecipeLists(true);
        handleShowChecklists(false);
        handleActivateChecklists(false);
      }
    }
  };

  // count recipes active on display
  const countMenus = () => {
    let total = 0;
    for (let i = 0; i < selectItems.length; i++) {
      let courses = selectItems[i].children;
      for (let j = 0; j < courses.length; j++) {
        total += courses[j].children.length;
      }
    }

    console.log('TOTAL MENUS ', total);
    setTotalRecipes(total);
  };

  // trigger counting of recipes when fast select menu is selected
  useEffect(() => {
    if (selectItems.length > 0) {
      countMenus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectItems]);

  return (
    <div>
      {hasRecipeLists && (
        <div className="station-rec-main">
          <div className="station-reci-title">Recipes </div>
          <div className="station-reci-total">
            {totalRecipes === 0
              ? ''
              : totalChecked === totalRecipes
              ? `All ${totalChecked} recipe${totalChecked > 1 ? 's' : ''} selected`
              : `${totalChecked} recipe${totalChecked > 1 ? 's' : ''} selected`}{' '}
            {(totalChecked > 0 && totalChecked < totalRecipes) || filtered ? (
              <span className="checked-reset" onClick={resetAllRecipes}>
                All recipes
              </span>
            ) : (
              ''
            )}
          </div>

          <Collapse activeKey={expandedRows} expandIconPosition={'right'} onChange={togglePanels}>
            {selectItems.map((menu, i) => {
              return (
                <Panel
                  header={
                    expandedRows?.includes(i.toString()) ? (
                      <div className="rec-select active">
                        {menu.completedIng === menu.totalIng && menu.active == 1 && <CheckmarkIcon />}
                        {menu.completedIng === menu.totalIng && menu.active == 1 ? (
                          <span>{menu.menuName}</span>
                        ) : (
                          <span style={{ marginLeft: 11 }}>{menu.menuName}</span>
                        )}
                      </div>
                    ) : (
                      <div className="rec-select">
                        {menu.completedIng === menu.totalIng && menu.active == 0 && <CheckmarkIcon />}{' '}
                        {menu.completedIng === menu.totalIng && menu.active == 0 ? (
                          <span>{menu.menuName}</span>
                        ) : (
                          <span style={{ marginLeft: 11 }}>{menu.menuName}</span>
                        )}
                      </div>
                    )
                  }
                  key={menu.key}
                >
                  {menu.children
                    .sort((a, b) => (a.courseId > b.courseId ? 1 : -1))
                    .sort((a, b) => (a.courseOrder > b.courseOrder ? 1 : -1))
                    .map((course) => {
                      return (
                        <div
                          key={course.key}
                          className={course.children.length == 0 ? 'rec-content-box22' : 'rec-content-box'}
                        >
                          <div className="rec-content-type">
                            <div>{course.courseName}</div>
                          </div>
                          {course.children
                            .sort((a, b) => (a.recipeAssignDate > b.recipeAssignDate ? 1 : -1))
                            .sort((a, b) => (a.recipeOrder > b.recipeOrder ? 1 : -1))
                            .map((recipe) => {
                            return (
                              <div key={recipe.key}>
                                {recipe.totalIngs !== undefined && (
                                  <div
                                    className={`rec-list${recipe.active ? ' active' : ''}`}
                                    onClick={selectRecipe(recipe)}
                                  >
                                    {recipe.completedIngs === recipe.totalIngs && recipe.active === 1 && (
                                      <CheckmarkIcon className="active" />
                                    )}
                                    {recipe.completedIngs === recipe.totalIngs && recipe.active == 0 && (
                                      <CheckmarkIcon />
                                    )}
                                    <div className="rec-list-name">{recipe.recipeName}</div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </Panel>
              );
            })}
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default StationRecipes;
