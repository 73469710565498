/* eslint eqeqeq: "off" */
import moment from 'moment';
import { getEvents } from '../../services/event.service';
import { momentizeEvent, convertToNewEventRecord, repeatDayIsValid } from '../../pages/Dashboard/DashboardUtils.js';
// let colors = ['yellow', 'blue', 'blue', 'purple', 'pink'];

const getDaysArray = (year, month) => {
  let monthIndex = month - 1; // 0..11 instead of 1..12
  let names = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let date = new Date(year, monthIndex, 1);
  let result = [];
  while (date.getMonth() == monthIndex) {
    result.push({ dayName: names[date.getDay()], actualDate: moment(date) });

    //console.log(">: " + moment(date).format("MMM D YYYY HH:mm"));
    date.setDate(date.getDate() + 1);
  }
  return result;
};

export const getEventsMonth = (monthStartDay, setMonthlyEvents) => {
  const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  const monthBegin = moment(monthStartDay).startOf('month');
  const monthEnd = monthBegin.clone().endOf('month'); //.add(1, 'months'); // really, this is the very beginning of the next month

  const monthIndex = monthBegin.clone().format('M');
  const year = monthBegin.clone().format('YYYY');
  const monthDayList = getDaysArray(year, monthIndex);

  getEvents({ restaurantId: resto_id, startTimestamp: monthBegin.unix(), endTimestamp: monthEnd.unix() }).then(
    (monthly_events) => {
      if (monthly_events) {
        console.log('API Month Events');
        console.log(monthly_events);

        let monthly_data = [];

        for (let j = 0; j < monthly_events.length; j++) {
          const { name, startTimestamp, endTimestamp, type, percentage, info, repeat, restaurantId, EventMenus, id } =
            monthly_events[j];

          let tempEvent = monthly_events[j]; // refactor scaffold
          const eventRecord = convertToNewEventRecord(tempEvent);
          momentizeEvent(eventRecord);

          const parsedInfo = info;
          const color = (() => {
            let ev_color = null;
            if (parsedInfo) {
              if (parsedInfo.hasOwnProperty('color')) {
                return parsedInfo.color;
              }
            }
            if (!ev_color) {
              ev_color = type === 'event' ? '#FF59CE' : '#4CBCF8';
            }
            return ev_color;
          })();

          var foundDays = [];

          if (eventRecord.repeat === 'never') {
            foundDays = monthDayList.filter((d) => {
              return (
                eventRecord.eventStart.isBefore(d.actualDate.clone().endOf('day')) &&
                eventRecord.eventEnd >= d.actualDate
              );
            });

            // if (eventRecord.name == "Women's Golf Member-Guest ") {
            //   console.log("foundDays for " + eventRecord.name);
            //   console.log(foundDays);
            //   console.log(monthDayList);
            //   console.log("start local: " + eventRecord.eventStart.format('MM-DD-YYYY H:mm'));
            //   console.log("end local: " + eventRecord.eventEnd.format('MM-DD-YYYY H:mm'));
            // } else {
            //   console.log("Processing " + eventRecord.name);
            // }

            for (let day = 0; day < foundDays.length; day++) {
              let dayBegin = foundDays[day].actualDate;
              let dayEnd = dayBegin.clone().endOf('day');
              let truncBegin = moment.max(eventRecord.eventStart, dayBegin);
              let truncEnd = moment.min(eventRecord.eventEnd, dayEnd);

              monthly_data.push({
                title: name,
                name: name,
                percentage,
                allDay: false,
                start: truncBegin,
                end: truncEnd, // incorrect but we don't use the end and it makes the calendar render weird things if it spans days
                type: type,
                color: color,
                info: parsedInfo,
                startTimestamp: startTimestamp,
                endTimestamp: endTimestamp,
                until: eventRecord.until,
                repeat,
                restaurantId,
                EventMenus,
                id,
              });

              // if (name == "Boykin Rehearsal Dinner") {
              //   console.log("");
              //   console.log(name);
              //   console.log("timestamp: " + startTimestamp);
              //   console.log("start local: " + eventRecord.eventStart.format('MM-DD-YYYY H:mm'));
              //   console.log("end local: " + moment.min(eventRecord.eventEnd, eventRecord.eventStart.clone().endOf('day')).format('MM-DD-YYYY H:mm'));
              // }
            }
          } else {
            // if (eventRecord.repeat === 'repeat')
            // identify if this repeating event *could* belong to this day;
            foundDays = monthDayList.filter((d) => {
              return (
                eventRecord.eventStart.isBefore(d.actualDate.clone().endOf('day')) &&
                eventRecord.eventUntil.isSameOrAfter(d.actualDate)
              );
            });

            // console.log(name);
            // console.log(eventRecord.eventStart.format('MM-DD-YYYY H:mm'));
            // console.log(eventRecord.eventEnd.format('MM-DD-YYYY H:mm'));
            // console.log(eventRecord.eventUntil.format('MM-DD-YYYY H:mm'));

            for (let day = 0; day < foundDays.length; day++) {
              const dayBegin = foundDays[day].actualDate.clone();
              if (repeatDayIsValid(eventRecord, dayBegin)) {
                var rpt_start = dayBegin; // beginning of a valid day
                rpt_start.add(moment.duration(eventRecord.eventStart.format('HH:mm'))); // offset by event time

                monthly_data.push({
                  title: name,
                  name: name,
                  allDay: false,
                  start: rpt_start,
                  end: rpt_start.clone().endOf('day'),
                  type: type,
                  color: color,
                  info: parsedInfo,
                  startTimestamp: startTimestamp,
                  endTimestamp: endTimestamp,
                  until: eventRecord.until,
                  repeat,
                  restaurantId,
                  EventMenus,
                  id,
                });

                // if (name == "Jorge and James ") {
                //   console.log(name);
                //   console.log("timestamp: " + startTimestamp);
                //   console.log("start local: " + rpt_start.format('MM-DD-YYYY H:mm'));
                //   console.log("end local: " + rpt_start.clone().endOf('day').format('MM-DD-YYYY H:mm'));
                // }
              }
            }
          }
        }

        console.log('Final Month Events');
        // console.log("current local time: " + moment().format('MM-DD-YYYY H:mm'));
        console.log(monthly_data);

        setMonthlyEvents(monthly_data);
      }
    }
  );
};
