/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useCallback } from 'react';
import './index.css';
import ProgressCircle from '../../components/progressCircle/progresscircle';
import StationGeneralMobile from './station-general-mobile';
import StationFilteredMobile from './station-filtered-mobile';
import ErrorPage from '../../components/ErrorPages/ErrorPage';
import more from '../../img/station-more.png';
import attach from '../../img/station-attach.png';
import { getKitchenStationIngredients } from '../../services/station.service';
import { all } from '../../services/operator.service';
import { searchDocLinks } from '../../services/doc.service';
import { getCheckList } from '../../services/checklist.service';
import { searchmeas } from '../../services/measurement.service';
import DocumentList from '../../components/DocumentList/DocumentList';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import useWindowSize from '../../components/windowdimensions/windowSize';
import { BacktoHeaderMobile } from '../../components/Header/HeadMobile.js';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { isEmpty } from 'lodash';
import { formatIfNeeded, reformatVal } from './utilities';
import { arrangeDBMeasurementUnits } from '../Recipes/utilities';
import { Dropdown, Menu, Select, Drawer } from 'antd';
import {
  getKitchenStations,
  getStation,
  getAllStationOperators,
  updateKitchenStation,
} from '../../services/station.service';
import { UserImage, StationAssigneeEdit } from '../../components/common/common_ui';

const { Option } = Select;

const StationDetailsMobile = (props) => {
  const [filtered, isFiltered] = useState(false);
  const [stations, setStations] = useState([]);
  const [recList, setRecList] = useState([]);
  const [activeRecs, setActiveRecs] = useState([]);
  const [activeChk, setActiveChk] = useState([]);
  const [showRec, setShowRec] = useState({});
  const [pristineIngs, setPristineIngs] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedStation, setSelectedStation] = useState('');
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const [selectedImg, setSelectedImg] = useState('');
  const [healthCheckCompleted, setHealthCheckCompleted] = useState(0);
  const [updateHealthCheck, setUpdateHealthCheck] = useState(true);
  const [neededIngs, setNeeded] = useState(0);
  const [percentNeeded, setPercentNeeded] = useState(0);
  const [updateNeeded, setUpdateNeeded] = useState(true);
  const [completed, setCompleted] = useState(0);
  const [updateCompleted, setUpdateCompleted] = useState(true);
  const [totalIngs, setTotalIngs] = useState(0);
  const [completedIngs, setCompletedIngs] = useState(0);
  const [selectedStationChecklists, setSelectedStationChecklists] = useState([]);
  const [currentSelectedStation, setCurrentSelectedStation] = useState('');
  const [stationTasks, setStationTasks] = useState([]);
  const [assignedToMe, setAssignedToMe] = useState('');
  const [hasList, setHasList] = useState(false);
  const [documents, setDocuments] = useState(0);
  const [visible, updateVisible] = useState(false);
  const [docLinks, setDocLinks] = useState({});
  const [links, setLinks] = useState({});
  const [checklistItemUpdate, setChecklistItemUpdate] = useState('');
  const [stationIngsUpdate, setStationIngsUpdate] = useState('');
  const [hasRecipeLists, setHasRecipeLists] = useState(false);
  const [showChecklists, setShowChecklists] = useState(false);
  const [stationMenuUpdate, setStationMenuUpdate] = useState('');
  const [stationRestoId, setStationRestoId] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [operatorsToChoose, setOperatorsToChoose] = useState([]);
  const [locationOperators, setLocationOperators] = useState([]);
  const [searchedAssignee, searchAssignee] = useState('');
	const [measurements, setMeasurements] = useState([]);
  const [isFirstLoading, setFirstLoading] = useState(true);

  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/checklist`));
  const [eventSocket2] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/station`));
  const [eventSocket3] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/menu`));

  const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  const resto_name = (JSON.parse(localStorage.getItem('restaurant')) || {}).name;
  const user_id = (JSON.parse(localStorage.getItem('operator')) || {}).id;

  let userInfo = JSON.parse(localStorage.getItem('operator')) || {};
  let userRestaurants = userInfo.restaurants;

  const windowSize = useWindowSize();

  const getStationIngredients = (station) => {
    getKitchenStationIngredients(station).then((ings) => {
      if (ings && ings.length > 0) {
        let formattedQuantities = ings.map((ing, i) => {
          ing.rowKey = `${i}-${ing.recipeId}-${ing.courseId}-${ing.menuId}-${ing.ingredientId}`;
          const formatOne = 
            ing?.ingredientQty * 1 > 0 && ing?.measurementId > 0 ? 
            reformatVal(ing?.ingredientQty, ing.measurementName, ing.ingredientId, measurements) :
            undefined;
          const formatTwo = ing?.ingredientQty * 1 > 0 ? formatIfNeeded(ing.ingredientQty) : undefined;
          ing.formattedQty = 
            formatTwo != undefined ?
            formatTwo :
            formatOne != undefined ?
            formatOne :
            '0';
          return ing;
        });
        setPristineIngs(formattedQuantities);
      }
    });
  }

  const getDocLinks = (stationId) => {
    let params = {
      type: 'kitchen',
      linkId: stationId.toString()
    }
    searchDocLinks(params)
      .then((res) => {
        console.log(res);

        if (res && res.length > 0) {
          let links = res;
          let removeDeleted = links.filter((l) => l.Document.folderId !== null);
          const list = Array.from(
            removeDeleted.reduce(
              (m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]),
              new Map()
            ),
            ([linkId, documentId]) => ({ linkId, documentId })
          );

          console.log(list);
          setDocuments(list[0].documentId.length);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  const computeOverallStatusIngredients = (data, filtered = true) => {
    let ingredients = [];
    if (filtered) {
      ingredients = data;
    } else {
      ingredients = data.reduce((res, category) => res.concat(category.children), []);
    }

    let completedHealthChecks = ingredients.filter((h) => h.healthCheck == 2);
    let healthCheckPercentage = Math.round(
      (parseInt(completedHealthChecks.length) / parseInt(ingredients.length)) * 100
    );
    if (updateHealthCheck && updateCompleted && updateNeeded) {
      let healthPercent = isNaN(parseInt(healthCheckPercentage)) ? 0 : healthCheckPercentage;
      console.log(healthPercent);
      setHealthCheckCompleted(healthPercent);

      let completedIngs = ingredients.filter((h) => h.completed == 2);
      let completedPercentage = Math.round((parseInt(completedIngs.length) / parseInt(ingredients.length)) * 100);

      let itemsNeeded = ingredients.filter((h) => h.needed == 2);
      let itemsPercentage = Math.round((parseInt(itemsNeeded.length) / parseInt(ingredients.length)) * 100);

      let completePercent = isNaN(parseInt(completedPercentage)) ? 0 : completedPercentage;

      setCompleted(completePercent);
      setCompletedIngs(completedIngs.length);
      setTotalIngs(ingredients.length);
      setNeeded(itemsNeeded.length);
      setPercentNeeded(itemsPercentage);
    }
  };

  const buildRecipeIngredientsView = (data, key) => {
    const toTable = data
      .reduce((toTable, item) => {
        if (key === item.rowKey.slice(item.rowKey.indexOf('-') + 1, item.rowKey.lastIndexOf('-'))) {
          toTable.push(item);
        }
        return toTable;
      }, []);

    if (toTable.length > 0) {
      computeOverallStatusIngredients(toTable);
      let sortedTable = toTable.sort((a, b) => a.ingredientOrder - b.ingredientOrder || a.name.localeCompare(b.name));
      console.log('SORTED TABLE', sortedTable);
      setTableData(sortedTable);
    } else {
      setTableData([]);
    }
  }

  const buildCategorizedIngredientsView = (data) => {
    let toTable = [];
    toTable = data
      .reduce((categories, item) => {
        let theCategory = {
          name: item.categoryName,
          rowKey: `station-${props.match.params.id}-${item.categoryName}`,
          type: 'parent',
          children: [],
          childrenCount: 0,
        };
        const existingCategory = categories.findIndex((cat) => cat.name === item.categoryName);
        if (item.rowKey == undefined) {
          item.rowKey = `${item.key}-${Math.random().toString()}`;
        }
        if (existingCategory === -1) {
          theCategory.children.push(item);
          theCategory.childrenCount += 1;
          categories.push(theCategory);
        } else {
          categories[existingCategory].childrenCount += 1;
          categories[existingCategory].children.push(item);
          categories[existingCategory].children = categories[existingCategory].children.sort((a, b) =>
            a.ingredientId > b.ingredientId ? 1 : a.ingredientId < b.ingredientId ? -1 : 0
          );
        }
        return categories;
      }, []);
    if (toTable.length > 0) {
      const sortedTable = toTable.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
      );
      computeOverallStatusIngredients(sortedTable, false);
      console.log('SORTED TABLE', sortedTable);
      setTableData(sortedTable);
    } else {
      setTableData([]);
    }
  };

  const checklistCompletedPercent = (() => {
    let totalChecklist = 0;
    let checkeditems = 0;
    for (let i = 0; i < stationTasks.length; i++) {
      const { children } = stationTasks[i];
      for (let j = 0; j < children.length; j++) {
        const { complete } = children[j];
        totalChecklist++;
        if (complete == 2) {
          checkeditems++;
        }
      }
    }
    return totalChecklist > 0 ? Math.round((checkeditems / totalChecklist) * 100) : 0;
  })();

  useEffect(() => {
    all(0, 1000).then((res) => {
      let getUser = res.map((r) => {
        return {
          username: r.firstName + ' ' + r.lastName,
          image: r.info ? r.info?.userImageThumb || r.info?.userImage || r.info?.userImg : '',
        };
      });
      let filterUser = getUser.find((user) => user.username == selectedAssignee);
      setSelectedImg(filterUser?.image);
    });
  }, [selectedAssignee]);

  const getCheckListss = (table) => {
    getCheckList({
      restaurantId: resto_id,
      station_id: parseInt(props.match.params.id),
    }).then((res) => {
      let selectedChecklist = res?.checklists?.filter((c) => JSON.parse(c.ChecklistItems).length > 0);

      if (selectedChecklist.length === 0) {
        setShowChecklists(false);
      } else {
        setShowChecklists(true);
      }

      if (selectedChecklist) {
        let lists = selectedChecklist.map((s) => {
          let obj = {
            key: s.id + 'CK',
            id: s.id,
            name: s.title,
            image: s.info && (s.info?.imageUrl || s.info?.imageUrlThumb) ? s.info?.imageUrl || s.info?.imageUrlThumb : '',
            selected: 1,
            itemsTotal: JSON.parse(s.ChecklistItems).length,
            itemsCompleted: JSON.parse(s.ChecklistItems).filter((f) => f?.info?.complete == 2).length,
          };
          return obj;
        });
        let stationSelectors = [
          {
            key: '1',
            name: 'Checklists',
            list: [
              {
                name: '',
                list: lists,
              },
            ],
          },
        ];
  
        if (table.type == 'general') {
          setSelectedStationChecklists(stationSelectors);
        }
  
        if (lists && lists.length > 0) {
          setHasList(true);
        } else {
          setHasList(false);
        }
  
        let tasks = selectedChecklist.map((c) => {
          return JSON.parse(c.ChecklistItems);
        });
  
        let merged = [].concat.apply([], tasks);
  
        merged.sort((x, y) => {
          return x.id - y.id;
        });
  
        let taskItems = merged.map((s) => {
          const assignee = (() => {
            let a = '';
            if (!isEmpty(s.Station)) {
              a = s.Station.name;
            }
            if (!isEmpty(s.Operator)) {
              a = `${s.Operator.firstName} ${s.Operator.lastName} `;
            }
            if (!isEmpty(s.Event)) {
              a = s.Event.name;
            }
            return a;
          })();
          let parsedInfo = s.info;
          let obj = {
            key: s.id,
            name: s.title,
            type: 'child',
            completed: 1,
            checklistId: s.checklistId,
            parentId: s.checklistId.toString() + 'CK',
            keyId: s.checklistId.toString(),
            checked: parsedInfo.checked,
            complete: parsedInfo.complete == 0 ? 1 : parsedInfo.complete,
            description: parsedInfo.description,
            details: parsedInfo.details,
            tags: parsedInfo.tags,
            dueDate: s.dueDate,
            project: s.project,
            priority: s.priority,
            assignee: assignee,
          };
          return obj;
        });
  
        let taskItems1 = merged.map((s) => {
          const assignee = (() => {
            let a = '';
            if (!isEmpty(s.Station)) {
              a = s.Station.name;
            }
            if (!isEmpty(s.Operator)) {
              a = `${s.Operator.firstName} ${s.Operator.lastName} `;
            }
            if (!isEmpty(s.Event)) {
              a = s.Event.name;
            }
            return a;
          })();
          let parsedInfo = s.info;
          let obj = {
            key: s.id,
            name: s.title,
            type: 'child',
            completed: 1,
            checklistId: s.checklistId,
            parentId: s.checklistId.toString() + 'CK',
            keyId: s.checklistId.toString(),
            checked: parsedInfo.checked,
            complete: parsedInfo.complete == 0 ? 1 : parsedInfo.complete,
            description: parsedInfo.description,
            details: parsedInfo.details,
            tagList: parsedInfo.tags,
            dueDate: s.dueDate,
            project: s.project,
            priority: s.priority,
            assignee: assignee,
          };
          return obj;
        });
  
        let taskListTile = selectedChecklist.map((s) => {
          let obj = {
            key: s.id + 'CK',
            name: s.title,
            type: 'parent',
            ingredients: 1,
            id: s.id,
            image: s.info && (s.info?.imageUrl || s.info?.imageUrlThumb) ? s.info?.imageUrl || s.info?.imageUrlThumb : '',
            children: [
              {
                key: s.id,
                type: 'child',
                parentId: s.id + 'CK',
                tags: taskItems1.filter((t) => t.checklistId == s.id),
              },
            ],
          };
          return obj;
        });
  
        //setStationIngs(taskListTile);
  
        let taskList = selectedChecklist.map((s) => {
          let obj = {
            key: s.id + 'CK',
            name: s.title,
            type: 'parent',
            ingredients: 1,
            id: s.id,
            image: s.info && (s.info?.imageUrl || s.info?.imageUrlThumb) ? s.info?.imageUrl || s.info?.imageUrlThumb : '',
            children: taskItems.filter((t) => t.checklistId == s.id),
          };
          return obj;
        });
  
        setStationTasks(taskList);
        setActiveChk(taskList);
      }
    });
  };

  useEffect(() => {
    if (props.location?.state && props.location.state?.restaurantId) {
      setStationRestoId(props.location.state.restaurantId);
    } else {
      getStation(props.match.params.id).then((res) => setStationRestoId(res.restaurantId));
    }
  }, []);

  // to determine if location of station accessed is the same as user's current location
  const loadOrRefresh = (stationRestoId) => {
    if (stationRestoId != resto_id) {
      const restaurantName = userRestaurants.find((r) => r.id === stationRestoId).name;
      localStorage.setItem(
        'restaurant',
        JSON.stringify({
          id: stationRestoId.toString(),
          name: restaurantName,
        })
      );
      localStorage.setItem('restaurantId', stationRestoId.toString());
      window.location.reload();
    } else {
      setStationRestoId(parseInt(resto_id));
      getKitchenStations(resto_id).then((res) => {
        setStations(res.kitchens);
        let selected = res.kitchens.filter((r) => r.id == props.match.params.id);
        setSelectedAssignee(selected[0]?.assignee);
        setCurrentSelectedStation(selected[0]?.station);
        if (user_id == selected[0]?.assignee_id) {
          setAssignedToMe('2');
        } else {
          setAssignedToMe('1');
        }
      });
    }
  }

  useEffect(() => {
    if (stationRestoId > 0) {
      let accountId = localStorage.getItem('operator') != undefined && 
        JSON.parse(localStorage.getItem('operator')).accountId != null ? 
        JSON.parse(localStorage.getItem('operator')).accountId : '';
    
      if (locationOperators.length > 0) {
        getAllStationOperators(resto_id).then((res) => {
          setLocationOperators(res);
        });
      }
      searchmeas({ accountId: accountId }).then((res) => {
        setMeasurements(arrangeDBMeasurementUnits(res));
      });
      // detects if url has indicated kitchen station id
      if (props.match.params.id) {
        setSelectedStation(props.match.params.id);
        loadOrRefresh(stationRestoId);
      } else {
        setStationRestoId(parseInt(resto_id));
        getKitchenStations(resto_id).then((res) => {
          setStations(res.kitchens);
          setSelectedStation(res.kitchens[0].id);
          setSelectedAssignee(res.kitchens[0].assignee);
          setCurrentSelectedStation(res.kitchens[0].station);
          if (user_id == res.kitchens[0].assignee_id) {
            setAssignedToMe('2');
          } else {
            setAssignedToMe('1');
          }
        });
      }
    }
  }, [stationRestoId]);

  useEffect(() => {
    getDocLinks(props.match.params.id);
    getStationIngredients(props.match.params.id);
    getCheckListss({ type: 'general', id: '' });
  }, [props.match.params.id]);

  useEffect(() => {
    if (pristineIngs.length > 0 && isFirstLoading) {
      if (recList.length === 0) {
        const toFastSelect = pristineIngs.reduce((result, item) => {
          const newMenu = {
            key: `${item.menuId}`,
            menuId: item.menuId,
            menuName: item.menuName,
            children: [],
            active: 1,
          };
          const newCourse = {
            key: `${item.courseId}-${item.menuId}`,
            courseId: item.courseId,
            courseName: item.courseName,
            courseOrder: item.courseOrder,
            children: [],
          };
          const newRecipe = {
            key: `${item.recipeId}-${item.courseId}-${item.menuId}`,
            recipeId: item.recipeId,
            recipeName: item.recipeName,
            recipeOrder: item.recipeOrder,
            recipeAssignDate: item.recipeAssignDate,
            recipeImage: item.recipeImage,
            menuId: item.menuId,
            active: 1,
          };
  
          const existingMenu = result.findIndex((i) => i.menuId === item.menuId);
          // if there are no menu item existing
          if (existingMenu > -1) {
            const existingCourse = result[existingMenu].children.findIndex((i) => i.courseId === item.courseId);
            // if there are no course item existing
            if (existingCourse === -1) {
              newCourse.children = [].concat([newRecipe]);
              result[existingMenu].children = result[existingMenu].children
                .concat([newCourse])
                .sort((a, b) => (a.courseOrder > b.courseOrder ? 1 : a.courseOrder === b.courseOrder ? 0 : -1))
                .sort((a, b) => (a.courseId > b.courseId ? 1 : a.courseId === b.courseId ? 0 : -1));
            } else {
              const existingRecipe = result[existingMenu].children[existingCourse].children.findIndex(
                (i) => i.recipeId === item.recipeId
              );
              // if there are no recipe item existing
              if (existingRecipe === -1) {
                result[existingMenu].children[existingCourse].children = result[existingMenu].children[existingCourse].children
                  .concat([newRecipe])
                  .sort((a, b) => (a.recipeId > b.recipeId ? 1 : a.recipeId === b.recipeId ? 0 : -1))
                  .sort((a, b) => (a.recipeOrder > b.recipeOrder ? 1 : a.recipeOrder === b.recipeOrder ? 0 : -1));
              }
            }
          } else {
            newCourse.children = [].concat([newRecipe]);
            newMenu.children = [].concat([newCourse]);
            result.push(newMenu);
          }
          return result;
        }, []);
        setRecList(toFastSelect);
        setHasRecipeLists(true);
      }
      setFirstLoading(false);
    }

    if (pristineIngs?.length > 0) {
      if (activeRecs.length == 1) {
        buildRecipeIngredientsView(pristineIngs, activeRecs[0].key);
      } else if (activeRecs.length > 1) {
        buildCategorizedIngredientsView(pristineIngs, activeRecs);
      } else {
        if (!showChecklists) {
          buildCategorizedIngredientsView(pristineIngs);
        }
      }
    } else {
      setTableData([]);
    }
  }, [pristineIngs, activeRecs, isFirstLoading]);

  const menu = (
    <Menu className="menu-  if (selectedChecklist.length === 0) -preview">
      <Menu.Item className="menu-show-preview">
        <span
          style={{ color: 'white' }}
          onClick={(e) => {
            toggleEditMode();
          }}
        >
          Reassign
        </span>
      </Menu.Item>
    </Menu>
  );

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const showFilter = (data) => {
    console.log('STILL FILTERED?', data);
    isFiltered(data);
  };

  useEffect(() => {
    if (filtered) {
      console.log('showing filtered data');
    } else {
      console.log('showing all data');
    }
  }, [filtered]);

  const selectStation = (event) => {
    setCurrentSelectedStation(event);
    let getId = stations.find((s) => s.station == event).id;
    setSelectedStation(getId);
    let selected = stations.filter((r) => r.id == getId);
    setSelectedAssignee(selected[0].assignee);
    if (user_id == selected[0].assignee_id) {
      setAssignedToMe('2');
    } else {
      setAssignedToMe('1');
    }
    localStorage.removeItem('checkedRec');
    const url = `/station/${getId}`;
    props.history.push(url);
    window.location.reload();
  };

  const updateActiveRecs = useCallback((data) => {
    setActiveRecs(data);
  }, []);

  const updateActiveChks = useCallback((data) => {
    setActiveChk(data);
    if (data?.length == 0) {
      let testGenData = tableData.map((t) => {
        t.ingredients = 0;
        t.children = [];
        return t;
      });

      const result2 = Array.from(new Set(testGenData.map((s) => s.name))).map((lab) => {
        let childArr = testGenData.filter((s) => s.name === lab).map((e) => e.children);
        let child = [].concat.apply([], childArr);
        let filterChild = child.filter((c) => {
          return recList.find((m) => {
            return m.id == c.menuId;
          });
        });

        return {
          name: lab,
          children: filterChild,
          categoryId: testGenData.find((s) => s.name === lab).categoryId,
          ingredients: filterChild.length,
          key: testGenData.find((s) => s.name === lab).key,
          type: testGenData.find((s) => s.name === lab).type,
        };
      });

      setTableData(result2);
    }
  }, []);

  const filterCompletedHealthChecks = useCallback(
    () => {
      localStorage.removeItem('checkedRec');
      let filterbyCompletedHealthChecks = pristineIngs.filter((ing) => ing.healthCheck == 1);
      // setTableData(filterbyCompletedHealthChecks);
      isFiltered(false);
      buildCategorizedIngredientsView(filterbyCompletedHealthChecks);
      setUpdateHealthCheck(false);
    },
    [pristineIngs]
  );

  const filterCompleted = useCallback(
    () => {
      localStorage.removeItem('checkedRec');
      let filterbyCompleted = pristineIngs.filter((ing) => ing.completed == 1);
      // setTableData(filterbyCompleted);
      isFiltered(false);
      buildCategorizedIngredientsView(filterbyCompleted);
      setUpdateCompleted(false);
    },
    [pristineIngs]
  );

  const filterProductionReady = useCallback(
    () => {
      localStorage.removeItem('checkedRec');
      let filterbyProductionReady = pristineIngs.filter((ing) => ing.healthCheck == 2 && ing.completed == 1);
      // setTableData(filterbyProductionReady);
      isFiltered(false);
      buildCategorizedIngredientsView(filterbyProductionReady);
      setUpdateHealthCheck(false);
      setUpdateCompleted(false);
      setUpdateNeeded(false);
    },
    [pristineIngs]
  );

  const filterNeeded = useCallback(
    () => {
      localStorage.removeItem('checkedRec');
      let filterbyList = pristineIngs.filter((ing) => ing.ordered >= 0);
      // setTableData(filterbyList);
      isFiltered(false);
      buildCategorizedIngredientsView(filterbyList);
      setUpdateHealthCheck(false);
      setUpdateCompleted(false);
      setUpdateNeeded(false);
    },
    [pristineIngs]
  );

  const updateIngredientState = useCallback(
    (data) => {
      eventSocket2.emit('updatesStationIngs', 'updateIngredients');
    },
    [selectedStation]
  );

  const updateTaskState = useCallback((data) => {
    getCheckListss(data);
    if (data == 'general') {
      eventSocket.emit(
        'updateStationTask',
        JSON.stringify({
          id: data.id,
        })
      );
    }
  }, []);

  const closeDrawer = useCallback((data) => {
    updateVisible(false);
    updateIngredientState(true);
  }, []);

  const showDrawer = (e) => {
    e.preventDefault();
    updateVisible(true);
    setDocLinks({
      type: 'kitchen',
      linkId: props.match.params.id,
    });
    setLinks({
      type: 'kitchen',
      linkId: props.match.params.id,
      info: { title: currentSelectedStation },
    });
  };

  const onClose = () => {
    updateVisible(false);
  };

  const showAllIngredients = useCallback(
    () => {
      localStorage.removeItem('checkedRec');
      // setTableData(pristineIngs);
      localStorage.removeItem('checkedRec');
      isFiltered(false);
      buildCategorizedIngredientsView(pristineIngs);
      setUpdateHealthCheck(false);
      setUpdateCompleted(false);
      setUpdateNeeded(false);
    },
    [pristineIngs]
  );

  useEffect(() => {
    eventSocket.on('updatedStationTask', (message) => {
      let newData = JSON.parse(message);
      setChecklistItemUpdate(newData.id);
    });
    eventSocket2.on('updatedStationIngs', (message) => {
      setStationIngsUpdate(message);
    });
    eventSocket3.on('updatedStationMenu', (message) => {
      console.log('MENUUPDATED!!', message);
      setStationMenuUpdate(message);
    });
    return () => {
      eventSocket.disconnect();
      eventSocket2.disconnect();
      eventSocket3.disconnect();
    };
  }, []);

  useEffect(() => {
    if (checklistItemUpdate) {
      getCheckListss({ type: 'general', id: checklistItemUpdate });
      setTimeout(() => {
        setChecklistItemUpdate('');
      }, 2000);
    }
  }, [checklistItemUpdate]);

  useEffect(() => {
    if (stationIngsUpdate) {
      const stationId = selectedStation ? selectedStation : props.match.params.id;
      getStationIngredients(stationId);
    }
  }, [stationIngsUpdate]);

  useEffect(() => {
    if (stationMenuUpdate) {
      const stationId = selectedStation ? selectedStation : props.match.params.id;
      getStationIngredients(stationId);
    }
  }, [stationMenuUpdate]);

  useEffect(() => {
    setOperatorsToChoose([]);
    setLocationOperators([]);
    let restoId = window.location.href.includes('impersonate') ? localStorage.getItem('restaurantId') : resto_id;
    getAllStationOperators(restoId).then((res) => {
      setLocationOperators(res);
    });
  }, [window.location.href, resto_id]);

  useEffect(() => {
    if (searchedAssignee !== '') {
      const likeOperators = locationOperators.filter(
        (op) =>
          op.firstName.toLowerCase().indexOf(searchedAssignee.toLowerCase()) !== -1 ||
          op.lastName.toLowerCase().indexOf(searchedAssignee.toLowerCase()) !== -1
      );
      setOperatorsToChoose(likeOperators);
    } else {
      if (locationOperators.length > 0) {
        setOperatorsToChoose(locationOperators);
      }
    }
  }, [searchedAssignee, resto_id]);

  useEffect(() => {
    if (locationOperators.length > 0) {
      setOperatorsToChoose(locationOperators);
    }
  }, [locationOperators]);

  const editStationAssignee = (id, assignee_id, assignee) => {
    searchAssignee(assignee);
    setSelectedAssignee(assignee);
    updateKitchenStation(id, assignee_id).then((res) => {
      // notification.open({
      //   message: 'Successfully Reassigned Kitchen Station',
      // });
      toggleEditMode();
      window.location.reload();
    });
  };

  const onClickRemoveAssignee = () => {
    searchAssignee('');
    setOperatorsToChoose(locationOperators);
  };

  return userRestaurants && userRestaurants?.filter((r) => r.id === stationRestoId).length === 1 ? (
    <div>
      <div className="marginT10 windowHFull">
        {/* <div style={{ textAlign: 'center' }}>{isLoading && <Spin tip="Loading station data..."></Spin>}</div> */}
        <BacktoHeaderMobile restaurant={resto_name} backText="Back to Dashboard" backLink="/dashboard" />
        <div className="mobile-station-controlbox">
          <div className="mobile-station-controls">
            <div className="mobile-station-control1">
              <div className="station-assignee-image-container-mobile">
                <div className="station-assignee-image" alt="user-photo">
                  <UserImage src={selectedImg !== '' ? selectedImg : null} />
                </div>
              </div>
              {editMode ? (
                <div className="kitchen-station-assignee-container">
                  <StationAssigneeEdit
                    assigneeChoices={operatorsToChoose}
                    assignee={searchedAssignee.length !== '' ? searchedAssignee : selectedAssignee}
                    onChange={(value) => {
                      editStationAssignee(
                        `${props.match.params.id}`,
                        `${JSON.parse(value).id}`,
                        `${JSON.parse(value).name}`
                      );
                    }}
                    onSearch={(value) => {
                      searchAssignee(value);
                    }}
                    onClickRemove={() => {
                      onClickRemoveAssignee();
                    }}
                  />
                  {/* <Popover
                    content={chooseFromOperators}
                    overlayStyle={{ marginTop: '-5px', width: '140px', backgroundColor: '#454545', zIndex: '99999' }}
                    placement="bottom"
                    trigger="focus"
                    getPopupContainer={(trigger) => trigger.parentElement}
                  >
                    <input
                      type="text"
                      placeholder="Search Assignee"
                      value={searchedAssignee}
                      onChange={(e) => {
                        searchAssignee(e.target.value);
                      }}
                      className="kitchen-station-assignee-input"
                    />
                  </Popover> */}
                </div>
              ) : (
                <div className="mobile-stationName">{selectedAssignee}</div>
              )}

              <div>
                <RoleAccessibleComponent permittedRoles={['Chef', 'Admin', 'Superadmin', 'Dev Team']}>
                  <Dropdown overlay={menu}>
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                      <img src={more} alt="more" className="stationMore" width="30" />
                    </a>
                  </Dropdown>
                </RoleAccessibleComponent>
              </div>
            </div>
            <div className="mobile-station-control2">
              <div>
                {documents > 0 ? (
                  <div onClick={(e) => showDrawer(e)}>
                    {documents}
                    <img src={attach} className="comment-menu" alt="comment" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div>
            <Select
              className="station-select-mobile"
              onChange={selectStation}
              value={currentSelectedStation}
              showArrow={false}
              showSearch
            >
              {stations.map((station, i) => (
                <Option key={`station-${i + 1}`} value={station.station}>
                  {station.station}
                </Option>
              ))}
            </Select>
          </div>
          <div className="station-progressbox">
            {hasList ? (
              <div className="station-progress-boxmobile">
                <ProgressCircle
                  progress={checklistCompletedPercent}
                  size={50}
                  strokeWidth={8}
                  xText={25}
                  yText={30}
                  type="percent"
                  resolution="mobile"
                />
                <div>Checklists completed</div>
              </div>
            ) : (
              ''
            )}
            <div className="station-progress-boxmobile">
              <ProgressCircle
                progress={healthCheckCompleted}
                size={50}
                strokeWidth={8}
                xText={25}
                yText={30}
                type="percent"
                resolution="mobile"
              />
              <div>Quality check completed</div>
            </div>
            <div className="station-progress-boxmobile">
              <ProgressCircle
                progress={completed}
                size={50}
                strokeWidth={8}
                xText={25}
                yText={30}
                type="percent"
                resolution="mobile"
              />
              <div>Production ready</div>
            </div>
            <div className="station-progress-boxmobile">
              <ProgressCircle
                progress={completed}
                size={50}
                strokeWidth={8}
                xText={25}
                yText={30}
                type="ingredients"
                resolution="mobile"
                ing={completedIngs}
                ingtotal={totalIngs}
              />
              <div>Production completed</div>
            </div>
          </div>
        </div>
      </div>
      {!filtered ? (
        <div>
          <StationGeneralMobile
            filtered={filtered}
            stationTasks={stationTasks}
            selectedStationChecklists={selectedStationChecklists}
            showFilter={showFilter}
            updateActiveRecs={updateActiveRecs}
            updateActiveChks={updateActiveChks}
            recList={recList}
            tableData={tableData}
            setTableData={setTableData}
            activeRecs={activeRecs}
            activeChk={activeChk}
            filterCompletedHealthChecks={filterCompletedHealthChecks}
            filterProductionReady={filterProductionReady}
            filterCompleted={filterCompleted}
            filterNeeded={filterNeeded}
            updateIngredientState={updateIngredientState}
            assignedToMe={assignedToMe}
            updateTaskState={updateTaskState}
            stationId={props.match.params.id}
            hasChecklist={hasList}
            documents={documents}
            isEvent={false}
            pristineIngs={pristineIngs}
            showAllIngredients={showAllIngredients}
            hasRecipeLists={hasRecipeLists}
            setPristineIngs={setPristineIngs}
          />
        </div>
      ) : (
        <div>
          <div>
            <StationFilteredMobile
              filtered={filtered}
              showRec={showRec}
              setShowRec={setShowRec}
              updateActiveRecs={updateActiveRecs}
              recList={recList}
              tableData={tableData}
							stationTasks={stationTasks}
              showFilter={showFilter}
              activeChk={activeChk}
              activeRecs={activeRecs}
              filterCompletedHealthChecks={filterCompletedHealthChecks}
              filterCompleted={filterCompleted}
              filterNeeded={filterNeeded}
              updateIngredientState={updateIngredientState}
              filterProductionReady={filterProductionReady}
              updateActiveChks={updateActiveChks}
              updateTaskState={updateTaskState}
              // selectedStationChecklists={stationTasks}
              selectedStationChecklists={selectedStationChecklists}
              selectedStationChecklist={selectedStationChecklists}
              assignedToMe={assignedToMe}
              stationId={props.match.params.id}
              hasChecklist={hasList}
              documents={documents}
              isEvent={false}
              pristineIngs={pristineIngs}
              showAllIngredients={showAllIngredients}
              hasRecipeLists={hasRecipeLists}
            />
          </div>
        </div>
      )}
      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={visible}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentList
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks={docLinks}
          links={links}
          isMobile={true}
          documentsIn="kitchen"
        />
      </Drawer>
    </div>
  ) : (
    <div className="common-page-container">
      <ErrorPage copy="Kitchen Closed! This content is unavailable to your user account. If you feel you should have access to this content, please contact your local administrator" />
    </div>
  );
};

export default StationDetailsMobile;
