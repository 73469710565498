/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './index.css';

import _, { remove } from 'lodash';
import moment from 'moment';

import { Table, Menu, Dropdown, Switch, Spin, Empty, Row, Col } from 'antd';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';

import AddIngredientPopup from './AddIngredientPopup';
import delete_img from '../../img/delete-icon.png';

import { PlusOutlined } from '@ant-design/icons';
import empty from '../../img/cidekic_logo_bw.svg';
import collapseall from '../../img/check-collapse.png';

import {
  bulkAddItems as addItems,
  getItems as getShoplistItems,
  deleteItem as removeFromList,
  itemOrdered as checkItem,
  itemNeeded as revertItem,
  getItemRequester,
} from '../../services/shoplist.service';

import { ShoppingCartIcon, ThumbnailImage, ChefIcon } from '../../components/common/common_ui';

const ShoppingListMobile = (props) => {
  // SUBCOMPONENT STATES
  // const [expandedRows, setExpandedRows] = useState([]);
  const [filterMenuName, setFilterMenuName] = useState('Needed');

  // STORAGE DATA
  const [restaurantName, setRestaurantName] = useState('');

  // TABLE DATA
  const [pristineData, setPristineData] = useState([]);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [newIngredients, setNewIngredients] = useState([]);

  // COMPONENT STATES
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearchResult, searchResultFound] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [orderedItemsOnly, showOrderedItemsOnly] = useState(false);
  const [allItems, showAllItems] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [viewChanged, isViewChanged] = useState(false);
  const [isCondensed, setToCondensed] = useState(false);
  const [currentSearchString, setCurrentSearchString] = useState('');
  const [originalSettings, setOriginalSettings] = useState({
    filter: '', // cached filter setting
    search: '', // cached search setting
    order: {
      // cached sort setting
      column: 'dateRequested',
      status: 'descend',
    },
  });

  const restaurantId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  const operatorId = (JSON.parse(localStorage.getItem('operator')) || {}).id;

  // CALLBACKS
  const finalizeRows = useCallback(
    (orderedItemsOnly, allItems, filteredBySearch = false, searchResults = []) => {
      let keySwitch = '';

      if (orderedItemsOnly) {
        keySwitch = 'ordered';
      } else if (allItems && !orderedItemsOnly) {
        keySwitch = 'all items';
      } else if (!allItems && !orderedItemsOnly) {
        keySwitch = 'needed';
      }

      let toTabularize = [];
      const currentOrderCol = originalSettings.order.column;
      const currentOrderStat = originalSettings.order.status;
      // tabularizes search results instead of whole shoplist item from database
      if (filteredBySearch && searchResults.length) {
        toTabularize = rearrangeTable(currentOrderCol, currentOrderStat, searchResults);
      } else {
        // if not searching, uses pristineData or if it exists, the saved table order, to tabularize
        // show all items
        if (allItems) {
          toTabularize = rearrangeTable(currentOrderCol, currentOrderStat, pristineData);
        } else {
          //filter out needed or ordered shoplist items
          let ingredientsFiltered = pristineData.filter((ing) => ing.ordered == ('ordered' === keySwitch));
          toTabularize = rearrangeTable(currentOrderCol, currentOrderStat, ingredientsFiltered);
        }
      }
      // saves rearranged table rows based on latest order
      setTableDataSource(toTabularize);
      setIsLoading(false);
    },
    [pristineData, orderedItemsOnly, allItems]
  );

  // COMPONENT FUNCTIONS:
  // a. triggerSearch - for manual search
  const triggerSearch = (e) => {
    searchShoplist(e.target.value);
  };
  // b. searchShoplist - search the Shopping List Table
  const searchShoplist = (searchString) => {
    setIsSearching(true);
    let inputValue = searchString;

    // if search input has term
    if (inputValue.length > 0) {
      const clonedData = _.cloneDeep(pristineData);
      let searchString = inputValue.toLowerCase();
      setCurrentSearchString(searchString);
      originalSettings.search = searchString;
      let results = clonedData.filter(searchAllArr(inputValue));

      filterOnSearch(results);
    } else {
      // reset searching state to false
      setIsSearching(false);
      setCurrentSearchString('');
      originalSettings.search = '';
      if (localStorage.getItem('shoplistSearchMob') != null) {
        localStorage.removeItem('shoplistSearchMob');
      }
    }
  };
  // c. filterOnSearch - filter based on search results
  const filterOnSearch = (res) => {
    // search for items with ingredient name / station name / requester name
    let searchResults = res.filter((item) => matchSearchKey(item));
    // if there is only one category to be shown, remove except items
    if (searchResults.length >= 1) {
      searchResultFound(true);
      finalizeRows(orderedItemsOnly, allItems, true, searchResults);
    } else {
      searchResultFound(false);
      setIsLoading(false);
    }
  };
  // d. searchAllArr - auxiliary function for filtering based on search string
  const searchAllArr = (text) => (value) => {
    if (!value) return false;
    const valueType = typeof value;

    if (valueType === 'string') {
      return value.toLowerCase().indexOf(text.toLowerCase()) > -1;
    }
    if (Array.isArray(value)) {
      return value.some(searchAllArr(text));
    }
    if (valueType === 'object') {
      return Object.values(value).some(searchAllArr(text));
    }
    return false;
  };
  // e. matchSearchKey - loops over shoplist rows that match search term with set criteria
  const matchSearchKey = useCallback(
    (item) => {
      return (
        item.name.toLowerCase().indexOf(currentSearchString) > -1 ||
        item.stationName?.toLowerCase().indexOf(currentSearchString) > -1 ||
        item.requesterName.toLowerCase().indexOf(currentSearchString) > -1 ||
        item.category.toLowerCase().indexOf(currentSearchString) > -1
      );
    },
    [currentSearchString]
  );
  // f. onSearchFocus - function when focused
  const onSearchFocus = (e) => {
    if (e.target.value === '' && originalSettings.search !== '') {
      if (localStorage?.getItem('shoplistSearchMob') != null) {
        e.target.value = localStorage.getItem('shoplistSearchMob');
      }
    }
  };

  // 2. Table Rows
  // a. handleRowExpand - handles table row expansion
  // const handleRowExpand = (record) => {
  //   if (expandedRows.includes(record.key)) {
  //     let filterRecord = expandedRows.filter((key) => key !== record.key);
  //     setExpandedRows(filterRecord);
  //     localStorage.setItem('shoplistOpenRows', JSON.stringify(filterRecord));
  //   } else {
  //     setExpandedRows([...expandedRows, record.key]);
  //     localStorage.setItem('shoplistOpenRows', JSON.stringify([...expandedRows, record.key]));
  //   }
  // };
  // b. collapseAll - collapse all open ingredient categories
  // const collapseAll = () => {
  //   setExpandedRows([]);
  //   localStorage.setItem('shopListOpenRows', JSON.stringify([]));
  // };

  // 3. Table Functions
  // a. filterShoplistMenu - menu items of dropdown [filterNeeded, filterOrdered]
  const filterNeeded = () => {
    setFilterMenuName('Needed');
  };
  const filterOrdered = () => {
    setFilterMenuName('Ordered');
  };
  const filterAll = () => {
    setFilterMenuName('All items');
  };
  const filterShoplistMenu = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={filterNeeded}>
        <span className="shoplist-filter-mob">Needed</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={filterOrdered}>
        <span className="shoplist-filter-mob">Ordered</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={filterAll}>
        <span className="shoplist-filter-mob">All Items</span>
      </Menu.Item>
    </Menu>
  );
  // b. toggleOrderedStatus - callback to setting item as 'ordered' or 'needed'
  const toggleOrderedStatus = (row) => {
    // let updatedPristineData = _.cloneDeep(pristineData);
    const currentDate = new Date() * 1;
    const statusChangeDetails = {
      operatorId: operatorId,
      dateUpdated: currentDate.toString(),
    };

    // if ordered, change to needed
    if (row.ordered) {
      revertItem(row.id, statusChangeDetails).then((result) => {
        setTimeout(() => {
          // notification.open({
          //   message: 'Reverted status back to needed.',
          // });
        }, 1000);
        updateOrderedStatus(result);
        // change count of "needed" and "ordered" ingredients for each category row
        // const updateCategoryIndex = updatedPristineData.findIndex((i) => i.name === row.category);

        // if updated item is a completed order, decrement orderedIngredients count, increment neededIngredients count
        // if (orderedItemsOnly) {
        //   updatedPristineData[updateCategoryIndex].ordered -= 1;
        //   updatedPristineData[updateCategoryIndex].needed += 1;
        //   // else, vice versa
        // } else {
        //   updatedPristineData[updateCategoryIndex].needed -= 1;
        //   updatedPristineData[updateCategoryIndex].ordered += 1;
        // }

        // change current value of record's "ordered" status
        // const updatedItemIndex = updatedPristineData[updateCategoryIndex].children.findIndex((i) => i.id === result.id);
        // updatedPristineData[updateCategoryIndex].children[updatedItemIndex].ordered = result.ordered;
        // setPristineData(updatedPristineData);

        // if (hasSearchResult) {
        //   let updatedSearchedData = _.cloneDeep(tableDataSource);
        //   const updatedItemIndex = updatedSearchedData[updateCategoryIndex].children.findIndex(
        //     (i) => i.id === result.id
        //   );
        //   updatedSearchedData[updateCategoryIndex].children[updatedItemIndex].ordered = result.ordered;
        //   setTableDataSource(updatedSearchedData);
        // }
      });
      // else, vice versa
    } else {
      checkItem(row.id, statusChangeDetails).then((result) => {
        setTimeout(() => {
          // notification.open({
          //   message: 'Changed status to ordered.',
          // });
        }, 1000);
        updateOrderedStatus(result);
        // change count of "needed" and "ordered" ingredients for each category row
        // const updateCategoryIndex = updatedPristineData.findIndex((i) => i.name === row.category);

        // if updated item is a completed order, decrement orderedIngredients count, increment neededIngredients count
        // if (orderedItemsOnly) {
        //   updatedPristineData[updateCategoryIndex].ordered -= 1;
        //   updatedPristineData[updateCategoryIndex].needed += 1;
        //   // else, vice versa
        // } else {
        //   updatedPristineData[updateCategoryIndex].needed -= 1;
        //   updatedPristineData[updateCategoryIndex].ordered += 1;
        // }

        // change current value of record's "ordered" status
        // const updatedItemIndex = updatedPristineData[updateCategoryIndex].children.findIndex((i) => i.id === result.id);
        // updatedPristineData[updateCategoryIndex].children[updatedItemIndex].ordered = result.ordered;
        // setPristineData(updatedPristineData);

        // if (hasSearchResult) {
        //   let updatedSearchedData = _.cloneDeep(tableDataSource);
        //   const updatedItemIndex = updatedSearchedData[updateCategoryIndex].children.findIndex(
        //     (i) => i.id === result.id
        //   );
        //   updatedSearchedData[updateCategoryIndex].children[updatedItemIndex].ordered = result.ordered;
        //   setTableDataSource(updatedSearchedData);
        // }
      });
    }
  };
  // c. updateOrderedStatus - callback after setting a shoplist item's status to 'ordered' or 'needed' and vice versa
  const updateOrderedStatus = (row) => {
    let updatedPristineData = _.cloneDeep(pristineData);
    const updatedItemIndex = updatedPristineData.findIndex((i) => i.id === row.id);
    updatedPristineData[updatedItemIndex].ordered = row.ordered;
    setPristineData(updatedPristineData);
    // if currently in search mode or filtered by status,
    if (isSearching || !allItems) {
      let updatedSearchedData = _.cloneDeep(tableDataSource);
      remove(updatedSearchedData, { id: row.id });
      updatedSearchedData = rearrangeTable(
        originalSettings.order.column,
        originalSettings.order.status,
        updatedSearchedData
      );
      setTableDataSource(updatedSearchedData);
    }
  };
  // d. removeItem - remove from shopping list
  const removeItem = (row) => {
    const itemId = row.id;
    // const itemCategory = row.category;
    let newPristineData = _.cloneDeep(pristineData);
    remove(newPristineData, { id: itemId });
    setPristineData(newPristineData);
    setTableDataSource(newPristineData);

    removeFromList(itemId).then(() => {
      // notification.open({
      //   message: 'Removing item from shopping list...',
      // });
      // if currently in search mode or filtered by status,
      if (isSearching || !allItems) {
        let updatedSearchedData = _.cloneDeep(tableDataSource);
        let searchedDataUpdated = rearrangeTable(
          originalSettings.order.column,
          originalSettings.order.status,
          updatedSearchedData.filter((item) => item.id !== itemId)
        );
        setTableDataSource(searchedDataUpdated);
      }
    });
  };
  // e. addNewItems - add new ingredient/s to shopping list
  const addNewItems = (items) => {
    // sorted out the needed information to display at table
    const itemsPrepared = items.map((item) => {
      return {
        ingredientCategoryId: parseInt(item.categoryId),
        category: item.category,
        imageUrl: item?.imageUrl ? item?.imageUrl : item?.info?.imageUrl ? item?.info?.imageUrl : '',
        imageUrlThumb: item?.imageUrlThumb
          ? item?.imageUrlThumb
          : item?.info?.imageUrlThumb
          ? item?.info?.imageUrlThumb
          : '',
        // subcategory: item.subcategory,
        ingredientId: parseInt(item.id),
        name: item.name,
      };
    });

    // prepare existing ingredient categories
    // let existingIngredients = _.cloneDeep(pristineData.sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0)));
    // const existingIngredientCategories = Array.from(new Set(existingIngredients.map((o) => o.category)));
    // let getIndexLastCat = existingIngredients[existingIngredients.length - 1].key;

    // prepare new records to Shoplist database
    const currentDate = new Date() * 1;
    let newShoppingListItems = itemsPrepared.map((n) => {
      return {
        operatorId: operatorId,
        ingredientId: n.ingredientId,
        ingredientCategoryId: n.ingredientCategoryId,
        restaurantId: restaurantId,
        dateTime: currentDate,
      };
    });
    const itemsToAdd = JSON.stringify(newShoppingListItems);

    // add new records to Shoplist
    addItems({ items: itemsToAdd })
      .then((newlyAddedItems) => {
        setTimeout(() => {
          // notification.open({
          //   message: 'Items added to shopping list!',
          // });
        }, 2000);

        const newItems = JSON.parse(newlyAddedItems);
        // newItems.sort((a, b) => a.category.toLowerCase().localeCompare(b.category.toLowerCase()));

        // after successfully adding requested items to Shoplist, get full name of requester
        getItemRequester(operatorId.toString()).then((res) => {
          const requesterName = res;
          // let updatedIngredients = [];

          // sort out new ingredients with existing categories FROM totally new ingredients
          let newIngs = newItems
            // .filter((item) => !existingIngredientCategories.includes(item.category))
            .map((item) => {
              const ingredientInfo = itemsPrepared.find((p) => p.ingredientId === item.ingredientId);
              return {
                key: item.id.toString(),
                id: parseInt(item.id),
                ingredientId: parseInt(item.ingredientId),
                name: ingredientInfo.name,
                category: item.category,
                ingredientCategoryId: parseInt(item.ingredientCategoryId),
                imageUrl: ingredientInfo?.imageUrl ? ingredientInfo?.imageUrl : '',
                imageUrlThumb: ingredientInfo?.imageUrlThumb ? ingredientInfo?.imageUrlThumb : '',
                requesterId: parseInt(item.operatorId),
                requesterName: requesterName,
                requestDate: item.dateTime,
                dateRequested: moment(item.dateTime).format('MM/DD/YYYY hh:mm A'),
                stationId: null,
                eventId: null,
                stationName: 'None',
                stationType: null,
                ordered: item.ordered,
                // type: 'child',
              };
            });
          // let newIngredientsWithExistingCats = newItems
          //   .filter((item) => existingIngredientCategories.includes(item.category))
          //   .map((item) => {
          //     const ingredientInfo = itemsPrepared.find((p) => p.ingredientId === item.ingredientId);
          //     return {
          //       id: parseInt(item.id),
          //       ingredientId: parseInt(item.ingredientId),
          //       category: item.category,
          //       name: ingredientInfo.name,
          //       imageUrl: ingredientInfo?.imageUrl ? ingredientInfo?.imageUrl : '',
          //       imageUrlThumb: ingredientInfo?.imageUrlThumb ? ingredientInfo?.imageUrlThumb : '',
          //       requesterId: parseInt(item.operatorId),
          //       requesterName: requesterName,
          //       requestDate: moment(item.dateTime).format('MM/DD/YYYY hh:mm A'),
          //       stationId: null,
          //       eventId: null,
          //       stationName: 'None',
          //       stationType: null,
          //       ordered: item.ordered,
          //       ingredientCategoryId: parseInt(item.ingredientCategoryId),
          //       type: 'child',
          //     };
          //   });

          // if there are new existing ingredients under existing categories
          // if (newIngredientsWithExistingCats.length > 0) {
          //   // aggregate number of items based on category name
          //   updatedIngredients = updatedIngredients.concat(
          //     Object.values(
          //       existingIngredients.concat(newIngredientsWithExistingCats).reduce((a, b) => {
          //         let tempKey = b.category;
          //         if (b.type === 'parent') {
          //           a[tempKey] = { ...b };
          //           return a;
          //         }
          //         if (b.type === 'child') {
          //           let updateCategoryChildren = [..._.cloneDeep(a[tempKey].children)];
          //           let categoryKey = a[tempKey].key;

          //           b.key = `${categoryKey}-${b.id}`;
          //           a[tempKey].children = updateCategoryChildren
          //             .concat({ ...b })
          //             .sort((x, y) => x.key.localeCompare(y.key));
          //           a[tempKey].needed += 1;
          //           return a;
          //         }
          //         return a;
          //       }, {})
          //     )
          //   );
          //   setPristineData((current) =>
          //     [...updatedIngredients].sort((a, b) => a.category.toLowerCase().localeCompare(b.category.toLowerCase()))
          //   );
          // }

          // if there are totally new ingredients from new categories
          if (newIngs.length > 0) {
            // let newIngredientsGrouped = newIngredientsAndCategories.reduce((a, b) => {
            //   // if ingredient is not the first instance of particular category, add to created category as child
            //   if (a.length >= 1 && a.find((c) => c.name === b.category) !== undefined) {
            //     let createdCategory = _.cloneDeep(a.find((c) => c.name === b.category));
            //     let createdCategoryIndex = a.findIndex((c) => c.name === b.category);
            //     const theNewIngredient = { ...b, key: `${createdCategory.key}-${b.id}` };
            //     let ingredientCategorized = [...createdCategory.children]
            //       .concat([{ ...theNewIngredient }])
            //       .sort((x, y) => x.key.localeCompare(y.key));
            //     createdCategory.children = [...ingredientCategorized];
            //     createdCategory.needed += 1;
            //     a[createdCategoryIndex] = createdCategory;
            //     return a;

            //     // else create the new category and add the first ingredient to it
            //   } else {
            //     getIndexLastCat += 1;
            //     let newCategory = {
            //       key: getIndexLastCat,
            //       name: b.category,
            //       category: b.category,
            //       ordered: 0,
            //       needed: 1,
            //       type: 'parent',
            //     };

            //     let newIngredients = { ...b, key: `${getIndexLastCat}-${b.id}` };
            //     newCategory.children = [].concat(newIngredients);
            //     a[a.length] = newCategory;
            //     return a;
            //   }
            // }, []);
            // updatedIngredients = updatedIngredients.concat([...newIngredientsGrouped]);
            setPristineData((current) => [...current, ...newIngs]);
            // if currently in search mode or filtered by status,
            if (isSearching || !allItems) {
              let updatedSearchedData = _.cloneDeep(tableDataSource);
              if (!orderedItemsOnly) {
                // only update table items when filtered to 'Needed'
                // otherwise no changing
                if (isSearching) {
                  // further determines which added item matches search term
                  let newSearchResults = newIngs.filter((item) => matchSearchKey(item));
                  if (newSearchResults.length > 0) {
                    updatedSearchedData = updatedSearchedData.concat([...newSearchResults]);
                    updatedSearchedData = rearrangeTable(
                      originalSettings.order.column,
                      originalSettings.order.status,
                      updatedSearchedData
                    );
                    setTableDataSource(updatedSearchedData);
                  }
                }
              }
            }
          }
          setNewIngredients([]);
        });
      })
      .catch((err) => {
        console.log(err);
        setNewIngredients([]);
      });
  };

  // 4. Table Columns
  // a. shoplistCols
  const shoplistCols = [
    {
      title: 'Sorted by Ingredients',
      dataIndex: 'name',
      sorter: (a, b) =>
        new Date(a.requestDate) * 1 < new Date(b.requestDate) * 1
          ? -1
          : new Date(a.requestDate) * 1 > new Date(b.requestDate)
          ? 1
          : 0,
      sortDirections: ['descend', 'ascend'],
      width: 320,
      ellipsis: true,
      render: (key, obj) => (
        <span className="shoplist-table-mobile">
          {' '}
          <span className="shoplist-title-row-container">
            {/* {obj.type === 'parent' && (
              <span className={obj.type == 'parent' ? '' : 'name-span-mobile'}>
                <span className={obj.type == 'parent' ? '' : 'name-key-mobile'}>
                  <span>
                    {key}{' '}
                    <span className="shoplist-table-parent-children-count">
                      {isSearching
                        ? `( ${obj.children.length} ingredient${obj.children.length > 1 ? 's' : ''} )`
                        : orderedItemsOnly
                        ? `( ${obj.ordered} ingredient${obj.ordered > 1 ? 's' : ''} )`
                        : allItems
                        ? `( ${obj.needed + obj.ordered} ingredient${obj.needed + obj.ordered > 1 ? 's' : ''} )`
                        : `( ${obj.needed} ingredient${obj.needed > 1 ? 's' : ''} )`}
                    </span>
                  </span>
                </span>
              </span>
            )} */}
            {
              <>
                <Row gutter={8} className="shoplist-item-row">
                  {/* Image */}
                  <Col span={12}>
                    <span className="shoplist-table-child-key-container-mobile">
                      <ThumbnailImage
                        className="shoplist-table-child-image-container-mobile"
                        src={
                          obj.imageUrl.length > 0
                            ? obj?.imageUrl
                            : obj.imageUrlThumb.length > 0
                            ? obj.imageUrlThumb
                            : null
                        }
                        placeholderIcon={<ChefIcon />}
                      />
                    </span>
                  </Col>
                  {/* Toggle Item Status */}
                  <Col span={9}>
                    <span className="shoplist-row-container-mobile">
                      <span
                        className="shoplist-order-status-icon-mobile"
                        alt="ordered-status"
                        onClick={() => toggleOrderedStatus(obj)}
                      >
                        <ShoppingCartIcon needed={!obj.ordered} ordered={obj.ordered} dimmed={false} />
                      </span>
                    </span>
                  </Col>
                  {/* Remove Item */}
                  <Col span={3}>
                    <span className="shoplist-row-container-mobile">
                      <img
                        alt="ordered-status"
                        height="18"
                        onClick={() => removeItem(obj)}
                        src={delete_img}
                        style={{ cursor: 'pointer', marginTop: '9px' }}
                      />
                    </span>
                  </Col>
                </Row>
                {/* Hides when Condensed View */}
                {/* Ingredient Name */}
                <Row
                  gutter={24}
                  className={isCondensed ? 'shoplist-item-row-condensed-hide' : 'shoplist-item-row-condensed-show'}
                >
                  <Col span={8}>Ingredient</Col>
                  <Col span={16}>
                    <span>{key}</span>
                  </Col>
                </Row>
                {/* Category */}
                <Row
                  gutter={24}
                  className={isCondensed ? 'shoplist-item-row-condensed-hide' : 'shoplist-item-row-condensed-show'}
                >
                  <Col span={8}>Category</Col>
                  <Col span={16}>
                    <span>{obj.category}</span>
                  </Col>
                </Row>
                {/* Requester */}
                <Row
                  gutter={24}
                  className={isCondensed ? 'shoplist-item-row-condensed-hide' : 'shoplist-item-row-condensed-show'}
                >
                  <Col span={8}>Requested by</Col>
                  <Col span={16}>
                    <span>{obj.requesterName}</span>
                  </Col>
                </Row>
                <Row
                  gutter={24}
                  className={isCondensed ? 'shoplist-item-row-condensed-hide' : 'shoplist-item-row-condensed-show'}
                >
                  <Col span={8}>Date/Time</Col>
                  <Col span={16}>
                    <span>{obj.requestDate}</span>
                  </Col>
                </Row>
                <Row
                  gutter={24}
                  className={isCondensed ? 'shoplist-item-row-condensed-hide' : 'shoplist-item-row-condensed-show'}
                >
                  <Col span={8}>Station</Col>
                  <Col span={16}>
                    <span>
                      {obj.type == 'child' && obj.stationName !== 'None' && obj.stationType ? (
                        <Link
                          to={
                            obj.stationType === 'event'
                              ? '/event/station/' + obj.eventId.toString()
                              : obj.stationType === 'kitchen'
                              ? '/station/' + obj.stationId.toString()
                              : ''
                          }
                        >
                          <span className="shoplist-table-child-text-link">{obj.stationName}</span>
                        </Link>
                      ) : (
                        <span className="shoplist-table-child-text-filter">{obj.stationName}</span>
                      )}
                    </span>
                  </Col>
                </Row>
              </>
            }
          </span>
        </span>
      ),
    },
  ];
  // b. rearrangeTable = automatically restores previous sort setting
  const rearrangeTable = (column, status, rows = []) => {
    // let tableRows = rows.length > 0 ? rows : pristineData;
    let tableRows = rows;
    if (tableRows.length > 1) {
      if (column === 'ordered') {
        if (status === 'ascend') {
          return tableRows.sort((a, b) => (a['ordered'] * 1 < b['ordered'] * 1 ? 1 : -1));
        } else {
          return tableRows.sort((a, b) => (a['ordered'] * 1 > b['ordered'] * 1 ? 1 : -1));
        }
      } else if (column === 'dateRequested') {
        if (status === 'ascend') {
          // from earliest to latest in date added
          return tableRows.sort((a, b) => (new Date(a.requestDate) * 1 >= new Date(b.requestDate) * 1 ? 1 : -1));
        } else {
          // from latest to earliest in date added
          return tableRows.sort((a, b) => (new Date(a.requestDate) * 1 <= new Date(b.requestDate) * 1 ? 1 : -1));
        }
      } else {
        if (status === 'ascend') {
          return tableRows.sort((a, b) =>
            a[column].toLowerCase().localeCompare(b[column].toLowerCase()) >= 0 ? 1 : -1
          );
        } else {
          return tableRows.sort((a, b) =>
            a[column].toLowerCase().localeCompare(b[column].toLowerCase()) <= 0 ? 1 : -1
          );
        }
      }
    } else {
      return tableRows;
    }
  };

  // 5. Table Change
  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order) {
      // console.log(sorter);
      localStorage.setItem(
        'shoplistOrderMob',
        JSON.stringify({
          column: sorter.columnKey,
          status: sorter.order,
        })
      );
    }
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  // INITIALIZATION
  useEffect(() => {
    let restaurantInfo = JSON.parse(localStorage.getItem('restaurant')) || {};
    let restaurantId = restaurantInfo.id;
    setRestaurantName(restaurantInfo.name);
    setIsLoading(true);
    getShoplistItems({
      from: 0,
      limit: 1000,
      restaurantId: restaurantId.toString(),
    }).then((items) => {
      let tableRows = [];
      if (items.length > 0) {
        items.forEach((item, i) => {
          item['key'] = `${item.id}`;
          item['dateRequested'] = moment(item.requestDate).format('MM/DD/YYYY hh:mm A');
          tableRows.push(item);
        });
      }
      setIsLoading(false);
      setPristineData(tableRows);
    });
  }, []);

  // APPLY CACHED SETTINGS ON STATES
  useEffect(() => {
    // 1. apply last search setting
    const lastSearchTerm =
      localStorage?.getItem('shoplistSearchMob') != null ? localStorage.getItem('shoplistSearchMob') : '';
    originalSettings.search = lastSearchTerm;
    setCurrentSearchString(lastSearchTerm);
    // 2. apply last filter setting
    const lastFilterSetting =
      localStorage?.getItem('shoplistFilterMob') != null ? localStorage.getItem('shoplistFilterMob') : 'All items';
    originalSettings.filter = lastFilterSetting;
    setFilterMenuName(originalSettings.filter);
    // 3. apply last sort setting
    const lastSortSetting =
      localStorage?.getItem('shoplistOrderMob') != null ? JSON.parse(localStorage.getItem('shoplistOrderMob')) : null;
    if (lastSortSetting) {
      originalSettings.order.column = lastSortSetting.column;
      originalSettings.order.status = lastSortSetting.status;
    }
  }, []);

  // CHANGE CURRENT FILTER WITH CHANGED MENU NAME
  useEffect(() => {
    if (filterMenuName === 'Needed') {
      showAllItems(false);
      showOrderedItemsOnly(false);
    } else if (filterMenuName === 'Ordered') {
      showOrderedItemsOnly(true);
      showAllItems(false);
    } else {
      showOrderedItemsOnly(false);
      showAllItems(true);
    }

    // stops entire search operation and clears search cache if exist
    setIsSearching(false);
    searchResultFound(false);
    setCurrentSearchString('');
    originalSettings.search = '';
    if (localStorage?.getItem('shoplistSearch') != null) {
      localStorage.removeItem('shoplistSearch');
    }
  }, [filterMenuName]);

  // Saving latest updates for shoplist data
  useEffect(() => {
    if (pristineData.length > 0 && !isSearching) {
      console.log('PRISTINE DATA', pristineData);
      if (pristineData.length > 1) {
        // only caches order if there are more than 1 rows
        localStorage.setItem(
          'shoplistOrderMob',
          JSON.stringify({
            column: originalSettings.order.column,
            status: originalSettings.order.status,
          })
        );
      }
      if (!isSearching && currentSearchString !== '') {
        searchShoplist(currentSearchString);
        setIsSearching(false);
      } else {
        finalizeRows(orderedItemsOnly, allItems);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pristineData, orderedItemsOnly, allItems, originalSettings.order, currentSearchString, isSearching]);

  // SETTING TABLE ROWS AND TABLE CONDITIONS
  // useEffect(() => {
  //   if (tableDataSource.length > 0) {
  //     if (isLoading) setIsLoading(false);

  //     // if view toggled from Needed to Ordered or vice versa, always expand first row only
  //     if (viewChanged) {
  //       setExpandedRows([tableDataSource[0].key]);
  //       localStorage.setItem('shopListOpenRows', JSON.stringify([tableDataSource[0].key]));
  //       isViewChanged(false);
  //       // else, do not change which rows are currently open or not if cached
  //     } else {
  //       let getOpenRows = localStorage.getItem('shopListOpenRows');
  //       if (getOpenRows && JSON.parse(getOpenRows.length) > 0) {
  //         setExpandedRows(JSON.parse(getOpenRows));
  //       } else {
  //         setExpandedRows([tableDataSource[0].key]);
  //         localStorage.setItem('shopListOpenRows', JSON.stringify([tableDataSource[0].key]));
  //       }
  //     }

  //     // if user is Searching and search results, first row always
  //     if (isSearching) {
  //       setExpandedRows([tableDataSource[0].key]);
  //       localStorage.setItem('shopListOpenRows', JSON.stringify([tableDataSource[0].key]));
  //     }
  //   } else {
  //     if (isLoading) setIsLoading(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tableDataSource, isSearching]);

  // AUTH SESSION KEYS
  // 1. save filter setting
  useEffect(() => {
    originalSettings.filter = filterMenuName;
    localStorage.setItem('shoplistFilterMob', filterMenuName);
  }, [filterMenuName]);
  // 2. save search setting
  useEffect(() => {
    if (currentSearchString.length) {
      originalSettings.search = currentSearchString;
      localStorage.setItem('shoplistSearchMob', currentSearchString);
    }
  }, [currentSearchString]);

  // AFTER CHOOSING NEW INGREDIENTS FROM DRAWER
  useEffect(() => {
    if (newIngredients.length > 0) {
      addNewItems(newIngredients);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIngredients]);

  // useEffect(() => {
  //   console.log('EXPANDED ROWS =====>', expandedRows);
  // }, [expandedRows]);

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Shopping List"
        restaurant={restaurantName}
        searchPrompt={currentSearchString !== '' ? currentSearchString : null}
        showSearch={showSearch}
        onChange={triggerSearch}
        clickSearch={() => setShowSearch(true)}
      />
      <div className="shoplist-line"></div>
      <div className="shoplist-controls">
        <div className="filter-shoplist-mobile">
          <Dropdown overlay={filterShoplistMenu}>
            <div className="filter-shoplist-item-mobile">{filterMenuName}</div>
          </Dropdown>
        </div>
        <div className="add-new-ingredients-mobile">
          <div
            className="add-new-ingredients-button"
            alt="Add new ingredients"
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          >
            <span>
              <PlusOutlined />
            </span>{' '}
            Add new ingredients{' '}
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {isLoading && <Spin tip="Loading shopping list ingredients..."></Spin>}
        </div>
      </div>
      <div className="shoplist-controls">
        {/* <div classNAme="filter-shoplist-mobile">
          <div className="collapse-shoplist-mobile">
            <div onClick={collapseAll}>
              <img src={collapseall} className="collapse-all-shoplist" alt="add-button" />
              Collapse all
            </div>
          </div>
        </div> */}
        <div className="flex-control-right flex-control-vert-center" style={{ paddingRight: '8px' }}>
          <Switch checked={isCondensed} onChange={setToCondensed} />
        </div>
      </div>
      <div className="common-table">
        <Table
          columns={shoplistCols}
          dataSource={tableDataSource}
          className={'show-custom-empty'}
          locale={{
            emptyText: (
              <Empty
                image={empty}
                description={
                  !hasSearchResult && isSearching
                    ? `No items found`
                    : `No ${orderedItemsOnly ? 'ordered' : 'pending'} items to display`
                }
              />
            ),
          }}
          // rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
          pagination={false}
          // onExpand={(expanded, record) => handleRowExpand(record)}
          // expandedRowKeys={expandedRows}
          rowKey={'key'}
          onChange={onTableChange}
        />
      </div>

      <AddIngredientPopup
        isDrawerOpen={isDrawerOpen}
        newIngredients={(newOrders) => setNewIngredients(newOrders)}
        closeDrawer={closeDrawer}
        restaurantId={restaurantId} // BUG 1202076501452783
      />
    </div>
  );
};

export default ShoppingListMobile;
