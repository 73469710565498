/* eslint eqeqeq: "off" */
import React, { useState } from 'react';
import moment from 'moment';
//import { findIndex } from 'lodash';
import './index.css';
import { formatGetTime, hexToRgbA } from '../../pages/Dashboard/DashboardUtils';
import EditEventDrawerV2 from '../../pages/Dashboard/components/EditEventDrawerV2';
import { Row, Col, Progress } from 'antd';
import RoleAccessibleComponent from '../RoleAccess/roleaccess';

const MeetingAndEventWeekMobile = (props) => {
  const { weekData, setDay, setCalendarType, setHasEditedEvent } = props;
  // const defaultActive = (() => {
  //   const dayUnix = new Date(moment(new Date()).format('dddd, MMMM D, YYYY')).getTime() / 1000;
  //   return findIndex(weekData, (data) => dayUnix === new Date(data.date).getTime() / 1000);
  // })();
  //const [active, setActive] = useState(defaultActive);
  const [showDrawer, setShowDrawer] = useState({ type: '', open: false, data: null });

  // const getRibbonColor = (num) => {
  //   const color = ['yellow', 'blue', 'pink', 'purple', 'red', 'indigo'];
  //   if (num < 6) {
  //     return color[num];
  //   }
  // };
  // useEffect(() => {
  //   if (today) {
  //     setActive(defaultActive);
  //   }
  // }, [today]);

  const clickDay = (param) => {
    //console.log("Select specific week day");
    let date = param.dateObj;
    //console.log(date);
    setCalendarType('day');
    setDay(moment(date).format('dddd, MMMM D, YYYY'));
    // setActive(key);
    // anotherDay(true);
  };

  return (
    <div className="marginT20 dash-mobile-data">
      {weekData.map((days, i) => {
        let dateObj = new Date(days.date);
        let daily_date = moment(dateObj).format('ddd, D');
        return (
          <div className="week-calendar-mobile" key={i}>
            <div onClick={(e) => clickDay({ dateObj })}>
              <div className={daily_date == moment().format('ddd, D') ? 'dash-mobile-dayActive' : 'dash-mobile-day'}>
                {daily_date}
              </div>
            </div>
            <div className="marginT10 dash-mobile-schedule ">
              <div
                className={
                  daily_date == moment().format('ddd, D') ? 'week-daily-body-highlight-mobile' : 'week-daily-body'
                }
              >
                {days.events.map((day, i) => {
                  const { info } = day;
                  const parsedInfo = info;
                  const color = (() => {
                    let ev_color = null;
                    if (parsedInfo) {
                      if (parsedInfo.hasOwnProperty('color')) {
                        return parsedInfo.color;
                      }
                    }
                    if (!ev_color) {
                      ev_color = day.type === 'event' ? '#FF59CE' : '#4CBCF8';
                    }
                    return ev_color;
                  })();
                  return (
                    <div
                      key={i}
                      style={{ backgroundColor: hexToRgbA(color) }}
                      onClick={() => {
                        // const dayClicked = moment(dateObj).format('dddd, MMMM D, YYYY')
                        // setDay(dayClicked);
                        // setCalendarType('day');
                        setShowDrawer({ type: day.type, open: true, data: day });
                      }}
                    >
                      <div style={{ borderLeft: ` 5px solid ${color}` }}>
                        <div className="dash-meet-box">
                          {day.type === 'event' && (
                            <div className="tracker-week-event">
                              <Row gutter={32}>
                                <Col span={3} className="percent-tracker-week">
                                  {day.percentage}%
                                </Col>
                                <Col span={16}>
                                  <Progress percent={parseInt(day.percentage)} showInfo={false} />
                                </Col>
                              </Row>
                            </div>
                          )}
                          <div className="event-title">
                            <strong>{day.name}</strong>
                          </div>
                          <div className="event-time">
                            {formatGetTime(day.eventStart)} - {formatGetTime(day.eventEnd)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      <RoleAccessibleComponent
        permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
      >
        {showDrawer.open && (
          <EditEventDrawerV2
            showDrawer={showDrawer.open}
            type={showDrawer.type}
            data={showDrawer.data}
            setHasCreatedEvent={props.setHasCreatedEvent}
            setShowDrawer={setShowDrawer}
            setHasEditedEvent={setHasEditedEvent}
          />
        )}
      </RoleAccessibleComponent>
    </div>
  );
};

export default MeetingAndEventWeekMobile;
