/* eslint eqeqeq: "off" */
import { request } from './http.service';
import { decodeMeasurementMessages } from '../protobuf-models/measurement.proto.js';

export const create = (params) => {
  return request('POST', '/measurement', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const update = (id, params) => {
  return request('PUT', '/measurement/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroy = (id) => {
  return request('DELETE', '/measurement/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const get = (id) => {
  return request('GET', '/measurement/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchmeas = (params) => {
  let finalParams = params;
  if (params.isSuperAdmin == undefined) {
    finalParams.isSuperAdmin = false;
  }
  return request('POST', '/measurement/search', 200, finalParams, decodeMeasurementMessages).then(
    (result) => {
      if (result && result.measurements) {
        let all = result.measurements.map((f) => {
          f.info = JSON.parse(f.info);
          return f;
        });
        return all;
      }
    },
    (error) => {
      // console.log(error);
    }
  );
};
