import React from 'react';
import { ThumbnailImage } from '../../components/common/common_ui';

const EquipmentListMobile = (props) => {
  const { equipmentList, isEditing } = props;

  return (
    <div className="main">
      {isEditing || equipmentList?.length ? <div className="view-recipe-section-title-mobile">Equipment</div> : ''}
      {equipmentList.map((equipment, i) => {
        return (
          <div className="ing-mobile-container">
            <div className="ingredient-name-container">
              <ThumbnailImage
                className="ingredient-image-container"
                src={
                  equipment.imageUrlThumb || equipment.imageUrl
                    ? equipment.imageUrlThumb || equipment.imageUrl
                    : equipment.info && (equipment.info?.imageUrlThumb || equipment.info?.imageUrl)
                    ? equipment.info?.imageUrlThumb || equipment.info?.imageUrl
                    : null
                }
              />
              <div className="ingredient-name"> {equipment.name}</div>
            </div>
            {/* <div>
              Type <span className="ing-value-qty">{equipment.type}</span>
            </div>
            <div>
              Size <span className="equip-value-meas">{equipment.size}</span>
            </div> */}
            <div>
              Category <span className="equip-value-meas">{equipment.category}</span>
            </div>{' '}
            <div>
              Quantity <span className="ing-value-prep">{equipment.quantity}</span>
            </div>
            <div className="ing-border"></div>
          </div>
        );
      })}
    </div>
  );
};

export default EquipmentListMobile;
