/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Drawer, TreeSelect, Spin } from 'antd';
import useWindowSize from '../../components/windowdimensions/windowSize';
import { search, count } from '../../services/ingredient.service';
import { getCategories } from '../../services/category.service';
import { searchMatch } from '../../services/ingredient.service';
// import { REACT_APP_SOCKET_BASE_URL } from '../../config';
// import openSocket from 'socket.io-client';
import _, { cloneDeep, find, has, findIndex } from 'lodash';
import { PlusButton, CidekicButton, ThumbnailImage, ExpandingSearchPanel } from '../../components/common/common_ui';

const AddIngredientPopup = (props) => {
  const { updateIngredients } = props;

  const windowSize = useWindowSize();

  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';

  const { TreeNode } = TreeSelect;

  const [visible, updateVisible] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [active, setActive] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [totalIngredients, setTotalIngredients] = useState(0);
  const [ingData, setIngData] = useState([]);
  const [ingCategoryChoices, setIngCategoryChoices] = useState([]);
  const [categoryChoices, setCategoryChoices] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState(undefined);

  const [isFetching, setIsFetching] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [nameFilter, setNameFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  // const [socketData3, setSocketData3] = useState([]);
  // const [ingUpdate, setIngUpdate] = useState([]);
  // const [ingAdd, setIngAdd] = useState([]);
  // const [ingDelete, setIngDelete] = useState('');
  // const [eventSocket3] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/ingredient`));

  const listInnerRef = useRef();

  const searchInputRef = useRef();

  const resto = localStorage.getItem('restaurantId') || '0';

  useEffect(() => {
    console.log(isFocused);
    if (!isFocused) {
      document.activeElement && document.activeElement.blur();
    }
  }, [isFocused]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (ingCategoryChoices.length < 1) {
      getCategories(accountId.toString()).then((res) => {
        setIngCategoryChoices(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, ingCategoryChoices]);

  useEffect(() => {
    if (categoryChoices.length < 1 && ingCategoryChoices.length > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      buildCategoryDropdown(ingCategoryChoices);
    }
  }, [ingCategoryChoices]);

  const handleScroll = () => {
    setIsFocused(false);
    //remove mobile keyboard while scrolling
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (!isSearching && Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        setIsFetching(true);
      }
    }
  };

  useEffect(() => {
    if (!isFetching || totalIngredients <= 400) return;
    fetchMoreListItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const buildCategoryDropdown = (res) => {
    if (res) {
      const catTree = res.reduce((categories, item) => {
        let newSubCategory = {
          id: parseInt(item.id),
          title: item.subcategory,
          generatedNumber: JSON.stringify(Math.random()),
        };
        if (categories.find((d) => d.category === item.category)) {
          const categoryIndex = findIndex(categories, (c) => c.category === item.category);
          let subcategories = categories[categoryIndex].children;
          let categoryIds = categories[categoryIndex].categoryIds;
          subcategories.push(newSubCategory);
          categories[categoryIndex].children = subcategories;
          categories[categoryIndex].categoryIds = categoryIds.concat(`-${item.id}`);
        } else {
          let subcategories = [];
          subcategories.push(newSubCategory);
          let newCategory = {
            category: item.category,
            categoryIds: `${item.id}`,
            generatedNumber: JSON.stringify(Math.random()),
            children: subcategories,
          };
          categories.push(newCategory);
        }
        return categories;
      }, []);
      catTree.forEach((category) => {
        category.children.sort((a, b) => a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase()));
      });
      catTree.sort((a, b) => a?.category?.toLowerCase().localeCompare(b?.category?.toLowerCase()));
      setCategoryChoices(catTree);
    }
  };

  const reformatIngredientData = (ingredients, toBackUp) => {
    let formattedIng = ingredients.map((i) => {
      let parsed = has(i, 'info') ? JSON.parse(i.info) : {};
      i.info = parsed;
      parsed.categoryId = i.categoryId;
      i.category = parsed?.category ? parsed.category : 'Uncategorized';
      i['sub category'] = has(parsed, 'sub category') ? parsed['sub category'] : 'Unsubcategorized';
      i.quantity = 0;
      i.cost = 0;
      i.measurementId = 0;
      i.stationId = 0;
      i.preparationId = 0;
      i.measurement = 'Select';
      i.station = 'Select Station';
      i.preparation = 'Select';
      i.imageUrlThumb =
        has(parsed, 'imageUrlThumb') || has(parsed, 'imageUrl')
          ? parsed.imageUrlThumb || parsed.imageUrl
          : has(i, 'imageUrlThumb') || has(i, 'imageUrl')
          ? i.imageUrlThumb || i.imageUrl
          : '';
      return i;
    });
    // formattedIng.sort((x, y) => {
    //   return x.id - y.id;
    // });
    if (toBackUp) {
      setIngData((currentData) => [...currentData, ...formattedIng]);
      setIsLoading(false);

      // setSocketData3((currentData) => [...currentData, ...formattedIng]);
    } else {
      setIngData(formattedIng);
      setIsLoading(false);
    }
  };

  const fetchMoreListItems = () => {
    setIsLoading(true);
    const totalDisplayedIngredients = ingData.length;

    if (totalIngredients === totalDisplayedIngredients) {
      setIsLoading(false);
      setCategoryTitle(undefined);
      return;
    }

    if (totalIngredients > totalDisplayedIngredients) {
      setTimeout(() => {
        setIsFetching(false);
        let params = {
          from: offset, // gets the current offset value from previous query
          limit: 400,
          accountId: accountId,
        };

        // if (isSearching) {
        //   params.name = nameFilter.toLowerCase();
        // }

        if (isFiltering) {
          const categoryIds = categoryFilter.id.toString();
          params.categoryId = categoryIds;
        }

        search(params).then((res) => {
          if (res.ingredients) {
            setOffset((current) => current + 400);
            reformatIngredientData(res.ingredients, true);
          }
        });
      }, 3500);
    }
  };

  const showDrawer = () => {
    updateVisible(true);
  };

  useEffect(() => {
    if (visible && !isOpened) {
      setIsOpened(true);
    }
  }, [visible, isOpened]);

  const onClose = () => {
    updateVisible(false);
    setCategoryTitle(undefined);
    setShowSearch(false);
    setNameFilter(null);
    setIsSearching(false);
    setIsFiltering(false);
    setIsLoading(false);
    setIngData([]);
    setActive([]);
  };

  const selectIngredient = (ingredient) => {
    console.log(ingredient);
    if (active.some((item) => item.name === ingredient.name)) {
      const filteractive = active
        .filter((item) => item.name !== ingredient.name)
        .map((ing, i) => {
          ing.order = i;
          return ing;
        });
      setActive(filteractive);
    } else {
      ingredient.order = active.length;
      setActive((ingredientlist) => [...ingredientlist, ingredient]);
    }
  };

  const addIngredients = () => {
    //transmit data to parent
    updateIngredients(active);
    onClose();
  };

  const filterIngs = (category) => {
    setTotalIngredients(0);
    // setOffset(0);
    let params = {
      accountId: accountId,
      categoryId: category.id.toString(),
      from: 0,
      limit: 400,
    };

    const countSearchedResults = async () => {
      return await count({ restaurantId: resto, categoryId: category.id.toString() }).then((res) => {
        return res;
      });
    };

    const returnFilteredResults = async (params) => {
      return await search(params).then((res) => {
        return res;
      });
    };

    const startFiltering = async (params) => {
      return Promise.all([countSearchedResults(), returnFilteredResults(params)]).then((res) => {
        const totalCount = res[0];
        const filterResults = res[1]?.ingredients;
        setIsLoading(true);
        setIsFetching(false);
        setTotalIngredients(totalCount);
        if (filterResults && filterResults.length > 0) {
          setOffset((current) => current + filterResults.length);
          reformatIngredientData(filterResults, false);
        }
      });
    };

    startFiltering(params).then(() => {
      setIsLoading(false);
      if (isSearching) {
        setIsSearching(false);
      }
    });
  };

  // removed unused 'showAll' declared method

  const debouncedApiCall = useCallback(
    _.debounce((value) => {
      searchIngs(value);
    }, 300),
    []
  );

  useEffect(() => {
    if (nameFilter && nameFilter !== '') {
      setIsSearching(true);
      debouncedApiCall(nameFilter);
    }
  }, [nameFilter, debouncedApiCall]);

  const searchIngs = (typed) => {
    setTotalIngredients(0);
    setOffset(0);
    if (typed.length >= 3) {
      setIsLoading(true);
      let keyword = typed.toLowerCase();
      let params = {
        restaurantId: resto,
        search: keyword,
      };

      // const countSearchedResults = async () => {
      //   return await count({ restaurantId: resto, name: keyword }).then((res) => {
      //     return res;
      //   });
      // };

      // const returnSearchedResults = async () => {
      //   return await search(params).then((res) => {
      //     return res;
      //   });
      // };

      // const startSearching = async () => {
      //   return Promise.all([countSearchedResults(), returnSearchedResults()]).then((res) => {
      //     const totalCount = res[0];
      //     const searchResults = res[1]?.ingredients;
      //     setIsLoading(true);
      //     setIsFetching(false);
      //     setTotalIngredients(totalCount);
      //     if (searchResults && searchResults.length > 0) {
      //       setOffset((current) => current + searchResults.length);
      //       reformatIngredientData(searchResults, false);
      //     }
      //   });
      // };

      searchMatch(params)
        .then((res) => {
          if (res && res?.ingredients) {
            // setOffset((current) => current + searchResults.length);
            const rankedIngs = res.ingredients;
            reformatIngredientData(rankedIngs, false);
          }
        })
        .catch(() => {
          setIngData([]);
        })
        .finally(() => {
          setIsFetching(false);
          setIsLoading(false);
          if (isFiltering) {
            setIsFiltering(false);
          }
        });

      // startSearching().then(() => {
      //   setIsLoading(false);
      //   if (isFiltering) {
      //     setIsFiltering(false);
      //   }
      // });
    } else {
      setIngData([]);
    }
  };

  // removed unused const 'menu'

  const onChangeIngredientCategory = (value) => {
    const obj = JSON.parse(value);
    setCategoryTitle(obj.title);
    setIsFiltering(true);
    setCategoryFilter(obj);
    filterIngs(obj);
    setOffset(0);
  };

  // useEffect(() => {
  //   eventSocket3.on('updatedIng', (message) => {
  //     let newData = JSON.parse(message);
  //     console.log('INGUPDATED!!', [newData]);
  //     setIngUpdate([newData]);
  //   });
  //   eventSocket3.on('addedIng', (message) => {
  //     let newData = JSON.parse(message);
  //     console.log('INGADDED!!', newData);
  //     setIngAdd(newData);
  //   });
  //   eventSocket3.on('deletedIng', (message) => {
  //     let newData = JSON.parse(message);
  //     console.log('INGDELETED!!', newData.id);
  //     setIngDelete(newData.id);
  //   });

  //   return () => {
  //     eventSocket3.disconnect();
  //   };
  // }, []);

  // useEffect(() => {
  //   setIngData((oldArray) => [...oldArray, ingAdd]);
  //   setSocketData3((oldArray) => [...oldArray, ingAdd]);
  // }, [ingAdd]);

  // useEffect(() => {
  //   console.log(socketData3);
  //   let updated = socketData3.map((obj) => ingUpdate.find((o) => o.id === obj.id) || obj);
  //   console.log(updated);
  //   setIngData(updated);
  //   setSocketData3(updated);
  // }, [ingUpdate]);

  // useEffect(() => {
  //   console.log(socketData3);
  //   let updated = socketData3.filter((obj) => obj.id.toString() !== ingDelete.id.toString());
  //   console.log(updated);
  //   setIngData(updated);
  //   setSocketData3(updated);
  // }, [ingDelete]);

  // const showSearchInput = () => {
  //   setShowSearch(true);
  // };

  return (
    <div>
      <div>
        <CidekicButton
          className="button-link-replacement recipe-add-item-button"
          alt="add ingredients"
          onClick={() => showDrawer()}
        >
          Add ingredients
        </CidekicButton>
      </div>

      <Drawer
        width={windowSize.width >= 842 ? '842px' : '100%'}
        onClose={onClose}
        visible={visible}
        closable={false}
        destroyOnClose={true}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <div style={{ overflow: 'auto', height: 780 }} onScroll={() => handleScroll()} ref={listInnerRef}>
          <div className="ingredients-loading-spinner">{isLoading && <Spin tip="Loading ingredients..."></Spin>}</div>
          <div className="add-popup-header">
            <div className={windowSize.width < 700 ? 'drawer-top-absolute' : ''}>
              <div className="add-popup-add-close-container">
                <div>
                  <div className="add-course-ing">
                    <PlusButton className="button-highlight" alt="Add selected ingredients" onClick={addIngredients}>
                      Add selected ingredients
                    </PlusButton>
                  </div>
                </div>
                {windowSize.width > 700 ? (
                  <div className="close-drawer" onClick={onClose}>
                    X <span className="close-text">Close</span>
                  </div>
                ) : (
                  <div className="close-drawer" onClick={onClose}>
                    X
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ overflow: 'hidden', position: 'relative' }}>
            <div className={`add-popup-header ${windowSize.width < 700 ? 'drawer-bottom-adjust' : ''}`}>
              <ExpandingSearchPanel
                className="flex-control-left"
                showSearch={showSearch}
                searchPrompt="Search ingredients"
                onChange={(evt) => setNameFilter(evt.target.value)}
                onFocus={(evt) => setIsFocused(true)}
                onBlur={(evt) => setIsFocused(false)}
                onClick={() => setShowSearch(true)}
                //ref={searchInputRef}
                value={nameFilter}
              />
            </div>
          </div>
          <br />
          <div>
            <div className="warpper">
              <input className="radio" id="one" name="group" type="radio" defaultChecked />
              <input className="radio" id="two" name="group" type="radio" />
              <input className="radio" id="three" name="group" type="radio" />
              <div className="tabs">
                <label className="tab" id="one-tab" htmlFor="one">
                  List
                </label>
                <label className="tab-2" id="two-tab" htmlFor="two">
                  Selected ({active.length})
                </label>
              </div>
              <div className="panels">
                <div className="panel" id="one-panel">
                  {/* <Dropdown overlay={menu}>  NOTE I removed the select-ing class, we do it different now
                  <div className="select-ing ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    {selected ? selected : 'View all ingredients categories'}
                  </div>
                </Dropdown> */}

                  <div className="filter-ing12">
                    {categoryChoices && categoryChoices.length > 0 && (
                      <TreeSelect
                        showSearch
                        className="panel-filter-select"
                        value={categoryTitle}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Category"
                        showArrow={false}
                        allowClear
                        onChange={(e) => {
                          onChangeIngredientCategory(e);
                        }}
                      >
                        {categoryChoices.map((c) => {
                          return (
                            <TreeNode
                              key={`${c.generatedNumber}-${c}`}
                              value={JSON.stringify({
                                id: c.categoryIds,
                                type: 'parent',
                                title: c.category,
                                generatedNumber: c.generatedNumber,
                              })}
                              title={c.category}
                            >
                              {c.children.map((ch) => {
                                return (
                                  <TreeNode
                                    key={`${ch.generatedNumber}-${ch}`}
                                    value={JSON.stringify({
                                      id: ch.id, // BUG 1202076501452783
                                      type: 'child',
                                      title: ch.title,
                                      generatedNumber: ch.generatedNumber,
                                    })}
                                    title={ch.title}
                                  ></TreeNode>
                                );
                              })}
                            </TreeNode>
                          );
                        })}
                      </TreeSelect>
                    )}
                  </div>

                  <div>
                    <ul className="flex-container wrap">
                      {visible &&
                        ingData.length > 0 &&
                        ingData.map((ingredient, i) => {
                          return (
                            <li key={i} className="flex-item" onClick={() => selectIngredient(ingredient)}>
                              <div
                                className={
                                  active.some((item) => item.id === ingredient.id) ? 'ing-name-box-2' : 'ing-name-box'
                                }
                              >
                                <div className="ing-name-panel-1">
                                  <ThumbnailImage
                                    src={
                                      ingredient.info && (ingredient.info?.imageUrlThumb || ingredient.info?.imageUrl)
                                        ? ingredient.info?.imageUrlThumb || ingredient.info?.imageUrl
                                        : null
                                    }
                                  />{' '}
                                </div>
                                <div className="ing-name-panel">
                                  <div className="ing-name">{ingredient.name}</div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="panel" id="two-panel">
                  <ul className="flex-container wrap">
                    {active.length > 0 &&
                      active.map((ingredient, i) => {
                        return (
                          <li key={i} className="flex-item">
                            <div className="ing-name-box-2">
                              <div className="ing-name-panel-1">
                                {' '}
                                <ThumbnailImage
                                  src={
                                    ingredient.info && (ingredient.info?.imageUrlThumb || ingredient.info?.imageUrl)
                                      ? ingredient.info?.imageUrlThumb || ingredient.info?.imageUrl
                                      : null
                                  }
                                />{' '}
                              </div>
                              <div className="ing-name-panel">
                                <div className="ing-name">{ingredient.name}</div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AddIngredientPopup;
