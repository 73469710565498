import { request } from './http.service';
import { decodeBatchRecipeMessages } from '../protobuf-models/batchrecipes.proto.js';

export const create = (params) => {
  return request('POST', '/course', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateCourse = (id, params) => {
  return request('PUT', '/course/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroy = (id) => {
  return request('DELETE', '/course/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const get = (id) => {
  return request('GET', '/course/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const search = (params) => {
  return request('POST', '/course/search', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchByRecipe = (id) => {
  return request('GET', '/course/with/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const recipes = (id) => {
  return request('GET', '/course/' + id + '/recipes', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const batchRecipes = (ids) => {
  return request('POST', '/course/recipes', 200, { ids }, decodeBatchRecipeMessages).then(
    (result) => {
      if (result && result.batchrecipes) {
        let batchrecipes = result.batchrecipes.map((b) => {
          b.CourseRecipes = JSON.parse(b.CourseRecipes);
          b.order = b.order ? b.order.toString() : '';
          return b;
        });
        return batchrecipes;
      }
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const assignRecipe = (id, recipeId) => {
  console.log(id, recipeId);
  return request('POST', '/course/' + id + '/recipes', 200, { recipeId }).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const unassignRecipe = (id, recipeId) => {
  return request('DELETE', '/course/' + id + '/recipes/' + recipeId, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const deleteMenuCourses = (courseId, menuId) => {
  return request('DELETE', '/course/' + courseId + '/in/menu/' + menuId, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const updateCourseRecipe = (id, recipeId, params) => {
  return request('PUT', '/course/' + id + '/recipes/' + recipeId, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const duplicateEventCourse = (params) => {
  return request('POST', '/course/for/event', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};
