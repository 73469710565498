import React, { useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { onFileSelected } from '../../services/upload.service';

const AddImage = (props) => {
  const { handleDrop, dropImg } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [imgFail, setImgFail] = useState(false);

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return { url: 'https://httpbin.org/post' };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file, remove }, status) => {
    dropImg();
    setIsLoading(true);
    console.log(status, meta, file);
    if (status === 'headers_received') {
      console.log('uploaded');
      onFileSelected(file).then((res) => {
        if (res && res.Location) {
          handleDrop(res.Location);
          setIsLoading(false);
          setImgFail(false);
        } else {
          handleDrop('none');
          setIsLoading(false);
          setImgFail(true);
        }
      });
      remove();
    } else if (status === 'aborted') {
      console.log('upload failed');
      setIsLoading(false);
      setImgFail(true);
    }
  };

  return (
    <div>
      {isLoading ? <div style={{ margin: '5px 0px 5px 50px' }}>Image is uploading...</div> : ''}
      {imgFail && <div style={{ margin: '5px 0px 5px 50px' }}>Image upload failed</div>}
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        accept="image/*"
        maxFiles={1}
        multiple={false}
        canCancel={false}
        inputContent="Drag and drop or click to upload"
        styles={{
          dropzone: {
            height: 100,
            width: 270,
            border: 'dashed 2px var(--main-borders)',
            background: 'var(--deep-fill)',
            overflow: 'auto',
          },
        }}
      />
      {/* </div> */}
    </div>
  );
};

export default AddImage;
