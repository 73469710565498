import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom'; //
import NutritionViewPopup from './NutritionViewPopup';
import IngredientsList from './../../components/IngredientsList/IngredientsList';
import IngredientTable from './IngredientTable';
import EquipmentList from './../../components/EquipmentList/EquipmentList';
import EquipmentTable from './EquipmentTable';
import InstructionsList from './../../components/InstructionsList/InstructionsList';
import ErrorPage from '../../components/ErrorPages/ErrorPage';
import useWindowSize from '../../components/windowdimensions/windowSize';
import AddIngredientPopup from './AddIngredientPopup';
import AddEquipment from './AddEquipment';
import edit from '../../img/recipe-edit.png';
import add2 from '../../img/calcPlus.png';
import less from '../../img/calcMinus.png';
import print from '../../img/recipe-print.png';
import more from '../../img/recipe-more.png';
import { recipeDetails } from '../../data/recipedetails';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import ReactToPrint from 'react-to-print';
import {
  get,
  update,
  details,
  destroy,
  equipment,
  editEquipment,
  unassignEquipment,
  assignEquipment,
  assignDetail,
  unassignDetail,
  editDetail,
} from '../../services/recipe.service';
import { update as updateEquip } from '../../services/equipment.service';
import {
  Popconfirm,
  message,
  Form,
  Tag,
  Radio,
  Slider,
  InputNumber,
  Dropdown,
  Menu
} from 'antd';
import AddFile from './AddFile';
import _, { cloneDeep } from 'lodash';
import Measurement from './measurements.js';
import fc from 'fraction-calculator';
import { searchmeas } from '../../services/measurement.service';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useHistory } from 'react-router-dom';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import { updateMenu, activateChecklist, searchByCourses, searchMenuRecipe } from '../../services/menu.service';
import { searchByRecipe } from '../../services/course.service';
import { searchByMenus } from '../../services/event.service';
import {
  getStationRecipeIngredients,
  createStationChecklist,
  createStationChecklists,
  // updateChecklist,
  updateChecklists,
  deleteStationChecklist,
} from '../../services/station.service';
import { ridAnnoyingZero, formatIfNeeded, roundOff, arrangeDBMeasurementUnits } from './utilities';
import { pluralize } from 'inflected';
import { BacktoHeader } from '../../components/Header/Header';
import {
  PrepTimeIcon,
  CookTimeIcon,
  YieldIcon,
  PortionIcon,
  ServingIcon,
  MasterRecipeIcon,
} from '../../components/common/common_ui';

import InputSelectCombo from '../../components/Recipe/InputSelectCombo';
import InputSelectCombo2 from '../../components/Recipe/InputSelectCombo2';
import InputSelectCombo3 from '../../components/Recipe/InputSelectCombo3';
import PrintRecipe from './PrintRecipe';
/* this does View and Edit for recipes viewed from /recipes */

const ViewRecipes = (props) => {
  //make sure existing Recipes have the correct values of measurement units
  const windowSize = useWindowSize();
  const history = useHistory();

  const componentRef = useRef();
  const [isEditing, setEditing] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  // const [recipeRestoId, setRecipeRestoId] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDesc] = useState('');
  const [addTags, setAddTags] = useState([]);
  const [viewRecipe, setViewRecipe] = useState({});
  const [prepInfo, setPrepInfo] = useState({});
  const [stationRestoId, setStationRestoId] = useState(0);

  // ONLY FOR INITIAL DISPLAY, PREPARATION AND COOKING TIMES TO BE ALWAYS SAVED IN MINUTES IN DATABASE
  const [prepHr, setPrepHr] = useState(0);
  const [prepMn, setPrepMn] = useState(0);
  const [cookHr, setCookHr] = useState(0);
  const [cookMn, setCookMn] = useState(0);

  const [prepTime, setPrepTime] = useState(0);
  const [cookTime, setCookTime] = useState(0);
  const [prepTimeUnit, setPrepTimeUnit] = useState('hours');
  const [cookTimeUnit, setCookTimeUnit] = useState('hours');
  const [quantityVal, setQuantityVal] = useState(0);
  const [portionVal, setPortionVal] = useState(0);
  const [yieldVal, setYieldVal] = useState(0);

  const [tags, setTags] = useState([]);
  const [intComment, setComment] = useState('');
  const [yieldMeasUnit, setYieldMeasUnit] = useState('');
  const [portionMeasUnit, setPortionMeasUnit] = useState('');
  const [quantityMeasUnit, setQuantityMeasUnit] = useState('');
  const [prevYieldMeasUnit, setPrevYieldMeasUnit] = useState('');
  const [prevPortionMeasUnit, setPrevPortionMeasUnit] = useState('');
  const [prevQuantityMeasUnit, setPrevQuantityMeasUnit] = useState('');

  // const [tagString, setTagString] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [incrementAmt, setIncrementAmt] = useState(0);
  const [recipeEquipment, setRecipeEquipment] = useState([]);
  const [recipeIngredients, setRecipeIngredients] = useState([]);
  const [prevIngredientsState, setPrevIngredientsState] = useState([]);
  const [addEquipmentList, setAddEquipmentList] = useState([]);
  const [addIngredientsList, setAddIngredientsList] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [showIns, setShowIns] = useState(false);
  const [enteredText, setEnteredText] = useState('');
  // const [addIns, setAddIns] = useState([]);
  const [portionValue, setPortionValue] = useState('scale');
  const [scaleInputValue, setScaleInputValue] = useState(1);
  const [qtyCombine, setQtyCombine] = useState(false);
  const [measurements, setMeasurements] = useState([]);
  const [stationIngredients, setStationIngredients] = useState([]);
  const [originalIngs, setOriginalIngredients] = useState([]);
  const [showReset, setShowReset] = useState(false);
  const [removeIngs, setRemoveIngs] = useState([]);
  const [removeEqs, setRemoveEqs] = useState([]);
  // const [currentIndex, setSlide] = useState(0);
  const [idList, setIdList] = useState({ menuid: '', courseid: '', recipeid: '' });
  const [associatedEvents, setAssociatedEvents] = useState([]);
  const [recipeMenuId, setRecipeMenuId] = useState(0);

  //TEMPORARY STATE
  const [recipeOrder, setRecipeOrder] = useState(0);
  // TEMPORARY STATE

  const [showPrepTime, setShowPrepTime] = useState('');
  const [showCookTime, setShowCookTime] = useState('');
  const [newPrepTime, setNewPrepTime] = useState();
  const [newCookTime, setNewCookTime] = useState();

  const [portionDisplay, setPortionDisplay] = useState('');
  const [yieldDisplay, setYieldDisplay] = useState('');
  const [quantityDisplay, setQuantityDisplay] = useState('');

  const [newYieldVal, setNewYieldVal] = useState();
  const [newPortionVal, setNewPortionVal] = useState();
  const [newQuantityVal, setNewQuantityVal] = useState();

  const [tempDisplayVal, setTempDisplayVal] = useState('');
  const [inputChanged, setInputChanged] = useState(false);
  const [updateEquipCat, setUpdateEquipCat] = useState([]);

  const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  const operatorLoggedIn = JSON.parse(localStorage.getItem('operator')) || {};
  const operatorRestaurants = operatorLoggedIn.restaurants;
  const accountId = JSON.parse(localStorage.getItem('operator')).accountId || '';

  // console.log('USER RESTAURANT ID', resto_id);

  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    console.log('PROPS LOCATION STATION', props.location);
    console.log('image urls', imageUrls);
  }, []);

  // useEffect(() => {
  //   console.log('PREV STATE RECIPE INGREDIENTS NOW >>>>>>>>>', prevIngredientsState);
  // }, [prevIngredientsState]);

  const checkIfPartOfEvents = () => {
    searchByRecipe(props.match.params.id).then((result) => {
      let courseIds = result;

      console.log('COURSES THIS RECIPE IS PART IN >>>>', courseIds);

      if (courseIds && courseIds.length > 0) {
        searchByCourses({ courseIds: courseIds }).then((result) => {
          let menuIds = result;

          console.log('MENUS THIS RECIPE IS PART IN >>>>', menuIds);

          if (menuIds && menuIds.length > 0) {
            searchByMenus({ menuIds: menuIds }).then((result) => {
              console.log('EVENTS THIS RECIPE IS PART IN >>>>', result);
              result && result.length > 0 && setAssociatedEvents(result);
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    // console.log(props.location.state.menuid, props.location.state.courseid, props.location.state.recipeid);
    let accountId = localStorage.getItem('operator') != undefined && 
      JSON.parse(localStorage.getItem('operator')).accountId != null ? 
      JSON.parse(localStorage.getItem('operator')).accountId : '';
    let params = {
      // restaurantId: resto.id,
      accountId: accountId,
    };

    searchmeas(params).then((res) => {
      setMeasurements(arrangeDBMeasurementUnits(res));
    });
    searchMenuRecipe(props.match.params.id).then((res) => {
      if (res !== '0') {
        setRecipeMenuId(res * 1);
      }
    });
  }, []);

  useEffect(() => {
    if (stationIngredients.length > 0) {
      console.log(`STATION CHECKLISTS FOR RECIPE ${props.match.params.id}`, stationIngredients);
    }
  }, [stationIngredients]);

  const fetchStationChecklists = (recipeId) => {
    getStationRecipeIngredients(recipeId).then((res) => {
      if (res && res.length > 0) {
        setStationIngredients(res);
      }
    });
  };

  useEffect(() => {
    if (measurements.length > 0) {
      fetchStationChecklists(props.match.params.id);
      getRecipe();
      getEquipment();
      getDetails();
      checkIfPartOfEvents();

      if (
        props.location.state &&
        (props.location.state.from == 'menurecipes' || props.location.state.from == 'station')
      ) {
        setIdList({
          menuid: props.location.state.menuid,
          courseid: props.location.state.courseid,
          recipeid: props.location.state.recipeid,
        });
      }

      if (props.location.state && props.location.state.setedit == true) {
        editRecipe1();
      }
    }
  }, [measurements]);

  useEffect(() => {
    if (portionValue !== 'scale') {
      let currentQuantityVal = newQuantityVal ? newQuantityVal : quantityVal;
      setScaleInputValue(currentQuantityVal);
    }
  }, [portionValue]);

  const incAmt = () => {
    setIncrementAmt(incrementAmt + 1);
  };

  const decAmt = () => {
    setIncrementAmt(incrementAmt - 1);
  };

  const reset = () => {
    setIncrementAmt(0);
  };

  const typeTag = (e) => {
    setEnteredText(e.target.value);
    // setTagString(e.target.value);
  };

  const addTag = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let tag = e.target.value;
      setAddTags([...addTags, tag]);
      setEnteredText('');
    }
  };

  const removeTag = (e, tag) => {
    setAddTags(addTags.filter((e) => e !== tag));
  };

  const unassignIng = useCallback((e) => {
    let id = props.match.params.id;
    let ingredientId = e.id;
    let stationId = e?.stationId ? e.stationId : 0;
    // let reg = /^[0-9]*[.]?[0-9]*$/;
    // let preparationId = reg.test(e.preparation) ? e.preparation : e.preparationId;
    // let measurementId = reg.test(e.measurement) ? e.measurement : e.measurementId;
    let preparationId = e?.preparationId ? e.preparationId : 0;
    let measurementId = e?.measurementId ? e.measurementId : 0;

    console.log(recipeIngredients);
    console.log(e);
    console.log(e.id);
    // let filterIng = recipeIngredients.filter((ing) => ing.id !== e.id);
    let filterIng = recipeIngredients.filter((ing) => ing.key !== e.key);
    setAddIngredientsList(filterIng);
    setOriginalIngredients(filterIng);
    setRecipeIngredients(filterIng);

    setRemoveIngs((oldArray) => [
      ...oldArray,
      {
        id: id,
        ingredientId: ingredientId,
        preparationId: preparationId,
        measurementId: measurementId,
        stationId: stationId,
      },
    ]);
  });

  const unassignEquip = useCallback((e) => {
    // let recipeId = props.match.params.id;
    let equipmentId = e.id;
    let equipmentKey = e.key;
    //let filterEq = addEquipmentList.filter((ing) => ing.id !== equipmentId);
    //BUG 1202397472239827
    let filterEq = addEquipmentList.filter((ing) => ing.key !== equipmentKey);
    setAddEquipmentList(filterEq);
    setRemoveEqs((oldArray) => [...oldArray, equipmentId]);
  });

  const updateEquipCategory = useCallback((e) => {
    console.log('UPDATEEQUIPCAT!', e);
    setUpdateEquipCat((arr) => [...arr, e]);
  });

  //TEMPORARY FIXING CODE
  //FIXES deeply nested recursive info JSON object key-pair data
  function findNested(o) {
    let found = o.info;
    return Object.keys(found).includes('tags') || Object.keys(found).includes('recipeImages')
      ? found
      : findNested(found);
  }
  function isItNested(o) {
    let start = o?.info;
    if (start) {
      console.log(Object.keys(start));
      return Object.keys(start).includes('tags') || Object.keys(start).includes('recipeImages') ? false : true;
    }
  }

  const getRecipe = () => {
    get(props.match.params.id).then((res) => {
      console.log('LOG RES', res);

      setStationRestoId(res?.restaurantId * 1);

      //TEMPORARY FIXING CODE
      //FIXES deeply nested recursive info JSON object key-pair data
      let deepestInfo = {};
      if (isItNested(res)) {
        deepestInfo = findNested(res);
        console.log('DEEPEST INFO', deepestInfo);
        res.info = deepestInfo;
      }
      //TEMPORARY FIXING CODE
      console.log('RELOG RES', res);

      // setRecipeRestoId(parseInt(res?.restaurantId));
      setRecipeOrder(res?.info.order);

      // parsed vs. prepared: parsed are actual values to display and edit in Edit mode; prepared in View mode
      let parsed = res?.info;
      let prepared = cloneDeep(parsed);

      // making sure the measurement values are properly reformatted
      // 1. recipe yield
      let theYieldDisplay = parsed?.yield
        ? parsed.yieldVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed.yieldVal)
          ? reformatVal(parsed?.yield, parsed?.yieldVal, 1, true).reformattedVal
          : formatIfNeeded(parsed?.yield)
        : '0';
      // 2. recipe portion
      let thePortionDisplay = parsed?.portion
        ? parsed.portionVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed.portionVal)
          ? reformatVal(parsed?.portion, parsed?.portionVal, 1, true).reformattedVal
          : formatIfNeeded(parsed?.portion)
        : '0';
      // 3. recipe quantity
      //////
      let theQuantityDisplay = parsed?.quantity
        ? parsed.qtyVal
          ? reformatVal(parsed?.quantity, 'Each', 1, true).reformattedVal
          : formatIfNeeded(parsed?.quantity)
        : '0';

      // Preparing the yield, portion and quantity values in View Mode
      if (prepared) {
        prepared.yield = theYieldDisplay;
        prepared.portion = thePortionDisplay;
        prepared.quantity = theQuantityDisplay;

        setPrepInfo(prepared);
      }

      setViewRecipe(res);
      setTags(parsed?.tags);
      // setTagString((parsed?.tags || []).toString());
      setAddTags(parsed?.tags || []);
      setInstructions(parsed?.instructions || []);
      setTitle(res?.name);
      setDesc(parsed?.description);
      setComment(parsed?.comment);

      const _prepTime = parsed?.prepMins ? parseInt(parsed?.prepMins) : 0;
      const _cookTime = parsed?.cookMins ? parseInt(parsed?.cookMins) : 0;

      if (_prepTime >= 60) {
        let initPrepTime = Math.floor(_prepTime / 60);
        setPrepHr(initPrepTime);
        setShowPrepTime(initPrepTime.toString());
        setPrepTimeUnit('hours');
      } else {
        setPrepMn(_prepTime);
        setShowPrepTime(_prepTime.toString());
        setPrepTimeUnit('minutes');
      }

      if (_cookTime >= 60) {
        let initCookTime = Math.floor(_cookTime / 60);
        setCookHr(initCookTime);
        setShowCookTime(initCookTime.toString());
        setCookTimeUnit('hours');
      } else {
        setCookMn(_cookTime);
        setShowCookTime(_cookTime.toString());
        setCookTimeUnit('minutes');
      }

      setPrepTime(_prepTime);
      setCookTime(_cookTime);
      setPortionVal(parsed?.portion);
      setYieldVal(parsed?.yield);
      setQuantityVal(parsed?.quantity);
      setYieldMeasUnit(
        parsed?.yieldVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.yieldVal)
          ? parsed?.yieldVal
          : 'Select'
      );
      setQuantityMeasUnit(
        parsed?.qtyVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.qtyVal)
          ? parsed?.qtyVal
          : 'Select'
      );
      setPrevYieldMeasUnit(
        parsed?.yieldVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.yieldVal)
          ? parsed?.yieldVal
          : 'Select'
      );
      setPrevQuantityMeasUnit(
        parsed?.qtyVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.qtyVal)
          ? parsed?.qtyVal
          : 'Select'
      );
      setPortionMeasUnit(
        parsed?.portionVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.yieldVal)
          ? parsed?.portionVal
          : 'Select'
      );
      setPrevPortionMeasUnit(
        parsed?.portionVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.yieldVal)
          ? parsed?.portionVal
          : 'Select'
      );

      setYieldDisplay(theYieldDisplay);
      setPortionDisplay(thePortionDisplay);
      setQuantityDisplay(theQuantityDisplay);

      // BUG 1201998443633172
      let uniqueImgThumbs = [...new Set(parsed?.recipeImageThumbs)];
      setImageUrls(
        uniqueImgThumbs && uniqueImgThumbs.length > 0
          ? uniqueImgThumbs
          : parsed?.recipeImages && parsed?.recipeImages.length > 0
          ? parsed?.recipeImages
          : []
      );
      // setImageUrls(
      //   parsed?.recipeImageThumbs && parsed?.recipeImageThumbs.length > 0
      //     ? parsed?.recipeImageThumbs
      //     : parsed?.recipeImages && parsed?.recipeImages.length > 0
      //     ? parsed?.recipeImages
      //     : []
      // );
      console.log(parsed);
      console.log(parsed?.instructions);
    });
  };

  const getEquipment = () => {
    equipment(props.match.params.id).then((res) => {
      console.log('EQTABLE', res);
      if (res) {
        let recEq = res;
        if (recEq.RecipeEquipments.length > 0) {
          let parse = recEq.RecipeEquipments.map((e) => {
            e.prepped = e.info;
            return e;
          });
          if (parse.length > 0 && parse.info) {
            let parsed = parse.map((p) => {
              p.prepped.id = p.equipmentId;
              p.prepped.name = p.Equipment.name;
              p.prepped.info = p.Equipment.info;
              p.prepped.quantity = p.prepped.quantity;
              p.prepped.isMaster = p.Equipment.createdBy === 'system_ingest';
              p.category = p.info?.category;
              return p;
            });
            let getEquipment = parsed?.map((q) => {
              return q.prepped;
            });
            console.log('GET EQUIPMENT', getEquipment);
            setRecipeEquipment(getEquipment);
            setAddEquipmentList(getEquipment);
          } else {
            let parsed1 = parse.map((p) => {
              p.id = p.equipmentId;
              p.name = p.Equipment.name;
              p.info = p.Equipment.info;
              p.quantity = p.prepped.quantity;
              // p.size = p.prepped.size;
              // p.type = p.prepped.type;
              p.isMaster = p.Equipment.createdBy === 'system_ingest';
              p.category = p.info?.category;
              return p;
            });
            console.log('GET EQUIPMENT', parsed1);
            setRecipeEquipment(parsed1);
            setAddEquipmentList(parsed1);
          }
        }
      }
    });
  };

  const getDetails = () => {
    details(props.match.params.id).then((res) => {
      if (res) {
        let recIng = res;
        console.log('INGTABLE', res);
        if (recIng.RecipeDetails.length > 0) {
          let prevState = recIng.RecipeDetails.map((e) => {
            let prevDet = {};
            prevDet.ingredientId = e.ingredientId;
            prevDet.stationId = e.stationId;
            prevDet.preparationId = e.preparationId;
            prevDet.measurementId = e.measurementId;
            return prevDet;
          });
          setPrevIngredientsState(prevState);
          let parse = recIng.RecipeDetails.map((e) => {
            e.info.quantity = parseFloat(e.info.quantity);
            e.info.originalQty = parseFloat(e.info.originalQty);
            e.info.cost = parseFloat(e.info.cost);
            e.prepped = e.info;
            return e;
          });
          let parsed = parse.map((p) => {
            p.prepped.id = p.ingredientId;
            p.prepped.name = p.Ingredient.name;
            p.prepped.measurementId = p.measurementId;
            p.prepped.measurement = p.Measurement === null ? '' : p?.Measurement?.name;
            p.prepped.preparationId = p.preparationId;
            p.prepped.preparation = p.Preparation === null ? '' : p?.Preparation?.name;
            p.prepped.station = p.Station === null ? '' : p.Station?.name;
            p.prepped.stationId = p.stationId;
            p.prepped.info = p.Ingredient.info;
            p.prepped.isMaster = p.Ingredient.createdBy === 'system_ingest';
            return p;
          });
          let getIngredients = parsed?.map((q) => {
            return q.prepped;
          });

          let sortedIngs = getIngredients?.sort((x, y) => {
            return x.order - y.order || x.name.localeCompare(y.name);
          });

          //console.log('ORIGINAL INGREDIENTS AND QUANTITY', sortedIngs);
          setRecipeIngredients(sortedIngs);
          setAddIngredientsList(sortedIngs);
          setOriginalIngredients(sortedIngs);
        }
      }
    });
  };

  const updateIngredients = useCallback((ingredients) => {
    console.log('UPDATED INGS', ingredients);
    if (addIngredientsList.length == 0) {
      setAddIngredientsList(ingredients);
      setRecipeIngredients(ingredients);
      setOriginalIngredients(ingredients);
      //setTotalIng(ingredients.length);
    } else {
      console.log(addIngredientsList);
      // let ids = new Set(addIngredientsList.map((d) => d.id));
      // let merged = [...addIngredientsList, ...ingredients.filter((d) => !ids.has(d.id))];
      //BUG 1202397472239827

      let merged = [...addIngredientsList, ...ingredients];

      console.log(merged);
      setAddIngredientsList(merged);
      setOriginalIngredients(merged);
      setRecipeIngredients(merged);
      //setTotalIng(merged.length);
    }
  });

  const cancelForm = () => {
    goBack();
    //window.location.reload();
  };

  const finalizeTimeToMinutes = (val, unit) => {
    console.log('Unit >>>>>>>', unit);
    console.log('Value >>>>>>>>', val);
    if (unit === 'hours') {
      return val * 60;
    }
    if (unit === 'minutes') {
      return val;
    }
  };

  const savingChecklists = (checklists) => {
    if (checklists.length > 1) {
      createStationChecklists(checklists)
        .then((result) => {
          console.log('CREATED NEW KITCHEN STATION CHECKLISTS');
        })
        .catch((err) => console.log(err));
    } else {
      createStationChecklist(checklists[0])
        .then((result) => {
          console.log('CREATED NEW KITCHEN STATION CHECKLISTS');
        })
        .catch((err) => console.log(err));
    }
  };

  const updatingChecklists = (checklists) => {
    // if (checklists.length > 1) {
      updateChecklists(checklists)
        .then((result) => {
          console.log('UPDATED NEW KITCHEN STATION CHECKLISTS');
        })
        .catch((err) => console.log(err));
    // } else {
    //   updateChecklist(checklists[0])
    //     .then((result) => {
    //       console.log('UPDATED NEW KITCHEN STATION CHECKLISTS');
    //     })
    //     .catch((err) => console.log(err));
    // }
  };

  const saveRecipe = async () => {
    // console.log(prepTime, showPrepTime);
    // console.log('MIN', prepMn, 'HR', prepHr);
    // console.log('ADDINGSLIST!!', addIngredientsList);
    if (scaleInputValue > 1) setScaleInputValue(1);
    let recIng = addIngredientsList.map((ing) => {
      if (ing?.displayQty) {
        delete ing.displayQty;
      }
      if (ing?.key) {
        delete ing.key;
      }
      return ing;
    });
    let info = {
      description: description || prepInfo.description,
      prepMins: newPrepTime
        ? finalizeTimeToMinutes(newPrepTime, prepTimeUnit)
        : finalizeTimeToMinutes(showPrepTime, prepTimeUnit),
      cookMins: newCookTime
        ? finalizeTimeToMinutes(newCookTime, cookTimeUnit)
        : finalizeTimeToMinutes(showCookTime, cookTimeUnit),
      comment: intComment,
      portion: !isNaN(newPortionVal) ? newPortionVal : portionVal,
      yield: !isNaN(newYieldVal) ? newYieldVal : yieldVal,
      yieldVal:
        prevYieldMeasUnit !== yieldMeasUnit ? (yieldMeasUnit !== 'Select' ? yieldMeasUnit : '') : prevYieldMeasUnit,
      portionVal:
        prevPortionMeasUnit !== portionMeasUnit
          ? portionMeasUnit !== 'Select'
            ? portionMeasUnit
            : ''
          : prevPortionMeasUnit,
      quantity: !isNaN(newQuantityVal) ? newQuantityVal : quantityVal,
      qtyVal:
        prevQuantityMeasUnit !== quantityMeasUnit
          ? quantityMeasUnit !== 'Select'
            ? quantityMeasUnit
            : ''
          : quantityMeasUnit,
      ingredients: recIng,
      tags: addTags,
      instructions: instructions, //.concat(addIns),
      recipeImageThumbs: [...new Set(imageUrls)],
      recipeImages: [...new Set(imageUrls)],
      order: recipeOrder,
    };

    setImageUrls([]);

    let params = {
      restaurantId: resto_id, // BUG 1202076501452783
      name: title ? title.trim() : viewRecipe.name.trim(),
      info,
    };

    // delete removed ing from DB
    if (removeIngs.length > 0) {
      console.log(removeIngs);
      const fetchIngData = () => {
        const removeSelIngs = removeIngs.map((ing) => {
          let id = ing.id;
          let ingredientId = ing.ingredientId;
          let preparationId = ing?.preparationId && ing.preparationId !== '' ? ing.preparationId : 0;
          let measurementId = ing?.measurementId && ing.measurementId !== '' ? ing.measurementId : 0;
          let stationId = ing?.stationId && ing.stationId !== '' ? ing.stationId : 0;

          unassignDetail(id, ingredientId, preparationId, measurementId, stationId)
            .then((response) => response)
            .catch((err) => console.log(err));
        });

        return Promise.all(removeSelIngs);
      };

      fetchIngData().then(() => {
        setRemoveIngs([]);
      });
    }

    if (addIngredientsList?.length > 0) {
      let newStationChecklists = [];
      let toUpdateStationChecklists = [];
      let toRemoveStationChecklists = [];
      let orderIngs = addIngredientsList?.map((ing, i) => {
        ing.order = i;
        return ing;
      });

      const originalIngredients = orderIngs?.filter((c) => !c.category);
      let newIngredients = orderIngs?.filter((c) => c.category);

      console.log('NEW INGREDIENTS', newIngredients);

      const saveEditedIngredients = () => {
        console.log('EDITED ORIGINAL INGREDIENTS', originalIngredients);
        console.log('PREVIOUS INGREDIENTS STATE', prevIngredientsState);
        const allRequests = originalIngredients?.map((ing, i) => {
          let reg = /^[0-9]*[.]?[0-9]*$/;
          let id = props.match.params.id.toString();
          let ingredientId = ing.id;
          let preparation = ing.preparation !== '' ? ing.preparation : 'Select';
          let measurement = ing.measurement !== '' ? ing.measurement : 'Select';
          let station = ing.station !== '' ? ing.station : 'Select Station';
          let preparationId = prevIngredientsState.find((p) => p.ingredientId === ingredientId).preparationId;
          let measurementId = prevIngredientsState.find((p) => p.ingredientId === ingredientId).measurementId;
          let stationId = prevIngredientsState.find((p) => p.ingredientId === ingredientId).stationId;
          let prepIng = {
            originalQty: parseFloat(ing.originalQty),
            quantity: parseFloat(ing.quantity),
            cost: parseFloat(ing.cost),
            order: parseInt(ing.order),
          };
          let params = {
            ingredientId: parseInt(ing.id),
            preparationId: reg.test(preparation) ? parseInt(preparation) : ing.preparationId ? ing.preparationId : 0,
            measurementId: reg.test(measurement) ? parseInt(measurement) : ing.measurementId ? ing.measurementId : 0,
            stationId: reg.test(station) ? parseInt(station) : ing.stationId ? ing.stationId : 0,
            info: prepIng,
          };
          let checklistParams = {};
          checklistParams.ingredientId = ingredientId;
          checklistParams.preparationId = preparationId;
          checklistParams.measurementId = measurementId;
          checklistParams.stationId = stationId;
          checklistParams.eventId = -1;
          checklistParams.recipeId = props.match.params.id * 1;
          checklistParams.menuId = idList?.menuid ? idList.menuid : recipeMenuId;
          checklistParams.params = _.cloneDeep(params);

          // if there are changes detected, push to newStationChecklists
          const previousRecord = _.find(stationIngredients, (ch) => ch.ingredientId === ing.id);
          // if there are new ingredients added with stationId
          if (
            previousRecord &&
            (previousRecord?.preparationId !== ing.preparationId ||
              previousRecord?.measurementId !== ing.measurementId ||
              previousRecord?.stationId !== ing.stationId ||
              previousRecord?.quantity !== ing.quantity ||
              previousRecord?.originalQuantity !== ing.originalQuantity ||
              previousRecord?.order !== ing.order)
          ) {
            if (ing.stationId > 0) {
              // checklistParams.params.info = {
              //   ...checklistParams.params.info,
              //   setBy: '',
              //   ordered: -1,
              //   setDate: '',
              //   completed: 1,
              //   healthCheck: 1,
              //   order: 0,
              // };
              toUpdateStationChecklists.push(checklistParams);
            } else {
              toRemoveStationChecklists.push(checklistParams);
            }
          } else {
            if (ing.stationId > 0) {
              params.info = {
                ...params.info,
                setBy: '',
                ordered: -1,
                setDate: '',
                completed: 1,
                healthCheck: 1,
                order: 0,
              };
              params.eventId = -1;
              params.recipeId = props.match.params.id * 1;
              params.menuId = idList?.menuid ? idList.menuid : recipeMenuId;
              newStationChecklists.push(params);
            }
          }

          console.log(
            'ID, INGID, PREPID, MEASID, STID, PARAMS >>>>>>',
            id,
            ingredientId,
            preparationId,
            measurementId,
            stationId,
            params
          );

          editDetail(id, ingredientId, preparationId, measurementId, stationId, params)
            .then((response) => response)
            .catch((err) => console.log(err));
        });

        return Promise.all(allRequests);
      };

      await saveEditedIngredients();

      if (newIngredients.length > 0) {
        const saveAddedIngredients = () => {
          console.log('NEW INGREDIENTS', newIngredients);
          const allIngReq = newIngredients.map((ing, i) => {
            let reg = /^[0-9]*[.]?[0-9]*$/;
            let id = props.match.params.id.toString();
            let prepIng = {
              originalQty: parseFloat(ing.originalQty),
              quantity: parseFloat(ing.quantity),
              cost: parseFloat(ing.cost),
              imageUrl: ing.imageUrl || ing.imageUrlThumb,
              order: parseInt(ing.order),
            };
            let params = {
              ingredientId: parseInt(ing.id),
              preparationId: reg.test(ing.preparation)
                ? parseInt(ing.preparation)
                : ing.preparationId
                ? ing.preparationId
                : 0,
              measurementId: reg.test(ing.measurement)
                ? parseInt(ing.measurement)
                : ing.measurementId
                ? ing.measurementId
                : 0,
              stationId: reg.test(ing.station) ? parseInt(ing.station) : ing.stationId ? ing.stationId : 0,
              info: prepIng,
            };
            let checklistParams = cloneDeep(params);
            checklistParams.eventId = -1;
            checklistParams.recipeId = props.match.params.id * 1;
            checklistParams.menuId = idList?.menuid ? idList.menuid : recipeMenuId;
            checklistParams.info = {
              ...checklistParams.info,
              setBy: '',
              ordered: -1,
              setDay: '',
              completed: 1,
              healthCheck: 1,
              order: 0,
            };

            console.log('NEWLY ADDED INGREDIENTS >>>>>>', params);

            if (ing.stationId > 0) {
              newStationChecklists.push(checklistParams);
            }

            assignDetail(id, params)
              .then((response) => response)
              .catch((err) => console.log(err));

            // code for saving to stationchecklist here
            if (associatedEvents && associatedEvents.length > 0) {
              associatedEvents.forEach((e) => {
                let eventId = e.eventId;
                let menuId = e.menuId;

                let checklistParams = {
                  menuId: menuId,
                  recipeId: parseInt(props.match.params.id),
                  ingredientId: parseInt(ing.id),
                  measurementId: reg.test(ing.measurement)
                    ? parseInt(ing.measurement)
                    : ing.measurementId
                    ? ing.measurementId
                    : 0,
                  preparationId: reg.test(ing.preparation)
                    ? parseInt(ing.preparation)
                    : ing.preparationId
                    ? ing.preparationId
                    : 0,
                  stationId: -1, // stationId defaults to -1 if ingredient is saved
                  eventId: eventId,
                  info: prepIng,
                };
                createStationChecklist(checklistParams)
                  .then((result) => {
                    console.log('CREATED NEW CHECKLIST ITEM SAVED IN MENUID >>>>', result.menuId);
                  })
                  .catch((err) => console.log(err));
              });
            }
          });
          return Promise.all(allIngReq);
        };

        await saveAddedIngredients();
      }

      if (
        props.location.state &&
        (props.location.state.from == 'menurecipes' || props.location.state.from == 'station')
      ) {
        let params1 = {
          info: { active: true },
          active: true,
        };

        let params2 = {
          info: { active: false },
          active: false,
        };
        // deactivateChecklist(idList.menuid).then((res) => {
        updateMenu(idList.menuid, params2).then((res) => {
          activateChecklist(idList.menuid).then((res) => {
            updateMenu(idList.menuid, params1).then((res) => {});
          });
        });
        // });
      }

      if (newStationChecklists.length) {
        savingChecklists(newStationChecklists);
      }

      if (toUpdateStationChecklists.length) {
        updatingChecklists(toUpdateStationChecklists);
      }

      if (toRemoveStationChecklists.length) {
        const removeChecklists = () => {
          const removeEachChecklist = toRemoveStationChecklists.map((ch) => {
            return deleteStationChecklist(ch.stationId, ch.recipeId, ch.ingredientId)
              .then((response) => response)
              .catch((err) => console.log(err));
          });
          return Promise.all(removeEachChecklist);
        };
        removeChecklists().then(() => {
          console.log('REMOVED KITCHEN STATION CHECKLISTS');
        });
      }
    }

    if (removeEqs.length > 0) {
      const recipeId = props.match.params.id;
      const fetchEqData = () => {
        const removeSelEqs = removeEqs.map((eqId) => {
          unassignEquipment(recipeId, eqId)
            .then((response) => {
              message.success('Equipment unassigned from recipe!');
            })
            .catch((err) => console.log(err));
        });

        return Promise.all(removeSelEqs);
      };

      fetchEqData().then((res) => {
        setRemoveIngs([]);
      });
    }

    if (updateEquipCat.length > 0) {
      const updateEquipCatg = () => {
        const allReq = updateEquipCat.map((e) => {
          let params = {
            accountId: accountId,
            name: e.name,
            info: {
              category: e.category,
              tags: e.info?.tags,
              imageUrl: e.info?.imageUrl,
              id: e.info?.id,
            },
            restaurantId: e.Equipment?.restaurantId,
          };

          console.log(params);

          updateEquip(e.info?.id, params)
            .then((response) => response)
            .catch((err) => console.log(err));
        });
        return Promise.all(allReq);
      };

      updateEquipCatg().then((res) => {
        console.log(res);
      });
    }

    if (addEquipmentList.length > 0) {
      let recEqs = addEquipmentList.map((eq) => {
        if (eq?.key) {
          delete eq.key;
        }
        return eq;
      });
      // let newEquipment = recEqs.filter((c) => c?.info?.category);
      // let existingEquipment = recEqs.filter((c) => !c?.info?.category);
      let newEquipment = recEqs.filter((c) => !c?.equipmentId);
      let existingEquipment = recEqs.filter((c) => c?.equipmentId);

      if (existingEquipment.length > 0) {
        const updateExistingEquipments = () => {
          const allReq = existingEquipment.map((e) => {
            let id = props.match.params.id;
            let equipmentId = e.id;
            let prep = {
              quantity: e?.quantity ? parseInt(e.quantity) : 0,
              size: 'size',
              type: 'type',
            };
            let params = {
              info: prep,
            };

            console.log(params);
            editEquipment(id, equipmentId, params)
              .then((response) => response)
              .catch((err) => console.log(err));
          });
          return Promise.all(allReq);
        };

        updateExistingEquipments().then((res) => {
          // console.log(res);
        });
      }

      if (newEquipment.length > 0) {
        const addNewEquipments = () => {
          const allReq = newEquipment.map((e) => {
            let id = props.match.params.id;
            let prep = {
              quantity: e?.quantity ? parseInt(e.quantity) : 0,
              size: 'size',
              type: 'type',
            };
            let params = {
              equipmentId: e.id,
              info: prep,
            };

            console.log(params);
            assignEquipment(id, params)
              .then((response) => response)
              .catch((err) => console.log(err));
          });
          return Promise.all(allReq);
        };

        addNewEquipments().then((res) => {
          console.log(res);
        });
      }
    }

    await update(props.match.params.id, params);

    setTimeout(() => {
      setAddIngredientsList([]);
      setAddEquipmentList([]);
      // console.log("Back location was " + props.location.state.from);
      if (props?.location?.state?.from && props?.location?.state?.from == 'menurecipes') {
        goBack();
      } else {
        fetchStationChecklists(props.match.params.id);
        getRecipe();
        getDetails();
        getEquipment();
        setEditing(false);
      }
    }, 2000);
  };

  const deleteRecipe = () => {
    destroy(props.match.params.id).then(
      (res) => {
        message.success('Recipe deleted!');
        setTimeout(() => {
          props.history.push(`/recipes/CreateRecipes`);
        }, 5000);
      },
      (error) => {
        message.error('Unable to delete recipe');
      }
    );
  };

  function confirm(e) {
    deleteRecipe();
  }

  function cancel(e) {
    message.error('Recipe not deleted');
  }

  const updateInstructions = useCallback((instructions) => {
    setInstructions(instructions);
  });

  const ingListUpdates = useCallback((ingredients) => {
    console.log('!!UPDATED INGREDIENTSS', ingredients);
    if (ingredients.length > 0) {
      setAddIngredientsList(ingredients);
      setOriginalIngredients(ingredients);
    }
  });

  const eqListUpdates = useCallback((equipment) => {
    console.log('!!UPDATED EQ', equipment);
    if (equipment.length > 0) {
      setAddEquipmentList(equipment);
    }
  });

  const updateEquipment = useCallback((equipment) => {
    console.log('UPDATED EQUIPMENT', equipment);
    let revisedEquip = equipment.map((i) => {
      i.quantity = i.quantity ? parseFloat(i.quantity) : 0;
      i.type = i.type ? i.type : 'type';
      i.size = i.size ? i.size : 'size';
      return i;
    });
    console.log(revisedEquip);
    setAddEquipmentList((oldArray) => [...oldArray.concat(revisedEquip)]);
  });

  const menu = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <Popconfirm
          title="Are you sure to delete this recipe?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <span>Delete recipe</span>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const handleDrop = useCallback((data) => {
    console.log('DATA', data);
    if (data !== 'none') {
      setImageUrls((oldArray) => [...oldArray.concat(data)]);
      // notification.open({
      //   message: 'Successfully Uploaded Image/s!',
      // });
    } else {
      // notification.open({
      //   message: 'Image/s not uploaded, please try again',
      // });
    }
  });

  const deleteRecImg = (e, img) => {
    console.log(imageUrls);
    console.log(img);
    let filterImgList = imageUrls.filter((image) => image !== img);
    setImageUrls(filterImgList);
  };

  const setPortions = (e) => {
    console.log('radio checked', e.target.value);
    setPortionValue(e.target.value);
    if (e.target.value === 'scale') {
      setScaleInputValue(1);
    } else {
      let currentQty = newQuantityVal ? newQuantityVal : quantityVal;
      setScaleInputValue(currentQty);
    }
  };

  const changeScaleVal = (e) => {
    setScaleInputValue(e);
  };

  const increaseVal = () => {
    let increasing = scaleInputValue + 1;
    setScaleInputValue(increasing);
  };

  const decreaseVal = () => {
    let increasing = scaleInputValue - 1;
    setScaleInputValue(increasing);
  };

  const calculateScale = (e) => {
    e.preventDefault();
    setShowReset(true);

    let currentQty = newQuantityVal ? newQuantityVal : quantityVal;
    let currentYield = newYieldVal ? newYieldVal : yieldVal;
    let scale_factor = 1;

    if (portionValue != 'scale') {
      const target_portions = scaleInputValue;
      scale_factor = currentQty ? (1.0 * target_portions) / currentQty : target_portions;
    } else {
      scale_factor = scaleInputValue;
    }

    // let scaleYield = currentYield ? currentYield * scale_factor : scale_factor;
    // let scaleQty = currentQty ? currentQty * scale_factor : scale_factor;

    // new values to assign
    let theYieldDisplay, theNewYield, theYieldMeasUnit;
    let theQuantityDisplay, theNewQuantity, theQuantityMeasUnit;

    const yieldRes = reformatVal(currentYield, yieldMeasUnit, scale_factor, true);

    const qtyRes = reformatVal(currentQty, 'Each', scale_factor, true);

    theYieldDisplay = yieldRes.reformattedVal;
    theNewYield = yieldRes.value;
    theYieldMeasUnit = yieldRes.changedUnit;

    theQuantityDisplay = qtyRes.reformattedVal;
    theNewQuantity = qtyRes.value;
    theQuantityMeasUnit = qtyRes.changedUnit;

    setYieldDisplay(scale_factor ? theYieldDisplay : '0');
    setNewYieldVal(scale_factor ? theNewYield : 0);
    setQuantityDisplay(scale_factor ? theQuantityDisplay : '0');
    setNewQuantityVal(scale_factor ? theNewQuantity : 0);
    setYieldMeasUnit(scale_factor ? theYieldMeasUnit : 'Select');
    setQuantityMeasUnit(scale_factor ? theQuantityMeasUnit : 'Select');

    const toScaleIngredients = cloneDeep(addIngredientsList);
    let scaledIngredients = toScaleIngredients.map((c) => {
      let ingredientQuantity = c?.originalQty ? fc(c.originalQty).toNumber() : fc(c.quantity).toNumber();
      if (ingredientQuantity > 0) {
        let ingredientMeasUnit =
          c.measurementId > 0
            ? c?.measurementName
              ? c.measurementName
              : c?.measurement
              ? c.measurement
              : measurements.find((m) => m.id === c.measurementId).name
            : 'Select';
        ingredientQuantity = ingredientQuantity > 0 ? ingredientQuantity : 0;
        const { reformattedVal: displayVal, value: actualVal, changedUnit: newUnit } = reformatVal(
          ingredientQuantity,
          ingredientMeasUnit,
          scale_factor,
          true
        );

        c.quantity = actualVal;
        c.originalQty = actualVal;
        c.displayQty = displayVal;

        let newMeasurementId = '';

        if (measurements.find((item) => item.name === newUnit)) {
          newMeasurementId = measurements.find((item) => item.name === newUnit).id;
        }

        c.measurement = newUnit;
        c.measurementId = newMeasurementId ? newMeasurementId.toString() : '0';
        c.measurementName = newUnit;
      }
      setQtyCombine(true);
      setScaleInputValue(1);
      return c;
    });
    setAddIngredientsList(scaledIngredients);
  };

  const updateQtyCombine = useCallback((data) => {
    setQtyCombine(data);
  });

  const editRecipe1 = () => {
    setEditing(true);
    setQtyCombine(true);
  };

  const resetMultiplier = () => {
    setScaleInputValue(1);

    setNewYieldVal(yieldVal);
    setYieldDisplay(reformatVal(yieldVal, prevYieldMeasUnit).reformattedVal);
    setYieldMeasUnit(prevYieldMeasUnit);

    setNewQuantityVal(quantityVal);
    setQuantityDisplay(reformatVal(quantityVal, prevQuantityMeasUnit).reformattedVal);
    setQuantityMeasUnit(prevQuantityMeasUnit);

    setAddIngredientsList(originalIngs);
    setPortionValue('scale');
    setShowReset(false);
  };

  function confirmDelete(e, image) {
    console.log(image);
    deleteRecImg(e, image);
  }

  const deletesImg = (obj) => {
    return (
      <Menu className="menu-show-preview">
        <Menu.Item className="menu-show-preview">
          <span style={{ color: 'var(--base-text)' }} onClick={(e) => confirmDelete(e, obj)}>
            Delete
          </span>
        </Menu.Item>
      </Menu>
    );
  };

  const goBack = () => {
    setRemoveEqs([]);
    setRemoveIngs([]);
    history.goBack();
    // //console.log("location: " + props.location.state.from);
    // let location = props.location.state;
    // if (location) {
    //   if (location.from == 'menurecipes') {
    //     // canceled edit = menu view recipe
    //     // back          = menu view recipe
    //     // ok            = menu view
    //     //console.log(`======== went back to /recipes/view1/${location.courseid}/${location.menuid}/${location.recipeid}`);
    //     history.push(`/recipes/view1/${location.courseid}/${location.menuid}/${location.recipeid}`);
    //     //history.push(`/menu/${props.location.state.menuid}`);
    //   } else if (location.from == 'station') {
    //     history.push(`/station/${props.location.state.stationId}`);
    //   } else if (location.from == 'event') {
    //     history.push(`/event/station/${props.location.state.stationId}`);
    //   } else {
    //     history.push(`/recipes`);
    //   }
    //
    // }
  };

  // reformat values from numeric to their desired formats based on metric
  const reformatVal = (val, measUnit, inputScale = undefined, toScale = false) => {
    if (measUnit && typeof measUnit === 'string' && measUnit !== 'Select' && toScale) {
      const { id: measure_id, equiv: equiv_amt } = measurements.find((item) => {
        return item.name === measUnit || measUnit.includes(item.name);
      });

      let reformattedVal,
        value = val,
        changedUnit = measUnit ? measUnit : 'Select';

      if (equiv_amt) {
        const showMeasure = new Measurement(val, measure_id, props.match.params.id, measurements);
        const scaledMeasure = showMeasure.scale(inputScale && toScale ? inputScale : 1).reduce();
        reformattedVal = scaledMeasure.quantity_ui_string();
        changedUnit = measurements.find((item) => scaledMeasure.measure_id === item.id).name;
        value = scaledMeasure.quantity;
      } else {
        if (inputScale != 0 && toScale) {
          value *= inputScale;
          reformattedVal = value;
        } else {
          reformattedVal = val;
        }
      }

      return {
        reformattedVal: formatIfNeeded(reformattedVal), // format into desired fractional string
        value: roundOff(value), // round off to 2 decimal places
        changedUnit,
      };
    } else {
      return {
        reformattedVal: val ? formatIfNeeded(val) : '0',
        value: val ? roundOff(val) : 0,
        changedUnit: measUnit ? measUnit : 'Select',
      };
    }
  };

  // FOCUS/BLUR FUNCTIONS for Recipe Metrics (prep time, cook time, yield, portion, quantity)
  const recalculateInputMeasurements = (val, measUnit, setNewVal, setValDisplay, setPrevMeasUnit, setMeasUnit) => {
    if (val) {
      const exactVal = Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100;

      // const {
      //   reformattedVal: theFormattedVal,
      //   value: theActualVal,
      //   changedUnit: theUnit,
      // } = reformatVal(exactVal, measUnit, 1, unitChanged);

      setNewVal(roundOff(exactVal));
      setValDisplay(formatIfNeeded(exactVal));
      setMeasUnit(measUnit);
      setPrevMeasUnit(measUnit);
    } else {
      setNewVal();
      setValDisplay('0');
    }
  };

  const blurInputMeasurements = (val, setNewVal, setValDisplay, type) => {
    if (val) {
      const exactVal = Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100;
      console.log('IS THE INPUT CHANGED??', inputChanged);

      if (inputChanged) {
        // formats the new value
        if (type === 'yield') {
          let measurementVal = newYieldVal ? newYieldVal : yieldVal;
          recalculateInputMeasurements(
            measurementVal,
            yieldMeasUnit,
            setNewYieldVal,
            setYieldDisplay,
            setPrevYieldMeasUnit,
            setYieldMeasUnit
            // true
          );
        } else if (type === 'portion') {
          let measurementVal = newPortionVal ? newPortionVal : portionVal;
          recalculateInputMeasurements(
            measurementVal,
            portionMeasUnit,
            setNewPortionVal,
            setPortionDisplay,
            setPrevPortionMeasUnit,
            setPortionMeasUnit
            // true
          );
        } else if (type === 'quantity') {
          setNewVal(exactVal);
          setValDisplay(formatIfNeeded(exactVal));
        }

        setInputChanged(false);
      } else {
        // restores the original reformatted value

        setValDisplay(tempDisplayVal);
        setTempDisplayVal('');
      }
    } else {
      setNewVal();
      setValDisplay('0');
    }
  };

  const blurInputTime = (e, metric) => {
    switch (metric) {
      case 'preptime':
        setNewPrepTime(e.target.value ? parseInt(e.target.value) : 0);
        break;
      case 'cooktime':
        setNewCookTime(e.target.value ? parseInt(e.target.value) : 0);
        break;
      default:
    }
  };

  const focusInputMeasurements = (metric) => {
    if (inputChanged) setInputChanged(false);

    switch (metric) {
      case 'yield':
        setTempDisplayVal(yieldDisplay);
        setYieldDisplay(ridAnnoyingZero(newYieldVal !== undefined ? newYieldVal : yieldVal));
        break;
      case 'portion':
        setTempDisplayVal(portionDisplay);
        setPortionDisplay(ridAnnoyingZero(newPortionVal !== undefined ? newPortionVal : portionVal));
        break;
      case 'quantity':
        setTempDisplayVal(quantityDisplay);
        setQuantityDisplay(ridAnnoyingZero(newQuantityVal !== undefined ? newQuantityVal : quantityVal));
        break;
      default:
    }
  };

  // INPUT FUNCTIONS for Recipe Metrics (prep time, cook time, yield, portion quantity)
  const checkMeasurementInput = (e, metric) => {
    // ////
    if (!inputChanged) setInputChanged(true);

    let isInvalid = false;
    if (!/^\d*\.?\d*$/.test(e.target.value)) {
      // notification.open({
      //   message: 'Please input a numeric value!',
      // });
      isInvalid = true;
    }

    const val = e.target.value;

    switch (metric) {
      case 'yield':
        setYieldDisplay(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : yieldVal));
        setNewYieldVal(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : yieldVal));
        break;
      case 'portion':
        setPortionDisplay(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : portionVal));
        setNewPortionVal(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : portionVal));
        break;
      case 'quantity':
        setQuantityDisplay(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : quantityVal));
        setNewQuantityVal(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : quantityVal));
        break;
      default:
    }
  };
  const checkTimeInput = (e, activity) => {
    let isInvalid = false;
    if (e.target.value !== '' && !/^-?\d+$/.test(e.target.value)) {
      // notification.open({
      //   message: 'Please input a numeric value!',
      // });
      isInvalid = true;
    }

    const val = e.target.value === '' ? 0 : e.target.value;

    switch (activity) {
      case 'preparation':
        setShowPrepTime(ridAnnoyingZero(!isInvalid ? val : prepTime));
        setNewPrepTime(isInvalid ? undefined : parseInt(val));
        break;
      case 'cooking':
        setShowCookTime(ridAnnoyingZero(!isInvalid ? val : cookTime));
        setNewCookTime(isInvalid ? undefined : parseInt(val));
        break;
      default:
    }
  };

  // const checkIfUnitHasEquivAmt = (valUnit) => {
  //   const findUnitInfo = measurements.find((d) => d.name === valUnit);
  //   if (findUnitInfo && 'equiv' in findUnitInfo) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const checkMeasurementUnitChange = (valUnit, metric) => {
    let measurementVal;
    //   toScale = false;
    // if (checkIfUnitHasEquivAmt(valUnit) && valUnit !== 'Select') {
    //   toScale = true;
    // }
    switch (metric) {
      case 'yield':
        measurementVal = newYieldVal ? newYieldVal : yieldVal;
        recalculateInputMeasurements(
          measurementVal,
          valUnit,
          setNewYieldVal,
          setYieldDisplay,
          setPrevYieldMeasUnit,
          setYieldMeasUnit
          // toScale
        );
        break;
      case 'portion':
        measurementVal = newPortionVal ? newPortionVal : portionVal;
        recalculateInputMeasurements(
          measurementVal,
          valUnit,
          setNewPortionVal,
          setPortionDisplay,
          setPrevPortionMeasUnit,
          setPortionMeasUnit
          // toScale
        );
        break;
      default:
    }
  };

  return operatorRestaurants.filter((r) => r.id == stationRestoId).length === 1 ? (
    <div id="view-recipe" className="common-page-container d-flex flex-column">
      <div className="common-subheader d-flex flex-row justify-content-between">
        <BacktoHeader
          backText={
            'Back to ' +
            (props.location.state && props.location.state.from
              ? (() => {
                  switch (props.location.state.from) {
                    case 'station':
                      return 'station';
                    case 'event':
                      return 'event station';
                    case 'menurecipes':
                      return 'menu recipe';
                    default:
                      return 'recipes';
                  }
                })()
              : 'recipes')
          }
          onClick={() => goBack()}
          showSearch={false}
        />
        <RoleAccessibleComponent
          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
        >
          <div className="view-recipe-controls align-center">
            {isEditing ? (
              <div className="dev-tag" onClick={saveRecipe} style={{ cursor: 'pointer' }}>
                {' '}
                <img src={edit} alt="edit" height="30" />
                Save
              </div>
            ) : (
              <div onClick={() => editRecipe1()} style={{ cursor: 'pointer' }}>
                {' '}
                <img src={edit} alt="edit" height="30" />
                Edit
              </div>
            )}
            {!isEditing && (
              <RoleAccessibleComponent
                permittedRoles={[
                  'Chef',
                  'Admin',
                  'Superadmin',
                  'Client Admin',
                  'Dev Team',
                  'Account Holder',
                  'Sous Chef',
                  'Cook',
                  'Trainer / Manager',
                ]}
              >
                <ReactToPrint
                  trigger={() => (
                    <div
                      className="flex-control-right"
                      style={{ cursor: 'pointer' }}
                      onMouseDown={() => {
                        // BUG 1201998443633170 NEW
                        if (!isPrinting) setIsPrinting(true);
                      }}
                      onMouseLeave={() => {
                        if (isPrinting) setIsPrinting(false);
                      }}
                    >
                      {' '}
                      {/* BUG 1201998443633170 */}
                      <img src={print} alt="print" height="30" />
                      Print
                    </div>
                  )}
                  content={() => componentRef.current}
                  onAfterPrint={() => setIsPrinting(false)} // BUG 1201998443633170
                  documentTitle={`${title}: Recipe Details`} // BUG 1201998443633170
                  onPrintError={() => setIsPrinting(false)} // BUG 1201998443633170
                  // see @media print stylesheet instead of pageStyle={``} // BUG 1201998443633170
                  pageStyle={`
									`} // BUG 1201998443633170
                />
              </RoleAccessibleComponent>
            )}
            <div className="flex-control-right">
              <Dropdown overlay={menu}>
                <div className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
                  <img src={more} alt="see_more" height="30" />
                </div>
              </Dropdown>
            </div>
          </div>
        </RoleAccessibleComponent>
      </div>

      {/* formsStart */}
      {isEditing ? (
        <div className="common-page-content">
          <Form name="create-edit-recipe-form">
            <div className="recipe-form-container">
              <div className="recipe-title required-asterisk">Title</div>
              <input
                placeholder="Enter a recipe name"
                type="text"
                className="recipe-input"
                defaultValue={title}
                onChange={(event) => setTitle(event.target.value)}
              />
              <div className="recipe-title">Description</div>
              <textarea
                type="text"
                className="recipe-textarea"
                placeholder="Short description of your recipe"
                defaultValue={description}
                onChange={(event) => setDesc(event.target.value)}
              ></textarea>
              <div className="recipe-title">
                Tag{' '}
                {showIns ? (
                  <span className="show-ins-enter">* Click 'Enter' key after typing each tag to save</span>
                ) : (
                  ''
                )}
              </div>
              <div className="recipe-input">
                {addTags.length > 0 &&
                  addTags.map((tag) => {
                    let color = 'black';
                    return (
                      <Tag color={color} key={tag} closable onClose={(e) => removeTag(e, tag)}>
                        {tag}
                      </Tag>
                    );
                  })}
                <input
                  type="text"
                  className="rec-input-create"
                  placeholder="Enter a tag"
                  value={enteredText}
                  onChange={typeTag}
                  onFocus={() => setShowIns(true)}
                  onKeyDown={addTag}
                />
              </div>

              <div className="recipe-title">Add file (includes photos, videos, documents)</div>
              <div style={{ position: 'relative' }}>
                <div className="demo-container">
                  <div className="carousel">
                    <input
                      checked="defaultChecked"
                      className="carousel__activator"
                      id="carousel-slide-activator-1"
                      name="carousel"
                      type="radio"
                    />
                    <input
                      className="carousel__activator"
                      id="carousel-slide-activator-2"
                      name="carousel"
                      type="radio"
                    />
                    <input
                      className="carousel__activator"
                      id="carousel-slide-activator-3"
                      name="carousel"
                      type="radio"
                    />
                    <div className="carousel__controls">
                      <label
                        className="carousel__control carousel__control--forward"
                        htmlFor="carousel-slide-activator-2"
                      >
                        <div className={'car-control'}> › </div>
                      </label>
                    </div>
                    <div className="carousel__controls">
                      <label
                        className="carousel__control carousel__control--backward"
                        htmlFor="carousel-slide-activator-1"
                      >
                        <div className="car-control"> ‹ </div>
                      </label>
                      <label
                        className="carousel__control carousel__control--forward"
                        htmlFor="carousel-slide-activator-3"
                      >
                        <div className="car-control"> › </div>
                      </label>
                    </div>
                    <div className="carousel__controls">
                      <label
                        className="carousel__control carousel__control--backward"
                        htmlFor="carousel-slide-activator-2"
                      >
                        <div className="car-control"> ‹ </div>
                      </label>
                    </div>
                    <div className="carousel__screen">
                      <div className="carousel__track">
                        {imageUrls.length > 0 &&
                          imageUrls.map((image, i) => {
                            return (
                              <div
                                key={`carousel-image-${i + 1}`}
                                className="carousel__item carousel__item--mobile-in-1 carousel__item--tablet-in-2 carousel__item--desktop-in-3"
                              >
                                <div className="demo-content">
                                  <div>
                                    <img src={image} />
                                    <Dropdown overlay={deletesImg(image)}>
                                      <div>
                                        {' '}
                                        <img src={IconsMoreOff} className="delete-Icons15" alt="IconsMoreOff" />
                                      </div>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={imageUrls.length > 0 ? 'addFile-margin' : 'addFile-margin1'}>
                <AddFile handleDrop={handleDrop} />
              </div>
            </div>
            <div className="Line"></div>
            {/* Prep and Cook Time */}
            <div className="recipe-form-container">
              <div className="prep-cook">
                <div className="flex-grid">
                  <InputSelectCombo3
                    formLabel="Prep time"
                    showValue={showPrepTime}
                    onChange={checkTimeInput}
                    keyword="preparation"
                    keyword2="preptime"
                    onBlur={blurInputTime}
                    timeUnit={prepTimeUnit}
                    setTimeUnit={setPrepTimeUnit}
                    windowSize={windowSize.width}
                  />
                  <InputSelectCombo3
                    formLabel="Cook time"
                    showValue={showCookTime}
                    onChange={checkTimeInput}
                    keyword="cooking"
                    keyword2="cooktime"
                    onBlur={blurInputTime}
                    timeUnit={cookTimeUnit}
                    setTimeUnit={setCookTimeUnit}
                    windowSize={windowSize.width}
                  />
                </div>
              </div>
            </div>
            <div className="Line"></div>
            {/* Yield, Portion and Quantity */}
            <div className="recipe-form-container">
              <div className="prep-cook">
                <div className="flex-grid">
                  <InputSelectCombo
                    formLabel="Yield"
                    onChange={checkMeasurementInput}
                    keyword="yield"
                    onFocus={focusInputMeasurements}
                    onBlur={blurInputMeasurements}
                    showValue={yieldDisplay}
                    selectVal={yieldMeasUnit}
                    setInputVal={setNewYieldVal}
                    setShowVal={setYieldDisplay}
                    onChangeSelect={checkMeasurementUnitChange}
                    windowSize={windowSize.width}
                  >
                    {measurements &&
                      measurements.length > 0 &&
                      measurements.map((m, i) => (
                        <option key={`${i + 1}`} value={m.name}>
                          {m.name}
                        </option>
                      ))}
                  </InputSelectCombo>
                  <InputSelectCombo
                    formLabel="Portion"
                    onChange={checkMeasurementInput}
                    keyword="portion"
                    onFocus={focusInputMeasurements}
                    onBlur={blurInputMeasurements}
                    showValue={portionDisplay}
                    selectVal={portionMeasUnit}
                    setInputVal={setNewPortionVal}
                    setShowVal={setPortionDisplay}
                    onChangeSelect={checkMeasurementUnitChange}
                    windowSize={windowSize.width}
                  >
                    {measurements &&
                      measurements.length > 0 &&
                      measurements.map((m, i) => (
                        <option key={`${i + 1}`} value={m.name}>
                          {m.name}
                        </option>
                      ))}
                  </InputSelectCombo>
                  <InputSelectCombo2
                    formLabel="Quantity"
                    onChange={checkMeasurementInput}
                    keyword="quantity"
                    onFocus={focusInputMeasurements}
                    onBlur={blurInputMeasurements}
                    showValue={quantityDisplay}
                    setInputVal={setNewQuantityVal}
                    setShowVal={setQuantityDisplay}
                  />
                </div>
              </div>

              <div className="recipe-title">
                <span>Adjust recipe</span>{' '}
                {showReset && (
                  <span onClick={resetMultiplier} className="reset-multiplier">
                    Reset
                  </span>
                )}
              </div>
              <div className="adj-rec-box">
                <div className="flex-grid-thirds">
                  <div className="col">Convert by</div>
                  <div className="col">
                    {' '}
                    <InputNumber
                      min={0}
                      max={1000}
                      style={{ margin: '0 90px', color: 'white', background: '#454545', border: 'none' }}
                      value={scaleInputValue}
                      onChange={changeScaleVal}
                    />
                  </div>
                  <div className="col"></div>
                </div>
                <div className="flex-grid-thirds1">
                  <div className="col">
                    <Radio.Group onChange={setPortions} value={portionValue}>
                      <Radio.Button value={'scale'}>Scale</Radio.Button>
                      <Radio.Button value={'portions'}>Portions</Radio.Button>
                    </Radio.Group>
                  </div>
                  <div className="col">
                    <div onClick={decreaseVal} className="calc-rec-less">
                      <img src={less} height="28" />
                    </div>
                    <Slider
                      min={1}
                      max={1000}
                      onChange={changeScaleVal}
                      value={typeof scaleInputValue === 'number' ? scaleInputValue : 0}
                    />
                    <div onClick={increaseVal} className="calc-rec-add">
                      <img src={add2} height="28" />
                    </div>
                  </div>
                  <div className="col">
                    <button className="calc-btn-recipe" onClick={(e) => calculateScale(e)}>
                      Calculate
                    </button>
                  </div>
                </div>
              </div>

              <div className="prep-cook"></div>
              <div>
                <IngredientTable
                  addIngredientsList={addIngredientsList}
                  unassignIng={unassignIng}
                  ingListUpdates={ingListUpdates}
                  isEditing={isEditing}
                  qtyCombine={qtyCombine}
                  updateQtyCombine={updateQtyCombine}
                  recipeId={props.match.params.id}
                />
                <AddIngredientPopup
                  updateIngredients={updateIngredients}
                  restaurantId={resto_id}
                />
              </div>
              <div>
                <EquipmentTable
                  addEquipmentList={addEquipmentList}
                  unassignEquip={unassignEquip}
                  eqListUpdates={eqListUpdates}
                  isEditing={isEditing}
                  updateEquipCategory={updateEquipCategory}
                />
                <AddEquipment updateEquipment={updateEquipment} />
              </div>
              <div>
                <InstructionsList
                  instructionlist={instructions}
                  isEditing={isEditing}
                  updateInstructions={updateInstructions}
                />
              </div>
            </div>
            <div className="Line"></div>

            <div className="btn-container">
              <button className="save-btn-recipe" onClick={saveRecipe}>
                Save
              </button>
              <button className="cancel-btn-recipe" onClick={cancelForm}>
                Cancel
              </button>
            </div>
          </Form>
        </div>
      ) : (
        <>
          {/* PRINTING LAYOUT */}
          <PrintRecipe
            ref={componentRef}
            isPrinting={isPrinting}
            name={viewRecipe.name}
            description={prepInfo.description}
            time={{
              preparation: prepInfo?.prepMins ? prepInfo.prepMins : 0,
              cooking: prepInfo?.cookMins ? prepInfo.cookMins : 0,
            }}
            scaling={{
              yield: {
                value: prepInfo.yield,
                unit: prepInfo.yieldVal,
              },
              portion: {
                value: prepInfo.portion,
                unit: prepInfo.portionVal,
              },
              quantity: prepInfo.quantity,
            }}
            ingredients={recipeIngredients}
            equipments={recipeEquipment}
            instructions={instructions}
            coverimage={imageUrls.length > 0 ? imageUrls[0] : null}
          />
          {/* VIEWING LAYOUT  --  add style={{ display: 'none' } to supress non-print render */}
          <div className="view-recipe-column-container">
            <div className="flex-grow-1 d-flex h-100 scrollable-outer">
              <div className="scrollable-container">
                <div className="scrollable h-100">
                  <div className="page-margins">
                    <div className="view-recipe-title-container flex-control-left">
                      <div className="view-recipe-title flex-control-left">{viewRecipe.name}</div>
                      <div className="flex-control flex-control-vert-center">
                        <MasterRecipeIcon />
                      </div>
                    </div>

                    <div className="view-recipe-description">{prepInfo.description}</div>

                    <div className="view-recipe-tags-container">
                      {(tags || []).map((tag, i) => {
                        return (
                          <div key={`recipe-tag-${i + 1}`} className="tag">
                            {tag}
                          </div>
                        );
                      })}
                    </div>

                    <div className="view-recipe-metadata-container">
                      <div className="view-recipe-metadata-cell flex-control-left">
                        {/*these are styled via .view-recipe-metadata-cell svg */}
                        <PrepTimeIcon />
                        Prep:
                        <span className="view-prep-details-container">
                          {prepTime === 0 ? (
                            <span className="view-prep-details-measure">To be Estimated</span>
                          ) : prepTime >= 60 ? (
                            <span className="view-prep-details-measure">
                              {showPrepTime + ' ' + (prepHr > 1 ? pluralize('hour') : ' hour')}
                            </span>
                          ) : (
                            <span className="view-prep-details-measure">
                              {showPrepTime + ' ' + (prepMn > 1 ? pluralize('min') : ' min')}
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="view-recipe-metadata-cell flex-control">
                        <CookTimeIcon />
                        Cook:
                        <span className="view-prep-details-container">
                          {cookTime === 0 ? (
                            <span className="view-prep-details-measure">To be Estimated</span>
                          ) : cookTime >= 60 ? (
                            <span className="view-prep-details-measure">
                              {showCookTime + ' ' + (cookHr > 1 ? pluralize('hour') : ' hour')}
                            </span>
                          ) : (
                            <span className="view-prep-details-measure">
                              {showCookTime + '  ' + (cookMn > 1 ? pluralize('min') : ' min')}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="view-recipe-metadata-container">
                      <div className="view-recipe-metadata-cell flex-control-left">
                        <YieldIcon />
                        Yield:
                        <span className="view-prep-details-container">
                          <span className="view-prep-details-measure">{prepInfo.yield}</span>
                          <span className="view-prep-details-measure">
                            {prepInfo.yieldVal &&
                            prepInfo.yieldVal !== 'Select' &&
                            measurements.length > 0 &&
                            measurements.map((d) => d.name).includes(prepInfo.yieldVal)
                              ? prepInfo.yieldVal
                              : ' '}
                          </span>
                        </span>
                      </div>
                      <div className="view-recipe-metadata-cell flex-control">
                        <PortionIcon />
                        Portion:
                        <span className="view-prep-details-container">
                          <span className="view-prep-details-measure">{prepInfo.portion}</span>
                          <span className="view-prep-details-measure">
                            {prepInfo.portionVal &&
                            prepInfo.portionVal !== 'Select' &&
                            measurements.length > 0 &&
                            measurements.map((d) => d.name).includes(prepInfo.portionVal)
                              ? prepInfo.portionVal
                              : ' '}
                          </span>
                        </span>
                      </div>
                      <div className="view-recipe-metadata-cell view-qty flex-control">
                        <ServingIcon />
                        Quantity:
                        <span className="view-prep-details-container">
                          <span className="view-prep-details-measure">{prepInfo.quantity}</span>
                          <span className="view-prep-details-measure">{prepInfo.quantity > 0 ? 'portions' : ' '}</span>
                        </span>
                      </div>
                    </div>

                    <div className="Line-flex"></div>
                    <div className="flex-break"></div>
                    {recipeIngredients.length || isEditing ? (
                      <IngredientsList ingredientList={recipeIngredients} />
                    ) : (
                      ''
                    )}
                    <div className="flex-break"></div>
                    {recipeEquipment.length || isEditing ? <EquipmentList equipmentList={recipeEquipment} /> : ''}
                    <div className="flex-break"></div>
                    <InstructionsList
                      instructionlist={instructions}
                      isEditing={isEditing}
                      updateInstructions={updateInstructions}
                    />
                    <div className="Line"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-margins">
              <NutritionViewPopup nutritionList={recipeDetails.nutrition} imageUrls={imageUrls} showCarousel={true} />
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="common-page-container">
      <ErrorPage copy="Recipe belongs to another restaurant! This content is unavailable to your user account. If you feel you should have access to this content, please contact your local administrator" />
    </div>
  );
};

export default Form.create()(ViewRecipes);
