import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Tag, Switch, Tooltip, notification, Menu, Dropdown, Drawer, Select } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { allLocations, destroy, update, create } from '../../services/restaurant.service';
import { cloneDeep, find, remove, findIndex, isEmpty } from 'lodash';
import _ from 'lodash';
import { timeZones } from '../../data/timezones';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { NewSubHeader } from '../../components/Header/Header';
import { PlusButton, CidekicDropdown, CidekicSelect, RowOkCancel } from '../../components/common/common_ui';

const Locations = (props) => {
  const [menuName, setMenuName] = useState('');
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [menulist, setList] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);

  const location_name = useRef(null);
  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';

  const newLocationValue = {
    id: 'add',
    name: '',
    address: '',
    timezone: timeZones[574],
  };

  const { Option } = Select;

  useEffect(() => {
    getLocations();
    setTimeout(() => {
      setHasUpdated(false);
      setEditing(false);
      setAdding(false);
      setEditId(null);
    }, 2000);
  }, [hasUpdated]);

  const getLocations = () => {
    allLocations(0, 1000).then((res) => {
      console.log(res);
      let allLoc = res;
      let address1 = allLoc.filter((a) => a.deletedAt == '');
      let address = address1.map((l) => {
        return {
          id: l.id,
          name: l.name,
          address: l.info && l.info?.address ? l.info?.address : '',
          timezone: l.info && l.info?.timezone ? l.info?.timezone : '',
        };
      });

      console.log(address);
      setList(address);
      setData(address);
      setOrigData(address);
    });
  };

  // const debouncedApiCall = useCallback(
  //   _.debounce((e) => {
  //     filterLocations(e);
  //   }, 200),
  //   []
  // );

  useEffect(() => {
    filterLocations(nameFilter);
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All restaurants');
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All restaurants</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>
                {m?.name} - {m?.address}
              </a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterLocations = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getLocations();
    }
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name + '-' + ' ' + menu.address;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.id === menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deleteLocation = (e) => {
    e.preventDefault();
    let id = selected?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Location Successfully Deleted',
      // });
      setHasUpdated(true);
    });
  };

  const editLocation = (e, obj) => {
    e.preventDefault();
    console.log(obj);
    setEditing(true);
    setEditId(obj.id);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editLocation(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteLocation(e)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updateLocation = (obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename,
      info: {
        address: obj.address,
        timezone: obj.timezone,
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated location!',
      // });
      setHasUpdated(true);
    });
  };

  const addLocation = (obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename !== '' ? rename : 'Untitled',
      info: {
        address: obj.address !== '' ? obj.address : '',
        timezone: obj.timezone !== '' ? obj.timezone : '',
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created location!',
      // });
      setHasUpdated(true);
    });
  };

  const handleAddressChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].address = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleTimeChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].timezone = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      width: '30%',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id ? (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateLocation(obj);
                }
              }}
            />
          ) : (
            <div>{key}</div>
          )}
          {isAdding && obj.id == 'add' ? (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  addLocation(obj);
                }
              }}
            />
          ) : (
            ''
          )}
        </span>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      width: '45%',
      sorter: (a, b) => (a.address < b.address ? -1 : a.address > b.address ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {((isEditing && editId == obj.id) || (isAdding && obj.id == 'add')) && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter an address"
              defaultValue={key}
              onChange={(e) => handleAddressChange(e.target.value, obj)}
            />
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Timezone',
      dataIndex: 'timezone',
      width: '20%',
      sorter: (a, b) => (a.timezone < b.timezone ? -1 : a.timezone > b.timezone ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {((isEditing && editId == obj.id) || (isAdding && obj.id == 'add')) && (
            <CidekicSelect
              className="admin-select-select"
              placeholder="Select timezone"
              onChange={(val) => handleTimeChange(val, obj)}
              defaultValue={isAdding ? undefined : obj.timezone}
              showSearch
            >
              {timeZones.map((zone) => (
                <Option value={zone}>{zone}</Option>
              ))}
            </CidekicSelect>
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'controls',
      width: '5%',
      render: (key, obj) => (
        <RoleAccessibleComponent
          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
        >
          <span>
            {!isEditing && !isAdding && (
              <Dropdown overlay={controlItems(obj)}>
                <div className="toggle-switch">
                  <img
                    src={IconsMoreOff}
                    className="UI-IconsMoreOff"
                    alt="IconsMoreOff"
                    onMouseEnter={() => setSelected(obj)}
                  />
                </div>
              </Dropdown>
            )}
            {((isEditing && editId == obj.id) || (isAdding && obj.id == 'add')) && (
              <RowOkCancel
                className="float-right"
                onClickOk={isEditing ? () => updateLocation(obj) : () => addLocation(obj)}
                onClickCancel={cancelEdit}
              />
            )}
          </span>
        </RoleAccessibleComponent>
      ),
    },
  ];

  return (
    <div id="admin-locations" className="common-page-container">
      <NewSubHeader
        title="Locations"
        onChange={(evt) => setNameFilter(evt.target.value)}
        showSearch={accountRole != 'Superadmin'}
      />
      <div className="Line"></div>
      <div className="common-page-content">
        <div className="common-controls-container controls-thin">
          <RoleAccessibleComponent
            permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
          >
            <PlusButton
              className="flex-control-left"
              alt="Add new location"
              onClick={() => {
                setAdding(true);
                const clonedDataSource = cloneDeep(origData);
                clonedDataSource.unshift(newLocationValue);
                setData(clonedDataSource);
              }}
            >
              Add new location
            </PlusButton>
          </RoleAccessibleComponent>

          <CidekicDropdown overlay={menuList} className="flex-control-right select-wide">
            {menuName ? menuName : 'Restaurant name'}
          </CidekicDropdown>
        </div>

        <Table
          dataSource={userData}
          columns={columns}
          pagination={false}
          className={'show-custom-empty'}
          locale={{ emptyText: <Empty image={empty} description="No locations" /> }}
          scroll={{ y: 'calc(100vh - var(--table-scroll-offset))' }}
        />
      </div>
    </div>
  );
};

export default Locations;
