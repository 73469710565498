/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useRef } from 'react';
import { Table, Tag, Menu, Dropdown, Select } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { PlusOutlined } from '@ant-design/icons';
//import { Link } from 'react-router-dom';
import { onFileSelected } from '../../services/upload.service';
import { search, create, update, destroy } from '../../services/equipment.service';
import { all } from '../../services/account.service';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { cloneDeep, remove, findIndex, uniq } from 'lodash';
//import _ from 'lodash';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';
import { ThumbnailImage, RowOkCancel } from '../../components/common/common_ui';

const SuperEquipmentMobile = (props) => {
  const [menuName, setMenuName] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState({});
  const [menulist, setList] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);
  //const [isLoading, setIsLoading] = useState(true);
  const [editTags, setEditTags] = useState(false);
  const [editObj, setEditObj] = useState({});
  const [userimg, setUserImg] = useState('');
  //const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(0);
  const [socketData, setSocketData] = useState([]);
  const [equipUpdate, setEquipUpdate] = useState([]);
  const [equipAdd, setEquipAdd] = useState([]);
  const [equipDelete, setEquipDelete] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/equipment`));
  const [equipCategories, setEquipCategories] = useState([]);
  const location_name = useRef(null);
  const fileInput = useRef();

  const { Option } = Select;

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let accountsId = JSON.parse(localStorage.getItem('operator')).accountId || '';
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';

  const newLocationValue = {
    key: `${userData.length}`,
    id: 'add',
    name: '',
    category: '',
    tags: [],
    image: '',
    restaurantId: resto,
  };

  useEffect(() => {
    getEquip();
    setTimeout(() => {
      setHasUpdated(false);
      setEditing(false);
      setAdding(false);
      setEditId(null);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdated]);

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    console.log(getEditData);
    console.log(editTags);
    console.log(isEditing);
    const listener = (event) => {
      if (isEditing && editTags && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        console.log('ADD!! Enter key was pressed. Run add.');
        //event.preventDefault();
        console.log(getEditData);
        updateEquip(event, getEditData);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, getEditData, editTags]);

  const getEquip = () => {
    //setIsLoading(true);
    let params = {
      description: '',
      restaurantId: resto.id,
      accountId: accountsId,
      isSuperAdmin: true,
    };
    search(params).then((res) => {
      console.log(res);
      if (res) {
        let allEquip = res.filter((r) => r.id !== 'add');
        console.log(res);
        let equipment = allEquip.map((l) => {
          return {
            id: l.id,
            name: l.name,
            restaurantId: l.restaurantId,
            category: l.info && l.info?.category ? l.info?.category : 'Uncategorized',
            tags: l.info && l.info?.tags ? l.info?.tags : [],
            image: l.info ? l.info?.imageUrlThumb || l.info?.imageUrl || '' : '',
            accountId: l.accountId,
            isMaster: l.info && l.info?.createdBy === 'system_ingest',
          };
        });

        console.log(equipment);
        let currentEquipCategories = uniq(equipment.map((e) => e.category).filter((cat) => cat !== '' && cat !== null));
        setEquipCategories(currentEquipCategories);
        equipment.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setData(equipment);
        setOrigData(equipment);
        setSocketData(equipment);
        //setIsLoading(false);
      }
    });
  };

  const getAccounts = () => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setList(allAccounts);
      }
    });
  };

  useEffect(() => {
    filterEquip(nameFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All accounts');
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <span>All accounts</span>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" key={i} onClick={(e) => filteringMenus(m)}>
              <span>{m?.name}</span>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterEquip = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getEquip();
    }
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.accountId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deleteEquip = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: 'Unable to delete a master list item.',
      // });
      return;
    }
    e.preventDefault();
    //let id = selected?.id;
    let id = obj?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Equipment Successfully Deleted',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'deleteEquipment',
        JSON.stringify({
          id: id,
        })
      );
    });
  };

  const editEquip = (e, obj) => {
    e.preventDefault();
    console.log(obj);
    setEditing(true);
    setEditId(obj.id);
    setEditObj(null);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editEquip(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteEquip(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = (obj) => {
    obj.image = selected?.image ? selected.image : '';
    setEditing(false);
    setEditObj(null);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updateEquip = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    let rename = location_name?.current?.value?.trim();
    let selectedObj = {};
    if (obj == null) {
      selectedObj = editObj;
    } else {
      selectedObj = obj;
    }
    console.log('UPDATE!!!', obj);
    let params = {
      accountId:
        (selectedObj.accountId && selectedObj.accountId !== 'Select') || selectedObj.accountId !== ''
          ? selectedObj.accountId
          : 0,
      name: rename,
      info: {
        category: selectedObj.category,
        tags: selectedObj.tags,
        imageUrl: obj.image,
        id: editId,
      },
    };
    console.log(params);
    update(editId, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated equipment!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'updateEquipment',
        JSON.stringify({
          accountId: params.accountId,
          category: params.info?.category,
          id: params.info?.id,
          image: params.info?.imageUrl,
          name: params.name,
          restaurantId: params.info?.restaurantId,
          tags: params.info?.tags,
        })
      );
    });
  };

  const updateEquip2 = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    let rename = location_name.current.value.trim();
    console.log('UPDATE!!!', obj);
    let params = {
      accountId: obj.accountId,
      name: rename,
      info: {
        category: obj.category,
        tags: obj.tags,
        imageUrl: obj.image,
        id: obj.id,
      },
    };
    console.log(params);
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated equipment!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'updateEquipment',
        JSON.stringify({
          accountId: params.accountId,
          category: params.info?.category,
          id: params.info?.id,
          image: params.info?.imageUrl,
          name: params.name,
          restaurantId: params.info?.restaurantId,
          tags: params.info?.tags,
        })
      );
    });
  };

  const addEquip = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      accountId: account,
      name: rename !== '' ? rename : 'Untitled',
      info: {
        category: obj.category,
        tags: obj.tags,
        imageUrl: userimg,
      },
    };
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created equipment!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'addEquipment',
        JSON.stringify({
          accountId: res?.accountId,
          category: res?.category,
          id: res?.id,
          image: res?.image,
          name: res?.name,
          restaurantId: res?.restaurantId,
        })
      );
    });
  };

  const addEquip2 = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      accountId: account,
      name: rename !== '' ? rename : 'Untitled',
      info: {
        category: obj.category,
        tags: obj.tags,
        imageUrl: userimg,
      },
    };
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created equipment!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'addEquipment',
        JSON.stringify({
          accountId: res?.accountId,
          category: res?.category,
          id: res?.id,
          image: res?.image,
          name: res?.name,
          restaurantId: res?.restaurantId,
        })
      );
    });
  };

  const handleCategoryChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].category = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleAddTags = (value, obj, type) => {
    console.log(value);
    console.log(obj);

    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].tags.push(value);
    console.log(clonedChecklist);
    setData(clonedChecklist);
    console.log(clonedChecklist[foundIndex]);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleRemoveTags = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    remove(clonedChecklist[foundIndex].tags, (tag) => {
      return tag === value;
    });
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleAccountChange = (value, obj) => {
    console.log(value, obj);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].accountId = value;
    setAccount(value);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleOnDrop = (e, obj) => {
    //setLoading(true);
    setEditing(true);
    e.preventDefault();
    e.stopPropagation();
    console.log(e.dataTransfer.files[0]);

    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        obj.image = res.Location;
        setUserImg(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        //setLoading(false);
      } else {
        //setLoading(false);
        let userimage = URL.createObjectURL(e.dataTransfer.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const handleOnDrop1 = (e, obj) => {
    //setLoading(true);
    setEditing(true);
    e.preventDefault();
    e.stopPropagation();
    console.log(e.dataTransfer.files[0]);

    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        obj.image = res.Location;
        //addEquip(e, obj);
        setUserImg(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        // setLoading(false);
      } else {
        //setLoading(false);
        let userimage = URL.createObjectURL(e.dataTransfer.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const uploadImg = (e, obj) => {
    //setLoading(true);
    if (!isAdding) {
      setEditing(true);
    }
    e.preventDefault();
    e.stopPropagation();

    onFileSelected(e.target.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        setUserImg(res.Location);
        obj.image = res.Location;
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        // setLoading(false);
      } else {
        //setLoading(false);
        let userimage = URL.createObjectURL(e.target.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const uploadImg1 = (e, obj) => {
    //setLoading(true);
    if (!isAdding) {
      setEditing(true);
    }
    e.preventDefault();
    e.stopPropagation();

    onFileSelected(e.target.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        setUserImg(res.Location);
        obj.image = res.Location;
        //addEquip(e, obj);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        // setLoading(false);
      } else {
        //setLoading(false);
        let userimage = URL.createObjectURL(e.target.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div>
            <div>
              <Dropdown overlay={controlItems(obj)}>
                <div style={{ cursor: 'pointer', float: 'right' }}>
                  <img
                    src={IconsMoreOff}
                    className="UI-IconsMoreOff"
                    alt="IconsMoreOff"
                    onClick={() => {
                      if (Object.keys(selected).length === 0 || selected.id != obj.id) {
                        setSelected({ ...obj });
                      }
                    }}
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          {/* {obj.image !== '' ? <img src={obj.image} className="admin-image-container-mobile" /> : <div className="user-name-box1"></div>} */}
          <span>
            {obj.image && obj.image !== '' && !isEditing && !isAdding && (
              <ThumbnailImage className="admin-image-container-mobile" type={'admin'} src={obj.image} />
            )}
            {obj.image && obj.image !== '' && isEditing && !isAdding && editId == obj.id && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop(e, obj)}
              >
                <ThumbnailImage
                  className="admin-image-container-mobile"
                  type={'admin'}
                  src={userimg}
                />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
            {(!obj.image || obj.image == '') && !isEditing && !isAdding && (
              <ThumbnailImage
                className="admin-image-container-mobile"
                type={'admin'}
                src={obj.image !== '' ? obj.image : null}
              />
            )}
            {(!obj.image || obj.image == '') && isEditing && !isAdding && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop(e, obj)}
              >
                <ThumbnailImage
                  className="admin-image-container-mobile"
                  type={'admin'}
                  src={userimg}
                />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
            {(!obj.image || obj.image == '') && isAdding && !isEditing && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop1(e, obj)}
              >
                <ThumbnailImage
                  className="admin-image-container-mobile"
                  type={'admin'}
                  src={userimg}
                />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg1(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
          </span>
          {/* Title */}
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '-6px' }}>Title</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      updateEquip(e, obj);
                    }
                  }}
                  // onBlur={(e) => updateEquip2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit(obj)}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className={isAdding && obj.id == 'add' ? '' : 'loc_nameMob19'}>{obj.name}</div>
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addEquip(e, obj);
                    }
                  }}
                  //onBlur={(e) => addEquip2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit(obj)}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          {/* Category */}
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Category</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 30 }}>
                <Select
                  className="station-selectEv10"
                  onChange={(e) => {
                    handleCategoryChange(e, obj);
                  }}
                  defaultValue={obj?.category ? obj.category : equipCategories[0]}
                  showArrow={false}
                  showSearch
                  onKeyPress={(e) => {
                    setEditTags(false);
                    if (e.key === 'Enter') {
                      setEditTags(true);
                      updateEquip(e, obj);
                    }
                  }}
                >
                  {equipCategories.map((cat, i) => (
                    <Option key={i} value={cat}>
                      {cat}
                    </Option>
                  ))}
                </Select>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a category"
                  defaultValue={obj.category}
                  onChange={(e) => handleCategoryChange(e.target.value, obj)}
                  onBlur={() => setEditTags(true)}
                  onKeyPress={(e) => {
                    setEditTags(false);
                    if (e.key === 'Enter') {
                      setEditTags(true);
                      updateEquip(e, obj);
                    }
                  }}
                /> */}
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 30 }}>
                <Select
                  className="station-selectEv10"
                  onChange={(e) => {
                    console.log('category value', e);
                    handleCategoryChange(e, obj);
                  }}
                  defaultValue={obj?.category ? obj.category : equipCategories[0]}
                  showArrow={false}
                  showSearch
                >
                  {equipCategories.map((cat, i) => (
                    <Option key={i} value={cat}>
                      {cat}
                    </Option>
                  ))}
                </Select>
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 30 }}>{obj.category}</div>}
          </div>
          {/* Tags */}
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px', marginRight: 57 }}>Tags</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 20 }}>
                <input
                  type="text"
                  placeholder="Add tags"
                  className="checklist-tags1"
                  name="input"
                  id="input"
                  onBlur={() => setEditTags(true)}
                  onKeyPress={(e) => {
                    setEditTags(false);
                    if (e.key === 'Enter') {
                      handleAddTags(e.target.value, obj, 'edit');
                      e.target.value = '';
                      setEditTags(true);
                    }
                  }}
                />
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <input
                style={{ marginLeft: 57 }}
                type="text"
                placeholder="Add tags"
                className="checklist-tags1"
                name="input"
                id="input"
                onBlur={() => setEditTags(true)}
                onKeyPress={(e) => {
                  setEditTags(false);
                  if (e.key === 'Enter') {
                    handleAddTags(e.target.value, obj, 'edit');
                    e.target.value = '';
                    setEditTags(true);
                  }
                }}
              />
            ) : (
              ''
            )}
            {obj.tags?.map((tag) => {
              let color = 'black';
              return (
                <Tag
                  color={color}
                  key={Math.random().toString()}
                  closable={isEditing}
                  onClose={(e) => {
                    // remove tags
                    handleRemoveTags(tag, obj);
                  }}
                >
                  {tag}
                </Tag>
              );
            })}
          </div>
          {/* Account */}
          <div>
            {/* adding */}
            {isAdding && obj.id == 'add' && (
              <span>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: '-6px', marginRight: 25 }}>Account</div>
                  <select
                    style={{ marginBotttom: 15 }}
                    className="admin-select"
                    onChange={(e) => handleAccountChange(e.target.value, obj)}
                  >
                    <option initialvalue="default">Select</option>
                    {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
                  </select>
                </div>
              </span>
            )}
            {/* editing */}
            {isEditing && editId == obj.id && (
              <span>
                <div style={{ display: 'flex', marginTop: 15 }}>
                  <div style={{ marginLeft: '-6px', marginRight: 34 }}>Account</div>
                  <select
                    className="admin-select"
                    defaultValue={obj.accountId}
                    onChange={(e) => handleAccountChange(e.target.value, obj)}
                  >
                    <option initialvalue="default">Select</option>
                    {menulist &&
                      menulist.length > 0 &&
                      menulist.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      ))}
                  </select>
                </div>
              </span>
            )}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px', marginRight: 115 }}></div>
            {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
              <RowOkCancel
                onClickOk={isEditing ? (e) => updateEquip(e, obj) : (e) => addEquip(e, obj)}
                onClickCancel={() => cancelEdit(obj)}
              />
            ) : (
              ''
            )}
          </div>
        </span>
      ),
    },
  ];

  const columns2 = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div>
            {isAdding && obj.id == 'add' && (
              <span>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: '-6px', marginRight: 25 }}>Account</div>
                  <select
                    style={{ marginBotttom: 15 }}
                    className="admin-select"
                    onChange={(e) => handleAccountChange(e.target.value, obj)}
                  >
                    <option initialvalue="default">Select</option>
                    {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
                  </select>
                </div>
              </span>
            )}
            {isEditing && editId == obj.id && (
              <span>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: '-6px', marginRight: 25 }}>Account</div>
                  <select
                    className="admin-select"
                    defaultValue={obj.accountId}
                    onChange={(e) => handleAccountChange(e.target.value, obj)}
                  >
                    <option initialvalue="default">Select</option>
                    {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
                  </select>
                </div>
              </span>
            )}
          </div>
          <div>
            <div>
              <Dropdown overlay={controlItems(obj)}>
                <div style={{ cursor: 'pointer', float: 'right' }}>
                  <img
                    src={IconsMoreOff}
                    className="UI-IconsMoreOff"
                    alt="IconsMoreOff"
                    onClick={() => {
                      if (Object.keys(selected).length === 0 || selected.id != obj.id) {
                        setSelected({ ...obj });
                      }
                    }}
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          {/* {obj.image !== '' ? <img src={obj.image} className="admin-image-container-mobile" /> : <div className="user-name-box1"></div>} */}
          <span>
            {obj.image && obj.image !== '' && !isEditing && !isAdding && (
              <ThumbnailImage className="admin-image-container-mobile" type={'admin'} src={obj.image} />
            )}
            {obj.image && obj.image !== '' && isEditing && !isAdding && editId == obj.id && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop(e, obj)}
              >
                <ThumbnailImage
                  className="admin-image-container-mobile"
                  type={'admin'}
                  src={userimg}
                />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
            {(!obj.image || obj.image == '') && !isEditing && !isAdding && (
              <ThumbnailImage
                className="admin-image-container-mobile"
                type={'admin'}
                src={obj.image !== '' ? obj.image : null}
              />
            )}
            {(!obj.image || obj.image == '') && isEditing && !isAdding && editId == obj.id && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop(e, obj)}
              >
                <ThumbnailImage
                  className="admin-image-container-mobile"
                  type={'admin'}
                  src={userimg}
                />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
            {(!obj.image || obj.image == '') && isAdding && !isEditing && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop1(e, obj)}
              >
                <ThumbnailImage
                  className="admin-image-container-mobile"
                  type={'admin'}
                  src={userimg}
                />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg1(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
          </span>
          {/* Title */}
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '-6px' }}>Title</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      updateEquip2(e, obj);
                    }
                  }}
                  // onBlur={(e) => updateEquip2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit(obj)}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className={isAdding && obj.id == 'add' ? '' : 'loc_nameMob19'}>{obj.name}</div>
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addEquip(e, obj);
                    }
                  }}
                  //onBlur={(e) => addEquip2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit(obj)}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          {/* Category */}
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Category</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 30 }}>
                <Select
                  className="station-selectEv10"
                  onChange={(e) => {
                    handleCategoryChange(e, obj);
                  }}
                  defaultValue={obj?.category ? obj.category : equipCategories[0]}
                  showArrow={false}
                  showSearch
                  onBlur={() => setEditTags(true)}
                >
                  {equipCategories.map((cat, i) => (
                    <Option key={i} value={cat}>
                      {cat}
                    </Option>
                  ))}
                </Select>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a category"
                  defaultValue={obj.category}
                  onChange={(e) => handleCategoryChange(e.target.value, obj)}
                  onBlur={() => setEditTags(true)}
                  
                /> */}
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 30 }}>
                <Select
                  className="station-selectEv10"
                  onChange={(e) => {
                    handleCategoryChange(e, obj);
                  }}
                  defaultValue={obj?.category ? obj.category : equipCategories[0]}
                  showArrow={false}
                  showSearch
                >
                  {equipCategories.map((cat, i) => (
                    <Option key={i} value={cat}>
                      {cat}
                    </Option>
                  ))}
                </Select>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a category"
                  defaultValue={obj.category}
                  onChange={(e) => handleCategoryChange(e.target.value, obj)}
                /> */}
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 30 }}>{obj.category}</div>}
          </div>
          {/* Tags */}
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px', marginRight: 57 }}>Tags</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 20 }}>
                <input
                  type="text"
                  placeholder="Add tags"
                  className="checklist-tags1"
                  name="input"
                  id="input"
                  onBlur={() => setEditTags(true)}
                  onKeyPress={(e) => {
                    setEditTags(false);
                    if (e.key === 'Enter') {
                      handleAddTags(e.target.value, obj, 'edit');
                      e.target.value = '';
                      setEditTags(true);
                    }
                  }}
                />
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <input
                style={{ marginLeft: 57 }}
                type="text"
                placeholder="Add tags"
                className="checklist-tags1"
                name="input"
                id="input"
                onBlur={() => setEditTags(true)}
                onKeyPress={(e) => {
                  setEditTags(false);
                  if (e.key === 'Enter') {
                    handleAddTags(e.target.value, obj, 'edit');
                    e.target.value = '';
                    setEditTags(true);
                  }
                }}
              />
            ) : (
              ''
            )}
            {obj.tags?.map((tag) => {
              let color = 'black';
              return (
                <Tag
                  color={color}
                  key={tag}
                  closable={isEditing}
                  onClose={(e) => {
                    // remove tags
                    handleRemoveTags(tag, obj);
                  }}
                >
                  {tag}
                </Tag>
              );
            })}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px', marginRight: 115 }}></div>
            {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
              <RowOkCancel
                onClickOk={isEditing ? (e) => updateEquip(e, obj) : (e) => addEquip(e, obj)}
                onClickCancel={() => cancelEdit(obj)}
              />
            ) : (
              ''
            )}
          </div>
        </span>
      ),
    },
  ];

  useEffect(() => {
    eventSocket.on('updatedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPUPDATED!!', [newData]);
      setEquipUpdate([newData]);
    });
    eventSocket.on('addedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPADDED!!', newData);
      setEquipAdd(newData);
    });
    eventSocket.on('deletedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPDELETED!!', newData.id);
      setEquipDelete(newData);
    });
    return () => {
      eventSocket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData((oldArray) => [...oldArray, equipAdd]);
    setOrigData((oldArray) => [...oldArray, equipAdd]);
    setSocketData((oldArray) => [...oldArray, equipAdd]);
  }, [equipAdd]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.map((obj) => equipUpdate.find((o) => parseInt(o.id) === obj.id) || obj);
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipUpdate]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.filter((obj) => obj.id !== parseInt(equipDelete));
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipDelete]);

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Equipment"
        restaurant="Super Admin"
        showSearch={showSearch}
        onChange={(evt) => setNameFilter(evt.target.value)}
        clickSearch={() => setShowSearch(true)}
      />
      <div style={{ display: 'flex' }}>
        <div className="add-cat23Mob">
          <div>{menuName ? menuName : 'Account name'}</div>
        </div>
        <Dropdown overlay={menuList}>
          <div className="dropdown-icon">
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </Dropdown>
        <div className="spacer-cat"></div>
        <div
          className="add-cat13Mob"
          // onClick={() => {
          //   setIsAdding(true);
          // }}
        >
          <div
            style={{ cursor: 'pointer', fontFamily: 'Helvetica Neue' }}
            onClick={() => {
              setAdding(true);
              const clonedDataSource = cloneDeep(origData);
              clonedDataSource.unshift(newLocationValue);
              setData(clonedDataSource);
              window.scrollTo(0, 0);
              setUserImg('')
            }}
          >
            + <span style={{ marginLeft: 5 }}>Add new equipment</span>
          </div>
        </div>
      </div>
      <div style={{ padding: '30px 20px 0px 15px' }}>
        <Table
          dataSource={userData}
          columns={isAdding ? columns2 : columns}
          pagination={false}
          className={'show-custom-empty'}
          rowKey={'id'}
          locale={{ emptyText: <Empty image={empty} description="No equipment" /> }}
        />
      </div>{' '}
      <div className="add-checklistLoc">
        <div
          className="add-checklist-but"
          onClick={() => {
            setAdding(true);
            const clonedDataSource = cloneDeep(origData);
            clonedDataSource.push(newLocationValue);
            setData(clonedDataSource);
          }}
        >
          <span>
            <PlusOutlined />
          </span>{' '}
          Add equipment
        </div>
      </div>
    </div>
  );
};

export default SuperEquipmentMobile;
