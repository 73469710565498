import React, { useEffect, useState, forwardRef } from 'react';
import { formatIfNeeded } from '../../pages/Recipes/utilities';
import { ThumbnailImage } from '../../components/common/common_ui';
import { pluralize } from 'inflected';
import { PrepTimeIcon, CookTimeIcon, YieldIcon, PortionIcon, ServingIcon } from '../../components/common/common_ui';

const PrintRecipe = forwardRef((props, ref) => {
  const [showPrepTime, setShowPrepTime] = useState('');
  const [showCookTime, setShowCookTime] = useState('');

  const [prepHr, setPrepHr] = useState(0);
  const [prepMn, setPrepMn] = useState(0);
  const [cookHr, setCookHr] = useState(0);
  const [cookMn, setCookMn] = useState(0);

  const parseDisplayTime = (time, setHour, setMin, setDisplay) => {
    if (time >= 60) {
      let displayTime = Math.floor(time / 60);
      setHour(displayTime);
      setDisplay(displayTime.toString());
    } else {
      setMin(time);
      setDisplay(time.toString());
    }
  };

  useEffect(() => {
    if (props.time.preparation > 0) {
      parseDisplayTime(props.time.preparation, setPrepHr, setPrepMn, setShowPrepTime);
    }

    if (props.time.cooking > 0) {
      parseDisplayTime(props.time.cooking, setCookHr, setCookMn, setShowCookTime);
    }
  }, [props.time.preparation, props.time.cooking]);

  return (
    <div className={`print-recipe-${props.isPrinting ? 'show' : 'hide'} view-recipe-column-container`} ref={ref}>
      {/* <ThumbnailImage
					type="common-cover-image"
					src={props?.coverimage ? props.coverimage : recipePlaceholderImg}
				/> */}
      <div className="flex-grow-1 d-flex h-100 scrollable-outer">
        <div className="scrollable-container">
          <div className="print-page-margins">
            <div className="d-flex">
              <div className="print-recipe-split-info">
                <div className="print-recipe-title-container flex-control-left">
                  <div className="view-recipe-title flex-control-left">{props.name}</div>
                </div>

                <div className="view-recipe-description">{props.description}</div>

                <div className="view-recipe-metadata-container">
                  <div className="print-recipe-metadata-cell flex-control-left">
                    {/*these are styled via .view-recipe-metadata-cell svg */}
                    <PrepTimeIcon />
                    Preparation Time:
                    <span className="view-prep-details-container">
                      {props.time.preparation >= 60 ? (
                        <span className="view-prep-details-measure">
                          {showPrepTime + ' ' + (prepHr > 1 ? pluralize('hour') : ' hour')}
                        </span>
                      ) : props.time.preparation > 0 ? (
                        <span className="view-prep-details-measure">
                          {showPrepTime + ' ' + (prepMn > 1 ? pluralize('min') : ' min')}
                        </span>
                      ) : (
                        <span className="view-prep-details-measure">0</span>
                      )}
                    </span>
                  </div>
                  <div className="print-recipe-metadata-cell flex-control">
                    <CookTimeIcon />
                    Cooking Time:
                    <span className="view-prep-details-container">
                      {props.time.cooking >= 60 ? (
                        <span className="view-prep-details-measure">
                          {showCookTime + ' ' + (cookHr > 1 ? pluralize('hour') : ' hour')}
                        </span>
                      ) : props.time.cooking > 0 ? (
                        <span className="view-prep-details-measure">
                          {showCookTime + '  ' + (cookMn > 1 ? pluralize('min') : ' min')}
                        </span>
                      ) : (
                        <span className="view-prep-details-measure">0</span>
                      )}
                    </span>
                  </div>
                </div>
                <div className="view-recipe-metadata-container">
                  <div className="print-recipe-metadata-cell flex-control-left">
                    <YieldIcon />
                    Yield:
                    <span className="view-prep-details-container">
                      <span className="view-prep-details-measure">{props.scaling.yield.value}</span>
                      <span className="view-prep-details-measure">{props.scaling.yield.unit}</span>
                    </span>
                  </div>
                  <div className="print-recipe-metadata-cell flex-control">
                    <PortionIcon />
                    Portion:
                    <span className="view-prep-details-container">
                      <span className="view-prep-details-measure">{props.scaling.portion.value}</span>
                      <span className="view-prep-details-measure">{props.scaling.portion.unit}</span>
                    </span>
                  </div>
                  <div className="print-recipe-metadata-cell view-qty flex-control">
                    <ServingIcon />
                    Quantity:
                    <span className="view-prep-details-container">
                      <span className="view-prep-details-measure">{props.scaling.quantity}</span>
                      <span className="view-prep-details-measure">{props.scaling.quantity > 0 ? 'portions' : ' '}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="print-recipe-split-image">
                <ThumbnailImage src={props?.coverimage ? props.coverimage : null} type={'recipe'} />
              </div>
            </div>
            {props.ingredients.length > 0 ? (
              <>
                <div className="Line"></div>
                <div className="print-recipe-section-container">
                  <div className="view-recipe-section-title">Ingredients</div>
                  <div>
                    {props.ingredients.map((ingredient) => {
                      return (
                        <div
                          key={`ingredient-${Math.random()}-info`}
                          className="flex-control"
                          style={{ marginRight: 20 }}
                        >
                          <div className="ingredient-name-container">
                            {/* <ThumbnailImage
  																type="ingredient-image-container"
  																src={(ingredient?.imageUrlThumb || ingredient?.imageUrl)
  																	? (ingredient?.imageUrlThumb || ingredient?.imageUrl)
  																	: ingredient?.info && (ingredient?.info?.imageUrlThumb || ingredient?.info?.imageUrl)
  																		? (ingredient?.info?.imageUrlThumb || ingredient?.info?.imageUrl)
  																		: null}
  																placeholderIcon={<ChefIcon />}
  															/> */}
                            <div style={{ marginLeft: 20 }}>
                              {`${formatIfNeeded(ingredient?.quantity)} ${ingredient?.measurement} ${ingredient?.name}${
                                ingredient?.preparation ? ', ' + ingredient?.preparation : ''
                              }`}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="Line"></div>
                <div className="print-recipe-section-container">
                  <div className="view-recipe-section-title">No ingredients yet</div>
                </div>
              </>
            )}
            {props.equipments.length > 0 && (
              <>
                <div className="Line"></div>
                <div className="print-recipe-section-container">
                  <div className="view-recipe-section-title">Equipment</div>
                  <div>
                    {props.equipments.map((equipment) => {
                      return (
                        <div
                          key={`equipment-${Math.random()}-info`}
                          className="flex-control"
                          style={{ marginRight: 20 }}
                        >
                          <div className="ingredient-name-container">
                            {/* <ThumbnailImage
  																type="ingredient-image-container"
  																src={(equipment?.imageUrlThumb || equipment?.imageUrl)
  																	? (equipment?.imageUrlThumb || equipment?.imageUrl)
  																	: equipment?.info && (equipment?.info?.imageUrlThumb || equipment?.info?.imageUrl)
  																		? (equipment?.info?.imageUrlThumb || equipment?.info?.imageUrl)
  																		: null}
  																placeholderIcon={<ChefIcon />}
  															/> */}
                            <div style={{ marginLeft: 20 }}>
                              {`${equipment?.quantity > 0 ? `${equipment.quantity} pieces ` : ''}${equipment?.name}${
                                equipment?.size ? equipment.size : ''
                              }${equipment?.type ? equipment.type : ''}`}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}

            {props.instructions.length > 0 && (
              <>
                <div className="Line"></div>
                <div className="print-recipe-section-container">
                  <div className={'view-recipe-section-title'}>Instructions</div>
                </div>
                <div>
                  {props.instructions.map((instruction) => {
                    return (
                      <div key={`instruction-${instruction.id}-info`}>
                        {' '}
                        <div className="viewRecipe-Line"></div>
                        <div className="instructions-box">
                          <div className="instructions-order">{instruction.id + 1}</div>
                          <div className="instruction-title">{instruction.title}</div>
                        </div>
                        <div className="instruction-description">{instruction.content}</div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrintRecipe;
