/* eslint eqeqeq: "off" */
import React, { useState } from 'react';
import { create } from '../../services/account.service';
import { Form } from 'antd';
import { BacktoHeader } from '../../components/Header/Header';

const AccountsNew = (props) => {
  const [account, setAccount] = useState('');

  const cancelForm = () => {
    window.location.reload();
  };

  const saveUser = (e) => {
    e.preventDefault();
    let rename = account.trim();
    let params = {
      name: rename,
      info: { name: rename },
    };
    console.log(params);
    if (rename == '') {
      return;
    } else {
      create(params).then((res) => {
        // notification.open({
        //   message: 'Successfully created account!',
        // });
        props.history.push('/super/accounts');
      });
    }
  };

  return (
    <div id="superadmin-accounts-new" className="common-page-container">
      <div className="common-subheader">
        <BacktoHeader backLink="/super/accounts" backText="Back to accounts" showSearch={false} />
      </div>
      <div className="Line"></div>
      <div className="common-page-content">
        <Form onSubmit={saveUser} style={{ color: 'var(--base-text)' }}>
          <div>
            <div className="checklist-title">Add new account</div>
            <br />
            <div>
              <div>
                <div style={{ marginLeft: 37 }}>Account name</div>
                <input
                  placeholder="Enter account name"
                  type="text"
                  className="user-input21"
                  defaultValue={account}
                  onChange={(event) => setAccount(event.target.value)}
                />
                <br />
                <div className="btn-container" style={{ marginLeft: 40 }}>
                  <button className="save-btn-recipe" htmltype="submit">
                    Create
                  </button>
                  <button className="cancel-btn-recipe" onClick={cancelForm}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Form.create()(AccountsNew);
