import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import './index.css';

const chartColors = ['red', 'yellow', 'green', 'orange', 'blue'];

const data = {
  labels: ['Ingredient 1', 'Ingredient 2', 'Ingredient 3', 'Ingredient 4', 'Ingredient 5'],
  datasets: [
    {
      backgroundColor: chartColors,
      hoverBackgroundColor: chartColors,
      borderWidth: 1,
      data: [17, 20, 12, 8, 5],
    },
  ],
};

function DonutGraph() {
  return (
    <div className="bargraph-container">
      <div className="bargraph-ing-title">Ingredients breakdown</div>
      <div className="donut-container">
        <div>
          <Doughnut
            data={data}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              legend: false,
              //  legend: { labels: { usePointStyle: true, fontColor: 'white' }, position: 'right' },
            }}
          />
        </div>
        <div>
          <div className="donut-stats-box">
            <div>
              <span className="donut-dot-red"></span>Ingredient 1
            </div>
            <div>
              <div>US $00.00</div>
              <div className="donut-stat-percent">
                <span className="donut-arrow-up"></span>2%
              </div>
            </div>
          </div>
          <div className="Line"></div>
          <div className="donut-stats-box">
            <div>
              <span className="donut-dot-yellow"></span>Ingredient 2
            </div>
            <div>
              <div>US $00.00</div>
              <div className="donut-stat-percent">
                <span className="donut-arrow-down"></span>4.5%
              </div>
            </div>
          </div>
          <div className="Line"></div>
          <div className="donut-stats-box">
            <div>
              <span className="donut-dot-green"></span>Ingredient 3
            </div>
            <div>
              <div>US $00.00</div>
              <div className="donut-stat-percent">
                <span className="donut-dot-gray"></span>0%
              </div>
            </div>
          </div>
          <div className="Line"></div>
          <div className="donut-stats-box">
            <div>
              <span className="donut-dot-orange"></span>Ingredient 4
            </div>
            <div>
              <div>US $00.00</div>
              <div className="donut-stat-percent">
                <span className="donut-dot-gray"></span>0%
              </div>
            </div>
          </div>
          <div className="Line"></div>
          <div className="donut-stats-box">
            <div>
              <span className="donut-dot-blue"></span>Ingredient 5
            </div>
            <div>
              <div>US $00.00</div>
              <div className="donut-stat-percent">
                <span className="donut-dot-gray"></span>0%
              </div>
            </div>
          </div>
          <div className="Line"></div>
        </div>
      </div>
    </div>
  );
}
export default DonutGraph;
