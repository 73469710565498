import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import { Link } from 'react-router-dom'
import MeetingAndEventDay from '../../components/MeetingAndEventDay/MeetingAndEventDay';
import MeetingAndEventWeek from '../../components/MeetingAndEventWeek/MeetingAndEventWeek';
import MeetingAndEventMonth from '../../components/MeetingAndEventMonth/MeetingAndEventMonth';
// import DayStations from '../../components/DayStations/DayStations';
import KitchenStations from '../../components/KitchenStations/KitchenStations';
import EventStations from '../../components/EventStations/EventStations';
// import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import moment from 'moment';
import { filter } from 'lodash';
import { Menu, Dropdown, DatePicker } from 'antd'; // { Menu, Dropdown, Progress, Row, Col }
import {
  getEventsToday,
  getEventsWeek,
  getEventsFuture,
  getCurrentWeek,
  formatGetTime,
  momentize,
} from './DashboardUtils';
import AddEventDrawerV2 from './components/AddEventDrawerV2';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import './index.css';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { AlertTwoTone } from '@ant-design/icons';
import { SubHeader } from '../../components/Header/Header';
import reorder from '../../img/reorder-icon.png';
import { PlusIcon } from '../../components/common/common_ui';

const Dashboard = () => {
  let currentWeekStart = moment().startOf('isoWeek');
  const [calendarType, setCalendarType] = useState('day');
  const [day, setDay] = useState(moment(new Date()).format('dddd, MMMM D, YYYY'));
  const [meetingsAndEvents, setMeetingsAndEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [weekStart, setWeekStart] = useState(currentWeekStart);
  const [currentWeek, setCurrentWeek] = useState(getCurrentWeek(weekStart));
  const [showDrawer, setShowDrawer] = useState({ type: '', open: false });
  const [hasCreateEvent, setHasCreatedEvent] = useState(false);
  const [today, setToday] = useState(false);
  const [eventUpdated, updatedEvent] = useState(false);
  const [addEventUpdate, setAddEventUpdate] = useState('');
  const [editEventUpdate, setEditEventUpdate] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/event`));
  const [monthEvents, setMonthEvents] = useState([]);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [monthAddedEvent, setMonthAddedEvent] = useState(false);

  const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;

  const stationToggles = [
    {
      id: 'event',
      name: 'eventSt',
    },
    {
      id: 'kitchen',
      name: 'kitchenSta',
    },
  ];
  const [characters, updateCharacters] = useState(stationToggles);

  let momentDateToday = momentize(day);

  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }, []);

  useEffect(() => {
    const dashOrder = JSON.parse(localStorage.getItem('dashboardOrder'));
    if (dashOrder) {
      updateCharacters(dashOrder);
    }
  }, []);

  const _getEventsToday = useCallback(
    (day, setMeetingsAndEvents, resto, calendarType) => {
      getEventsToday(day, setMeetingsAndEvents, resto, calendarType);
    },
    [calendarType, day, weekStart]
  );

  // get Events for Today
  useEffect(() => {
    const restoId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
    setHasCreatedEvent(false);
    updatedEvent(false);
    setMonthAddedEvent(false);
    let resto = window.location.href.includes('impersonate') ? restoId : resto_id;
    // getEventsToday(day, setMeetingsAndEvents, restoId);
    if (calendarType == 'week') {
      _getEventsToday(weekStart, setMeetingsAndEvents, resto, calendarType);
    } else if (calendarType == 'day') {
      _getEventsToday(day, setMeetingsAndEvents, resto, calendarType);
    } else {
      console.log(monthEvents);
      setMeetingsAndEvents(monthEvents);
    }
  }, [
    day,
    hasCreateEvent,
    setMeetingsAndEvents,
    eventUpdated,
    window.location.href,
    calendarType,
    weekStart,
    monthEvents,
  ]);

  const _getEventsFuture = useCallback(
    (day, setFutureEvents, resto) => {
      getEventsFuture(day, setFutureEvents, resto);
    },
    [day]
  );

  // get Events for Tomorrow Onward
  useEffect(() => {
    const restoId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
    setHasCreatedEvent(false);
    updatedEvent(false);
    let resto = window.location.href.includes('impersonate') ? restoId : resto_id;
    _getEventsFuture(day, setFutureEvents, resto);
  }, [day, hasCreateEvent, setFutureEvents, eventUpdated, window.location.href]);

  const _getEventsWeek = useCallback(
    (weekStart, setCurrentWeek, resto) => {
      getEventsWeek(weekStart, setCurrentWeek, resto);
    },
    [weekStart]
  );

  // getEvents for the Week
  useEffect(() => {
    const restoId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
    // console.log(weekStart);
    setHasCreatedEvent(false);
    updatedEvent(false);
    let resto = window.location.href.includes('impersonate') ? restoId : resto_id;
    _getEventsWeek(weekStart, setCurrentWeek, resto);
  }, [setCurrentWeek, hasCreateEvent, weekStart, eventUpdated, window.location.href]);

  const _eventStationData = useMemo(() => {
    console.log(meetingsAndEvents);
    const eventStationData = [];
    const events = filter(meetingsAndEvents, (ev) => {
      return ev.type === 'event';
    });
    console.log('EVENTS', events);
    for (let i = 0; i < events.length; i++) {
      const {
        id,
        name,
        startTimestamp,
        endTimestamp,
        percentage,
        type,
        repeatDays,
        repeat,
        info,
        EventMenus,
        EventOperators,
      } = events[i];
      const sortDate = repeatDays ? new Date(repeatDays) : new Date(moment.unix(parseInt(startTimestamp)));
      const date = repeatDays
        ? moment(repeatDays[0]).format('ddd, D')
        : moment.unix(parseInt(startTimestamp)).format('ddd, D');
      const time = `${formatGetTime(startTimestamp)} - ${formatGetTime(endTimestamp)}`;

      eventStationData.push({
        id,
        event: name,
        percentage: percentage ? parseInt(percentage) : 0,
        sortDate,
        date,
        time,
        type,
        repeatDays,
        startTimestamp,
        endTimestamp,
        repeat,
        name,
        info,
        EventMenus,
        EventOperators,
      });
    }
    console.log('EVENTSTATION!', eventStationData);
    eventStationData.sort((a, b) => {
      return a.sortDate - b.sortDate;
    });
    return eventStationData;
  }, [meetingsAndEvents]);

  const eventList = (
    <Menu className="menu-show-preview">
      {[
        { name: 'event', label: 'Event' },
        { name: 'meeting', label: 'Meeting' },
      ].map((m, i) => {
        return (
          <Menu.Item key={i} className="menu-show-preview" onClick={() => setShowDrawer({ type: m.name, open: true })}>
            <a>{m.label}</a>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);

    localStorage.setItem('dashboardOrder', JSON.stringify(items));
  }

  const simultateClickEvent = (id) => {
    let el = document.getElementById(id);
    if (!el) return;
    if (el.fireEvent) {
      el.fireEvent('onclick');
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent('click', true, false);
      el.dispatchEvent(evObj);
    }
  };

  const getToday = () => {
    let today = moment(new Date()).format('dddd, MMMM D, YYYY');
    setDay(today);
    setToday(true);
    if (calendarType !== 'day') {
      setCalendarType('day');
    }
    const restoId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id; // BUG 1202030604174557
    _getEventsWeek(currentWeekStart, setCurrentWeek, restoId); // 1202030604174557
  };

  const anotherDay = useCallback(
    (data) => {
      setToday(false);
    },
    [today]
  );

  const editedEvent = useCallback((data) => {
    updatedEvent(data);
  }, []);

  const addedEvent = useCallback((data) => {
    _getEventsToday(day, setMeetingsAndEvents, resto_id);
    _getEventsFuture(day, setFutureEvents, resto_id);
    _getEventsWeek(weekStart, setCurrentWeek, resto_id);
    setMonthAddedEvent(true);
  }, []);

  // useEffect(() => {
  //   if (showDrawer.open) {
  //     document.documentElement.style.overflow = 'hidden';
  //     document.body.scroll = 'no';
  //   } else {
  //     document.documentElement.style.overflow = 'scroll';
  //     document.body.scroll = 'yes';
  //   }
  // }, [showDrawer]);

  useEffect(() => {
    eventSocket.on('addedEvent', (message) => {
      console.log('EVENTADDED!!', message);
      setAddEventUpdate(message);
    });
    eventSocket.on('updatedEvent', (message) => {
      console.log('EVENTUPDATED!!', message);
      setEditEventUpdate(message);
    });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (addEventUpdate) {
      _getEventsToday(day, setMeetingsAndEvents, resto_id);
      _getEventsFuture(day, setFutureEvents, resto_id);
      _getEventsWeek(weekStart, setCurrentWeek, resto_id);
    }
  }, [addEventUpdate]);

  useEffect(() => {
    if (editEventUpdate) {
      updatedEvent(true);
    }
  }, [editEventUpdate]);

  const handleDateChange = (e) => {
    console.log(e);
    if (calendarType == 'day') {
      setDay(moment(new Date(e)).format('dddd, MMMM D, YYYY'));
      setOpenDatePicker(false);
    } else if (calendarType == 'week') {
      setWeekStart(e.clone().startOf('isoWeek'));
      setOpenDatePicker(false);
    }
  };

  useEffect(() => {
    const container = document.getElementById('clickbox');

    const listener = (event) => {
      if (container && container !== event.target && !container.contains(event.target)) {
        if (openDatePicker) {
          setOpenDatePicker(false);
        }
      }
    };

    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [openDatePicker]);

  return (
    <div id="dashboard" className="common-page-container">
      <div className="common-subheader">
        <SubHeader title="Dashboard" showSearch={false} />
        {calendarType === 'day' ? (
          <div>
            <div className="dashboard-subheader-controls">
              <div
                className="dash-button first-btn"
                onClick={() => {
                  setDay(momentDateToday.subtract(1, 'days').format('dddd, MMMM D, YYYY'));
                }}
              >
                <span className="Back-Chevron">&#60;</span>
              </div>

              <div className="dash-button date-btn" id="clickbox">
                {openDatePicker ? (
                  <DatePicker
                    getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                    style={{ height: 0 }}
                    open={openDatePicker}
                    onChange={(e) => {
                      handleDateChange(e);
                    }}
                    allowClear={false}
                    size={`small`}
                  />
                ) : (
                  <div onClick={() => setOpenDatePicker(true)}>{day}</div>
                )}
              </div>

              <div
                className="dash-button last-btn"
                onClick={() => {
                  setDay(momentDateToday.add(1, 'days').format('dddd, MMMM D, YYYY'));
                }}
              >
                <span className="Back-Chevron">&#62;</span>
              </div>
            </div>
          </div>
        ) : calendarType === 'week' ? (
          <div className="dashboard-subheader-controls">
            <div>
              <div
                className="dash-button first-btn"
                onClick={() => {
                  setWeekStart(weekStart.clone().subtract(1, 'weeks').startOf('isoWeek'));
                }}
              >
                <span className="Back-Chevron">&#60;</span>
              </div>
              <div className="dash-button date-btn" id="clickbox">
                {openDatePicker ? (
                  <DatePicker
                    getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                    style={{ height: 0 }}
                    open={openDatePicker}
                    onChange={(e) => {
                      handleDateChange(e);
                    }}
                    allowClear={false}
                    size={`small`}
                  />
                ) : (
                  <div onClick={() => setOpenDatePicker(true)}>
                    {' '}
                    {momentize(currentWeek[0].date).format('D MMMM')} - {currentWeek[6].date}
                  </div>
                )}
              </div>

              <div
                className="dash-button last-btn"
                onClick={() => {
                  setWeekStart(weekStart.clone().add(1, 'weeks').startOf('isoWeek'));
                }}
              >
                <span className="Back-Chevron">&#62;</span>
              </div>
            </div>
          </div>
        ) : calendarType === 'month' ? (
          <div>
            <div className="mo-btn"></div>
          </div>
        ) : null}
        <div className="dashboard-subheader-controls">
          <div style={{ display: 'flex' }}>
            <div>
              <div
                className={'dash-button first-btn' + (calendarType == 'day' ? ' dash-shaded' : '')}
                onClick={() => setCalendarType('day')}
              >
                Day
              </div>
              <div
                className={'dash-button week-btn' + (calendarType == 'week' ? ' dash-shaded' : '')}
                onClick={() => setCalendarType('week')}
              >
                Week
              </div>
              <div
                className={'dash-button last-btn' + (calendarType == 'month' ? ' dash-shaded' : '')}
                onClick={() => setCalendarType('month')}
              >
                Month
              </div>
              {calendarType == 'month' ? (
                <div className="dash-button today-btn" onClick={() => simultateClickEvent('BigCalendar-nav-today')}>
                  Today
                </div>
              ) : (
                <div className="dash-button today-btn" onClick={getToday}>
                  Today
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="dashboard-subheader-controls">
          <RoleAccessibleComponent
            permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
          >
            <Dropdown overlay={eventList} trigger={['click']}>
              <div alt="add-button" className="common-plus-icon">
                <PlusIcon />
              </div>
            </Dropdown>
          </RoleAccessibleComponent>
        </div>
        <RoleAccessibleComponent
          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
        >
          <AddEventDrawerV2
            showDrawer={showDrawer.open}
            type={showDrawer.type}
            setHasCreatedEvent={setHasCreatedEvent}
            setShowDrawer={setShowDrawer}
            addedEvent={addedEvent}
          />
        </RoleAccessibleComponent>
      </div>
      <div className="Line"></div>
      {/* <div className="common-page-content">
        <div className="common-controls-container"> */}
      <div className="common-page-content-flex d-flex flex-column">
        {calendarType === 'day' ? (
          <MeetingAndEventDay
            daySelected={day}
            isToday={momentDateToday.isSame(moment(), 'day')}
            setHasCreatedEvent={setHasCreatedEvent}
            setHasEditedEvent={editedEvent}
            meetingsAndEvents={meetingsAndEvents}
            futureEvents={futureEvents}
          />
        ) : calendarType === 'week' ? (
          <div className="scrollable-outer" style={{ height: '400px', marginBottom: '0 !important' }}>
            <MeetingAndEventWeek
              day={day}
              weekData={currentWeek}
              setCalendarType={setCalendarType}
              setDay={setDay}
              today={today}
              anotherDay={anotherDay}
              setHasEditedEvent={editedEvent}
            />
          </div>
        ) : calendarType === 'month' ? (
          <div className="" style={{ height: '600px', marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
            <MeetingAndEventMonth
              hasCreateEvent={hasCreateEvent}
              setCalendarType={setCalendarType}
              setDay={setDay}
              today={today}
              setMonthEvents={setMonthEvents}
              monthAddedEvent={monthAddedEvent}
            />
          </div>
        ) : null}

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <ul
                className="characters dashboard-station-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {characters.map(({ id, name }, index) => {
                  return (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided) => (
                        <li ref={provided.innerRef} {...provided.draggableProps}>
                          {name == 'eventSt' ? (
                            <EventStations
                              eventStationData={_eventStationData}
                              setHasCreatedEvent={setHasCreatedEvent}
                              setHasEditedEvent={editedEvent}
                            />
                          ) : (
                            <span>
                              <span>
                                {' '}
                                <div style={{ float: 'right', marginTop: 15 }} {...provided.dragHandleProps}>
                                  <img src={reorder} className="stationToggle" alt="Reorder collections" />
                                </div>
                              </span>
                              <KitchenStations />
                            </span>
                          )}
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default Dashboard;
