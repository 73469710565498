import { request } from './http.service';
import { notification } from 'antd';
import { decodeRecipeMessages } from '../protobuf-models/recipe.proto.js';

export const create = (params) => {
  return request('POST', '/recipe', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

// export const duplicateEventRecipe = (params) => {
// 	return request('POST', '/recipe/for/event', 201, params).then(
// 		(result) => {
// 			return result;
// 		},
// 		(error) => {
// 			console.log(error);
// 		}
// 	)
// }

export const duplicate = (params) => {
  return request('POST', '/recipe/duplicate', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const update = (id, params) => {
  return request('PUT', '/recipe/' + id, 200, params).then(
    (result) => {
      notification['success']({
        message: 'Recipe updated!',
        description: `Successfully updated the recipe. Fetching updated recipe...`,
      });
      return result;
    },
    (error) => {
      console.log(error);
      notification['error']({
        message: 'Recipe not updated!',
        description: `Failed to update recipe. Please try again`,
      });
    }
  );
};

export const destroy = (id) => {
  return request('DELETE', '/recipe/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const get = (id) => {
  return request('GET', '/recipe/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const search = (params) => {
  return request('POST', '/recipe/search', 200, params, decodeRecipeMessages).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const details = (id) => {
  return request('GET', '/recipe/' + id + '/details', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const assignDetail = (id, params) => {
  return request('POST', '/recipe/' + id + '/details', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const assignAllDetails = (id, ingredients) => {
  return request('POST', '/recipe/' + id + '/details/bulk', 201, { ingredients: ingredients }).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const editDetail = (id, ingredientId, preparationId, measurementId, stationId, params) => {
  return request(
    'PUT',
    '/recipe/' + id + '/details/' + ingredientId + '/' + preparationId + '/' + measurementId + '/' + stationId,
    200,
    params
  ).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const unassignDetail = (id, ingredientId, preparationId, measurementId, stationId) => {
  return request(
    'DELETE',
    '/recipe/' + id + '/details/' + ingredientId + '/' + preparationId + '/' + measurementId + '/' + stationId,
    204
  ).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const equipment = (id) => {
  return request('GET', '/recipe/' + id + '/equipments', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const assignEquipment = (id, params) => {
  return request('POST', '/recipe/' + id + '/equipments', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const assignAllEquipments = (id, equipments) => {
  return request('POST', '/recipe/' + id + '/equipments/bulk', 201, { equipments: equipments }).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const editEquipment = (id, equipmentId, params) => {
  return request('PUT', '/recipe/' + id + '/equipments/' + equipmentId, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const unassignEquipment = (id, equipmentId) => {
  return request('DELETE', '/recipe/' + id + '/equipments/' + equipmentId, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const deleteEventRecipes = (recipeId, menuId) => {
  return request('DELETE', '/recipe/' + recipeId + '/duplicates/under/menu/' + menuId, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};
