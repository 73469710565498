import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentView = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const originPage = JSON.parse(localStorage.getItem('docProps')) || {};

  console.log(originPage);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const downloadFile = async (e, imgSrc) => {
    e.preventDefault();
    let img = imgSrc.replace('https://cidekic-images.s3.amazonaws.com', 'https://images.cidekic-dev.com');
    console.log(img);
    const image = await fetch(img);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    let fileName = img.split('/').pop();
    link.download = fileName.substr(0, fileName.lastIndexOf('.'));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className="main-docview">
        <div>
          <div className="docview-name">{originPage?.name}</div>
          <div>{originPage?.date}</div>
        </div>
        <div className="docview-download-box">
          <i className="fa fa-download"></i>
          <span onClick={(e) => downloadFile(e, originPage?.file)}> Download</span>
        </div>
      </div>
      <div className="docview">
        {/* this should be a <PreviewDocument> component or part of <ThumbnailImage> */}
        {(originPage?.type || []).includes('image') && <img src={originPage?.file} alt="file" />}

        {(originPage?.type || []).includes('PDF') && (
          <>
            <div>
              <p>
                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </p>
              <button type="button" disabled={pageNumber <= 1} onClick={previousPage} className="prev-btn1">
                Previous
              </button>
              <button type="button" disabled={pageNumber >= numPages} onClick={nextPage} className="next-btn1">
                Next
              </button>
            </div>
            <div className="align-viewDoc">
              <Document file={originPage?.file} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} width={600} />
              </Document>
            </div>
          </>
        )}
        {(originPage?.type || []).includes('Word') && (
          <>
            <div className="align-viewDoc">
              <iframe
                style={{ height: 500, width: 500 }}
                class="doc"
                src={`https://docs.google.com/gview?url=${originPage?.file}&embedded=true`}
                title="file"
              ></iframe>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentView;
