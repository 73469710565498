import React, { useState, useEffect } from 'react';
//import { Collapse } from 'antd';
import { getKitchenStations } from '../../services/station.service';
import { searchEvents } from '../../services/event.service';
import { search } from '../../services/recipe.service';
import { create, createDocLinks } from '../../services/doc.service';
import { getCheckList } from '../../services/checklist.service';
import { searchMenu } from '../../services/menu.service';
//import { KITCHENSTATIONNAME } from '../../components/common/constant.js';
import { PlusButton, UserImage } from '../../components/common/common_ui';

//const { Panel } = Collapse;

const CreateNotePopup = (props) => {
  const { closeDrawer, folder, visible, setAddedDocument, handleNewDocument } = props;
  //const [kitchenStations, setKitchenStations] = useState([]);
  // const [eventStations, setEventStations] = useState([]);
  //const [recipes, setRecipes] = useState([]);
  //const [tasks, setTasks] = useState([]);
  //const [menus, setMenus] = useState([]);
  const [selectedKitchens, setSelectedKitchens] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [createdNote, setCreateNote] = useState('');
  const [createdNoteTitle, setCreateNoteTitle] = useState('');
  const [createdComment, setCreateComment] = useState([]);
  // const [checkedKitchens, setCheckedKitchens] = useState([]);
  //const [checkedEvents, setCheckedEvents] = useState([]);
  //const [checkedMenus, setCheckedMenus] = useState([]);
  // const [checkedRecipes, setCheckedRecipes] = useState([]);
  //const [checkedTasks, setCheckedTasks] = useState([]);

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  const user_id = (JSON.parse(localStorage.getItem('operator')) || {}).id;
  const user_email = (JSON.parse(localStorage.getItem('operator')) || {}).email;
  const userImage =
    JSON.parse(localStorage.getItem('operator') || {})?.info?.userImageThumb ||
    JSON.parse(localStorage.getItem('operator') || {})?.info?.userImage;

  useEffect(() => {
    setCreateComment('');
    setCreateNote('');
    setCreateNoteTitle('');
    setSelectedKitchens([]);
    setSelectedEvents([]);
    setSelectedRecipes([]);
    setSelectedTasks([]);
    setSelectedMenus([]);
    //setCheckedKitchens([]);
    //setCheckedEvents([]);
    //setCheckedMenus([]);
    //setCheckedRecipes([]);
    //setCheckedTasks([]);
  }, [visible]);

  // const getKitchen = (checkedValues) => {
  //   console.log('checked = ', checkedValues);
  //   let names = kitchenStations.filter((e) => checkedValues.includes(e.value));
  //   let checkedKitchens = checkedValues.map((e, i) => {
  //     return {
  //       linkId: e,
  //       info: { title: names[i] },
  //       type: 'kitchen',
  //     };
  //   });
  //   setSelectedKitchens(checkedKitchens);
  //   // setCheckedKitchens(checkedValues);
  // };

  // const getEvent = (checkedValues) => {
  //   console.log('event checked = ', checkedValues);
  //   let names = eventStations.filter((e) => checkedValues.includes(e.value));
  //   let checkedEvents = checkedValues.map((e, i) => {
  //     return {
  //       linkId: e,
  //       info: { title: names[i] },
  //       type: 'events',
  //     };
  //   });
  //   setSelectedEvents(checkedEvents);
  //   //setCheckedEvents(checkedValues);
  // };

  // const getRecipes = (checkedValues) => {
  //   console.log('recipe checked = ', checkedValues);
  //   let names = recipes.filter((e) => checkedValues.includes(e.value));
  //   let checkedRecipes = checkedValues.map((e, i) => {
  //     return {
  //       linkId: e,
  //       info: { title: names[i] },
  //       type: 'recipes',
  //     };
  //   });
  //   setSelectedRecipes(checkedRecipes);
  //   //setCheckedRecipes(checkedValues);
  // };

  // const getTasks = (checkedValues) => {
  //   console.log('task checked = ', checkedValues);
  //   let names = tasks.filter((e) => checkedValues.includes(e.value));
  //   let checkedTasks = checkedValues.map((e, i) => {
  //     return {
  //       linkId: e,
  //       info: { title: names[i] },
  //       type: 'tasks',
  //     };
  //   });
  //   setSelectedTasks(checkedTasks);
  //   //setCheckedTasks(checkedValues);
  // };

  // const getMenus = (checkedValues) => {
  //   console.log('menu checked = ', checkedValues);
  //   let names = menus.filter((e) => checkedValues.includes(e.value));
  //   let checkedMenus = checkedValues.map((e, i) => {
  //     return {
  //       linkId: e,
  //       info: { title: names[i] },
  //       type: 'menus',
  //     };
  //   });
  //   setSelectedMenus(checkedMenus);
  //   // setCheckedMenus(checkedValues);
  // };

  useEffect(() => {
    console.log('FOLDERID');
    console.log(folder);
    let params = {
      restaurantId: resto.id,
      startTimestamp: '315626789',
      endTimestamp: '64060679385',
      from: 0,
      limit: 1000,
    };

    getCheckList({ restaurantId: resto.id, user_id, email: user_email }).then((res) => {
      if (res) {
        let checklists = res.checklists;
        let items = checklists.map((c) => JSON.parse(c.ChecklistItems));
        let merged = [].concat.apply([], items);
        let taskOptions = merged.map((m) => {
          return {
            label: m.title,
            value: m.id,
          };
        });
        taskOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        //setTasks(taskOptions);
      }
    });

    search({ restaurantId: resto.id, from: 0, limit: 10000 }).then((res) => {
      console.log(res);
      let recipes = res.recipes;
      let recipeOptions = recipes.map((k) => {
        return {
          label: k.name,
          value: k.id,
        };
      });
      recipeOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      //setRecipes(recipeOptions);
    });

    searchEvents(params).then((res) => {
      let events = res;
      let eventOptions = events.map((k) => {
        return {
          label: k.name,
          value: k.id,
        };
      });

      eventOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      console.log(eventOptions);
      //setEventStations(eventOptions);
    });

    searchMenu({ restaurantId: resto.id }).then((res) => {
      if (res && res.menus) {
        let menu = res.menus;
        let menuOptions = menu.map((k) => {
          return {
            label: k.name,
            value: k.id,
          };
        });

        menuOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        //setMenus(menuOptions);
      }
    });

    getKitchenStations(resto.id).then((res) => {
      let kitchens = res.kitchens;
      let kitchenOptions = kitchens?.map((k) => {
        return {
          label: k.station,
          value: k.id,
        };
      });
      if (kitchenOptions) {
        kitchenOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      }
      //setKitchenStations(kitchenOptions);
    });
  }, [folder, resto.id, user_email, user_id]);

  const createNote = () => {
    console.log(selectedEvents, selectedKitchens);
    let allSelectedLinks = [].concat(selectedEvents, selectedKitchens, selectedRecipes, selectedTasks, selectedMenus);
    const operator = JSON.parse(localStorage.getItem('operator')) || {};

    let noteInfo = {
      type: 'Note',
      comments: [{ text: createdComment, timestamp: new Date() * 1 }],
      timestamp: new Date() * 1,
      updatedDate: new Date() * 1,
      createdBy: operator.firstName + ' ' + operator.lastName,
      createdDate: new Date() * 1,
      collaborator: '',
      linked: allSelectedLinks.length,
      title: createdNoteTitle,
    };

    let params = {
      folderId: folder,
      title: createdNote,
      info: noteInfo,
    };

    //save note

    console.log(allSelectedLinks);
    console.log(params);

    create(params).then((res) => {
      let created = res;
      if (created && created.id) {
        if (handleNewDocument != undefined) {
          handleNewDocument(created);
        }
        console.log('NEW NOTE', res);
        createDocLinks(created.id, allSelectedLinks).then((res) => {
          console.log('NEW NOTE', res);
          if (setAddedDocument != undefined) {
            setAddedDocument(true);
          }
          setTimeout(() => {
            // notification.open({
            //   message: 'Note Successfully created!',
            // });
            closeDrawer(true);
          }, 2000);
        });
      }
    });
  };

  return (
    <div className="main">
      <div className="doc-note-create">
        <PlusButton alt="Create note" onClick={createNote}>
          Create Note
        </PlusButton>
      </div>
      <div>
        <div className="doc-note-title">Title of Note</div>
        <input
          type="text"
          className="ck-input-md1"
          onChange={(e) => setCreateNoteTitle(e.target.value)}
          value={createdNoteTitle}
        />
        <div className="doc-note-title">Note Body</div>
        <textarea
          type="text"
          className="doc-textarea"
          onChange={(e) => setCreateNote(e.target.value)}
          value={createdNote}
        ></textarea>
      </div>
      <br />
      {/* <div>
        <div className="doc-note-station">Linky link</div>
        <div className="doc-station-title">Select all doodads you want this note to appear in</div>
        <div className="doc-collapse-box">
          <Collapse bordered={false} defaultActiveKey={[]}>
            <Panel header={<div className="doc-note-header">{KITCHENSTATIONNAME}s</div>} key="1">
              <Row>
                <Col>
                  <Checkbox.Group options={kitchenStations} value={checkedKitchens} onChange={getKitchen} />
                </Col>
              </Row>
            </Panel>
            <Panel header={<div className="doc-note-header">Event stations</div>} key="2">
              <Row>
                <Col>
                  <Checkbox.Group options={eventStations} value={checkedEvents} onChange={getEvent} />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
        <div className="doc-collapse-box">
          <Collapse bordered={false} defaultActiveKey={[]}>
            <Panel header={<div className="doc-note-header">Menus</div>} key="1">
              <Row>
                <Col>
                  {' '}
                  <Checkbox.Group options={menus} value={checkedMenus} onChange={getMenus} />
                </Col>
              </Row>
            </Panel>
            <Panel header={<div className="doc-note-header">Recipes</div>} key="3">
              <Row>
                <Col>
                  <Checkbox.Group options={recipes} value={checkedRecipes} onChange={getRecipes} />
                </Col>
              </Row>
            </Panel>
            <Panel header={<div className="doc-note-header">Tasks</div>} key="4">
              <Row>
                <Col>
                  <Checkbox.Group options={tasks} value={checkedTasks} onChange={getTasks} />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
      </div> */}
      <div>
        <div id="ck-add-comment">
          <div className="Line"></div>
          <div className="doc-comment-box">
            <div className="doc-log-image-container">
              <div className="common-user-icon" alt="user-photo">
                <UserImage src={userImage} />
              </div>
            </div>
            <input
              type="text"
              className="ck-input-md"
              placeholder="Enter comment"
              onChange={(e) => setCreateComment(e.target.value)}
              value={createdComment}
            />
          </div>
          {/* <div className="doc-collab">
            <div className="doc-collab-text">Collaborators</div>
            <div className="flex">
              <div className="doc-log-image-container">
                <div className="common-user-icon" alt="user-photo">
                  <UserImage src={userImage} />
                </div>
              </div>
              <span>Add collaborators</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CreateNotePopup;
