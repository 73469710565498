/* eslint eqeqeq: "off" */
import React, { useState } from 'react';
import { List } from 'antd';
import { filter } from 'lodash';
import { formatGetTime } from '../../pages/Dashboard/DashboardUtils';
import EditEventDrawerV2 from '../../pages/Dashboard/components/EditEventDrawerV2';
import moment from 'moment';
import RoleAccessibleComponent from '../RoleAccess/roleaccess';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';

const MeetingAndEventDay = (props) => {
  const [showDrawer, setShowDrawer] = useState({ type: '', open: false, data: null });
  const { meetingsAndEvents, futureEvents, setHasEditedEvent } = props;
  //const [theMeetingsAndEvents, setTheMeetingsAndEvents] = useState(meetingsAndEvents)
  //const [theFutureEvents, setTheFutureEvents] = useState(futureEvents)

  //const reminders = filter(meetingsAndEvents, { type: 'reminder' });
  const meetings_events = filter(meetingsAndEvents, (i) => {
    return i.type === 'event' || i.type === 'meeting';
  });

  //console.log('Day Render');
  //console.log(theMeetingsAndEvents);
  //console.log('Future Render');
  //console.log(theFutureEvents);
  console.log('DAY IS', props.daySelected);

  // useEffect(() => {
  //   if (showDrawer.open) {
  //     document.documentElement.style.overflow = 'hidden';
  //     document.body.scroll = 'no';
  //   } else {
  //     document.documentElement.style.overflow = 'scroll';
  //     document.body.scroll = 'yes';
  //   }
  // }, [showDrawer]);

  return (
    <div id="MeetingAndEventDay" className="common-controls-container d-flex flex-row flex-wrap">
      <div className="meeting-events-panel">
        <div className="meeting-events-panel-title">
          {props.isToday ? "Today's meetings and events" : 'Meetings and events'}
        </div>
        <div className="meeting-events-panel-display">
          <List
            dataSource={meetings_events}
            className={'show-custom-empty'}
            locale={{ emptyText: <Empty image={empty} description="No events" /> }}
            renderItem={(item) => {
              const { info } = item;
              const parsedInfo = info;
              const color = (() => {
                let ev_color = null;
                if (parsedInfo) {
                  if (parsedInfo.hasOwnProperty('color')) {
                    return parsedInfo.color;
                  }
                }
                if (!ev_color) {
                  ev_color = item.type === 'event' ? '#FF59CE' : '#4CBCF8';
                }
                return ev_color;
              })();

              const ev_style = {
                marginRight: 10,
                height: 10,
                width: 10,
                backgroundColor: color,
                borderRadius: '50%',
                display: 'inline-block',
              };

              return (
                <List.Item
                  className="meeting-events-list-item"
                  onClick={() => setShowDrawer({ type: item.type, open: true, data: item })}
                >
                  <div style={{ display: 'flex' }}>
                    <div className="meet-event-title flex-grow-1">
                      <span style={ev_style}></span>
                      {item.name}
                    </div>
                    <div className="event-time-dy">
                      {formatGetTime(item.eventStart)} - {formatGetTime(item.eventEnd)}
                    </div>
                  </div>
                </List.Item>
              );
            }}
          />
        </div>
      </div>
      {/* <div className="meeting-events-panel flex-control" >
        <div className="meeting-events-panel-title">Reminders</div>
        <div className="meeting-events-panel-display">
          <List
            dataSource={reminders}
            className={'show-custom-empty'}
            locale={{ emptyText: <Empty image={empty} description='No events'/> }}
            renderItem={(item) => (
              <List.Item className="meeting-events-list-item">
                <div>
                  <span className="list-bullet-green"></span>
                  {item.name}
                </div>
              </List.Item>
            )}
          />
        </div>
      </div> */}
      <div className="meeting-events-panel">
        <div className="meeting-events-panel-title">Future meetings and events</div>
        <div className="meeting-events-panel-display">
          <List
            dataSource={futureEvents}
            className={'show-custom-empty'}
            locale={{ emptyText: <Empty image={empty} description="No events" /> }}
            renderItem={(item) => {
              const { info } = item;
              const parsedInfo = info;
              const color = (() => {
                let ev_color = null;
                if (parsedInfo) {
                  if (parsedInfo.hasOwnProperty('color')) {
                    return parsedInfo.color;
                  }
                }
                if (!ev_color) {
                  ev_color = item.type === 'event' ? '#FF59CE' : '#4CBCF8';
                }
                return ev_color;
              })();

              const ev_style = {
                marginRight: 10,
                height: 10,
                width: 10,
                backgroundColor: color,
                borderRadius: '50%',
                display: 'inline-block',
              };

              const date =
                moment().add('1', 'days').format('MMM D YYYY') == item.eventStart.format('MMM D YYYY')
                  ? // eslint-disable-next-line no-useless-concat
                    ' ' + 'tomorrow ' + item.eventStart.format('ddd MMM D')
                  : ' ' + item.eventStart.format('ddd MMM D');

              return (
                <List.Item className="meeting-events-list-item">
                  <div
                    style={{ cursor: 'pointer', display: 'flex' }}
                    onClick={() => setShowDrawer({ type: item.type, open: true, data: item })}
                  >
                    <span style={ev_style}></span>
                    <div>
                      {item.name},
                      {moment().year() == item.eventStart.year() ? date : date + ' ' + item.eventStart.format('YYYY')}
                    </div>
                  </div>
                </List.Item>
              );
            }}
          />
        </div>
      </div>
      <RoleAccessibleComponent
        permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
      >
        {showDrawer.open && (
          <EditEventDrawerV2
            showDrawer={showDrawer.open}
            type={showDrawer.type}
            data={showDrawer.data}
            setShowDrawer={setShowDrawer}
            setHasEditedEvent={setHasEditedEvent}
          />
        )}
      </RoleAccessibleComponent>
    </div>
  );
};

export default MeetingAndEventDay;
