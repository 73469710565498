import React, { useState, useEffect, useRef, useCallback } from 'react';
import './index.css';
import { Empty, Table, Switch, Tooltip, Menu, Dropdown, Drawer, Spin, Select } from 'antd';
import { Link } from 'react-router-dom';
import ReactDragListView from 'react-drag-listview';
import { cloneDeep, remove, findIndex, has, isEqual, find } from 'lodash';
import openSocket from 'socket.io-client';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';

import reorder from '../../img/reorder-icon.png';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import useWindowSize from '../../components/windowdimensions/windowSize';
import empty from '../../img/cidekic_logo_bw.svg';

import DocumentList from '../../components/DocumentList/DocumentList';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import { NewSubHeader } from '../../components/Header/Header';

import {
  PlusButton,
  CidekicButton,
  CidekicSelect,
  FolderIcon,
  AttachmentIndicator,
  ExpandCollapseIcon,
  UserImage,
  RowAssigneeEditWithSearch,
  RowTags,
  RowOkCancel,
} from '../../components/common/common_ui';

import {
  searchMenuCategories,
  createMenuCategory,
  destroyMenuCategory,
  updateMenuCategory,
} from '../../services/category.service';
import {
  searchMenu,
  create,
  updateMenu,
  destroy,
  activateChecklist,
  batchCourses,
  duplicate,
} from '../../services/menu.service';
import { all } from '../../services/operator.service';
import { batchRecipes } from '../../services/course.service';
import { searchDocLinks } from '../../services/doc.service';
import { getAllStationOperators } from '../../services/station.service';

const Menus = () => {
  // expanded rows result
  const [expandedRows, setExpandedRows] = useState([]);
  const [soloMenuResult, isSoloMenuResult] = useState(false);
  // Table data source and back-up clones
  const [dataSource, setDataSource] = useState([]);
  const [pristineData, setPristineData] = useState([]);
  // drawer visibility states and variables
  const [visible, updateVisible] = useState(false);
  const [docLinks, setDocLinks] = useState({});
  const [links, setLinks] = useState({});
  // table states and references
  const isEditingCategory = useRef({});
  const isEditingMenu = useRef({});
  const [tableOrderChanged, hasTableOrderChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  // input states and choices
  // const [inputAssignee, setInputAssignee] = useState('');
  const [currentTags, setCurrentTags] = useState([]);
  const [currentAssignee, setCurrentAssignee] = useState('');
  const [currentAssigneeImg, setCurrentAssigneeImg] = useState('');
  const [menuChoices, setMenuChoices] = useState([]);
  const [operatorChoices, setOperatorChoices] = useState([]);
  // for draggable feature of table
  const [menuToDrag, setMenuToDrag] = useState({});
  const [categoryToDrag, setCategoryToDrag] = useState(false);
  const [menuToReplace, setMenuToReplace] = useState({});
  // socket.io state variables
  const [socketData, setSocketData] = useState([]);
  const [categoryUpdate, setCategoryUpdate] = useState({});
  const [categoryAdd, setCategoryAdd] = useState({});
  const [categoryDelete, setCategoryDelete] = useState('');
  const [menuAdd, setMenuAdd] = useState({});
  const [menuUpdate, setMenuUpdate] = useState({});
  const [menuDelete, setMenuDelete] = useState('');
  const [menuUpdateCategory, setMenuUpdateCategory] = useState({});
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/menu`));

  const windowSize = useWindowSize();
  const { Option } = Select;
  // for dragging feature
  const activeCat = useRef(null);
  const isDown = useRef(false);
  // constants
  let userInfo = JSON.parse(localStorage.getItem('operator')) || {};
  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let userImage =
    JSON.parse(localStorage.getItem('operator'))?.info?.userImageThumb ||
    JSON.parse(localStorage.getItem('operator'))?.info?.userImage;
  let userName = userInfo.firstName + ' ' + userInfo.lastName;

  useEffect(() => {
    if (Object.keys(isEditingMenu.current).length > 0) {
      isEditingMenu.current.assignee = currentAssignee;
    }
  }, [currentAssignee]);

  useEffect(() => {
    if (dataSource.length > 0) {
      console.log('CURRENT DATASOURCE', dataSource);
    }
  }, [dataSource]);

  // initialization callbacks
  // menus
  const getMenus = async (cat, restoid) => {
    const allUsers = await all(0, 1000);
    let getUser = allUsers.map((r) => {
      return {
        username: r.firstName + ' ' + r.lastName,
        image: r.info ? r.info?.userImageThumb || r.info?.userImage || r.info?.userImg : '',
      };
    });

    let getCategoryIds = cat.map((c) => {
      return c.id;
    });
    console.log('CATIDS', cat, getCategoryIds);

    searchMenu({ restaurantId: restoid }).then((res) => {
      if (res && res.menus.length) {
        let allMenus = res.menus.map((m) => {
          m.menuId = m.id.toString();
          m.info = m.info !== 'null' || m.info !== null ? JSON.parse(m.info) : null;
          return m;
        });
        let filterMenus = allMenus.filter((p) => getCategoryIds.includes(p.categoryId));
        console.log('FILTER MENUS CATID', filterMenus);

        let menuIds = filterMenus.map((f) => f.id);

        console.log('MENUIDS', menuIds);

        batchCourses(menuIds).then((res) => {
          if (res) {
            console.log('BATCHCOURSES', res);
            let allCourses = res.map((c) => {
              return c.MenuCourses;
            });
            console.log('ALL COURSES', allCourses);

            let merged = [].concat.apply([], allCourses);

            console.log(merged);

            let getCourseIds = merged.map((c) => {
              return c.courseId;
            });
            console.log('COURSEIDS++', getCourseIds);

            batchRecipes(getCourseIds).then((res) => {
              console.log(res);
              let mapRecs = res?.map((f) => {
                return f?.CourseRecipes?.map((c, i) => ({
                  menuId: res.menuId ? res.menuId : 0,
                  recipes: [{ active: c.active, id: c.recipeId, name: c?.Recipe?.name }],
                  courseId: f.id,
                }));
              });

              let newRecs = [].concat.apply([], mapRecs);

              console.log('NEWRECCSSS!!!', newRecs);

              let combineCourses = merged.concat(newRecs);
              console.log('COMBO MENU-COURSE-RECIPE++++', combineCourses);

              const combResult = Object.values(
                combineCourses?.reduce((r, o) => {
                  const key = `${o?.courseId}`;

                  if (!r[key]) r[key] = { ...o, recipes: [] };

                  r[key].recipes.push(o.recipes);

                  r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined && u !== null);

                  return r;
                }, {})
              );

              console.log('ADDED MENUIDS', combResult);

              const comboResult2 = Object.values(
                combResult?.reduce((r, o) => {
                  const key = `${o.menuId}`;

                  if (!r[key]) r[key] = { ...o, recipes: [] };

                  r[key].recipes.push(o.recipes);

                  r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined && u !== null);

                  return r;
                }, {})
              );

              console.log('FINAL COMBO MENU_REC', comboResult2);

              let combined = filterMenus.concat(comboResult2);
              console.log('COMBO', combined);

              //remove event menus from other categories
              let combined2 = combined.filter((e) => !e.info?.originalMenuId);

              const result = Object.values(
                combined2.reduce((r, o) => {
                  const key = `${o.menuId}`;

                  if (!r[key]) r[key] = { ...o, recipes: [] };

                  r[key].recipes.push(o.recipes);

                  r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined);

                  return r;
                }, {})
              );

              const finalMenus = result.filter((m) => m.id);

              console.log('menuList2', result);
              console.log('MENUS WITH RECIPES+++', finalMenus);

              setMenuChoices(finalMenus);

              let categoryMenus = Object.values(
                finalMenus.reduce((result, { categoryId, id, type, recipes, name, info }) => {
                  if (!result[categoryId]) {
                    result[categoryId] = {
                      categoryId,
                      menus: [],
                    };
                  }
                  result[categoryId].menus.push({
                    categoryId,
                    id,
                    key: `${categoryId}-${id}`,
                    name,
                    type: 'child',
                    prepped: info,
                    activate: info?.active,
                    recipes: recipes ? recipes : [],
                    activerecipes: recipes ? recipes.filter((r) => r.active == true) : [],
                    assignee: info?.createdBy ? info?.createdBy : info?.assignee ? info?.assignee : '',
                    assigneeImg: info?.createdBy ? getUser?.find((user) => user.username == info?.createdBy).image : '',
                    tags: info?.tags ? info?.tags : [],
                    texts: 15,
                    attachments: 5,
                    categorized: 'yes',
                    // order: ''
                  });
                  return result;
                }, {})
              );

              console.log(categoryMenus);

              let combinedMenus = cat.concat(categoryMenus);
              console.log('COMBOMENUS', combinedMenus);

              const resultMenus = Object.values(
                combinedMenus.reduce((r, o) => {
                  const key = `${o.categoryId}`;

                  if (!r[key]) r[key] = { ...o, menus: [] };

                  r[key].menus.push(o.menus);

                  r[key].menus = [].concat.apply([], r[key].menus).filter((u) => u !== undefined);

                  return r;
                }, {})
              );

              let tableData = resultMenus.map((m) => {
                m.menuCount = m?.menus ? m.menus.length : 0;
                m.children = m?.menus
                  ? m.menus.concat([{ type: 'add-button', categoryId: m.id, key: `${m.id}-9999999` }])
                  : [{ type: 'add-button', categoryId: m.id, key: `${m.id}-9999999` }];
                m.key = `${m.id}`;
                delete m.menus;
                return m;
              });

              //move event menus to their own category

              const eventMenus = combined.filter((e) => (e.eventName && e.info?.originalMenuId) || e.recipes);

              console.log('EVENTMENUS', eventMenus);

              let newObj = {};

              if (eventMenus && eventMenus.length > 0) {
                //find if category exists
                const uncategorized = cat.find((e) => e.name == 'UNCATEGORIZED');
                console.log('EVENTEXISTS', uncategorized);
                if (!uncategorized) {
                  const eventresult = Object.values(
                    eventMenus.reduce((r, o) => {
                      const key = `${o.menuId}`;

                      if (!r[key]) r[key] = { ...o, recipes: [] };

                      r[key].recipes.push(o.recipes);

                      r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined);

                      return r;
                    }, {})
                  );

                  const finalEventMenus = eventresult.filter((m) => m.id);

                  console.log('menuList', eventresult);
                  console.log('MENUS WITH RECIPES+++', finalEventMenus);

                  let childMenus = finalEventMenus.map((c) => {
                    c.key = `9999999999-${c.id}`;
                    c.type = 'child';
                    c.prepped = c.info;
                    c.activate = c.info?.active;
                    c.recipes = c.recipes ? c.recipes : [];
                    c.activerecipes = c.recipes ? c.recipes.filter((r) => r.active == true) : [];
                    c.assignee = c.info?.createdBy ? c.info?.createdBy : c.info.assignee ? c.info.assignee : '';
                    c.assigneeImg = c.info?.createdBy
                      ? c.info?.createdBy == userName
                        ? userImage
                        : ''
                      : c.info?.assigneeImg
                      ? c.info.assigneeImg
                      : '';
                    c.tags = c.info?.tags ? c.info.tags : [];
                    c.texts = 15;
                    c.attachments = 5;
                    c.categorized = 'no';
                    return c;
                  });

                  console.log('EVENTCHILDMENUS', childMenus);

                  newObj.key = '9999999999'; // to make sure UNCATEGORIZED CATEGORY is always last
                  newObj.categoryId = 'UNCAT1';
                  newObj.id = 'UNCAT1';
                  newObj.children = childMenus.sort((a, b) => (a.name > b.name ? 1 : -1));
                  newObj.menuCount = childMenus.filter((d) => d.name !== '').length;
                  newObj.type = 'parent';
                  newObj.name = 'UNCATEGORIZED';
                }
              }

              console.log('NEWOBJ1', newObj);

              console.log('MENUS WITH CATEGORIES', resultMenus);

              if (Object.keys(newObj).length !== 0) {
                tableData.push(newObj);
              }

              let getLinks = tableData
                .filter((e) => e.children || [])
                .map((e) => (e.children || []).map((link) => link.id))
                .reduce((a, b) => a.concat(b), [])
                .filter((l) => l !== undefined);

              console.log(getLinks);

              let stringList = getLinks.length > 0 ? getLinks.join(', ') : 0;

              let params = {
                type: 'menus',
                linkId: stringList,
              };
              searchDocLinks(params).then((res) => {
                console.log(res);
                // if docLinks are found, attach to categorized menus then sort,
                // else straight to sorting
                if (res && res.length > 0) {
                  let links = res;
                  let removeDeleted = links.filter((l) => l.Document.folderId !== null);
                  const list = Array.from(
                    removeDeleted.reduce(
                      (m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]),
                      new Map()
                    ),
                    ([linkId, documentId]) => ({ linkId, documentId })
                  );

                  console.log(list);

                  let addSelectedLinks = tableData.map((o) => {
                    (o.children || []).map((c) => {
                      list.map((d) => {
                        if (d.linkId == c.id) {
                          c.document = d.documentId.length;
                        }
                        return d;
                      });
                      return c;
                    });
                    return o;
                  });
                  // if custom menu order exists, apply
                  // else if ANTD order is cached, apply
                  // else no sort to apply
                  if (
                    localStorage.getItem('menuColKeyOrder') != undefined
                  ) {
                    const { order, columnKey } = JSON.parse(localStorage.getItem('menuColKeyOrder'));
                    const sorted = applyColumnOrder(columnKey, order, addSelectedLinks);
                    setDataSource(sorted);
                  } else if (
                    localStorage.getItem('menuOrder') != undefined &&
                    JSON.parse(localStorage.getItem('menuOrder')).length === addSelectedLinks.length
                  ) {
                    const sortedTable = annotateTableOrder(
                      addSelectedLinks, 
                      JSON.parse(localStorage.getItem('menuOrder'))
                    );
                    setDataSource(sortedTable);
                  } else {
                    setDataSource(addSelectedLinks);
                  }
                  setSocketData(addSelectedLinks);
                  setPristineData(addSelectedLinks);
                  hasTableOrderChanged(false);
                  setIsLoading(false);
                  localStorage.setItem('originalMenus', JSON.stringify(addSelectedLinks));
                  localStorage.setItem('menuOriginalData', addSelectedLinks.length);
                } else {
                  // if custom menu order exists, apply
                  // else if ANTD order is cached, apply
                  // else no sort to apply
                  if (
                    localStorage.getItem('menuColKeyOrder') != undefined
                  ) {
                    const { order, columnKey } = JSON.parse(localStorage.getItem('menuColKeyOrder'));
                    const sorted = applyColumnOrder(columnKey, order, tableData);
                    setDataSource(sorted);
                  } else if (
                    localStorage.getItem('menuOrder') != undefined &&
                    JSON.parse(localStorage.getItem('menuOrder')).length === tableData.length
                  ) {
                    const sortedTable = annotateTableOrder(
                      tableData, 
                      JSON.parse(localStorage.getItem('menuOrder'))
                    );
                    setDataSource(sortedTable);
                  } else {
                    setDataSource(tableData);
                  }
                  setSocketData(tableData);
                  setPristineData(tableData);
                  hasTableOrderChanged(false);
                  setIsLoading(false);
                  localStorage.setItem('originalMenus', JSON.stringify(tableData));
                  localStorage.setItem('menuOriginalData', tableData.length);
                }
              }).catch(() => {
                // if custom menu order exists, apply
                // else if ANTD order is cached, apply
                // else no sort to apply
                if (
                  localStorage.getItem('menuColKeyOrder') != undefined
                ) {
                  const { order, columnKey } = JSON.parse(localStorage.getItem('menuColKeyOrder'));
                  const sorted = applyColumnOrder(columnKey, order, tableData);
                  setDataSource(sorted);
                } else if (
                  localStorage.getItem('menuOrder') != undefined &&
                  JSON.parse(localStorage.getItem('menuOrder')).length === tableData.length
                ) {
                  const sortedTable = annotateTableOrder(
                    tableData, 
                    JSON.parse(localStorage.getItem('menuOrder'))
                  );
                  setDataSource(sortedTable);
                } else {
                  setDataSource(tableData);
                }
                setSocketData(tableData);
                setPristineData(tableData);
                hasTableOrderChanged(false);
                setIsLoading(false);
                localStorage.setItem('originalMenus', JSON.stringify(tableData));
                localStorage.setItem('menuOriginalData', tableData.length);
              });
            });
          } else {
            setIsLoading(false);
          }
        });
      } else {
        let noMenus = cat.map((c, i) => {
          c.children = [
            { categoryId: c.categoryId, key: `${c.categoryId}-9999999`, type: 'add-button' },
          ];
          c.menuCount = 0;
          c.key = `${c.categoryId}`;
          return c;
        });
        setDataSource(noMenus);
        setSocketData(noMenus);
        setPristineData(noMenus);
        hasTableOrderChanged(false);
        setIsLoading(false);
      }
    });
  };
  // categories
  const getCategory = () => {
    let params = {
      restaurantId: resto.id,
    };
    searchMenuCategories(params).then((res) => {
      if (res && res.menucategories) {
        console.log('MENU CATS', res.menucategories);
        let classifyCtg = res.menucategories.map((c) => {
          c.type = 'parent';
          c.categoryId = c.id.toString();
          c.id = c.id.toString();
          c.restaurantId = c.restaurantId.toString();
          return c;
        });
        getMenus(classifyCtg, resto.id);
      } else {
        setIsLoading(false);
      }
    });
  };

  // resets Menu item record without refreshing table
  const refreshMenuItem = (menu, key, value) => {
    // update the displayed table datasource
    let clonedMenuList = [];
    if (isFiltering || isSearching) {
      clonedMenuList = cloneDeep(socketData);
    } else {
      clonedMenuList = cloneDeep(dataSource);
    }
    
    const findCategoryIndex = findIndex(clonedMenuList, { categoryId: menu.categoryId });
    let menuItems = clonedMenuList[findCategoryIndex].children;
    const findMenuIndex = findIndex(clonedMenuList[findCategoryIndex].children, { key: menu.key });
    let menuItem = cloneDeep(menuItems[findMenuIndex]);

    menuItem[key] = value;
    clonedMenuList[findCategoryIndex].children[findMenuIndex] = { ...menuItem };
    setDataSource(clonedMenuList);

    // update the originalData
    const origMenuList = deannotateTableOrder(clonedMenuList);
    localStorage.setItem('originalMenus', JSON.stringify(origMenuList));
    setPristineData(origMenuList);
  };

  // reset menu categories and items being edited
  const resetMenuWatchers = (refreshTable) => {
    if (refreshTable) {
      let clonedMenuList = cloneDeep(dataSource);
      if (Object.keys(isEditingMenu.current).length) {
        const findCategoryIndex = findIndex(clonedMenuList, { categoryId: isEditingMenu.current.categoryId });
        const findMenuIndex = findIndex(clonedMenuList[findCategoryIndex].children, { key: isEditingMenu.current.key });

        if (has(isEditingMenu.current, 'tags')) {
          clonedMenuList[findCategoryIndex].children[findMenuIndex].tags =
            isEditingMenu.current.tags.length > 0 ? [...isEditingMenu.current.tags] : [];
        }

        if (has(isEditingMenu.current, 'assignee')) {
          clonedMenuList[findCategoryIndex].children[findMenuIndex].assignee = isEditingMenu.current.assignee;
        }

        if (has(isEditingMenu.current, 'name')) {
          clonedMenuList[findCategoryIndex].children[findMenuIndex].name = isEditingMenu.current.name;
        }

        isEditingMenu.current = {};
      }
      if (Object.keys(isEditingCategory.current).length) {
        const findCategoryIndex = findIndex(clonedMenuList, { categoryId: isEditingCategory.current.categoryId });
        if (has(isEditingCategory.current, 'name')) {
          clonedMenuList[findCategoryIndex].name = isEditingCategory.current.name;
        }
        isEditingCategory.current = {};
      }
      setDataSource(clonedMenuList);
    } else {
      if (Object.keys(isEditingMenu.current).length) {
        let cloneOriginal = cloneDeep(dataSource);

        const findCategoryIndex = findIndex(cloneOriginal, { categoryId: isEditingMenu.current.categoryId });

        const findMenuIndex = findIndex(cloneOriginal[findCategoryIndex].children, { key: isEditingMenu.current.key });
        cloneOriginal[findCategoryIndex].children[findMenuIndex].tags = [...currentTags];
        cloneOriginal[findCategoryIndex].children[findMenuIndex].assignee = currentAssignee;
        cloneOriginal[findCategoryIndex].children[findMenuIndex].assigneeImg = currentAssigneeImg;
        isEditingMenu.current = {};
        setDataSource(cloneOriginal);
        setCurrentTags([]);
        setCurrentAssignee('');
        setCurrentAssigneeImg('');
      }
      if (Object.keys(isEditingCategory.current).length) {
        isEditingCategory.current = {};
      }
    }
  };

  // effect after table is changed
  useEffect(() => {
    if (tableOrderChanged) {
      let menuOrder = 
        localStorage.getItem('menuOrder') != undefined ? 
          JSON.parse(localStorage.getItem('menuOrder')) : 
          null;
      if (menuOrder && menuOrder?.length > 0) {
        const data = cloneDeep(dataSource);
        const orderedDataSource = annotateTableOrder(data, menuOrder);
        setDataSource(orderedDataSource);
        setIsLoading(false);
        hasTableOrderChanged(false);
      } else {
        let originalData = 
          localStorage.getItem('originalMenus') != undefined ? 
            JSON.parse(localStorage.getItem('originalMenus')) : 
            null;
        if (originalData && originalData?.length > 0) {
          setDataSource(originalData);
          setIsLoading(false);
          hasTableOrderChanged(false);
        } else {
          getCategory();
        } 
      }
    }
  }, [tableOrderChanged]);

  // to populate operator choices based on input
  // DISABLED FOR CHANGE
  // const searchOperators = (value, obj) => {
  //   if (value !== '') {
  //     const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  //     getAllStationOperators({ id: resto.id }).then((res) => {
  //       console.log('OPERATORS', res);
  //       setOperatorChoices(res);
  //     });
  //   } else {
  //     setOperatorChoices([]);
  //   }
  // };

  // useEffect(() => {
  //   if (inputAssignee !== '') {
  //     const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  //     getAllStationOperators({ id: resto.id }).then((res) => {
  //       console.log('OPERATORS', res);
  //       setOperatorChoices(res);
  //     });
  //   } else {
  //     setOperatorChoices([]);
  //   }
  // }, [inputAssignee]);

  // Drawer Callbacks
  const closeDrawer = useCallback((data) => {
    updateVisible(false);
  }, []);
  const onClose = () => {
    updateVisible(false);
  };
  const showDrawer = (e, obj) => {
    updateVisible(true);
    setDocLinks({
      type: 'menus',
      linkId: obj.id,
    });
    setLinks({
      type: 'menus',
      linkId: obj.id,
      info: { title: obj.name },
    });
  };

  // ANTD table row callbacks
  const hoverBeforeDrag = (e, obj) => {
    if (obj.type == 'parent') {
      setCategoryToDrag(obj);
      setMenuToDrag({});
      activeCat.current = obj.id;
      isDown.current = true;
    } else {
      setMenuToDrag(obj);
      setCategoryToDrag({});
      activeCat.current = obj.id;
      isDown.current = true;
    }
  };
  const cancelHover = (e, obj) => {
    if (obj.type == 'child') {
      setMenuToDrag({});
      activeCat.current = null;
      isDown.current = false;
    } else {
      setCategoryToDrag({});
      activeCat.current = null;
      isDown.current = false;
    }
  };
  const setDestination = (e, obj) => {
    e.preventDefault();
    setMenuToReplace(obj);
  };

  // filtering table callback
  const filteringMenus = (val) => {
    setIsSearching(false);
    const menu = JSON.parse(val);
    let menuName = menu.name === 'All Menus' ? '' : menu.name;
    let filtered = cloneDeep(pristineData).filter((obj) => obj.children.some((menu) => menu.name === menuName));
    let viewOnly = menuName !== '' ? true : false; // when filtered, sorted display filters out open add rows and button rows
    let toShow = [];

    if (viewOnly) {
      toShow = filtered.filter((pr) => pr.categoryId !== 'UNCAT1');
      setIsFiltering(true);
    } else {
      toShow = cloneDeep(pristineData);
      setIsFiltering(false);
    }

    if (
      localStorage.getItem('menuColKeyOrder') != undefined
    ) {
      const { order, columnKey } = JSON.parse(localStorage.getItem('menuColKeyOrder'));
      const sorted = applyColumnOrder(columnKey, order, toShow, viewOnly);
      setDataSource(sorted);
    } else if (
      localStorage.getItem('menuOrder') != undefined &&
      JSON.parse(localStorage.getItem('menuOrder')).length > 0
    ) {
      const menuOrder = JSON.parse(localStorage.getItem('menuOrder'));
      const sorted = annotateTableOrder(toShow, menuOrder, viewOnly);
      setDataSource(sorted);
    } else {
      setDataSource(toShow);
    }
  };

  // searching table callbacks
  const filterMenus = (e) => {
    setIsFiltering(false);
    let inputValue = e.target.value;

    if (inputValue.length) {
      setIsSearching(true);
      const clonedData = cloneDeep(pristineData).filter((pr) => pr.categoryId !== 'UNCAT1');

      let searchString = inputValue.toLowerCase();

      let results = searchAllArr(clonedData, inputValue);

      let searchTags = results.map((item) => ({
        ...item,
        children: item.children?.filter(
          (child) =>
            child.tags?.find((a) => a.includes(searchString)) ||
            child.name?.toLowerCase().indexOf(searchString) !== -1 ||
            child.assignee?.toLowerCase().indexOf(searchString) !== -1
        ),
      }));

      if (searchTags.length === 1) {
        if (searchTags[0].children.length === 1) {
          isSoloMenuResult(true);
          setDataSource([searchTags[0].children[0]]);
        } else {
          if (localStorage.getItem('menuColKeyOrder') != undefined) {
            setExpandedRows([searchTags[0].key]);
            localStorage.setItem('menuOpenRows', JSON.stringify([searchTags[0].key]));

            const { order, columnKey } = JSON.parse(localStorage.getItem('menuColKeyOrder'));
            const toDisplay = applyColumnOrder(columnKey, order, searchTags, true);
            setDataSource(toDisplay);
          } else if (
            localStorage.getItem('menuOrder') != undefined && 
            JSON.parse(localStorage.getItem('menuOrder')).length
          ) {
            setExpandedRows([searchTags[0].key]);
            localStorage.setItem('menuOpenRows', JSON.stringify([searchTags[0].key]));

            const menuOrder = JSON.parse(localStorage.getItem('menuOrder'));
            const toDisplay = annotateTableOrder(searchTags, menuOrder, true);
            setDataSource(toDisplay);
          } else {
            setExpandedRows([searchTags[0].key]);
            localStorage.setItem('menuOpenRows', JSON.stringify([searchTags[0].key]));
            setDataSource(searchTags);
          }
        }
      } else {
        console.log(searchTags);
        if (localStorage.getItem('menuColKeyOrder') != undefined) {
          const { order, columnKey } = JSON.parse(localStorage.getItem('menuColKeyOrder'));
          const toDisplay = applyColumnOrder(columnKey, order, searchTags, false);
          setDataSource(toDisplay);
          setExpandedRows([]);
          isSoloMenuResult(false);
        } else if (
          localStorage.getItem('menuOrder') != undefined && 
          JSON.parse(localStorage.getItem('menuOrder')).length
        ) {
          const menuOrder = JSON.parse(localStorage.getItem('menuOrder'));
          const toDisplay = annotateTableOrder(searchTags, menuOrder, false);
          setDataSource(toDisplay);
          setExpandedRows([]);
          isSoloMenuResult(false);
        } else {
          setDataSource(searchTags);
          setExpandedRows([]);
          isSoloMenuResult(false);
        }
      }
    } else {
      setIsSearching(false);
      const cloned = cloneDeep(pristineData);
      if (localStorage.getItem('menuColKeyOrder') != undefined) {
        const { order, columnKey } = JSON.parse(localStorage.getItem('menuColKeyOrder'));
        const toDisplay = applyColumnOrder(columnKey, order, cloned, true);
        setDataSource(toDisplay);
      } else if (
        localStorage.getItem('menuOrder') != undefined && 
        JSON.parse(localStorage.getItem('menuOrder')).length
      ) {
        const menuOrder = JSON.parse(localStorage.getItem('menuOrder'));
        const toDisplay = annotateTableOrder(cloned, menuOrder, true);
        setDataSource(toDisplay);
      } else {
        setDataSource(cloned);
      }
    }
  };

  const searchAllArr = (dataArray, searchTerm) => {
    return dataArray.flatMap((obj) => {
      const objHasSearchTerm = Object.entries(obj).some(
        ([key, value]) => key !== 'children' && String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (objHasSearchTerm && !obj.children) return [obj];

      const matchedChildren = searchAllArr(obj.children ?? [], searchTerm);
      return objHasSearchTerm || matchedChildren.length > 0
        ? [
            {
              ...obj,
              children: matchedChildren,
            },
          ]
        : [];
    });
  };

  // table row expansion callbacks
  const handleRowExpand = (record) => {
    if (expandedRows.includes(record.key)) {
      let filterRecord = expandedRows.filter((key) => key !== record.key);
      setExpandedRows(filterRecord);
      localStorage.setItem('menuOpenRows', JSON.stringify(filterRecord));
    } else {
      setExpandedRows([...expandedRows, record.key]);
      localStorage.setItem('menuOpenRows', JSON.stringify([...expandedRows, record.key]));
    }
  };
  const collapseAll = () => {
    setExpandedRows([]);
    localStorage.setItem('menuOpenRows', JSON.stringify([]));
  };

  // cacheTableOrder - caches and annotates position of each parent and child row of current table order (index-based position)
  const cacheTableOrder = (data) => {
    let orderCached = [];
    let tableOrdered = [];
    if (data.length > 0) {
      tableOrdered = data.map((d, i) => { // for menu categories
        const parentRow = {};
        parentRow.key = d.key;
        if (d?.order == undefined || d.order !== i) {
          parentRow.order = i; // caches order
          d.order = i; // annotates order
        } else {
          parentRow.order = d.order;
        }
        parentRow.children = [];
        if (d?.children != undefined && d.children.length) {
          d.children = d.children.map((c, j) => { // for menus
            const childRow = {};
            childRow.key = c.key;
            if (c?.order == undefined || c.order !== j) {
              childRow.order = j; // caches order
              c.order = j; // annotates order
            } else {
              childRow.order = c.order;
            }
            parentRow.children.push(childRow);
            return c;
          });
          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        orderCached.push(parentRow);
        return d;
      });
      if (data.length > 1) {
        tableOrdered = tableOrdered.sort((a, b) => a.order - b.order);
      }
    }
    return {
      menuOrder: orderCached, // cached table order
      orderedMenu: tableOrdered // annotated table rows
    };
  }

  // annotateTableOrder - annotates new/current/updated order for each parent and child record of table (index-based position)
  // callback when localStorage variable 'menuOrder' exists, then applies to fetched data
  const annotateTableOrder = (data, menuOrder, viewOnly = false) => {
    let orderedTable = [];
    if (data.length > 0) {
      const tableAnnotated = data.map((d) => {
        // annotate menu category cached order
        const folderIndex = findIndex(menuOrder, (r) => r.key === d.key);
        d.order = menuOrder[folderIndex].order;
        if (d.children && d.children.length) {
          d.children = d.children.map((c, i) => {
            // annotate menu cached order
            const menuIndex = findIndex(menuOrder[folderIndex].children, (h) => h.key === c.key);
            if (menuOrder[folderIndex].children[menuIndex] != undefined) {
              const cachedOrder = menuOrder[folderIndex]?.children[menuIndex].order;
              c.order = (
                cachedOrder != undefined &&
                (
                  !viewOnly && cachedOrder !== i
                )
              ) ? cachedOrder : i;
            } else {
              c.order = i;
            }
            return c;
          });

          if (viewOnly) { // removes open add menus
            d.children = d.children.filter((a) => a.type !== 'add-button');
          }
          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        return d;
      });

      orderedTable = tableAnnotated;

      if (viewOnly) { // removes open add categories
        orderedTable = tableAnnotated.filter((a) => a.key.indexOf('0.') === -1);
      }

      if (data.length > 1) {
        orderedTable = tableAnnotated.sort((a, b) => a.order - b.order);
      }
    }
    return orderedTable;
  }

  // deannotateTableOrder - removes index order for each parent and child record
  const deannotateTableOrder = (data, filterOpenAdds = true) => {
    if (data.length > 0) {
      // filters out open add menu category rows
      let orderRemoved = data
      if (filterOpenAdds) {
        orderRemoved = orderRemoved.filter((k) => !k.tempId || k.tempId == undefined)
          .map((p) => {
            if (p.categoryId !== 'UNCAT1' && p.children && p.children.length) {
              const findOpenAddMenuIndex = findIndex(p.children, (c) => c.type === 'add-button');
              if (findOpenAddMenuIndex) { // replace open add menu items with the category button row
                p.children[findOpenAddMenuIndex] = {
                  type: 'add-button', 
                  categoryId: p.categoryId, 
                  key: `${p.categoryId}-9999999` 
                }
              }
            }
            return p;
          });
      }
      
      if (orderRemoved.length) {
        orderRemoved = orderRemoved.map((d) => {
          if (d?.order != undefined) {
            delete d['order'];
          }
          if (d.children && d.children.length) {
            d.children = d.children.map((e) => { 
              if (e?.order != undefined) {
                delete e['order'];
              }
              return e;
            });
            if (d.children.length > 1) {
              d.children = d.children.sort((a, b) => a.key.localeCompare(b.key));
            }
          }
          return d;
        });
        if (orderRemoved.length > 1) {
          orderRemoved = orderRemoved.sort((a, b) => a.key.localeCompare(b.key));
        }
        return orderRemoved;
      }
    }
    return data;
  }

  // removeOpenAdds - remove open add rows in menuRows
  const removeOpenAdds = (menuRows) => {
    let removedOpenAdds = menuRows.filter((k) => k.key.toString().indexOf('0.') === -1); // removes open add menu category rows
    if (removedOpenAdds.length > 1) {
      // table rows with removed open add rows might have index-based order values
      // making sorting formula inaccurate with simple subtraction
      // used boolean comparison instead on ternary
      // then sorted with new assigned index as order values for each
      return removedOpenAdds.sort((a, b) => a.order > b.order ? 1 : -1)
        .map((l, i) => {
          if (l.order * 1 !== i) {
            l.order = i;
          }
          return l;
        }).sort((a, b) => a.order - b.order);
    } else {
      return removedOpenAdds;
    }
  }

  // dragging rows and columns across table
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      console.log('DRAGGING STARTS');
      if (Object.keys(categoryToDrag).length > 0) {
        const data = [...dataSource];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        localStorage.setItem('menuOrder', JSON.stringify(cacheTableOrder(data).menuOrder));
        localStorage.removeItem('menuColKeyOrder');
        hasTableOrderChanged(true);
      } else {
        console.log('DRAGGING MENU');
        if (menuToDrag.categoryId == menuToReplace.categoryId) {
          console.log('WITHIN THE SAME CATEGORY', pristineData);
          let originalData = [...dataSource];
          const getObj = originalData.find((p) => p.categoryId == menuToDrag.categoryId);
          const data = getObj.children;
          const findIndx = data.findIndex((x) => x.id === menuToDrag.id);
          let firstIndex = Math.floor(fromIndex - findIndx);
          let destinationIndex = Math.floor(toIndex - firstIndex);

          const item = data.splice(findIndx, 1)[0];
          if (destinationIndex !== data.length - 1) {
            data.splice(destinationIndex, 0, item);
          } else {
            destinationIndex -= 1;
            data.splice(destinationIndex, 0, item);
          }
          
          originalData.find((v) => v.id === menuToDrag.categoryId).children = data;
          console.log(originalData);
          const { menuOrder } = cacheTableOrder(originalData);
          localStorage.setItem('menuOrder', JSON.stringify(menuOrder));
          localStorage.removeItem('menuColKeyOrder');
          hasTableOrderChanged(true);
        } else {
          console.log('IN DIFFERENT CATEGORY');
          let originalData1 = cloneDeep(dataSource);
          const getObj1 = originalData1.find((p) => p.categoryId == menuToReplace.categoryId);
          const data1 = cloneDeep(getObj1.children);
          const findIndx1 = data1.findIndex((x) => x.id === menuToReplace.id);
          let firstIndex1 = Math.floor(fromIndex - findIndx1);
          let destinationIndex1 = Math.floor(toIndex - firstIndex1);

          const getObj = originalData1.find((p) => p.categoryId == menuToDrag.categoryId);
          let data = cloneDeep(getObj.children);
          const findIndx = data.findIndex((x) => x.id === menuToDrag.id);
          let item = data[findIndx];
          // data = data.filter((d) => d.key !== item.key);
          item.categoryId = menuToReplace.categoryId;
          item.key = `${menuToReplace.categoryId}-${item.id}`;

          eventSocket.emit(
            'updatesMenuItem',
            JSON.stringify({
              activate: item.activate,
              activerecipes: item.activerecipes,
              assignee: item.assignee,
              assigneeImg: item.assigneeImg,
              attachments: item.attachments,
              categorized: item.categorized,
              categoryId: menuToReplace.categoryId,
              destinationIndex: destinationIndex1, // to be deleted in next steps
              id: item.id,
              key: item.key,
              name: item.name,
              oldCategoryId: menuToDrag.categoryId, // to be deleted in next steps
              prepped: item.prepped,
              recipes: item.recipes,
              tags: item.tags,
              texts: item.texts,
              type: 'child',
            })
          );

          updateMenu(item.id, { categoryId: menuToReplace.categoryId }).then(() => {});
        }
      }
    },
    handleSelector: 'a',
    nodeSelector:
      Object.keys(categoryToDrag).length > 0
        ? 'tr.ant-table-row.parent.ant-table-row-level-0'
        : 'tr.ant-table-row.child.ant-table-row-level-1',
    ignoreSelector:
      Object.keys(categoryToDrag).length > 0
        ? 'tr.ant-table-row.child.ant-table-row-level-1'
        : 'tr.ant-table-row.parent.ant-table-row-level-0',
  };
  // const dragColumns = {
  //   onDragEnd(fromIndex, toIndex) {
  //     const columns1 = [...columns];
  //     const item = columns1.splice(fromIndex, 1)[0];
  //     columns1.splice(toIndex, 0, item);
  //     console.log(item, toIndex);
  //     console.log(columns1);
  //     setColumns(columns1);
  //     localStorage.setItem('menuMoveColumns', JSON.stringify(columns1));
  //   },
  //   nodeSelector: 'th',
  // };

  // after clicking "Add new category", creates new category folder
  const createNewCategory = () => {
    const tempId = Math.random().toString();
    const newCategory = {
      tempId: tempId,
      key: `${tempId}`,
      name: '',
      type: 'parent',
    };

    let cloneOriginal = cloneDeep(dataSource);

    // If a menu item is currently being edited
    if (Object.keys(isEditingMenu.current).length) {
      const findCategoryIndex = findIndex(cloneOriginal, { categoryId: isEditingMenu.current.categoryId });

      const findMenuIndex = findIndex(cloneOriginal[findCategoryIndex].children, { key: isEditingMenu.current.key });
      cloneOriginal[findCategoryIndex].children[findMenuIndex].tags = [...currentTags];
      cloneOriginal[findCategoryIndex].children[findMenuIndex].assignee = currentAssignee;
      cloneOriginal[findCategoryIndex].children[findMenuIndex].assigneeImg = currentAssigneeImg;
      isEditingMenu.current = {};
      setCurrentTags([]);
      setCurrentAssignee('');
      setCurrentAssigneeImg('');
    }

    cloneOriginal.unshift({ ...newCategory });
    let updatedOrder = cacheTableOrder(cloneOriginal);
    localStorage.setItem('menuOrder', JSON.stringify(updatedOrder.menuOrder))
    setDataSource(updatedOrder.orderedMenu);
  };
  // after clicking row "Cancel" CTA
  const cancelNewCategory = (category) => {
    let clonedMenuList = cloneDeep(dataSource);
    remove(clonedMenuList, (m) => m.tempId === category.tempId);
    let updatedOrder = cacheTableOrder(clonedMenuList);
    localStorage.setItem('menuOrder', JSON.stringify(updatedOrder.menuOrder));
    setDataSource(updatedOrder.orderedMenu);
  };
  // callbacks for adding/editing category row
  // adds category to API
  const addCategory = (category) => {
    // notification.open({
    //   message: 'Please provide a category name',
    // });
    // return;

    const newCategoryName = category.name && category.name !== '' ? category.name.trim() : 'Untitled';
    createMenuCategory({
      restaurantId: resto.id,
      name: newCategoryName,
    }).then((res) => {
      // setTimeout(() => {
      // notification.open({
      //   message: 'Successfully created category! Fetching all categories...',
      // });

      //
      const newCategory = {
        categoryId: res.id.toString(),
        children: [
          { categoryId: res.id.toString(), key: `${res.id}-9999999`, type: 'add-button' },
        ],
        id: res.id.toString(),
        key: res.id.toString(),
        menuCount: 0,
        name: newCategoryName,
        restaurantId: resto.id.toString(),
        type: 'parent',
        tempId: category.tempId,
      };

      eventSocket.emit('addsMenu', JSON.stringify(newCategory));
      // }, 3000);
    });
  };
  // edit category to API
  const editCategory = (obj) => {
    let params = {
      restaurantId: resto.id,
      name:
        has(isEditingCategory.current, 'name') && isEditingCategory.current.name !== ''
          ? isEditingCategory.current.name.trim()
          : obj.name,
    };
    eventSocket.emit(
      'updatesMenu',
      JSON.stringify({
        categoryId: obj.id,
        children: [...obj.children],
        id: obj.id,
        key: obj.key,
        menuCount: 0,
        name: params.name,
        restaurantId: obj.restaurantId,
        type: 'parent',
      })
    );
    updateMenuCategory(isEditingCategory.current.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated category!',
      // });
      //
      resetMenuWatchers(true);
    });
  };
  // delete category to API
  const deleteCategory = (category) => {
    destroyMenuCategory(category.id).then((res) => {
      // notification.open({
      //   message: 'Successfully deleted category!',
      // });
      eventSocket.emit(
        'deletesMenu',
        JSON.stringify({
          id: category.id,
        })
      );
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);
    });
  };

  // after clicking "Add menu", creates new menu item
  const createNewMenu = (menu) => {
    const clonedMenulist = cloneDeep(dataSource);
    const foundCategoryIndex = findIndex(clonedMenulist, { categoryId: menu.categoryId });
    const menuItems = clonedMenulist[foundCategoryIndex].children;
    const addMenuRow = find(menuItems, { key: menu.key });
    let addMenuRowIndex = findIndex(menuItems, { key: menu.key });

    let toCreate = {
      name: '',
      activate: false,
      activerecipes: [],
      assignee: '',
      assigneeImg: '',
      attachments: 0,
      categorized: 'no',
      prepped: {
        active: false,
      },
      recipes: [],
      tags: [],
    };

    clonedMenulist[foundCategoryIndex].children[addMenuRowIndex] = { ...addMenuRow, ...toCreate };
    clonedMenulist[foundCategoryIndex].children[addMenuRowIndex].type = 'child';

    setDataSource(clonedMenulist);
  };
  // after clicking "Cancel" while adding a new menu item
  const cancelNewMenu = (menu) => {
    const clonedMenulist = cloneDeep(dataSource);
    const foundCategoryIndex = findIndex(clonedMenulist, { categoryId: menu.categoryId });
    const menuItems = clonedMenulist[foundCategoryIndex].children;
    let addMenuRowIndex = findIndex(menuItems, { key: menu.key });

    clonedMenulist[foundCategoryIndex].children[addMenuRowIndex] = {
      key: menu.key,
      type: 'add-button',
      categoryId: menu.categoryId,
    };
    setDataSource(clonedMenulist);
  };

  // add menu to API
  const addMenu = (menu) => {
    console.log('MENU TO ADD', menu);
    if (menu.name === '') {
      //
      // notification.open({
      //   message: 'Please provide a menu name',
      // });
      return;
    }

    let prep = {
      active: false,
      createdBy: menu.assignee,
      tags: menu.tags.length > 0 ? [...menu.tags] : [],
    };

    let params = {
      restaurantId: resto.id,
      name: menu.name,
      categoryId: menu.categoryId,
      info: prep,
    };

    create(params).then((res) => {
      if (res) {
        console.log('adding menu results', res);

        // notification.open({
        //   message: 'Successfully created menu! Fetching all categories...',
        // });
        const newMenu = {
          activate: false,
          activerecipes: [],
          assignee: res.info?.createdBy,
          assigneeImg: '',
          attachments: 0,
          categorized: 'yes',
          categoryId: res.categoryId.toString(),
          id: res.id.toString(),
          key: `${res.categoryId}-${res.id}`,
          name: res.name,
          prepped: res.info,
          recipes: [],
          tags: res.info?.tags && res.info.tags.length > 0 ? [...res.info.tags] : [],
          texts: 0,
          type: 'child',
        };

        eventSocket.emit('addMenuItem', JSON.stringify(newMenu));
        // setTimeout(() => {
        //   window.location.reload();
        // }, 5000);
      }
    });
  };
  // edit menu to API
  const editMenu = (menu) => {
    console.log(menu);
    let prep = {};

    let params = {
      restaurantId: resto.id,
      categoryId: menu.categoryId,
    };

    prep.createdBy = has(isEditingMenu.current, 'assignee') ? isEditingMenu.current.assignee : menu.assignee;

    prep.tags =
      has(isEditingMenu.current, 'tags') && !isEqual(isEditingMenu.current.tags, menu.tags)
        ? isEditingMenu.current.tags
        : menu.tags;

    params.name =
      has(isEditingMenu.current, 'name') && isEditingMenu.current.name !== '' ? isEditingMenu.current?.name : menu.name;

    prep.active = menu.activate;
    if (Object.keys(prep).length > 0) {
      params.info = prep;
    }

    eventSocket.emit(
      'updateStationMenu',
      JSON.stringify({
        id: isEditingMenu.current.id,
      })
    );

    eventSocket.emit(
      'updatesMenuItem',
      JSON.stringify({
        activate: menu.activate,
        activerecipes: menu.activerecipes,
        assignee: prep.createdBy,
        assigneeImg: '',
        attachments: menu?.attachments,
        categorized: menu?.categorized,
        categoryId: menu?.categoryId,
        id: menu.id,
        key: menu.key,
        name: params.name,
        prepped: Object.keys(prep).length > 0 ? prep : menu.prepped,
        recipes: menu.recipes,
        tags: prep.tags,
        texts: menu.texts,
        type: 'child',
      })
    );

    updateMenu(menu.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated menu!',
      // });

      resetMenuWatchers(true);
    });
  };
  // delete menu to API
  const deleteMenu = (menu) => {
    console.log('menu to delete', menu);
    const isMenuOriginal = menu.name.indexOf('Copy of ') === 0 ? false : true;
    destroy(parseInt(menu.id), isMenuOriginal).then(() => {
      // notification.open({
      //   message: 'Successfully deleted menu!',
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);

      eventSocket.emit(
        'deleteMenuItem',
        JSON.stringify({
          id: menu.id,
          categoryId: menu.categoryId,
        })
      );
    });
  };

  // removing assignee
  const removeAssignee = (menu) => {
    // setInputAssignee('');
    // setOperatorChoices([]); // CHANGE 1202077200318274
    menu.assignee = '';
    menu.assigneeImg = '';
  };
  // toggling a menu
  const toggleActiveMenu = (checked, obj) => {
    let prep = {
      active: checked,
      activerecipes: obj?.activerecipes,
      assignee: obj?.assignee,
      assigneeImg: obj?.assigneeImg,
      attachments: obj?.attachments,
      categorized: obj?.categorized,
      recipes: obj?.recipes,
      tags: obj?.tags,
      texts: obj?.texts,
    };
    eventSocket.emit(
      'updateStationMenu',
      JSON.stringify({
        id: obj.id,
      })
    );
    eventSocket.emit(
      'updatesMenuItem',
      JSON.stringify({
        activate: checked,
        activerecipes: obj.activerecipes,
        assignee: obj.assignee,
        assigneeImg: obj.assigneeImg,
        attachments: obj.attachments,
        categorized: obj.categorized,
        categoryId: obj.categoryId,
        id: obj.id,
        name: obj.name,
        prepped: obj.prepped,
        recipes: obj.recipes,
        tags: obj.tags,
        texts: obj.texts,
        type: 'child',
      })
    );
    if (checked) {
      activateChecklist(obj.id).then((res) => {
        updateMenu(obj.id, {
          info: prep,
          active: checked,
        }).then((res) => {
          refreshMenuItem(obj, 'activate', checked);
          // notification.open({
          //   message: 'Successfully activated menu!',
          // });

          // setTimeout(() => {
          //   window.location.reload();
          // }, 5000);
        });
      });
    } else {
      // deactivateChecklist(obj.id).then((res) => {
      updateMenu(obj.id, {
        info: prep,
        active: checked,
      }).then((res) => {
        refreshMenuItem(obj, 'activate', checked);
        // notification.open({
        //   message: 'Successfully deactivated menu!',
        // });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 5000);
        // });
      });
    }
  };

  // table row actions
  const menuActions = (menu) => {
    return (
      <Menu className="menu-show-preview">
        <Menu.Item
          className="menu-show-preview"
          onClick={async () => {
            setCurrentTags(menu.tags);
            setCurrentAssignee(menu.assignee);
            setCurrentAssigneeImg(menu.assigneeImg);

            isEditingMenu.current.id = menu.id;
            isEditingMenu.current.categoryId = menu.categoryId;
            isEditingMenu.current.key = menu.key;

            let cloneList = cloneDeep(dataSource);
            remove(cloneList, (cat) => cat.tempId && cat.key === `${cat.tempId}`);
            setDataSource(cloneList);
          }}
        >
          <span>Edit</span>
        </Menu.Item>
        <Menu.Item
          className="menu-show-preview"
          onClick={() => {
            const menuId = menu.id;
            duplicate(menuId).then((res) => {
              if (res) {
                console.log('duplicating menu results', res);

                // notification.open({
                //   message: 'Successfully created menu! Fetching all categories...',
                // });
                const newMenu = {
                  activate: false,
                  activerecipes: [],
                  assignee: res.info?.createdBy,
                  assigneeImg: '',
                  attachments: 0,
                  categorized: 'yes',
                  categoryId: res.categoryId.toString(),
                  id: res.id.toString(),
                  key: `${res.categoryId}-${res.id}`,
                  name: res.name,
                  prepped: res.info,
                  recipes: [],
                  tags: res.info?.tags && res.info.tags.length > 0 ? [...res.info.tags] : [],
                  texts: 0,
                  type: 'child',
                };
                eventSocket.emit('addMenuItem', JSON.stringify(newMenu));
              }
            });
          }}
        >
          <span>Duplicate</span>
        </Menu.Item>
        <Menu.Item
          className="menu-show-preview"
          onClick={() => {
            deleteMenu(menu);
          }}
        >
          <span>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };
  const categoryActions = (category) => (
    <Menu className="menu-show-preview">
      <Menu.Item
        className="menu-show-preview"
        onClick={() => {
          isEditingCategory.current = { ...category };
        }}
      >
        <span>Edit</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={() => deleteCategory(category)}>
        <span>Delete</span>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    // row dragger
    {
      title: () => {
        return <div></div>;
      },
      // className: 'col_handle',
      dataIndex: 'dragrow',
      // sorter: (a, b) => (a.checked < b.checked ? -1 : a.checked > b.checked ? 1 : 0),
      // sortDirections: ['descend', 'ascend'],
      width: '2%',
      render: (key, obj) => (
        isFiltering || isSearching ? ( null ) : (
          <span>
            {obj.type == 'parent' && !soloMenuResult ? (
              <a className="drag-handle">
                <span className={isDown.current && activeCat.current == obj.id ? 'showDragIcon' : 'hideDragIcon'}>
                  {' '}
                  <img src={reorder} alt="reorder" className="menuToggles" />
                </span>
              </a>
            ) : (
              obj.categorized == 'yes' && (
                <a className="drag-handle">
                  <span className={isDown.current && activeCat.current == obj.id ? 'showDragIcon' : 'hideDragIcon'}>
                    <img src={reorder} alt="reorder" className="menuToggles" />
                  </span>
                </a>
              )
            )}
          </span>
        )
      ),
    },
    // row expander
    {
      title: () => {
        return <div></div>;
      },
      // className: 'col_expand',
      width: '2%',
    },
    // name
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: '29%',
      render: (key, obj) => (
        <div style={{ display: 'flex' }}>
          <span className={obj.type == 'parent' && !soloMenuResult ? '' : 'name-span1'}>
            {obj.type == 'child' ||
            (Object.keys(isEditingCategory.current).length &&
              isEditingCategory.current.children.filter((m) => m.id === obj.id).length > 0) ? (
              <span className="common-folder-icon">
                <FolderIcon />
              </span>
            ) : (
              ''
            )}
          </span>
          <span
            className={obj.type == 'parent' && !soloMenuResult ? '' : 'menu-row-name'}
            onDrop={(e) => setDestination(e, obj)}
          >
            {key && (
              <span className={obj.selected ? 'highlight' : ''}>
                {/* Menu item editing */}
                {obj.type === 'child' && has(isEditingMenu.current, 'id') && isEditingMenu.current.id == obj.id && (
                  <input
                    type="text"
                    className="row-name-input"
                    placeholder="Enter a menu name"
                    defaultValue={key}
                    onChange={(e) => (isEditingMenu.current.name = e.target.value.trim())}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        if (isEditingMenu.current.id === obj.id) {
                          editMenu(obj);
                        }
                      }
                    }}
                  />
                )}
                {/* Menu category editing */}
                {obj.type === 'parent' &&
                  has(isEditingCategory.current, 'id') &&
                  isEditingCategory.current.id === obj.id && (
                    <span>
                      <input
                        autoFocus
                        type="text"
                        className="row-name-input"
                        placeholder="Change category name"
                        defaultValue={obj.name}
                        onChange={(e) => {
                          isEditingCategory.current.name = e.target.value;
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            editCategory(obj);
                          }
                        }}
                      />
                    </span>
                  )}
                {/* Menu item tooltip */}
                {obj.type === 'child' && (
                  <span
                    onMouseOver={() => {
                      resetMenuWatchers(false);
                    }}
                  >
                    <Link to={`/menu/${obj.id}`}>
                      <Tooltip title={key}>
                        <span
                          className={
                            obj.key === `${obj.categoryId}-9999999` && obj.type !== 'add-button' ? 'menu-name' : ''
                          }
                        >
                          {isEditingMenu.current.id != obj.id && <span>{obj.name}</span>}
                        </span>
                      </Tooltip>
                    </Link>
                  </span>
                )}
              </span>
            )}{' '}
            {/* Menu category adding */}
            {obj.type === 'parent' && obj.key === `${obj.tempId}` && (
              <>
                <input
                  type="text"
                  className="row-name-input"
                  placeholder="Enter new category name"
                  defaultValue={obj.name}
                  onChange={(e) => {
                    obj.name = e.target.value;
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addCategory(obj);
                    }
                  }}
                />
              </>
            )}
            {/* Menu category not editing */}
            {obj.type === 'parent' &&
              !soloMenuResult &&
              (isEditingCategory.current.id !== obj.id && !has(obj, 'tempId') ? (
                <span className="row-parent-title">
                  {obj.name} ( {obj.menuCount > 0 ? obj.menuCount + ' ' + 'menus' : 'no menus'} )
                </span>
              ) : (
                ''
              ))}
            {obj.type === 'child' &&
              obj.key !== `${obj.categoryId}-9999999` &&
              (!has(isEditingMenu.current, 'id') || !isEditingMenu.current.id === obj.id) && (
                <AttachmentIndicator
                  className="menu-attachment-indicator"
                  showIfZero={true}
                  count={obj.document}
                  onClick={(e) => showDrawer(e, obj)}
                />
              )}
            {/* Add menu row open */}
            {obj.type === 'child' && obj.key === `${obj.categoryId}-9999999` && (
              <span>
                <input
                  // autoFocus
                  type="text"
                  className="row-name-input"
                  placeholder="Enter new menu name"
                  onChange={(e) => {
                    obj.name = e.target.value;
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      //
                      addMenu(obj);
                    }
                  }}
                />
              </span>
            )}
            {/* Add menu row button */}
            {obj.type === 'add-button' && obj.key === `${obj.categoryId}-9999999` && (
              <RoleAccessibleComponent
                permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
              >
                <CidekicButton
                  className="button-link-replacement"
                  alt="create new menu"
                  onClick={() => {
                    createNewMenu(obj);
                  }}
                >
                  Add menu
                </CidekicButton>
              </RoleAccessibleComponent>
            )}
          </span>
          {obj.type === 'child' &&
            obj.key === `${obj.categoryId}-9999999` &&
            isEditingMenu.current.id != obj.id && (
              <AttachmentIndicator
                className="menu-attachment-indicator"
                count={obj.document}
                showIfZero={false}
                onClick={(e) => showDrawer(e, obj)}
              />
            )}
        </div>
      ),
    },
    // attached recipes
    {
      title: 'Recipes',
      dataIndex: 'recipes',
      width: '10%',
      sorter: (a, b) => (a.recipes < b.recipes ? -1 : a.recipes > b.recipes ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (key, obj) => (
        <span>
          {obj.type == 'child' && key && obj.key !== `${obj.categoryId}-9999999` ? (
            <span>{`${obj.recipes.length} recipe${obj.recipes.length != 1 ? 's' : ''}`}</span>
          ) : (
            ''
          )}
        </span>
      ),
    },
    // active recipes
    {
      title: 'Active',
      dataIndex: 'activerecipes',
      width: '10%',
      // sorter: (a, b) => (a.active < b.active ? -1 : a.active > b.active ? 1 : 0),
      // sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (key, obj) => (
        <span>
          {obj.type == 'child' && key && obj.key !== `${obj.categoryId}-9999999` ? (
            <span>
              {obj.activerecipes.length} out of {obj.recipes.length}
            </span>
          ) : (
            ''
          )}
        </span>
      ),
    },
    // assignee
    {
      title: 'Creator',
      dataIndex: 'assignee',
      width: '20%',
      sorter: (a, b) => (a.assignee < b.assignee ? -1 : a.assignee > b.assignee ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      render: (key, obj) => (
        <span>
          {/* Creator Add Mode */}
          {obj.type === 'child' && obj.key === `${obj.categoryId}-9999999` && has(obj, 'categorized') && (
            <RowAssigneeEditWithSearch
              assigneeChoices={operatorChoices}
              assignee={obj.assignee}
              // onSearch={(e) => searchOperators(e)} // CHANGE 1202077200318274
              onChange={(e) => {
                // setInputAssignee(e.target.value);
                obj.assignee = e;
              }}
              onClickRemove={() => removeAssignee(obj)}
            />
          )}
          {/* Creator Edit Mode */}
          {obj.type === 'child' && has(isEditingMenu.current, 'id') && isEditingMenu.current.id === obj.id && (
            <RowAssigneeEditWithSearch
              assigneeChoices={operatorChoices}
              assignee={
                has(isEditingMenu.current, 'assignee') && isEditingMenu.current.assignee !== ''
                  ? isEditingMenu.current.assignee
                  : null
              }
              // onSearch={(e) => searchOperators(e)} // CHANGE 1202077200318274
              onChange={(e) => {
                // setInputAssignee(e.target.value);
                isEditingMenu.current.assignee = e;
              }}
              onClickRemove={() => {
                removeAssignee(isEditingMenu.current);
              }}
            />
          )}
          {obj.type === 'child' && obj.key !== `${obj.categoryId}-9999999` && obj.id !== isEditingMenu.current.id && (
            <>
              <div className="common-user-icon" alt="user-photo">
                <UserImage src={obj.assigneeImg !== '' ? obj.assigneeImg : null} />
              </div>
              <span>{key}</span>
            </>
          )}
        </span>
      ),
    },
    // tags
    {
      title: 'Tags',
      dataIndex: 'tags',
      sorter: (a, b) => (a.tags < b.tags ? -1 : a.tags > b.tags ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: '15%',
      render: (tags, obj) => (
        <RowTags
          isEditing={has(isEditingMenu.current, 'id') && isEditingMenu.current.id === obj.id}
          isAdding={obj.key === `${obj.categoryId}-9999999` && obj.type === 'child'}
          inputName={`add-tags-${obj.id}-input`}
          inputId={`${obj.id}-input-tag`}
          onEditKeyPress={(e) => {
            if (e.key === 'Enter') {
              // if row is open edit
              if (has(isEditingMenu.current, 'id') && isEditingMenu.current.id === obj.id) {
                // if reference has 'tags' key already
                if (has(isEditingMenu.current, 'tags')) {
                  isEditingMenu.current.tags.push(e.target.value.trim());
                } else {
                  isEditingMenu.current.tags = currentTags.concat(e.target.value.trim());
                }
              }
              // if row is being added
              if (obj.key === `${obj.categoryId}-9999999` && obj.type === 'child') {
                obj.tags.push(e.target.value.trim());
              }
              e.target.value = '';
            }
          }}
          tags={
            has(isEditingMenu.current, 'tags') && isEditingMenu.current.id === obj.id
              ? isEditingMenu.current.tags
              : obj.tags
          }
          objId={() =>
            obj.key === `${obj.categoryId}-9999999` && obj.type !== 'add-button' ? `${obj.categoryId}-9999999` : obj.id
          }
          closable={
            (obj.type === 'child' && obj.key === `${obj.categoryId}-9999999`) ||
            (has(isEditingMenu.current, 'id') && isEditingMenu.current.id === obj.id)
          }
          onClose={(value) => {
            if (has(isEditingMenu.current, 'tags') && isEditingMenu.current.id === obj.id) {
              remove(isEditingMenu.current.tags, (tag) => tag === value);
            } else {
              remove(obj.tags, (tag) => tag === value);
            }
          }}
        />
      ),
    },
    // activate menu
    {
      title: 'Active',
      dataIndex: 'activate',
      width: '5%',
      sorter: (a, b) => (a.activate < b.activate ? -1 : a.activate > b.activate ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {obj.type == 'child' && obj.key !== `${obj.categoryId}-9999999` && isEditingMenu.current.id !== obj.id && (
            <span>
              {' '}
              <div className="row-toggle-switch">
                <Switch checked={key} onChange={(e) => toggleActiveMenu(e, obj)} />
              </div>
            </span>
          )}
        </span>
      ),
    },
    // controls
    {
      title: '',
      dataIndex: 'controls',
      width: '7%',
      render: (key, obj) => (
        <span>
          {obj.type === 'child' && has(isEditingMenu.current, 'id') && obj.id === isEditingMenu.current.id ? (
            <RowOkCancel
              className="float-right"
              onClickOk={() => {
                editMenu(obj);
              }}
              onClickCancel={() => {
                resetMenuWatchers(false);
              }}
            />
          ) : (
            ' '
          )}
          {obj.type === 'parent' &&
          obj.name !== 'UNCATEGORIZED' &&
          obj.id === isEditingCategory.current.id &&
          !has(obj, 'tempId') ? (
            <RowOkCancel
              className="float-right"
              onClickOk={() => {
                editCategory(obj);
              }}
              onClickCancel={() => {
                resetMenuWatchers(false);
              }}
            />
          ) : (
            ' '
          )}
          {obj.type === 'parent' && obj.key === `${obj.tempId}` && (
            <RowOkCancel
              className="float-right"
              onClickOk={() => {
                addCategory(obj);
              }}
              onClickCancel={() => {
                cancelNewCategory(obj);
              }}
            />
          )}
          {obj.type === 'child' && obj.key === `${obj.categoryId}-9999999` && has(obj, 'categorized') && (
            <RowOkCancel
              className="float-right"
              onClickOk={() => {
                addMenu(obj);
              }}
              onClickCancel={() => {
                cancelNewMenu(obj);
              }}
            />
          )}
          {
            // actions will only show for category rows not being added and edited
            obj.type === 'parent' &&
              !Object.keys(isEditingCategory.current).length &&
              !Object.keys(isEditingMenu.current).length &&
              obj.name !== 'UNCATEGORIZED' &&
              !has(obj, 'tempId') && (
                <RoleAccessibleComponent
                  permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
                >
                  <Dropdown overlay={categoryActions(obj)}>
                    <div
                      className="row-toggle-switch"
                      onMouseOver={() => {
                        resetMenuWatchers(false);
                      }}
                    >
                      <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                    </div>
                  </Dropdown>
                </RoleAccessibleComponent>
              )
          }
          {
            // actions will only show for menu items not being added and edited
            obj.type === 'child' &&
              !Object.keys(isEditingCategory.current).length &&
              !Object.keys(isEditingMenu.current).length &&
              obj.categorized === 'yes' &&
              obj.id !== isEditingMenu.current.id && (
                <RoleAccessibleComponent
                  permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
                >
                  <Dropdown overlay={menuActions(obj)}>
                    <div
                      className="row-toggle-switch"
                      onMouseOver={() => {
                        resetMenuWatchers(false);
                      }}
                    >
                      <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                    </div>
                  </Dropdown>
                </RoleAccessibleComponent>
              )
          }
        </span>
      ),
    },
  ];
  useEffect(() => {
    getAllStationOperators(resto.id).then((res) => {
      console.log('OPERATORS', res);
      setOperatorChoices(res);
    });

    let getOpenRows = 
      localStorage.getItem('menuOpenRows') != undefined ?
        JSON.parse(localStorage.getItem('menuOpenRows')) :
        null;
    // let getColumns = 
    //   localStorage.getItem('menuMoveColumns') != undefined ? 
    //     JSON.parse(localStorage.getItem('menuMoveColumns')) :
    //     null;

    if (getOpenRows && getOpenRows.length > 0) {
      setExpandedRows(getOpenRows);
    } else {
      setExpandedRows([]);
    }

    // if (getColumns && getColumns.length > 0) {
    //   let cols = [...columns];
    //   cols.sort((a, b) => {
    //     return getColumns.findIndex((p) => p.title === a.title) - getColumns.findIndex((p) => p.title == b.title);
    //   });
    // } else {
    //   return;
    // }

    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }, []);

  const addCategoryToList = (cloned, data) => {
    // find index of UNCATEGORIZED MENUS
    let newCloned = cloned;
    const uncatIndex = findIndex(newCloned, (m) => m.key === '9999999999');

    // replace open add row with new added menu category, same index position
    newCloned.splice(uncatIndex, 0, data);
    return newCloned;
  }

  const addMenuToCategory = (cloned, data) => {
    let newCloned = cloned;
    let index = newCloned.findIndex((obj) => obj.categoryId === data.categoryId);
    let dataChildren = newCloned[index].children;

    if (dataChildren.length > 1) {
      newCloned[index].children[dataChildren.length - 1] = { ...data };
    } else {
      newCloned[index].children[0] = { ...data };
      newCloned[index].children.push({
        type: 'add-button',
        categoryId: data.categoryId,
        key: `${data.categoryId}-9999999`,
      });
    }

    newCloned[index].menuCount = newCloned[index].children.length - 1;
    return newCloned;
  }

  const updateMenuInCategory = (cloned, data) => {
    let newCloned = cloned;
    let index = newCloned?.findIndex((obj) => obj.categoryId === data?.categoryId);
    let childIndex = newCloned[index]?.children.findIndex((obj) => obj.id === data?.id);
    newCloned = newCloned.map((c) => {
      c.children.map((t) => {
        if (t.id == data.id) {
          c.children[childIndex] = data;
          return t;
        }
      });
      return c;
    });
    return newCloned;
  }

  const removeMenuFromCategory = (cloned, data) => {
    let newCloned = cloned;
    const findCategoryIndex = findIndex(newCloned, { categoryId: data.categoryId });
    remove(newCloned[findCategoryIndex].children, (c) => c.id === data.id);
    newCloned[findCategoryIndex].menuCount = newCloned[findCategoryIndex].children.length - 1;
    return newCloned;
  }

  const transferMenuToCategory = (cloned, data) => {
    let newCloned = cloned;
    let movedData = cloneDeep(data);
    const findOldCatIndex = findIndex(newCloned, { categoryId: movedData.oldCategoryId });
    const findNewCatIndex = findIndex(newCloned, { categoryId: movedData.categoryId });
    let newPosition = movedData.destinationIndex;
    delete movedData['oldCategoryId'];
    delete movedData['destinationIndex'];
    let newData = {
      ...movedData
    };
    
    if (
      findOldCatIndex > -1 &&
      findNewCatIndex > -1
    ) {
      remove(newCloned[findOldCatIndex].children, (c) => c.id === movedData.id);
      newCloned[findOldCatIndex].menuCount = newCloned[findOldCatIndex].children.length - 1;

      if (newPosition > 0) {
        let newCatGetButtonIndex = newCloned[findNewCatIndex].children.length - 1;
        newPosition = (
          newPosition >= newCatGetButtonIndex
        ) ? newCatGetButtonIndex : newPosition;
        newCloned[findNewCatIndex].children.splice(newPosition, 0, newData);
      } else {
        newCloned[findNewCatIndex].children.unshift(newData);
        // newCloned[index].children.push({
        //   type: 'add-button',
        //   categoryId: movedData.newCategoryId,
        //   key: `${movedData.categoryId}-9999999`,
        // });
      }

      newCloned[findNewCatIndex].menuCount = newCloned[findNewCatIndex].children.length - 1;
      return newCloned;
    } else {
      return false;
    }
  }

  useEffect(() => {
    eventSocket.on('updatedMenu', (message) => {
      let newData = JSON.parse(message);
      console.log('CATUPDATED!!', [newData]);
      setCategoryUpdate([newData]);
    });
    eventSocket.on('updatedMenuItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMUPDATED!!', newData);
      setMenuUpdate(newData);
    });
    eventSocket.on('addedMenu', (message) => {
      let newData = JSON.parse(message);
      console.log('CATADDED!!', newData);
      setCategoryAdd(newData);
    });
    eventSocket.on('addedMenuItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMADDED!!', newData);
      setMenuAdd(newData);
    });
    eventSocket.on('deletedMenu', (message) => {
      let newData = JSON.parse(message);
      console.log('CATDELETED!!', newData.id);
      setCategoryDelete(newData.id);
    });
    eventSocket.on('deletedMenuItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMDELETED!!', newData.id);
      setMenuDelete(newData);
    });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(categoryAdd).length > 0) {
      // find index of open add menu category row
      const tempId = categoryAdd.tempId;
      remove(dataSource, (m) => m.tempId === tempId);
      delete categoryAdd['tempId'];

      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);

      const updatedData = addCategoryToList(clonedData, categoryAdd);
      const updatedSocket = addCategoryToList(clonedSocket, categoryAdd);

      // update new table order
      const updatedTableOrder = cacheTableOrder(updatedData);
      setDataSource(updatedTableOrder.orderedMenu);
      localStorage.setItem('menuOrder', JSON.stringify(updatedTableOrder.menuOrder));

      // update to originalMenus all menu categories added to table
      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
      localStorage.setItem('originalMenus', JSON.stringify(updatedSocket));
      localStorage.setItem('menuOriginalData', updatedSocket.length);
    }
  }, [categoryAdd]);
  useEffect(() => {
    if (categoryDelete !== '') {
      let updatedData = dataSource.filter((obj) => obj.id !== categoryDelete.toString());
      let updatedSocket = socketData.filter((obj) => obj.id !== categoryDelete.toString());

      const updatedTableOrder = cacheTableOrder(updatedData);
      setDataSource(updatedTableOrder.orderedMenu);
      localStorage.setItem('menuOrder', JSON.stringify(updatedTableOrder.menuOrder));

      // update to originalMenus all menu categories deleted from table
      setSocketData(updatedSocket);
      setPristineData(updatedSocket);
      localStorage.setItem('originalMenus', JSON.stringify(updatedSocket));
      localStorage.setItem('menuOriginalData', updatedSocket.length);
    }
  }, [categoryDelete]);
  useEffect(() => {
    if (categoryUpdate.length > 0) {
      let updatedData = dataSource.map((obj) => categoryUpdate.find((o) => o.id.toString() === obj.id) || obj);
      let updatedSocket = socketData.map((obj) => categoryUpdate.find((o) => o.id.toString() === obj.id) || obj);
      const { orderedMenu } = cacheTableOrder(updatedData.filter((k) => k.key.indexOf('0.') !== -1))
      setDataSource(orderedMenu);

      setSocketData(updatedSocket);
      setPristineData(updatedSocket);
      localStorage.setItem('originalMenus', JSON.stringify(updatedSocket));
    }
  }, [categoryUpdate]);
  useEffect(() => {
    if (Object.keys(menuDelete).length > 0) {
      let clonedData = cloneDeep(dataSource);
      let clonedSocket = cloneDeep(socketData);

      let updatedData = removeMenuFromCategory(clonedData, menuDelete);
      let updatedSocket = removeMenuFromCategory(clonedSocket, menuDelete);
      
      const updatedTableOrder = cacheTableOrder(updatedData);
      setDataSource(updatedTableOrder.orderedMenu);
      localStorage.setItem('menuOrder', JSON.stringify(updatedTableOrder.menuOrder));

      setSocketData(updatedSocket);
      setPristineData(updatedSocket);
      localStorage.setItem('originalMenus', JSON.stringify(updatedSocket));
    }
  }, [menuDelete]);
  useEffect(() => {
    if (Object.keys(menuUpdate).length > 0) {
      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);
      let updatedSocket = [];

      if (
        Object.hasOwn(menuUpdate, 'oldCategoryId') ||
        Object.hasOwn(menuUpdate, 'destinationIndex')
      ) {
        // update with slice and remove
        const updatedData = transferMenuToCategory(clonedData, menuUpdate);
        updatedSocket = transferMenuToCategory(clonedSocket, menuUpdate);
        if (updatedData) {
          const { menuOrder, orderedMenu: tableOrdered } = cacheTableOrder(updatedData);
          setDataSource(tableOrdered);
          localStorage.setItem('menuOrder', JSON.stringify(menuOrder));
        }
      } else {
        // normal update
        const updatedData = updateMenuInCategory(clonedData, menuUpdate);
        updatedSocket = updateMenuInCategory(clonedSocket, menuUpdate);
        setDataSource(updatedData);
      }
      if (updatedSocket) {
        setSocketData(updatedSocket);
        setPristineData(updatedSocket);
        localStorage.setItem('originalMenus', JSON.stringify(updatedSocket));
      }
      setMenuUpdate({});
    }
  }, [menuUpdate]);
  useEffect(() => {
    if (Object.keys(menuAdd).length > 0) {
      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);

      const updatedData = addMenuToCategory(clonedData, menuAdd);
      const updatedSocket = addMenuToCategory(clonedSocket, menuAdd);
      
      const updatedTableOrder = cacheTableOrder(updatedData);
      setDataSource(updatedTableOrder.orderedMenu);
      localStorage.setItem('menuOrder', JSON.stringify(updatedTableOrder.menuOrder));
      
      setSocketData(updatedSocket);
      setPristineData(updatedSocket);
      localStorage.setItem('originalMenus', JSON.stringify(updatedSocket));
    }
  }, [menuAdd]);

  // applyColumnOrder - apply cached ANTD column order to dataSource
  const applyColumnOrder = (column, status, list = [], viewOnly = false) => {
    let rows = list.length ? list : cloneDeep(pristineData);
    let tableRows = rows.filter((r) => r.key.indexOf('0.') === -1);
    let toAddCategories = rows.filter((r) => r.key.indexOf('0.') !== -1);
    let orderedRows = [];

    if (tableRows.length) {
      // if single result, return
      if (tableRows.length === 1 && tableRows[0].children.length === 1) {
        return tableRows;
      }

      orderedRows = tableRows.map((p) => {
        if (p.categoryId !== 'UNCAT1' && p?.children != undefined && p.children.length > 2) {
          let getRows = p.children.filter((c) => c.type !== 'add-button');
          let buttonRow = p.children.filter((c) => c.type === 'add-button');

          if (column === 'recipes' || column === 'tags') {
            if (status === 'ascend') {
              getRows = getRows.sort((a, b) => a[column].length - b[column].length);
            } else {
              getRows = getRows.sort((a, b) => b[column].length - a[column].length);
            }
          } else if (column === 'activate') {
            if (status === 'ascend') {
              getRows = getRows.sort((a, b) => a[column] * 1 - b[column] * 1);
            } else {
              getRows = getRows.sort((a, b) => b[column] * 1 - a[column] * 1);
            }
          } else if (column === 'assignee' || column === 'name') {
            if (status === 'ascend') {
              getRows = getRows.sort((a, b) => a[column].toLowerCase().localeCompare(b[column].toLowerCase()));
            } else {
              getRows = getRows.sort((a, b) => b[column].toLowerCase().localeCompare(a[column].toLowerCase()));
            }
          }
          if (!viewOnly) {
            getRows.push(buttonRow[0]);
          }
          p.children = getRows;
        }
        return p;
      });

      // if more than 1 parent rows and column key sorter is 'name', sort as well
      if (orderedRows.length > 1 && column === 'name') {
        if (status === 'ascend') {
          const getUncategorizedMenus = orderedRows.filter((u) => u.categoryId === 'UNCAT1');
          let getCategorizedMenus = orderedRows.filter((u) => u.categoryId !== 'UNCAT1');
          orderedRows = getCategorizedMenus.sort((a, b) => a[column].toLowerCase().localeCompare(b[column].toLowerCase()));
          if (getUncategorizedMenus.length) {
            orderedRows.push(getUncategorizedMenus[0]);
          }
        } else {
          const getUncategorizedMenus = orderedRows.filter((u) => u.categoryId === 'UNCAT1');
          let getCategorizedMenus = orderedRows.filter((u) => u.categoryId !== 'UNCAT1');
          orderedRows = getCategorizedMenus.sort((a, b) => b[column].toLowerCase().localeCompare(a[column].toLowerCase()));
          if (getUncategorizedMenus.length) {
            orderedRows.push(getUncategorizedMenus[0]);
          }
        }
      }

      if (!viewOnly && toAddCategories.length) {
        // column sorting does not apply but open add rows always at the top
        const preserveOpenAddRows = toAddCategories.sort(
          (a, b) => a.key.localeCompare(b.key)
        );
        orderedRows.unshift(...preserveOpenAddRows);
      } 
    }
    console.log(orderedRows);
    return orderedRows;
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    let data = cloneDeep(dataSource);
    // const current = extra['currentDataSource'];
    const viewOnly = isFiltering || isSearching ? true : false;
    if (data.length && !viewOnly) {
      // if sorter is neither ascending nor descending, 
      // resort to manual menu table order
      // or sort by key
      // else save last auto table order (ascending or descending)
      if (sorter?.order == undefined) {
        if (
          localStorage.getItem('menuOrder') != undefined && 
          JSON.parse(localStorage.getItem('menuOrder')).length
        ) {
          const menuOrder = JSON.parse(localStorage.getItem('menuOrder'));
          const restoredOrder = annotateTableOrder(data, menuOrder, viewOnly);
          setDataSource(restoredOrder);
        } else {
          if (viewOnly) {
            setDataSource(data);
          } else {
            const restoredOrder = deannotateTableOrder(data, false);
            setDataSource(restoredOrder);
          }
        };
        localStorage.removeItem('menuColKeyOrder');
      } else {
        localStorage.setItem('menuColKeyOrder', JSON.stringify({
          order: sorter.order,
          columnKey: sorter.columnKey
        }));
        const restoredOrder = applyColumnOrder(sorter.columnKey, sorter.order, data, viewOnly);
        setDataSource(restoredOrder);
        // extra['currentDataSource'] = [...restoredOrder]; // ANTD Table remains uncontrolled component when applying newly sorted order from custom function, to be changed...
      }
    }
  };

  // let nameOrder = localStorage.getItem('sortMenuOrder');
  // useEffect(() => {
  //   if (nameOrder) {
  //     setTimeout(() => {
  //       localStorage.removeItem('sortMenuOrder');
  //       window.location.reload();
  //     }, 2000);
  //   }
  // }, [nameOrder]);

  // const removeOrder = () => {
  //   const backUp = cloneDeep(pristineData);
  //   setDataSource(backUp);
  // }; 

  // extractMenuWithRecipes - populate filter menu choices when fetch callback is not activated
  const extractMenuWithRecipes = (data) => {
    if (data.length > 0) {
      let menuWithRecipes = data.filter((d) => d.categoryId !== 'UNCAT1').reduce((acc, val) => {
        let menuItems = val.children.filter((c) => c.type != undefined && c.type !== 'add-button');
        if (val.children.length) {
          if (menuItems.length) {
            let toPush = menuItems.filter((m) => m.recipes != undefined && m.recipes.length);
            acc.push(...toPush);
          }
        }
        return acc;
      }, []);
      menuWithRecipes.sort(
        (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      return menuWithRecipes;
    } else {
      return [];
    }
  }

  const listener = () => {
    let originalMenus = localStorage.getItem('originalMenus') != undefined ? JSON.parse(localStorage.getItem('originalMenus')) : [];
    let menuOrder = localStorage.getItem('menuOrder') != undefined ? JSON.parse(localStorage.getItem('menuOrder')) : null;
    let menuColumnKeyOrder = localStorage.getItem('menuColKeyOrder') != undefined ? JSON.parse(localStorage.getItem('menuColKeyOrder')) : null
    let originalDataCount = localStorage.getItem('menuOriginalData') != undefined ? localStorage.getItem('menuOriginalData') : null;

    if (menuOrder && originalDataCount && originalMenus.length) {
      let openAddsRemoved = menuOrder;
      // if current number of categories in menuOrder != originalDataCount, change some variables
      // else return
      if (parseInt(menuOrder.length) !== parseInt(originalDataCount)) {
        openAddsRemoved = removeOpenAdds(menuOrder); // removes open add category rows and resort
        // if menuOrder retains menu categories, update to localStorage
        // else remove 'menuOrder' and 'menuOriginalData' from localStorage
        if (openAddsRemoved.length) {
          localStorage.setItem('menuOrder', JSON.stringify(openAddsRemoved));
        } else {
          localStorage.removeItem('menuOrder');
          localStorage.removeItem('menuOriginalData');
        }
      } else {
        // if cached table data originalMenus is not empty array,
        // apply latest column sorting 
        // or latest manual sorting (without open add category rows)
        // or no sorting applied if above are not applicable
        // to dataSource and socketData
        if (menuColumnKeyOrder) {
          const { order, columnKey } = menuColumnKeyOrder;
          const restoreData = applyColumnOrder(columnKey, order, originalMenus);
          const finalMenus = extractMenuWithRecipes(originalMenus);
          setPristineData(originalMenus);
          setDataSource(restoreData);
          setSocketData(originalMenus);
          if (finalMenus.length) {
            setMenuChoices(finalMenus);
          }
          setIsLoading(false);
        } else {
          const restoreData = annotateTableOrder(originalMenus, menuOrder);
          const finalMenus = extractMenuWithRecipes(originalMenus);
          setPristineData(originalMenus);
          setDataSource(restoreData);
          setSocketData(originalMenus);
          if (finalMenus.length) {
            setMenuChoices(finalMenus);
          }
          setIsLoading(false);
        }
      }
    } else {
      getCategory();
    }
  };

  useEffect(() => {
    document.addEventListener('beforeunload', listener());
    return () => {
      document.removeEventListener('beforeunload', listener());
    };
  }, []);

  return (
    <div id="menus" className="common-page-container d-flex flex-column">
      <NewSubHeader title="Menus" onChange={filterMenus} />
      <div className="Line"></div>
      <div className="page-margins flex-grow-1 d-flex flex-column">
        <div className="controls-thin mb-2 d-flex flex-row">
          <RoleAccessibleComponent
            permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
          >
            <PlusButton
              className="flex-control-left flex-control-rightspace"
              alt="Add new category"
              onClick={() => createNewCategory()}
            >
              Add new category
            </PlusButton>
          </RoleAccessibleComponent>
          <PlusButton className="flex-control-left" alt="Collapse all folders" onClick={collapseAll}>
            Collapse all
          </PlusButton>
          <CidekicSelect
            className="select-wide flex-control-right"
            onChange={filteringMenus}
            defaultValue={`${JSON.stringify({ name: 'All Menus' })}`}
          >
            {menuChoices &&
              [{ name: 'All menus' }].concat(menuChoices).map((m, i) => {
                return (
                  <Option key={i} value={`${i === 0 ? JSON.stringify({ name: 'All Menus' }) : JSON.stringify(m)}`}>
                    {m?.name}
                  </Option>
                );
              })}
          </CidekicSelect>
        </div>
        {isLoading && (
          <div style={{ textAlign: 'center' }}>
            <Spin tip="Loading menus..."></Spin>
          </div>
        )}
        {!isLoading && (
          <div className="flex-grow-1">
            <ReactDragListView {...dragProps}>
              {/* <ReactDragListView.DragColumn {...dragColumns}> */}
              <Table
                useFixedHeader={true}
                columns={columns}
                dataSource={dataSource}
                className={'show-custom-empty ant-table-fixedheader'}
                rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
                onRow={(record, index) => ({
                  onMouseOver: (event) => {
                    hoverBeforeDrag(event, record);
                  },
                  onDrop: (event) => {
                    setDestination(event, record);
                  },
                  onMouseLeave: (event) => {
                    cancelHover(event, record);
                  },
                })}
                expandIconColumnIndex={1}
                expandIcon={(props) => {
                  if (props.record.type == 'parent') {
                    return (
                      <ExpandCollapseIcon
                        expanded={props.expanded}
                        onClick={(e) => {
                          props.onExpand(props.record, e);
                        }}
                      />
                    );
                  }
                }}
                pagination={false}
                // scroll={{ y: 'calc(100vh - var(--table-scroll-offset))' }}
                // scroll={{ y: '100%' }}
                locale={{ emptyText: <Empty image={empty} description="No menus" /> }}
                onExpand={(expanded, record) => handleRowExpand(record)}
                expandedRowKeys={expandedRows}
                onChange={onTableChange}
                rowKey={'key'}
              />
              {/* </ReactDragListView.DragColumn> */}
            </ReactDragListView>
          </div>
        )}
      </div>

      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={visible}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentList
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks={docLinks}
          links={links}
          isMobile={false}
          documentsIn="menus"
        />
      </Drawer>
    </div>
  );
};

export default Menus;
