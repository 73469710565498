import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { onFileSelected } from '../../services/upload.service';

const AddFile = (props) => {
  const {
    handleDrop,
    handleChangeStatus, // called every time a file's `status` changes
  } = props;
  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return { url: 'https://httpbin.org/post' };
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log(files);
    //let allfiles = files.map((f) => f.meta);
    let allfiles = files.map((f) => {
      const name = f.file.name.replace(
        /^([^.]+)$|(\.[^.]+)$/i,
        '$1' + '_' + (Math.random() + 1).toString(36).substring(7) + '$2'
      );
      return new File([f.file], name, { type: f.file.type });
    });
    allFiles.forEach((f) => f.remove());

    const fetchData = () => {
      const urls = allfiles ? allfiles : [];

      const allRequests = urls.map((url) => onFileSelected(url).then((response) => response));

      return Promise.all(allRequests);
    };

    fetchData().then((res) => {
      if (res && res.length > 0) {
        let imageUrls = res.map((r) => r.Location);
        handleDrop(imageUrls);
      } else {
        handleDrop([]);
      }
    });
  };

  return (
    <div>
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        inputContent="Drag and drop or click to upload"
        accept="image/*"
        styles={{
          dropzone: {
            minHeight: 100,
            maxHeight: 200,
            border: 'dashed 2px var(--main-borders)',
            background: 'var(--deep-fill)',
          },
        }}
      />
      {/* </div> */}
    </div>
  );
};

export default AddFile;
