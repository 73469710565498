import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Tag, Switch, Tooltip, notification, Menu, Dropdown, Drawer, Select } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { PlusOutlined } from '@ant-design/icons';
import { allLocations, destroy, update, create } from '../../services/restaurant.service';
import { cloneDeep, find, remove, findIndex, isEmpty } from 'lodash';
import _ from 'lodash';
import { timeZones } from '../../data/timezones';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';

const LocationsMobile = (props) => {
  const [menuName, setMenuName] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [menulist, setList] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);

  const location_name = useRef(null);
  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';
  

  const newLocationValue = {
    id: 'add',
    name: '',
    address: '',
    timezone: timeZones[574],
  };

  const { Option } = Select;

  useEffect(() => {
    getLocations();
    setTimeout(() => {
      setHasUpdated(false);
      setEditing(false);
      setAdding(false);
      setEditId(null);
    }, 2000);
  }, [hasUpdated]);

  useEffect(() => {
    const listener = (event) => {
      if (isEditing && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        console.log('ADD!! Enter key was pressed. Run add.');
        event.preventDefault();
        console.log(getEditData);
        updateLocation(event, getEditData);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [isEditing, getEditData]);

  const getLocations = () => {
    allLocations(0, 1000).then((res) => {
      console.log(res);
      let allLoc = res;
      let address1 = allLoc.filter((a) => a.deletedAt == '');
      let address = address1.map((l) => {
        return {
          id: l.id,
          name: l.name,
          address: l.info && l.info?.address ? l.info?.address : '',
          timezone: l.info && l.info?.timezone ? l.info?.timezone : '',
        };
      });

      console.log(address);
      setList(address);
      setData(address);
      setOrigData(address);
    });
  };

  // const debouncedApiCall = useCallback(
  //   _.debounce((e) => {
  //     filterLocations(e);
  //   }, 200),
  //   []
  // );

  useEffect(() => {
    filterLocations(nameFilter);
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All restaurants');
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All restaurants</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>
                {m?.name} - {m?.address}
              </a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterLocations = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getLocations();
    }
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name + '-' + ' ' + menu.address;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.id === menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deleteLocation = (e, obj) => {
    e.preventDefault();
    // let id = selected?.id;
    let id = obj?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Location Successfully Deleted',
      // });
      setHasUpdated(true);
    });
  };

  const editLocation = (e, obj) => {
    e.preventDefault();
    console.log(obj);
    setEditing(true);
    setEditId(obj.id);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editLocation(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteLocation(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updateLocation = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename,
      info: {
        address: obj.address,
        timezone: obj.timezone,
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated location!',
      // });
      setHasUpdated(true);
    });
  };

  const updateLocation2 = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename,
      info: {
        address: obj.address,
        timezone: obj.timezone,
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated location!',
      // });
      setHasUpdated(true);
    });
  };

  const addLocation = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename !== '' ? rename : 'Untitled',
      info: {
        address: obj.address !== '' ? obj.address : '',
        timezone: obj.timezone !== '' ? obj.timezone : '',
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created location!',
      // });
      setHasUpdated(true);
    });
  };

  const addLocation2 = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename !== '' ? rename : 'Untitled',
      info: {
        address: obj.address !== '' ? obj.address : '',
        timezone: obj.timezone !== '' ? obj.timezone : '',
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created location!',
      // });
      setHasUpdated(true);
    });
  };

  const handleAddressChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].address = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleTimeChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].timezone = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div>
            <div>
              <RoleAccessibleComponent
                permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
              >
                <Dropdown overlay={controlItems(obj)}>
                  <div style={{ cursor: 'pointer', float: 'right' }}>
                    <img
                      src={IconsMoreOff}
                      className="UI-IconsMoreOff"
                      alt="IconsMoreOff"
                      onClick={() => setSelected(obj)}
                    />
                  </div>
                </Dropdown>
              </RoleAccessibleComponent>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '-6px' }}>Title</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      updateLocation(e, obj);
                    }
                  }}
                  onBlur={(e) => updateLocation2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className={isAdding && obj.id == 'add' ? '' : 'loc_nameMob'}>{obj.name}</div>
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addLocation(e, obj);
                    }
                  }}
                  onBlur={(e) => addLocation2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Address</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 30 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter an address"
                  defaultValue={obj.address}
                  onChange={(e) => handleAddressChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 30 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter an address"
                  defaultValue={obj.address}
                  onChange={(e) => handleAddressChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 30 }}>{obj.address}</div>}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Timezone</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 20 }}>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a timezone"
                  defaultValue={obj.timezone}
                  onChange={(e) => handleTimeChange(e.target.value, obj)}
                /> */}
                <Select
                  className="station-selectEv10"
                  onChange={(e) => handleTimeChange(e, obj)}
                  value={obj.timezone}
                  showArrow={false}
                  showSearch
                >
                  {timeZones.map((zone) => (
                    <Option value={zone}>{zone}</Option>
                  ))}
                </Select>
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 20 }}>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a timezone"
                  defaultValue={obj.timezone}
                  onChange={(e) => handleTimeChange(e.target.value, obj)}
                /> */}
                <Select
                  className="station-selectEv10"
                  onChange={(e) => handleTimeChange(e, obj)}
                  value={obj.timezone}
                  showArrow={false}
                  showSearch
                >
                  {timeZones.map((zone) => (
                    <Option value={zone}>{zone}</Option>
                  ))}
                </Select>
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 25 }}>{obj.timezone}</div>}
          </div>
        </span>
      ),
    },
  ];

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Locations"
        restaurant="Admin"
        showSearch={showSearch}
        onChange={(evt) => setNameFilter(evt.target.value)}
        clickSearch={() => setShowSearch(true)}
      />
      <div style={{ display: 'flex' }}>
        <div className="add-cat23Mob">
          <div>{menuName ? menuName : 'Restaurant name'}</div>
        </div>
        <Dropdown overlay={menuList}>
          <div className="dropdown-icon">
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </Dropdown>
        <div className="spacer-cat"></div>
        <RoleAccessibleComponent
          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
        >
          <div
            className="add-cat13Mob"
            onClick={() => {
              //setIsAdding(true);
            }}
          >
            <div
              style={{ cursor: 'pointer', fontFamily: 'Helvetica Neue' }}
              onClick={() => {
                setAdding(true);
                const clonedDataSource = cloneDeep(origData);
                clonedDataSource.unshift(newLocationValue);
                setData(clonedDataSource);
              }}
            >
              + <span style={{ marginLeft: 5 }}>Add new location</span>
            </div>
          </div>
        </RoleAccessibleComponent>
      </div>
      <div style={{ padding: '30px 20px 0px 15px' }}>
        <Table
          dataSource={userData}
          columns={columns}
          pagination={false}
          className={'show-custom-empty'}
          locale={{ emptyText: <Empty image={empty} description="No locations" /> }}
        />
      </div>{' '}
      <RoleAccessibleComponent
        permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
      >
        <div className="add-checklistLoc">
          <div
            className="add-checklist-but"
            onClick={() => {
              setAdding(true);
              const clonedDataSource = cloneDeep(origData);
              clonedDataSource.push(newLocationValue);
              setData(clonedDataSource);
            }}
          >
            <span>
              <PlusOutlined />
            </span>{' '}
            Add new location
          </div>
        </div>
      </RoleAccessibleComponent>
    </div>
  );
};

export default LocationsMobile;
