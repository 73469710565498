import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { onFileSelected } from '../../services/upload.service';

const AddDoc = (props) => {
  const { handleDrop } = props;

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return { url: 'https://httpbin.org/post' };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file, remove }, status) => {
    console.log(status, meta, file);
    if (status === 'headers_received') {
      console.log('uploaded');
      onFileSelected(file).then((res) => {
        if (res && res.Location) {
          handleDrop({ url: res.Location, type: file.type });
        } else {
          handleDrop('none');
        }
      });
      remove();
    } else if (status === 'aborted') {
      console.log('upload failed');
    }
  };

  return (
    <div>
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        inputContent="Drag and drop or click to upload"
        styles={{
          dropzone: {
            height: 100,
            width: 270,
            border: 'dashed 2px var(--main-borders)',
            background: 'var(--deep-fill)',
            marginLeft: 40,
          },
        }}
      />
      {/* </div> */}
    </div>
  );
};

export default AddDoc;
