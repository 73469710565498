import React from 'react';
import dashboardIcon from '../img/menuIcon/ui-icons-dashboard-off.svg';
import menuIcon from '../img/menuIcon/ui-icons-recipes-on.svg';
import recipesIcon from '../img/menuIcon/ui-icons-menus-on.svg';
import checklistIcon from '../img/menuIcon/ui-icons-checklist-on.svg';
//import staffRotaIcon from "../img/menuIcon/ui-icons-staff-rota-on.svg"
import documentsIcon from '../img/menuIcon/ui-icons-documents-on.svg';
import shoppingIcon from '../img/menuIcon/menu-shopcart-placeholder.png';
// import needsOnIcon from "../img/menuIcon/ui-icons-needs-on.svg"
// import orderedIcon from "../img/menuIcon/ui-icons-ordered.svg"

export const menuItems = [
  {
    name: 'main',
    title: 'Main',
    children: [
      {
        name: 'dashboard',
        title: 'Dashboard',
        icon: <img src={dashboardIcon} alt="dashboardIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'recipes',
        title: 'Recipes',
        icon: <img src={recipesIcon} alt="recipesIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'menus',
        title: 'Menus',
        icon: <img src={menuIcon} alt="menuIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'checklist',
        title: 'Checklists',
        icon: <img src={checklistIcon} alt="checklistIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'documents',
        title: 'Documents',
        icon: <img src={documentsIcon} alt="documentsIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'shoppinglist',
        title: 'Shopping List',
        icon: <img src={shoppingIcon} alt="shoppingIcon" className="UI-IconsMenusOn" />,
      },
    ],
  },
  // {
  //     name: "shop",
  //     title: "Shop",
  //     children: [
  // 			{
  // 				name: "shoppinglist",
  // 				title: "Shopping List",
  // 				icon: <img src={shoppingIcon} alt="shoppingIcon" className="UI-IconsMenusOn" />
  // 			},
  //         // {
  //         //     name: "need",
  //         //     title: "Need",
  //         //     icon: <img src={needsOnIcon} alt="needsOnIcon"  className="UI-IconsMenusOn"/>
  //         // },
  //         // {
  //         //     name: "ordered",
  //         //     title: "Ordered",
  //         //     icon: <img src={orderedIcon} alt="orderedIcon"  className="UI-IconsMenusOn"/>
  //         // }
  //     ]
  // },
  // {
  //     name: "more",
  //     title: "More",
  //     children: [
  //         // {
  //         //     name: "faq",
  //         //     title: "FAQ",
  //         //     icon: ""
  //         // },
  //         // {
  //         //     name: "help",
  //         //     title: "HELP",
  //         //     icon: ""
  //         // },
  //         {
  //             name: "legal",
  //             title: "Legal",
  //             icon: ""
  //         },
  //         // {
  //         //     name: "about",
  //         //     title: "ABOUT",
  //         //     icon: ""
  //         // },
  //         {
  //             name: "contactus",
  //             title: "Contact Us",
  //             icon: ""
  //         }
  //     ]
  // }
];
