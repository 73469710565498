/* eslint eqeqeq: "off" */
import React, { useState } from 'react';
import moment from 'moment';
import EditEventDrawerV2 from '../../pages/Dashboard/components/EditEventDrawerV2';
import './index.css';
import RoleAccessibleComponent from '../RoleAccess/roleaccess';

const CustomEventMobile = ({ event, setCalendarType, setDay, setHasEditedEvent }) => {
  const [showDrawer, setShowDrawer] = useState({ type: '', open: false, data: null });
  return (
    <div className="main">
      <div
        className="custom-event-mob"
        onClick={() => {
          // setDay(event.start.format('dddd, MMMM D, YYYY'));
          // setCalendarType('day');
          setShowDrawer({ type: event.type, open: true, data: event });
        }}
      >
        <span className={event.type == 'event' ? 'dot-pink' : 'dot-blue'}></span>{' '}
        <strong>{moment(event.start).format('LT')}</strong> {event.title}
      </div>
      <RoleAccessibleComponent
        permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
      >
        {showDrawer.open && (
          <EditEventDrawerV2
            showDrawer={showDrawer.open}
            type={showDrawer.type}
            data={showDrawer.data}
            setShowDrawer={setShowDrawer}
            setHasEditedEvent={setHasEditedEvent}
          />
        )}
      </RoleAccessibleComponent>
    </div>
  );
};

export default CustomEventMobile;
