export const stationDetail = {
  assignee: 'Ma Leonor Villalobos',
  healthCheck: 2,
  productionReady: 5,
  totalIngredients: 20,
  completedIng: 1,
  totalHours: 11,
  completedHrs: 2,
  comments: 2,
  attachments: 1,
};

export const stationIngs = [
  {
    key: '1',
    name: 'Food production safety',
    type: 'parent',
    ingredients: 3,
    children: [
      {
        key: 8,
        type: 'child',
        tags: [
          { key: 8, name: 'Hair net', type: 'child', completed: 1, texts: 2, attachments: 1 },
          {
            key: 9,
            name: 'Chef hat',
            type: 'child',
            completed: 2,
            texts: 10,
            attachments: 0,
          },
          {
            key: 10,
            name: 'Clean uniform',
            type: 'child',
            completed: 1,
            texts: 0,
            attachments: 5,
          },
        ],
      },
    ],
  },
  {
    key: '2',
    name: 'Equipment',
    type: 'parent',
    ingredients: 3,
    children: [
      {
        key: 9,
        type: 'child',
        tags: [
          {
            key: 11,
            name: 'Gloves, Xtra Large',
            type: 'child',
            completed: 1,
          },
          {
            key: 12,
            name: 'Towels',
            type: 'child',
            completed: 1,
          },
          {
            key: 13,
            name: 'Printed paper',
            type: 'child',
            completed: 1,
          },
        ],
      },
    ],
  },
  {
    key: '3',
    name: 'Focus points',
    type: 'parent',
    ingredients: 3,
    children: [
      {
        key: 10,
        type: 'child',
        tags: [
          {
            key: 14,
            name: 'Clean and organized station',
            type: 'child',
            completed: 1,
          },
          {
            key: 15,
            name: 'No tablets on cutting boards',
            type: 'child',
            completed: 1,
          },
          {
            key: 16,
            name: 'No towels on cutting boards',
            type: 'child',
            completed: 1,
          },
        ],
      },
    ],
  },
];

export const stationIng = [
  {
    key: '1',
    name: 'Food production safety',
    type: 'parent',
    ingredients: 3,
    children: [
      {
        key: 8,
        name: 'Hair net',
        type: 'child',
        completed: 1,
        texts: 2,
        attachments: 1,
      },
      {
        key: 9,
        name: 'Chef hat',
        type: 'child',
        completed: 2,
        texts: 10,
        attachments: 0,
      },
      {
        key: 10,
        name: 'Clean uniform',
        type: 'child',
        completed: 1,
        texts: 0,
        attachments: 5,
      },
    ],
  },
  {
    key: '2',
    name: 'Equipment',
    type: 'parent',
    ingredients: 3,
    children: [
      {
        key: 11,
        name: 'Gloves, Xtra Large',
        type: 'child',
        completed: 1,
      },
      {
        key: 12,
        name: 'Towels',
        type: 'child',
        completed: 1,
      },
      {
        key: 13,
        name: 'Printed paper',
        type: 'child',
        completed: 1,
      },
    ],
  },
  {
    key: '3',
    name: 'Focus points',
    type: 'parent',
    ingredients: 3,
    children: [
      {
        key: 14,
        name: 'Clean and organized station',
        type: 'child',
        completed: 1,
      },
      {
        key: 15,
        name: 'No tablets on cutting boards',
        type: 'child',
        completed: 1,
      },
      {
        key: 16,
        name: 'No towels on cutting boards',
        type: 'child',
        completed: 1,
      },
    ],
  },
];

export const stationFilter = {
  key: 1,
  name: 'Poached Shrimp Cocktail',
  ingredients: 3,
  children: [
    {
      key: 11,
      name: 'Ingredient',
      quantity: 1,
      measurement: 'Measurement',
      preparation: 'Preparation',
      healthCheck: 1,
      completed: 1,
      needed: 2,
      day: 'monday',
      assignee: 'Leonard Server',
      date: '11/28/20',
      time: '11:00am',
    },
    {
      key: 12,
      name: 'Ingredient',
      quantity: 1,
      measurement: 'Measurement',
      preparation: 'Preparation',
      healthCheck: 2,
      completed: 2,
      needed: 1,
      day: 'wednesday',
      assignee: 'Leonard Server',
      date: '11/28/20',
      time: '11:00am',
    },
    {
      key: 13,
      name: 'Ingredient',
      quantity: 1,
      measurement: 'Measurement',
      preparation: 'Preparation',
      healthCheck: 2,
      completed: 3,
      needed: 2,
      day: 'tuesday',
      assignee: 'Leonard Server',
      date: '11/28/20',
      time: '11:00am',
    },
  ],
};
