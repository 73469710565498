import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Tag, Switch, Tooltip, notification, Menu, Dropdown, Drawer } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { PlusOutlined } from '@ant-design/icons';
import { searchmeas, create, update, destroy } from '../../services/measurement.service';
import { allLocations } from '../../services/restaurant.service';
import { all } from '../../services/account.service';
import { cloneDeep, find, remove, findIndex, isEmpty } from 'lodash';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import _ from 'lodash';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';
import { CidekicButton } from '../../components/common/common_ui';

const MeasurementsMobile = (props) => {
  const [menuName, setMenuName] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [menulist, setList] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);
  const [socketData, setSocketData] = useState([]);
  const [measUpdate, setMeasUpdate] = useState([]);
  const [measAdd, setMeasAdd] = useState([]);
  const [measDelete, setMeasDelete] = useState('');
  const [account, setAccount] = useState(0);
  const [accountlist, setAccountList] = useState([]);
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/measurement`));

  const location_name = useRef(null);

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';

  const newLocationValue = {
    id: 'add',
    name: '',
    units: '',
    type: '',
    grams: '',
    milliliters: '',
  };

  useEffect(() => {
    getMeas();
    setTimeout(() => {
      setHasUpdated(false);
      setEditing(false);
      setAdding(false);
      setEditId(null);
    }, 2000);
  }, [hasUpdated]);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    const listener = (event) => {
      if (isEditing && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        console.log('ADD!! Enter key was pressed. Run add.');
        event.preventDefault();
        console.log(getEditData);
        updateMeas(event, getEditData);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [isEditing, getEditData]);

  const getMeas = () => {
    let params = {
      from: 0,
      limit: 10000,
      restaurantId: resto.id,
      accountId: accountId,
    };
    searchmeas(params).then((res) => {
      console.log(res);
      if (res) {
        let allMeas = res;
        let measurements = allMeas.map((l) => {
          return {
            id: l.id,
            name: l.name,
            restaurantId: l.restaurantId,
            type: l.info && l.info?.type ? l.info?.type : '',
            units: l.info && l.info?.units ? l.info?.units : '',
            grams: l.info && l.info?.g_equiv ? l.info?.g_equiv : '',
            milliliters: l.info && l.info?.ml_equiv ? l.info?.ml_equiv : '',
            isMaster: l.info && l.info?.createdBy === 'system_ingest',
            accountId: l.accountId,
          };
        });

        console.log(measurements);
        measurements.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setData(measurements);
        setOrigData(measurements);
        setSocketData(measurements);
      }
    });
  };

  const getLocations = () => {
    allLocations(0, 1000).then((res) => {
      console.log(res);
      let allLoc = res;
      let address1 = allLoc.filter((a) => a.deletedAt == '');
      let address = address1.map((l) => {
        return {
          id: l.id,
          name: l.name,
          address: l.info && l.info?.address ? l.info?.address : '',
          timezone: l.info && l.info?.timezone ? l.info?.timezone : '',
        };
      });

      console.log(address);
      setList(address);
    });
  };

  // const debouncedApiCall = useCallback(
  //   _.debounce((e) => {
  //     filterLocations(e);
  //   }, 200),
  //   []
  // );

  useEffect(() => {
    filterMeas(nameFilter);
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All restaurants');
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All restaurants</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>
                {m?.name} - {m?.address}
              </a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterMeas = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getMeas();
    }
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name + '-' + ' ' + menu.address;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.restaurantId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deleteMeas = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: 'Unable to delete a master list item.',
      // });
      return;
    }
    e.preventDefault();
    let id = selected?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Measurement Successfully Deleted',
      // });
      setHasUpdated(true);
    });
  };

  const editMeas = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    e.preventDefault();
    console.log(obj);
    setEditing(true);
    setEditId(obj.id);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editMeas(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteMeas(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updateMeas = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    console.log(obj);
    let rename = location_name.current.value.trim();
    let params = {
      restaurantId: resto.id,
      name: rename,
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    if (getEditData !== null) {
      let info = {
        type: obj.type,
        units: obj.units,
        g_equiv: obj.grams,
        ml_equiv: obj.milliliters,
      };
      params.info = info;
    }
    console.log(params);
    update(editId, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated measurement!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'updatesMeas',
        JSON.stringify({
          accountId: obj.accountId,
          grams: obj.grams,
          id: obj.id,
          milliliters: obj.restaurantId,
          name: rename,
          restaurantId: obj.restaurantId,
          type: obj.type,
          units: obj.units,
        })
      );
    });
  };

  const updateMeas2 = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    let rename = location_name.current.value.trim();
    let params = {
      restaurantId: resto.id,
      name: rename,
      info: {
        type: obj.type !== '' ? obj.type : '',
        units: obj.units !== '' ? obj.units : '',
        g_equiv: obj.grams !== '' ? obj.grams : '',
        ml_equiv: obj.milliliters !== '' ? obj.milliliters : '',
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated measurement!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'updatesMeas',
        JSON.stringify({
          accountId: obj.accountId,
          grams: obj.grams,
          id: obj.id,
          milliliters: obj.restaurantId,
          name: rename,
          restaurantId: obj.restaurantId,
          type: obj.type,
          units: obj.units,
        })
      );
    });
  };

  const addMeas = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      restaurantId: resto.id,
      name: rename !== '' ? rename : 'Untitled',
      info: {
        type: obj.type !== '' ? obj.type : '',
        units: obj.units !== '' ? obj.units : '',
        g_equiv: obj.grams !== '' ? obj.grams : '',
        ml_equiv: obj.milliliters !== '' ? obj.milliliters : '',
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created measurement!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'addsMeas',
        JSON.stringify({
          accountId: res?.accountId,
          grams: res?.grams,
          id: res?.id,
          milliliters: res?.restaurantId,
          name: res?.name,
          restaurantId: res?.restaurantId,
          type: res?.type,
          units: res?.units,
        })
      );
    });
  };

  const addMeas2 = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      restaurantId: resto.id,
      name: rename !== '' ? rename : 'Untitled',
      info: {
        type: obj.type !== '' ? obj.type : '',
        units: obj.units !== '' ? obj.units : '',
        g_equiv: obj.grams !== '' ? obj.grams : '',
        ml_equiv: obj.milliliters !== '' ? obj.milliliters : '',
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created measurement!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'addsMeas',
        JSON.stringify({
          accountId: res?.accountId,
          grams: res?.grams,
          id: res?.id,
          milliliters: res?.restaurantId,
          name: res?.name,
          restaurantId: res?.restaurantId,
          type: res?.type,
          units: res?.units,
        })
      );
    });
  };

  const handleUnitChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].units = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleTypeChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].type = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleGramsChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].grams = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleMLChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].milliliters = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setAccountList(allAccounts);
      }
    });
  };

  const handleAccountChange = (value, obj) => {
    console.log(value, obj);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].accountId = value;
    setAccount(value);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div>
            <div>
              <RoleAccessibleComponent
                permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
              >
                {!obj?.isMaster && (
                  <Dropdown overlay={controlItems(obj)}>
                    <div style={{ cursor: 'pointer', float: 'right' }}>
                      <img
                        src={IconsMoreOff}
                        className="UI-IconsMoreOff"
                        alt="IconsMoreOff"
                        onClick={() => setSelected(obj)}
                      />
                    </div>
                  </Dropdown>
                )}
              </RoleAccessibleComponent>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '-6px' }}>Title</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  // onKeyPress={(e) => {
                  //   if (e.key === 'Enter') {
                  //     updateMeas(e, obj);
                  //   }
                  // }}
                  // onBlur={(e) => updateMeas2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className={isAdding && obj.id == 'add' ? '' : 'loc_nameMob'}>{obj.name}</div>
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  // onKeyPress={(e) => {
                  //   if (e.key === 'Enter') {
                  //     addMeas(e, obj);
                  //   }
                  // }}
                  // onBlur={(e) => addMeas2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Unit</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 50 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a unit"
                  defaultValue={obj.units}
                  onChange={(e) => handleUnitChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a unit"
                  defaultValue={obj.units}
                  onChange={(e) => handleUnitChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 55 }}>{obj.units}</div>}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Type</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 50 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a type"
                  defaultValue={obj.type}
                  onChange={(e) => handleTypeChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 50 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a type"
                  defaultValue={obj.type}
                  onChange={(e) => handleTypeChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 50 }}>{obj.type}</div>}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Grams</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 40 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter grams equivalent"
                  defaultValue={obj.grams}
                  onChange={(e) => handleGramsChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 40 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter grams equivalent"
                  defaultValue={obj.grams}
                  onChange={(e) => handleGramsChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 40 }}>{obj.grams}</div>}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Milliliters</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 27 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter ml equivalent"
                  defaultValue={obj.milliliters}
                  onChange={(e) => handleMLChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 27 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter ml equivalent"
                  defaultValue={obj.milliliters}
                  onChange={(e) => handleMLChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 27 }}>{obj.milliliters}</div>}
          </div>
          <div>
            {!obj.isMaster && !isAdding && !isEditing && (
              <div style={{ display: 'flex', marginTop: 15 }}>
                <div style={{ marginLeft: '-6px', marginRight: 30 }}>Account</div>
                {accountlist && accountlist.length > 0 && accountlist.find((m) => m.id == accountId).name}
              </div>
            )}
            {isAdding && obj.id == 'add' && (
              <span>
                <div style={{ display: 'flex', marginTop: 20 }}>
                  <div style={{ marginLeft: '-6px', marginRight: 30 }}>Account</div>
                  {accountlist && accountlist.length > 0 && accountlist.find((m) => m.id == accountId).name}
                </div>
              </span>
            )}
            {isEditing && editId == obj.id && (
              <span>
                <div style={{ display: 'flex', marginTop: 15 }}>
                  <div style={{ marginLeft: '-6px', marginRight: 30 }}>Account</div>
                  {accountlist && accountlist.length > 0 && accountlist.find((m) => m.id == accountId).name}
                </div>
              </span>
            )}
          </div>
          {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
            <div style={{ display: 'flex', marginTop: 15 }}>
              <div style={{ marginLeft: '-6px', marginRight: 57 }}>
                <div style={{ marginLeft: 83 }}>
                  <div className="common-controls-container">
                    <CidekicButton
                      className="flex-control-right button-table-row button-highlight"
                      onClick={isEditing ? (e) => updateMeas(e, obj) : (e) => addMeas(e, obj)}
                    >
                      Save
                    </CidekicButton>
                    <CidekicButton className="flex-control button-table-row" onClick={() => cancelEdit()}>
                      Cancel
                    </CidekicButton>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </span>
      ),
    },
  ];

  useEffect(() => {
    eventSocket.on('updatedMeas', (message) => {
      let newData = JSON.parse(message);
      console.log('MEASUPDATED!!', [newData]);
      setMeasUpdate([newData]);
    });
    eventSocket.on('addedMeas', (message) => {
      let newData = JSON.parse(message);
      console.log('MEASADDED!!', newData);
      setMeasAdd(newData);
    });
    eventSocket.on('deletedMeas', (message) => {
      let newData = JSON.parse(message);
      console.log('MEASDELETED!!', newData.id);
      setMeasDelete(newData.id);
    });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    setData((oldArray) => [...oldArray, measAdd]);
    setOrigData((oldArray) => [...oldArray, measAdd]);
    setSocketData((oldArray) => [...oldArray, measAdd]);
  }, [measAdd]);

  useEffect(() => {
    console.log(socketData);
    console.log(measUpdate);
    let updated = socketData.map((obj) => measUpdate.find((o) => o.id.toString() === obj.id.toString()) || obj);
    console.log(updated);
    setData(updated);
    setOrigData(updated);
  }, [measUpdate]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.filter((obj) => obj.id.toString() !== measDelete.toString());
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
  }, [measDelete]);

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Measurements"
        restaurant="Admin"
        showSearch={showSearch}
        onChange={(evt) => setNameFilter(evt.target.value)}
        clickSearch={() => setShowSearch(true)}
      />
      <div style={{ display: 'flex' }}>
        <div className="add-cat30Mob">
          <div>{menuName ? menuName : 'Restaurant name'}</div>
        </div>
        <Dropdown overlay={menuList}>
          <div className="dropdown-icon">
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </Dropdown>
        <div className="spacer-cat"></div>
        <RoleAccessibleComponent
          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
        >
          <div
            className="add-cat13Mob"
            onClick={() => {
              //setIsAdding(true);
            }}
          >
            <div
              style={{ cursor: 'pointer', fontFamily: 'Helvetica Neue' }}
              onClick={() => {
                setAdding(true);
                const clonedDataSource = cloneDeep(origData);
                clonedDataSource.unshift(newLocationValue);
                setData(clonedDataSource);
                window.scrollTo(0, 0);
              }}
            >
              + <span style={{ marginLeft: 5 }}>Add measurement</span>
            </div>
          </div>
        </RoleAccessibleComponent>
      </div>
      <div style={{ padding: '30px 20px 0px 15px' }}>
        <Table
          dataSource={userData}
          columns={columns}
          pagination={false}
          className={'show-custom-empty'}
          locale={{ emptyText: <Empty image={empty} description="No measurements" /> }}
        />
      </div>{' '}
      <RoleAccessibleComponent
        permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
      >
        <div className="add-checklistMeas1">
          <div
            className="add-checklist-but"
            onClick={() => {
              setAdding(true);
              const clonedDataSource = cloneDeep(origData);
              clonedDataSource.push(newLocationValue);
              setData(clonedDataSource);
            }}
          >
            <span>
              <PlusOutlined />
            </span>{' '}
            Add measurement
          </div>
        </div>
      </RoleAccessibleComponent>
    </div>
  );
};

export default MeasurementsMobile;
