import React, { useEffect, useState } from 'react';
import './index.css';
import UserMenu from '../UserMenu/UserMenu.js';
import 'antd/dist/antd.css';
import { useHistory } from 'react-router-dom';
import { unimpersonate } from '../../services/operator.service';
import { SearchSpyglass, BackButton } from '../../components/common/common_ui';

const Header = (props) => {
  const history = useHistory();
  const [getUrl, setGetUrl] = useState('');
  const [restaurant, setRestaurant] = useState('');

  let impersonating = JSON.parse(localStorage.getItem('impersonate')) || {};

  useEffect(() => {
    return history.listen((location) => {
      setGetUrl(location.pathname);
    });
  }, [history]);

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    setRestaurant(resto.name);
    setGetUrl(window.location.href);
  }, []);

  useEffect(() => {
    let url = window.location.href;
    if (url.includes('impersonate')) {
      let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
      setRestaurant(resto.name);
    }
  }, []);

  const unimpersonateUser = () => {
    let changeOperator = localStorage.getItem('operator2');
    let changeResto = localStorage.getItem('restaurant2');
    let changeRestoId = localStorage.getItem('restaurantId2');
    localStorage.setItem('restaurant', changeResto);
    localStorage.setItem('restaurantId', changeRestoId);
    localStorage.setItem('operator', changeOperator);
    unimpersonate(impersonating.id).then((res) => {
      console.log(res);
      localStorage.removeItem('impersonate');
      localStorage.removeItem('operator2');
      localStorage.removeItem('restaurant2');
      localStorage.removeItem('restaurantId2');
      history.push({
        pathname: '/super/accounts',
        state: 'unimpersonate',
        search: '?unimpersonate=unimpersonate',
      });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    });
  };

  return (
    <div>
      {impersonating && Object.keys(impersonating).length !== 0 && (
        <div className="impersonate-user">
          You are impersonating {impersonating.name}{' '}
          <span className="exit-impersonate" onClick={unimpersonateUser}>
            Exit view
          </span>
        </div>
      )}
      {getUrl.includes('public') ? null : getUrl.includes('admin') ? (
        <div className="header">
          <div className="header-content">
            <div className="common-restaurant-title">Admin</div>
            <div className="header-controls">
              <UserMenu />
            </div>
          </div>
          <div className="Line"></div>
        </div>
      ) : getUrl.includes('super') ? (
        <div className="header">
          <div className="header-content">
            <div className="common-restaurant-title">Super Admin</div>
            <div className="header-controls">
              <UserMenu />
            </div>
          </div>
          <div className="Line"></div>
        </div>
      ) : (
        <div className="common-header">
          <div className="header-content">
            <div className="common-restaurant-title">{restaurant}</div>
            <div className="header-controls">
              <UserMenu />
            </div>
          </div>
          <div className="Line"></div>
        </div>
      )}
    </div>
  );
};

export const SubHeader = (props) => {
  return (
    <React.Fragment>
      <div className="common-header-title">{props.title}</div>
      {props.showSearch ? (
        <div className="common-search-container">
          <input
            type="text"
            className="common-search-bar"
            placeholder={props.placeholder ? props.placeholder : 'Search ' + props.title}
            onChange={props.onChange}
            onFocus={props.onFocus}
          />
          <span className="common-search-icon">
            <SearchSpyglass />
          </span>
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

SubHeader.defaultProps = {
  showSearch: true,
};

export const NewSubHeader = (props) => {
  return (
    <div className="common-subheader">
      <div className="d-flex flex-row">
        <div className="common-header-title mr-2">{props.title}</div>
        {props.children}
      </div>
      {props.showSearch ? (
        <div className="common-search-container">
          <input
            type="text"
            className="common-search-bar"
            placeholder={props.placeholder ? props.placeholder : 'Search ' + props.title}
            onChange={props.onChange}
            onFocus={props.onFocus}
          />
          <span className="common-search-icon">
            <SearchSpyglass />
          </span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

NewSubHeader.defaultProps = {
  showSearch: true,
};

export const BacktoHeader = (props) => {
  return (
    <React.Fragment>
      <BackButton backLink={props.backLink} backText={props.backText} alt={props.alt} onClick={props.onClick} />
      {props.showSearch ? (
        <div className="common-search-container">
          <input
            type="text"
            className="common-search-bar"
            placeholder={props.placeholder ? props.placeholder : 'Enter search term'}
            onChange={props.onChange}
          />
          <span className="common-search-icon">
            <SearchSpyglass />
          </span>
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

BacktoHeader.defaultProps = {
  showSearch: true,
};

export default Header;
