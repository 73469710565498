/* eslint eqeqeq: "off" */
import * as React from 'react';
import moment from 'moment';

export const navigateContants = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
};

export const views = {
  MONTH: 'month',
  WEEK: 'week',
  WORK_WEEK: 'work_week',
  DAY: 'day',
  AGENDA: 'agenda',
};

const CustomToolbar = (props) => {
  //let userRole = (JSON.parse(localStorage.getItem('operator')) || {}).role;
  function navigate(action) {
    let setDate;
    if (action === 'PREV') {
      setDate = moment(props.date).clone().startOf('month').subtract(1, 'months').format('YYYY-MM-DD hh:mm');
    } else if (action === 'NEXT') {
      setDate = moment(props.date).clone().startOf('month').add(1, 'months').format('YYYY-MM-DD hh:mm');
    } else if (action == 'TODAY') {
      setDate = moment().clone().startOf('month').format('YYYY-MM-DD hh:mm');
    } else {
    }

    props.setSelectedDate(setDate);
    props.onNavigate(action);
  }

  function viewItem(view) {
    props.onView(view);
  }

  return (
    <div>
      <div className="toolbar-box">
        <div className="dash-button first-btn" onClick={navigate.bind(null, navigateContants.PREVIOUS)}>
          <span className="Back-Chevron">&#60;</span>
        </div>
        <div className="dash-button date-btn" onClick={viewItem.bind(null, views.MONTH)}>
          {props.label}
        </div>
        <div className="dash-button last-btn" onClick={navigate.bind(null, navigateContants.NEXT)}>
          <span className="Back-Chevron">&#62;</span>
        </div>
        <div id="BigCalendar-nav-today" className="today-toolbar" onClick={navigate.bind(null, navigateContants.TODAY)}>
          Today
        </div>
      </div>
    </div>
  );
};

export default CustomToolbar;
