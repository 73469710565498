import React from 'react';
import './index.css';

const Legal = () => {
  return (
    <div className="legal-parent">
      <div>Hello, this is the Legal page, your destination for all things Legal.</div>
      <br />
      <div>Thank you, from the Legal Page Department</div>
    </div>
  );
};

export default Legal;
