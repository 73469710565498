import React from 'react';
import { Form } from 'antd';
import { requestResetPassword } from '../../services/operator.service';
import logo from '../../img/cidekic_logo.svg';
import value from '../../img/value-ic.png';
import benefit from '../../img/benefit-ic.png';
import market from '../../img/market-ic.png';
import copyright from '../../img/copyright.png';
import './resetPassword.css';
import { Link } from 'react-router-dom';
import MailTo from '../../components/MailTo/MailTo';

let passwordReset = false;

const ResetPassword = (props) => {
  const {
    form: { getFieldDecorator, getFieldsError },
  } = props;

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    props.form.validateFields((err, { email }) => {
      if (!err && !passwordReset) {
        requestResetPassword(email).then(
          (res) => {
            openNotification('success', email);
            passwordReset = true;
            setTimeout(() => {
              props.history.goBack();
            }, 4000);
          },
          (err) => {
            openNotification('error', err);
          }
        );
      }
    });
  };

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  const openNotification = (type, mssg) => {
    if (type === 'success') {
      // notification.open({
      //   message: 'Password Successfully Reset',
      //   description: `An email has been sent to ${mssg} with reset instructions.`,
      // });
    } else {
      // notification.open({
      //   message: 'Error Resetting Password',
      //   description: mssg,
      // });
    }
  };

  return (
    // <div className="login-container flex">
    //   <div className="login-form-container">
    //     <div className="login-box">
    //       <div className="reset-password-form-container">
    //         <div className="reset-header">Enter your email to receive password reset instructions</div>
    //         <Form onSubmit={handlePasswordReset} layout="vertical">
    //           <div className="login-labels">
    //             <div className="reset-label">Email</div>
    //             <Form.Item>
    //               {getFieldDecorator('email', {
    //                 rules: [
    //                   { required: true, message: 'Please enter your email!' },
    //                   { type: 'email', message: 'Must be valid email!' },
    //                 ],
    //               })(<input  className='login-user-input' label="Email" placeholder="Enter a valid email address" />)}
    //             </Form.Item>
    //           </div>
    //           <div className="reset-btn">
    //             <Form.Item>
    //               <button className="login-btn-recipe" type="submit" disabled={hasErrors(getFieldsError())}>
    //                 Reset Password
    //               </button>
    //             </Form.Item>
    //           </div>
    //         </Form>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div>
      <div className="login-container">
        <div className="form-content">
          <div style={{ textAlign: 'center' }}>
            <img className="cidekic-logo-login" src={logo} alt="logo" />
          </div>
          <div className="reset-password-form-container">
            <div className="reset-header">Forgot password</div>
            <Form onSubmit={handlePasswordReset} layout="vertical">
              <div className="login-labels">
                <div className="reset-label">Enter username or email address</div>
                <Form.Item>
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: 'Please enter your email!' },
                      { type: 'email', message: 'Must be valid email!' },
                    ],
                  })(
                    <input
                      className="login-user-input"
                      label="Email"
                      placeholder="Enter username or a valid email address"
                    />
                  )}
                </Form.Item>
              </div>
              <div className="reset-btn">
                <Form.Item>
                  <button className="login-btn-recipe" type="submit" disabled={hasErrors(getFieldsError())}>
                    Reset Password
                  </button>
                </Form.Item>
              </div>
              <div className="signup-msg1">
                or
                <Link to={'/public/login'}>
                  <span className="signup-msg2">login</span>
                </Link>
                {/* <span className="signup-msg3">register a new account</span> */}
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div>
        <div className="wrapper-login">
          <header className="header-login">
            <h2 className="login-title">What is Cide Kic</h2>
          </header>

          <section className="columns-login">
            <div className="column-login1">
              <img src={value} className="login-ic" alt="value" />
              <h4 className="login-caps">THE VALUE</h4>
              <p>Automate and streamline all aspects of professional kitchen operations</p>
            </div>

            <div className="column-login1">
              <img src={benefit} className="login-ic" alt="benefit" />
              <h4 className="login-caps">THE BENEFIT</h4>
              <p>Gain insights in real-time to efficiently manage kitchen workflow</p>
            </div>

            <div className="column-login1">
              <img src={market} className="login-ic" alt="market" />
              <h4 className="login-caps">THE MARKET</h4>
              <p>Hotels, resorts, private clubs, restaurants and so many more!</p>
            </div>
          </section>

          <footer>
            <p>
              <span>
                <img src={copyright} className="copyrt-ic" alt="copyright" />
              </span>{' '}
              2021 Cide Kic All rights reserved
            </p>
            <div className="login-contactus">
              <MailTo email="info@cidekic.com" subject="Customer Access Request">
                Contact Us
              </MailTo>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Form.create({ name: 'resetPassword' })(ResetPassword);
