/* eslint eqeqeq: "off" */
const Fractionals = [
  { value: 0.25, symbol: 'Â¼' },
  { value: 0.3333, symbol: 'â…“' },
  { value: 0.5, symbol: 'Â½' },
  { value: 0.6667, symbol: 'â…”' },
  { value: 0.75, symbol: 'Â¾' },
];

export const arrangeDBMeasurementUnits = (data) => {
  return data
    .map((unit) => {
      let toreturn = {
        id: unit.id * 1,
        unitId: unit.id * 1,
        name: unit.name,
      };

      if (unit.info?.type && (unit.info.type !== null || unit.info.type.length > 0)) {
        toreturn.type = unit.info.type;
      }
      if (unit.info?.g_equiv && (unit.info?.g_equiv !== null || unit.info.g_equiv.length > 0)) {
        toreturn.equiv = parseFloat(unit.info.g_equiv);
      } else if (unit.info?.ml_equiv && (unit.info?.ml_equiv != null || unit.info.ml_equiv.length > 0)) {
        toreturn.equiv = parseFloat(unit.info.ml_equiv);
      }
      if (unit.info?.units && (unit.info?.units !== null || unit.info.units.length > 0)) {
        toreturn.units = unit.info.units;
      }
      return toreturn;
    })
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((u, i) => {
      u.key = i + 1;
      return u;
    });
};

export const ridAnnoyingZero = (x) => {
  if (x === 0 || !x) {
    return '';
  }
  return x.toString();
};

export const formatIfNeeded = (x) => {
  if (isNaN(x)) {
    return fixTheFormat(x);
  }
  if (x % 1 != 0) {
    const wholePart = Math.floor(x);
    const fracPart = Math.round((x - wholePart + Number.EPSILON) * 100) / 100;
    const equiv_amt = Fractionals.find((f) => f.value === findClosestValue(fracPart)).symbol;
    const fixedFormat = fixTheFormat(equiv_amt);
    return `${wholePart ? wholePart.toString() : ''}${fixedFormat}`;
  } else {
    return x;
  }
};

// rounds off to two decimal places max
export const roundOff = (x) => {
  if (isNaN(x)) {
    return;
  }
  return Math.round((parseFloat(x) + Number.EPSILON) * 100) / 100;
};

const findClosestValue = (val) => {
  let fractionVals = Fractionals.map((f) => f.value);
  let closestFraction = fractionVals.reduce((prev, curr) => {
    return Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev;
  });
  return closestFraction;
};

export const fixTheFormat = (y) => {
  console.log('the fraction to format:', y);
  if (y) {
    return y.includes('Â¼')
      ? y.replace(/Â¼/g, '¼')
      : y.includes('Â½')
      ? y.replace(/Â½/g, '½')
      : y.includes('Â¾')
      ? y.replace(/Â¾/g, '¾')
      : y.includes('â…”')
      ? y.replace(/â…”/g, '⅔')
      : y.includes('â…“')
      ? y.replace(/â…“/g, '⅓')
      : y;
  } else {
    return '';
  }
};
// these utility functions are for temporarily converting stored fractions in database into strings in float format
// to help purposely clean all data in "Recipes" table for better formatting when fetched to frontend
export const convertFromFormat = (x) => {
  let hasFormatting = false,
    theFormat = '';
  for (let k = 0; k < Fractionals.length; k++) {
    let theFractional = Fractionals[k];
    if (x.includes(Fractionals[k].symbol)) {
      hasFormatting = true;
      theFormat = theFractional.value;
      break;
    }
  }
  if (hasFormatting) {
    const wholeNumber = parseFloat(x.substring(0, x.length - 1));
    return wholeNumber + theFormat;
  } else {
    return parseFloat(x);
  }
};
