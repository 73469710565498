/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useRef } from 'react';
import { Table, Menu, Dropdown, Select } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { PlusOutlined } from '@ant-design/icons';
//import { Link } from 'react-router-dom';
import { all } from '../../services/account.service';
import { allLocations, destroy, update, create } from '../../services/restaurant.service';
import { cloneDeep, findIndex } from 'lodash';
//import _ from 'lodash';
import { timeZones } from '../../data/timezones';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';

const SuperLocationsMobile = (props) => {
  const [menuName, setMenuName] = useState('');
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [menulist, setList] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);
  const [account, setAccount] = useState(0);
  const [editObj, setEditObj] = useState({});
  const [showSearch, setShowSearch] = useState(false);
  const [locName, setLocName] = useState('');
  const [editedId, setEditedId] = useState(false);

  const location_name = useRef(null);

  const { Option } = Select;

  const newLocationValue = {
    id: 'add',
    name: '',
    address: '',
    timezone: timeZones[574],
  };

  //let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  //let accountId = JSON.parse(localStorage.getItem('operator')).accountId || '';

  useEffect(() => {
    setHasUpdated(false);
    setEditing(false);
    setAdding(false);
    setEditId(null);
    setEditObj(null);
    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdated]);

  useEffect(() => {
    const listener = (event) => {
      if (isEditing && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        console.log('ADD!! Enter key was pressed. Run add.');
        event.preventDefault();
        console.log(getEditData);
        updateLocation(event, getEditData);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, getEditData]);

  const getLocations = () => {
    allLocations(0, 1000).then((res) => {
      console.log(res);
      let allLoc = res;
      let address1 = allLoc.filter((a) => a.deletedAt == '');
      let address = address1.map((l) => {
        return {
          id: l.id,
          name: l.name,
          address: l.info && l.info?.address ? l.info?.address : '',
          timezone: l.info && l.info?.timezone ? l.info?.timezone : '',
          accountId: l.accountId,
        };
      });

      address.sort((x, y) => {
        return y.id - x.id;
      });
      address.forEach((item, i) => {
        if (item.id === 99999) {
          address.splice(i, 1);
          address.unshift(item);
        }
      });

      console.log(address);

      getAccounts(address);
    });
  };

  const getAccounts = (usrData) => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setList(allAccounts);

        const getIds = allAccounts.map((m) => parseInt(m.id)).filter((f) => f !== null);

        const result = usrData.map((o) =>
          getIds.includes(o.accountId)
            ? Object.assign({}, o, {
                accountName: allAccounts.find((m) => parseInt(m.id) == o.accountId).name,
              })
            : o
        );

        if (editedId) {
          setEditedId(false);
          let filtered = result.filter((r) => r.name == locName);
          result.forEach((v, i) => {
            if (v.id == filtered[0].id) {
              result.unshift(result[i]);
              result.splice(i, 2);
              window.scrollTo(0, 0);
            }
          });
          editModeLocation(filtered[0]);
        }

        setData(result);
        setOrigData(result);
      }
    });
  };

  // const debouncedApiCall = useCallback(
  //   _.debounce((e) => {
  //     filterLocations(e);
  //   }, 200),
  //   []
  // );

  useEffect(() => {
    filterLocations(nameFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All accounts');
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <span>All accounts</span>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <span>{m?.name}</span>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterLocations = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getLocations();
    }
  };

  const filteringMenus = (menu) => {
    console.log(menu.id);
    console.log(origData);
    let menuName = menu.name;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.accountId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deleteLocation = (e, obj) => {
    e.preventDefault();
    let id = obj?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Location Successfully Deleted',
      // });
      setHasUpdated(true);
    });
  };

  const editLocation = (e, obj) => {
    e.preventDefault();
    console.log(obj);
    setEditing(true);
    setEditId(obj.id);
    setEditObj(obj);
  };

  const editModeLocation = (obj) => {
    console.log('EDITMODE', obj);
    setEditing(true);
    setEditId(obj.id);
    setEditObj(obj);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editLocation(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteLocation(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updateLocation = (e, obj) => {
    let rename = location_name.current.value.trim();
    let selectedObj = {};
    if (obj == null) {
      selectedObj = editObj;
    } else {
      selectedObj = obj;
    }
    let params = {
      name: rename,
      accountId: selectedObj.accountId,
      info: {
        address: selectedObj.address,
        timezone: selectedObj.timezone,
      },
    };
    console.log(params);
    update(editId, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated location!',
      // });
      setHasUpdated(true);
    });
  };

  const updateLocation2 = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename,
      accountId: obj.accountId,
      info: {
        address: obj.address,
        timezone: obj.timezone,
      },
    };
    console.log(params);
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated location!',
      // });
      setHasUpdated(true);
    });
  };

  const addLocation = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename !== '' ? rename : 'Untitled',
      accountId: account,
      info: {
        address: obj.address !== '' ? obj.address : '',
        timezone: obj.timezone !== '' ? obj.timezone : '',
      },
    };
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created location!',
      // });
      setHasUpdated(true);
    });
  };

  const addLocation2 = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename !== '' ? rename : 'Untitled',
      accountId: account,
      info: {
        address: obj.address !== '' ? obj.address : '',
        timezone: obj.timezone !== '' ? obj.timezone : '',
      },
    };
    console.log(params);
    create(params).then((res) => {
      setLocName(params.name);
      setEditedId(true);
      // notification.open({
      //   message: 'Successfully created location!',
      // });
      setHasUpdated(true);
    });
  };

  const handleAddressChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].address = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleTimeChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].timezone = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleAccountChange = (value, obj) => {
    console.log(value, obj);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].accountId = value;
    setAccount(value);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div>
            <div>
              <Dropdown overlay={controlItems(obj)}>
                <div style={{ cursor: 'pointer', float: 'right' }}>
                  <img
                    src={IconsMoreOff}
                    className="UI-IconsMoreOff"
                    alt="IconsMoreOff"
                    onClick={() => setSelected(obj)}
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '-6px' }}>Title</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      updateLocation(e, obj);
                    }
                  }}
                  onBlur={(e) => updateLocation2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className={isAdding && obj.id == 'add' ? '' : 'loc_nameMob'}>{obj.name}</div>
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addLocation(e, obj);
                    }
                  }}
                  onBlur={(e) => addLocation2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Address</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 30 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter an address"
                  defaultValue={obj.address}
                  onChange={(e) => handleAddressChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 30 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter an address"
                  defaultValue={obj.address}
                  onChange={(e) => handleAddressChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 30 }}>{obj.address}</div>}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Timezone</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 20 }}>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a timezone"
                  defaultValue={obj.timezone}
                  onChange={(e) => handleTimeChange(e.target.value, obj)}
                /> */}
                <Select
                  className="station-selectEv10"
                  onChange={(e) => handleTimeChange(e, obj)}
                  value={obj.timezone}
                  showArrow={false}
                  showSearch
                >
                  {timeZones.map((zone) => (
                    <Option value={zone}>{zone}</Option>
                  ))}
                </Select>
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 20 }}>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a timezone"
                  defaultValue={obj.timezone}
                  onChange={(e) => handleTimeChange(e.target.value, obj)}
                /> */}
                <Select
                  className="station-selectEv10"
                  onChange={(e) => handleTimeChange(e, obj)}
                  value={obj.timezone}
                  showArrow={false}
                  showSearch
                >
                  {timeZones.map((zone) => (
                    <Option value={zone}>{zone}</Option>
                  ))}
                </Select>
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 25 }}>{obj.timezone}</div>}
          </div>
          <div>
            {isAdding && obj.id == 'add' && (
              <span>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: '-6px', marginRight: 25, marginTop: 10 }}>Account</div>
                  <select
                    style={{ marginBotttom: 15, marginTop: 10 }}
                    className="admin-select"
                    onChange={(e) => handleAccountChange(e.target.value, obj)}
                    defaultValue={obj.accountId}
                  >
                    <option initialvalue="default">Select</option>
                    {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
                  </select>
                </div>
              </span>
            )}
            {isEditing && editId == obj.id && (
              <span>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: '-6px', marginRight: 25, marginTop: 10 }}>Account</div>
                  <select
                    className="admin-select"
                    style={{ marginTop: 10 }}
                    defaultValue={obj.accountId}
                    onChange={(e) => handleAccountChange(e.target.value, obj)}
                  >
                    <option initialvalue="default">Select</option>
                    {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
                  </select>
                </div>
              </span>
            )}
            <div style={{ display: 'flex', marginTop: 15 }}>
              <div style={{ marginLeft: '-6px', marginRight: 35 }}>Account</div>
              {!isEditing && !isAdding && <span>{obj.accountName}</span>}
            </div>
          </div>
        </span>
      ),
    },
  ];

  const columns2 = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div>
            <div>
              {isAdding && obj.id == 'add' && (
                <span>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginLeft: '-6px', marginRight: 25, marginTop: 10 }}>Account</div>
                    <select
                      style={{ marginBotttom: 15, marginTop: 10 }}
                      className="admin-select"
                      onChange={(e) => handleAccountChange(e.target.value, obj)}
                    >
                      <option initialvalue="default">Select</option>
                      {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
                    </select>
                  </div>
                </span>
              )}
              {isEditing && editId == obj.id && (
                <span>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginLeft: '-6px', marginRight: 25, marginTop: 10 }}>Account</div>
                    <select
                      style={{ marginTop: 10 }}
                      className="admin-select"
                      defaultValue={obj.accountId}
                      onChange={(e) => handleAccountChange(e.target.value, obj)}
                    >
                      <option initialvalue="default">Select</option>
                      {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
                    </select>
                  </div>
                </span>
              )}
            </div>
            <div>
              <Dropdown overlay={controlItems(obj)}>
                <div style={{ cursor: 'pointer', float: 'right' }}>
                  <img
                    src={IconsMoreOff}
                    className="UI-IconsMoreOff"
                    alt="IconsMoreOff"
                    onClick={() => setSelected(obj)}
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '-6px' }}>Title</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      updateLocation(e, obj);
                    }
                  }}
                  onBlur={(e) => updateLocation2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className={isAdding && obj.id == 'add' ? '' : 'loc_nameMob'}>{obj.name}</div>
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addLocation(e, obj);
                    }
                  }}
                  onBlur={(e) => addLocation2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Address</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 30 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter an address"
                  defaultValue={obj.address}
                  onChange={(e) => handleAddressChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 30 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter an address"
                  defaultValue={obj.address}
                  onChange={(e) => handleAddressChange(e.target.value, obj)}
                />
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 30 }}>{obj.address}</div>}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Timezone</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 20 }}>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a timezone"
                  defaultValue={obj.timezone}
                  onChange={(e) => handleTimeChange(e.target.value, obj)}
                /> */}
                <Select
                  className="station-selectEv10"
                  onChange={(e) => handleTimeChange(e, obj)}
                  value={obj.timezone}
                  showArrow={false}
                  showSearch
                >
                  {timeZones.map((zone) => (
                    <Option value={zone}>{zone}</Option>
                  ))}
                </Select>
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 20 }}>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a timezone"
                  defaultValue={obj.timezone}
                  onChange={(e) => handleTimeChange(e.target.value, obj)}
                /> */}
                <Select
                  className="station-selectEv10"
                  onChange={(e) => handleTimeChange(e, obj)}
                  value={obj.timezone}
                  showArrow={false}
                  showSearch
                >
                  {timeZones.map((zone) => (
                    <Option value={zone}>{zone}</Option>
                  ))}
                </Select>
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 25 }}>{obj.timezone}</div>}
          </div>
        </span>
      ),
    },
  ];

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Locations"
        restaurant="Super Admin"
        showSearch={showSearch}
        onChange={(evt) => setNameFilter(evt.target.value)}
        clickSearch={() => setShowSearch(true)}
      />
      <div style={{ display: 'flex' }}>
        <div className="add-cat30Mob">
          <div>{menuName ? menuName : 'All accounts'}</div>
        </div>
        <Dropdown overlay={menuList}>
          <div className="dropdown-icon">
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </Dropdown>
        <div className="spacer-cat"></div>
        {!isEditing && (
          <div
            className="add-cat13Mob"
            onClick={() => {
              //setIsAdding(true);
            }}
          >
            <div
              style={{ cursor: 'pointer', fontFamily: 'Helvetica Neue' }}
              onClick={() => {
                setAdding(true);
                const clonedDataSource = cloneDeep(origData);
                clonedDataSource.unshift(newLocationValue);
                setData(clonedDataSource);
              }}
            >
              + <span style={{ marginLeft: 5 }}>Add location</span>
            </div>
          </div>
        )}
      </div>
      <div style={{ padding: '30px 20px 0px 15px' }}>
        <Table
          dataSource={userData}
          columns={isAdding ? columns2 : columns}
          pagination={false}
          className={'show-custom-empty'}
          locale={{ emptyText: <Empty image={empty} description="No locations" /> }}
        />
      </div>{' '}
      {!isEditing && (
        <div className="add-checklistMeas1">
          <div
            className="add-checklist-but"
            onClick={() => {
              setAdding(true);
              const clonedDataSource = cloneDeep(origData);
              clonedDataSource.push(newLocationValue);
              setData(clonedDataSource);
            }}
          >
            <span>
              <PlusOutlined />
            </span>{' '}
            Add location
          </div>
        </div>
      )}
    </div>
  );
};

export default SuperLocationsMobile;
