/* eslint eqeqeq: "off" */
import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import { Row, Radio } from 'antd';
import 'antd/dist/antd.css';
import logo from '../../img/cidekic_logo.svg';
import admin from '../../img/admin_ic.png';
import logout from '../../img/logout_ic.png';
import daymode_on from '../../img/daymode-on.png';
import daymode_off from '../../img/daymode-off.png';
import { Switch } from 'antd';
import { slide as BurgerMenu } from 'react-burger-menu';
import SideMenuMobile from '../../components/SideMenu/SideMenuMobile.js';
import { saveSettings, loadSettings } from '../../services/operator.service';
import { UserImage, CompletionIcon } from '../../components/common/common_ui';
import { findIndex, cloneDeep } from 'lodash';
import setDayMode from './UserMenuUtils';

class UserMenuMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideMenuOpen: false,
      profileMenuOpen: false,
      showAlert: false,
      showMsg: false,
      userInfo: {},
      restaurants: [],
      selectedRestaurant: 0,
      isUrlAdmin: false,
      dayMode: false,
    };
  }

  // reassign from browserProfile record to important localStorage keys of restored location (menuOrder, checklistOrder and documentOrder)
  checkKeyExists(key, browserProfile = null, browserRecords = null, profileIndex = null) {
    let cacheValue = null;
    if (browserProfile && Object.keys(browserProfile).includes(key)) {
      try {
        const toParse = JSON.parse(browserProfile[key]);
        if (toParse && typeof toParse === 'object') {
          cacheValue = toParse;
        }
      } catch (e) {
        cacheValue = browserProfile[key];
      }
    } else {
      if (localStorage.getItem(key) != undefined) {
        try {
          const toParse = JSON.parse(localStorage.getItem(key));
          if (toParse && typeof toParse === 'object') {
            cacheValue = toParse;
          }
        } catch (e) {
          cacheValue = localStorage.getItem(key);
        }
      }
    }
    if (cacheValue) {
      if (browserProfile) {
        delete browserProfile[key];
        browserRecords[profileIndex] = browserProfile;
        localStorage.setItem('browserProfiles', JSON.stringify(browserRecords));
      }
      // temporary code to replace old 'menuOrder'/'checklistOrder'/'documentOrder'
      if (
        (
          key === 'menuOrder' ||
          key === 'checklistOrder' ||
          key === 'documentOrder'
        ) && (
          cacheValue.length && 
          cacheValue[0]?.type === 'parent'
        )
      ) {
        return;
      } else {
        if (typeof cacheValue === 'string') {
          localStorage.setItem(key, cacheValue);
        } else {
          localStorage.setItem(key, JSON.stringify(cacheValue));
        }
      }
    }
  }

  componentDidMount() {
    let operatorLoggedIn = JSON.parse(localStorage.getItem('operator')) || {};
    let restos = operatorLoggedIn.restaurants;
    let operatorEmail = operatorLoggedIn.email;

    const currentUserId = localStorage.getItem('currentOperatorId')?.toString()
      ? localStorage.getItem('currentOperatorId').toString()
      : operatorLoggedIn?.id;
    const currentLocation = localStorage.getItem('restaurant')
      ? JSON.parse(localStorage.getItem('restaurant')).name
      : null;

    // important keys to retain and backup (other than 'restaurant' and 'restaurantId')
    const importantKeys = [
      'menuOrder', 'checklistOrder', 'documentOrder', 
      'menuColKeyOrder', 'checklistColKeyOrder', 'documentColKeyOrder', 'menuOpenRowsMobile'];

    // for checking if profile within 'browserProfiles' exists and/or 'browserProfiles' exist in localStorage
    let foundProfile = null;
    let browserRecords = [];

    // if 'browserProfiles' exist, find the matching record of user logging in
    if (localStorage.getItem('browserProfiles') && JSON.parse(localStorage.getItem('browserProfiles')).length > 0) {
      browserRecords = JSON.parse(localStorage.getItem('browserProfiles'));
      foundProfile = findIndex(
        browserRecords,
        (prof) => prof.userId === currentUserId && JSON.parse(prof.restaurant).name === currentLocation
      );
      // if user logging in has existing profile and/or 'browserProfiles' exist
      if (foundProfile !== null && foundProfile >= 0) {
        const browserProfile = browserRecords[foundProfile];
        importantKeys.forEach((key) => this.checkKeyExists(key, browserProfile, browserRecords, foundProfile));
      } else {
        importantKeys.forEach((key) => this.checkKeyExists(key));
      }
    }

    loadSettings(`${operatorEmail}`)
      .then((settings) => {
        console.log('DAY MODE AFTER REFRESH', settings?.dayMode);
        this.setState({
          dayMode: settings?.dayMode ? settings?.dayMode : false,
        });
      })
      .finally(() => {
        if (restos && restos.length) {
          let restoArr = restos.map((r) => {
            return r;
          });
          restoArr.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          this.setState({
            userInfo: JSON.parse(localStorage.getItem('operator')),
            restaurants: restoArr,
          });

          let selectedResto = localStorage.getItem('restaurant') || '';
          let restaurantId = localStorage.getItem('restaurantId') || '';

          this.setState({
            selectedRestaurant: selectedResto ? JSON.parse(selectedResto) : '',
            selectedRestaurantId: restaurantId,
          });
        } else {
          this.setState({
            restaurants: [],
          });
        }

        document.body.addEventListener('click', (e) => {
          let container = document.getElementById('profile-menu');
          if (container == null || document.getElementById('profile-menu-icon')?.contains(e.target)) {
            return;
          }
          if (!container.contains(e.target)) {
            this.setState({ profileMenuOpen: false });
          }
        });

        let currentPage = window.location.href;
        if (currentPage.includes('super') || currentPage.includes('admin')) {
          this.setState({ isUrlAdmin: true });
        }

        // listen for changes
        const timerId = setInterval(() => {
          if (currentPage != window.location.href) {
            currentPage = window.location.href;

            if (currentPage.includes('impersonate') || currentPage.includes('unimpersonate')) {
              console.log('IMPERSONATE!!!');
              this.showProfileMenu();
            }
          }
        }, 500);

        return () => clearInterval(timerId);
      });
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousState.dayMode !== this.state.dayMode) {
      setDayMode(this.state.dayMode);
      localStorage.setItem('dayMode', `${this.state.dayMode}`);
    }
  }

  handleSideMenuStateChange = (state) => {
    this.setState({ sideMenuOpen: state.isOpen });
  };

  closeSideMenu = () => {
    console.log('closing side menu');
    this.setState({ sideMenuOpen: false });
  };

  showProfileMenu = () => {
    this.closeSideMenu();
    let restos = (JSON.parse(localStorage.getItem('operator')) || {}).restaurants;
    this.setState({
      userInfo: JSON.parse(localStorage.getItem('operator')) || {},
    });
    if (restos && restos.length) {
      let restoArr = restos.map((r) => {
        return r;
      });
      restoArr.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      this.setState({
        restaurants: restoArr,
      });

      let selectedResto = localStorage.getItem('restaurant') || {};
      let restaurantId = localStorage.getItem('restaurantId') || '';
      console.log(selectedResto);
      this.setState({
        selectedRestaurant: selectedResto ? JSON.parse(selectedResto) : '',
        selectedRestaurantId: restaurantId,
      });
    } else {
      this.setState({
        restaurants: [],
      });
    }
  };

  // this gets called when logging out or changing location/restoId
  addUserLocationProfile(isLoggingOut) {
    // prepare backup of latest user setting
    const currentUserId = JSON.parse(localStorage.getItem('operator')).id.toString();
    const currentLocation = JSON.parse(localStorage.getItem('restaurant')).name;
    let userSetting = {
      userId: currentUserId.toString(),
    };

    let browserRecords = [];
    const browserProfiles = cloneDeep(localStorage.getItem('browserProfiles'));
    if (browserProfiles) {
      browserRecords = JSON.parse(browserProfiles);
    }

    // back up desired keys to record of browser profile (different when changing locations and logging out)
    if (isLoggingOut) {
      Object.keys(localStorage).forEach((key) => {
        if (
          key.includes('restaurantId') ||
          key.includes('restaurant') ||
          key.includes('menuOrder') ||
          key.includes('checklistOrder') ||
          key.includes('documentOrder') ||
          key.includes('menuOpenRowsMobile') ||
          key.includes('menuColKeyOrder') ||
          key.includes('checklistColKeyOrder') ||
          key.includes('documentColKeyOrder')
        ) {
          userSetting[key] = localStorage.getItem(key);
        } else {
          delete localStorage[key];
        }
      });
    } else {
      Object.keys(localStorage).forEach((key) => {
        if (
          key.includes('restaurantId') ||
          key.includes('restaurant') ||
          key.includes('menuOrder') ||
          key.includes('checklistOrder') ||
          key.includes('documentOrder') ||
          key.includes('menuOpenRowsMobile') ||
          key.includes('menuColKeyOrder') ||
          key.includes('checklistColKeyOrder') ||
          key.includes('documentColKeyOrder')
        ) {
          userSetting[key] = localStorage.getItem(key);
        } else if (key.includes('operator') || key.includes('currentOperatorId')) {
          console.log('SKIP operator key for now');
        } else {
          delete localStorage[key];
        }
      });
    }

    // if key 'browserUsers' found AND has existing records
    if (browserRecords.length > 0) {
      // find if there is an existing profile with operator id
      const foundProfile = findIndex(
        browserRecords,
        (prof) => prof.userId === currentUserId && JSON.parse(prof.restaurant).name === currentLocation
      );

      // but before that, if user is logging out, mark this location as last and unmark the profile that is previously marked as last if exists
      if (isLoggingOut) {
        const lastLocationSameUser = findIndex(
          browserRecords,
          (prof) => prof.userId === currentUserId && prof?.isLastLocation
        );
        if (lastLocationSameUser >= 0 && foundProfile !== lastLocationSameUser) {
          let lastLocationRemoved = cloneDeep(browserRecords[lastLocationSameUser]);
          delete lastLocationRemoved['isLastLocation'];
        }
        userSetting.isLastLocation = true;
      }

      // if yes, update and replace to 'browserProfiles' key
      if (foundProfile >= 0) {
        browserRecords[foundProfile] = userSetting;
        // else, append to and stringify 'browserProfiles' key
      } else {
        browserRecords.push(userSetting);
      }
      // update 'browserProfiles' key
      localStorage.setItem('browserProfiles', JSON.stringify(browserRecords));
      // else, create the key if localStorage
    } else {
      let newBrowserUserLocation = [userSetting];
      localStorage.setItem('browserProfiles', JSON.stringify(newBrowserUserLocation));
    }
  }

  logout() {
    this.addUserLocationProfile(true);
    localStorage.removeItem('checklistOrder');
    localStorage.removeItem('menuOrder');
    localStorage.removeItem('documentOrder');
    localStorage.removeItem('menuOpenRowsMobile');
    localStorage.removeItem('menuColKeyOrder');
    localStorage.removeItem('checklistColKeyOrder');
    localStorage.removeItem('documentColKeyOrder');
    window.location = '/public/login';
  }

  onChange = (e) => {
    console.log('radio checked', e.target.value);
    let selected = JSON.parse(e.target.value);
    this.setState({
      selectedRestaurant: selected,
      selectedRestaurantId: selected.id,
    });
    this.addUserLocationProfile(false);
    localStorage.setItem('restaurant', e.target.value);
    localStorage.setItem('restaurantId', selected.id);
    localStorage.removeItem('menuOpenRowsMobile');
    localStorage.removeItem('checklistOrder');
    localStorage.removeItem('menuOrder');
    localStorage.removeItem('documentOrder');
    localStorage.removeItem('menuColKeyOrder');
    localStorage.removeItem('checklistColKeyOrder');
    localStorage.removeItem('documentColKeyOrder');
    if (window.location.href.includes('station/')) {
      window.location = '/dashboard';
    } else {
      window.location.reload();
    }
  };

  handleProfileToggle = () => {
    this.closeSideMenu();
    this.setState({ profileMenuOpen: !this.state.profileMenuOpen });
  };

  switchDayMode = (bool) => {
    this.setState({
      dayMode: bool,
    });
  };

  render() {
    const {
      state: { selectedRestaurantId },
    } = this;
    const menu = (
      <div id="profile-menu">
        <Row>
          <div className="common-user-icon" alt="user-photo">
            <UserImage
              src={
                this.state.userInfo &&
                this.state.userInfo.info &&
                (this.state.userInfo.info?.userImageThumb || this.state.userInfo.info?.userImage)
                  ? this.state.userInfo.info?.userImageThumb || this.state.userInfo.info?.userImage
                  : null
              }
            />
          </div>
          <span style={{ fontSize: 16 }}>
            {this.state.userInfo.firstName} {this.state.userInfo.lastName}
          </span>
        </Row>
        <div className="divider1" />
        <Row>
          {' '}
          <span className="myLocations">My locations</span>
        </Row>
        <Row>
          <Radio.Group onChange={this.onChange} value={this.state.selectedRestaurant}>
            {this.state.restaurants.map(function (obj, i) {
              return (
                <div className="locationNames" key={`location-${i}`}>
                  <Radio value={JSON.stringify({ id: obj.id, name: obj.name })} key={obj.id}>
                    <span className="menu-location-indicator">
                      <CompletionIcon
                        completed={selectedRestaurantId == obj.id}
                        dimmed={selectedRestaurantId != obj.id}
                      />
                    </span>
                    <span style={{ color: 'var(--base-text' }}> {obj.name}</span>
                  </Radio>
                </div>
              );
            })}
          </Radio.Group>
        </Row>

        {(this.state.userInfo.role !== 'Trainer / Manager' || this.state.userInfo.role !== 'Front House') && (
          <div className="divider1" />
        )}
        <Row>
          {(this.state.userInfo.role !== 'Trainer / Manager' || this.state.userInfo.role !== 'Front House') && (
            <div>
              <img src={admin} height="28" style={{ marginLeft: '-10px' }} alt="admin" />
              <Link
                to={
                  this.state.userInfo.role === 'Admin'
                    ? '/admin/users'
                    : this.state.userInfo.role == 'Superadmin'
                    ? '/super/accounts'
                    : this.state.isUrlAdmin
                    ? '/admin/locations'
                    : '/dashboard'
                }
              >
                <span style={{ fontSize: 16 }}>
                  {this.state.userInfo.role == 'Superadmin' ? 'Super Admin' : this.state.userInfo.role}
                </span>
              </Link>
            </div>
          )}
        </Row>

        <div className="divider1" />
        <Row>
          <img
            src={this.state.dayMode ? daymode_off : daymode_on}
            height="18"
            style={{ marginLeft: '-2px' }}
            alt="daymode"
          />
          <span style={{ fontSize: 16, marginLeft: '10px' }}>{`${
            this.state.dayMode ? 'Night Mode' : 'Day Mode'
          }`}</span>
          <span style={{ marginLeft: '10px' }}>
            <Switch checked={this.state.dayMode} onChange={this.switchDayMode} />
          </span>
        </Row>

        <div className="divider1" />
        <Row onClick={() => this.logout()} style={{ cursor: 'pointer' }}>
          <img src={logout} height="28" style={{ marginLeft: '-10px' }} alt="logout" />
          <span style={{ fontSize: 16 }}>Logout</span>
        </Row>
      </div>
    );

    return (
      <div className="header-top-container-mobile">
        <BurgerMenu
          className={'side-menu-mobile'}
          noOverlay
          customCrossIcon={false}
          isOpen={this.state.sideMenuOpen}
          onStateChange={(state) => this.handleSideMenuStateChange(state)}
        >
          <SideMenuMobile closeMenu={this.closeSideMenu} />
        </BurgerMenu>
        <div className="logo-container-mobile">
          <Link to={'/dashboard'} onClick={this.closeSideMenu}>
            <img src={logo} className="cidekic-logo-mobile" alt="logo" />
          </Link>
        </div>
        <div className="badge1">
          {/* }<div className="icon-spacing">
            <Badge
              count={5} offset={[1, 4]}
              onMouseEnter={() => this.setState({ showAlert: true })}
              onMouseLeave={() => this.setState({ showAlert: false })}
            >
              <AlertIcon
                highlight={this.state.showAlert}
              />
            </Badge>
          </div>
          <div
            className="icon-spacing-msg"
            onMouseEnter={() => this.setState({ showMsg: true })}
            onMouseLeave={() => this.setState({ showMsg: false })}
          >
            <ChatIcon
              highlight={this.state.showMsg}
            />
          </div> */}
          <div className="icon-spacing-user">
            {/* <Dropdown overlay={menu} placement="bottomRight"> */}
            <div
              className="common-user-icon"
              id="profile-menu-icon"
              onClick={() => this.handleProfileToggle()}
              alt="user-photo"
            >
              <UserImage
                src={
                  this.state.userInfo &&
                  this.state.userInfo.info &&
                  (this.state.userInfo.info?.userImageThumb || this.state.userInfo.info?.userImage)
                    ? this.state.userInfo.info?.userImageThumb || this.state.userInfo.info?.userImage
                    : this.state.profileMenuOpen
                    ? null
                    : null
                }
              />
            </div>
            {this.state.profileMenuOpen ? menu : ''}
            {/* </Dropdown> */}
          </div>
        </div>
      </div>
    );
  }
}

export default UserMenuMobile;
