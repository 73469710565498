import { request } from './http.service';
import { decodeDocumentFolderMessages } from '../protobuf-models/documentfolder.proto.js';
import { decodeDocumentListMessages } from '../protobuf-models/documentlist.proto.js';
import { result } from 'lodash';

export const create = (params) => {
  return request('POST', '/document', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const searchDocuments = (params) => {
  return request('POST', '/document/search', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchDocFolders = (params) => {
  return request('POST', '/document/folder/search', 200, params, decodeDocumentFolderMessages).then(
    (result) => {
      console.log(result);
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const getDocumentList = (params) => {
  return request('POST', '/document/list', 200, params, decodeDocumentListMessages).then(
    (result) => {
      console.log('document list', result);
      if (result?.documentlists) {
        const res = result.documentlists.map((item) => {
          item.key = item.folderId * 1;
          item.folderId = item.folderId * 1;
          item.type = 'parent';
          const docs = item.children.length > 0 ? JSON.parse(item.children) : [];
          if (docs.length) {
            item.children = docs.map((d, i) => {
              const docInfo = JSON.parse(d.info);
              const docSelectedTask = JSON.parse(d.selectedTask);
              d.key = `folder-${d.folderId}-document-${d.id}-${i}`;
              d.info = docInfo;
              d.selectedTask = docSelectedTask;
              return d;
            });
          } else {
            item.children = docs;
          }
          item.notes = item.notes * 1;
          item.uploads = item.uploads * 1;
          item.selectedTasks = item.selectedTasks.length > 0 ? JSON.parse(item.selectedTasks) : [];
          return item;
        });
        return res;
      } else {
        return [];
      }
    },
    (error) => {
      // console.log(error);
    }
  )
}

export const createDocumentFolder = (params) => {
  return request('POST', '/document/folder', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const destroyDocFolder = (id) => {
  return request('DELETE', '/document/folder/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateDocFolder = (id, params) => {
  return request('PUT', '/document/folder/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const createDocLinks = (id, params) => {
  return request('POST', `/document/${id}/links`, 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getLinks = (id, params) => {
  return request('GET', '/document/' + id + '/links', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchDocLinks = (params) => {
  return request('POST', `/document/links/search`, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const deleteLink = (id, type, linkId) => {
  return request('DELETE', `/document/${id}/type/${type}/link/${linkId}`, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const deleteAllLinks = (id) => {
  return request('DELETE', `/document/${id}/links`, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateDoc = (id, params) => {
  return request('PUT', '/document/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const deleteDocument = (id) => {
  return request('DELETE', `/document/${id}`, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};
