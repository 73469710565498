import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Tag, Switch, Tooltip, notification, Menu, Dropdown, Select, Drawer } from 'antd';
//import { userData } from '../../data/userdata';
//import imgPlaceholder from '../../img/img-placeholder.png';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { search, create, update, destroy } from '../../services/equipment.service';
import { onFileSelected } from '../../services/upload.service';
import { all } from '../../services/account.service';
import { cloneDeep, find, remove, findIndex, isEmpty, uniq } from 'lodash';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import _ from 'lodash';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { NewSubHeader } from '../../components/Header/Header';
import {
  PlusButton,
  CidekicDropdown,
  CidekicSelect,
  ThumbnailImage,
  RowTags,
  RowOkCancel,
} from '../../components/common/common_ui';
import { faObjectGroup } from '@fortawesome/free-solid-svg-icons';

const SuperEquipment = (props) => {
  const [menuName, setMenuName] = useState('');
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState({});
  const [menulist, setList] = useState([]);
  const [editTags, setEditTags] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState(0);
  const [editObj, setEditObj] = useState({});
  const [userimg, setUserImg] = useState('');
  const [loading, setLoading] = useState(false);
  const [socketData, setSocketData] = useState([]);
  const [equipUpdate, setEquipUpdate] = useState([]);
  const [equipAdd, setEquipAdd] = useState([]);
  const [equipDelete, setEquipDelete] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/equipment`));
  const [equipCategories, setEquipCategories] = useState([]);

  const location_name = useRef(null);
  const location_category = useRef(null);
  const location_tags = useRef(null);
  const location_accountId = useRef(null);
  const fileInput = useRef();

  const { Option } = Select;

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let accountsId = JSON.parse(localStorage.getItem('operator')).accountId || '';
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';

  const newLocationValue = {
    key: `${userData.length}`,
    id: 'add',
    name: '',
    category: '',
    tags: [],
    image: '',
    restaurantId: resto?.id,
  };

  useEffect(() => {
    getEquip();
    setTimeout(() => {
      setHasUpdated(false);
      setEditing(false);
      setAdding(false);
      setEditId(null);
      setGetEditData(null);
    }, 2000);
  }, [hasUpdated]);

  useEffect(() => {
    console.log('CURRENT ACCOUNT ID', accountsId);
    getAccounts();
  }, []);

  const getEquip = () => {
    setIsLoading(true);
    let params = {
      description: '',
      // restaurantId: resto?.id,
      accountId: accountsId,
      isSuperAdmin: true,
    };
    search(params).then((res) => {
      console.log(res);
      if (res) {
        let allEquip = res.filter((r) => r.id !== 'add');
        // console.log(res);
        let equipment = allEquip.map((l, i) => {
          return {
            key: `${i}`,
            id: l.id,
            name: l.name,
            restaurantId: l.restaurantId,
            category: l.info && 'category' in l.info ? l.info?.category : 'Uncategorized',
            tags: l.info && 'tags' in l.info ? l.info?.tags : [],
            image: l.info ? l.info?.imageUrlThumb || l.info?.imageUrl || '' : '',
            accountId: l.accountId,
            isMaster: l.info && l.info?.createdBy === 'system_ingest',
          };
        });

        // console.log(equipment);
        let currentEquipCategories = uniq(equipment.map((e) => e.category).filter((cat) => cat !== '' && cat !== null));
        // console.log('CURRENT EQUIPMENT CATEGORIES', currentEquipCategories);
        setEquipCategories(currentEquipCategories);
        let sortedeq = equipment.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setData(sortedeq);
        setOrigData(equipment);
        setSocketData(equipment);
        setIsLoading(false);
      }
    });
  };

  const getAccounts = () => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setList(allAccounts);
      }
    });
  };

  useEffect(() => {
    console.log('NAME FILTER', nameFilter);
    filterEquip(nameFilter);
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All accounts');
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All accounts</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item key={i} className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>{m?.name}</a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterEquip = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getEquip();
    }
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.accountId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deleteImg = (e, obj) => {
    e.preventDefault();
    console.log(obj);

    let clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].image = '';
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const deleteEquip = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: 'Unable to delte a master list item.',
      // });
      return;
    }
    e.preventDefault();
    let id = selected?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Equipment Successfully Deleted',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'deleteEquipment',
        JSON.stringify({
          id: id,
        })
      );
    });
  };

  const editEquip = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    e.preventDefault();
    console.log('equipment to edit: ', obj.id);
    setGetEditData(obj);
    setEditing(true);
    setEditId(obj.id);
    setEditObj(null);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editEquip(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteEquip(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const controlItem = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteImg(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = (obj) => {
    setEditing(false);
    setEditObj(null);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
    obj.image = selected?.image ? selected.image : '';
  };

  const updateEquip = (obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    let rename = location_name?.current?.value?.trim() ? location_name?.current?.value?.trim() : '';
    let recategorize = editObj?.value?.trim() ? editObj?.value?.trim() : obj.category; // BUG 1202093574609426
    let retag = location_tags?.current?.value ? location_tags?.current?.value : '';
    let reaccountId = location_accountId?.current?.value?.trim()
      ? location_accountId?.current?.value?.trim()
      : obj.accountId; // BUG 1202093574609426
    let existingTags = [];
    if (obj == null) {
      existingTags = editObj.tags;
    } else {
      existingTags = obj.tags;
    }
    console.log('UPDATE!!!', obj);
    console.log(reaccountId);
    let params = {
      accountId: account.trim(),
      name: rename,
      info: {
        category: recategorize,
        tags: retag.length > 0 ? existingTags.push(retag) : existingTags,
        imageUrl: obj.image,
        id: editId,
      },
      restaurantId: resto?.id,
    };
    console.log('THE PARAMS', params);
    eventSocket.emit(
      'updateEquipment',
      JSON.stringify({
        accountId: params.accountId,
        category: params.info?.category,
        id: params.info?.id,
        image: params.info?.imageUrl,
        name: params.name,
        restaurantId: params.restaurantId,
        tags: params.info?.tags,
      })
    );
    console.log(params);
    console.log(editId);
    update(editId, params).then((res) => {
      console.log('UPDATERES1', res);
      // notification.open({
      //   message: 'Successfully updated equipment!',
      // });
      setHasUpdated(true);
    });
  };

  const addEquip = (obj) => {
    // console.log(account);
    let rename = location_name.current.value.trim();
    let params = {
      accountId: account,
      name: rename !== '' ? rename : 'Untitled',
      info: {
        category: userData[0].category,
        tags: userData[0].tags,
        imageUrl: userimg,
      },
      restaurantId: resto?.id,
    };
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created equipment!',
      // });
      setHasUpdated(true);

      eventSocket.emit(
        'addEquipment',
        JSON.stringify({
          accountId: res?.accountId,
          category: res?.category,
          id: res?.id,
          image: res?.image,
          name: res?.name,
          restaurantId: res?.restaurantId,
        })
      );
    });
  };

  const handleCategoryChange = (value, obj) => {
    let clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].category = value;
    // console.log(clonedChecklist[foundIndex].category);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleAddTags = (value, obj, type) => {
    console.log(value);
    console.log(obj);

    let clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].tags.push(value);
    console.log(clonedChecklist);
    setData(clonedChecklist);
    console.log(clonedChecklist[foundIndex]);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleRemoveTags = (value, obj) => {
    let clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    remove(clonedChecklist[foundIndex].tags, (tag) => {
      return tag === value;
    });
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleAccountChange = (value, obj) => {
    console.log(value, obj);
    let clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].accountId = value;
    setAccount(value);
    if (isEditing && !isAdding) {
      //updateEquip(clonedChecklist[foundIndex]);
    }
    if (isAdding && location_name.current.value.trim() !== '') {
      setData(clonedChecklist);
      setGetEditData(clonedChecklist[foundIndex]);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    console.log('CURRENTLY EDITING', editObj);
  }, [editObj]);

  const handleOnDrop = (e, obj) => {
    setLoading(true);
    if (!isAdding) {
      setEditing(true);
    }
    e.preventDefault();
    e.stopPropagation();
    console.log(e.dataTransfer.files[0]);

    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        obj.image = res.Location;
        setUserImg(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        setLoading(false);
      } else {
        setLoading(false);
        let userimage = URL.createObjectURL(e.dataTransfer.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const uploadImg = (e, obj) => {
    console.log('FILE', e.target.files);
    if (account != null && location_name.current.value.trim !== '') {
      console.log('uploading image for ', obj);
      setLoading(true);
      if (!isAdding) {
        setEditing(true);
        setGetEditData(obj);
      }

      e.preventDefault();
      e.stopPropagation();

      onFileSelected(e.target.files[0])
        .then((res) => {
          console.log(res);
          setUserImg(res.Location);
          obj.image = res.Location;
          // notification.open({
          //   message: 'Successfully uploaded image!',
          // });
        })
        .catch((err) => {
          console.log('Cannot upload image for equipment - ', err);
          let userimage = URL.createObjectURL(e.target.files[0]);
          setUserImg(userimage);
          // notification.open({
          //   message: 'Image not uploaded. Please try again',
          // });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // notification.open({
      //   message: 'Set account and name of equipment first.',
      // });
    }
  };

  const handleCatChange = (value, obj) => {
    const clonedEquip = cloneDeep(userData);
    const foundIndex = findIndex(clonedEquip, { id: obj.id });
    clonedEquip[foundIndex].category = value;
    setData(clonedEquip);
    setGetEditData(clonedEquip[foundIndex]);
  };

  const columns = [
    // photo
    {
      title: 'Photo',
      dataIndex: 'photo',
      render: (key, obj) => (
        <span id={`photo-${key}`}>
          {isEditing && editId == obj.id && (
            <span
              onClick={() => fileInput.current.click()}
              onDragOver={handleDragOver}
              onDrop={(e) => handleOnDrop(e, obj)}
            >
              <ThumbnailImage type={'admin'} src={obj.image && obj.image !== '' ? obj.image : null} />
              <input
                type="file"
                style={{ display: 'none', cursor: 'pointer' }}
                onChange={(e) => uploadImg(e, obj)}
                ref={fileInput}
                accept="image/*"
              />
            </span>
          )}
          {((isEditing && editId !== obj.id) || (isAdding && obj.id !== 'add') || (!isEditing && !isAdding)) && (
            <ThumbnailImage type={'admin'} src={obj.image && obj.image !== '' ? obj.image : null} />
          )}
          {isAdding && obj.id == 'add' && (
            <span
              onClick={() => fileInput.current.click()}
              onDragOver={handleDragOver}
              onDrop={(e) => handleOnDrop(e, obj)}
            >
              <ThumbnailImage type={'admin'} src={obj.image && obj.image !== '' ? obj.image : null} />
              <input
                type="file"
                style={{ display: 'none', cursor: 'pointer' }}
                onChange={(e) => uploadImg(e, obj)}
                ref={fileInput}
                accept="image/*"
              />
            </span>
          )}
          <span>
            {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
              <div className="common-controls-container">
                <Dropdown overlay={controlItem(obj)}>
                  <div className="toggle-switch">
                    <img
                      src={IconsMoreOff}
                      className="UI-IconsMoreOff"
                      alt="IconsMoreOff"
                      onMouseEnter={() => {
                        if (Object.keys(selected).length === 0 || selected.id != obj.id) {
                          setSelected({ ...obj });
                        }
                      }}
                    />
                  </div>
                </Dropdown>
              </div>
            ) : (
              ''
            )}
          </span>
        </span>
      ),
    },
    // title
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateEquip(obj);
                }
              }}
            />
          )}
          {isAdding && obj.id == 'add' && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && account != null) {
                  addEquip(obj);
                }
              }}
            />
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    // category
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: (a, b) => (a.category < b.category ? -1 : a.category > b.category ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id ? (
            <>
              <CidekicSelect
                ref={location_category}
                className="admin-select-select"
                onChange={(e) => handleCatChange(e, obj)}
                defaultValue={key ? key : equipCategories[0]}
                placeholder="Select category"
                showSearch
              >
                {equipCategories.map((cat, i) => (
                  <Option key={i} value={cat}>
                    {cat}
                  </Option>
                ))}
              </CidekicSelect>

              {/* <input
                type="text"
                className="row-name-input"
                placeholder="Enter a category"
                defaultValue={key}
                ref={location_category}
                onChange={(e) => handleCategoryChange(e.target.value, obj)}
                onBlur={() => setEditTags(true)}
                onKeyPress={(e) => {
                  setEditTags(false);
                  if (e.key === 'Enter') {
                    setEditTags(true);
                    updateEquip(e, obj);
                  }
                }}
              /> */}
            </>
          ) : (
            ''
          )}
          {isAdding && obj.id == 'add' ? (
            // <div>
            //   <input
            //     type="text"
            //     className="row-name-input"
            //     placeholder="Enter a category"
            //     defaultValue={key}
            //     onChange={(e) => handleCategoryChange(e.target.value, obj)}
            //   />
            // </div>
            <CidekicSelect
              ref={location_category}
              className="station-selectEv10"
              onChange={(e) => handleCatChange(e, obj)}
              defaultValue={key}
              showArrow={false}
              showSearch
            >
              {equipCategories.map((cat, i) => (
                <Option key={i} value={cat}>
                  {cat}
                </Option>
              ))}
            </CidekicSelect>
          ) : (
            ''
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    // tags
    {
      title: 'Tags',
      dataIndex: 'tags',
      sorter: (a, b) => (a.tags < b.tags ? -1 : a.tags > b.tags ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <RowTags
          tags={obj.tags}
          isEditing={(isEditing && editId == obj.id) || (isAdding && obj.id == 'add')}
          inputName="input"
          inpuId="input"
          ref={location_tags}
          objId={obj.id}
          onEditKeyPress={(e) => {
            setEditTags(false);
            if (e.key === 'Enter') {
              handleAddTags(e.target.value, obj, 'edit');
              e.target.value = '';
              setEditTags(true);
            }
          }}
          onEditBlur={() => setEditTags(true)}
          closable={isEditing || isAdding}
          onClose={(tag) => {
            // remove tags
            handleRemoveTags(tag, obj);
          }}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'accountId',
      render: (key, obj) => (
        <span>
          {(isAdding && obj.id == 'add') || (isEditing && editId == obj.id) ? (
            <CidekicSelect
              className="admin-select-select"
              ref={location_accountId}
              placeholder="Select account"
              defaultValue={isAdding ? undefined : key}
              onChange={(val) => handleAccountChange(val, obj)}
              showSearch
            >
              {menulist &&
                menulist.length > 0 &&
                menulist.map((m) => (
                  <Option key={`${m.id}`} value={m.id}>
                    {m.name}
                  </Option>
                ))}
            </CidekicSelect>
          ) : (
            <span>{menulist && menulist.length > 0 && menulist.find((x) => x.id == key)?.name}</span>
          )}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'controls',
      render: (key, obj) => (
        <span>
          {!isEditing && !isAdding && (
            <Dropdown overlay={controlItems(obj)}>
              <div className="toggle-switch">
                <img
                  src={IconsMoreOff}
                  className="UI-IconsMoreOff"
                  alt="IconsMoreOff"
                  onMouseEnter={() => {
                    if (Object.keys(selected).length === 0 || selected.id != obj.id) {
                      setSelected({ ...obj });
                    }
                  }}
                />
              </div>
            </Dropdown>
          )}
          {((isEditing && editId == obj.id) || (isAdding && obj.id == 'add')) && (
            <RowOkCancel
              onClickOk={isEditing ? () => updateEquip(obj) : () => addEquip(obj)}
              onClickCancel={() => {
                cancelEdit(obj);
              }}
            />
          )}
        </span>
      ),
    },
  ];

  useEffect(() => {
    eventSocket.on('updatedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPUPDATED!!', [newData]);
      setEquipUpdate([newData]);
    });
    eventSocket.on('addedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPADDED!!', newData);
      setEquipAdd(newData);
    });
    eventSocket.on('deletedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPDELETED!!', newData.id);
      setEquipDelete(newData);
    });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    setData((oldArray) => [...oldArray, equipAdd]);
    setOrigData((oldArray) => [...oldArray, equipAdd]);
    setSocketData((oldArray) => [...oldArray, equipAdd]);
  }, [equipAdd]);

  useEffect(() => {
    console.log(socketData);
    console.log(equipUpdate);
    let updated = socketData.map((obj) => equipUpdate.find((o) => parseInt(o.id) === parseInt(obj.id)) || obj);

    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
  }, [equipUpdate]);

  useEffect(() => {
    console.log(socketData);
    console.log(equipDelete);
    let updated = socketData?.filter((obj) => parseInt(obj.id) !== parseInt(equipDelete.id));
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
  }, [equipDelete]);

  return (
    <div id="superadmin-equipment" className="common-page-container">
      <NewSubHeader title="Equipment" onChange={(evt) => setNameFilter(evt.target.value)} />
      <div className="Line"></div>
      <div className="common-page-content">
        <div className="common-controls-container controls-thin">
          <PlusButton
            className="flex-control-left"
            alt="Add new equipment"
            onClick={() => {
              setAdding(true);
              const clonedDataSource = cloneDeep(origData);
              clonedDataSource.unshift(newLocationValue);
              setData(clonedDataSource);
              window.scrollTo(0, 0);
            }}
          >
            Add new equipment
          </PlusButton>

          <CidekicDropdown overlay={menuList} className="flex-control-right select-wide">
            {menuName ? menuName : 'All accounts'}
          </CidekicDropdown>
        </div>

        {!isLoading && (
          <Table
            dataSource={userData}
            columns={columns}
            pagination={false}
            rowKey={'i'}
            className={'show-custom-empty'}
            locale={{ emptyText: <Empty image={empty} description="No equipment" /> }}
            scroll={{ y: 'calc(100vh - var(--table-scroll-offset))' }}
          />
        )}
      </div>
    </div>
  );
};

export default SuperEquipment;
