import React from 'react';
import MenuDetailsMobile from './MenuDetailsMobile';

// what is this for?

const MenuMobile = () => {
  return <MenuDetailsMobile />;
};

export default MenuMobile;
