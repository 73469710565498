import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Tag, Switch, Tooltip, notification, Menu, Dropdown, Drawer } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { PlusOutlined } from '@ant-design/icons';
import { searchprep, create, update, destroy } from '../../services/preparation.service';
import { allLocations } from '../../services/restaurant.service';
import { all } from '../../services/account.service';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { cloneDeep, find, remove, findIndex, isEmpty } from 'lodash';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import _ from 'lodash';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';
import { CidekicButton } from '../../components/common/common_ui';

const PreparationsMobile = (props) => {
  const [menuName, setMenuName] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [menulist, setList] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);
  const [socketData, setSocketData] = useState([]);
  const [prepUpdate, setPrepUpdate] = useState([]);
  const [prepAdd, setPrepAdd] = useState([]);
  const [prepDelete, setPrepDelete] = useState('');
  const [account, setAccount] = useState(0);
  const [accountlist, setAccountList] = useState([]);
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/preparation`));

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let accountId =
    localStorage.getItem('operator') != undefined && JSON.parse(localStorage.getItem('operator')).accountId != null
      ? JSON.parse(localStorage.getItem('operator')).accountId
      : '';
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';

  const location_name = useRef(null);

  const newLocationValue = {
    id: 'add',
    name: '',
  };

  useEffect(() => {
    getPrep();
    setTimeout(() => {
      setHasUpdated(false);
      setEditing(false);
      setAdding(false);
      setEditId(null);
    }, 2000);
  }, [hasUpdated]);

  useEffect(() => {
    getLocations();
  }, []);

  const getPrep = () => {
    let params = {
      from: 0,
      limit: 10000,
      restaurantId: resto.id,
      accountId: accountId,
    };
    searchprep(params).then((res) => {
      console.log(res);
      if (res) {
        let allPrep = res;
        let prep = allPrep.map((l) => {
          return {
            id: l.id,
            name: l.name,
            restaurantId: l.restaurantId,
            isMaster: l.info && l.info?.createdBy === 'system_ingest',
            accountId: l.accountId,
          };
        });

        console.log(prep);
        prep.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setData(prep);
        setOrigData(prep);
        setSocketData(prep);
      }
    });
  };

  const getLocations = () => {
    allLocations(0, 1000).then((res) => {
      console.log(res);
      let allLoc = res;
      let address1 = allLoc.filter((a) => a.deletedAt == '');
      let address = address1.map((l) => {
        return {
          id: l.id,
          name: l.name,
          address: l.info && l.info?.address ? l.info?.address : '',
          timezone: l.info && l.info?.timezone ? l.info?.timezone : '',
        };
      });

      console.log(address);
      setList(address);
    });
  };

  // const debouncedApiCall = useCallback(
  //   _.debounce((e) => {
  //     filterLocations(e);
  //   }, 200),
  //   []
  // );

  useEffect(() => {
    filterPrep(nameFilter);
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All restaurants');
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All restaurants</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>
                {m?.name} - {m?.address}
              </a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterPrep = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getPrep();
    }
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name + '-' + ' ' + menu.address;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.restaurantId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deletePrep = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: 'Unable to delete a master list item.',
      // });
      return;
    }
    e.preventDefault();
    let id = selected?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Preparation Item Successfully Deleted',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'deletesPrep',
        JSON.stringify({
          id: id,
        })
      );
    });
  };

  const editPrep = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    e.preventDefault();
    console.log(obj);
    setEditing(true);
    setEditId(obj.id);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editPrep(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deletePrep(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updatePrep = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    let rename = location_name.current.value.trim();
    let params = {
      restaurantId: resto.id,
      name: rename !== '' ? rename : 'Untitled',
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    eventSocket.emit(
      'updatesPrep',
      JSON.stringify({
        accountId: accountId,
        id: obj.id,
        name: rename,
        restaurantId: resto.id,
      })
    );
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated preparation item!',
      // });
      setHasUpdated(true);
    });
  };

  const updatePrep2 = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    let rename = location_name.current.value.trim();
    let params = {
      restaurantId: resto.id,
      name: rename !== '' ? rename : 'Untitled',
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    eventSocket.emit(
      'updatesPrep',
      JSON.stringify({
        accountId: accountId,
        id: obj.id,
        name: rename,
        restaurantId: resto.id,
      })
    );
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated preparation item!',
      // });
      setHasUpdated(true);
    });
  };

  const addPrep = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      restaurantId: resto.id,
      name: rename !== '' ? rename : 'Untitled',
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created preparation item!',
      // });
      setHasUpdated(true);
      setAccount(0);
      eventSocket.emit(
        'addsPrep',
        JSON.stringify({
          accountId: res?.accountId?.toString(),
          id: res?.id?.toString(),
          name: res?.name,
          restaurantId: res?.restaurantId !== null ? res?.restaurantId : undefined,
        })
      );
    });
  };

  const addPrep2 = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      restaurantId: resto.id,
      name: rename !== '' ? rename : 'Untitled',
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created preparation item!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'addsPrep',
        JSON.stringify({
          accountId: res?.accountId?.toString(),
          id: res?.id?.toString(),
          name: res?.name,
          restaurantId: res?.restaurantId !== null ? res?.restaurantId : undefined,
        })
      );
    });
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setAccountList(allAccounts);
      }
    });
  };

  const handleAccountChange = (value, obj) => {
    console.log(value, obj);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].accountId = value;
    console.log(userData);
    setAccount(value);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div>
            <RoleAccessibleComponent
              permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
            >
              <div>
                {!obj?.isMaster && (
                  <Dropdown overlay={controlItems(obj)}>
                    <div style={{ cursor: 'pointer', float: 'right' }}>
                      <img
                        src={IconsMoreOff}
                        className="UI-IconsMoreOff"
                        alt="IconsMoreOff"
                        onClick={() => setSelected(obj)}
                      />
                    </div>
                  </Dropdown>
                )}
              </div>
            </RoleAccessibleComponent>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '-6px' }}>Title</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  // onKeyPress={(e) => {
                  //   if (e.key === 'Enter') {
                  //     updatePrep(e, obj);
                  //   }
                  // }}
                  // onBlur={(e) => updatePrep2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className={isAdding && obj.id == 'add' ? '' : 'loc_nameMob'}>{obj.name}</div>
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 55 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addPrep(e, obj);
                    }
                  }}
                  onBlur={(e) => addPrep2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit()}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div>
            {!obj.isMaster && !isAdding && !isEditing && (
              <div style={{ display: 'flex', marginTop: 15 }}>
                <div style={{ marginLeft: '-6px', marginRight: 28 }}>Account</div>
                {accountlist && accountlist.length > 0 && accountlist.find((m) => m.id == accountId).name}
              </div>
            )}
            {isAdding && obj.id == 'add' && (
              <span>
                <div style={{ display: 'flex', marginTop: 10 }}>
                  <div style={{ marginLeft: '-6px', marginRight: 30 }}>Account</div>
                  {accountlist && accountlist.length > 0 && accountlist.find((m) => m.id == accountId).name}
                </div>
              </span>
            )}
            {isEditing && editId == obj.id && (
              <span>
                <div style={{ display: 'flex', marginTop: 10 }}>
                  <div style={{ marginLeft: '-6px', marginRight: 30 }}>Account</div>
                  {accountlist && accountlist.length > 0 && accountlist.find((m) => m.id == accountId).name}
                </div>
              </span>
            )}
          </div>
          {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
            <div style={{ display: 'flex', marginTop: 15 }}>
              <div style={{ marginLeft: '-6px', marginRight: 57 }}>
                <div style={{ marginLeft: 85 }}>
                  <div className="common-controls-container">
                    <CidekicButton
                      className="flex-control-right button-table-row button-highlight"
                      onClick={isEditing ? (e) => updatePrep(e, obj) : (e) => addPrep(e, obj)}
                    >
                      Save
                    </CidekicButton>
                    <CidekicButton className="flex-control button-table-row" onClick={() => cancelEdit()}>
                      Cancel
                    </CidekicButton>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </span>
      ),
    },
  ];

  useEffect(() => {
    eventSocket.on('updatedPrep', (message) => {
      let newData = JSON.parse(message);
      console.log('PREPUPDATED!!', [newData]);
      setPrepUpdate([newData]);
    });
    eventSocket.on('addedPrep', (message) => {
      let newData = JSON.parse(message);
      console.log('PREPADDED!!', newData);
      setPrepAdd(newData);
    });
    eventSocket.on('deletedPrep', (message) => {
      let newData = JSON.parse(message);
      console.log('PREPDELETED!!', newData.id);
      setPrepDelete(newData.id);
    });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    setData((oldArray) => [...oldArray, prepAdd]);
    setOrigData((oldArray) => [...oldArray, prepAdd]);
    setSocketData((oldArray) => [...oldArray, prepAdd]);
  }, [prepAdd]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.map((obj) => prepUpdate.find((o) => o.id === obj.id) || obj);
    console.log(updated);
    setData(updated);
    setOrigData(updated);
  }, [prepUpdate]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.filter((obj) => obj.id !== prepDelete);
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
  }, [prepDelete]);

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Preparations"
        restaurant="Admin"
        showSearch={showSearch}
        onChange={(evt) => setNameFilter(evt.target.value)}
        clickSearch={() => setShowSearch(true)}
      />
      <div style={{ display: 'flex' }}>
        <div className="add-cat23Mob">
          <div>{menuName ? menuName : 'Restaurant name'}</div>
        </div>
        <Dropdown overlay={menuList}>
          <div className="dropdown-icon">
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </Dropdown>
        <div className="spacer-cat"></div>
        <RoleAccessibleComponent
          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
        >
          <div
            className="add-cat14Mob"
            onClick={() => {
              //setIsAdding(true);
            }}
          >
            <div
              style={{ cursor: 'pointer', fontFamily: 'Helvetica Neue' }}
              onClick={() => {
                setAdding(true);
                const clonedDataSource = cloneDeep(origData);
                clonedDataSource.unshift(newLocationValue);
                setData(clonedDataSource);
                window.scrollTo(0, 0);
              }}
            >
              + <span style={{ marginLeft: 5 }}>Add new preparation</span>
            </div>
          </div>
        </RoleAccessibleComponent>
      </div>
      <div style={{ padding: '30px 20px 0px 15px' }}>
        <Table
          dataSource={userData}
          columns={columns}
          pagination={false}
          className={'show-custom-empty'}
          locale={{ emptyText: <Empty image={empty} description="No preparations" /> }}
        />
      </div>{' '}
      <RoleAccessibleComponent
        permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
      >
        <div className="add-checklist15">
          <div
            className="add-checklist-but"
            onClick={() => {
              setAdding(true);
              const clonedDataSource = cloneDeep(origData);
              clonedDataSource.push(newLocationValue);
              setData(clonedDataSource);
            }}
          >
            <span>
              <PlusOutlined />
            </span>{' '}
            Add new preparation
          </div>
        </div>
      </RoleAccessibleComponent>
    </div>
  );
};

export default PreparationsMobile;
