import React from 'react';
import allergen from '../../img/recipe-allergen.png';
import { formatIfNeeded } from '../../pages/Recipes/utilities';
import { ThumbnailImage } from '../../components/common/common_ui';

const IngredientsList = (props) => {
  const { ingredientList } = props;

  console.log(ingredientList);

  return (
    <div className="view-recipe-section-container">
      <div className="view-recipe-section-title">Ingredients</div>

      <table className="view-recipe-items-table">
        <thead>
          <tr className="ing-list-thead">
            <th>Name</th>
            <th>Quantity</th>
            <th>Measurement</th>
            <th>Preparation</th>
          </tr>
        </thead>
        <tbody>
          {ingredientList.map((ingredient, i) => {
            return (
              <tr key={`${i}`}>
                <td>
                  <div className="ingredient-name-container">
                    <ThumbnailImage
                      className="ingredient-image-container"
                      src={
                        ingredient?.imageUrlThumb || ingredient?.imageUrl
                          ? ingredient?.imageUrlThumb || ingredient?.imageUrl
                          : ingredient?.info && (ingredient?.info?.imageUrlThumb || ingredient?.info?.imageUrl)
                          ? ingredient?.info?.imageUrlThumb || ingredient?.info?.imageUrl
                          : null
                      }
                    />
                    <div className="ingredient-name"> {ingredient?.name}</div>
                  </div>
                </td>
                <td>{formatIfNeeded(ingredient?.quantity)}</td>
                <td>{ingredient?.measurement}</td>
                <td>{ingredient?.preparation}</td>
                {/*<td>
                  <div className="ingredient-allergen">
                    {' '}
                    <div>{ingredient?.allergen && <img src={allergen} alt="allergen" height="30" />}</div>
                    <div className="ing-allergen">{ingredient?.allergen}</div>
                  </div>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IngredientsList;
