import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Tag, Switch, Tooltip, notification, Menu, Dropdown, Drawer, Select } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { searchprep, create, update, destroy } from '../../services/preparation.service';
import { all } from '../../services/account.service';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { cloneDeep, find, remove, findIndex, isEmpty } from 'lodash';
import _ from 'lodash';
import { object } from 'prop-types';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { NewSubHeader } from '../../components/Header/Header';
import { PlusButton, CidekicDropdown, CidekicSelect, RowOkCancel } from '../../components/common/common_ui';

const SuperPreparations = (props) => {
  const [menuName, setMenuName] = useState('');
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [menulist, setList] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState(0);
  const [editObj, setEditObj] = useState({});
  const [socketData, setSocketData] = useState([]);
  const [prepUpdate, setPrepUpdate] = useState([]);
  const [prepAdd, setPrepAdd] = useState([]);
  const [prepDelete, setPrepDelete] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/preparation`));

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let accountsId = JSON.parse(localStorage.getItem('operator')).accountId || '';
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';

  const location_name = useRef(null);
  // const location_accountId = useRef(null);

  const { Option } = Select;

  const newLocationValue = {
    id: 'add',
    name: '',
  };

  useEffect(() => {
    getPrep();
    setTimeout(() => {
      setHasUpdated(false);
      setEditing(false);
      setAdding(false);
      setEditId(null);
      setEditObj(null);
    }, 2000);
  }, [hasUpdated]);

  useEffect(() => {
    getAccounts();
  }, []);

  const getPrep = () => {
    setIsLoading(true);
    let params = {
      from: 0,
      limit: 10000,
      accountId: accountsId,
      isSuperAdmin: true,
      //restaurantId: resto.id,
    };
    searchprep(params).then((res) => {
      console.log(res);
      if (res) {
        let allPrep = res;
        let prep = allPrep.map((l) => {
          return {
            id: l.id,
            name: l.name,
            restaurantId: l.restaurantId,
            accountId: l.accountId,
            isMaster: l.info && l.info?.createdBy === 'system_ingest',
          };
        });

        console.log(prep);
        prep.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setData(prep);
        setOrigData(prep);
        setSocketData(prep);
        setIsLoading(false);
      }
    });
  };

  const getAccounts = () => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setList(allAccounts);
      }
    });
  };

  // const debouncedApiCall = useCallback(
  //   _.debounce((e) => {
  //     filterLocations(e);
  //   }, 200),
  //   []
  // );

  useEffect(() => {
    filterPrep(nameFilter);
  }, [nameFilter]);

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All accounts');
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All accounts</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>{m?.name}</a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterPrep = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getPrep();
    }
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.accountId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deletePrep = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to delete a master list item.",
      // });
      return;
    }
    e.preventDefault();
    let id = selected?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Preparation Item Successfully Deleted',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'deletesPrep',
        JSON.stringify({
          id: id,
        })
      );
    });
  };

  const editPrep = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    e.preventDefault();
    console.log(obj);
    setEditing(true);
    setEditId(obj.id);
    setEditObj(obj);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editPrep(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deletePrep(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updatePrep = (obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    let selectedObj = {};
    if (obj == null) {
      selectedObj = editObj;
    } else {
      selectedObj = obj;
    }
    let rename = location_name.current.value.trim();
    // let reaccountId = location_accountId.current.value.trim();
    let params = {
      //restaurantId: resto.id,
      name: rename,
      accountId: selectedObj.accountId !== '' ? selectedObj.accountId : '0',
    };
    eventSocket.emit(
      'updatesPrep',
      JSON.stringify({
        accountId: params.accountId,
        id: editId,
        name: rename,
        restaurantId: obj.restaurantId,
      })
    );
    update(editId, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated preparation item!',
      // });
      setHasUpdated(true);
    });
  };

  const addPrep = (obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      // restaurantId: resto.id,
      name: rename !== '' ? rename : 'Untitled',
      accountId: account,
    };
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created preparation item!',
      // });
      setHasUpdated(true);
      setAccount(0)
      eventSocket.emit(
        'addsPrep',
        JSON.stringify({
          accountId: res?.accountId?.toString(),
          id: res?.id?.toString(),
          name: res?.name,
          restaurantId: res?.restaurantId !== null ? res?.restaurantId : undefined,
        })
      );
    });
  };

  const handleAccountChange = (value, obj) => {
    console.log(value, obj);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].accountId = value;
    setAccount(value);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updatePrep(obj);
                }
              }}
            />
          )}
          {isAdding && obj.id == 'add' && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  addPrep(obj);
                }
              }}
            />
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'accountId',
      render: (key, obj) => (
        <span>
          {(isAdding && obj.id == 'add') || (isEditing && editId == obj.id) ? (
            <CidekicSelect
              className="admin-select-select"
              //ref={location_accountId}
              placeholder="Select account"
              defaultValue={isAdding ? undefined : key}
              onChange={(val) => handleAccountChange(val, obj)}
              showSearch
            >
              {menulist &&
                menulist.length > 0 &&
                menulist.map((m) => (
                  <Option key={`${m.id}`} value={m.id}>
                    {m.name}
                  </Option>
                ))}
            </CidekicSelect>
          ) : (
            <span>{menulist && menulist.length > 0 && menulist.find((x) => x.id == key)?.name}</span>
          )}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'controls',
      render: (key, obj) => (
        <span>
          {!isEditing && !isAdding && (
            <Dropdown overlay={controlItems(obj)}>
              <div className="toggle-switch">
                <img
                  src={IconsMoreOff}
                  className="UI-IconsMoreOff"
                  alt="IconsMoreOff"
                  onMouseEnter={() => setSelected(obj)}
                />
              </div>
            </Dropdown>
          )}
          {((isEditing && editId == obj.id) || (isAdding && obj.id == 'add')) && (
            <RowOkCancel
              className="float-right"
              onClickOk={isEditing ? () => updatePrep(obj) : () => addPrep(obj)}
              onClickCancel={cancelEdit}
            />
          )}
        </span>
      ),
    },
  ];

  // const columns2 = [
  //   {
  //     title: '',
  //     dataIndex: 'accountId',
  //     render: (key, obj) => (
  //       <span>
  //         {isAdding && obj.id == 'add' && (
  //           <select className="admin-select" onChange={(e) => handleAccountChange(e.target.value, obj)}>
  //             <option initialvalue="default">Select</option>
  //             {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
  //           </select>
  //         )}
  //         {isEditing && editId == obj.id && (
  //           <select
  //             className="admin-select"
  //             defaultValue={key}
  //             onChange={(e) => handleAccountChange(e.target.value, obj)}
  //           >
  //             <option initialvalue="default">Select</option>
  //             {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
  //           </select>
  //         )}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: 'Title',
  //     dataIndex: 'name',
  //     sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
  //     sortDirections: ['descend', 'ascend'],
  //     render: (key, obj) => (
  //       <span>
  //         {isEditing && editId == obj.id ? (
  //           <div>
  //             <input
  //               type="text"
  //               className="prep-bar1"
  //               placeholder="Enter a title"
  //               defaultValue={key}
  //               ref={location_name}
  //               onKeyPress={(e) => {
  //                 if (e.key === 'Enter') {
  //                   updatePrep(e, obj);
  //                 }
  //               }}
  //               onBlur={(e) => updatePrep(e, obj)}
  //             />
  //
  //             <div
  //               onMouseDown={() => cancelEdit()}
  //               style={{
  //                 width: 100,
  //                 cursor: 'pointer',
  //                 margin: '4px 0px 7px 3px',
  //                 color: 'var(--accent-color)',
  //                 fontSize: 13,
  //               }}
  //             >
  //               Cancel
  //             </div>
  //           </div>
  //         ) : (
  //           <div>{key}</div>
  //         )}
  //         {isAdding && obj.id == 'add' ? (
  //           <div>
  //             <input
  //               type="text"
  //               className="loc-bar"
  //               placeholder="Enter a title"
  //               defaultValue={key}
  //               ref={location_name}
  //               onKeyPress={(e) => {
  //                 if (e.key === 'Enter') {
  //                   addPrep(e, obj);
  //                 }
  //               }}
  //               onBlur={(e) => addPrep(e, obj)}
  //             />
  //
  //             <div
  //               onMouseDown={() => cancelEdit()}
  //               style={{
  //                 width: 100,
  //                 cursor: 'pointer',
  //                 margin: '4px 0px 7px 3px',
  //                 color: 'var(--accent-color)',
  //                 fontSize: 13,
  //               }}
  //             >
  //               Cancel
  //             </div>
  //           </div>
  //         ) : (
  //           ''
  //         )}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: '',
  //     dataIndex: 'controls',
  //     render: (key, obj) => (
  //       <span>
  //         {!isEditing && !isAdding && (
  //           <Dropdown overlay={controlItems(obj)}>
  //             <div className="toggle-switch">
  //               <img
  //                 src={IconsMoreOff}
  //                 className="UI-IconsMoreOff"
  //                 alt="IconsMoreOff"
  //                 onMouseEnter={() => setSelected(obj)}
  //               />
  //             </div>
  //           </Dropdown>
  //         )}
  //       </span>
  //     ),
  //   },
  // ];

  useEffect(() => {
    eventSocket.on('updatedPrep', (message) => {
      let newData = JSON.parse(message);
      console.log('PREPUPDATED!!', [newData]);
      setPrepUpdate([newData]);
    });
    eventSocket.on('addedPrep', (message) => {
      let newData = JSON.parse(message);
      console.log('PREPADDED!!', newData);
      setPrepAdd(newData);
    });
    eventSocket.on('deletedPrep', (message) => {
      let newData = JSON.parse(message);
      console.log('PREPDELETED!!', newData.id);
      setPrepDelete(newData.id);
    });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    setData((oldArray) => [...oldArray, prepAdd]);
    setOrigData((oldArray) => [...oldArray, prepAdd]);
    setSocketData((oldArray) => [...oldArray, prepAdd]);
  }, [prepAdd]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.map((obj) => prepUpdate.find((o) => o.id === obj.id) || obj);
    console.log(updated);
    setData(updated);
    setOrigData(updated);
  }, [prepUpdate]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.filter((obj) => obj.id !== prepDelete);
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
  }, [prepDelete]);

  return (
    <div id="superadmin-preparations" className="common-page-container">
      <NewSubHeader title="Preparations" onChange={(evt) => setNameFilter(evt.target.value)} />
      <div className="Line"></div>
      <div className="common-page-content">
        <div className="common-controls-container controls-thin">
          <PlusButton
            className="flex-control-left"
            alt="Add new preparation"
            onClick={() => {
              setAdding(true);
              const clonedDataSource = cloneDeep(origData);
              clonedDataSource.unshift(newLocationValue);
              setData(clonedDataSource);
              window.scrollTo(0, 0);
            }}
          >
            Add new preparation
          </PlusButton>

          <CidekicDropdown overlay={menuList} className="flex-control-right select-wide">
            {menuName ? menuName : 'All accounts'}
          </CidekicDropdown>
        </div>

        {!isLoading && (
          <Table
            dataSource={userData}
            columns={columns}
            pagination={false}
            className={'show-custom-empty'}
            locale={{ emptyText: <Empty image={empty} description="No preparations" /> }}
            scroll={{ y: 'calc(100vh - var(--table-scroll-offset))' }}
          />
        )}
      </div>
    </div>
  );
};

export default SuperPreparations;
