import React, { useState, useEffect } from 'react'; //
import {
  Drawer,
  Menu,
  Dropdown,
  Button,
  Row,
  Col,
  Form,
  DatePicker,
  TimePicker,
  Spin,
  Popover,
  Checkbox,
  Input,
  Tag,
  Table,
} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../components/windowdimensions/windowSize';
import { updateEvent, destroy, unassignMenu } from '../../../services/event.service';
import { activateEventChecklist, searchMenu, batchCourses } from '../../../services/menu.service';
import { searchMenuCategories } from '../../../services/category.service';
import { batchRecipes } from '../../../services/course.service';
import { getAllStationOperators, getExistingEventMenus } from '../../../services/station.service';
import { getMenuListForEvents, getEventParticipants } from '../DashboardUtils';
import { cloneDeep, find, includes, remove } from 'lodash';
import { SketchPicker } from 'react-color';
import { REACT_APP_SOCKET_BASE_URL } from '../../../config';
import openSocket from 'socket.io-client';
import {
  FolderIcon,
  CompletionIcon,
  MeetingParticipantAdd,
  CidekicButton as LinkButton,
} from '../../../components/common/common_ui';

const format = 'h:mm A';
const dateFormat = 'MM / DD / YYYY';
const daysOfWeek = ['Monday', 'Friday', 'Tuesday', 'Saturday', 'Wednesday', 'Sunday', 'Thursday'];
const repeats = [
  { name: 'never', label: 'Never' },
  { name: 'everyday', label: 'Every Day' },
  { name: 'weekdays', label: 'Week Days' },
  { name: 'weekends', label: 'Weekends' },
  { name: 'custom', label: 'Custom' },
];

const EditEventDrawerV2 = (props) => {
  const {
    data,
    form: { getFieldDecorator },
  } = props;
  const { name, startTimestamp, endTimestamp, until, EventMenus, EventOperators } = data;
  const info = data.info; //weird but it fixed

  const repeatType = data.repeat === 'repeat' ? info?.repeat_type : data.repeat; // refactor else case should be 'never'

  let r = find(repeats, { name: repeatType });
  if (!r) {
    // meaning range
    r = { name: 'never', label: 'Never' };
  }

  const { color } = info;

  const description = info?.hasOwnProperty('description') ? info?.description : '';
  const guestInfo = info?.hasOwnProperty('guest') ? info?.guest : 0;
  const custom_days = info?.hasOwnProperty('custom_days') ? info?.custom_days : [];
  // const dateStart = repeatType === 'range' ?moment.unix(parseInt(data.originalStart)):  moment.unix(parseInt(startTimestamp));
  // const dateEnd = repeatType === 'range' ? moment.unix(parseInt(data.originalEnd)): moment.unix(parseInt(endTimestamp));
  const dateStart = moment.unix(parseInt(startTimestamp));
  const dateEnd = moment.unix(parseInt(endTimestamp));
  // console.log("Got dateEnd: " + dateEnd.format('MM-DD-YYYY H:mm'));
  const dateUntil = until ? moment.unix(parseInt(until)) : dateEnd;
  // const startT = repeatType === 'range' ? moment.unix(parseInt(data.originalStart)).format('HH:mm A'): moment.unix(parseInt(startTimestamp)).format('HH:mm A');
  // const endT = repeatType === 'range' ?moment.unix(parseInt(data.originalEnd)).format('HH:mm A') : moment.unix(parseInt(endTimestamp)).format('HH:mm A');
  const startT = moment.unix(parseInt(startTimestamp)).format('HH:mm A');
  const endT = moment.unix(parseInt(endTimestamp)).format('HH:mm A');
  const { showDrawer, setShowDrawer, type, setHasEditedEvent } = props;
  const [isSubmitting, setSubmitting] = useState(false);
  const [eventName, setEventName] = useState(name);
  const [eventStartDate, setEventStartDate] = useState(dateStart);
  const [eventEndDate, setEventEndDate] = useState(dateEnd);
  const [start, setStart] = useState(moment(startT, format));
  const [end, setEnd] = useState(moment(endT, format));
  const [repeatUntil, setUntil] = useState(dateUntil);
  const [eventMenus, setEventMenus] = useState(EventMenus);
  const [menuList, setMenuList] = useState([]);
  const [eventColor, setEventColor] = useState(color);
  const [participants, setEventParticipants] = useState(EventOperators);
  const [operatorsToChoose, setOperatorsToChoose] = useState([]);
  const [locationOperators, setLocationOperators] = useState([]);
  const [selectedCustomDays, setSelectedCustomDays] = useState(custom_days);
  const [searchedParticipant, setSearchedParticipant] = useState('');
  const [guest, setGuest] = useState(guestInfo);
  const [repeat, setRepeat] = useState(r);
  const [eventDescription, setEventDescription] = useState(description);
  const [menus, setMenus] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  // const [restoId, setRestoId] = useState('');
  // const [isAdding, setIsAdding] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [showMenus, setShowMenus] = useState(false);
  const [eventData, setEventData] = useState([]);
  const windowSize = useWindowSize();
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/event`));
  const [catgUpdate, setCatgUpdate] = useState([]);
  const [catgAdd, setCatgAdd] = useState([]);
  const [catgDelete, setCatgDelete] = useState('');
  const [menuAdd, setMenuAdd] = useState({});
  const [menuUpdate, setMenuUpdate] = useState({});
  const [menu1Delete, setMenuDelete] = useState('');
  const [eventSocket2] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/menu`));
  const { TextArea } = Input;

  const currentOperator = JSON.parse(localStorage.getItem('operator')) || {};

  const restoInfo = JSON.parse(localStorage.getItem('restaurant')) || {};

  console.log(EventMenus);

  const resetForm = () => {
    setEventName('');
    setEventDescription('');
    setEventStartDate(moment());
    setEventEndDate(moment());
    setUntil(moment());
    setEventParticipants([]);
    setSelectedCustomDays([]);
    setEventColor(color);
    setStart(moment('00:00', format));
    setEnd(moment('00:00', format));
    setEventMenus([]);
    setShowMenus(false);
  };

  useEffect(() => {
    if (showDrawer && type === 'event') {
      getMenuListForEvents(setMenuList);
    }
  }, [showDrawer, type, setMenuList]);

  useEffect(() => {
    if (locationOperators.length === 0) {
      getAllStationOperators(restoInfo.id).then((res) => {
        setLocationOperators(res);
      });
    }
    getCategory();
  }, []);

  useEffect(() => {
    if (locationOperators.length > 0) {
      getEventParticipants(locationOperators, setOperatorsToChoose, participants);
    }
    // eslint-disable-next-line no-unused-vars
  }, [locationOperators]);

  useEffect(() => {
    props.form.setFieldsValue({
      title: name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (searchedParticipant !== '') {
      getEventParticipants(locationOperators, setOperatorsToChoose, participants);
    } else {
      if (locationOperators.length > 0) {
        setOperatorsToChoose(locationOperators);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedParticipant]);

  // const addMenuToEvent = (menu) => {
  //   const clonedEventMenus = cloneDeep(eventMenus);
  //   clonedEventMenus.push({ eventId: data.id, menuId: menu.id, originalMenuId: menu?.info?.originalMenuId });
  //   setEventMenus(clonedEventMenus);
  // };

  const handleAddParticipant = (operator) => {
    const clonedParticip = cloneDeep(participants) || [];
    clonedParticip.push({
      eventId: data.id,
      operatorId: operator.id,
      Operators: [
        {
          firstName: operator.firstName,
          lastName: operator.lastName,
        },
      ],
    });
    setEventParticipants(clonedParticip);
    setSearchedParticipant('');
  };

  useEffect(() => {
    if (participants?.length > 0) {
      getEventParticipants(locationOperators, setOperatorsToChoose, participants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  const checkIfDaySelected = (dayName) => {
    // for form control
    return includes(selectedCustomDays, dayName);
  };

  const handleAddCustomDay = (dayName) => {
    const cloneCustomDays = cloneDeep(selectedCustomDays);
    if (checkIfDaySelected(dayName)) {
      // have to remove
      remove(cloneCustomDays, (day) => {
        return day === dayName;
      });
    } else {
      cloneCustomDays.push(dayName);
    }
    setSelectedCustomDays(cloneCustomDays);
  };

  // const menuListItem = (
  //   <Menu className="menu-list-dropdown">
  //     {typeof menuList !== 'undefined' &&
  //       menuList.length > 0 &&
  //       menuList.map((m, i) => {
  //         let found = find(eventMenus, { menuId: m.id });
  //         if (!found) {
  //           return (
  //             <Menu.Item
  //               key={i}
  //               onClick={() => {
  //                 addMenuToEvent(m);
  //               }}
  //             >
  //               <a>{m.name}</a>
  //             </Menu.Item>
  //           );
  //         }
  //       })}
  //   </Menu>
  // );

  // let clonedOperators = cloneDeep(operatorsToChoose);
  // const availableOperators = (() => {
  //   // don't render selected participants
  // 	// CHANGE 1202077200318274
  // 	if (operatorsToChoose.length > 0) {
  // 		let clonedOperators = cloneDeep(operatorsToChoose);
  // 		let available = [];
  // 		for (let i = 0; i < clonedOperators.length; i++) {
  // 			let isExisting = find(participants, (p) => p.operatorId === parseInt(clonedOperators[i].id));
  // 			if (!isExisting) {
  // 				available.push(clonedOperators[i]);
  // 			}
  // 		}
  // 		return available;
  // 	}
  // 	return [];
  // })();

  const onClickRemoveParticipant = () => {
    setSearchedParticipant('');
    getEventParticipants(locationOperators, setOperatorsToChoose, participants);
  };

  // const participantList = (
  //   <div className="assignee-list">
  //     <List
  //       dataSource={availableOperators}
  //       renderItem={(item) => (
  //         <List.Item
  //           className="meeting-events-list-item"
  //           onClick={() => {
  //             handleAddParticipant(item);
  //           }}
  //         >
  //           <div>
  //             {item.firstName} {item.lastName}
  //           </div>
  //         </List.Item>
  //       )}
  //     />
  //   </div>
  // );
  const isEventSameDay = eventStartDate.format(dateFormat) === eventEndDate.format(dateFormat);

  const repeatOptions = (
    <Menu className="menu-show-preview">
      {repeats.map((m, i) => {
        return (
          <Menu.Item key={`menu-item-${i}`} className="menu-show-preview" onClick={() => setRepeat(m)}>
            <span>{m.label}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const disabledDate = (current) => {
    // Can not select days before today
    //return current && current < eventStartDate.endOf('day').clone().subtract(1, 'days');

    let disable =
      current &&
      (current < eventStartDate.clone().startOf('day') ||
        (repeat.name !== 'never' && current > eventStartDate.clone().endOf('day')));

    return disable;
  };

  const disabledDateUntil = (current) => {
    // Can not select days before today
    return current && current < eventStartDate.endOf('day');
  };

  const colorPicker = <SketchPicker color={eventColor} onChange={(color) => setEventColor(color.hex)} />;

  const handleSubmit = (prop) => {
    props.form.validateFields((err) => {
      if (!err) {
        setSubmitting(true);
        const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
        const operator = (JSON.parse(localStorage.getItem('operator')) || {}).email;

        const startFormatted = moment.isMoment(start) ? start : moment(start, format);
        const startTimeStamp = moment(
          `${eventStartDate.format(dateFormat)} ${startFormatted.format(format)}`,
          'MM / DD / YYYY hh:mm A'
        );

        const endFormatted = moment.isMoment(end) ? end : moment(end, format);
        let endTimeStamp = moment(
          `${eventEndDate.format(dateFormat)} ${endFormatted.format(format)}`,
          'MM / DD / YYYY hh:mm A'
        );

        endTimeStamp = moment.max(endTimeStamp, startTimeStamp); // event cannot be <0 length

        let untilTimeStamp = repeatUntil
          ? moment(`${repeatUntil.format(dateFormat)}`, 'MM / DD / YYYY').endOf('day')
          : endTimeStamp;

        const eventInfo = {
          color: eventColor,
        };

        if (type === 'meeting') {
          eventInfo.description = eventDescription;
        }

        if (type === 'event') {
          eventInfo.guest = guest;
        }

        // set 'repeat' field to 'never' or 'repeat'
        // and store repeat type/data in info
        let repeat_fieldval = repeat.name === 'never' ? 'never' : 'repeat';

        if (repeat.name != 'never') {
          endTimeStamp = moment.min(endTimeStamp, startTimeStamp.clone().add(1, 'days')); // repeating event cannot be > 1 day in length
          console.log('3 - ' + endTimeStamp.format('MM-DD-YYYY H:mm'));
          console.log(endTimeStamp);
          untilTimeStamp = moment.max(untilTimeStamp, endTimeStamp); // event until must be after end of event
          eventInfo.repeat_type = repeat.name;

          if (repeat.name === 'custom') {
            eventInfo.custom_days = selectedCustomDays;
          }
        }

        console.log('Update Event');
        console.log('Repeat: ' + repeat_fieldval);
        console.log('Start:  ' + startTimeStamp.format('MM-DD-YYYY H:mm'));
        console.log('  End:  ' + endTimeStamp.format('MM-DD-YYYY H:mm'));
        console.log('Until:  ' + (repeat_fieldval === 'never' ? 'N/A' : untilTimeStamp.format('MM-DD-YYYY H:mm')));

        const startUnix = startTimeStamp.unix();
        const endUnix = endTimeStamp.unix();
        const untilUnix = repeat_fieldval === 'never' ? null : untilTimeStamp.unix();

        updateEvent(data.id, {
          restaurantId: resto_id,
          name: eventName.trim(),
          startTimestamp: startUnix,
          endTimestamp: endUnix,
          until: untilUnix,
          repeat: repeat_fieldval,
          info: eventInfo,
          type,
          user: operator,
          participants:
            participants && participants?.length > 0
              ? participants
              : [
                  {
                    eventId: data.id,
                    operatorId: currentOperator.id,
                    Operators: [
                      {
                        firstName: currentOperator.firstName,
                        lastName: currentOperator.lastName,
                      },
                    ],
                  },
                ],
          menus: { event_menus: eventMenus },
        })
          .then((result) => {
            if (result) {
              eventSocket.emit('updatesEvent', 'updatedEvent');
              // activateChecklist
              const { id, EventMenus } = result;
              if (EventMenus.length) {
                getExistingEventMenus(data.id).then((res) => {
                  if (res && res.length) {
                    // filter out event menus with existing station checklists
                    EventMenus.filter((ev) => res.indexOf(parseInt(ev.menuId)) === -1).forEach((menu) => {
                      const { menuId } = menu;
                      activateEventChecklist(menuId, id).then((res) => {});
                    });
                  }
                });
              }
              // notification.open({
              //   message: 'Successfully Edited Event!',
              // });
              resetForm();
              setShowDrawer({ type: '', open: false });
              setHasEditedEvent(true);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        console.log(err);
      }
    });
  };

  const deleteEventStation = () => {
    console.log(data);
    const fetchData = () => {
      const allRequests = data.EventMenus.map((e) => unassignMenu(e.id, e.menuId).then((res) => res));

      return Promise.all(allRequests);
    };

    fetchData().then((res) => {
      console.log(res);
    });
    destroy(data.id).then((res) => {
      eventSocket.emit('updatesEvent', 'updatedEvent');
      // notification.open({
      //   message: 'Successfully Deleted Event!',
      // });
      resetForm();
      setShowDrawer({ type: '', open: false });
      setHasEditedEvent(true);
    });
  };

  const getCategory = () => {
    // setIsLoading(true);
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    // setRestoId(resto.id);
    let params = {
      restaurantId: resto.id,
    };
    searchMenuCategories(params).then((res) => {
      if (res && res.menucategories) {
        console.log('MENU CATS', res.menucategories);
        // setIsAdding(false);
        let classifyCtg = res.menucategories.map((c) => {
          c.type = 'parent';
          c.categoryId = c.id.toString();
          c.id = c.id.toString();
          c.restaurantId = c.restaurantId.toString();
          return c;
        });
        getMenus(classifyCtg, resto.id);
      }
    });
  };

  const getMenus = (cat, restoid) => {
    let getCatIds = cat.map((c) => {
      return c.id;
    });
    console.log('CATIDS', cat, getCatIds);

    searchMenu({ restaurantId: restoid }).then((res) => {
      if (res && res.menus) {
        console.log('GET ALL MENUS', res?.menus);
        let allMenus1 = res?.menus?.map((m) => {
          m.menuId = m.id.toString();
          m.info = m.info !== 'null' || m.info !== null ? JSON.parse(m.info) : null;
          return m;
        });
        let filterMenuss = allMenus1.filter((p) => getCatIds.includes(p.categoryId));

        let filterMenus = filterMenuss.filter((f) => f?.info?.active == true);

        console.log('FILTER MENUS CATID', filterMenus);

        let menuIds = filterMenus.map((f) => f.id);

        console.log('MENUIDS', menuIds);

        batchCourses(menuIds).then((res) => {
          if (res) {
            let allCourses = res.map((c) => {
              return c.MenuCourses;
            });
            console.log('ALL COURSES', allCourses);

            let merged = [].concat.apply([], allCourses);

            console.log(merged);

            let getCourseIds = merged.map((c) => {
              return c.courseId;
            });
            console.log('COURSEIDS++', getCourseIds);

            batchRecipes(getCourseIds).then((res) => {
              let mapRecs = res?.map((f) => {
                return f.CourseRecipes.map((c, i) => ({
                  menuId: res.menuId ? res.menuId : 0,
                  recipes: [{ active: c.active, id: c.recipeId, name: c?.Recipe?.name }],
                  courseId: f.id,
                }));
              });

              let newRecs = [].concat.apply([], mapRecs);

              console.log('NEWRECCSSS!!!', newRecs);

              let combineCourses = merged.concat(newRecs);
              console.log('COMBO MENU-COURSE-RECIPE++++', combineCourses);

              const combResult = Object.values(
                combineCourses.reduce((r, o) => {
                  const key = `${o.courseId}`;

                  if (!r[key]) r[key] = { ...o, recipes: [] };

                  r[key].recipes.push(o.recipes);

                  r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined && u !== null);

                  return r;
                }, {})
              );

              console.log('ADDED MENUIDS', combResult);

              const comboResult2 = Object.values(
                combResult.reduce((r, o) => {
                  const key = `${o.menuId}`;

                  if (!r[key]) r[key] = { ...o, recipes: [] };

                  r[key].recipes.push(o.recipes);

                  r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined && u !== null);

                  return r;
                }, {})
              );

              console.log('FINAL COMBO MENU_REC', comboResult2);

              let combineDeactivated = filterMenuss.concat(comboResult2)

              let combined = filterMenus.concat(comboResult2);
              console.log('COMBO', combined);

              //remove event menus from other categories
              let combined2 = combined.filter((e) => !e.info?.originalMenuId);

              const result = Object.values(
                combined2.reduce((r, o) => {
                  const key = `${o.menuId}`;

                  if (!r[key]) r[key] = { ...o, recipes: [] };

                  r[key].recipes.push(o.recipes);

                  r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined);

                  return r;
                }, {})
              );

              const result1 = Object.values(
                combineDeactivated.reduce((r, o) => {
                  const key = `${o.menuId}`;

                  if (!r[key]) r[key] = { ...o, recipes: [] };

                  r[key].recipes.push(o.recipes);

                  r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined);

                  return r;
                }, {})
              );

              const finalMenus = result.filter((m) => m.id);

              const allMenus = result1.filter((m) => m.id);

              console.log('MENUS WITH RECIPES+++', finalMenus);

              setMenuList(result);

              let eventChecked = eventMenus.map((e) => e.menuId);

              let eventsChecked = eventChecked.map(String);

              console.log('EVENTMENUSSSSSSS!!!', eventsChecked);

              //BUG 1202594144743641
              console.log(allMenus)
              const checkedMenus = allMenus.filter((c) => eventsChecked.includes(c.menuId));
              console.log('CHECKED MENUS', checkedMenus);
              setEventData(checkedMenus);

              let categoryMenus = Object.values(
                finalMenus.reduce((result, { categoryId, id, type, recipes, name, info }) => {
                  if (!result[categoryId])
                    result[categoryId] = {
                      categoryId,
                      menus: [],
                    };
                  result[categoryId].menus.push({
                    categoryId,
                    id,
                    key: id,
                    name,
                    type: 'child',
                    prepped: info,
                    activate: info?.active,
                    recipes: recipes ? recipes : [],
                    activerecipes: recipes ? recipes.filter((r) => r.active == true) : [],
                    assignee: info?.createdBy ? info?.createdBy : '',
                    tags: ['tag 1', 'tag 2', 'tag 3'],
                    texts: 16,
                    attachments: 8,
                    checked: eventsChecked.includes(id) ? true : false,
                  });
                  return result;
                }, {})
              );

              console.log(categoryMenus);

              let combinedMenus = cat.concat(categoryMenus);
              console.log('COMBOMENUS', combinedMenus);

              const resultMenus = Object.values(
                combinedMenus.reduce((r, o) => {
                  const key = `${o.categoryId}`;

                  if (!r[key]) r[key] = { ...o, menus: [] };

                  r[key].menus.push(o.menus);

                  r[key].menus = [].concat.apply([], r[key].menus).filter((u) => u !== undefined);

                  return r;
                }, {})
              );

              console.log('MENUS WITH CATEGORIES', resultMenus);

              let tableData = resultMenus.map((m, i) => {
                m.children = m.menus;
                m.menuCount = m.children.filter((d) => d.name !== '');
                m.key = i + 1;
                m.checkedCount = m.children.reduce((acc, cur) => (cur.checked === true ? ++acc : acc), 0);
                return m;
              });

              //move event menus to their own category

              const eventMenus1 = combined.filter((e) => (e.eventName && e.info?.originalMenuId) || e.recipes);

              console.log('EVENTMENUS', eventMenus1);

              let newObj = {};

              if (eventMenus1 && eventMenus1.length > 0) {
                //find if category exists
                const uncategorized = cat.find((e) => e.name == 'UNCATEGORIZED');
                console.log('EVENTEXISTS', uncategorized);
                if (!uncategorized) {
                  const eventresult = Object.values(
                    eventMenus1.reduce((r, o) => {
                      const key = `${o.menuId}`;

                      if (!r[key]) r[key] = { ...o, recipes: [] };

                      r[key].recipes.push(o.recipes);

                      r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined);

                      return r;
                    }, {})
                  );

                  const finalEventMenus = eventresult.filter((m) => m.id);

                  console.log('menuList', eventresult);
                  console.log('MENUS WITH RECIPES+++', finalEventMenus);

                  let childMenus = finalEventMenus.map((c) => {
                    c.key = c.id;
                    c.type = 'child';
                    c.prepped = c.info;
                    c.activate = c.info?.active;
                    c.recipes = c.recipes ? c.recipes : [];
                    c.activerecipes = c.recipes ? c.recipes.filter((r) => r.active == true) : [];
                    c.assignee = c.info?.createdBy ? c.info?.createdBy : '';
                    c.tags = c.info?.tags ? c.info?.tags : [];
                    c.texts = 15;
                    c.attachments = 5;
                    c.categorized = 'no';
                    c.checked = eventsChecked.includes(c.id) ? true : false;
                    return c;
                  });

                  console.log('EVENTCHILDMENUS', childMenus);

                  newObj.key = cat?.length + 1;
                  newObj.categoryId = 'UNCAT1';
                  newObj.id = 'UNCAT1';
                  newObj.children = childMenus.sort((a, b) => (a.name > b.name ? 1 : -1));
                  newObj.menuCount = childMenus.filter((d) => d.name !== '');
                  newObj.menus = childMenus.filter((d) => d.name !== '');
                  newObj.type = 'parent';
                  newObj.name = 'UNCATEGORIZED';
                  newObj.checkedCount = childMenus.reduce((acc, cur) => (cur.checked === true ? ++acc : acc), 0);
                }
              }

              console.log('NEWOBJ1', newObj);

              console.log('MENUS WITH CATEGORIES', resultMenus);

              if (Object.keys(newObj).length !== 0) {
                tableData.push(newObj);
              }

              console.log('TABLEDATA', tableData);
              // setIsLoading(false);
              setMenus(tableData);
            });
          } else {
            // setIsLoading(false);
          }
        });
      } else {
        console.log(cat);
        let noMenus = cat.map((c, i) => {
          c.children = [{ assignee: '', catId: c.categoryId, key: c.categoryId, name: '', tags: [], type: 'child' }];
          c.menuCount = [];
          c.menus = [];
          c.key = i;
          return c;
        });
        setMenus(noMenus);
        setMenuList([]);
        // setIsLoading(false);
      }
    });
  };

  const handleRowExpand = (record) => {
    if (expandedRows.includes(record.key)) {
      let filterRecord = expandedRows.filter((key) => key !== record.key);
      setExpandedRows(filterRecord);
    } else {
      setExpandedRows([...expandedRows, record.key]);
    }
  };

  const deleteMenu = (e, obj) => {
    toggleSelect(e, obj, 'selected');
    unassignMenu(obj.id, obj.menuId).then((res) => res);
  };

  const toggleSelect = (e, obj, status) => {
    console.log(e, obj, status);
    if (status === 'selected') {
      let addSelectedLinks = menus.map((o) => {
        o.children = o.children.map((c) => {
          if (obj.id == c.id) {
            c.checked = false;
            o.checkedCount = o.children.reduce((acc, cur) => (cur.checked === true ? ++acc : acc), 0);
          }
          return c;
        });
        return o;
      });
      const clonedEventMenus = cloneDeep(eventMenus);
      clonedEventMenus.forEach((v) => (v.menuId += ''));
      let filtered = clonedEventMenus.filter((item) => item.menuId !== obj.id);
      console.log(clonedEventMenus);
      console.log(filtered);
      setEventMenus(filtered);
      setMenus(addSelectedLinks);
      if (eventData && eventData.length > 0) {
        const arr = eventData.filter((item) => item.id !== obj.id);
        setEventData(arr);
      }
    } else {
      let addSelectedLinks = menus.map((o) => {
        o.children = o.children.map((c) => {
          if (obj.id == c.id) {
            c.checked = true;
            o.checkedCount = o.children.reduce((acc, cur) => (cur.checked === true ? ++acc : acc), 0);
          }
          return c;
        });
        return o;
      });

      const clonedEventMenus = cloneDeep(eventMenus);
      clonedEventMenus.push({ eventId: data.id, menuId: obj.id, originalMenuId: obj?.info?.originalMenuId });
      console.log(clonedEventMenus);
      setEventMenus(clonedEventMenus);
      setMenus(addSelectedLinks);
      setEventData((oldArray) => [...oldArray, { name: obj.name, recipes: obj.recipes, id: obj.id }]);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: 320,
      render: (key, obj) => (
        <span style={{ fontFamily: 'Helvetica Neue' }}>
          <span className={obj.type == 'parent' ? '' : 'name-key'}>
            {key && (
              <span className={obj.selected ? 'highlight' : ''}>
                {obj.type == 'child' ? (
                  <span>
                    <span>
                      {obj.checked ? (
                        <span className="checkmark-icon" onClick={(e) => toggleSelect(e, obj, 'selected')}>
                          <CompletionIcon completed={true} dimmed={false} />
                        </span>
                      ) : (
                        <span className="checkmark-icon" onClick={(e) => toggleSelect(e, obj, 'unselected')}>
                          <CompletionIcon completed={false} dimmed={true} />
                        </span>
                      )}
                    </span>
                    <span class="event-folder-icon">
                      <FolderIcon />
                    </span>
                    <span className="menu-Namespan">
                      <span>{key}</span>{' '}
                    </span>
                    <span style={{ fontFamily: 'Helvetica Neue', marginLeft: 15 }}>
                      (
                      {obj.type == 'child' && obj.recipes ? (
                        <span>{`${obj.recipes.length} recipe${obj.recipes.length != 1 ? 's' : ''}`}</span>
                      ) : (
                        ''
                      )}
                      )
                    </span>
                  </span>
                ) : (
                  <span>
                    <span style={{ marginLeft: 15 }}>{key}</span>
                  </span>
                )}
              </span>
            )}{' '}
            {obj.type == 'parent' ? (
              <span className="doc-upload-count" style={{ fontSize: 14 }}>
                ( {obj.checkedCount ? obj.checkedCount : '0'} of{' '}
                {obj.menuCount ? `${obj.menuCount.length} menus` : 'no menus'} )
              </span>
            ) : (
              ''
            )}
          </span>
        </span>
      ),
    },
  ];

  useEffect(() => {
    eventSocket2.on('updatedMenu', (message) => {
      let newData = JSON.parse(message);
      console.log('CATUPDATED!!', [newData]);
      setCatgUpdate([newData]);
    });
    eventSocket2.on('updatedMenuItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMUPDATED!!', newData);
      setMenuUpdate(newData);
    });
    eventSocket2.on('addedMenu', (message) => {
      let newData = JSON.parse(message);
      console.log('CATADDED!!', newData);
      setCatgAdd(newData);
    });
    eventSocket2.on('addedMenuItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMADDED!!', newData);
      setMenuAdd(newData);
    });
    eventSocket2.on('deletedMenu', (message) => {
      let newData = JSON.parse(message);
      console.log('CATDELETED!!', newData.id);
      setCatgDelete(newData.id);
    });
    eventSocket2.on('deletedMenuItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMDELETED!!', newData.id);
      setMenuDelete(newData.id);
    });
    return () => {
      eventSocket.disconnect();
      eventSocket2.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(catgAdd).length > 0) {
      getCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catgAdd]);

  useEffect(() => {
    if (catgDelete !== '') {
      getCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catgDelete]);

  useEffect(() => {
    if (catgUpdate.length > 0) {
      getCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catgUpdate]);

  return (
    <>
      <Drawer
        width={windowSize.width > 800 ? '500px' : '100%'}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
        placement="right"
        closable={false}
        onClose={() => {
          setShowDrawer({ type: '', open: false });
          resetForm();
        }}
        visible={showDrawer}
      >
        <div className="scroll-div1">
          <>
            <Row gutter={32}>
              <Col className="gutter-row" span={21}></Col>
              <Col className="gutter-row" span={3}>
                <Button
                  className="close-add-event"
                  onClick={() => {
                    setShowDrawer({ type: '', open: false });
                    resetForm();
                  }}
                  type="primary"
                  shape="circle"
                  size={`small`}
                >
                  <CloseCircleOutlined />
                </Button>
              </Col>
            </Row>
            <Row gutter={32}>
              {type === 'event' ? (
                <div style={{ paddingLeft: 16 }}>
                  <span className="event-type-designator">Event</span>
                  <Link to={`/event/station/${data.id}`}>
                    <span className="edit-event-link">View event station</span>
                  </Link>
                </div>
              ) : (
                <span className="event-type-designator" style={{ paddingLeft: 16 }}>
                  Meeting
                </span>
              )}
            </Row>
            <Form
              wrapperCol={{ span: 24 }}
              layout="horizontal"
              // initialValues={{ size: componentSize }}
              // onValuesChange={onFormLayoutChange}
              // size={componentSize as SizeType}
            >
              <Row gutter={32}>
                <Col className="gutter-row" span={24}>
                  {/* <div style={{ textAlign: 'center' }}>{isLoading && <Spin tip="Loading menus..."></Spin>}</div> */}
                  <Form.Item style={{ color: 'white' }} name="title">
                    <span>Title</span>
                    {getFieldDecorator('title', {
                      rules: [{ required: true, message: 'Title is required' }],
                    })(
                      <input
                        type="text"
                        className="event-name-input"
                        placeholder={`Enter Title`}
                        value={eventName}
                        onChange={(e) => setEventName(e.currentTarget.value)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Popover content={colorPicker} trigger="click" placement="right">
                    <div
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 4,
                        backgroundColor: eventColor,
                        cursor: 'pointer',
                      }}
                    ></div>
                  </Popover>
                </Col>
                {type === 'event' && (
                  <>
                    <Col className="gutter-row" span={12}>
                      <Form.Item style={{ color: 'white' }}>
                        <span>Guests</span>
                        <input
                          type="number"
                          className="event-name-input"
                          value={guest}
                          onChange={(e) => setGuest(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}></Col>
                  </>
                )}

                <Col className="gutter-row" span={12}>
                  <Form.Item style={{ color: 'white' }}>
                    <div>Start</div>
                    <DatePicker
                      getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                      onChange={(e) => setEventStartDate(e)}
                      defaultValue={eventStartDate}
                      format={dateFormat}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item style={{ color: 'white', paddingTop: 40 }}>
                    <TimePicker
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={(e) => {
                        setStart(e.format(format));
                      }}
                      defaultValue={start}
                      use12Hours
                      minuteStep={5}
                      format={format}
                    />
                  </Form.Item>
                </Col>
                {
                  // repeat.name === 'never' && (
                  <Col className="gutter-row" span={12}>
                    <Form.Item style={{ color: 'white' }}>
                      <div>End</div>
                      <DatePicker
                        getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                        onChange={(e) => setEventEndDate(e)}
                        disabledDate={disabledDate}
                        defaultValue={eventEndDate}
                        format={dateFormat}
                        //disabled={repeat.name != 'never'}
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  // repeat.name === 'never' && (
                  <Col className="gutter-row" span={12}>
                    <Form.Item style={{ color: 'white', paddingTop: 40 }}>
                      <TimePicker
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onChange={(e) => {
                          setEnd(e.format(format));
                        }}
                        defaultValue={end}
                        use12Hours
                        minuteStep={5}
                        format={format}
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  // isEventSameDay && (
                  <>
                    <Col className="gutter-row" span={12}>
                      <Form.Item style={{ color: 'white' }}>
                        <div>Repeat</div>
                        <Dropdown
                          overlay={repeatOptions}
                          placement="bottomCenter"
                          trigger={['click']}
                          // onChange={(e) => {
                          //   updateRepeatingEndDate(e); does not work
                          // }}
                        >
                          <div className="event-name-input">{repeat.label}</div>
                        </Dropdown>
                      </Form.Item>
                    </Col>
                    {repeat.name != 'never' && (
                      <>
                        <Col className="gutter-row" span={12}>
                          <Form.Item style={{ color: 'white' }}>
                            <div>Until</div>
                            <DatePicker
                              getCalendarContainer={(triggerNode) => triggerNode.parentElement}
                              disabledDate={disabledDateUntil}
                              onChange={(e) => setUntil(e)}
                              defaultValue={repeatUntil}
                              format={dateFormat}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                }

                {isEventSameDay && repeat.name === 'custom' && (
                  <>
                    <Col className="gutter-row" span={24}>
                      <div style={{ color: 'white', fontSize: 15, padding: 10 }}> Repeat Days</div>
                    </Col>
                    {daysOfWeek.map((day) => {
                      return (
                        <Col className="gutter-row" span={12}>
                          <Checkbox
                            className="day-checkbox"
                            checked={checkIfDaySelected(day)}
                            onChange={() => handleAddCustomDay(day)}
                          >
                            {day}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </>
                )}
                {type === 'meeting' && (
                  <>
                    <Col className="gutter-row" span={24}>
                      <div style={{ color: 'white', fontSize: 15, padding: 10 }}> Participants</div>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <div className="participant-box">
                        <span className="participant-name">
                          {participants &&
                            participants?.length > 0 &&
                            participants.map((participant) => {
                              return (
                                <Tag
                                  color={'black'}
                                  className="tag-participant"
                                  key={participant.operatorId}
                                  closable={true}
                                  onClose={() => {
                                    const clonedP = cloneDeep(participants);
                                    remove(clonedP, { operatorId: participant.operatorId });
                                    setEventParticipants(clonedP);
                                  }}
                                >
                                  {`${participant.Operators[0].firstName} ${participant.Operators[0].lastName}`}
                                </Tag>
                              );
                            })}
                        </span>
                        {/* CHANGE 1202077200318274 */}
                        <MeetingParticipantAdd
                          participantChoices={operatorsToChoose}
                          participant={searchedParticipant}
                          placeholder={'Add Participant'}
                          onChange={(value) => {
                            handleAddParticipant(JSON.parse(value));
                          }}
                          onSearch={(value) => {
                            setSearchedParticipant(value);
                          }}
                          onClickRemove={() => {
                            onClickRemoveParticipant();
                          }}
                        />
                        {/* <Popover
                          content={participantList}
                          getPopupContainer={(trigger) => trigger.parentElement}
                          overlayStyle={{ marginTop: 5, marginLeft: 0, width: '140px', backgroundColor: '#454545' }}
                          placement="bottom"
                          trigger="focus"
                        >
                          <input
                            type="text"
                            className="participant-input"
                            placeholder="Add Participant"
                            value={searchedParticipant}
                            onChange={(e) => {
                              setSearchedParticipant(e.target.value);
                            }}
                          />
                        </Popover> */}
                      </div>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <div style={{ color: 'white', fontSize: 15, padding: 10 }}> Description</div>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <TextArea
                        value={eventDescription}
                        onChange={(e) => setEventDescription(e.target.value)}
                        style={{ backgroundColor: 'var(--deep-fill)', color: 'white', border: 0 }}
                        className="textarea-inputs"
                        rows={5}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row gutter={32}>
                {type === 'event' && (
                  <>
                    {/* <Col className="gutter-row" span={8}>
                      <div className="event-menu-list">
                        <div>{'Select menus'}</div>
                      </div>
                    </Col>
                    <Col className="gutter-row" span={1}>
                      <Dropdown overlay={menuListItem} trigger={['click']}>
                        <div className="dropdown-icon-menu">
                          <i className="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                      </Dropdown>
                    </Col>
                    <Col className="gutter-row" span={15}></Col>
                    <Col className="gutter-row" span={8}></Col>
                    <Col className="gutter-row" span={24}>
                      <br />
                      <br />
                      <div className="meeting-reminders-future-title">Event Menus</div>
                      <div className="meeting-events-panel-display">
                        <List
                          dataSource={eventMenus}
                          renderItem={(item) => {
                            let foundMenu = find(menuList, { id: item.menuId });
                            console.log(menuList);
                            return (
                              <List.Item className="meeting-events-list-item">
                                <div>
                                  <span className="list-bullet"></span>
                                  {foundMenu ? foundMenu.name : ''}
                                </div>
                              </List.Item>
                            );
                          }}
                        />
                      </div>
                    </Col> */}
                    <br />
                    <div className="meeting-reminders-future-title" style={{ marginLeft: 10 }}>
                      Selected ({eventMenus && eventMenus.length > 0 ? eventMenus.length : 0})
                    </div>
                    <br />
                    <div>
                      {eventData &&
                        eventData.length > 0 &&
                        eventData.map((obj) => {
                          return (
                            <div>
                              <div style={{ display: 'flex', color: 'var(--base-text)' }}>
                                <span class="event-folder-selected-icon">
                                  <FolderIcon />
                                </span>
                                <div className="menu-Namespan eventData-name">
                                  {obj.name ? obj.name : 'Untitled'}
                                  {/* {obj.name} and obj.recipes below are not defined */}
                                </div>
                                <div style={{ fontFamily: 'Helvetica Neue', marginLeft: 15, marginTop: 7 }}>
                                  (<span>{`${obj.recipes.length} recipe${obj.recipes.length != 1 ? 's' : ''}`}</span>)
                                </div>

                                <Link to={`/menu/${obj.menuId}`}>
                                  <div class="eventData-viewMenu"> View menu</div>
                                </Link>
                                <div class="eventData-deleteMenu" onClick={(e) => deleteMenu(e, obj)}>
                                  Remove menu
                                </div>
                              </div>
                              <hr class="doc-line3" />
                            </div>
                          );
                        })}
                    </div>

                    {menus.length > 0 && !showMenus && (
                      <LinkButton
                        className="button-link-replacement event-menu-add"
                        alt="add menu"
                        onClick={() => {
                          setShowMenus(true);
                        }}
                      >
                        Add menu
                      </LinkButton>
                    )}
                    <br />
                    <br />
                    {showMenus && (
                      <div>
                        <div className="meeting-reminders-future-title">Select Menus</div>
                        <div>
                          <Table
                            columns={columns}
                            dataSource={menus}
                            rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
                            pagination={false}
                            onExpand={(expanded, record) => handleRowExpand(record)}
                            expandedRowKeys={expandedRows}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Row>
              <div>
                <button className="edit-event-btn" htmltype="submit" onClick={handleSubmit}>
                  {isSubmitting ? <Spin /> : type === 'event' ? 'Update' : 'Update'}
                </button>
                <span
                  onClick={deleteEventStation}
                  style={{ color: 'var(--accent-color)', fontSize: 16, cursor: 'pointer' }}
                >
                  Delete
                </span>
              </div>
            </Form>
          </>
        </div>
      </Drawer>
    </>
  );
};

export default Form.create()(EditEventDrawerV2);
