/* eslint eqeqeq: "off" */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import './index.css';

const ProgressCircle = (props) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);
  const { size, progress, strokeWidth, xText, yText, type, ing, ingtotal, time, totaltime, resolution } = props;

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    console.log(type);
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);

    circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOffset, progress, circumference, offset]);

  return (
    <>
      <svg className="progress-circle-container" width={size} height={size}>
        <circle
          className="progress-circle-bg"
          stroke="currentColor"
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="progress-circle"
          ref={circleRef}
          stroke="currentColor"
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        {type == 'percent' && (
          <text
            x={`${xText}`}
            y={`${yText}`}
            className={resolution == 'mobile' ? 'progress-circle-text-mobile' : 'progress-circle-text'}
          >
            {progress}%
          </text>
        )}
        {type == 'ingredients' && (
          <text
            x={`${xText}`}
            y={`${yText}`}
            className={resolution == 'mobile' ? 'progress-circle-text-mobile' : 'progress-circle-text'}
          >
            {ing} / {ingtotal}
          </text>
        )}
        {type == 'time' && (
          <text
            x={`${xText}`}
            y={`${yText}`}
            className={
              resolution == 'mobile' ? 'progress-circle-text-mobile' : 'progress-circle-text progress-circle-time'
            }
          >
            {time}:{totaltime}hr
          </text>
        )}
      </svg>
    </>
  );
};

ProgressCircle.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
};

export default ProgressCircle;
