/* eslint eqeqeq: "off" */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TabCat from './tabCat';
import { courses } from '../../services/menu.service';
import { searchRecipeCategories, updateRecipeCategory } from '../../services/category.service';
//import { takeRightWhile } from 'lodash';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
//import { notification } from 'antd';

class TabsCat extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  recipeLabel;

  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      stateChanged: false,
      children1: [],
      activeTab: this.props.page == 'recipe' || this.props.page == 'category' ? '' : this.props.children[0].props.label,
    };
    this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
  }

  onClickTabItem = (tab) => {
    console.log(tab);
    // this.setState({ stateChanged: true });
    this.setState({ activeTab: tab });
  };

  componentDidMount() {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    if (this.props.menuId && this.props.page == 'recipe') {
      courses(this.props.menuId).then((res) => {
        let result = res.MenuCourses;
        result.sort((x, y) => {
          return y.courseId - x.courseId;
        });
        console.log(result);
        this.recipeLabel = result.length > 0 ? result[0].Course.name : 'New Course';
        console.log(this.recipeLabel);
        this.setState({ activeTab: this.recipeLabel });
      });
    }

    if (this.props.page == 'category') {
      let params = {
        restaurantId: resto.id,
      };
      searchRecipeCategories(params).then((res) => {
        console.log('CATEGORIES', res);
        let result = res;
        const name_changer = (n) => {
          let splitted = n.split('_.');
          if (splitted.length > 1) {
            let name = splitted[0];
            let ord = parseInt(splitted[1]);
            return `${name}_.${ord + 1}`;
          }
          return `${n}_.${1}`;
        };

        let stack = [];

        result.forEach((obj) => {
          if (stack.indexOf(obj.name) > -1) {
            obj.name = name_changer(obj.name);
          }
          stack.push(obj.name);
        });

        let results = res.sort((x, y) => {
          return parseInt(x.order) - parseInt(y.order) || parseInt(y.id) - parseInt(x.id);
        });
        console.log(results)
        this.recipeLabel = results.length > 0 ? results[0].name : 'New Course';
        console.log('CAT LABEL', this.recipeLabel);
        this.setState({ activeTab: this.recipeLabel });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps !== this.props &&
      this.props.children.length > 0 &&
      this.props.children[0].props.label == 'New Category'
    ) {
      console.log(this.props.children[0].props.label);
      this.setState({ activeTab: this.props.children[0].props.label });
    }
    if (prevProps !== this.props && this.props.children.length == 1) {
      console.log(this.props.children[0].props.label);
      this.setState({ activeTab: this.props.children[0].props.label });
    }
  }

  handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(this.state.children1);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    console.log(items);
    this.setState({ children1: items });
    this.setState({ stateChanged: true });

    //save course order

    let getCatIds = items.map((item, index) => {
      let catArr = JSON.parse(item.key);
      return {
        id: catArr.id,
        order: index,
        restaurantId: catArr.restaurantId,
        name: catArr.name,
      };
    });

    console.log('CATEGORY IDS', getCatIds);

    const fetchData = () => {
      const allRequests = getCatIds.map((e, index) =>
        updateRecipeCategory(e.id, { order: e.order, restaurantId: e.restaurantId, name: e.name }).then((res) => res)
      );

      return Promise.all(allRequests);
    };

    fetchData().then((res) => {
      // notification.open({
      //   message: 'Successfully updated category order!',
      // });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  }

  static getDerivedStateFromProps(props, state) {
    console.log(state.stateChanged);
    console.log(props.children);
    console.log(state.children1);
    if (props.children && props.children.length > 0 && !state.stateChanged) {
      state.children1 = props.children;
      //state.activeTab = props.children[0].props.label;
    }
  }

  render() {
    const {
      onClickTabItem,
      //props: { children },
      state: { activeTab, children1 },
    } = this;

    return (
      <div className="tabs">
        <div
          className={
            this.props.page == 'recipe' || this.props.page == 'category' ? 'tab-nav-wrapper-recipe' : 'tab-nav-wrapper'
          }
        >
          {' '}
          <DragDropContext onDragEnd={this.handleOnDragEnd}>
            <Droppable droppableId="droppableId" direction="horizontal" type="droppableItem">
              {(provided, snapshot) => (
                <ol className="tab-list" {...provided.droppableProps} ref={provided.innerRef}>
                  {children1.map((child, i) => {
                    const { label } = child.props;

                    //   return <TabCat activeTab={activeTab} key={label} label={label} onClick={onClickTabItem} />;
                    return (
                      <TabCat
                        activeTab={activeTab}
                        key={label}
                        label={label}
                        onClick={onClickTabItem}
                        indexed={i.toString()}
                      />
                    );
                  })}
                  {provided.placeholder}
                </ol>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="tab-content">
          {children1.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default TabsCat;
