import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import './index.css';
import { Menu, Dropdown, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  deleteKitchenStation,
  updateStation,
  updateKitchenStation,
  createKitchenStation,
  getAllStationOperators,
} from '../../services/station.service';
import { getKitchenStationList } from './KitchenStationUtils';

import RoleAccessibleComponent from '../RoleAccess/roleaccess';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';

import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';

import { KITCHENSTATIONNAME } from '../../components/common/constant.js';
import {
  PlusIcon,
  UserImage,
  RowOkCancel,
  StationAssigneeEdit,
  CidekicProgress,
} from '../../components/common/common_ui';
import { useMeasure } from 'react-grid-dnd/esm/use-measure';
import EditKitchenStation from './EditKitchenStation';

const newValue = {
  id: 0,
  key: 0,
  station: '',
  percentage: 0,
  assignee_id: 0,
  assigneeImg: '',
  assignee: '',
};

const KitchenStations = () => {
  const [kitchenStations, setKitchenStations] = useState([]);
  const [newKitchenStation, setNewKitchenStation] = useState(null);
  const [locationOperators, setLocationOperators] = useState([]);
  const [operatorsToChoose, setOperatorsToChoose] = useState([]);
  const [addKitchenUpdate, setAddKitchenUpdate] = useState('');
  const [editKitchenUpdate, setEditKitchenUpdate] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/station`));
  const [dragPointer, setDragPointer] = useState('pointer');
  const [dragState, setDragState] = useState(false);
  const [addState, setAddState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [cancelEdit, setCancelEdit] = useState(false);
  const history = useHistory();
  const [safeToDrop, isSafeToDrop] = useState(true);

  const resto_id = localStorage.getItem('restaurantId');

  useEffect(() => {
    if (locationOperators.length === 0) {
      getAllStationOperators(resto_id).then((res) => {
        setLocationOperators(res);
      });
    }
  }, []);

  const handleOnClick = useCallback(
    (id, restaurantId) => {
      if (!dragState) {
        // console.log('CLICK', dragState);
        // console.log(`Going to /station/${id}`);
        history.push({ pathname: `/station/${id}`, state: { restaurantId:  restaurantId.length ? restaurantId * 1 : resto_id }});
      }
      // console.log('NOTE: navigating to station temp disabled')
    },
    [history, dragState]
  );

  const handleUpdate = useCallback(
    () =>
      setTimeout(() => {
        eventSocket.emit('updatesKitchen', 'updatedKitchen');
      }, 1000),
    [kitchenStations]
  );

  useEffect(() => {
    console.log('DRAGGING STATE', dragState);
  }, [dragState]);

  const handleEditState = useCallback((editMode) => setEditState(editMode));

  const cancelNewKitchen = useCallback((mode) => {
    setNewKitchenStation(null);
    setAddState(false);
    let removeKitchenStation = kitchenStations.filter(
      (obj) => !(obj && Object.keys(obj).length === 0 && obj.constructor === Object)
    );
    setKitchenStations(removeKitchenStation);
  });

  // const handleCurrentStation = (e) => {
  // 	console.log('CURRENT STATION ========>', e);
  // }

  const node1 = useRef();
  const containerRef = useRef();

  // const isValidated = newKitchenStation
  //   ? newKitchenStation.station !== newValue.station &&
  //     newKitchenStation.station.trim() !== '' &&
  //     newKitchenStation.assignee_id > 0
  //   : false;

  // useEffect(() => {
  //   if (searchedAssignee !== '') {
  //     const likeOperators = locationOperators.filter(op => op.firstName.toLowerCase().indexOf(searchedAssignee.toLowerCase()) !== -1 || op.lastName.toLowerCase().indexOf(searchedAssignee.toLowerCase()) !== -1);
  // 		setOperatorsToChoose(likeOperators);
  //   } else {
  // 		if (locationOperators.length > 0) {
  // 			setOperatorsToChoose(locationOperators);
  // 		}
  // 	}
  // }, [searchedAssignee]);

  useEffect(() => {
    setOperatorsToChoose([]);
    setLocationOperators([]);
    let restoId = window.location.href.includes('impersonate') ? localStorage.getItem('restaurantId') : resto_id;
    getAllStationOperators(restoId).then((res) => {
      setLocationOperators(res);
    });
  }, [window.location.href, resto_id]);

  useEffect(() => {
    if (locationOperators.length > 0) {
      setOperatorsToChoose(locationOperators);
    }
  }, [locationOperators]);

  // useEffect(() => {
  //   document.addEventListener('mousedown', (e) => {
  //     console.log('mousedown');
  //     setDragState(false);
  //     handleMouseMove(e);
  //   });

  //   return () => document.removeEventListener('mousedown', handleMouseMove);
  // }, []);

  // const handleMouseMove = useCallback(
  //   (e) => {
  //     const drag = (e) => {
  //       setDragPointer('grab');
  //       setDragState(true);
  //       console.log('mouse is moving');
  //     };

  //     const lift = (e) => {
  //       setDragPointer('pointer');
  //       console.log('mouse move ended');
  //       window.removeEventListener('mousemove', drag);
  //       window.removeEventListener('mouseup', this);
  //     };

  //     window.addEventListener('mousemove', drag);
  //     window.addEventListener('mouseup', lift);
  //   },
  //   [dragState]
  // );

  // useEffect(() => {
  //   if (window.location.href.includes('impersonate')) {
  //     const restoId = localStorage.getItem('restaurantId');
  //     getKitchenStationList(setKitchenStations, restoId);
  //   } else {
  //     getKitchenStationList(setKitchenStations, resto_id);
  //   }
  // }, [hasCreatedStation, window.location.href]);

  // useEffect(() => {
  //   if (hasCreatedKitchen) {
  //     let removeKitchenStation = kitchenStations.filter(
  //       (obj) => !(obj && Object.keys(obj).length === 0 && obj.constructor === Object)
  //     );
  //     setKitchenStations(removeKitchenStation);
  //     eventSocket.emit('addsKitchen', 'addedKitchen');
  //     setHasCreatedKitchen(false);
  //     handleUpdate();
  //   }
  // }, [hasCreatedKitchen]);

  // const resetForm = () => {
  //   setNewKitchenStation();
  //   setSearchedAssignee('');
  // };

  // const validateKitchen = useCallback(() => {
  //   if (newKitchenStation.station !== newValue.station &&
  //     newKitchenStation.station.trim() !== '' &&
  //     newKitchenStation.assignee_id > 0) {
  //     handleAddStations();
  //   }
  // }, [newKitchenStation]);

  // useEffect(() => {
  //   console.log('NEWKITCHEN', newKitchenStation);
  //   setAddState(true);
  //   if (newKitchenStation !== null) {
  //     document.addEventListener('mousedown', handleClick);
  //     console.log(newKitchenStation);
  //     // if (newKitchenStation?.assignee && newKitchenStation?.assignee !== '') {
  //     // validateKitchen()
  //     //   handleAddStations();
  //     // }
  //     return () => {
  //       document.removeEventListener('mousedown', handleClick);
  //     };
  //   }
  // }, [newKitchenStation]);

  // const handleClick = (e) => {
  //   if (node1.current && node1.current.contains(e.target)) {
  //     return;
  //   }
  //   // outside click
  //   // validateKitchen();
  //   handleAddStations();
  // };

  // useEffect(() => {
  //   console.log('KITCHSTA', kitchenStations);
  // }, [kitchenStations]);

  // const totalCompletedPercentages =
  //   kitchenStations.length > 0
  //     ? kitchenStations.reduce((a, b) => ({ percentage: parseInt(a.percentage) + parseInt(b.percentage) }))
  //     : { percentage: 0 };
  // const totalPercentages = kitchenStations.length * 100;
  // const filledPercentages = kitchenStations.filter((p) => p.percentage !== '0');
  // const totalPercentages = filledPercentages.length * 100;
  // const accumulativePerc = Math.round((totalCompletedPercentages.percentage / totalPercentages) * 100);

  const handleAddStation = (restaurantId, name, assignee_id) => {
    if (name.trim() !== '' && assignee_id > 0) {
      // console.log("handleAddStation", restaurantId, name, assignee_id);
      createKitchenStation({ restaurantId: restaurantId, name: name.trim(), operatorId: assignee_id }).then((res) => {
        // console.log("compledted handleAddStation", res);
        notification.open({
          message: `${KITCHENSTATIONNAME} Successfully Created`,
        });
        setNewKitchenStation(null);
        reloadKitchenStations();
      });
    }
  };
  // const handleAddStations = useCallback(() => {
  //   let restoId = window.location.href.includes('impersonate') ? localStorage.getItem('restaurantId') : resto_id;
  //   if (
  //     newKitchenStation?.station !== newValue?.station &&
  //     newKitchenStation?.station.trim() !== '' &&
  //     newKitchenStation?.assignee_id > 0
  //   ) {
  //     setAddState(false);
  //     createStation(newKitchenStation, resetForm, restoId, setHasCreatedKitchen);
  //     setSearchedAssignee('');
  //   }
  // }, [newKitchenStation]);

  // const content = (
  //   <div className="assignee-list">
  //     <List
  //       dataSource={operatorsToChoose}
  //       renderItem={(item) => (
  //         <List.Item
  //           className="meeting-events-list-item"
  //           onClick={() => {
  //             setNewKitchenStation({
  //               ...newKitchenStation,
  //               assignee_id: parseInt(item.id),
  //               assignee: `${item.firstName} ${item.lastName}`,
  //             });
  //             setSearchedAssignee(`${item.firstName} ${item.lastName}`);
  //           }}
  //         >
  //           <div>
  //             {item.firstName} {item.lastName}
  //           </div>
  //         </List.Item>
  //       )}
  //     />
  //   </div>
  // );
  // const toolTipText = 'Complete the fields';
  // const stationActions = (
  //   <Menu className="menu-show-preview">
  //     {[{ name: 'Save' }, { name: 'Delete' }].map((m, i) => {
  //       return (
  //         <Menu.Item
  //           key={i}
  //           className="menu-show-preview"
  //           disabled={!isValidated && m.name === 'Save'}
  //           onClick={() => {
  //             if (m.name === 'Save') {
  //               if (isValidated) {
  //                 handleAddStations();
  //               }
  //               // === save now
  //             } else {
  //               setNewKitchenStation();
  //             }
  //           }}
  //         >
  //           {!isValidated && m.name === 'Save' ? (
  //             <Tooltip placement="right" title={toolTipText}>
  //               <span style={{ color: 'gray' }}>{m.name}</span>
  //             </Tooltip>
  //           ) : (
  //             <span style={{ color: 'white' }}>{m.name}</span>
  //           )}
  //         </Menu.Item>
  //       );
  //     })}
  //   </Menu>
  // );

  const reloadAfterAdding = (message) => {
    console.log('KITCHENADDED!!', message);
    setAddKitchenUpdate(message);
  };

  const reloadAfterUpdating = (message) => {
    console.log('KITCHENUPDATED!!', message);
    setEditKitchenUpdate(message);
  };

  const reloadKitchenStations = () => {
    let restoId =
      addKitchenUpdate && window.location.href.includes('impersonate')
        ? localStorage.getItem('restaurantId')
        : resto_id;
    getKitchenStationList(setKitchenStations, restoId);
  };

  const reloadAccumulativePerc = (kitchenStations) => {
    const filterTotalCompleted = kitchenStations.filter((k) => k.total_items !== '0');
    const totalCompletedIngredients =
      filterTotalCompleted.length > 0
        ? filterTotalCompleted.reduce((a, b) => {
            return {
              total_items: parseInt(a.total_items) + parseInt(b.total_items),
              completed_items: parseInt(a.completed_items) + parseInt(b.completed_items),
            };
          })
        : 0;
    const accumulativePerc = Math.round(
      Math.round((totalCompletedIngredients.completed_items / totalCompletedIngredients.total_items) * 100)
    );
    return accumulativePerc;
  };

  useEffect(() => {
    eventSocket.on('addedKitchen', (message) => {
      reloadAfterAdding(message);
    });
    eventSocket.on('updatedKitchen', (message) => {
      reloadAfterUpdating(message);
    });

    return () => {
      eventSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    reloadKitchenStations();
  }, [window.location.href, addKitchenUpdate, editKitchenUpdate]);

  useEffect(() => {
    console.log(kitchenStations);
  }, [kitchenStations]);

  const _kitchenStations = useMemo(() => kitchenStations, [kitchenStations]);

  const _accumulativePerc = useMemo(() => reloadAccumulativePerc(kitchenStations), [kitchenStations]);

  useEffect(() => {
    console.log(_kitchenStations);
  }, [_kitchenStations]);

  // const handleOnDragEnd = (result) => {
  //   console.log(result);
  //   if (!result.destination) return;
  //   const items = Array.from(kitchenStations);
  //   const [reorderedItem] = items.splice(result.source.index, 1);
  //   items.splice(result.destination.index, 0, reorderedItem);
  //   setKitchenStations(items);

  //   const fetchData = () => {
  //     const allRequests = items.map((e, index) => updateStation(e.id, { info: { order: index } }).then((res) => res));

  //     return Promise.all(allRequests);
  //   };
  //   fetchData().then((res) => {
  //     notification.open({
  //       message: 'Successfully updated station order!',
  //     });
  //     setTimeout(() => {
  //       getKitchenStationList(setKitchenStations, resto_id);
  //     }, 2000);
  //   });
  // };

  const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    // console.warn("onChange", safeToDrop, sourceId, sourceIndex, targetIndex, targetId);
    if (sourceIndex == targetIndex) {
      // no-op
      console.warn('GridContextProvider.onChange', sourceIndex, targetIndex);
      return;
    }
    if (safeToDrop) {
      const nextState = swap(_kitchenStations, sourceIndex, targetIndex);
      setKitchenStations(nextState);
      const fetchData = () => {
        const allRequests = nextState.map((e, index) =>
          updateStation(e.id, { info: { order: index } }).then((res) => res)
        );

        return Promise.all(allRequests);
      };
      fetchData().then((res) => {
        if (sourceIndex !== targetIndex) {
          // notification.open({
          // 	message: 'Successfully updated station order!',
          // });
        }
        setTimeout(() => {
          getKitchenStationList(setKitchenStations, resto_id);
        }, 2000);
      });
    } else {
      const nextState = swap(_kitchenStations, sourceIndex, sourceIndex);
      setKitchenStations(nextState);
      isSafeToDrop(true); // resets to droppable
    }
  };

  const addNewKitchenStation = () => {
    if (null != newKitchenStation) return; // Only add one kitchen at a time
    setNewKitchenStation(newValue);
    setKitchenStations((oldArray) => [newValue, ...oldArray]);
    setCancelEdit(true);
  };

  const cancelAddKitchen = () => {
    setKitchenStations(kitchenStations.filter((v) => v != newKitchenStation));
    setNewKitchenStation(null);
  };

  const determineIfInside = (event, droppable) => {
    // if (dragState) {
    let mousePosition = event || window.event;
    let dropZone = droppable.parentElement; // access the parent element <GridZone>
    let dropZonePosition = dropZone.getBoundingClientRect(); // position and location of <GridZone> within the current viewport
    let droppablePosition = droppable.getBoundingClientRect(); // position and location of <GridItem> within the current viewport

    let dropZoneTop = dropZonePosition.top;
    let dropZoneBottom = dropZonePosition.bottom;
    let dropZoneLeft = dropZonePosition.left;
    let dropZoneRight = dropZonePosition.right;

    let mousePositionY = mousePosition.clientY;
    let mousePositionX = mousePosition.clientX;

    let droppableWidth = droppablePosition.width;
    let droppableHeight = droppablePosition.height;
    // let droppableTop = droppablePosition.x;
    // let droppableBottom = droppableTop + droppableHeight;
    // let droppableLeft = droppablePosition.y;
    // let droppableRight = droppableLeft + droppableWidth;

    console.log('droppable height', droppableHeight);
    console.log('droppable width', droppableWidth);

    console.log('top of dropzone', dropZoneTop);
    console.log('bottom of dropzone', dropZoneBottom);

    console.log('top of droppable', mousePositionY);
    console.log('bottom of droppable', mousePositionY + droppableHeight * 0.5);

    // console.log('mouse vertical position', mousePositionY);
    // console.log('droppable vertical position', droppablePositionY);

    console.log('left of dropzone', dropZoneLeft);
    console.log('right of dropzone', dropZoneRight);

    console.log('left of droppable', mousePositionX);
    console.log('right of droppable', mousePositionX + droppableHeight * 0.5);

    // console.log('mouse horizontal position', mousePositionX);
    // console.log('droppable horizontal position', droppablePositionX);

    if (mousePositionY + droppableHeight * 0.5 < dropZoneTop) {
      if (safeToDrop) isSafeToDrop(false);
      console.log('below, outside dropzone');
    }

    if (mousePositionY > dropZoneBottom) {
      if (safeToDrop) isSafeToDrop(false);
      console.log('above, outside dropzone');
    }

    if (mousePositionX + droppableWidth * 0.5 < dropZoneLeft) {
      if (safeToDrop) isSafeToDrop(false);
      console.log('left, outside dropzone');
    }

    if (mousePositionX > dropZoneRight) {
      if (safeToDrop) isSafeToDrop(false);
      console.log('right, outside dropzone');
    }
    // }
  };

  // Calculate the number of Kitchen Stations we can have, per row
  // based on the curent width of the kitchen-stations div
  const sectionRef = useRef(null);
  const { bounds, remeasure } = useMeasure(sectionRef);
  const [itemsPerRow, setItemsPerRow] = useState(6);
  const minItemWidth = 160;
  const rowHeight = 184;
  const numCards = _kitchenStations.length;
  const numRows = Math.max(1, Math.ceil(numCards / itemsPerRow));
  useEffect(() => {
    if (bounds) {
      setItemsPerRow(Math.floor(bounds.width / minItemWidth));
    }
  }, [bounds]);
  const restaurantId = window.location.href.includes('impersonate') ? localStorage.getItem('restaurantId') : resto_id;

  return (
    <div className="station-collection">
      <div className="station-collection-header">
        <div className="station-collection-title flex-control-vert-center">{KITCHENSTATIONNAME}s</div>
        <div className="station-collection-progress">
          <CidekicProgress percent={_accumulativePerc} />
        </div>
        <div className="flex flex-control-right">
          <RoleAccessibleComponent
            permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
          >
            <div className="flex-control-left flex-control-vert-center" onClick={addNewKitchenStation}>
              <div alt="add-button" className="common-plus-icon">
                <PlusIcon />
              </div>
            </div>
          </RoleAccessibleComponent>
          <div className="flex-control flex-control-vert-center">
            {/* <img src={reorder} alt="bar" className="stationToggle" alt="Reorder collections" /> */}
          </div>
        </div>
      </div>

      <div className="station-container1" ref={sectionRef}>
        <div className="station-tiles1">
          <GridContextProvider onChange={onChange}>
            <GridDropZone
              id="items"
              style={{ height: `${numRows * rowHeight + 20}px` }}
              boxesPerRow={itemsPerRow}
              rowHeight={editState ? rowHeight : rowHeight}
            >
              {_kitchenStations.map((kitchen, i) => {
                if (kitchen == newKitchenStation) {
                  // New Kitchen Station prompt
                  return (
                    <EditKitchenStation
                      restaurantId={restaurantId}
                      onSubmit={handleAddStation}
                      onCancel={cancelAddKitchen}
                      locationOperators={locationOperators}
                    />
                  );
                }
                return (
                  <KitchenStation
                    key={kitchen.key}
                    kitchen={kitchen}
                    index={i}
                    handleOnClick={() => handleOnClick(kitchen.id, kitchen.restaurantId)}
                    handleUpdate={handleUpdate}
                    handleEditState={handleEditState}
                    restaurantId={restaurantId}
                    ref={containerRef}
                    dragPointer={dragPointer}
                    dragState={dragState}
                    editState={editState}
                    addState={addState}
                    handleDropping={determineIfInside}
                    cancelNewKitchen={cancelNewKitchen}
                    cancelEdit={cancelEdit}
                    locationOperators={locationOperators}
                  />
                );
              })}
            </GridDropZone>
          </GridContextProvider>
        </div>
      </div>
    </div>
  );
};

// Individual Kitchen Stations
const KitchenStation = React.forwardRef((props, ref) => {
  const {
    handleUpdate,
    handleOnClick,
    kitchen,
    handleEditState,
    restaurantId,
    dragState,
    addState,
    cancelNewKitchen,
    cancelEdit,
    locationOperators,
  } = props;
  console.log('UPDATED KITCHEN', kitchen);
  const [editMode, setEditMode] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [stationName, setStationName] = useState(kitchen.station);
  const [operatorsToChoose, setOperatorsToChoose] = useState([]);
  const [searchedAssignee, setSearchedAssignee] = useState(kitchen.assignee);
  const [assigneeId, setAssigneeId] = useState(kitchen.operatorid);
  const [kitchenId, setKitchenId] = useState(kitchen.id);
  //const [stationAssigneeImg, setStationAssigneeImg] = useState(kitchen?.assigneeImg);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [gridStationClassName, setGridStationClassName] = useState('');

  // states for more finely-tuned control of inputs to edit
  const [editTitle, setEditTitle] = useState(false);
  const [editAssignee, setEditAssignee] = useState(false);

  const isFirefox = typeof InstallTrigger !== 'undefined';

  const noDrag = useRef(null);

  const assigneeInput = useRef(null);

  useEffect(() => {
    setEditMode(false);
  }, [kitchen]);

  useEffect(() => {
    if (cancelEdit) {
      setEditMode(false);
    }
  }, [cancelEdit]);

  useEffect(() => {
    handleEditState(editMode);
    // temporarily changes the className to fix side effect bug of ANTD PopOver concealed behind <GridItem> uncles and aunts
    setGridStationClassName(editMode ? 'kitchen-station-elevated' : ''); // ./index.css line 296-298
  }, [editMode]);

  useEffect(() => {
    // temporarily changes the className to fix side effect bug of ANTD PopOver concealed behind <GridItem> uncles and aunts
    // after clicking assignee text, automatically focuses on input counterpart with Popover displayed
    setGridStationClassName(editAssignee || editTitle ? 'kitchen-station-elevated' : '');

    if (editAssignee) {
      assigneeInput.current.focus();
    }
    if (editAssignee || editTitle) {
      setShowCancel(true);
    }
  }, [assigneeInput, editAssignee, editTitle]);

  // to ensure when kitchen station is being dragged, all sat
  useEffect(() => {
    if (editTitle) {
      setEditTitle(false);
    }
    if (editAssignee) {
      setEditAssignee(false);
    }
  }, [dragState]);

  const toggleToEditTitle = () => {
    setEditTitle(!editTitle);
    if (editAssignee) setEditAssignee(false);
  };

  const toggleToEditAssignee = () => {
    setEditAssignee(!editAssignee);
    if (editTitle) setEditTitle(false);
  };

  useEffect(() => {
    if (isFirefox && noDrag.current) {
      noDrag.current.addEventListener('dragstart', (e) => e.preventDefault());
    }
  }, [noDrag]);

  const toggleEditMode = () => {
    setSearchedAssignee('');
    cancelNewKitchen(true);
    setShowCancel(!showCancel);
    setStationName(kitchen.station);

    if (!editMode && editTitle) {
      setEditTitle(!editTitle);
      return;
    }
    if (!editMode && editAssignee) {
      setEditAssignee(!editAssignee);
      return;
    }
    setEditMode(!editMode);
  };

  const toggleDropdown = () => {
    setActionsVisible(!actionsVisible);
  };

  const saveEdit = () => {
    const params = {
      name: stationName,
    };
    updateStation(kitchenId, params).then((res) => {
      // notification.open({
      //   message: `${KITCHENSTATIONNAME} Successfully Updated`,
      // });
    });
    updateKitchenStation(kitchenId, assigneeId).then((res) => {
      // notification.open({
      //   message: 'Successfully Reassigned Kitchen Station',
      // });
      handleUpdate();
    });
  };

  const editStationName = (id) => {
    const params = {
      name: stationName,
    };
    updateStation(id, params).then((res) => {
      // notification.open({
      //   message: `${KITCHENSTATIONNAME} Successfully Updated`,
      // });
      toggleEditMode();
      toggleToEditTitle();
      handleUpdate();
    });
  };

  useEffect(() => {
    if (editAssignee) {
      toggleToEditAssignee();
    }
  }, [setSearchedAssignee]);

  const editStationAssignee = (assignee_id, assignee) => {
    setSearchedAssignee(assignee);
    setAssigneeId(assignee_id);
  };

  const handleNameInput = (val) => {
    setStationName(val);
  };

  const stationItemActions = (id) => {
    return (
      <>
        <Menu className="menu-show-preview">
          <Menu.Item
            className="menu-show-preview"
            onClick={(e) => {
              toggleEditMode();
              toggleDropdown();
              setKitchenId(id);
            }}
          >
            <span>Edit</span>
          </Menu.Item>
        </Menu>
        <Menu className="menu-show-preview">
          <Menu.Item
            className="menu-show-preview"
            onClick={(e) => {
              toggleDropdown();
              deleteKitchenStation(id).then((res) => {
                notification.open({
                  message: `${KITCHENSTATIONNAME} Successfully Deleted`,
                });
                handleUpdate();
              });
            }}
          >
            <span>Delete</span>
          </Menu.Item>
        </Menu>
      </>
    );
  };

  // AFTER CHANGING STATION ASSIGNEE
  // const chooseFromOperators = (
  //   <div className="assignee-list">
  //     <List
  //       dataSource={operatorsToChoose}
  //       renderItem={(operator) => (
  //         <List.Item
  //           className="meeting-events-list-item"
  //           onClick={() => {
  //             editStationAssignee(`${operator.id}`, `${operator.firstName} ${operator.lastName}`);
  //           }}
  //         >
  //           <div>
  //             {operator.firstName} {operator.lastName}
  //           </div>
  //         </List.Item>
  //       )}
  //     />
  //   </div>
  // );

  const determineIfInside = (e) => {
    // triggers the parent div which is <GridItem>
    // props.handleDropping(ref.current.parentElement);
    if (ref.current && ref.current.parentElement) {
      props.handleDropping(e, ref.current.parentElement);
    }
    //console.log("e parent is", ref.current.parentElement);
  };

  useEffect(() => {
    if (searchedAssignee !== '') {
      const likeOperators = locationOperators.filter(
        (op) =>
          op.firstName.toLowerCase().indexOf(searchedAssignee.toLowerCase()) !== -1 ||
          op.lastName.toLowerCase().indexOf(searchedAssignee.toLowerCase()) !== -1
      );
      setOperatorsToChoose(likeOperators);
    } else {
      if (locationOperators.length > 0) {
        setOperatorsToChoose(locationOperators);
      }
    }
  }, [searchedAssignee]);

  const onClickRemoveAssignee = () => {
    setAssigneeId();
    setSearchedAssignee('');
    setOperatorsToChoose(locationOperators);
  };

  return (
    <GridItem key={kitchen.id} className={gridStationClassName}>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        ref={ref}
        onMouseUp={(e) => determineIfInside(e)}
        disabled={addState}
      >
        <div className="kitchen-station d-flex flex-column" key={kitchen.key} style={{ cursor: props.dragPointer }}>
          <div className="flex flex-row">
            {kitchen.total_items !== '0' ? (  // this does not work. Variable is undefined.
              <CidekicProgress
                percent={parseInt(kitchen.percentage)}
                onClick={() => handleOnClick(kitchen.id, kitchen.restaurantId)}
              />
            ) : (
              <div className="p-1 flex-grow-1"></div>
            )}
            <RoleAccessibleComponent permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin']}>
              <div className="p-1" onClick={() => toggleDropdown()}>
                <Dropdown overlay={stationItemActions(kitchen.id)} visible={actionsVisible} trigger={['click']}>
                  <div style={{ textAlign: 'center' }}>
                    {kitchen.key ? <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" /> : ''}
                  </div>
                </Dropdown>
              </div>
            </RoleAccessibleComponent>
          </div>

          {editMode || editTitle ? (
            <div className="flex-grow-1">
              <div className="kitchen-station-name-input">
                <label className="required-asterisk" style={{ marginLeft: 15 }}>
                  Station
                </label>
                <input
                  autoFocus
                  type="text"
                  placeholder="Enter station name"
                  className="kitchen-station-name-input1"
                  value={stationName}
                  onChange={(e) => {
                    handleNameInput(e.target.value);
                  }}
                />
                <div style={{ marginTop: 10 }}>
                  <div className="kitchen-station-assignee-container">
                    {/* CHANGE 1202077200318274 */}

                    <StationAssigneeEdit
                      assigneeChoices={operatorsToChoose}
                      assignee={searchedAssignee}
                      onChange={(value) => {
                        editStationAssignee(`${JSON.parse(value).id}`, `${JSON.parse(value).name}`);
                      }}
                      onSearch={(value) => {
                        setSearchedAssignee(value);
                      }}
                      onClickRemove={() => {
                        onClickRemoveAssignee();
                      }}
                    />
                    {/* <Popover
                      content={chooseFromOperators}
                      overlayStyle={{
                        marginTop: '-2px',
                        width: '100%',
                        backgroundColor: '#454545',
                        zIndex: '99999',
                      }}
                      placement="bottom"
                      trigger="focus"
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      <label style={{ marginLeft: 15 }}>Assignee</label>
                      <input
                        type="text"
                        placeholder="Search assignee"
                        value={searchedAssignee}
                        onChange={(e) => {
                          setSearchedAssignee(e.target.value);
                        }}
                        className="kitchen-station-assignee-input1"
                        placement="top"
                      />
											<span className="row-assignee-clear" onClick={onClickRemoveAssignee}>
												<TrimmedPlusIcon />
											</span>
                    </Popover> */}
                  </div>
                </div>
              </div>
              <div className="station-tile-kitchen-controls">
                <RowOkCancel onClickOk={saveEdit} onClickCancel={toggleEditMode} />
              </div>
            </div>
          ) : (
            <div className="flex-grow-1 d-flex flex-column h-100">
              <div className="kitchen-station-title" onClick={() => handleOnClick(kitchen.id, kitchen.restaurantId)}>
                {kitchen.station}
              </div>
              <div onClick={() => handleOnClick(kitchen.id, kitchen.restaurantId)} className="flex-grow-1 text-center">
                <div className="station-tile-assignee-image m-2" alt="user-photo" draggable="false" ref={noDrag}>
                  <UserImage src={kitchen?.assigneeImg ? kitchen?.assigneeImg : null} showPlaceholder={true} />
                </div>
              </div>
              <div className={kitchen.key ? 'kitchenStationFooter' : ''} onClick={() => handleOnClick(kitchen.id, kitchen.restauranId)}>
                <div className="kitchen-title">{kitchen?.assignee}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </GridItem>
  );
});
export default KitchenStations;
