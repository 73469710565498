/* eslint eqeqeq: "off" */
import React, { useState } from 'react';
// import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.css';
// import { events } from '../../data/calendarmonthevents';
import { filter } from 'lodash';
import { formatGetTime } from '../../pages/Dashboard/DashboardUtils';
import { Row, Col, List } from 'antd';
import EditEventDrawerV2 from '../../pages/Dashboard/components/EditEventDrawerV2';
import RoleAccessibleComponent from '../RoleAccess/roleaccess';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';

// const localizer = momentLocalizer(moment);

const MeetingAndEventDayMobile = (props) => {
  const [showDrawer, setShowDrawer] = useState({ type: '', open: false, data: null });
  const { meetingsAndEvents, futureEvents, setHasEditedEvent } = props;
  // const reminders = filter(meetingsAndEvents, { type: 'reminder' });
  const meetings_events = filter(meetingsAndEvents, (i) => {
    return i.type === 'event' || i.type === 'meeting';
  });

  return (
    <div className="main marginL10">
      <div>
        <Row gutter={32}>
          <Col className="gutter-row" span={24}>
            <div className="meeting-reminders-future-title">Meetings and events</div>
            <div className="meeting-events-panel-display">
              <List
                dataSource={meetings_events}
                className={'show-custom-empty'}
                locale={{ emptyText: <Empty image={empty} description="No events" /> }}
                renderItem={(item) => {
                  const { info } = item;
                  const parsedInfo = info;
                  const color = (() => {
                    let ev_color = null;
                    if (parsedInfo) {
                      if (parsedInfo.hasOwnProperty('color')) {
                        return parsedInfo.color;
                      }
                    }
                    if (!ev_color) {
                      ev_color = item.type === 'event' ? '#FF59CE' : '#4CBCF8';
                    }
                    return ev_color;
                  })();

                  const ev_style = {
                    marginRight: 10,
                    height: 10,
                    width: 10,
                    backgroundColor: color,
                    borderRadius: '50%',
                    display: 'inline-block',
                  };

                  return (
                    <List.Item
                      className="meeting-events-list-item"
                      onClick={() => setShowDrawer({ type: item.type, open: true, data: item })}
                    >
                      <div>
                        <span style={ev_style}></span>
                        <span style={{ fontSize: 14 }}>{item.name}</span>
                        <div className="event-time-mob">
                          {formatGetTime(item.eventStart)} - {formatGetTime(item.eventEnd)}
                        </div>
                      </div>
                    </List.Item>
                  );
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <br />
      {/* <div>
        <Col className="gutter-row" span={24}>
          <div className="meeting-reminders-future-title">Reminders</div>
          <div className="meeting-events-panel-display">
            <List
              dataSource={reminders}
              className={'show-custom-empty'}
              locale={{ emptyText: <Empty image={empty} description='No events'/> }}
              renderItem={(item) => (
                <List.Item className="meeting-events-list-item">
                  <div>
                    <span className="list-bullet-green"></span>
                    {item.name}
                  </div>
                </List.Item>
              )}
            />
          </div>
        </Col>
      </div> */}
      <div>
        <Col className="gutter-row" style={{ marginTop: 20 }} span={24}>
          <div className="meeting-reminders-future-title">Future meetings and events</div>
          <div className="meeting-events-panel-display">
            <List
              dataSource={futureEvents}
              className={'show-custom-empty'}
              locale={{ emptyText: <Empty image={empty} description="No events" /> }}
              renderItem={(item) => {
                const { info } = item;
                const parsedInfo = info;
                const color = (() => {
                  let ev_color = null;
                  if (parsedInfo) {
                    if (parsedInfo.hasOwnProperty('color')) {
                      return parsedInfo.color;
                    }
                  }
                  if (!ev_color) {
                    ev_color = item.type === 'event' ? '#FF59CE' : '#4CBCF8';
                  }
                  return ev_color;
                })();

                const ev_style = {
                  marginRight: 10,
                  height: 10,
                  width: 10,
                  backgroundColor: color,
                  borderRadius: '50%',
                  display: 'inline-block',
                };

                const date =
                  moment().add('1', 'days').format('MMM D YYYY') == item.eventStart.format('MMM D YYYY')
                    ? // eslint-disable-next-line no-useless-concat
                      ' ' + 'tomorrow ' + item.eventStart.format('ddd D')
                    : ' ' + item.eventStart.format('ddd D MMM');

                return (
                  <List.Item className="meeting-events-list-item">
                    <div
                      style={{ cursor: 'pointer', display: 'flex' }}
                      onClick={() => setShowDrawer({ type: item.type, open: true, data: item })}
                    >
                      <span style={ev_style}></span>
                      <div>
                        {item.name.trim()},
                        {moment().year() == item.eventStart.year() ? date : date + ' ' + item.eventStart.format('YYYY')}
                      </div>
                    </div>
                  </List.Item>
                );
              }}
            />
          </div>
        </Col>
      </div>
      <RoleAccessibleComponent
        permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
      >
        {showDrawer.open && (
          <EditEventDrawerV2
            showDrawer={showDrawer.open}
            type={showDrawer.type}
            data={showDrawer.data}
            setHasEditedEvent={setHasEditedEvent}
            setShowDrawer={setShowDrawer}
          />
        )}
      </RoleAccessibleComponent>
    </div>
  );
};

export default MeetingAndEventDayMobile;
