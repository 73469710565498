import { request } from './http.service';
import { decodeChecklistMessages } from '../protobuf-models/checklist.proto.js';

export const create = (params) => {
  return request('POST', '/checklist', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getCheckList = (params) => {
  return request('POST', '/checklist/getCheckList', 200, params, decodeChecklistMessages).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};
export const createListItem = (params) => {
  return request('POST', '/checklist/createListItem', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getChecklistAssignees = (params) => {
  return request('GET', '/checklist/getChecklistAssignees/' + params.id + '/search', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroy = (id) => {
  return request('DELETE', '/checklist/item/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};
export const destroyChecklist = (id) => {
  return request('DELETE', '/checklist/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateItem = (id, params) => {
  return request('POST', '/checklist/item/' + id, 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateCheckList = (id, params) => {
  return request('POST', '/checklist/' + id, 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};
