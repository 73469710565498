export const recipeDetails = {
  name: 'Honey Barbecue Shrimp Tacos',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  tags: ['tag 1', 'tag 2', 'tag 3'],

  prep: '30min',
  cook: '1h 10m',
  yield: '1 bucket',
  portion: '1 shrimp',
  quantity: '100',
  nutritionDetails: [
    {
      name: 'Total Fat',
      mg: '2.9g',
      percent: '4%',
      components: [
        { name: 'Saturated Fat', mg: '0.4g', percent: '4%' },
        { name: 'Trans Fat', mg: '0.2g', percent: '1%' },
      ],
    },
    {
      name: 'Cholesterol',
      mg: '0g',
      percent: '4%',
      components: [],
    },
    {
      name: 'Sodium',
      mg: '92mg',
      percent: '4%',
      components: [],
    },
    {
      name: 'Total Carbohydrate',
      mg: '13.7g',
      percent: '5%',
      components: [
        { name: 'Dietary Fiber', mg: '1.7g', percent: '6%' },
        { name: 'Sugar', mg: '8g', percent: '2%' },
      ],
    },
    {
      name: 'Protein',
      mg: '0.8g',
      percent: '6%',
      components: [],
    },
    {
      name: 'Vitamin A',
      mg: '0mg',
      percent: '0%',
      components: [],
    },
    {
      name: 'Vitamin C',
      mg: '2mg',
      percent: '1%',
      components: [],
    },
    {
      name: 'Calcium',
      mg: '18mg',
      percent: '6%',
      components: [],
    },
    {
      name: 'Iron',
      mg: '242mg',
      percent: '5%',
      components: [],
    },
  ],
  nutrition: {
    servings: 5,
    calories: 80,
    caloriefat: 20,
    comments: '14',
    attachments: '4',
  },
  ingredients: [
    {
      name: 'Ingredient 1',
      quantity: 1,
      measurement: 'Measurement',
      preparation: 'Preparation',
      allergen: '',
    },
    {
      name: 'Ingredient 2',
      quantity: 2,
      measurement: 'Measurement',
      preparation: 'Preparation',
      allergen: 'Fish',
    },
    {
      name: 'Ingredient 3',
      quantity: 3,
      measurement: 'Measurement',
      preparation: 'Preparation',
      allergen: '',
    },
  ],
  equipmentlist: [
    {
      name: 'Equipment 1',
      type: 'Type',
      size: 'Size',
      quantity: 1,
    },
    {
      name: 'Equipment 2',
      type: 'Type',
      size: 'Size',
      quantity: 1,
    },
    {
      name: 'Equipment 3',
      type: 'Type',
      size: 'Size',
      quantity: 1,
    },
    {
      name: 'Equipment 4',
      type: 'Type',
      size: 'Size',
      quantity: 1,
    },
    {
      name: 'Equipment 5',
      type: 'Type',
      size: 'Size',
      quantity: 1,
    },
  ],
  instructionlist: [
    {
      order: 1,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      order: 2,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },

    {
      order: 3,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      order: 4,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      order: 5,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      order: 6,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
  ],
};

export const nutritionDetails = [
  {
    name: 'Folate',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Magnesium',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Potassium',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Thiamine',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
  {
    name: 'Misc',
    mg: '242mg',
    percent: '5%',
  },
];
