import { request } from './http.service';
import { decodeMenuMessages } from '../protobuf-models/menu.proto.js';
import { decodeBatchCourseMessages } from '../protobuf-models/batchcourses.proto.js';

export const create = (params) => {
  return request('POST', '/menu', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const duplicate = (menuId) => {
  return request('POST', '/menu/duplicate', 201, { menuId: menuId }).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateMenu = (id, params) => {
  return request('PUT', '/menu/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroy = (id, original) => {
  return request('DELETE', '/menu/' + id + '/is/original/' + original, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const get = (id) => {
  return request('GET', '/menu/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchDuplicates = (menuId) => {
  return request('GET', `/menu/${menuId}/event/versions`, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchMenu = (params) => {
  return request('POST', '/menu/search', 200, params, decodeMenuMessages).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const searchByCourses = (params) => {
  console.log('API ENDPOINT: searchByCourses >>>>>', params);
  return request('POST', '/menu/with/courses', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchMenuRecipe = (recipeId) => {
  return request('GET', `/menu/of/recipe/${recipeId}`, 200).then(
    (result) => {
      if (result) {
        return result.menuId;
      }
      return '0';
    },
    (error) => {
      // console.log(error)
    }
  );
};

export const courses = (id) => {
  return request('GET', '/menu/' + id + '/courses', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const batchCourses = (ids) => {
  return request('POST', '/menu/courses', 200, { ids }, decodeBatchCourseMessages).then(
    (result) => {
      if (result && result.batchcourses) {
        let batchcourses = result.batchcourses.map((b) => {
          b.MenuCourses = JSON.parse(b.MenuCourses);
          b.info = JSON.parse(b.info);
          return b;
        });
        return batchcourses;
      }
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const assignCourse = (id, courseId) => {
  return request('POST', '/menu/' + id + '/courses', 200, { courseId }).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const activateChecklist = (id) => {
  return request('GET', '/menu/' + id + '/checklist', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const activateNewCourseRecipes = (menuId, recipeIds) => {
  return request('POST', '/menu/' + menuId + '/new/course/recipes', 200, { recipeIds: recipeIds }).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const activateEventChecklist = (id, eventId) => {
  return request('GET', '/menu/' + id + '/event/' + eventId + '/checklist', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};
export const deactivateChecklist = (id) => {
  return request('DELETE', '/menu/' + id + '/checklist', 204).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const activateEventRecipeChecklist = (recipeId, id) => {
  return request('GET', '/menu/' + id + '/recipe/' + recipeId + '/checklist', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};
export const deactivateEventRecipeChecklist = (recipeId, id) => {
  return request('DELETE', '/menu/' + id + '/recipe/' + recipeId + '/checklist', 204).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};