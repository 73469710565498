export const data = [
  {
    key: 1,
    name: 'Food  production safety',
    type: 'parent',
    percentage: 66,
    children: [
      {
        key: 2,
        name: 'Hair net',
        type: 'child',
        checked: true,
        complete: 0,
        assignee: 'Pizza',
        due: '11/28/20',
        texts: 2,
        attachments: 1,
        project: 'Food safety',
        selected: false,
        tags: ['tag 1', 'tag 2', 'tag 3'],
        priority: 'High',
        details: true,
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 3,
        name: 'Chef hat',
        checked: true,
        type: 'child',
        complete: 0,
        assignee: 'Pizza',
        due: '11/29/20',
        texts: 10,
        attachments: 0,
        project: 'Food safety',
        selected: false,
        tags: ['tag 2', 'tag 3'],
        priority: 'Medium',
        details: false,
        description: '',
      },
      {
        key: 4,
        name: 'Clean uniform',
        checked: true,
        type: 'child',
        complete: 1,
        assignee: 'Pizza',
        due: '11/30/20',
        texts: 0,
        attachments: 5,
        project: 'Food safety',
        selected: false,
        tags: ['tag 2', 'tag 3'],
        priority: 'Low',
        details: false,
        description: '',
      },
    ],
  },
  {
    key: 2,
    name: 'Equipment',
    type: 'parent',
    percentage: 66,
    children: [
      {
        key: 5,
        name: 'Gloves, Xtra Large',
        type: 'child',
        checked: true,
        complete: 0,
        assignee: 'Pantry',
        due: '11/28/20',
        texts: 2,
        attachments: 1,
        project: 'Equipment',
        selected: false,
        tags: ['tag 1', 'tag 2', 'tag 3'],
        priority: 'High',
        details: true,
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 6,
        name: 'Towels',
        checked: true,
        type: 'child',
        complete: 0,
        assignee: 'Pantry',
        due: '11/29/20',
        texts: 10,
        attachments: 0,
        project: 'Equipment',
        selected: false,
        tags: ['tag 2', 'tag 3'],
        priority: 'Medium',
        details: false,
        description: '',
      },
      {
        key: 7,
        name: 'Printed paper',
        checked: true,
        type: 'child',
        complete: 1,
        assignee: 'Pantry',
        due: '11/30/20',
        texts: 0,
        attachments: 5,
        project: 'Equipment',
        selected: false,
        tags: ['tag 2', 'tag 3'],
        priority: 'Low',
        details: false,
        description: '',
      },
    ],
  },
  {
    key: 3,
    name: 'Focus points',
    type: 'parent',
    percentage: 66,
    children: [
      {
        key: 8,
        name: 'Clean and organized station',
        type: 'child',
        checked: true,
        complete: 0,
        assignee: 'Dessert',
        due: '11/28/20',
        texts: 2,
        attachments: 1,
        project: 'Focus points',
        selected: false,
        tags: ['tag 1', 'tag 2', 'tag 3'],
        priority: 'High',
        details: true,
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 9,
        name: 'No tablets on cutting boards',
        checked: true,
        type: 'child',
        complete: 0,
        assignee: 'Dessert',
        due: '11/29/20',
        texts: 10,
        attachments: 0,
        project: 'Focus points',
        selected: false,
        tags: ['tag 2', 'tag 3'],
        priority: 'Medium',
        details: false,
        description: '',
      },
      {
        key: 10,
        name: 'No towels on cutting boards',
        checked: true,
        type: 'child',
        complete: 1,
        assignee: 'Dessert',
        due: '11/30/20',
        texts: 0,
        attachments: 5,
        project: 'Focus points',
        selected: false,
        tags: ['tag 2', 'tag 3'],
        priority: 'Low',
        details: false,
        description: '',
      },
    ],
  },
  {
    key: 10,
    name: 'Checklist 11',
    type: 'parent',
    percentage: 66,
    children: [
      {
        key: 11,
        name: 'Task item 1',
        type: 'child',
        checked: true,
        complete: 1,
        assignee: 'Leonardo Serverserverserver',
        due: '11/28/20',
        texts: 2,
        attachments: 1,
        project: 'Project 1',
        selected: false,
        tags: ['tag 1', 'tag 2', 'tag 3'],
        priority: 'High',
        details: true,
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 12,
        name: 'Task item 2',
        checked: true,
        type: 'child',
        complete: 1,
        assignee: 'Leonardo Serverserverserver',
        due: '11/29/20',
        texts: 10,
        attachments: 0,
        project: 'Project 1',
        selected: false,
        tags: ['tag 2', 'tag 3'],
        priority: 'Medium',
        details: false,
        description: '',
      },
      {
        key: 13,
        name: 'Task item 3',
        checked: true,
        type: 'child',
        complete: 0,
        assignee: 'Leonardo Serverserverserver',
        due: '11/30/20',
        texts: 0,
        attachments: 5,
        project: 'Project 2',
        selected: true,
        tags: ['tag 2', 'tag 3'],
        priority: 'Low',
        details: false,
        description: '',
      },
    ],
  },
  {
    key: 21,
    name: 'Checklist 2  (no task)',
    percentage: 0,
    type: 'parent',
    children: [],
  },
  {
    key: 31,
    name: 'Checklist 3',
    type: 'parent',
    percentage: 44,
    children: [],
  },
  {
    key: 41,
    name: 'Checklist 4',
    percentage: 21,
    type: 'parent',
    children: [],
  },
];
