import React, { useState, useEffect } from 'react';
import { ridAnnoyingZero } from '../../pages/Recipes/utilities';

const IngredientQtyInput = (props) => {
  const {
    inputClassName,
    tabIndex,
    onChangeValue,
    onChangeValueMob,
    quantityValue,
    displayValue,
    onFocusValue,
    rowData,
  } = props;
  const [quantity, setQuantity] = useState(null);
  const [showQuantity, setShowQuantity] = useState(null);

  useEffect(() => {
    setShowQuantity(displayValue);
  }, [displayValue]);

  useEffect(() => {
    setQuantity(quantityValue);
  }, [quantityValue]);

  const onFocus = () => {
    onFocusValue(rowData);
    setShowQuantity(ridAnnoyingZero(quantityValue));
  };

  const onBlur = (e) => {
    if (onChangeValue) {
      onChangeValue(e);
    }
    if (onChangeValueMob) {
      onChangeValueMob(e, rowData);
    }
    setShowQuantity(displayValue);
  };

  const onChange = (e) => {
    let isInvalid = false;
    if (!/^\d*\.?\d*$/.test(e.target.value)) {
      // notification.open({
      // 	message: 'Please input a numeric value!'
      // })
      isInvalid = true;
    }

    if (!isInvalid) {
      setQuantity(e.target.value);
      setShowQuantity(e.target.value);
    }
  };

  return (
    <input
      className={inputClassName}
      type="text"
      tabindex={tabIndex | undefined}
      onChange={(e) => onChange(e)}
      onFocus={(e) => onFocus(e)}
      onBlur={(e) => onBlur(e)}
      value={showQuantity ? showQuantity : ''}
      tabIndex={props.tabIndex != null ? props.tabIndex.toString() : ''}
    ></input>
  );
};

export default IngredientQtyInput;
