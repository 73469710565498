import React, { useState, useEffect, useCallback, useRef } from 'react';
import './index.css';
import { Table, Drawer, Dropdown, Menu, Row, Col, Spin } from 'antd';
import reorder from '../../img/reorder-icon.png';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import useWindowSize from '../../components/windowdimensions/windowSize';
import CreateNotePopup from './CreateNotePopup';
import DocumentsDetailPopup from './DocumentsDetailPopup';
import UploadDocument from './UploadDocument';
import { CloseCircleOutlined } from '@ant-design/icons';
import {
  searchDocuments,
  searchDocFolders,
  createDocumentFolder,
  destroyDocFolder,
  updateDocFolder,
  getLinks,
  // deleteAllLinks,
} from '../../services/doc.service';
import { cloneDeep, find, remove, findIndex, slice } from 'lodash';
import _ from 'lodash';
import moment from 'moment';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { docIcon, docThumbnailSrc } from './DocUtils';
import { NewSubHeader } from '../../components/Header/Header';
import {
  CidekicButton,
  PlusButton,
  CidekicDropdown,
  CommentIndicator,
  ExpandCollapseIcon,
  UserImage,
  ThumbnailImage,
  RowOkCancel,
} from '../../components/common/common_ui';
import { pluralize } from 'inflected';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import ReactDragListView from 'react-drag-listview';

const newDocsValue = {
  key: '0',
  name: '',
  type: 'parent',
  children: [],
};
//

const Documents = () => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [visible, updateVisible] = useState(false);
  const [visible1, updateVisible1] = useState(false);
  const [visible2, updateVisible2] = useState(false);
  const [dragParent, setDragParent] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  // const [catId, setCatId] = useState(false);
  const [getCatId, setGetCatId] = useState({});
  const [destinationId, setDestinationId] = useState({});
  const [origSource, setOriginal] = useState([]);
  const [documentid, setDocumentId] = useState('');
  const [hasChanged, setHasChanged] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [folderid, setFolderId] = useState('');
  const [newDocument, setNewDocument] = useState({});
  const [rename, setRename] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [typeName, setTypeName] = useState('');
  const [userImage, setUserImage] = useState(
    JSON.parse(localStorage.getItem('operator'))?.info?.userImageThumb ||
      JSON.parse(localStorage.getItem('operator'))?.info?.userImage
  );
  const [socketData, setSocketData] = useState([]);
  const [docFolderUpdate, setDocFolderUpdate] = useState([]);
  const [docFolderAdd, setDocFolderAdd] = useState([]);
  const [docFolderDelete, setDocFolderDelete] = useState('');
  const [docAdded, setDocAdded] = useState(false);
  const [docUpdated, setDocUpdated] = useState({});
  const [docDeleted, setDocDeleted] = useState(false);
  // const [itemAdd, setItemAdd] = useState({});
  // const [itemUpdate, setItemUpdate] = useState({});
  // const [itemCheckUpdate, setItemCheckUpdate] = useState(0);
  // const [itemDelete, setItemDelete] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/document`));

  const [isSearching, setIsSearching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let userInfo = JSON.parse(localStorage.getItem('operator')) || {};
  let userName = userInfo.firstName + ' ' + userInfo.lastName;

  const isDown = useRef(false);

  const activeCat = useRef(null);

  const getAllFolders = () => {
    searchDocFolders({ restaurantId: resto.id }).then((res) => {
      let folders = [];
      if (res) {
        let folderInfo = res.documentfolders;
        for (let i = 0; i < (folderInfo || []).length; i++) {
          const { id, title } = folderInfo[i];

          let documentData = [];

          documentData.push({
            // always on the last row
            key: `${id}-9999999`,
            type: 'add-button',
            parent_id: id,
          });

          console.log(documentData);

          folders.push({
            key: id.toString(),
            folderId: id,
            name: title,
            type: 'parent',
            children: documentData,
          });

          console.log(folders);
        }
        getDocuments(folders);
      }
    });
  };

  const getDocuments = (folders) => {
    let getFolderIds = folders.map((f) => f.key);
    console.log(getFolderIds);

    const fetchData = () => {
      const allRequests = getFolderIds.map((e) => searchDocuments({ folderId: e }).then((res) => res));

      return Promise.all(allRequests);
    };

    fetchData().then((res) => {
      let docList = res;
      let merged = [].concat.apply([], docList).filter((u) => u != undefined);

      console.log(merged);

      let docListData = merged.map((d) => {
        return {
          folderId: d.folderId,
          id: d.id,
          key: `${d.folderId}-${d.id}`,
          name: d?.info?.title && d?.info?.title !== '' ? d?.info?.title : 'Untitled',
          content: d.title && d.title !== '' ? d.title : '',
          type: 'child',
          info: d.info,
          category: d?.info?.type,
          selectedTask: d?.info?.selectedTasks,
          assignee: d?.info?.createdBy?.replace(/\s{2,}/g, ' '),
          assigneeImg: d?.info?.createdBy?.replace(/\s{2,}/g, ' ')
            ? d?.info?.createdBy?.replace(/\s{2,}/g, ' ') == userName
              ? userImage
              : ''
            : '',
          upload_date: d?.info?.timestamp
            ? moment(d?.info?.timestamp).format('MM-DD-YYYY')
            : moment(d?.info?.updatedDate).format('MM-DD-YYYY'),
          linked: d?.info?.linked,
          attachments: d?.info?.comments?.length,
          details: 'true',
        };
      });

      console.log(docListData);

      let dataCombined = folders.concat(docListData);

      console.log(dataCombined);

      const result = Object.values(
        dataCombined.reduce((r, o, i) => {
          const key = `${o.folderId}`;

          if (!r[key])
            r[key] = {
              ...o,
            };

          r[key].children.unshift(o);

          r[key].children = [].concat.apply([], r[key].children).filter((u) => u !== undefined && u.type !== 'parent');

          r[key].notes = r[key]?.children?.filter((c) => c?.info?.type == 'Note').length;

          r[key].uploads = r[key]?.children?.filter((c) => c?.info?.type !== 'Note' && c.type !== 'add-button').length;

          r[key].selectedTasks = [].concat.apply(
            [],
            r[key]?.children?.map((t) => t.selectedTask).filter((u) => u !== undefined)
          );

          r[key].children.sort((x, y) => {
            return x.id - y.id;
          });

          console.log(r[key]);

          return r;
        }, {})
      );

      console.log('RESULT', result);

      let getLink = result
        .filter((e) => e.children)
        .map((e) => e.children.map((link) => link.id))
        .reduce((a, b) => a.concat(b), [])
        .filter((l) => l !== undefined);

      console.log('ALL DOC IDS', getLink);

      let params = {};

      const fetchData = () => {
        const allRequests = getLink.map((e) => getLinks(e, params).then((res) => res));

        return Promise.all(allRequests);
      };

      fetchData().then((res) => {
        if (res && res.length > 0) {
          let linkArr = [].concat.apply([], res).filter((u) => u != undefined);
          console.log('ALL LINKS', linkArr);

          const link = Array.from(
            linkArr.reduce(
              (m, { documentId, linkId }) => m.set(documentId, [...(m.get(documentId) || []), linkId]),
              new Map()
            ),
            ([documentId, linkId]) => ({ documentId, linkId })
          );

          console.log(link);

          let addSelectedLinks = result.map((o) => {
            o.children.map((c) => {
              link.map((d) => {
                if (d.documentId == c.id) {
                  c.linkeds = d.linkId.length;
                }
              });
            });
            o.children.sort((x, y) => {
              return x.id - y.id;
            });
            return o;
          });

          console.log('the data source:');
          console.log(addSelectedLinks);

          if (
            localStorage.getItem('documentOrder') != undefined &&
            JSON.parse(localStorage.getItem('documentOrder')).length === addSelectedLinks.length
          ) {
            const documentOrder = JSON.parse(localStorage.getItem('documentOrder'));
            const orderedDocument = annotateTableOrder(addSelectedLinks, documentOrder);
            setDataSource(orderedDocument);
          } else if (localStorage.getItem('documentColKeyOrder') != undefined) {
            const { columnKey, order } = JSON.parse(localStorage.getItem('documentColKeyOrder'));
            const sortedTable = applyColumnOrder(columnKey, order, addSelectedLinks);
            setDataSource(sortedTable);
            setIsLoading(false);
          } else {
            setDataSource(addSelectedLinks);
            setIsLoading(false);
          }
          setOriginal(addSelectedLinks);
          setSocketData(addSelectedLinks);
          localStorage.setItem('originalDocuments', JSON.stringify(addSelectedLinks));
          localStorage.setItem('documentOriginalData', addSelectedLinks.length);
          setHasChanged(false);
        } else {
          if (
            localStorage.getItem('documentOrder') != undefined &&
            JSON.parse(localStorage.getItem('documentOrder')).length === result.length
          ) {
            const documentOrder = JSON.parse(localStorage.getItem('documentOrder'));
            const orderedDocument = annotateTableOrder(result, documentOrder);
            setDataSource(orderedDocument);
          } else if (localStorage.getItem('documentColKeyOrder') != undefined) {
            const { columnKey, order } = JSON.parse(localStorage.getItem('documentColKeyOrder'));
            const sortedTable = applyColumnOrder(columnKey, order, result);
            setDataSource(sortedTable);
            setIsLoading(false);
          } else {
            setDataSource(result);
            setIsLoading(false);
          }
          setOriginal(result);
          setSocketData(result);
          localStorage.setItem('originalDocuments', JSON.stringify(result));
          localStorage.setItem('documentOriginalData', result.length);
          setHasChanged(false);
        }
      });
    });
  };

  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }, []);

  // useEffect(() => {
  //   // let storedDocs = JSON.parse(localStorage.getItem('documentOrder'));
  //   // if (storedDocs && storedDocs.length > 0) {
  //   //   console.log(storedDocs);
  //   //   setDataSource(storedDocs);
  //   //   setOriginal(storedDocs);
  //   //   setSocketData(storedDocs);
  //   // } else {
  //   getAllFolders();
  //   // }
  // }, []);

  useEffect(() => {
    // searchDocuments({ folderId: '87' }).then((res) => {
    //   let docs = res;
    //   console.log(docs);
    // });
    if (hasChanged) {
      let documentOrder =
        localStorage.getItem('documentOrder') != undefined ? JSON.parse(localStorage.getItem('documentOrder')) : null;
      if (documentOrder && documentOrder?.length > 0) {
        const data = cloneDeep(dataSource);
        const orderedDataSource = annotateTableOrder(data, documentOrder);
        setDataSource(orderedDataSource);
        setIsLoading(false);
        setHasChanged(false);
      } else {
        let originalData =
          localStorage.getItem('originalDocuments') != undefined
            ? JSON.parse(localStorage.getItem('originalDocuments'))
            : null;
        if (originalData && originalData?.length > 0) {
          setDataSource(originalData);
          setIsLoading(false);
          setHasChanged(false);
        } else {
          getAllFolders();
        }
      }
    }
  }, [hasChanged]);

  // useEffect(() => {
  //   data.forEach((item) =>
  //     item.children.forEach((subItem, index) => {
  //       if (typeof subItem.key === 'string') {
  //         return item.children.splice(index, 1);
  //       }
  //     })
  //   );

  //   let dataSrc = data.map((d) => {
  //     d.children.push({ key: Math.random().toString(), name: '' });
  //     return d;
  //   });

  //   console.log(dataSrc);

  //   setDataSource(dataSrc);
  // }, []);

  const windowSize = useWindowSize();

  const showDrawer = (e, obj) => {
    updateVisible(true);
    setSelectedDocument(obj);
  };

  const showNote = (e, folderId) => {
    e.preventDefault();
    updateVisible1(true);
    setFolderId(folderId);
  };

  const showDocForm = (e, folderId) => {
    e.preventDefault();
    updateVisible2(true);
    setFolderId(folderId);
  };

  const onClose = () => {
    updateVisible(false);
    updateVisible1(false);
    updateVisible2(false);
  };

  const handleEditDocumentFolder = (info) => {
    if (rename) {
      eventSocket.emit(
        'updatesDocFolder',
        JSON.stringify({
          children: info?.children,
          folderId: info?.folderId,
          key: info?.key,
          name: info?.name.trim(),
          notes: info?.notes,
          selectedTasks: info?.selectedTasks,
          type: info?.type,
          uploads: info?.uploads,
        })
      );
      updateDocFolder(info?.key, { title: info?.name.trim() }).then((res) => {
        setIsEditing(false);
        setDocumentId('0');
        setRename(false);
      });
    } else {
      createDocumentFolder({ title: info?.name.trim(), restaurantId: resto.id }).then((res) => {
        if (res) {
          setIsAdding(false);
          eventSocket.emit(
            'addDocFolder',
            JSON.stringify({
              children: [
                {
                  key: `${res.id}-9999999`,
                  type: 'add-button',
                  parent_id: res.id,
                },
              ],
              key: res.id.toString(),
              name: res?.title && res.title.length ? res.title : 'Untitled Document Folder',
              type: 'parent',
              folderId: res.id.toString(),
              notes: 0,
              uploads: 0,
              selectedTasks: [],
            })
          );
        }
      });
    }
  };

  // const saveDocumentFolder = (e, info) => {
  //   e.preventDefault();
  //   console.log('DOC FOLDER', info);
  //   if (!info?.name) {
  //     const clonedList = cloneDeep(dataSource);
  //     remove(clonedList, (cl) => cl.key === '0');
  //     console.log(clonedList);
  //     setDataSource(clonedList);
  //   } else {
  //     if (rename) {
  //       eventSocket.emit(
  //         'updatesDocFolder',
  //         JSON.stringify({
  //           children: info?.children,
  //           folderId: info?.folderId,
  //           key: info?.key,
  //           name: info?.name.trim(),
  //           notes: info?.notes,
  //           selectedTasks: info?.selectedTasks,
  //           type: info?.type,
  //           uploads: info?.uploads,
  //         })
  //       );
  //       updateDocFolder(info?.key, { title: info?.name.trim() }).then((res) => {
  //         setIsEditing(false);
  //         setHasChanged(false);
  //         localStorage.removeItem('documentOrder');
  //         setDocumentId('0');
  //         setRename(false);
  //         getAllFolders();
  //       });
  //     } else {
  //       createDocumentFolder({ title: info?.name.trim(), restaurantId: resto.id }).then((res) => {
  //         setHasChanged(false);
  //         setIsEditing(false);
  //         localStorage.removeItem('documentOrder');
  //         eventSocket.emit(
  //           'addDocFolder',
  //           JSON.stringify({
  //             children: [],
  // key: res.id.toString(),
  // name: res.name,
  // type: 'parent',
  // folderId: res.id.toString(),
  // notes: 0,
  // uploads: 0,
  // selectedTasks: []
  //           })
  //         );
  //       });
  //     }
  //   }
  // };

  const renameFolder = (info) => {
    console.log('RENAME THIS FOLDER', info?.key);
    setDocumentId(info?.key);
    setIsEditing(true);
    setRename(true);
  };

  const deleteFolder = (info) => {
    console.log(info);
    if (info?.key * 1 > 0) {
      destroyDocFolder(info?.key).then((res) => {
        console.log('Resss', res);
        // setHasChanged(false);
        // localStorage.removeItem('documentOrder');
        eventSocket.emit(
          'deleteDocFolder',
          JSON.stringify({
            id: info?.key,
          })
        );
        // notification.open({
        //   message: 'Document Folder Successfully deleted!',
        // });
      });
    } else {
      const clonedList = cloneDeep(dataSource);
      remove(clonedList, (cl) => cl.key === '0');
      console.log(clonedList);
      setDataSource(clonedList);
    }
  };

  // const detachDocument = (info) => {
  //   setDocumentId(info.id.toString());
  //   setFolderId(info.folderId.toString());
  //   deleteAllLinks(info.id)
  //     .then((res) => {
  //       setTimeout(() => {
  //         setDocUpdated({});
  //       }, 2000);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const setDocProps = (e, obj) => {
    console.log(obj);
    if (obj.category !== 'Note') {
      localStorage.setItem(
        'docProps',
        JSON.stringify({
          name: obj.name,
          date: obj.upload_date,
          file: obj.info?.file,
          type: obj.info?.type,
        })
      );
    } else {
      localStorage.removeItem('docProps');
    }
  };

  // const menu = (
  //   <Menu className="menu-show-preview">
  //     {/* <Menu.Item className="menu-show-preview">
  //       <a target="_blank" rel="noopener noreferrer" href="">
  //         Upload
  //       </a>
  //     </Menu.Item>
  //     <Menu.Item className="menu-show-preview">
  //       <a target="_blank" rel="noopener noreferrer" href="">
  //         Create note
  //       </a>
  //     </Menu.Item> */}
  //     <Menu.Item className="menu-show-preview">
  //       <a target="_blank" rel="noopener noreferrer" href="">
  //         Add document folder
  //       </a>
  //     </Menu.Item>
  //   </Menu>
  // );

  const filterDocs = (type, typeName) => {
    setIsSearching(false);
    console.log(origSource);
    setTypeName(typeName);
    let toShow = [];
    if (type == 'All') {
      setIsFiltering(false);
      toShow = cloneDeep(origSource);
    } else {
      setIsFiltering(true);
      let clonedOrig = cloneDeep(origSource);
      toShow = clonedOrig
        .map((obj) => {
          obj.children = obj.children.filter(
            (doc) => doc.type !=='add-button' && 
            doc.category.indexOf(type) >= 0
          );
          return obj;
        })
        .filter((c) => c.children.length);
    }
    let viewOnly = type !== 'All' ? true : false; // when filtered, sorted display filters out open add rows and button rows

    if (localStorage.getItem('documentColKeyOrder') != undefined) {
      const { order, columnKey } = JSON.parse(localStorage.getItem('documentColKeyOrder'));
      const sorted = applyColumnOrder(columnKey, order, toShow, viewOnly);
      setDataSource(sorted);
    } else if (
      localStorage.getItem('documentOrder') != undefined &&
      JSON.parse(localStorage.getItem('documentOrder')).length > 0
    ) {
      const documentOrder = JSON.parse(localStorage.getItem('documentOrder'));
      const sorted = annotateTableOrder(toShow, documentOrder, viewOnly);
      setDataSource(sorted);
    } else {
      setDataSource(toShow);
    }
  };

  const menu1 = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs('All')}>
        <span>All</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs('Word', 'Word only')}>
        <span>Word only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs('Excel', 'Excel only')}>
        <span>Excel only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs('PDF', 'PDF only')}>
        <span>PDF only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs('image', 'Photo only')}>
        <span>Photo (JPEG, PNG, GIF etc.) only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs('Video', 'Video only')}>
        <span>Video (MOV, QT, etc) only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs('Note', 'Note only')}>
        <span>Note only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs('Powerpoint', 'Powerpoint only')}>
        <span>Powerpoint only</span>
      </Menu.Item>
    </Menu>
  );

  const docFolder = (action) => {
    return (
      <Menu className="menu-show-preview">
        <Menu.Item className="menu-show-preview" onClick={() => renameFolder(action)}>
          <span>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview" onClick={() => deleteFolder(action)}>
          <span>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (dragParent) {
        const data = [...dataSource];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        // setDataSource(data);
        // setOriginal(data);
        localStorage.setItem('documentOrder', JSON.stringify(cacheTableOrder(data).documentOrder));
        localStorage.removeItem('documentColKeyOrder');
        setHasChanged(true);
      } else {
        console.log(getCatId);
        let originalData = [...dataSource];
        console.log('ALL ROWS', originalData);
        const getObj = originalData.find((p) => p.key == getCatId.folderId);
        console.log('PARENT OF ROW TO MOVE', getObj);
        const data = getObj.children;
        const findIndx = data.findIndex((x) => x.key === getCatId.key);
        let firstIndex = Math.floor(fromIndex - findIndx);
        let destinationIndex = Math.floor(toIndex - firstIndex);
        const item = data.splice(findIndx, 1)[0];
        data.splice(destinationIndex, 0, item);
        originalData.find((v) => v.key == getCatId.folderId).children = data;
        console.log('REORDERED', originalData);
        localStorage.setItem('documentOrder', JSON.stringify(cacheTableOrder(originalData).documentOrder));
        localStorage.removeItem('documentColKeyOrder');
        // setDataSource(originalData);
        // setOriginal(originalData);
        setHasChanged(true);
      }
    },
    handleSelector: 'a',
    nodeSelector: dragParent
      ? 'tr.ant-table-row.parent.ant-table-row-level-0'
      : 'tr.ant-table-row.child.ant-table-row-level-1',
    ignoreSelector: dragParent
      ? 'tr.ant-table-row.child.ant-table-row-level-1'
      : 'tr.ant-table-row.parent.ant-table-row-level-0',
  };

  const hoverBeforeDrag = (e, obj) => {
    if (obj.type == 'parent') {
      setDragParent(true);
      activeCat.current = obj.key;
      isDown.current = true;
    } else {
      setGetCatId(obj);
      setDragParent(false);
      activeCat.current = obj.key;
      isDown.current = true;
    }
  };

  const cancelHover = (e, obj) => {
    if (obj.id == 'child') {
      setGetCatId({});
      activeCat.current = null;
      isDown.current = false;
    } else {
      setDragParent(false);
      activeCat.current = null;
      isDown.current = false;
    }
  };

  const columns = [
    {
      title: () => {
        return <div></div>;
      },
      className: 'col_handle',
      dataIndex: 'dragrow',
      width: 30,
      render: (key, obj) =>
        isFiltering || isSearching ? null : (
          <span>
            {obj.type == 'parent' ? (
              <a className="drag-handle" href="#">
                <span className={isDown.current && obj.key == activeCat.current ? 'showDragIcon' : 'hideDragIcon'}>
                  {' '}
                  <img src={reorder} alt="reorder" className="docToggles" />
                </span>
              </a>
            ) : (
              <a className="drag-handle" href="#">
                <span className={isDown.current && obj.key == activeCat.current ? 'showDragIcon' : 'hideDragIcon'}>
                  <img src={reorder} alt="reorder" className="docToggles" />
                </span>
              </a>
            )}
          </span>
        ),
    },
    // expand-row-icon
    {
      title: () => {
        return <div></div>;
      },
      className: 'col_expand',
      width: 20,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['ascend', 'descend'],
      width: '45%',
      render: (key, obj) => (
        <span>
          <span className={obj.type == 'parent' ? 'row-parent-title' : 'doc-name-key1'}>
            {obj.type == 'child' ? (
              <ThumbnailImage
                className="doc-image-container"
                src={docThumbnailSrc(obj)}
                placeholderIcon={docIcon(obj)}
                onClick={(e) => showDrawer(e, obj)}
              />
            ) : (
              ''
            )}
            {obj.type == 'child' || obj.type == 'add-button' ? (
              <span className="doc-title-container" onClick={(e) => setDocProps(e, obj)}>
                {key && (
                  <span onClick={(e) => showDrawer(e, obj)}>
                    {obj.category !== 'Note' ? obj.content : key}
                    {/* <Link
                      target={obj.category !== 'Note' ? '_blank' : ''}
                      to={
                        obj.category !== 'Note'
                          ? {
                              pathname: `/document/public/${obj.id}`,
                            }
                          : '/documents'
                      }
                    >
                      {' '}
                      {key}
                    </Link> */}
                  </span>
                )}
              </span>
            ) : obj.type === 'parent' && ((obj.key == documentid && isEditing) || (obj.key === '0' && isAdding)) ? (
              <div style={{ display: 'flex' }}>
                <input
                  autoFocus
                  type="text"
                  className="row-name-input"
                  defaultValue={obj.name}
                  onChange={(e) => {
                    const cloneList = cloneDeep(dataSource);
                    const index = findIndex(cloneList, { key: obj.key });
                    cloneList[index].name = e.target.value;
                    setDataSource(cloneList);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleEditDocumentFolder(obj);
                    }
                  }}
                  placeholder="Enter document folder name"
                />
              </div>
            ) : (
              <span className="attached-doc-folder-title"> {key}</span>
            )}

            {obj.type === 'parent' && obj.key !== '0' ? (
              <div className="doc-upload-count">
                ( {obj?.uploads} uploads, {obj?.notes} notes )
              </div>
            ) : (
              ''
            )}

            {obj.type == 'child' && obj.attachments > 0 ? (
              <CommentIndicator
                className="menu-attachment-indicator foo"
                count={obj.attachments}
                onClick={(e) => showDrawer(e, obj)}
              />
            ) : (
              ''
            )}

            {obj.type == 'add-button' ? (
              <span>
                {!key && (
                  <CidekicButton
                    className="button-link-replacement doc-item-add"
                    alt="upload file"
                    onClick={(e) => showDocForm(e, obj.parent_id)}
                  >
                    Upload
                  </CidekicButton>
                )}
                {!key && (
                  <CidekicButton
                    className="button-link-replacement doc-item-add"
                    alt="create note"
                    onClick={(e) => showNote(e, obj.parent_id)}
                  >
                    Create note
                  </CidekicButton>
                )}
              </span>
            ) : (
              ''
            )}
          </span>
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'category',
      sorter: (a, b) => (a.category < b.category ? -1 : a.category > b.category ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      width: '10%',
      render: (key, obj) => <span>{obj.type == 'child' && key ? <span>{key}</span> : ''}</span>,
    },
    {
      title: 'Date uploaded',
      dataIndex: 'upload_date',
      sorter: (a, b) => (a.upload_date < b.upload_date ? -1 : a.upload_date > b.upload_date ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: '10%',
    },
    {
      title: 'Linked',
      dataIndex: 'linkeds',
      sorter: (a, b) => (a.linkeds < b.linkeds ? -1 : a.linkeds > b.linkeds ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: '10%',
    },
    {
      title: 'Initiated by',
      dataIndex: 'assignee',
      sorter: (a, b) => (a.assignee < b.assignee ? -1 : a.assignee > b.assignee ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      width: '20%',
      render: (key, obj) => (
        <span>
          {obj.type == 'child' && key ? (
            <div className="common-user-icon" alt="user-photo">
              <UserImage src={obj.assigneeImg !== '' ? obj.assigneeImg : null} />
            </div>
          ) : (
            ''
          )}
          {obj.type == 'child' && key ? <span>{key}</span> : ''}
        </span>
      ),
    },
    // {
    //   title: 'Details',
    //   dataIndex: 'details',
    //   sorter: (a, b) => (a.details < b.details ? -1 : a.details > b.details ? 1 : 0),
    //   sortDirections: ['descend', 'ascend'],
    //   render: (key, obj) => (
    //     <span>
    //       {obj.type == 'child' && (
    //         <span className="docs-details" onClick={(e) => showDrawer(e, obj)}>
    //           Details
    //         </span>
    //       )}
    //     </span>
    //   ),
    // },
    // {
    // 		title: '',
    // 		dataIndex: 'detach',
    // 		width: 120,
    // 		render: (key, obj) => (
    // 				obj.type == 'child' && (
    // 						<span className='detach-docs' onClick={() => detachDocument(obj)}>Detach</span>
    // 				)
    // 		)
    // },
    {
      title: '',
      dataIndex: 'delete',
      width: 50,
      render: (key, obj) => (
        <span>
          {/* {obj.type == 'child' && (
            <span>
              {' '}
              <Dropdown overlay={deletesTask(obj)}>
                <div className="toggle-switch" onMouseOver={(e) => setDeleteTask(obj)}>
                  <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                </div>
              </Dropdown>
            </span>
          )} */}

          {obj.type == 'parent' && obj.key !== '0' && (
            <span>
              {' '}
              <Dropdown overlay={docFolder(obj)}>
                <div className="toggle-switch">
                  <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                </div>
              </Dropdown>
            </span>
          )}
          {obj.type === 'parent' && documentid == obj.key && isEditing && (
            <RowOkCancel
              className="float-right"
              onClickOk={() => handleEditDocumentFolder(obj)}
              onClickCancel={() => cancelEditDocumentFolder()}
            />
          )}
        </span>
      ),
    },
  ];

  const handleRowExpand = (record) => {
    if (expandedRows.includes(record.key)) {
      let filterRecord = expandedRows.filter((key) => key !== record.key);
      setExpandedRows(filterRecord);
    } else {
      setExpandedRows([...expandedRows, record.key]);
    }
  };

  const closeDrawer = useCallback((data) => {
    updateVisible1(false);
    updateVisible(false);
    updateVisible2(false);
  }, []);

  const updateComments = useCallback((data) => {
    setHasChanged(true);
  }, []);

  const setUpdatedDocument = useCallback((data) => {
    setDocUpdated(data);
  }, []);

  const setDeletedDocument = useCallback((data) => {
    setDocDeleted(data);
  });

  const searchDocs = (e) => {
    setIsFiltering(false);
    let inputValue = e.target.value;

    if (inputValue.length) {
      setIsSearching(true);

      const clonedData = _.cloneDeep(origSource);
      let searchString = inputValue.toLowerCase();
      let results = clonedData.filter(searchAllArr(inputValue));
      console.log(results);

      const searchTags = results.map((item) => ({
        ...item,
        children: item?.children?.filter(
          (child) =>
            (typeof child.name == 'string' && child.name?.toLowerCase().indexOf(searchString) !== -1) ||
            (typeof child.assignee == 'string' && child.assignee?.toLowerCase().indexOf(searchString) !== -1) ||
            (typeof child.category == 'string' && child.category?.toLowerCase().indexOf(searchString) !== -1) ||
            pluralize(typeof child.name == 'string' && child.name?.toLowerCase()).indexOf(searchString) !== 1 ||
            pluralize(typeof child.assignee == 'string' && child.assignee?.toLowerCase()).indexOf(searchString) !==
              -1 ||
            pluralize(typeof child.category == 'string' && child.category?.toLowerCase()).indexOf(searchString) !== -1
        ),
      }));
      console.log(searchTags);

      if (localStorage.getItem('documentColKeyOrder') != undefined) {
        const { order, columnKey } = JSON.parse(localStorage.getItem('documentColKeyOrder'));
        const toDisplay = applyColumnOrder(columnKey, order, searchTags, true);
        setDataSource(toDisplay);
      } else if (
        localStorage.getItem('documentOrder') != undefined &&
        JSON.parse(localStorage.getItem('documentOrder')).length
      ) {
        const documentOrder = JSON.parse(localStorage.getItem('documentOrder'));
        const toDisplay = annotateTableOrder(searchTags, documentOrder, true);
        setDataSource(toDisplay);
      } else {
        setDataSource(searchTags);
      }
    } else {
      setIsSearching(false);
      if (localStorage.getItem('documentColKeyOrder') != undefined) {
        const { order, columnKey } = JSON.parse(localStorage.getItem('documentColKeyOrder'));
        const toDisplay = applyColumnOrder(columnKey, order, socketData, false);
        setDataSource(toDisplay);
      } else if (
        localStorage.getItem('documentOrder') != undefined &&
        JSON.parse(localStorage.getItem('documentOrder')).length
      ) {
        const documentOrder = JSON.parse(localStorage.getItem('documentOrder'));
        const toDisplay = annotateTableOrder(socketData, documentOrder, false);
        setDataSource(toDisplay);
      } else {
        setDataSource(socketData);
      }
    }
  };

  const searchAllArr = (text) => (value) => {
    if (!value) return false;
    const valueType = typeof value;

    if (valueType === 'string') {
      return (
        value.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
        pluralize(value.toLowerCase()).indexOf(text.toLowerCase()) > -1
      );
    }
    if (Array.isArray(value)) {
      return value.some(searchAllArr(text));
    }
    if (valueType === 'object') {
      return Object.values(value).some(searchAllArr(text));
    }
    return false;
  };

  const setDestination = (e, obj) => {
    e.preventDefault();
    setDestinationId(obj);
  };

  const handleNewDocument = useCallback((val) => {
    setNewDocument(val);
  }, []);

  const handleDocumentId = useCallback((val) => {
    setDocumentId(val);
  }, []);

  const handleFolderId = useCallback((val) => {
    setFolderId(val);
  }, []);

  // const searchDocs = (e) => {
  //   let inputValue = e.target.value;
  //   const re = new RegExp(_.escapeRegExp(inputValue), 'i');

  //   const clonedData = _.cloneDeep(origSource);
  //   const results = clonedData.filter((object) => {
  //     return (
  //       object.children.filter((item) => {
  //         if (re.test(item.name)) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       }).length > 0 || re.test(object.name)
  //     );
  //   });
  //   console.log(results);
  //   setDataSource(results);
  //   filterRecs(results, inputValue);
  // };

  // const filterRecs = (arr, id) => {
  //   let r = arr.filter((o) => {
  //     if (o.children) o.children = filterRecs(o.children, id);
  //     return o?.name?.toLowerCase().includes(id.toLowerCase());
  //   });
  //   return r;
  // };

  const cancelEditDocumentFolder = () => {
    if (isEditing) {
      setDocumentId('');
      setIsEditing(false);
      setRename(false);
    } else {
      const clonedList = cloneDeep(dataSource);
      remove(clonedList, (cl) => cl.key === '0');
      console.log(clonedList);
      setDataSource(clonedList);
    }
  };

  useEffect(() => {
    eventSocket.on('updatedDocFolder', (message) => {
      let newData = JSON.parse(message);
      console.log('DOCFOLDERUPDATED!!', [newData]);
      setDocFolderUpdate([newData]);
    });
    // eventSocket.on('updatedChecklistItem', (message) => {
    //   let newData = JSON.parse(message);
    //   console.log('ITEMUPDATED!!', newData);
    //   setItemUpdate(newData);
    // });
    // eventSocket.on('updatedStationTask', (message) => {
    //   let newData = JSON.parse(message);
    //   console.log('ITEMUPDATEDSTATION!!', newData.id);
    //   setItemCheckUpdate(newData.id);
    // });
    eventSocket.on('addedDocFolder', (message) => {
      let newData = JSON.parse(message);
      console.log('DOCFOLDERADDED!!', newData);
      setDocFolderAdd(newData);
    });
    // eventSocket.on('addedChecklistItem', (message) => {
    //   let newData = JSON.parse(message);
    //   console.log('ITEMADDED!!', newData);
    //   setItemAdd(newData);
    // });
    eventSocket.on('deletedDocFolder', (message) => {
      let newData = JSON.parse(message);
      console.log('DOCFOLDERDELETED!!', newData.id);
      setDocFolderDelete(newData.id);
    });
    // eventSocket.on('deletedChecklistItem', (message) => {
    //   let newData = JSON.parse(message);
    //   console.log('CHKLISTITEMDELETED!!', newData.id);
    //   setItemDelete(newData.id);
    // });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  // // concerning conflict between localStorage variables 'documentOrder' and 'browserProfiles[i].documentOrder'
  // const resetDocOrderBrowserProfile = () => {
  //   let operatorLoggedIn = JSON.parse(localStorage.getItem('operator')) || {};
  //   const currentUserId = localStorage.getItem('currentOperatorId')?.toString()
  //     ? localStorage.getItem('currentOperatorId').toString()
  //     : operatorLoggedIn?.id;
  //   const currentLocation = localStorage.getItem('restaurant')
  //     ? JSON.parse(localStorage.getItem('restaurant')).name
  //     : null;
  //   if (localStorage.getItem('browserProfiles') && JSON.parse(localStorage.getItem('browserProfiles')).length > 0) {
  //     let browserRecords = JSON.parse(localStorage.getItem('browserProfiles'));
  //     const foundProfile = findIndex(
  //       browserRecords,
  //       (prof) => prof.userId === currentUserId && JSON.parse(prof.restaurant).name === currentLocation
  //     );
  //     // if user logging in has existing profile and/or 'browserProfiles' exist
  //     if (foundProfile !== null && foundProfile >= 0) {
  //       const browserProfile = browserRecords[foundProfile];
  //       const hasDocumentOrder = browserProfile?.documentOrder;
  //       if (hasDocumentOrder) {
  //         delete browserProfile['documentOrder'];
  //         browserRecords[foundProfile] = browserProfile;
  //         localStorage.setItem('browserProfiles', JSON.stringify(browserRecords));
  //       }
  //     }
  //   }
  // };

  const addDocumentToFolder = (cloned, data) => {
    let newCloned = cloned;
    console.log('CLONED', newCloned, 'DATA', data);
    let newDoc = {
      folderId: data.folderId,
      id: data.id,
      key: `${data.folderId}-${data.id}`,
      name: data?.info?.title && data?.info?.title !== '' ? data?.info?.title : 'Untitled',
      content: data.title && data.title !== '' ? data.title : '',
      type: 'child',
      info: data.info,
      category: data?.info?.type,
      selectedTask: data?.info?.selectedTasks,
      assignee: data?.info?.createdBy?.replace(/\s{2,}/g, ' '),
      assigneeImg: data?.info?.createdBy?.replace(/\s{2,}/g, ' ')
        ? data?.info?.createdBy?.replace(/\s{2,}/g, ' ') == userName
          ? userImage
          : ''
        : '',
      upload_date: data?.info?.timestamp
        ? moment(data?.info?.timestamp).format('MM-DD-YYYY')
        : moment(data?.info?.updatedDate).format('MM-DD-YYYY'),
      linked: data?.info?.linked,
      attachments: data?.info?.comments?.length,
      details: 'true',
      linkeds: data?.info?.linked ? data.info.linked : 0,
    };
    const targetFolder = findIndex(newCloned, (docFolder) => docFolder.key == folderid);
    let dataChildren = newCloned[targetFolder].children;
    if (targetFolder !== -1) {
      let dataChildren = newCloned[targetFolder].children;
      if (dataChildren.length > 1) {
        // newCloned[targetFolder].children[dataChildren.length - 1] = { ...newDoc };
        newCloned[targetFolder].children.splice(2, 1, { ...newDoc });
      } else {
        newCloned[targetFolder].children.unshift(newDoc);
      }

      newCloned[targetFolder].notes = newCloned[targetFolder]?.children?.filter((c) => c?.info?.type == 'Note').length;
      newCloned[targetFolder].uploads = newCloned[targetFolder]?.children?.filter(
        (c) => c?.info?.type !== 'Note' && c.type !== 'add-button'
      ).length;
      return newCloned;
    } else {
      return false;
    }
  };

  const updateDocumentInFolder = (cloned, data) => {
    let newCloned = cloned;
    let updatedDoc = {
      folderId: data.folderId,
      id: data.id,
      key: `${data.folderId}-${data.id}`,
      name: data?.info?.title && data?.info?.title !== '' ? data?.info?.title : 'Untitled',
      content: data.title && data.title !== '' ? data.title : '',
      type: 'child',
      info: data.info,
      category: data?.info?.type,
      selectedTask: data?.info?.selectedTasks,
      assignee: data?.info?.createdBy?.replace(/\s{2,}/g, ' '),
      assigneeImg: data?.info?.createdBy?.replace(/\s{2,}/g, ' ')
        ? data?.info?.createdBy?.replace(/\s{2,}/g, ' ') == userName
          ? userImage
          : ''
        : '',
      upload_date: data?.info?.timestamp
        ? moment(data?.info?.timestamp).format('MM-DD-YYYY')
        : moment(data?.info?.updatedDate).format('MM-DD-YYYY'),
      linked: data?.info?.linked,
      attachments: data?.info?.comments?.length,
      details: 'true',
      linkeds: data?.info?.linked ? data.info.linked : 0,
    };
    const targetFolder = findIndex(newCloned, (docFolder) => docFolder.key == folderid);
    if (targetFolder !== -1) {
      const targetDocument = findIndex(newCloned[targetFolder].children, (doc) => doc.id == documentid);
      newCloned[targetFolder].children[targetDocument] = { ...updatedDoc };
      return newCloned;
    } else {
      return false;
    }
  };

  const removeDocumentFromFolder = (cloned) => {
    let newCloned = cloned;
    const targetFolder = findIndex(newCloned, (docFolder) => docFolder.key == folderid);
    // const targetDocument = findIndex(newCloned[targetFolder].children, (doc) => doc.id == documentid);
    // const deletedDocument = newCloned[targetFolder].children[targetDocument];
    if (targetFolder !== -1) {
      newCloned[targetFolder].children = newCloned[targetFolder].children.filter((doc) => doc.id !== documentid);
      newCloned[targetFolder].notes = newCloned[targetFolder]?.children?.filter((c) => c?.info?.type == 'Note').length;
      newCloned[targetFolder].uploads = newCloned[targetFolder]?.children?.filter(
        (c) => c?.info?.type !== 'Note' && c.type !== 'add-button'
      ).length;
      return newCloned;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // if (Object.keys(docFolderAdd).length > 0) {
    //   setDataSource((oldArray) => [...oldArray, docFolderAdd]);
    //   setOriginal((oldArray) => [...oldArray, docFolderAdd]);
    //   setSocketData((oldArray) => [...oldArray, docFolderAdd]);
    // }
    if (Object.keys(docFolderAdd).length) {
      // const openAddRows = removeOpenAdds(dataSource);
      const currentIndex = findIndex(dataSource, (k) => k.key === '0');
      const clonedData = cloneDeep(dataSource);
      const clonedSocket = cloneDeep(socketData);
      clonedData.splice(currentIndex, 1, docFolderAdd);
      clonedSocket.splice(currentIndex, 1, docFolderAdd);

      const updatedTableOrder = cacheTableOrder(clonedData);
      setDataSource(updatedTableOrder.orderedDocument);
      localStorage.setItem('documentOrder', JSON.stringify(updatedTableOrder.documentOrder));

      setOriginal(clonedSocket);
      setSocketData(clonedSocket);
      localStorage.setItem('originalDocuments', JSON.stringify(clonedSocket));
      localStorage.setItem('documentOriginalData', clonedSocket.length);
    }
  }, [docFolderAdd]);

  useEffect(() => {
    if (docFolderDelete.length) {
      let updatedData = dataSource.filter((obj) => obj.key !== docFolderDelete.toString());
      let updatedSocket = socketData.filter((obj) => obj.key !== docFolderDelete.toString());

      const updatedTableOrder = cacheTableOrder(updatedData);
      setDataSource(updatedTableOrder.orderedDocument);
      localStorage.setItem('documentOrder', JSON.stringify(updatedTableOrder.documentOrder));

      // update to originalDocuments all document categories deleted from table
      setSocketData(updatedSocket);
      setOriginal(updatedSocket);
      localStorage.setItem('originalDocuments', JSON.stringify(updatedSocket));
      localStorage.setItem('documentOriginalData', updatedSocket.length);
    }
  }, [docFolderDelete]);

  useEffect(() => {
    if (docFolderUpdate.length) {
      let updatedData = dataSource.map((obj) => docFolderUpdate.find((o) => o.key === obj.key) || obj);
      let updatedSocket = socketData.map((obj) => docFolderUpdate.find((o) => o.key === obj.key) || obj);
      if (
        localStorage.getItem('documentOrder') != undefined &&
        JSON.parse(localStorage.getItem('documentOrder')).length
      ) {
        const docOrder = JSON.parse(localStorage.getItem('documentOrder'));
        const dataOrdered = annotateTableOrder(updatedData, docOrder);
        setDataSource(dataOrdered);
      } else if (localStorage.getItem('documentColKeyOrder') != undefined) {
        const { columnKey, order } = JSON.parse(localStorage.getItem('documentColKeyOrder'));
        const dataOrdered = applyColumnOrder(columnKey, order, updatedData);
        setDataSource(dataOrdered);
      } else {
        setDataSource(updatedData);
      }

      setOriginal(updatedSocket);
      setSocketData(updatedSocket);
      localStorage.setItem('originalDocuments', JSON.stringify(updatedSocket));
    }
  }, [docFolderUpdate]);

  useEffect(() => {
    if (docDeleted && documentid.length && folderid.length) {
      let clonedData = cloneDeep(dataSource);
      let clonedSocket = cloneDeep(socketData);

      let updatedData = removeDocumentFromFolder(clonedData);
      let updatedSocket = removeDocumentFromFolder(clonedSocket);
      setDocumentId('');
      setFolderId('');

      if (updatedData && updatedSocket) {
        const updatedTableOrder = cacheTableOrder(updatedData);
        localStorage.setItem('documentOrder', JSON.stringify(updatedTableOrder.documentOrder));
        setDataSource(updatedTableOrder.orderedDocument);

        setOriginal(updatedSocket);
        setSocketData(updatedSocket);
        localStorage.setItem('originalDocuments', JSON.stringify(updatedSocket));
        localStorage.setItem('documentOriginalData', updatedSocket.length);
        setDocDeleted(false);
      }
    }
  }, [docDeleted]);

  useEffect(() => {
    if (docAdded && Object.keys(newDocument).length) {
      let clonedData = cloneDeep(dataSource);
      let clonedSocket = cloneDeep(socketData);
      let updatedData = addDocumentToFolder(clonedData, newDocument);
      let updatedSocket = addDocumentToFolder(clonedSocket, newDocument);
      setNewDocument({});
      setDocumentId('');
      setFolderId('');
      setDocAdded(false);

      if (updatedData && updatedSocket) {
        const updatedTableOrder = cacheTableOrder(updatedData);
        localStorage.setItem('documentOrder', JSON.stringify(updatedTableOrder.documentOrder));
        setDataSource(updatedTableOrder.orderedDocument);

        setOriginal(updatedSocket);
        setSocketData(updatedSocket);
        localStorage.setItem('originalDocuments', JSON.stringify(updatedSocket));
        localStorage.setItem('documentOriginalData', updatedSocket.length);
      }
    }
  }, [docAdded]);

  useEffect(() => {
    if (Object.keys(docUpdated).length) {
      let clonedData = cloneDeep(dataSource);
      let clonedSocket = cloneDeep(socketData);

      let updatedData = updateDocumentInFolder(clonedData, docUpdated);
      let updatedSocket = updateDocumentInFolder(clonedSocket, docUpdated);
      setFolderId('');
      setDocUpdated({});

      if (updatedData && updatedSocket) {
        const updatedTableOrder = cacheTableOrder(updatedData);
        localStorage.setItem('documentOrder', JSON.stringify(updatedTableOrder.documentOrder));
        setDataSource(updatedTableOrder.orderedDocument);

        setOriginal(updatedSocket);
        setSocketData(updatedSocket);
        localStorage.setItem('originalDocuments', JSON.stringify(updatedSocket));
        localStorage.setItem('documentOriginalData', updatedSocket.length);
      }
    }
  }, [docUpdated]);

  const onTableChange = (pagination, filters, sorter, extra) => {
    const data = [...dataSource];
    // const current = extra['currentDataSource'];
    const viewOnly = isFiltering || isSearching ? true : false;
    if (data.length && !viewOnly) {
      // if sorter is neither ascending nor descending,
      // resort to manual document table order
      // or sort by key
      // else save last auto table order (ascending or descending)

      if (sorter?.order == undefined) {
        if (
          localStorage.getItem('documentOrder') != undefined &&
          JSON.parse(localStorage.getItem('documentOrder')).length
        ) {
          const documentOrder = JSON.parse(localStorage.getItem('documentOrder'));
          const restoredOrder = annotateTableOrder(data, documentOrder, viewOnly);
          setDataSource(restoredOrder);
        } else {
          if (viewOnly) {
            setDataSource(data);
          } else {
            const restoredOrder = deannotateTableOrder(data);
            setDataSource(restoredOrder);
          }
        }
        localStorage.removeItem('documentColKeyOrder');
      } else {
        localStorage.setItem(
          'documentColKeyOrder',
          JSON.stringify({
            order: sorter.order,
            columnKey: sorter.columnKey,
          })
        );
        const restoredOrder = applyColumnOrder(sorter.columnKey, sorter.order, data);
        setDataSource(restoredOrder);
        // extra['currentDataSource'] = [...restoredOrder]; // ANTD Table remains uncontrolled component when applying newly sorted order from custom function, to be changed...
      }
    }
  };

  // let nameOrder = localStorage.getItem('sortDocumentOrder');
  // useEffect(() => {
  //   if (nameOrder) {
  //     setTimeout(() => {
  //       localStorage.removeItem('sortDocumentOrder');
  //       window.location.reload();
  //     }, 2000);
  //   }
  // }, [nameOrder]);

  // cacheTableOrder - caches and annotates position of each parent and child row of current table order (index-based position)
  const cacheTableOrder = (data) => {
    let orderCached = [];
    let tableOrdered = [];
    if (data.length > 0) {
      tableOrdered = data.map((d, i) => {
        // for menu categories
        const parentRow = {};
        parentRow.key = d.key;
        if (d?.order == undefined || d.order !== i) {
          parentRow.order = i; // caches order
          d.order = i; // annotates order
        } else {
          parentRow.order = d.order;
        }
        parentRow.children = [];
        if (d.children && d.children.length) {
          d.children = d.children.map((c, j) => {
            // for menus
            const childRow = {};
            childRow.key = c.key;
            if (c?.order == undefined || c.order !== j) {
              childRow.order = j; // caches order
              c.order = j; // annotates order
            } else {
              childRow.order = c.order;
            }
            parentRow.children.push(childRow);
            return c;
          });
          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        orderCached.push(parentRow);
        return d;
      });
      if (data.length > 1) {
        tableOrdered = tableOrdered.sort((a, b) => a.order - b.order);
      }
    }
    return {
      documentOrder: orderCached, // cached table order
      orderedDocument: tableOrdered, // annotated table rows
    };
  };

  // annotateTableOrder - annotates new/current/updated order for each parent and child record of table (index-based position)
  // callback when localStorage variable 'documentOrder' exists, then applies to fetched data
  const annotateTableOrder = (data, documentOrder, viewOnly = false) => {
    let orderedTable = cloneDeep(data);
    if (data.length > 0) {
      const tableAnnotated = orderedTable.map((d) => {
        // annotate document folder cached order
        const folderIndex = findIndex(documentOrder, (r) => r.key === d.key);
        d.order = documentOrder[folderIndex]?.order;
        if (d.children && d.children.length) {
          d.children = d.children.map((c, i) => {
            // annotate document cached order
            const documentIndex = findIndex(documentOrder[folderIndex].children, (h) => h.key === c.key);
            if (documentOrder[folderIndex]?.children[documentIndex] != undefined) {
              const cachedOrder = documentOrder[folderIndex]?.children[documentIndex].order;
              c.order = cachedOrder != undefined && !viewOnly && cachedOrder !== i ? cachedOrder : i;
            } else {
              c.order = i;
            }
            return c;
          });

          if (viewOnly) {
            // removes open add documents
            d.children = d.children.filter((a) => a.type !== 'add-button');
          }
          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        return d;
      });

      orderedTable = tableAnnotated;

      if (viewOnly) {
        // removes open add folder
        orderedTable = tableAnnotated.filter((a) => a.key !== '0');
      }

      if (data.length > 1) {
        orderedTable = tableAnnotated.sort((a, b) => a.order - b.order);
      }
    }
    return orderedTable;
  };

  // deannotateTableOrder - removes index order for each parent and child record
  const deannotateTableOrder = (data) => {
    if (data.length > 0) {
      // filters out open add document folder rows
      let orderRemoved = data;

      if (orderRemoved.length) {
        orderRemoved = orderRemoved.map((d) => {
          if (d?.order != undefined) {
            delete d['order'];
          }
          if (d.children && d.children.length) {
            d.children = d.children.map((e) => {
              if (e?.order != undefined) {
                delete e['order'];
              }
              return e;
            });
            if (d.children.length > 1) {
              d.children = d.children.sort((a, b) => a.key.localeCompare(b.key));
            }
          }
          return d;
        });
        if (orderRemoved.length > 1) {
          orderRemoved = orderRemoved.sort((a, b) => a.key.localeCompare(b.key));
        }
        return orderRemoved;
      }
    }
    return data;
  };

  // applyColumnOrder - apply cached ANTD column order to dataSource
  const applyColumnOrder = (column, status, list = [], viewOnly = false) => {
    let rows = list.length ? list : cloneDeep(origSource);
    let tableRows = rows.filter((r) => r.key !== '0');
    let toAddFolder = rows.filter((r) => r.key === '0');
    let orderedRows = [];

    if (tableRows.length) {
      // if single result, return
      if (rows.length === 1 && rows[0].children.length === 1) {
        return list;
      }

      orderedRows = tableRows.map((p) => {
        if (p.children !== undefined && p.children.length > 2) {
          let getRows = p.children.filter((c) => c.type !== 'add-button');
          let buttonRow = p.children.filter((c) => c.type === 'add-button');

          if (column === 'category' || column === 'name' || column === 'assignee') {
            // string value column keys
            if (status === 'ascend') {
              getRows = getRows.sort((a, b) => a[column].toLowerCase().localeCompare(b[column].toLowerCase()));
            } else {
              getRows = getRows.sort((a, b) => b[column].toLowerCase().localeCompare(a[column].toLowerCase()));
            }
          } else if (column === 'linkeds') {
            // number value column key
            if (status === 'ascend') {
              getRows = getRows.sort((a, b) => a[column] - b[column]);
            } else {
              getRows = getRows.sort((a, b) => b[column] - a[column]);
            }
          } else if (column === 'upload_date') {
            // timestamp value column key
            if (status === 'ascend') {
              getRows = getRows.sort(
                (a, b) =>
                  Math.round(new Date(a[column]).getTime() / 1000) - Math.round(new Date(b[column]).getTime() / 1000)
              );
            } else {
              getRows = getRows.sort(
                (a, b) =>
                  Math.round(new Date(b[column]).getTime() / 1000) - Math.round(new Date(a[column]).getTime() / 1000)
              );
            }
          }

          if (!viewOnly) {
            getRows.push(buttonRow[0]);
          }
          p.children = getRows;
        }
        return p;
      });

      // if more than 1 parent rows and column key sorter is 'name', sort as well
      if (orderedRows.length > 1 && column === 'name') {
        if (status === 'ascend') {
          orderedRows = orderedRows.sort((a, b) => a[column].toLowerCase().localeCompare(b[column].toLowerCase()));
        } else {
          orderedRows = orderedRows.sort((a, b) => b[column].toLowerCase().localeCompare(a[column].toLowerCase()));
        }
      }

      if (!viewOnly && toAddFolder.length) {
        // column sorting does not apply but open add rows always at the top
        orderedRows.unshift(toAddFolder[0]);
      }
    }
    console.log(orderedRows);
    return orderedRows;
  };

  // removeOpenAdds - remove open add rows in documentRows
  const removeOpenAdds = (documentRows) => {
    let removedOpenAdds = documentRows.filter((k) => k.key !== '0'); // removes open add document folder rows
    if (removedOpenAdds.length > 1) {
      // table rows with removed open add rows might have index-based order values
      // making sorting formula inaccurate with simple subtraction
      // used boolean comparison instead on ternary
      // then sorted with new assigned index as order values for each
      return removedOpenAdds
        .map((l, i) => {
          if (l.order != undefined || l.order * 1 !== i) {
            l.order = i;
          }
          return l;
        })
        .sort((a, b) => a.order - b.order);
    } else {
      return removedOpenAdds;
    }
  };

  const listener = () => {
    let originalDocuments =
      localStorage.getItem('originalDocuments') != undefined
        ? JSON.parse(localStorage.getItem('originalDocuments'))
        : [];
    let documentOrder =
      localStorage.getItem('documentOrder') != undefined ? JSON.parse(localStorage.getItem('documentOrder')) : null;
    let documentColumnKeyOrder =
      localStorage.getItem('documentColKeyOrder') != undefined
        ? JSON.parse(localStorage.getItem('documentColKeyOrder'))
        : null;
    let originalDataCount =
      localStorage.getItem('documentOriginalData') != undefined ? localStorage.getItem('documentOriginalData') : null;

    if (documentOrder && originalDataCount && originalDocuments.length) {
      let openAddsRemoved = documentOrder;
      // if current number of folders in documentOrder != originalDataCount, change some variables
      // else return
      if (parseInt(documentOrder.length) !== parseInt(originalDataCount)) {
        openAddsRemoved = removeOpenAdds(documentOrder); // removes open add folder rows and resort
        // if documentOrder retains document folders, update to localStorage
        // else remove 'documentOrder' and 'documentOriginalData' from localStorage
        if (openAddsRemoved.length) {
          setOriginal(originalDocuments);
          setSocketData(originalDocuments);
          setDataSource(originalDocuments);
          localStorage.setItem('documentOrder', JSON.stringify(openAddsRemoved));
        } else {
          localStorage.removeItem('documentOrder');
          localStorage.removeItem('documentOriginalData');
        }
      } else {
        // if cached table data originalDocuments is not empty array,
        // apply latest column sorting
        // or latest manual sorting (without open add folder rows)
        // or no sorting applied if above are not applicable
        // to dataSource and socketData
        if (documentColumnKeyOrder) {
          const { order, columnKey } = documentColumnKeyOrder;
          const restoreData = applyColumnOrder(columnKey, order, originalDocuments);
          setOriginal(originalDocuments);
          setSocketData(originalDocuments);
          setDataSource(restoreData);
          setIsLoading(false);
        } else {
          const restoreData = annotateTableOrder(originalDocuments, documentOrder);
          setOriginal(originalDocuments);
          setSocketData(originalDocuments);
          setDataSource(restoreData);
          setIsLoading(false);
        }
      }
    } else {
      getAllFolders();
    }
  };

  useEffect(() => {
    document.addEventListener('beforeunload', listener());
    return () => {
      document.removeEventListener('beforeunload', listener());
    };
  }, []);

  return (
    <div id="documents" className="common-page-container">
      <NewSubHeader title="Documents" onChange={searchDocs} />
      <div className="Line"></div>
      <div className="common-page-content">
        <div className="common-controls-container controls-thin">
          <PlusButton
            className="flex-control-left flex-control-rightspace"
            alt="Add new folder"
            onClick={() => {
              const clonedDataSource = cloneDeep(dataSource);
              const hasNew = find(clonedDataSource, { key: '0' });
              if (!hasNew) {
                clonedDataSource.unshift(newDocsValue);
                setIsAdding(true);
                if (
                  localStorage.getItem('documentOrder') != undefined &&
                  JSON.parse(localStorage.getItem('documentOrder')).length
                ) {
                  const { documentOrder, orderedDocument } = cacheTableOrder(clonedDataSource);
                  localStorage.setItem('documentOrder', JSON.stringify(documentOrder));
                  setDataSource(orderedDocument);
                } else {
                  setDataSource(clonedDataSource);
                }
                window.scrollTo(0, 0);
              }
            }}
          >
            Add new folder
          </PlusButton>
          <PlusButton className="flex-control-left" alt="Collapse all folders" onClick={() => setExpandedRows([])}>
            Collapse all
          </PlusButton>
          <CidekicDropdown
            overlay={menu1}
            className="flex-control-right select-wide"
            onClick={(e) => e.preventDefault()}
          >
            {typeName ? typeName : 'All documents'}
          </CidekicDropdown>
          {/* <Dropdown overlay={menu}> */}
          {/* </Dropdown> */}
          {/* <Dropdown overlay={menu}>
            <div className="dropdown-icon ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </Dropdown> */}
        </div>
        <div className="flex-break"></div>
        {isLoading && (
          <div style={{ textAlign: 'center' }}>
            <Spin tip="Loading documents.."></Spin>
          </div>
        )}
        {!isLoading && (
          <div className="flex-grow-1">
            <ReactDragListView {...dragProps}>
              {/* <div className="proxy">Proxy</div> */}
              <Table
                useFixedHeader={true}
                columns={columns}
                dataSource={dataSource}
                className={'show-custom-empty ant-table-fixedheader'}
                rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
                onRow={(record, index) => ({
                  onMouseOver: (event) => {
                    hoverBeforeDrag(event, record);
                  },
                  onDrop: (event) => {
                    setDestination(event, record);
                  },
                  onMouseLeave: (event) => {
                    cancelHover(event, record);
                  },
                })}
                expandIconColumnIndex={1}
                expandIcon={(props) => {
                  if (props.record.type == 'parent') {
                    return (
                      <ExpandCollapseIcon
                        expanded={props.expanded}
                        onClick={(e) => {
                          props.onExpand(props.record, e);
                        }}
                      />
                    );
                  }
                }}
                pagination={false}
                locale={{ emptyText: <Empty image={empty} description="No documents" /> }}
                onExpand={(expanded, record) => handleRowExpand(record)}
                expandedRowKeys={expandedRows}
                onChange={onTableChange}
                // scroll={{y: 'calc(100vh - var(--table-scroll-offset))'}}
                rowKey={'key'}
              />
            </ReactDragListView>
          </div>
        )}
      </div>

      <Drawer
        width={windowSize.width > 800 ? '600px' : '100%'}
        closable={false}
        onClose={onClose}
        visible={visible}
        drawerStyle={{ backgroundColor: 'var(--main-fill)', overflow: 'hidden' }}
        destroyOnClose={true}
      >
        <div style={{ marginTop: 15 }}>
          <Row gutter={32}>
            <Col className="gutter-row" span={21}></Col>
            <Col className="gutter-row" span={3}>
              <CloseCircleOutlined
                className="close-add-event"
                onClick={() => {
                  updateVisible(false);
                  updateVisible1(false);
                  updateVisible2(false);
                }}
              />
            </Col>
          </Row>
        </div>
        <DocumentsDetailPopup
          data={selectedDocument}
          closeDrawer={closeDrawer}
          updateComments={updateComments}
          handleDocumentId={handleDocumentId}
          handleFolderId={handleFolderId}
          setUpdatedDocument={setUpdatedDocument}
          setDeletedDocument={setDeletedDocument}
        />
      </Drawer>

      <Drawer
        width={windowSize.width > 800 ? '600px' : '100%'}
        closable={false}
        onClose={onClose}
        visible={visible1}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <div style={{ marginTop: 15 }}>
          <Row gutter={32}>
            <Col className="gutter-row" span={21}></Col>
            <Col className="gutter-row" span={3}>
              <CloseCircleOutlined
                className="close-add-event"
                onClick={() => {
                  updateVisible(false);
                  updateVisible1(false);
                  updateVisible2(false);
                  // getAllFolders();
                }}
              />
            </Col>
          </Row>
        </div>
        <CreateNotePopup
          closeDrawer={closeDrawer}
          folder={folderid}
          visible={visible1}
          setAddedDocument={setDocAdded}
          handleNewDocument={handleNewDocument}
        />
      </Drawer>
      <Drawer
        width={windowSize.width > 800 ? '600px' : '100%'}
        onClose={onClose}
        visible={visible2}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <div style={{ marginTop: 15 }}>
          <Row gutter={32}>
            <Col className="gutter-row" span={21}></Col>
            <Col className="gutter-row" span={3}>
              <CloseCircleOutlined
                className="close-add-event"
                onClick={() => {
                  updateVisible(false);
                  updateVisible1(false);
                  updateVisible2(false);
                }}
              />
            </Col>
          </Row>
        </div>
        <UploadDocument
          closeDrawer={closeDrawer}
          folder={folderid}
          visible={visible2}
          setAddedDocument={setDocAdded}
          handleNewDocument={handleNewDocument}
        />
      </Drawer>
    </div>
  );
};

export default Documents
