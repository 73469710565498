import { request } from './http.service';
import { decodeGetEventsMessages } from '../protobuf-models/getevents.proto.js';
import { decodeGetEventsFutureMessages } from '../protobuf-models/geteventsfuture.proto.js'; //

export const create = (params) => {
  console.log('Event Create Params');
  console.log(params);
  return request('POST', '/event', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateEvent = (id, params) => {
  console.log('Event Update Params');
  console.log(params);
  // return null;
  return request('POST', '/event/' + id, 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroy = (id) => {
  return request('DELETE', '/event/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const get = (id) => {
  return request('GET', '/event/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchEvents = (params) => {
  return request('POST', '/event/search', 200, params, decodeGetEventsMessages).then(
    (result) => {
      if (result && result.events) {
        let events = result.events.map((f) => {
          f.info = JSON.parse(f.info);
          f.EventMenus = JSON.parse(f.EventMenus);
          f.percentage = f.percentage !== '' ? f.percentage : '0';
          return f;
        });
        return events;
      }
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchByMenus = (params) => {
  return request('POST', '/event/with/menus', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const getEvents = (params) => {
  return request('POST', '/event/getEvents', 200, params, decodeGetEventsMessages).then(
    (result) => {
      if (result && result.events) {
        let events = result.events.map((f) => {
          f.info = JSON.parse(f.info);
          f.EventOperators = JSON.parse(f.EventOperators);
          f.EventMenus = JSON.parse(f.EventMenus);
          // f.percentage = f.percentage !== '' ? f.percentage : '0';
          return f;
        });

        return events;
      } else {
        return [];
      }
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const getFutureEvents = (params) => {
  return request('POST', '/event/getFutureEvents', 200, params, decodeGetEventsFutureMessages).then(
    (result) => {
      if (result && result.futureevents) {
        let future = result.futureevents.map((f) => {
          f.info = JSON.parse(f.info);
          f.EventMenus = JSON.parse(f.EventMenus);
          f.EventOperators = JSON.parse(f.EventOperators);
          return f;
        });
        return future;
      } else {
        return [];
      }
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const menus = (id) => {
  return request('GET', '/event/' + id + '/menus', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const assignMenu = (id, menuId) => {
  return request('POST', '/event/' + id + '/menus/' + menuId, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const unassignMenu = (id, menuId) => {
  return request('DELETE', '/event/' + id + '/menus/' + menuId, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};
