/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Table, Menu, Dropdown } from 'antd';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { useHistory } from 'react-router-dom';
import { all, destroy, update, create } from '../../services/account.service';
import { impersonate } from '../../services/operator.service';
import { SubHeader } from '../../components/Header/Header';
import { PlusButton, RowOkCancel } from '../../components/common/common_ui';

const Accounts = (props) => {
  const history = useHistory();

  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [isAdding, setAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const location_name = useRef(null);

  useEffect(() => {
    console.log(props.location.state);
    if (props.location.state == 'unimpersonate') {
      setHasUpdated(true);
    }
  }, [props.location.state]);

  useEffect(() => {
    getAccounts();
    setTimeout(() => {
      setHasUpdated(false);
      setEditing(false);
      setAdding(false);
      setEditId(null);
    }, 2000);
  }, [hasUpdated]);

  const getAccounts = () => {
    setIsLoading(true);

    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setData(allAccounts);
        setOrigData(allAccounts);
        setIsLoading(false);
      }
    });
  };

  const filterMeas = useCallback((e, origData) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getAccounts();
    }
  }, []);

  useEffect(() => {
    filterMeas(nameFilter, origData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter, filterMeas]);

  const deleteAccount = (e) => {
    e.preventDefault();
    let id = selected?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Account Successfully Deleted',
      // });
      setHasUpdated(true);
    });
  };

  const editAccount = (e, obj) => {
    e.preventDefault();
    setEditing(true);
    setEditId(obj.id);
  };

  const impersonateUser = (e, obj) => {
    let changeOperator = localStorage.getItem('operator');
    let changeResto = localStorage.getItem('restaurant');
    let changeRestoId = localStorage.getItem('restaurantId');
    localStorage.setItem('operator2', changeOperator);
    localStorage.setItem('restaurant2', changeResto);
    localStorage.setItem('restaurantId2', changeRestoId);
    impersonate(obj.id).then((res) => {
      if (res) {
        console.log('NEW OPERATOR', res);
        localStorage.setItem('operator', JSON.stringify(res));
        localStorage.setItem(
          'restaurant',
          res.restaurants.length !== 0 ? JSON.stringify(res.restaurants[0]) : JSON.stringify({ id: 0, name: '' })
        );
        localStorage.setItem('restaurantId', res.restaurants.length !== 0 ? res.restaurants[0].id : 0);
        localStorage.setItem('impersonate', JSON.stringify({ name: obj.name, id: obj.id }));
        history.push({
          pathname: '/admin/users',
          state: 'impersonate',
          search: '?impersonate=impersonate',
        });

        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
    });
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => impersonateUser(e, obj)}>Impersonate</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editAccount(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteAccount(e)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updateAccount = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename,
      info: {
        name: rename,
      },
    };
    console.log(params);
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated account!',
      // });
      setHasUpdated(true);
    });
  };

  const addAccount = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename !== '' ? rename : 'Untitled',
      info: { name: rename !== '' ? rename : 'Untitled' },
    };
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created account!',
      // });
      setHasUpdated(true);
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id ? (
            <div>
              <input
                type="text"
                className="loc-bar"
                placeholder="Enter a title"
                defaultValue={key}
                ref={location_name}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    updateAccount(e, obj);
                  }
                }}
              />
            </div>
          ) : (
            <div>{key}</div>
          )}
          {isAdding && obj.id == 'add' ? (
            <div>
              <input
                type="text"
                className="loc-bar"
                placeholder="Enter a title"
                defaultValue={key}
                ref={location_name}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    addAccount(e, obj);
                  }
                }}
              />
            </div>
          ) : (
            ''
          )}
        </span>
      ),
    },
    {
      title: 'View as',
      dataIndex: 'units',
      sorter: (a, b) => (a.units < b.units ? -1 : a.units > b.units ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span style={{ color: '#f05c22', cursor: 'pointer' }} onClick={(e) => impersonateUser(e, obj)}>
          Impersonate
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'controls',
      width: 20,
      render: (key, obj) => (
        <span>
          {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
            <RowOkCancel
              onClickOk={isEditing ? (e) => updateAccount(e, obj) : (e) => addAccount(e, obj)}
              onClickCancel={() => cancelEdit()}
            />
          ) : (
            <Dropdown overlay={controlItems(obj)}>
              <div className="toggle-switch">
                <img
                  src={IconsMoreOff}
                  className="UI-IconsMoreOff"
                  alt="IconsMoreOff"
                  onMouseEnter={() => setSelected(obj)}
                />
              </div>
            </Dropdown>
          )}
        </span>
      ),
    },
  ];

  return (
    <div id="superadmin-accounts" className="common-page-container">
      <div className="common-subheader">
        <SubHeader title="Accounts" onChange={(evt) => setNameFilter(evt.target.value)} />
      </div>
      <div className="Line"></div>
      <div className="common-page-content">
        <div className="common-controls-container controls-thin">
          {/* <div className="collapse-user"></div> */}

          <PlusButton
            className="flex-control-left"
            alt="Add new account"
            onClick={() => {
              history.push('/super/accounts/new');
            }}
          >
            Add new account
          </PlusButton>
        </div>
        {!isLoading && (
          <Table
            className="common-table"
            dataSource={userData}
            columns={columns}
            pagination={false}
            scroll={{ y: 'calc(100vh - var(--table-scroll-offset))' }}
          />
        )}
      </div>
    </div>
  );
};

export default Accounts;
