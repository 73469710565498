/* eslint eqeqeq: "off" */
import React, { Children, useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.css';
import CustomEventMobile from './customEventMobile';
import CustomToolbarMobile from './customToolBarMobile';
import { getEventsMonth } from './MeetingAndEventMonthUtils';

const localizer = momentLocalizer(moment);

const MeetingAndEventMonthMobile = (props) => {
  const { setCalendarType, setDay, hasCreateEvent, monthAddedEvent } = props;
  const defaultDayToday = moment(new Date()).format('dddd, MMMM D, YYYY');
  const [selectedDate, setSelectedDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD hh:mm'));
  const [monthlyEvents, setMonthlyEvents] = useState([]);
  const [eventUpdated, updatedEvent] = useState(false);

  useEffect(() => {
    getEventsMonth(selectedDate, setMonthlyEvents);
    updatedEvent(false);
  }, [selectedDate, hasCreateEvent, eventUpdated, monthAddedEvent]);

  const eventStyleGetter = () => {
    var style = {
      background: selectedDate ? 'var(--mid-fill)' : 'var(--deep-fill)',
    };
    return {
      style: style,
    };
  };

  const DateCellWrapper = (props) => {
    const { children, value } = props;
    const isSelected = new Date(value).valueOf() === new Date(selectedDate).valueOf();

    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        borderTop: isSelected ? '3px solid var(--accent-color)' : '',
        backgroundColor: isSelected ? 'var(--mid-fill)' : '',
      },
    });
  };
  const handleSelectSlot = ({ start, end }) => {
    // setSelectedDate(start, end);
    setCalendarType('day');
    setDay(moment(new Date(start)).format('dddd, MMMM D, YYYY'));
    console.log(start, end);
  };

  console.log('MONTHMOB', monthlyEvents);

  const editedEvent = useCallback((data) => {
    updatedEvent(data);
  }, []);

  return (
    <div className="main">
      <div className="dash-calendar-mobile">
        <Calendar
          selectable
          events={monthlyEvents}
          step={60}
          localizer={localizer}
          defaultDate={new Date(defaultDayToday)}
          components={{
            event: (props) => (
              <CustomEventMobile
                {...props}
                // setCalendarType={setCalendarType}
                // setDay={setDay}
                setHasEditedEvent={editedEvent}
              />
            ),
            toolbar: (props) => <CustomToolbarMobile {...props} setSelectedDate={setSelectedDate} />,
            dateCellWrapper: DateCellWrapper,
          }}
          eventPropGetter={eventStyleGetter}
          onSelectSlot={handleSelectSlot}
          popup
          messages={{
            showMore: (total) => (
              <div
                style={{
                  cursor: 'pointer',
                  background: selectedDate ? 'var(--mid-fill)' : 'var(--deep-fill)',
                  textAlign: 'center',
                  paddingTop: 10,
                }}
                onMouseOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {`...`}
              </div>
            ),
          }}
        />
      </div>
      {/* <div>
        <div>
          <div className="dash-mobile-day">
            {selectedEvents.length > 0 ? moment(selectedEvents[0].start).format('ddd, DD') : ''}
          </div>
        </div>
        {selectedEvents.map((event, i) => {
          return (
            <div className="week-calendar-mobile" key={i} onClick={() => setActive(i)}>
              <div className="dash-mobile-schedule ">
                <div  className={active == i ? 'daily-body-highlight-mobile' : 'week-daily-body'}>
                  <div className="Line-mobile">
                    <div className={'ui-elements-tables-daypicker-trow-tag-' + event.color}>
                      <div className="dash-meet-box">
                        <div className="event-title">
                          <strong>{event.title}</strong>
                        </div>
                        <div className="event-time">
                          {moment(event.start).format('LT')} - {moment(event.end).format('LT')}{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default MeetingAndEventMonthMobile;
