/* eslint eqeqeq: "off" */
import React, { useState, useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import reorder from '../../img/reorder-icon.png';
import ReactDragListView from 'react-drag-listview';
import { CidekicButton } from '../../components/common/common_ui';

const InstructionsList = (props) => {
  const { instructionlist, isEditing, updateInstructions } = props;

  const [revisedList, setRevised] = useState([]);

  useEffect(() => {
    setRevised(instructionlist);
  }, [instructionlist]);

  // let revisedList = instructionlist;

  useEffect(() => {
    console.log(revisedList);
  }, [revisedList]);

  const getContent = (e, id) => {
    console.log(revisedList);
    let revised = revisedList.map((r) => {
      if (r.id == id) {
        r.content = e.target.value;
      }
      return r;
    });
    console.log(revised);
    updateInstructions(revised);
  };

  const getTitle = (e, id) => {
    console.log(revisedList);
    let revised = revisedList.map((r) => {
      if (r.id == id) {
        r.title = e.target.value;
      }
      return r;
    });
    console.log(revised);
    updateInstructions(revised);
  };

  const handleClick = (e, listitem) => {
    e.preventDefault();
    console.log(listitem);
    let listcopy = [...revisedList];
    console.log(listcopy);
    const newArray = listcopy.filter((item) => {
      return listitem.id !== item.id;
    });
    console.log(newArray);
    setRevised(newArray);
    updateInstructions(newArray);
  };

  const addHover = (listitem) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <span onClick={(e) => handleClick(e, listitem)}>Delete</span>
      </Menu.Item>
    </Menu>
  );

  const addInst = () => {
    let lastItem = revisedList[revisedList.length - 1];
    setRevised((oldArray) => [
      ...oldArray,
      { title: '', content: '', id: lastItem ? lastItem.id + 1 : 0, itemWidth: '' },
    ]);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      console.log(fromIndex, toIndex);
      const data = [...revisedList];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setRevised(data);
      updateInstructions(data);
    },
    nodeSelector: 'li',
    handleSelector: 'a',
  };

  return (
    <div className={isEditing ? '' : 'view-recipe-section-container'}>
      {instructionlist?.length || isEditing ? (
        <div className="create-rec-controls">
          <div className={isEditing ? 'view-recipe-section-title-edit' : 'view-recipe-section-title'}>Instructions</div>
        </div>
      ) : (
        ''
      )}
      <ReactDragListView {...dragProps}>
        <ol>
          {revisedList.map((instruction, i) => {
            return (
              <li key={i + 1}>
                <div>
                  {isEditing ? (
                    <div>
                      <div className="instructions-box-edit">
                        <div style={{ display: 'flex' }}>
                          <div style={{ margin: '0px 10px 0px 0px' }}>
                            <a href="/#">
                              <img src={reorder} height="15" width="20" alt="reorder" />
                            </a>
                          </div>
                          <div className="instructions-order"> {i + 1}</div>
                          <Dropdown overlay={addHover(instruction)}>
                            <div className="toggle-switch3">
                              <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                            </div>
                          </Dropdown>
                        </div>
                        <input
                          type="text"
                          id="add"
                          name="initvalue"
                          autoComplete="off"
                          value={instruction.title}
                          onChange={(e) => getTitle(e, instruction.id)}
                          className="textarea-inputs"
                        />
                        <input
                          type="text"
                          id="add"
                          name="initvalue"
                          autoComplete="off"
                          value={instruction.content}
                          onChange={(e) => getContent(e, instruction.id)}
                          className="textarea-inputs"
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      {' '}
                      <div className="viewRecipe-Line"></div>
                      <div className="instructions-box">
                        <div className="instructions-order">{instruction.id + 1}</div>
                        <div className="instruction-title">{instruction.title}</div>
                      </div>
                      <div className="instruction-description">{instruction.content}</div>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ol>
      </ReactDragListView>
      {isEditing ? (
        <CidekicButton
          className="button-link-replacement recipe-add-item-button"
          alt="add ingredients"
          onClick={addInst}
        >
          Add instructions
        </CidekicButton>
      ) : (
        ''
      )}
    </div>
  );
};

export default InstructionsList;
