import React, { useState, useEffect, useCallback } from 'react';
import './index.css';
import { Switch, Drawer, Dropdown, Menu, Spin, Tag, Select } from 'antd'; //
import { Link } from 'react-router-dom';
import useWindowSize from '../../components/windowdimensions/windowSize';
import { search, deleteEventRecipes } from '../../services/recipe.service';
import {
  get,
  courses,
  assignCourse,
  updateMenu,
  activateChecklist,
  deactivateChecklist,
  activateEventRecipeChecklist,
  deactivateEventRecipeChecklist,
  activateNewCourseRecipes,
  searchDuplicates,
} from '../../services/menu.service';

import {
  assignRecipe,
  unassignRecipe,
  create,
  updateCourse,
  updateCourseRecipe,
  destroy,
  batchRecipes,
  duplicateEventCourse,
  deleteMenuCourses,
} from '../../services/course.service';
import { searchRecipeCategories } from '../../services/category.service';
import { all } from '../../services/operator.service';
import _, { remove, findIndex, cloneDeep } from 'lodash';
import DocumentList from '../DocumentList/DocumentList';
import { searchDocLinks } from '../../services/doc.service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RoleAccessibleComponent from '../RoleAccess/roleaccess';
import {
  CidekicButton,
  PlusButton,
  PlusIcon,
  AttachmentIndicator,
  ThumbnailImage,
  MoreIcon,
  UserImage,
  ExpandingSearchPanel,
  CidekicDropdown,
  MultiTagSelect,
} from '../../components/common/common_ui';

/* This implements the main display of a menu, showing the courses and their recipes */
/* this is contained by MenuDetails, which implements the top page (/menu/:id) */

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  display: 'flex',
  overflow: 'auto',
});

const getItemStyle2 = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const getListStyle2 = (isDraggingOver) => ({
  overflow: 'auto',
});

export const RecipeCourse = (props) => {
  const { currentPath, searchTerm } = props;
  const menuId = currentPath.substr(currentPath.lastIndexOf('/') + 1);

  const [visible, updateVisible] = useState(false);
  const [active, setActive] = useState([]);
  const [searchedTag, setSearchedTag] = useState('');
  const [activeRecipe, setActiveRecipe] = useState([]);
  const [pristineRecipe, setPristineRecipe] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [drawerTags, setDrawerTags] = useState([]);
  const [recipeCourse, setRecipeCourse] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [courseTitle, setCourseTitle] = useState('');
  const [courseid, setCourseId] = useState('');
  const [rec, setRec] = useState({});
  const [menuData, setMenu] = useState({});
  const [menuActive, setMenuActive] = useState(false);
  const [restoId, setRestoId] = useState('');
  const [pristineData, setPristineData] = useState([]);
  const [recName, setRecName] = useState('');
  const [isCanceled, setCancel] = useState(false);
  const [visible1, updateVisible1] = useState(false);
  const [visible2, updateVisible2] = useState(false);
  const [docLinks, setDocLinks] = useState({});
  const [documents, setDocuments] = useState(0);
  const [menuDocuments, setMenuDocuments] = useState(0);
  const [links, setLinks] = useState({});
  const [links1, setLinks1] = useState({});
  const [docLinks1, setDocLinks1] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('Category');
  const [recipeCat, setRecipeCat] = useState([]);
  const [menuIsDuplicated, isDuplicatedMenu] = useState(false);
  const [tagList, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [duplicatedMenus, setDuplicatedMenus] = useState([]);
  const [inputVal, setIntputVal] = useState(null);

  const { Option } = Select;

  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    console.log('CURRENT PATH', currentPath);
  }, []);

  useEffect(() => {
    getCourses2();
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    setRestoId(resto.id);
    // changed all instances of tagFilter to existing state variable selectedTags within this closure, BUG 1202030604174459
    if (selectedTags.length > 0) {
      const filteredTags = pristineRecipe.filter((a) => a.tags.some((tag) => selectedTags.includes(tag)));
      setActiveRecipe(filteredTags);
    } else {
      setActiveRecipe([]);
    }

    get(menuId).then((res) => {
      console.log('MENU', res);
      if (Object.keys(res.info).includes('originalMenuId')) {
        isDuplicatedMenu(true);
      }
      let prepped = res.info;
      setMenuActive(prepped?.active);
      setMenu(res);
    });
  }, [selectedTags, hasChanged]);

  const fetchMenusDuplicated = (menuId) => {
    searchDuplicates(menuId).then((res) => {
      if (res) {
        setDuplicatedMenus(res);
      }
    });
  };

  useEffect(() => {
    if (menuIsDuplicated == 1) {
      console.log('MENU IS DUPLICATED');
    } else {
      console.log('MENU IS ORIGINAL');
      fetchMenusDuplicated(menuId);
    }
  }, [menuIsDuplicated]);

  useEffect(() => {
    filterRecipes(pristineData, searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    console.log(courseid, recName);
  }, [courseid, recName]);

  useEffect(() => {
    let params = {
      type: 'menus',
      linkId: menuId,
    };
    searchDocLinks(params).then((res) => {
      console.log(res);

      if (res && res.length > 0) {
        let links = res;
        let removeDeleted = links.filter((l) => l.Document.folderId !== null);
        const list = Array.from(
          removeDeleted.reduce(
            (m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]),
            new Map()
          ),
          ([linkId, documentId]) => ({ linkId, documentId })
        );

        console.log(list);
        setMenuDocuments(list[0].documentId.length);
      }
    });
  }, [menuId]);

  useEffect(() => {
    console.log('RECIPECOURSE', recipeCourse, 'PRISTINE', pristineData);
  }, [recipeCourse, pristineData]);

  useEffect(() => {
    if (visible) {
      setIsLoading(true);
      let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
      let params = {
        restaurantId: resto.id,
        from: 0,
        limit: 10000,
      };
      search(params).then((res) => {
        let recipe = res.recipes.map((i) => {
          let parsed = JSON.parse(i.info);
          i.info = parsed;
          i.tags = parsed.tags ? parsed.tags : [];
          i.RecipeDetails = JSON.parse(i.RecipeDetails);
          i.imageUrl =
            parsed.recipeImageThumbs?.length > 0
              ? parsed.recipeImageThumbs[0]
              : parsed.recipeImages?.length > 0
              ? parsed.recipeImages[0]
              : null;
          return i;
        });
        let tags = recipe.map((t) => {
          return t.tags;
        });
        let mergedTags = [...new Set([].concat(...tags))]
          .map((t) => {
            if (t === '' || t === null) {
              return 'untagged';
            } else {
              return t.trim();
            }
          })
          .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() > b.toLowerCase() ? 1 : 0));
        // setActiveRecipe(recipe);  // BUG 1201998443633215 RETURNED
        setPristineRecipe(recipe);
        let uniqueTags = [...new Set(mergedTags)];
        setTags(uniqueTags);
        setDrawerTags(uniqueTags);
        setIsLoading(false);
        if (document.getElementById('recipeAddBox')) {
          document.getElementById('recipeAddBox').focus();
        }
      });
    }
  }, [visible]);

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    let params = {
      restaurantId: resto.id,
    };
    searchRecipeCategories(params).then((res) => {
      console.log('CATEGORIES', res);
      setIsAdding(false);
      let categories = res.map((r) => {
        r.categoryId = r.id;
        r.name = r.name;
        return r;
      });
      setRecipeCat(categories);
    });
  }, []);

  const windowSize = useWindowSize();

  const toggleMenu = (checked) => {
    console.log(`Menu ${menuId} toggled to ${checked}`);
    setMenuActive(checked);
    let prep = { active: checked };
    let params = {
      info: prep,
      active: true,
    };
    let params2 = {
      info: prep,
      active: false,
    };
    if (checked) {
      activateChecklist(menuId).then(() => {
        updateMenu(menuId, params).then(() => {
          // notification.open({
          //   message: 'Successfully activated menu!',
          // });

          setTimeout(() => {
            getCourses();
          }, 2000);
        });
      });
    } else {
      deactivateChecklist(menuId).then((res) => {
        updateMenu(menuId, params2).then((res) => {
          // notification.open({
          //   message: 'Successfully deactivated menu!',
          // });

          setTimeout(() => {
            getCourses();
          }, 2000);
        });
      });
    }
  };

  const toggleRecipes = (checked, obj) => {
    console.log(`recipe to ${checked}`);
    console.log(obj);
    let params = {
      active: checked,
    };
    console.log('PARAMS', params);
    updateCourseRecipe(courseid, obj.recipeId, params).then((res) => {
      if (checked) {
        // notification.open({
        //   message: 'Successfully activated recipe!',
        // });
        activateEventRecipeChecklist(obj.recipeId, menuId).then((res) => {
          console.log('REACTIVATED ALL EVENT RECIPE CHECKLISTS');
        });
      } else {
        // notification.open({
        //   message: 'Successfully deactivated recipe!',
        // });
        deactivateEventRecipeChecklist(obj.recipeId, menuId).then((res) => {
          console.log('DEACTIVATED ALL EVENT RECIPE CHECKLISTS');
        });
      }
      setHasChanged(true);
    });
  };

  // const onChange = (checked) => {
  //   console.log(`switch to ${checked}`);
  // };

  const getCourses = () => {
    setHasChanged(false);
    courses(menuId).then((res) => {
      setCourseTitle('');
      setCourseId('');
      setIsAdding(false);
      if (res) {
        getRecipes(res);
      } else {
        return;
      }
    });
  };

  const getCourses2 = () => {
    setHasChanged(false);
    setCourseTitle('');
    courses(menuId).then((res) => {
      setIsAdding(false);
      if (res) {
        getRecipes(res);
      } else {
        return;
      }
    });
  };

  const getRecipes = async (courses) => {
    const allUsers = await all(0, 1000);
    let getUser = allUsers.map((r) => {
      return {
        email: r.email,
        image: r.info ? r.info?.userImageThumb || r.info?.userImage || r.info?.userImg : '',
      };
    });
    console.log('NEW COURSES!', courses.MenuCourses);
    let menuCourses = courses.MenuCourses;
    // setRecipeCourse(menuCourses);
    let getCourseIds = menuCourses.map((c) => {
      return c.courseId;
    });
    console.log('COURSEIDS', getCourseIds);

    if (getCourseIds.length == 0) {
      setRecipeCourse([]);
    } else {
      batchRecipes(getCourseIds).then((res) => {
        if (res) {
          let getCourses = res.map((r) => {
            return r.CourseRecipes;
          });
          let merged = [].concat.apply([], getCourses);
  
          console.log(merged);
          console.log(getUser)
  
          let parsed = merged.map((e) => {
            e.prepped = e.Recipe ? e.Recipe.info : [];
            e.name = e.Recipe ? e.Recipe.name : '';
            e.recipeId = e.recipeId;
            e.updatedBy = e.Recipe ? e.Recipe.updatedBy : '';
            e.assigneeImg =
              e.Recipe && e.Recipe.updatedBy ? getUser?.find((user) => user.email == e.Recipe.updatedBy).image : '';
            return e;
          });
  
          console.log('PARSED', parsed);
  
          let courseRecipes = Object.values(
            parsed.reduce(
              (result, { courseId, name, prepped, recipeId, updatedBy, assigneeImg, active, info, Recipe }) => {
                if (!result[courseId])
                  result[courseId] = {
                    courseId,
                    recipes: [],
                  };
  
                result[courseId].recipes.push({
                  name,
                  prepped,
                  recipeId,
                  updatedBy,
                  assigneeImg,
                  active,
                  info,
                  dateAssigned:
                    info?.dateAssigned && info.dateAssigned.length > 0
                      ? new Date(info.dateAssigned).getTime()
                      : Recipe?.updatedAt?.length > 0
                      ? new Date(Recipe?.updatedAt).getTime()
                      : 0
                      ? new Date(Recipe?.createdAt).getTime()
                      : Recipe?.createdAt?.length > 0
                      ? new Date(Recipe?.createdAt).getTime()
                        ? new Date(Recipe?.updatedAt).getTime()
                        : 0
                      : 0,
                  order: (info || {}).order || 0,
                  tags: (prepped || {}).tags || [],
                });
  
                result[courseId].recipes.sort((x, y) => {
                  return y.dateAssigned - x.dateAssigned;
                });
  
                result[courseId].recipes.sort((x, y) => {
                  return x.order - y.order;
                });
  
                return result;
              },
              {}
            )
          );
  
          console.log(courseRecipes);
  
          console.log(menuCourses);
  
          let arr3 = menuCourses.map((itm) => ({
            ...courseRecipes.find((item) => item.courseId === itm.courseId && item),
            ...itm,
          }));
  
          console.log(arr3);
  
          let getCourseName = arr3.map((c, i) => {
            return {
              ...c,
              name: c.Course.name,
              courseOrder: c?.Course.order !== null ? c?.Course.order : i,
              order: c?.Course.order !== null ? c?.Course.order : i,
              recipes: c?.recipes?.length > 0 ? c?.recipes : [],
            };
          });
  
          console.log(getCourseName);
  
          getCourseName.sort((x, y) => {
            return y.courseId - x.courseId;
          });
  
          getCourseName.sort((x, y) => {
            return x.courseOrder - y.courseOrder;
          });
  
          // setRecipeCourse(getCourseName);
          // setPristineData(getCourseName);
  
          console.log(getCourseName);
  
          if (getCourseName && getCourseName.length > 0) {
            let getLinks = getCourseName
              .filter((e) => e.recipes)
              .map((e) => e.recipes.map((link) => link.recipeId))
              .reduce((a, b) => a.concat(b), [])
              .filter((l) => l !== undefined);
  
            let stringList = getLinks.join(', ');
  
            let params = {
              type: 'recipes',
              linkId: stringList,
            };
  
            searchDocLinks(params).then((res) => {
              console.log(res);
              if (res && res.length > 0) {
                let links = res;
                let removeDeleted = links.filter((l) => l.Document.folderId !== null);
                const list = Array.from(
                  removeDeleted.reduce(
                    (m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]),
                    new Map()
                  ),
                  ([linkId, documentId]) => ({ linkId, documentId })
                );
  
                console.log(list);
                console.log(list[0].documentId.length);
                setDocuments(list[0].documentId.length);
  
                let addSelectedLinks = getCourseName.map((o) => {
                  (o.recipes || []).map((c) => {
                    list.map((d) => {
                      if (d.linkId == c.recipeId) {
                        c.selected = d.documentId.length;
                      }
                    });
                  });
                  return o;
                });
  
                setRecipeCourse(addSelectedLinks);
                setPristineData(addSelectedLinks);
              } else {
                setRecipeCourse(getCourseName);
                setPristineData(getCourseName);
              }
            });
          } else {
            setRecipeCourse(getCourseName);
            setPristineData(getCourseName);
          }
        }
      });
    }
  };

  const showDrawer = (e, obj) => {
    setCourseId(obj?.id);
    setCourseTitle(obj?.name);
    updateVisible(true);
  };

  const onClose = () => {
    updateVisible(false);
    updateVisible1(false);
    setActiveRecipe([]);
    document.getElementById('recipeCat').value = '';
    setIntputVal(null);
    setSearchedTag('');
    setTags([]);
    setName('All recipes');
    // removed setTagFilter(), not needed // BUG 1202030604174459
    if (document.getElementById('recipeAddBox')) {
      document.getElementById('recipeAddBox').blur();
    }
  };

  const closeDrawer = useCallback((data) => {
    updateVisible1(false);
    updateVisible2(false);
    window.location.reload();
  }, []);

  const selectIngredient = (ingredient) => {
    if (active.some((item) => item.name === ingredient.name)) {
      const filteractive = active.filter((item) => item.name !== ingredient.name);
      setActive(filteractive);
    } else {
      setActive((ingredientlist) => [...ingredientlist, ingredient]);
    }
  };

  const addRecipes = async () => {
    console.log('ACTIVE REC', active);
    console.log('COURSE ID', courseid);
    onClose();

    // const updateRecipes = () => {
    //   console.log(active, menuId)
    //   const allRecs = active.map((e) => {
    //     e.prep = e.info;
    //     e.prep.menuId.push(menuId);
    //     let params = {
    //       info: e.prep,
    //     };
    //     update(e.id, params).then((res) => res);
    //   });
    //   console.log(allRecs);
    //   return Promise.all(allRecs);
    // };

    // updateRecipes().then((res) => {
    //   console.log(res);
    // });

    const addRecs = () => {
      const allRequests = active.map((e) => assignRecipe(courseid, e.id).then((response) => {}));
      return Promise.all(allRequests);
    };

    const activateRecIngs = () => {
      const recIds = active.map((e) => e.id);
      activateNewCourseRecipes(menuId, recIds).then((response) => {});
    };

    return Promise.all([addRecs(), activateRecIngs()]).then((res) => {
      setActive([]);
      setCourseTitle('');
      setCourseId('');
      setName('All recipes');
      // notification.open({
      //   message: 'Successfully added recipe to course!',
      // });
      setTimeout(() => {
        getCourses();
      }, 1000);
    });
  };

  // const duplicateIngredients = () => {
  //   console.log('DUPLICATE ING');
  //   console.log('ACTIVE REC', active);
  //   console.log('COURSE ID', courseid);
  //   onClose();

  //   let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  //   const allRecipes = active.map((e) => e.id.toString());

  //   activateNewCourseRecipes(courseid, menuId, resto.id, allRecipes).then((response) => {
  //     setActive([]);
  //     // notification.open({
  //     //   message: 'Successfully added recipes to course!',
  //     // });
  //   }).catch((err) => {
  // 				console.log('ERROR DUPLICATE RECIPES', err)
  // 		});
  // };

  // const duplicateRecipeToEvents = (recipeIds) => {
  // 	if (recipeIds.length > 0) {
  // 		const saveDuplicateRecipes = (recipeIds) => {
  // 			const eventMenus = _.cloneDeep(duplicatedMenus);
  // 			const targetCourses = eventMenus.filter((d) => d.courseName == courseTitle);
  // 			let params = []
  // 			targetCourses.forEach((c) => {
  // 				const {
  // 					courseId,
  // 					menuId,
  // 					eventId,
  // 					eventName
  // 				} = c;
  // 				recipeIds.forEach((r) => {
  // 					params.push({
  // 						courseId: courseId.toString(),
  // 						recipeId: r.toString(),
  // 						menuId: menuId.toString(),
  // 						eventId: eventId.toString(),
  // 						eventName: eventName
  // 					});
  // 				});
  // 			});
  // 			const addToEvents = params.map((p) => {
  // 				const {
  // 					courseId,
  // 					recipeId,
  // 					menuId,
  // 					eventId,
  // 					eventName
  // 				} = p;

  // 				duplicateEventRecipe({courseId: courseId, recipeId: recipeId, menuId: menuId, eventId: eventId, eventName: eventName, restaurantId: restoId}).then((response) => response).catch((err) => console.log(err));
  // 			});
  // 			return Promise.all(addToEvents);
  // 		}
  // 		saveDuplicateRecipes(recipeIds).then(() => {
  // 			addRecipes();
  // 		});
  // 	}
  // }

  // removed filterTags(), not needed // BUG 1202030604174459

  const addNewCourse = () => {
    setIsAdding(true);
  };

  const delRecipe = (courseId) => {
    // console.log(rec.recipeId, courseid);

    const recipeUnassigned = async () => {
      unassignRecipe(courseId, rec.recipeId)
        .then((response) => {
          return response;
          // let updateInfo = rec.prepped;
          // let removeMenuId = updateInfo.menuId.filter((r) => r !== menuId);
          // updateInfo.menuId = removeMenuId;
          // let params = {
          //   info: updateInfo,
          // };
          // console.log(params);

          // update(rec.recipeId, params).then((res) => res);

          // notification.open({
          //   message: 'Successfully unassigned recipe from course! Fetching all courses...',
          // });
        })
        .catch((err) => console.log(err));
    };

    const duplicatesDeleted = async () => {
      deleteEventRecipes(rec.recipeId, menuId)
        .then((response) => {
          return response;
        })
        .catch((err) => err);
    };

    const deleteRecipe = async () => {
      if (!menuIsDuplicated && duplicatedMenus.length > 0) {
        return Promise.all([recipeUnassigned(), duplicatesDeleted()]);
      }
      return Promise.bind(recipeUnassigned());
    };

    deleteRecipe().then(() => {
      setTimeout(() => {
        getCourses();
      }, 2000);
    });
  };

  const editCourse = (e, obj) => {
    setIsEditing(true);
    // debugger
    setidss(obj);
  };

  const renameCourse = async (e, obj) => {
    console.log(courseTitle, courseid);
    let params = {
      restaurantId: restoId,
      name: courseTitle.trim(),
    };

    if (e.key === 'Enter') {
      if (!courseTitle) {
        // notification.open({
        //   message: 'Please provide a course name',
        // });
        return;
      }

      if (recipeCourse && recipeCourse.length > 0 && Object.keys(obj).length > 0) {
        const cloneList = cloneDeep(recipeCourse);
        const index = findIndex(cloneList, { courseId: obj.courseId });
        if (cloneList[index]) {
          cloneList[index].name = courseTitle.trim();
          cloneList[index].Course.name = courseTitle.trim();
          setRecipeCourse(cloneList);
        }
      }

      const res = await updateCourse(courseid, params);
      setIsEditing(false);
      setTimeout(() => {
        //notification.open({
        //   message: 'Successfully changed course name! Fetching all courses...',
        // });
        getCourses();
      }, 2000);
    }
  };

  const renamesCourse = async (e, obj) => {
    if (isCanceled) {
      setIsEditing(false);
      setCancel(false);
      return;
    }

    let params = {
      restaurantId: restoId,
      name: courseTitle.trim(),
    };

    console.log(params);

    if (!courseTitle) {
      setIsEditing(false);
    } else {
      if (recipeCourse && recipeCourse.length > 0 && Object.keys(obj).length > 0) {
        const cloneList = cloneDeep(recipeCourse);
        const index = findIndex(cloneList, { courseId: obj.courseId });
        if (cloneList[index]) {
          cloneList[index].name = courseTitle.trim();
          cloneList[index].Course.name = courseTitle.trim();
          console.log(cloneList);
          setRecipeCourse(cloneList);
        }
      }
      const res = await updateCourse(courseid, params);
      setIsEditing(false);
      setTimeout(() => {
        // notification.open({
        //   message: 'Successfully changed course name! Fetching all courses...',
        // });
        getCourses();
      }, 2000);
    }
  };

  const cancelRename = () => {
    setCancel(true);
    setTimeout(() => {
      setIsEditing(false);
      setCancel(false);
    }, 100);
  };

  const createCourse = async (e) => {
    let course = courseTitle.trim();

    console.log(menuId, course);
    let params = {
      restaurantId: restoId,
      name: course,
      menuId: menuId,
      order: recipeCourse.length + 1,
    };

    if (e.key === 'Enter') {
      if (!course) {
        // notification.open({
        //   message: 'Please provide a course name',
        // });
        return;
      }
      if (!menuIsDuplicated && duplicatedMenus.length > 0) {
        duplicateEventCourse({
          menuId: menuId.toString(),
          courseName: course,
          order: recipeCourse.length + 1,
          restaurantId: restoId * 1,
        })
          .then(() => {
            fetchMenusDuplicated(menuId);
          })
          .catch((err) => console.log(err));
      }
      const res = await create(params);
      let courseId = res.id;

      assignCourse(menuId, courseId).then((res) => {
        console.log(res);
        // notification.open({
        //   message: 'Successfully created course! Fetching all courses...',
        // });
        setTimeout(() => {
          setCourseId('');
          getCourses();
        }, 2000);
      });
    }
  };

  const deleteCourse = (e, obj) => {
    //unassign course and unassign associated recipes
    let id = menuId;
    // let courseId = courseid;
    let courseId = obj.courseId;
    console.log('DELETE', courseId, recipeCourse);
    let getRecipe = recipeCourse.find((r) => r.courseId == courseId);
    console.log(getRecipe.recipes);

    const courseDestroyed = async () => {
      destroy(courseId)
        .then((res) => {
          return res;
        })
        .catch((err) => err);
    };

    const associatedCleanUp = async () => {
      deleteMenuCourses(courseId, menuId)
        .then((res) => {
          return res;
        })
        .catch((err) => err);
    };

    const courseDeleted = async () => {
      if (!menuIsDuplicated && duplicatedMenus.length > 0) {
        return Promise.all([courseDestroyed(), associatedCleanUp()]);
      }
      return Promise.bind(courseDestroyed());
    };

    courseDeleted().then(() => {
      setTimeout(() => {
        getCourses();
      }, 1000);
    });
  };

  const menu = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={() => addNewCourse()}>
        <span>Add new course</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span>
          <Link to={{ pathname: '/recipes/CreateRecipes', createProps: { name: 'course' } }}>Create new recipe</Link>
        </span>
      </Menu.Item>
    </Menu>
  );

  const menu1 = (obj) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a onClick={(e) => editCourse(e, obj)}>Rename {obj?.name ? obj?.name : 'courses'}</a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a onClick={(e) => deleteCourse(e, obj)}>Delete {obj?.name ? obj?.name : 'courses'}</a>
      </Menu.Item>
    </Menu>
  );

  const menuRec = (courseId) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a onClick={() => delRecipe(courseId)}>Remove recipe</a>
      </Menu.Item>
    </Menu>
  );

  const menu3 = (obj) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <Link
          to={{
            pathname: '/recipes/CreateRecipes',
            createProps: { name: 'course', courseid: courseid, menuid: menuId },
          }}
        >
          Create new recipe
        </Link>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a onClick={(e) => showDrawer(e, obj)}>Add existing recipe</a>
      </Menu.Item>
    </Menu>
  );

  const getAllRecipes = () => {
    setActiveRecipe(pristineRecipe);
    setName('All recipes');
  };

  const menuFilter = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={getAllRecipes}>
        <span>All recipes</span>
      </Menu.Item>
      {[...recipeCat]
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((m, i) => {
          return (
            <Menu.Item
              key={`menu-${m.categoryId}-${i}`}
              className="menu-show-preview"
              onClick={(e) => filteringRecipes(m)}
            >
              <span>{m.name}</span>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filteringRecipes = (m) => {
    console.log(m);
    setName(m.name);
    console.log(pristineRecipe);
    let inputValue = parseInt(m.categoryId);
    const clonedData = _.cloneDeep(pristineRecipe);
    let filtered = clonedData.filter((obj) => obj.categoryId === inputValue);
    console.log(filtered);
    setActiveRecipe(filtered);
  };

  const filterRecipes = (data, e) => {
    let inputValue = e;

    const clonedData = _.cloneDeep(data);

    let searchString = inputValue.toLowerCase();

    console.log(clonedData);

    const searchTags = clonedData
      .map((item) => ({
        ...item,
        recipes: item.recipes.filter((child) => {
          let tagslowercased = child.tags?.map((name) => name.toLowerCase());
          return (
            item.name.toLowerCase().indexOf(searchString) !== -1 ||
            tagslowercased?.find((a) => a.includes(searchString)) ||
            child.name.toLowerCase().indexOf(searchString) !== -1
          );
        }),
      }))
      .filter((item) => item.recipes.length > 0);

    console.log(searchTags);

    if (inputValue == '') {
      setRecipeCourse(clonedData);
    } else {
      setRecipeCourse(searchTags);
    }
  };

  // const filterRecipes = (data, inputValue) => {
  //   console.log(inputValue);
  //   const re = new RegExp(_.escapeRegExp(inputValue), 'i');

  //   const clonedData = _.cloneDeep(data);
  //   const results = clonedData.filter((object) => {
  //     return (
  //       object.recipes.filter((item) => {
  //         if (re.test(item.name)) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       }).length > 0 || re.test(object.name)
  //     );
  //   });
  //   console.log(results);
  //   setRecipeCourse(results);

  //   filterRecs(results, inputValue);
  // };

  // const filterRecs = (arr, id) => {
  //   let r = arr.filter((o) => {
  //     if (o.recipes) o.recipes = filterRecs(o.recipes, id);
  //     return o?.name?.toLowerCase().includes(id.toLowerCase());
  //   });
  //   return r;
  // };

  const searchRecipe = (e) => {
    setIntputVal(e.target.value);

    console.log(pristineRecipe);

    let inputValue = e.target.value;

    const clonedData = _.cloneDeep(pristineRecipe);

    let searchString = inputValue.toLowerCase();

    const searchTags = clonedData.filter((child) => {
      return child.tags?.find((a) => a.includes(searchString)) || child.name.toLowerCase().indexOf(searchString) !== -1;
    });
    // .filter((item) => item.tags.length > 0);

    console.log(searchTags);

    setActiveRecipe(searchTags);
  };

  const setidss = (recipe) => {
    // debugger
    setCourseId(recipe?.courseId);
    setRecName(recipe?.name);
  };

  const showDrawer1 = () => {
    updateVisible1(true);
    setDocLinks1({
      type: 'menus',
      linkId: menuId,
    });
    setLinks1({
      type: 'menus',
      linkId: menuId,
      info: { title: menuData.name },
    });
  };

  const showDrawer2 = (e, obj) => {
    updateVisible2(true);
    setDocLinks({
      type: 'recipes',
      linkId: obj.recipeId,
    });
    setLinks({
      type: 'recipes',
      linkId: obj.recipeId,
      info: { title: obj.name },
    });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    if (result.type === 'droppableItem') {
      const items = Array.from(recipeCourse);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      console.log(reorderedItem, result.source.index, result.destination.index);

      setRecipeCourse(items);

      //save course order

      let getCourseIds = items.map((item, index) => {
        return {
          id: item.courseId,
          order: index,
          restaurantId: item.Course.restaurantId,
          name: item.name,
        };
      });

      console.log('COURSE IDS', getCourseIds);

      const fetchData = () => {
        const allRequests = getCourseIds.map((e, index) =>
          updateCourse(e.id, { order: e.order, restaurantId: e.restaurantId, name: e.name }).then((res) => res)
        );

        return Promise.all(allRequests);
      };

      fetchData().then((res) => {
        // notification.open({
        //   message: 'Successfully updated course order!',
        // });
        setTimeout(() => {
          getCourses();
        }, 2000);
      });
    } else if (result.type === 'droppableSubItem') {
      const itemSubItemMap = recipeCourse.reduce((acc, item) => {
        acc[item.courseId] = item.recipes || [];
        return acc;
      }, {});

      console.log(itemSubItemMap);

      const sourceParentId = parseInt(result.source.droppableId);
      const destParentId = parseInt(result.destination.droppableId);

      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];

      console.log('SOURCESUBITEMS', sourceSubItems);

      let newItems = [...recipeCourse];

      if (sourceParentId === destParentId) {
        const items1 = Array.from(sourceSubItems);
        const [reorderedItem] = items1.splice(result.source.index, 1);
        items1.splice(result.destination.index, 0, reorderedItem);

        console.log(reorderedItem, result.source.index, result.destination.index);

        console.log('ITEMS', items1);

        console.log(newItems, sourceParentId);

        let reOrdered = newItems.map((item) => {
          if (item.courseId === sourceParentId) {
            item.recipes = items1;
          }
          return item;
        });

        console.log('NEWITEMS', reOrdered);

        setRecipeCourse(newItems);

        //save order

        let getRecipeIds = items1.map((item) => {
          return {
            id: item.recipeId,
            info: item.info,
          };
        });

        console.log('RECIPE IDS', getRecipeIds);
        const fetchData = () => {
          const allRequests = getRecipeIds.map((e, index) =>
            updateCourseRecipe(sourceParentId, e.id, {
              info:
                e.info && Object.keys(e.info).length > 0 ? Object.assign(e.info, { order: index }) : { order: index },
            }).then((res) => res)
          );

          return Promise.all(allRequests);
        };

        fetchData().then((res) => {
          // notification.open({
          //   message: 'Successfully updated recipe order!',
          // });
          setTimeout(() => {
            getCourses();
          }, 2000);
        });
      } else {
        let newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(result.source.index, 1);
        console.log('DRAGGED FROM COURSE', sourceParentId);
        console.log('DRAGGED TO COURSE', destParentId);
        console.log('DRAGGED ITEM', draggedItem);
        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(result.destination.index, 0, draggedItem);
        let moveItems = newItems.map((item) => {
          if (item.courseId === sourceParentId) {
            item.recipes = newSourceSubItems;
          } else if (item.courseId === destParentId) {
            item.recipes = newDestSubItems;
          }
          return item;
        });

        console.log('MOVED ITEMS', moveItems);

        //assign recipe to new course

        unassignRecipe(sourceParentId, draggedItem.recipeId)
          .then((response) => {
            assignRecipe(destParentId, draggedItem.recipeId).then((response) => {
              // notification.open({
              //   message: 'Successfully reassigned recipe to another course! Fetching all courses...',
              // });
              setTimeout(() => {
                getCourses();
              }, 2000);
            });
          })
          .catch((err) => console.log(err));

        setRecipeCourse(moveItems);
      }
    }
  };

  // const seeMoreTags = () => {
  //   setDrawerTags(tagList);
  //   showSeeMore(false);
  // };

  // const handleTagChange = (tag, checked) => {
  //   const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
  //   console.log('SELECTED TAGS: ', nextSelectedTags);
  //   setSelectedTags(nextSelectedTags);
  // };

  // const handleTagAppend = (tag) => {
  //   setSelectedTags((currentTags) => [...currentTags, tag]);
  // };

  // const handleTagRemove = (tag) => {
  //   setSelectedTags((currentTags) => currentTags.filter((t) => t !== tag));
  // };

  // const tagOptions = (tags, accumulator, inputValue) => (
  //   <Menu className="assignee-options1">
  //     {tags?.map((tag, i) => {
  //       return (
  //         <Menu.Item className="assignee-options-item1" key={`${i}-${tag}-menu-item`}>
  //           <span
  //             onClick={() => {
  //               inputValue(tag);
  //               accumulator(tag);
  //             }}
  //           >
  //             {tag}
  //           </span>
  //         </Menu.Item>
  //       );
  //     })}
  //   </Menu>
  // );

  useEffect(() => {
    if (searchedTag !== '') {
      let relatedTags = tagList.filter(
        (tag) =>
          tag.toLowerCase().indexOf(searchedTag) !== -1 || searchedTag.toString().toLowerCase().indexOf(tag) !== -1
      );
      if (selectedTags.length > 0) {
        relatedTags = relatedTags.filter((tag) => !selectedTags.includes(tag));
      } else {
        relatedTags = tagList; // FEATURE 1202040063159015 NEW
      }
      let uniqueTags = [...new Set(tagList)];
      setDrawerTags(uniqueTags.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))); // BUG 1201998443633215
      setSelectedTags(searchedTag);
    } else {
      let relatedTags = tagList; // FEATURE 1202040063159015 NEW
      // FEATURE 1202040063159015 NEW
      if (selectedTags.length > 0) {
        relatedTags = relatedTags.filter((tag) => !selectedTags.includes(tag));
      } else {
        relatedTags = tagList;
      }
      let uniqueTags = [...new Set(relatedTags)];
      setSelectedTags(uniqueTags.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)));
      setDrawerTags(uniqueTags); // FEATURE 1202040063159015 NEW
    }
  }, [searchedTag]);

  return (
    <div className="flex-grow-1 d-flex flex-column" id="recipe-course">
      <div className="page-margins">
        <div className="d-flex flex-row justify-content-between flex-grow-1 align-items-center my-2">
          <div className="recipe-course-menu-title">{menuData.name}</div>
          <AttachmentIndicator className="flex-control" count={menuDocuments} onClick={showDrawer1} />
          <div className="flex-control-vert-center flex-control">
            <Switch checked={menuActive} onChange={toggleMenu} label={menuActive ? 'Active' : 'Not Active'} />
            <span className="common-switch-label">{menuActive ? 'Active' : 'Not Active'}</span>
          </div>
          <RoleAccessibleComponent
            permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
          >
            <PlusButton
              className="flex-control-left flex-control-vert-center flex-control-rightspace  flex-control"
              alt="Add new course"
              onClick={() => addNewCourse()}
            >
              Add new course
            </PlusButton>
          </RoleAccessibleComponent>
        </div>
      </div>
      <div className="scrollable-outer flex-grow-1">
        <div className="recipe-course-box scrollable-container d-flex flex-column flex-grow-1">
          {isAdding ? (
            <div style={{ display: 'flex' }}>
              <div>
                <div className="recipe-name-box">
                  <div>
                    <input
                      autoFocus
                      type="text"
                      className="courses-bar"
                      placeholder="Enter a course name"
                      onChange={(e) => setCourseTitle(e.target.value)}
                      onKeyDown={(e) => createCourse(e)}
                    />
                    {/* <img src={add} className="add-menu" alt="add" onClick={createCourse} /> */}
                  </div>
                </div>
                <div className="recipe-tile-container" style={{ height: 400 }}></div>
              </div>
              {recipeCourse &&
                recipeCourse.length > 0 &&
                recipeCourse.map((recipe, i) => {
                  return (
                    <div>
                      {/* <div className="recipe-name-box" onMouseOver={() => setCourseId(recipe?.courseId)}> */}
                      <div className="recipe-name-box">
                        <div className="recipe-name">{recipe?.Course?.name}</div>
                        <Dropdown overlay={menu3(recipe)}>
                          <div className="add-menu" alt="add">
                            <PlusIcon />
                          </div>
                        </Dropdown>
                        <Dropdown overlay={menu1(recipe)}>
                          <div
                            className="more-menu ant-dropdown-link"
                            alt="see_more"
                            onClick={(e) => e.preventDefault()}
                          >
                            <MoreIcon />
                          </div>
                        </Dropdown>
                      </div>
                      {/* <div className="recipe-tile-container" onMouseOver={() => setCourseId(recipe?.courseId)}> */}
                      <div className="recipe-tile-container">
                        {recipe?.recipes &&
                          recipe?.recipes.map((r, i) => {
                            return (
                              <div className="recipe-tile" key={`recipe-${r?.recipeId || i}-${i}`}>
                                <Link
                                  to={{
                                    pathname: `/recipes/view1/${recipe?.courseId}/${menuId}/${r?.recipeId}`,
                                    state: { from: 'menu-details', menuId: menuId },
                                  }}
                                >
                                  <ThumbnailImage
                                    src={
                                      r?.prepped &&
                                      (r?.prepped?.recipeImages || r?.prepped?.recipeImageThumbs) &&
                                      (r?.prepped?.recipeImages?.length || r?.prepped?.recipeImageThumbs?.length) > 0
                                        ? r?.prepped?.recipeImages[0] || r?.prepped?.recipeImageThumbs[0]
                                        : null
                                    }
                                    type={'recipe'}
                                  />
                                </Link>
                                <div className="course-name-box">
                                  <Link
                                    to={{
                                      pathname: `/recipes/view1/${recipe?.courseId}/${menuId}/${r?.recipeId}`,
                                      state: { from: 'menu-details', menuId: menuId },
                                    }}
                                  >
                                    <div className="course-title">{r?.name}</div>
                                  </Link>
                                  <div onMouseOver={() => setRec(r)}>
                                    <RoleAccessibleComponent
                                      permittedRoles={[
                                        'Chef',
                                        'Admin',
                                        'Superadmin',
                                        'Client Admin',
                                        'Dev Team',
                                        'Account Holder',
                                      ]}
                                    >
                                      <Dropdown overlay={menuRec(recipe?.courseId)}>
                                        <div className="more-menu-2" alt="see_more">
                                          <MoreIcon />
                                        </div>
                                      </Dropdown>
                                    </RoleAccessibleComponent>
                                  </div>
                                </div>
                                <Link
                                  to={{
                                    pathname: `/recipes/view1/${recipe?.courseId}/${menuId}/${r?.recipeId}`,
                                    state: { from: 'menu-details', menuId: menuId },
                                  }}
                                >
                                  <div className="tag-box">
                                    {r?.prepped &&
                                      r?.prepped?.tags?.map((tag, j) => {
                                        return (
                                          <div className="tag" key={`recipe-${r?.id || j}-tag-${j}`}>
                                            {tag}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </Link>
                                <div className="Line-2"></div>
                                <div className="recipe-box-tile-info">
                                  <div className="common-user-icon flex-control-left" alt="user-photo">
                                    <UserImage src={r.assigneeImg} />
                                  </div>
                                  <AttachmentIndicator
                                    className="flex-control"
                                    showIfZero={true}
                                    count={r?.selected}
                                    onClick={(e) => showDrawer2(e, r)}
                                  />

                                  {currentPath && currentPath.includes('menu') ? (
                                    <div className="recipe-box-tile-switch">
                                      <Switch checked={r?.active} onChange={(e) => toggleRecipes(e, r)} />
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              </div>
                            );
                          })}

                        <Link
                          to={{
                            pathname: '/recipes/CreateRecipes',
                            createProps: { name: 'course', courseid: recipe?.courseId, menuid: menuId },
                          }}
                        >
                          <div className="add-recipe">Create new recipe</div>
                        </Link>

                        <div className="add-recipe" data-value={i} onClick={(e) => showDrawer(e, recipe.Course)}>
                          Add existing recipe
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppableId" direction="horizontal" type="droppableItem">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="scrollable-outer fancy-scrollbar"
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {recipeCourse &&
                      recipeCourse.length > 0 &&
                      recipeCourse.map((recipe, i) => {
                        return (
                          <Draggable
                            key={recipe?.courseId.toString()}
                            draggableId={recipe?.courseId.toString()}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="scrollable-inner d-flex flex-column mb-2"
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                {isEditing && recipe?.name === recName ? (
                                  <div>
                                    <input
                                      autoFocus
                                      type="text"
                                      className="courses-bar"
                                      placeholder="Enter new course name"
                                      defaultValue={recipe?.name}
                                      onChange={(e) => setCourseTitle(e.target.value)}
                                      onKeyDown={(e) => renameCourse(e, recipe)}
                                      onBlur={(e) => renamesCourse(e, recipe)}
                                    />
                                    {/* <img src={add} className="add-menu" alt="add" onClick={renameCourse} /> */}
                                    <div
                                      onClick={cancelRename}
                                      style={{
                                        width: 100,
                                        cursor: 'pointer',
                                        margin: '3px 0px 7px 3px',
                                        color: 'var(--accent-color)',
                                      }}
                                    >
                                      Cancel
                                    </div>
                                  </div>
                                ) : (
                                  <RoleAccessibleComponent
                                    permittedRoles={[
                                      'Chef',
                                      'Admin',
                                      'Superadmin',
                                      'Client Admin',
                                      'Dev Team',
                                      'Account Holder',
                                    ]}
                                  >
                                    <div className="recipe-name-box">
                                      <div className="recipe-name">{recipe?.Course?.name}</div>

                                      <Dropdown overlay={menu3(recipe?.Course)}>
                                        <div
                                          className="add-menu"
                                          alt="add"
                                          //onMouseOver={() => setCourseId(recipe?.courseId)}
                                        >
                                          <PlusIcon />
                                        </div>
                                      </Dropdown>

                                      <Dropdown overlay={menu1(recipe)}>
                                        {/* <div onMouseOver={() => setidss(recipe)}> */}
                                        <div>
                                          <div
                                            className="more-menu ant-dropdown-link"
                                            alt="see_more"
                                            onClick={(e) => setCourseTitle(recipe?.Course?.name)}
                                          >
                                            <MoreIcon />
                                          </div>
                                        </div>
                                      </Dropdown>
                                    </div>
                                  </RoleAccessibleComponent>
                                )}

                                <Droppable droppableId={recipe?.courseId.toString()} type={`droppableSubItem`}>
                                  {(provided, snapshot) => (
                                    <div
                                      className="recipe-tile-container flex-grow-1 fancy-scrollbar"
                                      onMouseOver={() => setCourseId(recipe?.courseId)}
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={getListStyle2(snapshot.isDraggingOver)}
                                    >
                                      {recipe.recipes &&
                                        recipe.recipes.map((r, i) => {
                                          return (
                                            <Draggable
                                              key={r?.recipeId.toString()}
                                              draggableId={r?.recipeId.toString()}
                                              index={i}
                                            >
                                              {(provided, snapshot) => (
                                                <div>
                                                  <div
                                                    className="recipe-tile"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle2(
                                                      snapshot.isDragging,
                                                      provided.draggableProps.style
                                                    )}
                                                  >
                                                    <Link
                                                      to={{
                                                        pathname: `/recipes/view1/${recipe?.courseId}/${menuId}/${r?.recipeId}`,
                                                        state: { from: 'menu-details', menuId: menuId },
                                                      }}
                                                    >
                                                      <ThumbnailImage
                                                        src={
                                                          r?.prepped &&
                                                          (r?.prepped?.recipeImages || r?.prepped?.recipeImageThumbs) &&
                                                          (r?.prepped?.recipeImages?.length ||
                                                            r?.prepped?.recipeImageThumbs?.length) > 0
                                                            ? r?.prepped?.recipeImages[0] ||
                                                              r?.prepped?.recipeImageThumbs[0]
                                                            : null
                                                        }
                                                        type={'recipe'}
                                                      />
                                                    </Link>
                                                    <div className="course-name-box">
                                                      <Link
                                                        to={{
                                                          pathname: `/recipes/view1/${recipe?.courseId}/${menuId}/${r?.recipeId}`,
                                                          state: { from: 'menu-details', menuId: menuId },
                                                        }}
                                                      >
                                                        <div className="course-title">{r?.name}</div>
                                                      </Link>
                                                      <div onMouseOver={() => setRec(r)}>
                                                        <RoleAccessibleComponent
                                                          permittedRoles={[
                                                            'Chef',
                                                            'Admin',
                                                            'Superadmin',
                                                            'Client Admin',
                                                            'Dev Team',
                                                            'Account Holder',
                                                          ]}
                                                        >
                                                          <Dropdown overlay={menuRec(recipe?.courseId)}>
                                                            <div className="more-menu-2" alt="see_more">
                                                              <MoreIcon />
                                                            </div>
                                                          </Dropdown>
                                                        </RoleAccessibleComponent>
                                                      </div>
                                                    </div>
                                                    <Link
                                                      to={{
                                                        pathname: `/recipes/view1/${recipe?.courseId}/${menuId}/${r?.recipeId}`,
                                                        state: { from: 'menu-details', menuId: menuId },
                                                      }}
                                                    >
                                                      <div className="tag-box">
                                                        {Object.keys(r?.prepped).length > 0 &&
                                                          r?.prepped?.tags?.map((tag, i) => {
                                                            return <div className="tag" key={`${i}-${tag}`}>{tag}</div>;
                                                          })}
                                                      </div>
                                                    </Link>
                                                    <div className="Line-2"></div>
                                                    <div className="recipe-box-tile-info">
                                                      <div
                                                        className="common-user-icon flex-control-left"
                                                        alt="user-photo"
                                                      >
                                                        <UserImage src={r.assigneeImg} />
                                                      </div>
                                                      <AttachmentIndicator
                                                        className="flex-control"
                                                        showIfZero={true}
                                                        count={r.selected}
                                                        onClick={(e) => showDrawer2(e, r)}
                                                      />
                                                      {currentPath && currentPath.includes('menu') ? (
                                                        <div className="recipe-box-tile-switch">
                                                          <Switch
                                                            checked={r?.active}
                                                            onChange={(e) => toggleRecipes(e, r)}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div></div>
                                                      )}
                                                    </div>
                                                  </div>
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Draggable>
                                          );
                                        })}
                                      {provided.placeholder}
                                      <Link
                                        to={{
                                          pathname: '/recipes/CreateRecipes',
                                          createProps: { name: 'course', courseid: recipe?.courseId, menuid: menuId },
                                        }}
                                      >
                                        <RoleAccessibleComponent
                                          permittedRoles={[
                                            'Chef',
                                            'Admin',
                                            'Superadmin',
                                            'Client Admin',
                                            'Dev Team',
                                            'Account Holder',
                                          ]}
                                        >
                                          <div className="add-recipe">Create new recipe</div>
                                        </RoleAccessibleComponent>
                                      </Link>
                                      <RoleAccessibleComponent
                                        permittedRoles={[
                                          'Chef',
                                          'Admin',
                                          'Superadmin',
                                          'Client Admin',
                                          'Dev Team',
                                          'Account Holder',
                                        ]}
                                      >
                                        <div
                                          className="add-recipe"
                                          data-value={i}
                                          onClick={(e) => showDrawer(e, recipe.Course)}
                                        >
                                          Add existing recipe
                                        </div>
                                      </RoleAccessibleComponent>
                                      {currentPath && currentPath.includes('menu') ? (
                                        <div></div>
                                      ) : (
                                        <div className="create-recipe">
                                          <Link
                                            to={{
                                              pathname: '/recipes/CreateRecipes',
                                              createProps: { name: 'course', courseid: recipe?.courseId, menuid: menuId },
                                            }}
                                          >
                                            Create new recipe
                                          </Link>
                                        </div>
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>
      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        closable={false}
        visible={visible}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <div id="recipeAddBox" tabIndex="0">
          <br />
          <div className="add-course-box-update">
            <CidekicButton className="button-highlight" alt="add selected recipes" onClick={addRecipes}>
              Add selected recipes
            </CidekicButton>
            {windowSize.width > 700 ? (
              <div className="close-drawer flex-control-right" onClick={onClose}>
                X <span className="close-text">Close</span>
              </div>
            ) : (
              <div className="close-drawer flex-control-right" onClick={onClose}>
                X
              </div>
            )}
          </div>
          <div style={{ textAlign: 'center' }}>{isLoading && <Spin tip="Loading all recipes..."></Spin>}</div>
          <div className="add-course-box-update">
            <ExpandingSearchPanel
              id={'recipeCat'}
              className="flex-control-left"
              showSearch={showSearch}
              searchPrompt="Search recipes"
              onChange={searchRecipe}
              onClick={() => setShowSearch(true)}
              value={inputVal}
            />
          </div>
          <div className="add-course-box-update">
            <CidekicDropdown overlay={menuFilter} className="rec-drawer-select">
              {name}
            </CidekicDropdown>
            {/* FEATURE 1202040063159015 */}
            <MultiTagSelect
              //overlay={tagOptions(drawerTags, handleTagAppend, setSearchedTag)}
              className="rec-drawer-tags"
              onChange={setSearchedTag}
              value={searchedTag}
            >
              {drawerTags.map((cat, i) => (
                <Option key={i} value={cat}>
                  {cat}
                </Option>
              ))}
            </MultiTagSelect>
          </div>
          {/* FEATURE 1202040063159015 NEW */}
          {/* {selectedTags.length > 0 && (
            <SelectedTags
              selectedTags={selectedTags.sort((a, b) =>
                a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() > b.toLowerCase() ? 1 : 0
              )}
              onRemove={handleTagRemove}
            />
          )} */}
          {/* <div className="tag-box">
            {drawerTags &&
              drawerTags.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={selectedTags.indexOf(tag) > -1}
                  onChange={(checked) => handleTagChange(tag, checked)}
                >
                  <div
                    className={selectedTags.includes(tag) ? 'selected-tag' : 'tag'}
                    data-value={tag}
                    // removed filterTags // BUG 1202030604174459
                  >
                    {tag}
                  </div>
                </CheckableTag>
              ))}
            {seeMore && (
              <span className="course-see-tags" onClick={seeMoreTags}>
                See more tags
              </span>
            )}
          </div> */}
          <br />
          <div>
            <div className="warpper">
              <div>
                <div className="recipe-tile-drawerbox">
                  {activeRecipe &&
                    activeRecipe.map((course, i) => {
                      return (
                        <div
                          className={
                            active.some((item) => item.id === course.id)
                              ? 'recipe-tile-drawerSelected'
                              : 'recipe-tile-drawer'
                          }
                          key={i}
                          onClick={() => selectIngredient(course)}
                        >
                          <ThumbnailImage src={course.imageUrl} type={'recipe'} />
                          <div className="course-name-box">
                            <div className="course-title">{course?.name}</div>
                          </div>
                          <div className="tag-box">
                            {course.tags.map((tag, i) => {
                              return <div className="tag">{tag}</div>;
                            })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={visible1}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        {/* <DocumentList2
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks1={docLinks1}
          links1={links1}
          isMobile={false}
        /> */}
        <DocumentList
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks={docLinks1}
          links={links1}
          isMobile={false}
          documentsIn="menus"
        />
      </Drawer>
      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={visible2}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentList
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks={docLinks}
          links={links}
          isMobile={false}
          documentsIn="recipes"
        />
      </Drawer>
    </div>
  );
};

export default RecipeCourse;
