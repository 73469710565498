import React, { useState, useEffect, useCallback } from 'react';
import { Table, Menu, Dropdown } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { all, searchAccounts, activateUser, deactivateUser } from '../../services/operator.service'; //destroy
import { allLocations } from '../../services/restaurant.service';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import _ from 'lodash';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';
import { ThumbnailImage, UserIcon } from '../../components/common/common_ui';

const UsersMobile = (props) => {
  const [menuName, setMenuName] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  // const [selected, setSelected] = useState(null);
  const [menulist, setList] = useState([]);

  const operatorInfo = JSON.parse(localStorage.getItem('operator') || {});

  useEffect(() => {
    getUsers();
    getLocations();
  }, []);

  const getUsers = () => {
    all(0, 1000).then((res) => {
      console.log(res);

      let allUsers = res.map((r) => {
        let arr = [],
          keys = Object.keys(r.restaurants);

        for (let i = 0, n = keys.length; i < n; i++) {
          let key = keys[i];
          arr[key] = r.restaurants[key];
        }

        let restoIds = arr.map((r) => r.restaurantId);
        return {
          firstName: r.firstName,
          lastName: r.lastName,
          role: r.role.name,
          active: r.info.activated ? 'Yes' : 'No',
          admin: r.role.name == 'Admin' || r.role.name == 'Superadmin' ? 'Yes' : 'No',
          email: r.email,
          id: r.id,
          username: r.username,
          restaurants: restoIds,
          image: r.info ? r.info?.userImageThumb || r.info?.userImage || r.info?.userImg : '',
          deletedAt: r.deletedAt,
        };
      });
      console.log(allUsers);
      let activeUsers = allUsers.filter((u) => u.active);
      setData(activeUsers);
      setOrigData(allUsers);
    });
  };

  const getLocations = () => {
    allLocations(0, 1000).then((res) => {
      console.log(res);
      let allLoc = res;
      let address = allLoc.map((l) => {
        return {
          id: l.id,
          name: l.name,
          address: l.info && l.info?.address ? l.info?.address : '',
        };
      });

      console.log(address);
      setList(address);
    });
  };

  const debouncedApiCall = useCallback(
    _.debounce((e) => {
      filterUsers(e);
    }, 300),
    []
  );

  useEffect(() => {
    debouncedApiCall(nameFilter);
  }, [nameFilter, debouncedApiCall]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All restaurants');
  };

  const filterUsers = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);

      searchAccounts(keyword, 0, 1000).then((res) => {
        console.log(res);
        let userData = res.map((r) => {
          return {
            firstName: r.firstName,
            lastName: r.lastName,
            role: '',
            active: r.info.activated ? 'Yes' : 'No',
            admin: r.role.name == 'Admin' || r.role.name == 'Superadmin' ? 'Yes' : 'No',
            email: r.email,
            id: r.id,
            username: r.username,
            image: r.info ? r.info?.userImageThumb || r.info?.userImage || r.info?.userImg : '',
          };
        });
        setData(userData);
      });
    } else {
      getUsers();
    }
  };

  // const deleteUser = (e) => {
  //   e.preventDefault();
  //   console.log(selected?.email);
  //   let email = selected?.email;
  //   destroy(email).then((res) => {
  //     console.log(res);
  //     // notification.open({
  //     //   message: 'User Successfully Deactivated',
  //     // });
  //     getUsers();
  //   });
  // };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All restaurants</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>
                {m?.name} - {m?.address}
              </a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filteringMenus = (menu) => {
    // eslint-disable-next-line no-useless-concat
    let menuName = menu.name + '-' + ' ' + menu.address;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.restaurants.some((r) => r === menu.id));
    console.log(filtered);
    setData(filtered);
  };

  const activateuser = (e, obj) => {
    e.preventDefault();
    const userToActivate = obj.id;
    const activator = operatorInfo?.email ? operatorInfo.email : '';

    activateUser(userToActivate.toString(), activator).then((res) => {
      getUsers();
      //setSelected(null);
    });
  };

  const deactivateuser = (e, obj) => {
    e.preventDefault();
    const userToDeactivate = obj.id;
    const deactivator = operatorInfo?.email ? operatorInfo.email : '';

    deactivateUser(userToDeactivate.toString(), deactivator).then((res) => {
      getUsers();
      //setSelected(null);
    });
  };

  const controlActivatedUsers = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <Link to={`/admin/users/edit/${obj.email}`}>
            <span>Edit</span>
          </Link>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => deactivateuser(e, obj)}>Deactivate</span>
        </Menu.Item>
      </Menu>
    );
  };

  const controlDeactivatedUsers = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <Link to={`/admin/users/edit/${obj.email}`}>
            <span>Edit</span>
          </Link>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => activateuser(e, obj)}>Activate</span>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      sorter: (a, b) => (a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ThumbnailImage
              className="admin-image-container-mobile"
              type={'admin'}
              src={obj.image !== '' ? obj.image : null}
              placeholderIcon={<UserIcon />}
            />
            <RoleAccessibleComponent
              permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
            >
              <div>
                <Dropdown overlay={obj.active == 'Yes' ? controlActivatedUsers(obj) : controlDeactivatedUsers(obj)}>
                  <div style={{ cursor: 'pointer' }}>
                    <img
                      src={IconsMoreOff}
                      className="UI-IconsMoreOff"
                      alt="IconsMoreOff"
                      //onClick={() => setSelected(obj)}
                    />
                  </div>
                </Dropdown>
              </div>
            </RoleAccessibleComponent>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '-6px' }}>First name</div>
            <div style={{ marginLeft: 20 }}>{obj.firstName}</div>
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Last name</div>
            <div style={{ marginLeft: 20 }}>{obj.lastName}</div>
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Role</div>
            <div style={{ marginLeft: 60 }}>{obj.role}</div>
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Active</div>
            <div style={{ marginLeft: 49 }}>{obj.active}</div>
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Admin</div>
            <div style={{ marginLeft: 49 }}>{obj.admin}</div>
          </div>
        </span>
      ),
    },
  ];

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Users"
        restaurant="Admin"
        showSearch={showSearch}
        onChange={(evt) => setNameFilter(evt.target.value)}
        clickSearch={() => setShowSearch(true)}
      />
      <div style={{ display: 'flex' }}>
        <div className="add-cat22Mob">
          <div>{menuName ? menuName : 'Restaurant name'}</div>
        </div>
        <Dropdown overlay={menuList}>
          <div className="dropdown-icon">
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </Dropdown>
        <div className="spacer-cat"></div>
        <RoleAccessibleComponent
          permittedRoles={('Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder')}
        >
          <div
            className="add-cat12Mob"
            onClick={() => {
              //setIsAdding(true);
            }}
          >
            <Link to={`/admin/users/new`}>
              <div style={{ cursor: 'pointer' }}>
                {/* use <PlusButton> for this */}+ <span style={{ marginLeft: 5 }}>Add new user</span>
              </div>
            </Link>
          </div>
        </RoleAccessibleComponent>
      </div>
      <div style={{ padding: '30px 20px 0px 15px' }}>
        <Table
          dataSource={userData}
          columns={columns}
          pagination={false}
          className={'show-custom-empty'}
          locale={{ emptyText: <Empty image={empty} description="No users" /> }}
        />
      </div>{' '}
      <RoleAccessibleComponent
        permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
      >
        <div className="add-checklistUser">
          <Link to={`/admin/users/new`}>
            <div className="add-checklist-but" onClick={() => {}}>
              <span>
                <PlusOutlined />
              </span>{' '}
              Add new user
            </div>
          </Link>
        </div>
      </RoleAccessibleComponent>
    </div>
  );
};

export default UsersMobile;
