/* eslint eqeqeq: "off" */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './tab';
import { courses } from '../../services/menu.service';
import { searchRecipeCategories } from '../../services/category.service';
import { updateCourse } from '../../services/course.service';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  recipeLabel;

  constructor(props) {
    super(props);
    console.log(props);
    console.log(props);
    this.state = {
      stateChanged: false,
      children1: [],
      activeTab: this.props.page == 'recipe' || this.props.page == 'category' ? '' : this.props.children[0].props.label,
    };
    this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };

  componentDidMount() {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    if (this.props.menuId && this.props.page == 'recipe') {
      courses(this.props.menuId).then((res) => {
        let result = res.MenuCourses;
        result.sort((x, y) => {
          return y.courseId - x.courseId;
        });
        console.log(result);
        this.recipeLabel = result.length > 0 ? result[0].Course.name : 'New Course';
        console.log(this.recipeLabel);
        this.setState({ activeTab: this.recipeLabel });
      });
    }

    if (this.props.page == 'category') {
      let params = {
        restaurantId: resto.id,
      };
      searchRecipeCategories(params).then((res) => {
        console.log('CATEGORIES', res);
        let result = res.sort((x, y) => {
          return y.id - x.id;
        });
        this.recipeLabel = result.length > 0 ? result[0].name : 'New Course';
        console.log('CAT LABEL', this.recipeLabel);
        this.setState({ activeTab: this.recipeLabel });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps !== this.props &&
      this.props.children.length > 0 &&
      this.props.children[0].props.label == 'New Course'
    ) {
      console.log(this.props.children[0].props.label);
      this.setState({ activeTab: this.props.children[0].props.label });
    }
    if (prevProps !== this.props && this.props.children.length == 1) {
      console.log(this.props.children[0].props.label);
      this.setState({ activeTab: this.props.children[0].props.label });
    }
  }

  handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(this.state.children1);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    console.log(items);
    this.setState({ children1: items });
    this.setState({ stateChanged: true });

    //save course order

    let getCourseIds = items.map((item, index) => {
      let courseArr = JSON.parse(item.key);
      return {
        id: courseArr.courseId,
        order: index,
        restaurantId: courseArr.restaurantId,
        name: courseArr.name,
      };
    });

    console.log('COURSE IDS', getCourseIds);

    const fetchData = () => {
      const allRequests = getCourseIds.map((e, index) =>
        updateCourse(e.id, { order: e.order, restaurantId: e.restaurantId, name: e.name }).then((res) => res)
      );

      return Promise.all(allRequests);
    };

    fetchData().then((res) => {
      // notification.open({
      //   message: 'Successfully updated course order!',
      // });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  }

  static getDerivedStateFromProps(props, state) {
    console.log(props.children);
    console.log(state.children1);
    if (props.children && props.children.length > 0 && !state.stateChanged) {
      state.children1 = props.children;
    }
  }

  render() {
    const {
      onClickTabItem,
      //props: { children },
      state: { activeTab, children1 },
    } = this;

    return (
      <div className="tabs">
        <div
          className={
            this.props.page == 'recipe' || this.props.page == 'category' ? 'tab-nav-wrapper-recipe' : 'tab-nav-wrapper'
          }
        >
          <DragDropContext onDragEnd={this.handleOnDragEnd}>
            <Droppable droppableId="droppableId" direction="horizontal" type="droppableItem">
              {(provided, snapshot) => (
                <ol className="tab-list" {...provided.droppableProps} ref={provided.innerRef}>
                  {children1.map((child, i) => {
                    const { label } = child.props;

                    return (
                      <Tab
                        activeTab={activeTab}
                        key={label}
                        label={label}
                        indexed={i.toString()}
                        onClick={onClickTabItem}
                      />
                    );
                  })}

                  <div className="tabLine1"></div>
                  {provided.placeholder}
                </ol>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="tab-content">
          {children1.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
