// app constants

export const KITCHENSTATIONNAME = 'Kitchen station'; // Some users of this string want the second words capitalized, some do not
export const VISIBLEROLES = [
  // these are the roles that are "user-visible", shown in create/edit user.
  'Superadmin',
  'Admin',
  'Chef',
  'Cook',
];

// other... things...

export const isMobile = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};
