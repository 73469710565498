import React, { useState, useEffect } from 'react';
import './index.css';
import approve from '../../img/approve-ck.png';
// import comment from '../../img/comment.png'; //
import attach from '../../img/attach.png';
import { Collapse, Checkbox, Row, Col, notification, Dropdown, Menu, Button } from 'antd';
import moment from 'moment';
import { getLinks } from '../../services/doc.service';
// import { faCommentSlash } from '@documentsFortawesome/free-solid-svg-icons';
import { getKitchenStations, updateStation, getStation } from '../../services/station.service';
import { searchEvents, updateEvent, getEvents } from '../../services/event.service';
import { search, update } from '../../services/recipe.service';
import { getCheckList, updateItem } from '../../services/checklist.service';
import { searchMenu } from '../../services/menu.service';
import { deleteLink, updateDoc, createDocLinks, deleteDocument } from '../../services/doc.service';
// import { CloseCircleOutlined, ConsoleSqlOutlined, ItalicOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { all } from '../../services/operator.service';
import { docIcon, docThumbnailSrc } from '../../pages/Documents/DocUtils';
import { UserImage, ThumbnailImage } from '../../components/common/common_ui';

// const { Panel } = Collapse;

const DocumentsDetailPopup = (props) => {
  const { data, closedDrawer, documentsFor } = props;
  const [editNote, setEditNote] = useState([]);
  const [comments, setComments] = useState([]);
  const [linked, setLinked] = useState([]);
  const [kitchenStations, setKitchenStations] = useState([]);
  const [eventStations, setEventStations] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [menus, setMenus] = useState([]);
  const [selectedKitchens, setSelectedKitchens] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [selectedImg, setSelectedImg] = useState('');
  const [checkedEvents, setCheckedEvents] = useState([]);
  const [checkedRecipes, setCheckedRecipes] = useState([]);
  const [checkedMenus, setCheckedMenus] = useState([]);
  const [checkedTasks, setCheckedTasks] = useState([]);
  const [checkedKitchens, setCheckedKitchens] = useState([]);
  const [createdComment, setCreateComment] = useState('');
  const [allSelected, setAllSelected] = useState([]);
  const [editTitle, setEditTitle] = useState(false);
  const [title, setTitle] = useState('');

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  const user_id = (JSON.parse(localStorage.getItem('operator')) || {}).id;
  const user_email = (JSON.parse(localStorage.getItem('operator')) || {}).email;

  console.log(data);

  useEffect(() => {
    let params = {
      restaurantId: resto.id,
      startTimestamp: '315626789',
      endTimestamp: '64060679385',
      from: 0,
      limit: 1000,
    };

    getCheckList({ restaurantId: resto.id, user_id, email: user_email }).then((res) => {
      if (res) {
        let checklists = res.checklists;
        let items = checklists.map((c) => JSON.parse(c.ChecklistItems));
        let merged = [].concat.apply([], items);
        let taskOptions = merged.map((m) => {
          return {
            label: m.title,
            value: m.id,
          };
        });
        console.log(taskOptions);
        taskOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        setTasks(taskOptions);
      }
    });

    search({ restaurantId: resto.id, from: 0, limit: 10000 }).then((res) => {
      console.log(res);
      let recipes = res.recipes;
      let recipeOptions = recipes.map((k) => {
        return {
          label: k.name,
          value: k.id,
        };
      });
      recipeOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      setRecipes(recipeOptions);
    });

    searchEvents(params).then((res) => {
      let events = res;
      let eventOptions = events.map((k) => {
        return {
          label: k.name,
          value: k.id,
        };
      });

      eventOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      console.log(eventOptions);
      setEventStations(eventOptions);
    });

    searchMenu({ restaurantId: resto.id }).then((res) => {
      if (res && res.menus) {
        let menu = res.menus;
        let menuOptions = menu?.map((k) => {
          return {
            label: k.name,
            value: k.id,
          };
        });

        menuOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        setMenus(menuOptions);
      }
    });

    getKitchenStations(resto.id).then((res) => {
      let kitchens = res.kitchens;
      let kitchenOptions = kitchens?.map((k) => {
        return {
          label: k.station,
          value: k.id,
        };
      });
      if (kitchenOptions) {
        kitchenOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      }
      setKitchenStations(kitchenOptions);
    });
  }, []);

  useEffect(() => {
    all(0, 1000).then((res) => {
      let getUser = res.map((r) => {
        return {
          username: r.firstName + ' ' + r.lastName,
          image: r.info ? r.info?.userImageThumb || r.info?.userImage || r.info?.userImg : '',
        };
      });
      let filterUser = getUser.find((user) => user.username == data?.assignee);
      setSelectedImg(filterUser?.image);
    });
  }, [data]);

  useEffect(() => {
    setComments(data.info?.comments);
    setEditNote(data.content);

    if (documentsFor !== 'kitchen' || documentsFor !== 'events') {
      setEditTitle(false);
      setTitle(data.category == 'Note' ? data.name : data.content);
    }

    let params = {
      id: data.id,
    };
    getLinks(data.id, params).then((res) => {
      setLinked(res);
      let getType = res.map((r) => {
        return {
          linkId: r.linkId,
          category: r.type,
          type: r.Document.info?.type,
        };
      });

      let allSelect = res.map((r) => {
        return {
          name: r?.info?.title?.label,
          id: r.linkId,
          type: r.type,
        };
      });
      console.log(allSelect);
      setAllSelected(allSelect);
      console.log(getType);
      let events = getType.filter((e) => e.category == 'events').map((m) => m.linkId);
      setCheckedEvents(events.toString().split(','));
      let recipes = getType.filter((e) => e.category == 'recipes').map((m) => m.linkId);
      setCheckedRecipes(recipes);
      let menus = getType.filter((e) => e.category == 'menus').map((m) => m.linkId);
      setCheckedMenus(menus.toString().split(','));
      let tasks = getType.filter((e) => e.category == 'tasks').map((m) => m.linkId);
      console.log(tasks);
      setCheckedTasks(tasks);
      let kitchen = getType.filter((e) => e.category == 'kitchen').map((m) => m.linkId);
      setCheckedKitchens(kitchen.toString().split(','));
    });
  }, [data]);

  const getKitchen = (e) => {
    let name = kitchenStations.filter((t) => e.target.value == t.value);
    const value = { linkId: e.target.value, info: { title: name[0] }, type: 'kitchen' };
    const selectedVal = { name: name[0].label, id: parseInt(e.target.value) };
    if (e.target.checked) {
      setSelectedKitchens((oldArray) => [...oldArray, value]);
      setAllSelected((oldArray) => [...oldArray, selectedVal]);
    } else {
      //splice from array
      let filter = selectedKitchens.filter((item) => item.linkId !== parseInt(e.target.value));
      let filtered = allSelected.filter((item) => item.id !== parseInt(e.target.value));
      setSelectedKitchens(filter);
      setAllSelected(filtered);
      //call delete link API
      deleteLink(data.id, 'kitchen', e.target.value).then((res) => res);
    }
  };

  const getEvent = (e) => {
    let name = eventStations.filter((t) => e.target.value == t.value);
    const value = { linkId: e.target.value, info: { title: name[0] }, type: 'events' };
    const selectedVal = { name: name[0].label, id: parseInt(e.target.value) };
    if (e.target.checked) {
      setSelectedEvents((oldArray) => [...oldArray, value]);
      setAllSelected((oldArray) => [...oldArray, selectedVal]);
    } else {
      //splice from array
      let filter = selectedEvents.filter((item) => item.linkId !== parseInt(e.target.value));
      let filtered = allSelected.filter((item) => item.id !== parseInt(e.target.value));
      setSelectedEvents(filter);
      setAllSelected(filtered);
      //call delete link API
      deleteLink(data.id, 'events', e.target.value).then((res) => res);
    }
  };

  const getRecipes = (e) => {
    let name = recipes.filter((t) => e.target.value == t.value);
    const value = { linkId: e.target.value, info: { title: name[0] }, type: 'recipes' };
    const selectedVal = { name: name[0].label, id: parseInt(e.target.value) };
    if (e.target.checked) {
      setSelectedRecipes((oldArray) => [...oldArray, value]);
      setAllSelected((oldArray) => [...oldArray, selectedVal]);
    } else {
      //splice from array
      let filter = selectedRecipes.filter((item) => item.linkId !== parseInt(e.target.value));
      let filtered = allSelected.filter((item) => item.id !== parseInt(e.target.value));
      setSelectedRecipes(filter);
      setAllSelected(filtered);
      //call delete link API
      deleteLink(data.id, 'recipes', e.target.value).then((res) => res);
    }
  };

  const getMenus = (e) => {
    let name = menus.filter((t) => e.target.value == t.value);
    const value = { linkId: e.target.value, info: { title: name[0] }, type: 'menus' };
    const selectedVal = { name: name[0].label, id: parseInt(e.target.value) };
    if (e.target.checked) {
      setSelectedMenus((oldArray) => [...oldArray, value]);
      setAllSelected((oldArray) => [...oldArray, selectedVal]);
    } else {
      //splice from array
      let filter = selectedMenus.filter((item) => item.linkId !== parseInt(e.target.value));
      let filtered = allSelected.filter((item) => item.id !== parseInt(e.target.value));
      setSelectedMenus(filter);
      setAllSelected(filtered);
      //call delete link API
      deleteLink(data.id, 'menus', e.target.value).then((res) => res);
    }
  };

  const getTasks = (e) => {
    let name = tasks.filter((t) => e.target.value == t.value);
    const value = { linkId: parseInt(e.target.value), info: { title: name[0] }, type: 'tasks' };
    const selectedVal = { name: name[0].label, id: parseInt(e.target.value), type: 'tasks' };
    if (e.target.checked) {
      setSelectedTasks((oldArray) => [...oldArray, value]);
      setAllSelected((oldArray) => [...oldArray, selectedVal]);
      // checkedTasks.push(parseInt(e.target.value));
      // setCheckedTasks(checkedTasks);
    } else {
      //splice from array
      let filter = selectedTasks.filter((item) => item.linkId !== parseInt(e.target.value));
      let filtered = allSelected.filter((item) => item.id !== parseInt(e.target.value));
      setSelectedTasks(filter);
      setAllSelected(filtered);
      // let filters = checkedTasks.filter((item) => item !== parseInt(e.target.value));
      // setCheckedTasks(filters);
      //call delete link API
      deleteLink(data.id, 'tasks', e.target.value).then((res) => res);
    }
  };

  const deleteDoc = () => {
    deleteDocument(data.id).then((res) => {
      // notification.open({
      //   message: 'Document Successfully deleted!',
      // });
      closedDrawer(true);
    });
  };

  const editDoc = () => {
    setEditTitle(true);
  };

  const saveTitle = () => {
    let allSelectedLinks = [].concat(selectedEvents, selectedKitchens, selectedRecipes, selectedTasks, selectedMenus);

    let noteInfo = {
      type: data?.category,
      // timestamp: new Date() * 1,
      updatedDate: new Date() * 1,
      createdBy: data?.assignee,
      collaborator: '',
      linked: allSelected.length,
      file: data?.info?.file ? data?.info?.file : '',
      title: title,
    };

    if (data?.info?.comments?.length > 0 && createdComment !== '') {
      noteInfo.comments = comments.concat({ text: createdComment, timestamp: new Date() * 1 });
    } else if (data?.info?.comments?.length == 0 && createdComment !== '') {
      let commentArr = [];
      commentArr.push({ text: createdComment, timestamp: new Date() * 1 });
      noteInfo.comments = commentArr;
    } else if (data?.info?.comments?.length > 0 && createdComment == '') {
      noteInfo.comments = data?.info?.comments;
    }

    let params = {
      folderId: data.folderId,
      title: data.category == 'Note' ? editNote : title,
      info: noteInfo,
    };

    console.log(params);
    console.log(allSelectedLinks);

    updateDoc(data.id, params).then((res) => {
      createDocLinks(data.id, allSelectedLinks).then((res) => {
        // notification.open({
        //   message: 'Document title Successfully updated!',
        // });
      });
    });
  };

  const saveChanges = () => {
    let allSelectedLinks = [].concat(selectedEvents, selectedKitchens, selectedRecipes, selectedTasks, selectedMenus);
    const operator = JSON.parse(localStorage.getItem('operator')) || {};
    console.log(allSelectedLinks);
    console.log(createdComment);
    console.log(allSelected);

    let noteInfo = {
      type: data?.category,
      timestamp: new Date() * 1,
      createdBy: data?.assignee,
      collaborator: '',
      linked: allSelected.length,
      file: data?.info?.file ? data?.info?.file : '',
    };

    if (data?.info?.comments?.length > 0 && createdComment !== '') {
      noteInfo.comments = comments.concat({ text: createdComment, timestamp: new Date() * 1 });
    } else if (data?.info?.comments?.length == 0 && createdComment !== '') {
      let commentArr = [];
      commentArr.push({ text: createdComment, timestamp: new Date() * 1 });
      noteInfo.comments = commentArr;
    } else if (data?.info?.comments?.length > 0 && createdComment == '') {
      noteInfo.comments = data?.info?.comments;
    }

    let params = {
      folderId: data.folderId,
      title: data.category == 'Note' ? editNote : title,
      info: noteInfo,
    };

    console.log(params);
    console.log(allSelectedLinks);

    updateDoc(data.id, params).then((res) => {
      createDocLinks(data.id, allSelectedLinks).then((res) => {
        // notification.open({
        //   message: 'Document Successfully updated!',
        // });
        // closeDrawer(true);
      });
    });
  };

  const deleteComment = (e, comment) => {
    console.log(comment);
  };

  const downloadFile = async (e, imgSrc) => {
    e.preventDefault();
    let img = imgSrc.replace('https://cidekic-images.s3.amazonaws.com', 'https://images.cidekic-dev.com');
    console.log(img);
    const image = await fetch(img);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = 'file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const setDocProps = (e, obj) => {
    console.log(obj);
    if (obj.category !== 'Note') {
      localStorage.setItem(
        'docProps',
        JSON.stringify({
          name: obj.name,
          date: obj.upload_date,
          file: obj.info?.file,
          type: obj.category,
        })
      );
    } else {
      localStorage.removeItem('docProps');
    }
  };

  const menu1 = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        {data.category !== 'Note' ? (
          <span onClick={(e) => setDocProps(e, data)}>
            <Link target="_blank" to={{ pathname: `/document/public/${data.id}` }}>
              View
            </Link>
          </span>
        ) : (
          ''
        )}
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a onClick={editDoc}>Edit</a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        {data.category !== 'Note' ? <span onClick={(e) => downloadFile(e, data?.info?.file)}>Download</span> : ''}
      </Menu.Item>
      {/* <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="">
          Duplicate
        </a>
      </Menu.Item> */}
      {/* <Menu.Item className="menu-show-preview">
        <a onClick={deleteDoc}>Delete</a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a onClick={saveChanges}>Save</a>
      </Menu.Item> */}
      {/* <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="">
          Leave
        </a>
      </Menu.Item> */}
    </Menu>
  );

  const removeLinked = (e, obj) => {
    console.log(obj);
    // let filtered = allSelected.filter((item) => item.id !== parseInt(obj.id));
    // setAllSelected(filtered);
    // if (obj.type == 'tasks') {
    //   let filter = checkedTasks.filter((item) => item !== parseInt(obj.id));
    //   console.log(filter);
    //   setCheckedTasks(filter);
    //   deleteLink(data.id, 'tasks', obj.id).then((res) => res);
    // }
  };

  return (
    <div>
      <div>
        <div className="close-drawer3" onClick={closedDrawer}>
          X
        </div>
        <div className="ck-task-box">
          <div className="ck-task2">
            <ThumbnailImage
              className="doc-details-preview-container"
              src={docThumbnailSrc(data)}
              placeholderIcon={docIcon(data)}
            />
            <div className="ck-data-box">
              <div className="ck-data-name">
                {' '}
                {editTitle ? (
                  <input
                    type="text"
                    className="doc-inputarea1"
                    defaultValue={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        return saveTitle(e);
                      }
                    }}
                    //onBlur={(e) => saveTitle(e)}
                  ></input>
                ) : (
                  <span>{data.type !== 'Note' ? data.content : data.name}</span>
                )}
              </div>
              <div className="ck-data-control">
                {data.category !== 'Note' ? (
                  <span onClick={(e) => setDocProps(e, data)}>
                    <Link target="_blank" to={{ pathname: `/document/public/${data.id}` }}>
                      <span className="ck-data-view">View</span>
                    </Link>
                  </span>
                ) : (
                  <span className="ck-data-view"></span>
                )}
                {data.category !== 'Note' ? (
                  <span onClick={(e) => downloadFile(e, data?.info?.file)}>
                    <span className="ck-data-download2">Download</span>
                  </span>
                ) : (
                  <span className="ck-data-download1"></span>
                )}
                <span>
                  {/* <Dropdown overlay={menu1}>
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                      <img src={more} className="more-menu" alt="see_more" />
                    </a>
                  </Dropdown> */}
                </span>
              </div>
            </div>
          </div>
          <div className="doc-det-box">
            <div>
              Type <span className="doc-det-spanType1">{data.category}</span> {data.attachments}{' '}
              <img src={attach} className="comment" alt="comment" />
            </div>
            <div>
              Date uploaded <span className="check-detail-spanDue">{data.upload_date}</span>{' '}
            </div>
            <div>
              Uploaded by{' '}
              <span className="check-detail-spanDue">
                <div className="common-user-icon" alt="user-photo">
                  <UserImage src={selectedImg} />
                </div>
                {data.assignee}
              </span>{' '}
            </div>
          </div>
        </div>
        <br />
        {data.category !== 'Note' ? (
          ''
        ) : (
          <textarea
            type="text"
            className="doc-textarea"
            value={editNote}
            onChange={(e) => setEditNote(e.target.value)}
          ></textarea>
        )}
        <br />
        <div className="ck-subtask-title">Linked ({allSelected.length})</div>
        <hr className="doc-line1" />
        {allSelected.map((l) => {
          return (
            <div>
              <div className="doc-linked-text">{l.name}</div>
              {/* <span onClick={(e) => removeLinked(e, l)}>X</span> */}
              <hr className="doc-line1" />
            </div>
          );
        })}
        <br />
        <br />
        <div>
          <div className="doc-Line"></div>
          <div className="doc-activity-log">
            <div className="common-user-icon" alt="user-photo">
              <UserImage src={selectedImg} />
            </div>
            <div className="doc-comment-mid">{data.assignee} uploaded this document</div>
            <div style={{ marginTop: 5 }}>{moment(data.upload_date.replace(/-/g, '/')).format('MM-DD-YYYY')}</div>
          </div>
          {comments?.map((c) => {
            return (
              <div className="doc-activity-log">
                <div className="common-user-icon" alt="user-photo">
                  <UserImage src={selectedImg} />
                </div>
                <div>
                  <div className="ck-comment-middle">{data.assignee}</div>
                  <div className="ck-text-comment">{c.text}</div>
                </div>
                <div className="ck-time-approval">
                  <div className="ck-timestamp">{moment(data.upload_date.replace(/-/g, '/')).format('MM-DD-YYYY')}</div>
                  <div>
                    {' '}
                    <img src={approve} className="approve-ck" alt="approve" height="20" />
                    {/* <span style={{ marginLeft: 50, cursor: 'pointer' }} onClick={(e) => deleteComment(e, c)}>
                      X
                    </span> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div id="ck-add-comment">
        <div className="Line"></div>
        <div className="doc-comment-box">
          <img src={user} className="user-ing" alt="user-photo" />
          <input
            type="text"
            className="ck-input-md"
            placeholder="Enter comment"
            onChange={(e) => setCreateComment(e.target.value)}
          />
        </div>
        <div className="doc-collab">
          <div className="doc-collab-text">Collaborators</div>
          <div>
            <img src={user} className="user-ing" alt="user-photo" />
            <span>Add collaborators</span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DocumentsDetailPopup;
