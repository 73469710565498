import React from 'react';
import allergen from '../../img/allergen-mobile.png';
import { formatIfNeeded } from '../../pages/Recipes/utilities';
import { ThumbnailImage } from '../../components/common/common_ui';

const IngredientsListMobile = (props) => {
  const { ingredientList, isEditing } = props;

  return (
    <div className="main">
      {isEditing || ingredientList?.length ? <div className="view-recipe-section-title-mobile">Ingredients</div> : ''}
      {ingredientList.map((ingredient, i) => {
        return (
          <div className="ing-mobile-container">
            <div className="ingredient-name-container">
              <ThumbnailImage
                className="ingredient-image-container"
                src={
                  ingredient.imageUrlThumb || ingredient.imageUrl
                    ? ingredient.imageUrlThumb || ingredient.imageUrl
                    : ingredient.info && (ingredient.info?.imageUrlThumb || ingredient.info?.imageUrl)
                    ? ingredient.info?.imageUrlThumb || ingredient.info?.imageUrl
                    : null
                }
              />
              <div className="ingredient-name"> {ingredient.name}</div>
            </div>
            <div>
              Quantity <span className="ing-value-qty">{formatIfNeeded(ingredient?.quantity)}</span>
            </div>
            <div>
              Measurement <span className="ing-value-meas">{ingredient.measurement}</span>
            </div>
            <div>
              Preparation <span className="ing-value-prep">{ingredient.preparation}</span>
            </div>
            {/*ingredient.allergen && (
              <div className="ingredient-allergen-mobile">
                <div>
                  {' '}
                  <div>Allergen</div>
                </div>
                <div className="ing-allergen-mobile">
                  <img src={allergen} alt="prep" height="25" /> {ingredient.allergen}
                </div>
              </div>
            )*/}
            <div className="ing-border"></div>
          </div>
        );
      })}
    </div>
  );
};

export default IngredientsListMobile;
