import React from 'react';
import { Bar } from 'react-chartjs-2';
import './index.css';

const chartColors = ['green', 'green', 'green', 'green', 'yellow'];

const data = {
  labels: ['2017', '2018', '2019', '2020', 'PROJECTED'],
  datasets: [
    {
      backgroundColor: chartColors,
      hoverBackgroundColor: chartColors,
      borderWidth: 1,
      data: [5, 8, 12, 17, 20],
    },
  ],
};

function BarGraph() {
  return (
    <div className="bargraph-container">
      <div className="bar-graph-title">
        <div>Cost Trend</div>
        <div className="bargraph-time">
          Yearly
          <span className="bargraph-time-icon">
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </span>
        </div>
      </div>
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          legend: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  fontColor: 'white',
                  beginAtZero: true,
                  min: 0,
                  max: 40,
                  callback: function (value) {
                    return `$ ${value}`;
                  },
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontColor: 'white',
                },
              },
            ],
          },
        }}
      />
    </div>
  );
}
export default BarGraph;
