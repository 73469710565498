import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from '../../components/windowdimensions/windowSize'; //
import IngredientsListMobile from './../../components/IngredientsList/IngredientsListMobile';
import IngredientTable from './IngredientTable';
import AddIngredientPopup from './AddIngredientPopup';
import EquipmentListMobile from './../../components/EquipmentList/EquipmentListMobile';
import InstructionsListMobile from './../../components/InstructionsList/InstructionsListMobile';
import ErrorPage from '../../components/ErrorPages/ErrorPage';
import EquipmentTable from './EquipmentTable';
import AddEquipment from './AddEquipment';
import more from '../../img/recipe-more.png';
import add2 from '../../img/calcPlus.png';
import less from '../../img/calcMinus.png';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import Tabs from '../../components/Tabs/tabs';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Modal, Dropdown, Menu, Popconfirm, message, notification, Tag, Radio, Slider, InputNumber } from 'antd';
import _, { cloneDeep, find, remove, findIndex, isEmpty, sortedLastIndexOf, clone } from 'lodash';
import ReactToPrint from 'react-to-print';
import {
  get,
  update,
  destroy,
  details,
  equipment,
  assignEquipment,
  unassignEquipment,
  assignDetail,
  unassignDetail,
  editEquipment,
  editDetail,
} from '../../services/recipe.service';
import { update as updateEquip } from '../../services/equipment.service';
import AddFile from './AddFile';
import Measurement from './measurements.js';
import fc from 'fraction-calculator';
import { searchmeas } from '../../services/measurement.service';
import { useHistory } from 'react-router-dom';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import {
  updateMenu,
  activateChecklist,
  searchByCourses,
  searchMenuRecipe,
  searchMenu,
} from '../../services/menu.service';
import { searchByRecipe } from '../../services/course.service';
import { searchByMenus } from '../../services/event.service';
import {
  getStationRecipeIngredients,
  createStationChecklist,
  createStationChecklists,
  updateChecklist,
  updateChecklists,
  deleteStationChecklist,
} from '../../services/station.service';
import { BacktoHeaderMobile } from '../../components/Header/HeadMobile.js';
import { ridAnnoyingZero, formatIfNeeded, roundOff, arrangeDBMeasurementUnits } from './utilities';
import { pluralize } from 'inflected';

import InputSelectCombo from '../../components/Recipe/InputSelectCombo';
import InputSelectCombo2 from '../../components/Recipe/InputSelectCombo2';
import InputSelectCombo3 from '../../components/Recipe/InputSelectCombo3';
import PrintRecipe from './PrintRecipe';
import {
  PrepTimeIcon,
  CookTimeIcon,
  YieldIcon,
  PortionIcon,
  ServingIcon,
  MasterRecipeIcon,
} from '../../components/common/common_ui';

/* this implements viewing a recipe from the /recipes page on mobile */

const ViewRecipesMobile = (props) => {
  //make sure existing Recipes have the correct values of measurement units
  // const existingUnits = measurements.map((d) => d.name);
  const history = useHistory();
  const windowSize = useWindowSize();
  const [recipeRestoId, setRecipeRestoId] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addTags, setAddTags] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false); // BUG 1201998443633170
  const [title, setTitle] = useState('');
  const [description, setDesc] = useState('');
  const [viewRecipe, setViewRecipe] = useState({});
  const [prepInfo, setPrepInfo] = useState({});
  const [stationRestoId, setStationRestoId] = useState(0);

  const [prepHr, setPrepHr] = useState(0);
  const [prepMn, setPrepMn] = useState(0);
  const [cookHr, setCookHr] = useState(0);
  const [cookMn, setCookMn] = useState(0);

  const [prepTime, setPrepTime] = useState(0);
  const [cookTime, setCookTime] = useState(0);
  const [prepTimeUnit, setPrepTimeUnit] = useState('hours');
  const [cookTimeUnit, setCookTimeUnit] = useState('hours');
  const [quantityVal, setQuantityVal] = useState(0);
  const [portionVal, setPortionVal] = useState(0);
  const [yieldVal, setYieldVal] = useState(0);

  const [tags, setTags] = useState([]);
  const [tagString, setTagString] = useState([]);
  const [intComment, setComment] = useState('');

  const [instructions, setInstructions] = useState([]);
  const [recipeEquipment, setRecipeEquipment] = useState([]);
  const [addEquipmentList, setAddEquipmentList] = useState([]);
  const [recipeIngredients, setRecipeIngredients] = useState([]);
  const [incrementAmt, setIncrementAmt] = useState(0);
  const [restaurant, setRestaurant] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const [showIns, setShowIns] = useState(false);
  const [enteredText, setEnteredText] = useState('');
  const [addIngredientsList, setAddIngredientsList] = useState([]);
  const [portionValue, setPortionValue] = useState('scale');
  const [scaleInputValue, setScaleInputValue] = useState(1);
  const [qtyCombine, setQtyCombine] = useState(false);
  const [measurements, setMeasurements] = useState([]);
  const [originalIngs, setOriginalIngredients] = useState([]);
  const [showReset, setShowReset] = useState(false);
  const [removeIngs, setRemoveIngs] = useState([]);
  const [removeEqs, setRemoveEqs] = useState([]);
  const [idList, setIdList] = useState({ menuid: '', courseid: '', recipeid: '', stationid: '' });
  const [prevIngredientsState, setPrevIngredientsState] = useState([]);
  const [associatedEvents, setAssociatedEvents] = useState([]);
  const [stationIngredients, setStationIngredients] = useState([]);
  const [recipeMenuId, setRecipeMenuId] = useState(0);

  //TEMPORARY STATE
  const [recipeOrder, setRecipeOrder] = useState(0);
  // TEMPORARY STATE

  const [showPrepTime, setShowPrepTime] = useState('');
  const [showCookTime, setShowCookTime] = useState('');
  const [newPrepTime, setNewPrepTime] = useState();
  const [newCookTime, setNewCookTime] = useState();

  const [portionDisplay, setPortionDisplay] = useState('');
  const [yieldDisplay, setYieldDisplay] = useState('');
  const [quantityDisplay, setQuantityDisplay] = useState('');

  const [newYieldVal, setNewYieldVal] = useState();
  const [newPortionVal, setNewPortionVal] = useState();
  const [newQuantityVal, setNewQuantityVal] = useState();

  const [yieldMeasUnit, setYieldMeasUnit] = useState('');
  const [portionMeasUnit, setPortionMeasUnit] = useState('');
  const [quantityMeasUnit, setQuantityMeasUnit] = useState('');

  const [prevYieldMeasUnit, setPrevYieldMeasUnit] = useState('');
  const [prevPortionMeasUnit, setPrevPortionMeasUnit] = useState('');
  const [prevQuantityMeasUnit, setPrevQuantityMeasUnit] = useState('');

  const [tempDisplayVal, setTempDisplayVal] = useState('');
  const [inputChanged, setInputChanged] = useState(false);
  const [updateEquipCat, setUpdateEquipCat] = useState([]);

  const componentRef = useRef();

  const operatorLoggedIn = JSON.parse(localStorage.getItem('operator')) || {};
  const operatorRestaurants = operatorLoggedIn.restaurants;
  const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id; // BUG 1202076501452783
  const accountId = JSON.parse(localStorage.getItem('operator')).accountId || '';

  useEffect(() => {
    console.log('PREV STATE RECIPE INGREDIENTS NOW >>>>>>>>>', prevIngredientsState);
  }, [prevIngredientsState]);

  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }, []);

  const checkIfPartOfEvents = () => {
    searchByRecipe(props.match.params.id).then((result) => {
      let courseIds = result;

      console.log('COURSES THIS RECIPE IS PART IN >>>>', courseIds);

      if (courseIds && courseIds.length > 0) {
        searchByCourses({ courseIds: courseIds }).then((result) => {
          let menuIds = result;

          console.log('MENUS THIS RECIPE IS PART IN >>>>', menuIds);

          if (menuIds && menuIds.length > 0) {
            searchByMenus({ menuIds: menuIds }).then((result) => {
              console.log('EVENTS THIS RECIPE IS PART IN >>>>', result);
              result && result.length > 0 && setAssociatedEvents(result);
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    let accountId = localStorage.getItem('operator') != undefined && 
      JSON.parse(localStorage.getItem('operator')).accountId != null ? 
      JSON.parse(localStorage.getItem('operator')).accountId : '';
    let params = {
      accountId: accountId,
    };

    searchmeas(params).then((res) => {
      setMeasurements(arrangeDBMeasurementUnits(res));
    });
    searchMenuRecipe(props.match.params.id).then((res) => {
      if (res !== '0') {
        setRecipeMenuId(res * 1);
      }
    });
  }, []);

  useEffect(() => {
    if (measurements.length > 0) {
      fetchStationChecklists(props.match.params.id);
      getRecipe();
      getEquipment();
      getDetails();
      checkIfPartOfEvents();

      if (
        props.location.state &&
        (props.location.state.from == 'menurecipes' || props.location.state.from == 'station')
      ) {
        setIdList({
          menuid: props.location.state.menuid,
          courseid: props.location.state.courseid,
          recipeid: props.location.state.recipeid,
          stationid: props.location.state.stationid,
        });
      }
      if (props.location.state && props.location.state.setedit == true) {
        editRecipe1();
      }
    }
  }, [measurements]);

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    setRestaurant(resto.name);
  }, []);

  useEffect(() => {
    if (portionValue !== 'scale') {
      let currentQuantityVal = newQuantityVal ? newQuantityVal : quantityVal;
      setScaleInputValue(currentQuantityVal);
    }
  }, [portionValue]);

  const getDetails = () => {
    details(props.match.params.id).then((res) => {
      if (res) {
        let recIng = res;
        console.log('INGTABLE', res);
        let prevState = recIng.RecipeDetails.map((e) => {
          let prevDet = {};
          prevDet.ingredientId = e.ingredientId;
          prevDet.stationId = e.stationId;
          prevDet.preparationId = e.preparationId;
          prevDet.measurementId = e.measurementId;
          return prevDet;
        });
        setPrevIngredientsState(prevState);
        let parse = recIng.RecipeDetails.map((e) => {
          e.info.quantity = parseFloat(e.info.quantity);
          e.info.originalQty = parseFloat(e.info.originalQty);
          e.info.cost = parseFloat(e.info.cost);
          e.prepped = e.info;
          return e;
        });
        let parsed = parse.map((p) => {
          p.prepped.id = p.ingredientId;
          p.prepped.name = p.Ingredient.name;
          p.prepped.measurementId = p.measurementId;
          p.prepped.measurement = p.Measurement === null ? '' : p?.Measurement?.name;
          p.prepped.preparationId = p.preparationId;
          p.prepped.preparation = p.Preparation === null ? '' : p?.Preparation?.name;
          p.prepped.station = p.Station === null ? '' : p?.Station.name;
          p.prepped.stationId = p.stationId;
          p.prepped.info = p.Ingredient.info;
          p.prepped.isMaster = p.Ingredient.createdBy === 'system_ingest';
          return p;
        });
        let getIngredients = parsed?.map((q) => {
          return q.prepped;
        });
        // let sortById = recIng?.info?.ingredients?.map((ing) => parseInt(ing.id));
        // console.log(getIngredients);
        // console.log(sortById);
        // const sortedIngs = sortById.map((i) => getIngredients.find((j) => j.id === i));
        // for (let i = sortedIngs.length - 1; i >= 0; i--) {
        //   if (!sortedIngs[i]) {
        //     sortedIngs.splice(i, 1);
        //   }
        // }

        let sortedIngs = getIngredients?.sort((x, y) => {
          return x.order - y.order || x.name.localeCompare(y.name);
        });
        //console.log('ORIGINAL INGREDIENTS AND QUANTITY', sortedIngs);
        setRecipeIngredients(sortedIngs);
        setAddIngredientsList(sortedIngs);
        setOriginalIngredients(sortedIngs);
      }
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const tabs = [
    { title: 'Ingredients' },
    { title: 'Equipment' },
    { title: 'Instructions' },
    //{ title: 'Nutrition Facts' },
    //{ title: 'More' },
  ];

  const incAmt = () => {
    setIncrementAmt(incrementAmt + 1);
  };

  const decAmt = () => {
    setIncrementAmt(incrementAmt - 1);
  };

  const reset = () => {
    setIncrementAmt(0);
  };

  const typeTag = (e) => {
    setEnteredText(e.target.value);
    // setTagString(e.target.value);
  };

  const addTag = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let tag = e.target.value;
      setAddTags([...addTags, tag]);
      setEnteredText('');
    }
  };

  const removeTag = (e, tag) => {
    setAddTags(addTags.filter((e) => e !== tag));
  };

  //TEMPORARY FIXING CODE
  //FIXES deeply nested recursive info JSON object key-pair data
  function findNested(o) {
    let found = o.info;
    return Object.keys(found).includes('tags') || Object.keys(found).includes('recipeImages')
      ? found
      : findNested(found);
  }
  function isItNested(o) {
    let start = o?.info;
    if (start) {
      console.log(Object.keys(start));
      return Object.keys(start).includes('tags') || Object.keys(start).includes('recipeImages') ? false : true;
    }
  }

  const getRecipe = () => {
    get(props.match.params.id).then((res) => {
      // console.log(res);
      setStationRestoId(res.restaurantId * 1);

      //TEMPORARY FIXING CODE
      //FIXES deeply nested recursive info JSON object key-pair data
      let deepestInfo = {};
      if (isItNested(res)) {
        deepestInfo = findNested(res);
        console.log('DEEPEST INFO', deepestInfo);
      }
      //TEMPORARY FIXING CODE

      setRecipeRestoId(parseInt(res?.restaurantId));
      setRecipeOrder(res?.info.order);

      // parsed vs. prepared: parsed are actual values to display and edit in Edit mode; prepared in View mode
      let parsed = res?.info;
      let prepared = cloneDeep(parsed);

      // making sure the measurement values are properly reformatted
      // 1. recipe yield
      let theYieldDisplay = parsed?.yield
        ? parsed.yieldVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed.yieldVal)
          ? reformatVal(parsed?.yield, parsed?.yieldVal, 1, true).reformattedVal
          : formatIfNeeded(parsed?.yield)
        : '0';
      // 2. recipe portion
      let thePortionDisplay = parsed?.portion
        ? parsed.portionVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed.portionVal)
          ? reformatVal(parsed?.portion, parsed?.portionVal, 1, true).reformattedVal
          : formatIfNeeded(parsed?.portion)
        : '0';
      // 3. recipe quantity
      //////
      let theQuantityDisplay = parsed?.quantity
        ? parsed.qtyVal
          ? reformatVal(parsed?.quantity, 'Each', 1, true).reformattedVal
          : formatIfNeeded(parsed?.quantity)
        : '0';

      // Preparing the yield, portion and quantity values in View Mode

      if (prepared) {
        prepared.yield = theYieldDisplay;
        prepared.portion = thePortionDisplay;
        prepared.quantity = theQuantityDisplay;
        setPrepInfo(prepared);
      }

      setViewRecipe(res);

      setTags(parsed?.tags);
      setTagString((parsed?.tags || []).toString());
      setAddTags(parsed?.tags || []);
      setInstructions(parsed?.instructions || []);
      setTitle(res.name);
      setDesc(parsed?.description);
      setComment(parsed?.comment);

      const _prepTime = parsed?.prepMins ? parseInt(parsed?.prepMins) : 0;
      const _cookTime = parsed?.cookMins ? parseInt(parsed?.cookMins) : 0;

      if (_prepTime >= 60) {
        let initPrepTime = Math.floor(_prepTime / 60);
        setPrepHr(initPrepTime);
        setShowPrepTime(initPrepTime.toString());
        setPrepTimeUnit('hours');
      } else {
        setPrepMn(_prepTime);
        setShowPrepTime(_prepTime.toString());
        setPrepTimeUnit('minutes');
      }

      if (_cookTime >= 60) {
        let initCookTime = Math.floor(_cookTime / 60);
        setCookHr(initCookTime);
        setShowCookTime(initCookTime.toString());
        setCookTimeUnit('hours');
      } else {
        setCookMn(_cookTime);
        setShowCookTime(_cookTime.toString());
        setCookTimeUnit('minutes');
      }

      setPrepTime(_prepTime);
      setCookTime(_cookTime);
      setPortionVal(parsed?.portion);
      setYieldVal(parsed?.yield);
      setQuantityVal(parsed?.quantity);
      setYieldMeasUnit(
        parsed?.yieldVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.yieldVal)
          ? parsed?.yieldVal
          : 'Select'
      );
      setQuantityMeasUnit(
        parsed?.qtyVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.qtyVal)
          ? parsed?.qtyVal
          : 'Select'
      );
      setPrevYieldMeasUnit(
        parsed?.yieldVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.yieldVal)
          ? parsed?.yieldVal
          : 'Select'
      );
      setPrevQuantityMeasUnit(
        parsed?.qtyVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.qtyVal)
          ? parsed?.qtyVal
          : 'Select'
      );
      setPortionMeasUnit(
        parsed?.portionVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.yieldVal)
          ? parsed?.portionVal
          : 'Select'
      );
      setPrevPortionMeasUnit(
        parsed?.portionVal && measurements.length > 0 && measurements.map((d) => d.name).includes(parsed?.yieldVal)
          ? parsed?.portionVal
          : 'Select'
      );

      setYieldDisplay(theYieldDisplay);
      setPortionDisplay(thePortionDisplay);
      setQuantityDisplay(theQuantityDisplay);

      // BUG 1201998443633172
      let uniqueImgThumbs = [...new Set(parsed?.recipeImageThumbs)];
      setImageUrls(
        uniqueImgThumbs && uniqueImgThumbs.length > 0
          ? uniqueImgThumbs
          : parsed?.recipeImages && parsed?.recipeImages.length > 0
          ? parsed?.recipeImages
          : []
      );
      // setImageUrls(
      //   parsed?.recipeImageThumbs && parsed?.recipeImageThumbs.length > 0
      //     ? parsed.recipeImageThumbs
      //     : parsed?.recipeImages && parsed?.recipeImages.length > 0
      //     ? parsed.recipeImages
      //     : []
      // );
      console.log(parsed?.instructions);
    });
  };

  const getEquipment = () => {
    equipment(props.match.params.id).then((res) => {
      console.log('EQTABLE', res);
      if (res) {
        let recEq = res;
        let parse = recEq.RecipeEquipments.map((e) => {
          e.prepped = e.info;
          return e;
        });
        if (parse.length > 0 && parse.info) {
          let parsed = parse.map((p) => {
            p.prepped.id = p.equipmentId;
            p.prepped.name = p.Equipment.name;
            p.prepped.info = p.Equipment.info;
            p.prepped.quantity = p.prepped.quantity;
            p.prepped.isMaster = p.Equipment.createdBy === 'system_ingest';
            p.category = p.info?.category;
            return p;
          });
          let getEquipment = parsed?.map((q) => {
            return q.prepped;
          });
          console.log(getEquipment);
          setRecipeEquipment(getEquipment);
          setAddEquipmentList(getEquipment);
        } else {
          let parsed1 = parse.map((p) => {
            p.id = p.equipmentId;
            p.name = p.Equipment.name;
            p.info = p.Equipment.info;
            p.quantity = p.prepped.quantity;
            p.isMaster = p.Equipment.createdBy === 'system_ingest';
            p.category = p.info?.category;
            return p;
          });
          setRecipeEquipment(parsed1);
          setAddEquipmentList(parsed1);
        }
      }
    });
  };

  const unassignEquip = useCallback((e) => {
    // let recipeId = props.match.params.id;
    let equipmentId = e;
    let filterEq = addEquipmentList.filter((ing) => ing.id !== equipmentId);
    setAddEquipmentList(filterEq);
    setRemoveEqs((oldArray) => [...oldArray, equipmentId]);
  });

  const unassignIng = useCallback((e) => {
    let id = props.match.params.id;
    let ingredientId = e.id;
    let stationId = e?.stationId ? e.stationId : 0;
    // let reg = /^[0-9]*[.]?[0-9]*$/;
    // let preparationId = reg.test(e.preparation) ? e.preparation : e.preparationId;
    // let measurementId = reg.test(e.measurement) ? e.measurement : e.measurementId;
    let preparationId = e?.preparationId ? e.preparationId : 0;
    let measurementId = e?.measurementId ? e.measurementId : 0;

    console.log(recipeIngredients);
    let filterIng = recipeIngredients.filter((ing) => ing.id !== e.id);
    setAddIngredientsList(filterIng);
    setOriginalIngredients(filterIng);
    setRecipeIngredients(filterIng);

    setRemoveIngs((oldArray) => [
      ...oldArray,
      {
        id: id,
        ingredientId: ingredientId,
        preparationId: preparationId,
        measurementId: measurementId,
        stationId: stationId,
      },
    ]);
  });

  const finalizeTimeToMinutes = (val, unit) => {
    console.log('Unit >>>>>>>', unit);
    console.log('Value >>>>>>>>', val);
    if (unit === 'hours') {
      return val * 60;
    }
    if (unit === 'minutes') {
      return val;
    }
  };

  const savingChecklists = (checklists) => {
    if (checklists.length > 1) {
      createStationChecklists(checklists)
        .then((result) => {
          console.log('CREATED NEW KITCHEN STATION CHECKLISTS');
        })
        .catch((err) => console.log(err));
    } else {
      createStationChecklist(checklists[0])
        .then((result) => {
          console.log('CREATED NEW KITCHEN STATION CHECKLISTS');
        })
        .catch((err) => console.log(err));
    }
  };

  const updatingChecklists = (checklists) => {
    if (checklists.length > 1) {
      updateChecklists(checklists)
        .then((result) => {
          console.log('CREATED NEW KITCHEN STATION CHECKLISTS');
        })
        .catch((err) => console.log(err));
    } else {
      updateChecklist(checklists[0])
        .then((result) => {
          console.log('CREATED NEW KITCHEN STATION CHECKLISTS');
        })
        .catch((err) => console.log(err));
    }
  };

  const fetchStationChecklists = (recipeId) => {
    getStationRecipeIngredients(recipeId).then((res) => {
      if (res && res.length > 0) {
        setStationIngredients(res);
      }
    });
  };

  const updateEquipCategory = useCallback((e) => {
    console.log('UPDATEEQUIPCAT!', e);
    setUpdateEquipCat((arr) => [...arr, e]);
  });

  const saveRecipe = () => {
    // console.log('ADDINGSLIST!!', recipeIngredients);
    if (scaleInputValue > 1) setScaleInputValue(1);
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    let recIng = recipeIngredients.map((ing) => {
      if (ing?.displayQty) {
        delete ing.displayQty;
      }
      if (ing?.key) {
        delete ing.key;
      }
      return ing;
    });
    let info = {
      description: description || prepInfo.description,
      prepMins: newPrepTime
        ? finalizeTimeToMinutes(newPrepTime, prepTimeUnit)
        : finalizeTimeToMinutes(showPrepTime, prepTimeUnit),
      cookMins: newCookTime
        ? finalizeTimeToMinutes(newCookTime, cookTimeUnit)
        : finalizeTimeToMinutes(showCookTime, cookTimeUnit),
      comment: intComment,
      portion: !isNaN(newPortionVal) ? newPortionVal : portionVal,
      yield: !isNaN(newYieldVal) ? newYieldVal : yieldVal,
      yieldVal:
        prevYieldMeasUnit !== yieldMeasUnit ? (yieldMeasUnit !== 'Select' ? yieldMeasUnit : '') : prevYieldMeasUnit,
      portionVal:
        prevPortionMeasUnit !== portionMeasUnit
          ? portionMeasUnit !== 'Select'
            ? portionMeasUnit
            : ''
          : prevPortionMeasUnit,
      quantity: !isNaN(newQuantityVal) ? newQuantityVal : quantityVal,
      qtyVal:
        prevQuantityMeasUnit !== quantityMeasUnit
          ? quantityMeasUnit !== 'Select'
            ? quantityMeasUnit
            : ''
          : quantityMeasUnit,
      ingredients: recIng,
      tags: tagString.split(','),
      instructions: instructions,
      recipeImageThumbs: [...new Set(imageUrls)],
      recipeImages: [...new Set(imageUrls)],
      order: recipeOrder,
    };
    // BUG 1201998443633172
    setImageUrls([]);

    let params = {
      restaurantId: resto_id, // BUG 1202076501452783
      name: title ? title.trim() : viewRecipe.name.trim(),
      info,
    };

    // console.log('OVERALL PARAMS FOR RECIPE >>>>>>>', params);

    if (associatedEvents && associatedEvents.length > 0) {
      console.log('ASSOCIATED EVENTS >>>>>', associatedEvents);
    }

    //delete removed ing from DB
    if (removeIngs.length > 0) {
      console.log(removeIngs);
      const fetchIngData = () => {
        const removeSelIngs = removeIngs.map((ing) => {
          let id = ing.id;
          let ingredientId = ing.ingredientId;
          let preparationId = ing.preparationId && ing.preparationId !== '' ? ing.preparationId : 0;
          let measurementId = ing.measurementId && ing.measurementId !== '' ? ing.measurementId : 0;
          let stationId = ing.stationId && ing.stationId !== '' ? ing.stationId : 0;

          unassignDetail(id, ingredientId, preparationId, measurementId, stationId)
            .then((response) => response)
            .catch((err) => console.log(err));
        });

        return Promise.all(removeSelIngs);
      };

      fetchIngData().then((res) => {
        setRemoveIngs([]);
      });
    }

    if (recipeIngredients.length > 0) {
      let newStationChecklists = [];
      let toUpdateStationChecklists = [];
      let toRemoveStationChecklists = [];

      let orderIngs = recipeIngredients?.map((ing, i) => {
        ing.order = i;
        return ing;
      });

      const originalIngredients = orderIngs?.filter((c) => !c.category);

      let newIngredients = orderIngs?.filter((c) => c.category);

      const saveEditedIngredients = () => {
        const allRequests = originalIngredients.map((ing, i) => {
          let reg = /^[0-9]*[.]?[0-9]*$/;
          let id = props.match.params.id.toString();
          let ingredientId = ing.id;
          let preparation = ing.preparation !== '' ? ing.preparation : 'Select';
          let measurement = ing.measurement !== '' ? ing.measurement : 'Select';
          let station = ing.station !== '' ? ing.station : 'Select Station';
          let preparationId = prevIngredientsState.find((p) => p.ingredientId === ingredientId).preparationId;
          let measurementId = prevIngredientsState.find((p) => p.ingredientId === ingredientId).measurementId;
          let stationId = prevIngredientsState.find((p) => p.ingredientId === ingredientId).stationId;
          let prepIng = {
            originalQty: parseFloat(ing.originalQty),
            quantity: parseFloat(ing.quantity),
            cost: parseFloat(ing.cost),
            order: parseInt(ing.order),
          };
          let params = {
            ingredientId: parseInt(ing.id),
            preparationId: reg.test(preparation) ? parseInt(preparation) : ing.preparationId ? ing.preparationId : 0,
            measurementId: reg.test(measurement) ? parseInt(measurement) : ing.measurementId ? ing.measurementId : 0,
            stationId: reg.test(station) ? parseInt(station) : ing.stationId ? ing.stationId : 0,
            info: prepIng,
          };
          let checklistParams = {};
          checklistParams.ingredientId = ingredientId;
          checklistParams.preparationId = preparationId;
          checklistParams.measurementId = measurementId;
          checklistParams.stationId = stationId;
          checklistParams.eventId = -1;
          checklistParams.recipeId = props.match.params.id * 1;
          checklistParams.menuId = idList?.menuid ? idList.menuid : recipeMenuId;
          checklistParams.params = _.cloneDeep(params);

          // if there are changes detected, push to newStationChecklists
          const previousRecord = _.find(stationIngredients, (ch) => ch.ingredientId === ing.id);
          // if there are new ingredients added with stationId
          if (
            previousRecord &&
            (previousRecord?.preparationId !== ing.preparationId ||
              previousRecord?.measurementId !== ing.measurementId ||
              previousRecord?.stationId !== ing.stationId ||
              previousRecord?.quantity !== ing.quantity ||
              previousRecord?.originalQuantity !== ing.originalQuantity ||
              previousRecord?.order !== ing.order)
          ) {
            if (stationId > 0) {
              checklistParams.params.info = {
                ...checklistParams.params.info,
                setBy: '',
                ordered: -1,
                setDay: '',
                completed: 1,
                healthCheck: 1,
                order: 0,
              };
              toUpdateStationChecklists.push(checklistParams);
            } else {
              params.info = {
                ...checklistParams.params.info,
                setBy: '',
                ordered: -1,
                setDay: '',
                completed: 1,
                healthCheck: 1,
                order: 0,
              };
              toRemoveStationChecklists.push(checklistParams);
            }
          } else {
            if (stationId > 0) {
              params.info = {
                ...params.info,
                setBy: '',
                ordered: -1,
                setDay: '',
                completed: 1,
                healthCheck: 1,
                order: 0,
              };
              params.eventId = -1;
              params.recipeId = props.match.params.id * 1;
              params.menuId = idList?.menuid ? idList.menuid : recipeMenuId;
              newStationChecklists.push(params);
            }
          }

          editDetail(id, ingredientId, preparationId, measurementId, stationId, params)
            .then((response) => {
              console.log(response);
            })
            .catch((err) => {
              console.log(err);
            });
        });

        return Promise.all(allRequests);
      };

      saveEditedIngredients().then((res) => {
        console.log(res);
      });

      if (newIngredients.length > 0) {
        const saveAddedIngredients = () => {
          const allIngReq = newIngredients.map((ing, i) => {
            let id = props.match.params.id;
            let reg = /^[0-9]*[.]?[0-9]*$/;
            let prepIng = {
              originalQty: parseFloat(ing.originalQty),
              quantity: parseFloat(ing.quantity),
              cost: parseFloat(ing.cost),
              imageUrl: ing.imageUrl || ing.imageUrlThumb,
              order: parseInt(ing.order),
            };
            let params = {
              ingredientId: parseInt(ing.id),
              preparationId: reg.test(ing.preparation)
                ? parseInt(ing.preparation)
                : ing.preparationId
                ? ing.preparationId
                : 0,
              measurementId: reg.test(ing.measurement)
                ? parseInt(ing.measurement)
                : ing.measurementId
                ? ing.measurementId
                : 0,
              stationId: reg.test(ing.station) ? parseInt(ing.station) : ing.stationId ? ing.stationId : 0,
              info: prepIng,
            };
            let checklistParams = cloneDeep(params);
            checklistParams.eventId = -1;
            checklistParams.recipeId = props.match.params.id * 1;
            checklistParams.menuId = idList?.menuid ? idList.menuid : recipeMenuId;
            checklistParams.info = {
              ...checklistParams.info,
              setBy: '',
              ordered: -1,
              setDay: '',
              completed: 1,
              healthCheck: 1,
              order: 0,
            };

            console.log('NEWLY ADDED INGREDIENTS >>>>>>', params);

            if (ing.stationId > 0) {
              newStationChecklists.push(checklistParams);
            }

            assignDetail(id, params)
              .then((response) => response)
              .catch((err) => console.log(err));

            // code for saving to stationchecklist here
            if (associatedEvents && associatedEvents.length > 0) {
              associatedEvents.forEach((e) => {
                let eventId = e.eventId;
                let menuId = e.menuId;

                let checklistParams = {
                  menuId: menuId,
                  recipeId: parseInt(props.match.params.id),
                  ingredientId: parseInt(ing.id),
                  measurementId: reg.test(ing.measurement)
                    ? parseInt(ing.measurement)
                    : ing.measurementId
                    ? ing.measurementId
                    : 0,
                  preparationId: reg.test(ing.preparation)
                    ? parseInt(ing.preparation)
                    : ing.preparationId
                    ? ing.preparationId
                    : 0,
                  stationId: reg.test(ing.station) ? parseInt(ing.station) : ing.stationId ? ing.stationId : 0,
                  eventId: eventId,
                  info: prepIng,
                };
                createStationChecklist(checklistParams)
                  .then((result) => {
                    console.log('CREATED NEW CHECKLIST ITEM SAVED IN MENUID >>>>', result.menuId);
                  })
                  .catch((err) => console.log(err));
              });
            }
          });
          return Promise.all(allIngReq);
        };

        saveAddedIngredients().then((res) => {
          console.log(res);
        });
      }

      if (
        props.location.state &&
        (props.location.state.from == 'menurecipes' || props.location.state.from == 'station')
      ) {
        //deactivate
        let params1 = {
          info: { active: true },
          active: true,
        };

        let params2 = {
          info: { active: false },
          active: false,
        };
        // deactivateChecklist(idList.menuid).then((res) => {
        updateMenu(idList.menuid, params2).then((res) => {
          activateChecklist(idList.menuid).then((res) => {
            updateMenu(idList.menuid, params1).then((res) => {});
          });
        });
        // });
      }

      if (newStationChecklists.length) {
        savingChecklists(newStationChecklists);
      }

      if (toUpdateStationChecklists.length) {
        updatingChecklists(toUpdateStationChecklists);
      }

      if (toRemoveStationChecklists.length) {
        const removeChecklists = () => {
          const removeEachChecklist = () => {
            return toRemoveStationChecklists.map((ch) => {
              deleteStationChecklist(ch.stationId, ch.recipeId, ch.ingredientId)
                .then((response) => response)
                .catch((err) => console.log(err));
            });
          };
          return Promise.all(removeEachChecklist);
        };
        removeChecklists.then(() => {
          console.log('REMOVED KITCHEN STATION CHECKLISTS');
        });
      }
    }

    if (updateEquipCat.length > 0) {
      const updateEquipCatg = () => {
        const allReq = updateEquipCat.map((e) => {
          let params = {
            accountId: accountId,
            name: e.name,
            info: {
              category: e.category,
              tags: e.info?.tags,
              imageUrl: e.info?.imageUrl,
              id: e.info?.id,
            },
            restaurantId: e.Equipment?.restaurantId,
          };

          console.log(params);

          updateEquip(e.info?.id, params)
            .then((response) => response)
            .catch((err) => console.log(err));
        });
        return Promise.all(allReq);
      };

      updateEquipCatg().then((res) => {
        console.log(res);
      });
    }

    if (removeEqs.length > 0) {
      const recipeId = props.match.params.id;
      const fetchEqData = () => {
        const removeSelEqs = removeEqs.map((eqId) => {
          unassignEquipment(recipeId, eqId)
            .then((response) => {
              message.success('Equipment unassigned from recipe!');
            })
            .catch((err) => console.log(err));
        });

        return Promise.all(removeSelEqs);
      };

      fetchEqData().then((res) => {
        setRemoveIngs([]);
      });
    }

    if (addEquipmentList.length > 0) {
      let recEqs = addEquipmentList.map((eq) => {
        if (eq?.key) {
          delete eq.key;
        }
        return eq;
      });
      // let newEquipment = recEqs.filter((c) => c.category);
      // let existingEquipment = recEqs.filter((c) => !c.category);
      let newEquipment = recEqs.filter((c) => !c?.equipmentId);
      let existingEquipment = recEqs.filter((c) => c?.equipmentId);

      if (existingEquipment.length > 0) {
        const updateExistingEquipment = () => {
          const allReq = addEquipmentList.map((e) => {
            let id = props.match.params.id;
            let equipmentId = e.id;
            let prep = { quantity: e?.quantity ? parseInt(e.quantity) : 0, size: 'size', type: 'type' };
            let params = {
              info: prep,
            };

            editEquipment(id, equipmentId, params)
              .then((response) => response)
              .catch((err) => console.log(err));
          });
          return Promise.all(allReq);
        };

        updateExistingEquipment().then((res) => {
          console.log(res);
        });
      }

      if (newEquipment.length > 0) {
        const addNewEquipments = () => {
          const allReq = newEquipment.map((e) => {
            let id = props.match.params.id;
            let equipmentId = e.id;
            let prep = { quantity: e?.quantity ? parseInt(e.quantity) : 0, size: 'size', type: 'type' };
            let params = {
              equipmentId: e.id,
              info: prep,
            };

            assignEquipment(id, params)
              .then((response) => response)
              .catch((err) => console.log(err));
          });
          return Promise.all(allReq);
        };

        addNewEquipments().then((res) => {
          console.log(res);
        });
      }
    }

    update(props.match.params.id, params).then((res) => {
      console.log(res);

      setTimeout(() => {
        setAddIngredientsList([]);
        setAddEquipmentList([]);
        if (props?.location?.state?.from && props?.location?.state?.from == 'menurecipes') {
          goBack();
        } else {
          fetchStationChecklists(props.match.params.id);
          getRecipe();
          getDetails();
          getEquipment();
          setEditing(false);
        }
      }, 2000);
    });
  };

  const deleteRecipe = () => {
    destroy(props.match.params.id).then(
      (res) => {
        message.success('Recipe deleted!');
        setTimeout(() => {
          props.history.push(`/recipes/CreateRecipes`);
        }, 5000);
      },
      (error) => {
        message.error('Unable to delete recipe');
      }
    );
  };

  function confirm(e) {
    deleteRecipe();
  }

  function cancel(e) {
    message.error('Recipe not deleted');
  }

  const updateInstructions = useCallback((instructions) => {
    setInstructions(instructions);
  });

  const menu = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <Popconfirm
          title="Are you sure to delete this recipe?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <span>Delete recipe</span>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const handleDrop = useCallback((data) => {
    console.log('DATA', data);
    if (data !== 'none') {
      setImageUrls((oldArray) => [...oldArray.concat(data)]);
      // notification.open({
      //   message: 'Successfully Uploaded Image/s!',
      // });
    } else {
      // notification.open({
      //   message: 'Image/s not uploaded, please try again',
      // });
    }
  });

  const deleteRecImg = (e, img) => {
    let filterImgList = imageUrls.filter((image) => image !== img);
    setImageUrls(filterImgList);
  };

  //
  const ingListUpdates = useCallback((ingredients) => {
    console.log('!!UPDATED INGREDIENTSS', ingredients);
    if (ingredients.length > 0) {
      setAddIngredientsList(ingredients);
      setRecipeIngredients(ingredients);
      setOriginalIngredients(ingredients);
    }
  });

  const updateIngredients = useCallback((ingredients) => {
    console.log('UPDATED INGS', ingredients);
    console.log(recipeIngredients);
    // if (addIngredientsList.length == 0) {
    if (recipeIngredients.length == 0) {
      // setAddIngredientsList(ingredients);
      setRecipeIngredients(ingredients);
      setOriginalIngredients(ingredients);
      // setRecipeIngredients(ingredients);
      //setTotalIng(ingredients.length);
    } else {
      let ids = new Set(recipeIngredients.map((d) => d.id));
      let merged = [...recipeIngredients, ...ingredients.filter((d) => !ids.has(d.id))];

      console.log(merged);
      // setAddIngredientsList(merged);
      setRecipeIngredients(merged);
      setOriginalIngredients(merged);
      //setTotalIng(merged.length);
    }
  });

  const eqListUpdates = useCallback((equipment) => {
    console.log('!!UPDATED EQ', equipment);
    if (equipment.length > 0) {
      setAddEquipmentList(equipment);
    }
  });

  const updateEquipment = useCallback((equipment) => {
    console.log('UPDATED EQUIPMENT', equipment);
    let revisedEquip = equipment.map((i) => {
      i.quantity = i.quantity ? parseFloat(i.quantity) : 0;
      i.type = i.type ? i.type : 'type';
      i.size = i.size ? i.size : 'size';
      return i;
    });
    console.log(revisedEquip);
    setAddEquipmentList((oldArray) => [...oldArray.concat(revisedEquip)]);
  });

  const setPortions = (e) => {
    console.log('radio checked', e.target.value);
    setPortionValue(e.target.value);
    if (e.target.value === 'scale') {
      setScaleInputValue(1);
    } else {
      let currentQty = newQuantityVal ? newQuantityVal : quantityVal;
      setScaleInputValue(currentQty);
    }
  };

  const changeScaleVal = (e) => {
    setScaleInputValue(e);
  };

  const increaseVal = () => {
    let increasing = scaleInputValue + 1;
    setScaleInputValue(increasing);
  };

  const decreaseVal = () => {
    let increasing = scaleInputValue - 1;
    setScaleInputValue(increasing);
  };

  const scaleIngredients = (listIngr, scale_factor) => {
    return listIngr.map((c) => {
      let ingredientQuantity = c?.originalQty ? fc(c.originalQty).toNumber() : fc(c.quantity).toNumber();
      if (ingredientQuantity > 0) {
        let ingredientMeasUnit =
          c.measurementId > 0
            ? c?.measurementName
              ? c.measurementName
              : c?.measurement
              ? c.measurement
              : measurements.find((m) => m.id === c.measurementId).name
            : 'Select';
        ingredientQuantity = ingredientQuantity > 0 ? ingredientQuantity : 1;

        const { reformattedVal: displayVal, value: actualVal, changedUnit: newUnit } = reformatVal(
          ingredientQuantity,
          ingredientMeasUnit,
          scale_factor,
          true
        );
        c.quantity = actualVal;
        c.originalQty = actualVal;
        c.displayQty = displayVal;

        let newMeasurementId = '';

        if (measurements.find((item) => item.name === newUnit)) {
          newMeasurementId = measurements.find((item) => item.name === newUnit).id;
        }

        c.measurement = newUnit;
        c.measurementId = newMeasurementId ? newMeasurementId.toString() : '0';
        c.measurementName = newUnit;
      }
      setQtyCombine(true);
      setScaleInputValue(1);
      return c;
    });
  };

  const calculateScale = (e) => {
    e.preventDefault();
    setShowReset(true);

    let currentQty = newQuantityVal ? newQuantityVal : quantityVal;
    let currentYield = newYieldVal ? newYieldVal : yieldVal;
    let scale_factor = 1;

    if (portionValue != 'scale') {
      const target_portions = scaleInputValue;
      scale_factor = currentQty ? (1.0 * target_portions) / currentQty : target_portions;
    } else {
      scale_factor = scaleInputValue;
    }

    // new values to assign
    let theYieldDisplay, theNewYield, theYieldMeasUnit;
    let theQuantityDisplay, theNewQuantity, theQuantityMeasUnit;

    const yieldRes = reformatVal(currentYield, yieldMeasUnit, scale_factor, true);
    const qtyRes = reformatVal(currentQty, 'Each', scale_factor, true);

    theYieldDisplay = yieldRes.reformattedVal;
    theNewYield = yieldRes.value;
    theYieldMeasUnit = yieldRes.changedUnit;

    theQuantityDisplay = qtyRes.reformattedVal;
    theNewQuantity = qtyRes.value;
    theQuantityMeasUnit = qtyRes.changedUnit;

    setYieldDisplay(scale_factor ? theYieldDisplay : '0');
    setNewYieldVal(scale_factor ? theNewYield : 0);
    setQuantityDisplay(scale_factor ? theQuantityDisplay : '0');
    setNewQuantityVal(scale_factor ? theNewQuantity : 0);
    setYieldMeasUnit(scale_factor ? theYieldMeasUnit : 'Select');
    setQuantityMeasUnit(scale_factor ? theQuantityMeasUnit : 'Select');

    const scaleAddIngredientsList = cloneDeep(addIngredientsList);
    const scaleRecipeIngredients = cloneDeep(recipeIngredients);

    const addIngredientsListScaled = scaleIngredients(scaleAddIngredientsList, scale_factor);

    const recipeIngredientsScaled = scaleIngredients(scaleRecipeIngredients, scale_factor);

    setAddIngredientsList(addIngredientsListScaled);
    setRecipeIngredients(recipeIngredientsScaled);
  };

  // const changeYield = (e) => {
  //   console.log(e.target.value);
  //   setYieldVal(e.target.value);
  // };

  // const changeQty = (e) => {
  //   console.log(e.target.value);
  //   setQuantityVal(e.target.value);
  // };

  const updateQtyCombine = useCallback((data) => {
    setQtyCombine(data);
  });

  const editRecipe1 = () => {
    setEditing(true);
    setQtyCombine(true);
  };

  const resetMultiplier = () => {
    setScaleInputValue(1);

    setNewYieldVal(yieldVal);
    setYieldDisplay(reformatVal(yieldVal, prevYieldMeasUnit).reformattedVal);
    setYieldMeasUnit(prevYieldMeasUnit);

    setNewQuantityVal(quantityVal);
    setQuantityDisplay(reformatVal(quantityVal, prevQuantityMeasUnit).reformattedVal);
    setQuantityMeasUnit(prevQuantityMeasUnit);

    setAddIngredientsList(originalIngs);
    setRecipeIngredients(originalIngs);
    setPortionValue('scale');
    setShowReset(false);
  };

  function confirmDelete(e, image) {
    console.log(image);
    deleteRecImg(e, image);
  }

  const deletesImg = (obj) => {
    return (
      <Menu className="menu-show-preview">
        <Menu.Item className="menu-show-preview">
          <span style={{ color: 'var(--base-text)' }} onClick={(e) => confirmDelete(e, obj)}>
            Delete
          </span>
        </Menu.Item>
      </Menu>
    );
  };

  const goBack = () => {
    if (props.location.state && props.location.state.from == 'menurecipes') {
      history.push(`/recipes`);
    } else if (props.location.state && props.location.state.from == 'station') {
      history.push(`/station/${props.location.state.stationId}`);
    } else if (props.location.state && props.location.state.from == 'event') {
      history.push(`/event/station/${props.location.state.stationId}`);
    } else {
      history.push(`/recipes`);
    }
  };
  // reformat values from numeric to their desired formats based on metric
  const reformatVal = (val, measUnit, inputScale = undefined, toScale = false) => {
    if (measUnit && typeof measUnit === 'string' && measUnit !== 'Select' && toScale) {
      const { id: measure_id, equiv: equiv_amt } = measurements.find((item) => {
        return item.name === measUnit || measUnit.includes(item.name);
      });
      let reformattedVal,
        value = val,
        changedUnit = measUnit ? measUnit : 'Select';
      if (equiv_amt) {
        const showMeasure = new Measurement(val, measure_id, props.match.params.id, measurements);
        const scaledMeasure = showMeasure.scale(inputScale && toScale ? inputScale : 1).reduce();
        reformattedVal = scaledMeasure.quantity_ui_string();
        changedUnit = measurements.find((item) => scaledMeasure.measure_id === item.id).name;
        value = scaledMeasure.quantity;
      } else {
        if (inputScale != 0 && toScale) {
          value *= inputScale;
          reformattedVal = value;
        } else {
          reformattedVal = val;
        }
      }
      return {
        reformattedVal: formatIfNeeded(reformattedVal),
        value: roundOff(value),
        changedUnit,
      };
    } else {
      return {
        reformattedVal: val ? formatIfNeeded(val) : '0',
        value: val ? roundOff(val) : 0,
        changedUnit: measUnit ? measUnit : 'Select',
      };
    }
  };

  // FOCUS/BLUR FUNCTIONS for Recipe Metrics (prep time, cook time, yield, portion, quantity)
  const recalculateInputMeasurements = (val, measUnit, setNewVal, setValDisplay, setPrevMeasUnit, setMeasUnit) => {
    if (val) {
      const exactVal = Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100;
      // const {
      //   reformattedVal: theFormattedVal,
      //   value: theActualVal,
      //   changedUnit: theUnit,
      // } = reformatVal(exactVal, measUnit, 1, unitChanged);
      setNewVal(roundOff(exactVal));
      setValDisplay(formatIfNeeded(exactVal));
      setMeasUnit(measUnit);
      setPrevMeasUnit(measUnit);
    } else {
      setNewVal();
      setValDisplay('0');
    }
  };

  const blurInputMeasurements = (val, setNewVal, setValDisplay, type) => {
    if (val) {
      const exactVal = Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100;
      if (inputChanged) {
        // formats the new value
        if (type === 'yield') {
          let measurementVal = newYieldVal ? newYieldVal : yieldVal;
          recalculateInputMeasurements(
            measurementVal,
            yieldMeasUnit,
            setNewYieldVal,
            setYieldDisplay,
            setPrevYieldMeasUnit,
            setYieldMeasUnit
            // true
          );
        } else if (type === 'portion') {
          let measurementVal = newPortionVal ? newPortionVal : portionVal;
          recalculateInputMeasurements(
            measurementVal,
            portionMeasUnit,
            setNewPortionVal,
            setPortionDisplay,
            setPrevPortionMeasUnit,
            setPortionMeasUnit
            // true
          );
        } else if (type === 'quantity') {
          setNewVal(exactVal);
          setValDisplay(formatIfNeeded(exactVal));
        }

        setInputChanged(false);
      } else {
        // restores the original reformatted value
        setValDisplay(tempDisplayVal);
        setTempDisplayVal('');
      }
    } else {
      setNewVal();
      setValDisplay('0');
    }
  };

  const blurInputTime = (e, metric) => {
    let timeUnit;
    switch (metric) {
      case 'preptime':
        timeUnit = prepTimeUnit === 'hours' ? 60 : 1;
        setNewPrepTime(e.target.value ? parseInt(e.target.value) * timeUnit : 0);
        break;
      case 'cooktime':
        timeUnit = cookTimeUnit === 'hours' ? 60 : 1;
        setNewCookTime(e.target.value ? parseInt(e.target.value) * timeUnit : 0);
        break;
      default:
    }
  };

  const focusInputMeasurements = (metric) => {
    if (inputChanged) setInputChanged(false);
    switch (metric) {
      case 'yield':
        setTempDisplayVal(yieldDisplay);
        setYieldDisplay(ridAnnoyingZero(newYieldVal !== undefined ? newYieldVal : yieldVal));
        break;
      case 'portion':
        setTempDisplayVal(portionDisplay);
        setPortionDisplay(ridAnnoyingZero(newPortionVal !== undefined ? newPortionVal : portionVal));
        break;
      case 'quantity':
        setTempDisplayVal(quantityDisplay);
        setQuantityDisplay(ridAnnoyingZero(newQuantityVal !== undefined ? newQuantityVal : quantityVal));
        break;
      default:
    }
  };

  // INPUT FUNCTIONS for Recipe Metrics (prep time, cook time, yield, portion quantity)
  const checkMeasurementInput = (e, metric) => {
    if (!inputChanged) setInputChanged(true);

    let isInvalid = false;
    if (!/^\d*\.?\d*$/.test(e.target.value)) {
      // notification.open({
      //   message: 'Please input a numeric value!',
      // });
      isInvalid = true;
    }

    const val = e.target.value;

    switch (metric) {
      case 'yield':
        setYieldDisplay(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : yieldVal));
        setNewYieldVal(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : yieldVal));
        break;
      case 'portion':
        setPortionDisplay(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : portionVal));
        setNewPortionVal(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : portionVal));
        break;
      case 'quantity':
        setQuantityDisplay(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : quantityVal));
        setNewQuantityVal(ridAnnoyingZero(!isInvalid ? (val ? val : 0) : quantityVal));
        break;
      default:
    }
  };
  const checkTimeInput = (e, activity) => {
    let isInvalid = false;
    if (e.target.value !== '' && !/^-?\d+$/.test(e.target.value)) {
      // notification.open({
      //   message: 'Please input a numeric value!',
      // });
      isInvalid = true;
    }

    const val = e.target.value === '' ? 0 : e.target.value;

    switch (activity) {
      case 'preparation':
        setShowPrepTime(ridAnnoyingZero(!isInvalid ? val : prepTime));
        setNewPrepTime(isInvalid ? undefined : parseInt(val));
        break;
      case 'cooking':
        setShowCookTime(ridAnnoyingZero(!isInvalid ? val : cookTime));
        setNewCookTime(isInvalid ? undefined : parseInt(val));
        break;
      default:
    }
  };

  // const checkIfUnitHasEquivAmt = (valUnit) => {
  //   const findUnitInfo = measurements.find((d) => d.name === valUnit);
  //   if (findUnitInfo && 'equiv' in findUnitInfo) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const checkMeasurementUnitChange = (valUnit, metric) => {
    let measurementVal;
    // toScale = false;
    // if (checkIfUnitHasEquivAmt(valUnit) && valUnit !== 'Select') {
    //   toScale = true;
    // }
    switch (metric) {
      case 'yield':
        measurementVal = newYieldVal ? newYieldVal : yieldVal;
        recalculateInputMeasurements(
          measurementVal,
          valUnit,
          setNewYieldVal,
          setYieldDisplay,
          setPrevYieldMeasUnit,
          setYieldMeasUnit
          // toScale
        );
        break;
      case 'portion':
        measurementVal = newPortionVal ? newPortionVal : portionVal;
        recalculateInputMeasurements(
          measurementVal,
          valUnit,
          setNewPortionVal,
          setPortionDisplay,
          setPrevPortionMeasUnit,
          setPortionMeasUnit
          // toScale
        );
        break;
      default:
    }
  };

  return operatorRestaurants.filter((r) => r.id == stationRestoId).length === 1 ? (
    <div className="marginT10 windowHFull">
      <BacktoHeaderMobile
        restaurant={restaurant}
        backText={
          'Back to ' +
          (props.location.state && props.location.state.from && props.location.state.from == 'station'
            ? 'Station'
            : 'Recipes')
        }
        onClick={() => goBack()}
      />
      <div className="Line"></div>
      <RoleAccessibleComponent
        permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
      >
        <div className="view-recipe-control-mobile">
          {isEditing ? (
            <div onClick={saveRecipe}> Save</div>
          ) : (
            <div onClick={() => editRecipe1()} style={{ cursor: 'pointer' }}>
              {' '}
              Edit
            </div>
          )}
          {/*}<div style={{ cursor: 'pointer' }}>Add</div>
          <div style={{ cursor: 'pointer' }}>Duplicate</div> */}
          <RoleAccessibleComponent
            permittedRoles={[
              'Chef',
              'Admin',
              'Superadmin',
              'Client Admin',
              'Dev Team',
              'Account Holder',
              'Sous Chef',
              'Cook',
              'Trainer / Manager',
            ]}
          >
            {/* BUG 1201998443633170 */}
            <ReactToPrint
              trigger={() => (
                <div
                  style={{ cursor: 'pointer' }}
                  onMouseDown={() => {
                    // BUG 1201998443633170 NEW
                    if (!isPrinting) setIsPrinting(true);
                  }}
                  onMouseLeave={() => {
                    if (isPrinting) setIsPrinting(false);
                  }}
                >
                  Print
                </div>
              )}
              content={() => componentRef.current}
              onAfterPrint={() => setIsPrinting(false)} // BUG 1201998443633170
              documentTitle={`${title}: Recipe Details`} // BUG 1201998443633170
              onPrintError={() => setIsPrinting(false)} // BUG 1201998443633170
              pageStyle={`
								@view-recipe-column-container {
									color: black !important;
									background-color: white;
								}
								@view-recipe-title {
									color: black !important;
								}
								@view-recipe-description {
									color: black !important;
								}
								@view-recipe-items-table td {
									color: black !important;
								}
								@view-recipe-items-table th {
									color: black !important;
								}
								@view-prep-details-container {
									color: black !important;
								}
								@view-recipe-metadata-container {
									color: black !important;
								}
							`} // BUG 1201998443633170
            />
          </RoleAccessibleComponent>
          {/* <RoleAccessibleComponent
            permittedRoles={[
              'Chef',
              'Admin',
              'Superadmin',
              'Client Admin',
              'Dev Team',
              'Account Holder',
              'Sous Chef',
              'Cook',
              'Trainer / Manager',
            ]}
          >
            {/* <Link
              to={{
                pathname: `/recipes/share/${props.match.params.id}`,
                state: { from: props?.location?.state?.from, stationId: props?.location?.state?.stationId },
              }}
            >
              <div style={{ cursor: 'pointer' }}>Share</div>
            </Link>
          </RoleAccessibleComponent> */}
          <div>
            <Dropdown overlay={menu}>
              <div className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
                <img src={more} alt="see_more" height="30" />
              </div>
            </Dropdown>
          </div>
        </div>
      </RoleAccessibleComponent>

      <div className="Line-recipe-mobile"></div>
      <div className="recipe-tabs">
        <Tabs>
          <div className="tabs-title" label={tabs[0].title} tabIndex={'0'}>
            <div className="view-recipe-container-mobile">
              {isEditing ? (
                <div className="recipe-form-container-mobile">
                  <div className="recipe-title">Title</div>
                  <input
                    placeholder="Enter a recipe name"
                    type="text"
                    className="recipe-input"
                    defaultValue={title}
                    onChange={(event) => setTitle(event.target.value)}
                  />
                  <div className="recipe-title">Description</div>
                  {/* BUG 1201998443633170 */}
                  <textarea
                    type="text"
                    className="recipe-textarea"
                    placeholder="Short description of your recipe"
                    defaultValue={description}
                    onChange={(event) => setDesc(event.target.value)}
                  ></textarea>
                  {/* BUG 1201998443633170 */}
                  <div className="recipe-title">
                    Tag{' '}
                    {showIns ? (
                      <span className="show-ins-enter">* Click 'Enter' key after typing each tag to save</span>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* BUG 1201998443633170 */}
                  <div className="recipe-input">
                    {addTags.map((tag) => {
                      let color = 'black';
                      return (
                        <Tag color={color} key={tag} closable onClose={(e) => removeTag(e, tag)}>
                          {tag}
                        </Tag>
                      );
                    })}
                    <input
                      type="text"
                      className="rec-input-create"
                      placeholder="Enter a tag"
                      value={enteredText}
                      onChange={typeTag}
                      onFocus={() => setShowIns(true)}
                      onKeyDown={addTag}
                    />
                  </div>

                  <div className="recipe-title">Add file (includes photos, videos, documents)</div>
                  <div>
                    <div class="demo-container-mobile">
                      <div class="carousel">
                        <input
                          checked="checked"
                          class="carousel__activator"
                          id="carousel-slide-activator-1"
                          name="carousel"
                          type="radio"
                        />
                        <input
                          class="carousel__activator"
                          id="carousel-slide-activator-2"
                          name="carousel"
                          type="radio"
                        />
                        <input
                          class="carousel__activator"
                          id="carousel-slide-activator-3"
                          name="carousel"
                          type="radio"
                        />
                        <div class="carousel__controls">
                          <label class="carousel__control carousel__control--forward" for="carousel-slide-activator-2">
                            <div className="car-control"> › </div>
                          </label>
                        </div>
                        <div class="carousel__controls">
                          <label class="carousel__control carousel__control--backward" for="carousel-slide-activator-1">
                            <div className="car-control"> ‹ </div>
                          </label>
                          <label class="carousel__control carousel__control--forward" for="carousel-slide-activator-3">
                            <div className="car-control"> › </div>
                          </label>
                        </div>
                        <div class="carousel__controls">
                          <label class="carousel__control carousel__control--backward" for="carousel-slide-activator-2">
                            <div className="car-control"> ‹ </div>
                          </label>
                        </div>
                        <div class="carousel__screen">
                          <div class="carousel__track">
                            {imageUrls.map((image, i) => {
                              return (
                                <div
                                  key={`carousel-image-${i + 1}`}
                                  class="carousel__item carousel__item--mobile-in-1 carousel__item--tablet-in-2 carousel__item--desktop-in-3"
                                >
                                  <div class="demo-content-mobile">
                                    <div>
                                      <img src={image} />
                                      <Dropdown overlay={deletesImg(image)}>
                                        <div>
                                          {' '}
                                          <img src={IconsMoreOff} className="delete-Icons15" alt="IconsMoreOff" />
                                        </div>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: 10 }}>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                      {imageUrls.map((image) => {
                        return (
                          <div style={{ position: 'relative' }}>
                            {/* <img className="recipe-thumbnail-mobile" src={image} /> */}
                            {/* <a className="remove-img1" onClick={(e) => deleteRecImg(e, image)}>
                              X
                            </a> */}
                            {/* <Dropdown overlay={deletesImg(image)}>
                              <a className="remove-img13">
                                <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                              </a>
                            </Dropdown> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={imageUrls.length > 0 ? 'addFile-nomargin' : 'addFile-nomargin'}>
                    <AddFile handleDrop={handleDrop} />
                  </div>
                  {/* Prep and Cook Times */}
                  <div className="recipe-form-container-mobile">
                    <div className="prep-cook-mobile">
                      <div className="flex-grid prep-mobile">
                        <InputSelectCombo3
                          formLabel="Prep time"
                          showValue={showPrepTime}
                          onChange={checkTimeInput}
                          keyword="preparation"
                          keyword2="preptime"
                          onBlur={blurInputTime}
                          timeUnit={prepTimeUnit}
                          setTimeUnit={setPrepTimeUnit}
                          windowSize={windowSize.width}
                        />
                        <InputSelectCombo3
                          formLabel="Cook time"
                          showValue={showCookTime}
                          onChange={checkTimeInput}
                          keyword="cooking"
                          keyword2="cooktime"
                          onBlur={blurInputTime}
                          timeUnit={cookTimeUnit}
                          setTimeUnit={setCookTimeUnit}
                          windowSize={windowSize.width}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Yield, Portion, Quantity */}
                  <div className="recipe-form-container-mobile">
                    <div className="prep-cook-mobile">
                      <div className="flex-grid prep-mobile">
                        <InputSelectCombo
                          formLabel="Yield"
                          onChange={checkMeasurementInput}
                          keyword="yield"
                          onFocus={focusInputMeasurements}
                          onBlur={blurInputMeasurements}
                          showValue={yieldDisplay}
                          selectVal={yieldMeasUnit}
                          //
                          setInputVal={setNewYieldVal}
                          setShowVal={setYieldDisplay}
                          //
                          //
                          onChangeSelect={checkMeasurementUnitChange}
                          inputClassMobile="recipe-input-smll"
                          windowSize={windowSize.width}
                        >
                          {measurements &&
                            measurements.length > 0 &&
                            measurements.map((m, i) => (
                              <option key={`${i + 1}`} value={m.name}>
                                {m.name}
                              </option>
                            ))}
                        </InputSelectCombo>
                        <InputSelectCombo
                          formLabel="Portion"
                          onChange={checkMeasurementInput}
                          keyword="portion"
                          onFocus={focusInputMeasurements}
                          onBlur={blurInputMeasurements}
                          showValue={portionDisplay}
                          selectVal={portionMeasUnit}
                          //
                          setInputVal={setNewPortionVal}
                          setShowVal={setPortionDisplay}
                          //
                          //
                          onChangeSelect={checkMeasurementUnitChange}
                          inputClassMobile="recipe-input-smll"
                          windowSize={windowSize.width}
                        >
                          {measurements &&
                            measurements.length > 0 &&
                            measurements.map((m, i) => (
                              <option key={`${i + 1}`} value={m.name}>
                                {m.name}
                              </option>
                            ))}
                        </InputSelectCombo>
                        <InputSelectCombo2
                          formLabel="Quantity"
                          onChange={checkMeasurementInput}
                          keyword="quantity"
                          onFocus={focusInputMeasurements}
                          onBlur={blurInputMeasurements}
                          showValue={quantityDisplay}
                          setInputVal={setNewQuantityVal}
                          setShowVal={setQuantityDisplay}
                          inputClassMobile="recipe-input-smll"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="recipe-title">
                      <span>Adjust recipe</span>{' '}
                      {showReset && (
                        <span onClick={resetMultiplier} className="reset-multiplier">
                          Reset
                        </span>
                      )}
                    </div>
                    <div className="adj-rec-box-mob">
                      <div>Convert by</div>
                      <div>
                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                          <Radio.Group onChange={setPortions} value={portionValue}>
                            <Radio.Button value={'scale'}>Scale</Radio.Button>
                            <Radio.Button value={'portions'}>Portions</Radio.Button>
                          </Radio.Group>
                        </div>
                        <div style={{ marginTop: 30, textAlign: 'center' }}>
                          {' '}
                          <InputNumber
                            min={0}
                            max={1000}
                            style={{ margin: '0 90px', color: 'white', background: '#454545', border: 'none' }}
                            value={scaleInputValue}
                            onChange={changeScaleVal}
                          />
                        </div>
                        <div>
                          <div onClick={decreaseVal} className="calc-rec-lessMob">
                            <img src={less} height="28" />
                          </div>
                          <div style={{ margin: '30px auto', width: '65%' }}>
                            <Slider
                              min={1}
                              max={1000}
                              onChange={changeScaleVal}
                              value={typeof scaleInputValue === 'number' ? scaleInputValue : 0}
                            />
                          </div>
                          <div onClick={increaseVal} className="calc-rec-addMob">
                            <img src={add2} height="28" />
                          </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                          <button className="calc-btn-recipeMob" onClick={(e) => calculateScale(e)}>
                            Calculate
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <IngredientTable
                        addIngredientsList={recipeIngredients}
                        unassignIng={unassignIng}
                        ingListUpdates={ingListUpdates}
                        isEditing={isEditing}
                        qtyCombine={qtyCombine}
                        updateQtyCombine={updateQtyCombine}
                        recipeId={props.match.params.id}
                      />
                      <AddIngredientPopup
                        updateIngredients={updateIngredients}
                        // BUG 1202076501452783
                        restaurantId={resto_id}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ' '
              )}

              <div className="view-recipe-title-mobile">
                <div className="view-recipe-name">
                  {' '}
                  {isEditing ? (
                    ''
                  ) : (
                    // <input
                    //   type="text"
                    //   className="recipe-input"
                    //   defaultValue={title}
                    //   onChange={(event) => setTitle(event.target.value)}
                    // />
                    <div className="view-recipe-name">{viewRecipe.name}</div>
                  )}
                </div>
                {/* BUG 1201998443633170 */}
                {isEditing ? (
                  ''
                ) : (
                  <div class="view-recipe-star-mobile">
                    <MasterRecipeIcon />
                  </div>
                )}
              </div>
              <div>
                {' '}
                {isEditing ? (
                  ''
                ) : (
                  // <textarea
                  //   defaultValue={description}
                  //   className="rec-textarea-mobile"
                  //   onChange={(event) => setDesc(event.target.value)}
                  // ></textarea>
                  <div className="view-recipe-desc-mobile">{prepInfo.description}</div>
                )}
              </div>
              {isEditing ? (
                ''
              ) : (
                <div className="recipe-carousel-mobile" onClick={showModal}>
                  <Carousel>
                    {/* <div>
                    <img src={recipe} />
                  </div>
                  <div>
                    <img src={recipe} />
                  </div>
                  <div>
                    <img src={recipe} />
                  </div>
                  <div>
                    <img src={recipe} />
                  </div> */}
                    {imageUrls &&
                      imageUrls.length > 0 &&
                      imageUrls.map((image) => {
                        return (
                          <div>
                            <img src={image} />
                          </div>
                        );
                      })}
                  </Carousel>
                </div>
              )}

              <div>
                {/* BUG 1201998443633170 */}
                {isEditing ? (
                  ''
                ) : (
                  // <div className="view-recipe-title-mob">
                  //   <input
                  //     type="text"
                  //     className="recipe-input"
                  //     value={tagString}
                  //     onChange={(e) => setTagString(e.target.value)}
                  //   />
                  // </div>
                  <div className="view-recipe-tags-mobile">
                    {(tags || []).map((tag, i) => {
                      return (
                        <div key={`recipe-tag-${i + 1}`} className="tag">
                          {tag}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div>
                {isEditing ? (
                  ''
                ) : (
                  // <div style={{ marginLeft: 22 }}>
                  //   <img src={prep} alt="prep" height="30" />
                  //   Prep:{' '}
                  //   <input
                  //     type="text"
                  //     className="recipe-input-sml"
                  //     value={prepHr}
                  //     placeholder="Hour"
                  //     onChange={(event) => setPrepHr(event.target.value)}
                  //   />
                  //   <input
                  //     type="text"
                  //     className="recipe-input-sml"
                  //     value={prepMn}
                  //     placeholder="Minutes"
                  //     onChange={(event) => setPrepMn(event.target.value)}
                  //   />
                  // </div>
                  <div className="view-prep-container">
                    <PrepTimeIcon />
                    Prep:
                    <span className="view-prep-details-container-cook">
                      {prepHr === 0 && prepMn === 0 ? (
                        <span className="view-prep-details-measure">To be Estimated</span>
                      ) : (
                        <>
                          <span className="view-prep-details-measure">
                            {prepHr ? prepHr.toString() + ' ' + (prepHr > 1 ? pluralize('hour') : 'hour') : ''}
                          </span>
                          <span className="view-prep-details-measure">
                            {prepMn ? prepMn.toString() + ' ' + (prepMn > 1 ? pluralize('min') : ' min') : ''}
                          </span>
                        </>
                      )}
                    </span>
                  </div>
                )}
              </div>
              {isEditing ? (
                ''
              ) : (
                // <div style={{ marginLeft: 22 }}>
                //   <img src={prep} alt="prep" height="30" />
                //   Cook:{' '}
                //   <input
                //     type="text"
                //     className="recipe-input-sml"
                //     value={cookHr}
                //     placeholder="Hour"
                //     onChange={(event) => setCookHr(event.target.value)}
                //   />
                //   <input
                //     type="text"
                //     className="recipe-input-sml"
                //     value={cookMn}
                //     placeholder="Minutes"
                //     onChange={(event) => setCookMn(event.target.value)}
                //   />
                // </div>
                <div className="view-prep-container">
                  <CookTimeIcon />
                  Cook:
                  <span className="view-prep-details-container-cook">
                    {cookHr === 0 && cookMn === 0 ? (
                      <span className="view-prep-details-measure">To be Estimated</span>
                    ) : (
                      <>
                        <span className="view-prep-details-measure">
                          {cookHr ? cookHr.toString() + ' ' + (cookHr > 1 ? pluralize('hour') : 'hour') : ''}
                        </span>
                        <span className="view-prep-details-measure">
                          {cookMn ? cookMn.toString() + ' ' + (cookMn > 1 ? pluralize('min') : ' min') : ''}
                        </span>
                      </>
                    )}
                  </span>
                </div>
              )}

              {isEditing ? (
                ''
              ) : (
                // <div style={{ marginLeft: 22 }}>
                //   <img src={recipeyield} alt="prep" height="30" />
                //   Yield:{' '}
                //   <input
                //     type="text"
                //     className="recipe-input-sml"
                //     value={yld}
                //     placeholder="0"
                //     onChange={(event) => setYield(event.target.value)}
                //   />
                //   <select className="recipe-metadata-select-mobile3" defaultValue={'default'}>
                //     <option value="default">Select</option>
                //     <option value="bucket">Bucket</option>
                //   </select>
                // </div>
                <div className="view-prep-container">
                  <YieldIcon />
                  Yield:
                  <span className="view-prep-details-container-mobile">
                    <span className="view-prep-details-measure">{prepInfo.yield}</span>
                    <span className="view-prep-details-measure">
                      {prepInfo.yieldVal &&
                      prepInfo.yieldVal !== 'Select' &&
                      measurements.length > 0 &&
                      measurements.map((d) => d.name).includes(prepInfo.yieldVal)
                        ? prepInfo.yieldVal
                        : ' '}
                    </span>
                  </span>
                </div>
              )}

              {isEditing ? (
                ''
              ) : (
                // <div style={{ marginLeft: 22 }}>
                //   <img src={portionimg} alt="prep" height="30" />
                //   Portion:{' '}
                //   <input
                //     type="text"
                //     className="recipe-input-sml"
                //     value={portion}
                //     placeholder="0"
                //     onChange={(event) => setPortion(event.target.value)}
                //   />
                //   <select className="recipe-metadata-select-mobile3" defaultValue={'default'}>
                //     <option value="default">Select</option>
                //     <option value="pieces">Pieces</option>
                //   </select>
                // </div>
                <div className="view-prep-container">
                  <PortionIcon />
                  Portion:
                  <span className="view-prep-details-container-portion">
                    <span className="view-prep-details-measure">{prepInfo.portion}</span>
                    <span className="view-prep-details-measure">
                      {prepInfo.portionVal &&
                      prepInfo.portionVal !== 'Select' &&
                      measurements.length > 0 &&
                      measurements.map((d) => d.name).includes(prepInfo.portionVal)
                        ? prepInfo.portionVal
                        : ' '}
                    </span>
                  </span>
                </div>
              )}

              {isEditing ? (
                ''
              ) : (
                // <div style={{ marginLeft: 45 }}>
                //   Quantity:{' '}
                //   <input
                //     type="text"
                //     className="recipe-input-sml"
                //     value={quantity}
                //     placeholder="0"
                //     onChange={(event) => setQty(event.target.value)}
                //   />
                //   <select className="recipe-metadata-select-mobile3" defaultValue={'default'}>
                //     <option value="default">Select</option>
                //     <option value="quantity">Qty</option>
                //   </select>
                // </div>
                <div className="view-prep-container">
                  <ServingIcon />
                  Quantity:
                  <span className="view-prep-details-container-qty">
                    <span className="view-prep-details-measure">{prepInfo.quantity}</span>
                    <span className="view-prep-details-measure">{prepInfo.quantity > 0 ? 'portions' : ' '}</span>
                  </span>
                </div>
              )}

              {/* {isEditing ? (
                ''
              ) : (
                <div className="view-recipe-servings">
                  <div className="servings-reset">
                    <div>Servings</div>
                    <div className="view-recipe-reset" onClick={reset}>
                      Reset
                    </div>
                  </div>
                  <div className="servings-reset-controls">
                    <div className="servings-reset-count-1" onClick={decAmt}>
                      -
                    </div>
                    <div className="servings-reset-count-2">{prepInfo.portion + incrementAmt}</div>
                    <div className="servings-reset-count-3" onClick={incAmt}>
                      +
                    </div>
                  </div>
                </div>
              )} */}

              <br />

              {isEditing ? (
                ''
              ) : (
                // <div>
                //   <IngredientTable addIngredientsList={recipeIngredients} unassignIng={unassignIng} />
                // </div>
                <IngredientsListMobile ingredientList={recipeIngredients} isEditing={isEditing} />
              )}
            </div>
          </div>
          <div className="tabs-title" label={tabs[1].title} tabIndex={'1'}>
            <div className="view-recipe-container-mobile">
              {isEditing ? (
                <div>
                  <EquipmentTable
                    addEquipmentList={addEquipmentList}
                    unassignEquip={unassignEquip}
                    eqListUpdates={eqListUpdates}
                    isEditing={isEditing}
                    updateEquipCategory={updateEquipCategory}
                  />
                  <AddEquipment updateEquipment={updateEquipment} />
                </div>
              ) : (
                // <div className="view-rec-eqtable">
                //   <EquipmentTable addEquipmentList={addEquipmentList} unassignEquip={unassignEquip} />
                // </div>
                <div>
                  {' '}
                  <EquipmentListMobile equipmentList={recipeEquipment} isEditing={isEditing} />
                </div>
              )}
            </div>
          </div>
          <div className="tabs-title" label={tabs[2].title} tabIndex={'2'}>
            <div className="view-recipe-container-mobile">
              <InstructionsListMobile
                instructionlist={instructions}
                isEditing={isEditing}
                updateInstructions={updateInstructions}
              />
            </div>
          </div>
          {/* <div className="tabs-title 3" label={tabs[3].title} tabIndex={'3'}>
            <div className="view-recipe-container-mobile">
              <NutritionViewPopupMobile nutritionList={recipeDetails.nutrition} />
            </div>
          </div>
          <div className="tabs-title 4" label={tabs[4].title} tabIndex={'4'}>
            <div className="view-recipe-container-mobile">
              <NutritionControls nutritionList={recipeDetails.nutrition} />
            </div>
          </div> */}
        </Tabs>
      </div>
      <PrintRecipe
        ref={componentRef}
        isPrinting={isPrinting}
        name={viewRecipe.name}
        description={prepInfo.description}
        time={{
          preparation: prepInfo?.prepMins ? prepInfo.prepMins : 0,
          cooking: prepInfo?.cookMins ? prepInfo.cookMins : 0,
        }}
        scaling={{
          yield: {
            value: prepInfo.yield,
            unit: prepInfo.yieldVal,
          },
          portion: {
            value: prepInfo.portion,
            unit: prepInfo.portionVal,
          },
          quantity: prepInfo.quantity,
          measurements: measurements,
        }}
        ingredients={recipeIngredients}
        equipments={recipeEquipment}
        instructions={instructions}
        coverimage={imageUrls.length > 0 ? imageUrls[0] : null}
      />
      <Modal title={viewRecipe.name} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div className="recipe-carousel-mobile">
          <Carousel>
            {/* <div>
              <img src={recipe} />
            </div>
            <div>
              <img src={recipe} />
            </div>
            <div>
              <img src={recipe} />
            </div>
            <div>
              <img src={recipe} />
            </div> */}
            {imageUrls.map((image) => {
              return (
                <div>
                  <img src={image} />
                </div>
              );
            })}
          </Carousel>
        </div>
      </Modal>
    </div>
  ) : (
    <div className="marginT10 windowHFull">
      <ErrorPage copy="Recipe belongs to another restaurant! This content is unavailable to your user account. If you feel you should have access to this content, please contact your local administrator" />
    </div>
  );
};

export default ViewRecipesMobile;
