import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import StationRecipesMobile from '../../components/StationRecipes/stationRecipes-mobile';
import StationChecklistsMobile from '../../components/StationChecklists/stationChecklists-mobile';
import './index.css';
import info from '../../img/station-info-icon.png';
import { Table, Switch, Menu, Dropdown, Popover, Drawer, notification, Row, Col, Button, Tag } from 'antd';
import useWindowSize from '../../components/windowdimensions/windowSize';
import { updateChecklist } from '../../services/station.service';
import {
  addItem as addItemToShop,
  itemOrdered,
  deleteItem,
} from '../../services/shoplist.service';
import { updateItem } from '../../services/checklist.service';
import { cloneDeep, findIndex } from 'lodash';
import { CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { CompletionIcon, HealthcheckIcon, ShoppingCartIcon, ChefIcon, ThumbnailImage } from '../../components/common/common_ui';

const StationGeneralMobile = (props) => {
  const {
    showFilter,
    selectedStationChecklists,
    stationTasks,
    recList,
    updateActiveRecs,
    updateActiveChks,
    tableData,
    filterCompletedHealthChecks,
    filterProductionReady,
    filterCompleted,
    filterNeeded,
    updateIngredientState,
    activeRecs,
    activeChk,
    recipesOnly,
    assignedToMe,
    updateTaskState,
    setHasUpdate,
    stationId,
    hasChecklist,
    isEvent,
    pristineIngs,
    showAllIngredients,
    hasRecipeLists,
    setPristineIngs,
  } = props;
  const [expandedRows, setExpandedRows] = useState([]);
  const [visible, updateVisible] = useState(true);
  const [showDrawerData, updateShowDrawer] = useState(false);
  const [menuName, setMenuName] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [showChecklists, setShowChecklists] = useState(true);
  const [showRecipeLists, setShowRecipeLists] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/checklist`));
  const [currentChecklist, setCurrentChecklist] = useState(0);

  const openRecipePanel_ids = JSON.parse(localStorage.getItem('openRecipePanelStations')) || [];

  const restaurantId = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  let userRole = (JSON.parse(localStorage.getItem('operator')) || {}).role;

  useEffect(() => {
    console.log('BOOM');
    setUserInfo(JSON.parse(localStorage.getItem('operator')) || {});

    if (selectedStationChecklists?.length > 0 && selectedStationChecklists[0]?.list[0]?.list.length > 0) {
      if (openRecipePanel_ids.includes(stationId)) {
        console.log('setting to false 1');
        setShowChecklists(false);
      } else {
        console.log('setting to true');
        setShowChecklists(true);
      }
    } else {
      console.log('setting to false');
      setShowChecklists(false);
    }
  }, [selectedStationChecklists]);

  useEffect(() => {
    console.log('to show checklist in fast select?', showChecklists);
  }, [showChecklists]);

  useEffect(() => {
    let condensed_view = localStorage.getItem('mobileCondensedView');
    if (condensed_view) {
      updateVisible(false);
    }
  }, []);

  useEffect(() => {
    if (activeChk?.length > 0) {
      let activeIds = activeChk.map((a) => a.key);
      setMenuName('');

      let stationLists2 = stationTasks.filter((s) => activeIds.includes(s.key));
      console.log(stationLists2);

      if (activeChk.length === 0 && activeRecs.length > 0) {
        setSelectedList(stationTasks);
      } else {
        setSelectedList(stationLists2);
      }
    }
  }, [activeChk, stationTasks]);

  // useEffect(() => {
  //   let getIds = JSON.parse(localStorage.getItem('stationVisitedIds'));
  //   if (getIds && getIds.includes(stationId)) {
  //     let getOpenRows = JSON.parse(localStorage.getItem('stationOpenRows'));
  //     if (getOpenRows && getOpenRows.length > 0) {
  //       setExpandedRows(getOpenRows);
  //     } else {
  //       setExpandedRows([1, 2]);
  //     }
  //   } else {
  //     setExpandedRows([1, 2]);
  //   }
  // }, [stationId]);

  const windowSize = useWindowSize();

  const showDrawer = () => {
    updateShowDrawer(true);
  };

  const handleShowDrawer = (bool) => {
    updateShowDrawer(bool);
  };

  const onClose = () => {
    updateShowDrawer(false);
  };

  const updateFilter = (data) => {
    console.log('TOUCHING GENERAL');
    console.log('RECLIST', recList);
    updateShowDrawer(true);

    showFilter(data);
  };

  // to show ingredients with quality check NOT YET completed
  const filterHealthComplete = () => {
    filterCompletedHealthChecks();
    setMenuName('Quality check incomplete');
  };

  // to show quality checked ingredients READY for production
  const filterProductionReady1 = () => {
    filterProductionReady();
    setMenuName('Production ready');
  };

  // to show quality checked ingredients NOT YET ready for production
  const filterComplete = () => {
    filterCompleted();
    setMenuName('Production completed');
  };

  const filterComplete1 = () => {
    setMenuName('Items completed');
    let comp = stationTasks.filter((obj) => obj.children.some((task) => task.checked == true));
    setSelectedList(comp);
  };

  const filterIncomplete = () => {
    setMenuName('Items incomplete');
    let inc2 = stationTasks.filter((obj) => obj.children.some((task) => task.checked == false));
    setSelectedList(inc2);
  };

  // filter ingredients that already in the shopping list page
  const filterShop = () => {
    filterNeeded();
    setMenuName('Shopping list');
  };

  const allIngredientFilter = () => {
    showAllIngredients();
    setMenuName('All ingredients');
  };

  const menu = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <span onClick={allIngredientFilter}>All ingredients</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterHealthComplete}>Quality check incomplete</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterProductionReady1}>Production ready</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterComplete}>Production completed</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterShop}>Shopping list</span>
      </Menu.Item>
    </Menu>
  );

  const menu1 = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <span onClick={() => window.location.reload()}>All items</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterIncomplete}>Items incomplete</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={filterComplete1}>Items completed</span>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Sorted by name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {obj.type == 'parent' && (
            <span className="row-parent-title">
              {key}
              <div className="station-ing-totals">( {obj.children.length} ingredients )</div>
            </span>
          )}
          {obj.type == 'child' && (
            <div className="station-name-key-mobile">
              <RoleAccessibleComponent
                permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
              >
                <div>
                  {obj?.day && obj.day !== 'Invalid date' ? (
                    <div
                      onClick={(e) =>
                        userRole == 'Cook' || userRole == 'Trainer / Manager' || userRole == 'Front House'
                          ? ''
                          : unsetDay(obj)
                      }
                    >
                      {obj.day == 'Friday' && <div className="station-set-days set-day-fri set-day-orient">FRI</div>}
                      {obj.day == 'Thursday' && <div className="station-set-days set-day-thu set-day-orient">THU</div>}
                      {obj.day == 'Tuesday' && <div className="station-set-days set-day-tue set-day-orient">TUE</div>}
                      {obj.day == 'Wednesday' && <div className="station-set-days set-day-wed set-day-orient">WED</div>}
                      {obj.day == 'Monday' && <div className="station-set-days set-day-mon set-day-orient">MON</div>}
                      {obj.day == 'Sunday' && <div className="station-set-days set-day-sun set-day-orient">SUN</div>}
                      {obj.day == 'Saturday' && <div className="station-set-days set-day-sat set-day-orient">SAT</div>}
                    </div>
                  ) : (
                    <div
                      className="station-set-days set-day set-day-orient"
                      onClick={(e) =>
                        userRole == 'Trainer / Manager' || userRole == 'Front House' ? '' : setActiveDay(obj)
                      }
                    >
                      SET
                    </div>
                  )}
                  <Popover
                    placement="right"
                    content={
                      <div className="day-infoPop">
                        {obj.day && obj.day !== 'Invalid date' ? (
                          <div>
                            <div style={{ marginBottom: 3 }}>
                              <b>Recorded by</b>
                            </div>
                            <p>{obj.setBy}</p>
                            <div style={{ marginBottom: 3 }}>
                              <b>Date</b>
                            </div>
                            <p>{obj.setDate}</p>
                            <div style={{ marginBottom: 3 }}>
                              <b>Time</b>
                            </div>
                            <p>{obj.setTime}</p>
                          </div>
                        ) : (
                          <div>This will display the name, date and time of when the day dot is set</div>
                        )}
                      </div>
                    }
                  >
                    <div className="station-info-image-container-mobile">
                      <img className="station-info-button-mobile" src={info} alt="info" />
                    </div>
                  </Popover>
                </div>
              </RoleAccessibleComponent>
              <ThumbnailImage
                className="station-ingredient-image-container-mobile"
                src={obj?.ingredientImage ? obj.ingredientImage : <ChefIcon />}
              />
              <div className="station-ingredient-action-container-mobile">
                <span className="station-healthcheck-icon-mobile" alt="healthcheck" onClick={(e) => toggleHealth(obj)}>
                  <HealthcheckIcon completed={obj.healthCheck === 2} dimmed={false} />
                </span>
              </div>
              <div className="station-ingredient-action-container-mobile">
                {/* <img
                  alt="completed"
                  className="station-action-button-mobile"
                  onClick={(e) => toggleCompleted(e, obj)}
                  src={obj.completed === 2 ? completed3 : obj.healthCheck === 2 ? completed2 : completed1}
                /> */}
                {obj.completed === 2 && (
                  <span className="station-checkmark-icon-mobile" onClick={(e) => toggleCompleted(obj)}>
                    <CompletionIcon completed={true} dimmed={false} />
                  </span>
                )}
                {obj.healthCheck === 2 && obj.completed !== 2 && (
                  <span className="station-checkmark-icon-mobile" onClick={(e) => toggleCompleted(obj)}>
                    <CompletionIcon completed={false} dimmed={false} />
                  </span>
                )}
                {obj.healthCheck !== 2 && obj.completed !== 2 && (
                  <span className="station-checkmark-icon-mobile" onClick={(e) => toggleCompleted(obj)}>
                    <CompletionIcon completed={false} dimmed={true} />
                  </span>
                )}
              </div>
              <div className="station-ingredient-action-container-mobile">
                <span
                  className="station-shoppingcart-icon-mobile"
                  alt="ordered-status"
                  onClick={(e) => toggleOrdered(obj)}
                >
                  <ShoppingCartIcon needed={obj.ordered === 0} ordered={obj.ordered === 1} dimmed={false} />
                </span>
              </div>
            </div>
          )}
          {visible ? (
            <div>
              {obj.type == 'child' && (
                <div className="chk-mobile-details">
                  <div className="station-ingredient-title">{key}</div>
                  <div className="station-ingredient-measure">{`${obj.formattedQty} ${obj.measurementName !== 'nomeas' ? obj.measurementName : ''}`}</div>
                  {obj.preparationName ? (
                    <div className="station-ingredient-preparation">
                      {obj.preparationName}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          ) : null}
        </span>
      ),
    },
  ];

  const columnChecklist = [
    {
      title: 'Sorted by name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {obj.type == 'child' && (
            <div>
              <div className="station-name-key-mobile">
                {/* <img
                style={{ paddingBottom: 6, marginRight: 5 }}
                alt="completed"
                height="38"
                src={obj.completed === 2 ? completed3 : completed1}
              /> */}
                <div className="station-checkmark-image-container-mobile">
                  <span className="station-checkmark-icon-mobile" onClick={() => setCompleteTask(obj, true)}>
                    <CompletionIcon completed={obj.complete == 2} dimmed={false} />
                  </span>
                </div>
                {/* {assignedToMe == 2 && (
                <div className="station-checkmark-image-container-mobile">
                  {' '}
                  <img
                    alt="completed"
                    className="station-action-button-mobile"
                    onClick={() => setCompleteTask(obj, true)}
                    src={obj.complete == 2 ? completed : unselected2}
                  />
                </div>
              )}
              {assignedToMe == 1 && (
                <div className="station-checkmark-image-container-mobile">
                  {' '}
                  <img
                    alt="completed"
                    className="station-action-button-mobile"
                    src={obj.complete == 2 ? unselected1 : incomplete}
                    style={{ cursor: 'not-allowed' }}
                  />
                </div>
              )} */}
                <div className="station-day-ing"></div>
                <div className="station-ingredient-title" style={{ marginLeft: 10 }}>
                  {key}
                </div>
                {/* <span>
                {obj.type == 'child' && obj.texts > 0 ? <span className="texts">{obj.texts}</span> : ''}
                {obj.type == 'child' && obj.texts > 0 ? (
                  <span className="texts-icon">
                    {' '}
                    <img src={comment} className="comment" alt="comment" />
                  </span>
                ) : (
                  ''
                )}
                {obj.type == 'child' && obj.attachments > 0 ? (
                  <span className={obj.texts > 0 ? 'attachments' : 'attachments1'}>{obj.attachments}</span>
                ) : (
                  ''
                )}
                {obj.type == 'child' && obj.attachments > 0 ? (
                  <span className={'texts-icon'}>
                    {' '}
                    <img src={attach} className="comment" alt="attach" />
                  </span>
                ) : (
                  ''
                )}
              </span> */}
              </div>
              <Row gutter={32} className="checklist-item-row">
                <Col span={11}>
                  <span style={{ marginLeft: 15 }}>Assignee</span>
                </Col>
                <Col span={20} style={{ width: 'auto' }}>
                  <span style={{ marginLeft: 11 }}>{obj.assignee}</span>
                </Col>
              </Row>
              <Row gutter={32} className="checklist-item-row">
                <Col span={11}>
                  <span style={{ marginLeft: 15 }}>Due date</span>
                </Col>
                <Col span={20} style={{ width: 'auto' }}>
                  <span style={{ marginLeft: 11 }}>
                    {obj.dueDate && !obj.dueDate.includes('1970')
                      ? moment.utc(obj.dueDate).format('MM/DD/YYYY')
                      : 'No date'}
                  </span>
                </Col>
              </Row>
              <Row gutter={32} className="checklist-item-row">
                <Col span={10}>
                  <span style={{ marginLeft: 15 }}>Project</span>
                </Col>
                <Col span={20} style={{ width: 'auto' }}>
                  <span style={{ marginLeft: 20 }}>{obj.project}</span>
                </Col>
              </Row>
              <Row gutter={32} className="checklist-item-row">
                <Col span={10}>
                  <span style={{ marginLeft: 15 }}>Priority</span>
                </Col>
                <Col span={20} style={{ width: 'auto' }}>
                  <span style={{ marginLeft: 14 }}>
                    {' '}
                    {obj.priority == 'High' && <span className="priorities high">{obj.priority}</span>}
                    {obj.priority == 'Medium' && <span className="priorities med">{obj.priority}</span>}
                    {obj.priority == 'Low' && <span className="priorities low">{obj.priority}</span>}
                  </span>
                </Col>
              </Row>
            </div>
          )}
          {obj.type == 'parent' && (
            <span className="row-parent-title">
              {key} <div className="station-ing-totals">( {obj.children.length} items )</div>
            </span>
          )}
        </span>
      ),
    },
  ];

  const handleRowExpand = (record) => {
    let openRows = JSON.parse(localStorage.getItem('stationOpenRows'));
    console.log('expand row', record.rowKey);
    if (expandedRows.includes(record.rowKey)) {
      let filterRecord = expandedRows.filter((key) => key !== record.rowKey);
      setExpandedRows(filterRecord);

      if (openRows) {
        let newRows = openRows.filter((val) => filterRecord.includes(val));
        localStorage.setItem('stationOpenRows', JSON.stringify(newRows));
      } else {
        localStorage.setItem('stationOpenRows', JSON.stringify(filterRecord));
      }

      let getActiveStations = JSON.parse(localStorage.getItem('stationVisitedIds'));
      if (getActiveStations) {
        const newArr = [...getActiveStations, stationId];
        const uniqIds = [...new Set(newArr)];
        localStorage.setItem('stationVisitedIds', JSON.stringify(uniqIds));
      } else {
        localStorage.setItem('stationVisitedIds', JSON.stringify([stationId]));
      }
    } else {
      setExpandedRows([...expandedRows, record.rowKey]);

      if (openRows) {
        const newRows = [...openRows, ...expandedRows, record.rowKey];
        const uniqRows = [...new Set(newRows)];
        localStorage.setItem('stationOpenRows', JSON.stringify(uniqRows));
      } else {
        localStorage.setItem('stationOpenRows', JSON.stringify([...expandedRows, record.rowKey]));
      }

      let getActiveStations = JSON.parse(localStorage.getItem('stationVisitedIds'));
      if (getActiveStations) {
        const newArr = [...getActiveStations, stationId];
        const uniqIds = [...new Set(newArr)];
        localStorage.setItem('stationVisitedIds', JSON.stringify(uniqIds));
      } else {
        localStorage.setItem('stationVisitedIds', JSON.stringify([stationId]));
      }
    }
  };

  const collapseAll = () => {
    setExpandedRows([]);
    localStorage.setItem('stationOpenRows', JSON.stringify([]));
  };

  const onChange = (checked) => {
    if (checked) {
      updateVisible(false);
      localStorage.setItem('mobileCondensedView', true);
    } else {
      updateVisible(true);
      localStorage.removeItem('mobileCondensedView');
    }
  };

  const updateStationIngredient = (fetchParams, ingredient, mappedUpdates) => {
    let clonedStationIngs = cloneDeep(pristineIngs);
    const ingIndex = findIndex(
      clonedStationIngs,
      (ing) =>
        ing.ingredientId === ingredient.ingredientId &&
        ing.recipeId === ingredient.recipeId &&
        ing.courseId === ingredient.courseId &&
        ing.menuId === ingredient.menuId
    );
    if (ingIndex > -1) {
      mappedUpdates.forEach((pair) => {
        const key = pair.key,
          value = pair.value;
        clonedStationIngs[ingIndex][key] = value;
      });
      setPristineIngs(clonedStationIngs);

      updateChecklist(fetchParams)
        .then((res) => {
          setTimeout(() => {
            updateIngredientState(true);
          }, 1000);
        })
        .catch((err) => console.log(err));
    }
  }

  const toggleHealth = (obj) => {
    let newValue = obj.healthCheck === 1 ? 2 : 1;
    let prepIng = {
      healthCheck: newValue,
      completed: obj.completed,
      ordered: obj.ordered,
      setDate: obj.setDate,
      setTime: obj.setTime,
      setBy: obj.setBy,
      quantity: obj.ingredientQty,
      cost: 0,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty
    }
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    }

    if (isEvent) {
      params.eventId = stationId;
    } else {
      params.stationId = stationId;
    }

    updateStationIngredient(params, obj, [{ key: 'healthCheck', value: newValue }]);
  };

  const toggleCompleted = (obj) => {
    let newValue = obj.completed === 1 ? 2 : 1;
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: newValue,
      ordered: obj.ordered,
      setDate: obj.setDate,
      setTime: obj.setTime,
      setBy: obj.setBy,
      quantity: obj.ingredientQty,
      cost: 0,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty
    };
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (isEvent) {
      params.eventId = stationId;
    } else {
      params.stationId = stationId;
    }
    updateStationIngredient(params, obj, [{ key: 'completed', value: newValue }]);
  };

  const updateIngredientOrderStatus = (obj, newStatus) => {
    // UPDATE STATION CHECKLIST
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: obj.completed,
      ordered: newStatus.toString(),
      setDate: obj.setDate,
      setTime: obj.setTime,
      setBy: obj.setBy,
      quantity: obj.ingredientQty,
      cost: 0, // this is temporary, API will be updated to accomodate this field,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };

    let toChecklistParams = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (isEvent) {
      toChecklistParams.eventId = stationId;
    } else {
      toChecklistParams.stationId = stationId;
    }

    updateStationIngredient(toChecklistParams, obj, [{ key: 'ordered', value: newStatus }]);
  };

  const toggleOrdered = (obj) => {
    let newStatus;

    // from default/nonexistent in shopping list to "needed"
    if (obj.ordered == -1) {
      newStatus = 0;
    } else if (obj.ordered == 0) {
      newStatus = 1;
    } else if (obj.ordered == 1) {
      newStatus = -1;
    }

    // ADD / UPDATE SHOPPING LIST

    // if ingredient is not in shopping list, ADD
    if (obj.ordered == -1) {
      const currentDate = new Date() * 1;
      let toShoplistParams = {
        operatorId: (JSON.parse(localStorage.getItem('operator')) || {}).id,
        stationId: isEvent ? null : stationId,
        eventId: isEvent ? stationId : null,
        ingredientId: obj.ingredientId,
        ingredientCategoryId: obj.categoryId,
        restaurantId: restaurantId,
        ordered: 0,
        dateTime: currentDate,
      };

      addItemToShop(toShoplistParams)
        .then((res) => {
          if (res?.results?.id) {
            obj.shoplistId = res.results.id;
            updateIngredientOrderStatus(obj, newStatus);
          } else {
            return;
          }
        })
        .catch((err) => console.log(err));

      // else, update the status
    } else {
      const currentDate = new Date() * 1;
      let changeStatParams = {
        operatorId: (JSON.parse(localStorage.getItem('operator')) || {}).id,
        dateUpdated: currentDate.toString(),
      };

      if (newStatus > 0) {
        itemOrdered(obj.shoplistId.toString(), changeStatParams)
          .then((res) => {
            if (res.id === obj.shoplistId) {
              updateIngredientOrderStatus(obj, newStatus);
            }
          })
          .catch((err) => console.log(err));
      } else {
        deleteItem(obj.shoplistId?.toString())
          .then((res) => {
            updateIngredientOrderStatus(obj, newStatus);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const clearStation = () => {
    let stationIngredients = cloneDeep(pristineIngs);
    const fetchData = () => {
      const allRequests = stationIngredients.map((obj) => {
        updateChecklist({
          menuId: obj.menuId,
          recipeId: obj.recipeId,
          ingredientId: obj.ingredientId,
          preparationId: obj.preparationId,
          measurementId: obj.measurementId,
          stationId: isEvent ? null : stationId,
          eventId: isEvent ? stationId : null,
          info: {
            healthCheck: 1,
            completed: 1,
            ordered: parseInt(obj?.ordered) != null ? obj.ordered : -1,
            setDate: obj?.setDate ? obj.setDate : 'unset',
            setTime: obj?.setTime ? obj.setTime : '',
            setBy: obj?.setBy ? obj.setBy : 'unset',
            cost: 0,
            originalQty: obj?.originalQty ? obj.originalQty : 0,
            quantity: obj?.quantity ? obj.quantity : 0,
            order: obj.order
          },
        }).then((response) => response);
      });

      return Promise.all([...allRequests]);
    }
    fetchData().then(() => {
      // notification.open({
      //   message: 'Successfully cleared station!',
      // });
      let updatedPristineIngs = cloneDeep(pristineIngs).map((item) => {
        item.healthCheck = 1;
        item.completed = 1;
        item.ordered = parseInt(item?.ordered) != null ? item.ordered : -1;
        item.shoplistId = parseInt(item?.shoplistId) != null ? item.shoplistId : 0;
        item.setDate = item?.setDate ? item.setDate : 'unset';
        item.day = item?.setDate && item.setDate !== 'unset' ? moment(item.setDate).format('dddd') : 'Invalid date';
        item.setTime = item?.setTime ? item.setTime : 'unset';
        item.setBy = item?.setBy ? item.setBy : 'unset';
        return item;
      });
      setPristineIngs(updatedPristineIngs);
      setTimeout(() => {
        updateIngredientState(true);
      }, 1000);
    });
  };

  const setActiveDay = (obj) => {
    let today = moment().format('YYYY-MM-DD');
    let todayTime = moment().format('LT');
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: obj.completed,
      ordered: obj.ordered,
      setDate: today,
      setTime: todayTime,
      setBy: userInfo.firstName + ' ' + userInfo.lastName,
      quantity: obj.ingredientQty,
      cost: 0,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (isEvent) {
      params.eventId = stationId;
    } else {
      params.stationId = stationId;
    }
    
    let clonedStationIngs = cloneDeep(tableData);
    const recIndex = findIndex(clonedStationIngs, (rec) => rec.name === obj.categoryName);
    const ingIndex = findIndex(clonedStationIngs[recIndex]?.children, (ing) => ing.ingredientId === obj.ingredientId);
    if (clonedStationIngs[recIndex]) {
      clonedStationIngs[recIndex].children[ingIndex].day = moment(today).format('dddd');
      clonedStationIngs[recIndex].children[ingIndex].setBy = userInfo.firstName + ' ' + userInfo.lastName;
      clonedStationIngs[recIndex].children[ingIndex].setDate = today;
      clonedStationIngs[recIndex].children[ingIndex].setTime = todayTime;
    }

    updateStationIngredient(params, obj, [
      { key: 'day', value: moment(today).format('dddd') },
      { key: 'setDate', value: today },
      { key: 'setTime', value: todayTime },
      { key: 'setBy', value: `${userInfo.firstName} ${userInfo.lastName}` },
    ]);
  };

  const unsetDay = (obj) => {
    let prepIng = {
      healthCheck: obj.healthCheck,
      completed: obj.completed,
      ordered: obj.ordered,
      setDate: '',
      setTime: '',
      setBy: '',
      quantity: obj.ingredientQty,
      cost: 0,
      order: obj.ingredientOrder,
      originalQty: obj.ingredientQty,
    };
    let params = {
      menuId: obj.menuId,
      recipeId: obj.recipeId,
      ingredientId: obj.ingredientId,
      preparationId: obj.preparationId,
      measurementId: obj.measurementId,
      info: prepIng,
    };

    if (!isEvent) {
      params.eventId = stationId;
    }

    if (isEvent) {
      params.stationId = stationId;
    }

    updateStationIngredient(params, obj, [
      { key: 'day', value: 'Invalid date' },
      { key: 'setDate', value: '' },
      { key: 'setTime', value: '' },
      { key: 'setBy', value: '' },
    ]);
  };

  const setCompleteTask = (obj, isComplete) => {
    const id = obj.key;
    const clonedChecklist = cloneDeep(selectedList);
    const foundIndex = findIndex(clonedChecklist, { key: obj.parentId });
    const foundChildIndex = findIndex(clonedChecklist[foundIndex]?.children, { key: obj.key });
    const checkItem = clonedChecklist[foundIndex]?.children[foundChildIndex];

    const iteminfo = {
      checked: !checkItem.checked,
      complete: checkItem.complete !== 2 ? 2 : 1,
      description: checkItem.description,
      details: checkItem.details,
      tags: checkItem.tags,
    };

    updateItem(id, { info: iteminfo }).then((res) => {
      localStorage.removeItem('openRecipePanelStations');
      // notification.open({
      //   message: 'Successfully updated task!',
      // });
      if (setHasUpdate) {
        setHasUpdate(true);
        eventSocket.emit(
          'updateStationTask',
          JSON.stringify({
            id: id,
          })
        );
      } else {
        setTimeout(() => {
          updateTaskState({ type: 'general', id: id });
        }, 1000);
      }
    });
  };

  const handleShowRecipeLists = (bool) => {
    setShowRecipeLists(bool);
  };

  const handleShowChecklists = (bool) => {
    console.log('handle change the checklist status', bool);
    setShowChecklists(bool);
  };

  const handleSetCurrentChecklist = useCallback((data) => {
    setCurrentChecklist(data);
  });

  return (
    <div>
      <div className="station-filters-mobile">
        {showChecklists ? (
          <div className="station-recipe-filter-mobile" onClick={showDrawer}>
            <span className="station-recipe-filter-name-mobile">All checklists</span>
            <span className="station-icon-rec">
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
          </div>
        ) : (
          <div className="station-recipe-filter-mobile" onClick={showDrawer}>
            <span className="station-recipe-filter-name-mobile">All recipes</span>
            <span className="station-icon-rec">
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
          </div>
        )}
        {showChecklists ? (
          <div style={{ display: 'flex' }}>
            <div className="station-all-ingMobile">
              <div>{menuName ? menuName : 'All items'}</div>
            </div>
            <Dropdown overlay={menu1}>
              <div className="station-dropdown-icon-mobile">
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
              </div>
            </Dropdown>
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <div className="station-all-ingMobile">
              <div>{menuName ? menuName : 'All ingredients'}</div>
            </div>
            <Dropdown overlay={menu}>
              <div className="station-dropdown-icon-mobile">
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
              </div>
            </Dropdown>
          </div>
        )}
      </div>
      <div className="station-controls-box-mob">
        {/* <div className="station-collapse-mobile" onClick={collapseAll}>
          + <span className="station-collapse-text">Collapse all</span>
        </div> */}
        {showChecklists ? (
          ''
        ) : (
          <div style={{ display: 'flex' }}>
            <RoleAccessibleComponent
              permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
            >
              <div className="station-clear-mobile" onClick={clearStation}>
                Clear Station
              </div>
            </RoleAccessibleComponent>
            <div className="station-condensed">
              <span className="station-condensedText">Condensed view </span>{' '}
              <Switch checked={!visible} onChange={onChange} />
            </div>
          </div>
        )}
      </div>
      {showChecklists ? (
        <div className="station-tableMobile">
          <Table
            columns={columnChecklist}
            dataSource={selectedList}
            rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
            pagination={false}
            onExpand={(expanded, record) => handleRowExpand(record)}
            expandedRowKeys={expandedRows}
          />
        </div>
      ) : (
        <div className="station-tableMobile">
          <Table
            columns={columns}
            dataSource={tableData}
            rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
            pagination={false}
            onExpand={(expanded, record) => handleRowExpand(record)}
            expandedRowKeys={expandedRows}
            rowKey={'rowKey'}
          />
        </div>
      )}

      <Drawer
        width={windowSize >= 842 ? '842px' : '100%'}
        closable={false}
        onClose={onClose}
        visible={showDrawerData}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <div style={{ marginRight: 20, marginTop: 20 }}>
          <Row gutter={32}>
            <Col className="gutter-row" span={21}></Col>
            <Col className="gutter-row" span={3}>
              <Button
                className="close-add-event"
                onClick={() => {
                  updateShowDrawer(false);
                }}
                type="primary"
                shape="circle"
                size={`small`}
              >
                <CloseCircleOutlined />
              </Button>
            </Col>
          </Row>
        </div>

        <div>
          <StationChecklistsMobile
            updateFilter={updateFilter}
            updateActiveChks={updateActiveChks}
            showChecklists={showChecklists}
            handleShowChecklists={handleShowChecklists}
            handleShowRecipeLists={handleShowRecipeLists}
            selectedStationChecklists={selectedStationChecklists}
            stationId={stationId}
            hasChecklist={hasChecklist}
            showDrawer={handleShowDrawer}
            handleSetCurrentChecklist={handleSetCurrentChecklist}
          />
        </div>
        <div>
          <StationRecipesMobile
            updateFilter={updateFilter}
            recipesOnly={recipesOnly}
            updateActiveRecs={updateActiveRecs}
            recList={recList}
            showDrawer={handleShowDrawer}
            showRecipeLists={showRecipeLists}
            handleShowChecklists={handleShowChecklists}
            handleShowRecipeLists={handleShowRecipeLists}
            stationId={stationId}
            pristineIngs={pristineIngs}
            isEvent={isEvent}
            hasRecipeLists={hasRecipeLists}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default StationGeneralMobile;
