import React from 'react';
import { nutritionDetails } from '../../data/recipedetails';

const ExpandedPanel = (props) => {
  return (
    <div>
      {nutritionDetails.map((detail, i) => {
        return (
          <div className="view-vitamin">
            <div>
              {detail.name} <span>{detail.mg}</span>
            </div>
            <div className="total-fat-percent">{detail.percent}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ExpandedPanel;
