import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import NutritionViewPopupMobile from './NutritionViewPopMobile';
import NutritionControls from './NutritionControls';
import IngredientsListMobile from './../../components/IngredientsList/IngredientsListMobile';
// import IngredientTable from './IngredientTable';
// import AddIngredientPopup from './AddIngredientPopup';
import EquipmentListMobile from './../../components/EquipmentList/EquipmentListMobile';
import InstructionsListMobile from './../../components/InstructionsList/InstructionsListMobile';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import EquipmentTable from './EquipmentTable';
import AddEquipment from './AddEquipment';
import more from '../../img/recipe-more.png';
import edit from '../../img/recipe-edit.png';
import add2 from '../../img/calcPlus.png';
import less from '../../img/calcMinus.png';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { recipeDetails } from '../../data/recipedetails';
import Tabs from '../../components/Tabs/tabs';
import recipe from '../../img/view-recipe-img.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Modal, Dropdown, Menu, Popconfirm, message, notification, Tag, Radio, Slider, InputNumber } from 'antd';
import { isEqual, cloneDeep, find, remove, findIndex, isEmpty, sortedLastIndexOf, clone } from 'lodash';
import ReactToPrint from 'react-to-print';
import { searchmeas } from '../../services/measurement.service';
import {
  get,
  update,
  ingredients,
  destroy,
  details,
  equipment,
  unassignEquipment,
  assignEquipment,
  unassignDetail,
  editEquipment,
  editDetail,
} from '../../services/recipe.service';
import { recipes, updateCourseRecipe } from '../../services/course.service';
import AddFile from './AddFile';
import Measurement from './measurements.js';
import fc from 'fraction-calculator';
import { pluralize } from 'inflected';
import { formatIfNeeded, roundOff, arrangeDBMeasurementUnits } from './utilities';
import { BacktoHeaderMobile } from '../../components/Header/HeadMobile.js';
import PrintRecipe from './PrintRecipe';
import {
  PrepTimeIcon,
  CookTimeIcon,
  YieldIcon,
  PortionIcon,
  ServingIcon,
  MasterRecipeIcon,
} from '../../components/common/common_ui';

/* this implements viewing a recipe from a menu course of a menu on mobile */

const ViewMenuRecipeMobile = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addTags, setAddTags] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false); // BUG 1201998443633170
  const [title, setTitle] = useState('');
  const [description, setDesc] = useState('');
  const [viewRecipe, setViewRecipe] = useState({});
  const [prepInfo, setPrepInfo] = useState({});
  const [prepHr, setPrepHr] = useState(0);
  const [prepMn, setPrepMn] = useState(0);
  const [cookHr, setCookHr] = useState(0);
  const [cookMn, setCookMn] = useState(0);
  const [tags, setTags] = useState([]);
  const [tagString, setTagString] = useState([]);
  const [intComment, setComment] = useState('');
  const [portion, setPortion] = useState('');
  const [yld, setYield] = useState('');
  const [instructions, setInstructions] = useState([]);
  const [quantity, setQty] = useState('');
  const [recipeEquipment, setRecipeEquipment] = useState([]);
  const [addEquipmentList, setAddEquipmentList] = useState([]);
  const [recipeIngredients, setRecipeIngredients] = useState([]);
  const [incrementAmt, setIncrementAmt] = useState(0);
  const [restaurant, setRestaurant] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const [showIns, setShowIns] = useState(false);
  const [enteredText, setEnteredText] = useState('');
  const [addIngredientsList, setAddIngredientsList] = useState([]);
  const [portionValue, setPortionValue] = useState('scale');
  const [inputValue, setInputValue] = useState(1);
  const [qtyCombine, setQtyCombine] = useState(false);
  const [currentRecipe, setCurrentRecipe] = useState({});
  const [originalRecipe, setOriginalRecipe] = useState({});
  const [hasChanged, setHasChanged] = useState(false);
  const [originalMultiplier, setOriginalMulitplier] = useState(1);
  const [showReset, setShowReset] = useState(false);
  const [measurements, setMeasurements] = useState([]);

  const [prepTime, setPrepTime] = useState(0);
  const [cookTime, setCookTime] = useState(0);
  const [prepTimeUnit, setPrepTimeUnit] = useState('hours');
  const [cookTimeUnit, setCookTimeUnit] = useState('hours');
  const [showPrepTime, setShowPrepTime] = useState('');
  const [showCookTime, setShowCookTime] = useState('');
  const [motherRecipeIngredients, setMotherRecipeIngredients] = useState([]);

  const componentRef = useRef();

  const history = useHistory();

  let courseid = props.match.params.courseid;
  let menuid = props.match.params.menuid;
  let recipeid = props.match.params.id;

  const reformatVal = (val, measUnit, inputScale = undefined, toScale = false) => {
    if (measUnit && typeof measUnit === 'string' && measUnit !== 'Select' && toScale) {
      const { id: measure_id, equiv: equiv_amt } = measurements.find((item) => {
        return item.name === measUnit || measUnit.includes(item.name);
      });

      let reformattedVal,
        value = val,
        changedUnit = measUnit ? measUnit : 'Select';

      if (equiv_amt) {
        const showMeasure = new Measurement(val, measure_id, props.match.params.id, measurements);
        const scaledMeasure = showMeasure.scale(inputScale && toScale ? inputScale : 1).reduce();
        reformattedVal = scaledMeasure.quantity_ui_string();
        changedUnit = measurements.find((item) => scaledMeasure.measure_id === item.id).name;
        value = scaledMeasure.quantity;
      } else {
        if (inputScale != 0 && toScale) {
          value *= inputScale;
          reformattedVal = value;
        } else {
          reformattedVal = val;
        }
      }

      return {
        reformattedVal: formatIfNeeded(reformattedVal), // format into desired fractional string
        value: roundOff(value), // round off to 2 decimal places
        changedUnit,
      };
    } else {
      return {
        reformattedVal: val ? formatIfNeeded(val) : '0',
        value: val ? roundOff(val) : 0,
        changedUnit: measUnit ? measUnit : 'Select',
      };
    }
  };

  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }, []);

  useEffect(() => {
    let accountId = localStorage.getItem('operator') != undefined && 
      JSON.parse(localStorage.getItem('operator')).accountId != null ? 
      JSON.parse(localStorage.getItem('operator')).accountId : '';
    searchmeas({ accountId: accountId }).then((res) => {
      setMeasurements(arrangeDBMeasurementUnits(res));
    });
  }, []);

  useEffect(() => {
    if (measurements.length > 0) {
      getCourse();
      getEquipment();
      getDetails();
      // getRecipe();
    }
  }, [measurements]);

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    setRestaurant(resto.name);
  }, []);

  useEffect(() => {
    if (portionValue !== 'scale') {
      setInputValue(currentRecipe.info?.quantity);
    }
  }, [portionValue]);

  const getCourse = () => {
    recipes(props.match.params.courseid).then((res) => {
      let multiplier = 1;
      //get Recipe by ID
      let courseRecs = res;
      let getRecipe = courseRecs.CourseRecipes.filter((item) => item.recipeId === parseInt(props.match.params.id));
      // console.log('THE RECIPE', getRecipe);
      setCurrentRecipe(getRecipe[0].Recipe);
      setOriginalRecipe(getRecipe[0].Recipe);
      let currentRec1 = getRecipe[0].Recipe;
      if (getRecipe[0].Recipe.info && getRecipe[0].Recipe.info?.multiplier) {
        multiplier = getRecipe[0].Recipe.info?.multiplier;
      } else {
        multiplier = 1;
      }

      //get measurement info
      // making sure the measurement values are properly reformatted
      // 1. recipe yield
      let theYieldDisplay = currentRec1.info?.yield
        ? currentRec1.info?.yieldVal &&
          measurements.length > 0 &&
          measurements.map((d) => d.name).includes(currentRec1.info?.yieldVal)
          ? reformatVal(currentRec1.info?.yield, currentRec1.info?.yieldVal, multiplier, true).reformattedVal
          : formatIfNeeded(currentRec1.info?.yield)
        : '0';
      // 2. recipe portion
      let thePortionDisplay = currentRec1.info?.portion
        ? currentRec1.info?.portionVal &&
          measurements.length > 0 &&
          measurements.map((d) => d.name).includes(currentRec1.info?.portionVal)
          ? reformatVal(currentRec1.info?.portion, currentRec1.info?.portionVal, multiplier, true).reformattedVal
          : formatIfNeeded(currentRec1.info?.portion)
        : '0';
      // 3. recipe quantity
      let theQuantityDisplay = currentRec1.info?.quantity
        ? currentRec1.info?.qtyVal
          ? reformatVal(currentRec1.info?.quantity, 'Each', multiplier, true).reformattedVal
          : formatIfNeeded(currentRec1.info?.quantity)
        : '0';

      // Preparing the yield, portion and quantity values in View Mode
      currentRec1.info.yieldDisplay = theYieldDisplay;
      currentRec1.info.portionDisplay = thePortionDisplay;
      currentRec1.info.quantityDisplay = theQuantityDisplay;

      currentRec1.info.yield = currentRec1.info?.yield * multiplier;
      currentRec1.info.quantity = currentRec1.info?.quantity * multiplier;

      setPrepInfo(currentRec1.info);

      const recipeImages = currentRec1.info?.recipeImages
        ? [...currentRec1.info.recipeImages]
        : currentRec1.info?.recipeImageThumbs
        ? [...currentRec1.info.recipeImageThumbs]
        : [];

      setImageUrls(recipeImages);

      //prepare info for preparation and cook minutes
      const _prepTime = currentRec1.info?.prepMins ? parseInt(currentRec1.info.prepMins) : 0;
      const _cookTime = currentRec1.info?.cookMins ? parseInt(currentRec1.info.cookMins) : 0;
      if (_prepTime >= 60) {
        let initPrepTime = Math.floor(_prepTime / 60);
        setPrepHr(initPrepTime);
        setShowPrepTime(initPrepTime.toString());
        setPrepTimeUnit('hours');
      } else {
        setPrepMn(_prepTime);
        setShowPrepTime(_prepTime.toString());
        setPrepTimeUnit('minutes');
      }
      if (_cookTime >= 60) {
        let initCookTime = Math.floor(_cookTime / 60);
        setCookHr(initCookTime);
        setShowCookTime(initCookTime.toString());
        setCookTimeUnit('hours');
      } else {
        setCookMn(_cookTime);
        setShowCookTime(_cookTime.toString());
        setCookTimeUnit('minutes');
      }
      setPrepTime(_prepTime);
      setCookTime(_cookTime);

      //get Ingredients
      console.log('SAVED INGREDIENTS', currentRec1.info.ingredients);
      let ingList = currentRec1?.info?.ingredients?.map((i) => {
        // mitigate errors in stored measurement unit values
        let ingMeasurement;

        if (i.measurementName) {
          ingMeasurement = i.measurementName;
        } else {
          ingMeasurement = i.measurement;
        }

        // // computes real floating value and reformatted string value of ingredient quantity value
        // const { reformattedVal, value, changedUnit } = reformatVal(i.quantity, ingMeasurement, multiplier, true);

        // i.quantity = value;
        // i.displayQuantity = reformattedVal;
        // i.measurementName = changedUnit;
        // i.measurement = changedUnit;

        return i;
      });
      setRecipeIngredients(ingList || []);
      //get Instructions
      setInstructions(currentRec1.info?.instructions || []);
      setShowReset(false);
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const tabs = [
    { title: 'Ingredients' },
    { title: 'Equipment' },
    { title: 'Instructions' },
    // { title: 'Nutrition Facts' },
    // { title: 'More' },
  ];

  const getEquipment = () => {
    equipment(props.match.params.id).then((res) => {
      console.log('EQTABLE', res);
      let recEq = res;
      let parse = recEq.RecipeEquipments.map((e) => {
        e.prepped = e.info;
        return e;
      });
      console.log(parse);
      if (parse.length > 0 && parse.info) {
        let parsed = parse.map((p) => {
          p.id = p.equipmentId;
          p.prepped.name = p.Equipment.name;
          p.prepped.info = p.Equipment.info;
          return p;
        });
        let getEquipment = parsed.map((q) => {
          return q.prepped;
        });
        console.log(getEquipment);
        setRecipeEquipment(getEquipment);
        setAddEquipmentList(getEquipment);
      } else {
        let parsed1 = parse.map((p) => {
          p.id = p.equipmentId;
          p.name = p.Equipment.name;
          p.info = p.Equipment.info;
          return p;
        });
        setRecipeEquipment(parsed1);
        setAddEquipmentList(parsed1);
      }
    });
  };

  const getDetails = () => {
    details(props.match.params.id).then((res) => {
      let recIng = res;
      console.log('INGTABLE', res);
      let parse = recIng.RecipeDetails.map((e) => {
        e.prepped = e.info;
        return e;
      });
      let parsed = parse.map((p) => {
        p.prepped.id = p.ingredientId;
        p.prepped.name = p.Ingredient.name;
        p.prepped.measurementId = p.measurementId;
        p.prepped.measurement = p.Measurement === null ? '' : p.Measurement.name;
        p.prepped.preparationId = p.preparationId;
        p.prepped.preparation = p.Preparation === null ? '' : p.Preparation.name;
        p.prepped.station = p.Station === null ? '' : p.Station.name;
        p.prepped.stationId = p.stationId;
        p.prepped.info = p.Ingredient.info;
        p.prepped.isMaster = p.Ingredient.createdBy === 'system_ingest';
        return p;
      });
      let getIngredients = parsed.map((q) => {
        return q.prepped;
      });

      let sortedIngs = getIngredients?.sort((x, y) => {
        return x.order - y.order || x.name.localeCompare(y.name);
      });

      console.log(sortedIngs);
      setMotherRecipeIngredients(sortedIngs);
      setMotherRecipeIngredients(sortedIngs);
    });
  };

  const setPortions = (e) => {
    console.log('radio checked', e.target.value);
    setPortionValue(e.target.value);
  };

  const changeScaleVal = (e) => {
    setInputValue(e);
  };

  const increaseVal = () => {
    let increasing = inputValue + 1;
    setInputValue(increasing);
  };

  const decreaseVal = () => {
    let increasing = inputValue - 1;
    setInputValue(increasing);
  };

  const resetMultiplier = () => {
    let restoreList = cloneDeep(originalRecipe);
    restoreList.info.multiplier = originalMultiplier;

    updateCourseRecipe(courseid, recipeid, { info: restoreList }).then((res) => {
      // notification.open({
      //   message: 'Successfully restored recipe prior changes!',
      // });
      setTimeout(() => {
        getCourse();
        setShowReset(false);
        setInputValue(originalMultiplier);
      }, 2000);
    });
  };

  const saveModifications = (newScaleFactor) => {
    setHasChanged(false);
    let clonedList = cloneDeep(currentRecipe);
    clonedList.info.ingredients = recipeIngredients;
    clonedList.info.multiplier = newScaleFactor;

    updateCourseRecipe(courseid, recipeid, { info: clonedList }).then((res) => {
      // notification.open({
      //   message: 'Successfully updated recipe!',
      // });
      setTimeout(() => {
        // window.location.reload();
        setShowReset(true);
      }, 2000);
    });
  };

  const calculateScale = () => {
    let scale_factor = 1;

    const cloneRec = cloneDeep(currentRecipe);

    if (portionValue != 'scale') {
      const target_portions = inputValue;
      scale_factor = cloneRec.info?.quantity ? (1.0 * target_portions) / cloneRec.info?.quantity : target_portions;
    } else {
      scale_factor = inputValue;
    }

    // new values to assign
    let theYieldDisplay, theNewYield, theYieldMeasUnit;
    let theQuantityDisplay, theNewQuantity, theQuantityMeasUnit;

    const yieldRes = reformatVal(cloneRec.info?.yield, cloneRec.info?.yieldVal, scale_factor, true);

    const qtyRes = reformatVal(cloneRec.info?.quantity, 'Each', scale_factor, true);

    theYieldDisplay = yieldRes.reformattedVal;
    theNewYield = yieldRes.value;
    theYieldMeasUnit = yieldRes.changedUnit;

    theQuantityDisplay = qtyRes.reformattedVal;
    theNewQuantity = qtyRes.value;
    theQuantityMeasUnit = qtyRes.changedUnit;

    cloneRec.info.yield = theNewYield;
    cloneRec.info.yieldVal = theYieldMeasUnit;
    cloneRec.info.yieldDisplay = theYieldDisplay;

    cloneRec.info.quantity = theNewQuantity;
    cloneRec.info.quantityVal = theQuantityMeasUnit;
    cloneRec.info.quantityDisplay = theQuantityDisplay;

    setCurrentRecipe(cloneRec);
    setPrepInfo(cloneRec.info);
    const cloneList = cloneDeep(recipeIngredients);
    let changeListQty = cloneList.map((i) => {
      if (i.quantity > 0) {
        // mitigate errors in stored measurement unit values
        let ingMeasurement;

        if (i.measurementName) {
          ingMeasurement = i.measurementName;
        } else {
          ingMeasurement = i.measurement;
        }
        // computes real floating value and reformatted string value of ingredient quantity value
        const { reformattedVal, value, changedUnit } = reformatVal(i.quantity, ingMeasurement, scale_factor, true);

        let newMeasurementId = '';

        if (measurements.find((item) => item.name === changedUnit)) {
          newMeasurementId = measurements.find((item) => item.name === changedUnit).id;
        }

        i.quantity = value;
        i.displayQuantity = reformattedVal;
        i.measurementName = changedUnit;
        i.measurement = changedUnit;
        i.measurementId = newMeasurementId;
      }
      return i;
    });
    setRecipeIngredients(changeListQty);
    setInputValue(scale_factor);
    setHasChanged(true);
  };

  useEffect(() => {
    if (
      hasChanged &&
      isEqual(recipeIngredients, originalRecipe.info?.ingredients) == 0 &&
      isEqual(currentRecipe, originalRecipe) == 0
    ) {
      saveModifications(inputValue);
    }
  }, [recipeIngredients, currentRecipe, hasChanged]);

  const getBackLink = () => {
    if (props.location.state && props.location.state.from == 'menu-details') {
      return `/menu/${props.location.state.menuId}`;
    } else {
      return `/menus`;
    }
  };

  return (
    <div className="marginT10 windowHFull">
      <BacktoHeaderMobile
        restaurant={restaurant}
        backText={
          'Back to ' +
          (props.location.state &&
            props.location.state.from &&
            (() => {
              switch (props.location.state.from) {
                case 'menu-details':
                  return 'menu details';
                default:
                  return 'menus';
              }
            })())
        }
        backLink={() => getBackLink()}
      />
      {/* <BacktoHeaderMobile
        restaurant={restaurant}
        backText="Back to course view"
        backLink={`/menu/${props.match.params.menuid}`}
      /> */}
      <div className="Line"></div>
      <div className="view-recipe-control-mobile">
        <Link
          to={{
            pathname: `/recipes/view/${props.match.params.id}`,
            state: { from: 'menurecipes', courseid: courseid, menuid: menuid, recipeid: recipeid, setedit: true },
          }}
        >
          <div style={{ marginBottom: 10, cursor: 'pointer' }}> Edit</div>
        </Link>
        <RoleAccessibleComponent
          permittedRoles={[
            'Chef',
            'Admin',
            'Superadmin',
            'Client Admin',
            'Dev Team',
            'Account Holder',
            'Sous Chef',
            'Cook',
            'Trainer / Manager',
          ]}
        >
          {/* BUG 1201998443633170 */}
          <ReactToPrint
            trigger={() => (
              <div
                style={{ cursor: 'pointer' }}
                onMouseDown={() => {
                  // BUG 1201998443633170 NEW
                  if (!isPrinting) setIsPrinting(true);
                }}
                onMouseLeave={() => {
                  if (isPrinting) setIsPrinting(false);
                }}
              >
                Print
              </div>
            )}
            content={() => componentRef.current}
            onAfterPrint={() => setIsPrinting(false)} // BUG 1201998443633170
            documentTitle={`${title}: Recipe Details`} // BUG 1201998443633170
            onPrintError={() => setIsPrinting(false)} // BUG 1201998443633170
            pageStyle={`
								@view-recipe-column-container {
									color: black !important;
									background-color: white;
								}
								@view-recipe-title {
									color: black !important;
								}
								@view-recipe-description {
									color: black !important;
								}
								@view-recipe-items-table td {
									color: black !important;
								}
								@view-recipe-items-table th {
									color: black !important;
								}
								@view-prep-details-container {
									color: black !important;
								}
								@view-recipe-metadata-container {
									color: black !important;
								}
							`} // BUG 1201998443633170
          />
        </RoleAccessibleComponent>
      </div>
      <div className="Line-recipe-mobile"></div>
      <div className="recipe-tabs">
        <Tabs>
          <div className="tabs-title" label={tabs[0].title}>
            <div className="view-recipe-container-mobile" ref={componentRef}>
              <div className="view-recipe-title-mobile">
                <div className="view-recipe-name">
                  {' '}
                  <div className="view-recipe-name">{currentRecipe.name}</div>
                </div>
                <div class="view-recipe-star-mobile">
                  <MasterRecipeIcon />
                </div>
              </div>
              <div>
                <div className="view-recipe-desc-mobile">{prepInfo.description}</div>
              </div>
              <div className="recipe-carousel-mobile" onClick={showModal}>
                <Carousel>
                  {imageUrls.map((image) => {
                    return (
                      <div>
                        <img src={image} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>

              <div>
                <div className="view-recipe-tags-mobile">
                  {(tags || []).map((tag, i) => {
                    return <div className="tag">{tag}</div>;
                  })}
                </div>
              </div>
              <div>
                <div className="view-prep-container">
                  <PrepTimeIcon />
                  Prep:
                  <span className="view-prep-details-container-cook">
                    {prepTime === 0 ? (
                      <span className="view-prep-details-measure">To be Estimated</span>
                    ) : prepTime >= 60 ? (
                      <span className="view-prep-details-measure">
                        {showPrepTime + ' ' + (prepHr > 1 ? pluralize('hour') : ' hour')}
                      </span>
                    ) : (
                      <span className="view-prep-details-measure">
                        {showPrepTime + ' ' + (prepMn > 1 ? pluralize('min') : ' min')}
                      </span>
                    )}
                  </span>
                </div>
              </div>

              <div className="view-prep-container">
                <CookTimeIcon />
                Cook:
                <span className="view-prep-details-container-cook">
                  {cookTime === 0 ? (
                    <span className="view-prep-details-measure">To be Estimated</span>
                  ) : cookTime >= 60 ? (
                    <span className="view-prep-details-measure">
                      {showCookTime + ' ' + (cookHr > 1 ? pluralize('hour') : ' hour')}
                    </span>
                  ) : (
                    <span className="view-prep-details-measure">
                      {showCookTime + '  ' + (cookMn > 1 ? pluralize('min') : ' min')}
                    </span>
                  )}
                </span>
              </div>

              <div className="view-prep-container">
                <YieldIcon />
                Yield:
                <span className="view-prep-details-container-mobile">
                  <span className="view-prep-details-measure">{prepInfo.yieldDisplay}</span>
                  <span className="view-prep-details-measure">
                    {prepInfo.yieldVal &&
                    prepInfo.yieldVal !== 'Select' &&
                    measurements.length > 0 &&
                    measurements.map((d) => d.name).includes(prepInfo.yieldVal)
                      ? prepInfo.yieldVal
                      : ' '}
                  </span>
                </span>
              </div>

              <div className="view-prep-container">
                <PortionIcon />
                Portion:
                <span className="view-prep-details-container-portion">
                  <span className="view-prep-details-measure">{prepInfo.portionDisplay}</span>
                  <span className="view-prep-details-measure">
                    {prepInfo.portionVal &&
                    prepInfo.portionVal !== 'Select' &&
                    measurements.length > 0 &&
                    measurements.map((d) => d.name).includes(prepInfo.portionVal)
                      ? prepInfo.portionVal
                      : ' '}
                  </span>
                </span>
              </div>

              <div className="view-prep-container">
                <ServingIcon />
                Quantity:
                <span className="view-prep-details-container-qty">
                  <span className="view-prep-details-measure">{prepInfo.quantityDisplay}</span>
                  <span className="view-prep-details-measure">{prepInfo.quantity ? 'portions' : ''}</span>
                </span>
              </div>

              <br />

              <div>
                <div className="recipe-titleMob1">
                  <span>Adjust recipe</span>{' '}
                  {showReset && (
                    <span onClick={resetMultiplier} className="reset-multiplier">
                      Reset
                    </span>
                  )}
                </div>

                <div className="adj-rec-box-mob1">
                  <div>Convert by</div>
                  <div>
                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                      <Radio.Group onChange={setPortions} value={portionValue}>
                        <Radio.Button value={'scale'}>Scale</Radio.Button>
                        <Radio.Button value={'portions'}>Portions</Radio.Button>
                      </Radio.Group>
                    </div>
                    <div style={{ marginTop: 30, textAlign: 'center' }}>
                      {' '}
                      <InputNumber
                        min={0}
                        max={1000}
                        style={{ margin: '0 90px', color: 'white', background: '#454545', border: 'none' }}
                        value={inputValue}
                        onChange={changeScaleVal}
                      />
                    </div>
                    <div>
                      <div onClick={decreaseVal} className="calc-rec-lessMob">
                        <img src={less} height="28" />
                      </div>
                      <div style={{ margin: '30px auto', width: '65%' }}>
                        <Slider
                          min={1}
                          max={200}
                          onChange={changeScaleVal}
                          value={typeof inputValue === 'number' ? inputValue : 0}
                        />
                      </div>
                      <div onClick={increaseVal} className="calc-rec-addMob">
                        <img src={add2} height="28" />
                      </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <button className="calc-btn-recipeMob" onClick={calculateScale}>
                        Calculate
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <IngredientsListMobile ingredientList={recipeIngredients} />
            </div>
          </div>
          <div className="tabs-title" label={tabs[1].title}>
            <div className="view-recipe-container-mobile">
              <div>
                {' '}
                <EquipmentListMobile equipmentList={recipeEquipment} />
              </div>
            </div>
          </div>
          <div className="tabs-title" label={tabs[2].title}>
            <div className="view-recipe-container-mobile">
              <InstructionsListMobile instructionlist={instructions} />
            </div>
          </div>
          {/* <div className="tabs-title" label={tabs[3].title}>
            <div className="view-recipe-container-mobile">
              <NutritionViewPopupMobile nutritionList={recipeDetails.nutrition} />
            </div>
          </div>
          <div className="tabs-title" label={tabs[4].title}>
            <div className="view-recipe-container-mobile">
              <NutritionControls nutritionList={recipeDetails.nutrition} />
            </div>
          </div> */}
        </Tabs>
      </div>
      <PrintRecipe
        ref={componentRef}
        isPrinting={isPrinting}
        name={currentRecipe.name}
        description={prepInfo.description}
        time={{
          preparation: prepInfo?.prepMins ? prepInfo.prepMins : 0,
          cooking: prepInfo?.cookMins ? prepInfo.cookMins : 0,
        }}
        scaling={{
          yield: {
            value: prepInfo.yieldDisplay,
            unit: prepInfo.yieldVal,
          },
          portion: {
            value: prepInfo.portionDisplay,
            unit: prepInfo.portionVal,
          },
          quantity: prepInfo.quantityDisplay,
          measurements: measurements.map((d) => d.name),
        }}
        ingredients={recipeIngredients}
        equipments={recipeEquipment}
        instructions={instructions}
        coverimage={imageUrls.length > 0 ? imageUrls[0] : null}
      />
      <Modal title={viewRecipe.name} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div className="recipe-carousel-mobile">
          <Carousel>
            {imageUrls.map((image) => {
              return (
                <div>
                  <img src={image} />
                </div>
              );
            })}
          </Carousel>
        </div>
      </Modal>
    </div>
  );
};

export default ViewMenuRecipeMobile;
