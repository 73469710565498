import React, { useState, useEffect, useRef } from 'react';
import approve from '../../img/approve-ck.png';
import comment from '../../img/comment.png';
// import attach from '../../img/attach.png';

import { Collapse, notification, Dropdown, Menu } from 'antd';
import moment from 'moment';
import { getLinks } from '../../services/doc.service';
// import { faCommentSlash } from '@fortawesome/free-solid-svg-icons';
import { getKitchenStations } from '../../services/station.service';
import { searchEvents } from '../../services/event.service';
import { search } from '../../services/recipe.service';
import { getCheckList } from '../../services/checklist.service';
import { searchMenu } from '../../services/menu.service';
import { deleteLink, updateDoc, createDocLinks, deleteDocument } from '../../services/doc.service';
// import { CloseCircleOutlined, ConsoleSqlOutlined, ItalicOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { docIcon, docThumbnailSrc } from './DocUtils';
import { KITCHENSTATIONNAME } from '../../components/common/constant.js';
import { MoreIcon, UserImage, ThumbnailImage, CidekicButton } from '../../components/common/common_ui';

const { Panel } = Collapse;

const DocumentsDetailPopup = (props) => {
  const {
    data,
    closeDrawer,
    updateComments,
    handleDocumentId,
    handleFolderId,
    setUpdatedDocument,
    setDeletedDocument,
  } = props;
  const [editNote, setEditNote] = useState([]);
  const [comments, setComments] = useState([]);
  const [linked, setLinked] = useState([]);
  const [kitchenStations, setKitchenStations] = useState([]);
  const [eventStations, setEventStations] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [menus, setMenus] = useState([]);
  const [selectedKitchens, setSelectedKitchens] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [checkedEvents, setCheckedEvents] = useState([]);
  const [checkedRecipes, setCheckedRecipes] = useState([]);
  const [checkedMenus, setCheckedMenus] = useState([]);
  const [checkedTasks, setCheckedTasks] = useState([]);
  const [checkedKitchens, setCheckedKitchens] = useState([]);
  const [createdComment, setCreateComment] = useState('');
  const [allSelected, setAllSelected] = useState([]);
  const [expand, setExpand] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [title, setTitle] = useState('');

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  const user_id = (JSON.parse(localStorage.getItem('operator')) || {}).id;
  const user_email = (JSON.parse(localStorage.getItem('operator')) || {}).email;

  console.log(data);

  useEffect(() => {
    let params = {
      restaurantId: resto.id,
      startTimestamp: '315626789',
      endTimestamp: '64060679385',
      from: 0,
      limit: 1000,
    };

    getCheckList({ restaurantId: resto.id, user_id, email: user_email }).then((res) => {
      if (res) {
        let checklists = res.checklists;
        let items = checklists.map((c) => JSON.parse(c.ChecklistItems));
        let merged = [].concat.apply([], items);
        let taskOptions = merged.map((m) => {
          return {
            label: m.title,
            value: m.id,
          };
        });
        taskOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        setTasks(taskOptions);
        console.log(taskOptions);
      }
    });

    search({ restaurantId: resto.id, from: 0, limit: 10000 }).then((res) => {
      console.log(res);
      let recipes = res.recipes;
      let recipeOptions = recipes.map((k) => {
        return {
          label: k.name,
          value: k.id,
        };
      });
      recipeOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      setRecipes(recipeOptions);
    });

    searchEvents(params).then((res) => {
      let events = res;
      let eventOptions = events.map((k) => {
        return {
          label: k.name,
          value: k.id,
        };
      });

      eventOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      console.log(eventOptions);
      setEventStations(eventOptions);
    });

    searchMenu({ restaurantId: resto.id }).then((res) => {
      if (res && res.menus) {
        let menu = res.menus;
        let menuOptions = menu.map((k) => {
          return {
            label: k.name,
            value: k.id,
          };
        });

        menuOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        setMenus(menuOptions);
      }
    });

    getKitchenStations(resto.id).then((res) => {
      let kitchens = res.kitchens;
      let kitchenOptions = kitchens?.map((k) => {
        return {
          label: k.station,
          value: k.id,
        };
      });
      if (kitchenOptions) {
        kitchenOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      }
      setKitchenStations(kitchenOptions);
    });
  }, []);

  // useEffect(() => {
  //   setTitle(editNote);
  // }, [editNote]);

  useEffect(() => {
    setEditTitle(false);
    setComments(data.info?.comments);
    setEditNote(data.content);
    setTitle(data.category == 'Note' ? data.name : data.content);

    let params = {
      id: data.id,
    };
    getLinks(data.id, params).then((res) => {
      setLinked(res);
      let getType = res.map((r) => {
        return {
          linkId: r.linkId,
          category: r.type,
          type: r.Document.info?.type,
        };
      });

      let allSelect = res.map((r) => {
        return {
          name: r?.info?.title?.label,
          id: r.linkId,
          type: r.type,
        };
      });
      console.log(allSelect);
      setAllSelected(allSelect);
      console.log(getType);
      let events = getType.filter((e) => e.category == 'events').map((m) => m.linkId);
      setCheckedEvents(events.toString().split(','));
      let recipes = getType.filter((e) => e.category == 'recipes').map((m) => m.linkId);
      setCheckedRecipes(recipes);
      let menus = getType.filter((e) => e.category == 'menus').map((m) => m.linkId);
      setCheckedMenus(menus.toString().split(','));
      let tasks = getType.filter((e) => e.category == 'tasks').map((m) => m.linkId);
      console.log(tasks);
      setCheckedTasks(tasks);
      let kitchen = getType.filter((e) => e.category == 'kitchen').map((m) => m.linkId);
      setCheckedKitchens(kitchen.toString().split(','));
    });
  }, [data]);

  useEffect(() => {
    console.log('EXPAND', expand);
  }, [expand]);

  const getKitchen = (e) => {
    let name = kitchenStations.filter((t) => e.target.value == t.value);
    const value = { linkId: e.target.value, info: { title: name[0] }, type: 'kitchen' };
    const selectedVal = { name: name[0].label, id: parseInt(e.target.value) };
    if (e.target.checked) {
      setSelectedKitchens((oldArray) => [...oldArray, value]);
      setAllSelected((oldArray) => [...oldArray, selectedVal]);
    } else {
      //splice from array
      let filter = selectedKitchens.filter((item) => item.linkId !== parseInt(e.target.value));
      let filtered = allSelected.filter((item) => item.id !== parseInt(e.target.value));
      setSelectedKitchens(filter);
      setAllSelected(filtered);
      //call delete link API
      deleteLink(data.id, 'kitchen', e.target.value).then((res) => res);
    }
  };

  const getEvent = (e) => {
    let name = eventStations.filter((t) => e.target.value == t.value);
    const value = { linkId: e.target.value, info: { title: name[0] }, type: 'events' };
    const selectedVal = { name: name[0].label, id: parseInt(e.target.value) };
    if (e.target.checked) {
      setSelectedEvents((oldArray) => [...oldArray, value]);
      setAllSelected((oldArray) => [...oldArray, selectedVal]);
    } else {
      //splice from array
      let filter = selectedEvents.filter((item) => item.linkId !== parseInt(e.target.value));
      let filtered = allSelected.filter((item) => item.id !== parseInt(e.target.value));
      setSelectedEvents(filter);
      setAllSelected(filtered);
      //call delete link API
      deleteLink(data.id, 'events', e.target.value).then((res) => res);
    }
  };

  const getRecipes = (e) => {
    let name = recipes.filter((t) => e.target.value == t.value);
    const value = { linkId: e.target.value, info: { title: name[0] }, type: 'recipes' };
    const selectedVal = { name: name[0].label, id: parseInt(e.target.value) };
    if (e.target.checked) {
      setSelectedRecipes((oldArray) => [...oldArray, value]);
      setAllSelected((oldArray) => [...oldArray, selectedVal]);
    } else {
      //splice from array
      let filter = selectedRecipes.filter((item) => item.linkId !== parseInt(e.target.value));
      let filtered = allSelected.filter((item) => item.id !== parseInt(e.target.value));
      setSelectedRecipes(filter);
      setAllSelected(filtered);
      //call delete link API
      deleteLink(data.id, 'recipes', e.target.value).then((res) => res);
    }
  };

  const getMenus = (e) => {
    let name = menus.filter((t) => e.target.value == t.value);
    const value = { linkId: e.target.value, info: { title: name[0] }, type: 'menus' };
    const selectedVal = { name: name[0].label, id: parseInt(e.target.value) };
    if (e.target.checked) {
      setSelectedMenus((oldArray) => [...oldArray, value]);
      setAllSelected((oldArray) => [...oldArray, selectedVal]);
    } else {
      //splice from array
      let filter = selectedMenus.filter((item) => item.linkId !== parseInt(e.target.value));
      let filtered = allSelected.filter((item) => item.id !== parseInt(e.target.value));
      setSelectedMenus(filter);
      setAllSelected(filtered);
      //call delete link API
      deleteLink(data.id, 'menus', e.target.value).then((res) => res);
    }
  };

  const getTasks = (e) => {
    let name = tasks.filter((t) => e.target.value == t.value);
    const value = { linkId: parseInt(e.target.value), info: { title: name[0] }, type: 'tasks' };
    const selectedVal = { name: name[0].label, id: parseInt(e.target.value), type: 'tasks' };
    if (e.target.checked) {
      setSelectedTasks((oldArray) => [...oldArray, value]);
      setAllSelected((oldArray) => [...oldArray, selectedVal]);
      // checkedTasks.push(parseInt(e.target.value));
      // setCheckedTasks(checkedTasks);
    } else {
      //splice from array
      let filter = selectedTasks.filter((item) => item.linkId !== parseInt(e.target.value));
      let filtered = allSelected.filter((item) => item.id !== parseInt(e.target.value));
      setSelectedTasks(filter);
      setAllSelected(filtered);
      // let filters = checkedTasks.filter((item) => item !== parseInt(e.target.value));
      // setCheckedTasks(filters);
      //call delete link API
      deleteLink(data.id, 'tasks', e.target.value).then((res) => res);
    }
  };

  const deleteDoc = () => {
    console.log(data);
    if (handleDocumentId != undefined) {
      handleDocumentId(data.id.toString());
    }
    if (handleFolderId != undefined) {
      handleFolderId(data.folderId.toString());
    }
    deleteDocument(data.id).then((res) => {
      // notification.open({
      //   message: 'Document Successfully deleted!',
      // });
      if (setDeletedDocument != undefined) {
        setDeletedDocument(true);
      }
      closeDrawer(true);
      // hasChanged(true);
    });
  };

  const editDoc = () => {
    setEditTitle(true);
  };

  const saveTitle = () => {
    let allSelectedLinks = [].concat(selectedEvents, selectedKitchens, selectedRecipes, selectedTasks, selectedMenus);

    let noteInfo = {
      type: data?.category,
      // timestamp: new Date() * 1,
      updatedDate: new Date() * 1,
      createdDate: data.createdDate,
      createdBy: data?.assignee,
      collaborator: '',
      linked: allSelected.length,
      file: data?.info?.file ? data?.info?.file : '',
      title: title,
    };

    if (data?.info?.comments?.length > 0 && createdComment !== '') {
      noteInfo.comments = comments.concat({ text: createdComment, timestamp: new Date() * 1 });
    } else if (data?.info?.comments?.length == 0 && createdComment !== '') {
      let commentArr = [];
      commentArr.push({ text: createdComment, timestamp: new Date() * 1 });
      noteInfo.comments = commentArr;
    } else if (data?.info?.comments?.length > 0 && createdComment == '') {
      noteInfo.comments = data?.info?.comments;
    }

    let params = {
      folderId: data.folderId,
      title: data.category == 'Note' ? editNote : title,
      info: noteInfo,
    };

    console.log(params);
    console.log(allSelectedLinks);

    if (handleDocumentId != undefined) {
      handleDocumentId(data.id.toString());
    }
    if (handleFolderId != undefined) {
      handleFolderId(data.folderId.toString());
    }
    updateDoc(data.id, params).then((res) => {
      createDocLinks(data.id, allSelectedLinks).then((res) => {
        // notification.open({
        //   message: 'Document title Successfully updated!',
        // });
        if (setUpdatedDocument != undefined) {
          setUpdatedDocument({
            id: data.id,
            ...params,
          });
        }
        closeDrawer(true);
        // handleChange(true);
      });
    });
  };

  const saveChanges = () => {
    let allSelectedLinks = [].concat(selectedEvents, selectedKitchens, selectedRecipes, selectedTasks, selectedMenus);
    const operator = JSON.parse(localStorage.getItem('operator')) || {};
    console.log(allSelectedLinks);
    console.log(createdComment);
    console.log(allSelected);

    let noteInfo = {
      type: data?.category,
      // timestamp: new Date() * 1,
      updatedDate: new Date() * 1,
      createdBy: data?.assignee,
      collaborator: '',
      linked: allSelected.length,
      file: data?.info?.file ? data?.info?.file : '',
      title: title,
    };

    if (data?.info?.comments?.length > 0 && createdComment !== '') {
      noteInfo.comments = comments.concat({ text: createdComment, timestamp: new Date() * 1 });
    } else if (data?.info?.comments?.length == 0 && createdComment !== '') {
      let commentArr = [];
      commentArr.push({ text: createdComment, timestamp: new Date() * 1 });
      noteInfo.comments = commentArr;
    } else if (data?.info?.comments?.length > 0 && createdComment == '') {
      noteInfo.comments = comments;
    }

    let params = {
      folderId: data.folderId,
      title: data.category == 'Note' ? editNote : title,
      info: noteInfo,
    };

    console.log(params);
    console.log(allSelectedLinks);

    if (handleDocumentId != undefined) {
      handleDocumentId(data.id.toString());
    }
    if (handleFolderId != undefined) {
      handleFolderId(data.folderId.toString());
    }
    updateDoc(data.id, params).then((res) => {
      createDocLinks(data.id, allSelectedLinks).then((res) => {
        notification.open({
          message: 'Document Successfully updated!',
        });
        if (setUpdatedDocument != undefined) {
          setUpdatedDocument({
            id: data.id,
            ...params,
          });
        }
        closeDrawer(true);
        // handleChange(true);
      });
    });
  };

  const saveComment = () => {
    if (expand) {
      let allSelectedLinks = [].concat(selectedEvents, selectedKitchens, selectedRecipes, selectedTasks, selectedMenus);
      const operator = JSON.parse(localStorage.getItem('operator')) || {};
      console.log(allSelectedLinks);
      console.log(createdComment);
      console.log(allSelected);

      let noteInfo = {
        type: data?.category,
        // timestamp: new Date() * 1,
        updatedDate: new Date() * 1,
        createdBy: data?.assignee,
        collaborator: '',
        linked: allSelected.length,
        file: data?.info?.file ? data?.info?.file : '',
      };

      if (data?.info?.comments?.length > 0 && createdComment !== '') {
        let addedComment = comments.concat({ text: createdComment, timestamp: new Date() * 1 });
        noteInfo.comments = addedComment;
        setComments(addedComment);
      } else if (data?.info?.comments?.length == 0 && createdComment !== '') {
        let commentArr = [];
        commentArr.push({ text: createdComment, timestamp: new Date() * 1 });
        setComments(commentArr);
        noteInfo.comments = commentArr;
      } else if (data?.info?.comments?.length > 0 && createdComment == '') {
        noteInfo.comments = data?.info?.comments;
      }

      let params = {
        folderId: data.folderId,
        title: title,
        info: noteInfo,
      };

      console.log(params);
      console.log(allSelectedLinks);

      if (handleDocumentId != undefined) {
        handleDocumentId(data.id.toString());
      }
      if (handleFolderId != undefined) {
        handleFolderId(data.folderId.toString());
      }
      updateDoc(data.id, params).then((res) => {
        createDocLinks(data.id, allSelectedLinks).then((res) => {
          // notification.open({
          //   message: 'Document comments Successfully updated!',
          // });
          // closeDrawer(true);
          if (setUpdatedDocument != undefined) {
            setUpdatedDocument({
              id: data.id,
              ...params,
            });
          }
          setCreateComment('');
          setScroll();
          setExpand(false);
          updateComments(res);

          // handleChange(true);
        });
      });
    }
  };

  const enterComment = (e) => {
    setCreateComment(e.target.value);
    setExpand(true);
  };

  const saveComment1 = (e) => {
    e.preventDefault();
    if (expand || createdComment !== '') {
      let allSelectedLinks = [].concat(selectedEvents, selectedKitchens, selectedRecipes, selectedTasks, selectedMenus);
      const operator = JSON.parse(localStorage.getItem('operator')) || {};
      console.log(allSelectedLinks);
      console.log(createdComment);
      console.log(allSelected);

      let noteInfo = {
        type: data?.category,
        // timestamp: new Date() * 1,
        updatedDate: new Date() * 1,
        createdBy: data?.assignee,
        collaborator: '',
        linked: allSelected.length,
        file: data?.info?.file ? data?.info?.file : '',
      };

      if (data?.info?.comments?.length > 0 && createdComment !== '') {
        let addedComment = comments.concat({ text: createdComment, timestamp: new Date() * 1 });
        noteInfo.comments = addedComment;
        setComments(addedComment);
      } else if (data?.info?.comments?.length == 0 && createdComment !== '') {
        let commentArr = [];
        commentArr.push({ text: createdComment, timestamp: new Date() * 1 });
        setComments(commentArr);
        noteInfo.comments = commentArr;
      } else if (data?.info?.comments?.length > 0 && createdComment == '') {
        noteInfo.comments = data?.info?.comments;
      }

      let params = {
        folderId: data.folderId,
        title: title,
        info: noteInfo,
      };

      console.log(params);
      console.log(allSelectedLinks);
      if (handleDocumentId != undefined) {
        handleDocumentId(data.id.toString());
      }
      if (handleFolderId != undefined) {
        handleFolderId(data.folderId.toString());
      }
      updateDoc(data.id, params).then((res) => {
        createDocLinks(data.id, allSelectedLinks).then((res) => {
          // notification.open({
          //   message: 'Document comments Successfully updated!',
          // });
          // closeDrawer(true);
          //setCreateComment('');
          if (setUpdatedDocument != undefined) {
            setUpdatedDocument({
              id: data.id,
              ...params,
            });
          }
          setExpand(false);
          setScroll();
          // handleChange(true);
        });
      });
    }
  };

  const deleteComment = (e, comment) => {
    console.log(comment);
  };

  const setDocProps = (e, obj) => {
    console.log(obj);
    if (obj.category !== 'Note') {
      localStorage.setItem(
        'docProps',
        JSON.stringify({
          name: obj.name,
          date: obj.upload_date,
          file: obj.info?.file,
          type: obj.info?.type,
        })
      );
    } else {
      localStorage.removeItem('docProps');
    }
  };

  const menu1 = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a onClick={editDoc}>Edit</a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span onClick={(e) => setDocProps(e, data)}>
          <Link target="_blank" to={{ pathname: `/document/public/${data.id}` }}>
            View
          </Link>
        </span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <span style={{ color: 'var(--base-text)' }} onClick={(e) => downloadFile(e, data?.info?.file)}>
          Download
        </span>
      </Menu.Item>
      {/* <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="">
          Duplicate
        </a>
      </Menu.Item> */}
      {/* <Menu.Item className="menu-show-preview">
        <a onClick={editDoc}>Edit</a>
      </Menu.Item> */}
      <Menu.Item className="menu-show-preview">
        <a onClick={deleteDoc}>Delete</a>
      </Menu.Item>
      {/* <Menu.Item className="menu-show-preview">
        <a onClick={saveChanges}>Save</a>
      </Menu.Item> */}
      {/* <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="">
          Leave
        </a>
      </Menu.Item> */}
    </Menu>
  );

  const menu2 = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview">
        <a onClick={editDoc}>Edit</a>
      </Menu.Item>
      <Menu.Item className="menu-show-preview">
        <a onClick={deleteDoc}>Delete</a>
      </Menu.Item>
    </Menu>
  );
  const removeLinked = (e, obj) => {
    console.log(obj);
    // let filtered = allSelected.filter((item) => item.id !== parseInt(obj.id));
    // setAllSelected(filtered);
    // if (obj.type == 'tasks') {

    //   let filter = checkedTasks.filter((item) => item !== parseInt(obj.id));
    //   console.log(filter);
    //   setCheckedTasks(filter);
    //   deleteLink(data.id, 'tasks', obj.id).then((res) => res);
    // }
  };

  const setScroll = () => {
    let time = document.getElementById('time');
    time.scrollIntoView({ block: 'end', behavior: 'smooth' });
  };

  const downloadFile = async (e, imgSrc) => {
    e.preventDefault();
    let img = imgSrc.replace('https://cidekic-images.s3.amazonaws.com', 'https://images.cidekic-dev.com');
    console.log(img);
    const image = await fetch(img);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    let fileName = img.split('/').pop();
    link.download = fileName.substr(0, fileName.lastIndexOf('.'));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="doc-details-main d-flex flex-column">
      <div className="doc-details-subcontainer flex-grow-1">
        <div className="ck-task-box">
          <div>
            {/* <div className="dev-tag">P D DDP</div> */}
            <CidekicButton className="button-highlight" alt="save and close" onClick={saveChanges}>
              Save and close
            </CidekicButton>
          </div>
          <div className="ck-task2">
            <ThumbnailImage
              className="doc-details-preview-container"
              src={docThumbnailSrc(data)}
              placeholderIcon={docIcon(data)}
            />
            <div className="ck-data-box">
              <div className="ck-data-name">
                {' '}
                {editTitle ? (
                  <input
                    type="text"
                    className="doc-inputarea1"
                    defaultValue={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        return saveTitle();
                      }
                    }}
                    // onBlur={(e) => saveTitle()}
                  ></input>
                ) : (
                  <span>{title}</span>
                )}
              </div>
              <div className="ck-data-control">
                {data.category !== 'Note' ? (
                  <span onClick={(e) => setDocProps(e, data)}>
                    <Link target="_blank" to={{ pathname: `/document/public/${data.id}` }}>
                      <span className="ck-data-view">View</span>
                    </Link>
                  </span>
                ) : (
                  <span className="ck-data-view"></span>
                )}
                {data.category !== 'Note' ? (
                  <span onClick={(e) => downloadFile(e, data?.info?.file)}>
                    <span className="ck-data-download3">Download</span>
                  </span>
                ) : (
                  <span className="ck-data-download1"></span>
                )}
                <span>
                  <Dropdown overlay={data.category !== 'Note' ? menu1 : menu2}>
                    <div className="more-menu ant-dropdown-link" alt="see_more" onClick={(e) => e.preventDefault()}>
                      <MoreIcon />
                    </div>
                  </Dropdown>
                </span>
              </div>
            </div>
          </div>
          <div className="doc-det-box">
            <div>
              Type <span className="doc-det-spanType">{data.category}</span> {data.attachments}{' '}
              <img src={comment} className="comment" alt="comment" />
            </div>
            <div>
              Date uploaded <span className="check-detail-spanDue">{data.upload_date}</span>{' '}
            </div>
            <div>
              Uploaded by{' '}
              <span className="check-detail-spanDue">
                {' '}
                <div className="common-user-icon" alt="user-photo">
                  <UserImage src={data.assigneeImg && data.assigneeImg !== '' ? data.assigneeImg : null} />
                </div>
                {data.assignee}
              </span>{' '}
            </div>
          </div>
        </div>
        <br />
        {data.category !== 'Note' ? (
          ''
        ) : (
          <textarea
            type="text"
            className="doc-textarea"
            defaultValue={editNote}
            onChange={(e) => setEditNote(e.target.value)}
          ></textarea>
        )}
        <br />
        <div className="ck-subtask-title">Linked ({allSelected.length})</div>
        <hr className="doc-line1" />
        {allSelected.map((l) => {
          return (
            <div>
              <div className="doc-linked-text">
                <span className="flex-control-left">{l.name}</span>
                {/* <span onClick={(e) => removeLinked(e, l)}>X</span> */}
                <span className="flex-control-right">
                  {l.type == 'tasks'
                    ? 'Task'
                    : l.type == 'menus'
                    ? 'Menu'
                    : l.type == 'kitchen'
                    ? KITCHENSTATIONNAME
                    : l.type == 'events'
                    ? 'Event station'
                    : l.type == 'recipes'
                    ? 'Recipe'
                    : 'Unknown'}
                </span>
              </div>
              <hr className="doc-line1" />
            </div>
          );
        })}
        <br />
        {/*<div>
          <div className="doc-note-station">Linky link</div>
          <div className="doc-station-title">Select all doodads you want this item to appear in</div>
          <div className="doc-collapse-box">
            <Collapse bordered={false} defaultActiveKey={[]}>
              <Panel header={<div className="doc-note-header">{KITCHENSTATIONNAME}s</div>} key="1">
                <Row>
                  <Col>
                    // <Checkbox.Group options={kitchenStations} onChange={getKitchen} defaultValue={checkedKitchens} />
                    {kitchenStations?.map((t) => {
                      return (
                        <div>
                          <input
                            onClick={getKitchen}
                            type="checkbox"
                            value={t.value}
                            defaultChecked={checkedKitchens.includes(t.value) ? true : false}
                          />
                          <label style={{ marginLeft: 7, color: 'var(--base-text)' }}>{t.label}</label>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </Panel>
              <Panel header={<div className="doc-note-header">Event stations</div>} key="2">
                <Row>
                  <Col>
                    // <Checkbox.Group options={eventStations} onChange={getEvent} defaultValue={checkedEvents} />
                    {eventStations.map((t) => {
                      return (
                        <div>
                          <input
                            onClick={getEvent}
                            type="checkbox"
                            value={t.value}
                            defaultChecked={checkedEvents.includes(t.value) ? true : false}
                          />
                          <label style={{ marginLeft: 7, color: 'var(--base-text)' }}>{t.label}</label>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
          <div className="doc-collapse-box">
            <Collapse bordered={false} defaultActiveKey={[]}>
              <Panel header={<div className="doc-note-header">Menus</div>} key="3">
                <Row>
                  <Col>
                    // <Checkbox.Group options={menus} onChange={getMenus} defaultValue={checkedMenus} />
                    {menus.map((t) => {
                      return (
                        <div>
                          <input
                            onClick={getMenus}
                            type="checkbox"
                            value={t.value}
                            defaultChecked={checkedMenus.includes(t.value) ? true : false}
                          />
                          <label style={{ marginLeft: 7, color: 'var(--base-text)' }}>{t.label}</label>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </Panel>
              <Panel header={<div className="doc-note-header">Recipes</div>} key="4">
                <Row>
                  <Col>
                    // <Checkbox.Group options={recipes} onChange={getRecipes} defaultValue={checkedRecipes} />
                    {recipes.map((t) => {
                      return (
                        <div>
                          <input
                            onClick={getRecipes}
                            type="checkbox"
                            value={t.value}
                            defaultChecked={checkedRecipes.includes(t.value) ? true : false}
                          />
                          <label style={{ marginLeft: 7, color: 'var(--base-text)' }}>{t.label}</label>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </Panel>
              <Panel header={<div className="doc-note-header">Tasks</div>} key="5">
                <Row>
                  <Col>
                    // <Checkbox.Group options={tasks} onChange={getTasks} defaultValue={checkedTasks} />
                    {tasks.map((t) => {
                      return (
                        <div>
                          <input
                            onClick={getTasks}
                            type="checkbox"
                            value={t.value}
                            defaultChecked={checkedTasks.includes(t.value) ? true : false}
                          />
                          <label style={{ marginLeft: 7, color: 'var(--base-text)' }}>{t.label}</label>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
        </div>
        <br /> */}
        <div>
          <div className="doc-Line"></div>
          <div className="doc-activity-log">
            <div className="doc-log-image-container">
              {' '}
              <div className="common-user-icon" alt="user-photo">
                <UserImage src={data.assigneeImg && data.assigneeImg !== '' ? data.assigneeImg : null} />
              </div>{' '}
            </div>
            <div className="doc-log-content">
              <div className="doc-log-heading">{data.assignee}</div>
              <div className="doc-log-comment"> uploaded this document</div>
            </div>

            <div className="doc-log-timestamp">{moment(data.upload_date.replace(/-/g, '/')).format('MM-DD-YYYY')}</div>
            <div className="doc-log-approve" />
          </div>
          {comments?.map((c, i) => {
            return (
              <div className="doc-activity-log" key={`doc-log-${i}`}>
                {' '}
                <div className="doc-log-image-container">
                  {' '}
                  <div className="common-user-icon" alt="user-photo">
                    <UserImage src={data.assigneeImg && data.assigneeImg !== '' ? data.assigneeImg : null} />
                  </div>{' '}
                </div>
                <div className="doc-log-content">
                  <div className="doc-log-heading">{data.assignee}</div>
                  <div className="doc-log-comment">{c.text}</div>
                </div>
                <div className="doc-log-timestamp">{moment(c.timestamp).format('MM-DD-YYYY')}</div>
                <img src={approve} className="doc-log-approve" alt="approve" />
              </div>
            );
          })}
        </div>
        <div id="time">.</div>
      </div>
      <div id="ck-add-comment">
        <div className="Line"></div>
        <div className="doc-comment-box">
          <div className="doc-log-image-container">
            <div className="common-user-icon" alt="user-photo">
              <UserImage src={data.assigneeImg && data.assigneeImg !== '' ? data.assigneeImg : null} />
            </div>
          </div>
          <textarea
            id="textareadoc"
            type="text"
            className={expand ? 'ck-input-expand' : 'ck-input-mdtxt'}
            placeholder="Enter comment"
            value={createdComment}
            onFocus={() => setExpand(true)}
            onChange={(e) => enterComment(e)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setExpand(false);
                return saveComment(e);
              }
            }}
            onBlur={(e) => saveComment1(e)}
          />
        </div>
        {/* <div className="doc-collab">
          <div className="doc-collab-text">Collaborators</div>
          <div className="flex">
            <div className="doc-log-image-container">
              <div className="common-user-icon" alt="user-photo">
                <UserImage src={data.assigneeImg && data.assigneeImg !== '' ? data.assigneeImg : null} />
              </div>
            </div>
            <span>Add collaborators</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DocumentsDetailPopup;
