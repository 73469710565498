/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { Table, Dropdown, Menu, Select } from 'antd';
import useWindowSize from '../../components/windowdimensions/windowSize';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
//import ImagesUploader from 'react-images-uploader';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import ReactDragListView from 'react-drag-listview';
import reorder from '../../img/reorder-icon.png';
import { onFileSelected } from '../../services/upload.service';
import { search, update } from '../../services/equipment.service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ThumbnailImage, CidekicSelect } from '../../components/common/common_ui';
import TableInputQty from '../../components/Recipe/TableInputQty';
import { uniq, cloneDeep, findIndex } from 'lodash';

const EquipmentTable = (props) => {
  const { addEquipmentList, unassignEquip, eqListUpdates, isEditing, updateEquipCategory, tabStart } = props;

  const windowSize = useWindowSize();
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';
  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';

  const { Option } = Select;

  const fileInput = useRef();

  const [addEquipment, setAddEquipment] = useState([]);
  //const [totalCost, setTotalCost] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [ingDelete, setDeleteIng] = useState({});
  const [equipCategories, setEquipCategories] = useState([]);
  //const [selectedEquipment, updateSelectedEquipment] = useState('');
  //const [saveImage, setImage] = useState('');

  // console.log('ADDEQUIP', addEquipmentList);

  useEffect(() => {
    console.log(addEquipment);
  }, [addEquipment]);

  useEffect(() => {
    if (addEquipmentList.length > 0) {
      const addedEquipments = addEquipmentList.map((eq) => {
        eq.key = `${Math.random().toString()}`;
        eq.category = eq.info?.category;
        return eq;
      });
      setAddEquipment(addedEquipments);
    }
    // const sum = addEquipmentList.reduce((a, { cost }) => a + parseFloat(cost), 0);
    //setTotalCost(sum.toFixed(2));
  }, [addEquipmentList]);

  useEffect(() => {
    console.log(selectedRow);
  }, [selectedRow]);

  useEffect(() => {
    let params = {
      description: '',
      accountId: accountId,
      isSuperAdmin: accountRole == 'Superadmin' ? true : false,
    };
    search(params).then((res) => {
      console.log(res);
      if (res) {
        let allEquip = res.filter((r) => r.id !== 'add');
        // console.log(res);
        let equipment = allEquip.map((l, i) => {
          return {
            key: `${i}`,
            id: l.id,
            name: l.name,
            restaurantId: l.restaurantId,
            category: l.info && 'category' in l.info ? l.info?.category : 'Uncategorized',
          };
        });

        let currentEquipCategories = uniq(equipment.map((e) => e.category).filter((cat) => cat !== '' && cat !== null));

        setEquipCategories(currentEquipCategories);
      }
    });
  }, []);

  const getRowVal = (r) => {
    setSelectedRow(r);
  };

  const updateQty = (e) => {
    let numQty = e.target.value === null ? 0 : e.target.value;
    console.log('NUMBER', numQty);

    let rowSelected = selectedRow;
    rowSelected.quantity = parseFloat(numQty);
    console.log(rowSelected);

    const updateEq = addEquipment.map((p) =>
      p.key === rowSelected.key
        ? {
            ...p,
            quantity: parseFloat(numQty),
            prepped: {
              size: p?.prepped?.size ? p.prepped.size : p.size ? p.size : 'size',
              type: p?.prepped?.type ? p.prepped.type : p.type ? p.type : 'type',
              quantity: parseFloat(numQty),
            },
          }
        : p
    );
    console.log('QTYUPDATE', updateEq);
    setAddEquipment(updateEq);
  };

  const updateQtyMob = (e, obj) => {
    let numQty = e.target.value === null ? 0 : e.target.value;
    console.log('NUMBERMOB', numQty);

    let rowSelected = obj;
    rowSelected.quantity = numQty;
    console.log(rowSelected);

    const updateEq = addEquipment.map((p) =>
      p.key === rowSelected.key
        ? {
            ...p,
            quantity: parseFloat(numQty),
            prepped: {
              size: p?.prepped?.size ? p.prepped.size : p.size ? p.size : 'size',
              type: p?.prepped?.type ? p.prepped.type : p.type ? p.type : 'type',
              quantity: parseFloat(numQty),
            },
          }
        : p
    );
    console.log('QTYUPDATEMOB', updateEq);
    setAddEquipment(updateEq);
    eqListUpdates(updateEq);
  };

  const uploadImg = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties",
      // });
      return;
    }
    let rowSelected = selectedRow;
    onFileSelected(e.target.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        let id = rowSelected.id;
        if (isEditing) {
          let updateImg = rowSelected.info;
          console.log(updateImg);
          updateImg.imageUrlThumb = res.Location;
          let params = {
            info: updateImg,
          };
          console.log(params);
          let imageInfo = res.Location;
          update(id, params).then((res) => {
            console.log(res);
            // notification.open({
            //   message: 'Successfully uploaded image!',
            // });
            rowSelected.imageUrlThumb = imageInfo;
            console.log(rowSelected);

            console.log(addEquipment);
            const updateIng = addEquipment.map((p) =>
              p.id === rowSelected.id ? { ...p, imageUrlThumb: imageInfo } : p
            );
            console.log('IMGUPDATE', updateIng);
            setAddEquipment(updateIng);
          });
        } else {
          let updateInfo = rowSelected.info;
          console.log(updateInfo);
          updateInfo.imageUrlThumb = res.Location;
          let params = {
            info: updateInfo,
          };
          console.log(params);
          let imageInfo = res.Location;
          update(id, params).then((res) => {
            console.log(res);
            // notification.open({
            //   message: 'Successfully uploaded image!',
            // });
            rowSelected.imageUrlThumb = imageInfo;
            console.log(rowSelected);

            console.log(addEquipment);
            const updateIng = addEquipment.map((p) =>
              p.id === rowSelected.id ? { ...p, imageUrlThumb: imageInfo } : p
            );
            console.log('IMGUPDATE', updateIng);
            setAddEquipment(updateIng);
          });
        }
      } else {
        // notification.open({
        //   message: 'Image failed to upload! Showing preview only...',
        // });

        rowSelected.imageUrlThumb = URL.createObjectURL(e.target.files[0]);
        console.log(rowSelected);

        console.log(addEquipment);
        const updateIng = addEquipment.map((p) =>
          p.id === rowSelected.id ? { ...p, imageUrlThumb: URL.createObjectURL(e.target.files[0]) } : p
        );
        console.log('IMGUPDATE', updateIng);
        setAddEquipment(updateIng);
      }
    });
  };

  const unassignEquipment = () => {
    console.log('REMOVE EQ', ingDelete);
    unassignEquip(ingDelete);
  };

  const deletesIng = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={unassignEquipment}>
        <span>Delete</span>
      </Menu.Item>
    </Menu>
  );

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleOnDrop = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties",
      // });
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        let id = selectedRow.id;
        if (isEditing) {
          let updateImg = selectedRow.info;
          console.log(updateImg);
          updateImg.imageUrlThumb = res.Location;
          let params = {
            info: updateImg,
          };
          console.log(params);
          let imageInfo = res.Location;
          update(id, params).then((res) => {
            console.log(res);
            // notification.open({
            //   message: 'Successfully uploaded image!',
            // });
            selectedRow.imageUrlThumb = imageInfo;
            console.log(selectedRow);

            console.log(addEquipment);
            const updateIng = addEquipment.map((p) =>
              p.id === selectedRow.id ? { ...p, imageUrlThumb: imageInfo } : p
            );
            console.log('IMGUPDATE', updateIng);
            setAddEquipment(updateIng);
          });
        } else {
          let updateInfo = selectedRow.info;
          console.log(updateInfo);
          updateInfo.imageUrlThumb = res.Location;
          let params = {
            info: updateInfo,
          };
          console.log(params);
          let imageInfo = res.Location;
          update(id, params).then((res) => {
            console.log(res);
            // notification.open({
            //   message: 'Successfully uploaded image!',
            // });
            selectedRow.imageUrlThumb = imageInfo;
            console.log(selectedRow);

            console.log(addEquipment);
            const updateIng = addEquipment.map((p) =>
              p.id === selectedRow.id ? { ...p, imageUrlThumb: imageInfo } : p
            );
            console.log('IMGUPDATE', updateIng);
            setAddEquipment(updateIng);
          });
        }
      } else {
        // notification.open({
        //   message: 'Image failed to upload! Showing preview only...',
        // });

        selectedRow.imageUrlThumb = URL.createObjectURL(e.dataTransfer.files[0]);
        console.log(selectedRow);

        console.log(addEquipment);
        const updateIng = addEquipment.map((p) =>
          p.id === selectedRow.id ? { ...p, imageUrlThumb: URL.createObjectURL(e.dataTransfer.files[0]) } : p
        );
        console.log('IMGUPDATE', updateIng);
        setAddEquipment(updateIng);
      }
    });
  };

  const handleCatChange = (value, obj) => {
    console.log(value, obj);
    console.log(addEquipment);
    const clonedEquip = cloneDeep(addEquipment);
    // const foundIndex = findIndex(clonedEquip, { id: obj.id });
    const foundIndex = findIndex(clonedEquip, { key: obj.key });
    clonedEquip[foundIndex].category = value;
    clonedEquip[foundIndex].info.category = value;
    console.log(clonedEquip);
    setAddEquipment(clonedEquip);
    eqListUpdates(clonedEquip);
    updateEquipCategory(clonedEquip[foundIndex]);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'name-col',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div className="ingredient-name-container">
            <div className="showDragIcon" style={{ marginRight: 5 }}>
              {' '}
              <a className="drag-handle" href="/#">
                <img src={reorder} alt="reorder" className="menusToggles" />
              </a>
            </div>
            <ThumbnailImage
              className="edit-ingredient-image-container"
              onDragOver={handleDragOver}
              onDrop={(e) => handleOnDrop(e, obj)}
              src={
                isEditing
                  ? obj.imageUrlThumb
                    ? obj.imageUrlThumb || obj.imageUrl
                    : obj.info && (obj.info?.imageUrlThumb || obj.info?.imageUrl)
                    ? obj.info?.imageUrlThumb || obj.info?.imageUrl
                    : null
                  : obj.info && (obj.info?.imageUrlThumb || obj.info?.imageUrl)
                  ? obj.info?.imageUrlThumb || obj.info?.imageUrl
                  : null
              }
            />
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => uploadImg(e, obj)}
              ref={fileInput}
              accept="image/*"
            />
            <div className="ingredient-name">{key}</div>
          </div>
        </span>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: (a, b) => (a.category < b.category ? -1 : a.category > b.category ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj, idx) => (
        <span>
          {key ? key : equipCategories[0]}
          {/* <CidekicSelect
            className="admin-select-select"
            onChange={(e) => handleCatChange(e, obj)}
            defaultValue={key ? key : equipCategories[0]}
            placeholder="Select category"
            showSearch
            tabIndex={idx * 2 + 1 + tabStart}
          >
            {equipCategories.map((cat, i) => (
              <Option key={i} value={cat}>
                {cat}
              </Option>
            ))}
          </CidekicSelect> */}
        </span>
      ),
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   sorter: (a, b) => (a.type < b.type ? -1 : a.type > b.type ? 1 : 0),
    //   sortDirections: ['descend', 'ascend'],
    //   render: (key, obj) => <span>{key}</span>,
    // },
    // {
    //   title: 'Size',
    //   dataIndex: 'size',
    //   sorter: (a, b) => (a.size < b.size ? -1 : a.size > b.size ? 1 : 0),
    //   sortDirections: ['descend', 'ascend'],
    //   render: (key, obj) => <span>{key}</span>,
    // },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: (a, b) => (a.quantity < b.quantity ? -1 : a.quantity > b.quantity ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj, idx) => (
        <TableInputQty
          inputClassName={'qty-input-sm'}
          onChangeValue={updateQty}
          quantityValue={obj.quantity}
          displayValue={obj.quantity}
          onFocusValue={getRowVal}
          rowData={obj}
          tabIndex={idx * 2 + 2 + tabStart}
        />
      ),
      // editInput ? (
      //   <input
      // 				className="qty-input-sm"
      // 				type="number"
      // 				placeholder="Enter"
      // 				value={key}
      // 				onChange={updateQty}>
      // 		</input>
      // ) : (
      //   <input
      //     className="qty-input-sm"
      //     type="number"
      //     value={key}
      //     placeholder="Enter"
      //     onClick={() => setEditInput(true)}
      //   ></input>
      // ),
    },
    {
      title: '',
      dataIndex: 'unassign',
      width: 30,
      render: (key, obj) => (
        <span>
          <Dropdown overlay={deletesIng}>
            <div className="toggle-switch" onMouseOver={(e) => setDeleteIng(obj)}>
              <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
            </div>
          </Dropdown>
        </span>
      ),
    },
  ];

  // const colMobile = [
  //   {
  //     title: '',
  //     dataIndex: 'name',
  //     // sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
  //     // sortDirections: ['descend', 'ascend'],
  //     width: 230,
  //     render: (key, obj) => (
  //       <span>
  //         <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
  //           <div className="showDragIconRecMob">
  //             {' '}
  //             <a className="drag-handle" href="#">
  //               <img src={reorder} alt="reorder" className="menusToggles" />
  //             </a>
  //           </div>
  //           <Dropdown overlay={deletesIng}>
  //             <div className="toggle-switch" onMouseOver={(e) => setDeleteIng(obj)}>
  //               <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
  //             </div>
  //           </Dropdown>
  //         </div>
  //         <div style={{ display: 'flex' }}>
  //           <ThumbnailImage
  //             className="edit-ingredient-image-container"
  //             onDragOver={handleDragOver}
  //             onDrop={e => handleOnDrop(e, obj)}
  //             src={isEditing
  //               ? obj.imageUrlThumb
  //                 ? (obj.imageUrlThumb || obj.imageUrl)
  //                 : obj.info && (obj.info?.imageUrlThumb || obj.info?.imageUrl)
  //                   ? (obj.info?.imageUrlThumb || obj.info?.imageUrl)
  //                   : null
  //               : obj.info && (obj.info?.imageUrlThumb || obj.info?.imageUrl)
  //                 ? (obj.info?.imageUrlThumb || obj.info?.imageUrl)
  //                 : null
  //             }
  //           />
  //           <input
  //             type="file"
  //             style={{ display: 'none' }}
  //             onChange={(e) => uploadImg(e, obj)}
  //             ref={fileInput}
  //             accept="image/*"
  //           />
  //           <div
  //             style={{
  //               whiteSpace: 'nowrap',
  //               overflow: 'hidden',
  //               textOverflow: 'ellipsis',
  //               minWidth: '50%',
  //             }}
  //           >
  //             {' '}
  //             {key}
  //           </div>
  //         </div>
  //         <div className="create-rec-margins">
  //           <span className="create-rec-typespan">Type</span>
  //           {obj.type}
  //         </div>
  //         <div className="create-rec-margins">
  //           <span className="create-rec-sizespan">Size</span>
  //           {obj.size}
  //         </div>
  //         <div className="create-rec-margins">
  //           <span className="create-rec-qtyeqspan">Quantity</span>
  //           {editInput ? (
  //             <input
  //               className="qty-input-sm"
  //               type="number"
  //               placeholder="Enter"
  //               value={obj.quantity}
  //               onChange={updateQty}
  //             ></input>
  //           ) : (
  //             <input
  //               className="qty-input-smMob"
  //               type="number"
  //               value={obj.quantity}
  //               placeholder="Enter"
  //               onClick={() => setEditInput(true)}
  //             ></input>
  //           )}
  //         </div>
  //         {/* <div className="create-rec-margins">
  //           <span style={{ marginRight: 15 }}>Unassign</span>
  //           <span style={{ cursor: 'pointer' }} onClick={() => unassignEquipment(obj.id)}>
  //             Unassign
  //           </span>
  //         </div> */}
  //       </span>
  //     ),
  //   },
  // ];

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      console.log(fromIndex, toIndex);
      const data = [...addEquipment];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      eqListUpdates(data);
    },
    handleSelector: 'a',
    nodeSelector: 'tr.ant-table-row.ant-table-row-level-0',
  };

  const sortEquipment = (e) => {
    let selected = e;
    //updateSelectedEquipment(selected);
    let cloneEq = [...addEquipmentList];
    if (selected == 'name') {
      cloneEq.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : Number(a.name.toLowerCase() > b.name.toLowerCase())
      );
      setAddEquipment(cloneEq);
    }
    if (selected == 'category') {
      cloneEq.sort((a, b) =>
        a.category.toLowerCase() < b.category.toLowerCase() ? -1 : Number(a.category.toLowerCase() > b.category.toLowerCase())
      );
      setAddEquipment(cloneEq);
    }
    if (selected == 'size') {
      cloneEq.sort((a, b) =>
        a.size.toLowerCase() < b.size.toLowerCase() ? -1 : Number(a.size.toLowerCase() > b.size.toLowerCase())
      );

      setAddEquipment(cloneEq);
    }
    if (selected == 'quantity') {
      cloneEq.sort((a, b) => {
        return a.quantity - b.quantity;
      });
      setAddEquipment(cloneEq);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(addEquipment);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAddEquipment(items);
    eqListUpdates(items);
  };

  // const setValues = () => {
  //   setEditInput(true);
  // };

  const makeAddedList = () => {
    const elements = (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {addEquipment.map((obj, index) => {
                return (
                  <Draggable key={obj.id.toString()} draggableId={obj.id.toString()} index={index}>
                    {(provided) => (
                      <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div style={{ color: 'var(--base-text)' }}>
                          <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                            <div className="showDragIconRecMob">
                              {' '}
                              <a className="drag-handle" href="/#">
                                <img src={reorder} alt="reorder" className="menusToggles" />
                              </a>
                            </div>
                            <Dropdown overlay={deletesIng}>
                              <div className="toggle-switchEq" onMouseOver={(e) => setDeleteIng(obj)}>
                                <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                              </div>
                            </Dropdown>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <ThumbnailImage
                              className="edit-ingredient-image-container"
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleOnDrop(e, obj)}
                              src={
                                isEditing
                                  ? obj.imageUrlThumb
                                    ? obj.imageUrlThumb || obj.imageUrl
                                    : obj.info && (obj.info?.imageUrlThumb || obj.info?.imageUrl)
                                    ? obj.info?.imageUrlThumb || obj.info?.imageUrl
                                    : null
                                  : obj.info && (obj.info?.imageUrlThumb || obj.info?.imageUrl)
                                  ? obj.info?.imageUrlThumb || obj.info?.imageUrl
                                  : null
                              }
                            />

                            <input
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) => uploadImg(e, obj)}
                              ref={fileInput}
                              accept="image/*"
                            />
                            <div
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                minWidth: '50%',
                              }}
                            >
                              {' '}
                              {obj.name}
                            </div>
                          </div>
                          {/* <div className="create-rec-margins">
                            <span className="create-rec-typespan">Type</span>
                            {obj.type}
                          </div>
                          <div className="create-rec-margins">
                            <span className="create-rec-sizespan">Size</span>
                            {obj.size}
                          </div> */}
                          <div className="create-rec-margins">
                            <span className="create-rec-typespan">Category</span>
                            {obj.category}
                          </div>
                          <div className="create-rec-margins">
                            <span className="create-rec-qtyeqspan">Quantity</span>
                            {
                              <TableInputQty
                                inputClassName={'qty-input-sm'}
                                onChangeValueMob={updateQtyMob}
                                quantityValue={obj.quantity}
                                displayValue={obj.quantity}
                                onFocusValue={getRowVal}
                                rowData={obj}
                              />
                            }
                            {/* {editInput ? (
                              <input
                                className="qty-input-sm"
                                type="number"
                                placeholder="Enter"
                                value={obj.quantity}
                                onChange={(e) => updateQtyMob(e, obj)}
                              ></input>
                            ) : (
                              <input
                                className="qty-input-smMob"
                                type="number"
                                value={obj.quantity}
                                placeholder="Enter"
                                onClick={() => setValues()}
                              ></input>
                            )*/}
                          </div>
                        </div>
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
    //));
    return elements;
  };
  // view-recipe-section-title
  return (
    <div className="main">
      {!addEquipmentList?.length || isEditing ? (
        <div className="create-rec-controls">
          <div className={isEditing ? 'view-recipe-section-title-edit' : 'view-recipe-section-title'}>Equipment</div>
        </div>
      ) : (
        ''
      )}
      {addEquipmentList?.length ? (
        <div>
          {windowSize.width > 700 ? (
            <div>
              <ReactDragListView {...dragProps}>
                <Table
                  columns={columns}
                  dataSource={addEquipment}
                  className="edit-view-recipe-items-table"
                  pagination={false}
                  onRow={(r) => ({
                    onClick: () => getRowVal(r),
                  })}
                  rowKey={'key'}
                />
              </ReactDragListView>
            </div>
          ) : (
            <div>
              <Select className="ing-selectble" onChange={sortEquipment} defaultValue={'sortby'}>
                <Option value="sortby" disabled>
                  Sort By
                </Option>
                <Option value="name">Equipment name</Option>
                <Option value="category">Category</Option>
                {/* <Option value="size">Size</Option> */}
                <Option value="quantity">Quantity</Option>
              </Select>
              {/* <ReactDragListView {...dragProps}>
                <Table
                  columns={colMobile}
                  dataSource={addEquipment}
                  pagination={false}
                  onRow={(r) => ({
                    onClick: () => getRowVal(r),
                  })}
                />
              </ReactDragListView> */}

              {makeAddedList()}
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default EquipmentTable;
