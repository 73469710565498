/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Select, Spin } from 'antd';
//import { equipmentData, selectedData } from '../../data/equipment';
import useWindowSize from '../../components/windowdimensions/windowSize';
import { search } from '../../services/equipment.service';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { PlusButton, CidekicButton, ThumbnailImage, ExpandingSearchPanel } from '../../components/common/common_ui';

const AddEquipment = (props) => {
  const { updateEquipment } = props;

  const windowSize = useWindowSize();

  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';

  const { Option } = Select;

  const [visible, updateVisible] = useState(false);
  const [active, setActive] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [pristineData, setPristineData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [equipmentData, setEquipData] = useState([]);
  //const [selected, showSelected] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState('Category');
  const [socketData, setSocketData] = useState([]);
  const [equipUpdate, setEquipUpdate] = useState([]);
  const [equipAdd, setEquipAdd] = useState([]);
  const [equipDelete, setEquipDelete] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/equipment`));

  const searchInputRef = useRef();

  const showDrawer = () => {
    setIsLoading(true);
    updateVisible(true);
    let params = {
      restaurantId: '',
      accountId: accountId,
    };

    search(params).then((res) => {
      console.log('EQUIPMENT', res);
      let result = res;

      const equipmentListss = result.sort((x, y) => {
        return x.id - y.id;
      });

      console.log(equipmentListss);
      setEquipData(equipmentListss);
      setPristineData(equipmentListss);
      setSocketData(equipmentListss);

      let getCat = result.map((c) => c.category);
      let uniq = getCat.filter((v, i) => getCat.indexOf(v) == i);
      setCategories(uniq);
      setIsLoading(false);
    });
  };

  const onClose = () => {
    updateVisible(false);
    setIsLoading(false);
    setEquipData([]);
    setPristineData([]);
    setActive([]);
  };

  const selectEquipment = (equipment) => {
    if (active.some((item) => item.name === equipment.name)) {
      const filteractive = active.filter((item) => item.name !== equipment.name);
      setActive(filteractive);
    } else {
      setActive((equipmentlist) => [...equipmentlist, equipment]);
    }
  };

  const addEquipment = () => {
    console.log(active);
    //transmit data to parent
    updateEquipment(active);
    setActive([]);
    onClose();
  };

  const searchEquip = (e) => {
    let keyword = e.target.value.toLowerCase();
    if (keyword.length >= 3) {
      const filtered = pristineData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      setEquipData(filtered);
    }
  };

  const filterIngs = (category) => {
    console.log(category);
    //showSelected(category);
    setEquipData((e) => pristineData.filter((i) => i.category === category));
  };

  // const showAll = () => {
  //   setEquipData(pristineData);
  //   //showSelected('All');
  //   setIsLoading(false);
  // };

  // const menu = (
  //   <Menu className="menu-show-preview">
  //     <Menu.Item className="menu-show-preview" onClick={showAll}>
  //       <a href="/#">All</a>
  //     </Menu.Item>
  //     {categories.map((c) => {
  //       return (
  //         <Menu.Item className="menu-show-preview" onClick={(e) => filterIngs(c)}>
  //           <a href="/#">{c}</a>
  //         </Menu.Item>
  //       );
  //     })}
  //   </Menu>
  // );

  const searchEquipmentCategories = (val) => {
    console.log('search:', val);
    setSelectedEquipment(val);
    filterIngs(val);
  };

  useEffect(() => {
    eventSocket.on('updatedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPUPDATED!!', [newData]);
      setEquipUpdate([newData]);
    });
    eventSocket.on('addedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPADDED!!', newData);
      setEquipAdd(newData);
    });
    eventSocket.on('deletedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPDELETED!!', newData.id);
      setEquipDelete(newData);
    });
    return () => {
      eventSocket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEquipData((oldArray) => [...oldArray, equipAdd]);
    setPristineData((oldArray) => [...oldArray, equipAdd]);
    setSocketData((oldArray) => [...oldArray, equipAdd]);
  }, [equipAdd]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.map((obj) => equipUpdate.find((o) => o.id.toString() === obj.id.toString()) || obj);
    console.log(updated);
    setEquipData(updated);
    setPristineData(updated);
    setSocketData(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipUpdate]);

  useEffect(() => {
    console.log(socketData);
    console.log(equipDelete);
    let updated = socketData.filter((obj) => obj.id.toString() !== equipDelete.id.toString());
    console.log(updated);
    setEquipData(updated);
    setPristineData(updated);
    setSocketData(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipDelete]);

  const showSearchInput = () => {
    setShowSearch(true);
  };

  useEffect(() => {
    if (showSearch && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showSearch, searchInputRef]);

  useEffect(() => {
    console.log(equipmentData);
  }, [equipmentData]);

  return (
    <div>
      <div>
        <CidekicButton
          className="button-link-replacement recipe-add-item-button"
          alt="add equipment"
          onClick={showDrawer}
        >
          Add equipment
        </CidekicButton>
      </div>

      <Drawer
        width={windowSize.width >= 842 ? '800px' : '100%'}
        onClose={onClose}
        destroyOnClose={true}
        visible={visible}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <div className="ingredients-loading-spinner">{isLoading && <Spin tip="Loading equipment..."></Spin>}</div>
        <div className="add-popup-header">
          <div className="add-popup-add-close-container">
            <div>
              <div className="add-course-ing">
                <PlusButton className="button-highlight" alt="Add selected equipment" onClick={addEquipment}>
                  Add selected equipment
                </PlusButton>
              </div>
            </div>
            {windowSize.width > 700 ? (
              <div className="close-drawer" onClick={onClose}>
                X <span className="close-text">Close</span>
              </div>
            ) : (
              <div className="close-drawer" onClick={onClose}>
                X
              </div>
            )}
          </div>
        </div>
        <div className="add-popup-header">
          <ExpandingSearchPanel
            className="flex-control-left"
            showSearch={showSearch}
            searchPrompt="Search equipment"
            onChange={(e) => searchEquip(e)}
            onClick={() => showSearchInput()}
            ref={searchInputRef}
          />
        </div>
        <br />
        <div>
          <div className="warpper">
            <input className="radio" id="one" name="group" type="radio" defaultChecked />
            <input className="radio" id="two" name="group" type="radio" />
            <input className="radio" id="three" name="group" type="radio" />
            <div className="tabs">
              <label className="tab" id="one-tab" htmlFor="one">
                List
              </label>
              <label className="tab-2" id="two-tab" htmlFor="two">
                Selected ({active.length})
              </label>
            </div>
            <div className="panels">
              <div className="panel" id="one-panel">
                {/* <Dropdown overlay={menu}>  NOTE I removed the select-ing class, we do it different now
                  <div className="select-ing ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    {selected ? selected : 'View all equipment categories'}
                  </div>
                </Dropdown> */}
                <div className="add-equip12">
                  <Select
                    className="panel-filter-select"
                    onChange={searchEquipmentCategories}
                    value={selectedEquipment}
                    showArrow={false}
                    showSearch
                  >
                    {categories.map((c, i) => (
                      <Option key={`equip-${c}-i`} value={c}>
                        {c}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div>
                  <ul className="flex-container wrap">
                    {equipmentData.length > 0 &&
                      equipmentData.map((equipment, i) => {
                        return (
                          <li key={i} className="flex-item" onClick={() => selectEquipment(equipment)}>
                            <div
                              className={
                                active.some((item) => item.name === equipment.name) ? 'ing-name-box-2' : 'ing-name-box'
                              }
                            >
                              <div className="ing-name-panel-1">
                                <ThumbnailImage
                                  src={equipment.info && equipment.info?.imageUrl ? equipment.info?.imageUrl : null}
                                />{' '}
                              </div>
                              <div className="ing-name-panel">
                                <div className="ing-name-equip">{equipment.name}</div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="panel" id="two-panel">
                <ul className="flex-container wrap">
                  {active.map((equipment, i) => {
                    return (
                      <li key={i} className="flex-item">
                        <div className="ing-name-box-2">
                          <div className="ing-name-panel-1"></div>
                          <div className="ing-name-panel">
                            <div className="ing-name-equip">{equipment.name}</div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AddEquipment;
