import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Tag, Switch, Tooltip, notification, Menu, Dropdown, Drawer } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { searchmeas, create, update, destroy } from '../../services/measurement.service';
import { allLocations } from '../../services/restaurant.service';
import { all } from '../../services/account.service';
import { cloneDeep, find, remove, findIndex, isEmpty } from 'lodash';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import _ from 'lodash';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { NewSubHeader } from '../../components/Header/Header';
import { PlusButton, CidekicDropdown, RowOkCancel } from '../../components/common/common_ui';

const Measurements = (props) => {
  const [menuName, setMenuName] = useState('');
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [menulist, setList] = useState([]);
  const [accountlist, setAccountList] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [socketData, setSocketData] = useState([]);
  const [measUpdate, setMeasUpdate] = useState([]);
  const [measAdd, setMeasAdd] = useState([]);
  const [measDelete, setMeasDelete] = useState('');
  const [account, setAccount] = useState(0);
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/measurement`));

  const location_name = useRef(null);

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';

  const newLocationValue = {
    id: 'add',
    name: '',
    units: '',
    type: '',
    grams: '',
    milliliters: '',
  };

  useEffect(() => {
    getMeas();
    setTimeout(() => {
      setHasUpdated(false);
      setEditing(false);
      setAdding(false);
      setEditId(null);
    }, 2000);
  }, [hasUpdated]);

  useEffect(() => {
    getLocations();
  }, []);

  const getMeas = () => {
    setIsLoading(true);
    let params = {
      from: 0,
      limit: 10000,
      restaurantId: resto.id,
      accountId: accountId,
    };
    searchmeas(params).then((res) => {
      console.log(res);
      if (res) {
        let allMeas = res;
        let measurements = allMeas.map((l) => {
          return {
            id: l.id,
            name: l.name,
            restaurantId: l.restaurantId,
            type: l.info && l.info?.type ? l.info?.type : '',
            units: l.info && l.info?.units ? l.info?.units : '',
            grams: l.info && l.info?.g_equiv ? l.info?.g_equiv : '',
            milliliters: l.info && l.info?.ml_equiv ? l.info?.ml_equiv : '',
            isMaster: l.info && l.info?.createdBy === 'system_ingest',
            accountId: l.accountId,
          };
        });

        console.log(measurements);
        measurements.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setData(measurements);
        setOrigData(measurements);
        setSocketData(measurements);
        setIsLoading(false);
      }
    });
  };

  const getLocations = () => {
    allLocations(0, 1000).then((res) => {
      console.log(res);
      let allLoc = res;
      let address1 = allLoc.filter((a) => a.deletedAt == '');
      let address = address1.map((l) => {
        return {
          id: l.id,
          name: l.name,
          address: l.info && l.info?.address ? l.info?.address : '',
          timezone: l.info && l.info?.timezone ? l.info?.timezone : '',
        };
      });

      console.log(address);
      setList(address);
    });
  };

  // const debouncedApiCall = useCallback(
  //   _.debounce((e) => {
  //     filterLocations(e);
  //   }, 200),
  //   []
  // );

  useEffect(() => {
    filterMeas(nameFilter);
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All restaurants');
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All restaurants</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>
                {m?.name} - {m?.address}
              </a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterMeas = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getMeas();
    }
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name + '-' + ' ' + menu.address;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.restaurantId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deleteMeas = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: 'Unable to delete a master list item.',
      // });
      return;
    }
    e.preventDefault();
    let id = selected?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Measurement Successfully Deleted',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'deletesMeas',
        JSON.stringify({
          id: id,
        })
      );
    });
  };

  const editMeas = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties",
      // });
      return;
    }
    e.preventDefault();
    console.log(obj);
    setEditing(true);
    setEditId(obj.id);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editMeas(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteMeas(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updateMeas = (obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties",
      // });
      return;
    }
    console.log(obj);
    let rename = location_name.current.value.trim();
    let params = {
      restaurantId: resto.id,
      name: rename,
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    if (getEditData !== null) {
      let info = {
        type: obj.type,
        units: obj.units,
        g_equiv: obj.grams,
        ml_equiv: obj.milliliters,
      };
      params.info = info;
    }
    console.log(params);
    eventSocket.emit(
      'updatesMeas',
      JSON.stringify({
        accountId: obj.accountId,
        grams: obj.grams,
        id: obj.id,
        milliliters: obj.restaurantId,
        name: rename,
        restaurantId: obj.restaurantId,
        type: obj.type,
        units: obj.units,
      })
    );
    update(editId, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated measurement!',
      // });
      setHasUpdated(true);
    });
  };

  const addMeas = (obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      restaurantId: resto.id,
      name: rename !== '' ? rename : 'Untitled',
      info: {
        type: obj.type !== '' ? obj.type : '',
        units: obj.units !== '' ? obj.units : '',
        g_equiv: obj.grams !== '' ? obj.grams : '',
        ml_equiv: obj.milliliters !== '' ? obj.milliliters : '',
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created measurement!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'addsMeas',
        JSON.stringify({
          accountId: res?.accountId,
          grams: res?.grams,
          id: res?.id,
          milliliters: res?.restaurantId,
          name: res?.name,
          restaurantId: res?.restaurantId,
          type: res?.type,
          units: res?.units,
        })
      );
    });
  };

  const handleUnitChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].units = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleTypeChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].type = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleGramsChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].grams = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleMLChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].milliliters = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setAccountList(allAccounts);
      }
    });
  };

  const handleAccountChange = (value, obj) => {
    console.log(value, obj);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].accountId = value;
    setAccount(value);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      width: '20%',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateMeas(obj);
                }
              }}
            />
          )}
          {isAdding && obj.id == 'add' && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  addMeas(e, obj);
                }
              }}
            />
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Unit',
      dataIndex: 'units',
      width: '15%',
      sorter: (a, b) => (a.units < b.units ? -1 : a.units > b.units ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a unit"
              defaultValue={key}
              onChange={(e) => handleUnitChange(e.target.value, obj)}
            />
          )}
          {isAdding && obj.id == 'add' && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a unit"
              defaultValue={key}
              onChange={(e) => handleUnitChange(e.target.value, obj)}
            />
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '15%',
      sorter: (a, b) => (a.type < b.type ? -1 : a.type > b.type ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a type"
              defaultValue={key}
              onChange={(e) => handleTypeChange(e.target.value, obj)}
            />
          )}
          {isAdding && obj.id == 'add' && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a type"
              defaultValue={key}
              onChange={(e) => handleTypeChange(e.target.value, obj)}
            />
          )}

          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Grams',
      dataIndex: 'grams',
      width: '15%',
      sorter: (a, b) => (a.grams < b.grams ? -1 : a.grams > b.grams ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter grams equivalent"
              defaultValue={key}
              onChange={(e) => handleGramsChange(e.target.value, obj)}
            />
          )}
          {isAdding && obj.id == 'add' && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter grams equivalent"
              defaultValue={key}
              onChange={(e) => handleGramsChange(e.target.value, obj)}
            />
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Milliliters',
      dataIndex: 'milliliters',
      width: '15%',
      sorter: (a, b) => (a.milliliters < b.milliliters ? -1 : a.milliliters > b.milliliters ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter milliliter equivalent"
              defaultValue={key}
              onChange={(e) => handleMLChange(e.target.value, obj)}
            />
          )}
          {isAdding && obj.id == 'add' && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter milliliter equivalent"
              defaultValue={key}
              onChange={(e) => handleMLChange(e.target.value, obj)}
            />
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'accountId',
      width: '15%',
      render: (key, obj) => (
        <span>
          {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
            <div className="admin-accountId1">
              {accountlist && accountlist.length > 0 && accountlist.find((m) => m.id == accountId).name}
            </div>
          ) : (
            <span>
              {!obj.isMaster && (
                <div className="admin-accountId1">
                  {accountlist && accountlist.length > 0 && accountlist.find((m) => m.id == accountId).name}
                </div>
              )}
            </span>
          )}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'controls',
      width: '5%',
      render: (key, obj) => (
        <RoleAccessibleComponent
          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
        >
          <span>
            {!isEditing && !isAdding && !obj.isMaster && (
              <Dropdown overlay={controlItems(obj)}>
                <div className="toggle-switch">
                  <img
                    src={IconsMoreOff}
                    className="UI-IconsMoreOff"
                    alt="IconsMoreOff"
                    onMouseEnter={() => setSelected(obj)}
                  />
                </div>
              </Dropdown>
            )}
            {((isEditing && editId == obj.id) || (isAdding && obj.id == 'add')) && (
              <RowOkCancel
                onClickOk={isEditing ? () => updateMeas(obj) : () => addMeas(obj)}
                onClickCancel={cancelEdit}
              />
            )}
          </span>
        </RoleAccessibleComponent>
      ),
    },
  ];

  useEffect(() => {
    eventSocket.on('updatedMeas', (message) => {
      let newData = JSON.parse(message);
      console.log('MEASUPDATED!!', [newData]);
      setMeasUpdate([newData]);
    });
    eventSocket.on('addedMeas', (message) => {
      let newData = JSON.parse(message);
      console.log('MEASADDED!!', newData);
      setMeasAdd(newData);
    });
    eventSocket.on('deletedMeas', (message) => {
      let newData = JSON.parse(message);
      console.log('MEASDELETED!!', newData.id);
      setMeasDelete(newData.id);
    });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    setData((oldArray) => [...oldArray, measAdd]);
    setOrigData((oldArray) => [...oldArray, measAdd]);
    setSocketData((oldArray) => [...oldArray, measAdd]);
  }, [measAdd]);

  useEffect(() => {
    console.log(socketData);
    console.log(measUpdate);
    let updated = socketData.map((obj) => measUpdate.find((o) => o.id.toString() === obj.id.toString()) || obj);
    console.log(updated);
    setData(updated);
    setOrigData(updated);
  }, [measUpdate]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.filter((obj) => obj.id.toString() !== measDelete.toString());
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
  }, [measDelete]);

  return (
    <div id="admin-measurements" className="common-page-container">
      <NewSubHeader
        title="Measurements"
        onChange={(evt) => setNameFilter(evt.target.value)}
        showSearch={accountRole != 'Superadmin'}
      />
      <div className="Line"></div>
      <div className="common-page-content">
        <div className="common-controls-container controls-thin">
          <RoleAccessibleComponent
            permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
          >
            <PlusButton
              className="flex-control-left"
              alt="Add new measurement"
              onClick={() => {
                setAdding(true);
                const clonedDataSource = cloneDeep(origData);
                clonedDataSource.unshift(newLocationValue);
                setData(clonedDataSource);
                window.scrollTo(0, 0);
              }}
            >
              Add new measurement
            </PlusButton>
          </RoleAccessibleComponent>

          <CidekicDropdown overlay={menuList} className="flex-control-right select-wide">
            {menuName ? menuName : 'Restaurant name'}
          </CidekicDropdown>
        </div>

        {!isLoading && (
          <Table
            dataSource={userData}
            columns={columns}
            pagination={false}
            className={'show-custom-empty'}
            locale={{ emptyText: <Empty image={empty} description="No measurements" /> }}
            scroll={{ y: 'calc(100vh - var(--table-scroll-offset))' }}
          />
        )}
      </div>
    </div>
  );
};

export default Measurements;
