/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useRef } from 'react';
import { Table, Tag, Menu, Dropdown, Select } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { ConsoleSqlOutlined, PlusOutlined } from '@ant-design/icons';
//import { Link } from 'react-router-dom';
import { onFileSelected } from '../../services/upload.service';
import { search, create, update, destroy } from '../../services/ingredient.service';
import { all } from '../../services/account.service';
import { cloneDeep, remove, findIndex } from 'lodash';
//import _ from 'lodash';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';
import { ThumbnailImage, CidekicSelect, RowOkCancel } from '../../components/common/common_ui';

const SuperIngredientsMobile = (props) => {
  const [menuName, setMenuName] = useState('');
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState({});
  const [menulist, setList] = useState([]);
  const [editTags, setEditTags] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);
  //const [title, setTitle] = useState([]);
  const [getPagination, setPagination] = useState({ pageSize: 30 });
  //const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState(0);
  const [editObj, setEditObj] = useState({});
  const [userimg, setUserImg] = useState('');
  //const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [socketData, setSocketData] = useState([]);
  const [ingUpdate, setIngUpdate] = useState([]);
  const [ingAdd, setIngAdd] = useState([]);
  const [ingDelete, setIngDelete] = useState('');
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState({});
  const [selectedSub, setSelectedSub] = useState([]);
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/ingredient`));
  const [secondDropdown, setSecondDropdown] = useState('');

  const location_name = useRef(null);
  const fileInput = useRef();

  const { Option } = Select;

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let accountsId = JSON.parse(localStorage.getItem('operator')).accountId || '';
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';

  const newLocationValue = {
    id: 'add',
    name: '',
    category: '',
    subcategory: '',
    tags: [],
    image: '',
  };

  useEffect(() => {
    getIngs();
    setTimeout(() => {
      setEditing(false);
      setAdding(false);
      setEditId(null);
      setEditObj(null);
      setPagination({ pageSize: 100 });
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdated]);

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    console.log(getEditData);
    console.log(editTags);
    console.log(isEditing);
    const listener = (event) => {
      if (isEditing && editTags && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        console.log('ADD!! Enter key was pressed. Run add.');
        //event.preventDefault();
        console.log(getEditData);
        updateIngs(event, getEditData);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, getEditData, editTags]);

  const getIngs = () => {
    setHasUpdated(false);
    //setIsLoading(true);
    let params = {
      // restaurantId: resto?.id,
      from: 0,
      limit: 10000,
      accountId: accountsId,
      isSuperAdmin: true,
    };
    search(params).then((res) => {
      console.log(res);
      if (res) {
        let allIngs = res.ingredients;
        let ingredients = allIngs.map((l) => {
          let parsed = JSON.parse(l.info);
          return {
            id: l.id,
            name: l.name,
            restaurantId: l.restaurantId,
            category: parsed && parsed.category ? parsed.category : '',
            subcategory: parsed && parsed['sub category'] ? parsed['sub category'] : '',
            tags: parsed && parsed.tags ? parsed.tags : [],
            image:
              parsed.imageUrlThumb || parsed.imageUrl
                ? parsed.imageUrlThumb || parsed.imageUrl
                : l.imageUrlThumb || l.imageUrl
                ? l.imageUrlThumb || l.imageUrl
                : '',

            accountId: l.accountId,
            isMaster: parsed && parsed.createdBy === 'system_ingest',
          };
        });

        console.log(ingredients);
        ingredients.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setData(ingredients);
        setOrigData(ingredients);
        setSocketData(ingredients);
        //setIsLoading(false);

        let getCat = ingredients.map((c) => c.category);
        let uniq = getCat.filter((v, i) => getCat.indexOf(v) == i);
        setCategories(uniq.sort());

        const getSubCat = ingredients.reduce((r, ing) => {
          const { category, subcategory } = ing;
          r[category] = [...(r[category] || []), subcategory];

          return r;
        }, {});

        console.log(getSubCat);
        setSubCategories(getSubCat);
      }
    });
  };

  const getAccounts = () => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setList(allAccounts);
      }
    });
  };

  useEffect(() => {
    filterIngs(nameFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All accounts');
  };
  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <span>All accounts</span>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <span>{m?.name}</span>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterIngs = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getIngs();
    }
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.accountId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deleteIngs = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: 'Unable to delete a master list item.',
      // });
      return;
    }
    e.preventDefault();
    let id = obj?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Ingredient Successfully Deleted',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'deleteIng',
        JSON.stringify({
          id: id,
        })
      );
    });
  };

  const editIngs = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    e.preventDefault();
    console.log(obj);
    let uniq = subcategories[obj.category].filter((v, i) => subcategories[obj.category].indexOf(v) == i);
    setSelectedSub(uniq.sort());
    setEditing(true);
    setEditId(obj.id);
    setEditObj(obj);
    setSecondDropdown(obj.subcategory);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editIngs(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteIngs(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = (obj) => {
    obj.image = selected?.image ? selected.image : '';
    setEditing(false);
    setEditId(null);
    setEditObj(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
    setPagination({ pageSize: 100 });
  };

  const updateIngs = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    let rename = location_name.current.value.trim();
    console.log('UPDATE!!!', obj);
    let selectedObj = {};
    if (obj == null) {
      selectedObj = editObj;
    } else {
      selectedObj = obj;
    }
    let params = {
      accountId: selectedObj.accountId !== '' ? selectedObj.accountId : '0',
      name: rename,
      info: {
        category: selectedObj.category,
        'sub category': selectedObj.subcategory,
        tags: selectedObj.tags,
        imageUrl: obj.image,
      },
      restaurantId: obj.restaurantId,
    };
    console.log(params);
    eventSocket.emit(
      'updateIng',
      JSON.stringify({
        accountId: params.accountId,
        category: params.info?.category,
        id: obj.id,
        image: params.info?.imageUrl,
        name: rename,
        restaurantId: obj.restaurantId,
        subcategory: obj.subcategory,
        tags: params.info?.tags,
      })
    );
    update(editId, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated ingredient!',
      // });
      setHasUpdated(true);
    });
  };

  const updateIngs2 = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    let rename = location_name.current.value.trim();
    console.log('UPDATE!!!', obj);
    let params = {
      accountId: obj.accountId !== '' ? obj.accountId : '0',
      name: rename,
      info: {
        category: obj.category,
        'sub category': obj.subcategory,
        tags: obj.tags,
        imageUrl: obj.image,
      },
      restaurantId: obj.restaurantId,
    };
    console.log(params);
    eventSocket.emit(
      'updateIng',
      JSON.stringify({
        accountId: params.accountId,
        category: params.info?.category,
        id: obj.id,
        image: params.info?.imageUrl,
        name: rename,
        restaurantId: obj.restaurantId,
        subcategory: obj.subcategory,
        tags: params.info?.tags,
      })
    );
    update(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated ingredient!',
      // });
      setHasUpdated(true);
    });
  };

  const addIngs = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      accountId: account,
      name: rename !== '' ? rename : 'Untitled',
      info: {
        category: obj.category,
        'sub category': obj.subcategory,
        tags: userData[0].tags,
        imageUrl: userimg,
      },
      restaurantId: resto?.id,
    };
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created ingredient!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'addIng',
        JSON.stringify({
          accountId: res?.accountId?.toString(),
          category: res?.info?.category,
          id: res?.id?.toString(),
          image: res?.info?.imageUrl,
          name: res?.name,
          restaurantId: res?.restaurantId,
          subcategory: res?.info?.subcategory,
          tags: res?.info?.tags,
        })
      );
    });
  };

  const addIngs2 = (e, obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      accountId: account,
      name: rename !== '' ? rename : 'Untitled',
      info: {
        category: obj.category,
        subcategory: obj.subcategory,
        tags: obj.tags,
        imageUrl: userimg,
      },
    };
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created ingredient!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'addIng',
        JSON.stringify({
          accountId: res?.accountId?.toString(),
          category: res?.info?.category,
          id: res?.id?.toString(),
          image: res?.info?.imageUrl,
          name: res?.name,
          restaurantId: res?.restaurantId,
          subcategory: res?.info?.subcategory,
          tags: res?.info?.tags,
        })
      );
    });
  };

  const handleCategoryChange = (value, obj) => {
    let uniq = subcategories[value].filter((v, i) => subcategories[value].indexOf(v) == i);
    setSelectedSub(uniq.sort());
    setSecondDropdown(uniq[0]);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].category = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleSubCategoryChange = (value, obj) => {
    setSecondDropdown(value);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].subcategory = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleAddTags = (value, obj, type) => {
    console.log(value);
    console.log(obj);

    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].tags.push(value);
    console.log(clonedChecklist);
    setData(clonedChecklist);
    console.log(clonedChecklist[foundIndex]);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleRemoveTags = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    remove(clonedChecklist[foundIndex].tags, (tag) => {
      return tag === value;
    });
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleAccountChange = (value, obj) => {
    console.log(value, obj);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].accountId = value;
    setAccount(value);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
    setEditTags(true);
  };

  const uploadImg = (e, obj) => {
    //setLoading(true);
    if (!isAdding) {
      setEditing(true);
    }
    e.preventDefault();
    e.stopPropagation();

    onFileSelected(e.target.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        setUserImg(res.Location);
        obj.image = res.Location;
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        // setLoading(false);
      } else {
        //setLoading(false);
        let userimage = URL.createObjectURL(e.target.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const uploadImg1 = (e, obj) => {
    //setLoading(true);
    if (!isAdding) {
      setEditing(true);
    }
    e.preventDefault();
    e.stopPropagation();

    onFileSelected(e.target.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        setUserImg(res.Location);
        obj.image = res.Location;
        //addIngs(e, obj);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        // setLoading(false);
      } else {
        // setLoading(false);
        let userimage = URL.createObjectURL(e.target.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleOnDrop = (e, obj) => {
    // setLoading(true);
    setEditing(true);
    e.preventDefault();
    e.stopPropagation();
    console.log(e.dataTransfer.files[0]);

    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        obj.image = res.Location;
        setUserImg(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        // setLoading(false);
      } else {
        // setLoading(false);
        let userimage = URL.createObjectURL(e.dataTransfer.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const handleOnDrop1 = (e, obj) => {
    // setLoading(true);
    setEditing(true);
    e.preventDefault();
    e.stopPropagation();
    console.log(e.dataTransfer.files[0]);

    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        obj.image = res.Location;
        //addIngs(e, obj);
        setUserImg(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        //setLoading(false);
      } else {
        //setLoading(false);
        let userimage = URL.createObjectURL(e.dataTransfer.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {!isEditing && !isAdding && (
            <div>
              <div>
                <Dropdown overlay={controlItems(obj)}>
                  <div style={{ cursor: 'pointer', float: 'right' }}>
                    <img
                      src={IconsMoreOff}
                      className="UI-IconsMoreOff"
                      alt="IconsMoreOff"
                      onClick={() => {
                        if (Object.keys(selected).length === 0 || selected.id != obj.id) {
                          setSelected({ ...obj });
                        }
                      }}
                    />
                  </div>
                </Dropdown>
              </div>
            </div>
          )}

          {/* {obj.image !== '' ? <img src={obj.image} className="admin-image-container-mobile" /> : <div className="user-name-box1"></div>} */}
          <span>
            {obj.image && obj.image !== '' && !isEditing && !isAdding && (
              <ThumbnailImage className="admin-image-container-mobile" type={'admin'} src={obj.image} />
            )}
            {obj.image && obj.image !== '' && isEditing && !isAdding && editId == obj.id && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop(e, obj)}
              >
                <ThumbnailImage
                  className="admin-image-container-mobile"
                  type={'admin'}
                  src={obj.image && obj.image !== '' ? obj.image : null}
                />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
            {(!obj.image || obj.image == '') && !isEditing && !isAdding && (
              <ThumbnailImage
                className="admin-image-container-mobile"
                type={'admin'}
                src={obj.image !== '' ? obj.image : null}
              />
            )}
            {(!obj.image || obj.image == '') && isEditing && !isAdding && editId == obj.id && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop(e, obj)}
              >
                <ThumbnailImage
                  className="admin-image-container-mobile"
                  type={'admin'}
                  src={obj.image !== '' ? obj.image : null}
                />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
            {(!obj.image || obj.image == '') && isAdding && !isEditing && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop1(e, obj)}
              >
                <ThumbnailImage className="admin-image-container-mobile" type={'admin'} src={userimg} />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
          </span>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '-6px' }}>Title</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 76 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      updateIngs(e, obj);
                    }
                  }}
                  // onBlur={(e) => updateIngs2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit(obj)}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className={isAdding && obj.id == 'add' ? '' : 'loc_nameMob20'}>{obj.name}</div>
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 76 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addIngs(e, obj);
                    }
                  }}
                  //onBlur={(e) => addIngs2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit(obj)}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Category</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 51 }}>
                <CidekicSelect
                  className="admin-select-select"
                  placeholder="Enter a category"
                  defaultValue={isEditing && editId == obj.id ? obj.category : undefined}
                  onChange={(value) => handleCategoryChange(value, obj)}
                  showSearch
                >
                  {categories.map((cat) => (
                    <Option value={cat}>{cat}</Option>
                  ))}
                </CidekicSelect>
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 51 }}>
                <CidekicSelect
                  className="admin-select-select"
                  placeholder="Enter a category"
                  defaultValue={isAdding && obj.id == 'add' ? undefined : obj.category}
                  onChange={(value) => handleCategoryChange(value, obj)}
                  showSearch
                >
                  {categories.map((cat) => (
                    <Option value={cat}>{cat}</Option>
                  ))}
                </CidekicSelect>
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 51 }}>{obj.category}</div>}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Subcategory</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 30 }}>
                <CidekicSelect
                  className="admin-select-select"
                  placeholder="Select subcategory"
                  defaultValue={isEditing && editId == obj.id ? obj.subcategory : undefined}
                  onChange={(value) => handleSubCategoryChange(value, obj)}
                  showSearch
                >
                  {selectedSub.map((subcat) => (
                    <Option value={subcat}>{subcat}</Option>
                  ))}
                </CidekicSelect>
              </div>
            ) : (
              // <input
              //   type="text"
              //   className="loc-bar"
              //   placeholder="Enter a sub category"
              //   defaultValue={obj.subcategory}
              //   onChange={(e) => handleSubCategoryChange(e.target.value, obj)}
              //   onBlur={() => setEditTags(true)}
              //   onKeyPress={(e) => {
              //     setEditTags(false);
              //     if (e.key === 'Enter') {
              //       setEditTags(true);
              //       updateIngs(e, obj);
              //     }
              //   }}
              // />
              // </div>
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 30 }}>
                <CidekicSelect
                  className="admin-select-select"
                  placeholder="Select subcategory"
                  defaultValue={isAdding ? undefined : obj.subcategory}
                  onChange={(value) => handleSubCategoryChange(value, obj)}
                  showSearch
                >
                  {selectedSub.map((subcat) => (
                    <Option value={subcat}>{subcat}</Option>
                  ))}
                </CidekicSelect>
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 30 }}>{obj.subcategory}</div>}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px', marginRight: 57 }}>Tags</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 20 }}>
                <input
                  type="text"
                  placeholder="Add tags"
                  className="checklist-tags1"
                  name="input"
                  id="input"
                  onBlur={() => setEditTags(true)}
                  onKeyPress={(e) => {
                    setEditTags(false);
                    if (e.key === 'Enter') {
                      handleAddTags(e.target.value, obj, 'edit');
                      e.target.value = '';
                      setEditTags(true);
                    }
                  }}
                />
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <input
                style={{ marginLeft: 57 }}
                type="text"
                placeholder="Add tags"
                className="checklist-tags1"
                name="input"
                id="input"
                onBlur={() => setEditTags(true)}
                onKeyPress={(e) => {
                  setEditTags(false);
                  if (e.key === 'Enter') {
                    handleAddTags(e.target.value, obj, 'edit');
                    e.target.value = '';
                    setEditTags(true);
                  }
                }}
              />
            ) : (
              ''
            )}
            {obj.tags.map((tag) => {
              let color = 'black';
              return (
                <Tag
                  color={color}
                  key={tag}
                  closable={isEditing}
                  onClose={(e) => {
                    // remove tags
                    handleRemoveTags(tag, obj);
                  }}
                >
                  {tag}
                </Tag>
              );
            })}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px', marginRight: 115 }}></div>
            {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
              <RowOkCancel
                onClickOk={isEditing ? (e) => updateIngs(e, obj) : (e) => addIngs(e, obj)}
                onClickCancel={() => cancelEdit(obj)}
              />
            ) : (
              ''
            )}
          </div>
          <div>
            {isAdding && obj.id == 'add' && (
              <span>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: '-6px', marginRight: 45 }}>Account</div>
                  <select
                    style={{ marginBotttom: 15 }}
                    className="admin-select"
                    onChange={(e) => handleAccountChange(e.target.value, obj)}
                  >
                    <option initialvalue="default">Select</option>
                    {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
                  </select>
                </div>
              </span>
            )}
            {isEditing && editId == obj.id && (
              <span>
                <div style={{ display: 'flex', marginTop: 15 }}>
                  <div style={{ marginLeft: '-6px', marginRight: 55 }}>Account</div>
                  <select
                    className="admin-select"
                    defaultValue={obj.accountId}
                    onChange={(e) => handleAccountChange(e.target.value, obj)}
                  >
                    <option initialvalue="default">Select</option>
                    {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
                  </select>
                </div>
              </span>
            )}
            {!isEditing && !isAdding && (
              <span>
                <div style={{ display: 'flex', marginTop: 15 }}>
                  <div style={{ marginLeft: '-6px', marginRight: 58 }}>Account</div>
                  <span>{menulist && menulist.length > 0 && menulist.find((x) => x.id == obj.accountId)?.name}</span>
                </div>
              </span>
            )}
          </div>
        </span>
      ),
    },
  ];

  const columns2 = [
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div>
            {isAdding && obj.id == 'add' && (
              <span>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: '-6px', marginRight: 45 }}>Account</div>
                  <select
                    style={{ marginBotttom: 15 }}
                    className="admin-select"
                    onChange={(e) => handleAccountChange(e.target.value, obj)}
                  >
                    <option initialvalue="default">Select</option>
                    {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
                  </select>
                </div>
              </span>
            )}
            {isEditing && editId == obj.id && (
              <span>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: '-6px', marginRight: 55 }}>Account</div>
                  <select
                    className="admin-select"
                    defaultValue={obj.accountId}
                    onChange={(e) => handleAccountChange(e.target.value, obj)}
                  >
                    <option initialvalue="default">Select</option>
                    {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
                  </select>
                </div>
              </span>
            )}
            {!isEditing && !isAdding && (
              <span>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginLeft: '-6px', marginRight: 55 }}>Account</div>
                  <span>{menulist && menulist.length > 0 && menulist.find((x) => x.id == key)?.name}</span>
                </div>
              </span>
            )}
          </div>
          {!isEditing && !isAdding && (
            <div>
              <div>
                <Dropdown overlay={controlItems(obj)}>
                  <div style={{ cursor: 'pointer', float: 'right' }}>
                    <img
                      src={IconsMoreOff}
                      className="UI-IconsMoreOff"
                      alt="IconsMoreOff"
                      onClick={() => {
                        if (Object.keys(selected).length === 0 || selected.id != obj.id) {
                          setSelected({ ...obj });
                        }
                      }}
                    />
                  </div>
                </Dropdown>
              </div>
            </div>
          )}

          {/* {obj.image !== '' ? <img src={obj.image} className="admin-image-container-mobile" /> : <div className="user-name-box1"></div>} */}
          <span>
            {obj.image && obj.image !== '' && !isEditing && !isAdding && (
              <ThumbnailImage className="admin-image-container-mobile" type={'admin'} src={obj.image} />
            )}
            {obj.image && obj.image !== '' && isEditing && !isAdding && editId == obj.id && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop(e, obj)}
              >
                <ThumbnailImage
                  className="admin-image-container-mobile"
                  type={'admin'}
                  src={obj.image && obj.image !== '' ? obj.image : null}
                />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
            {(!obj.image || obj.image == '') && !isEditing && !isAdding && (
              <ThumbnailImage
                className="admin-image-container-mobile"
                type={'admin'}
                src={obj.image !== '' ? obj.image : null}
              />
            )}
            {(!obj.image || obj.image == '') && isEditing && !isAdding && editId == obj.id && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop(e, obj)}
              >
                <ThumbnailImage
                  className="admin-image-container-mobile"
                  type={'admin'}
                  src={obj.image !== '' ? obj.image : null}
                />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
            {(!obj.image || obj.image == '') && isAdding && !isEditing && obj.id == 'add' && (
              <span
                onClick={() => fileInput.current.click()}
                onDragOver={handleDragOver}
                onDrop={(e) => handleOnDrop1(e, obj)}
              >
                <ThumbnailImage className="admin-image-container-mobile" type={'admin'} src={userimg} />
                <input
                  type="file"
                  style={{ display: 'none', cursor: 'pointer' }}
                  onChange={(e) => uploadImg1(e, obj)}
                  ref={fileInput}
                  accept="image/*"
                />
              </span>
            )}
          </span>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '-6px' }}>Title</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 76 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      updateIngs(e, obj);
                    }
                  }}
                  // onBlur={(e) => updateIngs2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit(obj)}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className={isAdding && obj.id == 'add' ? '' : 'loc_nameMob20'}>{obj.name}</div>
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 76 }}>
                <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a title"
                  defaultValue={key}
                  ref={location_name}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addIngs(e, obj);
                    }
                  }}
                  //onBlur={(e) => addIngs2(e, obj)}
                />

                <div
                  onMouseDown={() => cancelEdit(obj)}
                  style={{
                    width: 100,
                    cursor: 'pointer',
                    margin: '4px 0px 7px 3px',
                    color: 'var(--accent-color)',
                    fontSize: 13,
                  }}
                >
                  Cancel
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Category</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 50 }}>
                <CidekicSelect
                  className="admin-select-select"
                  placeholder="Enter a category"
                  defaultValue={isEditing && editId == obj.id ? obj.category : undefined}
                  onChange={(value) => handleCategoryChange(value, obj)}
                  showSearch
                >
                  {categories.map((cat) => (
                    <Option value={cat}>{cat}</Option>
                  ))}
                </CidekicSelect>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a category"
                  defaultValue={obj.category}
                  onChange={(e) => handleCategoryChange(e.target.value, obj)}
                  onBlur={() => setEditTags(true)}
                  onKeyPress={(e) => {
                    setEditTags(false);
                    if (e.key === 'Enter') {
                      setEditTags(true);
                      updateIngs(e, obj);
                    }
                  }}
                /> */}
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 50 }}>
                <CidekicSelect
                  className="admin-select-select"
                  placeholder="Enter a category"
                  defaultValue={obj.category}
                  onChange={(value) => handleCategoryChange(value, obj)}
                  showSearch
                >
                  {categories.map((cat) => (
                    <Option value={cat}>{cat}</Option>
                  ))}
                </CidekicSelect>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a category"
                  defaultValue={obj.category}
                  onChange={(e) => handleCategoryChange(e.target.value, obj)}
                /> */}
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 51 }}>{obj.category}</div>}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Subcategory</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 30 }}>
                <CidekicSelect
                  className="admin-select-select"
                  placeholder="Enter a sub category"
                  defaultValue={isEditing && editId == obj.id ? obj.subcategory : undefined}
                  onChange={(value) => handleSubCategoryChange(value, obj)}
                  onBlur={() => setEditTags(true)}
                  onKeyPress={(e) => {
                    setEditTags(false);
                    if (e.key === 'Enter') {
                      setEditTags(true);
                      // updateIngs(e, obj);
                    }
                  }}
                  showSearch
                >
                  {selectedSub.map((subCat) => (
                    <Option value={subCat}>{subCat}</Option>
                  ))}
                </CidekicSelect>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a sub category"
                  defaultValue={obj.subcategory}
                  onChange={(e) => handleSubCategoryChange(e.target.value, obj)}
                  onBlur={() => setEditTags(true)}
                  onKeyPress={(e) => {
                    setEditTags(false);
                    if (e.key === 'Enter') {
                      setEditTags(true);
                      updateIngs(e, obj);
                    }
                  }}
                /> */}
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <div style={{ marginLeft: 30 }}>
                <CidekicSelect
                  className="admin-select-select"
                  placeholder="Enter a sub category"
                  defaultValue={obj.subcategory}
                  onChange={(value) => handleSubCategoryChange(value, obj)}
                  showSearch
                >
                  {selectedSub.map((subCat) => (
                    <Option value={subCat}>{subCat}</Option>
                  ))}
                </CidekicSelect>
                {/* <input
                  type="text"
                  className="loc-bar"
                  placeholder="Enter a sub category"
                  defaultValue={obj.subcategory}
                  onChange={(e) => handleSubCategoryChange(e.target.value, obj)}
                /> */}
              </div>
            ) : (
              ''
            )}
            {obj.id !== 'add' && obj.id !== editId && <div style={{ marginLeft: 30 }}>{obj.subcategory}</div>}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px', marginRight: 57 }}>Tags</div>
            {isEditing && editId == obj.id ? (
              <div style={{ marginLeft: 20 }}>
                <input
                  type="text"
                  placeholder="Add tags"
                  className="checklist-tags1"
                  name="input"
                  id="input"
                  onBlur={() => setEditTags(true)}
                  onKeyPress={(e) => {
                    setEditTags(false);
                    if (e.key === 'Enter') {
                      handleAddTags(e.target.value, obj, 'edit');
                      e.target.value = '';
                      setEditTags(true);
                    }
                  }}
                />
              </div>
            ) : (
              ''
            )}
            {isAdding && obj.id == 'add' ? (
              <input
                style={{ marginLeft: 57 }}
                type="text"
                placeholder="Add tags"
                className="checklist-tags1"
                name="input"
                id="input"
                onBlur={() => setEditTags(true)}
                onKeyPress={(e) => {
                  setEditTags(false);
                  if (e.key === 'Enter') {
                    handleAddTags(e.target.value, obj, 'edit');
                    e.target.value = '';
                    setEditTags(true);
                  }
                }}
              />
            ) : (
              ''
            )}
            {obj.tags.map((tag) => {
              let color = 'black';
              return (
                <Tag
                  color={color}
                  key={tag}
                  closable={isEditing}
                  onClose={(e) => {
                    // remove tags
                    handleRemoveTags(tag, obj);
                  }}
                >
                  {tag}
                </Tag>
              );
            })}
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px', marginRight: 115 }}></div>
            {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
              <RowOkCancel
                onClickOk={isEditing ? (e) => updateIngs(e, obj) : (e) => addIngs(e, obj)}
                onClickCancel={() => cancelEdit(obj)}
              />
            ) : (
              ''
            )}
          </div>
        </span>
      ),
    },
  ];

  useEffect(() => {
    eventSocket.on('updatedIng', (message) => {
      let newData = JSON.parse(message);
      console.log('INGUPDATED!!', [newData]);
      setIngUpdate([newData]);
    });
    eventSocket.on('addedIng', (message) => {
      let newData = JSON.parse(message);
      console.log('INGADDED!!', newData);
      setIngAdd(newData);
    });
    eventSocket.on('deletedIng', (message) => {
      let newData = JSON.parse(message);
      console.log('INGDELETED!!', newData.id);
      setIngDelete(newData);
    });
    return () => {
      eventSocket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData((oldArray) => [...oldArray, ingAdd]);
    setOrigData((oldArray) => [...oldArray, ingAdd]);
    setSocketData((oldArray) => [...oldArray, ingAdd]);
  }, [ingAdd]);

  useEffect(() => {
    console.log(socketData);
    console.log(ingUpdate);
    let updated = socketData.map((obj) => ingUpdate.find((o) => o.id === obj.id) || obj);
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingUpdate]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.filter((obj) => obj.id.toString() !== ingDelete.id.toString());
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingDelete]);

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Ingredients"
        restaurant="Super Admin"
        showSearch={showSearch}
        onChange={(evt) => setNameFilter(evt.target.value)}
        clickSearch={() => setShowSearch(true)}
      />
      <div style={{ display: 'flex' }}>
        <div className="add-cat23Mob">
          <div>{menuName ? menuName : 'Account name'}</div>
        </div>
        <Dropdown overlay={menuList}>
          <div className="dropdown-icon">
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </Dropdown>
        <div className="spacer-cat"></div>
        <div
          className="add-cat13Mob"
          onClick={() => {
            //setIsAdding(true);
          }}
        >
          <div
            style={{ cursor: 'pointer', fontFamily: 'Helvetica Neue' }}
            onClick={() => {
              setAdding(true);
              window.scrollTo(0, 0);
              const clonedDataSource = cloneDeep(origData);
              clonedDataSource.unshift(newLocationValue);
              setData(clonedDataSource);
              setSecondDropdown('');
            }}
          >
            + <span style={{ marginLeft: 5 }}>Add ingredient</span>
          </div>
        </div>
      </div>
      <div style={{ padding: '30px 20px 0px 15px' }}>
        <Table
          dataSource={userData}
          columns={isAdding ? columns2 : columns}
          pagination={getPagination}
          className={'show-custom-empty'}
          locale={{ emptyText: <Empty image={empty} description="No ingredients" /> }}
        />
      </div>{' '}
      <div className="add-checklistLoc">
        <div
          className="add-checklist-but"
          onClick={() => {
            setAdding(true);
            window.scrollTo(0, 0);
            const clonedDataSource = cloneDeep(origData);
            clonedDataSource.unshift(newLocationValue);
            setData(clonedDataSource);
          }}
        >
          <span>
            <PlusOutlined />
          </span>{' '}
          Add ingredient
        </div>
      </div>
    </div>
  );
};

export default SuperIngredientsMobile;
