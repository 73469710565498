/* eslint eqeqeq: "off" */
import { request } from './http.service';
import { notification } from 'antd';
import { findIndex } from 'lodash';

export const login = (email, password, rememberMe) => {
  return request('POST', '/operator/login', 200, { email, password, rememberMe }).then(
    (result) => {
      // callback if restaurant/location exists under user's account
      const verifyRestaurantExist = (restaurant, restaurantId) => {
        let verified = {
          restaurant: restaurant,
          restaurantId: restaurantId,
        };
        let restos = (result || {}).restaurants;
        if (restos && restos.length > 0) {
          let restoArr = restos.map((r) => {
            // r.name = r.Restaurant.name;
            // r.name = r.name;
            return r;
          });

          let restoId = verified.restaurantId;
          let resto = verified.restaurant || {};
          // let restoName = JSON.parse(localStorage.getItem('restaurant')) || {};

          const restoExists = restos.some((r) => r.id == resto.id && r.name == resto.name);
          verified['restaurant'] = restoExists ? resto : { id: restoArr[0].id, name: restoArr[0].name };
          verified['restaurantId'] = restoExists ? restoId : restoArr[0].id;
        }
        localStorage.setItem('restaurant', JSON.stringify(verified.restaurant));
        localStorage.setItem('restaurantId', JSON.stringify(verified.restaurantId));
      };
      // callback to set important keys with backed up value
      const checkKeyExists = (key, browserProfile = null, browserRecords = null, profileIndex = null) => {
        let cacheValue = null;
        if (browserProfile && Object.keys(browserProfile).includes(key)) {
          try {
            const toParse = JSON.parse(browserProfile[key]);
            if (toParse && typeof toParse === 'object') {
              cacheValue = toParse;
            }
          } catch (e) {
            cacheValue = browserProfile[key];
          }
        } else {
          if (localStorage.getItem(key) != undefined) {
            try {
              const toParse = JSON.parse(localStorage.getItem(key));
              if (toParse && typeof toParse === 'object') {
                cacheValue = toParse;
              }
            } catch (e) {
              cacheValue = localStorage.getItem(key);
            }
          }
        }
        if (cacheValue) {
          if (browserProfile) {
            delete browserProfile[key];
            browserRecords[profileIndex] = browserProfile;
            localStorage.setItem('browserProfiles', JSON.stringify(browserRecords));
          }
          // temporary code to replace old 'menuOrder'/'checklistOrder'/'documentOrder'
          if (
            (
              key === 'menuOrder' ||
              key === 'checklistOrder' ||
              key === 'documentOrder'
            ) && (
              cacheValue.length && 
              cacheValue[0]?.type === 'parent'
            )
          ) {
            return;
          } else {
            if (typeof cacheValue === 'string') {
              localStorage.setItem(key, cacheValue);
            } else {
              localStorage.setItem(key, JSON.stringify(cacheValue));
            }
          }
        }
      };
      // if login successful
      if (result) {
        localStorage.setItem('operator', JSON.stringify(result));
        localStorage.setItem('currentOperatorId', result.id.toString());
        const currentUserId = result.id.toString();

        // important keys to retain and backup (other than 'restaurant' and 'restaurantId')
        const importantKeys = [
          'menuOrder', 'checklistOrder', 'documentOrder',
          'menuColKeyOrder', 'checklistColKeyOrder', 'documentColKeyOrder',
        ];

        // for checking if profile within 'browserProfiles' exists and/or 'browserProfiles' exist in localStorage
        let foundProfile = null;
        let browserRecords = [];

        // if 'browserProfiles' exist, find the matching record of user logging in
        if (localStorage.getItem('browserProfiles') && JSON.parse(localStorage.getItem('browserProfiles')).length > 0) {
          browserRecords = JSON.parse(localStorage.getItem('browserProfiles'));
          foundProfile = findIndex(browserRecords, (prof) => prof.userId === currentUserId && prof?.isLastLocation);
        }

        // if user logging in has existing profile and/or 'browserProfiles' exist
        if (foundProfile !== null && foundProfile >= 0) {
          const browserProfile = browserRecords[foundProfile];
          verifyRestaurantExist(JSON.parse(browserProfile['restaurant']), JSON.parse(browserProfile['restaurantId']));
          importantKeys.forEach((key) => {
            checkKeyExists(key, browserProfile, browserRecords, foundProfile)
          });
        } else {
          const restaurant = localStorage.getItem('restaurant') ? JSON.parse(localStorage.getItem('restaurant')) : null;
          const restaurantId = localStorage.getItem('restaurantId')
            ? JSON.parse(localStorage.getItem('restaurantId'))
            : null;
          verifyRestaurantExist(restaurant, restaurantId);
          importantKeys.forEach((key) => checkKeyExists(key));
        }

        return Promise.resolve(result);
        // else invalidate login
      } else {
        return Promise.reject('Invalid login');
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const searchAccounts = (text, from, limit) => {
  return request('POST', '/operator/search', 200, { text, from, limit }).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const logout = () => {
  localStorage.removeItem('operator');
  localStorage.removeItem('rememberMe');
  localStorage.removeItem('impersonate');
  window.location = '/';
};

export const create = (params) => {
  // { email: 'test@admin.com', username: 'testadmin', password: 'password', firstName: 'Test', lastName: 'Admin', roleId: 1}
  // set base url for activation email
  params.baseUrl = window.location.origin + '/activate';
  return request('POST', '/operator', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      notification.open({
        message: `User not created. ${error}`,
      });
      console.log(error);
    }
  );
};

export const update = (email, params) => {
  // { email: 'test@admin.com', username: 'testadmin', firstName: 'Test', lastName: 'Admin', roleId: 1}
  return request('PUT', '/operator/' + email, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const resetPassword = (email, params) => {
  // { activeToken: '....', password: 'newPassword' }
  return request('PATCH', '/operator/' + email + '/resetPassword', 200, params).then(
    (result) => {
      if (result) {
        localStorage.setItem('operator', JSON.stringify(result));
        let restos = (result || {}).restaurants;
        if (restos) {
          let restoArr = restos.map((r) => {
            r.name = r.Restaurant.name;
            return r;
          });
          restoArr.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          let selectedResto = localStorage.getItem('restaurant');
          let restaurantId = localStorage.getItem('restaurantId');
          if (!selectedResto && !restaurantId) {
            localStorage.setItem(
              'restaurant',
              JSON.stringify({ id: restoArr[0].restaurantId, name: restoArr[0].name })
            );
          }
        }
        return result;
      } else {
        return 'Error with creating password';
      }
    },
    (error) => {
      console.log(error);
      throw error;
    }
  );
};

export const requestResetPassword = (email) => {
  // set base url for activation email
  return request('PATCH', '/operator/' + email + '/requestResetPassword', 200, {
    baseUrl: window.location.origin + '/activate',
  }).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
      throw error;
    }
  );
};

export const destroy = (email) => {
  return request('DELETE', '/operator/' + email, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const undelete = (email) => {
  return request('GET', '/operator/' + email + '/undelete', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const get = (email) => {
  return request('GET', '/operator/' + email, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const all = (from, limit) => {
  return request('GET', '/operator/' + from + '/' + limit, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const roles = (from, limit) => {
  return request('GET', '/operator/roles', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const impersonate = (accountId) => {
  return request('GET', '/operator/impersonate/' + accountId, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const unimpersonate = () => {
  return request('GET', '/operator/unimpersonate', 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const saveSettings = (email, settings) => {
  return request('PUT', '/operator/settings/' + email, 200, { settings: settings }).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const loadSettings = (email) => {
  return request('GET', '/operator/settings/' + email, 200).then(
    (settings) => {
      return settings;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const activateUser = (id, by) => {
  return request('PUT', '/operator/activate/' + id, 200, { by: by }).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const deactivateUser = (id, by) => {
  return request('PUT', '/operator/deactivate/' + id, 200, { by: by }).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};
