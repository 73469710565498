import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
// import { kitchenStationData } from '../../data/kitchendata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import add from '../../img/add-orange.png';
import { Row, Col, Progress, Menu, Dropdown } from 'antd';
import { deleteKitchenStation, getAllStationOperators } from '../../services/station.service';
import { createStation, getKitchenStationList } from './KitchenStationUtils';
import { useHistory } from 'react-router-dom';
import './index.css';
import RoleAccessibleComponent from '../RoleAccess/roleaccess';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import { UserImage, StationAssigneeEdit } from '../../components/common/common_ui';

const newValue = {
  id: 0,
  key: 0,
  station: '',
  percentage: 0,
  assignee_id: 0,
  assignee: '',
  assigneeImg: '',
};

const KitchenStationMobile = () => {
  const [kitchenStations, setKitchenStations] = useState([]);
  const [newKitchenStation, setNewKitchenStation] = useState(null);
  const [searchedAssignee, setSearchedAssignee] = useState('');
  const [operatorsToChoose, setOperatorsToChoose] = useState([]);
  const [locationOperators, setLocationOperators] = useState([]);
  // const [hasCreatedStation, setHasCreatedStation] = useState(false);
  const [addKitchenUpdate, setAddKitchenUpdate] = useState('');
  const [editKitchenUpdate, setEditKitchenUpdate] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/station`));
  const [hasCreatedKitchen, setHasCreatedKitchen] = useState(false);

  const history = useHistory();
  const resto_id = localStorage.getItem('restaurantId');
  const handleOnClick = useCallback((id, restaurantId) => history.push({ pathname: `/station/${id}`, state: { restaurantId: restaurantId.length ? restaurantId * 1 : resto_id } }), [history]);

  const handleUpdate = useCallback(
    () =>
      setTimeout(() => {
        eventSocket.emit('updatesKitchen', 'updatedKitchen');
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kitchenStations]
  );

  const node1 = useRef();

  // const isValidated = newKitchenStation
  //   ? newKitchenStation.station !== newValue.station &&
  //     newKitchenStation.station.trim() !== '' &&
  //     newKitchenStation.assignee_id > 0
  //   : false;

  useEffect(() => {
    setOperatorsToChoose([]);
    setLocationOperators([]);
    let restoId = window.location.href.includes('impersonate') ? localStorage.getItem('restaurantId') : resto_id;
    getAllStationOperators(restoId).then((res) => {
      setLocationOperators(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href, resto_id]);

  useEffect(() => {
    if (locationOperators.length > 0) {
      setOperatorsToChoose(locationOperators);
    }
  }, [locationOperators]);

  useEffect(() => {
    if (searchedAssignee !== '') {
      const likeOperators = locationOperators.filter(
        (op) =>
          op.firstName.toLowerCase().indexOf(searchedAssignee.toLowerCase()) !== -1 ||
          op.lastName.toLowerCase().indexOf(searchedAssignee.toLowerCase()) !== -1
      );
      setOperatorsToChoose(likeOperators);
    } else {
      if (locationOperators.length > 0) {
        setOperatorsToChoose(locationOperators);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedAssignee]);

  // useEffect(() => {
  //   if (window.location.href.includes('impersonate')) {
  //     const restoId = localStorage.getItem('restaurantId');
  //     getKitchenStationList(setKitchenStations, restoId);
  //   } else {
  //     getKitchenStationList(setKitchenStations, resto_id);
  //   }
  // }, [hasCreatedStation, window.location.href]);

  useEffect(() => {
    if (hasCreatedKitchen) {
      eventSocket.emit('addsKitchen', 'addedKitchen');
      setHasCreatedKitchen(false);
      handleUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCreatedKitchen]);

  // const validateKitchen = () => {
  //   if (isValidated) {
  //     handleAddStations();
  //   }
  // };

  useEffect(() => {
    console.log('NEWKITCHEN', newKitchenStation);
    if (newKitchenStation !== null) {
      document.addEventListener('mousedown', handleClick);
      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newKitchenStation]);

  const handleClick = (e) => {
    if (node1.current && node1.current.contains(e.target)) {
      return;
    }
    // outside click
    // validateKitchen();
    handleAddStations();
  };

  // const totalCompletedPercentages =
  //   kitchenStations.length > 0
  //     ? kitchenStations.reduce((a, b) => ({ percentage: parseInt(a.percentage) + parseInt(b.percentage) }))
  //     : { percentage: 0 };
  // const totalPercentages = kitchenStations.length * 100;
  // const filledPercentages = kitchenStations.filter((p) => p.percentage !== '0');
  // const totalPercentages = filledPercentages.length * 100;
  // const accumulativePerc = Math.round((totalCompletedPercentages.percentage / totalPercentages) * 100);

  const handleAddStations = useCallback(() => {
    let restoId = window.location.href.includes('impersonate') ? localStorage.getItem('restaurantId') : resto_id;
    if (
      newKitchenStation.station !== newValue.station &&
      newKitchenStation.station.trim() !== '' &&
      newKitchenStation.assignee_id > 0
    ) {
      createStation(newKitchenStation, resetForm, restoId, setHasCreatedKitchen);
    }
  }, [newKitchenStation, resto_id]);

  const resetForm = () => {
    setNewKitchenStation();
    setSearchedAssignee('');
  };

  // const content = (
  //   <div className="assignee-list">
  //     <List
  //       dataSource={operatorsToChoose}
  //       renderItem={(item) => (
  //         <List.Item
  //           className="meeting-events-list-item"
  //           onClick={() => {
  //             setNewKitchenStation({
  //               ...newKitchenStation,
  //               assignee_id: parseInt(item.id),
  //               assignee: `${item.firstName} ${item.lastName}`,
  //             });
  //           }}
  //         >
  //           <div>
  //             {item.firstName} {item.lastName}
  //           </div>
  //         </List.Item>
  //       )}
  //     />
  //   </div>
  // );

  // const toolTipText = 'Complete the fields';
  // const stationActions = (
  //   <Menu className="menu-show-preview">
  //     {[{ name: 'Save' }, { name: 'Delete' }].map((m, i) => {
  //       return (
  //         <Menu.Item
  //           className="menu-show-preview"
  //           disabled={!isValidated && m.name === 'Save'}
  //           onClick={() => {
  //             if (m.name === 'Save') {
  //               if (isValidated) {
  //                 handleAddStations();
  //               }
  //               // === save now
  //             } else {
  //               setNewKitchenStation();
  //             }
  //           }}
  //         >
  //           {!isValidated && m.name === 'Save' ? (
  //             <Tooltip placement="right" title={toolTipText}>
  //               <span style={{ color: 'gray' }}>{m.name}</span>
  //             </Tooltip>
  //           ) : (
  //             <span style={{ color: 'white' }}>{m.name}</span>
  //           )}
  //         </Menu.Item>
  //       );
  //     })}
  //   </Menu>
  // );

  const deleteAction = (id) => {
    return (
      <Menu className="menu-show-preview">
        <Menu.Item
          className="menu-show-preview"
          onClick={(e) => {
            deleteKitchenStation(id).then((res) => {
              // setHasCreatedStation(true);
              handleUpdate();
            });
          }}
        >
          <span>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const reloadAfterAdding = (message) => {
    console.log('KITCHENADDED!!', message);
    setAddKitchenUpdate(message);
  };

  const reloadAfterUpdating = (message) => {
    console.log('KITCHENUPDATED!!', message);
    setEditKitchenUpdate(message);
  };

  // useEffect(() => {
  //   if (addKitchenUpdate || editKitchenUpdate) {
  //     getKitchenStationList(setKitchenStations, resto_id);
  //   }
  // }, [addKitchenUpdate, editKitchenUpdate]);

  const reloadKitchenStations = () => {
    let restoId =
      addKitchenUpdate && window.location.href.includes('impersonate')
        ? localStorage.getItem('restaurantId')
        : resto_id;
    getKitchenStationList(setKitchenStations, restoId);
  };

  const reloadAccumulativePerc = (kitchenStations) => {
    const filterTotalCompleted = kitchenStations.filter((k) => k.total_items !== '0');
    const totalCompletedIngredients =
      filterTotalCompleted.length > 0
        ? filterTotalCompleted.reduce((a, b) => {
            return {
              total_items: parseInt(a.total_items) + parseInt(b.total_items),
              completed_items: parseInt(a.completed_items) + parseInt(b.completed_items),
            };
          })
        : 0;
    const accumulativePerc = Math.round(
      Math.round((totalCompletedIngredients.completed_items / totalCompletedIngredients.total_items) * 100)
    );
    return accumulativePerc;
  };

  useEffect(() => {
    eventSocket.on('addedKitchen', (message) => {
      reloadAfterAdding(message);
    });
    eventSocket.on('updatedKitchen', (message) => {
      reloadAfterUpdating(message);
    });
    return () => {
      eventSocket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reloadKitchenStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href, addKitchenUpdate, editKitchenUpdate]);

  const _kitchenStations = useMemo(() => kitchenStations, [kitchenStations]);

  const _accumulativePerc = useMemo(() => reloadAccumulativePerc(kitchenStations), [kitchenStations]);

  return (
    <div className="main marginL10">
      <div>
        <div style={{ padding: 10 }}>
          <Row gutter={32}>
            <Col span={3}>{isNaN(_accumulativePerc) ? `` : `${_accumulativePerc}%`}</Col>
            <Col span={18}>
              <Progress percent={_accumulativePerc} showInfo={false} />
            </Col>
            <Col span={3}>
              <RoleAccessibleComponent
                permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
              >
                <img
                  src={add}
                  onClick={() => setNewKitchenStation(newValue)}
                  alt="add-button"
                  className="add-box-kitchen"
                  height="30"
                />
              </RoleAccessibleComponent>
            </Col>
          </Row>
        </div>
        {newKitchenStation && (
          <Row gutter={32}>
            <Col className="gutter-row" span={24}>
              <div className="event-station-mobile-box">
                <div className="day-mobile-progress">
                  <Row gutter={32}>
                    <Col span={3}>{newKitchenStation.percentage}%</Col>
                    <Col span={18}>
                      <Progress percent={newKitchenStation.percentage} showInfo={false} />
                    </Col>
                    <Col span={3}>
                      {/* <Dropdown overlay={stationActions} trigger={['click']}>
                        <div>
                          <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                        </div>
                      </Dropdown> */}
                    </Col>
                  </Row>
                  <Row gutter={32}>
                    <Col style={{ padding: 0 }} span={6}>
                      <div className="station-tile-assignee-image-mobile" alt="user-photo">
                        <UserImage src={null} />
                      </div>
                    </Col>
                    <Col span={18}>
                      <div className="event-mobile" ref={node1}>
                        <input
                          autoFocus
                          type="text"
                          className="kitchen-station-name-input1-mobile"
                          placeholder="Station Name"
                          value={newKitchenStation.station}
                          onChange={(e) => {
                            setNewKitchenStation({ ...newKitchenStation, station: e.target.value });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              // validateKitchen();
                              handleAddStations();
                            }
                          }}
                        />
                      </div>
                      <div className="cancel-kitchen2" onClick={() => setNewKitchenStation()}>
                        Cancel
                      </div>
                      {newKitchenStation && newKitchenStation.assignee_id > 0 ? (
                        <div
                          onClick={() => {
                            setNewKitchenStation({
                              ...newKitchenStation,
                              id: 0,
                              key: 0,
                              assignee_id: 0,
                              assigneeImg: '',
                              assignee: '',
                            });
                            setSearchedAssignee('');
                          }}
                        >
                          {newKitchenStation.assignee}
                        </div>
                      ) : (
                        <div className="kitchen-station-assignee-container">
                          {/* CHANGE 1202077200318274 */}
                          <StationAssigneeEdit
                            assigneeChoices={operatorsToChoose}
                            assignee={searchedAssignee}
                            onChange={(value) => {
                              setSearchedAssignee(value.name);
                              if (value.name.trim() === '') {
                                setOperatorsToChoose(locationOperators);
                              }
                            }}
                            onSearch={(value) => {
                              setSearchedAssignee(value);
                            }}
                            onClickRemove={() => {
                              setSearchedAssignee('');
                              setOperatorsToChoose(locationOperators);
                            }}
                          />
                          {/* <Popover content={content} placement="bottom" trigger="focus">
                            <input
                              type="text"
                              placeholder="Search Assignee"
                              value={searchedAssignee}
                              onChange={(e) => {
                                setSearchedAssignee(e.target.value);
                                // if(e.target.value.trim()===""){
                                //   setOperatorsToChoose([])
                                // }
                              }}
                              className="kitchen-station-assignee-input-mobile"
                            />
                          </Popover> */}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {_kitchenStations.map((kitchen, i) => {
          return (
            <Row gutter={32}>
              <Col className="gutter-row" span={24}>
                <div className="event-station-mobile-box">
                  <div className="day-mobile-progress">
                    <Row gutter={32}>
                      {kitchen.total_items !== 0 ? <Col span={3}>{kitchen.percentage}%</Col> : ''}
                      {kitchen.total_items !== 0 ? (
                        <Col span={18}>
                          <Progress percent={kitchen.percentage} showInfo={false} />
                        </Col>
                      ) : (
                        ''
                      )}
                      <Col span={3}>
                        <RoleAccessibleComponent permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin']}>
                          <Dropdown overlay={deleteAction(kitchen.id)} trigger={['click']}>
                            <div>
                              <img src={IconsMoreOff} className="UI-IconsMoreOff-mobile" alt="IconsMoreOff" />
                            </div>
                          </Dropdown>
                        </RoleAccessibleComponent>
                      </Col>
                    </Row>
                    <Row gutter={32} onClick={() => handleOnClick(kitchen.id, kitchen.restaurantId)}>
                      <Col style={{ padding: 0 }} span={6}>
                        <div className="station-tile-assignee-image-mobile" alt="user-photo">
                          <UserImage src={kitchen.assigneeImg !== '' ? kitchen.assigneeImg : null} />
                        </div>
                      </Col>
                      <Col span={18}>
                        <div className="event-mobile">{kitchen.station}</div>
                        <div className="event-mobile-date">{kitchen.assignee}</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
};

export default KitchenStationMobile;
