/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useRef } from 'react';
//import { Link } from 'react-router-dom';
import './index.css';
import burger from '../../img/burger-icon.png';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { slide as MenuBurger } from 'react-burger-menu';
import { Menu, Dropdown } from 'antd';
import AdminMenuMobile from '../../components/AdminMenu/AdminMenuMobile.js';
import { get, requestResetPassword, roles, update } from '../../services/operator.service';
import { allLocations } from '../../services/restaurant.service';
import { onFileSelected } from '../../services/upload.service';
import { BacktoHeaderMobile } from '../../components/Header/HeadMobile.js';
import { CompletionIcon } from '../../components/common/common_ui';

const UsersEditMobile = (props) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [user, setUser] = useState('');
  const [role, setRole] = useState('');
  const [locations, setLocations] = useState([]);
  const [image, setImage] = useState('');
  //const [loading, setLoading] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [allRoles, setRoles] = useState('');

  const fileInput = useRef();
  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';

  //user settings
  //const [dayMode, setDayMode] = useState(false);

  const cancelForm = () => {
    window.location.reload();
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = () => {
    get(props.match.params.id).then((res) => {
      console.log(res);
      setImage(res.info ? res.info?.userImageThumb || res.info?.userImage || '' : '');
      setFirstname(res.firstName);
      setLastname(res.lastName);
      setEmail(res.email);
      setUser(res.username);
      setRole(res.role.id);

      let restoIds = res.restaurants.map((r) => r.restaurantId);
      setSelectedLocations(restoIds);
      getLocations(restoIds);
    });
  };

  const saveUser = () => {
    let selectedIds = selectedLocations;
    const params = {
      firstName: firstname.trim(),
      lastName: lastname.trim(),
      email: email.trim(),
      username: user.trim(),
      roleId: role,
      restaurantIds: selectedIds,
      accountId: accountId,
      info: {
        userImage: image,
        dayMode: false,
      },
    };

    update(props.match.params.id, params).then((res) => {
      console.log(res);
      if (res) {
        // notification.open({
        //   message: 'Successfully updated user!',
        // });
        props.history.push('/admin/users');
      }
    });
    console.log('EDITED USER', params);
  };

  const controlItems = () => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">Delete</Menu.Item>
      </Menu>
    );
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    requestResetPassword(props.match.params.id).then(
      (res) => {
        openNotification('success', email);
      },
      (err) => {
        openNotification('error', err);
      }
    );
  };

  const openNotification = (type, mssg) => {
    if (type === 'success') {
      // notification.open({
      //   message: 'Password Successfully Reset',
      //   description: `An email has been sent to ${mssg} with reset instructions.`,
      // });
    } else {
      // notification.open({
      //   message: 'Error Resetting Password',
      //   description: mssg,
      // });
    }
  };

  const getLocations = (ids) => {
    allLocations(0, 1000).then((res) => {
      console.log(res);
      let allLoc = res;
      let address = allLoc.map((l) => {
        return {
          id: l.id,
          name: l.name,
          address: l.info && l.info?.address ? l.info?.address : '',
        };
      });

      address.forEach((p) => {
        if (ids.includes(p.id)) {
          p.checked = true;
        } else {
          p.checked = false;
        }
      });
      console.log(ids);
      console.log(address);
      setLocations(address);
    });
  };

  const getRoles = () => {
    roles().then((res) => {
      console.log(res);
      setRoles(res);
      let getBasic = res.filter((r) => r.name !== 'Superadmin');
      setRoles(getBasic);
    });
  };

  useEffect(() => {
    getRoles();
  }, []);

  const selectedLocation = () => {
    const values = Array.from(document.querySelectorAll('input[type="checkbox"]'))
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => JSON.parse(checkbox.value));

    console.log(values);
    console.log(locations);

    let ids = values.map((v) => v.id);
    setSelectedLocations(ids);
    let address = locations;
    address.forEach((p) => {
      if (ids.includes(p.id)) {
        p.checked = true;
      } else {
        p.checked = false;
      }
    });
    console.log(selectedLocations);
    setLocations(address);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const uploadImg = (e) => {
    //setLoading(true);
    e.preventDefault();
    e.stopPropagation();

    onFileSelected(e.target.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        setImage(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        // setLoading(false);
      } else {
        //setLoading(false);
        let userimage = URL.createObjectURL(e.target.files[0]);
        setImage(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const handleOnDrop = (e) => {
    //setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    console.log(e.dataTransfer.files[0]);

    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        setImage(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        // setLoading(false);
      } else {
        //setLoading(false);
        let userimage = URL.createObjectURL(e.dataTransfer.files[0]);
        setImage(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  return (
    <div>
      {' '}
      <MenuBurger customCrossIcon={<img src={burger} alt="menu" />}>
        <AdminMenuMobile />
      </MenuBurger>
      <div style={{ marginLeft: 20, fontSize: 16 }}>Admin</div>
      <BacktoHeaderMobile restaurant="Edit User" backText="Back to users" backLink="/admin/users" />
      <div className="Line"></div>
      <div>
        <div className="userTitle-Mob">Edit user</div>
        <br />
        <div>
          <div>
            <div>
              {image !== '' ? (
                <img src={image} className="user-photo16" style={{ marginLeft: 30 }} alt="user" />
              ) : (
                <div
                  className="upload-boxMob"
                  onClick={() => fileInput.current.click()}
                  onDragOver={handleDragOver}
                  onDrop={handleOnDrop}
                >
                  Upload
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => uploadImg(e)}
                    ref={fileInput}
                    accept="image/*"
                  />
                </div>
              )}
            </div>
            <Dropdown overlay={controlItems}>
              <img src={IconsMoreOff} style={{ marginTop: 15, marginLeft: 135 }} alt="seemore" />
            </Dropdown>
          </div>
          <div className="userInfo-data1">
            <div style={{ marginLeft: 5 }}>First name</div>
            <input
              placeholder="Enter first name"
              type="text"
              className="user-input12"
              defaultValue={firstname}
              onChange={(event) => setFirstname(event.target.value)}
            />
            <br />
            <div style={{ marginLeft: 5 }}>Last name</div>
            <input
              placeholder="Enter last name"
              type="text"
              className="user-input12"
              defaultValue={lastname}
              onChange={(event) => setLastname(event.target.value)}
            />
            <br />
            <div style={{ marginLeft: 5 }}>Email address</div>
            <input
              placeholder="Enter email address"
              type="text"
              className="user-input12"
              defaultValue={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <br />
            <div style={{ marginLeft: 5 }}>Username</div>
            <input
              placeholder="Enter username"
              type="text"
              className="user-input12"
              defaultValue={user}
              onChange={(event) => setUser(event.target.value)}
            />
            <br />
            <div style={{ marginLeft: 5 }}>Password</div>
            <div
              style={{ color: 'var(--accent-color)', marginTop: 15, marginLeft: 5, marginBottom: 10 }}
              onClick={(e) => handlePasswordReset(e)}
            >
              Reset Password
            </div>
            <br />
            <div style={{ marginLeft: 5 }}>Role</div>
            <select className="user-select21" value={role} onChange={(event) => setRole(event.target.value)}>
              <option initialvalue="default">Select</option>
              {allRoles &&
                allRoles.length > 0 &&
                allRoles.map((m) => {
                  return <option value={m.id}>{m.name}</option>;
                })}
            </select>
            <div style={{ marginLeft: 5, marginTop: 20 }}>
              Locations
              <span className="user-locations1">
                {' '}
                ( {selectedLocations.length > 0 ? selectedLocations.length : 0} of {locations.length} locations selected
                )
              </span>
              <div style={{ marginLeft: '-10px', marginTop: 15 }}>
                {locations &&
                  locations.length > 0 &&
                  locations.map((l) => {
                    return (
                      <div style={{ display: 'flex' }}>
                        <input
                          type="checkbox"
                          id={l.id}
                          value={JSON.stringify({ id: l.id, name: l.name, address: l.address })}
                          onChange={selectedLocation}
                          checked={l.checked}
                          style={{ display: 'none' }}
                        />
                        <label for={l.id} className="checklabel">
                          <span className="check-task">
                            <CompletionIcon completed={l.checked} dimmed={!l.checked} />
                          </span>
                        </label>
                        <div style={{ marginLeft: 10, marginTop: 5 }}>
                          {l.name} - {l.address}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <br />
            <div className="btn-container">
              <button className="save-btn-recipe" onClick={saveUser}>
                Save
              </button>
              <button className="cancel-btn-recipe" onClick={cancelForm}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersEditMobile;
