/* eslint eqeqeq: "off" */
import { request } from './http.service';
import { decodePrepMessages } from '../protobuf-models/prep.proto.js';

export const create = (params) => {
  return request('POST', '/preparation', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const update = (id, params) => {
  return request('PUT', '/preparation/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroy = (id) => {
  return request('DELETE', '/preparation/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const get = (id) => {
  return request('GET', '/preparation/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchprep = (params) => {
  let finalParams = params;
  if (params.isSuperAdmin == undefined) {
    finalParams.isSuperAdmin = false;
  }
  return request('POST', '/preparation/search', 200, finalParams, decodePrepMessages).then(
    (result) => {
      if (result && result.preparations) {
        let all = result.preparations.map((f) => {
          f.info = JSON.parse(f.info);
          return f;
        });
        return all;
      }
    },
    (error) => {
      // console.log(error);
    }
  );
};
