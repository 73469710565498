/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Drawer, TreeSelect, Spin } from 'antd';
import useWindowSize from '../../components/windowdimensions/windowSize';
import { search, count } from '../../services/ingredient.service';
import { getCategories } from '../../services/category.service';
import _, { has, findIndex } from 'lodash';
import { PlusButton, ThumbnailImage, ExpandingSearchPanel } from '../../components/common/common_ui';

const AddIngredientPopup = (props) => {
  const { newIngredients, isDrawerOpen, closeDrawer } = props;

  const windowSize = useWindowSize();

  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';

  const { TreeNode } = TreeSelect;

  const [visible, updateVisible] = useState(false);
  const [newOrders, setNewOrders] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [totalIngredients, setTotalIngredients] = useState(0);
  const [ingData, setIngData] = useState([]);
  const [ingCategoryChoices, setIngCategoryChoices] = useState([]); // BUG 1202076501452783
  const [categoryChoices, setCategoryChoices] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState(undefined);

  const [isFetching, setIsFetching] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [nameFilter, setNameFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);

  // container of ingredient lists
  const listInnerRef = useRef();

  const resto = localStorage.getItem('restaurantId') || '0';

  // upon mounting, make sure to scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
    // BUG 1202076501452783
    if (ingCategoryChoices.length < 1) {
      getCategories(accountId.toString()).then((res) => {
        setIngCategoryChoices(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // scrolling increases starting offset of ingredient API fetch query per 1000s
  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      // if not all are fetched yet, start fetching
      // avoid batched fetching while user is currently searching or filtering
      // BUG 1202076501452775
      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        setIsFetching(true);
      }
    }
  };

  // if isFetching, trigger callback fetchMoreListItems()
  useEffect(() => {
    if (!isFetching || totalIngredients <= 400) return;
    fetchMoreListItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  // BUG 1202076501452783
  const buildCategoryDropdown = (res) => {
    if (res) {
      const catTree = res.reduce((categories, item) => {
        let newSubCategory = {
          id: parseInt(item.id),
          title: item.subcategory,
          generatedNumber: JSON.stringify(Math.random()),
        };
        if (categories.find((d) => d.category === item.category)) {
          const categoryIndex = findIndex(categories, (c) => c.category === item.category);
          let subcategories = categories[categoryIndex].children;
          let categoryIds = categories[categoryIndex].categoryIds;
          subcategories.push(newSubCategory);
          categories[categoryIndex].children = subcategories;
          categories[categoryIndex].categoryIds = categoryIds.concat(`-${item.id}`);
        } else {
          let subcategories = [];
          subcategories.push(newSubCategory);
          let newCategory = {
            category: item.category,
            categoryIds: `${item.id}`,
            generatedNumber: JSON.stringify(Math.random()),
            children: subcategories,
          };
          categories.push(newCategory);
        }
        return categories;
      }, []);
      catTree.forEach((category) => {
        category.children.sort((a, b) => a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase()));
      });
      catTree.sort((a, b) => a?.category?.toLowerCase().localeCompare(b?.category?.toLowerCase()));
      setCategoryChoices(catTree);
    }
  };

  useEffect(() => {
    if (categoryChoices.length < 1) {
      buildCategoryDropdown(ingCategoryChoices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingCategoryChoices]);

  // reformat ingredient results
  const reformatIngredientData = (ingredients, toBackUp) => {
    let formattedIng = ingredients.map((i) => {
      let parsed = has(i, 'info') ? JSON.parse(i.info) : {};
      i.info = parsed;
      parsed.categoryId = i.categoryId;
      i.category = parsed?.category ? parsed.category : 'Uncategorized';
      i['sub category'] = has(parsed, 'sub category') ? parsed['sub category'] : 'Unsubcategorized';
      i.quantity = 0;
      i.cost = 0;
      i.measurement = 'Select';
      i.station = 'Select';
      i.preparation = 'Select';
      i.imageUrlThumb =
        has(parsed, 'imageUrlThumb') || has(parsed, 'imageUrl')
          ? parsed.imageUrlThumb || parsed.imageUrl
          : has(i, 'imageUrlThumb') || has(i, 'imageUrl')
          ? i.imageUrlThumb || i.imageUrl
          : '';
      return i;
    });
    formattedIng.sort((x, y) => {
      return x.id - y.id;
    });
    if (toBackUp) {
      setIngData((currentData) => [...currentData, ...formattedIng]);
      setIsLoading(false);
    } else {
      setIngData(formattedIng);
      setIsLoading(false);
    }
  };

  // for fetching more ingredients from database while scrolling drawer
  const fetchMoreListItems = () => {
    setIsLoading(true);
    const totalDisplayedIngredients = ingData.length;
    // if overall ingredient results queried exceed 10000, abort
    if (totalIngredients === totalDisplayedIngredients) {
      setIsLoading(false);
      setCategoryTitle(undefined);
      return;
    }

    // when user reopens and scrolls down
    // no need to reload next batches when already downloaded
    if (totalIngredients > totalDisplayedIngredients) {
      setTimeout(() => {
        setIsFetching(false);
        let params = {
          from: offset, // gets the current offset value from previous query
          limit: 1000,
          accountId: accountId,
        };

        if (isSearching) {
          params.name = nameFilter.toLowerCase();
        }

        if (isFiltering) {
          const categoryIds = categoryFilter.id.toString();
          params.categoryId = categoryIds;
        }

        search(params).then((res) => {
          if (res.ingredients) {
            setOffset((current) => current + 400);
            reformatIngredientData(res.ingredients, true);
          }
        });
      }, 3500);
    }
  };

  // shows Drawer
  // when Page drawer state is open, show drawer
  useEffect(() => {
    if (isDrawerOpen) {
      updateVisible(true);
    }
  }, [isDrawerOpen]);

  // resets necessary state variables when closing drawer
  const onClose = () => {
    updateVisible(false);
    setCategoryTitle(undefined);
    setShowSearch(false);
    setNameFilter(null);
    setIsLoading(false);
    setIsSearching(false);
    setIsFiltering(false);
    setNewOrders([]);
    setIngData([]);
    closeDrawer();
  };

  // select an ingredient to add
  const selectIngredient = (ingredient) => {
    if (newOrders.some((item) => item.name === ingredient.name)) {
      const filterSelected = newOrders.filter((item) => item.name !== ingredient.name);
      setNewOrders(filterSelected);
    } else {
      setNewOrders((ingredientlist) => [...ingredientlist, ingredient]);
    }
  };

  // add selected ingredients to Page parent component
  const addIngredients = () => {
    //transmit data to parent
    newIngredients(newOrders);
    onClose();
  };

  // filter ingredients
  const filterIngs = (category) => {
    setTotalIngredients(0);
    setOffset(0);
    let params = {
      accountId: accountId,
      categoryId: category.id.toString(),
      from: offset,
      limit: 400,
    };

    const countSearchedResults = async () => {
      return await count({ restaurantId: resto, categoryId: category.id.toString() }).then((res) => {
        return res;
      });
    };

    const returnFilteredResults = async (params) => {
      return await search(params).then((res) => {
        return res;
      });
    };

    const startFiltering = async (params) => {
      return Promise.all([countSearchedResults(), returnFilteredResults(params)]).then((res) => {
        const totalCount = res[0];
        const filterResults = res[1]?.ingredients;
        setIsLoading(true);
        setIsFetching(false);
        setTotalIngredients(totalCount);
        if (filterResults && filterResults.length > 0) {
          setOffset((current) => current + filterResults.length);
          reformatIngredientData(filterResults, false);
        }
      });
    };

    startFiltering(params).then(() => {
      setIsLoading(false);
      if (isSearching) {
        setIsSearching(false);
      }
    });
  };

  // debounce search callback
  const debouncedApiCall = useCallback(
    _.debounce((value) => {
      searchIngs(value);
    }, 300),
    []
  );

  // if search keyword changes, including ''
  useEffect(() => {
    if (nameFilter && nameFilter !== '') {
      setIsSearching(true);
      debouncedApiCall(nameFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter, debouncedApiCall]);

  // search ingredients based on keyword
  const searchIngs = (typed) => {
    setTotalIngredients(0);
    setOffset(0);
    let keyword = typed.toLowerCase();
    let params = {
      from: offset, // gets the current offset value from previous query
      limit: 400,
      accountId: accountId,
      name: keyword,
    };

    const countSearchedResults = async () => {
      return await count({ restaurantId: resto, name: keyword }).then((res) => {
        return res;
      });
    };

    const returnSearchedResults = async () => {
      return await search(params).then((res) => {
        return res;
      });
    };

    const startSearching = async () => {
      return Promise.all([countSearchedResults(), returnSearchedResults()]).then((res) => {
        const totalCount = res[0];
        const searchResults = res[1]?.ingredients;
        setIsLoading(true);
        setIsFetching(false);
        setTotalIngredients(totalCount);
        if (searchResults && searchResults.length > 0) {
          setOffset((current) => current + searchResults.length);
          reformatIngredientData(searchResults, false);
        }
      });
    };

    startSearching().then(() => {
      setIsLoading(false);
      if (isFiltering) {
        setIsFiltering(false);
      }
    });
  };

  // change category from dropdown
  const onChangeIngredientCategory = (value) => {
    console.log(value);
    const obj = JSON.parse(value);
    setCategoryTitle(obj.title);
    setCategoryFilter(obj);
    setIsFiltering(true); // BUG 1202076501452775
    filterIngs(obj);
  };

  return (
    <div>
      <Drawer
        width={windowSize.width >= 842 ? '842px' : '100%'}
        onClose={onClose}
        visible={visible}
        closable={false}
        destroyOnClose={true}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <div style={{ overflow: 'auto', height: 780 }} onScroll={() => handleScroll()} ref={listInnerRef}>
          <div className="ingredients-loading-spinner">{isLoading && <Spin tip="Loading ingredients..."></Spin>}</div>
          <div className="add-popup-header">
            <div className={windowSize.width < 700 ? 'drawer-top-absolute' : ''}>
              <div className="add-popup-add-close-container">
                <div>
                  <div className="add-course-ing">
                    <PlusButton className="button-highlight" alt="Add selected ingredients" onClick={addIngredients}>
                      Add selected ingredients
                    </PlusButton>
                  </div>
                </div>
                {windowSize.width > 700 ? (
                  <div className="close-drawer" onClick={onClose}>
                    X <span className="close-text">Close</span>
                  </div>
                ) : (
                  <div className="close-drawer" onClick={onClose}>
                    X
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ overflow: 'hidden', position: 'relative' }}>
            <div className={`add-popup-header ${windowSize.width < 700 ? 'drawer-bottom-adjust' : ''}`}>
              <ExpandingSearchPanel
                className="flex-control-left"
                showSearch={showSearch}
                searchPrompt="Search ingredients"
                onChange={(e) => setNameFilter(e.target.value)}
                onClick={() => setShowSearch(true)}
                value={nameFilter}
              />
            </div>
          </div>
          <br />
          <div>
            <div className="warpper">
              <input className="radio" id="one" name="group" type="radio" defaultChecked />
              <input className="radio" id="two" name="group" type="radio" />
              <input className="radio" id="three" name="group" type="radio" />
              <div className="tabs">
                <label className="tab" id="one-tab" htmlFor="one">
                  List
                </label>
                <label className="tab-2" id="two-tab" htmlFor="two">
                  Selected ({newOrders.length})
                </label>
              </div>
              <div className="panels">
                <div className="panel" id="one-panel">
                  <div className="filter-ing12">
                    {categoryChoices && categoryChoices.length > 0 && (
                      <TreeSelect
                        showSearch
                        className="panel-filter-select"
                        value={categoryTitle}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Category"
                        showArrow={false}
                        allowClear
                        onChange={(value) => onChangeIngredientCategory(value)}
                      >
                        {categoryChoices.map((c) => {
                          return (
                            <TreeNode
                              key={`${c.generatedNumber}-${c}`}
                              value={JSON.stringify({
                                id: c.categoryIds,
                                type: 'parent',
                                title: c.category,
                                generatedNumber: c.generatedNumber,
                              })}
                              title={c.category}
                            >
                              {c.children.map((ch) => {
                                return (
                                  <TreeNode
                                    key={`${ch.generatedNumber}-${ch}`}
                                    value={JSON.stringify({
                                      id: ch.id,
                                      type: 'child',
                                      title: ch.title,
                                      generatedNumber: ch.generatedNumber,
                                    })}
                                    title={ch.title}
                                  ></TreeNode>
                                );
                              })}
                            </TreeNode>
                          );
                        })}
                      </TreeSelect>
                    )}
                  </div>

                  <div>
                    <ul className="flex-container wrap">
                      {visible &&
                        ingData.length > 0 &&
                        ingData.map((ingredient, i) => {
                          return (
                            <li key={i} className="flex-item" onClick={() => selectIngredient(ingredient)}>
                              <div
                                className={
                                  newOrders.some((item) => item.id === ingredient.id)
                                    ? 'ing-name-box-2'
                                    : 'ing-name-box'
                                }
                              >
                                <div className="ing-name-panel-1">
                                  <ThumbnailImage
                                    src={
                                      ingredient.info && (ingredient.info?.imageUrlThumb || ingredient.info?.imageUrl)
                                        ? ingredient.info?.imageUrlThumb || ingredient.info?.imageUrl
                                        : null
                                    }
                                  />{' '}
                                </div>
                                <div className="ing-name-panel">
                                  <div className="ing-name">{ingredient.name}</div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="panel" id="two-panel">
                  <ul className="flex-container wrap">
                    {newOrders.length > 0 &&
                      newOrders.map((ingredient, i) => {
                        return (
                          <li key={i} className="flex-item">
                            <div className="ing-name-box-2">
                              <div className="ing-name-panel-1">
                                {' '}
                                <ThumbnailImage
                                  src={
                                    ingredient.info && (ingredient.info?.imageUrlThumb || ingredient.info?.imageUrl)
                                      ? ingredient.info?.imageUrlThumb || ingredient.info?.imageUrl
                                      : null
                                  }
                                />{' '}
                              </div>
                              <div className="ing-name-panel">
                                <div className="ing-name">{ingredient.name}</div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AddIngredientPopup;
