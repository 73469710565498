import React, { useState } from 'react';
import RecipeCourse from '../../components/RecipeCourse/RecipeCourse.js';
import './index.css';
import { BacktoHeader } from '../../components/Header/Header';

/* This implements the top-level page (/menu/:id) for display of a menu, showing the courses and their recipes */
/* most of the actual implementation is in RecipeCoure.js */

const MenuDetails = () => {
  const currentPath = window.location.href;
  const [searchTerm, setSearchTerm] = useState('');

  const searchVal = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div id="menu-details" className="h-100 d-flex flex-column">
      <div className="common-subheader">
        <BacktoHeader backLink="/menus" backText="Back to menus" placeholder="Search menu" onChange={searchVal} />
      </div>
      <div className="Line"></div>
      <RecipeCourse currentPath={currentPath} searchTerm={searchTerm} />
      {/* <AddRecipe /> */}
    </div>
  );
};

export default MenuDetails;
