import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import commentrecipe from '../../img/recipe-comment.png';
import attachrecipe from '../../img/recipe-attach.png';
import star from '../../img/nutrition-star.png';
import { Drawer } from 'antd';
import useWindowSize from '../../components/windowdimensions/windowSize';
import NutritionPanel from '../../components/nutritionPanel/nutritionPanel';
import ExpandedPanel from '../../components/nutritionPanel/expandedPanel';
import BarGraph from '../../components/graphs/bar';
import DonutGraph from '../../components/graphs/donut';
import LineGraph from '../../components/graphs/line';

const NutritionViewPopup = (props) => {
  const { nutritionList, imageUrls, showCarousel } = props; // BUG 1201998443633170

  const [visible, updateVisible] = useState(false);
  const [costAnalysis, showCostAnalysis] = useState(false);

  const windowSize = useWindowSize();

  const showDrawer = () => {
    updateVisible(true);
  };

  // const showCost = () => {
  //   updateVisible(true);
  //   showCostAnalysis(true);
  // };

  const onClose = () => {
    updateVisible(false);
    showCostAnalysis(false);
  };

  return (
    <div className="main">
      <div className="recipe-carousel">
        {/* BUG 1201998443633170 */}
        {showCarousel ? (
          <Carousel>
            {imageUrls &&
              imageUrls.length > 0 &&
              imageUrls.map((image, i) => {
                return (
                  <div key={`image-${i}`}>
                    <img src={image} alt="recipe" />
                  </div>
                );
              })}
          </Carousel>
        ) : (
          imageUrls &&
          imageUrls.length > 0 && (
            // BUG 1201998443633170 NEW
            // <div style={{ width: 'inherit' }}>
            <img src={imageUrls[0]} style={{ width: 'inherit', height: 'auto' }} alt="recipe" />
            // </div>
          )
        )}
      </div>
      <div className="nutrition-panel">
        <NutritionPanel nutritionList={nutritionList} />
        <div className="calorie-diet">*Based on a 2000 calorie diet</div>
        <div className="nutrition-breakdown" onClick={showDrawer}>
          View nutritional breakdown
        </div>
        <div className="stock-recipe">
          {' '}
          {showCarousel && <img src={star} alt="menu_title" height="30" />} Stock recipe {/* BUG 1201998443633170 */}
        </div>
        <div className="nutrition-created">Created MM/DD/YYYY</div>
        <div className="nutrition-linked-recipe">View 4 linked recipes</div>
        {/* <div className="nutrition-cost-analysis" onClick={showCost}>
          <img src={cost} alt="menu_title" height="30" /> View cost analysis
        </div> */}
        {/* BUG 1201998443633170 */}
        {showCarousel && (
          <div className="nutrition-comment-panel">
            <div className="comment-box">
              {nutritionList.comments}
              <img src={commentrecipe} className="comment-menu" alt="comment" />
            </div>
            <div className="attachment-box">
              {nutritionList.attachments}
              <img src={attachrecipe} className="attach-menu" alt="attachment" />
            </div>
          </div>
        )}
      </div>
      <Drawer
        width={windowSize >= 842 ? '400px' : '100%'}
        onClose={onClose}
        visible={visible}
        drawerStyle={{ backgroundColor: 'var(--deep-fill)' }}
      >
        <div className="nutrition-panel1">
          <NutritionPanel nutritionList={nutritionList} />
          <ExpandedPanel />
        </div>
      </Drawer>
      <Drawer
        width={windowSize >= 842 ? '842px' : '100%'}
        onClose={onClose}
        visible={costAnalysis}
        drawerStyle={{ backgroundColor: 'var(--mid-fill)' }}
      >
        <div className="cost-analysis-page">Cost Analysis</div>
        <div className="cost-circle-container">
          <div>
            <div className="cost-circle">$7.5</div>
            <div className="cost-ingr">Ingredients cost</div>
          </div>
          <div>
            <div className="cost-circle">$25</div>
            <div className="cost-ingr">Menu price</div>
          </div>
          <div>
            <div className="cost-circle">70%</div>
            <div className="cost-ingr">Profit</div>
          </div>
        </div>
        <div>
          <BarGraph />
        </div>
        <div>
          <DonutGraph />
        </div>
        <div>
          <LineGraph />
        </div>
      </Drawer>
    </div>
  );
};

export default NutritionViewPopup;
