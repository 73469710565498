import React from 'react';
import dashboardIcon from '../img/menuIcon/ui-icons-dashboard-off.svg';
import menuIcon from '../img/menuIcon/ui-icons-recipes-on.svg';
import recipesIcon from '../img/menuIcon/ui-icons-menus-on.svg';
import checklistIcon from '../img/menuIcon/ui-icons-checklist-on.svg';
import staffRotaIcon from '../img/menuIcon/ui-icons-staff-rota-on.svg';
import documentsIcon from '../img/menuIcon/ui-icons-documents-on.svg';

export const menuItems2 = [
  {
    name: 'main',
    title: 'Super Admin',
    children: [
      {
        name: 'accounts',
        title: 'Accounts',
        icon: <img src={dashboardIcon} alt="dashboardIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'users',
        title: 'Users',
        icon: <img src={dashboardIcon} alt="dashboardIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'locations',
        title: 'Locations',
        icon: <img src={menuIcon} alt="menuIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'ingredients',
        title: 'Ingredients',
        icon: <img src={recipesIcon} alt="recipesIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'equipment',
        title: 'Equipment',
        icon: <img src={checklistIcon} alt="checklistIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'preparations',
        title: 'Preparations',
        icon: <img src={staffRotaIcon} alt="staffRotaIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'measurements',
        title: 'Measurements',
        icon: <img src={documentsIcon} alt="documentsIcon" className="UI-IconsMenusOn" />,
      },
    ],
  },
];

export const menuItems = [
  {
    name: 'main',
    title: 'Admin',
    children: [
      {
        name: 'users',
        title: 'Users',
        icon: <img src={dashboardIcon} alt="dashboardIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'locations',
        title: 'Locations',
        icon: <img src={menuIcon} alt="menuIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'ingredients',
        title: 'Ingredients',
        icon: <img src={recipesIcon} alt="recipesIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'equipment',
        title: 'Equipment',
        icon: <img src={checklistIcon} alt="checklistIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'preparations',
        title: 'Preparations',
        icon: <img src={staffRotaIcon} alt="staffRotaIcon" className="UI-IconsMenusOn" />,
      },
      {
        name: 'measurements',
        title: 'Measurements',
        icon: <img src={documentsIcon} alt="documentsIcon" className="UI-IconsMenusOn" />,
      },
    ],
  },
];
