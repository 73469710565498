import React from 'react';
import Filler from './filler';
import './index.css';

const ProgressBar = (props) => {
  return (
    <div className="progress-bar-box">
      <div className="progress-percentage">{props.percentage}%</div>
      <div className="progress-bar">
        <Filler percentage={props !== undefined ? props.percentage : 0} />
      </div>
    </div>
  );
};

export default ProgressBar;
