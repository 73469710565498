import React, { useState, useEffect, useCallback, useRef } from 'react';
import collapseall from '../../img/check-collapse.png';
import { Tag, Switch, Tooltip, Dropdown, Menu, Drawer } from 'antd';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import reorder from '../../img/reorder-icon.png';
import { Link } from 'react-router-dom';
import {
  searchMenuCategories,
  createMenuCategory,
  destroyMenuCategory,
  updateMenuCategory,
} from '../../services/category.service';
import { all } from '../../services/operator.service';
import {
  searchMenu,
  // courses,
  create,
  updateMenu,
  destroy,
  batchCourses,
  activateChecklist,
  duplicate,
} from '../../services/menu.service';
// import { search } from '../../services/recipe.service';
import { batchRecipes } from '../../services/course.service';
import { getAllStationOperators } from '../../services/station.service';
import ReactDragListView from 'react-drag-listview';
import _, { cloneDeep } from 'lodash';
import DocumentList from '../../components/DocumentList/DocumentList';
import { searchDocLinks } from '../../services/doc.service';
import useWindowSize from '../../components/windowdimensions/windowSize';
// import { FolderOpenFilled } from '@ant-design/icons';
// import ChartComponent from 'react-chartjs-2';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess.js';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import {
  ChevronLeft,
  FolderIcon,
  PlusIcon,
  AttachmentIndicatorMobile,
  PlusButton,
  UserImage,
  RowAssigneeEditWithSearch,
} from '../../components/common/common_ui';

const MenusMobile = () => {
  // const [expandedRows, setExpandedRows] = useState([]);
  // const [soloMenuCategory, isSoloMenuCategory] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [visible, updateVisible] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [catTitle, setCatTitle] = useState('');
  // const [categoryId, setCatId] = useState(false);
  const [menus, setMenus] = useState([]);
  const [selected, setSelected] = useState({});
  const [addMenus, setAddMenu] = useState(false);
  const [menuTitle, setMenuTitle] = useState('');
  const [menulist, setMenuList] = useState([]);
  const [menuName, setMenuName] = useState('All menus');
  const [operatorChoices, setOperatorChoices] = useState([]);
  // const [userInfo, setUserInfo] = useState({});
  const [restaurant, setRestaurant] = useState('');
  const [restoId, setRestoId] = useState('');
  const [menuDelete, setDeleteMenu] = useState({});
  const [catDelete, setDeleteCat] = useState({});
  // const [isDown, setIsDown] = useState(false);
  // const [activeCat, setActiveCat] = useState({});
  const [pristineData, setPristineData] = useState([]);
  // const [getCatId, setGetCatId] = useState({});
  // const [dragParent, setDragParent] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [docLinks, setDocLinks] = useState({});
  const [links, setLinks] = useState({});
  const [triggerEditCat, setTriggerEditCat] = useState(false);
  const [triggerEditMenu, setTriggerEditMenu] = useState(false);
  // const [searchedAssignee, setSearchedAssignee] = useState(''); CHANGE 1202077200318274
  const [userImage, setUserImage] = useState(
    JSON.parse(localStorage.getItem('operator'))?.info?.userImageThumb ||
      JSON.parse(localStorage.getItem('operator'))?.info?.userImage
  );
  const [socketData, setSocketData] = useState([]);
  const [catgUpdate, setCatgUpdate] = useState([]);
  const [catgAdd, setCatgAdd] = useState([]);
  const [catgDelete, setCatgDelete] = useState('');
  const [menuAdd, setMenuAdd] = useState({});
  const [menuUpdate, setMenuUpdate] = useState({});
  const [menu1Delete, setMenuDelete] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/menu`));

  let userInfos = JSON.parse(localStorage.getItem('operator')) || {};

  let userName = userInfos.firstName + ' ' + userInfos.lastName;

  const cat_name = useRef(null);

  const menu_edit = useRef(null);

  const operators = useRef([]);

  const windowSize = useWindowSize();

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    if (result.type === 'droppableItem') {
      const items = Array.from(menus);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setMenus(items);
      localStorage.removeItem('menuColKeyOrder');
      localStorage.setItem('menuOrder', JSON.stringify(cacheTableOrder(items).menuOrder));
    } else if (result.type === 'droppableSubItem') {
      const itemSubItemMap = menus.reduce((acc, item) => {
        acc[item.id] = item.children || [];
        return acc;
      }, {});

      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      const sourceSubItems = itemSubItemMap[sourceParentId];
      // const destSubItems = itemSubItemMap[destParentId];

      let newItems = [...menus];

      if (sourceParentId === destParentId) {
        const items1 = Array.from(sourceSubItems);
        const [reorderedItem] = items1.splice(result.source.index, 1);
        items1.splice(result.destination.index, 0, reorderedItem);

        let reOrdered = newItems.map((item) => {
          if (item.id === sourceParentId.toString()) {
            item.children = items1;
          }
          return item;
        });

        console.log(items1);

        console.log('NEWITEMS', reOrdered);

        // setArr(newItems);
        setMenus(newItems);
        localStorage.removeItem('menuColKeyOrder');
        localStorage.setItem('menuOrder', JSON.stringify(cacheTableOrder(newItems).menuOrder));
      } else {
        //let newSourceSubItems = [...sourceSubItems];
        let newSourceSubItems = sourceSubItems ? Array.from(sourceSubItems) : [];
        const [draggedItem] = newSourceSubItems.splice(result.source.index, 1);
        // let newDestSubItems = [...destSubItems];
        // newDestSubItems.splice(result.destination.index, 0, draggedItem);
        // let moveItems = newItems.map((item) => {
        //   if (item.id === sourceParentId.toString()) {
        //     item.children = newSourceSubItems;
        //   } else if (item.id === destParentId.toString()) {
        //     item.children = newDestSubItems;
        //   }
        //   return item;
        // });

        // console.log('MOVED ITEMS', moveItems);
        //setArr(moveItems);
        // setMenus(moveItems);

        //assign menu to another category
        eventSocket.emit(
          'updatesMenuItem',
          JSON.stringify({
            activate: draggedItem.activate,
            activerecipes: draggedItem.activerecipes,
            assignee: draggedItem.assignee,
            assigneeImg: draggedItem.assigneeImg,
            attachments: draggedItem.attachments,
            categorized: draggedItem.categorized,
            categoryId: destParentId,
            destinationIndex: result.destination.index,
            id: draggedItem.id,
            key: `${destParentId}-${draggedItem.id}`,
            name: draggedItem.name,
            oldCategoryId: sourceParentId,
            prepped: draggedItem.prepped,
            recipes: draggedItem.recipes,
            tags: draggedItem.tags,
            texts: draggedItem.texts,
            type: 'child',
          })
        );

        updateMenu(draggedItem.id, { categoryId: destParentId }).then((res) => {
          // notification.open({
          //   message: 'Successfully reassigned menu!',
          // });
          // localStorage.setItem('menuOrder', JSON.stringify(cacheTableOrder(moveItems).menuOrder));
        });
      }
    }
  };

  const refreshMenuItem = (menu, key, value) => {
    let clonedMenuList = _.cloneDeep(menus);
    const findCategoryIndex = _.findIndex(clonedMenuList, { categoryId: menu.categoryId });
    let menuItems = clonedMenuList[findCategoryIndex].children;
    const findMenuIndex = _.findIndex(clonedMenuList[findCategoryIndex].children, { key: menu.key });
    let menuItem = _.cloneDeep(menuItems[findMenuIndex]);

    menuItem[key] = value;
    clonedMenuList[findCategoryIndex].children[findMenuIndex] = { ...menuItem };
    setMenus(clonedMenuList);
    setPristineData(clonedMenuList);
  };

  const getMenus = async (cat, restoid) => {
    const allUsers = await all(0, 1000);
    let getUser = allUsers.map((r) => {
      return {
        username: r.firstName + ' ' + r.lastName,
        image: r.info ? r.info?.userImageThumb || r.info?.userImage || r.info?.userImg : '',
      };
    });
    let getCategoryIds = cat.map((c) => {
      return c.id;
    });
    console.log('CATIDS', cat, getCategoryIds);

    searchMenu({ restaurantId: restoid }).then((res) => {
      if (res && res.menus) {
        let allMenus = res.menus.map((m) => {
          m.menuId = m.id.toString();
          m.info = m.info !== 'null' || m.info !== null ? JSON.parse(m.info) : null;
          return m;
        });
        let filterMenus = allMenus.filter((p) => getCategoryIds.includes(p.categoryId));
        console.log('FILTER MENUS CATID', filterMenus);

        let menuIds = filterMenus.map((f) => f.id);

        console.log('MENUIDS', menuIds);

        batchCourses(menuIds).then((res) => {
          if (res) {
            let allCourses = res.map((c) => {
              return c.MenuCourses;
            });
            console.log('ALL COURSES', allCourses);

            let merged = [].concat.apply([], allCourses);

            console.log(merged);

            let getCourseIds = merged.map((c) => {
              return c.courseId;
            });
            console.log('COURSEIDS++', getCourseIds);

            batchRecipes(getCourseIds).then((res) => {
              let mapRecs = res?.map((f) => {
                return f.CourseRecipes.map((c) => ({
                  menuId: res.menuId ? res.menuId : 0,
                  recipes: [{ active: c.active, id: c.recipeId, name: c?.Recipe?.name }],
                  courseId: f.id,
                }));
              });

              let newRecs = [].concat.apply([], mapRecs);

              console.log('NEWRECCSSS!!!', newRecs);

              let combineCourses = merged.concat(newRecs);
              console.log('COMBO MENU-COURSE-RECIPE++++', combineCourses);

              const combResult = Object.values(
                combineCourses.reduce((r, o) => {
                  const key = `${o.courseId}`;

                  if (!r[key]) r[key] = { ...o, recipes: [] };

                  r[key].recipes.push(o.recipes);

                  r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined && u !== null);

                  return r;
                }, {})
              );

              console.log('ADDED MENUIDS', combResult);

              const comboResult2 = Object.values(
                combResult.reduce((r, o) => {
                  const key = `${o.menuId}`;

                  if (!r[key]) r[key] = { ...o, recipes: [] };

                  r[key].recipes.push(o.recipes);

                  r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined && u !== null);

                  return r;
                }, {})
              );

              console.log('FINAL COMBO MENU_REC', comboResult2);

              let combined = filterMenus.concat(comboResult2);
              console.log('COMBO', combined);

              //remove event menus from other categories
              let combined2 = combined.filter((e) => !e.info?.originalMenuId);

              const result = Object.values(
                combined2.reduce((r, o) => {
                  const key = `${o.menuId}`;

                  if (!r[key]) r[key] = { ...o, recipes: [] };

                  r[key].recipes.push(o.recipes);

                  r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined);

                  return r;
                }, {})
              );

              const finalMenus = result.filter((m) => m.id);

              console.log('MENUS WITH RECIPES+++', finalMenus);

              setMenuList(result);

              let categoryMenus = Object.values(
                finalMenus.reduce((result, { categoryId, id, type, recipes, name, info }) => {
                  if (!result[categoryId]) {
                    result[categoryId] = {
                      categoryId,
                      menus: [],
                    };
                  }
                  result[categoryId].menus.push({
                    categoryId,
                    id,
                    key: `${categoryId}-${id}`,
                    name,
                    type: 'child',
                    prepped: info,
                    activate: info?.active,
                    recipes: recipes ? recipes : [],
                    activerecipes: recipes ? recipes.filter((r) => r.active == true) : [],
                    assignee: info?.createdBy ? info.createdBy : info?.assignee ? info.assignee : '',
                    assigneeImg: info?.createdBy ? getUser?.find((user) => user.username == info?.createdBy).image : '',
                    tags: info?.tags ? info?.tags : [],
                    texts: 15,
                    attachments: 5,
                    categorized: 'yes',
                  });
                  return result;
                }, {})
              );

              console.log(categoryMenus);

              let combinedMenus = cat.concat(categoryMenus);
              console.log('COMBOMENUS', combinedMenus);

              const resultMenus = Object.values(
                combinedMenus.reduce((r, o) => {
                  const key = `${o.categoryId}`;

                  if (!r[key]) r[key] = { ...o, menus: [] };

                  r[key].menus.push(o.menus);

                  r[key].menus = [].concat.apply([], r[key].menus).filter((u) => u !== undefined);

                  return r;
                }, {})
              );

              console.log('MENUS WITH CATEGORIES', resultMenus);

              let tableData = resultMenus.map((m, i) => {
                m.menuCount = m?.menus ? m.menus.length : 0;
                m.children = m?.menus
                  ? m.menus.concat(
                      [{ 
                        name: '', 
                        type: 'child', 
                        categoryId: m.id, 
                        key: `${m.id}-9999999`, 
                        tags: [], 
                        assignee: '' 
                      }])
                  : [{ name: '', type: 'child', categoryId: m.id, key: `${m.id}-9999999` }];
                m.key = `${m.id}`;
                delete m.menus;
                return m;
              });

              //move event menus to their own category
              const eventMenus = combined.filter((e) => (e.eventName && e.info?.originalMenuId) || e.recipes);

              console.log('EVENTMENUS', eventMenus);

              let newObj = {};

              if (eventMenus && eventMenus.length > 0) {
                //find if category exists
                const uncategorized = cat.find((e) => e.name == 'UNCATEGORIZED');
                console.log('EVENTEXISTS', uncategorized);
                if (!uncategorized) {
                  const eventresult = Object.values(
                    eventMenus.reduce((r, o) => {
                      const key = `${o.menuId}`;

                      if (!r[key]) r[key] = { ...o, recipes: [] };

                      r[key].recipes.push(o.recipes);

                      r[key].recipes = [].concat.apply([], r[key].recipes).filter((u) => u !== undefined);

                      return r;
                    }, {})
                  );

                  const finalEventMenus = eventresult.filter((m) => m.id);

                  console.log('menuList', eventresult);
                  console.log('MENUS WITH RECIPES+++', finalEventMenus);

                  let childMenus = finalEventMenus.map((c) => {
                    c.key = `9999999999-${c.id}`;
                    c.type = 'child';
                    c.prepped = c.info;
                    c.activate = c.info?.active;
                    c.recipes = c.recipes ? c.recipes : [];
                    c.activerecipes = c.recipes ? c.recipes.filter((r) => r.active == true) : [];
                    c.assignee = c.info?.createdBy ? c.info?.createdBy : c.info.assignee ? c.info.assignee : '';
                    c.assigneeImg = c.info?.createdBy
                      ? c.info?.createdBy == userName
                        ? userImage
                        : ''
                      : c.info?.assigneeImg
                      ? c.info.assigneeImg
                      : '';
                    c.tags = c.info?.tags ? c.info?.tags : [];
                    c.texts = 15;
                    c.attachments = 5;
                    c.categorized = 'no';
                    return c;
                  });

                  console.log('EVENTCHILDMENUS', childMenus);

                  newObj.key = '9999999999';
                  newObj.categoryId = 'UNCAT1';
                  newObj.id = 'UNCAT1';
                  newObj.children = childMenus.sort((a, b) => (a.name > b.name ? 1 : -1));
                  newObj.menuCount = childMenus.filter((d) => d.name !== '').length;
                  newObj.type = 'parent';
                  newObj.name = 'UNCATEGORIZED';
                }
              }

              console.log('NEWOBJ1', newObj);

              console.log('MENUS WITH CATEGORIES', resultMenus);

              if (Object.keys(newObj).length !== 0) {
                tableData.push(newObj);
              }
              // console.log('TABLEDATA', tableData);
              // setMenus(tableData);
              // setPristineData(tableData);
              // setIsLoading(false);

              let getLinks = tableData
                .filter((e) => e.children || [])
                .map((e) => (e.children || []).map((link) => link.id))
                .reduce((a, b) => a.concat(b), [])
                .filter((l) => l !== undefined);

              console.log(getLinks);

              let stringList = getLinks.join(', ');

              let params = {
                type: 'menus',
                linkId: stringList,
              };
              searchDocLinks(params).then((res) => {
                console.log(res);
                if (res && res.length > 0) {
                  let links = res;
                  let removeDeleted = links.filter((l) => l.Document.folderId !== null);
                  const list = Array.from(
                    removeDeleted.reduce(
                      (m, { linkId, documentId }) => m.set(linkId, [...(m.get(linkId) || []), documentId]),
                      new Map()
                    ),
                    ([linkId, documentId]) => ({ linkId, documentId })
                  );

                  console.log(list);

                  let addSelectedLinks = tableData.map((o) => {
                    (o.children || []).map((c) => {
                      list.map((d) => {
                        if (d.linkId == c.id) {
                          c.document = d.documentId.length;
                        }
                      });
                    });
                    return o;
                  });

                  console.log(addSelectedLinks);

                  if (
                    localStorage.getItem('menuColKeyOrder') != undefined
                  ) {
                    let nameOrder = localStorage.getItem('menuColKeyOrder');
                    if (nameOrder) {
                      if (nameOrder == 'ascend') {
                        addSelectedLinks.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                        setMenus(addSelectedLinks);
                      } else {
                        addSelectedLinks.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
                        setMenus(addSelectedLinks);
                      }
                    }
                  } else if (
                    localStorage.getItem('menuOrder') != undefined &&
                    JSON.parse(localStorage.getItem('menuOrder')).length
                  ) {
                    const menuOrder = JSON.parse(localStorage.getItem('menuOrder'));
                    const tableSorted = annotateTableOrder(addSelectedLinks, menuOrder);
                    setMenus(tableSorted);
                  } else {
                    setMenus(addSelectedLinks);
                  }
                  setSocketData(addSelectedLinks);
                  setPristineData(addSelectedLinks);
                  // setIsLoading(false);
                  
                } else {
                  if (
                    localStorage.getItem('menuColKeyOrder') != undefined
                  ) {
                    let nameOrder = localStorage.getItem('menuColKeyOrder');
                    if (nameOrder) {
                      if (nameOrder == 'ascend') {
                        tableData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                        setMenus(tableData);
                      } else {
                        tableData.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
                        setMenus(tableData);
                      }
                    }
                  } else if (
                    localStorage.getItem('menuOrder') != undefined &&
                    JSON.parse(localStorage.getItem('menuOrder')).length
                  ) {
                    const menuOrder = JSON.parse(localStorage.getItem('menuOrder'));
                    const tableSorted = annotateTableOrder(tableData, menuOrder);
                    setMenus(tableSorted);
                  } else {
                    setMenus(tableData);
                  }
                  setSocketData(tableData)
                  setPristineData(tableData);
                  // setIsLoading(false);
                }
              });
            });
          }
        });
      } else {
        let noMenus = cat.map((c, i) => {
          c.children = [
            { assignee: '', categoryId: c.categoryId, key: `${c.categoryId}`, name: '', tags: [], type: 'child' },
          ];
          c.menuCount = 0;
          c.key = c?.id;
          return c;
        });
        setMenus(noMenus);
        setSocketData(noMenus);
        setPristineData(noMenus);
        // setIsLoading(false);
      }
    });
  };

  const getCategory = () => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    setRestoId(resto.id);
    let params = {
      restaurantId: resto.id,
    };

    searchMenuCategories(params).then((res) => {
      if (res && res.menucategories) {
        console.log('MENU CATS', res.menucategories);
        setIsAdding(false);
        let classifyCtg = res.menucategories.map((c) => {
          c.type = 'parent';
          c.categoryId = c.id.toString();
          c.id = c.id.toString();
          c.restaurantId = c.restaurantId.toString();
          return c;
        });
        getMenus(classifyCtg, resto.id);
        setTriggerEditCat(false);
        setTriggerEditMenu(false);
      }
    });
  };

  // cacheTableOrder - caches and annotates position of each parent and child row of current table order (index-based position)
  const cacheTableOrder = (data) => {
    let orderCached = [];
    let tableOrdered = [];
    if (data.length > 0) {
      tableOrdered = data.map((d, i) => { // for menu categories
        const parentRow = {};
        parentRow.key = d.key;
        if (d?.order == undefined || d.order !== i) {
          parentRow.order = i; // caches order
          d.order = i; // annotates order
        } else {
          parentRow.order = d.order;
        }
        parentRow.children = [];
        if (d.children && d.children.length) {
          d.children = d.children.map((c, j) => { // for menus
            const childRow = {};
            childRow.key = c.key;
            if (c?.order == undefined || c.order !== j) {
              childRow.order = j; // caches order
              c.order = j; // annotates order
            } else {
              childRow.order = c.order;
            }
            parentRow.children.push(childRow);
            return c;
          });
          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        orderCached.push(parentRow);
        return d;
      });
      if (data.length > 1) {
        tableOrdered = tableOrdered.sort((a, b) => a.order - b.order);
      }
    }
    return {
      menuOrder: orderCached, // cached table order
      orderedMenu: tableOrdered // annotated table rows
    };
  }

  // annotateTableOrder - annotates new/current/updated order for each parent and child record of table (index-based position)
  // callback when localStorage variable 'menuOrder' exists, then applies to fetched data
  const annotateTableOrder = (data, menuOrder, viewOnly = false) => {
    let orderedTable = [];
    if (data.length > 0) {
      const tableAnnotated = data.map((d) => {
        // annotate menu category cached order
        const folderIndex = _.findIndex(menuOrder, (r) => r.key === d.key);
        d.order = menuOrder[folderIndex].order;
        if (d.children && d.children.length) {
          d.children = d.children.map((c, i) => {
            // annotate menu cached order
            const menuIndex = _.findIndex(menuOrder[folderIndex].children, (h) => h.key === c.key);
            if (menuOrder[folderIndex].children[menuIndex] != undefined) {
              const cachedOrder = menuOrder[folderIndex]?.children[menuIndex].order;
              c.order = (
                cachedOrder != undefined &&
                (
                  !viewOnly && cachedOrder !== i
                )
              ) ? cachedOrder : i;
            } else {
              c.order = i;
            }
            return c;
          });

          if (viewOnly) { // removes open add menus
            d.children = d.children.filter((a) => a.key !== `${a.categoryId}-9999999`);
          }
          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        return d;
      });

      orderedTable = tableAnnotated;

      if (data.length > 1) {
        orderedTable = tableAnnotated.sort((a, b) => a.order - b.order);
      }
    }
    return orderedTable;
  }

  // deannotateTableOrder - removes index order for each parent and child record
  // const deannotateTableOrder = (data, filterOpenAdds = true) => {
  //   if (data.length > 0) {
  //     // filters out open add menu category rows
  //     let orderRemoved = data
  //     if (filterOpenAdds) {
  //       orderRemoved = orderRemoved.filter((k) => !k.tempId || k.tempId == undefined)
  //         .map((p) => {
  //           if (p.categoryId !== 'UNCAT1' && p.children && p.children.length) {
  //             const findOpenAddMenuIndex = findIndex(p.children, (a) => a.key === `${a.categoryId}-9999999`);
  //             if (findOpenAddMenuIndex) { // replace open add menu items with the category button row
  //               p.children[findOpenAddMenuIndex] = {
  //                 name: '', 
  //                 type: 'child', 
  //                 categoryId: m.id, 
  //                 key: `${m.id}-9999999`, 
  //                 tags: [], 
  //                 assignee: '' 
  //               }
  //             }
  //           }
  //           return p;
  //         });
  //     }
      
  //     if (orderRemoved.length) {
  //       orderRemoved = orderRemoved.map((d) => {
  //         if (d?.order != undefined) {
  //           delete d['order'];
  //         }
  //         if (d.children && d.children.length) {
  //           d.children = d.children.map((e) => { 
  //             if (e?.order != undefined) {
  //               delete e['order'];
  //             }
  //             return e;
  //           });
  //           if (d.children.length > 1) {
  //             d.children = d.children.sort((a, b) => a.key.localeCompare(b.key));
  //           }
  //         }
  //         return d;
  //       });
  //       if (orderRemoved.length > 1) {
  //         orderRemoved = orderRemoved.sort((a, b) => a.key.localeCompare(b.key));
  //       }
  //       return orderRemoved;
  //     }
  //   }
  //   return data;
  // }

  // const columns = [
  //   {
  //     title: 'Sorted by Name',
  //     dataIndex: 'name',
  //     sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
  //     sortDirections: ['descend', 'ascend'],
  //     width: 320,
  //     ellipsis: true,
  //     render: (key, obj) => (
  //       <span className="chk-list-mobile">
  //         {' '}
  //         <span className={obj.type == 'parent' ? '' : 'name-span-mobile'}>
  //           {obj.type == 'child' ? (
  //             <span className="common-folder-icon">
  //               <FolderIcon />
  //             </span>
  //           ) : (
  //             ''
  //           )}
  //         </span>
  //         <span className={obj.type == 'parent' ? '' : 'name-key'}>
  //           {key && (
  //             <span className={obj.selected ? 'highlight' : ''}>
  //               {obj.type == 'child' ? (
  //                 <Link to={`/menu/${obj.id}`} onMouseOver={(e) => getObj(e, obj)}>
  //                   {key}
  //                 </Link>
  //               ) : (
  //                 <span
  //                   style={{ fontSize: 14, marginLeft: 15 }}
  //                   onMouseEnter={(e) => toggleClass(e, obj)}
  //                   onMouseLeave={(e) => toggleClass(e, obj)}
  //                 >
  //                   {key}
  //                 </span>
  //               )}
  //             </span>
  //           )}
  //           {obj.type == 'parent' ? (
  //             <span className="doc-upload-count">
  //               ( {obj.menuCount ? obj.menuCount.length + ' ' + 'menus' : 'no menus'} )
  //             </span>
  //           ) : (
  //             ''
  //           )}
  //           {obj.type == 'parent' && obj.name !== 'UNCATEGORIZED' && (
  //             <span
  //               className="menu-collection-widgets-mobile"
  //               onMouseEnter={(e) => toggleClass(e, obj)}
  //               onMouseLeave={(e) => toggleClass(e, obj)}
  //             >
  //               <a className="drag-handle" href="#/">
  //                 <span className="showDragIconMenuMob">
  //                   {' '}
  //                   <img src={reorder} alt="reorder" className="menusToggles" />
  //                 </span>{' '}
  //               </a>
  //               <RoleAccessibleComponent
  //                 permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
  //               >
  //                 <Dropdown overlay={deletesCategory}>
  //                   <span className={'texts-icon'} onMouseOver={(e) => setDeleteCat(obj)}>
  //                     {' '}
  //                     <img src={IconsMoreOff} className="del-menu" alt="delete" />
  //                   </span>
  //                 </Dropdown>
  //               </RoleAccessibleComponent>
  //             </span>
  //           )}
  //           {!addMenus && obj.type == 'child' && obj.name == '' ? (
  //             <RoleAccessibleComponent
  //               permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
  //             >
  //               <span className="task-item-add-mobile" onClick={(e) => addMenu(e, obj)}>
  //                 Add menu
  //               </span>
  //             </RoleAccessibleComponent>
  //           ) : (
  //             ''
  //           )}
  //           {addMenus && obj.type == 'child' && selected.categoryId == obj.categoryId ? (
  //             <span style={{ margin: 20 }}>
  //               <input
  //                 type="text"
  //                 className="menuCat-bar"
  //                 placeholder="Enter a menu name"
  //                 onChange={(e) => setMenuTitle(e.target.value)}
  //               />
  //               <span className="menu-attachment-icon" alt="add menu" onClick={createMenu}>
  //                 <PlusIcon />
  //               </span>
  //             </span>
  //           ) : (
  //             ''
  //           )}
  //         </span>
  //         {obj.type == 'child' && obj.document == 0 ? (
  //           <AttachmentIndicatorMobile
  //             className="menu-attachment-indicator-mobile"
  //             count={obj.document}
  //             onClick={(e) => showDrawer(e, obj)}
  //           />
  //         ) : (
  //           ''
  //         )}
  //         {/* {obj.type == 'child' && obj.attachments > 0 ? (
  //           <span className={obj.texts > 0 ? 'attachmentsMob' : 'attachments1'}>{obj.attachments}</span>
  //         ) : (
  //           ''
  //         )}
  //         {obj.type == 'child' && obj.attachments > 0 ? (
  //           <span className={'texts-icon'}>
  //             {' '}
  //             <img src={attach} className="comment" alt="attach" />
  //           </span>
  //         ) : (
  //           ''
  //         )} */}
  //         {obj.type == 'child' && obj.name !== '' && (
  //           <span className="menu-collection-widgets-mobile" onMouseOver={(e) => getObj(e, obj)}>
  //             {obj.categorized == 'yes' && (
  //               <a className="drag-handle" href="#/">
  //                 <span className="showDragIconMenuMob">
  //                   {' '}
  //                   <img src={reorder} alt="reorder" className="menusToggles" />
  //                 </span>{' '}
  //               </a>
  //             )}

  //             <RoleAccessibleComponent
  //               permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
  //             >
  //               <Dropdown overlay={deletesCategory}>
  //                 <span className={'texts-icon'} onMouseOver={(e) => setDeleteCat(obj)}>
  //                   {' '}
  //                   <img src={IconsMoreOff} className="del-menu" alt="delete" />
  //                 </span>
  //               </Dropdown>
  //             </RoleAccessibleComponent>
  //           </span>
  //         )}
  //         <div className="chk-mobile-details">
  //           {obj.type == 'child' && obj.name !== '' && (
  //             <span className="chk-mobile-title">
  //               Recipes
  //               <span className="chk-mobile-project">
  //                 {obj.type == 'child' && obj.recipes ? <span>{`${obj.recipes.length} recipe${obj.recipes.length != 1 ? 's' : ''}`}</span> : ''}
  //               </span>
  //             </span>
  //           )}
  //         </div>
  //         <div className="chk-mobile-details">
  //           {obj.type == 'child' && obj.name !== '' && (
  //             <span className="chk-mobile-title">
  //               Active
  //               <span className="chk-mobile-project3">
  //                 {obj.type == 'child' && obj.recipes ? (
  //                   <span>
  //                     {obj.activerecipes.length} out of {obj.recipes.length}
  //                   </span>
  //                 ) : (
  //                   ''
  //                 )}
  //               </span>
  //             </span>
  //           )}
  //         </div>
  //         <div className="chk-mobile-details">
  //           {obj.type == 'child' && obj.name !== '' && (
  //             <span className="chk-mobile-title">
  //               Creator
  //               <span className="chk-mobile-assign1">
  //                 {obj.type == 'child' && obj.assignee ? (
  //                   <div className="common-user-icon" alt="user-photo">
  //                     <UserImage
  //                       src={obj.assigneeImg !== '' ? obj.assigneeImg : null}
  //                     />
  //                   </div>
  //                 ) : (
  //                   ''
  //                 )}
  //                 {obj.type == 'child' && obj.assignee ? <span>{obj.assignee}</span> : ''}
  //               </span>
  //             </span>
  //           )}
  //         </div>
  //         <div className="chk-mobile-details">
  //           {obj.type == 'child' && obj.name !== '' && (
  //             <div className="menu-taggy1">
  //               <div>Tags</div>
  //               <div className="menu-taggy-mob">
  //                 {obj.tags && obj?.tags?.length > 0
  //                   ? obj.tags.map((tag) => {
  //                       let color = 'black';
  //                       return (
  //                         <Tag color={color} key={tag}>
  //                           {tag}
  //                         </Tag>
  //                       );
  //                     })
  //                   : ''}{' '}
  //                 {obj.type == 'child' && obj.tags ? (
  //                   <Tooltip title="Add tag">
  //                     <span className="menus-add-tag">+</span>
  //                   </Tooltip>
  //                 ) : (
  //                   ''
  //                 )}
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //         <div className="chk-mobile-details-priority">
  //           {obj.type == 'child' && obj.name !== '' && (
  //             <span className="chk-mobile-title">
  //               Activate
  //               <span>
  //                 <span className="chk-mobile-priority">
  //                   {obj.type == 'child' && (
  //                     <span>
  //                       {' '}
  //                       <Switch checked={obj.activate} onChange={(e) => toggleMenus(e, obj)} />
  //                     </span>
  //                   )}
  //                 </span>
  //               </span>
  //             </span>
  //           )}
  //         </div>
  //       </span>
  //     ),
  //   },
  // ];

  // useEffect(() => {
  //   console.log('STATE OF MENUS', menus);
  // }, [menus]);

  // const handleRowExpand = (record) => {
  //   if (expandedRows.includes(record.key)) {
  //     let filterRecord = expandedRows.filter((key) => key !== record.key);
  //     setExpandedRows(filterRecord);
  //     localStorage.setItem('menuOpenRows', JSON.stringify(filterRecord));
  //   } else {
  //     setExpandedRows([...expandedRows, record.key]);
  //     localStorage.setItem('menuOpenRows', JSON.stringify([...expandedRows, record.key]));
  //   }
  // };

  const collapseAll = () => {
    // setExpandedRows([]);
    // localStorage.setItem('menuOrder', JSON.stringify([]));

    let selected = [...menus];
    selected.map((select) => {
      select.selected = false;
      return select;
    });
    setMenus(selected);
  };

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    getAllStationOperators(resto.id).then((res) => {
      console.log('OPERATORS', res);
      setOperatorChoices(res);
    });
    setRestaurant(resto.name);
    // setUserInfo(JSON.parse(localStorage.getItem('operator')) || {});
    // let getCachedRows = JSON.parse(localStorage.getItem('menuOrder'));
    // if (getCachedRows && getCachedRows.length > 0) {
    //   setMenus(getCachedRows);
    // } else {
    getCategory();
    // }
  }, []);

  // useEffect(() => {
  //   console.log(menuDelete);
  // }, [menuDelete]);

  // useEffect(() => {
  //   if (searchedAssignee && searchedAssignee !== '') {
  //     const resto_id = (JSON.parse(localStorage.getItem('restaurant')) || {}).id;
  //     getStationOperators({ id: resto_id, name: searchedAssignee }).then((res) => {
  //       operators.current = res;
  //     });
  //   } else {
  //     operators.current = [];
  //   }
  // }, [searchedAssignee]);

  const addCollection = () => {
    setIsAdding(true);
  };

  const createCategory = async (e) => {
    setIsAdding(false);
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    let params = {
      restaurantId: resto.id,
      name: catTitle.trim(),
    };

    if (!catTitle) {
      // notification.open({
      //   message: 'Please provide a category name',
      // });
      return;
    }

    const res = await createMenuCategory(params);
    if (res) {
      setTimeout(() => {
        // notification.open({
        //   message: 'Successfully created category! Fetching all categories...',
        // });
        // getCategory();
        eventSocket.emit(
          'addsMenu',
          JSON.stringify({
            categoryId: res.id.toString(),
            children: [
              {
                assignee: '',
                categoryId: res.id.toString(),
                key: `${res.id}-9999999`,
                name: '',
                tags: [],
                type: 'child',
              },
            ],
            id: res.id.toString(),
            key: `${res.id}`,
            menuCount: 0,
            menus: [],
            name: catTitle.trim(),
            restaurantId: resto.id.toString(),
            type: 'parent',
          })
        );
      }, 2000);
    }
  };

  const createCategory2 = async () => {
    setIsAdding(false);
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    let params = {
      restaurantId: resto.id,
      name: catTitle.trim(),
    };

    if (!catTitle) {
      // notification.open({
      //   message: 'Please provide a category name',
      // });
      return;
    }

    const res = await createMenuCategory(params);
    if (res) {
      setTimeout(() => {
        // notification.open({
        //   message: 'Successfully created category! Fetching all categories...',
        // });
        // getCategory();
        eventSocket.emit(
          'addsMenu',
          JSON.stringify({
            categoryId: res.id.toString(),
            children: [
              {
                assignee: '',
                categoryId: res.id.toString(),
                key: `${res.id}-9999999`,
                name: '',
                tags: [],
                type: 'child',
              },
            ],
            id: res.id.toString(),
            key: `${res.id}`,
            menuCount: 0,
            menus: [],
            name: catTitle.trim(),
            restaurantId: resto.id.toString(),
            type: 'parent',
          })
        );
      }, 2000);
    }
  };

  const addMenu = (e, obj) => {
    console.log(e, obj);
    e.preventDefault();
    setSelected(obj);
    setAddMenu(true);
  };

  const duplicateMenu = (e, obj) => {
    e.preventDefault();
    duplicate(obj.id).then((res) => {
      if (res) {
        eventSocket.emit(
          'addMenuItem',
          JSON.stringify({
            activate: res?.activate,
            activerecipes: res?.activerecipes,
            assignee: res?.assignee,
            assigneeImg: res?.assigneeImg,
            attachments: res?.attachments,
            categorized: res?.categorized,
            categoryId: res?.categoryId,
            id: res?.id,
            key: `${res.categoryId}-${res.id}`,
            name: res?.name,
            prepped: res?.prepped,
            recipes: res?.recipes,
            tags: res?.tags,
            texts: res?.texts,
            type: 'child',
          })
        );
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
    });
  };

  const createMenu = () => {
    let prep = { active: false };
    let params = {
      restaurantId: restoId,
      name: menuTitle.trim(),
      categoryId: selected.categoryId,
      info: prep,
    };

    if (!menuTitle) {
      // notification.open({
      //   message: 'Please provide a menu name',
      // });
      return;
    }

    console.log('NEW MENU', params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created menu! Fetching all categories...',
      // });
      eventSocket.emit(
        'addMenuItem',
        JSON.stringify({
          activate: res?.activate,
          activerecipes: res?.activerecipes,
          assignee: res?.assignee,
          assigneeImg: res?.assigneeImg,
          attachments: res?.attachments,
          categorized: res?.categorized,
          categoryId: res?.categoryId,
          id: res?.id,
          key: `${res.categoryId}-${res.id}`,
          name: res?.name,
          prepped: res?.prepped,
          recipes: res?.recipes,
          tags: res?.tags,
          texts: res?.texts,
          type: 'child',
        })
      );
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    });
  };

  const createMenu2 = () => {
    let prep = { active: false };
    let params = {
      restaurantId: restoId,
      name: menuTitle.trim(),
      categoryId: selected.categoryId,
      info: prep,
    };

    if (!menuTitle) {
      // notification.open({
      //   message: 'Please provide a menu name',
      // });
      return;
    }

    console.log('NEW MENU', params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created menu! Fetching all categories...',
      // });
      eventSocket.emit(
        'addMenuItem',
        JSON.stringify({
          activate: res?.activate,
          activerecipes: res?.activerecipes,
          assignee: res?.assignee,
          assigneeImg: res?.assigneeImg,
          attachments: res?.attachments,
          categorized: res?.categorized,
          categoryId: res?.categoryId,
          id: res?.id,
          key: `${res.categoryId}-${res.id}`,
          name: res?.name,
          prepped: res?.prepped,
          recipes: res?.recipes,
          tags: res?.tags,
          texts: res?.texts,
          type: 'child',
        })
      );
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    });
  };

  // const duplicatingMenu = () => {};

  // const duplicatingMenu2 = () => {};

  const toggleMenus = (checked, obj) => {
    console.log(checked, obj);
    let prep = {
      active: checked,
      activerecipes: obj?.activerecipes,
      assignee: obj?.assignee,
      assigneeImg: obj?.assigneeImg,
      attachments: obj?.attachments,
      categorized: obj?.categorized,
      recipes: obj?.recipes,
      tags: obj?.tags,
      texts: obj?.texts,
    };

    eventSocket.emit(
      'updateStationMenu',
      JSON.stringify({
        id: obj.id,
      })
    );
    eventSocket.emit(
      'updatesMenuItem',
      JSON.stringify({
        activate: checked,
        activerecipes: obj.activerecipes,
        assignee: obj.assignee,
        assigneeImg: obj.assigneeImg,
        attachments: obj.attachments,
        categorized: obj.categorized,
        categoryId: obj.categoryId,
        id: obj.id,
        key: obj.key,
        name: obj.name,
        prepped: obj.prepped,
        recipes: obj.recipes,
        tags: obj.tags,
        texts: obj.texts,
        type: 'child',
      })
    );

    if (checked) {
      activateChecklist(obj.id).then((res) => {
        updateMenu(obj.id, {
          info: prep,
          active: checked,
        }).then((res) => {
          // notification.open({
          //   message: 'Successfully activated menu!',
          // });
          refreshMenuItem(obj, 'activate', checked);
          // setTimeout(() => {
          // 	window.location.reload();
          // }, 5000);
        });
      });
    } else {
      // deactivateChecklist(obj.id).then((res) => {
      updateMenu(obj.id, {
        info: prep,
        active: checked,
      }).then((res) => {
        // notification.open({
        //   message: 'Successfully deactivated menu!',
        // });
        refreshMenuItem(obj, 'activate', checked);
        // setTimeout(() => {
        // 	window.location.reload();
        // }, 5000);
      });
      // });
    }
  };

  const deleteMenu = () => {
    console.log('DELETE THIS MENU', menuDelete.id);
    const isMenuOriginal = menuDelete.name.indexOf('Copy of ') === 0 ? false : true;
    destroy(menuDelete.id, isMenuOriginal).then((res) => {
      // notification.open({
      //   message: 'Successfully deleted menu!',
      // });
      eventSocket.emit(
        'deleteMenuItem',
        JSON.stringify({
          id: menuDelete.id,
        })
      );
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);
    });
  };

  const deleteCat = () => {
    console.log('DELETE THIS CAT', catDelete.id);
    destroyMenuCategory(catDelete.id).then((res) => {
      // notification.open({
      //   message: 'Successfully deleted category!',
      // });
      eventSocket.emit(
        'deletesMenu',
        JSON.stringify({
          id: catDelete.id,
        })
      );
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);
    });
  };

  const editCatgory = () => {
    setTriggerEditCat(true);
  };

  const editMenu = () => {
    setTriggerEditMenu(true);
  };

  const menuList = (
    <Menu className="menu-show-preview">
      {menulist &&
        [{ name: 'All menus' }].concat(menulist).map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" key={`${i}-${m.menuId}`} onClick={() => filterMenus(m)}>
              <span>{m?.name ? m.name : 'Unnamed menu'}</span>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const deletesMenu = (obj) => (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={editMenu}>
        <span>Edit</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={() => duplicateMenu(obj)}>
        <span>Duplicate</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={deleteMenu}>
        <span>Delete</span>
      </Menu.Item>
    </Menu>
  );

  const deletesCategory = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={editCatgory}>
        <span>Edit</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={deleteCat}>
        <span>Delete</span>
      </Menu.Item>
    </Menu>
  );

  const filterMenus = (menu) => {
    let menuName = menuName === 'All menus' ? menu.name : '';
    setMenuName(menuName);
    let filtered = 
      menuName === 'All menus' ? 
        _.cloneDeep(pristineData) : 
        _.cloneDeep(pristineData).filter(
          (obj) => obj.children.some(
            (cat) => cat.name === menuName
          )
        );
    let viewOnly = menuName !== 'All menus' ? true : false;

    console.log(filtered);

    if (
      localStorage.getItem('menuColKeyOrder') != undefined
    ) {
      let nameOrder = localStorage.getItem('menuColKeyOrder');
      if (nameOrder) {
        if (nameOrder == 'ascend') {
          filtered.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          setMenus(filtered);
        } else {
          filtered.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
          setMenus(filtered);
        }
      }
    } else if (
      localStorage.getItem('menuOrder') != undefined &&
      JSON.parse(localStorage.getItem('menuOrder')).length
    ) {
      const menuOrder = JSON.parse(localStorage.getItem('menuOrder'));
      const filtered = annotateTableOrder(filtered, menuOrder, viewOnly);
      setMenus(filtered);
    } else {
      setMenus(filtered);
    }
  };

  // const dragProps = {
  //   onDragEnd(fromIndex, toIndex) {
  //     if (dragParent) {
  //       const data = [...menus];
  //       const item = data.splice(fromIndex, 1)[0];
  //       data.splice(toIndex, 0, item);
  //       setMenus(data);
  //       setPristineData(data); {}
  //     } else {
  //       console.log(getCatId);
  //       let originalData = [...pristineData];
  //       const getObj = originalData.find((p) => p.categoryId == getCatId.categoryId);
  //       const data = getObj.children;
  //       const findIndx = data.findIndex((x) => x.id === getCatId.id);
  //       let firstIndex = Math.floor(fromIndex - findIndx);
  //       let destinationIndex = Math.floor(toIndex - firstIndex);
  //       const item = data.splice(findIndx, 1)[0];
  //       data.splice(destinationIndex, 0, item);
  //       originalData.find((v) => v.id === getCatId.categoryId).children = data;
  //       console.log('REORDERED', originalData);
  //       setMenus(originalData);
  //     }
  //   },
  //   handleSelector: 'a',
  //   nodeSelector: dragParent
  //     ? 'tr.ant-table-row.parent.ant-table-row-level-0'
  //     : 'tr.ant-table-row.child.ant-table-row-level-1',
  //   ignoreSelector: dragParent
  //     ? 'tr.ant-table-row.child.ant-table-row-level-1'
  //     : 'tr.ant-table-row.parent.ant-table-row-level-0',
  // };

  // const toggleClass = (e, obj) => {
  //   setDragParent(true);
  //   setActiveCat(obj.id);
  //   setIsDown(!isDown);
  // };

  // const getObj = (e, obj) => {
  //   setGetCatId(obj);
  //   setDragParent(false);
  // };

  const searchMenus = (e) => {
    let inputValue = e.target.value;

    const clonedData = _.cloneDeep(pristineData);

    if (inputValue.length) {
      let searchString = inputValue.toLowerCase();

      let results = searchAllArr(clonedData, inputValue);

      let searchTags = results.map((item) => ({
        ...item,
        children: item.children?.filter(
          (child) =>
            child.tags?.find((a) => a.includes(searchString)) ||
            child.name?.toLowerCase().indexOf(searchString) !== -1 ||
            child.assignee?.toLowerCase().indexOf(searchString) !== -1
        ),
      }));

      if (searchTags.length === 1) {
        searchTags[0].selected = true;
        const updatedSearchTags = searchTags;
        setMenus(updatedSearchTags);
      } else {
        const updatedSearchTags = searchTags.map((tag) => {
          if (tag.selected) {
            tag.selected = false;
          }
          return tag;
        });
        if (localStorage.getItem('menuColKeyOrder') != undefined) {
          let nameOrder = localStorage.getItem('menuColKeyOrder');
          if (nameOrder) {
            if (nameOrder == 'ascend') {
              updatedSearchTags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
              setMenus(updatedSearchTags);
            } else {
              updatedSearchTags.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
              setMenus(updatedSearchTags);
            }
          }
        } else if (
          localStorage.getItem('menuOrder') != undefined && 
          JSON.parse(localStorage.getItem('menuOrder')).length
        ) {
          const menuOrder = JSON.parse(localStorage.getItem('menuOrder'));
          const toDisplay = annotateTableOrder(updatedSearchTags, menuOrder, true);
          setMenus(toDisplay);
        } else {
          setMenus(updatedSearchTags);
        }
      }
    } else {
      if (localStorage.getItem('menuColKeyOrder') != undefined) {
        let nameOrder = localStorage.getItem('menuColKeyOrder');
        if (nameOrder) {
          if (nameOrder == 'ascend') {
            clonedData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            setMenus(clonedData);
          } else {
            clonedData.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
            setMenus(clonedData);
          }
        }
      } else if (
        localStorage.getItem('menuOrder') != undefined && 
        JSON.parse(localStorage.getItem('menuOrder')).length
      ) {
        const menuOrder = JSON.parse(localStorage.getItem('menuOrder'));
        const toDisplay = annotateTableOrder(clonedData, menuOrder, false);
        setMenus(toDisplay);
      } else {
        setMenus(clonedData);
      }
    }
  };

  const searchAllArr = (dataArray, searchTerm) => {
    return dataArray.flatMap((obj) => {
      const objHasSearchTerm = Object.entries(obj).some(
        ([key, value]) => key !== 'children' && String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (objHasSearchTerm && !obj.children) return [obj];

      const matchedChildren = searchAllArr(obj.children ?? [], searchTerm);
      return objHasSearchTerm || matchedChildren.length > 0
        ? [
            {
              ...obj,
              children: matchedChildren,
            },
          ]
        : [];
    });
  };

  const closeDrawer = useCallback((data) => {
    updateVisible(false);
    getCategory();
  }, []);

  const onClose = () => {
    updateVisible(false);
    getCategory();
  };

  const showDrawer = (e, obj) => {
    updateVisible(true);
    setDocLinks({
      type: 'menus',
      linkId: obj.id,
    });
    setLinks({
      type: 'menus',
      linkId: obj.id,
      info: { title: obj.name },
    });
  };

  const handleAccordion = (e, s) => {
    if (s.selected) {
      let selected = [...menus];
      selected.map((select) => {
        if (select.id == s.id) {
          select.selected = false;
        }
        return select;
      });
      setMenus(selected);
    } else {
      let selected = [...menus];
      selected.map((select) => {
        if (select.id == s.id) {
          select.selected = true;
        }
        return select;
      });
      setMenus(selected);
    }
  };

  const sortByName = () => {
    localStorage.removeItem('menuOrder');
    let selected = _.cloneDeep(pristineData);
    if (
      localStorage.getItem('menuColKeyOrder') != undefined
    ) {
      let nameOrder = localStorage.getItem('menuColKeyOrder');
      if (nameOrder === 'ascend') {
        selected.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setMenus(selected);
      } else {
        selected.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
        setMenus(selected);
      }
    } else {
      selected.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      setMenus(selected);
      localStorage.setItem('menuColKeyOrder', 'ascend');
    }
  };

  const editMenu1 = (obj) => {
    let rename = menu_edit.current.value.trim();
    let params = {
      name: rename,
    };
    eventSocket.emit(
      'updateStationMenu',
      JSON.stringify({
        id: obj.id,
      })
    );
    eventSocket.emit(
      'updatesMenuItem',
      JSON.stringify({
        activate: obj.activate,
        activerecipes: obj.activerecipes,
        assignee: obj.assignee,
        assigneeImg: obj.assigneeImg,
        attachments: obj.attachments,
        categorized: obj.categorized,
        categoryId: obj.categoryId,
        id: obj.id,
        key: obj.key,
        name: rename,
        prepped: obj.prepped,
        recipes: obj.recipes,
        tags: obj.tags,
        texts: obj.texts,
        type: 'child',
      })
    );
    updateMenu(obj.id, params).then((res) => {
      setTimeout(() => {
        // notification.open({
        //   message: 'Successfully updated menu!',
        // });
        // getCategory();
      }, 2000);
    });
  };

  const editMenu2 = (e, obj) => {
    let rename = menu_edit.current.value.trim();
    let params = {
      name: rename,
    };
    eventSocket.emit(
      'updateStationMenu',
      JSON.stringify({
        id: obj.id,
      })
    );
    eventSocket.emit(
      'updatesMenuItem',
      JSON.stringify({
        activate: obj.activate,
        activerecipes: obj.activerecipes,
        assignee: obj.assignee,
        assigneeImg: obj.assigneeImg,
        attachments: obj.attachments,
        categorized: obj.categorized,
        categoryId: obj.categoryId,
        id: obj.id,
        key: obj.key,
        name: rename,
        prepped: obj.prepped,
        recipes: obj.recipes,
        tags: obj.tags,
        texts: obj.texts,
        type: 'child',
      })
    );
    updateMenu(obj.id, params).then((res) => {
      setTimeout(() => {
        // notification.open({
        //   message: 'Successfully updated menu!',
        // });
        // getCategory();
      }, 2000);
    });
  };

  const cancelEditMenu = () => {
    setTriggerEditMenu(false);
  };

  const cancelAddMenu = () => {
    setAddMenu(false);
  };

  const editCategory = (e, obj) => {
    let rename = cat_name.current.value;
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    let params = {
      restaurantId: resto.id,
      name: rename.trim(),
    };
    eventSocket.emit(
      'updatesMenu',
      JSON.stringify({
        categoryId: obj.id.toString(),
        children: obj.children,
        id: obj.id.toString(),
        key: `${obj.id}`,
        menuCount: obj.menuCount,
        menus: [],
        name: rename.trim(),
        restaurantId: resto.id.toString(),
        type: 'parent',
      })
    );
    updateMenuCategory(obj.id, params).then((res) => {
      // setTimeout(() => {
        // notification.open({
        //   message: 'Successfully updated category! Fetching all categories...',
        // });
        // getCategory();
      // }, 2000);
    });
  };

  const editCategory2 = (e, obj) => {
    let rename = cat_name.current.value;
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    let params = {
      restaurantId: resto.id,
      name: rename.trim(),
    };
    eventSocket.emit(
      'updatesMenu',
      JSON.stringify({
        categoryId: obj.id.toString(),
        children: obj.children,
        id: obj.id.toString(),
        key: `${obj.id}`,
        menuCount: obj.menuCount,
        menus: [],
        name: rename.trim(),
        restaurantId: resto.id.toString(),
        type: 'parent',
      })
    );
    updateMenuCategory(obj.id, params).then((res) => {
      setTimeout(() => {
        // notification.open({
        //   message: 'Successfully updated category! Fetching all categories...',
        // });
        // getCategory();
      }, 2000);
    });
  };

  const cancelEditCat = () => {
    setTriggerEditCat(false);
  };

  const handleEditTags = (e, obj, type) => {
    e.preventDefault();
    console.log(menus);
    const clonedChecklist = _.cloneDeep(menus);
    const foundIndex = _.findIndex(clonedChecklist, { id: obj.categoryId });
    const foundChildIndex = _.findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
    clonedChecklist[foundIndex].children[foundChildIndex].tags.push(e.target.value);
    setMenus(clonedChecklist);
    let tagList = clonedChecklist[foundIndex].children[foundChildIndex].tags.filter((item) => item);
    let tags = { tags: tagList, createdBy: obj.assignee, active: obj.activate };
    let params = {
      info: tags,
    };

    if (!e.target.value && tagList.length > 0) {
      eventSocket.emit(
        'updatesMenuItem',
        JSON.stringify({
          activate: obj.activate,
          activerecipes: obj.activerecipes,
          assignee: obj.assignee,
          assigneeImg: obj.assigneeImg,
          attachments: obj.attachments,
          categorized: obj.categorized,
          categoryId: obj.categoryId,
          id: obj.id,
          key: obj.key,
          name: obj.name,
          prepped: obj.prepped,
          recipes: obj.recipes,
          tags: tagList,
          texts: obj.texts,
          type: 'child',
        })
      );
      updateMenu(obj.id, params).then((res) => {
        // setTimeout(() => {
          // notification.open({
          //   message: 'Successfully updated menu!',
          // });
          // getCategory();
        // }, 2000);
      });
    }
  };

  const handleRemoveTags = (value, obj) => {
    const clonedChecklist = _.cloneDeep(menus);
    const foundIndex = _.findIndex(clonedChecklist, { id: obj.categoryId });
    const foundChildIndex = _.findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
    _.remove(clonedChecklist[foundIndex].children[foundChildIndex].tags, (tag) => {
      return tag === value;
    });
    setMenus(clonedChecklist);
    let tagList = clonedChecklist[foundIndex].children[foundChildIndex].tags;
    let tags = { tags: tagList, createdBy: obj.assignee, active: obj.activate };
    let params = {
      info: tags,
    };
    eventSocket.emit(
      'updatesMenuItem',
      JSON.stringify({
        activate: obj.activate,
        activerecipes: obj.activerecipes,
        assignee: obj.assignee,
        assigneeImg: obj.assigneeImg,
        attachments: obj.attachments,
        categorized: obj.categorized,
        categoryId: obj.categoryId,
        id: obj.id,
        key: obj.key,
        name: obj.name,
        prepped: obj.prepped,
        recipes: obj.recipes,
        tags: tagList,
        texts: obj.texts,
        type: 'child',
      })
    );
    updateMenu(obj.id, params).then((res) => {
      // setTimeout(() => {
        // notification.open({
        //   message: 'Successfully updated menu!',
        // });
        // getCategory();
      // }, 2000);
    });
  };

  // const assigneeOptions1 = (obj) => (
  //   <Menu className="assignee-options1">
  //     {operators?.current?.map((m, i) => {
  //       return (
  //         <Menu.Item className="assignee-options-item1" key={i}>
  //           <span
  //             onClick={() => {
  //               const clonedChecklist = _.cloneDeep(menus);
  //               const foundIndex = _.findIndex(clonedChecklist, { id: obj.categoryId });
  //               const foundChildIndex = _.findIndex(clonedChecklist[foundIndex].children, { key: obj.id });
  //               clonedChecklist[foundIndex].children[foundChildIndex].assignee = `${m.firstName} ${m.lastName}`;
  //               setMenus(clonedChecklist);
  //               let assigneeList = clonedChecklist[foundIndex].children[foundChildIndex].assignee;
  //               let assignees = { createdBy: assigneeList, tags: obj.tags, active: obj.activate };
  //               let params = {
  //                 info: assignees,
  //               };
  //               eventSocket.emit(
  //                 'updatesMenuItem',
  //                 JSON.stringify({
  //                   activate: obj.activate,
  //                   activerecipes: obj.activerecipes,
  //                   assignee: assigneeList,
  //                   assigneeImg: obj.assigneeImg,
  //                   attachments: obj.attachments,
  //                   categorized: obj.categorized,
  //                   categoryId: obj.categoryId,
  //                   id: obj.id,
  //                   key: obj.key,
  //                   name: obj.name,
  //                   prepped: obj.prepped,
  //                   recipes: obj.recipes,
  //                   tags: obj.tags,
  //                   texts: obj.texts,
  //                   type: 'child',
  //                 })
  //               );
  //               updateMenu(obj.id, params).then((res) => {
  //                 setTimeout(() => {
  //                   // notification.open({
  //                   //   message: 'Successfully updated menu!',
  //                   // });
  //                   getCategory();
  //                 }, 2000);
  //               });
  //             }}
  //           >
  //             {m.firstName} {m.lastName}
  //           </span>
  //         </Menu.Item>
  //       );
  //     })}
  //   </Menu>
  // );

  useEffect(() => {
    eventSocket.on('updatedMenu', (message) => {
      let newData = JSON.parse(message);
      console.log('CATUPDATED!!', [newData]);
      setCatgUpdate([newData]);
    });
    eventSocket.on('updatedMenuItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMUPDATED!!', newData);
      setMenuUpdate(newData);
    });
    eventSocket.on('addedMenu', (message) => {
      let newData = JSON.parse(message);
      console.log('CATADDED!!', newData);
      setCatgAdd(newData);
    });
    eventSocket.on('addedMenuItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMADDED!!', newData);
      setMenuAdd(newData);
    });
    eventSocket.on('deletedMenu', (message) => {
      let newData = JSON.parse(message);
      console.log('CATDELETED!!', newData.id);
      setCatgDelete(newData.id);
    });
    eventSocket.on('deletedMenuItem', (message) => {
      let newData = JSON.parse(message);
      console.log('ITEMDELETED!!', newData.id);
      setMenuDelete(newData.id);
    });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  const addCategoryToList = (cloned, data) => {
    // find index of UNCATEGORIZED MENUS
    let newCloned = cloned;
    const uncatIndex = _.findIndex(newCloned, (m) => m.key === '9999999999');

    // replace open add row with new added menu category, same index position
    newCloned.splice(uncatIndex, 0, data);
    return newCloned;
  }

  const addMenuToCategory = (cloned, data) => {
    let newCloned = cloned;

    let index = newCloned.findIndex((obj) => obj.categoryId === data.categoryId);
    _.remove(newCloned[index].children, (c) => c.type === 'add-button');
    let dataChildren = newCloned[index].children;

    if (dataChildren.length > 1) {
      newCloned[index].children[dataChildren.length - 1] = { ...data };
    } else {
      newCloned[index].children[0] = { ...data };
    }

    newCloned[index].children.push({
      name: '', 
      type: 'child', 
      categoryId: data.categoryId, 
      key: `${data.categoryId}-9999999`, 
      tags: [], 
      assignee: ''
    });

    return newCloned;
  }

  const updateMenuInCategory = (cloned, data) => {
    let newCloned = cloned;
    let index = newCloned?.findIndex((obj) => obj.categoryId === data?.categoryId);
    let childIndex = newCloned[index]?.children.findIndex((obj) => obj.id === data?.id);
    newCloned = newCloned.map((c) => {
      c.children.map((t) => {
        if (t.id == data.id) {
          c.children[childIndex] = data;
          return t;
        }
      });
      return c;
    });
    return newCloned;
  }

  const removeMenuFromCategory = (cloned, data) => {
    let newCloned = cloned.map((result) => {
      result.children = result.children.filter((d) => d.id != data);
      return result;
    });
    return newCloned;
  }

  const transferMenuToCategory = (cloned, data) => {
    let newCloned = cloned;
    let movedData = cloneDeep(data);
    const findOldCatIndex = _.findIndex(newCloned, { categoryId: movedData.oldCategoryId });
    const findNewCatIndex = _.findIndex(newCloned, { categoryId: movedData.categoryId });
    let newPosition = movedData.destinationIndex;
    delete movedData['oldCategoryId'];
    delete movedData['destinationIndex'];
    let newData = {
      ...movedData
    };
    
    if (
      findOldCatIndex > -1 &&
      findNewCatIndex > -1
    ) {
      _.remove(newCloned[findOldCatIndex].children, (c) => c.id === movedData.id);
      newCloned[findOldCatIndex].menuCount = newCloned[findOldCatIndex].children.length - 1;

      if (newPosition > 0) {
        let newCatGetButtonIndex = newCloned[findNewCatIndex].children.length - 1;
        newPosition = (
          newPosition >= newCatGetButtonIndex
        ) ? newCatGetButtonIndex : newPosition;
        newCloned[findNewCatIndex].children.splice(newPosition, 0, newData);
      } else {
        newCloned[findNewCatIndex].children.unshift(newData);
        // newCloned[index].children.push({
        //   type: 'add-button',
        //   categoryId: movedData.newCategoryId,
        //   key: `${movedData.categoryId}-9999999`,
        // });
      }

      newCloned[findNewCatIndex].menuCount = newCloned[findNewCatIndex].children.length - 1;
      return newCloned;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (Object.keys(catgAdd).length > 0) {
      const clonedData = cloneDeep(menus);
      const clonedSocket = cloneDeep(socketData);

      const updatedMenus = addCategoryToList(clonedData, catgAdd[0]);
      const updatedSocket = addCategoryToList(clonedSocket, catgAdd[0]);

      const updatedTableOrder = cacheTableOrder(updatedMenus);
      setMenus(updatedTableOrder.orderedMenu);
      localStorage.setItem('menuOrder', JSON.stringify(updatedTableOrder.menuOrder));

      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
    }
  }, [catgAdd]);

  useEffect(() => {
    if (catgDelete !== '') {
      // console.log(socketData);
      let updatedMenus = menus.filter((obj) => obj.id !== catgDelete.toString());
      let updatedSocket = socketData.filter((obj) => obj.id !== catgDelete.toString());
      console.log(updatedMenus);

      const updatedTableOrder = cacheTableOrder(updatedMenus);
      setMenus(updatedTableOrder.orderedMenu);
      localStorage.setItem('menuOrder', JSON.stringify(updatedTableOrder.menuOrder));

      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
    }
  }, [catgDelete]);

  useEffect(() => {
    if (Object.keys(catgUpdate).length > 0) {
      console.log(socketData);
      let updatedSocket = socketData.map((obj) => catgUpdate[0].id.toString() === obj.id ? catgUpdate[0] : obj);
      let updatedMenus = menus.map((obj) => catgUpdate[0].id.toString() === obj.id ? catgUpdate[0] : obj);
      const updatedTableOrder = cacheTableOrder(updatedMenus);
      setMenus(updatedTableOrder.orderedMenu);
      localStorage.setItem('menuOrder', JSON.stringify(updatedTableOrder.menuOrder));

      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
      setTriggerEditCat(false);
    }
  }, [catgUpdate]);

  useEffect(() => {
    if (menu1Delete !== '') {
      const clonedData = cloneDeep(menus);
      const clonedSocket = cloneDeep(socketData);

      let updatedSocket = removeMenuFromCategory(clonedSocket, menu1Delete);
      let updatedMenus = removeMenuFromCategory(clonedData, menu1Delete);
      
      const updatedTableOrder = cacheTableOrder(updatedMenus);
      setMenus(updatedTableOrder.orderedMenu);
      localStorage.setItem('menuOrder', JSON.stringify(updatedTableOrder.menuOrder));

      setPristineData(updatedSocket);
      setSocketData(updatedSocket);
    }
  }, [menu1Delete]);

  useEffect(() => {
    if (Object.keys(menuUpdate).length > 0) {
      const clonedSocket = cloneDeep(socketData);
      const clonedMenus = cloneDeep(menus);
      let updatedSocket = [];

      if (
        Object.hasOwn(menuUpdate, 'oldCategoryId') ||
        Object.hasOwn(menuUpdate, 'destinationIndex')
      ) {
        // update with slice and remove
        const updatedMenus = transferMenuToCategory(clonedMenus, menuUpdate);
        updatedSocket = transferMenuToCategory(clonedSocket, menuUpdate);
        if (updatedMenus) {
          const { menuOrder, orderedMenu: tableOrdered } = cacheTableOrder(updatedMenus);
          setMenus(tableOrdered);
          localStorage.setItem('menuOrder', JSON.stringify(menuOrder));
        }
      } else {
        // normal update
        const updatedMenus = updateMenuInCategory(clonedMenus, menuUpdate);
        updatedSocket = updateMenuInCategory(clonedSocket, menuUpdate);
        setMenus(updatedMenus);
        setTriggerEditMenu(false);
      }
      if (updatedSocket) {
        setSocketData(updatedSocket);
        setPristineData(updatedSocket);
      }
      setMenuUpdate({});
    }
  }, [menuUpdate]);

  useEffect(() => {
    if (Object.keys(menuAdd).length > 0) {
      const clonedSocket = cloneDeep(socketData);
      const clonedMenus = cloneDeep(menus);

      const updatedSocket = addMenuToCategory(clonedSocket, menuAdd);
      const updatedMenus = addMenuToCategory(clonedMenus, menuAdd);

      const updatedTableOrder = cacheTableOrder(updatedMenus);
      setMenus(updatedTableOrder.orderedMenu);
      localStorage.setItem('menuOrder', JSON.stringify(updatedTableOrder.menuOrder));

      setSocketData(updatedSocket);
      setPristineData(updatedSocket);
    }
  }, [menuAdd]);

  const removeAssignee = (e, obj) => {
    console.log(obj);
    const clonedChecklist = _.cloneDeep(menus);
    const foundIndex = _.findIndex(clonedChecklist, { id: obj.categoryId });
    const foundChildIndex = _.findIndex(clonedChecklist[foundIndex].children, { key: obj.key });
    clonedChecklist[foundIndex].children[foundChildIndex].assignee = '';
    console.log(clonedChecklist);
    setMenus(clonedChecklist);
    // setSearchedAssignee(''); // CHANGE 1202077200318274
    operators.current = [];
    let assigneeList = clonedChecklist[foundIndex].children[foundChildIndex].assignee;
    let assignees = { createdBy: assigneeList, tags: obj.tags, active: obj.activate };
    let params = {
      info: assignees,
    };
    eventSocket.emit(
      'updatesMenuItem',
      JSON.stringify({
        activate: obj.activate,
        activerecipes: obj.activerecipes,
        assignee: assigneeList,
        assigneeImg: obj.assigneeImg,
        attachments: obj.attachments,
        categorized: obj.categorized,
        categoryId: obj.categoryId,
        id: obj.id,
        key: obj.key,
        name: obj.name,
        prepped: obj.prepped,
        recipes: obj.recipes,
        tags: obj.tags,
        texts: obj.texts,
        type: 'child',
      })
    );
    console.log('REMOVE', obj);
    updateMenu(obj.id, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated menu!',
      // });
    });
  };

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Menus"
        restaurant={restaurant}
        showSearch={showSearch}
        onChange={searchMenus}
        clickSearch={() => setShowSearch(true)}
      />
      <div className="checklist-line"></div>
      <div className="check-controls">
        <div className="all-menusfilter-mobile ">
          <div className="text-spanMenu">{menuName}</div>
          <Dropdown overlay={menuList}>
            <span className="allmenus-icon">
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
          </Dropdown>
        </div>
        <RoleAccessibleComponent
          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
        >
          <PlusButton className="add-cats-mobile" alt="AAdd new category" onClick={addCollection}>
            Add new category
          </PlusButton>
        </RoleAccessibleComponent>
        {/* <RoleAccessibleComponent
          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
        >
          <div className="dropdown-icon-mobile">
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </RoleAccessibleComponent> */}
      </div>
      <div className="collapse-check-mobile">
        <div onClick={collapseAll}>
          <img src={collapseall} className="collapse-all-check" alt="add-button" />
          Collapse all
        </div>
      </div>
      <div className="checklist-table">
        {/* <ReactDragListView {...dragProps}>
            <Table
              columns={columns}
              dataSource={menus}
              rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
              pagination={false}
              onExpand={(expanded, record) => handleRowExpand(record)}
              expandedRowKeys={expandedRows}
            />
          </ReactDragListView> */}

        {isAdding ? (
          <div style={{ margin: 20 }}>
            <input
              autoFocus
              type="text"
              className="courses-bar-mobile"
              placeholder="Enter a category name"
              onChange={(e) => setCatTitle(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  if (isAdding) {
                    createCategory(e);
                  }
                }
              }}
              onBlur={createCategory2}
            />
            {/* <img src={add} className="add-menu" alt="add" onClick={createCategory} /> */}
            <div
              onMouseDown={() => setIsAdding(false)}
              style={{
                width: 100,
                cursor: 'pointer',
                margin: '8px 0px 7px 3px',
                color: 'var(--accent-color)',
                fontSize: 15,
              }}
            >
              Cancel
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppableId" type="droppableItem">
          {(provided, snapshot) => (
            <div className="main12" {...provided.droppableProps} ref={provided.innerRef}>
              <div className="title12" onClick={sortByName}>
                Sorted by title
              </div>
              {menus?.length &&
                menus?.map((s, i) => {
                  return (
                    <Draggable key={s.key} draggableId={s.key} index={i}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div className={s.selected ? 'section12 open' : 'section12'}>
                            {/* <button>toggle</button> */}
                            <div className="menu-collection-header-mobile">
                              <div
                                className="menu-accordian-icon-container-mobile"
                                onClick={(e) => handleAccordion(e, s)}
                              >
                                <div
                                  className={s.selected ? 'menu-accordian-open-mobile' : 'menu-accordian-closed-mobile'}
                                  alt="select_arrow"
                                >
                                  <ChevronLeft />
                                </div>
                              </div>
                              {
                                (
                                  !triggerEditCat || 
                                  (
                                    triggerEditCat && 
                                    s.id != catDelete.id
                                  )
                                ) && (
                                  <span className="collection-name-mobile" onClick={(e) => handleAccordion(e, s)}>
                                    {s.name}
                                  </span>
                                )
                              }

                              {
                                triggerEditCat && 
                                catDelete.id == s.id && 
                                (
                                  <span style={{ margin: 0 }}>
                                    <input
                                      autoFocus
                                      type="text"
                                      className="menuCat-bar"
                                      placeholder="Enter a category name"
                                      defaultValue={s.name}
                                      ref={cat_name}
                                      onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                          editCategory(e, s);
                                        }
                                      }}
                                      onBlur={(e) => editCategory2(e, s)}
                                    />

                                    <div
                                      onMouseDown={() => cancelEditCat()}
                                      style={{
                                        width: 100,
                                        cursor: 'pointer',
                                        margin: '4px 0px 7px 27px',
                                        color: 'var(--accent-color)',
                                        fontSize: 13,
                                      }}
                                    >
                                      Cancel
                                    </div>
                                  </span>
                                )
                              }
                              <span className="collection-menu-count-mobile" onClick={(e) => handleAccordion(e, s)}>
                                ( {s.menuCount > 0 ? s.menuCount + ' ' + 'menus' : 'no menus'} )
                              </span>
                              <span className="menu-collection-widgets-mobile">
                                <a>
                                  <span className="showDragIconMenuMob">
                                    {' '}
                                    <img src={reorder} alt="reorder" className="menusToggles" />
                                  </span>{' '}
                                </a>
                                <RoleAccessibleComponent
                                  permittedRoles={[
                                    'Chef',
                                    'Admin',
                                    'Superadmin',
                                    'Client Admin',
                                    'Dev Team',
                                    'Account Holder',
                                  ]}
                                >
                                  {s.name !== 'UNCATEGORIZED' && (
                                    <Dropdown overlay={deletesCategory}>
                                      <span className={'texts-icon'} onClick={(e) => setDeleteCat(s)}>
                                        {' '}
                                        <img src={IconsMoreOff} className="del-menu" alt="delete" />
                                      </span>
                                    </Dropdown>
                                  )}
                                </RoleAccessibleComponent>
                              </span>
                            </div>

                            <Droppable droppableId={s.key} type={`droppableSubItem`}>
                              {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                  {s.children.map((c, i) => {
                                    return (
                                      <Draggable key={c.key} draggableId={c.key} index={i}>
                                        {(provided, snapshot) => (
                                          <div className="articlewrap">
                                            <div
                                              className="article12"
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div className="menu-collection-entry-mobile">
                                                {c.type == 'child' && c.name !== '' ? (
                                                  <span className="common-folder-icon">
                                                    <FolderIcon />
                                                  </span>
                                                ) : (
                                                  ''
                                                )}
                                                {!triggerEditMenu && (
                                                  <Link to={`/menu/${c.id}`}>
                                                  {/* onMouseOver={(e) => getObj(e, c)}> */}
                                                    <div
                                                      style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        width: c.document > 0 ? 162 : 225,
                                                        paddingTop: 6,
                                                      }}
                                                    >
                                                      {c.name}
                                                    </div>
                                                  </Link>
                                                )}

                                                {triggerEditMenu && menuDelete.id == c.id && (
                                                  <span style={{ margin: 20 }}>
                                                    <input
                                                      type="text"
                                                      className="menuCat-bar"
                                                      placeholder="Enter a menu name"
                                                      defaultValue={c.name}
                                                      ref={menu_edit}
                                                      onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                          editMenu1(c);
                                                        }
                                                      }}
                                                      onBlur={(e) => editMenu2(e, c)}
                                                    />
                                                    <div
                                                      style={{
                                                        width: 100,
                                                        cursor: 'pointer',
                                                        margin: '4px 0px 7px 5px',
                                                        color: 'var(--accent-color)',
                                                        fontSize: 13,
                                                      }}
                                                    >
                                                      <span onMouseDown={() => cancelEditMenu()}>Cancel</span>
                                                      <span
                                                        onMouseDown={() => editMenu1(c)}
                                                        style={{ paddingLeft: '14px' }}
                                                      >
                                                        Save
                                                      </span>
                                                    </div>
                                                  </span>
                                                )}
                                                {c.type == 'child' ? (
                                                  <AttachmentIndicatorMobile
                                                    className="menu-attachment-indicator-mobile"
                                                    showIfZero={true}
                                                    count={c.document}
                                                    onClick={(e) => showDrawer(e, c)}
                                                  />
                                                ) : (
                                                  ''
                                                )}
                                                {c.type == 'child' && c.name !== '' && (
                                                  <span className="menu-collection-widgets-mobile">
                                                    {c.categorized == 'yes' && (
                                                      <a>
                                                        <span className="showDragIconMenuMob">
                                                          {' '}
                                                          <img src={reorder} alt="reorder" className="menusToggles" />
                                                        </span>{' '}
                                                      </a>
                                                    )}

                                                    <RoleAccessibleComponent
                                                      permittedRoles={[
                                                        'Chef',
                                                        'Admin',
                                                        'Superadmin',
                                                        'Client Admin',
                                                        'Dev Team',
                                                        'Account Holder',
                                                      ]}
                                                    >
                                                      <Dropdown overlay={deletesMenu(c)}>
                                                        <span className={'texts-icon'}>
                                                          {' '}
                                                          <img
                                                            src={IconsMoreOff}
                                                            className="del-menu"
                                                            alt="delete"
                                                            onClick={() => setDeleteMenu(c)}
                                                          />
                                                        </span>
                                                      </Dropdown>
                                                    </RoleAccessibleComponent>
                                                  </span>
                                                )}
                                              </div>

                                              <div className="chk-mobile-details">
                                                {c.type == 'child' && c.name !== '' && (
                                                  <span className="chk-mobile-title">
                                                    Recipes
                                                    <span className="chk-mobile-project">
                                                      {c.recipes ? (
                                                        <span>{`${c.recipes.length} recipe${
                                                          c.recipes.length != 1 ? 's' : ''
                                                        }`}</span>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </span>
                                                  </span>
                                                )}
                                              </div>

                                              <div className="chk-mobile-details">
                                                {c.type == 'child' && c.name !== '' && (
                                                  <span className="chk-mobile-title">
                                                    Active
                                                    <span className="chk-mobile-project3">
                                                      {c.recipes ? (
                                                        <span>
                                                          {c.activerecipes.length} out of {c.recipes.length}
                                                        </span>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </span>
                                                  </span>
                                                )}
                                              </div>
                                              <div className="chk-mobile-details">
                                                {c.type == 'child' && c.name !== '' && (
                                                  <span className="chk-mobile-title">
                                                    Creator
                                                    <span className="chk-mobile-assign1">
                                                      {c.assignee ? (
                                                        <div className="common-user-icon" alt="user-photo">
                                                          <UserImage
                                                            src={c.assigneeImg !== '' ? c.assigneeImg : null}
                                                          />
                                                        </div>
                                                      ) : (
                                                        ''
                                                      )}
                                                      {c.assignee && !triggerEditMenu ? <span>{c.assignee}</span> : ''}
                                                    </span>
                                                    {triggerEditMenu && menuDelete.id == c.id && (
                                                      <RowAssigneeEditWithSearch
                                                        assigneeChoices={operatorChoices}
                                                        assignee={c?.assignee ? c.assignee : null}
                                                        onChange={(e) => {
                                                          c.assignee = e.target.value;
                                                        }}
                                                        onClickRemove={(e) => {
                                                          removeAssignee(e.target.value);
                                                        }}
                                                      />
                                                    )}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="chk-mobile-details">
                                                {c.name !== '' && (
                                                  <div className="menu-taggy1">
                                                    <div>Tags</div>
                                                    <div className="menu-taggy-mob">
                                                      {triggerEditMenu && menuDelete.id == c.id && (
                                                        <span style={{ marginBottom: 7 }}>
                                                          <input
                                                            type="text"
                                                            placeholder="Add"
                                                            className="checklist-tags"
                                                            name="input"
                                                            id="input"
                                                            onKeyPress={(e) => {
                                                              if (e.key === 'Enter') {
                                                                handleEditTags(e, c, 'edit');
                                                                e.target.value = '';
                                                              }
                                                            }}
                                                          />
                                                        </span>
                                                      )}
                                                      {c.tags && c?.tags?.length > 0
                                                        ? c.tags.map((tag) => {
                                                            let color = 'black';
                                                            return (
                                                              <Tag
                                                                color={color}
                                                                key={tag}
                                                                closable={triggerEditMenu}
                                                                onClose={(e) => {
                                                                  // remove tags
                                                                  handleRemoveTags(tag, c);
                                                                }}
                                                              >
                                                                {tag}
                                                              </Tag>
                                                            );
                                                          })
                                                        : ''}{' '}
                                                      {c.tags ? (
                                                        <Tooltip title="Add tag">
                                                          <span className="menus-add-tag"></span>
                                                        </Tooltip>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                              <div className="chk-mobile-details-priority">
                                                {c.type == 'child' && c.name !== '' && (
                                                  <span className="chk-mobile-title">
                                                    Activate
                                                    <span>
                                                      <span className="chk-mobile-priority">
                                                        {c.type == 'child' && (
                                                          <span>
                                                            {' '}
                                                            <Switch
                                                              checked={c.activate}
                                                              onChange={(e) => toggleMenus(e, c)}
                                                            />
                                                          </span>
                                                        )}
                                                      </span>
                                                    </span>
                                                  </span>
                                                )}
                                              </div>
                                              <div style={{ paddingBottom: 20 }}>
                                                {!addMenus && c.type == 'child' && c.name == '' ? (
                                                  <span>
                                                    <span className="common-folder-icon">
                                                      <FolderIcon />
                                                    </span>
                                                    <span
                                                      className="task-item-add-mobile"
                                                      onClick={(e) => addMenu(e, c)}
                                                    >
                                                      Add menu
                                                    </span>
                                                  </span>
                                                ) : (
                                                  ''
                                                )}
                                                {addMenus &&
                                                c.type == 'child' &&
                                                selected.categoryId == c.categoryId &&
                                                c.name == '' ? (
                                                  <span style={{ margin: 20 }}>
                                                    <input
                                                      autoFocus
                                                      type="text"
                                                      className="menuCat-bar"
                                                      placeholder="Enter a menu name"
                                                      onChange={(e) => setMenuTitle(e.target.value)}
                                                      onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                          createMenu();
                                                        }
                                                      }}
                                                      onBlur={createMenu2}
                                                    />
                                                    {/* <img src={add} className="add-menu" alt="add" onClick={createMenu} /> */}
                                                    <div
                                                      style={{
                                                        width: 100,
                                                        cursor: 'pointer',
                                                        margin: '4px 0px 7px 27px',
                                                        color: 'var(--accent-color)',
                                                        fontSize: 13,
                                                      }}
                                                    >
                                                      <span onMouseDown={() => cancelAddMenu()}>Cancel</span>
                                                      <span
                                                        onMouseDown={() => createMenu()}
                                                        style={{ paddingLeft: '14px' }}
                                                      >
                                                        Save
                                                      </span>
                                                    </div>
                                                  </span>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </div>
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <RoleAccessibleComponent permittedRoles={['Chef', 'Admin', 'Superadmin']}>
        <div className="add-checklist-mobile" onClick={addCollection}>
          Add category
        </div>
      </RoleAccessibleComponent>
      <Drawer
        width={windowSize.width > 800 ? '850px' : '100%'}
        onClose={onClose}
        visible={visible}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentList
          windowSize={windowSize}
          closeDrawer={closeDrawer}
          docLinks={docLinks}
          links={links}
          isMobile={true}
          documentsIn="menus"
        />
      </Drawer>
    </div>
  );
};

export default MenusMobile;
