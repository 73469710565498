import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Tag, Switch, Tooltip, notification, Menu, Dropdown, Drawer, Select } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { search, create, update, destroy } from '../../services/ingredient.service';
import { onFileSelected } from '../../services/upload.service';
import { all } from '../../services/account.service';
import { cloneDeep, find, remove, findIndex, isEmpty } from 'lodash';
import _ from 'lodash';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { NewSubHeader } from '../../components/Header/Header';
import {
  CidekicButton,
  PlusButton,
  CidekicDropdown,
  CidekicSelect,
  ThumbnailImage,
  RowTags,
  RowOkCancel,
} from '../../components/common/common_ui';

const SuperIngredients = (props) => {
  const [menuName, setMenuName] = useState('');
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState({});
  const [menulist, setList] = useState([]);
  const [editTags, setEditTags] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);
  const [title, setTitle] = useState([]);
  const [getPagination, setPagination] = useState({ pageSize: 100 });
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState(0);
  const [editObj, setEditObj] = useState({});
  const [userimg, setUserImg] = useState('');
  const [loading, setLoading] = useState(false);
  const [socketData, setSocketData] = useState([]);
  const [ingUpdate, setIngUpdate] = useState([]);
  const [ingAdd, setIngAdd] = useState([]);
  const [ingDelete, setIngDelete] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/ingredient`));
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState({});
  const [selectedSub, setSelectedSub] = useState([]);
  const [secondDropdown, setSecondDropdown] = useState('');

  const { Option, OptGroup } = Select;

  const location_name = useRef(null);
  const fileInput = useRef();

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let accountsId = JSON.parse(localStorage.getItem('operator')).accountId || '';
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';

  const newLocationValue = {
    id: 'add',
    name: '',
    category: '',
    subcategory: '',
    tags: [],
    image: '',
    restaurantId: resto?.id,
  };

  useEffect(() => {
    getIngs();
    setTimeout(() => {
      setEditing(false);
      setAdding(false);
      setEditId(null);
      setEditObj(null);
      setPagination({ pageSize: 100 });
    }, 2000);
  }, [hasUpdated]);

  useEffect(() => {
    getAccounts();
    console.log('LOCATION!!!!', resto);
  }, []);

  const getIngs = () => {
    setHasUpdated(false);
    setIsLoading(true);
    let params = {
      // restaurantId: resto?.id,
      from: 0,
      limit: 10000,
      accountId: accountsId,
      isSuperAdmin: true,
    };
    search(params).then((res) => {
      console.log(res);
      if (res) {
        let allIngs = res.ingredients;
        let ingredients = allIngs.map((l) => {
          let parsed = JSON.parse(l.info);
          return {
            id: l.id,
            name: l.name,
            restaurantId: l.restaurantId,
            category: parsed && parsed.category ? parsed.category : '',
            subcategory: parsed && parsed['sub category'] ? parsed['sub category'] : '',
            tags: parsed && parsed.tags ? parsed.tags : [],
            image:
              parsed.imageUrlThumb || parsed.imageUrl
                ? parsed.imageUrlThumb || parsed.imageUrl
                : l.imageUrlThumb || l.imageUrl
                ? l.imageUrlThumb || l.imageUrl
                : '',

            accountId: l.accountId,
            isMaster: parsed && parsed.createdBy === 'system_ingest',
          };
        });

        console.log(ingredients);
        ingredients.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setData(ingredients);
        setOrigData(ingredients);
        setSocketData(ingredients);
        setIsLoading(false);

        let getCat = ingredients.map((c) => c.category);
        let uniq = getCat.filter((v, i) => getCat.indexOf(v) == i);
        setCategories(uniq.sort());

        const getSubCat = ingredients.reduce((r, ing) => {
          const { category, subcategory } = ing;
          r[category] = [...(r[category] || []), subcategory];

          return r;
        }, {});

        console.log(getSubCat);
        setSubCategories(getSubCat);
      }
    });
  };

  const getAccounts = () => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setList(allAccounts);
      }
    });
  };

  useEffect(() => {
    filterIngs(nameFilter);
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All accounts');
  };
  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All accounts</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>{m?.name}</a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterIngs = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getIngs();
    }
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.accountId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deleteImg = (e, obj) => {
    e.preventDefault();
    console.log(obj);

    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].image = '';
    console.log(clonedChecklist);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const deleteIngs = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: 'Unable to delete a master list item.',
      // });
      return;
    }
    e.preventDefault();
    let id = selected?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Ingredient Successfully Deleted',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'deleteIng',
        JSON.stringify({
          id: id,
        })
      );
    });
  };

  const editIngs = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    e.preventDefault();
    console.log(obj);
    let uniq = subcategories[obj.category].filter((v, i) => subcategories[obj.category].indexOf(v) == i);
    setSelectedSub(uniq.sort());
    setEditing(true);
    setEditId(obj.id);
    setEditObj(obj);
    setSecondDropdown(obj.subcategory);
  };

  const controlItem = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteImg(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editIngs(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteIngs(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = (obj) => {
    obj.image = selected?.image ? selected.image : '';
    setEditing(false);
    setEditId(null);
    setEditObj(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
    setPagination({ pageSize: 100 });
  };

  const updateIngs = (obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    let rename = location_name.current.value.trim();
    console.log('UPDATE!!!', obj);
    // console.log("rename is " + rename);
    let selectedObj = {};
    if (obj == null) {
      selectedObj = editObj;
    } else {
      selectedObj = obj;
    }
    let params = {
      accountId: selectedObj.accountId !== '' ? selectedObj.accountId : '0',
      name: rename,
      info: {
        category: selectedObj.category,
        'sub category': selectedObj.subcategory,
        tags: selectedObj.tags,
        imageUrl: obj.image,
      },
      restaurantId: obj.restaurantId,
    };
    console.log(params);
    eventSocket.emit(
      'updateIng',
      JSON.stringify({
        accountId: params.accountId,
        category: params.info?.category,
        id: obj.id,
        image: params.info?.imageUrl,
        name: rename,
        restaurantId: obj.restaurantId,
        subcategory: obj.subcategory,
        tags: params.info?.tags,
      })
    );
    update(editId, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated ingredient!',
      // });
      setHasUpdated(true);
    });
  };

  const addIngs = (obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      accountId: account,
      name: rename !== '' ? rename : 'Untitled',
      info: {
        category: userData[0].category,
        'sub category': userData[0].subcategory,
        tags: userData[0].tags,
        imageUrl: userimg,
      },
      restaurantId: resto?.id,
    };
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created ingredient!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'addIng',
        JSON.stringify({
          accountId: res?.accountId?.toString(),
          category: res?.info?.category,
          id: res?.id?.toString(),
          image: res?.info?.imageUrl,
          name: res?.name,
          restaurantId: res?.restaurantId,
          subcategory: res?.info?.subcategory,
          tags: res?.info?.tags,
        })
      );
    });
  };

  const handleCatChange = (value, obj) => {
    let uniq = subcategories[value].filter((v, i) => subcategories[value].indexOf(v) == i);
    setSelectedSub(uniq.sort());
    setSecondDropdown(uniq[0]);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].category = value;
    clonedChecklist[foundIndex].subcategory = uniq[0];
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleSubCatChange = (value, obj) => {
    setSecondDropdown(value);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].subcategory = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleAddTags = (value, obj, type) => {
    console.log(value);
    console.log(obj);

    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].tags.push(value);
    console.log(clonedChecklist);
    setData(clonedChecklist);
    console.log(clonedChecklist[foundIndex]);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleRemoveTags = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    remove(clonedChecklist[foundIndex].tags, (tag) => {
      return tag === value;
    });
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleAccountChange = (value, obj) => {
    console.log(value, obj);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].accountId = value;
    setAccount(value);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
    setEditTags(true);
  };

  const uploadImg = (e, obj) => {
    setLoading(true);
    if (!isAdding) {
      setEditing(true);
    }
    e.preventDefault();
    e.stopPropagation();

    onFileSelected(e.target.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        setUserImg(res.Location);
        obj.image = res.Location;
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        setLoading(false);
      } else {
        setLoading(false);
        let userimage = URL.createObjectURL(e.target.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleOnDrop = (e, obj) => {
    setLoading(true);
    if (!isAdding) {
      setEditing(true);
    }
    e.preventDefault();
    e.stopPropagation();
    console.log(e.dataTransfer.files[0]);

    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        obj.image = res.Location;
        setUserImg(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        setLoading(false);
      } else {
        setLoading(false);
        let userimage = URL.createObjectURL(e.dataTransfer.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'photo',
      render: (key, obj) => (
        <span id={`photo-${key}`}>
          {isEditing && editId == obj.id && (
            <span
              onClick={() => fileInput.current.click()}
              onDragOver={handleDragOver}
              onDrop={(e) => handleOnDrop(e, obj)}
            >
              <ThumbnailImage type={'admin'} src={obj.image && obj.image !== '' ? obj.image : null} />
              <input
                type="file"
                style={{ display: 'none', cursor: 'pointer' }}
                onChange={(e) => uploadImg(e, obj)}
                ref={fileInput}
                accept="image/*"
              />
            </span>
          )}
          {((isEditing && editId !== obj.id) || (isAdding && obj.id !== 'add') || (!isEditing && !isAdding)) && (
            <ThumbnailImage type={'admin'} src={obj.image && obj.image !== '' ? obj.image : null} />
          )}
          {isAdding && obj.id == 'add' && (
            <span
              onClick={() => fileInput.current.click()}
              onDragOver={handleDragOver}
              onDrop={(e) => handleOnDrop(e, obj)}
            >
              <ThumbnailImage type={'admin'} src={obj.image && obj.image !== '' ? obj.image : null} />
              <input
                type="file"
                style={{ display: 'none', cursor: 'pointer' }}
                onChange={(e) => uploadImg(e, obj)}
                ref={fileInput}
                accept="image/*"
              />
            </span>
          )}
          <span>
            {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
              <div className="common-controls-container">
                <Dropdown overlay={controlItem(obj)}>
                  <div className="toggle-switch">
                    <img
                      src={IconsMoreOff}
                      className="UI-IconsMoreOff"
                      alt="IconsMoreOff"
                      onMouseEnter={() => {
                        if (Object.keys(selected).length === 0 || selected.id != obj.id) {
                          setSelected({ ...obj });
                        }
                      }}
                    />
                  </div>
                </Dropdown>
              </div>
            ) : (
              ''
            )}
          </span>
        </span>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateIngs(obj);
                }
              }}
            />
          )}
          {isAdding && obj.id == 'add' && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  addIngs(obj);
                }
              }}
            />
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: (a, b) => (a.category < b.category ? -1 : a.category > b.category ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {((isAdding && obj.id == 'add') || (isEditing && editId == obj.id)) && (
            <CidekicSelect
              className="admin-select-select"
              placeholder="Select category"
              defaultValue={isAdding ? undefined : key ? key : categories[0]}
              onChange={(value) => handleCatChange(value, obj)}
              showSearch
            >
              {categories.map((cat) => (
                <Option value={cat}>{cat}</Option>
              ))}
            </CidekicSelect>
          )}
          {obj.id !== '' && obj.id !== editId && obj.id !== 'add' && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      sorter: (a, b) => (a.subcategory < b.subcategory ? -1 : a.subcategory > b.subcategory ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {((isAdding && obj.id == 'add') || (isEditing && editId == obj.id)) && (
            <CidekicSelect
              className="admin-select-select"
              placeholder="Select subcategory"
              defaultValue={isAdding ? undefined : key}
              onChange={(value) => handleSubCatChange(value, obj)}
              showSearch
            >
              {selectedSub.map((subcat) => (
                <Option value={subcat}>{subcat}</Option>
              ))}
            </CidekicSelect>
          )}
          {obj.id !== '' && obj.id !== editId && obj.id !== 'add' && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      sorter: (a, b) => (a.tags < b.tags ? -1 : a.tags > b.tags ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <RowTags
            tags={obj.tags}
            isEditing={(editId == obj.id && isEditing) || (isAdding && obj.id == 'add')}
            inputName="input"
            inpuId="input"
            objId={obj.id}
            onEditKeyPress={(e) => {
              setEditTags(false);
              if (e.key === 'Enter') {
                handleAddTags(e.target.value, obj, 'edit');
                e.target.value = '';
                setEditTags(true);
              }
            }}
            closable={obj.id == 'add' || editId == obj.id}
            onClose={(tag) => handleRemoveTags(tag, obj)}
          />
        </span>
      ),
    },
    {
      title: 'Account',
      dataIndex: 'accountId',
      render: (key, obj) => (
        <span>
          {(isAdding && obj.id == 'add') || (isEditing && editId == obj.id) ? (
            <CidekicSelect
              className="admin-select-select"
              placeholder="Select account"
              defaultValue={isAdding ? undefined : key}
              onChange={(val) => handleAccountChange(val, obj)}
              showSearch
            >
              {menulist && menulist.length > 0 && menulist.map((m) => <Option value={m.id}>{m.name}</Option>)}
            </CidekicSelect>
          ) : (
            <span>{menulist && menulist.length > 0 && menulist.find((x) => x.id == key)?.name}</span>
          )}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'controls',
      render: (key, obj) => (
        <span>
          {!isEditing && !isAdding && (
            <Dropdown overlay={controlItems(obj)}>
              <div className="toggle-switch">
                <img
                  src={IconsMoreOff}
                  className="UI-IconsMoreOff"
                  alt="IconsMoreOff"
                  onMouseEnter={() => {
                    if (Object.keys(selected).length === 0 || selected.id != obj.id) {
                      setSelected({ ...obj });
                    }
                  }}
                />
              </div>
            </Dropdown>
          )}
          {((isEditing && editId == obj.id) || (isAdding && obj.id == 'add')) && (
            <RowOkCancel
              onClickOk={isEditing ? () => updateIngs(obj) : () => addIngs(obj)}
              onClickCancel={() => cancelEdit(obj)}
            />
          )}
        </span>
      ),
    },
  ];

  useEffect(() => {
    eventSocket.on('updatedIng', (message) => {
      let newData = JSON.parse(message);
      console.log('INGUPDATED!!', [newData]);
      setIngUpdate([newData]);
    });
    eventSocket.on('addedIng', (message) => {
      let newData = JSON.parse(message);
      console.log('INGADDED!!', newData);
      setIngAdd(newData);
    });
    eventSocket.on('deletedIng', (message) => {
      let newData = JSON.parse(message);
      console.log('INGDELETED!!', newData.id);
      setIngDelete(newData);
    });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    setData((oldArray) => [...oldArray, ingAdd]);
    setOrigData((oldArray) => [...oldArray, ingAdd]);
    setSocketData((oldArray) => [...oldArray, ingAdd]);
  }, [ingAdd]);

  useEffect(() => {
    console.log(socketData);
    console.log(ingUpdate);
    let updated = socketData.map((obj) => ingUpdate.find((o) => o.id === obj.id) || obj);
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
  }, [ingUpdate]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.filter((obj) => obj.id.toString() !== ingDelete.id.toString());
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
  }, [ingDelete]);

  return (
    <div id="superadmin-ingredients" className="common-page-container">
      <NewSubHeader title="Ingredients" onChange={(evt) => setNameFilter(evt.target.value)} />
      <div className="Line"></div>
      <div className="common-page-content">
        <div className="common-controls-container controls-thin">
          <PlusButton
            className="flex-control-left"
            alt="Add new ingredient"
            onClick={() => {
              setAdding(true);
              window.scrollTo(0, 0);
              const clonedDataSource = cloneDeep(origData);
              clonedDataSource.splice(0, 0, newLocationValue);
              setData(clonedDataSource);
              setPagination({ current: 1, pageSize: 100 });
              setSecondDropdown('');
            }}
          >
            Add new ingredient
          </PlusButton>

          <CidekicDropdown overlay={menuList} className="flex-control-right select-wide">
            {menuName ? menuName : 'All accounts'}
          </CidekicDropdown>
        </div>

        {!isLoading && (
          <Table
            dataSource={userData}
            columns={columns}
            pagination={getPagination}
            className={'show-custom-empty'}
            locale={{ emptyText: <Empty image={empty} description="No ingredients" /> }}
            scroll={{ y: 'calc(100vh - var(--table-scroll-offset-pagination))' }}
          />
        )}
      </div>
    </div>
  );
};

export default SuperIngredients;
