import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Tag, Switch, Tooltip, notification, Menu, Dropdown, Drawer, Select } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { onFileSelected } from '../../services/upload.service';
import { search, create, update, destroy } from '../../services/equipment.service';
import { allLocations } from '../../services/restaurant.service';
import { all } from '../../services/account.service';
import { cloneDeep, find, remove, findIndex, isEmpty, uniq } from 'lodash';
import RoleAccessibleComponent from '../../components/RoleAccess/roleaccess';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import _ from 'lodash';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { NewSubHeader } from '../../components/Header/Header';
import {
  PlusButton,
  CidekicDropdown,
  CidekicSelect,
  ThumbnailImage,
  RowTags,
  RowOkCancel,
} from '../../components/common/common_ui';

const Equipment = (props) => {
  const [menuName, setMenuName] = useState('');
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState({});
  const [menulist, setList] = useState([]);
  const [accountlist, setAccountList] = useState([]);
  const [account, setAccount] = useState(0);
  const [editTags, setEditTags] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editObj, setEditObj] = useState({});
  const [userimg, setUserImg] = useState('');
  const [loading, setLoading] = useState(false);
  const [socketData, setSocketData] = useState([]);
  const [equipUpdate, setEquipUpdate] = useState([]);
  const [equipAdd, setEquipAdd] = useState([]);
  const [equipDelete, setEquipDelete] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/equipment`));
  const [equipCategories, setEquipCategories] = useState([]);
  const location_name = useRef(null);
  const location_category = useRef(null);
  const fileInput = useRef();

  const { Option } = Select;

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';

  const newLocationValue = {
    id: 'add',
    name: '',
    category: '',
    tags: [],
  };

  useEffect(() => {
    getEquip();
    setTimeout(() => {
      setHasUpdated(false);
      setEditing(false);
      setAdding(false);
      setEditId(null);
      setEditObj(null);
    }, 2000);
  }, [hasUpdated]);

  useEffect(() => {
    getLocations();
  }, []);

  const getEquip = () => {
    setIsLoading(true);
    let params = {
      description: '',
      restaurantId: resto.id,
      accountId: accountId,
    };
    search(params).then((res) => {
      console.log(res);
      if (res) {
        let allEquip = res.filter((r) => r.id !== 'add');
        console.log(res);
        let equipment = allEquip.map((l) => {
          return {
            id: l.id,
            name: l.name,
            restaurantId: l.restaurantId,
            category: l.info && l.info?.category ? l.info?.category : 'Uncategorized',
            tags: l.info && l.info?.tags ? l.info?.tags : [],
            image: l.info ? l.info?.imageUrlThumb || l.info?.imageUrl || '' : '',
            accountId: l.accountId,
            isMaster: l.info && l.info?.createdBy === 'system_ingest',
          };
        });

        console.log(equipment);
        let equipCats = uniq(equipment.map((e) => e.category).filter((cat) => cat !== '' && cat !== null));
        setEquipCategories(equipCats);
        equipment.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setData(equipment);
        setOrigData(equipment);
        setSocketData(equipment);
        setIsLoading(false);
      }
    });
  };

  const getLocations = () => {
    allLocations(0, 1000).then((res) => {
      console.log(res);
      let allLoc = res;
      let address1 = allLoc.filter((a) => a.deletedAt == '');
      let address = address1.map((l) => {
        return {
          id: l.id,
          name: l.name,
          address: l.info && l.info?.address ? l.info?.address : '',
          timezone: l.info && l.info?.timezone ? l.info?.timezone : '',
        };
      });

      console.log(address);
      setList(address);
    });
  };

  useEffect(() => {
    filterEquip(nameFilter);
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All restaurants');
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All restaurants</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>
                {m?.name} - {m?.address}
              </a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterEquip = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getEquip();
    }
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name + '-' + ' ' + menu.address;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.restaurantId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deleteImg = (e, obj) => {
    e.preventDefault();
    console.log(obj);

    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].image = '';
    console.log(clonedChecklist);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
    updateEquip(clonedChecklist[foundIndex]);
  };

  const deleteEquip = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: 'Unable to delete a master list item.',
      // });
      return;
    }
    e.preventDefault();
    let id = selected?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Equipment Successfully Deleted',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'deleteEquipment',
        JSON.stringify({
          id: id,
        })
      );
    });
  };

  const editEquip = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    e.preventDefault();
    console.log(obj);
    setEditing(true);
    setEditId(obj.id);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span
            onClick={(e) => {
              editEquip(e, obj);
            }}
          >
            Edit
          </span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteEquip(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const controlItem = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteImg(e, obj)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = (obj) => {
    obj.image = selected?.image ? selected.image : '';
    setEditing(false);
    setEditId(null);
    setEditObj(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updateEquip = (obj) => {
    console.log('EQUIPMENT UPDATED', obj);
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties.",
      // });
      return;
    }
    let rename = location_name?.current?.value?.trim();
    let recategorize = location_category?.current?.value
      ? location_category?.current?.value?.trim()
      : selectedObj.category; // BUG 1202093574609426
    console.log('UPDATE!!!', obj);
    let selectedObj = {};
    if (obj == null) {
      selectedObj = editObj;
    } else {
      selectedObj = obj;
    }
    let params = {
      accountId:
        (selectedObj.accountId && selectedObj.accountId !== 'Select') || selectedObj.accountId !== ''
          ? selectedObj.accountId
          : 0,
      name: rename,
      info: {
        category: recategorize,
        tags: selectedObj.tags,
        imageUrl: obj.image,
        id: selectedObj.id,
        restaurantId: selectedObj.restaurantId,
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    update(editId, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated equipment!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'updateEquipment',
        JSON.stringify({
          accountId: accountId,
          category: params.info?.category,
          id: params.info?.id,
          image: params.info?.imageUrl,
          name: params.name,
          restaurantId: params.info?.restaurantId,
          tags: params.info?.tags,
        })
      );
    });
  };

  const addEquip = (obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      restaurantId: resto.id,
      name: rename !== '' ? rename : 'Untitled',
      info: {
        category: obj.category,
        tags: obj.tags,
        image: obj.image,
      },
    };
    if (accountId !== '') {
      params.accountId = accountId;
    }
    console.log(params);
    create(params).then((res) => {
      // notification.open({
      //   message: 'Successfully created equipment!',
      // });
      setHasUpdated(true);
      eventSocket.emit(
        'addEquipment',
        JSON.stringify({
          accountId: res?.accountId,
          category: res?.category,
          id: res?.id,
          image: res?.image,
          name: res?.name,
          restaurantId: res?.restaurantId,
        })
      );
    });
  };

  const handleCategoryChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].category = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleAddTags = (value, obj, type) => {
    console.log(value);
    console.log(obj);

    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].tags.push(value);
    console.log(clonedChecklist);
    setData(clonedChecklist);
    console.log(clonedChecklist[foundIndex]);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleRemoveTags = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    remove(clonedChecklist[foundIndex].tags, (tag) => {
      return tag === value;
    });
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const uploadImg = (e, obj) => {
    setLoading(true);
    setEditing(true);
    e.preventDefault();
    e.stopPropagation();

    onFileSelected(e.target.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        setUserImg(res.Location);
        obj.image = res.Location;
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        setLoading(false);
      } else {
        setLoading(false);
        let userimage = URL.createObjectURL(e.target.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleOnDrop = (e, obj) => {
    setLoading(true);
    setEditing(true);
    e.preventDefault();
    e.stopPropagation();
    console.log(e.dataTransfer.files[0]);

    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        obj.image = res.Location;
        setUserImg(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        setLoading(false);
      } else {
        setLoading(false);
        let userimage = URL.createObjectURL(e.dataTransfer.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  const handleOnDrop1 = (e, obj) => {
    setLoading(true);
    setEditing(true);
    e.preventDefault();
    e.stopPropagation();
    console.log(e.dataTransfer.files[0]);

    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        obj.image = res.Location;
        setUserImg(res.Location);
        // notification.open({
        //   message: 'Successfully uploaded image!',
        // });
        setLoading(false);
      } else {
        setLoading(false);
        let userimage = URL.createObjectURL(e.dataTransfer.files[0]);
        setUserImg(userimage);
        // notification.open({
        //   message: 'Image not uploaded. Please try again',
        // });
      }
    });
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setAccountList(allAccounts);
      }
    });
  };

  const handleAccountChange = (value, obj) => {
    console.log(value, obj);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].accountId = value;
    setAccount(value);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
    setEditTags(true);
  };

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'photo',
      render: (key, obj) => (
        <span id={`photo-${key}`}>
          {isEditing && editId == obj.id && (
            <span
              onClick={() => fileInput.current.click()}
              onDragOver={handleDragOver}
              onDrop={(e) => handleOnDrop(e, obj)}
            >
              <ThumbnailImage type={'admin'} src={obj.image && obj.image !== '' ? obj.image : null} />
              <input
                type="file"
                style={{ display: 'none', cursor: 'pointer' }}
                onChange={(e) => uploadImg(e, obj)}
                ref={fileInput}
                accept="image/*"
              />
            </span>
          )}
          {!isEditing && !isAdding && (
            <ThumbnailImage type={'admin'} src={obj.image && obj.image !== '' ? obj.image : null} />
          )}
          {isAdding && obj.id == 'add' && (
            <span
              onClick={() => fileInput.current.click()}
              onDragOver={handleDragOver}
              onDrop={(e) => handleOnDrop1(e, obj)}
            >
              <ThumbnailImage type={'admin'} src={obj.image && obj.image !== '' ? obj.image : null} />
              <input
                type="file"
                style={{ display: 'none', cursor: 'pointer' }}
                onChange={(e) => uploadImg(e, obj)}
                ref={fileInput}
                accept="image/*"
              />
            </span>
          )}
          <span>
            {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
              <div className="common-controls-container">
                <Dropdown overlay={controlItem(obj)}>
                  <div className="toggle-switch">
                    <img
                      src={IconsMoreOff}
                      className="UI-IconsMoreOff"
                      alt="IconsMoreOff"
                      onMouseEnter={() => {
                        if (Object.keys(selected).length === 0 || selected.id != obj.id) {
                          setSelected({ ...obj });
                        }
                      }}
                    />
                  </div>
                </Dropdown>
              </div>
            ) : (
              ''
            )}
          </span>
        </span>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateEquip(obj);
                }
              }}
            />
          )}
          {isAdding && obj.id == 'add' && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && account != null) {
                  addEquip(obj);
                }
              }}
            />
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: (a, b) => (a.category < b.category ? -1 : a.category > b.category ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id ? (
            <>
              <CidekicSelect
                className="admin-select-select"
                ref={location_category}
                onChange={(e) => handleCategoryChange(e, obj)}
                defaultValue={key ? key : equipCategories[0]}
                showSearch
              >
                {equipCategories.map((cat, i) => (
                  <Option key={i} value={cat}>
                    {cat}
                  </Option>
                ))}
              </CidekicSelect>
              {/* <div>
								<input
									type="text"
									className="row-name-input"
									placeholder="Enter a category"
									defaultValue={key}
									onChange={(e) => handleCategoryChange(e.target.value, obj)}
									//onBlur={() => setEditTags(true)}
									// onKeyPress={(e) => {
									//   setEditTags(false);
									//   if (e.key === 'Enter') {
									//     setEditTags(true);
									//     updateEquip(e, obj);
									//   }
									// }}
								/>
							</div> */}
            </>
          ) : (
            ''
          )}
          {isAdding && obj.id == 'add' ? (
            <>
              <CidekicSelect
                className="admin-select-select"
                ref={location_category}
                onChange={(e) => handleCategoryChange(e, obj)}
                defaultValue={key}
                showSearch
              >
                {equipCategories.map((cat, i) => (
                  <Option key={i} value={cat}>
                    {cat}
                  </Option>
                ))}
              </CidekicSelect>
              {/* <div>
								<input
									type="text"
									className="row-name-input"
									placeholder="Enter a category"
									defaultValue={key}
									onChange={(e) => handleCategoryChange(e.target.value, obj)}
								/>
							</div> */}
            </>
          ) : (
            ''
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      sorter: (a, b) => (a.tags < b.tags ? -1 : a.tags > b.tags ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <RowTags
          tags={obj.tags}
          isEditing={(isEditing && editId == obj.id) || (isAdding && obj.id == 'add')}
          inputName="input"
          inpuId="input"
          objId={obj.id}
          onEditKeyPress={(e) => {
            setEditTags(false);
            if (e.key === 'Enter') {
              handleAddTags(e.target.value, obj, 'edit');
              e.target.value = '';
              setEditTags(true);
            }
          }}
          onEditBlur={() => setEditTags(true)}
          closable={isEditing || isAdding}
          onClose={(tag) => {
            // remove tags
            handleRemoveTags(tag, obj);
          }}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'accountId',
      render: (key, obj) => (
        <span>
          {(isEditing && editId == obj.id) || (isAdding && obj.id == 'add') ? (
            <div className="admin-accountId1">
              {accountlist && accountlist.length > 0 && accountlist.find((m) => m.id == accountId).name}
            </div>
          ) : (
            <span>
              {!obj.isMaster && (
                <div className="admin-accountId1">
                  {accountlist && accountlist.length > 0 && accountlist.find((m) => m.id == accountId).name}
                </div>
              )}
            </span>
          )}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'controls',
      render: (key, obj) => (
        <RoleAccessibleComponent
          permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
        >
          <span>
            {!isEditing && !isAdding && !obj.isMaster && (
              <Dropdown overlay={controlItems(obj)}>
                <div className="toggle-switch">
                  <img
                    src={IconsMoreOff}
                    className="UI-IconsMoreOff"
                    alt="IconsMoreOff"
                    onMouseEnter={() => {
                      if (Object.keys(selected).length === 0 || selected.id != obj.id) {
                        setSelected({ ...obj });
                      }
                    }}
                  />
                </div>
              </Dropdown>
            )}
            {((isEditing && editId == obj.id) || (isAdding && obj.id == 'add')) && (
              <RowOkCancel
                onClickOk={isEditing ? () => updateEquip(obj) : () => addEquip(obj)}
                onClickCancel={cancelEdit}
              />
            )}
          </span>
        </RoleAccessibleComponent>
      ),
    },
  ];

  useEffect(() => {
    eventSocket.on('updatedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPUPDATED!!', [newData]);
      setEquipUpdate([newData]);
    });
    eventSocket.on('addedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPADDED!!', newData);
      setEquipAdd(newData);
    });
    eventSocket.on('deletedEquipment', (message) => {
      let newData = JSON.parse(message);
      console.log('EQUIPDELETED!!', newData.id);
      setEquipDelete(newData);
    });
    return () => {
      eventSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    setData((oldArray) => [...oldArray, equipAdd]);
    setOrigData((oldArray) => [...oldArray, equipAdd]);
    setSocketData((oldArray) => [...oldArray, equipAdd]);
  }, [equipAdd]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.map((obj) => equipUpdate.find((o) => o.id === obj.id) || obj);
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
  }, [equipUpdate]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.filter((obj) => obj.id !== equipDelete);
    console.log(updated);
    setData(updated);
    setOrigData(updated);
    setSocketData(updated);
  }, [equipDelete]);

  const addNewEquipment = () => {
    setAdding(true);
    window.scrollTo(0, 0);
    const clonedDataSource = cloneDeep(origData);
    clonedDataSource.unshift(newLocationValue);
    setData(clonedDataSource);
  };

  return (
    <div id="admin-equipment" className="common-page-container">
      <NewSubHeader
        title="Equipment"
        onChange={(evt) => setNameFilter(evt.target.value)}
        showSearch={accountRole != 'Superadmin'}
      />
      <div className="Line"></div>
      <div className="common-page-content">
        <div className="common-controls-container controls-thin">
          <RoleAccessibleComponent
            permittedRoles={['Chef', 'Admin', 'Superadmin', 'Client Admin', 'Dev Team', 'Account Holder']}
          >
            <PlusButton className="flex-control-left" alt="Add new equipment" onClick={addNewEquipment}>
              Add new equipment
            </PlusButton>
          </RoleAccessibleComponent>

          <CidekicDropdown overlay={menuList} className="flex-control-right select-wide">
            {menuName ? menuName : 'Restaurant name'}
          </CidekicDropdown>
        </div>

        {!isLoading && (
          <Table
            dataSource={userData}
            columns={columns}
            pagination={false}
            rowKey={'id'}
            className={'show-custom-empty'}
            locale={{ emptyText: <Empty image={empty} description="No equipment" /> }}
            scroll={{ y: 'calc(100vh - var(--table-scroll-offset))' }}
          />
        )}
      </div>
    </div>
  );
};

export default Equipment;
