import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Tag, Switch, Tooltip, notification, Menu, Dropdown, Drawer, Select } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { allLocations, destroy, update, create } from '../../services/restaurant.service';
import { all } from '../../services/account.service';
import { cloneDeep, find, remove, findIndex, isEmpty } from 'lodash';
import _ from 'lodash';
import { timeZones } from '../../data/timezones';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { NewSubHeader } from '../../components/Header/Header';
import { PlusButton, CidekicDropdown, CidekicSelect, RowOkCancel } from '../../components/common/common_ui';

const SuperLocations = (props) => {
  const [menuName, setMenuName] = useState('');
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  const [selected, setSelected] = useState(null);
  const [menulist, setList] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [getEditData, setGetEditData] = useState(null);
  const [isAdding, setAdding] = useState(false);
  const [account, setAccount] = useState(0);
  const [editObj, setEditObj] = useState({});
  const [locName, setLocName] = useState('');
  const [editedId, setEditedId] = useState(false);

  const location_name = useRef(null);

  const { Option } = Select;

  const newLocationValue = {
    id: 'add',
    name: '',
    address: '',
    timezone: timeZones[574],
  };

  useEffect(() => {
    setHasUpdated(false);
    setEditing(false);
    setAdding(false);
    setEditId(null);
    setEditObj(null);
    getLocations();
  }, [hasUpdated]);

  // useEffect(() => {
  //   getAccounts();
  // }, []);

  const getLocations = () => {
    allLocations(0, 1000).then((res) => {
      let allLoc = res;
      let address1 = allLoc.filter((a) => a.deletedAt == '');
      let address = address1.map((l) => {
        return {
          id: l.id,
          name: l.name,
          address: l.info && l.info?.address ? l.info?.address : '',
          timezone: l.info && l.info?.timezone ? l.info?.timezone : '',
          accountId: l.accountId,
        };
      });

      address.sort((x, y) => {
        return y.id - x.id;
      });
      address.forEach((item, i) => {
        if (item.id === 99999) {
          address.splice(i, 1);
          address.unshift(item);
        }
      });
      getAccounts(address);
    });
  };

  const getAccounts = (usrData) => {
    all(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

        setList(allAccounts);

        const getIds = allAccounts.map((m) => parseInt(m.id)).filter((f) => f !== null);

        const result = usrData.map((o) =>
          getIds.includes(o.accountId)
            ? Object.assign({}, o, {
                accountName: allAccounts.find((m) => parseInt(m.id) == o.accountId).name,
              })
            : o
        );

        if (editedId) {
          setEditedId(false);
          let filtered = result.filter((r) => r.name == locName);
          result.forEach((v, i) => {
            if (v.id == filtered[0].id) {
              result.unshift(result[i]);
              result.splice(i, 2);
              window.scrollTo(0, 0);
            }
          });
          editModeLocation(filtered[0]);
        }

        setData(result);
        setOrigData(result);
      }
    });
  };

  // const debouncedApiCall = useCallback(
  //   _.debounce((e) => {
  //     filterLocations(e);
  //   }, 200),
  //   []
  // );

  useEffect(() => {
    filterLocations(nameFilter);
  }, [nameFilter]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All accounts');
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <a>All accounts</a>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <a>{m?.name}</a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterLocations = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);
      console.log(origData);
      const filtered = origData.filter((entry) =>
        Object.values(entry).some((val) => typeof val === 'string' && val.toLowerCase().includes(keyword))
      );
      console.log(filtered);
      setData(filtered);
    } else {
      getLocations();
    }
  };

  const filteringMenus = (menu) => {
    console.log(menu.id);
    console.log(origData);
    let menuName = menu.name;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.accountId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const deleteLocation = (e) => {
    e.preventDefault();
    let id = selected?.id;
    console.log(id);
    destroy(id).then((res) => {
      console.log(res);
      // notification.open({
      //   message: 'Location Successfully Deleted',
      // });
      setHasUpdated(true);
    });
  };

  const editLocation = (e, obj) => {
    e.preventDefault();
    console.log(obj);
    setEditing(true);
    setEditId(obj.id);
    setEditObj(obj);
  };

  const editModeLocation = (obj) => {
    console.log('EDITMODE', obj);
    setEditing(true);
    setEditId(obj.id);
    setEditObj(obj);
  };

  const controlItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <span onClick={(e) => editLocation(e, obj)}>Edit</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deleteLocation(e)}>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditId(null);
    setData(origData);
    setAdding(false);
    setEditing(false);
  };

  const updateLocation = (obj) => {
    let rename = location_name.current.value.trim();
    let selectedObj = {};
    if (obj == null) {
      selectedObj = editObj;
    } else {
      selectedObj = obj;
    }
    let params = {
      name: rename,
      accountId: selectedObj.accountId,
      info: {
        address: selectedObj.address,
        timezone: selectedObj.timezone,
      },
    };
    console.log(params);
    update(editId, params).then((res) => {
      // notification.open({
      //   message: 'Successfully updated location!',
      // });
      setHasUpdated(true);
    });
  };

  const addLocation = (obj) => {
    let rename = location_name.current.value.trim();
    let params = {
      name: rename !== '' ? rename : 'Untitled',
      accountId: account,
      info: {
        address: obj.address !== '' ? obj.address : '',
        timezone: obj.timezone !== '' ? obj.timezone : '',
      },
    };
    console.log(params);
    create(params).then((res) => {
      setLocName(params.name);
      setEditedId(true);
      // notification.open({
      //   message: 'Successfully created location!',
      // });
      setHasUpdated(true);
    });
  };

  const handleAddressChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].address = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleTimeChange = (value, obj) => {
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].timezone = value;
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const handleAccountChange = (value, obj) => {
    console.log(value, obj);
    const clonedChecklist = cloneDeep(userData);
    const foundIndex = findIndex(clonedChecklist, { id: obj.id });
    clonedChecklist[foundIndex].accountId = value;
    setAccount(value);
    setData(clonedChecklist);
    setGetEditData(clonedChecklist[foundIndex]);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      width: '25%',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {isEditing && editId == obj.id ? (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateLocation(obj);
                }
              }}
            />
          ) : (
            <div>{key}</div>
          )}
          {isAdding && obj.id == 'add' ? (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter a title"
              defaultValue={key}
              ref={location_name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  addLocation(obj);
                }
              }}
            />
          ) : (
            ''
          )}
        </span>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      width: '30%',
      sorter: (a, b) => (a.address < b.address ? -1 : a.address > b.address ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {((isEditing && editId == obj.id) || (isAdding && obj.id == 'add')) && (
            <input
              type="text"
              className="row-name-input"
              placeholder="Enter an address"
              defaultValue={key}
              onChange={(e) => handleAddressChange(e.target.value, obj)}
            />
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Timezone',
      dataIndex: 'timezone',
      width: '20%',
      sorter: (a, b) => (a.timezone < b.timezone ? -1 : a.timezone > b.timezone ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {((isEditing && editId == obj.id) || (isAdding && obj.id == 'add')) && (
            <CidekicSelect
              className="admin-select-select"
              placeholder="Select timezone"
              onChange={(val) => handleTimeChange(val, obj)}
              defaultValue={isAdding ? undefined : obj.timezone}
              showSearch
            >
              {timeZones.map((zone) => (
                <Option value={zone}>{zone}</Option>
              ))}
            </CidekicSelect>
          )}
          {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
        </span>
      ),
    },
    {
      title: 'Account name',
      dataIndex: 'accountName',
      width: '20%',
      sorter: (a, b) => (a.accountName < b.accountName ? -1 : a.accountName > b.accountName ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          {(isAdding && obj.id == 'add') || (isEditing && editId == obj.id) ? (
            <CidekicSelect
              className="admin-select-select"
              placeholder="Select account"
              defaultValue={isAdding ? undefined : key}
              onChange={(val) => handleAccountChange(val, obj)}
              showSearch
            >
              {menulist && menulist.length > 0 && menulist.map((m) => <Option value={m.id}>{m.name}</Option>)}
            </CidekicSelect>
          ) : (
            <span>{key}</span>
          )}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'controls',
      width: '5%',
      render: (key, obj) => (
        <span>
          {!isEditing && !isAdding && (
            <Dropdown overlay={controlItems(obj)}>
              <div className="toggle-switch">
                <img
                  src={IconsMoreOff}
                  className="UI-IconsMoreOff"
                  alt="IconsMoreOff"
                  onMouseEnter={() => setSelected(obj)}
                />
              </div>
            </Dropdown>
          )}
          {((isEditing && editId == obj.id) || (isAdding && obj.id == 'add')) && (
            <RowOkCancel
              className="float-right"
              onClickOk={isEditing ? () => updateLocation(obj) : () => addLocation(obj)}
              onClickCancel={cancelEdit}
            />
          )}
        </span>
      ),
    },
  ];

  // Well, I didn't really under why Mark did this but it seemed like it would
  // work without this second columns code -- so I took it out
  // const columns2 = [
  //   {
  //     title: '',
  //     dataIndex: 'control1',
  //     render: (key, obj) => (
  //       <span>
  //         {isAdding && obj.id == 'add' && (
  //           <select className="admin-select" onChange={(e) => handleAccountChange(e.target.value, obj)}>
  //             <option initialvalue="default">Select account</option>
  //             {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
  //           </select>
  //         )}
  //         {isEditing && editId == obj.id && (
  //           <select className="admin-select" onChange={(e) => handleAccountChange(e.target.value, obj)}>
  //             <option initialvalue="default">Select account</option>
  //             {menulist && menulist.length > 0 && menulist.map((m) => <option value={m.id}>{m.name}</option>)}
  //           </select>
  //         )}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: 'Title',
  //     dataIndex: 'name',
  //     sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
  //     sortDirections: ['descend', 'ascend'],
  //     render: (key, obj) => (
  //       <span>
  //         {isEditing && editId == obj.id ? (
  //           <div>
  //             <input
  //               type="text"
  //               className="row-name-input"
  //               placeholder="Enter a title"
  //               defaultValue={key}
  //               ref={location_name}
  //               onKeyPress={(e) => {
  //                 if (e.key === 'Enter') {
  //                   updateLocation(e, obj);
  //                 }
  //               }}
  //               onBlur={(e) => updateLocation2(e, obj)}
  //             />
  //
  //             <div
  //               onMouseDown={() => cancelEdit()}
  //               style={{
  //                 width: 100,
  //                 cursor: 'pointer',
  //                 margin: '4px 0px 7px 3px',
  //                 color: 'var(--accent-color)',
  //                 fontSize: 13,
  //               }}
  //             >
  //               Cancel
  //             </div>
  //           </div>
  //         ) : (
  //           <div>{key}</div>
  //         )}
  //         {isAdding && obj.id == 'add' ? (
  //           <div>
  //             <input
  //               type="text"
  //               className="row-name-input"
  //               placeholder="Enter a title"
  //               defaultValue={key}
  //               ref={location_name}
  //               onKeyPress={(e) => {
  //                 if (e.key === 'Enter') {
  //                   addLocation(e, obj);
  //                 }
  //               }}
  //               onBlur={(e) => addLocation2(e, obj)}
  //             />
  //
  //             <div
  //               onMouseDown={() => cancelEdit()}
  //               style={{
  //                 width: 100,
  //                 cursor: 'pointer',
  //                 margin: '4px 0px 7px 3px',
  //                 color: 'var(--accent-color)',
  //                 fontSize: 13,
  //               }}
  //             >
  //               Cancel
  //             </div>
  //           </div>
  //         ) : (
  //           ''
  //         )}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: 'Address',
  //     dataIndex: 'address',
  //     sorter: (a, b) => (a.address < b.address ? -1 : a.address > b.address ? 1 : 0),
  //     sortDirections: ['descend', 'ascend'],
  //     render: (key, obj) => (
  //       <span>
  //         {isEditing && editId == obj.id ? (
  //           <div>
  //             <input
  //               type="text"
  //               className="row-name-input"
  //               placeholder="Enter an address"
  //               defaultValue={key}
  //               onChange={(e) => handleAddressChange(e.target.value, obj)}
  //             />
  //           </div>
  //         ) : (
  //           ''
  //         )}
  //         {isAdding && obj.id == 'add' ? (
  //           <div>
  //             <input
  //               type="text"
  //               className="row-name-input"
  //               placeholder="Enter an address"
  //               defaultValue={key}
  //               onChange={(e) => handleAddressChange(e.target.value, obj)}
  //             />
  //           </div>
  //         ) : (
  //           ''
  //         )}
  //         {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: 'Timezone',
  //     dataIndex: 'timezone',
  //     sorter: (a, b) => (a.timezone < b.timezone ? -1 : a.timezone > b.timezone ? 1 : 0),
  //     sortDirections: ['descend', 'ascend'],
  //     render: (key, obj) => (
  //       <span>
  //         {isEditing && editId == obj.id ? (
  //           <div>
  //             {/* <input
  //               type="text"
  //               className="row-name-input"
  //               placeholder="Enter a timezone"
  //               defaultValue={key}
  //               onChange={(e) => handleTimeChange(e.target.value, obj)}
  //             /> */}
  //             <Select
  //               className="admin-ant-select"
  //               onChange={(e) => handleTimeChange(e, obj)}
  //               value={obj.timezone}
  //               showArrow={false}
  //               showSearch
  //             >
  //               {timeZones.map((zone) => (
  //                 <Option value={zone}>{zone}</Option>
  //               ))}
  //             </Select>
  //           </div>
  //         ) : (
  //           ''
  //         )}
  //         {isAdding && obj.id == 'add' ? (
  //           <div>
  //             {/* <input
  //               type="text"
  //               className="row-name-input"
  //               placeholder="Enter a timezone"
  //               defaultValue={key}
  //               onChange={(e) => handleTimeChange(e.target.value, obj)}
  //             /> */}
  //             <Select
  //               className="admin-ant-select"
  //               onChange={(e) => handleTimeChange(e, obj)}
  //               value={obj.timezone}
  //               showArrow={false}
  //               showSearch
  //             >
  //               {timeZones.map((zone) => (
  //                 <Option value={zone}>{zone}</Option>
  //               ))}
  //             </Select>
  //           </div>
  //         ) : (
  //           ''
  //         )}
  //
  //         {obj.id !== 'add' && obj.id !== editId && <div>{key}</div>}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: '',
  //     dataIndex: 'controls',
  //     render: (key, obj) => (
  //       <span>
  //         {!isEditing && !isAdding && (
  //           <Dropdown overlay={controlItems(obj)}>
  //             <div className="toggle-switch">
  //               <img
  //                 src={IconsMoreOff}
  //                 className="UI-IconsMoreOff"
  //                 alt="IconsMoreOff"
  //                 onMouseEnter={() => setSelected(obj)}
  //               />
  //             </div>
  //           </Dropdown>
  //         )}
  //       </span>
  //     ),
  //   },
  // ];

  return (
    <div id="superadmin-locations" className="common-page-container">
      <NewSubHeader title="Locations" onChange={(evt) => setNameFilter(evt.target.value)} />
      <div className="Line"></div>
      <div className="common-page-content">
        <div className="common-controls-container controls-thin">
          {!isEditing && (
            <PlusButton
              className="flex-control-left"
              alt="Add new location"
              onClick={() => {
                setAdding(true);
                const clonedDataSource = cloneDeep(origData);
                clonedDataSource.unshift(newLocationValue);
                setData(clonedDataSource);
                window.scrollTo(0, 0);
              }}
            >
              Add new location
            </PlusButton>
          )}

          <CidekicDropdown overlay={menuList} className="flex-control-right select-wide">
            {menuName ? menuName : 'All accounts'}
          </CidekicDropdown>
        </div>

        <Table
          dataSource={userData}
          columns={columns}
          pagination={false}
          className={'show-custom-empty'}
          locale={{ emptyText: <Empty image={empty} description="No locations" /> }}
          scroll={{ y: 'calc(100vh - var(--table-scroll-offset))' }}
        />
      </div>
    </div>
  );
};

export default SuperLocations;
