/* eslint eqeqeq: "off" */
import { request } from './http.service';
import { decodeEquipmentMessages } from '../protobuf-models/equipment.proto.js';

export const create = (params) => {
  return request('POST', '/equipment', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const update = async (id, params) => {
  return request('PUT', '/equipment/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroy = (id) => {
  return request('DELETE', '/equipment/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const get = (id) => {
  return request('GET', '/equipment/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const search = (params) => {
  let finalParams = params;
  if (params.isSuperAdmin == undefined) {
    finalParams.isSuperAdmin = false;
  }
  return request('POST', '/equipment/search', 200, finalParams, decodeEquipmentMessages).then(
    (result) => {
      if (result && result.equipments) {
        let equipment = result.equipments.map((e) => {
          e.info = JSON.parse(e.info);
          return e;
        });
        return equipment;
      }
    },
    (error) => {
      // console.log(error);
    }
  );
};
