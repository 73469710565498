import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { Table, Drawer, Select, Menu, Dropdown, Divider, Input, Popover, List, Checkbox } from 'antd';
import { searchmeas } from '../../services/measurement.service';
import { searchprep } from '../../services/preparation.service';
import useWindowSize from '../../components/windowdimensions/windowSize';
import BarGraph from '../../components/graphs/bar';
import DonutGraph from '../../components/graphs/donut';
import LineGraph from '../../components/graphs/line';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import {
  createKitchenStation,
  getStationOperators,
  loadKitchenStations,
  getKitchenStations,
} from '../../services/station.service';
import { onFileSelected } from '../../services/upload.service';
import { update } from '../../services/ingredient.service';
import ImagesUploader from 'react-images-uploader';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import ReactDragListView from 'react-drag-listview';
import reorder from '../../img/reorder-icon.png';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { REACT_APP_SOCKET_BASE_URL } from '../../config';
import openSocket from 'socket.io-client';
import TableInputQty from '../../components/Recipe/TableInputQty';
import { formatIfNeeded, arrangeDBMeasurementUnits } from '../../pages/Recipes/utilities';
import { ThumbnailImage, CidekicSelect } from '../../components/common/common_ui';
import _ from 'lodash';

const { Option } = Select;

const IngredientTable = (props) => {
  const { addIngredientsList, unassignIng, ingListUpdates, isEditing, qtyCombine, updateQtyCombine, recipeId } = props;

  const [addIngredients, setAddIngredients] = useState([]);
  const [originalStations, setOriginalStations] = useState([]);
  const [editInput, setEditInput] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [visible, updateVisible] = useState(false);
  const [visible1, updateVisible1] = useState(false);
  const [measurements, setMeasurements] = useState([]);
  const [preparation, setPrep] = useState([]);
  const [station, setStation] = useState([]);
  const [ingDelete, setDeleteIng] = useState({});
  const [name, setName] = useState('');
  const [assignees, setAssignees] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const [isSubmitting, setIsSubmitting] = useState('');
  const [isDown, setIsDown] = useState(false);
  const [activeCat, setActiveCat] = useState({});
  const [socketData, setSocketData] = useState([]);
  const [prepUpdate, setPrepUpdate] = useState([]);
  const [prepAdd, setPrepAdd] = useState([]);
  const [prepDelete, setPrepDelete] = useState('');
  const [eventSocket] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/preparation`));
  const [socketData2, setSocketData2] = useState([]);
  const [measUpdate, setMeasUpdate] = useState([]);
  const [measAdd, setMeasAdd] = useState([]);
  const [measDelete, setMeasDelete] = useState('');
  const [searchedAssignee, searchAssignee] = useState('');
  const [operatorsToChoose, setOperatorsToChoose] = useState([]);
  const [eventSocket2] = useState(() => openSocket(`${REACT_APP_SOCKET_BASE_URL}/measurement`));
  const [pageNumber, setPageNumber] = useState({
    current: 1,
    defaultPageSize: 10,
    showQuickJumper: true,
  });
  const [oneStationOnly, setOneStationOnly] = useState(false);
  const [oneStation, setOneStation] = useState(0);

  const windowSize = useWindowSize();

  const fileInput = useRef();

  let accountId = localStorage.getItem('operator') != undefined && 
    JSON.parse(localStorage.getItem('operator')).accountId != null ? 
    JSON.parse(localStorage.getItem('operator')).accountId : '';
  let accountRole = JSON.parse(localStorage.getItem('operator')).role || '';

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};

  useEffect(() => {
    console.log('NEWLY UPDATED AND CURRENT INGREDIENT LISTS', addIngredients);
  }, [addIngredients]);

  useEffect(() => {
    const showDisplayQty = addIngredientsList.map((ing, i) => {
      ing.key = `${i}`;
      if (!Object.keys(ing).includes('displayQty')) {
        ing.displayQty = formatIfNeeded(ing.quantity);
      }
      return ing;
    });
    setAddIngredients(showDisplayQty);
    if (!oneStationOnly && showDisplayQty.length) {
      setOriginalStations(
        showDisplayQty.map((ing) => {
          return {
            ingredientId: ing.id,
            stationId: ing.stationId,
            stationName: ing.station,
          };
        })
      );
    }
  }, [addIngredientsList]);

  useEffect(() => {
    console.log(selectedRow);
  }, [selectedRow]);

  useEffect(() => {
    let params = {
      accountId: accountId,
    };

    searchmeas(params).then((res) => {
      setMeasurements(arrangeDBMeasurementUnits(res));
      setSocketData2(res);
    });

    searchprep(params).then((res) => {
      let results = res;
      let nullVal = {
        accountId: '',
        id: 0,
        info: null,
        name: 'Select',
        restaurantId: '',
      };

      results.sort((x, y) => x.name.localeCompare(y.name));
      results.unshift(nullVal);
      setPrep(results);
      setSocketData(results);
    });

    if (station.length === 0) {
      loadKitchenStations(resto.id).then((res) => {
        let kitchenOptions = res.map((k) => {
          return {
            stationName: k.stationName,
            id: k.stationId,
          };
        });
        setStation(kitchenOptions);
      });
      // getKitchenStations(resto.id).then((res) => {
      //   let kitchens = res.kitchens;
      //   let kitchenOptions = kitchens?.map((k) => {
      //     return {
      //       stationName: k.station,
      //       id: k.id,
      //     };
      //   });
      //   setStation(kitchenOptions);
      // });
    }
  }, []);

  useEffect(() => {
    if (searchedAssignee !== '') {
      getStationOperators({ id: resto.id, name: searchedAssignee }).then((res) => {
        setOperatorsToChoose(res);
      });
    }
  }, [searchedAssignee]);

  useEffect(() => {
    //
    console.log('QTYCOMBINE', qtyCombine);
    setEditInput(!qtyCombine);
    // if (qtyCombine) {
    //   setEditInput(false);
    // } else {
    //   setEditInput(true);
    // }
  }, [qtyCombine]);

  useEffect(() => {
    console.log('EDIT INPUT', editInput);
  }, [editInput]);

  const getRowVal = (r) => {
    setSelectedRow(r);
  };

  const showDrawer = () => {
    updateVisible(true);
  };

  const onClose = () => {
    updateVisible(false);
  };

  const onClose1 = () => {
    updateVisible1(false);
  };

  const getInput = (e) => {
    setName(e.target.value);
  };

  const selectAssignee = (e) => {
    setSelectedAssignee(e);
  };

  const submitNewStation = () => {
    setIsSubmitting(true);
    createKitchenStation({ restaurantId: resto.id, name: name.trim(), operatorId: selectedAssignee }).then((res) => {
      // loadKitchenStations(resto.id).then((res) => {
      //   if (res) {
      //     setStation(res);
      //     setIsSubmitting(false);
      //     updateVisible1(false);
      //   }
      // });
      getKitchenStations(resto.id).then((res) => {
        let kitchens = res.kitchens;
        let kitchenOptions = kitchens?.map((k) => {
          return {
            stationName: k.station,
            id: k.id,
          };
        });
        setStation(kitchenOptions);
        setIsSubmitting(false);
        updateVisible1(false);
      });
    });
  };

  const unassignIngredient = () => {
    // console.log('REMOVE ING', ingDelete.id);
    // let filterIng = addIngredients.find((a) => a.id == ingDelete.id);
    //BUG 1202397472239827
    let filterIng = addIngredients.find((a) => a.key == ingDelete.key);
    console.log(filterIng);
    unassignIng(filterIng);
  };

  const updateCost = (e) => {
    let num = e.target.value === null ? 0 : e.target.value;
    console.log('NUMBER', num);

    let rowSelected = selectedRow;
    rowSelected.cost = parseFloat(num);
    console.log(rowSelected);

    const updateIng = addIngredients
      .map((p, i) => (p.id === rowSelected.id ? { ...p, cost: parseFloat(num) } : p))
      .map((ing) => {
        delete ing.editing;
        return ing;
      });
    console.log('COSTUPDATE', updateIng);
    const sum = updateIng.reduce((a, { cost }) => a + parseFloat(cost), 0);
    setTotalCost(sum.toFixed(2));
    setAddIngredients(updateIng);
  };

  const updateCostMob = (e, obj) => {
    let num = e.target.value === null ? 0 : e.target.value;
    console.log('NUMBER', num);

    let rowSelected = obj;
    rowSelected.cost = parseFloat(num);
    console.log(rowSelected);

    const updateIng = addIngredients
      .map((p, i) => (p.id === rowSelected.id ? { ...p, cost: parseFloat(num) } : p))
      .map((ing) => {
        // delete ing.displayQty;
        delete ing.editing;
        return ing;
      });
    console.log('COSTUPDATE', updateIng);
    const sum = updateIng.reduce((a, { cost }) => a + parseFloat(cost), 0);
    setTotalCost(sum.toFixed(2));
    setAddIngredients(updateIng);

    ingListUpdates(updateIng);
  };

  // const focusQtyMob = (e, obj) => {
  // 	let numQty = e.target.value === null ? '' :
  // }

  const uploadImg = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties",
      // });
      return;
    }

    let rowSelected = selectedRow;
    onFileSelected(e.target.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        let id = rowSelected.id;
        if (isEditing) {
          let updateImg = rowSelected.info;
          console.log(updateImg);
          updateImg.imageUrlThumb = res.Location;
          let params = {
            info: updateImg,
          };
          console.log(params);
          let imageInfo = res.Location;
          update(id, params).then((res) => {
            console.log(res);
            // notification.open({
            //   message: 'Successfully uploaded image!',
            // });
            rowSelected.imageUrlThumb = imageInfo;
            console.log(rowSelected);

            console.log(addIngredients);
            const updateIng = addIngredients
              .map((p, i) => (p.id === rowSelected.id ? { ...p, imageUrlThumb: imageInfo } : p))
              .map((ing) => {
                // delete ing.displayQty;
                delete ing.editing;
                return ing;
              });
            console.log('IMGUPDATE', updateIng);
            setAddIngredients(updateIng);
          });
        } else {
          let updateInfo = rowSelected.info;
          console.log(updateInfo);
          updateInfo.imageUrlThumb = res.Location;
          let params = {
            info: updateInfo,
          };
          console.log(params);
          let imageInfo = res.Location;
          update(id, params).then((res) => {
            console.log(res);
            // notification.open({
            //   message: 'Successfully uploaded image!',
            // });
            rowSelected.imageUrlThumb = imageInfo;
            console.log(rowSelected);

            console.log(addIngredients);
            const updateIng = addIngredients
              .map((p, i) => (p.id === rowSelected.id ? { ...p, imageUrlThumb: imageInfo } : p))
              .map((ing) => {
                // delete ing.displayQty;
                delete ing.editing;
                return ing;
              });
            console.log('IMGUPDATE', updateIng);
            setAddIngredients(updateIng);
          });
        }
      } else {
        // notification.open({
        //   message: 'Image failed to upload! Showing preview only...',
        // });

        rowSelected.imageUrlThumb = URL.createObjectURL(e.target.files[0]);
        console.log(rowSelected);

        console.log(addIngredients);
        const updateIng = addIngredients
          .map((p, i) =>
            p.id === rowSelected.id ? { ...p, imageUrlThumb: URL.createObjectURL(e.target.files[0]) } : p
          )
          .map((ing) => {
            // delete ing.displayQty;
            delete ing.editing;
            return ing;
          });
        console.log('IMGUPDATE', updateIng);
        setAddIngredients(updateIng);
      }
    });
  };

  const selectPrepMob = (e, obj) => {
    let rowSelected = obj;
    rowSelected.preparationId = parseInt(e.target.value);
    let filterPrep = preparation.find((m) => m.id == e.target.value);

    rowSelected.preparation = filterPrep && filterPrep?.name ? filterPrep.name : '';
    rowSelected.preparationName = filterPrep && filterPrep?.name ? filterPrep.name : '';
    console.log(rowSelected);

    const updateIng = addIngredients
      .map((p, i) =>
        // p.id === rowSelected.id
        p.key == rowSelected.key
          ? {
              ...p,
              preparationId: e.target.value,
              preparationName: rowSelected.preparationName,
              preparation: rowSelected.preparationName,
            }
          : p
      )
      .map((ing) => {
        // delete ing.displayQty;
        delete ing.editing;
        return ing;
      });
    setAddIngredients(updateIng);

    ingListUpdates(updateIng);
  };

  const selectPrep = (e) => {
    let rowSelected = selectedRow;
    rowSelected.preparationId = e.target.value;
    let filterPrep = preparation.find((m) => m.id == e.target.value);

    rowSelected.preparation = filterPrep && filterPrep?.name ? filterPrep.name : '';
    rowSelected.preparationName = filterPrep && filterPrep?.name ? filterPrep.name : '';
    console.log(rowSelected);

    const updateIng = addIngredients
      .map((p, i) =>
        // p.id === rowSelected.id
        p.key == rowSelected.key
          ? {
              ...p,
              preparationId: e.target.value,
              preparationName: rowSelected.preparationName,
              preparation: rowSelected.preparation,
            }
          : p
      )
      .map((ing) => {
        // delete ing.displayQty;
        delete ing.editing;
        return ing;
      });
    setAddIngredients(updateIng);
  };

  const recalculateInputMeasurements = (val) => {
    if (val) {
      const exactVal = Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100;
      return {
        reformattedVal: formatIfNeeded(exactVal),
        value: exactVal,
      };
    } else {
      return {
        reformattedVal: '0',
        value: 0,
      };
    }
  };

  const updateQty = (e) => {
    let numQty = e.target.value === null ? 0 : parseFloat(e.target.value);

    let rowSelected = selectedRow;

    let { reformattedVal, value } = recalculateInputMeasurements(numQty);

    rowSelected.quantity = value;
    rowSelected.originalQty = value;
    rowSelected.displayQty = reformattedVal;

    const updateIng = addIngredients
      .map((p, i) =>
        //p.id === rowSelected.id
        p.key == rowSelected.key
          ? {
              ...p,
              quantity: value,
              originalQty: value,
              displayQty: reformattedVal,
            }
          : p
      )
      .map((ing) => {
        // delete ing.displayQty;
        delete ing.editing;
        return ing;
      });

    setAddIngredients(updateIng);

    ingListUpdates(updateIng);
    updateQtyCombine(false);
  };

  const updateQtyMob = (e, obj) => {
    let numQty = e.target.value === null ? 0 : parseFloat(e.target.value);

    let rowSelected = obj;

    let { reformattedVal, value } = recalculateInputMeasurements(numQty);

    rowSelected.quantity = value;
    rowSelected.originalQty = value;
    rowSelected.displayQty = reformattedVal;

    const updateIng = addIngredients
      .map((p, i) =>
        // p.id === rowSelected.id
        p.key == rowSelected.key
          ? {
              ...p,
              quantity: value,
              originalQty: value,
              displayQty: reformattedVal,
            }
          : p
      )
      .map((ing) => {
        // delete ing.displayQty;
        delete ing.editing;
        return ing;
      });

    setAddIngredients(updateIng);

    ingListUpdates(updateIng);
    updateQtyCombine(false);
  };

  const selectMeasMob = (e, obj) => {
    let rowSelected = obj;
    let filterMeas = measurements.find((m) => m.id == e.target.value);

    let { reformattedVal, value } = recalculateInputMeasurements(rowSelected.quantity);

    if (filterMeas && filterMeas?.name) {
      rowSelected.measurement = filterMeas.name;
      rowSelected.measurementName = filterMeas.name;
      rowSelected.measurementId = e.target.value;
    } else {
      rowSelected.measurement = '';
      rowSelected.measurementName = '';
      rowSelected.measurementId = '0';
    }

    rowSelected.quantity = value;
    rowSelected.originalQty = value;
    rowSelected.displayQty = reformattedVal;

    console.log(rowSelected);

    const updateIng = addIngredients
      .map((p, i) =>
        // p.id === rowSelected.id
        p.key == rowSelected.key
          ? {
              ...p,
              quantity: value,
              originalQty: value,
              displayQty: reformattedVal,
              measurementId: e.target.value,
              measurement: rowSelected.measurement,
              measurementName: rowSelected.measurementName,
            }
          : p
      )
      .map((ing) => {
        // delete ing.displayQty;
        delete ing.editing;
        return ing;
      });
    setAddIngredients(updateIng);

    ingListUpdates(updateIng);
  };

  const selectMeas = (e) => {
    console.log('SELECTED MEASUREMENT');
    let rowSelected = selectedRow;
    let filterMeas = measurements.find((m) => m.id == e.target.value);

    let { reformattedVal, value } = recalculateInputMeasurements(rowSelected.quantity);

    if (filterMeas && filterMeas?.name) {
      rowSelected.measurement = filterMeas.name;
      rowSelected.measurementName = filterMeas.name;
      rowSelected.measurementId = e.target.value;
    } else {
      rowSelected.measurement = '';
      rowSelected.measurementName = '';
      rowSelected.measurementId = '0';
    }

    rowSelected.quantity = value;
    rowSelected.originalQty = value;
    rowSelected.displayQty = reformattedVal;

    console.log('CURRENT INGREDIENT CHANGED MEASUREMENT', rowSelected);
    const updateIng = addIngredients
      .map((p, i) =>
        // p.id === rowSelected.id
        p.key == rowSelected.key
          ? {
              ...p,
              quantity: value,
              originalQty: value,
              displayQty: reformattedVal,
              measurementId: parseInt(e.target.value),
              measurementName: rowSelected.measurementName,
              measurement: rowSelected.measurement,
            }
          : p
      )
      .map((ing) => {
        // delete ing.displayQty;
        delete ing.editing;
        return ing;
      });

    setAddIngredients(updateIng);

    ingListUpdates(updateIng);
  };
  // FUNCTIONS EDITING MEASUREMENT OF INGREDIENTS //

  const selectStation = (e) => {
    let filterSta = '';
    // if (e == 'Station') {
    //   addItem();
    // } else {
    let rowSelected = selectedRow;
    rowSelected.stationId = e;
    filterSta = station.find((m) => m.id == e);
    rowSelected.station = filterSta && filterSta?.stationName ? filterSta.stationName : '';

    console.log(rowSelected);

    const updateIng = addIngredients
      .map((p, i) =>
        p.id === rowSelected.id
          ? {
              ...p,
              stationId: parseInt(e),
              station: rowSelected.station,
              stationName: rowSelected.station,
            }
          : p
      )
      .map((ing) => {
        // delete ing.displayQty;
        delete ing.editing;
        return ing;
      });

    ingListUpdates(updateIng);
    setAddIngredients(updateIng);

    // }
  };

  const selectStationMob = (e, obj) => {
    console.log(e);
    let rowSelected = obj;
    rowSelected.stationId = e;
    let filterStation = station.filter((m) => m.id == e);
    console.log(filterStation);
    rowSelected.station = filterStation && filterStation?.stationName ? filterStation.stationName : '';
    console.log(rowSelected);

    const updateIng = addIngredients
      .map((p, i) =>
        p.id === rowSelected.id
          ? { ...p, station: parseInt(e), station: rowSelected.station, stationName: rowSelected.station }
          : p
      )
      .map((ing) => {
        // delete ing.displayQty;
        delete ing.editing;
        return ing;
      });
    setAddIngredients(updateIng);

    ingListUpdates(updateIng);
  };

  const deletesIng = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={unassignIngredient}>
        <span>Delete</span>
      </Menu.Item>
    </Menu>
  );

  const addItem = () => {
    console.log('addItem');
    updateVisible1(true);
    // const { items, name } = this.state;
    // this.setState({
    //   items: [...items, name || `New item ${index++}`],
    //   name: '',
    // });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleOnDrop = (e, obj) => {
    if (obj?.isMaster && accountRole !== 'Superadmin') {
      // notification.open({
      //   message: "Unable to edit a master list item's properties",
      // });
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    onFileSelected(e.dataTransfer.files[0]).then((res) => {
      if (res && res.Location) {
        console.log(res);
        let id = selectedRow.id;
        if (isEditing) {
          let updateImg = selectedRow.info;
          console.log(updateImg);
          updateImg.imageUrlThumb = res.Location;
          let params = {
            info: updateImg,
          };
          console.log(params);
          let imageInfo = res.Location;
          update(id, params).then((res) => {
            console.log(res);
            // notification.open({
            //   message: 'Successfully uploaded image!',
            // });
            selectedRow.imageUrlThumb = imageInfo;
            console.log(selectedRow);

            console.log(addIngredients);
            const updateIng = addIngredients
              .map((p, i) => (p.id === selectedRow.id ? { ...p, imageUrlThumb: imageInfo } : p))
              .map((ing) => {
                // delete ing.displayQty;
                delete ing.editing;
                return ing;
              });
            console.log('IMGUPDATE', updateIng);
            setAddIngredients(updateIng);
          });
        } else {
          let updateInfo = selectedRow.info;
          console.log(updateInfo);
          updateInfo.imageUrlThumb = res.Location;
          let params = {
            info: updateInfo,
          };
          console.log(params);
          let imageInfo = res.Location;
          update(id, params).then((res) => {
            // console.log(res);
            // notification.open({
            //   message: 'Successfully uploaded image!',
            // });
            selectedRow.imageUrlThumb = imageInfo;
            console.log(selectedRow);

            console.log(addIngredients);
            const updateIng = addIngredients
              .map((p, i) => (p.id === selectedRow.id ? { ...p, imageUrlThumb: imageInfo } : p))
              .map((ing) => {
                // delete ing.displayQty;
                delete ing.editing;
                return ing;
              });
            console.log('IMGUPDATE', updateIng);
            setAddIngredients(updateIng);
          });
        }
      } else {
        // notification.open({
        //   message: 'Image failed to upload! Showing preview only...',
        // });

        selectedRow.imageUrlThumb = URL.createObjectURL(e.dataTransfer.files[0]);
        console.log(selectedRow);

        console.log(addIngredients);
        const updateIng = addIngredients
          .map((p, i) =>
            p.id === selectedRow.id ? { ...p, imageUrlThumb: URL.createObjectURL(e.dataTransfer.files[0]) } : p
          )
          .map((ing) => {
            // delete ing.displayQty;
            delete ing.editing;
            return ing;
          });
        console.log('IMGUPDATE', updateIng);
        setAddIngredients(updateIng);
      }
    });
  };

  const getPage = (page) => {
    let setPg = {
      current: page.current,
    };
    setPageNumber(setPg);
  };

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'name-col',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: '35%',
      ellipsis: true,
      render: (key, obj) => (
        <span>
          <div className="ingredient-name-container">
            <div className="showDragIconRec">
              {' '}
              <a className="drag-handle" href="#">
                <img src={reorder} alt="reorder" className="menusToggles" />
              </a>
            </div>
            <ThumbnailImage
              className="edit-ingredient-image-container"
              src={
                isEditing
                  ? obj.imageUrlThumb
                    ? obj?.imageUrlThumb || obj?.imageUrl
                    : obj?.info && (obj?.info?.imageUrlThumb || obj?.info?.imageUrl)
                    ? obj?.info?.imageUrlThumb || obj?.info?.imageUrl
                    : null
                  : obj?.info && (obj?.info?.imageUrlThumb || obj?.info?.imageUrl)
                  ? obj?.info?.imageUrlThumb || obj?.info?.imageUrl
                  : obj?.imageUrlThumb || obj?.imageUrl
                  ? obj?.imageUrlThumb || obj?.imageUrl
                  : null
              }
              // BUG 1202284288749235
              // onClick={() => fileInput.current.click()}
              // onDragOver={handleDragOver}
              // onDrop={(e) => handleOnDrop(e, obj)}
            />
            {/* <input
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => uploadImg(e, obj)}
              ref={fileInput}
              accept="image/*"
            /> */}
            <div className="ingredient-name">{key}</div>
          </div>
        </span>
      ),
    },
    {
      title: 'Quantity',
      // dataIndex: 'qty',
      sorter: (a, b) => (a.quantity < b.quantity ? -1 : a.quantity > b.quantity ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: '13%',
      render: (key, obj, idx) => (
        <TableInputQty
          inputClassName={'qty-input-sm'}
          onChangeValue={updateQty}
          quantityValue={obj.quantity}
          displayValue={obj?.displayQty ? obj.displayQty : '0'}
          onFocusValue={getRowVal}
          rowData={obj}
          tabIndex={idx * 4 + 1}
        />
      ),
    },
    {
      title: 'Measurement',
      dataIndex: 'measurement',
      sorter: (a, b) => (a.measurementId < b.measurementId ? -1 : a.measurementId > b.measurementId ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: '17%',
      render: (key, obj, idx) => (
        <span>
          <select
            className="ing-select123"
            value={obj.measurementId ? obj.measurementId : 0}
            onChange={(e) => selectMeas(e, obj.quantity, obj.id)}
            tabIndex={idx * 4 + 2}
          >
            <option key="0" value={0} initialvalue="default">
              Select
            </option>
            {measurements &&
              measurements.length > 0 &&
              measurements.map((m, i) => {
                return (
                  <option key={`measurement-${i}`} value={m.id}>
                    {m.name}
                  </option>
                );
              })}
          </select>
        </span>
      ),
    },
    {
      title: 'Preparation',
      dataIndex: 'preparation',
      sorter: (a, b) => (a.preparationId < b.preparationId ? -1 : a.preparationId > b.preparationId ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: '15%',
      render: (key, obj, idx) => (
        <span>
          <select
            className="ing-select123"
            value={obj.preparationId ? obj.preparationId : 0}
            onChange={selectPrep}
            tabIndex={idx * 4 + 3}
          >
            {preparation &&
              preparation.length > 0 &&
              preparation.map((m, i) => {
                return (
                  <option key={`prep-${i}`} value={m.id}>
                    {m.name}
                  </option>
                );
              })}
          </select>
        </span>
      ),
    },
    {
      title: 'Station',
      dataIndex: 'station',
      sorter: (a, b) => (a.stationId < b.stationId ? -1 : a.stationId > b.stationId ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: '25%',
      render: (key, obj, idx) => (
        <span>
          <CidekicSelect
            // className="ing-selectble"
            defaultValue={obj?.station ? obj.station : 'Select station'}
            // value={obj?.station ? obj.station : "Select station"}
            onChange={(e) => selectStation(e)}
            disabled={oneStationOnly}
            tabIndex={idx * 4 + 4}
            // dropdownRender={(menu) => (
            // 	<div onMouseDown={(e) => e.preventDefault()}>
            // 			{menu}
            // 			<Divider style={{ margin: '4px 0' }} />
            // 			<div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            // 					{/* <Input style={{ flex: 'auto', width: '50%' }} onChange={onNameChange}  /> */}
            // 					<span
            // 							style={{
            // 									flex: 'none',
            // 									padding: '3px',
            // 									display: 'block',
            // 									cursor: 'pointer',
            // 									color: 'var(--base-text)',
            // 							}}
            // 							onClick={addItem}
            // 					>
            // 							Add new station
            // 					</span>
            // 			</div>
            // 	</div>
            // )}
          >
            <Option key={0} value="default">
              Select station
            </Option>
            {station &&
              station.length > 0 &&
              station.map((m, i) => {
                return (
                  <Option key={i + 1} value={m.id}>
                    {m.stationName}
                  </Option>
                );
              })}
          </CidekicSelect>
        </span>
      ),
    },
    // {
    //   title: 'Cost',
    //   dataIndex: 'cost',
    //   sorter: (a, b) => (a.cost < b.cost ? -1 : a.cost > b.cost ? 1 : 0),
    //   sortDirections: ['descend', 'ascend'],
    //   width: 140,
    //   render: (key, obj, index) =>
    //     editInput ? (
    //       <span className="qty-input-sm">
    //         $
    //         <input placeholder="Enter" className="qty-input-in" type="number" value={key} onChange={updateCost}></input>
    //       </span>
    //     ) : (
    //       <span className="qty-input-sm">
    //         $
    //         <input
    //           className="qty-input-in"
    //           type="number"
    //           value={key}
    //           placeholder="Enter"
    //           onClick={() => setEditInput(true)}
    //         ></input>
    //       </span>
    //     ),
    // },
    {
      title: '',
      dataIndex: 'unassign',
      width: '5%',
      render: (key, obj) => (
        <span>
          <Dropdown overlay={deletesIng}>
            <div className="toggle-switch13" onMouseOver={(e) => setDeleteIng(obj)}>
              <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
            </div>
          </Dropdown>
        </span>
      ),
    },
  ];

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      console.log(fromIndex, toIndex);
      const data = [...addIngredients];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      data.map((d, i) => {
        d.recipe = true;
        d.order = i;
        return d;
      });
      ingListUpdates(data);
    },
    handleSelector: 'a',
    nodeSelector: 'tr.ant-table-row.ant-table-row-level-0',
  };

  const toggleClass = (e, obj) => {
    setActiveCat(obj.id);
    setIsDown(!isDown);
  };

  const sortIngredient = (e) => {
    let selected = e;
    let cloneIng = [...addIngredients].map((ing) => {
      // delete ing.displayQty;
      delete ing.editing;
      return ing;
    });
    if (selected == 'name') {
      cloneIng.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : Number(a.name.toLowerCase() > b.name.toLowerCase())
      );
      ingListUpdates(cloneIng);
      setAddIngredients(cloneIng);
    }
    if (selected == 'measurement') {
      cloneIng.sort((a, b) =>
        a.measurementName.toLowerCase() < b.measurementName.toLowerCase()
          ? -1
          : Number(a.measurementName.toLowerCase() > b.measurementName.toLowerCase())
      );
      ingListUpdates(cloneIng);
      setAddIngredients(cloneIng);
    }
    if (selected == 'preparation') {
      cloneIng.sort((a, b) =>
        a.preparationName.toLowerCase() < b.preparationName.toLowerCase()
          ? -1
          : Number(a.preparationName.toLowerCase() > b.preparationName.toLowerCase())
      );

      ingListUpdates(cloneIng);
      setAddIngredients(cloneIng);
    }
    if (selected == 'station') {
      cloneIng.sort((a, b) =>
        a.stationName.toLowerCase() < b.stationName.toLowerCase()
          ? -1
          : Number(a.stationName.toLowerCase() > b.stationName.toLowerCase())
      );

      ingListUpdates(cloneIng);
      setAddIngredients(cloneIng);
    }
    if (selected == 'quantity') {
      cloneIng.sort((a, b) => {
        return a.quantity - b.quantity;
      });
      console.log(cloneIng);
      ingListUpdates(cloneIng);
      setAddIngredients(cloneIng);
    }

    if (selected == 'cost') {
      cloneIng.sort((a, b) => {
        return a.cost - b.cost;
      });
      ingListUpdates(cloneIng);
      setAddIngredients(cloneIng);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(addIngredients).map((ing) => {
      // delete ing.displayQty;
      delete ing.editing;
      return ing;
    });
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAddIngredients(items);

    ingListUpdates(items);
  };

  const setValues = () => {
    setEditInput(true);
  };

  const makeAddedList = () => {
    const elements = (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {addIngredients.map((obj, index) => {
                return (
                  <Draggable key={obj.id.toString()} draggableId={obj.id.toString()} index={index}>
                    {(provided) => (
                      <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div style={{ color: 'var(--base-text)' }}>
                          {/* DRAGGER  */}
                          <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                            <div className="showDragIconRecMob">
                              {' '}
                              <a className="drag-handle" href="#">
                                <img src={reorder} alt="reorder" className="menusToggles" />
                              </a>
                            </div>
                            <Dropdown overlay={deletesIng}>
                              <div className="toggle-switch" onMouseOver={(e) => setDeleteIng(obj)}>
                                <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                              </div>
                            </Dropdown>
                          </div>
                          {/* IMAGE  */}
                          <div style={{ display: 'flex' }}>
                            <ThumbnailImage
                              className="ingredient-image-container"
                              src={
                                isEditing
                                  ? obj.imageUrlThumb
                                    ? obj?.imageUrlThumb || obj?.imageUrl
                                    : obj?.info && (obj?.info?.imageUrlThumb || obj?.info?.imageUrl)
                                    ? obj?.info?.imageUrlThumb || obj?.info?.imageUrl
                                    : null
                                  : obj?.info && (obj?.info?.imageUrlThumb || obj?.info?.imageUrl)
                                  ? obj?.info?.imageUrlThumb || obj?.info?.imageUrl
                                  : obj?.imageUrlThumb || obj?.imageUrl
                                  ? obj?.imageUrlThumb || obj?.imageUrl
                                  : null
                              }
                              onClick={() => fileInput.current.click()}
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleOnDrop(e, obj)}
                            />

                            <input
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) => uploadImg(e, obj)}
                              ref={fileInput}
                              accept="image/*"
                            />

                            <div
                              style={{
                                overflow: 'hidden',
                                minWidth: '50%',
                              }}
                            >
                              {' '}
                              {obj.name}
                            </div>
                          </div>
                          {/* QUANTITY */}
                          <div>
                            <span className="create-rec-qtyspan">Quantity</span>
                            <TableInputQty
                              inputClassName={'qty-input-smMob'}
                              onChangeValueMob={updateQtyMob}
                              quantityValue={obj.quantity}
                              displayValue={obj.displayQty}
                              onFocusValue={getRowVal}
                              rowData={obj}
                            />
                          </div>
                          {/* MEASUREMENT */}
                          <div className="create-rec-margins">
                            <div className="create-rec-measr">
                              <div className="create-rec-measpan">Measurement</div>
                              <div>
                                <select
                                  className="ing-selectble123"
                                  value={parseInt(obj.measurement) ? obj.measurement : obj.measurementId}
                                  onChange={(e) => selectMeasMob(e, obj)}
                                >
                                  <option initialvalue="default">Select</option>
                                  {measurements &&
                                    measurements.length > 0 &&
                                    measurements.map((m, i) => {
                                      return (
                                        <option key={`measurement-${i}`} value={m.id}>
                                          {m.name}
                                        </option>
                                      );
                                    })}
                                </select>
                                {/* <Select
                                  className="ing-selectble"
                                  defaultValue={'DEFAULT'}
                                  value={obj.measurement}
                                  onChange={(e) => selectMeasMob(e, obj)}
                                >
                                  <Option value="DEFAULT" disabled>
                                    Select
                                  </Option>
                                  {measurement.map((m) => {
                                    return <Option value={m.id}>{m.name}</Option>;
                                  })}
                                </Select> */}
                              </div>
                            </div>
                          </div>
                          {/* PREPARATION */}
                          <div className="create-rec-margins">
                            <div className="create-rec-measr">
                              <div className="create-rec-prepspan">Preparation</div>
                              <div>
                                <select
                                  className="ing-selectble123"
                                  value={parseInt(obj.preparation) ? obj.preparation : obj.preparationId}
                                  onChange={(e) => selectPrepMob(e, obj)}
                                >
                                  <option initialvalue="default">Select</option>
                                  {preparation &&
                                    preparation.length > 0 &&
                                    preparation.map((m) => {
                                      return <option value={m.id}>{m.name}</option>;
                                    })}
                                </select>
                                {/* <Select
                                  showSearch
                                  className="ing-selectble"
                                  defaultValue={'DEFAULT'}
                                  value={obj.preparation}
                                  onChange={(e) => selectPrepMob(e, obj)}
                                >
                                  <Option value="DEFAULT" disabled>
                                    Select
                                  </Option>
                                  {preparation.map((m) => (
                                    <Option value={m.name}>{m.name}</Option>
                                  ))}
                                </Select> */}
                              </div>
                            </div>
                          </div>
                          {/* STATION */}
                          <div className="create-rec-margins">
                            <div className="create-rec-measr">
                              <span className="create-rec-stationspan">Station</span>
                              <div>
                                <Select
                                  className="ing-selectables-mob"
                                  // defaultValue={'default'}
                                  value={obj?.station ? obj.station : 'Select'}
                                  onChange={(e) => selectStationMob(e, obj)}
                                  disabled={oneStationOnly}
                                  dropdownRender={(menu) => (
                                    <div onMouseDown={(e) => e.preventDefault()}>
                                      {menu}
                                      <Divider style={{ margin: '4px 0' }} />
                                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        {/* <Input style={{ flex: 'auto', width: '50%' }} onChange={onNameChange}  /> */}
                                        <span
                                          style={{
                                            flex: 'none',
                                            padding: '3px',
                                            display: 'block',
                                            cursor: 'pointer',
                                            color: 'var(--base-text)',
                                          }}
                                          onClick={addItem}
                                        >
                                          Add new station
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                >
                                  <Option key={0} value="default">
                                    Select
                                  </Option>
                                  {station &&
                                    station.length > 0 &&
                                    station.map((m, i) => {
                                      return (
                                        <Option key={i + 1} value={m.id}>
                                          {m.stationName}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                          {/* <div className="create-rec-padding">
                            <span className="create-rec-costspan">Cost</span>
                            {editInput ? (
                              <span className="qty-input-smMob">
                                $
                                <input
                                  type="number"
                                  placeholder="Enter"
                                  className="qty-input-in"
                                  type="number"
                                  value={obj.cost}
                                  onChange={(e) => updateCostMob(e, obj)}
                                ></input>
                              </span>
                            ) : (
                              <span className="qty-input-smMob">
                                $
                                <input
                                  className="qty-input-in"
                                  type="number"
                                  value={obj.cost}
                                  placeholder="Enter"
                                  onClick={() => setValues()}
                                ></input>
                              </span>
                            )}
                          </div> */}
                        </div>
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
    //));
    return elements;
  };

  useEffect(() => {
    eventSocket.on('updatedPrep', (message) => {
      let newData = JSON.parse(message);
      console.log('PREPUPDATED!!', [newData]);
      setPrepUpdate([newData]);
    });
    eventSocket.on('addedPrep', (message) => {
      let newData = JSON.parse(message);
      console.log('PREPADDED!!', newData);
      setPrepAdd(newData);
    });
    eventSocket.on('deletedPrep', (message) => {
      let newData = JSON.parse(message);
      console.log('PREPDELETED!!', newData.id);
      setPrepDelete(newData.id);
    });
    eventSocket2.on('updatedMeas', (message) => {
      let newData = JSON.parse(message);
      console.log('MEASUPDATED!!', [newData]);
      setMeasUpdate([newData]);
    });
    eventSocket2.on('addedMeas', (message) => {
      let newData = JSON.parse(message);
      console.log('MEASADDED!!', newData);
      setMeasAdd(newData);
    });
    eventSocket2.on('deletedMeas', (message) => {
      let newData = JSON.parse(message);
      console.log('MEASDELETED!!', newData.id);
      setMeasDelete(newData.id);
    });
    return () => {
      eventSocket.disconnect();
      eventSocket2.disconnect();
    };
  }, []);

  useEffect(() => {
    setPrep((oldArray) => [...oldArray, prepAdd]);
    setMeasurements((oldArray) => [...oldArray, arrangeDBMeasurementUnits(measAdd)]);
    setSocketData((oldArray) => [...oldArray, prepAdd]);
    setSocketData2((oldArray) => [...oldArray, measAdd]);
  }, [prepAdd, measAdd]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.map((obj) => prepUpdate.find((o) => o.id === obj.id) || obj);
    console.log(updated);
    setPrep(updated);
    setSocketData(updated);
    console.log(socketData2);
    let updated1 = socketData2.map((obj) => measUpdate.find((o) => o.id.toString() === obj.id.toString()) || obj);
    console.log(updated1);
    setMeasurements(arrangeDBMeasurementUnits(updated1));
    setSocketData(updated1);
  }, [prepUpdate, measUpdate]);

  useEffect(() => {
    console.log(socketData);
    let updated = socketData.filter((obj) => obj.id !== prepDelete);
    console.log(updated);
    setPrep(updated);
    setSocketData(updated);
    let updated1 = socketData.filter((obj) => obj.id.toString() !== measDelete.toString());
    setMeasurements(arrangeDBMeasurementUnits(updated1));
    setSocketData(updated1);
  }, [prepDelete, measDelete]);

  const chooseFromOperators = (
    <div className="assignee-list">
      <List
        dataSource={operatorsToChoose}
        renderItem={(operator) => (
          <List.Item
            className="meeting-events-list-item"
            onClick={() => {
              searchAssignee(`${operator.firstName} ${operator.lastName}`);
              setSelectedAssignee(operator.id);
            }}
          >
            <div>
              {operator.firstName} {operator.lastName}
            </div>
          </List.Item>
        )}
      />
    </div>
  );

  const singleKitchenStation = (e) => {
    if (oneStationOnly) {
      let stationId = 0,
        stationName = '';
      if (e !== 'default') {
        stationId = e;
        stationName = station.find((st) => st.id === e).stationName;
      }
      const updateIng = addIngredients.map((p) => {
        return {
          ...p,
          stationId: stationId * 1,
          stationName: stationName,
          station: stationName,
        };
      });
      setAddIngredients(updateIng);
      setOneStation(stationId);
      ingListUpdates(updateIng);
    }
  };

  const toggleSingleStation = () => {
    if (oneStationOnly) {
      const updateIng = _.cloneDeep(originalStations);
      const reverseIngredients = addIngredients.map((ing) => {
        const ingredientId = ing.id;
        const previousStation = updateIng.find((ing) => ing.ingredientId === ingredientId);
        return {
          ...ing,
          stationId: previousStation.stationId,
          stationName: previousStation.stationName,
          station: previousStation.stationName,
        };
      });
      setAddIngredients(reverseIngredients);
      ingListUpdates(reverseIngredients);
      setOneStationOnly(false);
      setOneStation(0);
      setOriginalStations([]);
    } else {
      setOneStationOnly(true);
    }
  };

  return (
    <div className="main">
      {!addIngredientsList?.length || isEditing ? (
        <div className="create-rec-controls">
          <div className="view-recipe-section-title-edit">Ingredients</div>
          <div className="view-recipe-ingredient-controls">
            <Checkbox
              className="flex-control-vert-center recipe-all-station-checkbox"
              checked={oneStationOnly}
              onChange={toggleSingleStation}
            >
              Single station
            </Checkbox>
            <div className="flex-control-right flex-control-vert-center recipe-all-station-select">
              <CidekicSelect
                // className="ing-selectble"
                disabled={!oneStationOnly}
                defaultValue={
                  station?.find((st) => st.id === oneStation)?.stationName
                    ? station.find((st) => st.id === oneStation).stationName
                    : 'default'
                }
                onChange={(e) => singleKitchenStation(e)}
                // dropdownRender={(menu) => (
                // 	<div onMouseDown={(e) => e.preventDefault()}>
                // 			{menu}
                // 			<Divider style={{ margin: '4px 0' }} />
                // 			<div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                // 					{/* <Input style={{ flex: 'auto', width: '50%' }} onChange={onNameChange}  /> */}
                // 					<span
                // 							style={{
                // 									flex: 'none',
                // 									padding: '3px',
                // 									display: 'block',
                // 									cursor: 'pointer',
                // 									color: 'var(--base-text)',
                // 							}}
                // 							onClick={addItem}
                // 					>
                // 							Add new station
                // 					</span>
                // 			</div>
                // 	</div>
                // )}
              >
                <Option key={0} value="default">
                  Select single station
                </Option>
                {station &&
                  station.length > 0 &&
                  station.map((m, i) => {
                    return (
                      <Option key={i + 1} value={m.id}>
                        {m.stationName}
                      </Option>
                    );
                  })}
              </CidekicSelect>
            </div>
          </div>
        </div>
      ) : (
        <div className="create-rec-controls">
          <div className="view-recipe-section-title-edit">Ingredients</div>
          <div className="create-rec-cost">
            <div className="view-cost-analysis" onClick={showDrawer}>
              View Cost Analysis
            </div>
            <div className="view-tot-cost">Total Cost: $ {totalCost}</div>
          </div>
        </div>
      )}
      {addIngredientsList?.length ? (
        <div>
          {windowSize.width > 700 ? (
            <div>
              <ReactDragListView {...dragProps}>
                <Table
                  columns={columns}
                  dataSource={addIngredients}
                  className="edit-view-recipe-items-table"
                  pagination={false}
                  rowKey={'key'}
                  onRow={(r) => ({
                    onClick: () => getRowVal(r),
                  })}
                />
              </ReactDragListView>
            </div>
          ) : (
            <div>
              {' '}
              <Select className="ing-selectble" onChange={sortIngredient} defaultValue={'sortby'}>
                <Option value="sortby" disabled>
                  Sort By
                </Option>
                <Option value="name">Ingredient name</Option>
                <Option value="quantity">Quantity</Option>
                <Option value="measurement">Measurement</Option>
                <Option value="preparation">Preparation</Option>
                <Option value="station">Station</Option>
                <Option value="cost">Cost</Option>
              </Select>
              {/* <ReactDragListView {...dragProps}>
                <Table
                  columns={colMobile}
                  dataSource={addIngredients}
                  pagination={false}
                  onRow={(r) => ({
                    onClick: () => getRowVal(r),
                  })}
                />
              </ReactDragListView> */}
              {makeAddedList()}
            </div>
          )}
        </div>
      ) : (
        ''
      )}
      <Drawer
        width={windowSize.width >= 842 ? '842px' : '100%'}
        onClose={onClose}
        visible={visible}
        drawerStyle={{ backgroundColor: 'var(--mid-fill)' }}
      >
        <div className="cost-analysis-page">Cost Analysis</div>
        <div className="cost-circle-container">
          <div>
            <div className="cost-circle">$7.5</div>
            <div className="cost-ingr">Ingredients cost</div>
          </div>
          <div>
            <div className="cost-circle">$25</div>
            <div className="cost-ingr">Menu price</div>
          </div>
          <div>
            <div className="cost-circle">70%</div>
            <div className="cost-ingr">Profit</div>
          </div>
        </div>
        <div>
          <BarGraph />
        </div>
        <div>
          <DonutGraph />
        </div>
        <div>
          <LineGraph />
        </div>
      </Drawer>
      <Drawer
        width={windowSize.width >= 842 ? '400px' : '100%'}
        onClose={onClose1}
        visible={visible1}
        drawerStyle={{ backgroundColor: 'var(--mid-fill)' }}
      >
        <div className="create-newSta-title">Enter a new station</div>
        <div className="create-newStation">
          <Input onChange={getInput} placeholder="New station name" className="create-newSta-input1" />
        </div>
        <div className="create-newSta-selectAssignee">
          {' '}
          <Popover
            content={chooseFromOperators}
            overlayStyle={{ marginTop: '-2px', width: '100%', backgroundColor: '#454545', zIndex: '99999' }}
            placement="bottom"
            trigger="focus"
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <input
              type="text"
              placeholder="Search Assignee"
              value={searchedAssignee}
              onChange={(e) => {
                searchAssignee(e.target.value);
              }}
              className="kitchen-station-assignee-input"
              placement="top"
            />
          </Popover>
        </div>
        <button className="create-newSta-save" onClick={submitNewStation}>
          {isSubmitting ? 'Submitting...' : 'Submit New Station'}
        </button>
      </Drawer>
    </div>
  );
};

export default IngredientTable;
