import React from 'react';
import { Form } from 'antd';
import { resetPassword } from '../../services/operator.service';
import './activateAccount.css';

const ActivateAccount = (props) => {
  const {
    match: {
      params: { token },
    },
  } = props;

  const {
    form: { getFieldDecorator, getFieldsError, getFieldValue },
  } = props;

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    props.form.validateFields((err, { email, password, confirm }) => {
      if (!err && password === confirm) {
        console.log(email, password, confirm);
        const params = {
          password,
          activateToken: decodeURIComponent(token),
        };
        console.log(params);
        resetPassword(email, params).then((res) => {
          console.log(res);
          props.history.push('/');
        });
      }
    });
  };

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  return (
    <div className="login-container flex">
      <div className="login-form-container">
        <div className="activate-box">
          <div className="login-header"></div>
          <div className="reset-password-form-container">
            <div className="reset-form-header">Enter and confirm your new password.</div>
            <Form onSubmit={handlePasswordReset} layout="vertical">
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  initialValue: '',
                })(<input placeholder="Email" className="login-user-input" />)}
              </Form.Item>
              <Form.Item className="password-reset" name="password" label="Password" hasFeedback>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(
                        /^(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;=?@[\]^_`{|}~])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9!"#$%&'()*+,-./:;=?@[\]^_`{|}~]{8,100}$/
                      ),
                      message:
                        'Password must be 9 characters or longer, have at least one lowercase letter, one uppercase letter, a number, and a special character.',
                    },
                    {
                      validator: (rule, value) => {
                        if (value) {
                          props.form.validateFields(['confirm'], { force: true });
                        }
                        return Promise.resolve('');
                      },
                    },
                  ],
                })(<input placeholder="Password" className="login-user-input" />)}
              </Form.Item>
              <Form.Item className="confirm-reset-password" name="confirm" label="Confirm Password" hasFeedback>
                {getFieldDecorator('confirm', {
                  rules: [
                    {
                      validator: (rule, value) => {
                        if (value && getFieldValue('password') !== value) {
                          return Promise.reject('Passwords do not match!');
                        }
                        return Promise.resolve('');
                      },
                    },
                  ],
                })(<input placeholder="Password" className="login-user-input" />)}
              </Form.Item>
              <div style={{ textAlign: 'center', paddingTop: '15px' }}>
                <Form.Item>
                  <button className="login-btn-recipe" type="submit" disabled={hasErrors(getFieldsError())}>
                    Reset Password
                  </button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form.create({ name: 'activateAccount' })(ActivateAccount);
