import React from 'react';
import { ThumbnailImage } from '../../components/common/common_ui';

const EquipmentList = (props) => {
  const { equipmentList } = props;

  console.log(equipmentList);

  return (
    <div className="view-recipe-section-container">
      <div className="view-recipe-section-title">Equipment</div>

      <table className="view-recipe-items-table">
        <thead>
          <tr className="ing-list-thead">
            <th>Name</th>
            {/* <th>Type</th>
            <th>Size</th> */}
            <th>Category</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {equipmentList.map((equipment, i) => {
            return (
              <tr key={`equipment-${i}`}>
                <td>
                  <div className="ingredient-name-container">
                    <ThumbnailImage
                      className="ingredient-image-container"
                      src={
                        equipment?.imageUrlThumb || equipment?.imageUrl
                          ? equipment?.imageUrlThumb || equipment?.imageUrl
                          : equipment?.info && (equipment?.info?.imageUrlThumb || equipment?.info?.imageUrl)
                          ? equipment?.info?.imageUrlThumb || equipment?.info?.imageUrl
                          : null
                      }
                    />
                    <div className="ingredient-name"> {equipment?.name}</div>
                  </div>
                </td>
                {/* <td>{equipment?.type}</td>
                <td>{equipment?.size}</td> */}
                <td>{equipment?.category}</td>
                <td>{equipment?.quantity}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EquipmentList;
