/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useCallback } from 'react';
import { Table, Menu, Dropdown } from 'antd';
//import { userData } from '../../data/userdata';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { all, searchAccounts, activateUser, deactivateUser } from '../../services/operator.service'; //destroy
import { all as allAccount } from '../../services/account.service';
import _ from 'lodash';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';
import { ThumbnailImage, UserIcon } from '../../components/common/common_ui';

const SuperUsersMobile = (props) => {
  const [menuName, setMenuName] = useState('');
  const [userData, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [nameFilter, setNameFilter] = useState(null);
  //const [selected, setSelected] = useState(null);
  const [menulist, setList] = useState([]);
  //const [isLoading, setIsLoading] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

  const operatorInfo = JSON.parse(localStorage.getItem('operator') || {});

  useEffect(() => {
    getUsers();
    getAccounts();
  }, []);

  const getUsers = () => {
    //setIsLoading(true);
    all(0, 1000).then((res) => {
      console.log(res);

      let allUsers = res.map((r) => {
        let arr = [],
          keys = Object.keys(r.restaurants);

        for (let i = 0, n = keys.length; i < n; i++) {
          let key = keys[i];
          arr[key] = r.restaurants[key];
        }

        let restoIds = arr.map((r) => r.restaurantId);
        return {
          firstName: r.firstName,
          lastName: r.lastName,
          role: r.role.name,
          active: r.info.activated ? 'Yes' : 'No',
          admin: r.role.name == 'Admin' || r.role.name == 'Superadmin'  ? 'Yes' : 'No',
          email: r.email,
          id: r.id,
          username: r.username,
          restaurants: restoIds,
          image: r.info ? r.info?.userImageThumb || r.info?.userImage || r.info?.userImg : '',
          accountId: r.accountId,
          deletedAt: r.deletedAt,
        };
      });
      console.log(allUsers);
      let activeUsers = allUsers.filter((u) => u.active);
      setData(activeUsers);
      setOrigData(allUsers);
      //setIsLoading(false);
    });
  };

  const getAccounts = () => {
    allAccount(0, 1000).then((res) => {
      console.log(res);
      if (res) {
        let allAccounts = res;
        console.log(allAccounts);
        allAccounts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setList(allAccounts);
      }
    });
  };

  const debouncedApiCall = useCallback(
    _.debounce((e) => {
      filterUsers(e);
    }, 300),
    []
  );

  useEffect(() => {
    debouncedApiCall(nameFilter);
  }, [nameFilter, debouncedApiCall]);

  const resetAllRestos = () => {
    setData(origData);
    setMenuName('All accounts');
  };

  const filteringMenus = (menu) => {
    let menuName = menu.name;
    setMenuName(menuName);
    let filtered = origData.filter((obj) => obj.accountId == menu.id);
    console.log(filtered);
    setData(filtered);
  };

  const menuList = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => resetAllRestos()}>
        <span>All accounts</span>
      </Menu.Item>
      {menulist &&
        menulist.map((m, i) => {
          return (
            <Menu.Item className="menu-show-preview" onClick={(e) => filteringMenus(m)}>
              <span>{m?.name}</span>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const filterUsers = (e) => {
    console.log(e);
    if (e) {
      let keyword = e.toLowerCase();
      console.log(keyword);

      searchAccounts(keyword, 0, 1000).then((res) => {
        console.log(res);
        let userData = res.map((r) => {
          return {
            firstName: r.firstName,
            lastName: r.lastName,
            role: '',
            active: r.info.activated ? 'Yes' : 'No',
            admin: r.role.name == 'Admin' || r.role.name == 'Superadmin'  ? 'Yes' : 'No',
            email: r.email,
            id: r.id,
            username: r.username,
            image: r.info ? r.info?.userImageThumb || r.info?.userImage || r.info?.userImg : '',
          };
        });
        setData(userData);
      });
    } else {
      getUsers();
    }
  };

  // const deleteUser = (e) => {
  //   e.preventDefault();
  //   console.log(selected?.email);
  //   let email = selected?.email;
  //   destroy(email).then((res) => {
  //     console.log(res);
  //     // notification.open({
  //     //   message: 'User Successfully Deactivated',
  //     // });
  //     getUsers();
  //   });
  // };

  const activateSuperUser = (e, obj) => {
    e.preventDefault();
    const userToActivate = obj.id;
    const activator = operatorInfo?.email ? operatorInfo.email : '';

    activateUser(userToActivate.toString(), activator).then((res) => {
      getUsers();
      //setSelected(null);
    });
  };

  const deactivateSuperUser = (e, obj) => {
    e.preventDefault();
    const userToDeactivate = obj.id;
    const deactivator = operatorInfo?.email ? operatorInfo.email : '';

    deactivateUser(userToDeactivate.toString(), deactivator).then((res) => {
      getUsers();
      //setSelected(null);
    });
  };

  const controlActivatedItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <Link to={`/super/users/edit/${obj.email}`}>
            <span>Edit</span>
          </Link>
        </Menu.Item>
        {/* <Menu.Item className="menu-show-preview12">Deactivate</Menu.Item> */}
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => deactivateSuperUser(e, obj)}>Deactivate</span>
        </Menu.Item>
      </Menu>
    );
  };

  const controlDeactivatedItems = (obj) => {
    return (
      <Menu className="menu-show-preview12">
        <Menu.Item className="menu-show-preview12">
          <Link to={`/super/users/edit/${obj.email}`}>
            <span>Edit</span>
          </Link>
        </Menu.Item>
        {/* <Menu.Item className="menu-show-preview12">Deactivate</Menu.Item> */}
        <Menu.Item className="menu-show-preview12">
          {' '}
          <span onClick={(e) => activateSuperUser(e, obj)}>Activate</span>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      sorter: (a, b) => (a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      render: (key, obj) => (
        <span>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              <ThumbnailImage
                className="admin-image-container-mobile"
                type={'admin'}
                src={obj.image !== '' ? obj.image : null}
                placeholderIcon={<UserIcon />}
              />
            </span>
            <div>
              <Dropdown overlay={obj.active == 'Yes' ? controlActivatedItems(obj) : controlDeactivatedItems(obj)}>
                <div style={{ cursor: 'pointer' }}>
                  <img
                    src={IconsMoreOff}
                    className="UI-IconsMoreOff"
                    alt="IconsMoreOff"
                    //onClick={() => setSelected(obj)}
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: '-6px' }}>First name</div>
            <div style={{ marginLeft: 20 }}>{obj.firstName}</div>
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Last name</div>
            <div style={{ marginLeft: 20 }}>{obj.lastName}</div>
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Role</div>
            <div style={{ marginLeft: 60 }}>{obj.role}</div>
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Active</div>
            <div style={{ marginLeft: 49 }}>{obj.active}</div>
          </div>
          <div style={{ display: 'flex', marginTop: 15 }}>
            <div style={{ marginLeft: '-6px' }}>Admin</div>
            <div style={{ marginLeft: 49 }}>{obj.admin}</div>
          </div>
        </span>
      ),
    },
  ];

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Users"
        restaurant="Super Admin"
        showSearch={showSearch}
        onChange={(evt) => setNameFilter(evt.target.value)}
        clickSearch={() => setShowSearch(true)}
      />
      <div style={{ display: 'flex' }}>
        <div className="add-cat22Mob">
          <div>{menuName ? menuName : 'Account name'}</div>
        </div>
        <Dropdown overlay={menuList}>
          <div className="dropdown-icon">
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </Dropdown>
        <div className="spacer-cat"></div>
        <div
          className="add-cat12Mob"
          onClick={() => {
            //setIsAdding(true);
          }}
        >
          <Link to={`/super/users/new`}>
            <div style={{ cursor: 'pointer', fontFamily: 'Helvetica Neue' }}>
              {/* use <PlusButton> for this /> */}+ <span style={{ marginLeft: 5 }}>Add new user</span>
            </div>
          </Link>
        </div>
      </div>
      <div style={{ padding: '30px 20px 0px 15px' }}>
        <Table
          dataSource={userData}
          columns={columns}
          pagination={false}
          className={'show-custom-empty'}
          locale={{ emptyText: <Empty image={empty} description="No users" /> }}
        />
      </div>{' '}
      <div className="add-checklistUser">
        <Link to={`/super/users/new`}>
          <div className="add-checklist-but" onClick={() => {}}>
            <span>
              <PlusOutlined />
            </span>{' '}
            Add new user
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SuperUsersMobile;
