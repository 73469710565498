/* eslint eqeqeq: "off" */
import { tableData } from './measurementTable.js';
//
// TODO:
// 60 fl oz should be 1 Quart + 1 Pint + 1 1/2 Cup Milk (the fraction is close enough)
// - currently, it is 1 Quart + 1 Pint + 1 Cup + 4oz Milk
// - decompose_measurement does not take the fractional rounding into account, so adds ounces to the deconstruction
// - this will require that each measure have the fractionals evaluated (see comments)
//
// Metrics should just be round kg/l or g/ml in output
//
// why is 1750 ml = 1L + 750ml and not 1.75L or 1 3/4 L(single quantity is correct)
// -- BUT 100 Cups in metric is 23 2/3 L (instead of 23L + Nml)
// -- ok, because if the rounded quotient of the measure is within error, it will use only that measure,
// -- though it may later choose a fractional value
// -- however at small values, the rounded value will be out of error range and an additional measure will be selected
// I am not sure now how 1.75 Gallon can be a result for composite - why doesn't it try the smaller measures? Or does it?
//
//
// Tests/issues
// 15 Cups (test) - 3.75G, 3 Quart + 1 Pint + 1 Cup
// 16 Cups is one gallon (test)
// 1.5 t is 1.5t (not rounded) (test)
// why is 300 Cups 18 3/4 Gallons and not 18G + 3Q? - because the algorithm prefers to use a single measure, if it can, even if that means adding a symbol.
// 0.1234 oz should be a test, and decide if the round-to-1-decimal should instead round accrding to eps (this shows that error)
// 100 Cups should be 6 1/4 Gal and "6 Gallon + 1 Quart Ingredient" at .5% error and gobbdygook at 0 error but do it anyway

// The _measurements table lists all the measures that we can convert/combine
// 'equiv' property is the equivalent mass (g) or volume (ml) of the measure
//
// This would be the master _measurements table in the DB
// const _measurements = tableData; // BUG 1202189635222538

// The Fractionals table lists all the partial measures that are allowed and
// how they are represented in a string.
// The algorithm will seek out and "stick" to these fractional values if they
// are within error limits.
// Fractionals without a symbol are instead added by value without any symbol.
//   (This creates the stickiness for the round numbers as well)
const Fractionals = [
  { value: 0 },
  { value: 0.25, symbol: 'Â¼' },
  { value: 0.3333, symbol: 'â…“' },
  { value: 0.5, symbol: 'Â½' },
  { value: 0.6667, symbol: 'â…”' },
  { value: 0.75, symbol: 'Â¾' },
  { value: 1 },
];

// Measurement Class
//
// A Measurement is described by three properties:
//   quantity (float) quantity of the measurement
//   measure_id (id) - id from the _measurements table
//   name (string) - string name of ingredient being measured (unused by the class)
//     (name only used for UI output, this property would likely be an ingredient id in practice)
//
// Features of the Measurement object:
//
// * Scale a measurement
//   scaling is non-destructive within floating-point limits
// * Reduce a measurement (convert to best human-readable measurement)
//   Reduction is ALSO non-destructive within floating-point limits
// * Obtain an array of component-measurements that represent the measurement
//   This component-output transformation is also non-destrucive.
//
// ONLY the formatting for UI output produces error, within the tolerance of the
// setting for allowed_error_pct. These are the UI methods:
//
// * Obtain a rounded UI-ready quantity string for display
// * Obtain the error of the rounded quantity string
// * Obtain plurality for rounded quantity
// * Obtain a string representation of the component measurements
// * Obtain the error of the composite string
//
// Examples?
// var measure = new Measurement(xyz)
// measure.scale(100).reduce()
// console.log(measure.composite_ui_string())
// > "1 Pint + 1 Cup Milk"
//
class Measurement {
  ///////////////////////////
  // Class Properties
  ///////////////////////////

  // SAFARI doesn't seem to allow static property definitions
  // so these are defined after the class definition
  //static __allowed_error_pct = 0.03 // 3% error
  //static __max_ui_decimals = 5      // return no more than 5 decimal places regardless of error

  ///////////////////////////
  // Class Methods
  ///////////////////////////

  static set_allowed_error_pct(error) {
    Measurement.__allowed_error_pct = error / 100.0;
  }

  static get_allowed_error_pct() {
    return Measurement.__allowed_error_pct * 100.0;
  }

  static set_max_ui_decimals(decimals) {
    Measurement.__max_ui_decimals = decimals;
  }

  ///////////////////////////
  // Private Properties
  ///////////////////////////
  _measurements = []; // BUG 1202189635222538
  _quantity = 0;
  _measure_id = 0;
  _name = 'Unknown';
  _units = 'unknown'; // "US" vs "metric"
  _type = 'unknown'; // "mass" vs "volume"
  _equiv_amt = 0; // measurement in ml
  _quantity_ui_error = 0; // error of UI quantity string in current measure
  _quantity_ui_plural = false; // bool describes if UI quantity string is plural
  _composite_ui_error = 0; // error of UI composite string, expressed in the Measure below
  _composite_ui_error_mid = 0; // id of measure used to represent UI composite string error

  ///////////////////////////
  // Public Methods
  ///////////////////////////

  // Constructors
  constructor(quantity, measure_id, name, measurements) {
    // console.log("========> Measurement:constructor")
    this._measurements = measurements;
    this._quantity = parseFloat(quantity);
    this._measure_id = parseInt(measure_id);
    this._name = name; // probably, this would be ingredient_id
    this.set_meta();
  }

  clone() {
    // only one constructor allowed in this JS engine
    return new Measurement(this._quantity, this._measure_id, this._name, this._measurements);
  }

  // Getters/setters

  get quantity() {
    return this._quantity;
  }
  set quantity(quantity) {
    this._quantity = quantity;
    this.set_meta();
  }
  get_quantity_in_measure(measure_id) {
    // if no measure is passed, will use internal measure
    return this.quantity_from_equiv(this._equiv_amt, measure_id || this._measure_id);
  }

  get measure_id() {
    return this._measure_id;
  }
  set measure_id(measure_id) {
    this._measure_id = measure_id;
    this.set_meta();
  }

  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
    // this.set_meta()
  }

  get type() {
    return this._type;
  }
  get units() {
    return this._units;
  }

  get equiv_amt() {
    return this._equiv_amt;
  }
  get quantity_ui_error() {
    return this._quantity_ui_error;
  }
  get quantity_ui_plural() {
    return this._quantity_ui_plural;
  }
  get composite_ui_error() {
    return this._composite_ui_error;
  }
  get composite_ui_error_mid() {
    return this._composite_ui_error_mid;
  }

  get_allowed_error_pct() {
    return Measurement.__allowed_error_pct;
  } // class variable
  get_allowed_error() {
    return this._equiv_amt * Measurement.__allowed_error_pct;
  } // in ml/g

  get_allowed_error_in_measure(measure_id) {
    // if no measure is passed, will use internal measure
    return this.quantity_from_equiv(this.get_allowed_error(), measure_id || this._measure_id);
  }

  // Scaling and Reducing

  // Measurement:scale()
  // Scaling is non-destructive within floating-point limits
  scale(factor) {
    this._quantity *= factor;
    this.set_meta();
    return this;
  }

  // Measurement:reduce(units_out)
  //
  // This function uses the _measurements table to convert the measure into the
  // 'best fit', defined as the largest single measure into which the measurement
  // fully fits (9oz fits in a Cup, but does not fully fit into a Pint,
  // thus Cup is chosen as the measure.)
  //
  // units_out - [optional] used to produce a measure in specified units.
  //    If empty, undefined, or 'original', the units of the measurement are preserved.
  //
  // -- this function incorporates the error setting but ONLY in deciding which
  // measure to use. It does NOT actually round the number. Error should
  // be incorporated at view time. See Measurement:quantity_ui_string
  //
  reduce(units_out) {
    // console.log("========> Measurement:reduce")
    // console.log("units_out: " + units_out)

    var try_units = this._units; // use current units if none specified
    if (units_out && units_out != 'original') {
      try_units = units_out;
    }
    // console.log("try_units: " + try_units)

    var rel_measures = this.filter_relevant_measures(try_units);
    if (rel_measures.length > 0) {
      var eps = this.get_allowed_error();
      // console.log("equiv = "+ this._equiv_amt)
      // console.log("  eps = "+ eps)

      for (var i = 0; i < rel_measures.length; i++) {
        var test_measure = rel_measures[i];
        //console.log("Trying " + test_measure.id + ':' + test_measure.name)

        var quotient = (this._equiv_amt + eps) / test_measure.equiv; // eps ensures we round up to the larger measure
        //console.log("Quotient was " + quotient)

        if (quotient >= 1) {
          this._measure_id = rel_measures[i].id;
          this._quantity = this.quantity_from_equiv(this._equiv_amt, this._measure_id);
          console.log('Algorithm result id: ' + this._measure_id + ':' + test_measure.name + ' qty: ' + this._quantity);
          break;
        }
      }
      this._units = try_units; // measure may have been converted
    }
    // console.log("Measurement:")
    // console.log(this)
    return this;
  }

  // Measurement:can_reduce()
  //
  // This function indicates whether the measure can possibly be reduced
  // _measurements like "Piece" and "Each" cannot be reduced as they have no equivalency
  // Return of true does not guarantee that the measure will be reduced, only that it is possible
  can_reduce() {
    // unless the Measure has some equivalent, it cannot be converted
    return this._measurements.find((unit) => unit.id === this._measure_id).equiv; // BUG 1202189635222538
  }

  // UI Methods
  // These take the allowed_error setting into account, but still do NOT affect
  // the accuracy of the measure itself.

  // Measurement:quantity_ui_string()
  //
  // This function returns the optimised human-readable form of the measurement
  // quantity. E.g., "2Â½" or "1.41". For use in displaying a rough quantity to the user.
  //
  // ERROR is incorporated at this point as the floating-point quantity
  // value is coerced into a human-readable string. The returned string will have
  // been rounded or transformed into a fractional representation according to
  // the allowed_error setting. The measurement itself is not affected.
  //
  // The method uses ONLY the floating-point quantity value so units and measure are not relevant.
  // Note that the returned string is not a numerical value.
  quantity_ui_string() {
    // console.log("========> Measurement:quantity_ui_string")
    // console.log("Quantity is " + this._quantity)

    var format = this.quantity_ui_format(this._quantity);
    this._quantity_ui_error = format.error;
    this._quantity_ui_plural = format.plural;
    return format.text;
  }

  // Measurement:composite_ui_string(units_out)
  //
  // This function uses the _measurements table to produce the optimised human-readable
  // component form of the measurement quantity E.g., "1 Pint + 1 Cup Milk"
  // that is optionally displayed to the user when viewing an ingredient quantity.
  //
  // units_out - [optional] used to produce a measure in specified units.
  //    If empty, undefined, or 'original', the units of the measurement are preserved.
  //
  // ERROR is incorporated at this point as the measure are decomposed and
  // reassembled into a human-readable string. The returned string will have
  // been rounded or transformed into a fractional representation according to
  // the allowed_error setting. The measurement itself is not affected.
  //
  // Note that the returned string is not a numerical value.
  composite_ui_string(units_out) {
    // console.log("========> Measurement:composite_ui_string")

    var try_units = this._units; // use current units if none specified
    if (units_out && units_out != 'original') {
      try_units = units_out;
    }

    var format = this.composite_ui_format(try_units);

    this._composite_ui_error = format.error;
    this._composite_ui_error_mid = format.error_mid;
    return format.text;
  }

  ///////////////////////////
  // Private Methods
  ///////////////////////////

  // Measurement:set_meta()
  //
  // Sets or clears associated Measure object values when the quantity or
  // measure_id values change.
  set_meta() {
    // set quantity and measure_id, then call this
    this._units = this._measurements.find((unit) => unit.id === this._measure_id).units || 'unknown'; // "US" vs "metric" // BUG 1202189635222538
    this._type = this._measurements.find((unit) => unit.id === this._measure_id).type || 'unknown'; // "mass" vs "volume" // BUG 1202189635222538
    this._equiv_amt = (this._measurements.find((unit) => unit.id === this._measure_id).equiv || 0) * this._quantity; // BUG 1202189635222538
    this._quantity_ui_error = 0;
    this._quantity_ui_plural = false;
    this._composite_ui_error = 0;
    this._composite_ui_error_mid = 0;
  }

  // Measurement:filter_relevant_measures(units_out)
  //
  // This function filters the _measurements table and returns only the
  // measure relevant to the conversion, those that match both the
  // type ("mass" vs "volume") and the units ("US" vs "metric") of the desired measurement
  //
  // units_out - [optional] used to produce a measure in specified units.
  //    If empty, undefined, or 'original', the units of the measurement are preserved.
  //
  // Resulting filtered list is then sorted from largest to smallest measure matching units and type.
  //
  filter_relevant_measures(units_out) {
    var units_filter = this._units; // use current units if none specified

    if (units_out && units_out != 'original') {
      units_filter = units_out;
    }

    // BUG 1202189635222538
    var rel_measures = this._measurements.filter((measure) => {
      return measure.units == units_filter && measure.type == this._type;
    });
    //console.log("filtered measures")
    //console.log(rel_measures)

    rel_measures.sort((a, b) => (a.equiv < b.equiv ? 1 : -1)); // sort high-low equiv
    //console.log("sorted measures")
    //console.log(rel_measures)
    return rel_measures;
  }

  // Measurement:quantity_from_equiv(amount, measure_id)
  //
  // this returns the size of the given measure needed to hold the amount
  // e.g., 10 ounces would require 1.25 cups
  quantity_from_equiv(amount, measure_id) {
    return amount / this._measurements.find((unit) => unit.id === measure_id).equiv; // BUG 1202189635222538
  }

  // Measurement:composite_ui_format(units_out)
  //
  // This function uses the _measurements table to produce the optimised
  // human-readable form of the measurement (e.g., "1T + 1t")
  //
  // units_out - [optional] used to produce a measure in specified units.
  //    If empty, undefined, or 'original', the units of the measurement are preserved.
  //
  // -- this function incorporates the error setting since it produces transitory
  // UI output
  //
  composite_ui_format(units_out) {
    //console.log("========> Measurement:composite_ui_format")
    //console.log(this)

    var result = { text: '', error: 0, error_mid: this._measure_id };

    var component_measures = this.decompose_measurement(units_out);
    if (this.can_reduce()) {
      //console.log("composite _measurements")
      //console.log(component_measures);
      var last_measure_idx = component_measures.length - 1;

      for (var i = 0; i < last_measure_idx; i++) {
        var measure = component_measures[i];
        result.text +=
          measure.quantity + ' ' + this._measurements.find((unit) => unit.id === measure.measure_id).name + ' + '; // BUG 1202189635222538
      }

      var last_measure = component_measures[last_measure_idx];
      var quantity_ui = this.quantity_ui_format(
        last_measure.quantity,
        this.quantity_from_equiv(this._equiv_amt, last_measure.measure_id)
      );
      //text plural error(units)
      result.text +=
        quantity_ui.text +
        ' ' +
        this._measurements.find((unit) => unit.id === last_measure.measure_id).name +
        (quantity_ui.plural ? 's' : ''); // BUG 1202189635222538
      result.text += ' ' + this._name;
      result.error = quantity_ui.error;
      result.error_mid = last_measure.measure_id;

      //console.log("result: " + result_txt)
      // console.log("Composite quantity_ui:")
      // console.log(result)
    } else {
      result.text =
        this._quantity +
        ' ' +
        this._measurements.find((unit) => unit.id === this._measure_id).name +
        (this._quantity > 1 ? 's' : ''); // BUG 1202189635222538
      result.text += ' ' + this._name;
    }
    return result;
  }

  // Measurement:decompose_measurement(units_out)
  //
  // This function uses the _measurements table to decompose a measurement
  // into its component measures using round numbers with the largest units.
  //
  // The final measure value is left unrounded. This method does not change
  // the orinal measurement.
  //
  // units_out - [optional] used to produce a measure in specified units.
  //    If empty, undefined, or 'original', the units of the measurement are preserved.
  //
  decompose_measurement(units_out) {
    // console.log("========> Measurement:decompose_measurement")
    //console.log(this)

    var result = []; // decomposed measurements
    var remaining = this._equiv_amt; // ml

    var rel_measures = this.filter_relevant_measures(units_out);
    if (rel_measures.length > 0) {
      var eps = this.get_allowed_error();

      // console.log("equiv = " + remaining + "ml")
      // console.log("EPS = " + eps + "ml")
      for (var i = 0; i < rel_measures.length && remaining > eps; i++) {
        // console.log("Evaluating measure: " + rel_measures[i].name)
        // console.log("remaining: " + remaining + "ml")

        // eps is added here so that the largest measure within error is selected
        if (Math.floor((remaining + eps) / rel_measures[i].equiv) > 0) {
          var quotient = remaining / rel_measures[i].equiv;
          // console.log("quotient: " + quotient)

          // check if a round value of this measure is within error
          // NOTE this does NOT check if a fractional value is within error and this has repercussions
          var measure_amt = Math.round(quotient);
          var equiv_amt = measure_amt * rel_measures[i].equiv;

          if (Math.abs(remaining - equiv_amt) > eps) {
            // otherwise, take the floor
            measure_amt = Math.floor(quotient);
            equiv_amt = measure_amt * rel_measures[i].equiv;
          }

          result.push({ quantity: measure_amt, measure_id: rel_measures[i].id });
          remaining -= equiv_amt;
          // console.log("Used " + measure_amt + ", remainder is " + remaining + "ml")
        }
      }
    }

    if (result.length > 0) {
      // there is at least one valid whole measure
      var last_measure = result[result.length - 1];
      last_measure.quantity += remaining / this._measurements.find((unit) => unit.id === last_measure.measure_id).equiv; // BUG 1202189635222538
    } else {
      // no valid measure exists
      result.push({ quantity: this._quantity, measure_id: this._measure_id });
    }

    //console.log("Decompose result:")
    //console.log(result)
    return result;
  }

  // Measurement:quantity_ui_format(quantity, full_quantity)
  //
  // This incorporates error and coverts a single float quantity measurement
  // into a pretty string for output.
  //
  // quantity - float
  // full_quantity - float
  //   full_quantity is provided if quantity does not represent the full
  //   measurement to ensure that the error is calculated properly.
  //
  // Function will attempt to "round" the quantity to a whole number or any
  // one of a list of "common fractions" so long as the error is not violated.
  // Error is based on the size of the passed quantity which should always be
  // the FULL measurement quantity, expressed in the same units as quantity.
  //
  // Return value is an object
  //   text: string that may contain non-number characters (e.g., comma seperators, fractional character entities).
  //   plural: indicates if text is plural, for UI measure labels
  //   error: error expressed in the same units as quantity
  //
  quantity_ui_format(quantity, full_quantity) {
    // console.log("========> Measurement:quantity_ui_format")

    full_quantity = full_quantity || this.quantity_from_equiv(this._equiv_amt, this._measure_id);
    // console.log("Quantity is " + quantity + " full_quantity is " + full_quantity)

    var base = Math.floor(quantity); // whole number part
    var best_error = quantity; // effectively the MAX error
    var best_point = 0;
    var result = {};

    // check if number is close enough to a whole number or common fraction
    for (var i = 0; i < Fractionals.length; i++) {
      var point = base + Fractionals[i].value;
      var error = Math.abs(quantity - point);

      // console.log("Evaluating " + Fractionals[i].value)
      // console.log("point is " + point + " error is " + error)

      if (error < best_error) {
        // console.log("-- better error is now " + error)
        best_error = error;
        best_point = i;
      }
    }

    // console.log("best_error: " + best_error)

    var allowed_error = full_quantity * this.get_allowed_error_pct();

    if (best_error <= allowed_error) {
      var symbol = Fractionals[best_point].symbol;
      result.error = best_error;
      // console.log("-- error accepted")

      if (symbol) {
        // if the Fractional has a symbol
        result.text = (base == 0 ? '' : base) + symbol; // then a common fraction was found
        result.plural = base > 0;
      } else {
        var modified_base = base + Fractionals[best_point].value; // otherwise a close whole number was found
        result.text = modified_base;
        result.plural = modified_base > 1;
      }
    } else {
      // else round to 1 decimal place
      // console.log("-- error not accepted, rounding")
      var approx;
      var pow = 0;
      do {
        pow += 1;
        approx = Math.round((quantity + Number.EPSILON) * 10 ** pow) / 10 ** pow;
        result.error = Math.abs(quantity - approx);
      } while (result.error > allowed_error && pow < Measurement.__max_ui_decimals);

      // console.log("Pow is " + pow)
      // console.log("result.error is " + result.error)
      // console.log("Allowed error is " + allowed_error)
      result.text = approx;
      result.plural = approx > 1.0;
    }
    return result;
  }
}

export default Measurement;

///////////////////////////
// Class Properties
///////////////////////////
Measurement.__allowed_error_pct = 0.05;
Measurement.__max_ui_decimals = 5;
