/* eslint eqeqeq: "off" */
import { request } from './http.service';
import { decodeIngredientMessages } from '../protobuf-models/ingredient.proto.js';

export const create = (params) => {
  return request('POST', '/ingredient', 201, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const update = (id, params) => {
  return request('PUT', '/ingredient/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroy = (id) => {
  return request('DELETE', '/ingredient/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const get = (id) => {
  return request('GET', '/ingredient/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const count = (params) => {
  return request('POST', `/ingredient/count`, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const search = (params) => {
  let finalParams = params;
  if (params.isSuperAdmin == undefined) {
    finalParams.isSuperAdmin = false;
  }
  return request('POST', '/ingredient/search', 200, finalParams, decodeIngredientMessages).then(
    (result) => {
      console.log('RESULT FROM SEARCH INGREDIENTS', result);
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const searchMatch = (params) => {
  return request('POST', '/ingredient/match/search', 200, params, decodeIngredientMessages).then(
    (result) => {
      console.log('RESULT FROM SEARCH INGREDIENTS', result);
      return result;
    },
    (error) => {
      // console.log(error);
    }
  )
}
