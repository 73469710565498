import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { GridItem } from 'react-grid-dnd';
import { StationAssigneeEdit, RowOkCancel } from '../common/common_ui';
//import { getKitchenOperators } from './KitchenStationUtils';

const EditKitchenStation = (props) => {
  const { restaurantId, locationOperators } = props;
  const [name, setName] = useState('');
  const [searchedAssignee, setSearchedAssignee] = useState('');
  //const [assignee, setAssignee] = useState();
  const [assigneeId, setAssigneeId] = useState();
  const [operatorsToChoose, setOperatorsToChoose] = useState([]);

  useEffect(() => {
    if (searchedAssignee !== '') {
      const likeOperators = locationOperators.filter(
        (op) =>
          op.firstName.toLowerCase().indexOf(searchedAssignee.toLowerCase()) !== -1 ||
          op.lastName.toLowerCase().indexOf(searchedAssignee.toLowerCase()) !== -1
      );
      setOperatorsToChoose(likeOperators);
    } else {
      if (locationOperators.length > 0) {
        setOperatorsToChoose(locationOperators);
      }
    }
  }, [searchedAssignee, locationOperators]);

  // CHANGE 1202077200318274
  const onClickRemoveAssignee = () => {
    setAssigneeId();
    setSearchedAssignee('');
    setOperatorsToChoose(locationOperators);
  };

  // CHANGE 1202077200318274
  // Content for the assignee pop-over
  // const content = <div className="assignee-list">
  //   <List
  //     dataSource={operatorsToChoose}
  //     renderItem={(item) => (
  //       <List.Item
  //         className="meeting-events-list-item"
  //         onClick={() => {
  //           const name = `${item.firstName} ${item.lastName}`;
  //           //setAssignee(name);
  //           setAssigneeId(parseInt(item.id));
  //           setSearchedAssignee(name);
  //         }}
  //       >
  //         <div>
  //           {item.firstName} {item.lastName}
  //         </div>
  //       </List.Item>
  //     )}
  //   />
  // 	<span className="row-assignee-clear" onClick={onClickRemoveAssignee}>
  //     <TrimmedPlusIcon />
  //   </span>
  // </div>;

  return (
    <GridItem key={-1} className="kitchen-station">
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        className="kitchen-station d-flex flex-column"
      >
        <div className="flex-grow-1" style={{ width: '100%' }}>
          <div className="kitchen-station-name-input">
            <label className="required-asterisk" style={{ marginLeft: 15 }}>
              Station
            </label>
            <input
              autoFocus
              type="text"
              placeholder="Enter station name"
              className="kitchen-station-name-input1"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <div style={{ marginTop: 10 }}>
              <div className="kitchen-station-assignee-container">
                {/* CHANGE 1202077200318274 */}
                <StationAssigneeEdit
                  assigneeChoices={operatorsToChoose}
                  assignee={searchedAssignee}
                  onChange={(value) => {
                    setAssigneeId(parseInt(JSON.parse(value).id));
                    setSearchedAssignee(`${JSON.parse(value).name}`);
                  }}
                  onSearch={(value) => {
                    setSearchedAssignee(value);
                  }}
                  onClickRemove={() => {
                    onClickRemoveAssignee();
                  }}
                />
                {/* <Popover
                content={content}
                overlayStyle={{
                  marginTop: '-2px',
                  width: '100%',
                  backgroundColor: '#454545',
                  zIndex: '99999',
                }}
                placement="bottom"
                trigger="focus"
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <label style={{ marginLeft: 15 }}>Assignee</label>
                <input
                  type="text"
                  placeholder="Search assignee"
                  value={searchedAssignee}
                  onChange={(e) => {
                    setSearchedAssignee(e.target.value);
                  }}
                  className="kitchen-station-assignee-input1"
                  placement="top"
                />
              </Popover> */}
              </div>
            </div>
          </div>
        </div>
        <div className="station-tile-kitchen-controls">
          <RowOkCancel
            onClickOk={() => props.onSubmit(restaurantId, name, assigneeId)}
            onClickCancel={props.onCancel}
          />
        </div>
      </div>
    </GridItem>
  );
};

export default EditKitchenStation;
