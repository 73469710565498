/* eslint eqeqeq: "off" */
import React, { useState } from 'react';
import { Form } from 'antd';
import { create } from '../../services/account.service';
import { BacktoHeaderMobile } from '../../components/Header/HeadMobile.js';

const AccountsNewMobile = (props) => {
  const [account, setAccount] = useState('');

  const cancelForm = () => {
    window.location.reload();
  };

  const saveUser = (e) => {
    e.preventDefault();
    let rename = account.trim();
    let params = {
      name: rename,
      info: { name: rename },
    };
    console.log(params);
    if (rename == '') {
      return;
    } else {
      create(params).then((res) => {
        // notification.open({
        //   message: 'Successfully created account!',
        // });
        props.history.push('/super/accounts');
      });
    }
  };

  return (
    <div>
      <Form onSubmit={saveUser} style={{ color: 'var(--base-text)' }}>
        <BacktoHeaderMobile restaurant="Super Admin" backText="Back to accounts" backLink="/super/accounts" />
        <div className="Line"></div>
        <div>
          <div className="userTitle-Mob">Add new account</div>
          <br />
          <div>
            <div className="userInfo-data1">
              <div style={{ marginLeft: 5 }}>Account name</div>
              <input
                placeholder="Enter account name"
                type="text"
                className="user-input12"
                defaultValue={account}
                onChange={(event) => setAccount(event.target.value)}
              />
              <br />
              <div className="btn-container">
                <button className="save-btn-recipe" htmltype="submit">
                  Save
                </button>
                <button className="cancel-btn-recipe" onClick={cancelForm}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Form.create()(AccountsNewMobile);
