/* eslint eqeqeq: "off" */
import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import './index.css';
import { Collapse } from 'antd';
import selected from '../../img/st-recipe-selected.png';
import unselected from '../../img/rec-unselect.png';
import select from '../../img/st-rec-select.png';
import unselect from '../../img/st-rec-unselect.png';
//import { theChecklist } from '../../data/recipeListData';

const { Panel } = Collapse;

const StationChecklistsMobile = (props) => {
  const {
    updateFilter,
    updateActiveChks,
    showDrawer,
    showChecklists,
    handleShowChecklists,
    handleShowRecipeLists,
    selectedStationChecklists,
    stationId,
    hasChecklist,
    handleSetCurrentChecklist,
    updateChecklist,
  } = props;
  const [expandedRows, setExpandedRows] = useState(['0']);
  const [checkedList, setChecked] = useState(0);
  const [activeChecklistItems, setActiveChecklistItems] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [theChecklist, setTheChecklist] = useState([]);

  let checkList = theChecklist;

  useEffect(() => {
    setTheChecklist(selectedStationChecklists);
  }, [selectedStationChecklists]);

  useEffect(() => {
    if (showChecklists) {
      setExpandedRows(['0']);
      let checkedItems = theChecklist
        .reduce((r, obj) => r.concat(obj.list), [])
        .reduce((r, obj) => r.concat(obj.list), [])
        .filter((l) => l.selected == 1);
      setChecked(checkedItems.length);
      updateActiveChks(checkedItems);
    } else {
      uncheckAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theChecklist, showChecklists]);

  const uncheckAll = () => {
    if (selectedStationChecklists) {
      selectedStationChecklists.map(function iter(a) {
        a.selected = 0;

        Array.isArray(a.list) && a.list.forEach(iter);
        return a;
      });
      setTheChecklist(selectedStationChecklists);
    }

    let checkedItems = theChecklist
      .reduce((r, obj) => r.concat(obj.list), [])
      .reduce((r, obj) => r.concat(obj.list), [])
      .filter((l) => l.selected == 1);

    setChecked(checkedItems.length);
    setExpandedRows([]);

    if (showChecklists) {
      handleShowChecklists(false);
      handleShowRecipeLists(true);
    }
  };

  useEffect(() => {
    //
    if (clicked) {
      updateActiveChks(activeChecklistItems);
    } else {
      console.log(selectedStationChecklists);
      let activeList = selectedStationChecklists?.map((l) =>
        l.list.map((m) => {
          return m.list;
        })
      );

      let merged = flatten(activeList);

      updateActiveChks(merged);
    }

    if (activeChecklistItems.length > 0) {
      handleShowChecklists(true);
      updateFilter(true);

      // if (activeChecklistItems.length < theChecklist.length) {
      //updateFilter(true);
      // }
    } else {
      handleShowChecklists(false);
      updateFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChecklistItems, clicked, selectedStationChecklists]);

  const flatten = (arr) => arr?.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

  const togglePanels = (key) => {
    //remove station id of open recipe panel
    let getActiveStations = JSON.parse(localStorage.getItem('openRecipePanelStations'));
    if (getActiveStations) {
      let newRows = getActiveStations.filter((val) => val !== stationId);
      localStorage.setItem('openRecipePanelStations', JSON.stringify(newRows));
    }
    setExpandedRows(key);

    if (!showChecklists) {
      handleShowChecklists(true);
      handleShowRecipeLists(false);
    }

    if (key.length > 0) {
      setExpandedRows(['0']);
    }
  };

  const checkAll = () => {
    checkList.map(function iter(a) {
      a.selected = 1;

      Array.isArray(a.list) && a.list.forEach(iter);
      return a;
    });

    let checkedItem = theChecklist
      .reduce((r, obj) => r.concat(obj.list), [])
      .reduce((r, obj) => r.concat(obj.list), [])
      .filter((l) => l.selected == 1);

    setChecked(checkedItem.length);
    setTheChecklist(checkList);

    if (!showChecklists) {
      handleShowChecklists(true);
      handleShowRecipeLists(false);
    }
    console.log('SET CHECKED', checkedList);
    console.log('CHECKLIST', theChecklist);
  };

  const selectChecklistItem = (list, index) => (event) => {
    setClicked(true);

    if (event.ctrlKey || event.metaKey) {
      selectedStationChecklists.map(function iter(a) {
        if (list.key == a.key) {
          a.selected = 1;
        }
        Array.isArray(a.list) && a.list.forEach(iter);
        return a;
      });

      let checkedItems = selectedStationChecklists
        .reduce((r, obj) => r.concat(obj.list), [])
        .reduce((r, obj) => r.concat(obj.list), [])
        .filter((l) => l.selected == 1);

      setChecked(checkedItems.length);
      setTheChecklist(selectedStationChecklists);
      handleSetCurrentChecklist(checkList.find((c) => c.selected === 1).id);
      setActiveChecklistItems(checkedItems);

      if (!showChecklists) {
        handleShowChecklists(true);
        handleShowRecipeLists(false);
      }

      showDrawer(false);
    } else {
      selectedStationChecklists.map(function iter(a) {
        if (list.key == a.key) {
          a.selected = 1;
        } else {
          a.selected = 0;
        }

        Array.isArray(a.list) && a.list.forEach(iter);
        return a;
      });

      let checkedItems = selectedStationChecklists
        .reduce((r, obj) => r.concat(obj.list), [])
        .reduce((r, obj) => r.concat(obj.list), [])
        .filter((l) => l.selected == 1);

      setChecked(checkedItems.length);
      setTheChecklist(selectedStationChecklists);
      handleSetCurrentChecklist(checkList.find((c) => c.selected === 1)?.id);
      setActiveChecklistItems(checkedItems);

      if (!showChecklists && checkedItems.length >= 1) {
        handleShowChecklists(true);
        handleShowRecipeLists(false);
      }

      showDrawer(false);
    }
  };

  return (
    <div>
      {hasChecklist ? (
        <div className="station-rec-main">
          <div className="station-reci-title">Checklists </div>
          <div className="station-reci-total">
            {`${checkedList} checklist${checkedList > 1 ? 's' : ''} selected`}{' '}
            <span className="checked-reset" onClick={checkAll}>
              Reset
            </span>
          </div>

          <Collapse
            defaultActiveKey={expandedRows}
            activeKey={expandedRows}
            expandIconPosition={'right'}
            onChange={togglePanels}
          >
            {theChecklist.map((recipe, i) => {
              return (
                <Panel
                  header={
                    expandedRows.includes(i.toString()) ? (
                      <div className="rec-select-active">
                        <img src={select} alt="selected" height="28" /> {recipe.name}
                      </div>
                    ) : (
                      <div className="rec-select-inactive">
                        <img src={unselect} alt="selected" height="28" /> {recipe.name}
                      </div>
                    )
                  }
                  key={i.toString()}
                >
                  {recipe.list.map((content, i) => {
                    return (
                      <div className="rec-content-box" key={`content-box-${i + 1}`}>
                        <div className="rec-content-type">
                          <div> {content.name}</div>
                        </div>
                        {content.list.map((list, i) => {
                          return (
                            <div key={`content-${(i + 1).toString()}`}>
                              <div
                                className={list.selected ? 'rec-list-contents' : 'rec-list-contents-unselect'}
                                onClick={selectChecklistItem(list, i)}
                              >
                                <div>
                                  {list.itemsCompleted === list.itemsTotal && list.selected == 1 && (
                                    <img src={selected} alt="selected" height="28" />
                                  )}
                                  {list.itemsCompleted === list.itemsTotal && list.selected == 0 && (
                                    <img src={unselected} alt="selected" height="28" />
                                  )}
                                </div>
                                <div className="rec-list-name">{list.name}</div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </Panel>
              );
            })}
          </Collapse>
        </div>
      ) : (
        ' '
      )}
    </div>
  );
};

export default StationChecklistsMobile;
