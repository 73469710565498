const RoleAccessibleComponent = ({ permittedRoles, children }) => {
  let userRole = (JSON.parse(localStorage.getItem('operator')) || {}).role;
  const hasAccess = permittedRoles.includes(userRole);
  if (hasAccess) {
    return children;
  }

  return null;
};

export default RoleAccessibleComponent;
