import React from 'react';


export const docThumbnailSrc = (doc_object) => {
  const category = doc_object.category;

  // if you return an image source here, the docIcon will not be used

  if (category) {
    if (category.includes('image')) {
      return doc_object.info?.file;
    }
  }
  return null;
};

export const docIcon = (doc_object) => {
  const category = doc_object.category;

  // return an SVG icon to be used when no docThumbnailSrc is available

  if (category) {
    if (category.includes('image')) {
      return null;  // could return 'missing image' placeholder
    } else if (category.includes('Word')) {
      return <WordIcon />;
    } else if (category.includes('PDF')) {
      return <PDFIcon />;
    } else if (category.includes('Excel')) {
      return <ExcelIcon />;
    } else if (category.includes('Powerpoint')) {
      return <PowerPointIcon />;
    } else {
      return <NoteIcon />;
    }
  }
  return null;
};

const NoteIcon = (props) => {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" fill="white" fillOpacity="0.01"/>
      <path d="M8 6C8 4.89543 8.89543 4 10 4H30L40 14V42C40 43.1046 39.1046 44 38 44H10C8.89543 44 8 43.1046 8 42V6Z" fill="#F6F27A" stroke="black" strokeWidth="0" strokeLinejoin="round"/>
      <path d="M16 20H32" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 28H32" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

const WordIcon = (props) => {
  return (
    <svg fill="#3075DC" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26 26" width="26px" height="26px">
      <path d="M 15 0 L 0 2.875 L 0 23.125 L 15 26 Z M 16 3 L 16 5.96875 L 23.03125 5.96875 L 23.03125 8 L 16 8 L 16 10 L 23 10 L 23 12 L 16 12 L 16 14 L 23 14 L 23 16 L 16 16 L 16 18 L 23 18 L 23 20 L 16 20 L 16 23 L 25.15625 23 C 25.617188 23 26 22.605469 26 22.125 L 26 3.875 C 26 3.394531 25.617188 3 25.15625 3 Z M 1.96875 7.9375 L 3.875 7.9375 L 5 14.6875 C 5.046875 14.972656 5.070313 15.359375 5.09375 15.84375 L 5.125 15.84375 C 5.140625 15.480469 5.191406 15.085938 5.28125 14.65625 L 6.71875 7.9375 L 8.59375 7.9375 L 9.90625 14.75 C 9.953125 15 9.996094 15.335938 10.03125 15.8125 C 10.046875 15.441406 10.09375 15.070313 10.15625 14.6875 L 11.25 7.9375 L 13.03125 7.9375 L 10.9375 18.0625 L 9 18.0625 L 7.6875 11.5625 C 7.617188 11.222656 7.578125 10.855469 7.5625 10.4375 L 7.53125 10.4375 C 7.5 10.898438 7.46875 11.265625 7.40625 11.5625 L 6.0625 18.0625 L 4.03125 18.0625 Z"/>
    </svg>
  );
};

const ExcelIcon = (props) => {
  return (
    <svg fill="#1A9256" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26 26" width="26px" height="26px">
      <path d="M 15 0 L 0 2.875 L 0 23.125 L 15 26 Z M 16 3 L 16 5.96875 L 19.03125 5.96875 L 19.03125 8 L 16 8 L 16 10 L 19 10 L 19 12 L 16 12 L 16 14 L 19 14 L 19 16 L 16 16 L 16 18 L 19 18 L 19 20 L 16 20 L 16 23 L 25.15625 23 C 25.617188 23 26 22.605469 26 22.125 L 26 3.875 C 26 3.394531 25.617188 3 25.15625 3 Z M 20 6 L 24 6 L 24 8 L 20 8 Z M 3.09375 7.9375 L 5.84375 7.9375 L 7.3125 11 C 7.425781 11.238281 7.535156 11.515625 7.625 11.84375 C 7.683594 11.644531 7.8125 11.359375 7.96875 10.96875 L 9.5625 7.9375 L 12.09375 7.9375 L 9.0625 12.96875 L 12.1875 18.09375 L 9.5 18.09375 L 7.75 14.78125 C 7.683594 14.660156 7.601563 14.421875 7.53125 14.09375 L 7.5 14.09375 C 7.46875 14.25 7.402344 14.496094 7.28125 14.8125 L 5.53125 18.09375 L 2.8125 18.09375 L 6.03125 13.03125 Z M 20 10 L 24 10 L 24 12 L 20 12 Z M 20 14 L 24 14 L 24 16 L 20 16 Z M 20 18 L 24 18 L 24 20 L 20 20 Z"/>
    </svg>
  );
};

const PDFIcon = (props) => {
  return (
    <svg fill="#EB3724" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="26px" height="26px">
      <path className="cls-1" d="M253.48,329.18A203.86,203.86,0,0,0,269,350.31c-3.09.6-7.54,1.56-13.55,3.05-5.41,1.36-9.73,2.73-13,3.9l2.3-5.28C248.06,344.37,251.38,335.21,253.48,329.18Zm-3-65.07c-.11-9.76-2.7-9.76-3.8-9.76-1.68,0-4.19,3.57-4.19,13.38A70.75,70.75,0,0,0,246,288.52C248,281.27,250.51,270.64,250.44,264.1ZM178.07,406.41c-.2.87-.1,1.41,1,2.08h0a4.51,4.51,0,0,0,3.89.4c7.34-2.09,16.53-15.36,22.24-25.08C195.29,389,179.52,399.94,178.07,406.41Zm116.54-49.15c10.88,8.38,23.73,12.07,29.49,9.64,1.94-.82,2.81-2.27,2.81-4.7a3.4,3.4,0,0,0-1.36-2.79C320.75,355.41,306,355.8,294.61,357.26ZM460.8,153.6V448a64,64,0,0,1-64,64H115.2a64,64,0,0,1-64-64V64a64,64,0,0,1,64-64H307.34Zm-115.26,210v-.05c-.32-3.22-3.44-19.27-30.71-18.55-19.26.48-28.06,1.53-31.51,2.09-4.12-4.44-18.38-20.24-24.57-33.07,1.72-5.37,8.42-27.35,8.07-42.22-.31-13.35-2.79-28.64-19.3-28.64h-.28c-4.82.05-8.61,1.59-11.29,4.57-4.27,4.77-5.52,12.63-4.17,26.27,1.29,13,7,27.66,8.64,31.73-1.33,4.12-6.38,19.52-12.71,34.67-5.94,14.25-10.4,23.15-11.91,26-5.23,1.88-26.15,9.79-36.24,19.41-9.39,8.95-17.92,19.59-10,30.58,2.64,3.66,7.57,5.88,13.2,5.93h.19c10.14,0,20.91-6.66,29.58-18.31,13.08-17.57,20.11-29.55,22.18-33.21,5-1.39,23.52-6.57,29.81-8.12,5.38-1.33,11.68-2.47,14.54-3,4.94,4.75,24.38,22,43.78,21.35,9-.34,15.51-2.71,19.32-7.06A13.94,13.94,0,0,0,345.54,363.64ZM435.2,166.4,294.4,25.6v76.8a64,64,0,0,0,64,64Z"/>
    </svg>
  );
};

const PowerPointIcon = (props) => {
  return (
    <svg fill="#C33A2B" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26 26" width="26px" height="26px">
      <path d="M 15 0 L 0 2.875 L 0 23.125 L 15 26 Z M 16 3 L 16 7.25 C 16.601563 6.929688 17.273438 6.71875 18 6.71875 L 18 11 L 22.28125 11 C 22.28125 13.359375 20.359375 15.28125 18 15.28125 C 17.273438 15.28125 16.601563 15.070313 16 14.75 L 16 18 L 21 18 L 21 20 L 16 20 L 16 23 L 25.15625 23 C 25.617188 23 26 22.605469 26 22.125 L 26 3.875 C 26 3.394531 25.617188 3 25.15625 3 Z M 19 5.71875 C 21.359375 5.71875 23.28125 7.640625 23.28125 10 L 19 10 Z M 3.875 7.9375 L 7.4375 7.9375 C 9.992188 7.9375 11.28125 9.003906 11.28125 11.15625 C 11.28125 12.171875 10.917969 13.027344 10.1875 13.65625 C 9.453125 14.285156 8.476563 14.59375 7.25 14.59375 L 6.15625 14.59375 L 6.15625 18.0625 L 3.875 18.0625 Z M 6.15625 9.6875 L 6.15625 12.84375 L 7.0625 12.84375 C 8.277344 12.84375 8.875 12.316406 8.875 11.25 C 8.875 10.207031 8.277344 9.6875 7.0625 9.6875 Z"/>
    </svg>
  );
};

export default docIcon;
