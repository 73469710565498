import { request } from './http.service';
import { decodeLocationsMessages } from '../protobuf-models/locations.proto.js';

export const create = (params) => {
  return request('POST', '/restaurant', 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const update = (id, params) => {
  return request('PUT', '/restaurant/' + id, 200, params).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const destroy = (id) => {
  return request('DELETE', '/restaurant/' + id, 204).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const get = (id) => {
  return request('GET', '/restaurant/' + id, 200).then(
    (result) => {
      return result;
    },
    (error) => {
      // console.log(error);
    }
  );
};

export const allLocations = (from, limit) => {
  return request('GET', '/restaurant/' + from + '/' + limit, 200, null, decodeLocationsMessages).then(
    (result) => {
      if (result && result.locations) {
        let all = result.locations.map((f) => {
          f.info = JSON.parse(f.info);
          return f;
        });
        return all;
      }
    },
    (error) => {
      // console.log(error);
    }
  );
};
